define("@fortawesome/ember-fontawesome/templates/components/fa-icon", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "TJvKobWq",
    "block": "[[[1,[30,0,[\"html\"]]],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "@fortawesome/ember-fontawesome/templates/components/fa-icon.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});