define("ember-animated-routes/helpers/animation-queue", ["exports", "ember-animated-routes/lib/animation-queue", "@ember/component/helper"], function (_exports, _animationQueue, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function animationQueue() {
    return new _animationQueue.default();
  });

  _exports.default = _default;
});