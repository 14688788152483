
    ;define("novi-data/chat-templates", ["exports"], function (_exports) {
      "use strict";

      Object.defineProperty(_exports, "__esModule", {
        value: true
      });
      _exports.default = [
  {
    "level": 0,
    "title": "Admin",
    "link_url": null,
    "banner_img": null,
    "notification_title": null,
    "notification_body": null,
    "items": [
      {
        "level": 1,
        "title": "Welcome message",
        "message": "Hi [[patient name]], this is [[coach name]], and it was good meeting you earlier.\n\nI have updated your diet and physical activity goals as per our discussion today and here is what you can expect after today: \n- I will be in touch with you regularly on the app to provide you with guidance and support. Feel free to drop me a message should you have any questions at anytime\n- We will schedule a 15-20 min phone or video call to review your progress periodically - this would allow us to review your progress together in more detail and plan the next steps forward. You can let me know if you have a preferred timeline for this (usually monthly or every 2 months is a good time) or we can determine this as we go along. \n\nHave a good day ahead and I look forward to supporting you towards your health goals! :)",
        "link_url": null,
        "banner_img": null,
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "title": "LIP Welcome",
        "message": "Hi [[patient name]],\n\nIt was a pleasure speaking with you today. The Lifestyle Improvement Program will run for the next [[one month]], and together, we will work towards your goal of [[losing 1 kg]].\n\nTo start off, we have set the following targets for you:\n[[- Taking 2 palm sized protein a day]]\n[[- Exercising 150mins a week]]\n\n[[Remember to scan your sensor every 8 hours so we can better optimize your sugar levels. This helps with cravings, energy levels and weight management. You can try out some of the experiments we have designed to get the most out of your sensor. These experiments can be assessed on your dashboard.\n\nDo also take some time to read more on [why the CGM sensor is useful for you.](https://novi-health.com/education/glucose-monitoring-benefits-2g2cg).]]\n\nFeel free to reach out if you have any questions!",
        "link_url": null,
        "banner_img": null,
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "title": "Arranging a follow up call",
        "message": "As we're now [[approaching the end of your program | x weeks/months into the program | coming to the end of the 2-week digital coaching period]], I would like to arrange a call with you to speak about your progress so far, answer any questions you may have and discuss the plans moving forward. Are you available on [[25 Feb Fri]] at [[10am]]? It'll take ~[[15 min]].",
        "link_url": null,
        "banner_img": null,
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "title": "Archive account",
        "message": "I just wanted to let you know that your NOVIFY account will be archived shortly [[as you're no longer on active coaching | to mark the end of the program]]. Feel free to reach out to us here at anytime should you require more support from us in the future.\n\nTake care and we wish you the best of health!",
        "link_url": null,
        "banner_img": null,
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "title": "Trainerize message",
        "message": "I'm pleased to inform you that your new workouts have been designed for you, and you should be able to see them when you next open the Trainerize app. When you open up the app, you can click on the calendar icon (second from the bottom left), to see when your workouts are scheduled. There are [[2]] workouts for you to complete each week, and I would recommend alternating between these [[2]] workouts [[(e.g. Workout 1 on Monday, and Workout 2 on Thursday)]], to ensure sufficient recovery between them.\n\nAdditionally, the workouts have different movement patterns and targets different muscles so as to keep it more exciting for you. The workouts will last you for around [[6]] weeks, with increments week on week to ensure that you are progressing. \n\nAnother thing is that you can feel free to rearrange the workouts whenever you like, by simply holding the workout, and dragging/dropping them around. You can also edit the workouts when you are doing them, which gives you the freedom to select/ remove exercises. \n\nYou can play and toggle around the app to familiarize yourself with it, and if you have any questions at all, please feel free to ask me.",
        "link_url": null,
        "banner_img": null,
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "title": "Switching patients to Digital Support",
        "message": "Hi [[patient name]], I'm glad to see that you have [[patient's achievements e.g. lost 2kg over the past 3 months and improved your blood glucose control]]! We hope you've had a good experience and great job on taking your first step towards a healthier you!\n\nWhilst you will no longer receive active coaching, we encourage you to continue using the app to track your progress against your health goals. \n\nWe are also here if you have any questions. Take care and stay safe and healthy!",
        "link_url": null,
        "banner_img": null,
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "title": "PT sessions pricing",
        "message": "Here are the prices for our PT sessions, those in brackets are inclusive of GST:\n- 30min: $45 ($48.15)\n- 45min: $65 ($69.55)\n- 60min: $80 ($85.60)\n\nThe sessions can be conducted virtually online, or at our clinic.",
        "link_url": null,
        "banner_img": null,
        "notification_title": null,
        "notification_body": null
      }
    ]
  },
  {
    "level": 0,
    "title": "CGM",
    "link_url": null,
    "banner_img": null,
    "notification_title": null,
    "notification_body": null,
    "items": [
      {
        "level": 1,
        "title": "Sensor vs glucometer readings",
        "message": "The readings can be different as they are measuring glucose levels in different compartments -- the sensor measures glucose levels in the fluid outside the bloodstream while the glucometer (finger prick) measures glucose levels in the bloodstream. The biggest difference would be when glucose levels are changing (e.g. after meals) as glucose levels will rise in the bloodstream (where the glucometer is measuring) before the cells (where the sensor is measuring). [Here is a video that illustrates this](https://www.youtube.com/watch?v=a3se-HAhua4)\n\nDespite the difference, the patterns we observe on the sensor is very helpful information. For example, if we see large spikes after a meal, we can look at strategies to reduce the spike and stabilise blood glucose levels, while allowing you to continue consuming the foods that you enjoy. \n\nFurthermore, with the CGM readings, we want to focus on the \"time in range\" or \"TIR\" as opposed to just point readings. Having higher TIR is correlated with better diabetes control and HbA1c, and being able to measure ther TIR is one of the key benefits of using CGM.",
        "link_url": null,
        "banner_img": null,
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "title": "Sensor issues - Abbott contact",
        "message": "Sorry to hear that your sensor has stopped working. You can contact the Abbott Hotline at 1800-272-2881 to let them know.\n\nPlease hang on to your old sensor (if you still have it) as they will need the old sensor if they send you a new one. You will also need to provide the serial number of your sensor when you call them. You will be able to find the serial number in the LibreLink app. Go to the menu option > About.\n\nHope this helps!",
        "link_url": null,
        "banner_img": null,
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "title": "Link Libre",
        "message": "Can you help me to do the following pls:\n\n1. Click on the 3 lines on the top left of your LibreLink app and scroll down to \"Connected Apps\"\n2. Click on \"LibreView\"\n3. Click on \"Connect to a Practice\"\n4. Type in \"01595936\". \"NOVI Health\" should appear and you can click \"Connect\".\n\nThis will allow me to view your readings on my end. Let me know if you face any issues. Thanks!",
        "link_url": null,
        "banner_img": null,
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "title": "When to scan sensor",
        "message": "The data will be stored in your sensor for 8 hours so as long as you scan it at least every 8 hours you will be able to see your readings for the whole day. \n\nHowever its useful to scan your sensor before and 1-2 hours after meals whenever you can - this allows you to see how your blood sugar levels are affected by the meal. It can also help with making smart meal choices - e.g. if your blood sugar levels are already high before the meal, you may choose to have a smaller portion of carbs at that meal to prevent your blood sugar levels from going too high. \n\nAs the longest period between scans would likely be overnight, you can also scan your sensor before bed and after you wake up to avoid any data gaps overnight.",
        "link_url": null,
        "banner_img": null,
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "title": "Tightening Glucose Targets",
        "message": "Overall, you have done really well keeping your sugars within the target range (3.5-7.8 mmol/L) for more than 95% of the time 👍\n\nGlucose levels in this range are considered “normal” (i.e. no diabetes) by most international guidelines. However, if you have a meal that causes a large increase in glucose levels, with an even larger drop afterwards, you might experience energy crashes (food coma), more cravings, and hunger. These spikes can also lead to weight gain, as more insulin is produced to bring down the sugars.\n\nTo help with your goal of [[weight loss | increasing engergy levels | others]], let's aim to keep glucose levels at an \"optimal\" range of 6 mmol/L and below for 90% of the time. You can read more about the [benefits of doing so](https://novi-health.com/education/optimal-blood-glucose-range) as well.",
        "link_url": null,
        "banner_img": null,
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "title": "CGM Report",
        "message": "Hi [[patient name]],\n\nHere is a summary of how you’ve done over the last 2 weeks:\n\n- *Time in Range (TIR)*: You have kept your glucose level within the [[standard range of 3.5 - 7.8 mmol/L | optimal range of 3.5 - 6 mmol/L]] for more than [[95%]] of the time\n- *Glucose Management Indicator (GMI)*: Your GMI is [[5.7%]]. This suggests you are at [[low | increased]] risk of diabetes\n- *Recommendations*: Overall, your [[glucose metabolism is well regulated, with a low risk of diabetes. Continue to stay healthy and keep up with your regular health checks! | risk of diabetes may be increased based on your GMI and TIR values. We suggest either an oral glucose tolerance test or HbA1c test to better determine your risk of diabetes]]\n- *Additional Suggestions*: Based on our observations, you should aim to keep your carbohydrate intake at your meals to about [[½ a bowl of noodles]] to keep your glucose within range. You should consider moderating the intake of [[food A, food B]] as these tend to increase your sugar quite significantly.",
        "link_url": null,
        "banner_img": null,
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "title": "Low sensor reading while sleeping",
        "message": "Common causes of hypoglycemia while sleeping include:\n- Skipping dinner\n- Intense exercise in the night\n- Having alcohol\n- [Pressure induced low sensor reading](https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3879750/)\n\n[[The low glucose reading we observed is likely due to pressure on the sensor during sleep. This can happen when you lie on the side of your body that your sensor is applied to. The pressure results in decreased blood flow to the sensor due to tissue compression, causing a low sugar reading to be recorded. Given that you do not have symptoms, this low reading is unlikely to be a true low sugar event and there is no cause for concern.]]",
        "link_url": null,
        "banner_img": null,
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "title": "High morning sugars",
        "message": "Sugar levels can be high in the morning for various reasons. Possible causes that can be easily addressed include taking high-carb foods near bedtime, or missing night-time doses of diabetes medications. \n\nOur body also releases glucose stores in the early morning, usually between 3-8am to prepare for the upcoming day. This ensures we have sufficient energy to function. This natural phenomenon can cause our morning sugars to increase, even if we are not consuming any food. This is know as the Dawn Phenomenon.\n\n[[In your case, the raised sugar levels in the morning are likely due to the Dawn Phenomenon.]] You may wish to try the following hacks to lower your early morning sugars:\n- Taking your last meal earlier in the evening\n- Going for a walk after dinner\n\n[[We can also adjust your medication regime if needed to help with this.]]",
        "link_url": null,
        "banner_img": null,
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "title": "Elevated fasting sugars",
        "message": "Fasting sugar levels can be elevated for numerous reasons: \n- [Increased stress](https://novi-health.com/library/stress-and-glucose)\n- Poor quality or insufficient sleep\n- Lack of physical activity\n- Consumption of large high-carb meals\n\nFasting sugars can also be elevated if you have diabetes, as your body may not be able to produce or respond to insulin well. Insulin is the hormone that helps to regulate blood glucose, including fasting glucose levels.\n\n[[It does not appear that the elevated fasting glucose levels are due to large carb-heavy meals that you consumed. Have you been less active, sleeping poorly or stressed lately? If so, we can try to address some of these triggers and observe if this helps to lower your fasting sugars.]]",
        "link_url": null,
        "banner_img": null,
        "notification_title": null,
        "notification_body": null
      }
    ]
  },
  {
    "level": 0,
    "title": "Weight",
    "link_url": null,
    "banner_img": null,
    "notification_title": null,
    "notification_body": null,
    "items": [
      {
        "level": 1,
        "title": "When to weigh",
        "message": "Do remember to log your weight once a week so we can track your progress.\n\nIt is best to record your weight first thing in the morning after visiting the bathroom for consistency.",
        "link_url": null,
        "banner_img": null,
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "title": "Girth measurements",
        "message": "Besides weight, girth measurements are a good way for us to track progress. When you lose fat but gain muscle, your weight may stay the same or increase. That's actually a good thing! \n\nWaist and hip circumferences tend to decrease as you lose fat, whereas chest, shoulder, arm and leg measurements may increase as you gain muscle. This is a useful metric for us to track, so we can objectively monitor your progress.\n\n[[The infographic below teaches you how best to do these girth measurements.]]",
        "link_url": null,
        "banner_img": null,
        "notification_title": null,
        "notification_body": null
      }
    ]
  },
  {
    "level": 0,
    "title": "Nutrition",
    "link_url": null,
    "banner_img": null,
    "notification_title": null,
    "notification_body": null,
    "items": [
      {
        "level": 1,
        "title": "Taking fruits",
        "message": "While fruit naturally contains sugar, they are rich in fibre, vitamins and minerals, and are an important part of a healthy diet. \n\nYou can start by keeping your fruit portion to 1 fist size at a time and avoid taking fruits with other high carb foods (like bread for example). This keeps the carbohydrate portion controlled. \n\nFor many, the increase in glucose levels after they consume fruit will be manageable as the fibre in fruit is sufficient to help keep their blood glucose levels stable. For others, their glucose levels may increase more significantly after taking fruit. In which case, we may need to opt for fruits lower in sugar, or cut down on the portion on fruit.\n\nAs the response can be very different from person to person, it is best to try it out so we can figure out what works best for you. [[We have also included a diagram with different types of low sugar fruits that you can safely snack on.]]",
        "link_url": null,
        "banner_img": null,
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "title": "Balancing diet to reduce glucose spikes",
        "message": "For your meals, try to make the following changes:\n- Limit your rice or noodle portion to about 1/2 a plate or bowl (or about 1 fist size) \n- Try to add in at least 1 palm size worth of protein (meat, tofu, egg, beans etc)\n- Take as much vegetables as you can, ideally more than your rice or noodles\n\nThis will ensure that you stay full for a longer period of time while lowering your blood glucose levels after meals.",
        "link_url": null,
        "banner_img": null,
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "title": "Sugar free drinks",
        "message": "Here are some sugar-free drink options you can try: \n- Ayataka Japanese Green Tea\n- Pokka Green Tea (No Sugar) \n- Oolong Tea \n- Chinese Tea \n- Diet Soft Drinks (e.g. Coke Zero, 100 Plus Zero, Sprite Zero) \n- Sparkling water (e.g. F&N Ice Mountain Sparkling Can Water, DASH, Drink Almighty Sparkling Water)\n- Fruit-infused water (cut up some fruit slices and place them in your water jug)",
        "link_url": null,
        "banner_img": null,
        "notification_title": null,
        "notification_body": null
      }
    ]
  },
  {
    "level": 0,
    "title": "Physical Activity",
    "link_url": null,
    "banner_img": null,
    "notification_title": null,
    "notification_body": null,
    "items": [
      {
        "level": 1,
        "title": "Blood glucose spikes with exercise",
        "message": "When you exercise, your body produces hormones that release glucose stores from your liver, causing blood glucose to increase. This is a natural, non-harmful increase, designed to fuel your workout. Your muscles will take up the glucose resulting in a subsequent fall in blood glucose levels so this transient increase in blood glucose levels is not a concern.",
        "link_url": null,
        "banner_img": null,
        "notification_title": null,
        "notification_body": null
      }
    ]
  },
  {
    "level": 0,
    "title": "Infographics",
    "link_url": null,
    "banner_img": null,
    "notification_title": null,
    "notification_body": null,
    "items": [
      {
        "level": 1,
        "type": "image",
        "title": "Girth Measurements (Woman)",
        "link_url": null,
        "banner_img": "Girth Measurements - Woman_v2.jpg",
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "type": "image",
        "title": "Girth Measurements (Man)",
        "link_url": null,
        "banner_img": "Girth Measurements - Man_v2.jpg",
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "type": "image",
        "title": "Fruit & Vegetable Portions",
        "link_url": null,
        "banner_img": "Fruits and Vegetables.jpg",
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "type": "image",
        "title": "Protein Sources",
        "link_url": null,
        "banner_img": "Protein_v5.jpg",
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "type": "image",
        "title": "Protein Sources (Chinese)",
        "link_url": null,
        "banner_img": "Protein_v3_Chinese.jpg",
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "type": "image",
        "title": "Plant-based Protein Sources",
        "link_url": null,
        "banner_img": "Protein_Plant Based Eggs and Diary_v4.jpg",
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "type": "image",
        "title": "Plant-based Protein Sources (Chinese)",
        "link_url": null,
        "banner_img": "Protein_Plant Based Eggs and Diary_v4_Chinese.jpg",
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "type": "image",
        "title": "Carbohydrate Sources",
        "link_url": null,
        "banner_img": "Carbs.jpg",
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "type": "image",
        "title": "Carbohydrate Sources (Chinese)",
        "link_url": null,
        "banner_img": "Carbs_Chinese.jpg",
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "type": "image",
        "title": "Fruits and Sugar",
        "link_url": null,
        "banner_img": "Fruits and Sugar.jpg",
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "type": "image",
        "title": "Fibre",
        "link_url": null,
        "banner_img": "Fiber.jpg",
        "notification_title": null,
        "notification_body": null
      },
      {
        "level": 1,
        "type": "image",
        "title": "My Healthy Plate",
        "link_url": null,
        "banner_img": "My Healthy Plate.jpg",
        "notification_title": null,
        "notification_body": null
      }
    ]
  },
  {
    "level": 0,
    "title": "Articles",
    "link_url": null,
    "banner_img": null,
    "notification_title": null,
    "notification_body": null,
    "items": [
      {
        "level": 1,
        "type": "article",
        "title": "Benefits of CGM even without diabetes",
        "message": "Health Benefits Of Glucose Monitoring Even If You Do Not Have Diabetes\nFind out how continuous glucose monitoring can help improve your health even if you do not have diabetes",
        "link_url": "https://novi-health.com/education/glucose-monitoring-benefits-2g2cg",
        "banner_img": "fsl.jpg",
        "notification_title": "Benefits Of Glucose Monitoring",
        "notification_body": "Find out how continuous glucose monitoring can help improve your health even if you do not have diabetes"
      },
      {
        "level": 1,
        "type": "article",
        "title": "Glucose experiments (basic)",
        "message": "Fun Experiments To Try While On Your Glucose Sensor\nTry out these different meals while on your sensor and see how small changes can make a difference to your sugar levels",
        "link_url": "https://novi-health.com/education/glucose-experiments",
        "banner_img": "meal-compare.jpg",
        "notification_title": "Glucose Experiments To Try",
        "notification_body": "Try out these meals while on your sensor and see how small changes can make a difference to your sugar levels"
      },
      {
        "level": 1,
        "type": "article",
        "title": "Causes of hypoglycemia without medication",
        "message": "What You Need to Know About Hypoglycemia If You Don’t Have Diabetes\nFind out the common causes of hypoglycemia and how to avoid them",
        "link_url": "https://novi-health.com/education/hypoglycemia-without-diabetes",
        "banner_img": "finger-prick.jpg",
        "notification_title": "Causes Of Low Blood Sugar",
        "notification_body": "Find out the common causes of hypoglycemia and how to avoid them"
      },
      {
        "level": 1,
        "type": "article",
        "title": "Diet tips for glucose control",
        "message": "5 Simple Dietary Tips For Improving Blood Sugar",
        "link_url": "https://novi-health.com/education/dietary-tips-for-improving-blood-sugar",
        "banner_img": "healthy-foods.jpg",
        "notification_title": "5 Tips For Better Blood Sugar",
        "notification_body": null
      }
    ]
  }
]
    });
  