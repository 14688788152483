define("ember-math-helpers/helpers/sqrt", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.sqrt = sqrt;

  /**
   * Executes `Math.sqrt` on the number passed to the helper.
   *
   * ```hbs
   * {{sqrt a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.sqrt`
   * @return {number} The sqrt of the passed number
   */
  function sqrt(_ref) {
    let [number] = _ref;
    return Math.sqrt(number);
  }

  var _default = (0, _helper.helper)(sqrt);

  _exports.default = _default;
});