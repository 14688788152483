define("ember-animated-routes/modifiers/animate-in", ["exports", "@ember/modifier"], function (_exports, _modifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AnimateInManager = {
    capabilities: (0, _modifier.capabilities)('3.22'),

    createModifier() {
      return {
        element: null,
        renderTask: null
      };
    },

    installModifier(state, element, _ref) {
      let {
        positional
      } = _ref;
      state.element = element;
      this.animateIn(state, positional);
    },

    updateModifier(state, _ref2) {
      let {
        positional
      } = _ref2;

      if (!state.renderTask) {
        this.animateIn(state, positional);
      }
    },

    destroyModifier() {},

    animateIn(state, _ref3) {
      let [component, fn] = _ref3;
      state.renderTask = component.args ? component.args.__renderTask__ : component.__renderTask__;

      if (state.renderTask) {
        state.renderTask.performInAnimation(fn, state.element);
      }
    }

  };

  var _default = (0, _modifier.setModifierManager)(() => AnimateInManager, class AnimateInModifier {});

  _exports.default = _default;
});