define("ember-animated-routes/lib/animation-queue", ["exports", "ember-animated-routes/lib/render-task", "@glimmer/tracking", "@ember/array"], function (_exports, _renderTask, _tracking, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AnimationQueue = (_class = class AnimationQueue {
    constructor() {
      _defineProperty(this, "promise", new Promise(res => res()));

      _initializerDefineProperty(this, "tasks", _descriptor, this);
    }

    renderTaskFor(component) {
      return this.tasks.find(task => task.component === component);
    }

    queueRender(component, fromRoute, attributes) {
      const existing = this.tasks.find(task => task.component === component && task.reusable);

      if (existing) {
        existing.reset();
        existing.fromRoute = fromRoute;
        existing.attributes = attributes;
        this.chainPromise(existing);
        return;
      }

      const task = new _renderTask.default(this, component, fromRoute, attributes);
      this.tasks.pushObject(task);
      this.chainPromise(task);
    }

    queueTeardown(component, toRoute) {
      this.tasks.find(task => task.component === component && !task.tearingdown).teardown(toRoute);
    }

    chainPromise(task) {
      this.promise = new Promise(async res => {
        await this.promise;
        await task.perform();
        res();
      });
    }

    remove(task) {
      this.tasks.removeObject(task);
    }

    hasInDOM(routeName) {
      return this.tasks.some(task => task.inDOM && task.component.args.name === routeName);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "tasks", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _array.A)();
    }
  })), _class);
  _exports.default = AnimationQueue;
});