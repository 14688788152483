define("ember-animated-routes/lib/render-task", ["exports", "@glimmer/tracking"], function (_exports, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let RenderTask = (_class = class RenderTask {
    constructor(queue, component, fromRoute, attributes) {
      _defineProperty(this, "fromRoute", void 0);

      _defineProperty(this, "toRoute", void 0);

      _defineProperty(this, "tearingdown", void 0);

      _defineProperty(this, "resolveTeardown", void 0);

      _defineProperty(this, "reusable", void 0);

      _defineProperty(this, "inPromises", []);

      _defineProperty(this, "inAnimations", []);

      _defineProperty(this, "outAnimations", []);

      _initializerDefineProperty(this, "inDOM", _descriptor, this);

      _initializerDefineProperty(this, "attributes", _descriptor2, this);

      this.queue = queue;
      this.component = component;
      this.fromRoute = fromRoute;
      this.attributes = attributes;
      this.reset();
    }

    reset() {
      this.inDOMPromise = new Promise(res => this.didInsertIntoDOM = res);
      this.tearingdown = false;
      this.teardownPromise = new Promise(res => this.resolveTeardown = res);
      this.inPromises = [];
      this.inAnimations.forEach(_ref => {
        let [fn, element] = _ref;
        return this.performInAnimation(fn, element);
      });
    }

    async perform() {
      this.inDOM = true;
      this.didInsertIntoDOM();
      await Promise.all(this.inPromises);
      await this.teardownPromise;
      await this.animateOut();
      this.reusable = true;
    }

    teardown(toRoute) {
      this.toRoute = toRoute;
      this.tearingdown = true;
      this.resolveTeardown();
    }

    async performInAnimation(fn, element) {
      if (!this.inAnimations.find(_ref2 => {
        let [existing] = _ref2;
        return existing === fn;
      })) {
        this.inAnimations.push([fn, element]);
      }

      await this.inDOMPromise;
      const promise = new Promise(async next => {
        await fn(element, {
          next,
          fromRoute: this.fromRoute
        });
        next();
      });
      this.inPromises.push(promise);
    }

    registerOutAnimation(fn, element) {
      if (!this.outAnimations.find(_ref3 => {
        let [existing] = _ref3;
        return existing === fn;
      })) {
        this.outAnimations.push([fn, element]);
      }
    }

    async animateOut() {
      const nextPromises = [];
      const fnPromises = [];

      for (let [fn, element] of this.outAnimations) {
        const nextPromise = new Promise(async next => {
          const fnPromise = fn(element, {
            next,
            toRoute: this.toRoute
          });
          fnPromises.push(fnPromise);

          try {
            await fnPromise;
          } catch (e) {
            // ember-concurrency
            if (e.name !== 'TaskCancelation') {
              throw e;
            }
          }

          next();
        });
        nextPromises.push(nextPromise);
      }

      Promise.all(fnPromises).then(() => this.removeFromDOM()).catch(e => {
        // ember-concurrency
        if (e.name !== 'TaskCancelation') {
          throw e;
        }
      });
      return Promise.all(nextPromises);
    }

    removeFromDOM() {
      if (this.tearingdown && !this.component.args.alwaysRendered) {
        this.inDOM = false;
        this.queue.remove(this);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "inDOM", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "attributes", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = RenderTask;
});