define("ember-math-helpers/helpers/sub", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.sub = sub;

  /**
   * Subtracts two or more numbers
   *
   * ```hbs
   * {{sub a b}}
   * ```
   *
   * @param {number[]} numbers A list of numbers to subtract
   * @return {number} The difference of all the passed numbers
   */
  function sub(numbers) {
    return numbers.reduce((a, b) => Number(a) - Number(b));
  }

  var _default = (0, _helper.helper)(sub);

  _exports.default = _default;
});