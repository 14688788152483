define("ember-animated-routes/helpers/in-dom", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function inDom(_ref) {
    let [queue, routeName] = _ref;
    return queue.hasInDOM(routeName);
  });

  _exports.default = _default;
});