define("ember-math-helpers/helpers/max", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.max = max;

  /**
   * Takes two or more numbers, and finds the max of the set using `Math.max`
   *
   * ```hbs
   * {{max a b}}
   * ```
   *
   * @param {number[]} numbers A list of numbers to pass to `Math.max`
   * @return {number} The max of the set of numbers
   */
  function max(numbers) {
    return Math.max(...numbers);
  }

  var _default = (0, _helper.helper)(max);

  _exports.default = _default;
});