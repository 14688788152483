
    ;define("novi-data/food-bank", ["exports"], function (_exports) {
      "use strict";

      Object.defineProperty(_exports, "__esModule", {
        value: true
      });
      _exports.default = [
  {
    "id": 435,
    "disabled": 1,
    "type": "meal",
    "name": "Vegetable (green e.g. kale, spinach)",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": null,
    "unit": null,
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "vegetable (green e.g. kale, spinach)",
    "weight-g": null,
    "volume-ml": null,
    "calories": null,
    "fat": null,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": null,
    "fibre": null,
    "sugar": null,
    "protein": null,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 621,
    "disabled": null,
    "type": "drink",
    "name": "2-in-1 coffee, unsweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "sachet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "2-in-1 coffee, unsweetened",
    "weight-g": 9,
    "volume-ml": null,
    "calories": 45.2,
    "fat": 2.4,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 5.2,
    "fibre": null,
    "sugar": 1.3,
    "protein": 0.7,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 620,
    "disabled": null,
    "type": "drink",
    "name": "3-in-1 coffee, reduced sugar",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "sachet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "3-in-1 coffee, reduced sugar",
    "weight-g": 15,
    "volume-ml": null,
    "calories": 66,
    "fat": 2.1,
    "saturated": 2,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 16,
    "carbs": 11,
    "fibre": 0.4,
    "sugar": 6.2,
    "protein": 0.6,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 474,
    "disabled": null,
    "type": "snack",
    "name": "Agar agar",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "agar agar",
    "weight-g": 111,
    "volume-ml": null,
    "calories": 84.6,
    "fat": 0.2,
    "saturated": 0.2,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 20.5,
    "fibre": 0.6,
    "sugar": 17.3,
    "protein": 0.2,
    "potassium": 6,
    "calcium": 2,
    "iron": 0.3,
    "phosphorus": 1,
    "info": null
  },
  {
    "id": 1025,
    "disabled": null,
    "type": "snack",
    "name": "Almond cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "almond cake",
    "weight-g": 108,
    "volume-ml": null,
    "calories": 450,
    "fat": 26,
    "saturated": 11,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0.5,
    "cholesterol": 130,
    "sodium": 291,
    "carbs": 49,
    "fibre": 3,
    "sugar": 38,
    "protein": 8,
    "potassium": null,
    "calcium": 100,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 166,
    "disabled": null,
    "type": "drink",
    "name": "Almond milk, sweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "almond milk, sweetened",
    "weight-g": null,
    "volume-ml": 240,
    "calories": 60.6,
    "fat": 2.6,
    "saturated": 0,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 167,
    "carbs": 8.3,
    "fibre": 0.5,
    "sugar": 7.3,
    "protein": 1,
    "potassium": null,
    "calcium": 118,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 362,
    "disabled": null,
    "type": "drink",
    "name": "Almond milk, unsweetened",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "almond milk, unsweetened",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 31.4,
    "fat": 2.6,
    "saturated": 0,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 177,
    "carbs": 1,
    "fibre": 0.5,
    "sugar": 0,
    "protein": 1,
    "potassium": null,
    "calcium": 118,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 188,
    "disabled": null,
    "type": "snack",
    "name": "Almond paste",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Xin Ren Hu",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "almond paste xin ren [hu|wu|woo]",
    "weight-g": 262,
    "volume-ml": null,
    "calories": 216.1,
    "fat": 11.3,
    "saturated": 0.7,
    "polyunsaturated": 2.7,
    "monounsaturated": 7.9,
    "trans": null,
    "cholesterol": 0,
    "sodium": 30.7,
    "carbs": 23.1,
    "fibre": 1.6,
    "sugar": 18.1,
    "protein": 5.5,
    "potassium": null,
    "calcium": 41,
    "iron": 0.9,
    "phosphorus": null,
    "info": "Xin Ren Hu"
  },
  {
    "id": 221,
    "disabled": null,
    "type": "snack",
    "name": "Almond pudding / jelly",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "almond pudding / jelly",
    "weight-g": 223,
    "volume-ml": null,
    "calories": 109,
    "fat": 0.5,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 2,
    "carbs": 36.6,
    "fibre": 1.3,
    "sugar": 34.6,
    "protein": 0.9,
    "potassium": null,
    "calcium": 25,
    "iron": 0.2,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 673,
    "disabled": null,
    "type": "snack",
    "name": "Aloe vera",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "aloe vera",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 2.4,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 16,
    "carbs": 0.6,
    "fibre": 0,
    "sugar": 0.6,
    "protein": 0,
    "potassium": 24,
    "calcium": 27,
    "iron": 0,
    "phosphorus": 3,
    "info": null
  },
  {
    "id": 61,
    "disabled": null,
    "type": "snack",
    "name": "Ang ku kueh with mung bean filling",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ang [ku|koo] [kueh|kuih|kue] with [mung|green] bean filling",
    "weight-g": 70,
    "volume-ml": null,
    "calories": 140.9,
    "fat": 1.7,
    "saturated": 1,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.4,
    "trans": null,
    "cholesterol": 0,
    "sodium": 26,
    "carbs": 28.1,
    "fibre": 2.1,
    "sugar": 5.7,
    "protein": 3.3,
    "potassium": null,
    "calcium": 8,
    "iron": 0.7,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 62,
    "disabled": null,
    "type": "snack",
    "name": "Ang ku kueh with peanut filling",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ang [ku|koo] [kueh|kuih|kue] with peanut filling",
    "weight-g": 68,
    "volume-ml": null,
    "calories": 239.8,
    "fat": 12.2,
    "saturated": 4.1,
    "polyunsaturated": 3.3,
    "monounsaturated": 4.4,
    "trans": null,
    "cholesterol": 0,
    "sodium": 36,
    "carbs": 26.4,
    "fibre": 3.3,
    "sugar": 5,
    "protein": 6.1,
    "potassium": null,
    "calcium": 15,
    "iron": 0.7,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 479,
    "disabled": null,
    "type": "snack",
    "name": "Apam balik",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Ban Chang Kueh",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with peanut / red bean",
    "search-text": "apam balik [ban|mee|meen|min] [chang|chien|chiang|jiang] [kueh|kuih|kue]",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 262.4,
    "fat": 5.2,
    "saturated": 1.4,
    "polyunsaturated": 1.6,
    "monounsaturated": 1.9,
    "trans": null,
    "cholesterol": 0,
    "sodium": 7,
    "carbs": 46.2,
    "fibre": 3.2,
    "sugar": 8.8,
    "protein": 7.7,
    "potassium": null,
    "calcium": 35,
    "iron": 1,
    "phosphorus": null,
    "info": "Ban Chang Kueh – with peanut / red bean"
  },
  {
    "id": 379,
    "disabled": null,
    "type": "snack",
    "name": "Apple",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "fruit",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "apple",
    "weight-g": 160,
    "volume-ml": null,
    "calories": 92,
    "fat": 0.4,
    "saturated": 0.1,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 21.8,
    "fibre": 2.7,
    "sugar": 21.8,
    "protein": 0.3,
    "potassium": 166,
    "calcium": 6,
    "iron": 0.1,
    "phosphorus": 10,
    "info": null
  },
  {
    "id": 706,
    "disabled": null,
    "type": "snack",
    "name": "Apricot",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 3,
    "unit": "fruit",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "apricot",
    "weight-g": 114,
    "volume-ml": null,
    "calories": 56.8,
    "fat": 0.4,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1,
    "carbs": 11.8,
    "fibre": 2.1,
    "sugar": 9.8,
    "protein": 1.5,
    "potassium": 275,
    "calcium": 14,
    "iron": 0.4,
    "phosphorus": 24,
    "info": null
  },
  {
    "id": 664,
    "disabled": null,
    "type": "meal",
    "name": "Asparagus",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 6,
    "unit": "stalk",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "asparagus",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 33.8,
    "fat": 0.2,
    "saturated": 1,
    "polyunsaturated": 1,
    "monounsaturated": 5,
    "trans": null,
    "cholesterol": 0,
    "sodium": 492,
    "carbs": 6,
    "fibre": 2,
    "sugar": 4,
    "protein": 2,
    "potassium": 221,
    "calcium": 25,
    "iron": 2,
    "phosphorus": 59,
    "info": null
  },
  {
    "id": 407,
    "disabled": null,
    "type": "snack",
    "name": "Avocado",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "fruit",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "avocado",
    "weight-g": 201,
    "volume-ml": null,
    "calories": 296,
    "fat": 29.5,
    "saturated": 4.3,
    "polyunsaturated": 3.7,
    "monounsaturated": 19.7,
    "trans": null,
    "cholesterol": 0,
    "sodium": 14.1,
    "carbs": 3.6,
    "fibre": 13.5,
    "sugar": 1.3,
    "protein": 4,
    "potassium": 975,
    "calcium": 24.1,
    "iron": 1.1,
    "phosphorus": 105,
    "info": null
  },
  {
    "id": 459,
    "disabled": null,
    "type": "meal",
    "name": "Ayam penyet",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": 1,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with tempeh, tauhu, sambal",
    "search-text": "ayam penyet",
    "weight-g": 462,
    "volume-ml": null,
    "calories": 917,
    "fat": 40.6,
    "saturated": 12.5,
    "polyunsaturated": 3.4,
    "monounsaturated": 7.2,
    "trans": null,
    "cholesterol": 136,
    "sodium": 2209,
    "carbs": 104.7,
    "fibre": 10.9,
    "sugar": 12,
    "protein": 33.2,
    "potassium": 400,
    "calcium": 101,
    "iron": 2.3,
    "phosphorus": 414,
    "info": "with tempeh, tauhu, sambal"
  },
  {
    "id": 649,
    "disabled": null,
    "type": "meal",
    "name": "Baby fine bean / french bean",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "baby fine bean / french",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 109.6,
    "fat": 7.6,
    "saturated": 3.4,
    "polyunsaturated": 0.7,
    "monounsaturated": 3.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 941,
    "carbs": 8.9,
    "fibre": 2.8,
    "sugar": 3.6,
    "protein": 1.4,
    "potassium": 18,
    "calcium": 44,
    "iron": 0.3,
    "phosphorus": 8,
    "info": null
  },
  {
    "id": 880,
    "disabled": null,
    "type": "meal",
    "name": "Baby octopus, seasoned",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 0.5,
    "unit": "plate",
    "info-translation": "Chuka Iidako",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "baby octopus, seasoned chuka iidako",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 143,
    "fat": 5.5,
    "saturated": 0.8,
    "polyunsaturated": 2.7,
    "monounsaturated": 1.7,
    "trans": null,
    "cholesterol": 109,
    "sodium": 668,
    "carbs": 8.3,
    "fibre": 1,
    "sugar": 4.1,
    "protein": 15.2,
    "potassium": 49,
    "calcium": 23,
    "iron": 0.5,
    "phosphorus": 87,
    "info": "Chuka Iidako"
  },
  {
    "id": 879,
    "disabled": null,
    "type": "meal",
    "name": "Baby octopus, stir-fried / grilled",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 0.5,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "baby octopus, stir-fried / grilled",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 222,
    "fat": 11.6,
    "saturated": 1.9,
    "polyunsaturated": 4.4,
    "monounsaturated": 4.4,
    "trans": null,
    "cholesterol": 79,
    "sodium": 547,
    "carbs": 12.2,
    "fibre": 0.6,
    "sugar": 0.9,
    "protein": 16.3,
    "potassium": 351,
    "calcium": 78,
    "iron": 5.6,
    "phosphorus": 207,
    "info": null
  },
  {
    "id": 92,
    "disabled": null,
    "type": "meal",
    "name": "Bacon",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bacon",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 121.3,
    "fat": 10.1,
    "saturated": 4,
    "polyunsaturated": 1.1,
    "monounsaturated": 4.6,
    "trans": null,
    "cholesterol": 38,
    "sodium": 606,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 7.6,
    "potassium": 87,
    "calcium": 4,
    "iron": 0.5,
    "phosphorus": 48,
    "info": null
  },
  {
    "id": 1106,
    "disabled": null,
    "type": "snack",
    "name": "Bagel, plain",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bagel",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bagel, plain",
    "weight-g": 114,
    "volume-ml": null,
    "calories": 314,
    "fat": 1.8,
    "saturated": 0.3,
    "polyunsaturated": 0.8,
    "monounsaturated": 1.3,
    "trans": null,
    "cholesterol": null,
    "sodium": 609,
    "carbs": 60.9,
    "fibre": 2.6,
    "sugar": null,
    "protein": 12,
    "potassium": 115,
    "calcium": 84,
    "iron": 4.1,
    "phosphorus": 109,
    "info": null
  },
  {
    "id": 890,
    "disabled": null,
    "type": "meal",
    "name": "Baguette, plain",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 0.5,
    "unit": "mini",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "4.5\"",
    "info-include-exclude": null,
    "search-text": "baguette, plain",
    "weight-g": 76,
    "volume-ml": null,
    "calories": 207,
    "fat": 1.8,
    "saturated": 0.4,
    "polyunsaturated": 0.7,
    "monounsaturated": 0.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 458,
    "carbs": 39.5,
    "fibre": 1.7,
    "sugar": 3.5,
    "protein": 8.2,
    "potassium": 89,
    "calcium": 40,
    "iron": 3,
    "phosphorus": 80,
    "info": "(4.5\")"
  },
  {
    "id": 445,
    "disabled": null,
    "type": "meal",
    "name": "Bak kut teh with rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bak kut teh with rice",
    "weight-g": 638,
    "volume-ml": null,
    "calories": 576,
    "fat": 24.3,
    "saturated": 9.6,
    "polyunsaturated": 0.6,
    "monounsaturated": 8.7,
    "trans": null,
    "cholesterol": 153,
    "sodium": 1325,
    "carbs": 56,
    "fibre": 1.8,
    "sugar": 0.2,
    "protein": 33,
    "potassium": null,
    "calcium": 336,
    "iron": 7,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1053,
    "disabled": null,
    "type": "meal",
    "name": "Bak kut teh, without rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bak kut teh, without rice",
    "weight-g": 458,
    "volume-ml": null,
    "calories": 346.7,
    "fat": 25.2,
    "saturated": 10.1,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 164.9,
    "sodium": 1415,
    "carbs": null,
    "fibre": null,
    "sugar": null,
    "protein": 30,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 488,
    "disabled": null,
    "type": "snack",
    "name": "Bak zhang",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Glutinous rice dumplings with meat filling",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bak zhang glutinous rice dumplings with meat filling",
    "weight-g": 165,
    "volume-ml": null,
    "calories": 314,
    "fat": 7.9,
    "saturated": 3,
    "polyunsaturated": 1.3,
    "monounsaturated": 3.3,
    "trans": null,
    "cholesterol": 18,
    "sodium": 495,
    "carbs": 52.3,
    "fibre": 1.8,
    "sugar": 3,
    "protein": 8.4,
    "potassium": null,
    "calcium": 25,
    "iron": 1.6,
    "phosphorus": null,
    "info": "Glutinous rice dumplings with meat filling"
  },
  {
    "id": 227,
    "disabled": null,
    "type": "snack",
    "name": "Baked puff with savoury filling",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "sardine, tuna",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "baked puff with savoury filling sardine, tuna",
    "weight-g": 36,
    "volume-ml": null,
    "calories": 112.2,
    "fat": 6.2,
    "saturated": 2.8,
    "polyunsaturated": 0.7,
    "monounsaturated": 2.5,
    "trans": null,
    "cholesterol": 8,
    "sodium": 146,
    "carbs": 11.1,
    "fibre": 1,
    "sugar": 1.1,
    "protein": 3,
    "potassium": 60,
    "calcium": 22,
    "iron": 0.5,
    "phosphorus": 35,
    "info": "e.g. sardine, tuna"
  },
  {
    "id": 40,
    "disabled": null,
    "type": "snack",
    "name": "Baklava",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "2\" x 2\" x 1\"",
    "info-include-exclude": null,
    "search-text": "baklava",
    "weight-g": 78,
    "volume-ml": null,
    "calories": 342.3,
    "fat": 22.7,
    "saturated": 9.5,
    "polyunsaturated": 4.1,
    "monounsaturated": 7.7,
    "trans": null,
    "cholesterol": 35,
    "sodium": 263,
    "carbs": 29.3,
    "fibre": 2,
    "sugar": 9.9,
    "protein": 5.2,
    "potassium": 130,
    "calcium": 31,
    "iron": 1.5,
    "phosphorus": 90,
    "info": "(2\" x 2\" x 1\")"
  },
  {
    "id": 692,
    "disabled": null,
    "type": "meal",
    "name": "Bamboo shoot, braised / canned, drained",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bamboo shoot, braised / canned, drained",
    "weight-g": 102,
    "volume-ml": null,
    "calories": 73.1,
    "fat": 5.1,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 782,
    "carbs": 5.1,
    "fibre": 1.7,
    "sugar": 0,
    "protein": 1.7,
    "potassium": 17,
    "calcium": 17,
    "iron": 3.4,
    "phosphorus": 34,
    "info": null
  },
  {
    "id": 446,
    "disabled": null,
    "type": "meal",
    "name": "Ban mian, dry",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ban mian, dry",
    "weight-g": 562,
    "volume-ml": null,
    "calories": 502.6,
    "fat": 21.8,
    "saturated": 8.5,
    "polyunsaturated": 3.3,
    "monounsaturated": 9.5,
    "trans": null,
    "cholesterol": 232,
    "sodium": 2885,
    "carbs": 54.6,
    "fibre": 3.2,
    "sugar": 2.3,
    "protein": 22,
    "potassium": 601,
    "calcium": 106,
    "iron": 1.9,
    "phosphorus": 217,
    "info": null
  },
  {
    "id": 239,
    "disabled": null,
    "type": "meal",
    "name": "Ban mian, soup",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ban mian, soup",
    "weight-g": 528,
    "volume-ml": null,
    "calories": 474.5,
    "fat": 21.7,
    "saturated": 8.5,
    "polyunsaturated": 3.2,
    "monounsaturated": 9.5,
    "trans": null,
    "cholesterol": 232,
    "sodium": 2196,
    "carbs": 48.1,
    "fibre": 3.2,
    "sugar": 0,
    "protein": 21.7,
    "potassium": 491,
    "calcium": 84,
    "iron": 1.1,
    "phosphorus": 174,
    "info": null
  },
  {
    "id": 380,
    "disabled": null,
    "type": "snack",
    "name": "Banana",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "fruit",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "banana",
    "weight-g": 136,
    "volume-ml": null,
    "calories": 116.7,
    "fat": 0.4,
    "saturated": 0.1,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1.2,
    "carbs": 27,
    "fibre": 3.1,
    "sugar": 14.4,
    "protein": 1.3,
    "potassium": 422,
    "calcium": 5.9,
    "iron": 0.3,
    "phosphorus": 26,
    "info": null
  },
  {
    "id": 1041,
    "disabled": null,
    "type": "snack",
    "name": "Banana bread",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "banana bread",
    "weight-g": 57,
    "volume-ml": null,
    "calories": 240,
    "fat": 9,
    "saturated": 1.2,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 25.1,
    "sodium": 170,
    "carbs": 39,
    "fibre": 1,
    "sugar": 24,
    "protein": 3,
    "potassium": null,
    "calcium": null,
    "iron": 1,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 15,
    "disabled": null,
    "type": "drink",
    "name": "Bandung",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[bandung|bandong]",
    "weight-g": null,
    "volume-ml": 240,
    "calories": 153.2,
    "fat": 2.4,
    "saturated": 1.7,
    "polyunsaturated": 0,
    "monounsaturated": 0.5,
    "trans": null,
    "cholesterol": 2,
    "sodium": 17,
    "carbs": 31.9,
    "fibre": 0,
    "sugar": 31.9,
    "protein": 1,
    "potassium": 44,
    "calcium": 44,
    "iron": 0,
    "phosphorus": 24,
    "info": null
  },
  {
    "id": 1070,
    "disabled": null,
    "type": "snack",
    "name": "Bao with chicken filling",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bao",
    "info-translation": "Chicken Bao",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "small",
    "info-include-exclude": null,
    "search-text": "[bao|pau|pao] with chicken filling chicken",
    "weight-g": 70,
    "volume-ml": null,
    "calories": 165,
    "fat": 7.8,
    "saturated": 2.7,
    "polyunsaturated": 1.4,
    "monounsaturated": 3.3,
    "trans": null,
    "cholesterol": 54,
    "sodium": 192,
    "carbs": 17.5,
    "fibre": 1.1,
    "sugar": 3,
    "protein": 6.2,
    "potassium": 122,
    "calcium": 18,
    "iron": 0.7,
    "phosphorus": 67,
    "info": "Chicken Bao (small)"
  },
  {
    "id": 1071,
    "disabled": null,
    "type": "snack",
    "name": "Bao with chicken filling",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bao",
    "info-translation": "Chicken Bao",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "big",
    "info-include-exclude": null,
    "search-text": "[bao|pau|pao] with chicken filling chicken",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 283.4,
    "fat": 13.4,
    "saturated": 4.6,
    "polyunsaturated": 2.4,
    "monounsaturated": 5.6,
    "trans": null,
    "cholesterol": 92,
    "sodium": 330,
    "carbs": 30,
    "fibre": 1.8,
    "sugar": 5.2,
    "protein": 10.7,
    "potassium": 209,
    "calcium": 31,
    "iron": 1.2,
    "phosphorus": 115,
    "info": "Chicken Bao (big)"
  },
  {
    "id": 74,
    "disabled": null,
    "type": "snack",
    "name": "Bao with lotus seed filling",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bao",
    "info-translation": "Lian Rong Bao",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[bao|pau|pao] with lotus seed filling lian rong",
    "weight-g": 56,
    "volume-ml": null,
    "calories": 169.6,
    "fat": 4.4,
    "saturated": 2,
    "polyunsaturated": 0.5,
    "monounsaturated": 1.7,
    "trans": null,
    "cholesterol": 2,
    "sodium": 55,
    "carbs": 29,
    "fibre": 1,
    "sugar": 13.8,
    "protein": 3.5,
    "potassium": 88,
    "calcium": 18,
    "iron": 0.6,
    "phosphorus": 49,
    "info": "Lian Rong Bao"
  },
  {
    "id": 1069,
    "disabled": null,
    "type": "snack",
    "name": "Bao with red bean paste",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bao",
    "info-translation": "Tau Sar Bao",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[bao|pau|pao] with red bean paste [tau|dou] [sar|sha]",
    "weight-g": 67,
    "volume-ml": null,
    "calories": 205.6,
    "fat": 6.4,
    "saturated": 2.8,
    "polyunsaturated": 0.7,
    "monounsaturated": 2.5,
    "trans": null,
    "cholesterol": 2,
    "sodium": 90,
    "carbs": 33,
    "fibre": 2.1,
    "sugar": 15.2,
    "protein": 4,
    "potassium": 139,
    "calcium": 42,
    "iron": 1.4,
    "phosphorus": 56,
    "info": "Tau Sar Bao"
  },
  {
    "id": 73,
    "disabled": null,
    "type": "snack",
    "name": "Bao with roast pork filling",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bao",
    "info-translation": "Char Siew Bao",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[bao|pau|pao] with roast pork filling [char|cha] [siew|shao|siu]",
    "weight-g": 70,
    "volume-ml": null,
    "calories": 211.3,
    "fat": 8.5,
    "saturated": 3.2,
    "polyunsaturated": 1.5,
    "monounsaturated": 3.1,
    "trans": null,
    "cholesterol": 13,
    "sodium": 276,
    "carbs": 26.1,
    "fibre": 1,
    "sugar": 3.8,
    "protein": 7.6,
    "potassium": null,
    "calcium": 13,
    "iron": 0.6,
    "phosphorus": null,
    "info": "Char Siew Bao"
  },
  {
    "id": 1004,
    "disabled": null,
    "type": "snack",
    "name": "Barley soup",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Fu Chok",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with gingko nut and beancurd",
    "search-text": "barley soup fu chok",
    "weight-g": 250,
    "volume-ml": null,
    "calories": 197.2,
    "fat": 3.2,
    "saturated": 0.6,
    "polyunsaturated": 1.5,
    "monounsaturated": 0.9,
    "trans": null,
    "cholesterol": 24,
    "sodium": 55,
    "carbs": 33.8,
    "fibre": 2.3,
    "sugar": 21,
    "protein": 8.3,
    "potassium": 255,
    "calcium": 51,
    "iron": 0.4,
    "phosphorus": 135,
    "info": "Fu Chok – with gingko nut and beancurd"
  },
  {
    "id": 173,
    "disabled": null,
    "type": "drink",
    "name": "Barley water, sweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": "Yi Mi Shui",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "barley water, sweetened yi [mi|mee] shui",
    "weight-g": null,
    "volume-ml": 237,
    "calories": 56,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 2,
    "carbs": 14,
    "fibre": 0,
    "sugar": 14,
    "protein": 0,
    "potassium": 21,
    "calcium": 24,
    "iron": 0,
    "phosphorus": 2,
    "info": "Yi Mi Shui"
  },
  {
    "id": 365,
    "disabled": null,
    "type": "drink",
    "name": "Barley water, unsweetened",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": "Yi Mi Shui",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "barley water, unsweetened yi [mi|mee] shui",
    "weight-g": null,
    "volume-ml": 237,
    "calories": 20,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 2,
    "carbs": 5,
    "fibre": 0,
    "sugar": 1,
    "protein": 0,
    "potassium": 21,
    "calcium": 24,
    "iron": 0,
    "phosphorus": 2,
    "info": "Yi Mi Shui"
  },
  {
    "id": 240,
    "disabled": null,
    "type": "meal",
    "name": "BBQ pork ribs",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bbq pork ribs",
    "weight-g": 28,
    "volume-ml": null,
    "calories": 73.5,
    "fat": 5.9,
    "saturated": 2.4,
    "polyunsaturated": 0.5,
    "monounsaturated": 2.7,
    "trans": null,
    "cholesterol": 17,
    "sodium": 101,
    "carbs": 0.9,
    "fibre": 0,
    "sugar": 0.9,
    "protein": 4.2,
    "potassium": 60,
    "calcium": 13,
    "iron": 0.3,
    "phosphorus": 24,
    "info": null
  },
  {
    "id": 761,
    "disabled": null,
    "type": "meal",
    "name": "BBQ pork ribs with rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bbq pork ribs with rice",
    "weight-g": 320,
    "volume-ml": null,
    "calories": 626,
    "fat": 26.3,
    "saturated": 6.1,
    "polyunsaturated": 2.2,
    "monounsaturated": 6.8,
    "trans": null,
    "cholesterol": 36,
    "sodium": 446,
    "carbs": 67.7,
    "fibre": 2,
    "sugar": 2.4,
    "protein": 29.7,
    "potassium": 392,
    "calcium": 44,
    "iron": 6.4,
    "phosphorus": 263,
    "info": null
  },
  {
    "id": 1072,
    "disabled": null,
    "type": "snack",
    "name": "Bao with pork filling",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bao",
    "info-translation": "Bak Bao",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "big",
    "info-include-exclude": null,
    "search-text": "bao with pork filling bak",
    "weight-g": 124,
    "volume-ml": null,
    "calories": 358.9,
    "fat": 16.1,
    "saturated": 5.6,
    "polyunsaturated": 3,
    "monounsaturated": 6.7,
    "trans": null,
    "cholesterol": 40,
    "sodium": 645,
    "carbs": 42.2,
    "fibre": 5.8,
    "sugar": 2,
    "protein": 11.3,
    "potassium": null,
    "calcium": 11,
    "iron": 1,
    "phosphorus": null,
    "info": "Bak Bao (big)"
  },
  {
    "id": 1073,
    "disabled": null,
    "type": "snack",
    "name": "Bao with pork filling",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bao",
    "info-translation": "Bak Bao",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "small",
    "info-include-exclude": null,
    "search-text": "[bao|pau|pao] with pork filling [bak|bah|ba]",
    "weight-g": 70,
    "volume-ml": null,
    "calories": 202.7,
    "fat": 9.1,
    "saturated": 3.1,
    "polyunsaturated": 1.7,
    "monounsaturated": 3.8,
    "trans": null,
    "cholesterol": 22,
    "sodium": 364,
    "carbs": 23.8,
    "fibre": 3.3,
    "sugar": 1.1,
    "protein": 6.4,
    "potassium": null,
    "calcium": 6,
    "iron": 0.6,
    "phosphorus": null,
    "info": "Bak Bao (small)"
  },
  {
    "id": 415,
    "disabled": null,
    "type": "meal",
    "name": "Bean curd, fried / grilled / braised",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Tofu",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bean curd, fried / grilled braised tofu",
    "weight-g": 13,
    "volume-ml": null,
    "calories": 38,
    "fat": 2.6,
    "saturated": 0.4,
    "polyunsaturated": 1.5,
    "monounsaturated": 0.6,
    "trans": null,
    "cholesterol": 0,
    "sodium": 2.1,
    "carbs": 1.2,
    "fibre": 0.5,
    "sugar": 0.4,
    "protein": 2.5,
    "potassium": 19,
    "calcium": 48.4,
    "iron": 0.6,
    "phosphorus": 37.3,
    "info": "Tofu"
  },
  {
    "id": 646,
    "disabled": null,
    "type": "meal",
    "name": "Bean sprouts, boiled / steamed",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": "Taugeh",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bean sprouts, boiled / steamed taugeh",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 11,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 10,
    "carbs": 4.2,
    "fibre": 0.8,
    "sugar": 0,
    "protein": 2,
    "potassium": 101,
    "calcium": 12,
    "iron": 0.7,
    "phosphorus": 28,
    "info": "Taugeh"
  },
  {
    "id": 1093,
    "disabled": null,
    "type": "meal",
    "name": "Bean sprouts, stir fried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": "Taugeh",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with garlic",
    "search-text": "bean sprouts, stir fried taugeh",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 86,
    "fat": 5.2,
    "saturated": 2.2,
    "polyunsaturated": 0.5,
    "monounsaturated": 2,
    "trans": null,
    "cholesterol": null,
    "sodium": 597,
    "carbs": 7,
    "fibre": 1,
    "sugar": 5,
    "protein": 2.8,
    "potassium": 229,
    "calcium": 30,
    "iron": 0.8,
    "phosphorus": 52,
    "info": "Taugeh – with garlic"
  },
  {
    "id": 908,
    "disabled": null,
    "type": "meal",
    "name": "Beancurd skin roll, fried",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Fu Pei Guen",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "beancurd skin roll, fried fu [pei|pi] [guen|zhuan]",
    "weight-g": 33,
    "volume-ml": null,
    "calories": 66,
    "fat": 4.8,
    "saturated": 1.9,
    "polyunsaturated": 0.7,
    "monounsaturated": 2,
    "trans": null,
    "cholesterol": 5,
    "sodium": 181,
    "carbs": 1.1,
    "fibre": 0.2,
    "sugar": 0.7,
    "protein": 4.6,
    "potassium": 50,
    "calcium": 14,
    "iron": 0,
    "phosphorus": 36,
    "info": "Fu Pei Guen"
  },
  {
    "id": 411,
    "disabled": null,
    "type": "snack",
    "name": "Beans, boiled",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "chickpeas, kidney / black / pinto / fava beans / green",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "beans, boiled chickpeas, kidney / black pinto fava beans green",
    "weight-g": 50,
    "volume-ml": null,
    "calories": 73.1,
    "fat": 0.3,
    "saturated": 0.1,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0.5,
    "carbs": 13.1,
    "fibre": 4.5,
    "sugar": 0.2,
    "protein": 4.5,
    "potassium": 218,
    "calcium": 23,
    "iron": 1,
    "phosphorus": 73.5,
    "info": "e.g. chickpeas, kidney / black / pinto / fava beans / green"
  },
  {
    "id": 525,
    "disabled": null,
    "type": "meal",
    "name": "Beef boat noodles",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "beef boat noodles",
    "weight-g": 515,
    "volume-ml": null,
    "calories": 411,
    "fat": 4.7,
    "saturated": 2,
    "polyunsaturated": 0.3,
    "monounsaturated": 2,
    "trans": null,
    "cholesterol": 68,
    "sodium": 795,
    "carbs": 63.1,
    "fibre": 4,
    "sugar": 0,
    "protein": 29.1,
    "potassium": 483,
    "calcium": 16,
    "iron": 3.8,
    "phosphorus": 178,
    "info": null
  },
  {
    "id": 243,
    "disabled": null,
    "type": "meal",
    "name": "Beef hor fun, fried",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "beef hor fun, fried",
    "weight-g": 481,
    "volume-ml": null,
    "calories": 696.5,
    "fat": 26.5,
    "saturated": 11.5,
    "polyunsaturated": 2.9,
    "monounsaturated": 11.5,
    "trans": null,
    "cholesterol": 38,
    "sodium": 1717,
    "carbs": 94.8,
    "fibre": 6.3,
    "sugar": 0,
    "protein": 19.7,
    "potassium": 298,
    "calcium": 77,
    "iron": 1.9,
    "phosphorus": 183,
    "info": null
  },
  {
    "id": 93,
    "disabled": null,
    "type": "meal",
    "name": "Beef patty, grilled",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "patty",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "beef patty, grilled",
    "weight-g": 90,
    "volume-ml": null,
    "calories": 255.9,
    "fat": 16.3,
    "saturated": 7.3,
    "polyunsaturated": 0.3,
    "monounsaturated": 7,
    "trans": 0.6,
    "cholesterol": 83,
    "sodium": 54,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 27.3,
    "potassium": 323,
    "calcium": 5,
    "iron": 2.1,
    "phosphorus": 182,
    "info": null
  },
  {
    "id": 487,
    "disabled": null,
    "type": "meal",
    "name": "Beef rendang",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "beef rendang",
    "weight-g": 236,
    "volume-ml": null,
    "calories": 527.9,
    "fat": 30.4,
    "saturated": 14.6,
    "polyunsaturated": 2.8,
    "monounsaturated": 11.6,
    "trans": null,
    "cholesterol": 210,
    "sodium": 1182.4,
    "carbs": 3.3,
    "fibre": 6.6,
    "sugar": 1.9,
    "protein": 60.2,
    "potassium": 710.4,
    "calcium": 59,
    "iron": 7.3,
    "phosphorus": 380,
    "info": null
  },
  {
    "id": 94,
    "disabled": null,
    "type": "meal",
    "name": "Beef steak, fatty cut",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "T-bone, ribs, skirt, flank",
    "info-portion-aid": "7\" x 4\" x 1\"",
    "info-include-exclude": null,
    "search-text": "beef steak, fatty cut t-bone, ribs, skirt, flank",
    "weight-g": 200,
    "volume-ml": null,
    "calories": 496,
    "fat": 38.2,
    "saturated": 16,
    "polyunsaturated": 1.5,
    "monounsaturated": 17.9,
    "trans": 1.4,
    "cholesterol": 148,
    "sodium": 122,
    "carbs": 1.2,
    "fibre": 0,
    "sugar": 0,
    "protein": 37,
    "potassium": null,
    "calcium": 4,
    "iron": 2.4,
    "phosphorus": null,
    "info": "e.g. T-bone, ribs, skirt, flank (7\" x 4\" x 1\")"
  },
  {
    "id": 118,
    "disabled": null,
    "type": "meal",
    "name": "Beef steak, lean cut",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "sirloin, tenderloin, striploin",
    "info-portion-aid": "7\" x 4\" x 1\"",
    "info-include-exclude": null,
    "search-text": "beef steak, lean cut sirloin, tenderloin, striploin",
    "weight-g": 200,
    "volume-ml": null,
    "calories": 385.6,
    "fat": 17.6,
    "saturated": 9,
    "polyunsaturated": 0.8,
    "monounsaturated": 7.8,
    "trans": null,
    "cholesterol": 134,
    "sodium": 150,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 56.8,
    "potassium": 460,
    "calcium": 6,
    "iron": 6,
    "phosphorus": 460,
    "info": "e.g. sirloin, tenderloin, striploin (7\" x 4\" x 1\")"
  },
  {
    "id": 771,
    "disabled": null,
    "type": "meal",
    "name": "Beef, black pepper",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "beef, black pepper",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 191.3,
    "fat": 12.1,
    "saturated": 4,
    "polyunsaturated": 2.9,
    "monounsaturated": 4.7,
    "trans": null,
    "cholesterol": 55,
    "sodium": 594,
    "carbs": 2.5,
    "fibre": 1.5,
    "sugar": 1.5,
    "protein": 18.1,
    "potassium": 327,
    "calcium": 34,
    "iron": 6.3,
    "phosphorus": 175,
    "info": null
  },
  {
    "id": 241,
    "disabled": null,
    "type": "meal",
    "name": "Beef, roasted / boiled / grilled",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "beef, roasted / boiled grilled",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 150.5,
    "fat": 4.9,
    "saturated": 2.2,
    "polyunsaturated": 0.3,
    "monounsaturated": 2.3,
    "trans": null,
    "cholesterol": 66,
    "sodium": 57,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 26.6,
    "potassium": 210,
    "calcium": 3,
    "iron": 2.5,
    "phosphorus": 210,
    "info": null
  },
  {
    "id": 127,
    "disabled": null,
    "type": "meal",
    "name": "Economical fried beehoon",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Rice vermicelli",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[economical|economy] fried beehoon rice vermicelli",
    "weight-g": 300,
    "volume-ml": null,
    "calories": 247.4,
    "fat": 5,
    "saturated": 2.4,
    "polyunsaturated": 0.4,
    "monounsaturated": 2,
    "trans": null,
    "cholesterol": 6,
    "sodium": 820,
    "carbs": 45,
    "fibre": 3.6,
    "sugar": 0.8,
    "protein": 5.6,
    "potassium": 44,
    "calcium": 16,
    "iron": 1.8,
    "phosphorus": 46,
    "info": "Rice vermicelli"
  },
  {
    "id": 881,
    "disabled": null,
    "type": "drink",
    "name": "Beer, light",
    "rating": 1,
    "alcohol": 1,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "can",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "beer, light",
    "weight-g": null,
    "volume-ml": 360,
    "calories": 29,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 14.4,
    "carbs": 5.9,
    "fibre": 0,
    "sugar": 0.3,
    "protein": 0.9,
    "potassium": 75.6,
    "calcium": 14.4,
    "iron": 0.1,
    "phosphorus": 43.2,
    "info": null
  },
  {
    "id": 4,
    "disabled": null,
    "type": "drink",
    "name": "Beer, regular",
    "rating": 1,
    "alcohol": 1,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "can",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "beer, regular",
    "weight-g": null,
    "volume-ml": 360,
    "calories": 155,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 14.2,
    "carbs": 12.6,
    "fibre": 0,
    "sugar": 0,
    "protein": 1.6,
    "potassium": 96.1,
    "calcium": 14.2,
    "iron": 0.1,
    "phosphorus": 49.8,
    "info": null
  },
  {
    "id": 671,
    "disabled": null,
    "type": "meal",
    "name": "Beetroot, raw",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "medium",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "beetroot, raw",
    "weight-g": 135,
    "volume-ml": null,
    "calories": 45.7,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 58,
    "carbs": 9.1,
    "fibre": 3,
    "sugar": 0,
    "protein": 2.1,
    "potassium": 313,
    "calcium": 9,
    "iron": 1,
    "phosphorus": 55,
    "info": null
  },
  {
    "id": 632,
    "disabled": null,
    "type": "meal",
    "name": "Bell peppers (any color)",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": "Capsicum",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bell peppers (any color) capsicum",
    "weight-g": 160,
    "volume-ml": null,
    "calories": 39.1,
    "fat": 0.3,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 2,
    "carbs": 6.4,
    "fibre": 2.6,
    "sugar": 6.2,
    "protein": 2.7,
    "potassium": 288,
    "calcium": 3,
    "iron": 0.5,
    "phosphorus": 35,
    "info": "Capsicum"
  },
  {
    "id": 406,
    "disabled": null,
    "type": "snack",
    "name": "Mixed berries, frozen",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mixed berries, frozen",
    "weight-g": 98,
    "volume-ml": null,
    "calories": 50,
    "fat": 0.6,
    "saturated": 0,
    "polyunsaturated": 0.3,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1,
    "carbs": 11.9,
    "fibre": 2.7,
    "sugar": 8.3,
    "protein": 0.4,
    "potassium": 53,
    "calcium": 7.8,
    "iron": 0.2,
    "phosphorus": 11,
    "info": null
  },
  {
    "id": 1034,
    "disabled": null,
    "type": "snack",
    "name": "Berry tart",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "strawberry, blueberry, cherry, mixed",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "berry tart strawberry, blueberry, cherry, mixed",
    "weight-g": 160,
    "volume-ml": null,
    "calories": 357,
    "fat": 17.7,
    "saturated": 8.9,
    "polyunsaturated": 0.9,
    "monounsaturated": 6.9,
    "trans": null,
    "cholesterol": 73,
    "sodium": 366,
    "carbs": 41.6,
    "fibre": 1.8,
    "sugar": 15.4,
    "protein": 8.9,
    "potassium": 150,
    "calcium": 103,
    "iron": 0.8,
    "phosphorus": 137,
    "info": "e.g. strawberry, blueberry, cherry, mixed"
  },
  {
    "id": 244,
    "disabled": null,
    "type": "meal",
    "name": "Bibimbap",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Korean mixed rice with egg",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bibimbap korean mixed rice with egg",
    "weight-g": 350,
    "volume-ml": null,
    "calories": 499.1,
    "fat": 21.5,
    "saturated": 6,
    "polyunsaturated": 7.5,
    "monounsaturated": 8,
    "trans": null,
    "cholesterol": 200,
    "sodium": 376,
    "carbs": 41.8,
    "fibre": 2,
    "sugar": 2,
    "protein": 25.6,
    "potassium": 400,
    "calcium": 20,
    "iron": 1.4,
    "phosphorus": 150,
    "info": "Korean mixed rice with egg"
  },
  {
    "id": 491,
    "disabled": null,
    "type": "snack",
    "name": "Bingsu with ice cream",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[bingsu|bingsoo] with ice cream",
    "weight-g": 319,
    "volume-ml": null,
    "calories": 397,
    "fat": 14,
    "saturated": 8.3,
    "polyunsaturated": 0.7,
    "monounsaturated": 3.7,
    "trans": null,
    "cholesterol": 53,
    "sodium": 178,
    "carbs": 62,
    "fibre": 4.1,
    "sugar": 48,
    "protein": 7.8,
    "potassium": 534,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 997,
    "disabled": null,
    "type": "snack",
    "name": "Bird nest, reduced sugar",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bottle",
    "info-translation": "Yan Wo",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bird nest, reduced sugar yan wo",
    "weight-g": null,
    "volume-ml": 70,
    "calories": 19,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 3,
    "carbs": 4.2,
    "fibre": 0,
    "sugar": 3.7,
    "protein": 0.4,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "Yan Wo"
  },
  {
    "id": 998,
    "disabled": null,
    "type": "snack",
    "name": "Bird nest, with sugar",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bottle",
    "info-translation": "Yan Wo",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bird nest, with sugar yan wo",
    "weight-g": null,
    "volume-ml": 70,
    "calories": 26,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 6,
    "carbs": 6.4,
    "fibre": 0,
    "sugar": 6,
    "protein": 0.1,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "Yan Wo"
  },
  {
    "id": 222,
    "disabled": null,
    "type": "snack",
    "name": "Biscotti",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "piece",
    "info-translation": "Cantucci",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "biscotti cantucci",
    "weight-g": 15,
    "volume-ml": null,
    "calories": 71,
    "fat": 3.4,
    "saturated": 0.5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 41,
    "carbs": 8,
    "fibre": 0.8,
    "sugar": 3.1,
    "protein": 2.3,
    "potassium": 70,
    "calcium": 10,
    "iron": 0.5,
    "phosphorus": null,
    "info": "Cantucci"
  },
  {
    "id": 1074,
    "disabled": null,
    "type": "snack",
    "name": "Biscuit with flavoured filling",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 5,
    "unit": "piece",
    "info-translation": null,
    "info-brands": "Hello Panda",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "biscuit with flavoured filling hello panda",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 162,
    "fat": 9,
    "saturated": 4.9,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0.1,
    "cholesterol": null,
    "sodium": 87,
    "carbs": 18.3,
    "fibre": 0.3,
    "sugar": 8.8,
    "protein": 1.8,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Hello Panda"
  },
  {
    "id": 599,
    "disabled": null,
    "type": "snack",
    "name": "Biscuit stick with cream / frosting",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "packet",
    "info-translation": null,
    "info-brands": "Glico Pocky, Yan Yan",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "biscuit stick with cream / frosting glico pocky, yan",
    "weight-g": 50,
    "volume-ml": null,
    "calories": 239,
    "fat": 13,
    "saturated": 5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0.2,
    "cholesterol": null,
    "sodium": 158,
    "carbs": 32,
    "fibre": 2,
    "sugar": 12,
    "protein": 4,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Glico Pocky, Yan Yan"
  },
  {
    "id": 22,
    "disabled": null,
    "type": "snack",
    "name": "Biscuit with cream filling",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "piece",
    "info-translation": null,
    "info-brands": "Julie's",
    "info-examples": "peanut butter, lemon",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "biscuit with cream filling julie's peanut butter, lemon",
    "weight-g": 24,
    "volume-ml": null,
    "calories": 109.8,
    "fat": 4.2,
    "saturated": 2.4,
    "polyunsaturated": 0.2,
    "monounsaturated": 1.2,
    "trans": null,
    "cholesterol": 6,
    "sodium": 34,
    "carbs": 17,
    "fibre": 0.2,
    "sugar": 7.4,
    "protein": 1,
    "potassium": 12,
    "calcium": 4,
    "iron": 0,
    "phosphorus": 16,
    "info": "e.g. Julie's, peanut butter, lemon"
  },
  {
    "id": 549,
    "disabled": null,
    "type": "snack",
    "name": "Biscuit, plain",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 5,
    "unit": "pieces",
    "info-translation": null,
    "info-brands": "Meiji, Jacob's, Khong Guan, Marie",
    "info-examples": "soda crackers",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "biscuit, plain meiji, jacob's, khong guan, marie soda crackers",
    "weight-g": 28,
    "volume-ml": null,
    "calories": 113.7,
    "fat": 3,
    "saturated": 1.5,
    "polyunsaturated": 0.5,
    "monounsaturated": 0.8,
    "trans": null,
    "cholesterol": 0,
    "sodium": 104,
    "carbs": 19.8,
    "fibre": 1,
    "sugar": 5.9,
    "protein": 2,
    "potassium": 86,
    "calcium": 0,
    "iron": 0,
    "phosphorus": 54,
    "info": "e.g. Meiji, Jacob's, Khong Guan, Marie, soda crackers"
  },
  {
    "id": 1011,
    "disabled": null,
    "type": "snack",
    "name": "Black forest cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": "Gateau",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "black forest cake gateau",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 321.7,
    "fat": 15.3,
    "saturated": 9.2,
    "polyunsaturated": 0.9,
    "monounsaturated": 4.5,
    "trans": null,
    "cholesterol": 45,
    "sodium": 220,
    "carbs": 43,
    "fibre": 2.5,
    "sugar": 35,
    "protein": 3,
    "potassium": 129,
    "calcium": 45,
    "iron": 2,
    "phosphorus": 119,
    "info": "Gateau"
  },
  {
    "id": 215,
    "disabled": null,
    "type": "snack",
    "name": "Black glutinous rice paste",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Pulut Hitam",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with coconut milk",
    "search-text": "black glutinous rice paste pulut hitam",
    "weight-g": 250,
    "volume-ml": null,
    "calories": 220.2,
    "fat": 3.8,
    "saturated": 3.4,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 44.5,
    "fibre": 7,
    "sugar": 25,
    "protein": 2,
    "potassium": null,
    "calcium": 10,
    "iron": 0.5,
    "phosphorus": null,
    "info": "Pulut Hitam – with coconut milk"
  },
  {
    "id": 187,
    "disabled": null,
    "type": "snack",
    "name": "Black sesame paste",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Zhi Ma Hu",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "black sesame paste [zhi|ji] ma [hu|wu|woo]",
    "weight-g": null,
    "volume-ml": 306,
    "calories": 286.7,
    "fat": 9.5,
    "saturated": 1.6,
    "polyunsaturated": 4,
    "monounsaturated": 3.6,
    "trans": null,
    "cholesterol": 0,
    "sodium": 13,
    "carbs": 47.5,
    "fibre": 2.3,
    "sugar": 41.8,
    "protein": 2.8,
    "potassium": 50,
    "calcium": 133,
    "iron": 0,
    "phosphorus": 70,
    "info": "Zhi Ma Hu"
  },
  {
    "id": 709,
    "disabled": null,
    "type": "snack",
    "name": "Blueberries",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "blueberries",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 16,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 3.5,
    "fibre": 0.7,
    "sugar": 3,
    "protein": 0.2,
    "potassium": 23,
    "calcium": 2,
    "iron": 0.1,
    "phosphorus": 4,
    "info": null
  },
  {
    "id": 1017,
    "disabled": null,
    "type": "snack",
    "name": "Blueberry cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "blueberry cake",
    "weight-g": 50,
    "volume-ml": null,
    "calories": 145,
    "fat": 9,
    "saturated": 5,
    "polyunsaturated": 0.4,
    "monounsaturated": 3.6,
    "trans": null,
    "cholesterol": null,
    "sodium": 69,
    "carbs": 14.5,
    "fibre": null,
    "sugar": 11,
    "protein": 6.1,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 627,
    "disabled": null,
    "type": "meal",
    "name": "Spinach, boiled",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": "Bo Cai, Phuay Leng",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "spinach, boiled [bo|po|poh] [cai|chye|choy], [phuay|puay] leng",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 29.9,
    "fat": 0.3,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 70,
    "carbs": 3.8,
    "fibre": 2.4,
    "sugar": 0,
    "protein": 3,
    "potassium": 466,
    "calcium": 136,
    "iron": 3.6,
    "phosphorus": 56,
    "info": "Bo Cai, Phuay Leng"
  },
  {
    "id": 1075,
    "disabled": null,
    "type": "meal",
    "name": "Shanghai green, stir-fried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": "Xiao Bai Cai, Chinese cabbage",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "shanghai green, stir-fried xiao [bai|bok|pak|pok] [cai|choy|chye], chinese cabbage",
    "weight-g": 115,
    "volume-ml": null,
    "calories": 69,
    "fat": 4.5,
    "saturated": 0.7,
    "polyunsaturated": 2.8,
    "monounsaturated": 0.8,
    "trans": null,
    "cholesterol": null,
    "sodium": 350,
    "carbs": 1.7,
    "fibre": 1.9,
    "sugar": 3.6,
    "protein": 2.5,
    "potassium": 300,
    "calcium": 110,
    "iron": 0.4,
    "phosphorus": 7,
    "info": "Xiao Bai Cai, Chinese cabbage"
  },
  {
    "id": 625,
    "disabled": null,
    "type": "meal",
    "name": "Shanghai green, boiled",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": "Xiao Bai Cai, Chinese cabbage",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "shanghai green, boiled xiao [bai|bok|pak|pok] [cai|choy|chye], chinese cabbage",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 13.8,
    "fat": 0.2,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 45,
    "carbs": 2,
    "fibre": 1,
    "sugar": 0,
    "protein": 1,
    "potassium": 300,
    "calcium": 60,
    "iron": 0.2,
    "phosphorus": 15,
    "info": "Xiao Bai Cai, Chinese cabbage"
  },
  {
    "id": 493,
    "disabled": null,
    "type": "meal",
    "name": "Borscht soup",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "beetroot",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[borscht|borsch] soup beetroot",
    "weight-g": 245,
    "volume-ml": null,
    "calories": 105.9,
    "fat": 4.3,
    "saturated": 2.2,
    "polyunsaturated": 0.3,
    "monounsaturated": 1.1,
    "trans": null,
    "cholesterol": 12.2,
    "sodium": 380,
    "carbs": 13.3,
    "fibre": 2.7,
    "sugar": 8.5,
    "protein": 3.4,
    "potassium": 402,
    "calcium": 61.2,
    "iron": 0.8,
    "phosphorus": 76,
    "info": "e.g. beetroot"
  },
  {
    "id": 530,
    "disabled": null,
    "type": "meal",
    "name": "Braised bee hoon with crabmeat",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Rice vermicelli",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "braised bee hoon with crabmeat rice vermicelli",
    "weight-g": 224,
    "volume-ml": null,
    "calories": 383.8,
    "fat": 5.4,
    "saturated": 1,
    "polyunsaturated": 1.5,
    "monounsaturated": 2.4,
    "trans": null,
    "cholesterol": 41,
    "sodium": 544,
    "carbs": 58,
    "fibre": 8.3,
    "sugar": 4.1,
    "protein": 25.8,
    "potassium": 625,
    "calcium": 175,
    "iron": 1.8,
    "phosphorus": 238,
    "info": "Rice vermicelli"
  },
  {
    "id": 895,
    "disabled": null,
    "type": "meal",
    "name": "Braised beef brisket noodles, dry",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "braised beef brisket noodles, dry",
    "weight-g": 111,
    "volume-ml": null,
    "calories": 416,
    "fat": 19.2,
    "saturated": 9.2,
    "polyunsaturated": 0.3,
    "monounsaturated": 4.1,
    "trans": null,
    "cholesterol": 28,
    "sodium": 407,
    "carbs": 43.7,
    "fibre": 0.5,
    "sugar": 0,
    "protein": 17.1,
    "potassium": 63,
    "calcium": 3,
    "iron": 0.9,
    "phosphorus": 57,
    "info": null
  },
  {
    "id": 245,
    "disabled": null,
    "type": "meal",
    "name": "Braised duck rice (white rice)",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "braised duck rice (white rice)",
    "weight-g": 300,
    "volume-ml": null,
    "calories": 451,
    "fat": 11,
    "saturated": 8.6,
    "polyunsaturated": 2.7,
    "monounsaturated": 7.3,
    "trans": null,
    "cholesterol": 109,
    "sodium": 352,
    "carbs": 62.8,
    "fibre": 2,
    "sugar": 0.2,
    "protein": 25.2,
    "potassium": 20,
    "calcium": 20,
    "iron": 7.6,
    "phosphorus": 22,
    "info": null
  },
  {
    "id": 1076,
    "disabled": null,
    "type": "meal",
    "name": "Braised duck rice (yam rice)",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "braised duck rice (yam rice)",
    "weight-g": 410,
    "volume-ml": null,
    "calories": 693,
    "fat": 20.5,
    "saturated": 6.1,
    "polyunsaturated": 4.5,
    "monounsaturated": 8.5,
    "trans": null,
    "cholesterol": 94,
    "sodium": 562,
    "carbs": 102.1,
    "fibre": 2,
    "sugar": 29.1,
    "protein": 21,
    "potassium": 201,
    "calcium": 25,
    "iron": 2.9,
    "phosphorus": 324,
    "info": null
  },
  {
    "id": 431,
    "disabled": null,
    "type": "meal",
    "name": "Braised mixed vegetables",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": "Buddha's Delight, Loh Hon Chai",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "braised mixed vegetables buddha's delight, [loh|lo] [hon|han] [chai|cai|jai|zai]",
    "weight-g": 200,
    "volume-ml": null,
    "calories": 142,
    "fat": 5.6,
    "saturated": 1.8,
    "polyunsaturated": 1.4,
    "monounsaturated": 2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 298,
    "carbs": 5.2,
    "fibre": 5.4,
    "sugar": 4,
    "protein": 1.6,
    "potassium": 288,
    "calcium": 38,
    "iron": 1.6,
    "phosphorus": 66,
    "info": "Buddha's Delight, Loh Hon Chai"
  },
  {
    "id": 723,
    "disabled": null,
    "type": "meal",
    "name": "Bread, ezekiel / sprouted",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bread, ezekiel / sprouted",
    "weight-g": 34,
    "volume-ml": null,
    "calories": 76,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 75,
    "carbs": 15,
    "fibre": 3,
    "sugar": 0,
    "protein": 4,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 34,
    "disabled": null,
    "type": "snack",
    "name": "Bread pudding",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "square",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "10cm square",
    "info-include-exclude": null,
    "search-text": "bread pudding",
    "weight-g": 56,
    "volume-ml": null,
    "calories": 86.8,
    "fat": 2.7,
    "saturated": 0.9,
    "polyunsaturated": 0.6,
    "monounsaturated": 0.9,
    "trans": null,
    "cholesterol": 31,
    "sodium": 121,
    "carbs": 13.2,
    "fibre": 0.6,
    "sugar": 8.2,
    "protein": 3.1,
    "potassium": 114,
    "calcium": 66.8,
    "iron": 0.6,
    "phosphorus": 62,
    "info": "(10cm square)"
  },
  {
    "id": 582,
    "disabled": null,
    "type": "meal",
    "name": "Bread with filling",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Bun",
    "info-brands": null,
    "info-examples": "tuna, ham, cheese, sardine",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bread with filling bun tuna, ham, cheese, sardine",
    "weight-g": 85,
    "volume-ml": null,
    "calories": 251.1,
    "fat": 9.9,
    "saturated": 5.1,
    "polyunsaturated": 1.7,
    "monounsaturated": 3.1,
    "trans": null,
    "cholesterol": 21,
    "sodium": 587,
    "carbs": 30.6,
    "fibre": 3.4,
    "sugar": 7.1,
    "protein": 9.9,
    "potassium": 123,
    "calcium": 76,
    "iron": 0.9,
    "phosphorus": 132,
    "info": "Bun, e.g. tuna, ham, cheese, sardine"
  },
  {
    "id": 898,
    "disabled": null,
    "type": "meal",
    "name": "Bread, paleo",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bread, paleo",
    "weight-g": 43,
    "volume-ml": null,
    "calories": 60.2,
    "fat": 3,
    "saturated": 1,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 115,
    "carbs": 6,
    "fibre": 5,
    "sugar": 1,
    "protein": 7,
    "potassium": 200,
    "calcium": 40,
    "iron": 0.4,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 95,
    "disabled": null,
    "type": "meal",
    "name": "Bread, white",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": "Gardenia, Hokkaido, Sunshine",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bread, white gardenia, hokkaido, sunshine",
    "weight-g": 32,
    "volume-ml": null,
    "calories": 88.3,
    "fat": 1.5,
    "saturated": 0.9,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 48.5,
    "carbs": 15.4,
    "fibre": 0.9,
    "sugar": 0.3,
    "protein": 3.3,
    "potassium": null,
    "calcium": 262,
    "iron": 1.8,
    "phosphorus": null,
    "info": "e.g. Gardenia, Hokkaido, Sunshine"
  },
  {
    "id": 246,
    "disabled": null,
    "type": "meal",
    "name": "Bread, wholemeal",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": "Gardenia, Sunshine",
    "info-examples": "whole grain, softmeal",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bread, wholemeal gardenia, sunshine whole grain, softmeal",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 66.5,
    "fat": 0.9,
    "saturated": 0.3,
    "polyunsaturated": 0.3,
    "monounsaturated": 0.2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 141,
    "carbs": 11.6,
    "fibre": 2,
    "sugar": 0.4,
    "protein": 3,
    "potassium": 81,
    "calcium": 16,
    "iron": 0.7,
    "phosphorus": 45,
    "info": "e.g. Gardenia, Sunshine, whole grain, softmeal"
  },
  {
    "id": 686,
    "disabled": null,
    "type": "meal",
    "name": "Breadfruit",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "square",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "5cm sqaure",
    "info-include-exclude": "cooked",
    "search-text": "breadfruit",
    "weight-g": 72,
    "volume-ml": null,
    "calories": 94.8,
    "fat": 2,
    "saturated": 0.9,
    "polyunsaturated": 0.4,
    "monounsaturated": 0.5,
    "trans": null,
    "cholesterol": 3,
    "sodium": 196,
    "carbs": 20.8,
    "fibre": 3.8,
    "sugar": 8.4,
    "protein": 0.8,
    "potassium": 339,
    "calcium": 13,
    "iron": 0.4,
    "phosphorus": 21,
    "info": "cooked (5cm sqaure)"
  },
  {
    "id": 655,
    "disabled": null,
    "type": "meal",
    "name": "Brinjal, stir fried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": "Aubergine, Eggplant",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "brinjal, stir fried aubergine, eggplant",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 80.7,
    "fat": 5.1,
    "saturated": 0.8,
    "polyunsaturated": 1.6,
    "monounsaturated": 2.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 199,
    "carbs": 6.8,
    "fibre": 1.2,
    "sugar": 4.9,
    "protein": 1.9,
    "potassium": 71,
    "calcium": 24,
    "iron": 0.6,
    "phosphorus": 26,
    "info": "Aubergine, Eggplant"
  },
  {
    "id": 96,
    "disabled": null,
    "type": "meal",
    "name": "Briyani with meat",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with chicken / mutton",
    "search-text": "briyani with meat",
    "weight-g": 488,
    "volume-ml": null,
    "calories": 876.8,
    "fat": 34.8,
    "saturated": 14.8,
    "polyunsaturated": 5.1,
    "monounsaturated": 13,
    "trans": null,
    "cholesterol": 158,
    "sodium": 1655,
    "carbs": 102,
    "fibre": 8.3,
    "sugar": 0,
    "protein": 38.9,
    "potassium": 441,
    "calcium": 93,
    "iron": 4.6,
    "phosphorus": 293,
    "info": "with chicken / mutton"
  },
  {
    "id": 1079,
    "disabled": null,
    "type": "meal",
    "name": "Broccoli, stir fried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "broccoli, stir fried",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 57,
    "fat": 3.9,
    "saturated": 1.2,
    "polyunsaturated": 1.2,
    "monounsaturated": 1.4,
    "trans": null,
    "cholesterol": null,
    "sodium": 184,
    "carbs": 2.5,
    "fibre": 2,
    "sugar": 2,
    "protein": 2.9,
    "potassium": 211,
    "calcium": 37,
    "iron": 0.5,
    "phosphorus": 53,
    "info": null
  },
  {
    "id": 641,
    "disabled": null,
    "type": "meal",
    "name": "Broccoli, boiled / steamed",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "broccoli, boiled / steamed",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 35.1,
    "fat": 0.3,
    "saturated": 0.1,
    "polyunsaturated": 0.2,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 26,
    "carbs": 5.1,
    "fibre": 2.9,
    "sugar": 0,
    "protein": 3,
    "potassium": 292,
    "calcium": 46,
    "iron": 0.8,
    "phosphorus": 59,
    "info": null
  },
  {
    "id": 9,
    "disabled": null,
    "type": "drink",
    "name": "Bubble tea, brown sugar, fresh milk, with pearls",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "75%-100% sweetness",
    "search-text": "bubble tea, brown sugar, fresh milk, with pearls",
    "weight-g": null,
    "volume-ml": 420,
    "calories": 418,
    "fat": 12.8,
    "saturated": 8.5,
    "polyunsaturated": 0.6,
    "monounsaturated": 3.7,
    "trans": 0.4,
    "cholesterol": 49,
    "sodium": 144,
    "carbs": 65,
    "fibre": 2.6,
    "sugar": 36.9,
    "protein": 10.7,
    "potassium": 536,
    "calcium": 428,
    "iron": 0.6,
    "phosphorus": 285,
    "info": "75%-100% sweetness"
  },
  {
    "id": 494,
    "disabled": null,
    "type": "snack",
    "name": "Bruschetta",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with tomato and basil",
    "search-text": "bruschetta",
    "weight-g": 43,
    "volume-ml": null,
    "calories": 74.9,
    "fat": 3.3,
    "saturated": 0.4,
    "polyunsaturated": 0.5,
    "monounsaturated": 2.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 102,
    "carbs": 9.5,
    "fibre": 0.9,
    "sugar": 1.2,
    "protein": 1.7,
    "potassium": 78.3,
    "calcium": 16.8,
    "iron": 0.6,
    "phosphorus": 24.1,
    "info": "with tomato and basil"
  },
  {
    "id": 681,
    "disabled": null,
    "type": "meal",
    "name": "Brussell sprouts, stir-fried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 5,
    "unit": "sprout",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "brussell sprouts, stir-fried",
    "weight-g": 110,
    "volume-ml": null,
    "calories": 36,
    "fat": 0.5,
    "saturated": 1.6,
    "polyunsaturated": 0.3,
    "monounsaturated": 0.6,
    "trans": null,
    "cholesterol": 0,
    "sodium": 275,
    "carbs": 8,
    "fibre": 4,
    "sugar": 2,
    "protein": 3.5,
    "potassium": 280,
    "calcium": 26,
    "iron": 0.5,
    "phosphorus": 55,
    "info": null
  },
  {
    "id": 798,
    "disabled": null,
    "type": "drink",
    "name": "Bubble tea, reduced sugar, without pearls",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "25%-50% sweetness",
    "search-text": "bubble tea, reduced sugar, without pearls",
    "weight-g": null,
    "volume-ml": 440,
    "calories": 252.9,
    "fat": 13.3,
    "saturated": 12.4,
    "polyunsaturated": 0,
    "monounsaturated": 0.4,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 32.6,
    "fibre": 0,
    "sugar": 17.6,
    "protein": 0.7,
    "potassium": 207,
    "calcium": 189,
    "iron": 1.2,
    "phosphorus": 78,
    "info": "25%-50% sweetness"
  },
  {
    "id": 797,
    "disabled": null,
    "type": "drink",
    "name": "Bubble tea, sweetened, without pearls",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "75%-100% sweetness",
    "search-text": "bubble tea, sweetened, without pearls",
    "weight-g": null,
    "volume-ml": 440,
    "calories": 281.8,
    "fat": 12.6,
    "saturated": 11.7,
    "polyunsaturated": 0,
    "monounsaturated": 0.4,
    "trans": null,
    "cholesterol": 0,
    "sodium": 28,
    "carbs": 41.4,
    "fibre": 0,
    "sugar": 28,
    "protein": 0.7,
    "potassium": 196,
    "calcium": 179,
    "iron": 1.1,
    "phosphorus": 74,
    "info": "75%-100% sweetness"
  },
  {
    "id": 799,
    "disabled": null,
    "type": "drink",
    "name": "Bubble tea, unsweetened, without pearls",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "0% sweetness",
    "search-text": "bubble tea, unsweetened, without pearls",
    "weight-g": null,
    "volume-ml": 440,
    "calories": 224.4,
    "fat": 14,
    "saturated": 13,
    "polyunsaturated": 0,
    "monounsaturated": 0.4,
    "trans": null,
    "cholesterol": 0,
    "sodium": 31,
    "carbs": 23.8,
    "fibre": 0,
    "sugar": 0,
    "protein": 0.8,
    "potassium": 218,
    "calcium": 199,
    "iron": 1.2,
    "phosphorus": 82,
    "info": "0% sweetness"
  },
  {
    "id": 164,
    "disabled": null,
    "type": "drink",
    "name": "Bubble tea, no sugar, with pearls",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "0% sweetness",
    "search-text": "bubble tea, no sugar, with pearls",
    "weight-g": null,
    "volume-ml": 450,
    "calories": 318.3,
    "fat": 11.1,
    "saturated": 10.3,
    "polyunsaturated": 0,
    "monounsaturated": 0.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 40,
    "carbs": 54,
    "fibre": 1,
    "sugar": 26,
    "protein": 0.6,
    "potassium": 180,
    "calcium": 166,
    "iron": 1.1,
    "phosphorus": 67,
    "info": "0% sweetness"
  },
  {
    "id": 11,
    "disabled": null,
    "type": "drink",
    "name": "Bubble tea, reduced sugar, with pearls",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "25%-50% sweetness",
    "search-text": "bubble tea, reduced sugar, with pearls",
    "weight-g": null,
    "volume-ml": 450,
    "calories": 341.8,
    "fat": 10.6,
    "saturated": 9.7,
    "polyunsaturated": 0,
    "monounsaturated": 0.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 15,
    "carbs": 61,
    "fibre": 1,
    "sugar": 33,
    "protein": 0.6,
    "potassium": 172,
    "calcium": 158,
    "iron": 1.1,
    "phosphorus": 64,
    "info": "25%-50% sweetness"
  },
  {
    "id": 10,
    "disabled": null,
    "type": "drink",
    "name": "Bubble tea, sweetened, with pearls",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "75%-100% sweetness",
    "search-text": "bubble tea, sweetened, with pearls",
    "weight-g": null,
    "volume-ml": 450,
    "calories": 364.4,
    "fat": 10,
    "saturated": 9.3,
    "polyunsaturated": 0,
    "monounsaturated": 0.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 37,
    "carbs": 68,
    "fibre": 1,
    "sugar": 40,
    "protein": 0.6,
    "potassium": 163,
    "calcium": 150,
    "iron": 1,
    "phosphorus": 61,
    "info": "75%-100% sweetness"
  },
  {
    "id": 199,
    "disabled": null,
    "type": "snack",
    "name": "Bubur cha cha",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[bubur|bubor|bobo] cha",
    "weight-g": 250,
    "volume-ml": null,
    "calories": 380.6,
    "fat": 23.8,
    "saturated": 21.2,
    "polyunsaturated": 0.5,
    "monounsaturated": 7.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 90,
    "carbs": 36.8,
    "fibre": 5.8,
    "sugar": 26.8,
    "protein": 4.8,
    "potassium": 418,
    "calcium": 23,
    "iron": 1.3,
    "phosphorus": 113,
    "info": null
  },
  {
    "id": 97,
    "disabled": null,
    "type": "meal",
    "name": "Bulgogi",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": "Korean barbecue beef",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bulgogi korean barbecue beef",
    "weight-g": 142,
    "volume-ml": null,
    "calories": 257.9,
    "fat": 12.7,
    "saturated": 4.9,
    "polyunsaturated": 1.6,
    "monounsaturated": 5.3,
    "trans": null,
    "cholesterol": 70,
    "sodium": 1290,
    "carbs": 12.9,
    "fibre": 0.8,
    "sugar": 11.7,
    "protein": 23,
    "potassium": 392,
    "calcium": 19,
    "iron": 3.5,
    "phosphorus": 188,
    "info": "Korean barbecue beef"
  },
  {
    "id": 1077,
    "disabled": null,
    "type": "snack",
    "name": "Bun with filling",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bun",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "chicken / pork floss, olive",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bun with filling chicken / pork floss, olive",
    "weight-g": 90,
    "volume-ml": null,
    "calories": 388,
    "fat": 22.1,
    "saturated": 4.2,
    "polyunsaturated": 9.8,
    "monounsaturated": 6.5,
    "trans": null,
    "cholesterol": 43,
    "sodium": 1328,
    "carbs": 36.2,
    "fibre": 1.5,
    "sugar": 7.6,
    "protein": 11.5,
    "potassium": 135,
    "calcium": 41,
    "iron": 0.5,
    "phosphorus": 112,
    "info": "e.g. chicken / pork floss, olive"
  },
  {
    "id": 812,
    "disabled": null,
    "type": "meal",
    "name": "Burger with double patty",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "burger",
    "info-translation": null,
    "info-brands": "Big Mac, Double Cheeseburger / Whopper",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "burger with double patty big mac, double cheeseburger / whopper",
    "weight-g": 155,
    "volume-ml": null,
    "calories": 437,
    "fat": 25.1,
    "saturated": 10.8,
    "polyunsaturated": 2,
    "monounsaturated": 9.3,
    "trans": null,
    "cholesterol": 77.5,
    "sodium": 956,
    "carbs": 27.9,
    "fibre": 1.6,
    "sugar": 6.5,
    "protein": 25.2,
    "potassium": 350,
    "calcium": 232,
    "iron": 3.6,
    "phosphorus": 256,
    "info": "e.g. Big Mac, Double Cheeseburger / Whopper"
  },
  {
    "id": 813,
    "disabled": null,
    "type": "meal",
    "name": "Burger with plant-based protein",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "burger",
    "info-translation": null,
    "info-brands": "Impossible / Beyond Meat",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "burger with plant-based protein impossible / beyond meat",
    "weight-g": 242,
    "volume-ml": null,
    "calories": 566,
    "fat": 28.5,
    "saturated": 15.6,
    "polyunsaturated": 2.9,
    "monounsaturated": 9.3,
    "trans": null,
    "cholesterol": 16,
    "sodium": 820,
    "carbs": 45.2,
    "fibre": 5.4,
    "sugar": 6.2,
    "protein": 31.6,
    "potassium": 801,
    "calcium": 302,
    "iron": 6.6,
    "phosphorus": 274,
    "info": "e.g. Impossible / Beyond Meat"
  },
  {
    "id": 811,
    "disabled": null,
    "type": "meal",
    "name": "Burger with single patty",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "burger",
    "info-translation": null,
    "info-brands": "Cheeseburger, Angus, Whopper",
    "info-examples": "hamburger",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "burger with single patty cheeseburger, angus, whopper hamburger",
    "weight-g": 127,
    "volume-ml": null,
    "calories": 343,
    "fat": 16.4,
    "saturated": 7.4,
    "polyunsaturated": 1.7,
    "monounsaturated": 6.4,
    "trans": null,
    "cholesterol": 49.5,
    "sodium": 798,
    "carbs": 32.3,
    "fibre": 2.4,
    "sugar": 6.7,
    "protein": 17.1,
    "potassium": 234,
    "calcium": 156,
    "iron": 2.9,
    "phosphorus": 180,
    "info": "e.g. Cheeseburger, Angus, Whopper, hamburger"
  },
  {
    "id": 111,
    "disabled": 1,
    "type": "meal",
    "name": "Burger, fried chicken",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "burger",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "burger, fried chicken",
    "weight-g": 205,
    "volume-ml": null,
    "calories": 522,
    "fat": 25,
    "saturated": 5.1,
    "polyunsaturated": 7.1,
    "monounsaturated": 9.9,
    "trans": null,
    "cholesterol": 75,
    "sodium": 1280,
    "carbs": 50,
    "fibre": 3,
    "sugar": 0.5,
    "protein": 24,
    "potassium": 330,
    "calcium": 160,
    "iron": 2.5,
    "phosphorus": 330,
    "info": null
  },
  {
    "id": 272,
    "disabled": null,
    "type": "meal",
    "name": "Burger, fried fish",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "burger",
    "info-translation": null,
    "info-brands": "Filet-O-Fish",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "burger, fried fish filet-o-fish",
    "weight-g": 136,
    "volume-ml": null,
    "calories": 391,
    "fat": 21.3,
    "saturated": 8.6,
    "polyunsaturated": 4.8,
    "monounsaturated": 5.4,
    "trans": null,
    "cholesterol": 31,
    "sodium": 646,
    "carbs": 34.6,
    "fibre": 0.8,
    "sugar": 4.3,
    "protein": 16.2,
    "potassium": 74,
    "calcium": 84,
    "iron": 2.3,
    "phosphorus": 182,
    "info": "e.g. Filet-O-Fish"
  },
  {
    "id": 242,
    "disabled": null,
    "type": "meal",
    "name": "Burger, grilled beef",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "burger",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "burger, grilled beef",
    "weight-g": 305,
    "volume-ml": null,
    "calories": 300,
    "fat": 32.4,
    "saturated": 10.6,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 1540,
    "carbs": 59.3,
    "fibre": null,
    "sugar": 11.9,
    "protein": 25.9,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 277,
    "disabled": null,
    "type": "meal",
    "name": "Burger, grilled chicken",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "burger",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "burger, grilled chicken",
    "weight-g": 225,
    "volume-ml": null,
    "calories": 446,
    "fat": 11.2,
    "saturated": 2.3,
    "polyunsaturated": 4.1,
    "monounsaturated": 3.3,
    "trans": null,
    "cholesterol": 90,
    "sodium": 961,
    "carbs": 53.1,
    "fibre": 2.5,
    "sugar": null,
    "protein": 33.1,
    "potassium": 497,
    "calcium": 56.2,
    "iron": 3.5,
    "phosphorus": 378,
    "info": null
  },
  {
    "id": 899,
    "disabled": null,
    "type": "meal",
    "name": "Burger, single patty, fried",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "burger",
    "info-translation": null,
    "info-brands": "McSpicy, Zinger",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "burger, single patty, fried mcspicy, zinger",
    "weight-g": 264,
    "volume-ml": null,
    "calories": 633,
    "fat": 35.9,
    "saturated": 6.5,
    "polyunsaturated": 14.9,
    "monounsaturated": 10.8,
    "trans": null,
    "cholesterol": 76.6,
    "sodium": 1630,
    "carbs": 72.3,
    "fibre": 3.7,
    "sugar": 9,
    "protein": 28.9,
    "potassium": 473,
    "calcium": 190,
    "iron": 4.5,
    "phosphorus": 380,
    "info": "e.g. McSpicy, Zinger"
  },
  {
    "id": 495,
    "disabled": null,
    "type": "snack",
    "name": "Burrata",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Italian mozeralla cheese and cream",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "burrata italian mozeralla cheese and cream",
    "weight-g": 28,
    "volume-ml": null,
    "calories": 70,
    "fat": 6,
    "saturated": 4,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 19.9,
    "sodium": 59.9,
    "carbs": 1,
    "fibre": 0,
    "sugar": 0,
    "protein": 3,
    "potassium": 0,
    "calcium": 59.9,
    "iron": 0,
    "phosphorus": null,
    "info": "Italian mozeralla cheese and cream"
  },
  {
    "id": 247,
    "disabled": null,
    "type": "meal",
    "name": "Burrito",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": "Tortillas",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with meat and beans",
    "search-text": "burrito tortillas",
    "weight-g": 241,
    "volume-ml": null,
    "calories": 444,
    "fat": 18,
    "saturated": 7,
    "polyunsaturated": 1.4,
    "monounsaturated": 7.8,
    "trans": null,
    "cholesterol": 63,
    "sodium": 1374,
    "carbs": 47,
    "fibre": 7.2,
    "sugar": 5.3,
    "protein": 27.8,
    "potassium": 648,
    "calcium": 217,
    "iron": 4.6,
    "phosphorus": 393,
    "info": "Tortillas – with meat and beans"
  },
  {
    "id": 1042,
    "disabled": null,
    "type": "snack",
    "name": "Butter cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "butter cake",
    "weight-g": 36,
    "volume-ml": null,
    "calories": 180,
    "fat": 10.6,
    "saturated": 6.9,
    "polyunsaturated": 0.1,
    "monounsaturated": 3.1,
    "trans": null,
    "cholesterol": 48,
    "sodium": 90,
    "carbs": 19.1,
    "fibre": 0,
    "sugar": 7.9,
    "protein": 2.2,
    "potassium": 27,
    "calcium": 29,
    "iron": 0.3,
    "phosphorus": 40,
    "info": null
  },
  {
    "id": 480,
    "disabled": null,
    "type": "meal",
    "name": "Butter chicken",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Murgh Makhani",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "butter chicken murgh makhani",
    "weight-g": 300,
    "volume-ml": null,
    "calories": 574,
    "fat": 27.4,
    "saturated": 12,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 1490,
    "carbs": 57.8,
    "fibre": 2.2,
    "sugar": null,
    "protein": 26,
    "potassium": null,
    "calcium": 0,
    "iron": 0,
    "phosphorus": null,
    "info": "Murgh Makhani"
  },
  {
    "id": 603,
    "disabled": null,
    "type": "snack",
    "name": "Butter cookies",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 3,
    "unit": "piece",
    "info-translation": null,
    "info-brands": "Kjeldsens, Walkers",
    "info-examples": "Danish, shortbread",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "butter cookies kjeldsens, walkers danish, shortbread",
    "weight-g": 50,
    "volume-ml": null,
    "calories": 242,
    "fat": 10,
    "saturated": 4,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 15,
    "sodium": 110,
    "carbs": 34,
    "fibre": 0,
    "sugar": 8,
    "protein": 4,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Kjeldsens, Walkers, Danish, shortbread"
  },
  {
    "id": 545,
    "disabled": null,
    "type": "snack",
    "name": "Butter, salted",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "butter, salted",
    "weight-g": 14.2,
    "volume-ml": null,
    "calories": 104,
    "fat": 11.5,
    "saturated": 7.3,
    "polyunsaturated": 0.4,
    "monounsaturated": 3,
    "trans": 0.5,
    "cholesterol": 30.5,
    "sodium": 91.3,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 0.1,
    "potassium": 3.4,
    "calcium": 3.4,
    "iron": 0,
    "phosphorus": 3.4,
    "info": null
  },
  {
    "id": 668,
    "disabled": null,
    "type": "meal",
    "name": "Button mushrooms, canned, drained",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "can",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "button mushrooms, canned, drained",
    "weight-g": 156,
    "volume-ml": null,
    "calories": 16,
    "fat": 0.5,
    "saturated": 0.1,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 592.8,
    "carbs": 7.7,
    "fibre": 4.7,
    "sugar": null,
    "protein": 3,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 660,
    "disabled": null,
    "type": "meal",
    "name": "Cabbage, boiled",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cabbage, boiled",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 25.6,
    "fat": 0.4,
    "saturated": 0.1,
    "polyunsaturated": 0.2,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 8,
    "carbs": 4.5,
    "fibre": 2.3,
    "sugar": 0,
    "protein": 1,
    "potassium": 97,
    "calcium": 31,
    "iron": 0.2,
    "phosphorus": 15,
    "info": null
  },
  {
    "id": 208,
    "disabled": null,
    "type": "snack",
    "name": "Cake, chiffon",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "pandan, orange, vanilla",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cake, chiffon pandan, orange, vanilla",
    "weight-g": 41,
    "volume-ml": null,
    "calories": 112.6,
    "fat": 7,
    "saturated": 3.2,
    "polyunsaturated": 0.5,
    "monounsaturated": 2.1,
    "trans": null,
    "cholesterol": 43,
    "sodium": 74,
    "carbs": 9.1,
    "fibre": 0.9,
    "sugar": 6.5,
    "protein": 3.3,
    "potassium": 63,
    "calcium": 20,
    "iron": 0.6,
    "phosphorus": 76,
    "info": "e.g. pandan, orange, vanilla"
  },
  {
    "id": 47,
    "disabled": null,
    "type": "snack",
    "name": "Cake, chocolate lava",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cake, chocolate lava",
    "weight-g": 128,
    "volume-ml": null,
    "calories": 430.7,
    "fat": 27.1,
    "saturated": 16.8,
    "polyunsaturated": 1.5,
    "monounsaturated": 8.2,
    "trans": 0.6,
    "cholesterol": null,
    "sodium": 67,
    "carbs": 39,
    "fibre": 3.2,
    "sugar": 30.5,
    "protein": 7.7,
    "potassium": null,
    "calcium": 54,
    "iron": 4.2,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 903,
    "disabled": null,
    "type": "snack",
    "name": "Cake, fruitcake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with candied / dried fruit, nuts and spices",
    "search-text": "cake, fruitcake",
    "weight-g": 43,
    "volume-ml": null,
    "calories": 146.2,
    "fat": 3.9,
    "saturated": 0.5,
    "polyunsaturated": 1.4,
    "monounsaturated": 1.8,
    "trans": null,
    "cholesterol": 2.2,
    "sodium": 43.4,
    "carbs": 26.5,
    "fibre": 1.6,
    "sugar": 11.8,
    "protein": 1.3,
    "potassium": 65.8,
    "calcium": 14.2,
    "iron": 0.9,
    "phosphorus": 22.4,
    "info": "with candied / dried fruit, nuts and spices"
  },
  {
    "id": 81,
    "disabled": null,
    "type": "snack",
    "name": "Cake, mille crepe",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cake, mille crepe",
    "weight-g": 74,
    "volume-ml": null,
    "calories": 186.8,
    "fat": 13.2,
    "saturated": 8.7,
    "polyunsaturated": 0.6,
    "monounsaturated": 3.5,
    "trans": 0.4,
    "cholesterol": null,
    "sodium": 42,
    "carbs": 12.9,
    "fibre": 0.4,
    "sugar": 6.3,
    "protein": 4.1,
    "potassium": null,
    "calcium": 70,
    "iron": 0.5,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 30,
    "disabled": null,
    "type": "snack",
    "name": "Cake, sugee",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with semolina, almond",
    "search-text": "cake, [sugee|suji|sujee|sugi]",
    "weight-g": 45,
    "volume-ml": null,
    "calories": 209.2,
    "fat": 14.8,
    "saturated": 7.7,
    "polyunsaturated": 1.6,
    "monounsaturated": 4.9,
    "trans": null,
    "cholesterol": 84,
    "sodium": 81,
    "carbs": 15.1,
    "fibre": 0.8,
    "sugar": 5.4,
    "protein": 3.9,
    "potassium": null,
    "calcium": 27,
    "iron": 0.5,
    "phosphorus": 51,
    "info": "with semolina, almond"
  },
  {
    "id": 612,
    "disabled": null,
    "type": "drink",
    "name": "Canned coffee",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "can",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with sugar",
    "search-text": "canned coffee",
    "weight-g": null,
    "volume-ml": 240,
    "calories": 100.7,
    "fat": 1.9,
    "saturated": 1.5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 17.5,
    "fibre": null,
    "sugar": 15.6,
    "protein": 3.4,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "with sugar"
  },
  {
    "id": 538,
    "disabled": null,
    "type": "meal",
    "name": "Canned fish in sauce",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "tuna, sardine",
    "info-portion-aid": null,
    "info-include-exclude": "with mayo / chilli / curry",
    "search-text": "canned fish in sauce tuna, sardine",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 150.8,
    "fat": 10,
    "saturated": 1.6,
    "polyunsaturated": 5.5,
    "monounsaturated": 2.8,
    "trans": 0.1,
    "cholesterol": 17,
    "sodium": 488,
    "carbs": 2.8,
    "fibre": 1.4,
    "sugar": 2.8,
    "protein": 12.4,
    "potassium": 95,
    "calcium": 9,
    "iron": 1.9,
    "phosphorus": 126,
    "info": "with mayo / chilli / curry, e.g. tuna, sardine"
  },
  {
    "id": 233,
    "disabled": null,
    "type": "snack",
    "name": "Canned fish in brine / water",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "tuna, sardine",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "canned fish in brine / water tuna, sardine",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 121.4,
    "fat": 3,
    "saturated": 0.8,
    "polyunsaturated": 1.1,
    "monounsaturated": 0.8,
    "trans": null,
    "cholesterol": 42,
    "sodium": 50,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 23.6,
    "potassium": 237,
    "calcium": 14,
    "iron": 1,
    "phosphorus": 217,
    "info": "e.g. tuna, sardine"
  },
  {
    "id": 1081,
    "disabled": null,
    "type": "snack",
    "name": "Canned fruits, drained",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 0.2,
    "unit": "can",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "pineapple, lychee, longan",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "canned fruits, drained pineapple, lychee, longan",
    "weight-g": 160,
    "volume-ml": null,
    "calories": 91,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 10,
    "carbs": 23.9,
    "fibre": 1.6,
    "sugar": 22.3,
    "protein": 0.6,
    "potassium": 142,
    "calcium": 9.6,
    "iron": 0.5,
    "phosphorus": 18,
    "info": "e.g. pineapple, lychee, longan"
  },
  {
    "id": 85,
    "disabled": null,
    "type": "snack",
    "name": "Canned fruits in syrup",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 0.2,
    "unit": "can",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "pineapple, lychee, longan",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "canned fruits in syrup pineapple, lychee, longan",
    "weight-g": 165,
    "volume-ml": null,
    "calories": 120,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 10,
    "carbs": 31.2,
    "fibre": 1.7,
    "sugar": 29.6,
    "protein": 0.6,
    "potassium": 145,
    "calcium": 9.9,
    "iron": 0.5,
    "phosphorus": 18,
    "info": "e.g. pineapple, lychee, longan"
  },
  {
    "id": 623,
    "disabled": null,
    "type": "meal",
    "name": "Canned mushroom, drained",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "canned mushroom, drained",
    "weight-g": 156,
    "volume-ml": null,
    "calories": 37,
    "fat": 0.5,
    "saturated": 0.1,
    "polyunsaturated": 0.2,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 593,
    "carbs": 7.6,
    "fibre": 4.7,
    "sugar": 0,
    "protein": 3,
    "potassium": 156,
    "calcium": 8,
    "iron": 1.3,
    "phosphorus": 162,
    "info": null
  },
  {
    "id": 1015,
    "disabled": null,
    "type": "snack",
    "name": "Caramel sea salt cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "caramel sea salt cake",
    "weight-g": 163,
    "volume-ml": null,
    "calories": 454.6,
    "fat": 23.8,
    "saturated": 12.7,
    "polyunsaturated": 2.2,
    "monounsaturated": 7.5,
    "trans": 0.7,
    "cholesterol": 73,
    "sodium": 279,
    "carbs": 53.8,
    "fibre": 2.2,
    "sugar": 43.7,
    "protein": 6.3,
    "potassium": 218,
    "calcium": 92,
    "iron": 1.4,
    "phosphorus": 176,
    "info": null
  },
  {
    "id": 496,
    "disabled": null,
    "type": "meal",
    "name": "Carpaccio",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "4 slices",
    "info-include-exclude": null,
    "search-text": "carpaccio",
    "weight-g": 39,
    "volume-ml": null,
    "calories": 90.5,
    "fat": 6.5,
    "saturated": 3,
    "polyunsaturated": 0.6,
    "monounsaturated": 2.9,
    "trans": null,
    "cholesterol": 27,
    "sodium": 133,
    "carbs": 1,
    "fibre": 0.1,
    "sugar": 0.4,
    "protein": 6.5,
    "potassium": 113,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "(4 slices)"
  },
  {
    "id": 1012,
    "disabled": null,
    "type": "snack",
    "name": "Carrot cake, without icing",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with walnut and raisins",
    "search-text": "carrot cake, without icing",
    "weight-g": 88,
    "volume-ml": null,
    "calories": 346.3,
    "fat": 26.3,
    "saturated": 3.5,
    "polyunsaturated": 13.4,
    "monounsaturated": 2.5,
    "trans": 0.9,
    "cholesterol": 28,
    "sodium": 106,
    "carbs": 21.9,
    "fibre": 2.5,
    "sugar": 13.7,
    "protein": 5.5,
    "potassium": 125,
    "calcium": 28,
    "iron": 0.8,
    "phosphorus": 98,
    "info": "with walnut and raisins"
  },
  {
    "id": 1082,
    "disabled": null,
    "type": "meal",
    "name": "Carrots, roasted",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "carrots, roasted",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 91,
    "fat": 6.2,
    "saturated": 0.9,
    "polyunsaturated": 0.6,
    "monounsaturated": 4.3,
    "trans": null,
    "cholesterol": null,
    "sodium": 211,
    "carbs": 5.8,
    "fibre": 4.6,
    "sugar": 5.8,
    "protein": 0.9,
    "potassium": 313,
    "calcium": 35,
    "iron": 0.3,
    "phosphorus": 41,
    "info": null
  },
  {
    "id": 643,
    "disabled": null,
    "type": "meal",
    "name": "Carrots, boiled / steamed",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "carrots, boiled / steamed",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 19.7,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 35,
    "carbs": 4,
    "fibre": 2.1,
    "sugar": 4,
    "protein": 0.7,
    "potassium": 211,
    "calcium": 25,
    "iron": 0.2,
    "phosphorus": 12,
    "info": null
  },
  {
    "id": 1083,
    "disabled": null,
    "type": "meal",
    "name": "Cauliflower, stir fried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cauliflower, stir fried",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 63,
    "fat": 5,
    "saturated": 0.6,
    "polyunsaturated": 3.2,
    "monounsaturated": 1.3,
    "trans": null,
    "cholesterol": null,
    "sodium": 15,
    "carbs": 4.1,
    "fibre": 2.7,
    "sugar": 0,
    "protein": 1.8,
    "potassium": 142,
    "calcium": 16,
    "iron": 0.3,
    "phosphorus": 32,
    "info": null
  },
  {
    "id": 682,
    "disabled": null,
    "type": "meal",
    "name": "Cauliflower, boiled / steamed",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cauliflower, boiled / steamed",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 28.1,
    "fat": 0.5,
    "saturated": 0.1,
    "polyunsaturated": 0.2,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 15,
    "carbs": 4.1,
    "fibre": 2.7,
    "sugar": 0,
    "protein": 1.8,
    "potassium": 142,
    "calcium": 16,
    "iron": 0.3,
    "phosphorus": 32,
    "info": null
  },
  {
    "id": 1084,
    "disabled": null,
    "type": "meal",
    "name": "Celery, stir fried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "celery, stir fried",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 73,
    "fat": 6.1,
    "saturated": 1,
    "polyunsaturated": 1.1,
    "monounsaturated": 3.6,
    "trans": null,
    "cholesterol": 1,
    "sodium": 117,
    "carbs": 3,
    "fibre": 1.6,
    "sugar": 1.4,
    "protein": 0.7,
    "potassium": 309,
    "calcium": 51,
    "iron": 0.3,
    "phosphorus": 35,
    "info": null
  },
  {
    "id": 642,
    "disabled": null,
    "type": "meal",
    "name": "Celery, raw / boiled / steamed",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "celery, raw / boiled steamed",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 12,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 45,
    "carbs": 1.8,
    "fibre": 2.4,
    "sugar": 1.8,
    "protein": 1.2,
    "potassium": 366,
    "calcium": 114,
    "iron": 0.3,
    "phosphorus": 48,
    "info": null
  },
  {
    "id": 381,
    "disabled": null,
    "type": "snack",
    "name": "Cempedak",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "seed",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[cempedak|chempedak|champada]",
    "weight-g": 8,
    "volume-ml": null,
    "calories": 12.4,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 3,
    "carbs": 2.6,
    "fibre": 1,
    "sugar": 2.5,
    "protein": 0.5,
    "potassium": 8,
    "calcium": 17,
    "iron": 0.2,
    "phosphorus": 0,
    "info": null
  },
  {
    "id": 586,
    "disabled": null,
    "type": "meal",
    "name": "Cereal bar, low sugar",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bar",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "energy / muesli / granola",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cereal bar, low sugar energy / muesli granola",
    "weight-g": 22,
    "volume-ml": null,
    "calories": 84,
    "fat": 1.7,
    "saturated": 1,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.6,
    "trans": null,
    "cholesterol": null,
    "sodium": 56,
    "carbs": 15,
    "fibre": 1.9,
    "sugar": 6.5,
    "protein": 1.4,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. energy / muesli / granola"
  },
  {
    "id": 585,
    "disabled": null,
    "type": "meal",
    "name": "Cereal bar, regular",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bar",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "energy / muesli / granola",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cereal bar, regular energy / muesli granola",
    "weight-g": 37,
    "volume-ml": null,
    "calories": 135.1,
    "fat": 3.2,
    "saturated": 0.5,
    "polyunsaturated": 1.7,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 131,
    "carbs": 25,
    "fibre": 1.1,
    "sugar": 10.7,
    "protein": 1.6,
    "potassium": 91.4,
    "calcium": 213,
    "iron": 2.3,
    "phosphorus": 49.6,
    "info": "e.g. energy / muesli / granola"
  },
  {
    "id": 763,
    "disabled": null,
    "type": "meal",
    "name": "Cereal prawn",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 3,
    "unit": "piece",
    "info-translation": "Mai Pian Xia",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cereal prawn mai pian xia",
    "weight-g": 75,
    "volume-ml": null,
    "calories": 185.2,
    "fat": 13.4,
    "saturated": 6.9,
    "polyunsaturated": 1.5,
    "monounsaturated": 5,
    "trans": 0,
    "cholesterol": 65.5,
    "sodium": 430,
    "carbs": 0.6,
    "fibre": 2.1,
    "sugar": 0.2,
    "protein": 15.6,
    "potassium": 163.5,
    "calcium": 169.5,
    "iron": 4.7,
    "phosphorus": 161.5,
    "info": "Mai Pian Xia"
  },
  {
    "id": 583,
    "disabled": null,
    "type": "meal",
    "name": "Cereal with dried fruits, sweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": "POST Raisin Bran",
    "info-examples": null,
    "info-portion-aid": "8 heaped tablespoons",
    "info-include-exclude": null,
    "search-text": "cereal with dried fruits, sweetened post raisin bran",
    "weight-g": 59,
    "volume-ml": null,
    "calories": 225,
    "fat": 4.3,
    "saturated": 0,
    "polyunsaturated": 1.1,
    "monounsaturated": 2.2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 145,
    "carbs": 44,
    "fibre": 5.4,
    "sugar": 14,
    "protein": 4.3,
    "potassium": 225,
    "calcium": 20,
    "iron": 9.7,
    "phosphorus": 100,
    "info": "e.g. POST Raisin Bran (8 heaped tablespoons)"
  },
  {
    "id": 248,
    "disabled": null,
    "type": "meal",
    "name": "Cereal, reduced sugar",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": "Special K, Cheerios",
    "info-examples": "granola",
    "info-portion-aid": "8 heaped tablespoons",
    "info-include-exclude": null,
    "search-text": "cereal, reduced sugar special k, cheerios granola",
    "weight-g": 60,
    "volume-ml": null,
    "calories": 214,
    "fat": 0.4,
    "saturated": 0.2,
    "polyunsaturated": 0.2,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 216,
    "carbs": 40,
    "fibre": 4,
    "sugar": 8.8,
    "protein": 12,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Special K, Cheerios, granola (8 heaped tablespoons)"
  },
  {
    "id": 584,
    "disabled": null,
    "type": "meal",
    "name": "Cereal, plain",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": "Weet-Bix",
    "info-examples": "muesli, corn flakes original",
    "info-portion-aid": "8 heaped tablespoons",
    "info-include-exclude": null,
    "search-text": "cereal, plain weet-bix muesli, corn flakes original",
    "weight-g": 56,
    "volume-ml": null,
    "calories": 208,
    "fat": 3.4,
    "saturated": 0.4,
    "polyunsaturated": 1.6,
    "monounsaturated": 1.4,
    "trans": null,
    "cholesterol": 0,
    "sodium": 100,
    "carbs": 36.2,
    "fibre": 4.4,
    "sugar": 9,
    "protein": 6.2,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Weet-Bix, muesli, corn flakes original (8 heaped tablespoons)"
  },
  {
    "id": 99,
    "disabled": null,
    "type": "meal",
    "name": "Cereal, sweetened",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": "Banana Nut Crunch, Honey Cheerios, Frosties, Fruit Loops, KoKo Krunch",
    "info-examples": null,
    "info-portion-aid": "8 heaped tablespoons",
    "info-include-exclude": null,
    "search-text": "cereal, sweetened banana nut crunch, honey cheerios, frosties, fruit loops, koko krunch",
    "weight-g": 59,
    "volume-ml": null,
    "calories": 230,
    "fat": 5,
    "saturated": 0.5,
    "polyunsaturated": 2,
    "monounsaturated": 2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 140,
    "carbs": 43,
    "fibre": 7,
    "sugar": 10,
    "protein": 6,
    "potassium": 210,
    "calcium": 20,
    "iron": 16.2,
    "phosphorus": 140,
    "info": "e.g. Banana Nut Crunch, Honey Cheerios, Frosties, Fruit Loops, KoKo Krunch (8 heaped tablespoons)"
  },
  {
    "id": 498,
    "disabled": null,
    "type": "meal",
    "name": "Chap chye",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": "Braised mixed vegetables",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with bean curd skins",
    "search-text": "[chap|cap] [chye|cai|chai] braised mixed vegetables",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 88.3,
    "fat": 6.7,
    "saturated": 3.1,
    "polyunsaturated": 1,
    "monounsaturated": 2.4,
    "trans": null,
    "cholesterol": 9,
    "sodium": 362,
    "carbs": 5.1,
    "fibre": 2.1,
    "sugar": 2,
    "protein": 1.9,
    "potassium": null,
    "calcium": 37,
    "iron": 0.9,
    "phosphorus": null,
    "info": "Braised mixed vegetables – with bean curd skins"
  },
  {
    "id": 249,
    "disabled": null,
    "type": "meal",
    "name": "Chapati",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[chapati|chappati|chapatti|chapathi|phulka]",
    "weight-g": 46,
    "volume-ml": null,
    "calories": 143.4,
    "fat": 5,
    "saturated": 2.4,
    "polyunsaturated": 0.7,
    "monounsaturated": 1.8,
    "trans": null,
    "cholesterol": 0,
    "sodium": 119,
    "carbs": 21.5,
    "fibre": 3.2,
    "sugar": 1,
    "protein": 3.1,
    "potassium": 102,
    "calcium": 13,
    "iron": 0.8,
    "phosphorus": 70,
    "info": null
  },
  {
    "id": 759,
    "disabled": null,
    "type": "meal",
    "name": "Char siew rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Barbecued pork rice",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[char|cha] [siew|shao|siu] rice [barbecued|bbq] pork",
    "weight-g": 327,
    "volume-ml": null,
    "calories": 606.4,
    "fat": 16.4,
    "saturated": 11.9,
    "polyunsaturated": 0.4,
    "monounsaturated": 1.1,
    "trans": null,
    "cholesterol": 56,
    "sodium": 880,
    "carbs": 91.2,
    "fibre": 5.9,
    "sugar": 13.1,
    "protein": 23.5,
    "potassium": 206,
    "calcium": 23,
    "iron": 0.3,
    "phosphorus": 193,
    "info": "Barbecued pork rice"
  },
  {
    "id": 913,
    "disabled": null,
    "type": "meal",
    "name": "Char siew soh, baked",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Barbecued pork puff",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[char|cha] [siew|shao|siu] [soh|sou], baked [barbecued|bbq] pork puff",
    "weight-g": 44,
    "volume-ml": null,
    "calories": 165.5,
    "fat": 11.1,
    "saturated": 5.8,
    "polyunsaturated": 1.1,
    "monounsaturated": 3.7,
    "trans": null,
    "cholesterol": 34,
    "sodium": 156,
    "carbs": 12.2,
    "fibre": 0.8,
    "sugar": 1.6,
    "protein": 4.2,
    "potassium": 64,
    "calcium": 12,
    "iron": 0.6,
    "phosphorus": 44,
    "info": "Barbecued pork puff"
  },
  {
    "id": 499,
    "disabled": null,
    "type": "meal",
    "name": "Charcuterie",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "4 slices",
    "info-include-exclude": "meat only",
    "search-text": "charcuterie",
    "weight-g": 28,
    "volume-ml": null,
    "calories": 89.9,
    "fat": 6.5,
    "saturated": 2.5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 24.9,
    "sodium": 470,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 8,
    "potassium": 155,
    "calcium": null,
    "iron": 2,
    "phosphorus": null,
    "info": "meat only (4 slices)"
  },
  {
    "id": 349,
    "disabled": null,
    "type": "snack",
    "name": "Chawanmushi",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": "Japanese steamed egg",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chawanmushi japanese steamed egg",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 74.1,
    "fat": 5.3,
    "saturated": 1.6,
    "polyunsaturated": 0.5,
    "monounsaturated": 2.2,
    "trans": null,
    "cholesterol": 208,
    "sodium": 360,
    "carbs": 0.2,
    "fibre": 0,
    "sugar": 0.1,
    "protein": 6.4,
    "potassium": 52,
    "calcium": 21,
    "iron": 0.9,
    "phosphorus": 70,
    "info": "Japanese steamed egg"
  },
  {
    "id": 100,
    "disabled": null,
    "type": "meal",
    "name": "Chee cheong fun with filling",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Steamed rice rolls",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with shrimps / char siew / scallops",
    "search-text": "chee [cheong|cheung] fun with filling steamed rice rolls",
    "weight-g": 129,
    "volume-ml": null,
    "calories": 132.2,
    "fat": 2.6,
    "saturated": 1.2,
    "polyunsaturated": 0.6,
    "monounsaturated": 0.8,
    "trans": null,
    "cholesterol": 17,
    "sodium": 239,
    "carbs": 18.5,
    "fibre": 1.2,
    "sugar": 1.2,
    "protein": 8.7,
    "potassium": 51,
    "calcium": 6,
    "iron": 0.8,
    "phosphorus": 0,
    "info": "Steamed rice rolls – with shrimps / char siew / scallops"
  },
  {
    "id": 843,
    "disabled": null,
    "type": "meal",
    "name": "Cheese baked rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cheese baked rice",
    "weight-g": 260,
    "volume-ml": null,
    "calories": 348,
    "fat": 4.8,
    "saturated": 2.6,
    "polyunsaturated": 0.5,
    "monounsaturated": 0.2,
    "trans": null,
    "cholesterol": 15,
    "sodium": 127,
    "carbs": 64.2,
    "fibre": 2.9,
    "sugar": 0.2,
    "protein": 12.8,
    "potassium": 161,
    "calcium": 2,
    "iron": 5.3,
    "phosphorus": 56,
    "info": null
  },
  {
    "id": 1086,
    "disabled": null,
    "type": "snack",
    "name": "Cheese cake, light (Japanese style)",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cheese cake, light (japanese style)",
    "weight-g": 32,
    "volume-ml": null,
    "calories": 87,
    "fat": 3.6,
    "saturated": 0.6,
    "polyunsaturated": 0.3,
    "monounsaturated": 2.8,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 11.4,
    "fibre": 0.1,
    "sugar": 7.7,
    "protein": 2.1,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1085,
    "disabled": null,
    "type": "snack",
    "name": "Cheese cake, fruit flavoured",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "blueberry, strawberry, chocolate",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cheese cake, fruit flavoured blueberry, strawberry, chocolate",
    "weight-g": 75,
    "volume-ml": null,
    "calories": 252,
    "fat": 15,
    "saturated": 8.1,
    "polyunsaturated": 1.1,
    "monounsaturated": 5,
    "trans": null,
    "cholesterol": 28,
    "sodium": 183,
    "carbs": 25.9,
    "fibre": 0.5,
    "sugar": 18.3,
    "protein": 4.3,
    "potassium": 119,
    "calcium": 88,
    "iron": 1.4,
    "phosphorus": 122,
    "info": "e.g. blueberry, strawberry, chocolate"
  },
  {
    "id": 48,
    "disabled": null,
    "type": "snack",
    "name": "Cheese cake, plain",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "New York, Chicago, basque burnt",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cheese cake, plain new york, chicago, basque burnt",
    "weight-g": 125,
    "volume-ml": null,
    "calories": 408.1,
    "fat": 28.1,
    "saturated": 12.4,
    "polyunsaturated": 2,
    "monounsaturated": 10.8,
    "trans": null,
    "cholesterol": 69,
    "sodium": 548,
    "carbs": 31.9,
    "fibre": 0.5,
    "sugar": 27.3,
    "protein": 6.9,
    "potassium": 112,
    "calcium": 64,
    "iron": 0.8,
    "phosphorus": 116,
    "info": "e.g. New York, Chicago, basque burnt"
  },
  {
    "id": 822,
    "disabled": null,
    "type": "snack",
    "name": "Cheese fries",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cheese fries",
    "weight-g": 216,
    "volume-ml": null,
    "calories": 592,
    "fat": 36,
    "saturated": 7,
    "polyunsaturated": 2.7,
    "monounsaturated": 26.3,
    "trans": null,
    "cholesterol": null,
    "sodium": 1438,
    "carbs": 61,
    "fibre": null,
    "sugar": 1.5,
    "protein": 8,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 605,
    "disabled": null,
    "type": "snack",
    "name": "Cheese rings",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "minipack",
    "info-translation": null,
    "info-brands": "Cheezels, Super Ring",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cheese rings cheezels, super ring",
    "weight-g": 15,
    "volume-ml": null,
    "calories": 86,
    "fat": 5.6,
    "saturated": 4,
    "polyunsaturated": 0.6,
    "monounsaturated": 0.8,
    "trans": null,
    "cholesterol": null,
    "sodium": 162,
    "carbs": 7.9,
    "fibre": null,
    "sugar": 1,
    "protein": 1,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Cheezels, Super Ring"
  },
  {
    "id": 740,
    "disabled": null,
    "type": "snack",
    "name": "Cheese sausage",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "stick",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cheese sausage",
    "weight-g": 70,
    "volume-ml": null,
    "calories": 211.2,
    "fat": 16.8,
    "saturated": 6.6,
    "polyunsaturated": 2.8,
    "monounsaturated": 7.4,
    "trans": 0.1,
    "cholesterol": 27,
    "sodium": 564,
    "carbs": 4.2,
    "fibre": 0.8,
    "sugar": 0.8,
    "protein": 10.8,
    "potassium": 184,
    "calcium": 56,
    "iron": 0.7,
    "phosphorus": 176,
    "info": null
  },
  {
    "id": 542,
    "disabled": null,
    "type": "snack",
    "name": "Cheese, natural, hard",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "square",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "Colby, Cheddar, Parmesan, Swiss",
    "info-portion-aid": "5cm square",
    "info-include-exclude": null,
    "search-text": "cheese, natural, hard colby, cheddar, parmesan, swiss",
    "weight-g": 20.6,
    "volume-ml": null,
    "calories": 80.8,
    "fat": 5.2,
    "saturated": 3.1,
    "polyunsaturated": 0.1,
    "monounsaturated": 1.5,
    "trans": null,
    "cholesterol": 14,
    "sodium": 242,
    "carbs": 0.7,
    "fibre": 0,
    "sugar": 0,
    "protein": 7.4,
    "potassium": 19,
    "calcium": 244,
    "iron": 0.2,
    "phosphorus": 143,
    "info": "e.g. Colby, Cheddar, Parmesan, Swiss (5cm square)"
  },
  {
    "id": 543,
    "disabled": null,
    "type": "snack",
    "name": "Cheese, natural, semi-hard",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "square",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "Muenster, Roquefort, Stilton, Gouda",
    "info-portion-aid": "5cm square",
    "info-include-exclude": null,
    "search-text": "cheese, natural, semi-hard muenster, roquefort, stilton, gouda",
    "weight-g": 34,
    "volume-ml": null,
    "calories": 126,
    "fat": 10.1,
    "saturated": 6.4,
    "polyunsaturated": 0.3,
    "monounsaturated": 2.9,
    "trans": null,
    "cholesterol": 32,
    "sodium": 190,
    "carbs": 0.9,
    "fibre": 0,
    "sugar": 0.2,
    "protein": 7.9,
    "potassium": 46,
    "calcium": 230,
    "iron": 0.1,
    "phosphorus": 153,
    "info": "e.g. Muenster, Roquefort, Stilton, Gouda (5cm square)"
  },
  {
    "id": 416,
    "disabled": null,
    "type": "meal",
    "name": "Cheese, natural, soft",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "square",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "Ricotta, Brie, Camembert, Mozzarella",
    "info-portion-aid": "5cm square",
    "info-include-exclude": null,
    "search-text": "cheese, natural, soft ricotta, brie, camembert, mozzarella",
    "weight-g": 34,
    "volume-ml": null,
    "calories": 114,
    "fat": 9.4,
    "saturated": 5.9,
    "polyunsaturated": 0.3,
    "monounsaturated": 2.7,
    "trans": null,
    "cholesterol": 34,
    "sodium": 214,
    "carbs": 0.2,
    "fibre": 0,
    "sugar": 0.2,
    "protein": 7.1,
    "potassium": 52,
    "calcium": 62.6,
    "iron": 0.2,
    "phosphorus": 64,
    "info": "e.g. Ricotta, Brie, Camembert, Mozzarella (5cm square)"
  },
  {
    "id": 250,
    "disabled": null,
    "type": "meal",
    "name": "Cheese, processed",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": "Kraft",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cheese, processed kraft",
    "weight-g": 21,
    "volume-ml": null,
    "calories": 70.6,
    "fat": 5.8,
    "saturated": 4,
    "polyunsaturated": 0.2,
    "monounsaturated": 1.6,
    "trans": null,
    "cholesterol": 17,
    "sodium": 284,
    "carbs": 0.1,
    "fibre": 0,
    "sugar": 0.1,
    "protein": 4.5,
    "potassium": 13,
    "calcium": 131,
    "iron": 0.1,
    "phosphorus": 59,
    "info": "e.g. Kraft"
  },
  {
    "id": 53,
    "disabled": null,
    "type": "snack",
    "name": "Chendol",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[chendol|cendol]",
    "weight-g": 250,
    "volume-ml": null,
    "calories": 208.4,
    "fat": 8.8,
    "saturated": 8,
    "polyunsaturated": 0,
    "monounsaturated": 0.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 29.3,
    "fibre": 8,
    "sugar": 29.8,
    "protein": 3,
    "potassium": null,
    "calcium": 38,
    "iron": 1.3,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 210,
    "disabled": null,
    "type": "snack",
    "name": "Cheng tng",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[cheng|qing] [tng|teng|tang]",
    "weight-g": 248,
    "volume-ml": null,
    "calories": 108,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 26.3,
    "fibre": 1.7,
    "sugar": 24.8,
    "protein": 0.7,
    "potassium": null,
    "calcium": 12,
    "iron": 0.3,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 382,
    "disabled": null,
    "type": "snack",
    "name": "Cherries",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 10,
    "unit": "cherry",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cherries",
    "weight-g": 82,
    "volume-ml": null,
    "calories": 57.3,
    "fat": 0.2,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 13.1,
    "fibre": 1.7,
    "sugar": 10.5,
    "protein": 0.9,
    "potassium": 182,
    "calcium": 10.7,
    "iron": 0.3,
    "phosphorus": 17.2,
    "info": null
  },
  {
    "id": 645,
    "disabled": null,
    "type": "meal",
    "name": "Cherry tomato",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 12,
    "unit": "tomato",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cherry tomato",
    "weight-g": 96,
    "volume-ml": null,
    "calories": 19.2,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 13.5,
    "carbs": 3.9,
    "fibre": 1.5,
    "sugar": 3.9,
    "protein": 0.9,
    "potassium": 240,
    "calcium": 15,
    "iron": 0.3,
    "phosphorus": 30,
    "info": null
  },
  {
    "id": 532,
    "disabled": null,
    "type": "snack",
    "name": "Chia seed pudding, flavoured",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "chocolate",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chia seed pudding, flavoured chocolate",
    "weight-g": 200,
    "volume-ml": null,
    "calories": 393,
    "fat": 19,
    "saturated": 9.5,
    "polyunsaturated": 4.8,
    "monounsaturated": 4.7,
    "trans": null,
    "cholesterol": 0,
    "sodium": 312,
    "carbs": 47.5,
    "fibre": 9.5,
    "sugar": 28.3,
    "protein": 8,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. chocolate"
  },
  {
    "id": 524,
    "disabled": null,
    "type": "meal",
    "name": "Chicken boat noodles",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chicken boat noodles",
    "weight-g": 540,
    "volume-ml": null,
    "calories": 493,
    "fat": 4.6,
    "saturated": 1.2,
    "polyunsaturated": 0.9,
    "monounsaturated": 1.5,
    "trans": null,
    "cholesterol": 112,
    "sodium": 830,
    "carbs": 63.9,
    "fibre": 4,
    "sugar": 0.9,
    "protein": 46.9,
    "potassium": 298,
    "calcium": 26,
    "iron": 1.4,
    "phosphorus": 239,
    "info": null
  },
  {
    "id": 894,
    "disabled": null,
    "type": "meal",
    "name": "Chicken chop & egg noodles, dry",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chicken chop & egg noodles, dry",
    "weight-g": 172,
    "volume-ml": null,
    "calories": 436.6,
    "fat": 18.6,
    "saturated": 7.4,
    "polyunsaturated": 1.7,
    "monounsaturated": 3.6,
    "trans": null,
    "cholesterol": 149,
    "sodium": 148,
    "carbs": 43.7,
    "fibre": 0.5,
    "sugar": 0.1,
    "protein": 23.6,
    "potassium": 161,
    "calcium": 21,
    "iron": 1.3,
    "phosphorus": 142,
    "info": null
  },
  {
    "id": 500,
    "disabled": null,
    "type": "meal",
    "name": "Chicken cotoletta",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": "Breaded cutlets",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chicken cotoletta breaded cutlets",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 288,
    "fat": 16.3,
    "saturated": 2.9,
    "polyunsaturated": 7.5,
    "monounsaturated": 4.9,
    "trans": null,
    "cholesterol": 43,
    "sodium": 632,
    "carbs": 17.8,
    "fibre": 2,
    "sugar": 0.7,
    "protein": 17.5,
    "potassium": 337,
    "calcium": 47,
    "iron": 1,
    "phosphorus": 240,
    "info": "Breaded cutlets"
  },
  {
    "id": 408,
    "disabled": null,
    "type": "snack",
    "name": "Chicken essence, bottled",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bottle",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chicken essence, bottled",
    "weight-g": null,
    "volume-ml": 68,
    "calories": 25.2,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 55,
    "carbs": 0.3,
    "fibre": 0.5,
    "sugar": null,
    "protein": 6,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 907,
    "disabled": null,
    "type": "meal",
    "name": "Chicken feet in black sauce, steamed",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 3,
    "unit": "feet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chicken feet in black sauce, steamed",
    "weight-g": 108,
    "volume-ml": null,
    "calories": 156.4,
    "fat": 10.8,
    "saturated": 3.4,
    "polyunsaturated": 2.1,
    "monounsaturated": 4.9,
    "trans": null,
    "cholesterol": 23,
    "sodium": 471,
    "carbs": 0.5,
    "fibre": 2.3,
    "sugar": 0.5,
    "protein": 14.3,
    "potassium": 29,
    "calcium": 279,
    "iron": 1.2,
    "phosphorus": 159,
    "info": null
  },
  {
    "id": 738,
    "disabled": null,
    "type": "snack",
    "name": "Chicken mushroom puff, fried",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": "Old Chang Kee",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chicken mushroom puff, fried old chang kee",
    "weight-g": 76,
    "volume-ml": null,
    "calories": 341.4,
    "fat": 25,
    "saturated": 8.6,
    "polyunsaturated": 4.9,
    "monounsaturated": 9.9,
    "trans": null,
    "cholesterol": 214,
    "sodium": 358,
    "carbs": 21.7,
    "fibre": 1.2,
    "sugar": 0.4,
    "protein": 7.4,
    "potassium": 503,
    "calcium": 69,
    "iron": 2.8,
    "phosphorus": 440,
    "info": "e.g. Old Chang Kee"
  },
  {
    "id": 818,
    "disabled": null,
    "type": "snack",
    "name": "Chicken nugget",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chicken nugget",
    "weight-g": 16,
    "volume-ml": null,
    "calories": 49,
    "fat": 3.2,
    "saturated": 0.6,
    "polyunsaturated": 1,
    "monounsaturated": 1.2,
    "trans": 0.4,
    "cholesterol": 8.8,
    "sodium": 95,
    "carbs": 2.4,
    "fibre": 0.1,
    "sugar": 0,
    "protein": 2.5,
    "potassium": 40.2,
    "calcium": 1.8,
    "iron": 0.1,
    "phosphorus": 43.5,
    "info": null
  },
  {
    "id": 741,
    "disabled": 1,
    "type": "snack",
    "name": "Chicken nugget on stick",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "stick",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "3 pieces",
    "info-include-exclude": null,
    "search-text": "chicken nugget on stick",
    "weight-g": 60,
    "volume-ml": null,
    "calories": 173.2,
    "fat": 10,
    "saturated": 3.4,
    "polyunsaturated": 1.9,
    "monounsaturated": 4.1,
    "trans": 0,
    "cholesterol": 14,
    "sodium": 389,
    "carbs": 12.5,
    "fibre": 0.2,
    "sugar": 4.3,
    "protein": 8.3,
    "potassium": 149,
    "calcium": 19,
    "iron": 0.4,
    "phosphorus": 168,
    "info": "(3 pieces)"
  },
  {
    "id": 252,
    "disabled": null,
    "type": "meal",
    "name": "Chicken rice, with steamed chicken",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chicken rice, with steamed chicken",
    "weight-g": 330,
    "volume-ml": null,
    "calories": 557.7,
    "fat": 13.9,
    "saturated": 5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 36.6,
    "sodium": 698,
    "carbs": 80.2,
    "fibre": 3.3,
    "sugar": null,
    "protein": 28.1,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 426,
    "disabled": null,
    "type": "meal",
    "name": "Chicken soup, non-creamy",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "ginseng, chinese herbs",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chicken soup, non-creamy ginseng, chinese herbs",
    "weight-g": 420,
    "volume-ml": null,
    "calories": 214,
    "fat": 10.1,
    "saturated": 3.8,
    "polyunsaturated": 2.1,
    "monounsaturated": 3.8,
    "trans": null,
    "cholesterol": 105,
    "sodium": 1046,
    "carbs": 7.1,
    "fibre": 0.4,
    "sugar": 3,
    "protein": 23.5,
    "potassium": null,
    "calcium": 34,
    "iron": 1.7,
    "phosphorus": null,
    "info": "e.g. ginseng, chinese herbs"
  },
  {
    "id": 820,
    "disabled": null,
    "type": "meal",
    "name": "Chicken tenders, breaded",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 3,
    "unit": "strips",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chicken tenders, breaded",
    "weight-g": 90,
    "volume-ml": null,
    "calories": 244,
    "fat": 12.5,
    "saturated": 2.2,
    "polyunsaturated": 5.2,
    "monounsaturated": 4.4,
    "trans": 0.1,
    "cholesterol": 43.2,
    "sodium": 693,
    "carbs": 15.5,
    "fibre": 1.1,
    "sugar": 0.4,
    "protein": 17.3,
    "potassium": 336,
    "calcium": 15.3,
    "iron": 0.7,
    "phosphorus": 254,
    "info": null
  },
  {
    "id": 253,
    "disabled": null,
    "type": "meal",
    "name": "Chicken wing, fried",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chicken wing, fried",
    "weight-g": 96,
    "volume-ml": null,
    "calories": 163,
    "fat": 11.3,
    "saturated": 3.1,
    "polyunsaturated": 2.5,
    "monounsaturated": 4.5,
    "trans": null,
    "cholesterol": 41,
    "sodium": 39,
    "carbs": 1.2,
    "fibre": 0,
    "sugar": 0,
    "protein": 13.3,
    "potassium": 90,
    "calcium": 8,
    "iron": 0.5,
    "phosphorus": 76,
    "info": null
  },
  {
    "id": 101,
    "disabled": null,
    "type": "meal",
    "name": "Chicken, fried, breaded",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": "KFC, Texas Chicken, Popeyes",
    "info-examples": null,
    "info-portion-aid": "5\" x 3\" x 1\"",
    "info-include-exclude": null,
    "search-text": "chicken, fried, breaded kfc, texas popeyes",
    "weight-g": 136,
    "volume-ml": null,
    "calories": 373.4,
    "fat": 24.6,
    "saturated": 6.6,
    "polyunsaturated": 4,
    "monounsaturated": 11.5,
    "trans": 0.3,
    "cholesterol": 144,
    "sodium": 1020,
    "carbs": 11.8,
    "fibre": 0.1,
    "sugar": 0,
    "protein": 26.2,
    "potassium": 326,
    "calcium": 73.4,
    "iron": 1.4,
    "phosphorus": 286,
    "info": "e.g. KFC, Texas Chicken, Popeyes (5\" x 3\" x 1\")"
  },
  {
    "id": 774,
    "disabled": null,
    "type": "meal",
    "name": "Chicken with dried chilli",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": "Gong Bao Ji",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chicken with dried [chilli|chili] [gong|kung] [bao|po|pao] ji",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 241.1,
    "fat": 17.1,
    "saturated": 6.1,
    "polyunsaturated": 2.5,
    "monounsaturated": 7.6,
    "trans": null,
    "cholesterol": 97,
    "sodium": 367,
    "carbs": 3.4,
    "fibre": 0,
    "sugar": 0.8,
    "protein": 18.4,
    "potassium": 285,
    "calcium": 17,
    "iron": 2.9,
    "phosphorus": 363,
    "info": "Gong Bao Ji"
  },
  {
    "id": 251,
    "disabled": null,
    "type": "meal",
    "name": "Chicken, roasted / boiled / grilled",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "skin removed",
    "search-text": "chicken, roasted / boiled grilled",
    "weight-g": 170,
    "volume-ml": null,
    "calories": 170,
    "fat": 6,
    "saturated": 3.4,
    "polyunsaturated": 0.6,
    "monounsaturated": 3,
    "trans": null,
    "cholesterol": 9.3,
    "sodium": 400,
    "carbs": 1,
    "fibre": 1.2,
    "sugar": 1,
    "protein": 28,
    "potassium": 234,
    "calcium": 32,
    "iron": 0.7,
    "phosphorus": 141,
    "info": "skin removed"
  },
  {
    "id": 767,
    "disabled": null,
    "type": "meal",
    "name": "Chicken, sweet and sour",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chicken, sweet and sour",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 243,
    "fat": 12.6,
    "saturated": 6.4,
    "polyunsaturated": 1.8,
    "monounsaturated": 4,
    "trans": null,
    "cholesterol": 36,
    "sodium": 364,
    "carbs": 19.3,
    "fibre": 0.1,
    "sugar": 9,
    "protein": 13.1,
    "potassium": 240,
    "calcium": 17,
    "iron": 1.7,
    "phosphorus": 180,
    "info": null
  },
  {
    "id": 694,
    "disabled": null,
    "type": "meal",
    "name": "Chickpeas, boiled",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chickpeas, boiled",
    "weight-g": 50,
    "volume-ml": null,
    "calories": 82,
    "fat": 1.3,
    "saturated": 0.1,
    "polyunsaturated": 0.6,
    "monounsaturated": 0.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 4,
    "carbs": 13.7,
    "fibre": 3.8,
    "sugar": 0,
    "protein": 4.5,
    "potassium": 146,
    "calcium": 25,
    "iron": 1.4,
    "phosphorus": 84,
    "info": null
  },
  {
    "id": 708,
    "disabled": null,
    "type": "snack",
    "name": "Chiku",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "fruit",
    "info-translation": "Sapota",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[chiku|chikoo] [sapota|zapota]",
    "weight-g": 170,
    "volume-ml": null,
    "calories": 155.9,
    "fat": 1.9,
    "saturated": 0.3,
    "polyunsaturated": 0,
    "monounsaturated": 0.9,
    "trans": null,
    "cholesterol": 0,
    "sodium": 20,
    "carbs": 33.9,
    "fibre": 9,
    "sugar": null,
    "protein": 0.8,
    "potassium": 328,
    "calcium": 36,
    "iron": 1.4,
    "phosphorus": 20,
    "info": "Sapota"
  },
  {
    "id": 732,
    "disabled": null,
    "type": "snack",
    "name": "Chilli crab puff, fried",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": "Old Chang Kee",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chilli crab puff, fried old chang kee",
    "weight-g": 64,
    "volume-ml": null,
    "calories": 200.5,
    "fat": 12.5,
    "saturated": 5,
    "polyunsaturated": 3.8,
    "monounsaturated": 3.7,
    "trans": null,
    "cholesterol": 29,
    "sodium": 684,
    "carbs": 17.4,
    "fibre": 0.9,
    "sugar": 4.5,
    "protein": 4.6,
    "potassium": 63,
    "calcium": 71,
    "iron": 0.7,
    "phosphorus": 51,
    "info": "e.g. Old Chang Kee"
  },
  {
    "id": 929,
    "disabled": null,
    "type": "meal",
    "name": "Chilli sauce",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chilli sauce",
    "weight-g": 16,
    "volume-ml": null,
    "calories": 21,
    "fat": 1.8,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 228,
    "carbs": 0.7,
    "fibre": 0.3,
    "sugar": 0.6,
    "protein": 0.5,
    "potassium": 25,
    "calcium": 14,
    "iron": 0.6,
    "phosphorus": 9,
    "info": null
  },
  {
    "id": 687,
    "disabled": null,
    "type": "meal",
    "name": "Chinese cabbage, boiled / steamed",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chinese cabbage, boiled / steamed",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 15.4,
    "fat": 0.2,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 34,
    "carbs": 1.8,
    "fibre": 0.6,
    "sugar": 0,
    "protein": 1.6,
    "potassium": 371,
    "calcium": 93,
    "iron": 1,
    "phosphorus": 29,
    "info": null
  },
  {
    "id": 226,
    "disabled": null,
    "type": "snack",
    "name": "Chinese carrot cake, pan-fried",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Lo Bak Go",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with radish / turnip",
    "search-text": "chinese carrot cake, pan-fried [lo|luo] [bak|bo] [go|gou|gao]",
    "weight-g": 130,
    "volume-ml": null,
    "calories": 180,
    "fat": 6.4,
    "saturated": 2.7,
    "polyunsaturated": 0.9,
    "monounsaturated": 2.7,
    "trans": null,
    "cholesterol": 5,
    "sodium": 476,
    "carbs": 27.2,
    "fibre": 1.2,
    "sugar": 1.4,
    "protein": 3.4,
    "potassium": 48,
    "calcium": 35,
    "iron": 1.4,
    "phosphorus": 38,
    "info": "Lo Bak Go – with radish / turnip"
  },
  {
    "id": 310,
    "disabled": null,
    "type": "meal",
    "name": "Chinese fruit rojak, without you tiao",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chinese fruit rojak, without you tiao",
    "weight-g": 240,
    "volume-ml": null,
    "calories": 345.8,
    "fat": 11.4,
    "saturated": 2.1,
    "polyunsaturated": 5.2,
    "monounsaturated": 3.4,
    "trans": null,
    "cholesterol": 35,
    "sodium": 419,
    "carbs": 47.2,
    "fibre": 9,
    "sugar": 3,
    "protein": 13.6,
    "potassium": null,
    "calcium": 124,
    "iron": 7.7,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 743,
    "disabled": null,
    "type": "meal",
    "name": "Chinese fruit rojak, with you tiao",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chinese fruit rojak, with you tiao",
    "weight-g": 315,
    "volume-ml": null,
    "calories": 557.8,
    "fat": 24.6,
    "saturated": 9.8,
    "polyunsaturated": 6.6,
    "monounsaturated": 6.9,
    "trans": null,
    "cholesterol": 35,
    "sodium": 857,
    "carbs": 64.6,
    "fibre": 9.8,
    "sugar": 5,
    "protein": 19.5,
    "potassium": null,
    "calcium": 192,
    "iron": 9.1,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 225,
    "disabled": null,
    "type": "snack",
    "name": "Chinese pumpkin cake, steamed",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chinese pumpkin cake, steamed",
    "weight-g": 130,
    "volume-ml": null,
    "calories": 146.2,
    "fat": 0.6,
    "saturated": 0.2,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.2,
    "trans": null,
    "cholesterol": 6,
    "sodium": 353,
    "carbs": 28.4,
    "fibre": 2.1,
    "sugar": 1.9,
    "protein": 6.8,
    "potassium": 242,
    "calcium": 57,
    "iron": 3.6,
    "phosphorus": 70,
    "info": null
  },
  {
    "id": 1087,
    "disabled": 1,
    "type": "meal",
    "name": "Steamboat, Chinese style",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "light meal with meat and vegetables",
    "search-text": "steamboat, chinese style",
    "weight-g": 351,
    "volume-ml": null,
    "calories": 405,
    "fat": 15.5,
    "saturated": 5.2,
    "polyunsaturated": 3.8,
    "monounsaturated": 4.9,
    "trans": null,
    "cholesterol": 12,
    "sodium": 531,
    "carbs": 47.8,
    "fibre": 3,
    "sugar": 2.6,
    "protein": 18.6,
    "potassium": 269,
    "calcium": 100,
    "iron": 4,
    "phosphorus": 150,
    "info": "light meal with meat and vegetables"
  },
  {
    "id": 1088,
    "disabled": null,
    "type": "meal",
    "name": "Chinese style steamboat (heavy meal with meat and vegetables)",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chinese style steamboat (heavy meal with meat and vegetables)",
    "weight-g": 683,
    "volume-ml": null,
    "calories": 705,
    "fat": 30.5,
    "saturated": 11.7,
    "polyunsaturated": 7.3,
    "monounsaturated": 9.3,
    "trans": null,
    "cholesterol": 100,
    "sodium": 2205,
    "carbs": 68.7,
    "fibre": 13,
    "sugar": 4,
    "protein": 37.6,
    "potassium": 400,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 372,
    "disabled": null,
    "type": "drink",
    "name": "Chinese tea",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chinese tea",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 3.2,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 5,
    "carbs": 0.3,
    "fibre": 0,
    "sugar": 0,
    "protein": 0.5,
    "potassium": 40,
    "calcium": 5,
    "iron": 0,
    "phosphorus": 8,
    "info": null
  },
  {
    "id": 224,
    "disabled": null,
    "type": "snack",
    "name": "Chinese yam cake, pan-fried",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chinese [yam|taro] cake, pan-fried",
    "weight-g": 133,
    "volume-ml": null,
    "calories": 193.2,
    "fat": 3.2,
    "saturated": 1.3,
    "polyunsaturated": 0.5,
    "monounsaturated": 1.2,
    "trans": null,
    "cholesterol": 3,
    "sodium": 580,
    "carbs": 37.8,
    "fibre": 1.3,
    "sugar": 0.3,
    "protein": 3.3,
    "potassium": 36,
    "calcium": 17,
    "iron": 0.4,
    "phosphorus": 35,
    "info": null
  },
  {
    "id": 600,
    "disabled": null,
    "type": "snack",
    "name": "Chips, vegetable, baked",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "packet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "small",
    "info-include-exclude": null,
    "search-text": "chips, vegetable, baked",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 130.2,
    "fat": 3.8,
    "saturated": 0.3,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 155,
    "carbs": 22,
    "fibre": 2,
    "sugar": 3,
    "protein": 2,
    "potassium": null,
    "calcium": 40,
    "iron": 5.5,
    "phosphorus": null,
    "info": "(small)"
  },
  {
    "id": 601,
    "disabled": null,
    "type": "snack",
    "name": "Chips, vegetable, fried",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "packet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "small",
    "info-include-exclude": null,
    "search-text": "chips, vegetable, fried",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 149.6,
    "fat": 6.4,
    "saturated": 2.1,
    "polyunsaturated": 0.5,
    "monounsaturated": 2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 180,
    "carbs": 21.5,
    "fibre": 0,
    "sugar": 8.5,
    "protein": 1.5,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "(small)"
  },
  {
    "id": 606,
    "disabled": 1,
    "type": "snack",
    "name": "Chips, potato and wheat",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 15,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chips, potato and wheat",
    "weight-g": 25,
    "volume-ml": null,
    "calories": 137.2,
    "fat": 8.4,
    "saturated": 3.7,
    "polyunsaturated": 0.8,
    "monounsaturated": 3.9,
    "trans": null,
    "cholesterol": 0,
    "sodium": 110,
    "carbs": 14.1,
    "fibre": 1.5,
    "sugar": 0.3,
    "protein": 1.3,
    "potassium": 299,
    "calcium": 6,
    "iron": 0.6,
    "phosphorus": 30,
    "info": null
  },
  {
    "id": 191,
    "disabled": null,
    "type": "snack",
    "name": "Chips, potato, baked",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 15,
    "unit": "chip",
    "info-translation": null,
    "info-brands": "Lay's, Ruffles",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chips, potato, baked lay's, ruffles",
    "weight-g": 25,
    "volume-ml": null,
    "calories": 112.7,
    "fat": 3.1,
    "saturated": 0,
    "polyunsaturated": 1.8,
    "monounsaturated": 0.9,
    "trans": null,
    "cholesterol": 0,
    "sodium": 141.2,
    "carbs": 19.4,
    "fibre": 0.9,
    "sugar": 1.8,
    "protein": 1.8,
    "potassium": 202.9,
    "calcium": 8.8,
    "iron": 0.3,
    "phosphorus": null,
    "info": "e.g. Lay's, Ruffles"
  },
  {
    "id": 20,
    "disabled": null,
    "type": "snack",
    "name": "Chips, potato, fried",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 15,
    "unit": "chip",
    "info-translation": null,
    "info-brands": "Pringles, Jack n Jill's",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chips, potato, fried pringles, jack n jill's",
    "weight-g": 25,
    "volume-ml": null,
    "calories": 137.2,
    "fat": 8.4,
    "saturated": 3.7,
    "polyunsaturated": 0.8,
    "monounsaturated": 3.9,
    "trans": null,
    "cholesterol": 0,
    "sodium": 110,
    "carbs": 14.1,
    "fibre": 1.5,
    "sugar": 0.3,
    "protein": 1.3,
    "potassium": 299,
    "calcium": 6,
    "iron": 0.6,
    "phosphorus": 30,
    "info": "e.g. Pringles, Jack n Jill's"
  },
  {
    "id": 184,
    "disabled": null,
    "type": "snack",
    "name": "Chips, vegetable, dehydrated",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "pack",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "kale, broccoli, okra",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chips, vegetable, dehydrated kale, broccoli, okra",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 143.2,
    "fat": 11.2,
    "saturated": 1.9,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 340,
    "carbs": 3.7,
    "fibre": 4.9,
    "sugar": 1.7,
    "protein": 6.9,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. kale, broccoli, okra"
  },
  {
    "id": 205,
    "disabled": null,
    "type": "snack",
    "name": "Chips, whole grain, baked",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 15,
    "unit": "chip",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "lentil",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chips, whole grain, baked lentil",
    "weight-g": 28,
    "volume-ml": null,
    "calories": 89.9,
    "fat": 2.3,
    "saturated": 0,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 101.3,
    "carbs": 14.3,
    "fibre": 2.3,
    "sugar": 0,
    "protein": 3,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. lentil"
  },
  {
    "id": 256,
    "disabled": null,
    "type": "meal",
    "name": "Chirashi don",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chirashi don",
    "weight-g": 409,
    "volume-ml": null,
    "calories": 611.2,
    "fat": 15.2,
    "saturated": 5.7,
    "polyunsaturated": 2.8,
    "monounsaturated": 6.5,
    "trans": null,
    "cholesterol": null,
    "sodium": 1472,
    "carbs": 85.9,
    "fibre": 10.6,
    "sugar": 5.3,
    "protein": 32.7,
    "potassium": null,
    "calcium": 52,
    "iron": 1.3,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 912,
    "disabled": null,
    "type": "meal",
    "name": "Chive with minced prawn dumpling, steamed",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Gao Choi Gao",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chive with minced prawn dumpling, steamed [gao|jiu|jiao] [choi|cai]",
    "weight-g": 34,
    "volume-ml": null,
    "calories": 47.9,
    "fat": 1.9,
    "saturated": 0.6,
    "polyunsaturated": 0.4,
    "monounsaturated": 0.8,
    "trans": null,
    "cholesterol": 10,
    "sodium": 164,
    "carbs": 5.1,
    "fibre": 0.8,
    "sugar": 0,
    "protein": 2.6,
    "potassium": 61,
    "calcium": 14,
    "iron": 0.2,
    "phosphorus": 22,
    "info": "Gao Choi Gao"
  },
  {
    "id": 924,
    "disabled": null,
    "type": "meal",
    "name": "Chives pork bun, pan-fried",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Sheng Jian Bao",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chives pork bun, pan-fried [sheng|shui] jian [bao|pau|pao]",
    "weight-g": 91,
    "volume-ml": null,
    "calories": 205.6,
    "fat": 8.4,
    "saturated": 3.4,
    "polyunsaturated": 0.9,
    "monounsaturated": 3.4,
    "trans": null,
    "cholesterol": 69,
    "sodium": 185,
    "carbs": 26.9,
    "fibre": 1,
    "sugar": 0.6,
    "protein": 5.6,
    "potassium": 111,
    "calcium": 13,
    "iron": 0.9,
    "phosphorus": 62,
    "info": "Sheng Jian Bao"
  },
  {
    "id": 1022,
    "disabled": null,
    "type": "snack",
    "name": "Chocolate banana cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chocolate banana cake",
    "weight-g": 80,
    "volume-ml": null,
    "calories": 259,
    "fat": 11,
    "saturated": 5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 35,
    "sodium": 330,
    "carbs": 37,
    "fibre": 1,
    "sugar": 25,
    "protein": 3,
    "potassium": null,
    "calcium": 26,
    "iron": 1,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 49,
    "disabled": null,
    "type": "snack",
    "name": "Chocolate brownie",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "square",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chocolate brownie",
    "weight-g": 56,
    "volume-ml": null,
    "calories": 236.1,
    "fat": 9.1,
    "saturated": 2.4,
    "polyunsaturated": 1.3,
    "monounsaturated": 5,
    "trans": null,
    "cholesterol": 9.5,
    "sodium": 160,
    "carbs": 35.8,
    "fibre": 1.2,
    "sugar": 20.5,
    "protein": 2.7,
    "potassium": 83.4,
    "calcium": 16.2,
    "iron": 1.3,
    "phosphorus": 56.6,
    "info": null
  },
  {
    "id": 1021,
    "disabled": null,
    "type": "snack",
    "name": "Chocolate fudge cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chocolate fudge cake",
    "weight-g": 145,
    "volume-ml": null,
    "calories": 644.9,
    "fat": 40.5,
    "saturated": 21.9,
    "polyunsaturated": 2.7,
    "monounsaturated": 13.4,
    "trans": 0.4,
    "cholesterol": 96,
    "sodium": 289,
    "carbs": 61,
    "fibre": 1.9,
    "sugar": 41.8,
    "protein": 9.1,
    "potassium": 332,
    "calcium": 108,
    "iron": 2.3,
    "phosphorus": 345,
    "info": null
  },
  {
    "id": 1019,
    "disabled": null,
    "type": "snack",
    "name": "Chocolate mousse cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chocolate mousse cake",
    "weight-g": 150,
    "volume-ml": null,
    "calories": 513,
    "fat": 29.7,
    "saturated": 17.5,
    "polyunsaturated": 1.4,
    "monounsaturated": 9,
    "trans": 0.5,
    "cholesterol": 144,
    "sodium": 232,
    "carbs": 55.7,
    "fibre": 1.1,
    "sugar": 41,
    "protein": 7.5,
    "potassium": 271,
    "calcium": 115,
    "iron": 1.8,
    "phosphorus": 285,
    "info": null
  },
  {
    "id": 1023,
    "disabled": null,
    "type": "snack",
    "name": "Chocolate praline cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chocolate praline cake",
    "weight-g": 73,
    "volume-ml": null,
    "calories": 297,
    "fat": 17,
    "saturated": 5,
    "polyunsaturated": 2,
    "monounsaturated": 8,
    "trans": null,
    "cholesterol": null,
    "sodium": 160,
    "carbs": 33,
    "fibre": 1,
    "sugar": 22,
    "protein": 3,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 65,
    "disabled": null,
    "type": "snack",
    "name": "Chocolate pudding",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chocolate pudding",
    "weight-g": 98,
    "volume-ml": null,
    "calories": 139.2,
    "fat": 4.5,
    "saturated": 1.2,
    "polyunsaturated": 0.1,
    "monounsaturated": 2.7,
    "trans": null,
    "cholesterol": 1,
    "sodium": 149,
    "carbs": 22.6,
    "fibre": 0,
    "sugar": 16.8,
    "protein": 2.1,
    "potassium": 180,
    "calcium": 50,
    "iron": 1.2,
    "phosphorus": 54.9,
    "info": null
  },
  {
    "id": 1031,
    "disabled": null,
    "type": "snack",
    "name": "Chocolate tart",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chocolate tart",
    "weight-g": 117,
    "volume-ml": null,
    "calories": 338,
    "fat": 16.2,
    "saturated": 5.4,
    "polyunsaturated": 3.6,
    "monounsaturated": 6.2,
    "trans": null,
    "cholesterol": 44.5,
    "sodium": 303,
    "carbs": 42.3,
    "fibre": 1.8,
    "sugar": 20.8,
    "protein": 6.2,
    "potassium": 178,
    "calcium": 86.6,
    "iron": 2.5,
    "phosphorus": 129,
    "info": null
  },
  {
    "id": 1020,
    "disabled": null,
    "type": "snack",
    "name": "Chocolate truffle cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chocolate truffle cake",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 389,
    "fat": 25,
    "saturated": 14,
    "polyunsaturated": 1,
    "monounsaturated": 10,
    "trans": null,
    "cholesterol": 65,
    "sodium": null,
    "carbs": 37,
    "fibre": 1,
    "sugar": 28,
    "protein": 4,
    "potassium": null,
    "calcium": 40,
    "iron": 1.8,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 19,
    "disabled": null,
    "type": "snack",
    "name": "Chocolate, sweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "square",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "milk, white",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chocolate, sweetened milk, white",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 158.2,
    "fat": 8.2,
    "saturated": 5.1,
    "polyunsaturated": 0.3,
    "monounsaturated": 2.5,
    "trans": null,
    "cholesterol": 5.5,
    "sodium": 27,
    "carbs": 18.6,
    "fibre": 0.3,
    "sugar": 16.6,
    "protein": 2.5,
    "potassium": 135,
    "calcium": 75,
    "iron": 0.4,
    "phosphorus": 78,
    "info": "e.g. milk, white"
  },
  {
    "id": 203,
    "disabled": null,
    "type": "snack",
    "name": "Chocolate, unsweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "square",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "dark",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chocolate, unsweetened dark",
    "weight-g": 25,
    "volume-ml": null,
    "calories": 132.4,
    "fat": 7.2,
    "saturated": 4.3,
    "polyunsaturated": 0.3,
    "monounsaturated": 2.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 14,
    "carbs": 15.6,
    "fibre": 0.3,
    "sugar": 13,
    "protein": 1.3,
    "potassium": 110,
    "calcium": 13,
    "iron": 1.1,
    "phosphorus": 50,
    "info": "e.g. dark"
  },
  {
    "id": 570,
    "disabled": null,
    "type": "drink",
    "name": "Chrysanthemum tea, sweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "packet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chrysanthemum tea, sweetened",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 87.2,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": null,
    "carbs": 21.8,
    "fibre": null,
    "sugar": 20.3,
    "protein": 0,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 571,
    "disabled": null,
    "type": "drink",
    "name": "Chrysanthemum tea, unsweetened",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "packet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chrysanthemum tea, unsweetened",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 10,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 53,
    "carbs": 2,
    "fibre": 0,
    "sugar": 0.2,
    "protein": 0.5,
    "potassium": 347,
    "calcium": 0,
    "iron": 0,
    "phosphorus": 10,
    "info": null
  },
  {
    "id": 52,
    "disabled": null,
    "type": "snack",
    "name": "Churros",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "churro",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "churros",
    "weight-g": 26,
    "volume-ml": null,
    "calories": 125,
    "fat": 8,
    "saturated": 2.3,
    "polyunsaturated": 2.1,
    "monounsaturated": 3.2,
    "trans": null,
    "cholesterol": 1.8,
    "sodium": 42,
    "carbs": 12.9,
    "fibre": 0.2,
    "sugar": 7.1,
    "protein": 0.8,
    "potassium": 9,
    "calcium": 1.8,
    "iron": 0.4,
    "phosphorus": 8,
    "info": null
  },
  {
    "id": 76,
    "disabled": null,
    "type": "snack",
    "name": "Chwee kueh with chye poh",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Steamed rice cake with preserved radish",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chwee [kueh|kway|kuih] with [chye|cai|chai] poh steamed rice cake preserved radish",
    "weight-g": 60,
    "volume-ml": null,
    "calories": 91.8,
    "fat": 5,
    "saturated": 3.3,
    "polyunsaturated": 0.3,
    "monounsaturated": 1.3,
    "trans": null,
    "cholesterol": 1,
    "sodium": 229,
    "carbs": 10.7,
    "fibre": 1.1,
    "sugar": 0,
    "protein": 1,
    "potassium": null,
    "calcium": 8,
    "iron": 0.4,
    "phosphorus": null,
    "info": "Steamed rice cake with preserved radish"
  },
  {
    "id": 693,
    "disabled": null,
    "type": "meal",
    "name": "Chye sim, stir-fried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": "Mustard green",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[chye|choy|cai|sawi|sio] [sim|sum|xin], stir-fried mustard green",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 14.7,
    "fat": 0.2,
    "saturated": 0.4,
    "polyunsaturated": 1.1,
    "monounsaturated": 1.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 254,
    "carbs": 1.7,
    "fibre": 1,
    "sugar": 0.8,
    "protein": 1.5,
    "potassium": 359,
    "calcium": 90,
    "iron": 1,
    "phosphorus": 28,
    "info": "Mustard green"
  },
  {
    "id": 169,
    "disabled": null,
    "type": "drink",
    "name": "Cider",
    "rating": 2,
    "alcohol": 1,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bottle",
    "info-translation": null,
    "info-brands": "Somersby",
    "info-examples": "apple, pear",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cider somersby apple, pear",
    "weight-g": null,
    "volume-ml": 330,
    "calories": 118.8,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 29.7,
    "fibre": null,
    "sugar": 29.7,
    "protein": 0,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Somersby, apple, pear"
  },
  {
    "id": 363,
    "disabled": null,
    "type": "drink",
    "name": "Cider vinegar",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cider vinegar",
    "weight-g": null,
    "volume-ml": 5,
    "calories": 0.4,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 0.1,
    "fibre": 0,
    "sugar": null,
    "protein": 0,
    "potassium": 4,
    "calcium": 0,
    "iron": 0,
    "phosphorus": 0,
    "info": null
  },
  {
    "id": 791,
    "disabled": null,
    "type": "meal",
    "name": "Claypot noodle",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "claypot",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with mixed vegetables",
    "search-text": "claypot noodle",
    "weight-g": 502,
    "volume-ml": null,
    "calories": 421.3,
    "fat": 18.1,
    "saturated": 7.9,
    "polyunsaturated": 1.9,
    "monounsaturated": 7.7,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1270,
    "carbs": 55.6,
    "fibre": 4,
    "sugar": 3.5,
    "protein": 9,
    "potassium": 266,
    "calcium": 85,
    "iron": 2,
    "phosphorus": 105,
    "info": "with mixed vegetables"
  },
  {
    "id": 447,
    "disabled": null,
    "type": "meal",
    "name": "Claypot rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with chicken, sausage, mushroom",
    "search-text": "claypot rice",
    "weight-g": 400,
    "volume-ml": null,
    "calories": 602.4,
    "fat": 24.8,
    "saturated": 10.4,
    "polyunsaturated": 3.6,
    "monounsaturated": 9.6,
    "trans": null,
    "cholesterol": 112,
    "sodium": 1464,
    "carbs": 62.4,
    "fibre": 4,
    "sugar": 4.5,
    "protein": 32.4,
    "potassium": 300,
    "calcium": 80,
    "iron": 2,
    "phosphorus": 240,
    "info": "with chicken, sausage, mushroom"
  },
  {
    "id": 831,
    "disabled": null,
    "type": "meal",
    "name": "Clear soup",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with meat and vegetables",
    "search-text": "clear soup",
    "weight-g": 350,
    "volume-ml": null,
    "calories": 286,
    "fat": 13,
    "saturated": 4.5,
    "polyunsaturated": 1.7,
    "monounsaturated": 6.8,
    "trans": null,
    "cholesterol": null,
    "sodium": 1000,
    "carbs": 31,
    "fibre": null,
    "sugar": null,
    "protein": 19,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "with meat and vegetables"
  },
  {
    "id": 7,
    "disabled": null,
    "type": "drink",
    "name": "Cocktail",
    "rating": 1,
    "alcohol": 1,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "glass",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cocktail",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 257,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": null,
    "sodium": 0,
    "carbs": 17,
    "fibre": 0,
    "sugar": null,
    "protein": 0,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 465,
    "disabled": null,
    "type": "drink",
    "name": "Coconut milk, sweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "coconut milk, sweetened",
    "weight-g": null,
    "volume-ml": 240,
    "calories": 70,
    "fat": 5,
    "saturated": 5,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 45.6,
    "carbs": 7,
    "fibre": 0,
    "sugar": 6,
    "protein": 0.5,
    "potassium": 45.6,
    "calcium": 451,
    "iron": 0.7,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 464,
    "disabled": null,
    "type": "drink",
    "name": "Coconut milk, unsweetened",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "coconut milk, unsweetened",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 45,
    "fat": 6.8,
    "saturated": 6.3,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 100,
    "carbs": 5,
    "fibre": null,
    "sugar": 0.7,
    "protein": 0.5,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1010,
    "disabled": null,
    "type": "snack",
    "name": "Coconut pudding",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "coconut pudding",
    "weight-g": 255,
    "volume-ml": null,
    "calories": 283,
    "fat": 8.8,
    "saturated": 7,
    "polyunsaturated": 0.2,
    "monounsaturated": 1.2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 454,
    "carbs": 44.3,
    "fibre": 1.3,
    "sugar": 40.3,
    "protein": 7.4,
    "potassium": 347,
    "calcium": 258,
    "iron": 0.3,
    "phosphorus": 357,
    "info": null
  },
  {
    "id": 198,
    "disabled": null,
    "type": "snack",
    "name": "Coconut tart",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "coconut tart",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 298,
    "fat": 16.6,
    "saturated": 7,
    "polyunsaturated": 1.5,
    "monounsaturated": 7,
    "trans": null,
    "cholesterol": 0,
    "sodium": 204,
    "carbs": 37.3,
    "fibre": 1.3,
    "sugar": 18.5,
    "protein": 2.1,
    "potassium": 65,
    "calcium": 29,
    "iron": 0.8,
    "phosphorus": 85,
    "info": null
  },
  {
    "id": 615,
    "disabled": null,
    "type": "drink",
    "name": "Coconut water (bottled / packet)",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "packet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "coconut water (bottled / packet)",
    "weight-g": null,
    "volume-ml": 330,
    "calories": 62.7,
    "fat": 0.3,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 132,
    "carbs": 15,
    "fibre": 0,
    "sugar": 15,
    "protein": 0,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 417,
    "disabled": null,
    "type": "meal",
    "name": "Cod, baked / grilled / steamed",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cod, baked / grilled steamed",
    "weight-g": 85,
    "volume-ml": null,
    "calories": 84.2,
    "fat": 0.7,
    "saturated": 0.1,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 46.8,
    "sodium": 66.3,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 19.4,
    "potassium": 207,
    "calcium": 11.9,
    "iron": 0.4,
    "phosphorus": 117,
    "info": null
  },
  {
    "id": 441,
    "disabled": null,
    "type": "meal",
    "name": "Cod, pan fried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cod, pan fried",
    "weight-g": 85,
    "volume-ml": null,
    "calories": 134,
    "fat": 6.8,
    "saturated": 1,
    "polyunsaturated": 3.7,
    "monounsaturated": 1.2,
    "trans": null,
    "cholesterol": 40.8,
    "sodium": 289,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 18.2,
    "potassium": 297.5,
    "calcium": 18.7,
    "iron": 0.3,
    "phosphorus": 161.5,
    "info": null
  },
  {
    "id": 1089,
    "disabled": 1,
    "type": "meal",
    "name": "Cod (pan fried)",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cod (pan fried)",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 157,
    "fat": 8,
    "saturated": 1.2,
    "polyunsaturated": 4.3,
    "monounsaturated": 1.4,
    "trans": null,
    "cholesterol": 48,
    "sodium": 340,
    "carbs": 0,
    "fibre": null,
    "sugar": null,
    "protein": 21.4,
    "potassium": 350,
    "calcium": 22,
    "iron": 0.4,
    "phosphorus": 190,
    "info": null
  },
  {
    "id": 971,
    "disabled": null,
    "type": "drink",
    "name": "Coffee, almond milk, sweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "latte, flat white, cappuccino",
    "info-portion-aid": null,
    "info-include-exclude": "with syrup/sugar",
    "search-text": "coffee, almond milk, sweetened latte, flat white, cappuccino",
    "weight-g": 240,
    "volume-ml": null,
    "calories": 70,
    "fat": 1.9,
    "saturated": 0,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 135,
    "carbs": 9.6,
    "fibre": 0.4,
    "sugar": 8.4,
    "protein": 1.5,
    "potassium": null,
    "calcium": 93.5,
    "iron": null,
    "phosphorus": null,
    "info": "with syrup/sugar, e.g. latte, flat white, cappuccino"
  },
  {
    "id": 970,
    "disabled": null,
    "type": "drink",
    "name": "Coffee, almond milk, unsweetened",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "latte, flat white, cappuccino",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "coffee, almond milk, unsweetened latte, flat white, cappuccino",
    "weight-g": 240,
    "volume-ml": null,
    "calories": 28,
    "fat": 1.9,
    "saturated": 0,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 135,
    "carbs": 1.2,
    "fibre": 0.4,
    "sugar": 0,
    "protein": 1.5,
    "potassium": null,
    "calcium": 93.5,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. latte, flat white, cappuccino"
  },
  {
    "id": 1,
    "disabled": null,
    "type": "drink",
    "name": "Coffee, black",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "espresso, cold brew coffee",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "coffee, black espresso, cold brew coffee",
    "weight-g": null,
    "volume-ml": 248,
    "calories": 4.7,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 2.5,
    "carbs": 0.4,
    "fibre": 0,
    "sugar": 0,
    "protein": 0.7,
    "potassium": 124,
    "calcium": 5,
    "iron": 0.1,
    "phosphorus": 7.4,
    "info": "e.g. espresso, cold brew coffee"
  },
  {
    "id": 157,
    "disabled": null,
    "type": "drink",
    "name": "Coffee, fresh milk, sweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "latte, flat white, cappuccino",
    "info-portion-aid": null,
    "info-include-exclude": "with syrup/sugar",
    "search-text": "coffee, fresh milk, sweetened latte, flat white, cappuccino",
    "weight-g": null,
    "volume-ml": 233,
    "calories": 164,
    "fat": 8.4,
    "saturated": 5.3,
    "polyunsaturated": 0.3,
    "monounsaturated": 2.4,
    "trans": null,
    "cholesterol": 28,
    "sodium": 63,
    "carbs": 11.9,
    "fibre": 0,
    "sugar": 16.9,
    "protein": 5.1,
    "potassium": 375,
    "calcium": 212,
    "iron": 0.2,
    "phosphorus": 137,
    "info": "with syrup/sugar, e.g. latte, flat white, cappuccino"
  },
  {
    "id": 156,
    "disabled": null,
    "type": "drink",
    "name": "Coffee, fresh milk, unsweetened",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "latte, flat white, cappuccino",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "coffee, fresh milk, unsweetened latte, flat white, cappuccino",
    "weight-g": null,
    "volume-ml": 233,
    "calories": 124,
    "fat": 8.4,
    "saturated": 5.3,
    "polyunsaturated": 0.7,
    "monounsaturated": 2.4,
    "trans": null,
    "cholesterol": 28,
    "sodium": 63,
    "carbs": 7,
    "fibre": 0,
    "sugar": 7,
    "protein": 5.1,
    "potassium": 375,
    "calcium": 212,
    "iron": 0.2,
    "phosphorus": 137,
    "info": "e.g. latte, flat white, cappuccino"
  },
  {
    "id": 967,
    "disabled": null,
    "type": "drink",
    "name": "Coffee, low-fat milk, sweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "skinny latte, flat white, cappuccino",
    "info-portion-aid": null,
    "info-include-exclude": "with syrup/sugar",
    "search-text": "coffee, low-fat milk, sweetened skinny latte, flat white, cappuccino",
    "weight-g": 225,
    "volume-ml": null,
    "calories": 130,
    "fat": 4.5,
    "saturated": 3,
    "polyunsaturated": 0.2,
    "monounsaturated": 1.2,
    "trans": null,
    "cholesterol": 25,
    "sodium": 90,
    "carbs": 10.3,
    "fibre": 0,
    "sugar": 15.4,
    "protein": 6.8,
    "potassium": 425,
    "calcium": 286,
    "iron": 0,
    "phosphorus": 189,
    "info": "with syrup/sugar, e.g. skinny latte, flat white, cappuccino"
  },
  {
    "id": 966,
    "disabled": null,
    "type": "drink",
    "name": "Coffee, low-fat milk, unsweetened",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "skinny latte, flat white, cappuccino",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "coffee, low-fat milk, unsweetened skinny latte, flat white, cappuccino",
    "weight-g": 211,
    "volume-ml": null,
    "calories": 89,
    "fat": 2.5,
    "saturated": 1.9,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.5,
    "trans": null,
    "cholesterol": 8,
    "sodium": 82,
    "carbs": 9.6,
    "fibre": null,
    "sugar": 9,
    "protein": 6.8,
    "potassium": 334,
    "calcium": 244,
    "iron": null,
    "phosphorus": 206,
    "info": "e.g. skinny latte, flat white, cappuccino"
  },
  {
    "id": 973,
    "disabled": null,
    "type": "drink",
    "name": "Coffee, oat milk, sweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "latte, flat white, cappuccino",
    "info-portion-aid": null,
    "info-include-exclude": "with syrup/sugar",
    "search-text": "coffee, oat milk, sweetened latte, flat white, cappuccino",
    "weight-g": 8.4,
    "volume-ml": null,
    "calories": 124,
    "fat": 0,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 22.5,
    "fibre": 0,
    "sugar": 22.5,
    "protein": 1.4,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "with syrup/sugar, e.g. latte, flat white, cappuccino"
  },
  {
    "id": 972,
    "disabled": null,
    "type": "drink",
    "name": "Coffee, oat milk, unsweetened",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "latte, flat white, cappuccino",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "coffee, oat milk, unsweetened latte, flat white, cappuccino",
    "weight-g": 240,
    "volume-ml": null,
    "calories": 90,
    "fat": 3,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 14,
    "fibre": null,
    "sugar": 14,
    "protein": 1.4,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. latte, flat white, cappuccino"
  },
  {
    "id": 969,
    "disabled": null,
    "type": "drink",
    "name": "Coffee, soy milk, sweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "latte, flat white, cappuccino",
    "info-portion-aid": null,
    "info-include-exclude": "with syrup/sugar",
    "search-text": "coffee, [soy|soya] milk, sweetened latte, flat white, cappuccino",
    "weight-g": 247,
    "volume-ml": null,
    "calories": 103,
    "fat": 2.5,
    "saturated": 0.4,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 65,
    "carbs": 14.4,
    "fibre": 1,
    "sugar": 12.4,
    "protein": 5,
    "potassium": null,
    "calcium": 472,
    "iron": 1,
    "phosphorus": null,
    "info": "with syrup/sugar, e.g. latte, flat white, cappuccino"
  },
  {
    "id": 968,
    "disabled": null,
    "type": "drink",
    "name": "Coffee, soy milk, unsweetened",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "latte, flat white, cappuccino",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "coffee, [soy|soya] milk, unsweetened latte, flat white, cappuccino",
    "weight-g": 236,
    "volume-ml": null,
    "calories": 70,
    "fat": 2.5,
    "saturated": 0.4,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 65,
    "carbs": 6,
    "fibre": 1,
    "sugar": 4,
    "protein": 5,
    "potassium": null,
    "calcium": 472,
    "iron": 1,
    "phosphorus": null,
    "info": "e.g. latte, flat white, cappuccino"
  },
  {
    "id": 574,
    "disabled": null,
    "type": "drink",
    "name": "Coffee, with flavored syrup",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "caramel latte, mocha",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "coffee, with flavored syrup caramel latte, mocha",
    "weight-g": null,
    "volume-ml": 355,
    "calories": 263,
    "fat": 3,
    "saturated": 2.5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 10,
    "sodium": 280,
    "carbs": 46,
    "fibre": 0,
    "sugar": 40,
    "protein": 13,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. caramel latte, mocha"
  },
  {
    "id": 867,
    "disabled": null,
    "type": "meal",
    "name": "Cold soba",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "chasoba",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cold soba chasoba",
    "weight-g": 275,
    "volume-ml": null,
    "calories": 171,
    "fat": 1.9,
    "saturated": 0.8,
    "polyunsaturated": 0.3,
    "monounsaturated": 0.8,
    "trans": null,
    "cholesterol": 0,
    "sodium": 558,
    "carbs": 28.1,
    "fibre": 2.8,
    "sugar": 3,
    "protein": 9.9,
    "potassium": 83,
    "calcium": 44,
    "iron": 2.5,
    "phosphorus": 77,
    "info": "e.g. chasoba"
  },
  {
    "id": 257,
    "disabled": null,
    "type": "meal",
    "name": "Coleslaw",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "coleslaw",
    "weight-g": 97,
    "volume-ml": null,
    "calories": 138,
    "fat": 6,
    "saturated": 0.7,
    "polyunsaturated": 3.8,
    "monounsaturated": 1.6,
    "trans": null,
    "cholesterol": 0,
    "sodium": 260,
    "carbs": 20,
    "fibre": 3,
    "sugar": 0,
    "protein": 1,
    "potassium": 200,
    "calcium": 33,
    "iron": 0.7,
    "phosphorus": 30,
    "info": null
  },
  {
    "id": 604,
    "disabled": null,
    "type": "snack",
    "name": "Cookie, large",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": "Subway, Pepperidge Farm",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cookie, large subway, pepperidge farm",
    "weight-g": 45,
    "volume-ml": null,
    "calories": 217.2,
    "fat": 10,
    "saturated": 5.6,
    "polyunsaturated": 0.6,
    "monounsaturated": 2.6,
    "trans": null,
    "cholesterol": 15,
    "sodium": 150,
    "carbs": 30,
    "fibre": 1,
    "sugar": 18,
    "protein": 1.8,
    "potassium": 67,
    "calcium": 16,
    "iron": 1.2,
    "phosphorus": 43,
    "info": "e.g. Subway, Pepperidge Farm"
  },
  {
    "id": 1090,
    "disabled": null,
    "type": "snack",
    "name": "Cookie, mini",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 4,
    "unit": "piece",
    "info-translation": null,
    "info-brands": "Famous Amos",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cookie, mini famous amos",
    "weight-g": 20,
    "volume-ml": null,
    "calories": 150,
    "fat": 7,
    "saturated": 3,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 5,
    "sodium": 105,
    "carbs": 20,
    "fibre": 0.5,
    "sugar": 9,
    "protein": 1,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Famous Amos"
  },
  {
    "id": 21,
    "disabled": null,
    "type": "snack",
    "name": "Cookie, regular",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 3,
    "unit": "piece",
    "info-translation": null,
    "info-brands": "Chips More, Oreo",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cookie, regular chips more, oreo",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 147.7,
    "fat": 6.8,
    "saturated": 2.2,
    "polyunsaturated": 0.7,
    "monounsaturated": 3.5,
    "trans": null,
    "cholesterol": 0,
    "sodium": 94.5,
    "carbs": 20,
    "fibre": 0.8,
    "sugar": null,
    "protein": 1.6,
    "potassium": 40.5,
    "calcium": 7.5,
    "iron": 0.3,
    "phosphorus": 34.4,
    "info": "e.g. Chips More, Oreo"
  },
  {
    "id": 1045,
    "disabled": null,
    "type": "snack",
    "name": "Cookies and cream cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cookies and cream cake",
    "weight-g": 147,
    "volume-ml": null,
    "calories": 449,
    "fat": 31.9,
    "saturated": 15.6,
    "polyunsaturated": 0.9,
    "monounsaturated": 5.4,
    "trans": 0.4,
    "cholesterol": 92,
    "sodium": 428,
    "carbs": 54.8,
    "fibre": 2.2,
    "sugar": 29.7,
    "protein": 6.3,
    "potassium": 179,
    "calcium": 79,
    "iron": 1,
    "phosphorus": 232,
    "info": null
  },
  {
    "id": 1065,
    "disabled": null,
    "type": "snack",
    "name": "Corn cup",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "corn cup",
    "weight-g": 85,
    "volume-ml": null,
    "calories": 59.8,
    "fat": 1,
    "saturated": 0.3,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 160,
    "carbs": 11,
    "fibre": 4,
    "sugar": 0,
    "protein": 1.7,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 258,
    "disabled": 1,
    "type": "meal",
    "name": "Corn on the cob, grilled",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cob",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "corn on the cob, grilled",
    "weight-g": 161,
    "volume-ml": null,
    "calories": 129.5,
    "fat": 4.7,
    "saturated": 0.9,
    "polyunsaturated": 2.1,
    "monounsaturated": 1.4,
    "trans": null,
    "cholesterol": 0,
    "sodium": 43,
    "carbs": 19.1,
    "fibre": 2.3,
    "sugar": 0,
    "protein": 2.7,
    "potassium": 239,
    "calcium": 4,
    "iron": 0.6,
    "phosphorus": 71,
    "info": null
  },
  {
    "id": 440,
    "disabled": null,
    "type": "snack",
    "name": "Corn on the cob, grilled / buttered and salted",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cob",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "corn on the cob, grilled / buttered and salted",
    "weight-g": 161,
    "volume-ml": null,
    "calories": 115,
    "fat": 4.7,
    "saturated": 0.9,
    "polyunsaturated": 2.1,
    "monounsaturated": 1.4,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 100,
    "carbs": 19.1,
    "fibre": 2.3,
    "sugar": 0,
    "protein": 2.7,
    "potassium": 239,
    "calcium": 4,
    "iron": 0.6,
    "phosphorus": 71,
    "info": null
  },
  {
    "id": 1091,
    "disabled": null,
    "type": "meal",
    "name": "Corn on the cob, boiled / steamed",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cob",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "corn on the cob, boiled / steamed",
    "weight-g": 156,
    "volume-ml": null,
    "calories": 80,
    "fat": 0.6,
    "saturated": 0.1,
    "polyunsaturated": 0.3,
    "monounsaturated": 0.2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 4,
    "carbs": 19.1,
    "fibre": 2.3,
    "sugar": 0,
    "protein": 2.6,
    "potassium": 238,
    "calcium": 3,
    "iron": 0.6,
    "phosphorus": 71,
    "info": null
  },
  {
    "id": 735,
    "disabled": null,
    "type": "snack",
    "name": "Crab nugget, fried",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "stick",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "crab nugget, fried",
    "weight-g": 60,
    "volume-ml": null,
    "calories": 171,
    "fat": 7,
    "saturated": 3.5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 668,
    "carbs": 20,
    "fibre": 0,
    "sugar": 3,
    "protein": 7,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 731,
    "disabled": null,
    "type": "snack",
    "name": "Crab stick, boiled",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "crab stick, boiled",
    "weight-g": 32,
    "volume-ml": null,
    "calories": 31.3,
    "fat": 0.1,
    "saturated": 0.2,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 218,
    "carbs": 4,
    "fibre": 0.6,
    "sugar": 0,
    "protein": 3.6,
    "potassium": null,
    "calcium": 42,
    "iron": 0.2,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 727,
    "disabled": null,
    "type": "meal",
    "name": "Crab, black pepper",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "1 pincer and 1 leg",
    "info-include-exclude": null,
    "search-text": "crab, black pepper",
    "weight-g": 133,
    "volume-ml": null,
    "calories": 173,
    "fat": 8.2,
    "saturated": 3,
    "polyunsaturated": 0.5,
    "monounsaturated": 2.2,
    "trans": null,
    "cholesterol": 115,
    "sodium": 1165,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 24.8,
    "potassium": 481,
    "calcium": 592,
    "iron": 2.7,
    "phosphorus": 471,
    "info": "(1 pincer and 1 leg)"
  },
  {
    "id": 729,
    "disabled": null,
    "type": "meal",
    "name": "Crab, butter",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "1 pincer and 1 leg",
    "info-include-exclude": null,
    "search-text": "crab, butter",
    "weight-g": 144,
    "volume-ml": null,
    "calories": 321.6,
    "fat": 28.4,
    "saturated": 6.8,
    "polyunsaturated": 1.3,
    "monounsaturated": 5.7,
    "trans": 0.3,
    "cholesterol": 63,
    "sodium": 672,
    "carbs": 6.4,
    "fibre": 0,
    "sugar": 0.2,
    "protein": 10.2,
    "potassium": 249,
    "calcium": 191,
    "iron": 1.2,
    "phosphorus": 241.5,
    "info": "(1 pincer and 1 leg)"
  },
  {
    "id": 254,
    "disabled": null,
    "type": "meal",
    "name": "Crab, chilli",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "1 pincer and 1 leg",
    "info-include-exclude": null,
    "search-text": "crab, chilli",
    "weight-g": 198,
    "volume-ml": null,
    "calories": 141,
    "fat": 8.5,
    "saturated": 3.9,
    "polyunsaturated": 1.1,
    "monounsaturated": 3.1,
    "trans": null,
    "cholesterol": 147,
    "sodium": 534,
    "carbs": 4.9,
    "fibre": 0,
    "sugar": 3,
    "protein": 11.3,
    "potassium": null,
    "calcium": 183,
    "iron": 2.5,
    "phosphorus": null,
    "info": "(1 pincer and 1 leg)"
  },
  {
    "id": 728,
    "disabled": null,
    "type": "meal",
    "name": "Crab, salted egg yolk",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "1 pincer and 1 leg",
    "info-include-exclude": null,
    "search-text": "crab, salted egg yolk",
    "weight-g": 152,
    "volume-ml": null,
    "calories": 410.4,
    "fat": 37.4,
    "saturated": 8.3,
    "polyunsaturated": 1.7,
    "monounsaturated": 8,
    "trans": 0.3,
    "cholesterol": 242,
    "sodium": 698,
    "carbs": 6.4,
    "fibre": 0,
    "sugar": 0.2,
    "protein": 12.1,
    "potassium": 255.5,
    "calcium": 207,
    "iron": 1.9,
    "phosphorus": 299,
    "info": "(1 pincer and 1 leg)"
  },
  {
    "id": 730,
    "disabled": null,
    "type": "meal",
    "name": "Crab, steamed",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "1 pincer and 1 leg",
    "info-include-exclude": null,
    "search-text": "crab, steamed",
    "weight-g": 119,
    "volume-ml": null,
    "calories": 54.9,
    "fat": 2.1,
    "saturated": 0.4,
    "polyunsaturated": 0,
    "monounsaturated": 0.3,
    "trans": null,
    "cholesterol": 51.5,
    "sodium": 240.5,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 9,
    "potassium": 151,
    "calcium": 81.5,
    "iron": 0.4,
    "phosphorus": 123.5,
    "info": "(1 pincer and 1 leg)"
  },
  {
    "id": 1092,
    "disabled": null,
    "type": "snack",
    "name": "Cranberries, raw",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cranberries, raw",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 46,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": null,
    "sodium": 2,
    "carbs": 12,
    "fibre": 3.6,
    "sugar": 4.3,
    "protein": 0.5,
    "potassium": 80,
    "calcium": 8,
    "iron": 0.2,
    "phosphorus": 11,
    "info": null
  },
  {
    "id": 710,
    "disabled": null,
    "type": "snack",
    "name": "Cranberries, dried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cranberries, dried",
    "weight-g": 15,
    "volume-ml": null,
    "calories": 48,
    "fat": 0.2,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.1,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 12.7,
    "fibre": 0.9,
    "sugar": 11.2,
    "protein": 0,
    "potassium": 9,
    "calcium": 0,
    "iron": 0.1,
    "phosphorus": 0,
    "info": null
  },
  {
    "id": 548,
    "disabled": null,
    "type": "snack",
    "name": "Cream cheese",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cream cheese",
    "weight-g": 14.5,
    "volume-ml": null,
    "calories": 51.7,
    "fat": 5,
    "saturated": 2.9,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 14.6,
    "sodium": 45.5,
    "carbs": 0.8,
    "fibre": 0,
    "sugar": 0.5,
    "protein": 0.9,
    "potassium": 19.1,
    "calcium": 14.1,
    "iron": 0,
    "phosphorus": 15.5,
    "info": null
  },
  {
    "id": 84,
    "disabled": null,
    "type": "snack",
    "name": "Cream / custard puff",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": "Beard Papa",
    "info-examples": null,
    "info-portion-aid": "large",
    "info-include-exclude": null,
    "search-text": "cream / custard puff beard papa",
    "weight-g": 64,
    "volume-ml": null,
    "calories": 216,
    "fat": 15.9,
    "saturated": 9.7,
    "polyunsaturated": 0.7,
    "monounsaturated": 4.9,
    "trans": null,
    "cholesterol": 90,
    "sodium": 112,
    "carbs": 14.5,
    "fibre": 1.1,
    "sugar": 4.4,
    "protein": 3.7,
    "potassium": 58,
    "calcium": 52,
    "iron": 0.5,
    "phosphorus": 70,
    "info": "e.g. Beard Papa (large)"
  },
  {
    "id": 231,
    "disabled": null,
    "type": "snack",
    "name": "Cream / custard puff",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "small",
    "info-include-exclude": null,
    "search-text": "cream / custard puff",
    "weight-g": 34,
    "volume-ml": null,
    "calories": 79.4,
    "fat": 5,
    "saturated": 3,
    "polyunsaturated": 0.3,
    "monounsaturated": 1.5,
    "trans": null,
    "cholesterol": 23.5,
    "sodium": 71,
    "carbs": 7.1,
    "fibre": 0.8,
    "sugar": 4,
    "protein": 1.5,
    "potassium": 45,
    "calcium": 15,
    "iron": 0.2,
    "phosphorus": 25,
    "info": "(small)"
  },
  {
    "id": 833,
    "disabled": null,
    "type": "meal",
    "name": "Cream soup with meat or seafood",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "clam chowder, creamy chicken",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cream soup with meat or seafood clam chowder, creamy chicken",
    "weight-g": 350,
    "volume-ml": null,
    "calories": 315,
    "fat": 20,
    "saturated": 6,
    "polyunsaturated": 3.7,
    "monounsaturated": 7.2,
    "trans": null,
    "cholesterol": 28,
    "sodium": 2461,
    "carbs": 25,
    "fibre": 0,
    "sugar": 1.9,
    "protein": 8.3,
    "potassium": 171,
    "calcium": 53,
    "iron": 3.7,
    "phosphorus": 109,
    "info": "e.g. clam chowder, creamy chicken"
  },
  {
    "id": 832,
    "disabled": null,
    "type": "meal",
    "name": "Cream soup with vegetables",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cream soup with vegetables",
    "weight-g": 350,
    "volume-ml": null,
    "calories": 230,
    "fat": 12.6,
    "saturated": 3.9,
    "polyunsaturated": 5.1,
    "monounsaturated": 3.1,
    "trans": 0.2,
    "cholesterol": 14,
    "sodium": 1260,
    "carbs": 20,
    "fibre": 1.1,
    "sugar": 10,
    "protein": 8.3,
    "potassium": 364,
    "calcium": 239,
    "iron": 0.4,
    "phosphorus": 209,
    "info": null
  },
  {
    "id": 32,
    "disabled": null,
    "type": "snack",
    "name": "Creme brulee",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "creme brulee",
    "weight-g": 226,
    "volume-ml": null,
    "calories": 343,
    "fat": 27,
    "saturated": 17,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 30,
    "carbs": 22,
    "fibre": 0,
    "sugar": 15,
    "protein": 3,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 502,
    "disabled": null,
    "type": "snack",
    "name": "Crepe suzette",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "crepe",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "crepe suzette",
    "weight-g": 32,
    "volume-ml": null,
    "calories": 140,
    "fat": 5,
    "saturated": 2,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 15,
    "sodium": 130,
    "carbs": 19,
    "fibre": 1,
    "sugar": 12,
    "protein": 2,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 787,
    "disabled": null,
    "type": "meal",
    "name": "Crispy noodle in egg gravy",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "crispy noodle in egg gravy",
    "weight-g": 408,
    "volume-ml": null,
    "calories": 692.7,
    "fat": 37.1,
    "saturated": 14.3,
    "polyunsaturated": 5.3,
    "monounsaturated": 15.9,
    "trans": null,
    "cholesterol": 257,
    "sodium": 1563,
    "carbs": 62.8,
    "fibre": 8.2,
    "sugar": 0.8,
    "protein": 26.9,
    "potassium": 437,
    "calcium": 196,
    "iron": 3.7,
    "phosphorus": 302,
    "info": null
  },
  {
    "id": 490,
    "disabled": null,
    "type": "snack",
    "name": "Crispy peanut or red bean pancake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": "Jollibean, Mr Bean",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "crispy peanut or red bean pancake jollibean, mr bean",
    "weight-g": 115,
    "volume-ml": null,
    "calories": 448,
    "fat": 28.9,
    "saturated": 10,
    "polyunsaturated": 6.2,
    "monounsaturated": 11.5,
    "trans": null,
    "cholesterol": 34,
    "sodium": 227,
    "carbs": 32.6,
    "fibre": 2.6,
    "sugar": 10,
    "protein": 15,
    "potassium": 138,
    "calcium": 78,
    "iron": 0.4,
    "phosphorus": 138,
    "info": "e.g. Jollibean, Mr Bean"
  },
  {
    "id": 610,
    "disabled": null,
    "type": "meal",
    "name": "Rye bread",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "rye bread",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 78.9,
    "fat": 0.7,
    "saturated": 0.1,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 119,
    "carbs": 15.1,
    "fibre": 0.1,
    "sugar": 0.6,
    "protein": 3.2,
    "potassium": 29,
    "calcium": 15.5,
    "iron": 1,
    "phosphorus": 43,
    "info": null
  },
  {
    "id": 825,
    "disabled": null,
    "type": "meal",
    "name": "Croissant sandwich with meat fillings",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "ham, bacon, chicken",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "croissant sandwich with meat fillings ham, bacon, chicken",
    "weight-g": 150,
    "volume-ml": null,
    "calories": 452,
    "fat": 29.3,
    "saturated": 10.6,
    "polyunsaturated": 9.1,
    "monounsaturated": 9.2,
    "trans": 0,
    "cholesterol": 28,
    "sodium": 647,
    "carbs": 27.4,
    "fibre": 2,
    "sugar": 6.4,
    "protein": 20.3,
    "potassium": 255,
    "calcium": 42,
    "iron": 1.2,
    "phosphorus": 178,
    "info": "e.g. ham, bacon, chicken"
  },
  {
    "id": 889,
    "disabled": null,
    "type": "meal",
    "name": "Croissant with savoury fillings",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "egg, cheese, tuna",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "croissant with savoury fillings egg, cheese, tuna",
    "weight-g": 113,
    "volume-ml": null,
    "calories": 368.7,
    "fat": 23.1,
    "saturated": 10.5,
    "polyunsaturated": 2.3,
    "monounsaturated": 7.7,
    "trans": null,
    "cholesterol": 254,
    "sodium": 542,
    "carbs": 25,
    "fibre": 1.4,
    "sugar": 6.6,
    "protein": 15.2,
    "potassium": 194,
    "calcium": 48.6,
    "iron": 2.4,
    "phosphorus": 220,
    "info": "e.g. egg, cheese, tuna"
  },
  {
    "id": 888,
    "disabled": null,
    "type": "meal",
    "name": "Croissant with sweet fillings",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "almond, chocolate, matcha",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "croissant with sweet fillings almond, chocolate, matcha",
    "weight-g": 84,
    "volume-ml": null,
    "calories": 379.2,
    "fat": 24,
    "saturated": 10.8,
    "polyunsaturated": 2.3,
    "monounsaturated": 9.8,
    "trans": null,
    "cholesterol": 45,
    "sodium": 255,
    "carbs": 33,
    "fibre": 2.1,
    "sugar": 14.4,
    "protein": 7.8,
    "potassium": 132,
    "calcium": 66,
    "iron": 1.1,
    "phosphorus": 106,
    "info": "e.g. almond, chocolate, matcha"
  },
  {
    "id": 102,
    "disabled": null,
    "type": "meal",
    "name": "Croissant, plain",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "croissant, plain",
    "weight-g": 65,
    "volume-ml": null,
    "calories": 256.1,
    "fat": 15.3,
    "saturated": 8.5,
    "polyunsaturated": 0.9,
    "monounsaturated": 5.4,
    "trans": null,
    "cholesterol": 38.2,
    "sodium": 241,
    "carbs": 23.1,
    "fibre": 1.9,
    "sugar": 3.3,
    "protein": 6.5,
    "potassium": 85,
    "calcium": 34,
    "iron": 0.7,
    "phosphorus": 59,
    "info": null
  },
  {
    "id": 103,
    "disabled": null,
    "type": "meal",
    "name": "Croquette",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Fried potato cake",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "croquette fried potato cake",
    "weight-g": 84,
    "volume-ml": null,
    "calories": 213.5,
    "fat": 15.9,
    "saturated": 7.7,
    "polyunsaturated": 1.6,
    "monounsaturated": 5.8,
    "trans": null,
    "cholesterol": 38,
    "sodium": 162,
    "carbs": 15,
    "fibre": 1,
    "sugar": 1.2,
    "protein": 2.6,
    "potassium": 184,
    "calcium": 27,
    "iron": 0.5,
    "phosphorus": 51,
    "info": "Fried potato cake"
  },
  {
    "id": 916,
    "disabled": null,
    "type": "meal",
    "name": "Crystal dumpling, steamed",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Shui Jing Bao",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "crystal dumpling, steamed [shui|sui] jing [bao|pau|pao]",
    "weight-g": 42,
    "volume-ml": null,
    "calories": 48,
    "fat": 0.8,
    "saturated": 0.8,
    "polyunsaturated": 0.3,
    "monounsaturated": 0.8,
    "trans": null,
    "cholesterol": 5,
    "sodium": 172,
    "carbs": 9.2,
    "fibre": 1.1,
    "sugar": 1.5,
    "protein": 1,
    "potassium": 26,
    "calcium": 12,
    "iron": 0.3,
    "phosphorus": 13,
    "info": "Shui Jing Bao"
  },
  {
    "id": 634,
    "disabled": null,
    "type": "meal",
    "name": "Cucumber, raw",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "medium",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cucumber, raw",
    "weight-g": 200,
    "volume-ml": null,
    "calories": 31.3,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 26,
    "carbs": 6.8,
    "fibre": 1,
    "sugar": 6.8,
    "protein": 1,
    "potassium": 152,
    "calcium": 28,
    "iron": 0.4,
    "phosphorus": 42,
    "info": null
  },
  {
    "id": 617,
    "disabled": null,
    "type": "drink",
    "name": "Cultured milk bottle drinks (low sugar)",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": 1,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bottle",
    "info-translation": null,
    "info-brands": "Yakult Ace Light, Vitagen less sugar",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cultured milk bottle drinks (low sugar) yakult ace light, vitagen less sugar",
    "weight-g": null,
    "volume-ml": 80,
    "calories": 46,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 16,
    "carbs": 10.6,
    "fibre": 0,
    "sugar": 6.8,
    "protein": 0.9,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Yakult Ace Light, Vitagen less sugar"
  },
  {
    "id": 616,
    "disabled": null,
    "type": "drink",
    "name": "Cultured milk bottle drinks (original)",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": 1,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bottle",
    "info-translation": null,
    "info-brands": "Yakult, Vitagen",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cultured milk bottle drinks (original) yakult, vitagen",
    "weight-g": null,
    "volume-ml": 80,
    "calories": 52,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": null,
    "carbs": 12,
    "fibre": 0,
    "sugar": 11,
    "protein": 1,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Yakult, Vitagen"
  },
  {
    "id": 26,
    "disabled": null,
    "type": "snack",
    "name": "Cupcake, without frosting",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cupcake, without frosting",
    "weight-g": 55,
    "volume-ml": null,
    "calories": 207.5,
    "fat": 9.9,
    "saturated": 6.1,
    "polyunsaturated": 0.4,
    "monounsaturated": 2.7,
    "trans": null,
    "cholesterol": 58,
    "sodium": 275,
    "carbs": 25.8,
    "fibre": 0.8,
    "sugar": 10.7,
    "protein": 3.8,
    "potassium": 84,
    "calcium": 42,
    "iron": 0.7,
    "phosphorus": 211,
    "info": null
  },
  {
    "id": 842,
    "disabled": null,
    "type": "meal",
    "name": "Curry baked rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "curry baked rice",
    "weight-g": 340,
    "volume-ml": null,
    "calories": 564,
    "fat": 23.1,
    "saturated": 10.2,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 27,
    "sodium": 870,
    "carbs": 69,
    "fibre": 6.1,
    "sugar": 6.8,
    "protein": 20.4,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 449,
    "disabled": null,
    "type": "meal",
    "name": "Curry chicken noodles",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "curry chicken noodles",
    "weight-g": 532,
    "volume-ml": null,
    "calories": 696,
    "fat": 44.8,
    "saturated": 21.7,
    "polyunsaturated": 5.4,
    "monounsaturated": 15.6,
    "trans": null,
    "cholesterol": 87,
    "sodium": 2219,
    "carbs": 41.6,
    "fibre": 6.4,
    "sugar": 0.5,
    "protein": 31.6,
    "potassium": 202,
    "calcium": 160,
    "iron": 2.8,
    "phosphorus": 202,
    "info": null
  },
  {
    "id": 448,
    "disabled": null,
    "type": "meal",
    "name": "Curry chicken rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "curry chicken rice",
    "weight-g": 400,
    "volume-ml": null,
    "calories": 553.6,
    "fat": 18.4,
    "saturated": 7.7,
    "polyunsaturated": 3.2,
    "monounsaturated": 6.3,
    "trans": null,
    "cholesterol": 47,
    "sodium": 498,
    "carbs": 76.6,
    "fibre": 6.2,
    "sugar": 4.1,
    "protein": 20.4,
    "potassium": 249,
    "calcium": 28,
    "iron": 5.1,
    "phosphorus": 171,
    "info": null
  },
  {
    "id": 44,
    "disabled": null,
    "type": "snack",
    "name": "Curry puff, fried",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "potato",
    "info-portion-aid": "regular",
    "info-include-exclude": null,
    "search-text": "curry puff, fried potato",
    "weight-g": 138,
    "volume-ml": null,
    "calories": 400.7,
    "fat": 21.9,
    "saturated": 10.7,
    "polyunsaturated": 1.5,
    "monounsaturated": 8.7,
    "trans": null,
    "cholesterol": 22,
    "sodium": 800,
    "carbs": 42.5,
    "fibre": 3.7,
    "sugar": 2.2,
    "protein": 8.4,
    "potassium": 393,
    "calcium": 35,
    "iron": 2.2,
    "phosphorus": 93,
    "info": "e.g. potato (regular)"
  },
  {
    "id": 200,
    "disabled": null,
    "type": "snack",
    "name": "Curry puff, fried",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "potato",
    "info-portion-aid": "small",
    "info-include-exclude": null,
    "search-text": "curry puff, fried potato",
    "weight-g": 40,
    "volume-ml": null,
    "calories": 131.3,
    "fat": 8.1,
    "saturated": 3.8,
    "polyunsaturated": 0.7,
    "monounsaturated": 3.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 168,
    "carbs": 10.6,
    "fibre": 0.6,
    "sugar": 4.2,
    "protein": 4,
    "potassium": 53,
    "calcium": 6,
    "iron": 0.3,
    "phosphorus": 23,
    "info": "e.g. potato (small)"
  },
  {
    "id": 259,
    "disabled": null,
    "type": "meal",
    "name": "Curry, beef",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "12cm bowl",
    "info-include-exclude": "with coconut milk",
    "search-text": "curry, beef",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 144,
    "fat": 5.7,
    "saturated": 2.8,
    "polyunsaturated": 0.5,
    "monounsaturated": 2.2,
    "trans": null,
    "cholesterol": 65,
    "sodium": 74,
    "carbs": 8.6,
    "fibre": 1.4,
    "sugar": 7.1,
    "protein": 14.6,
    "potassium": 147,
    "calcium": 22,
    "iron": 2.5,
    "phosphorus": 349,
    "info": "with coconut milk (12cm bowl)"
  },
  {
    "id": 260,
    "disabled": null,
    "type": "meal",
    "name": "Curry, chicken",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "12cm bowl",
    "info-include-exclude": "with coconut milk",
    "search-text": "curry, chicken",
    "weight-g": 150,
    "volume-ml": null,
    "calories": 270,
    "fat": 20,
    "saturated": 7.2,
    "polyunsaturated": 2.9,
    "monounsaturated": 8.8,
    "trans": null,
    "cholesterol": 86,
    "sodium": 245,
    "carbs": 2.3,
    "fibre": 2,
    "sugar": 1.7,
    "protein": 20.3,
    "potassium": 353,
    "calcium": 155,
    "iron": 3.8,
    "phosphorus": 245,
    "info": "with coconut milk (12cm bowl)"
  },
  {
    "id": 261,
    "disabled": null,
    "type": "meal",
    "name": "Curry, fish",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "12cm bowl",
    "info-include-exclude": "with coconut milk",
    "search-text": "curry, fish",
    "weight-g": 150,
    "volume-ml": null,
    "calories": 213,
    "fat": 10.2,
    "saturated": 5.3,
    "polyunsaturated": 1.3,
    "monounsaturated": 2.7,
    "trans": 0.1,
    "cholesterol": 80,
    "sodium": 163,
    "carbs": 2.1,
    "fibre": 1.1,
    "sugar": 1.8,
    "protein": 27.6,
    "potassium": 514,
    "calcium": 47,
    "iron": 1,
    "phosphorus": 273,
    "info": "with coconut milk (12cm bowl)"
  },
  {
    "id": 263,
    "disabled": null,
    "type": "meal",
    "name": "Curry, vegetable",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with coconut milk",
    "search-text": "curry, vegetable",
    "weight-g": 278,
    "volume-ml": null,
    "calories": 159,
    "fat": 12.6,
    "saturated": 8.4,
    "polyunsaturated": 1.9,
    "monounsaturated": 1.7,
    "trans": null,
    "cholesterol": 0,
    "sodium": 625,
    "carbs": 9.3,
    "fibre": 2.6,
    "sugar": 6.7,
    "protein": 2.8,
    "potassium": 393,
    "calcium": 48,
    "iron": 1.1,
    "phosphorus": 72,
    "info": "with coconut milk"
  },
  {
    "id": 716,
    "disabled": null,
    "type": "snack",
    "name": "Custard apple",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "fruit",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "medium",
    "info-include-exclude": null,
    "search-text": "custard apple",
    "weight-g": 145,
    "volume-ml": null,
    "calories": 62.6,
    "fat": 0.2,
    "saturated": 0.1,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": null,
    "sodium": 1,
    "carbs": 13.6,
    "fibre": 2,
    "sugar": 10.9,
    "protein": 1.6,
    "potassium": 224,
    "calcium": 11,
    "iron": 0.4,
    "phosphorus": 41,
    "info": "(medium)"
  },
  {
    "id": 1047,
    "disabled": null,
    "type": "snack",
    "name": "Cuttlefish snack",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "packet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "thin and crispy",
    "search-text": "cuttlefish snack",
    "weight-g": 85,
    "volume-ml": null,
    "calories": 134,
    "fat": 1,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 190,
    "sodium": 632,
    "carbs": 12,
    "fibre": 0,
    "sugar": 12,
    "protein": 15,
    "potassium": 541,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "thin and crispy"
  },
  {
    "id": 1048,
    "disabled": null,
    "type": "snack",
    "name": "Cuttlefish snack",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "packet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "thick and chewy",
    "search-text": "cuttlefish snack",
    "weight-g": 40,
    "volume-ml": null,
    "calories": 141.1,
    "fat": 0.3,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 369,
    "carbs": 19.6,
    "fibre": 0,
    "sugar": 10,
    "protein": 15,
    "potassium": 109,
    "calcium": 20,
    "iron": 1.8,
    "phosphorus": 146,
    "info": "thick and chewy"
  },
  {
    "id": 878,
    "disabled": null,
    "type": "meal",
    "name": "Daenjang",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Korean soya bean paste soup",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[daenjang|doenjang] korean soya bean paste soup",
    "weight-g": 480,
    "volume-ml": null,
    "calories": 154,
    "fat": 7.4,
    "saturated": 1,
    "polyunsaturated": 3,
    "monounsaturated": 2.2,
    "trans": null,
    "cholesterol": 19,
    "sodium": 2920,
    "carbs": 11.9,
    "fibre": 1.9,
    "sugar": 4.3,
    "protein": 11.9,
    "potassium": 254,
    "calcium": 77,
    "iron": 1.6,
    "phosphorus": 139,
    "info": "Korean soya bean paste soup"
  },
  {
    "id": 202,
    "disabled": null,
    "type": "snack",
    "name": "Red / medjool date, pitted",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "Medjool",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "red / medjool date, pitted medjool",
    "weight-g": 24,
    "volume-ml": null,
    "calories": 73.6,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 18,
    "fibre": 1.6,
    "sugar": 16,
    "protein": 0.4,
    "potassium": 167,
    "calcium": 15,
    "iron": 0.2,
    "phosphorus": 15,
    "info": "e.g. Medjool"
  },
  {
    "id": 783,
    "disabled": null,
    "type": "meal",
    "name": "Deep fried tofu with seafood / crabmeat",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "deep fried tofu with seafood / crabmeat",
    "weight-g": 134,
    "volume-ml": null,
    "calories": 246.2,
    "fat": 20.2,
    "saturated": 6,
    "polyunsaturated": 6,
    "monounsaturated": 7.3,
    "trans": null,
    "cholesterol": 58,
    "sodium": 214,
    "carbs": 4.3,
    "fibre": 1.7,
    "sugar": 0.4,
    "protein": 11.8,
    "potassium": 143,
    "calcium": 225,
    "iron": 3.5,
    "phosphorus": 143,
    "info": null
  },
  {
    "id": 828,
    "disabled": null,
    "type": "snack",
    "name": "Dessert pies, fried",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "pie",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "apple, taro",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "dessert pies, fried apple, taro",
    "weight-g": 85,
    "volume-ml": null,
    "calories": 203,
    "fat": 9.5,
    "saturated": 4.5,
    "polyunsaturated": 0.9,
    "monounsaturated": 3.4,
    "trans": null,
    "cholesterol": 12,
    "sodium": 133,
    "carbs": 27.9,
    "fibre": 1.4,
    "sugar": 8,
    "protein": 3.2,
    "potassium": 54,
    "calcium": 11,
    "iron": 1.2,
    "phosphorus": 37,
    "info": "e.g. apple, taro"
  },
  {
    "id": 418,
    "disabled": null,
    "type": "meal",
    "name": "Dhal with coconut milk",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[dhal|dal] with coconut milk",
    "weight-g": 170,
    "volume-ml": null,
    "calories": 190,
    "fat": 11.7,
    "saturated": 8.5,
    "polyunsaturated": 1.2,
    "monounsaturated": 1.4,
    "trans": null,
    "cholesterol": 0,
    "sodium": 329,
    "carbs": 12.2,
    "fibre": 6.5,
    "sugar": 1.2,
    "protein": 9.3,
    "potassium": 553,
    "calcium": 56,
    "iron": 1.8,
    "phosphorus": 179,
    "info": null
  },
  {
    "id": 442,
    "disabled": null,
    "type": "snack",
    "name": "Dhal, without coconut milk",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[dhal|dal], without coconut milk",
    "weight-g": 140,
    "volume-ml": null,
    "calories": 106,
    "fat": 3.2,
    "saturated": 0.9,
    "polyunsaturated": 1.1,
    "monounsaturated": 1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 325,
    "carbs": 11.3,
    "fibre": 6.2,
    "sugar": 0.3,
    "protein": 8.4,
    "potassium": 433,
    "calcium": 49,
    "iron": 1.4,
    "phosphorus": 147,
    "info": null
  },
  {
    "id": 147,
    "disabled": 1,
    "type": "meal",
    "name": "Dim sum (pastry-based, e.g. barbequed pork tart, yam puff)",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": null,
    "unit": null,
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "dim sum (pastry-based, e.g. barbequed pork tart, yam puff)",
    "weight-g": null,
    "volume-ml": null,
    "calories": null,
    "fat": null,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": null,
    "fibre": null,
    "sugar": null,
    "protein": null,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 27,
    "disabled": null,
    "type": "snack",
    "name": "Doughnut",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[doughnut|donut]",
    "weight-g": 42,
    "volume-ml": null,
    "calories": 164.5,
    "fat": 8.5,
    "saturated": 4.2,
    "polyunsaturated": 0.7,
    "monounsaturated": 3.2,
    "trans": 0.1,
    "cholesterol": 8,
    "sodium": 112,
    "carbs": 18.7,
    "fibre": 1,
    "sugar": 5.7,
    "protein": 3.3,
    "potassium": 42,
    "calcium": 15,
    "iron": 0.3,
    "phosphorus": 37,
    "info": null
  },
  {
    "id": 383,
    "disabled": null,
    "type": "snack",
    "name": "Dragonfruit",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "whole",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "dragonfruit",
    "weight-g": 300,
    "volume-ml": null,
    "calories": 106,
    "fat": 0.2,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.1,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 26,
    "carbs": 22.9,
    "fibre": 5.8,
    "sugar": 14.3,
    "protein": 2.8,
    "potassium": 127,
    "calcium": 13,
    "iron": 1.9,
    "phosphorus": 102,
    "info": null
  },
  {
    "id": 41,
    "disabled": null,
    "type": "snack",
    "name": "Dried fruit",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "plum, mango, gauva",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "dried fruit plum, mango, gauva",
    "weight-g": 50,
    "volume-ml": null,
    "calories": 167.4,
    "fat": 0.6,
    "saturated": 0.3,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 81,
    "carbs": 39.3,
    "fibre": 1.2,
    "sugar": 33.1,
    "protein": 1.2,
    "potassium": 139.5,
    "calcium": 0,
    "iron": 0.1,
    "phosphorus": 25,
    "info": "e.g. plum, mango, gauva"
  },
  {
    "id": 717,
    "disabled": null,
    "type": "snack",
    "name": "Raisins",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[raisins|sultanas]",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 95,
    "fat": 0.2,
    "saturated": 0.2,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 6,
    "carbs": 23.1,
    "fibre": 1.8,
    "sugar": 21.4,
    "protein": 0.9,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 794,
    "disabled": null,
    "type": "meal",
    "name": "Dry ramen",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Mazesoba",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "dry ramen mazesoba",
    "weight-g": 150,
    "volume-ml": null,
    "calories": 617.2,
    "fat": 21.6,
    "saturated": 12,
    "polyunsaturated": 3.2,
    "monounsaturated": 9,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1855,
    "carbs": 90.4,
    "fibre": 4.4,
    "sugar": 3,
    "protein": 15.3,
    "potassium": 273,
    "calcium": 31,
    "iron": 6.2,
    "phosphorus": 172.5,
    "info": "Mazesoba"
  },
  {
    "id": 544,
    "disabled": null,
    "type": "meal",
    "name": "Duck, braised, skinless",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "3\" x 3' x 1\"",
    "info-include-exclude": "without skin",
    "search-text": "duck, braised, skinless",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 152,
    "fat": 5.8,
    "saturated": 8.4,
    "polyunsaturated": 2.3,
    "monounsaturated": 7.1,
    "trans": null,
    "cholesterol": 109,
    "sodium": 342,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 24.8,
    "potassium": null,
    "calcium": 20,
    "iron": 3,
    "phosphorus": null,
    "info": "without skin (3\" x 3' x 1\")"
  },
  {
    "id": 265,
    "disabled": null,
    "type": "meal",
    "name": "Duck, roasted",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "3\" x 3' x 1\"",
    "info-include-exclude": null,
    "search-text": "duck, roasted",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 238,
    "fat": 16,
    "saturated": 5.9,
    "polyunsaturated": 2.2,
    "monounsaturated": 7,
    "trans": null,
    "cholesterol": 130,
    "sodium": 208,
    "carbs": 1.5,
    "fibre": 0,
    "sugar": 0,
    "protein": 22.1,
    "potassium": null,
    "calcium": 48,
    "iron": 2.8,
    "phosphorus": null,
    "info": "(3\" x 3' x 1\")"
  },
  {
    "id": 105,
    "disabled": null,
    "type": "meal",
    "name": "Duck, smoked",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "3\" x 3' x 1\"",
    "info-include-exclude": null,
    "search-text": "duck, smoked",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 142,
    "fat": 4.4,
    "saturated": 1.3,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 84,
    "sodium": 1327,
    "carbs": 0.9,
    "fibre": 0,
    "sugar": 0.9,
    "protein": 22,
    "potassium": null,
    "calcium": null,
    "iron": 4.8,
    "phosphorus": null,
    "info": "(3\" x 3' x 1\")"
  },
  {
    "id": 104,
    "disabled": null,
    "type": "meal",
    "name": "Dukbokki",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Korean spicy rice cake",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[dukbokki|tteok-bokki|tteokbokki] korean spicy rice cake",
    "weight-g": 210,
    "volume-ml": null,
    "calories": 317.1,
    "fat": 1.1,
    "saturated": 0.4,
    "polyunsaturated": 0.4,
    "monounsaturated": 0.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 893,
    "carbs": 69.9,
    "fibre": 0.6,
    "sugar": 9.7,
    "protein": 6.9,
    "potassium": 176,
    "calcium": 27,
    "iron": 0.8,
    "phosphorus": 120,
    "info": "Korean spicy rice cake"
  },
  {
    "id": 384,
    "disabled": null,
    "type": "snack",
    "name": "Duku",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 5,
    "unit": "piece",
    "info-translation": "Langsat",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "duku langsat",
    "weight-g": 70,
    "volume-ml": null,
    "calories": 15.6,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 5.4,
    "carbs": 3.7,
    "fibre": 0.8,
    "sugar": 3.7,
    "protein": 0.2,
    "potassium": 103.1,
    "calcium": 4.6,
    "iron": 0.5,
    "phosphorus": 8.8,
    "info": "Langsat"
  },
  {
    "id": 919,
    "disabled": null,
    "type": "meal",
    "name": "Dumplings with minced meat filling, steamed",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "dumplings with minced meat filling, steamed",
    "weight-g": 31,
    "volume-ml": null,
    "calories": 68.5,
    "fat": 3.7,
    "saturated": 1.2,
    "polyunsaturated": 0.7,
    "monounsaturated": 1.5,
    "trans": null,
    "cholesterol": 13,
    "sodium": 97,
    "carbs": 6.2,
    "fibre": 0.5,
    "sugar": 0.6,
    "protein": 2.6,
    "potassium": 45,
    "calcium": 6,
    "iron": 0.2,
    "phosphorus": 23,
    "info": null
  },
  {
    "id": 182,
    "disabled": null,
    "type": "snack",
    "name": "Durian",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "seed",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "durian",
    "weight-g": 41,
    "volume-ml": null,
    "calories": 66,
    "fat": 1.2,
    "saturated": 0.4,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.5,
    "trans": null,
    "cholesterol": 0,
    "sodium": 3,
    "carbs": 12.8,
    "fibre": 1.3,
    "sugar": 9.8,
    "protein": 1,
    "potassium": 22,
    "calcium": 2,
    "iron": 0.3,
    "phosphorus": 18,
    "info": null
  },
  {
    "id": 1039,
    "disabled": null,
    "type": "snack",
    "name": "Durian cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "durian cake",
    "weight-g": 128,
    "volume-ml": null,
    "calories": 346,
    "fat": 16,
    "saturated": 13.3,
    "polyunsaturated": 2.3,
    "monounsaturated": 3.7,
    "trans": null,
    "cholesterol": 58,
    "sodium": 154,
    "carbs": 45,
    "fibre": 2.3,
    "sugar": 35,
    "protein": 5.5,
    "potassium": 191,
    "calcium": 36,
    "iron": 1.2,
    "phosphorus": 119,
    "info": null
  },
  {
    "id": 1007,
    "disabled": null,
    "type": "snack",
    "name": "Durian mochi",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "durian mochi",
    "weight-g": 35,
    "volume-ml": null,
    "calories": 164,
    "fat": 4,
    "saturated": 2,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 13,
    "carbs": 29,
    "fibre": 0,
    "sugar": 21,
    "protein": 3,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 59,
    "disabled": null,
    "type": "snack",
    "name": "Durian puff",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "durian puff",
    "weight-g": 31,
    "volume-ml": null,
    "calories": 79.4,
    "fat": 5,
    "saturated": 3,
    "polyunsaturated": 0.3,
    "monounsaturated": 1.5,
    "trans": null,
    "cholesterol": 23.5,
    "sodium": 71,
    "carbs": 7.1,
    "fibre": 0.8,
    "sugar": 4,
    "protein": 1.5,
    "potassium": 43,
    "calcium": 15,
    "iron": 0.2,
    "phosphorus": 25,
    "info": null
  },
  {
    "id": 851,
    "disabled": null,
    "type": "meal",
    "name": "Ebi ikura don",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ebi ikura don",
    "weight-g": 315,
    "volume-ml": null,
    "calories": 384,
    "fat": 2.6,
    "saturated": 0.7,
    "polyunsaturated": 0.9,
    "monounsaturated": 0.7,
    "trans": null,
    "cholesterol": 229,
    "sodium": 305,
    "carbs": 59.8,
    "fibre": 0.5,
    "sugar": 0.2,
    "protein": 28.1,
    "potassium": 311,
    "calcium": 96,
    "iron": 4.6,
    "phosphorus": 182,
    "info": null
  },
  {
    "id": 64,
    "disabled": null,
    "type": "snack",
    "name": "Eclair",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "a choux pastry filled with cream and topped with chocolate icing",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "eclair a choux pastry filled with cream and topped chocolate icing",
    "weight-g": 38,
    "volume-ml": null,
    "calories": 196.7,
    "fat": 16.3,
    "saturated": 11.2,
    "polyunsaturated": 0.7,
    "monounsaturated": 3.7,
    "trans": null,
    "cholesterol": 54,
    "sodium": 111,
    "carbs": 10.1,
    "fibre": 0.6,
    "sugar": 5.4,
    "protein": 2.4,
    "potassium": 78,
    "calcium": 21,
    "iron": 0.7,
    "phosphorus": 49,
    "info": "a choux pastry filled with cream and topped with chocolate icing"
  },
  {
    "id": 106,
    "disabled": 1,
    "type": "meal",
    "name": "Economic fried bee hoon / noodles",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "economic fried bee hoon / noodles",
    "weight-g": 299,
    "volume-ml": null,
    "calories": 452.8,
    "fat": 9,
    "saturated": 3.7,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 86.7,
    "sodium": 1118.3,
    "carbs": 86.7,
    "fibre": 5.1,
    "sugar": 0,
    "protein": 6.3,
    "potassium": 358,
    "calcium": 40,
    "iron": 2.3,
    "phosphorus": 210,
    "info": null
  },
  {
    "id": 353,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "1 deep fried meat and 2 vegetables",
    "search-text": "[economical|economy] rice",
    "weight-g": 500,
    "volume-ml": null,
    "calories": 727,
    "fat": 31,
    "saturated": 13,
    "polyunsaturated": 5,
    "monounsaturated": 12,
    "trans": null,
    "cholesterol": 90,
    "sodium": 870,
    "carbs": 86,
    "fibre": 9,
    "sugar": 12.7,
    "protein": 26,
    "potassium": 647,
    "calcium": 77,
    "iron": 9.7,
    "phosphorus": 277,
    "info": "1 deep fried meat and 2 vegetables"
  },
  {
    "id": 354,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "1 deep fried meat and 1 vegetable",
    "search-text": "[economical|economy] rice",
    "weight-g": 400,
    "volume-ml": null,
    "calories": 684,
    "fat": 28,
    "saturated": 11,
    "polyunsaturated": 4,
    "monounsaturated": 11,
    "trans": null,
    "cholesterol": 90,
    "sodium": 700,
    "carbs": 83,
    "fibre": 6.8,
    "sugar": 10.4,
    "protein": 25,
    "potassium": 443,
    "calcium": 48,
    "iron": 8.9,
    "phosphorus": 226,
    "info": "1 deep fried meat and 1 vegetable"
  },
  {
    "id": 355,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "1 non-fried meat and 1 vegetable",
    "search-text": "[economical|economy] rice",
    "weight-g": 400,
    "volume-ml": null,
    "calories": 554,
    "fat": 18,
    "saturated": 5,
    "polyunsaturated": 4,
    "monounsaturated": 8,
    "trans": null,
    "cholesterol": 67,
    "sodium": 756,
    "carbs": 73,
    "fibre": 6,
    "sugar": 2.9,
    "protein": 25,
    "potassium": 400,
    "calcium": 50,
    "iron": 6.3,
    "phosphorus": 189,
    "info": "1 non-fried meat and 1 vegetable"
  },
  {
    "id": 356,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "2 vegetables",
    "search-text": "[economical|economy] rice",
    "weight-g": 400,
    "volume-ml": null,
    "calories": 394.5,
    "fat": 6.5,
    "saturated": 2,
    "polyunsaturated": 2.5,
    "monounsaturated": 2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 350,
    "carbs": 76,
    "fibre": 10,
    "sugar": 4.8,
    "protein": 8,
    "potassium": 428,
    "calcium": 58,
    "iron": 6.2,
    "phosphorus": 124,
    "info": "2 vegetables"
  },
  {
    "id": 443,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "1 non-fried meat and 2 vegetables",
    "search-text": "[economical|economy] rice",
    "weight-g": 500,
    "volume-ml": null,
    "calories": 597,
    "fat": 21,
    "saturated": 6,
    "polyunsaturated": 5.5,
    "monounsaturated": 8,
    "trans": null,
    "cholesterol": 67,
    "sodium": 926,
    "carbs": 76,
    "fibre": 9,
    "sugar": 5.2,
    "protein": 26,
    "potassium": 604,
    "calcium": 79,
    "iron": 7.1,
    "phosphorus": 240,
    "info": "1 non-fried meat and 2 vegetables"
  },
  {
    "id": 444,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "3 vegetables",
    "search-text": "[economical|economy] rice",
    "weight-g": 500,
    "volume-ml": null,
    "calories": 446,
    "fat": 10,
    "saturated": 2.5,
    "polyunsaturated": 3.1,
    "monounsaturated": 2.9,
    "trans": null,
    "cholesterol": 0,
    "sodium": 520,
    "carbs": 79,
    "fibre": 14,
    "sugar": 7.1,
    "protein": 10,
    "potassium": 632,
    "calcium": 87,
    "iron": 7,
    "phosphorus": 175,
    "info": "3 vegetables"
  },
  {
    "id": 468,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "2 non-fried meat and 2 vegetables",
    "search-text": "[economical|economy] rice",
    "weight-g": 600,
    "volume-ml": null,
    "calories": 732,
    "fat": 28,
    "saturated": 10,
    "polyunsaturated": 5,
    "monounsaturated": 13,
    "trans": null,
    "cholesterol": 134,
    "sodium": 1502,
    "carbs": 76,
    "fibre": 10,
    "sugar": 5.6,
    "protein": 44,
    "potassium": 780,
    "calcium": 100,
    "iron": 8,
    "phosphorus": 356,
    "info": "2 non-fried meat and 2 vegetables"
  },
  {
    "id": 469,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "1 deep fried, 1 non-fried meat and 2 vegetables",
    "search-text": "[economical|economy] rice",
    "weight-g": 600,
    "volume-ml": null,
    "calories": 898,
    "fat": 42,
    "saturated": 16,
    "polyunsaturated": 7,
    "monounsaturated": 18,
    "trans": null,
    "cholesterol": 157,
    "sodium": 1446,
    "carbs": 86,
    "fibre": 9,
    "sugar": 13.1,
    "protein": 44,
    "potassium": 813,
    "calcium": 98,
    "iron": 10.6,
    "phosphorus": 393,
    "info": "1 deep fried, 1 non-fried meat and 2 vegetables"
  },
  {
    "id": 470,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "1 non-fried meat, 2 fried meat and 3 vegetables",
    "search-text": "[economical|economy] rice",
    "weight-g": 800,
    "volume-ml": null,
    "calories": 1206,
    "fat": 62,
    "saturated": 26,
    "polyunsaturated": 5,
    "monounsaturated": 30,
    "trans": null,
    "cholesterol": 247,
    "sodium": 2136,
    "carbs": 99,
    "fibre": 13.8,
    "sugar": 23.3,
    "protein": 63,
    "potassium": 1246,
    "calcium": 146,
    "iron": 14.9,
    "phosphorus": 597,
    "info": "1 non-fried meat, 2 fried meat and 3 vegetables"
  },
  {
    "id": 471,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "2 non-fried meat, 1 fried meat and 3 vegetables",
    "search-text": "[economical|economy] rice",
    "weight-g": 800,
    "volume-ml": null,
    "calories": 1022,
    "fat": 46,
    "saturated": 16,
    "polyunsaturated": 9,
    "monounsaturated": 20,
    "trans": null,
    "cholesterol": 157,
    "sodium": 1616,
    "carbs": 89,
    "fibre": 13,
    "sugar": 15.4,
    "protein": 63,
    "potassium": 872,
    "calcium": 127,
    "iron": 11.4,
    "phosphorus": 444,
    "info": "2 non-fried meat, 1 fried meat and 3 vegetables"
  },
  {
    "id": 472,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "3 fried meat and 3 vegetables",
    "search-text": "[economical|economy] rice",
    "weight-g": 965,
    "volume-ml": null,
    "calories": 1390,
    "fat": 78,
    "saturated": 31,
    "polyunsaturated": 12,
    "monounsaturated": 34,
    "trans": null,
    "cholesterol": 270,
    "sodium": 2080,
    "carbs": 109,
    "fibre": 22.6,
    "sugar": 30.8,
    "protein": 63,
    "potassium": 1289,
    "calcium": 144,
    "iron": 17.5,
    "phosphorus": 634,
    "info": "3 fried meat and 3 vegetables"
  },
  {
    "id": 473,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "3 non-fried meat and 3 vegetables",
    "search-text": "[economical|economy] rice",
    "weight-g": 800,
    "volume-ml": null,
    "calories": 1108,
    "fat": 60,
    "saturated": 19,
    "polyunsaturated": 13,
    "monounsaturated": 28,
    "trans": null,
    "cholesterol": 268,
    "sodium": 2654,
    "carbs": 79,
    "fibre": 10,
    "sugar": 6.4,
    "protein": 63,
    "potassium": 1132,
    "calcium": 142,
    "iron": 9.8,
    "phosphorus": 588,
    "info": "3 non-fried meat and 3 vegetables"
  },
  {
    "id": 951,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "1 deep fried meat and 1 non-fried meat",
    "search-text": "[economical|economy] rice",
    "weight-g": 455,
    "volume-ml": null,
    "calories": 821,
    "fat": 37,
    "saturated": 13,
    "polyunsaturated": 6,
    "monounsaturated": 17,
    "trans": null,
    "cholesterol": 157,
    "sodium": 1106,
    "carbs": 80,
    "fibre": 2.8,
    "sugar": 8.5,
    "protein": 42,
    "potassium": 415,
    "calcium": 40,
    "iron": 9,
    "phosphorus": 291,
    "info": "1 deep fried meat and 1 non-fried meat"
  },
  {
    "id": 952,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "2 deep fried meat",
    "search-text": "[economical|economy] rice",
    "weight-g": 555,
    "volume-ml": null,
    "calories": 933,
    "fat": 45,
    "saturated": 18,
    "polyunsaturated": 6,
    "monounsaturated": 21,
    "trans": null,
    "cholesterol": 180,
    "sodium": 1050,
    "carbs": 90,
    "fibre": 7.5,
    "sugar": 16,
    "protein": 42,
    "potassium": 457,
    "calcium": 38,
    "iron": 11.3,
    "phosphorus": 327,
    "info": "2 deep fried meat"
  },
  {
    "id": 953,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "2 non-fried meat",
    "search-text": "[economical|economy] rice",
    "weight-g": 400,
    "volume-ml": null,
    "calories": 691,
    "fat": 27,
    "saturated": 9,
    "polyunsaturated": 5,
    "monounsaturated": 13,
    "trans": null,
    "cholesterol": 134,
    "sodium": 1162,
    "carbs": 70,
    "fibre": 2,
    "sugar": 1,
    "protein": 42,
    "potassium": 372,
    "calcium": 42,
    "iron": 6.4,
    "phosphorus": 254,
    "info": "2 non-fried meat"
  },
  {
    "id": 954,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "1 deep fried meat and 2 non-fried meat",
    "search-text": "[economical|economy] rice",
    "weight-g": 500,
    "volume-ml": null,
    "calories": 1010,
    "fat": 50,
    "saturated": 18,
    "polyunsaturated": 8,
    "monounsaturated": 23,
    "trans": null,
    "cholesterol": 224,
    "sodium": 1682,
    "carbs": 80,
    "fibre": 2.8,
    "sugar": 8.9,
    "protein": 60,
    "potassium": 591,
    "calcium": 61,
    "iron": 9.9,
    "phosphorus": 407,
    "info": "1 deep fried meat and 2 non-fried meat"
  },
  {
    "id": 955,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "2 deep fried meat and 1 non-fried meat",
    "search-text": "[economical|economy] rice",
    "weight-g": 655,
    "volume-ml": null,
    "calories": 1140,
    "fat": 60,
    "saturated": 23,
    "polyunsaturated": 9,
    "monounsaturated": 27,
    "trans": null,
    "cholesterol": 247,
    "sodium": 1626,
    "carbs": 90,
    "fibre": 7.5,
    "sugar": 16.4,
    "protein": 60,
    "potassium": 633,
    "calcium": 59,
    "iron": 12.5,
    "phosphorus": 425,
    "info": "2 deep fried meat and 1 non-fried meat"
  },
  {
    "id": 956,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "3 deep fried meat",
    "search-text": "[economical|economy] rice",
    "weight-g": 765,
    "volume-ml": null,
    "calories": 1243,
    "fat": 67,
    "saturated": 26,
    "polyunsaturated": 8,
    "monounsaturated": 31,
    "trans": null,
    "cholesterol": 270,
    "sodium": 1570,
    "carbs": 100,
    "fibre": 12.3,
    "sugar": 23.9,
    "protein": 60,
    "potassium": 666,
    "calcium": 57,
    "iron": 14.8,
    "phosphorus": 480,
    "info": "3 deep fried meat"
  },
  {
    "id": 957,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "3 non-fried meat",
    "search-text": "[economical|economy] rice",
    "weight-g": 500,
    "volume-ml": null,
    "calories": 628,
    "fat": 12,
    "saturated": 13,
    "polyunsaturated": 8,
    "monounsaturated": 20,
    "trans": null,
    "cholesterol": 201,
    "sodium": 1738,
    "carbs": 70,
    "fibre": 2,
    "sugar": 1.4,
    "protein": 60,
    "potassium": 538,
    "calcium": 63,
    "iron": 7.3,
    "phosphorus": 370,
    "info": "3 non-fried meat"
  },
  {
    "id": 931,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice, less rice",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "1 deep fried meat and 1 vegetable",
    "search-text": "[economical|economy] rice, less rice",
    "weight-g": 300,
    "volume-ml": null,
    "calories": 562,
    "fat": 26,
    "saturated": 10,
    "polyunsaturated": 4,
    "monounsaturated": 11,
    "trans": null,
    "cholesterol": 90,
    "sodium": 695,
    "carbs": 59,
    "fibre": 5.8,
    "sugar": 10.3,
    "protein": 23,
    "potassium": 433,
    "calcium": 48,
    "iron": 6.6,
    "phosphorus": 215,
    "info": "1 deep fried meat and 1 vegetable"
  },
  {
    "id": 932,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice, less rice",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "1 deep fried meat and 2 vegetables",
    "search-text": "[economical|economy] rice, less rice",
    "weight-g": 400,
    "volume-ml": null,
    "calories": 605,
    "fat": 29,
    "saturated": 11,
    "polyunsaturated": 5,
    "monounsaturated": 12,
    "trans": null,
    "cholesterol": 90,
    "sodium": 865,
    "carbs": 62,
    "fibre": 9.8,
    "sugar": 12.6,
    "protein": 24,
    "potassium": 637,
    "calcium": 77,
    "iron": 7.4,
    "phosphorus": 266,
    "info": "1 deep fried meat and 2 vegetables"
  },
  {
    "id": 933,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice, less rice",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "1 deep fried, 1 non-fried meat and 2 vegetables",
    "search-text": "[economical|economy] rice, less rice",
    "weight-g": 500,
    "volume-ml": null,
    "calories": 785,
    "fat": 41,
    "saturated": 15,
    "polyunsaturated": 7,
    "monounsaturated": 18,
    "trans": null,
    "cholesterol": 157,
    "sodium": 1441,
    "carbs": 62,
    "fibre": 9.8,
    "sugar": 13,
    "protein": 42,
    "potassium": 813,
    "calcium": 98,
    "iron": 8.3,
    "phosphorus": 382,
    "info": "1 deep fried, 1 non-fried meat and 2 vegetables"
  },
  {
    "id": 934,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice, less rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "1 non-fried meat and 1 vegetable",
    "search-text": "[economical|economy] rice, less rice",
    "weight-g": 300,
    "volume-ml": null,
    "calories": 441,
    "fat": 17,
    "saturated": 5,
    "polyunsaturated": 4,
    "monounsaturated": 7,
    "trans": null,
    "cholesterol": 67,
    "sodium": 751,
    "carbs": 49,
    "fibre": 5,
    "sugar": 2.8,
    "protein": 23,
    "potassium": 390,
    "calcium": 50,
    "iron": 4,
    "phosphorus": 178,
    "info": "1 non-fried meat and 1 vegetable"
  },
  {
    "id": 935,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice, less rice",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "1 non-fried meat and 2 vegetables",
    "search-text": "[economical|economy] rice, less rice",
    "weight-g": 400,
    "volume-ml": null,
    "calories": 484,
    "fat": 20,
    "saturated": 6,
    "polyunsaturated": 5,
    "monounsaturated": 8,
    "trans": null,
    "cholesterol": 67,
    "sodium": 921,
    "carbs": 52,
    "fibre": 9,
    "sugar": 5.1,
    "protein": 24,
    "potassium": 604,
    "calcium": 79,
    "iron": 4.8,
    "phosphorus": 229,
    "info": "1 non-fried meat and 2 vegetables"
  },
  {
    "id": 936,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice, less rice",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "1 non-fried meat, 2 deep fried meat and 3 vegetables",
    "search-text": "[economical|economy] rice, less rice",
    "weight-g": 865,
    "volume-ml": null,
    "calories": 1156,
    "fat": 68,
    "saturated": 26,
    "polyunsaturated": 11,
    "monounsaturated": 30,
    "trans": null,
    "cholesterol": 211,
    "sodium": 2129,
    "carbs": 75,
    "fibre": 24.6,
    "sugar": 23,
    "protein": 61,
    "potassium": 1296,
    "calcium": 152,
    "iron": 10.7,
    "phosphorus": 682,
    "info": "1 non-fried meat, 2 deep fried meat and 3 vegetables"
  },
  {
    "id": 937,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice, less rice",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "2 deep fried meat and 2 vegetables",
    "search-text": "[economical|economy] rice, less rice",
    "weight-g": 555,
    "volume-ml": null,
    "calories": 924,
    "fat": 52,
    "saturated": 21,
    "polyunsaturated": 8,
    "monounsaturated": 22,
    "trans": null,
    "cholesterol": 180,
    "sodium": 1385,
    "carbs": 72,
    "fibre": 14.6,
    "sugar": 20.5,
    "protein": 42,
    "potassium": 856,
    "calcium": 96,
    "iron": 10.9,
    "phosphorus": 419,
    "info": "2 deep fried meat and 2 vegetables"
  },
  {
    "id": 938,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice, less rice",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "2 non-fried meat and 2 vegetables",
    "search-text": "[economical|economy] rice, less rice",
    "weight-g": 500,
    "volume-ml": null,
    "calories": 673,
    "fat": 33,
    "saturated": 10,
    "polyunsaturated": 7,
    "monounsaturated": 15,
    "trans": null,
    "cholesterol": 134,
    "sodium": 1497,
    "carbs": 52,
    "fibre": 9,
    "sugar": 5.5,
    "protein": 42,
    "potassium": 770,
    "calcium": 100,
    "iron": 5.7,
    "phosphorus": 770,
    "info": "2 non-fried meat and 2 vegetables"
  },
  {
    "id": 939,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice, less rice",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "2 non-fried meat, 1 deep fried meat and 3 vegetables",
    "search-text": "[economical|economy] rice, less rice",
    "weight-g": 700,
    "volume-ml": null,
    "calories": 1026,
    "fat": 58,
    "saturated": 20,
    "polyunsaturated": 11,
    "monounsaturated": 26,
    "trans": null,
    "cholesterol": 224,
    "sodium": 2187,
    "carbs": 65,
    "fibre": 13.8,
    "sugar": 15.7,
    "protein": 61,
    "potassium": 1183,
    "calcium": 148,
    "iron": 10,
    "phosphorus": 549,
    "info": "2 non-fried meat, 1 deep fried meat and 3 vegetables"
  },
  {
    "id": 940,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice, less rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "2 vegetables",
    "search-text": "[economical|economy] rice, less rice",
    "weight-g": 300,
    "volume-ml": null,
    "calories": 295,
    "fat": 7,
    "saturated": 2,
    "polyunsaturated": 2,
    "monounsaturated": 2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 345,
    "carbs": 52,
    "fibre": 9,
    "sugar": 4.7,
    "protein": 6,
    "potassium": 428,
    "calcium": 58,
    "iron": 3.9,
    "phosphorus": 113,
    "info": "2 vegetables"
  },
  {
    "id": 941,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice, less rice",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "3 deep fried meat and 3 vegetables",
    "search-text": "[economical|economy] rice, less rice",
    "weight-g": 810,
    "volume-ml": null,
    "calories": 1259,
    "fat": 75,
    "saturated": 30,
    "polyunsaturated": 11,
    "monounsaturated": 33,
    "trans": null,
    "cholesterol": 270,
    "sodium": 2075,
    "carbs": 85,
    "fibre": 19.4,
    "sugar": 30.7,
    "protein": 61,
    "potassium": 1279,
    "calcium": 144,
    "iron": 15.2,
    "phosphorus": 623,
    "info": "3 deep fried meat and 3 vegetables"
  },
  {
    "id": 942,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice, less rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "3 non-fried meat and 3 vegetables",
    "search-text": "[economical|economy] rice, less rice",
    "weight-g": 700,
    "volume-ml": null,
    "calories": 896,
    "fat": 48,
    "saturated": 15,
    "polyunsaturated": 10,
    "monounsaturated": 22,
    "trans": null,
    "cholesterol": 201,
    "sodium": 2243,
    "carbs": 55,
    "fibre": 13,
    "sugar": 8.2,
    "protein": 61,
    "potassium": 1140,
    "calcium": 150,
    "iron": 7.4,
    "phosphorus": 512,
    "info": "3 non-fried meat and 3 vegetables"
  },
  {
    "id": 943,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice, less rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "3 vegetables",
    "search-text": "[economical|economy] rice, less rice",
    "weight-g": 400,
    "volume-ml": null,
    "calories": 329,
    "fat": 9,
    "saturated": 2,
    "polyunsaturated": 3,
    "monounsaturated": 3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 515,
    "carbs": 55,
    "fibre": 13,
    "sugar": 7,
    "protein": 7,
    "potassium": 632,
    "calcium": 87,
    "iron": 4.7,
    "phosphorus": 164,
    "info": "3 vegetables"
  },
  {
    "id": 944,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice, less rice",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "1 deep fried meat and 1 non-fried meat",
    "search-text": "[economical|economy] rice, less rice",
    "weight-g": 355,
    "volume-ml": null,
    "calories": 726,
    "fat": 38,
    "saturated": 14,
    "polyunsaturated": 6,
    "monounsaturated": 17,
    "trans": null,
    "cholesterol": 157,
    "sodium": 1101,
    "carbs": 56,
    "fibre": 1.8,
    "sugar": 8.4,
    "protein": 40,
    "potassium": 405,
    "calcium": 40,
    "iron": 6.7,
    "phosphorus": 280,
    "info": "1 deep fried meat and 1 non-fried meat"
  },
  {
    "id": 945,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice, less rice",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "2 deep fried meat",
    "search-text": "[economical|economy] rice, less rice",
    "weight-g": 465,
    "volume-ml": null,
    "calories": 838,
    "fat": 46,
    "saturated": 19,
    "polyunsaturated": 6,
    "monounsaturated": 20,
    "trans": null,
    "cholesterol": 180,
    "sodium": 1045,
    "carbs": 66,
    "fibre": 6.6,
    "sugar": 15.9,
    "protein": 40,
    "potassium": 448,
    "calcium": 38,
    "iron": 9.3,
    "phosphorus": 317,
    "info": "2 deep fried meat"
  },
  {
    "id": 946,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice, less rice",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "2 non-fried meat",
    "search-text": "[economical|economy] rice, less rice",
    "weight-g": 300,
    "volume-ml": null,
    "calories": 605,
    "fat": 29,
    "saturated": 9,
    "polyunsaturated": 5,
    "monounsaturated": 13,
    "trans": null,
    "cholesterol": 134,
    "sodium": 1157,
    "carbs": 46,
    "fibre": 1,
    "sugar": 0.9,
    "protein": 40,
    "potassium": 362,
    "calcium": 42,
    "iron": 4.1,
    "phosphorus": 243,
    "info": "2 non-fried meat"
  },
  {
    "id": 947,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice, less rice",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "1 deep fried meat and 2 non-fried meat",
    "search-text": "[economical|economy] rice, less rice",
    "weight-g": 510,
    "volume-ml": null,
    "calories": 924,
    "fat": 52,
    "saturated": 21,
    "polyunsaturated": 8,
    "monounsaturated": 24,
    "trans": null,
    "cholesterol": 228,
    "sodium": 1677,
    "carbs": 56,
    "fibre": 6.4,
    "sugar": 15.5,
    "protein": 58,
    "potassium": 499,
    "calcium": 54,
    "iron": 7.6,
    "phosphorus": 398,
    "info": "1 deep fried meat and 2 non-fried meat"
  },
  {
    "id": 948,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice, less rice",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "2 deep fried meat and 1 non-fried meat",
    "search-text": "[economical|economy] rice, less rice",
    "weight-g": 565,
    "volume-ml": null,
    "calories": 1027,
    "fat": 59,
    "saturated": 23,
    "polyunsaturated": 8,
    "monounsaturated": 27,
    "trans": null,
    "cholesterol": 247,
    "sodium": 1621,
    "carbs": 66,
    "fibre": 6.6,
    "sugar": 16.3,
    "protein": 58,
    "potassium": 624,
    "calcium": 59,
    "iron": 10.2,
    "phosphorus": 415,
    "info": "2 deep fried meat and 1 non-fried meat"
  },
  {
    "id": 949,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice, less rice",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "3 deep fried meat",
    "search-text": "[economical|economy] rice, less rice",
    "weight-g": 675,
    "volume-ml": null,
    "calories": 1121,
    "fat": 65,
    "saturated": 26,
    "polyunsaturated": 8,
    "monounsaturated": 30,
    "trans": null,
    "cholesterol": 270,
    "sodium": 1565,
    "carbs": 76,
    "fibre": 11.4,
    "sugar": 23.8,
    "protein": 58,
    "potassium": 667,
    "calcium": 57,
    "iron": 12.8,
    "phosphorus": 470,
    "info": "3 deep fried meat"
  },
  {
    "id": 950,
    "disabled": null,
    "type": "meal",
    "name": "Economical rice, less rice",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "3 non-fried meat",
    "search-text": "[economical|economy] rice, less rice",
    "weight-g": 400,
    "volume-ml": null,
    "calories": 794,
    "fat": 42,
    "saturated": 13,
    "polyunsaturated": 8,
    "monounsaturated": 20,
    "trans": null,
    "cholesterol": 201,
    "sodium": 1733,
    "carbs": 46,
    "fibre": 1,
    "sugar": 1.3,
    "protein": 58,
    "potassium": 528,
    "calcium": 63,
    "iron": 5,
    "phosphorus": 359,
    "info": "3 non-fried meat"
  },
  {
    "id": 413,
    "disabled": null,
    "type": "snack",
    "name": "Edamame",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "edamame",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 148,
    "fat": 4.7,
    "saturated": 0.4,
    "polyunsaturated": 5.1,
    "monounsaturated": 2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 343,
    "carbs": 9.5,
    "fibre": 7,
    "sugar": 0,
    "protein": 16.9,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 481,
    "disabled": null,
    "type": "meal",
    "name": "Ee fu noodles, stir fried",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[ee fu|e fu] noodles, stir fried",
    "weight-g": 418,
    "volume-ml": null,
    "calories": 605.3,
    "fat": 31.3,
    "saturated": 11.6,
    "polyunsaturated": 8.7,
    "monounsaturated": 11,
    "trans": null,
    "cholesterol": 35,
    "sodium": 2218,
    "carbs": 55.4,
    "fibre": 3.5,
    "sugar": 4.5,
    "protein": 25.5,
    "potassium": 255,
    "calcium": 57,
    "iron": 4.5,
    "phosphorus": 213,
    "info": null
  },
  {
    "id": 1078,
    "disabled": null,
    "type": "snack",
    "name": "Egg mayo sandwich",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "egg [mayo|mayonnaise] sandwich",
    "weight-g": 125,
    "volume-ml": null,
    "calories": 264,
    "fat": 9.1,
    "saturated": 2.2,
    "polyunsaturated": 2.8,
    "monounsaturated": 3.2,
    "trans": null,
    "cholesterol": 211,
    "sodium": 345,
    "carbs": 33.4,
    "fibre": 1.6,
    "sugar": 2.9,
    "protein": 12.3,
    "potassium": 88,
    "calcium": 46,
    "iron": 2.7,
    "phosphorus": 119,
    "info": null
  },
  {
    "id": 1002,
    "disabled": null,
    "type": "snack",
    "name": "Egg pudding with ginger",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "egg pudding with ginger",
    "weight-g": 125,
    "volume-ml": null,
    "calories": 203,
    "fat": 7,
    "saturated": 4,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 200,
    "sodium": 78,
    "carbs": 23,
    "fibre": 0,
    "sugar": 14,
    "protein": 12,
    "potassium": 138,
    "calcium": 429,
    "iron": 2.2,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 196,
    "disabled": null,
    "type": "snack",
    "name": "Egg tart, small",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Dan Tat",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "egg tart, small dan tat",
    "weight-g": 64,
    "volume-ml": null,
    "calories": 178.1,
    "fat": 9.7,
    "saturated": 5.5,
    "polyunsaturated": 0.6,
    "monounsaturated": 3.2,
    "trans": null,
    "cholesterol": 54,
    "sodium": 75,
    "carbs": 18.4,
    "fibre": 0.7,
    "sugar": 7.4,
    "protein": 4.3,
    "potassium": 76,
    "calcium": 35,
    "iron": 0.7,
    "phosphorus": 70,
    "info": "Dan Tat"
  },
  {
    "id": 266,
    "disabled": null,
    "type": "meal",
    "name": "Egg, fried",
    "rating": 3,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "sunny side up / bullseye",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "egg, fried sunny side up / bullseye",
    "weight-g": 46,
    "volume-ml": null,
    "calories": 84.4,
    "fat": 6.4,
    "saturated": 2,
    "polyunsaturated": 0.7,
    "monounsaturated": 2.8,
    "trans": null,
    "cholesterol": 198,
    "sodium": 150,
    "carbs": 0.1,
    "fibre": 0,
    "sugar": 0.1,
    "protein": 6.6,
    "potassium": 71,
    "calcium": 29,
    "iron": 1.2,
    "phosphorus": 92,
    "info": "e.g. sunny side up / bullseye"
  },
  {
    "id": 780,
    "disabled": null,
    "type": "meal",
    "name": "Egg, fried with bitter gourd / french bean",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "egg, fried with bitter gourd / french bean",
    "weight-g": 200,
    "volume-ml": null,
    "calories": 341,
    "fat": 30.6,
    "saturated": 13.8,
    "polyunsaturated": 3.6,
    "monounsaturated": 11.8,
    "trans": null,
    "cholesterol": 292,
    "sodium": 1262,
    "carbs": 6.8,
    "fibre": 4.6,
    "sugar": 0,
    "protein": 9.6,
    "potassium": null,
    "calcium": 54,
    "iron": 2.4,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 779,
    "disabled": null,
    "type": "meal",
    "name": "Egg, fried with cai pu / preserved radish",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "egg, fried with [cai|chai] [pu|poh] / preserved radish",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 251.2,
    "fat": 20,
    "saturated": 4,
    "polyunsaturated": 1.4,
    "monounsaturated": 5.6,
    "trans": null,
    "cholesterol": 396,
    "sodium": 906,
    "carbs": 4.2,
    "fibre": 0.2,
    "sugar": 4.2,
    "protein": 13.6,
    "potassium": 210,
    "calcium": 58,
    "iron": 3.2,
    "phosphorus": 206,
    "info": null
  },
  {
    "id": 775,
    "disabled": null,
    "type": "meal",
    "name": "Egg, fried fu rong",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "egg, fried [fu|foo] [rong|yong]",
    "weight-g": 160,
    "volume-ml": null,
    "calories": 413.6,
    "fat": 36,
    "saturated": 17.6,
    "polyunsaturated": 3.6,
    "monounsaturated": 13.2,
    "trans": null,
    "cholesterol": 530,
    "sodium": 592,
    "carbs": 3.6,
    "fibre": 1,
    "sugar": 0,
    "protein": 18.8,
    "potassium": null,
    "calcium": 76,
    "iron": 3,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 776,
    "disabled": null,
    "type": "meal",
    "name": "Egg, fried with minced meat",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "egg, fried with minced meat",
    "weight-g": 200,
    "volume-ml": null,
    "calories": 479.6,
    "fat": 40.4,
    "saturated": 12.2,
    "polyunsaturated": 8.6,
    "monounsaturated": 15.6,
    "trans": null,
    "cholesterol": 428,
    "sodium": 1000,
    "carbs": 0.2,
    "fibre": 0,
    "sugar": 0.2,
    "protein": 28.8,
    "potassium": 414,
    "calcium": 66,
    "iron": 3.2,
    "phosphorus": 340,
    "info": null
  },
  {
    "id": 778,
    "disabled": null,
    "type": "meal",
    "name": "Egg, fried with onion",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "egg, fried with onion",
    "weight-g": 200,
    "volume-ml": null,
    "calories": 297.1,
    "fat": 23.5,
    "saturated": 8.1,
    "polyunsaturated": 2.7,
    "monounsaturated": 8.6,
    "trans": null,
    "cholesterol": 130,
    "sodium": 496,
    "carbs": 6.8,
    "fibre": 2.4,
    "sugar": 3.1,
    "protein": 14.6,
    "potassium": 167,
    "calcium": 57,
    "iron": 1.2,
    "phosphorus": 202,
    "info": null
  },
  {
    "id": 419,
    "disabled": null,
    "type": "meal",
    "name": "Egg, hard boiled",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "egg",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "egg, hard boiled",
    "weight-g": 50,
    "volume-ml": null,
    "calories": 75.1,
    "fat": 5.3,
    "saturated": 1.6,
    "polyunsaturated": 0.7,
    "monounsaturated": 2,
    "trans": null,
    "cholesterol": 187,
    "sodium": 62,
    "carbs": 0.6,
    "fibre": 0,
    "sugar": 0.6,
    "protein": 6.3,
    "potassium": 63,
    "calcium": 25,
    "iron": 0.6,
    "phosphorus": 86,
    "info": null
  },
  {
    "id": 550,
    "disabled": null,
    "type": "meal",
    "name": "Egg, scrambled",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "egg",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "egg, scrambled",
    "weight-g": 80,
    "volume-ml": null,
    "calories": 135,
    "fat": 10.8,
    "saturated": 4.8,
    "polyunsaturated": 2,
    "monounsaturated": 4,
    "trans": null,
    "cholesterol": 251,
    "sodium": 113,
    "carbs": 1,
    "fibre": 0,
    "sugar": 1,
    "protein": 8.3,
    "potassium": 102,
    "calcium": 59,
    "iron": 1.2,
    "phosphorus": 130,
    "info": null
  },
  {
    "id": 726,
    "disabled": null,
    "type": "meal",
    "name": "Egg, soft boiled / steamed / half boiled",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "egg",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "egg, soft boiled / steamed half",
    "weight-g": 55,
    "volume-ml": null,
    "calories": 74.1,
    "fat": 5.3,
    "saturated": 1.6,
    "polyunsaturated": 0.5,
    "monounsaturated": 2.2,
    "trans": null,
    "cholesterol": 208,
    "sodium": 60,
    "carbs": 0.2,
    "fibre": 0,
    "sugar": 0.2,
    "protein": 6.4,
    "potassium": 52,
    "calcium": 21,
    "iron": 0.9,
    "phosphorus": 70,
    "info": null
  },
  {
    "id": 958,
    "disabled": null,
    "type": "meal",
    "name": "Egg white",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "egg",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "without yolk",
    "search-text": "egg white",
    "weight-g": 33,
    "volume-ml": null,
    "calories": 35.7,
    "fat": 2.2,
    "saturated": 0.6,
    "polyunsaturated": 0.7,
    "monounsaturated": 0.8,
    "trans": null,
    "cholesterol": 1.7,
    "sodium": 167,
    "carbs": 0.3,
    "fibre": 0,
    "sugar": 0.2,
    "protein": 3.8,
    "potassium": 57.1,
    "calcium": 2.6,
    "iron": 0,
    "phosphorus": 5.6,
    "info": "without yolk"
  },
  {
    "id": 863,
    "disabled": null,
    "type": "meal",
    "name": "Eggs benedict",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "eggs benedict",
    "weight-g": 291,
    "volume-ml": null,
    "calories": 669,
    "fat": 49.4,
    "saturated": 23.7,
    "polyunsaturated": 6.1,
    "monounsaturated": 19.4,
    "trans": 0.2,
    "cholesterol": null,
    "sodium": 1016,
    "carbs": 24.4,
    "fibre": 4.1,
    "sugar": 4.7,
    "protein": 31.7,
    "potassium": null,
    "calcium": 249,
    "iron": 3.4,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 267,
    "disabled": null,
    "type": "meal",
    "name": "Enchiladas with beef / pork",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "enchiladas with beef / pork",
    "weight-g": 114,
    "volume-ml": null,
    "calories": 192,
    "fat": 10.4,
    "saturated": 3.4,
    "polyunsaturated": 2.2,
    "monounsaturated": 3.9,
    "trans": null,
    "cholesterol": 32,
    "sodium": 237,
    "carbs": 14.1,
    "fibre": 2.3,
    "sugar": 2.4,
    "protein": 11.2,
    "potassium": 241,
    "calcium": 91,
    "iron": 1.5,
    "phosphorus": 179,
    "info": null
  },
  {
    "id": 351,
    "disabled": null,
    "type": "meal",
    "name": "Enchiladas with chicken",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "enchiladas with chicken",
    "weight-g": 114,
    "volume-ml": null,
    "calories": 171,
    "fat": 8.7,
    "saturated": 2.6,
    "polyunsaturated": 2.3,
    "monounsaturated": 3.2,
    "trans": null,
    "cholesterol": 34,
    "sodium": 226,
    "carbs": 14.1,
    "fibre": 2.3,
    "sugar": 2.4,
    "protein": 10.3,
    "potassium": 245,
    "calcium": 91,
    "iron": 0.8,
    "phosphorus": 180,
    "info": null
  },
  {
    "id": 629,
    "disabled": null,
    "type": "meal",
    "name": "Endives, stir-fried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "endives, stir-fried",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 61,
    "fat": 5.2,
    "saturated": 0.8,
    "polyunsaturated": 3.1,
    "monounsaturated": 1.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 22,
    "carbs": 0.3,
    "fibre": 3.1,
    "sugar": 0,
    "protein": 1.3,
    "potassium": 314,
    "calcium": 52,
    "iron": 0.8,
    "phosphorus": 28,
    "info": null
  },
  {
    "id": 618,
    "disabled": null,
    "type": "drink",
    "name": "Energy drink",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "can",
    "info-translation": null,
    "info-brands": "Red Bull, Monster",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "energy drink red bull, monster",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 110,
    "fat": 0,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 27.5,
    "fibre": 0,
    "sugar": 27.5,
    "protein": 0,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Red Bull, Monster"
  },
  {
    "id": 648,
    "disabled": null,
    "type": "meal",
    "name": "Enoki mushroom",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "enoki mushroom",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 40,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 8,
    "fibre": 3,
    "sugar": 2,
    "protein": 2,
    "potassium": 410,
    "calcium": 10,
    "iron": 1.1,
    "phosphorus": 100,
    "info": null
  },
  {
    "id": 268,
    "disabled": null,
    "type": "meal",
    "name": "Fajita, chicken",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": "Mexican grilled chicken in tortilla",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fajita, chicken mexican grilled in tortilla",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 149.1,
    "fat": 3.5,
    "saturated": 0.6,
    "polyunsaturated": 1.1,
    "monounsaturated": 1.8,
    "trans": null,
    "cholesterol": 82,
    "sodium": 800,
    "carbs": 4.7,
    "fibre": 0,
    "sugar": 0,
    "protein": 24.7,
    "potassium": null,
    "calcium": 0,
    "iron": 0.8,
    "phosphorus": null,
    "info": "Mexican grilled chicken in tortilla"
  },
  {
    "id": 695,
    "disabled": null,
    "type": "meal",
    "name": "Fern shoot",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[fern|pucuk|paku] shoot",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 77,
    "fat": 5,
    "saturated": 1.8,
    "polyunsaturated": 1.3,
    "monounsaturated": 1.9,
    "trans": null,
    "cholesterol": 20,
    "sodium": 300,
    "carbs": 6,
    "fibre": 3,
    "sugar": 2,
    "protein": 2,
    "potassium": 500,
    "calcium": 155,
    "iron": 5.5,
    "phosphorus": 59,
    "info": null
  },
  {
    "id": 1018,
    "disabled": null,
    "type": "snack",
    "name": "Financier",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "French almond cake",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "financier french almond cake",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 141,
    "fat": 8,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 13,
    "carbs": 15,
    "fibre": null,
    "sugar": null,
    "protein": 1,
    "potassium": null,
    "calcium": 104,
    "iron": null,
    "phosphorus": null,
    "info": "French almond cake"
  },
  {
    "id": 107,
    "disabled": null,
    "type": "meal",
    "name": "Fish and chips",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fish and chips",
    "weight-g": 268,
    "volume-ml": null,
    "calories": 923.8,
    "fat": 47.4,
    "saturated": 20.1,
    "polyunsaturated": 4.6,
    "monounsaturated": 20.6,
    "trans": null,
    "cholesterol": 121,
    "sodium": 624,
    "carbs": 76.9,
    "fibre": 4.6,
    "sugar": 2.4,
    "protein": 47.4,
    "potassium": 1142,
    "calcium": 70,
    "iron": 3.2,
    "phosphorus": 456,
    "info": null
  },
  {
    "id": 734,
    "disabled": null,
    "type": "snack",
    "name": "Fish ball on stick, fried",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "stick",
    "info-translation": null,
    "info-brands": "Old Chang Kee",
    "info-examples": null,
    "info-portion-aid": "3 pieces",
    "info-include-exclude": null,
    "search-text": "fish ball on stick, fried old chang kee",
    "weight-g": 69,
    "volume-ml": null,
    "calories": 131.2,
    "fat": 9.6,
    "saturated": 4.8,
    "polyunsaturated": 0.9,
    "monounsaturated": 3.9,
    "trans": null,
    "cholesterol": 18,
    "sodium": 390,
    "carbs": 2.7,
    "fibre": 0,
    "sugar": 0.2,
    "protein": 8.5,
    "potassium": 29,
    "calcium": 26,
    "iron": 1.7,
    "phosphorus": 49,
    "info": "e.g. Old Chang Kee (3 pieces)"
  },
  {
    "id": 736,
    "disabled": null,
    "type": "snack",
    "name": "Fish fillet on stick, fried",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "stick",
    "info-translation": null,
    "info-brands": "Old Chang Kee",
    "info-examples": null,
    "info-portion-aid": "3 pieces",
    "info-include-exclude": null,
    "search-text": "fish fillet on stick, fried old chang kee",
    "weight-g": 113,
    "volume-ml": null,
    "calories": 236.8,
    "fat": 11.2,
    "saturated": 2.1,
    "polyunsaturated": 6,
    "monounsaturated": 2.6,
    "trans": 0.1,
    "cholesterol": 41,
    "sodium": 634,
    "carbs": 19,
    "fibre": 1,
    "sugar": 0.5,
    "protein": 15,
    "potassium": 284,
    "calcium": 16,
    "iron": 0.4,
    "phosphorus": 234,
    "info": "e.g. Old Chang Kee (3 pieces)"
  },
  {
    "id": 108,
    "disabled": null,
    "type": "meal",
    "name": "Fish head curry",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fish head curry",
    "weight-g": 350,
    "volume-ml": null,
    "calories": 366.6,
    "fat": 24.2,
    "saturated": 16.1,
    "polyunsaturated": 2.1,
    "monounsaturated": 4.9,
    "trans": null,
    "cholesterol": 119,
    "sodium": 1271,
    "carbs": 13,
    "fibre": 4.9,
    "sugar": 0.5,
    "protein": 24.2,
    "potassium": 300,
    "calcium": 175,
    "iron": 6,
    "phosphorus": 150,
    "info": null
  },
  {
    "id": 273,
    "disabled": null,
    "type": "meal",
    "name": "Fish head steamboat",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fish head steamboat",
    "weight-g": 696,
    "volume-ml": null,
    "calories": 493.6,
    "fat": 14.8,
    "saturated": 7,
    "polyunsaturated": 1.2,
    "monounsaturated": 4.7,
    "trans": null,
    "cholesterol": 56,
    "sodium": 1494,
    "carbs": 56.5,
    "fibre": 4.2,
    "sugar": 17.5,
    "protein": 33.6,
    "potassium": 1278,
    "calcium": 343,
    "iron": 3.7,
    "phosphorus": 502,
    "info": null
  },
  {
    "id": 269,
    "disabled": null,
    "type": "meal",
    "name": "Fish, fried, breaded / battered",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "3\" x 3\" x 1\"",
    "info-include-exclude": null,
    "search-text": "fish, fried, breaded / battered",
    "weight-g": 113,
    "volume-ml": null,
    "calories": 245.8,
    "fat": 12.2,
    "saturated": 2.1,
    "polyunsaturated": 6,
    "monounsaturated": 2.6,
    "trans": 0.1,
    "cholesterol": 41,
    "sodium": 634,
    "carbs": 19,
    "fibre": 1,
    "sugar": 0.5,
    "protein": 15,
    "potassium": 284,
    "calcium": 16,
    "iron": 0.4,
    "phosphorus": 234,
    "info": "(3\" x 3\" x 1\")"
  },
  {
    "id": 1066,
    "disabled": null,
    "type": "meal",
    "name": "Fish, grilled / pan fried",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "3\" x 3\" x 1\"",
    "info-include-exclude": null,
    "search-text": "fish, grilled / pan fried",
    "weight-g": 117,
    "volume-ml": null,
    "calories": 184,
    "fat": 11,
    "saturated": 1,
    "polyunsaturated": 1,
    "monounsaturated": 3,
    "trans": null,
    "cholesterol": 65,
    "sodium": 80,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 20,
    "potassium": 391,
    "calcium": 40,
    "iron": 1,
    "phosphorus": 186,
    "info": "(3\" x 3\" x 1\")"
  },
  {
    "id": 420,
    "disabled": null,
    "type": "meal",
    "name": "Fish, steamed",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "3\" x 3\" x 1\"",
    "info-include-exclude": null,
    "search-text": "fish, steamed",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 110.5,
    "fat": 3.3,
    "saturated": 1.6,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 40,
    "sodium": 297,
    "carbs": null,
    "fibre": 0.2,
    "sugar": null,
    "protein": 20.2,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "(3\" x 3\" x 1\")"
  },
  {
    "id": 764,
    "disabled": null,
    "type": "meal",
    "name": "Fish, sweet and sour",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fish, sweet and sour",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 274,
    "fat": 13.8,
    "saturated": 6.6,
    "polyunsaturated": 1.7,
    "monounsaturated": 6.4,
    "trans": null,
    "cholesterol": 70,
    "sodium": 887,
    "carbs": 17.3,
    "fibre": 4.8,
    "sugar": 11.2,
    "protein": 28.3,
    "potassium": 464,
    "calcium": 20,
    "iron": 1.2,
    "phosphorus": 254,
    "info": null
  },
  {
    "id": 270,
    "disabled": null,
    "type": "meal",
    "name": "Fishball noodle, dry",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fishball noodle, dry",
    "weight-g": 420,
    "volume-ml": null,
    "calories": 474.7,
    "fat": 13.5,
    "saturated": 5.7,
    "polyunsaturated": 2.2,
    "monounsaturated": 5.5,
    "trans": null,
    "cholesterol": 55,
    "sodium": 2552,
    "carbs": 63.5,
    "fibre": 5,
    "sugar": 9.5,
    "protein": 24.8,
    "potassium": 113,
    "calcium": 80,
    "iron": 2.5,
    "phosphorus": 248,
    "info": null
  },
  {
    "id": 959,
    "disabled": null,
    "type": "meal",
    "name": "Fishball noodle, soup",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fishball noodle, soup",
    "weight-g": 600,
    "volume-ml": null,
    "calories": 420,
    "fat": 7.2,
    "saturated": 1.8,
    "polyunsaturated": 1,
    "monounsaturated": 1.7,
    "trans": null,
    "cholesterol": 30,
    "sodium": 2190,
    "carbs": 66,
    "fibre": 6.6,
    "sugar": 5,
    "protein": 22.8,
    "potassium": 156,
    "calcium": 42,
    "iron": 2.4,
    "phosphorus": 144,
    "info": null
  },
  {
    "id": 271,
    "disabled": null,
    "type": "meal",
    "name": "Fishball, boiled",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 3,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "fish cakes, sotong balls",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fishball, boiled fish cakes, sotong balls",
    "weight-g": 75,
    "volume-ml": null,
    "calories": 49.8,
    "fat": 0.6,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 24,
    "sodium": 495,
    "carbs": 3.6,
    "fibre": 0,
    "sugar": 0.9,
    "protein": 7.5,
    "potassium": 36,
    "calcium": 24,
    "iron": 0.3,
    "phosphorus": 66,
    "info": "e.g. fish cakes, sotong balls"
  },
  {
    "id": 113,
    "disabled": null,
    "type": "meal",
    "name": "Fishball, fried",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 3,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "fish cakes, sotong balls",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fishball, fried fish cakes, sotong balls",
    "weight-g": 69,
    "volume-ml": null,
    "calories": 131.2,
    "fat": 9.6,
    "saturated": 4.8,
    "polyunsaturated": 0.9,
    "monounsaturated": 3.9,
    "trans": null,
    "cholesterol": 18,
    "sodium": 390,
    "carbs": 2.7,
    "fibre": 0,
    "sugar": 0.2,
    "protein": 8.5,
    "potassium": 29,
    "calcium": 26,
    "iron": 1.7,
    "phosphorus": 49,
    "info": "e.g. fish cakes, sotong balls"
  },
  {
    "id": 115,
    "disabled": null,
    "type": "meal",
    "name": "Five spice prawn roll, fried",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "roll",
    "info-translation": "Ngor Hiang, Loh Bak, Wu Xiang",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "five spice prawn roll, fried [ngor|ngo|ngoh] hiang, [loh|lor] bak, wu xiang",
    "weight-g": 59,
    "volume-ml": null,
    "calories": 228.4,
    "fat": 15.2,
    "saturated": 6.7,
    "polyunsaturated": 2,
    "monounsaturated": 5.8,
    "trans": null,
    "cholesterol": 14,
    "sodium": 291,
    "carbs": 15.8,
    "fibre": 0.8,
    "sugar": 1.7,
    "protein": 7.1,
    "potassium": 116,
    "calcium": 17,
    "iron": 0.9,
    "phosphorus": 58,
    "info": "Ngor Hiang, Loh Bak, Wu Xiang"
  },
  {
    "id": 901,
    "disabled": null,
    "type": "snack",
    "name": "Flourless cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "flourless cake",
    "weight-g": 82,
    "volume-ml": null,
    "calories": 375,
    "fat": 30,
    "saturated": 18,
    "polyunsaturated": 1.3,
    "monounsaturated": 8.9,
    "trans": 0.5,
    "cholesterol": 101,
    "sodium": 163,
    "carbs": 19,
    "fibre": 5.4,
    "sugar": 9.7,
    "protein": 7.1,
    "potassium": 300,
    "calcium": 47,
    "iron": 6,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 503,
    "disabled": null,
    "type": "meal",
    "name": "Foie gras, pate",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "foie gras, pate",
    "weight-g": 13,
    "volume-ml": null,
    "calories": 59.6,
    "fat": 5.7,
    "saturated": 1.9,
    "polyunsaturated": 0.1,
    "monounsaturated": 3.3,
    "trans": null,
    "cholesterol": 19.5,
    "sodium": 90.6,
    "carbs": 0.6,
    "fibre": 0,
    "sugar": null,
    "protein": 1.5,
    "potassium": 17.9,
    "calcium": 9.1,
    "iron": 0.7,
    "phosphorus": 26,
    "info": null
  },
  {
    "id": 683,
    "disabled": null,
    "type": "meal",
    "name": "Four angle bean",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": "Winged / goa / princess bean",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "four angle bean winged / goa princess",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 86.7,
    "fat": 2.3,
    "saturated": 0.9,
    "polyunsaturated": 1.5,
    "monounsaturated": 2.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 249,
    "carbs": 15,
    "fibre": 1.3,
    "sugar": null,
    "protein": 1.5,
    "potassium": 280,
    "calcium": 142,
    "iron": 4.3,
    "phosphorus": 153,
    "info": "Winged / goa / princess bean"
  },
  {
    "id": 598,
    "disabled": null,
    "type": "snack",
    "name": "Freeze dried fruit snack",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bag",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "banana, strawberry",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "freeze dried fruit snack banana, strawberry",
    "weight-g": 15,
    "volume-ml": null,
    "calories": 57.4,
    "fat": 0.2,
    "saturated": 0.1,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1,
    "carbs": 13.2,
    "fibre": null,
    "sugar": 10.8,
    "protein": 0.7,
    "potassium": null,
    "calcium": 2,
    "iron": 0.4,
    "phosphorus": null,
    "info": "e.g. banana, strawberry"
  },
  {
    "id": 644,
    "disabled": null,
    "type": "meal",
    "name": "French bean",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "french bean",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 67.3,
    "fat": 5.3,
    "saturated": 0.8,
    "polyunsaturated": 3.2,
    "monounsaturated": 0.9,
    "trans": null,
    "cholesterol": 0,
    "sodium": 496,
    "carbs": 2.6,
    "fibre": 2.9,
    "sugar": 1.5,
    "protein": 2.3,
    "potassium": 172,
    "calcium": 46,
    "iron": 1,
    "phosphorus": 40,
    "info": null
  },
  {
    "id": 109,
    "disabled": null,
    "type": "meal",
    "name": "French fries",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "pack",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "medium",
    "info-include-exclude": null,
    "search-text": "french fries",
    "weight-g": 116,
    "volume-ml": null,
    "calories": 349,
    "fat": 19.5,
    "saturated": 7.6,
    "polyunsaturated": 4.8,
    "monounsaturated": 7.1,
    "trans": 0.1,
    "cholesterol": 0,
    "sodium": 290,
    "carbs": 47,
    "fibre": 4,
    "sugar": 1.2,
    "protein": 3.5,
    "potassium": 650,
    "calcium": 20,
    "iron": 1,
    "phosphorus": 153,
    "info": "(medium)"
  },
  {
    "id": 992,
    "disabled": null,
    "type": "meal",
    "name": "French onion soup",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "french onion soup",
    "weight-g": 301,
    "volume-ml": null,
    "calories": 477.9,
    "fat": 19.9,
    "saturated": 9.4,
    "polyunsaturated": 1.7,
    "monounsaturated": 7.6,
    "trans": null,
    "cholesterol": 45.2,
    "sodium": 1290,
    "carbs": 52.3,
    "fibre": 3.6,
    "sugar": 6.7,
    "protein": 22.4,
    "potassium": 476,
    "calcium": 400,
    "iron": 3.3,
    "phosphorus": 352,
    "info": null
  },
  {
    "id": 274,
    "disabled": null,
    "type": "meal",
    "name": "French toast, multigrain",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "french toast, multigrain",
    "weight-g": 65,
    "volume-ml": null,
    "calories": 134.5,
    "fat": 4.9,
    "saturated": 1.1,
    "polyunsaturated": 0.7,
    "monounsaturated": 1.2,
    "trans": null,
    "cholesterol": 48,
    "sodium": 303,
    "carbs": 16.2,
    "fibre": 2.5,
    "sugar": 1.5,
    "protein": 6.4,
    "potassium": 79,
    "calcium": 100,
    "iron": 1.8,
    "phosphorus": 104,
    "info": null
  },
  {
    "id": 110,
    "disabled": null,
    "type": "meal",
    "name": "French toast, white bread",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "french toast, white bread",
    "weight-g": 59,
    "volume-ml": null,
    "calories": 125.6,
    "fat": 3.6,
    "saturated": 0.9,
    "polyunsaturated": 0.7,
    "monounsaturated": 1.2,
    "trans": null,
    "cholesterol": 48,
    "sodium": 292,
    "carbs": 18.9,
    "fibre": 0.7,
    "sugar": 0,
    "protein": 4.4,
    "potassium": 79,
    "calcium": 63,
    "iron": 1.3,
    "phosphorus": 82,
    "info": null
  },
  {
    "id": 1064,
    "disabled": null,
    "type": "drink",
    "name": "Fresh coconut water",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "fruit",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fresh coconut water",
    "weight-g": 250,
    "volume-ml": null,
    "calories": 48.9,
    "fat": 0.5,
    "saturated": 0.4,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 262,
    "carbs": 9.3,
    "fibre": 2.8,
    "sugar": 6.5,
    "protein": 1.8,
    "potassium": 625,
    "calcium": 59,
    "iron": 0.7,
    "phosphorus": 50,
    "info": null
  },
  {
    "id": 705,
    "disabled": null,
    "type": "snack",
    "name": "Fresh coconut water with flesh",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "fruit",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fresh coconut water with flesh",
    "weight-g": 300,
    "volume-ml": null,
    "calories": 295,
    "fat": 27,
    "saturated": 24,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": null,
    "carbs": 10,
    "fibre": 7,
    "sugar": 5,
    "protein": 3,
    "potassium": 200,
    "calcium": null,
    "iron": 1.9,
    "phosphorus": 91,
    "info": null
  },
  {
    "id": 1080,
    "disabled": null,
    "type": "snack",
    "name": "Fresh fruit cream cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fresh fruit cream cake",
    "weight-g": 161,
    "volume-ml": null,
    "calories": 451,
    "fat": 21.3,
    "saturated": 12.6,
    "polyunsaturated": 1.8,
    "monounsaturated": 3.3,
    "trans": 0.7,
    "cholesterol": 43,
    "sodium": 196,
    "carbs": 61.4,
    "fibre": 1.9,
    "sugar": 42.6,
    "protein": 5.2,
    "potassium": 202,
    "calcium": 68,
    "iron": 1.6,
    "phosphorus": 138,
    "info": null
  },
  {
    "id": 235,
    "disabled": null,
    "type": "meal",
    "name": "Fried anchovies",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": "Ikan Bilis",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fried anchovies ikan bilis",
    "weight-g": 60,
    "volume-ml": null,
    "calories": 125.6,
    "fat": 7.6,
    "saturated": 3.4,
    "polyunsaturated": 0.8,
    "monounsaturated": 3.1,
    "trans": null,
    "cholesterol": 20,
    "sodium": 1245,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 14.3,
    "potassium": 211,
    "calcium": 160,
    "iron": 0.7,
    "phosphorus": 195,
    "info": "Ikan Bilis"
  },
  {
    "id": 23,
    "disabled": null,
    "type": "snack",
    "name": "Fried banana",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Pisang goreng",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fried banana pisang goreng",
    "weight-g": 65,
    "volume-ml": null,
    "calories": 196.9,
    "fat": 5.3,
    "saturated": 3.5,
    "polyunsaturated": 0.3,
    "monounsaturated": 1.4,
    "trans": null,
    "cholesterol": 0,
    "sodium": 103,
    "carbs": 35.9,
    "fibre": 2.3,
    "sugar": 13.6,
    "protein": 1.4,
    "potassium": 218,
    "calcium": 31,
    "iron": 0.5,
    "phosphorus": 29,
    "info": "Pisang goreng"
  },
  {
    "id": 275,
    "disabled": null,
    "type": "meal",
    "name": "Fried basil with meat",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "pork / chicken / beef",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fried basil with meat pork / chicken beef",
    "weight-g": 123,
    "volume-ml": null,
    "calories": 229.8,
    "fat": 15,
    "saturated": 5,
    "polyunsaturated": 4,
    "monounsaturated": 5,
    "trans": null,
    "cholesterol": 76,
    "sodium": 431,
    "carbs": 2.4,
    "fibre": 0.3,
    "sugar": 2.4,
    "protein": 21.3,
    "potassium": 384,
    "calcium": 20,
    "iron": 2.2,
    "phosphorus": 214,
    "info": "e.g. pork / chicken / beef"
  },
  {
    "id": 905,
    "disabled": null,
    "type": "meal",
    "name": "Fried carrot cake with dark sauce)",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Chai Tau Kway (black)",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fried carrot cake with dark sauce) [chai|cai] [tau|tow|tao] [kway|kueh|kuey|kuih] (black)",
    "weight-g": 295,
    "volume-ml": null,
    "calories": 493.4,
    "fat": 35.4,
    "saturated": 14.1,
    "polyunsaturated": 4.2,
    "monounsaturated": 14.6,
    "trans": null,
    "cholesterol": 109,
    "sodium": 1289,
    "carbs": 41.6,
    "fibre": 5.9,
    "sugar": 26.6,
    "protein": 2.1,
    "potassium": 490,
    "calcium": 94,
    "iron": 3.3,
    "phosphorus": 156,
    "info": "Chai Tau Kway (black)"
  },
  {
    "id": 43,
    "disabled": null,
    "type": "snack",
    "name": "Fried carrot cake with egg",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Chai Tau Kway (white)",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fried carrot cake with egg [chai|cai] [tau|tow|tao] [kway|kueh|kuey|kuih] (white)",
    "weight-g": 292,
    "volume-ml": null,
    "calories": 469.3,
    "fat": 24.5,
    "saturated": 10.5,
    "polyunsaturated": 4.1,
    "monounsaturated": 8.8,
    "trans": null,
    "cholesterol": 41,
    "sodium": 1291,
    "carbs": 51.1,
    "fibre": 5,
    "sugar": 0,
    "protein": 11.1,
    "potassium": null,
    "calcium": 158,
    "iron": 6.1,
    "phosphorus": null,
    "info": "Chai Tau Kway (white)"
  },
  {
    "id": 112,
    "disabled": null,
    "type": "meal",
    "name": "Fried chicken cutlet",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fried chicken cutlet",
    "weight-g": 105,
    "volume-ml": null,
    "calories": 235,
    "fat": 14.1,
    "saturated": 2.6,
    "polyunsaturated": 1.9,
    "monounsaturated": 9.6,
    "trans": null,
    "cholesterol": 39,
    "sodium": 668,
    "carbs": 12.3,
    "fibre": 1.1,
    "sugar": 0.9,
    "protein": 13.4,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 458,
    "disabled": null,
    "type": "snack",
    "name": "Fried crackers",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Keropok",
    "info-brands": null,
    "info-examples": "prawn, fish",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fried crackers [keropok|krupuk|kerupuk] prawn, fish",
    "weight-g": 7,
    "volume-ml": null,
    "calories": 32.8,
    "fat": 1.6,
    "saturated": 0.7,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.7,
    "trans": null,
    "cholesterol": 1.3,
    "sodium": 84.3,
    "carbs": 4.1,
    "fibre": 0.1,
    "sugar": 0,
    "protein": 0.5,
    "potassium": 6,
    "calcium": 10,
    "iron": 0.1,
    "phosphorus": 6.5,
    "info": "Keropok, e.g. prawn, fish"
  },
  {
    "id": 25,
    "disabled": null,
    "type": "snack",
    "name": "Fried dough fritters",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "you tiao / you char kway, butterfly fritter, ham chim beng",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fried dough fritters you tiao / char kway, butterfly fritter, ham chim beng",
    "weight-g": 75,
    "volume-ml": null,
    "calories": 283.9,
    "fat": 12.7,
    "saturated": 10.6,
    "polyunsaturated": 0.4,
    "monounsaturated": 1.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 602,
    "carbs": 36.3,
    "fibre": 1,
    "sugar": 0.3,
    "protein": 6.1,
    "potassium": null,
    "calcium": 16,
    "iron": 0.5,
    "phosphorus": null,
    "info": "e.g. you tiao / you char kway, butterfly fritter, ham chim beng"
  },
  {
    "id": 345,
    "disabled": null,
    "type": "meal",
    "name": "Fried fish soup with noodles / rice, with evaporated milk",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fried fish soup with noodles / rice, evaporated milk",
    "weight-g": 646,
    "volume-ml": null,
    "calories": 534.2,
    "fat": 16.2,
    "saturated": 5.8,
    "polyunsaturated": 2.6,
    "monounsaturated": 5.7,
    "trans": null,
    "cholesterol": 3,
    "sodium": 2259,
    "carbs": 73.7,
    "fibre": 6.3,
    "sugar": 15.1,
    "protein": 23.4,
    "potassium": 316,
    "calcium": 168,
    "iron": 0.3,
    "phosphorus": 352,
    "info": null
  },
  {
    "id": 786,
    "disabled": null,
    "type": "meal",
    "name": "Fried Hong Kong egg noodle",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fried hong kong egg noodle",
    "weight-g": 408,
    "volume-ml": null,
    "calories": 692.7,
    "fat": 37.1,
    "saturated": 14.3,
    "polyunsaturated": 5.3,
    "monounsaturated": 15.9,
    "trans": null,
    "cholesterol": 257,
    "sodium": 1563,
    "carbs": 62.8,
    "fibre": 8.2,
    "sugar": 0.8,
    "protein": 26.9,
    "potassium": 437,
    "calcium": 196,
    "iron": 3.7,
    "phosphorus": 302,
    "info": null
  },
  {
    "id": 114,
    "disabled": null,
    "type": "meal",
    "name": "Fried kuey tiao",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Char Kway Teow",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fried [kuey|kueh|kuih] tiao char kway teow",
    "weight-g": 400,
    "volume-ml": null,
    "calories": 771.2,
    "fat": 40,
    "saturated": 30.4,
    "polyunsaturated": 3.2,
    "monounsaturated": 4.8,
    "trans": null,
    "cholesterol": 244,
    "sodium": 1520,
    "carbs": 79.2,
    "fibre": 2,
    "sugar": 0,
    "protein": 23.6,
    "potassium": null,
    "calcium": 204,
    "iron": 6.8,
    "phosphorus": null,
    "info": "Char Kway Teow"
  },
  {
    "id": 846,
    "disabled": null,
    "type": "snack",
    "name": "Fried mussel nuggets, breaded",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 4,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fried mussel nuggets, breaded",
    "weight-g": 150,
    "volume-ml": null,
    "calories": 303,
    "fat": 16.8,
    "saturated": 4,
    "polyunsaturated": 4.3,
    "monounsaturated": 6.8,
    "trans": null,
    "cholesterol": 92,
    "sodium": 546,
    "carbs": 15.5,
    "fibre": null,
    "sugar": null,
    "protein": 8.5,
    "potassium": 489,
    "calcium": 95,
    "iron": 21,
    "phosphorus": 282,
    "info": null
  },
  {
    "id": 116,
    "disabled": null,
    "type": "meal",
    "name": "Fried pandan chicken rolls",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fried pandan chicken rolls",
    "weight-g": 81,
    "volume-ml": null,
    "calories": 163,
    "fat": 12.3,
    "saturated": 5.3,
    "polyunsaturated": 3.8,
    "monounsaturated": 3.2,
    "trans": null,
    "cholesterol": 49,
    "sodium": 44,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 13.2,
    "potassium": 117,
    "calcium": 6,
    "iron": 0.7,
    "phosphorus": 92,
    "info": null
  },
  {
    "id": 845,
    "disabled": null,
    "type": "snack",
    "name": "Fried prawns, breaded / battered",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fried prawns, breaded / battered",
    "weight-g": 34,
    "volume-ml": null,
    "calories": 105,
    "fat": 6.3,
    "saturated": 1.1,
    "polyunsaturated": 3.2,
    "monounsaturated": 1.5,
    "trans": null,
    "cholesterol": 30,
    "sodium": 382,
    "carbs": 7.6,
    "fibre": 0.5,
    "sugar": 0.3,
    "protein": 4.3,
    "potassium": 37,
    "calcium": 14,
    "iron": 0.4,
    "phosphorus": 102,
    "info": null
  },
  {
    "id": 117,
    "disabled": null,
    "type": "meal",
    "name": "Fried rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fried rice",
    "weight-g": 250,
    "volume-ml": null,
    "calories": 530.9,
    "fat": 19.3,
    "saturated": 8,
    "polyunsaturated": 2.3,
    "monounsaturated": 8,
    "trans": null,
    "cholesterol": 103,
    "sodium": 728,
    "carbs": 72.8,
    "fibre": 0.5,
    "sugar": 13.3,
    "protein": 16.5,
    "potassium": 148,
    "calcium": 45,
    "iron": 1.5,
    "phosphorus": 130,
    "info": null
  },
  {
    "id": 777,
    "disabled": null,
    "type": "meal",
    "name": "Fried shrimps",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fried shrimps",
    "weight-g": 126,
    "volume-ml": null,
    "calories": 314.4,
    "fat": 16.8,
    "saturated": 3,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 102,
    "sodium": 300,
    "carbs": 25.8,
    "fibre": 0,
    "sugar": 1.8,
    "protein": 15,
    "potassium": null,
    "calcium": 60,
    "iron": 2.4,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1056,
    "disabled": null,
    "type": "meal",
    "name": "Fried fish soup, without evaporated milk",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fried fish soup, without evaporated milk",
    "weight-g": 465,
    "volume-ml": null,
    "calories": 275.7,
    "fat": 19.7,
    "saturated": 8.9,
    "polyunsaturated": 1.8,
    "monounsaturated": 7.2,
    "trans": null,
    "cholesterol": 21,
    "sodium": 1186,
    "carbs": 3.7,
    "fibre": 2.1,
    "sugar": 1.6,
    "protein": 20.9,
    "potassium": 397,
    "calcium": 90,
    "iron": 0.2,
    "phosphorus": 219,
    "info": null
  },
  {
    "id": 1067,
    "disabled": null,
    "type": "meal",
    "name": "Fried fish soup with evaporated milk",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fried fish soup with evaporated milk",
    "weight-g": 616,
    "volume-ml": null,
    "calories": 357.4,
    "fat": 22.2,
    "saturated": 4.6,
    "polyunsaturated": 1.9,
    "monounsaturated": 7.9,
    "trans": null,
    "cholesterol": 29,
    "sodium": 332,
    "carbs": 28.9,
    "fibre": 2.1,
    "sugar": 4.9,
    "protein": 17.3,
    "potassium": 111,
    "calcium": 140,
    "iron": 0.3,
    "phosphorus": 66,
    "info": null
  },
  {
    "id": 1068,
    "disabled": null,
    "type": "meal",
    "name": "Fried fish soup with noodles / rice, without evaporated milk",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fried fish soup with noodles / rice, without evaporated milk",
    "weight-g": 616,
    "volume-ml": null,
    "calories": 510,
    "fat": 15.6,
    "saturated": 5.4,
    "polyunsaturated": 2.6,
    "monounsaturated": 5.5,
    "trans": null,
    "cholesterol": 27,
    "sodium": 2230,
    "carbs": 70.5,
    "fibre": 6.4,
    "sugar": 11.9,
    "protein": 21.1,
    "potassium": 215,
    "calcium": 91,
    "iron": 0.2,
    "phosphorus": 272,
    "info": null
  },
  {
    "id": 773,
    "disabled": null,
    "type": "meal",
    "name": "Frog leg, herbal or with ginger and spring onion",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "frog leg, herbal or with ginger and spring onion",
    "weight-g": 279,
    "volume-ml": null,
    "calories": 391,
    "fat": 26,
    "saturated": 11,
    "polyunsaturated": 3,
    "monounsaturated": 11,
    "trans": null,
    "cholesterol": 64,
    "sodium": 1417,
    "carbs": 3,
    "fibre": 0.5,
    "sugar": 3,
    "protein": 36,
    "potassium": 407,
    "calcium": 340,
    "iron": 2,
    "phosphorus": 282,
    "info": null
  },
  {
    "id": 614,
    "disabled": null,
    "type": "drink",
    "name": "Frozen carbonated drink",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": "Slurpee",
    "info-examples": null,
    "info-portion-aid": "medium",
    "info-include-exclude": null,
    "search-text": "frozen carbonated drink slurpee",
    "weight-g": null,
    "volume-ml": 375,
    "calories": 104,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 50,
    "carbs": 26,
    "fibre": 0,
    "sugar": 32,
    "protein": 0,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Slurpee (medium)"
  },
  {
    "id": 826,
    "disabled": null,
    "type": "snack",
    "name": "Frozen yogurt, plain",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": 1,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "frozen [yogurt|yoghurt], plain",
    "weight-g": 144,
    "volume-ml": null,
    "calories": 228,
    "fat": 8.1,
    "saturated": 4.9,
    "polyunsaturated": 0.3,
    "monounsaturated": 2.3,
    "trans": null,
    "cholesterol": 2.8,
    "sodium": 125,
    "carbs": 35,
    "fibre": 0,
    "sugar": 34.6,
    "protein": 5.8,
    "potassium": 304,
    "calcium": 206,
    "iron": 0.4,
    "phosphorus": 186,
    "info": null
  },
  {
    "id": 827,
    "disabled": null,
    "type": "snack",
    "name": "Frozen yogurt, with toppings",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": 1,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "frozen [yogurt|yoghurt], with toppings",
    "weight-g": 170,
    "volume-ml": null,
    "calories": 460,
    "fat": 14,
    "saturated": 10.3,
    "polyunsaturated": 0.4,
    "monounsaturated": 2.7,
    "trans": null,
    "cholesterol": 2.8,
    "sodium": 131,
    "carbs": 41,
    "fibre": 0,
    "sugar": 40,
    "protein": 6.1,
    "potassium": 309,
    "calcium": 208,
    "iron": 0.4,
    "phosphorus": 188,
    "info": null
  },
  {
    "id": 376,
    "disabled": 1,
    "type": "snack",
    "name": "Fruit (green, edible with / without skin e.g. guava, green apple, kiwi)",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": null,
    "unit": null,
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fruit (green, edible with / without skin e.g. guava, green apple, kiwi)",
    "weight-g": null,
    "volume-ml": null,
    "calories": null,
    "fat": null,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": null,
    "fibre": null,
    "sugar": null,
    "protein": null,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 378,
    "disabled": 1,
    "type": "snack",
    "name": "Fruit (red, edible without skin e.g. watermelon)",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "wedge",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fruit (red, edible without skin e.g. watermelon)",
    "weight-g": null,
    "volume-ml": null,
    "calories": 88,
    "fat": 0,
    "saturated": 2,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 2,
    "carbs": 21,
    "fibre": 1,
    "sugar": 17,
    "protein": 1,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 377,
    "disabled": 1,
    "type": "snack",
    "name": "Fruit (yellow / purple / brown, edible without skin e.g. orange, dragonfruit, mangosteen)",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "fruit",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fruit (yellow / purple brown, edible without skin e.g. orange, dragonfruit, mangosteen)",
    "weight-g": null,
    "volume-ml": null,
    "calories": 96,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1,
    "carbs": 23,
    "fibre": 2,
    "sugar": 12,
    "protein": 1,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1043,
    "disabled": null,
    "type": "snack",
    "name": "Fruit flan cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fruit flan cake",
    "weight-g": 300,
    "volume-ml": null,
    "calories": 528.3,
    "fat": 24.3,
    "saturated": 7.1,
    "polyunsaturated": 4.4,
    "monounsaturated": 11.4,
    "trans": 0.4,
    "cholesterol": 42,
    "sodium": 180,
    "carbs": 64.5,
    "fibre": 6.6,
    "sugar": 35.3,
    "protein": 12.9,
    "potassium": 462,
    "calcium": 112,
    "iron": 2.4,
    "phosphorus": 254,
    "info": null
  },
  {
    "id": 1032,
    "disabled": null,
    "type": "snack",
    "name": "Mixed Fruit Tart",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mixed fruit tart",
    "weight-g": 195,
    "volume-ml": null,
    "calories": 376.3,
    "fat": 17.9,
    "saturated": 8.9,
    "polyunsaturated": 1,
    "monounsaturated": 6.9,
    "trans": null,
    "cholesterol": 73,
    "sodium": 367,
    "carbs": 44.6,
    "fibre": 2.2,
    "sugar": 18.2,
    "protein": 9.2,
    "potassium": 211,
    "calcium": 109,
    "iron": 0.9,
    "phosphorus": 144,
    "info": null
  },
  {
    "id": 482,
    "disabled": null,
    "type": "meal",
    "name": "Gado gado with sauce",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Indonesian salad",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "gado gado with sauce indonesian salad",
    "weight-g": 421,
    "volume-ml": null,
    "calories": 656.4,
    "fat": 40,
    "saturated": 14.3,
    "polyunsaturated": 10.5,
    "monounsaturated": 13.5,
    "trans": null,
    "cholesterol": 88,
    "sodium": 1082,
    "carbs": 44.2,
    "fibre": 19.4,
    "sugar": 15,
    "protein": 29.9,
    "potassium": null,
    "calcium": 244,
    "iron": 5,
    "phosphorus": null,
    "info": "Indonesian salad"
  },
  {
    "id": 626,
    "disabled": 1,
    "type": "meal",
    "name": "Gai choy/ mustard leaf",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "gai choy/ mustard leaf",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 28.3,
    "fat": 0.7,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": null,
    "carbs": 3.5,
    "fibre": 1,
    "sugar": 0,
    "protein": 2,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 591,
    "disabled": null,
    "type": "meal",
    "name": "Garlic bread, multigrain",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "garlic bread, multigrain",
    "weight-g": 50,
    "volume-ml": null,
    "calories": 49.5,
    "fat": 2.3,
    "saturated": 1,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 6,
    "sodium": 117,
    "carbs": 3.7,
    "fibre": 0.1,
    "sugar": 0.3,
    "protein": 3.5,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 590,
    "disabled": null,
    "type": "meal",
    "name": "Garlic bread, white",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "garlic bread, white",
    "weight-g": 67,
    "volume-ml": null,
    "calories": 74.2,
    "fat": 3.4,
    "saturated": 1.7,
    "polyunsaturated": 0.9,
    "monounsaturated": 0.9,
    "trans": null,
    "cholesterol": 0,
    "sodium": 111.3,
    "carbs": 9.1,
    "fibre": 0.5,
    "sugar": 0.4,
    "protein": 1.8,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 475,
    "disabled": null,
    "type": "snack",
    "name": "Gingko nuts",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 14,
    "unit": "nut",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "gingko nuts",
    "weight-g": 28.4,
    "volume-ml": null,
    "calories": 47.5,
    "fat": 0.5,
    "saturated": 0.1,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 87,
    "carbs": 10.2,
    "fibre": 2.6,
    "sugar": null,
    "protein": 0.6,
    "potassium": 51,
    "calcium": 1.1,
    "iron": 0.1,
    "phosphorus": 15.3,
    "info": null
  },
  {
    "id": 179,
    "disabled": null,
    "type": "drink",
    "name": "Ginseng tea, sweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ginseng tea, sweetened",
    "weight-g": null,
    "volume-ml": 180,
    "calories": 74,
    "fat": 2.3,
    "saturated": 1.1,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.9,
    "trans": null,
    "cholesterol": 2,
    "sodium": 113,
    "carbs": 12,
    "fibre": 0,
    "sugar": 12,
    "protein": 1.3,
    "potassium": 113,
    "calcium": 41,
    "iron": 0,
    "phosphorus": 43,
    "info": null
  },
  {
    "id": 371,
    "disabled": null,
    "type": "drink",
    "name": "Ginseng tea, unsweetened",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ginseng tea, unsweetened",
    "weight-g": null,
    "volume-ml": 200,
    "calories": 8,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 2,
    "fibre": null,
    "sugar": 1,
    "protein": 0,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 489,
    "disabled": null,
    "type": "snack",
    "name": "Glutinous rice dumpling, vegetarian",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "glutinous rice dumpling, vegetarian",
    "weight-g": 197,
    "volume-ml": null,
    "calories": 396,
    "fat": 8.1,
    "saturated": 3.2,
    "polyunsaturated": 1.2,
    "monounsaturated": 3.5,
    "trans": null,
    "cholesterol": 0,
    "sodium": 896,
    "carbs": 69,
    "fibre": 4.5,
    "sugar": 6.1,
    "protein": 11.6,
    "potassium": 179,
    "calcium": 35,
    "iron": 2.2,
    "phosphorus": 89,
    "info": null
  },
  {
    "id": 467,
    "disabled": null,
    "type": "meal",
    "name": "Gnocchi",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "potato, cheese",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "gnocchi potato, cheese",
    "weight-g": 188,
    "volume-ml": null,
    "calories": 250,
    "fat": 11.7,
    "saturated": 7.3,
    "polyunsaturated": 0.6,
    "monounsaturated": 3,
    "trans": null,
    "cholesterol": 30,
    "sodium": 543,
    "carbs": 32.1,
    "fibre": 1.9,
    "sugar": 1.8,
    "protein": 4.4,
    "potassium": 252,
    "calcium": 39.5,
    "iron": 1.3,
    "phosphorus": 75,
    "info": "e.g. potato, cheese"
  },
  {
    "id": 711,
    "disabled": null,
    "type": "snack",
    "name": "Gooseberries",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "gooseberries",
    "weight-g": 150,
    "volume-ml": null,
    "calories": 66,
    "fat": 0.9,
    "saturated": 0.1,
    "polyunsaturated": 0.5,
    "monounsaturated": 0.1,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 1.5,
    "carbs": 15.3,
    "fibre": 6.5,
    "sugar": null,
    "protein": 1.3,
    "potassium": 297,
    "calcium": 37.5,
    "iron": 0.5,
    "phosphorus": 41,
    "info": null
  },
  {
    "id": 656,
    "disabled": null,
    "type": "meal",
    "name": "Bitter gourd, boiled/ steamed",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": "Bitter melon",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bitter gourd, boiled/ steamed melon",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 19.3,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 21,
    "carbs": 3.6,
    "fibre": 1.8,
    "sugar": 3.6,
    "protein": 1,
    "potassium": 145,
    "calcium": 70,
    "iron": 0.9,
    "phosphorus": 13,
    "info": "Bitter melon"
  },
  {
    "id": 183,
    "disabled": null,
    "type": "snack",
    "name": "Grapefruit",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 0.5,
    "unit": "fruit",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "grapefruit",
    "weight-g": 98,
    "volume-ml": null,
    "calories": 38,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 1,
    "carbs": 9,
    "fibre": null,
    "sugar": null,
    "protein": 0.5,
    "potassium": 159,
    "calcium": 8.8,
    "iron": 0.2,
    "phosphorus": 15,
    "info": null
  },
  {
    "id": 385,
    "disabled": null,
    "type": "snack",
    "name": "Grapes",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 10,
    "unit": "grape",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "grapes",
    "weight-g": 101,
    "volume-ml": null,
    "calories": 65.7,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 4,
    "carbs": 15,
    "fibre": 0.4,
    "sugar": 15,
    "protein": 1.2,
    "potassium": 165,
    "calcium": 9,
    "iron": 0.2,
    "phosphorus": 10,
    "info": null
  },
  {
    "id": 171,
    "disabled": null,
    "type": "drink",
    "name": "Grass jelly drink, sweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": "Cincau",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "grass jelly drink, sweetened cincau",
    "weight-g": null,
    "volume-ml": 231,
    "calories": 135.6,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 53,
    "carbs": 33.9,
    "fibre": 1.2,
    "sugar": 32.2,
    "protein": 0,
    "potassium": 25,
    "calcium": 18,
    "iron": 0.2,
    "phosphorus": 0,
    "info": "Cincau"
  },
  {
    "id": 364,
    "disabled": null,
    "type": "drink",
    "name": "Grass jelly drink, unsweetened",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": "Cincau",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "grass jelly drink, unsweetened cincau",
    "weight-g": 231,
    "volume-ml": null,
    "calories": 14,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 53,
    "carbs": 3,
    "fibre": 0.1,
    "sugar": 1,
    "protein": 0.5,
    "potassium": 25,
    "calcium": 18,
    "iron": 0.2,
    "phosphorus": 0,
    "info": "Cincau"
  },
  {
    "id": 961,
    "disabled": null,
    "type": "meal",
    "name": "Gravy, braised sauce",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "ladle",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "for economical rice",
    "search-text": "gravy, braised sauce",
    "weight-g": 45,
    "volume-ml": null,
    "calories": 43.8,
    "fat": 2.6,
    "saturated": 1.2,
    "polyunsaturated": 0.2,
    "monounsaturated": 1.1,
    "trans": null,
    "cholesterol": null,
    "sodium": 272,
    "carbs": 3.2,
    "fibre": 0.4,
    "sugar": 2.6,
    "protein": 1.9,
    "potassium": 53,
    "calcium": 3,
    "iron": 0.7,
    "phosphorus": 18,
    "info": "for economical rice"
  },
  {
    "id": 960,
    "disabled": null,
    "type": "meal",
    "name": "Gravy, curry sauce",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "ladle",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "for economical rice",
    "search-text": "gravy, curry sauce",
    "weight-g": 45,
    "volume-ml": null,
    "calories": 86.9,
    "fat": 7.7,
    "saturated": 3.8,
    "polyunsaturated": 1.4,
    "monounsaturated": 2.7,
    "trans": null,
    "cholesterol": null,
    "sodium": 218,
    "carbs": 3.8,
    "fibre": 0.5,
    "sugar": 2.9,
    "protein": 0.6,
    "potassium": null,
    "calcium": 30,
    "iron": 1.2,
    "phosphorus": null,
    "info": "for economical rice"
  },
  {
    "id": 930,
    "disabled": null,
    "type": "meal",
    "name": "Gravy, hakka",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "for yong tau foo",
    "search-text": "gravy, hakka",
    "weight-g": 216,
    "volume-ml": null,
    "calories": 174.2,
    "fat": 11.4,
    "saturated": 4.2,
    "polyunsaturated": 1.2,
    "monounsaturated": 4.8,
    "trans": null,
    "cholesterol": 36,
    "sodium": 1148,
    "carbs": 7.2,
    "fibre": 0.2,
    "sugar": 3.5,
    "protein": 10.7,
    "potassium": 333,
    "calcium": 46,
    "iron": 1.2,
    "phosphorus": 459,
    "info": "for yong tau foo"
  },
  {
    "id": 185,
    "disabled": null,
    "type": "snack",
    "name": "Green / red bean soup, without coconut milk",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "green / red bean soup, without coconut milk",
    "weight-g": null,
    "volume-ml": 240,
    "calories": 132.2,
    "fat": 1,
    "saturated": 0,
    "polyunsaturated": 0.5,
    "monounsaturated": 0.5,
    "trans": null,
    "cholesterol": 0,
    "sodium": 2,
    "carbs": 22.6,
    "fibre": 10.3,
    "sugar": 8.2,
    "protein": 8.2,
    "potassium": 382,
    "calcium": 43,
    "iron": 1.2,
    "phosphorus": 127,
    "info": null
  },
  {
    "id": 186,
    "disabled": null,
    "type": "snack",
    "name": "Green / red bean soup with coconut milk",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "green / red bean soup with coconut milk",
    "weight-g": null,
    "volume-ml": 240,
    "calories": 252.9,
    "fat": 7.7,
    "saturated": 6.2,
    "polyunsaturated": 0.7,
    "monounsaturated": 0.4,
    "trans": null,
    "cholesterol": 0,
    "sodium": 45,
    "carbs": 34.8,
    "fibre": 10.4,
    "sugar": 15.4,
    "protein": 11.1,
    "potassium": 698,
    "calcium": 43,
    "iron": 2.7,
    "phosphorus": 204,
    "info": null
  },
  {
    "id": 654,
    "disabled": null,
    "type": "meal",
    "name": "Green marrow, stir-fried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": "Mao Gua / hairy melon",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "green marrow, stir-fried mao [gua|gourd] / hairy melon",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 12.6,
    "fat": 0.2,
    "saturated": 0.7,
    "polyunsaturated": 0.8,
    "monounsaturated": 2.8,
    "trans": null,
    "cholesterol": 57.3,
    "sodium": 492,
    "carbs": 2,
    "fibre": 1.7,
    "sugar": 1.7,
    "protein": 0.7,
    "potassium": 251,
    "calcium": 12,
    "iron": 0.3,
    "phosphorus": 0,
    "info": "Mao Gua / hairy melon"
  },
  {
    "id": 276,
    "disabled": null,
    "type": "meal",
    "name": "Green thai salad",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "green papaya / mango",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "green thai salad green papaya / mango",
    "weight-g": 201,
    "volume-ml": null,
    "calories": 211.6,
    "fat": 8.4,
    "saturated": 1.7,
    "polyunsaturated": 1.5,
    "monounsaturated": 4.8,
    "trans": null,
    "cholesterol": 0,
    "sodium": 178,
    "carbs": 30.1,
    "fibre": 3.3,
    "sugar": 20.6,
    "protein": 3.9,
    "potassium": null,
    "calcium": null,
    "iron": 0.2,
    "phosphorus": null,
    "info": "e.g. green papaya / mango"
  },
  {
    "id": 421,
    "disabled": null,
    "type": "meal",
    "name": "Guacamole",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "guacamole",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 60.7,
    "fat": 6.3,
    "saturated": 1.4,
    "polyunsaturated": 2.5,
    "monounsaturated": 2.5,
    "trans": null,
    "cholesterol": 5,
    "sodium": 178,
    "carbs": 0.4,
    "fibre": 0.9,
    "sugar": 0.4,
    "protein": 0.6,
    "potassium": 30,
    "calcium": 0,
    "iron": 0,
    "phosphorus": 0,
    "info": null
  },
  {
    "id": 386,
    "disabled": null,
    "type": "snack",
    "name": "Guava",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "fruit",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "guava",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 46.2,
    "fat": 0.2,
    "saturated": 0.1,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 23,
    "carbs": 10,
    "fibre": 4.9,
    "sugar": 9.8,
    "protein": 1.1,
    "potassium": 29,
    "calcium": 33,
    "iron": 1.2,
    "phosphorus": 15,
    "info": null
  },
  {
    "id": 522,
    "disabled": null,
    "type": "snack",
    "name": "Gulab jamun",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Deep-fried milk balls with sugar syrup",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "gulab jamun deep-fried milk balls with sugar syrup",
    "weight-g": 77,
    "volume-ml": null,
    "calories": 190.9,
    "fat": 4.5,
    "saturated": 2,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.9,
    "trans": null,
    "cholesterol": 10,
    "sodium": 113,
    "carbs": 33.9,
    "fibre": 0.4,
    "sugar": 31.5,
    "protein": 3.7,
    "potassium": 169,
    "calcium": 107,
    "iron": 0.2,
    "phosphorus": 74,
    "info": "Deep-fried milk balls with sugar syrup"
  },
  {
    "id": 505,
    "disabled": null,
    "type": "meal",
    "name": "Gyoza",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Japanese pan-fried dumplings / Guo Tie",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "gyoza japanese pan-fried dumplings / guo tie",
    "weight-g": 18,
    "volume-ml": null,
    "calories": 31.8,
    "fat": 1.4,
    "saturated": 0.5,
    "polyunsaturated": 0.3,
    "monounsaturated": 0.6,
    "trans": null,
    "cholesterol": 8,
    "sodium": 68,
    "carbs": 3.3,
    "fibre": 0.4,
    "sugar": 0.2,
    "protein": 1.5,
    "potassium": 27,
    "calcium": 6,
    "iron": 0.1,
    "phosphorus": 12,
    "info": "Japanese pan-fried dumplings / Guo Tie"
  },
  {
    "id": 278,
    "disabled": null,
    "type": "meal",
    "name": "Gyudon",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Japanese beef bowl",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "gyudon japanese beef bowl",
    "weight-g": 422,
    "volume-ml": null,
    "calories": 567.4,
    "fat": 12.6,
    "saturated": 4.4,
    "polyunsaturated": 2.6,
    "monounsaturated": 4.5,
    "trans": 0.4,
    "cholesterol": 93,
    "sodium": 1376,
    "carbs": 76,
    "fibre": 2.8,
    "sugar": 7.8,
    "protein": 37.5,
    "potassium": 688,
    "calcium": 33,
    "iron": 8.2,
    "phosphorus": 391,
    "info": "Japanese beef bowl"
  },
  {
    "id": 546,
    "disabled": null,
    "type": "meal",
    "name": "Hae bee hiam",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "tsp",
    "info-translation": "Spicy dried shrimps sambal",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "hae bee hiam spicy dried shrimps sambal",
    "weight-g": 12,
    "volume-ml": null,
    "calories": 54.9,
    "fat": 4.5,
    "saturated": 1.9,
    "polyunsaturated": 0.5,
    "monounsaturated": 1.9,
    "trans": null,
    "cholesterol": 22,
    "sodium": 301,
    "carbs": 0.9,
    "fibre": 0.2,
    "sugar": 0.9,
    "protein": 2.7,
    "potassium": 26,
    "calcium": 35,
    "iron": 0.4,
    "phosphorus": 18,
    "info": "Spicy dried shrimps sambal"
  },
  {
    "id": 279,
    "disabled": 1,
    "type": "meal",
    "name": "Haemul pajeon",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Korean seafood vegetable pancake",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "haemul pajeon korean seafood vegetable pancake",
    "weight-g": 39,
    "volume-ml": null,
    "calories": 78.1,
    "fat": 3.3,
    "saturated": 1.5,
    "polyunsaturated": 0.5,
    "monounsaturated": 1.4,
    "trans": null,
    "cholesterol": null,
    "sodium": 159,
    "carbs": 9.2,
    "fibre": null,
    "sugar": 0.6,
    "protein": 2.9,
    "potassium": null,
    "calcium": 16,
    "iron": 0.3,
    "phosphorus": null,
    "info": "Korean seafood vegetable pancake"
  },
  {
    "id": 280,
    "disabled": null,
    "type": "meal",
    "name": "Hainanese beef noodle",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "hainanese beef noodle",
    "weight-g": 391,
    "volume-ml": null,
    "calories": 395.1,
    "fat": 4.3,
    "saturated": 2,
    "polyunsaturated": 0.8,
    "monounsaturated": 1.2,
    "trans": null,
    "cholesterol": 35,
    "sodium": 1775,
    "carbs": 72.3,
    "fibre": 5.5,
    "sugar": 3,
    "protein": 16.8,
    "potassium": null,
    "calcium": 47,
    "iron": 4.3,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 450,
    "disabled": null,
    "type": "meal",
    "name": "Hainanese curry rice",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "hainanese curry rice",
    "weight-g": 400,
    "volume-ml": null,
    "calories": 614,
    "fat": 25.9,
    "saturated": 8.7,
    "polyunsaturated": 4.2,
    "monounsaturated": 12.9,
    "trans": null,
    "cholesterol": null,
    "sodium": 1051,
    "carbs": 75.5,
    "fibre": 4,
    "sugar": 5.7,
    "protein": 20,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 119,
    "disabled": null,
    "type": "meal",
    "name": "Ham",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ham",
    "weight-g": 32,
    "volume-ml": null,
    "calories": 30.3,
    "fat": 1.5,
    "saturated": 0.6,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.7,
    "trans": null,
    "cholesterol": 10,
    "sodium": 369,
    "carbs": 0.4,
    "fibre": 0,
    "sugar": 0,
    "protein": 3.8,
    "potassium": 36,
    "calcium": 16,
    "iron": 0.7,
    "phosphorus": 40,
    "info": null
  },
  {
    "id": 547,
    "disabled": null,
    "type": "drink",
    "name": "Hard liquor",
    "rating": 1,
    "alcohol": 1,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "shot",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "whisky, tequila, brandy, gin, vodka, rum",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "hard liquor whisky, tequila, brandy, gin, vodka, rum",
    "weight-g": null,
    "volume-ml": 42,
    "calories": 70,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 0,
    "potassium": 0.4,
    "calcium": 0,
    "iron": 0,
    "phosphorus": 1.3,
    "info": "e.g. whisky, tequila, brandy, gin, vodka, rum"
  },
  {
    "id": 819,
    "disabled": null,
    "type": "snack",
    "name": "Hashbrown",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "hashbrown",
    "weight-g": 53,
    "volume-ml": null,
    "calories": 155,
    "fat": 10,
    "saturated": 4.1,
    "polyunsaturated": 1.1,
    "monounsaturated": 4.5,
    "trans": null,
    "cholesterol": 0,
    "sodium": 330,
    "carbs": 14.9,
    "fibre": 2,
    "sugar": 0.2,
    "protein": 1.1,
    "potassium": 197,
    "calcium": 6,
    "iron": 0.3,
    "phosphorus": 51,
    "info": null
  },
  {
    "id": 1000,
    "disabled": null,
    "type": "snack",
    "name": "Hashima, reduced sugar",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Snow jelly soup, Xue Ge",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[hashima|hasma|harsmar], reduced sugar snow jelly soup, xue ge",
    "weight-g": 175,
    "volume-ml": null,
    "calories": 66.2,
    "fat": 0.2,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1.9,
    "carbs": 15.6,
    "fibre": 0.9,
    "sugar": 7.4,
    "protein": 0.5,
    "potassium": 32,
    "calcium": 0,
    "iron": 0,
    "phosphorus": 4,
    "info": "Snow jelly soup, Xue Ge"
  },
  {
    "id": 999,
    "disabled": null,
    "type": "snack",
    "name": "Hashima, with sugar",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Snow jelly soup, Xue Ge",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[hashima|hasma|harsmar], with sugar snow jelly soup, xue ge",
    "weight-g": 175,
    "volume-ml": null,
    "calories": 101,
    "fat": 0.2,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1.8,
    "carbs": 23.9,
    "fibre": 0.5,
    "sugar": 15.6,
    "protein": 0.9,
    "potassium": 32,
    "calcium": 0,
    "iron": 0,
    "phosphorus": 4,
    "info": "Snow jelly soup, Xue Ge"
  },
  {
    "id": 1026,
    "disabled": null,
    "type": "snack",
    "name": "Hazelnut cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "hazelnut cake",
    "weight-g": 61,
    "volume-ml": null,
    "calories": 220,
    "fat": 12,
    "saturated": 5.7,
    "polyunsaturated": 1,
    "monounsaturated": 4.8,
    "trans": null,
    "cholesterol": 39,
    "sodium": 131,
    "carbs": 26.2,
    "fibre": 1.2,
    "sugar": 18.1,
    "protein": 3.6,
    "potassium": 105,
    "calcium": 29,
    "iron": 1.2,
    "phosphorus": 311,
    "info": null
  },
  {
    "id": 721,
    "disabled": null,
    "type": "snack",
    "name": "Seeds, mixed",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "seeds, mixed",
    "weight-g": 15,
    "volume-ml": null,
    "calories": 100,
    "fat": 8,
    "saturated": 1,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 2,
    "carbs": 1.8,
    "fibre": 0.6,
    "sugar": 0.2,
    "protein": 4.2,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1006,
    "disabled": null,
    "type": "snack",
    "name": "Herbal jelly",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Gui Ling Gao",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "herbal jelly gui ling gao",
    "weight-g": 237,
    "volume-ml": null,
    "calories": 70,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 21,
    "carbs": 17.3,
    "fibre": 0,
    "sugar": 15.6,
    "protein": 0.2,
    "potassium": 192,
    "calcium": 26,
    "iron": 2.8,
    "phosphorus": 14,
    "info": "Gui Ling Gao"
  },
  {
    "id": 176,
    "disabled": null,
    "type": "drink",
    "name": "Herbal tea, sweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bottle",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "chrysanthemum, ginseng",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "herbal tea, sweetened chrysanthemum, ginseng",
    "weight-g": null,
    "volume-ml": 500,
    "calories": 140,
    "fat": 0,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": null,
    "sodium": null,
    "carbs": 34,
    "fibre": null,
    "sugar": 34,
    "protein": 0,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. chrysanthemum, ginseng"
  },
  {
    "id": 367,
    "disabled": null,
    "type": "drink",
    "name": "Herbal tea, unsweetened",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bottle",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "chamomile, peppermint",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "herbal tea, unsweetened chamomile, peppermint",
    "weight-g": null,
    "volume-ml": 500,
    "calories": null,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 4,
    "carbs": 1.1,
    "fibre": 0,
    "sugar": 0,
    "protein": 0,
    "potassium": 44,
    "calcium": 11,
    "iron": 0.4,
    "phosphorus": 0,
    "info": "e.g. chamomile, peppermint"
  },
  {
    "id": 281,
    "disabled": null,
    "type": "meal",
    "name": "Hokkien mee",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Fried prawn noodles",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "hokkien mee fried prawn noodles",
    "weight-g": 442,
    "volume-ml": null,
    "calories": 521,
    "fat": 19,
    "saturated": 7.3,
    "polyunsaturated": 2.7,
    "monounsaturated": 7.7,
    "trans": null,
    "cholesterol": 133,
    "sodium": 1423,
    "carbs": 69.4,
    "fibre": 4.4,
    "sugar": 17.2,
    "protein": 18.1,
    "potassium": 212,
    "calcium": 102,
    "iron": 3.1,
    "phosphorus": 181,
    "info": "Fried prawn noodles"
  },
  {
    "id": 476,
    "disabled": null,
    "type": "snack",
    "name": "Honey sea coconut",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "honey sea coconut",
    "weight-g": 240,
    "volume-ml": null,
    "calories": 193.6,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 16,
    "carbs": 47.8,
    "fibre": 0.3,
    "sugar": 35.4,
    "protein": 0.6,
    "potassium": 50,
    "calcium": 10,
    "iron": 0.4,
    "phosphorus": 9,
    "info": null
  },
  {
    "id": 387,
    "disabled": null,
    "type": "snack",
    "name": "Honeydew, cantaloupe, rockmelon",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "honeydew, cantaloupe, rockmelon",
    "weight-g": 125,
    "volume-ml": null,
    "calories": 49.9,
    "fat": 0.2,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 22.5,
    "carbs": 11.4,
    "fibre": 1,
    "sugar": 10.2,
    "protein": 0.7,
    "potassium": 285,
    "calcium": 7.5,
    "iron": 0.2,
    "phosphorus": 13.8,
    "info": null
  },
  {
    "id": 579,
    "disabled": null,
    "type": "drink",
    "name": "Horlicks C",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with evaporated milk",
    "search-text": "horlicks c",
    "weight-g": 250,
    "volume-ml": null,
    "calories": 95.7,
    "fat": 1.7,
    "saturated": 1,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.5,
    "trans": null,
    "cholesterol": 3,
    "sodium": 92,
    "carbs": 16.8,
    "fibre": 0,
    "sugar": 11.8,
    "protein": 3.3,
    "potassium": 134,
    "calcium": 86,
    "iron": 0.3,
    "phosphorus": 60,
    "info": "with evaporated milk"
  },
  {
    "id": 578,
    "disabled": null,
    "type": "drink",
    "name": "Horlicks O",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "without milk or sugar",
    "search-text": "horlicks o",
    "weight-g": 250,
    "volume-ml": null,
    "calories": 127.6,
    "fat": 0.8,
    "saturated": 0.4,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 92,
    "carbs": 27.6,
    "fibre": 0,
    "sugar": 22.6,
    "protein": 2.5,
    "potassium": 134,
    "calcium": 86,
    "iron": 0.3,
    "phosphorus": 60,
    "info": "without milk or sugar"
  },
  {
    "id": 14,
    "disabled": null,
    "type": "drink",
    "name": "Horlicks, 3-in-1",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "sachet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "horlicks, 3-in-1",
    "weight-g": 25,
    "volume-ml": 200,
    "calories": 106.7,
    "fat": 1.1,
    "saturated": 0.8,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 46,
    "carbs": 21.1,
    "fibre": 0.5,
    "sugar": 14.8,
    "protein": 3.1,
    "potassium": 170,
    "calcium": 532,
    "iron": 3.6,
    "phosphorus": 250,
    "info": null
  },
  {
    "id": 153,
    "disabled": null,
    "type": "snack",
    "name": "Hot dog with sauce",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with bun",
    "search-text": "hot dog with sauce",
    "weight-g": 183,
    "volume-ml": null,
    "calories": 372.2,
    "fat": 15.4,
    "saturated": 3.6,
    "polyunsaturated": 6.3,
    "monounsaturated": 5,
    "trans": null,
    "cholesterol": 21,
    "sodium": 1896,
    "carbs": 40.7,
    "fibre": 3,
    "sugar": 9.5,
    "protein": 17.7,
    "potassium": 707,
    "calcium": 66,
    "iron": 3.7,
    "phosphorus": 722,
    "info": "with bun"
  },
  {
    "id": 120,
    "disabled": null,
    "type": "meal",
    "name": "Hot dog, without sauce",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with bun",
    "search-text": "hot dog, without sauce",
    "weight-g": 123,
    "volume-ml": null,
    "calories": 261.1,
    "fat": 8.3,
    "saturated": 2.9,
    "polyunsaturated": 2.1,
    "monounsaturated": 2.9,
    "trans": null,
    "cholesterol": 14,
    "sodium": 1249,
    "carbs": 30.7,
    "fibre": 1.8,
    "sugar": 1.5,
    "protein": 15.9,
    "potassium": 648,
    "calcium": 43,
    "iron": 3,
    "phosphorus": 684,
    "info": "with bun"
  },
  {
    "id": 409,
    "disabled": null,
    "type": "snack",
    "name": "Hummus",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "hummus",
    "weight-g": 15,
    "volume-ml": null,
    "calories": 37.7,
    "fat": 2.7,
    "saturated": 0.4,
    "polyunsaturated": 1.3,
    "monounsaturated": 0.8,
    "trans": null,
    "cholesterol": 0,
    "sodium": 63.9,
    "carbs": 2.3,
    "fibre": 0.8,
    "sugar": 0.1,
    "protein": 1.2,
    "potassium": 46.8,
    "calcium": 7.1,
    "iron": 0.4,
    "phosphorus": 27.2,
    "info": null
  },
  {
    "id": 348,
    "disabled": 1,
    "type": "meal",
    "name": "Ice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": null,
    "unit": null,
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ice",
    "weight-g": null,
    "volume-ml": null,
    "calories": null,
    "fat": null,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": null,
    "fibre": null,
    "sugar": null,
    "protein": null,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 573,
    "disabled": null,
    "type": "drink",
    "name": "Ice blended coffee with cream",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "glass",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "frappuccino",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ice blended coffee with cream frappuccino",
    "weight-g": 372,
    "volume-ml": null,
    "calories": 300,
    "fat": 10.1,
    "saturated": 6.4,
    "polyunsaturated": 0.5,
    "monounsaturated": 2.6,
    "trans": null,
    "cholesterol": 33.5,
    "sodium": 138,
    "carbs": 46.5,
    "fibre": 0,
    "sugar": 43.7,
    "protein": 5.8,
    "potassium": 339,
    "calcium": 205,
    "iron": 0.1,
    "phosphorus": 179,
    "info": "e.g. frappuccino"
  },
  {
    "id": 572,
    "disabled": null,
    "type": "drink",
    "name": "Ice blended coffee, without cream",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "glass",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "frappuccino",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ice blended coffee, without cream frappuccino",
    "weight-g": 372,
    "volume-ml": null,
    "calories": 246.5,
    "fat": 3.4,
    "saturated": 2.1,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.9,
    "trans": null,
    "cholesterol": 14.9,
    "sodium": 138,
    "carbs": 48.5,
    "fibre": 0,
    "sugar": 45.5,
    "protein": 5.5,
    "potassium": 339,
    "calcium": 201,
    "iron": 0.1,
    "phosphorus": 175,
    "info": "e.g. frappuccino"
  },
  {
    "id": 829,
    "disabled": null,
    "type": "snack",
    "name": "Ice cream pie",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": "Hershey's sundae pie",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ice cream pie hershey's sundae",
    "weight-g": 79,
    "volume-ml": null,
    "calories": 310,
    "fat": 19,
    "saturated": 12,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 10,
    "sodium": 220,
    "carbs": 32,
    "fibre": 1,
    "sugar": 22,
    "protein": 3,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Hershey's sundae pie"
  },
  {
    "id": 213,
    "disabled": null,
    "type": "snack",
    "name": "Ice cream with cone",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cone",
    "info-translation": null,
    "info-brands": "Cornetto Royale",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ice cream with cone cornetto royale",
    "weight-g": 88,
    "volume-ml": null,
    "calories": 233,
    "fat": 9,
    "saturated": 8,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": null,
    "sodium": 80,
    "carbs": 35,
    "fibre": 1,
    "sugar": 22,
    "protein": 3,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Cornetto Royale"
  },
  {
    "id": 37,
    "disabled": null,
    "type": "snack",
    "name": "Ice cream, fried",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ice cream, fried",
    "weight-g": 133,
    "volume-ml": null,
    "calories": 375,
    "fat": 20.2,
    "saturated": 8.9,
    "polyunsaturated": 3.6,
    "monounsaturated": 6.3,
    "trans": null,
    "cholesterol": 45,
    "sodium": 198,
    "carbs": 43.6,
    "fibre": 1.6,
    "sugar": 27.8,
    "protein": 5.2,
    "potassium": 245,
    "calcium": 149,
    "iron": 1.1,
    "phosphorus": 153,
    "info": null
  },
  {
    "id": 535,
    "disabled": null,
    "type": "snack",
    "name": "Ice cream, low calorie",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "scoop",
    "info-translation": null,
    "info-brands": "Callery's",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ice cream, low calorie callery's",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 100,
    "fat": 6.4,
    "saturated": 4.2,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0.1,
    "cholesterol": 9.7,
    "sodium": 107,
    "carbs": 24.3,
    "fibre": 7.8,
    "sugar": 4.2,
    "protein": 3.8,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Callery's"
  },
  {
    "id": 536,
    "disabled": null,
    "type": "snack",
    "name": "Ice cream, low sugar",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "scoop",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ice cream, low sugar",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 144,
    "fat": 8.6,
    "saturated": 5.7,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 7,
    "sodium": null,
    "carbs": 13,
    "fibre": 6.5,
    "sugar": 5.6,
    "protein": 3.8,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 211,
    "disabled": null,
    "type": "snack",
    "name": "Ice cream, regular",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "scoop",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ice cream, regular",
    "weight-g": 50,
    "volume-ml": null,
    "calories": 104,
    "fat": 5.5,
    "saturated": 3.4,
    "polyunsaturated": 0.2,
    "monounsaturated": 1.5,
    "trans": null,
    "cholesterol": 22,
    "sodium": 40,
    "carbs": 11.8,
    "fibre": 0.4,
    "sugar": 10.6,
    "protein": 1.8,
    "potassium": 100,
    "calcium": 64,
    "iron": 0,
    "phosphorus": 53,
    "info": null
  },
  {
    "id": 537,
    "disabled": null,
    "type": "snack",
    "name": "Ice cream, sorbet",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ice cream, sorbet",
    "weight-g": 200,
    "volume-ml": null,
    "calories": 189.2,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 16,
    "carbs": 46.3,
    "fibre": 0.2,
    "sugar": 40,
    "protein": 1,
    "potassium": 200,
    "calcium": 18,
    "iron": 0.9,
    "phosphorus": 26,
    "info": null
  },
  {
    "id": 1008,
    "disabled": null,
    "type": "snack",
    "name": "Ice jelly",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "lemongrass, aiyu, lime",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ice jelly lemongrass, aiyu, lime",
    "weight-g": 250,
    "volume-ml": null,
    "calories": 95,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 23.5,
    "fibre": 1.5,
    "sugar": 17.5,
    "protein": 0.3,
    "potassium": null,
    "calcium": 15,
    "iron": 0.5,
    "phosphorus": null,
    "info": "e.g. lemongrass, aiyu, lime"
  },
  {
    "id": 28,
    "disabled": null,
    "type": "snack",
    "name": "Ice kacang",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ice kacang",
    "weight-g": 350,
    "volume-ml": null,
    "calories": 181.6,
    "fat": 0.4,
    "saturated": 0.2,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 40.3,
    "fibre": 4,
    "sugar": 33.3,
    "protein": 4.2,
    "potassium": null,
    "calcium": 70,
    "iron": 1.4,
    "phosphorus": 70,
    "info": null
  },
  {
    "id": 460,
    "disabled": null,
    "type": "meal",
    "name": "Ikan assam pedas",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": "Sour and spicy fish stew",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ikan [assam|asam] pedas sour and spicy fish stew",
    "weight-g": 227,
    "volume-ml": null,
    "calories": 219.3,
    "fat": 6.5,
    "saturated": 3.7,
    "polyunsaturated": 0.3,
    "monounsaturated": 1.6,
    "trans": null,
    "cholesterol": 107,
    "sodium": 937.2,
    "carbs": 3.6,
    "fibre": 3.2,
    "sugar": 3.6,
    "protein": 36.6,
    "potassium": 630.7,
    "calcium": 95.7,
    "iron": 1.1,
    "phosphorus": 316.2,
    "info": "Sour and spicy fish stew"
  },
  {
    "id": 462,
    "disabled": null,
    "type": "meal",
    "name": "Ikan bakar",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Grilled fish",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ikan bakar grilled fish",
    "weight-g": 94,
    "volume-ml": null,
    "calories": 153.1,
    "fat": 4.7,
    "saturated": 0.9,
    "polyunsaturated": 2.4,
    "monounsaturated": 1.4,
    "trans": null,
    "cholesterol": 70,
    "sodium": 225,
    "carbs": 0.5,
    "fibre": 0,
    "sugar": 0.5,
    "protein": 27.2,
    "potassium": 416,
    "calcium": 127,
    "iron": 1.3,
    "phosphorus": 211,
    "info": "Grilled fish"
  },
  {
    "id": 66,
    "disabled": null,
    "type": "snack",
    "name": "Rice kheer",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Indian rice pudding",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "rice kheer indian rice pudding",
    "weight-g": 218,
    "volume-ml": null,
    "calories": 352.1,
    "fat": 13.7,
    "saturated": 6.7,
    "polyunsaturated": 1.1,
    "monounsaturated": 5.9,
    "trans": null,
    "cholesterol": 26,
    "sodium": 137,
    "carbs": 43,
    "fibre": 3.1,
    "sugar": 15.5,
    "protein": 14.2,
    "potassium": 390,
    "calcium": 438,
    "iron": 0.7,
    "phosphorus": 268,
    "info": "Indian rice pudding"
  },
  {
    "id": 154,
    "disabled": null,
    "type": "meal",
    "name": "Indian rojak, deep fried",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "indian rojak, deep fried",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 203,
    "fat": 15.7,
    "saturated": 4,
    "polyunsaturated": 6.7,
    "monounsaturated": 4.4,
    "trans": null,
    "cholesterol": null,
    "sodium": 198,
    "carbs": 20,
    "fibre": 1,
    "sugar": 0,
    "protein": 10,
    "potassium": 112,
    "calcium": 217,
    "iron": 2,
    "phosphorus": 178,
    "info": null
  },
  {
    "id": 177,
    "disabled": null,
    "type": "drink",
    "name": "Instant cereal oat drink, 3-in-1",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "sachet",
    "info-translation": null,
    "info-brands": "Quaker, Super NutreMill",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "instant cereal oat drink, 3-in-1 quaker, super nutremill",
    "weight-g": 28,
    "volume-ml": 150,
    "calories": 112.4,
    "fat": 2.8,
    "saturated": 0.9,
    "polyunsaturated": 0.6,
    "monounsaturated": 1.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 88,
    "carbs": 20,
    "fibre": 1.1,
    "sugar": 9.1,
    "protein": 1.8,
    "potassium": null,
    "calcium": 360,
    "iron": 0.6,
    "phosphorus": null,
    "info": "e.g. Quaker, Super NutreMill"
  },
  {
    "id": 597,
    "disabled": null,
    "type": "meal",
    "name": "Instant bowl noodle",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": "Nissin, Myojo, Kang Shi Fu",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "instant bowl noodle nissin, myojo, kang shi fu",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 505,
    "fat": 18,
    "saturated": 10,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 2576,
    "carbs": 76,
    "fibre": null,
    "sugar": 2,
    "protein": 10,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Nissin, Myojo, Kang Shi Fu"
  },
  {
    "id": 595,
    "disabled": null,
    "type": "meal",
    "name": "Instant cup noodle",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": "Nissin",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "instant cup noodle nissin",
    "weight-g": 75,
    "volume-ml": null,
    "calories": 335.2,
    "fat": 16.8,
    "saturated": 9.2,
    "polyunsaturated": 1.6,
    "monounsaturated": 5,
    "trans": null,
    "cholesterol": null,
    "sodium": 1830,
    "carbs": 38.8,
    "fibre": 1,
    "sugar": 6,
    "protein": 7.2,
    "potassium": 160,
    "calcium": 40,
    "iron": 2.8,
    "phosphorus": 110,
    "info": "e.g. Nissin"
  },
  {
    "id": 596,
    "disabled": null,
    "type": "meal",
    "name": "Instant noodle, korean style",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "packet",
    "info-translation": null,
    "info-brands": "Samyang, Nongshim",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "instant noodle, korean style samyang, nongshim",
    "weight-g": 470,
    "volume-ml": null,
    "calories": 532,
    "fat": 16,
    "saturated": 8,
    "polyunsaturated": 4,
    "monounsaturated": 4,
    "trans": null,
    "cholesterol": null,
    "sodium": 1200,
    "carbs": 85,
    "fibre": 1,
    "sugar": 7,
    "protein": 12,
    "potassium": 160,
    "calcium": 88,
    "iron": 2.8,
    "phosphorus": 110,
    "info": "e.g. Samyang, Nongshim"
  },
  {
    "id": 594,
    "disabled": null,
    "type": "meal",
    "name": "Instant noodle, non-fried",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "packet",
    "info-translation": null,
    "info-brands": "Koka, Woh Hup",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "instant noodle, non-fried koka, woh hup",
    "weight-g": 60,
    "volume-ml": null,
    "calories": 253.6,
    "fat": 6,
    "saturated": 0.2,
    "polyunsaturated": 1.6,
    "monounsaturated": 3.4,
    "trans": null,
    "cholesterol": 0,
    "sodium": 570,
    "carbs": 41,
    "fibre": 2,
    "sugar": 4,
    "protein": 8.9,
    "potassium": 160,
    "calcium": 20,
    "iron": 1.1,
    "phosphorus": 110,
    "info": "e.g. Koka, Woh Hup"
  },
  {
    "id": 146,
    "disabled": null,
    "type": "meal",
    "name": "Instant noodles, soup",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": "Indomie, Myojo, Koka",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "instant noodles, soup indomie, myojo, koka",
    "weight-g": 85,
    "volume-ml": null,
    "calories": 363,
    "fat": 15,
    "saturated": 6,
    "polyunsaturated": 4.5,
    "monounsaturated": 4.5,
    "trans": null,
    "cholesterol": null,
    "sodium": 900,
    "carbs": 49,
    "fibre": 3,
    "sugar": 8,
    "protein": 8,
    "potassium": 160,
    "calcium": 88,
    "iron": 2.8,
    "phosphorus": 110,
    "info": "e.g. Indomie, Myojo, Koka"
  },
  {
    "id": 527,
    "disabled": null,
    "type": "meal",
    "name": "Ipoh hor fun",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ipoh hor fun",
    "weight-g": 340,
    "volume-ml": null,
    "calories": 342.1,
    "fat": 10.5,
    "saturated": 3.4,
    "polyunsaturated": 2,
    "monounsaturated": 4.8,
    "trans": null,
    "cholesterol": 54,
    "sodium": 1241,
    "carbs": 50,
    "fibre": 5.1,
    "sugar": 0,
    "protein": 11.9,
    "potassium": 173,
    "calcium": 24,
    "iron": 0,
    "phosphorus": 92,
    "info": null
  },
  {
    "id": 702,
    "disabled": null,
    "type": "snack",
    "name": "Jackfruit",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "seed",
    "info-translation": "Nangka",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[jackfruit|jak|jaca] nangka",
    "weight-g": 41,
    "volume-ml": null,
    "calories": 33.9,
    "fat": 0.3,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 7,
    "carbs": 7.6,
    "fibre": 0.8,
    "sugar": 6.3,
    "protein": 0.2,
    "potassium": 103,
    "calcium": 6,
    "iron": 0.2,
    "phosphorus": 9,
    "info": "Nangka"
  },
  {
    "id": 121,
    "disabled": null,
    "type": "meal",
    "name": "Jam",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "jam",
    "weight-g": 20,
    "volume-ml": null,
    "calories": 55.6,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 6.4,
    "carbs": 13.8,
    "fibre": 0.2,
    "sugar": 9.7,
    "protein": 0.1,
    "potassium": 15.4,
    "calcium": 4,
    "iron": 0.1,
    "phosphorus": 3.8,
    "info": null
  },
  {
    "id": 853,
    "disabled": null,
    "type": "meal",
    "name": "Japanese curry katsu udon",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "japanese curry katsu udon",
    "weight-g": 257,
    "volume-ml": null,
    "calories": 572,
    "fat": 15.5,
    "saturated": 5.7,
    "polyunsaturated": 3.1,
    "monounsaturated": 5.5,
    "trans": null,
    "cholesterol": 85,
    "sodium": 980,
    "carbs": 71,
    "fibre": 1.1,
    "sugar": 1.5,
    "protein": 32.1,
    "potassium": 356,
    "calcium": 40,
    "iron": 1.9,
    "phosphorus": 250,
    "info": null
  },
  {
    "id": 352,
    "disabled": null,
    "type": "meal",
    "name": "Japanese curry rice with chicken",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "japanese curry rice with chicken",
    "weight-g": 546,
    "volume-ml": null,
    "calories": 509,
    "fat": 20.1,
    "saturated": 8.9,
    "polyunsaturated": 2,
    "monounsaturated": 8.1,
    "trans": 0.3,
    "cholesterol": 109,
    "sodium": 978,
    "carbs": 74.1,
    "fibre": 5.6,
    "sugar": 12.7,
    "protein": 31,
    "potassium": 654,
    "calcium": 63,
    "iron": 2.3,
    "phosphorus": 318,
    "info": null
  },
  {
    "id": 122,
    "disabled": null,
    "type": "meal",
    "name": "Japanese curry rice with chicken katsu",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "japanese curry rice with chicken katsu",
    "weight-g": 597,
    "volume-ml": null,
    "calories": 701,
    "fat": 14.1,
    "saturated": 5.7,
    "polyunsaturated": 1.4,
    "monounsaturated": 2.2,
    "trans": null,
    "cholesterol": 109,
    "sodium": 922,
    "carbs": 70.3,
    "fibre": 1.6,
    "sugar": 3.6,
    "protein": 46.2,
    "potassium": 459,
    "calcium": 27,
    "iron": 4.3,
    "phosphorus": 371,
    "info": null
  },
  {
    "id": 852,
    "disabled": null,
    "type": "meal",
    "name": "Japanese curry udon",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "japanese curry udon",
    "weight-g": 524,
    "volume-ml": null,
    "calories": 383,
    "fat": 10,
    "saturated": 5.8,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 1478,
    "carbs": 60.3,
    "fibre": 6.3,
    "sugar": null,
    "protein": 13,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 71,
    "disabled": null,
    "type": "snack",
    "name": "Japanese sweet mochi",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "stick",
    "info-translation": "Daifuku",
    "info-brands": null,
    "info-examples": "soy, green tea, red bean",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "japanese sweet mochi daifuku soy, green tea, red bean",
    "weight-g": 51,
    "volume-ml": null,
    "calories": 112.7,
    "fat": 0.3,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 83,
    "carbs": 25.3,
    "fibre": 0.7,
    "sugar": 11.7,
    "protein": 2.2,
    "potassium": 53,
    "calcium": 9,
    "iron": 0.2,
    "phosphorus": 31,
    "info": "Daifuku, e.g. soy, green tea, red bean"
  },
  {
    "id": 282,
    "disabled": null,
    "type": "meal",
    "name": "Japchae",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": "Korean stir-fried glass noodles",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "japchae korean stir-fried glass noodles",
    "weight-g": 442,
    "volume-ml": null,
    "calories": 503.9,
    "fat": 17.1,
    "saturated": 5.5,
    "polyunsaturated": 4.6,
    "monounsaturated": 7,
    "trans": null,
    "cholesterol": null,
    "sodium": 2033,
    "carbs": 62.3,
    "fibre": 11.9,
    "sugar": 12.8,
    "protein": 25.2,
    "potassium": null,
    "calcium": 77,
    "iron": 6.1,
    "phosphorus": null,
    "info": "Korean stir-fried glass noodles"
  },
  {
    "id": 483,
    "disabled": null,
    "type": "snack",
    "name": "Jemput jemput pisang",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Fried banana balls / cekodok pisang",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "jemput jemput pisang fried banana balls / cekodok",
    "weight-g": 76,
    "volume-ml": null,
    "calories": 219.8,
    "fat": 7.8,
    "saturated": 3.5,
    "polyunsaturated": 0.8,
    "monounsaturated": 3.2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 73,
    "carbs": 33.4,
    "fibre": 1.6,
    "sugar": 10.3,
    "protein": 4,
    "potassium": 161,
    "calcium": 13,
    "iron": 0.2,
    "phosphorus": 37,
    "info": "Fried banana balls / cekodok pisang"
  },
  {
    "id": 876,
    "disabled": null,
    "type": "meal",
    "name": "Jajangmyeon",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Korean noodles in black bean sauce",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "jajangmyeon korean noodles in black bean sauce",
    "weight-g": 484,
    "volume-ml": null,
    "calories": 627.8,
    "fat": 22.6,
    "saturated": 4.9,
    "polyunsaturated": 7.6,
    "monounsaturated": 8.1,
    "trans": null,
    "cholesterol": 80,
    "sodium": 1724,
    "carbs": 77.2,
    "fibre": 5.3,
    "sugar": 8.2,
    "protein": 28.9,
    "potassium": 847,
    "calcium": 58,
    "iron": 1.2,
    "phosphorus": 839,
    "info": "Korean noodles in black bean sauce"
  },
  {
    "id": 877,
    "disabled": null,
    "type": "meal",
    "name": "Jjamppong",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Korean spicy seafood noodle soup",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "jjamppong korean spicy seafood noodle soup",
    "weight-g": 658,
    "volume-ml": null,
    "calories": 478,
    "fat": 18.4,
    "saturated": 7.1,
    "polyunsaturated": 1.7,
    "monounsaturated": 6.5,
    "trans": null,
    "cholesterol": 145,
    "sodium": 1720,
    "carbs": 52,
    "fibre": 2.2,
    "sugar": 5.1,
    "protein": 26.1,
    "potassium": 610,
    "calcium": 144,
    "iron": 4,
    "phosphorus": 223,
    "info": "Korean spicy seafood noodle soup"
  },
  {
    "id": 158,
    "disabled": null,
    "type": "drink",
    "name": "Juice, natural, unsweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "blended, cold pressed",
    "search-text": "juice, natural, unsweetened",
    "weight-g": null,
    "volume-ml": 366,
    "calories": 109.9,
    "fat": 0.3,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 157,
    "carbs": 22.1,
    "fibre": 1,
    "sugar": 8.2,
    "protein": 4.7,
    "potassium": 892,
    "calcium": 64,
    "iron": 1.9,
    "phosphorus": 107,
    "info": "blended, cold pressed"
  },
  {
    "id": 2,
    "disabled": null,
    "type": "drink",
    "name": "Juice, sweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "juice, sweetened",
    "weight-g": null,
    "volume-ml": 249,
    "calories": 135.6,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 5,
    "carbs": 33.4,
    "fibre": 0.5,
    "sugar": 23.3,
    "protein": 0.5,
    "potassium": 105,
    "calcium": 5,
    "iron": 0.3,
    "phosphorus": 10,
    "info": null
  },
  {
    "id": 630,
    "disabled": null,
    "type": "meal",
    "name": "Kai lan, stir-fried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[kai|gai] lan, stir-fried",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 76,
    "fat": 5.4,
    "saturated": 0.6,
    "polyunsaturated": 3.2,
    "monounsaturated": 1.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 123,
    "carbs": 5.6,
    "fibre": 0.8,
    "sugar": 0,
    "protein": 1.9,
    "potassium": 228,
    "calcium": 72,
    "iron": 0.9,
    "phosphorus": 28,
    "info": null
  },
  {
    "id": 850,
    "disabled": null,
    "type": "meal",
    "name": "Kaisen don",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Japanese seafood rice bowl",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "kaisen don japanese seafood rice bowl",
    "weight-g": 409,
    "volume-ml": null,
    "calories": 552,
    "fat": 15.2,
    "saturated": 5.7,
    "polyunsaturated": 2.8,
    "monounsaturated": 6.5,
    "trans": 0.1,
    "cholesterol": null,
    "sodium": 1472,
    "carbs": 85.9,
    "fibre": 10.6,
    "sugar": 5.3,
    "protein": 32.7,
    "potassium": null,
    "calcium": 52,
    "iron": 1.3,
    "phosphorus": null,
    "info": "Japanese seafood rice bowl"
  },
  {
    "id": 670,
    "disabled": null,
    "type": "meal",
    "name": "Kale, stir-fried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "kale, stir-fried",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 76,
    "fat": 5.4,
    "saturated": 0.6,
    "polyunsaturated": 3.2,
    "monounsaturated": 1.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 123,
    "carbs": 5.6,
    "fibre": 0.8,
    "sugar": 0,
    "protein": 1.9,
    "potassium": 228,
    "calcium": 72,
    "iron": 0.9,
    "phosphorus": 28,
    "info": null
  },
  {
    "id": 628,
    "disabled": null,
    "type": "meal",
    "name": "Kang kung, stir-fried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": "Water spinach",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "kang [kung|kong], stir-fried water spinach",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 76,
    "fat": 5.2,
    "saturated": 0.5,
    "polyunsaturated": 3,
    "monounsaturated": 1.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 382,
    "carbs": 4.8,
    "fibre": 1.3,
    "sugar": 4.4,
    "protein": 2.8,
    "potassium": 83,
    "calcium": 75,
    "iron": 4.4,
    "phosphorus": 13,
    "info": "Water spinach"
  },
  {
    "id": 859,
    "disabled": null,
    "type": "meal",
    "name": "Karaage",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 2,
    "unit": "piece",
    "info-translation": "Japanese fried chicken",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "karaage japanese fried chicken",
    "weight-g": 74,
    "volume-ml": null,
    "calories": 212,
    "fat": 14,
    "saturated": 2.6,
    "polyunsaturated": 6.2,
    "monounsaturated": 4.8,
    "trans": 0.2,
    "cholesterol": 60,
    "sodium": 292,
    "carbs": 9.8,
    "fibre": 0.3,
    "sugar": 0.7,
    "protein": 12,
    "potassium": 154,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "Japanese fried chicken"
  },
  {
    "id": 123,
    "disabled": null,
    "type": "meal",
    "name": "Kaya",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "kaya",
    "weight-g": 20,
    "volume-ml": null,
    "calories": 63.2,
    "fat": 2.4,
    "saturated": 1.8,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.4,
    "trans": null,
    "cholesterol": 23,
    "sodium": 8,
    "carbs": 9.6,
    "fibre": 0.2,
    "sugar": 8.7,
    "protein": 0.8,
    "potassium": 24,
    "calcium": 5,
    "iron": 0.2,
    "phosphorus": 14,
    "info": null
  },
  {
    "id": 739,
    "disabled": null,
    "type": "snack",
    "name": "Kaya butter puff, fried",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "kaya butter puff, fried",
    "weight-g": 90,
    "volume-ml": null,
    "calories": 302,
    "fat": 18,
    "saturated": 5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 34,
    "carbs": 31,
    "fibre": 1,
    "sugar": null,
    "protein": 4,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 451,
    "disabled": null,
    "type": "snack",
    "name": "Kaya butter toast, traditional",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": "Ya Kun",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "kaya butter toast, traditional ya kun",
    "weight-g": 53,
    "volume-ml": null,
    "calories": 205.3,
    "fat": 11.3,
    "saturated": 7.4,
    "polyunsaturated": 0.6,
    "monounsaturated": 2.8,
    "trans": null,
    "cholesterol": 48.5,
    "sodium": 204,
    "carbs": 22.7,
    "fibre": 0.9,
    "sugar": 9.3,
    "protein": 3.2,
    "potassium": 56.5,
    "calcium": 20.5,
    "iron": 0.5,
    "phosphorus": 40,
    "info": "e.g. Ya Kun"
  },
  {
    "id": 283,
    "disabled": null,
    "type": "meal",
    "name": "Kebab, grilled meat",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 2,
    "unit": "skewer",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "kebab, grilled meat",
    "weight-g": 145,
    "volume-ml": null,
    "calories": 403.4,
    "fat": 32.6,
    "saturated": 10.8,
    "polyunsaturated": 2.1,
    "monounsaturated": 18.8,
    "trans": null,
    "cholesterol": 64,
    "sodium": 69,
    "carbs": 4.1,
    "fibre": 0.8,
    "sugar": 1.9,
    "protein": 23.4,
    "potassium": 261,
    "calcium": 14,
    "iron": 2.6,
    "phosphorus": 197,
    "info": null
  },
  {
    "id": 989,
    "disabled": null,
    "type": "meal",
    "name": "Ketupat",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Indonesian rice dumplings",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[ketupat|kupat] indonesian rice dumplings",
    "weight-g": 95,
    "volume-ml": null,
    "calories": 88,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 4,
    "carbs": 19.8,
    "fibre": 1,
    "sugar": 0,
    "protein": 2.2,
    "potassium": null,
    "calcium": 4,
    "iron": 0.2,
    "phosphorus": null,
    "info": "Indonesian rice dumplings"
  },
  {
    "id": 422,
    "disabled": null,
    "type": "meal",
    "name": "Kimchi",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": 1,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "kimchi",
    "weight-g": 75,
    "volume-ml": null,
    "calories": 13.9,
    "fat": 0.4,
    "saturated": 0.1,
    "polyunsaturated": 0.2,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 373.5,
    "carbs": 1.8,
    "fibre": 1.2,
    "sugar": 0.8,
    "protein": 0.8,
    "potassium": 133,
    "calcium": 24.8,
    "iron": 1.9,
    "phosphorus": 18,
    "info": null
  },
  {
    "id": 860,
    "disabled": null,
    "type": "meal",
    "name": "Kimchi pancake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": 1,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 0.3,
    "unit": "pancake",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "kimchi pancake",
    "weight-g": 76,
    "volume-ml": null,
    "calories": 204,
    "fat": 12.8,
    "saturated": 4.8,
    "polyunsaturated": 2.2,
    "monounsaturated": 5.8,
    "trans": null,
    "cholesterol": null,
    "sodium": 388,
    "carbs": 19.4,
    "fibre": 1.6,
    "sugar": 2,
    "protein": 3,
    "potassium": null,
    "calcium": 24,
    "iron": 0.6,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 857,
    "disabled": null,
    "type": "meal",
    "name": "Kimchi ramen",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": 1,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "kimchi ramen",
    "weight-g": 519,
    "volume-ml": null,
    "calories": 559,
    "fat": 15.8,
    "saturated": 6.6,
    "polyunsaturated": 1.7,
    "monounsaturated": 9,
    "trans": null,
    "cholesterol": 89,
    "sodium": 1522,
    "carbs": 56.7,
    "fibre": 6.7,
    "sugar": 4.9,
    "protein": 47.1,
    "potassium": 751,
    "calcium": 140,
    "iron": 5,
    "phosphorus": 342,
    "info": null
  },
  {
    "id": 862,
    "disabled": null,
    "type": "meal",
    "name": "Kimchi soup",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": 1,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "without noodles, with pork",
    "search-text": "kimchi soup",
    "weight-g": 462,
    "volume-ml": null,
    "calories": 222,
    "fat": 12.9,
    "saturated": 4.5,
    "polyunsaturated": 2.7,
    "monounsaturated": 5,
    "trans": null,
    "cholesterol": 28,
    "sodium": 2365,
    "carbs": 9.7,
    "fibre": 8.8,
    "sugar": 9.7,
    "protein": 16.6,
    "potassium": 647,
    "calcium": 157,
    "iron": 3.7,
    "phosphorus": 171,
    "info": "without noodles, with pork"
  },
  {
    "id": 666,
    "disabled": null,
    "type": "meal",
    "name": "King oyster mushroom",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "large",
    "info-translation": "Eryngii",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "king oyster mushroom eryngii",
    "weight-g": 148,
    "volume-ml": null,
    "calories": 59.2,
    "fat": 0.4,
    "saturated": 0.1,
    "polyunsaturated": 0.2,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 26.6,
    "carbs": 9,
    "fibre": 3.4,
    "sugar": 1.6,
    "protein": 4.9,
    "potassium": 622,
    "calcium": 4.4,
    "iron": 2,
    "phosphorus": 178,
    "info": "Eryngii"
  },
  {
    "id": 388,
    "disabled": null,
    "type": "snack",
    "name": "Kiwifruit",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "kiwi",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "kiwifruit",
    "weight-g": 81,
    "volume-ml": null,
    "calories": 56.5,
    "fat": 0.2,
    "saturated": 0.1,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 2.4,
    "carbs": 12.8,
    "fibre": 1.1,
    "sugar": 10,
    "protein": 0.8,
    "potassium": 255,
    "calcium": 13.8,
    "iron": 0.2,
    "phosphorus": 20.2,
    "info": null
  },
  {
    "id": 963,
    "disabled": null,
    "type": "drink",
    "name": "Kombucha, sweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": 1,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "kombucha, sweetened",
    "weight-g": null,
    "volume-ml": 240,
    "calories": 45.6,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 9.6,
    "carbs": 11,
    "fibre": null,
    "sugar": 10,
    "protein": 0,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 373,
    "disabled": null,
    "type": "drink",
    "name": "Kombucha, unsweetened",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": 1,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "kombucha, unsweetened",
    "weight-g": null,
    "volume-ml": 236,
    "calories": 30.7,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": null,
    "sodium": 9,
    "carbs": 4,
    "fibre": null,
    "sugar": 4,
    "protein": 0,
    "potassium": null,
    "calcium": null,
    "iron": 0.7,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 556,
    "disabled": null,
    "type": "drink",
    "name": "Kopi",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": "Coffee with condensed milk",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "kopi coffee with condensed milk",
    "weight-g": 250,
    "volume-ml": null,
    "calories": 152.9,
    "fat": 6.5,
    "saturated": 3.7,
    "polyunsaturated": 1.6,
    "monounsaturated": 1.6,
    "trans": null,
    "cholesterol": 5,
    "sodium": 88,
    "carbs": 20,
    "fibre": 0,
    "sugar": 20,
    "protein": 3.6,
    "potassium": 124,
    "calcium": 113,
    "iron": 0.1,
    "phosphorus": 7,
    "info": "Coffee with condensed milk"
  },
  {
    "id": 557,
    "disabled": null,
    "type": "drink",
    "name": "Kopi C",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": "Coffee with evaporated milk and sugar",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "kopi [c|si] coffee with evaporated milk and sugar",
    "weight-g": 288,
    "volume-ml": null,
    "calories": 92.2,
    "fat": 2.6,
    "saturated": 1.7,
    "polyunsaturated": 0.5,
    "monounsaturated": 0.4,
    "trans": null,
    "cholesterol": 9,
    "sodium": 55,
    "carbs": 14.4,
    "fibre": 0,
    "sugar": 10,
    "protein": 2.8,
    "potassium": 124,
    "calcium": 80,
    "iron": 0.1,
    "phosphorus": 20,
    "info": "Coffee with evaporated milk and sugar"
  },
  {
    "id": 562,
    "disabled": null,
    "type": "drink",
    "name": "Kopi C kosong",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": "Coffee with evaporated milk, without sugar",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "kopi [c|si] kosong coffee with evaporated milk, without sugar",
    "weight-g": 250,
    "volume-ml": null,
    "calories": 47.7,
    "fat": 2.5,
    "saturated": 1.6,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.7,
    "trans": null,
    "cholesterol": 8,
    "sodium": 34,
    "carbs": 3.9,
    "fibre": 0,
    "sugar": 3,
    "protein": 2.4,
    "potassium": 115,
    "calcium": 81,
    "iron": 0.1,
    "phosphorus": 70,
    "info": "Coffee with evaporated milk, without sugar"
  },
  {
    "id": 559,
    "disabled": null,
    "type": "drink",
    "name": "Kopi C siew dai",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": "Coffee with evaporated milk, less sugar",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "kopi [c|si] [siew|siu] dai coffee with evaporated milk, less sugar",
    "weight-g": 250,
    "volume-ml": null,
    "calories": 71.7,
    "fat": 2.5,
    "saturated": 1.6,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.7,
    "trans": null,
    "cholesterol": 8,
    "sodium": 34,
    "carbs": 9.9,
    "fibre": 0,
    "sugar": 9.1,
    "protein": 2.4,
    "potassium": 115,
    "calcium": 81,
    "iron": 0.1,
    "phosphorus": 70,
    "info": "Coffee with evaporated milk, less sugar"
  },
  {
    "id": 560,
    "disabled": null,
    "type": "drink",
    "name": "Kopi O",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": "Black coffee with sugar",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "kopi o black coffee with sugar",
    "weight-g": 260,
    "volume-ml": null,
    "calories": 149.3,
    "fat": 5.3,
    "saturated": 3,
    "polyunsaturated": 1.1,
    "monounsaturated": 1.2,
    "trans": null,
    "cholesterol": 1,
    "sodium": 3,
    "carbs": 22.4,
    "fibre": 0,
    "sugar": 19,
    "protein": 3,
    "potassium": 83,
    "calcium": 3,
    "iron": 0,
    "phosphorus": 6,
    "info": "Black coffee with sugar"
  },
  {
    "id": 561,
    "disabled": null,
    "type": "drink",
    "name": "Kopi O kosong",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": "Black coffee without sugar",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "kopi o kosong black coffee without sugar",
    "weight-g": 155,
    "volume-ml": null,
    "calories": 3.2,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 3,
    "carbs": 0.6,
    "fibre": 0,
    "sugar": 0.6,
    "protein": 0.2,
    "potassium": 84,
    "calcium": 3,
    "iron": 0.6,
    "phosphorus": 2,
    "info": "Black coffee without sugar"
  },
  {
    "id": 906,
    "disabled": null,
    "type": "drink",
    "name": "Kopi O siew dai",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": "Black coffee with less sugar",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "kopi o [siew|siu] dai black coffee with less sugar",
    "weight-g": 194,
    "volume-ml": null,
    "calories": 45.8,
    "fat": 1.8,
    "saturated": 0.6,
    "polyunsaturated": 0.1,
    "monounsaturated": 1.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 17,
    "carbs": 7,
    "fibre": 0,
    "sugar": 7,
    "protein": 0.4,
    "potassium": 89,
    "calcium": 4,
    "iron": 0,
    "phosphorus": 14,
    "info": "Black coffee with less sugar"
  },
  {
    "id": 558,
    "disabled": null,
    "type": "drink",
    "name": "Kopi siew dai",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": "Coffee with less condensed milk",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "kopi [siew|siu] dai coffee with less condensed milk",
    "weight-g": 194,
    "volume-ml": null,
    "calories": 45.8,
    "fat": 1.8,
    "saturated": 0.6,
    "polyunsaturated": 0.1,
    "monounsaturated": 1.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 17,
    "carbs": 7,
    "fibre": 0,
    "sugar": 7,
    "protein": 0.4,
    "potassium": 89,
    "calcium": 4,
    "iron": 0,
    "phosphorus": 14,
    "info": "Coffee with less condensed milk"
  },
  {
    "id": 861,
    "disabled": null,
    "type": "meal",
    "name": "Haemul Pajeon",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 0.3,
    "unit": "pancake",
    "info-translation": "Korean seafood pancake",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "haemul pajeon korean seafood pancake",
    "weight-g": 78,
    "volume-ml": null,
    "calories": 158,
    "fat": 6.6,
    "saturated": 3,
    "polyunsaturated": 1,
    "monounsaturated": 2.8,
    "trans": null,
    "cholesterol": null,
    "sodium": 318,
    "carbs": 18.4,
    "fibre": null,
    "sugar": 1.2,
    "protein": 5.8,
    "potassium": null,
    "calcium": 32,
    "iron": 0.6,
    "phosphorus": null,
    "info": "Korean seafood pancake"
  },
  {
    "id": 882,
    "disabled": null,
    "type": "meal",
    "name": "Korean sweet crispy chicken",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "korean sweet crispy chicken",
    "weight-g": 99,
    "volume-ml": null,
    "calories": 271.5,
    "fat": 13.9,
    "saturated": 3.5,
    "polyunsaturated": 3.2,
    "monounsaturated": 6.4,
    "trans": 0.1,
    "cholesterol": 94,
    "sodium": 486,
    "carbs": 20.1,
    "fibre": 0.3,
    "sugar": 14.8,
    "protein": 16.5,
    "potassium": 170,
    "calcium": 30,
    "iron": 0.7,
    "phosphorus": 148,
    "info": null
  },
  {
    "id": 667,
    "disabled": null,
    "type": "meal",
    "name": "Kow kee chye, stir-fried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": "Wolfberry leaf",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[kow|gao|kau] [kee|kei] [chye|cai|choy|chai], stir-fried wolfberry leaf",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 20,
    "fat": 0,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 2,
    "carbs": 2,
    "fibre": 1.3,
    "sugar": 0,
    "protein": 3,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "Wolfberry leaf"
  },
  {
    "id": 60,
    "disabled": null,
    "type": "snack",
    "name": "Kueh lapis, baked",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[kueh|kuih|kue] lapis, baked",
    "weight-g": 31,
    "volume-ml": null,
    "calories": 147.3,
    "fat": 10.5,
    "saturated": 6.5,
    "polyunsaturated": 0.7,
    "monounsaturated": 2.8,
    "trans": null,
    "cholesterol": 69,
    "sodium": 99,
    "carbs": 10.7,
    "fibre": 0.4,
    "sugar": 10,
    "protein": 2.5,
    "potassium": null,
    "calcium": 10,
    "iron": 0.4,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 149,
    "disabled": null,
    "type": "meal",
    "name": "Kuey chap",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with meat, innards and tau pok",
    "search-text": "[kuey|kuay|kway] chap",
    "weight-g": 730,
    "volume-ml": null,
    "calories": 703.2,
    "fat": 26.4,
    "saturated": 9.9,
    "polyunsaturated": 4.3,
    "monounsaturated": 10.7,
    "trans": null,
    "cholesterol": 293,
    "sodium": 3206,
    "carbs": 82,
    "fibre": 5.9,
    "sugar": 15,
    "protein": 34.4,
    "potassium": 417,
    "calcium": 234,
    "iron": 3.7,
    "phosphorus": 425,
    "info": "with meat, innards and tau pok"
  },
  {
    "id": 284,
    "disabled": null,
    "type": "meal",
    "name": "Kuih pie tee",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[kuih|kueh] [pie|pai] [tee|ti]",
    "weight-g": 37,
    "volume-ml": null,
    "calories": 88.1,
    "fat": 6.1,
    "saturated": 3,
    "polyunsaturated": 0.7,
    "monounsaturated": 2,
    "trans": null,
    "cholesterol": 10,
    "sodium": 121,
    "carbs": 6.6,
    "fibre": 0.8,
    "sugar": 4,
    "protein": 1.7,
    "potassium": null,
    "calcium": 13,
    "iron": 0.4,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 653,
    "disabled": null,
    "type": "meal",
    "name": "Ladies fingers, stir-fried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": "Okra",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[ladies|lady|lady's] fingers, stir-fried okra",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 73,
    "fat": 4.7,
    "saturated": 0.6,
    "polyunsaturated": 0.5,
    "monounsaturated": 3.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 123,
    "carbs": 7.5,
    "fibre": 3.2,
    "sugar": 1.5,
    "protein": 1.9,
    "potassium": 299,
    "calcium": 82,
    "iron": 0.7,
    "phosphorus": 61,
    "info": "Okra"
  },
  {
    "id": 964,
    "disabled": null,
    "type": "meal",
    "name": "Laksa, assam",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "laksa, [assam|asam]",
    "weight-g": 660,
    "volume-ml": null,
    "calories": 432.4,
    "fat": 2,
    "saturated": 0.7,
    "polyunsaturated": 0.7,
    "monounsaturated": 0.7,
    "trans": null,
    "cholesterol": 13,
    "sodium": 2653,
    "carbs": 82.5,
    "fibre": 4.6,
    "sugar": 16,
    "protein": 21.1,
    "potassium": null,
    "calcium": 158,
    "iron": 7.3,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 124,
    "disabled": null,
    "type": "meal",
    "name": "Laksa, curry",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "laksa, curry",
    "weight-g": 585,
    "volume-ml": null,
    "calories": 768.6,
    "fat": 47.4,
    "saturated": 34,
    "polyunsaturated": 3,
    "monounsaturated": 7.5,
    "trans": null,
    "cholesterol": 88,
    "sodium": 1591,
    "carbs": 63.8,
    "fibre": 4.7,
    "sugar": 8.2,
    "protein": 21.7,
    "potassium": 450,
    "calcium": 129,
    "iron": 5.8,
    "phosphorus": 234,
    "info": null
  },
  {
    "id": 455,
    "disabled": null,
    "type": "meal",
    "name": "Lamb chop, grilled",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "chop",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "3\" x 3\" x 1\"",
    "info-include-exclude": null,
    "search-text": "lamb chop, grilled",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 191,
    "fat": 11.4,
    "saturated": 5.8,
    "polyunsaturated": 0.7,
    "monounsaturated": 4.6,
    "trans": null,
    "cholesterol": 49.1,
    "sodium": 42.7,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 22.1,
    "potassium": 158,
    "calcium": 5.9,
    "iron": 1.2,
    "phosphorus": 107,
    "info": "(3\" x 3\" x 1\")"
  },
  {
    "id": 285,
    "disabled": null,
    "type": "meal",
    "name": "Lasagne, beef / chicken",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "lasagne, beef / chicken",
    "weight-g": 437,
    "volume-ml": null,
    "calories": 833.9,
    "fat": 48.3,
    "saturated": 19.1,
    "polyunsaturated": 3.1,
    "monounsaturated": 15.6,
    "trans": 1.3,
    "cholesterol": 135,
    "sodium": 2290,
    "carbs": 54,
    "fibre": 5.7,
    "sugar": 12.1,
    "protein": 45.8,
    "potassium": 1160,
    "calcium": 808,
    "iron": 3.2,
    "phosphorus": 769,
    "info": null
  },
  {
    "id": 965,
    "disabled": null,
    "type": "meal",
    "name": "Lasagne, vegetarian",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "lasagne, vegetarian",
    "weight-g": 400,
    "volume-ml": null,
    "calories": 498.3,
    "fat": 23.1,
    "saturated": 8.9,
    "polyunsaturated": 3.6,
    "monounsaturated": 8.2,
    "trans": 0.6,
    "cholesterol": 37,
    "sodium": 945,
    "carbs": 52,
    "fibre": 8.9,
    "sugar": 14.6,
    "protein": 20.6,
    "potassium": 1142,
    "calcium": 431,
    "iron": 4.5,
    "phosphorus": 395,
    "info": null
  },
  {
    "id": 689,
    "disabled": null,
    "type": "meal",
    "name": "Leek, stir-fried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "leek, stir-fried",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 89.6,
    "fat": 6.4,
    "saturated": 2,
    "polyunsaturated": 2,
    "monounsaturated": 8,
    "trans": null,
    "cholesterol": 0,
    "sodium": 392,
    "carbs": 6,
    "fibre": 0,
    "sugar": 6,
    "protein": 2,
    "potassium": 50,
    "calcium": 14,
    "iron": 0,
    "phosphorus": 78,
    "info": null
  },
  {
    "id": 652,
    "disabled": null,
    "type": "meal",
    "name": "Lemongrass",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "lemongrass",
    "weight-g": 108,
    "volume-ml": null,
    "calories": 29.4,
    "fat": 0.6,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 5.4,
    "fibre": 0.6,
    "sugar": 1.8,
    "protein": 0.6,
    "potassium": 312,
    "calcium": 30,
    "iron": 1.2,
    "phosphorus": 30,
    "info": null
  },
  {
    "id": 690,
    "disabled": null,
    "type": "meal",
    "name": "Lentils, boiled / steamed",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 4,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "lentils, boiled / steamed",
    "weight-g": 50,
    "volume-ml": null,
    "calories": 57.2,
    "fat": 0.2,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 1,
    "carbs": 9.9,
    "fibre": 3.9,
    "sugar": 0.9,
    "protein": 4.4,
    "potassium": 182,
    "calcium": 9,
    "iron": 1.6,
    "phosphorus": 880,
    "info": null
  },
  {
    "id": 691,
    "disabled": null,
    "type": "meal",
    "name": "Lima beans",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 0.5,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "lima beans",
    "weight-g": 85,
    "volume-ml": null,
    "calories": 104.5,
    "fat": 0.3,
    "saturated": 0.1,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 14,
    "carbs": 20,
    "fibre": 4.6,
    "sugar": 1.4,
    "protein": 5.8,
    "potassium": 485,
    "calcium": 27,
    "iron": 2.1,
    "phosphorus": 111,
    "info": null
  },
  {
    "id": 712,
    "disabled": null,
    "type": "snack",
    "name": "Lime",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 5,
    "unit": "lime",
    "info-translation": "Calamansi",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "lime calamansi",
    "weight-g": 110,
    "volume-ml": null,
    "calories": 36.5,
    "fat": 2.5,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 3,
    "fibre": 0.5,
    "sugar": 3,
    "protein": 0.5,
    "potassium": 45,
    "calcium": 10,
    "iron": 0,
    "phosphorus": 5,
    "info": "Calamansi"
  },
  {
    "id": 175,
    "disabled": null,
    "type": "drink",
    "name": "Lime juice, sweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "lime juice, sweetened",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 121.3,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1,
    "carbs": 30,
    "fibre": 0.2,
    "sugar": 30,
    "protein": 0.1,
    "potassium": 28,
    "calcium": 5,
    "iron": 0.1,
    "phosphorus": 4,
    "info": null
  },
  {
    "id": 366,
    "disabled": null,
    "type": "drink",
    "name": "Lime juice, unsweetened",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "lime juice, unsweetened",
    "weight-g": 242,
    "volume-ml": null,
    "calories": 61,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 4.8,
    "carbs": 20.4,
    "fibre": 1,
    "sugar": 4,
    "protein": 1,
    "potassium": 283,
    "calcium": 34,
    "iron": 0.2,
    "phosphorus": 34,
    "info": null
  },
  {
    "id": 979,
    "disabled": null,
    "type": "meal",
    "name": "Lobster thermidor",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1.5,
    "unit": "palm",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "without shell",
    "search-text": "lobster thermidor",
    "weight-g": 150,
    "volume-ml": null,
    "calories": 239,
    "fat": 14.7,
    "saturated": 8.9,
    "polyunsaturated": 1,
    "monounsaturated": 3.8,
    "trans": null,
    "cholesterol": 231,
    "sodium": 755,
    "carbs": 8,
    "fibre": 0,
    "sugar": 0,
    "protein": 25.5,
    "potassium": 311,
    "calcium": 132,
    "iron": 0.4,
    "phosphorus": 251,
    "info": "without shell"
  },
  {
    "id": 1038,
    "disabled": null,
    "type": "snack",
    "name": "Log cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "log cake",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 356,
    "fat": 24,
    "saturated": 9,
    "polyunsaturated": 8,
    "monounsaturated": 8,
    "trans": 0.1,
    "cholesterol": 70,
    "sodium": 190,
    "carbs": 32,
    "fibre": 1,
    "sugar": 20,
    "protein": 3,
    "potassium": 129,
    "calcium": 45,
    "iron": 1.8,
    "phosphorus": 119,
    "info": null
  },
  {
    "id": 624,
    "disabled": null,
    "type": "meal",
    "name": "Long bean, stir-fried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "long bean, stir-fried",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 23.9,
    "fat": 0.3,
    "saturated": 0.8,
    "polyunsaturated": 0.9,
    "monounsaturated": 0.9,
    "trans": null,
    "cholesterol": 0,
    "sodium": 170,
    "carbs": 2.6,
    "fibre": 4,
    "sugar": 2.3,
    "protein": 2.7,
    "potassium": 204,
    "calcium": 29,
    "iron": 0.8,
    "phosphorus": 51,
    "info": null
  },
  {
    "id": 389,
    "disabled": null,
    "type": "snack",
    "name": "Longan",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 10,
    "unit": "longan",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "longan",
    "weight-g": 60,
    "volume-ml": null,
    "calories": 21,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1.3,
    "carbs": 4.9,
    "fibre": 0.1,
    "sugar": 4.9,
    "protein": 0.3,
    "potassium": 74.7,
    "calcium": 2,
    "iron": 0.1,
    "phosphorus": 9.3,
    "info": null
  },
  {
    "id": 580,
    "disabled": null,
    "type": "drink",
    "name": "Longan red date drink, sweetened",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "packet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "longan red date drink, sweetened",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 95.2,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 13,
    "carbs": 23.8,
    "fibre": null,
    "sugar": 23,
    "protein": 0,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 286,
    "disabled": null,
    "type": "meal",
    "name": "Lontong with sayur lodeh",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": 1,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "lontong with sayur lodeh",
    "weight-g": 770,
    "volume-ml": null,
    "calories": 802.7,
    "fat": 40.3,
    "saturated": 21.9,
    "polyunsaturated": 5.4,
    "monounsaturated": 9.8,
    "trans": null,
    "cholesterol": 264,
    "sodium": 1930,
    "carbs": 64.3,
    "fibre": 6,
    "sugar": 27.9,
    "protein": 45.7,
    "potassium": 411,
    "calcium": 100,
    "iron": 2.3,
    "phosphorus": 488,
    "info": null
  },
  {
    "id": 287,
    "disabled": null,
    "type": "meal",
    "name": "Lor mee",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "lor mee",
    "weight-g": 640,
    "volume-ml": null,
    "calories": 596.1,
    "fat": 17.3,
    "saturated": 6.9,
    "polyunsaturated": 2.6,
    "monounsaturated": 7.9,
    "trans": null,
    "cholesterol": 109,
    "sodium": 3315,
    "carbs": 84.5,
    "fibre": 4.5,
    "sugar": 14.1,
    "protein": 25.6,
    "potassium": 154,
    "calcium": 70,
    "iron": 5.1,
    "phosphorus": 314,
    "info": null
  },
  {
    "id": 696,
    "disabled": null,
    "type": "meal",
    "name": "Lotus root, boiled",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 6,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "lotus root, boiled",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 79,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 54,
    "carbs": 19.2,
    "fibre": 3.7,
    "sugar": 0,
    "protein": 1.9,
    "potassium": 436,
    "calcium": 31,
    "iron": 1.1,
    "phosphorus": 94,
    "info": null
  },
  {
    "id": 478,
    "disabled": null,
    "type": "snack",
    "name": "Lotus seed soup dessert",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Lian Zi Suan",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "lotus seed soup dessert lian zi suan",
    "weight-g": 240,
    "volume-ml": null,
    "calories": 115,
    "fat": 1,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 5,
    "carbs": 24.5,
    "fibre": 2.6,
    "sugar": 19.9,
    "protein": 1.9,
    "potassium": 101,
    "calcium": 7,
    "iron": 0.7,
    "phosphorus": 36,
    "info": "Lian Zi Suan"
  },
  {
    "id": 893,
    "disabled": null,
    "type": "meal",
    "name": "Luncheon meat & egg noodles, dry",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "luncheon meat & egg noodles, dry",
    "weight-g": 147,
    "volume-ml": null,
    "calories": 526.7,
    "fat": 29.9,
    "saturated": 12.3,
    "polyunsaturated": 0.4,
    "monounsaturated": 1.4,
    "trans": null,
    "cholesterol": 131,
    "sodium": 615,
    "carbs": 48,
    "fibre": 0.5,
    "sugar": 0.1,
    "protein": 16.4,
    "potassium": 35,
    "calcium": 15,
    "iron": 0.6,
    "phosphorus": 46,
    "info": null
  },
  {
    "id": 795,
    "disabled": null,
    "type": "meal",
    "name": "Luncheon meat, fried",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "luncheon meat, fried",
    "weight-g": 50,
    "volume-ml": null,
    "calories": 163.4,
    "fat": 14.2,
    "saturated": 5.3,
    "polyunsaturated": 1.5,
    "monounsaturated": 6.7,
    "trans": null,
    "cholesterol": 27,
    "sodium": 428,
    "carbs": 3.6,
    "fibre": 0.7,
    "sugar": 0.1,
    "protein": 5.3,
    "potassium": 104,
    "calcium": 22,
    "iron": 1.9,
    "phosphorus": 58,
    "info": null
  },
  {
    "id": 390,
    "disabled": null,
    "type": "snack",
    "name": "Lychee",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 5,
    "unit": "fruit",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[lychee|litchi]",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 35.3,
    "fat": 0.2,
    "saturated": 0.1,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0.5,
    "carbs": 8,
    "fibre": 0.6,
    "sugar": 7.3,
    "protein": 0.4,
    "potassium": 82,
    "calcium": 0.2,
    "iron": 0.2,
    "phosphorus": 14.9,
    "info": null
  },
  {
    "id": 336,
    "disabled": null,
    "type": "meal",
    "name": "Ma la xiang guo",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Chinese hot and spicy pot",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ma la xiang guo chinese hot and spicy pot",
    "weight-g": 463,
    "volume-ml": null,
    "calories": 653.6,
    "fat": 50,
    "saturated": 17.3,
    "polyunsaturated": 13.2,
    "monounsaturated": 19.4,
    "trans": 0.2,
    "cholesterol": null,
    "sodium": 2139,
    "carbs": 13.9,
    "fibre": 13,
    "sugar": 4.2,
    "protein": 37,
    "potassium": null,
    "calcium": 384,
    "iron": 8.3,
    "phosphorus": null,
    "info": "Chinese hot and spicy pot"
  },
  {
    "id": 33,
    "disabled": null,
    "type": "snack",
    "name": "Macaroon",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "macaroon",
    "weight-g": 36,
    "volume-ml": null,
    "calories": 165.4,
    "fat": 8.1,
    "saturated": 7.2,
    "polyunsaturated": 0.3,
    "monounsaturated": 0.6,
    "trans": null,
    "cholesterol": 0,
    "sodium": 86.8,
    "carbs": 22,
    "fibre": 1.8,
    "sugar": 16.3,
    "protein": 1.1,
    "potassium": 44.3,
    "calcium": 1.8,
    "iron": 0.3,
    "phosphorus": 24.5,
    "info": null
  },
  {
    "id": 1037,
    "disabled": null,
    "type": "snack",
    "name": "Madeleine",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "madeleine",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 135,
    "fat": 7.9,
    "saturated": 3.6,
    "polyunsaturated": 2.2,
    "monounsaturated": 2.1,
    "trans": null,
    "cholesterol": null,
    "sodium": 0,
    "carbs": 15,
    "fibre": 0.5,
    "sugar": 7.1,
    "protein": 1.4,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1046,
    "disabled": null,
    "type": "snack",
    "name": "Mamee noodles",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "packet",
    "info-translation": null,
    "info-brands": "Monster",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mamee noodles monster",
    "weight-g": 25,
    "volume-ml": null,
    "calories": 114,
    "fat": 4.2,
    "saturated": 2.1,
    "polyunsaturated": 1.1,
    "monounsaturated": 1.1,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 347,
    "carbs": 16.5,
    "fibre": 0.8,
    "sugar": 0.5,
    "protein": 2.7,
    "potassium": 60,
    "calcium": 3,
    "iron": 0.3,
    "phosphorus": 53,
    "info": "e.g. Monster"
  },
  {
    "id": 713,
    "disabled": null,
    "type": "snack",
    "name": "Mandarin orange",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "fruit",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mandarin orange",
    "weight-g": 116,
    "volume-ml": null,
    "calories": 50.3,
    "fat": 0.3,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 3,
    "carbs": 10.6,
    "fibre": 2.2,
    "sugar": 10.6,
    "protein": 1.3,
    "potassium": 164,
    "calcium": 21,
    "iron": 0.2,
    "phosphorus": 20,
    "info": null
  },
  {
    "id": 391,
    "disabled": null,
    "type": "snack",
    "name": "Mango",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 0.5,
    "unit": "whole",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mango",
    "weight-g": 168,
    "volume-ml": null,
    "calories": 69,
    "fat": 0.6,
    "saturated": 0.2,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1.7,
    "carbs": 25.2,
    "fibre": 2.3,
    "sugar": 23,
    "protein": 1.4,
    "potassium": 282,
    "calcium": 18.5,
    "iron": 0.3,
    "phosphorus": 23.5,
    "info": null
  },
  {
    "id": 1030,
    "disabled": null,
    "type": "snack",
    "name": "Mango graham cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": "Mango float",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mango graham cake float",
    "weight-g": 94,
    "volume-ml": null,
    "calories": 235,
    "fat": 13,
    "saturated": 7.4,
    "polyunsaturated": 0.5,
    "monounsaturated": 0.8,
    "trans": null,
    "cholesterol": 39,
    "sodium": 72,
    "carbs": 24.5,
    "fibre": 0.7,
    "sugar": 20.1,
    "protein": 2.9,
    "potassium": 124,
    "calcium": 118,
    "iron": 0.4,
    "phosphorus": 58,
    "info": "Mango float"
  },
  {
    "id": 68,
    "disabled": null,
    "type": "snack",
    "name": "Mango pomelo sago dessert",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mango pomelo sago dessert",
    "weight-g": 312,
    "volume-ml": null,
    "calories": 217,
    "fat": 1.3,
    "saturated": 0.6,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.4,
    "trans": 0,
    "cholesterol": 2,
    "sodium": 25,
    "carbs": 48,
    "fibre": 1.1,
    "sugar": 36,
    "protein": 1.6,
    "potassium": 128,
    "calcium": 83,
    "iron": 0.3,
    "phosphorus": 64,
    "info": null
  },
  {
    "id": 54,
    "disabled": null,
    "type": "snack",
    "name": "Mango pudding",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mango pudding",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 47.1,
    "fat": 0.7,
    "saturated": 0.4,
    "polyunsaturated": 0,
    "monounsaturated": 0.2,
    "trans": null,
    "cholesterol": 2,
    "sodium": 25,
    "carbs": 8.9,
    "fibre": 2.4,
    "sugar": 8.3,
    "protein": 1.3,
    "potassium": 99,
    "calcium": 42,
    "iron": 0,
    "phosphorus": 33,
    "info": null
  },
  {
    "id": 82,
    "disabled": null,
    "type": "snack",
    "name": "Mango sticky rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mango sticky rice",
    "weight-g": 190,
    "volume-ml": null,
    "calories": 267.7,
    "fat": 7.3,
    "saturated": 3.3,
    "polyunsaturated": 0.3,
    "monounsaturated": 0.5,
    "trans": null,
    "cholesterol": 0,
    "sodium": 27,
    "carbs": 45.2,
    "fibre": 2.9,
    "sugar": 18.4,
    "protein": 5.3,
    "potassium": 124,
    "calcium": 10,
    "iron": 1.6,
    "phosphorus": 100,
    "info": null
  },
  {
    "id": 392,
    "disabled": null,
    "type": "snack",
    "name": "Mangosteen",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "fruit",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mangosteen",
    "weight-g": 69,
    "volume-ml": null,
    "calories": 5.8,
    "fat": 0.2,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1,
    "carbs": 0.9,
    "fibre": 0.3,
    "sugar": 0.9,
    "protein": 0.1,
    "potassium": 7,
    "calcium": 1,
    "iron": 0.2,
    "phosphorus": 2,
    "info": null
  },
  {
    "id": 152,
    "disabled": null,
    "type": "snack",
    "name": "Mantou, fried",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mantou, fried",
    "weight-g": 52,
    "volume-ml": null,
    "calories": 206.7,
    "fat": 9.9,
    "saturated": 3.6,
    "polyunsaturated": 2.2,
    "monounsaturated": 3.7,
    "trans": null,
    "cholesterol": 0,
    "sodium": 110,
    "carbs": 26,
    "fibre": 1,
    "sugar": 4.7,
    "protein": 3.4,
    "potassium": 42,
    "calcium": 6,
    "iron": 0.2,
    "phosphorus": 78,
    "info": null
  },
  {
    "id": 232,
    "disabled": null,
    "type": "snack",
    "name": "Mantou, steamed",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mantou, steamed",
    "weight-g": 52,
    "volume-ml": null,
    "calories": 133.4,
    "fat": 1.4,
    "saturated": 0.5,
    "polyunsaturated": 0.3,
    "monounsaturated": 0.5,
    "trans": null,
    "cholesterol": 0,
    "sodium": 69,
    "carbs": 27.5,
    "fibre": 1,
    "sugar": 4.8,
    "protein": 2.7,
    "potassium": 44,
    "calcium": 19,
    "iron": 0.2,
    "phosphorus": 48,
    "info": null
  },
  {
    "id": 834,
    "disabled": null,
    "type": "meal",
    "name": "Mapo Tofu",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mapo tofu",
    "weight-g": 160,
    "volume-ml": null,
    "calories": 226,
    "fat": 17,
    "saturated": 4.5,
    "polyunsaturated": 6.7,
    "monounsaturated": 5.3,
    "trans": null,
    "cholesterol": 27,
    "sodium": 308,
    "carbs": 1.1,
    "fibre": 1,
    "sugar": 0.5,
    "protein": 16.7,
    "potassium": 249,
    "calcium": 192,
    "iron": 5.1,
    "phosphorus": 185,
    "info": null
  },
  {
    "id": 341,
    "disabled": null,
    "type": "meal",
    "name": "Masala, meat",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "fish, chicken, beef, prawn, mutton",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "masala, meat fish, chicken, beef, prawn, mutton",
    "weight-g": 150,
    "volume-ml": null,
    "calories": 237,
    "fat": 12.9,
    "saturated": 6.1,
    "polyunsaturated": 0.9,
    "monounsaturated": 5.8,
    "trans": null,
    "cholesterol": 39,
    "sodium": 809,
    "carbs": 3.2,
    "fibre": 3.5,
    "sugar": 2.4,
    "protein": 26.9,
    "potassium": 317,
    "calcium": 41,
    "iron": 3.5,
    "phosphorus": 197,
    "info": "e.g. fish, chicken, beef, prawn, mutton"
  },
  {
    "id": 342,
    "disabled": null,
    "type": "meal",
    "name": "Masala, vegetable",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "chana, potatoes",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "masala, vegetable chana, potatoes",
    "weight-g": 140,
    "volume-ml": null,
    "calories": 168,
    "fat": 8.5,
    "saturated": 4.5,
    "polyunsaturated": 1,
    "monounsaturated": 2.7,
    "trans": null,
    "cholesterol": 7,
    "sodium": 668,
    "carbs": 12.9,
    "fibre": 8.5,
    "sugar": 0.7,
    "protein": 10.2,
    "potassium": 504,
    "calcium": 122,
    "iron": 8.1,
    "phosphorus": 193,
    "info": "e.g. chana, potatoes"
  },
  {
    "id": 288,
    "disabled": null,
    "type": "meal",
    "name": "Mashed potato, regular",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mashed potato, regular",
    "weight-g": 136,
    "volume-ml": null,
    "calories": 126,
    "fat": 6,
    "saturated": 1,
    "polyunsaturated": 1.5,
    "monounsaturated": 2.2,
    "trans": null,
    "cholesterol": 1,
    "sodium": 441,
    "carbs": 17,
    "fibre": 2,
    "sugar": 0,
    "protein": 1,
    "potassium": 231,
    "calcium": 15,
    "iron": 0.3,
    "phosphorus": 0,
    "info": null
  },
  {
    "id": 872,
    "disabled": null,
    "type": "meal",
    "name": "Mayonnaise",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mayonnaise",
    "weight-g": 13.8,
    "volume-ml": null,
    "calories": 93.5,
    "fat": 10.3,
    "saturated": 1.6,
    "polyunsaturated": 6.2,
    "monounsaturated": 2.3,
    "trans": null,
    "cholesterol": 5.8,
    "sodium": 87.6,
    "carbs": 0.1,
    "fibre": 0,
    "sugar": 0.1,
    "protein": 0.1,
    "potassium": 2.8,
    "calcium": 1.1,
    "iron": 0,
    "phosphorus": 2.9,
    "info": null
  },
  {
    "id": 622,
    "disabled": null,
    "type": "drink",
    "name": "Glucerna (powder)",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "standard dilution (5 scoops with water)",
    "info-include-exclude": null,
    "search-text": "glucerna (powder)",
    "weight-g": null,
    "volume-ml": 237,
    "calories": 228,
    "fat": 8.7,
    "saturated": 1,
    "polyunsaturated": 2,
    "monounsaturated": 4.1,
    "trans": 0.1,
    "cholesterol": 4,
    "sodium": 211,
    "carbs": 26.1,
    "fibre": 3.9,
    "sugar": 5.6,
    "protein": 10.2,
    "potassium": 370,
    "calcium": 223,
    "iron": 2.1,
    "phosphorus": 168,
    "info": "(standard dilution (5 scoops with water))"
  },
  {
    "id": 1061,
    "disabled": null,
    "type": "drink",
    "name": "Nepro HP (liquid)",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bottle",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "nepro hp (liquid)",
    "weight-g": null,
    "volume-ml": 220,
    "calories": 394.3,
    "fat": 21.5,
    "saturated": 1.6,
    "polyunsaturated": 3.1,
    "monounsaturated": 16,
    "trans": null,
    "cholesterol": 0,
    "sodium": 154,
    "carbs": 32.4,
    "fibre": 2.8,
    "sugar": 7,
    "protein": 17.8,
    "potassium": 233,
    "calcium": 233,
    "iron": 4.2,
    "phosphorus": 158,
    "info": null
  },
  {
    "id": 1062,
    "disabled": null,
    "type": "drink",
    "name": "Ensure (liquid)",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ensure (liquid)",
    "weight-g": 250,
    "volume-ml": null,
    "calories": 267.7,
    "fat": 6.5,
    "saturated": 0.6,
    "polyunsaturated": 1.8,
    "monounsaturated": 3.8,
    "trans": null,
    "cholesterol": 0,
    "sodium": 210,
    "carbs": 43,
    "fibre": 0,
    "sugar": 14.5,
    "protein": 9.3,
    "potassium": 390,
    "calcium": 318,
    "iron": 4.8,
    "phosphorus": 318,
    "info": null
  },
  {
    "id": 486,
    "disabled": null,
    "type": "meal",
    "name": "Mee goreng",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mee goreng",
    "weight-g": 309,
    "volume-ml": null,
    "calories": 500,
    "fat": 20.4,
    "saturated": 8.3,
    "polyunsaturated": 2.6,
    "monounsaturated": 8.6,
    "trans": null,
    "cholesterol": 136,
    "sodium": 1851,
    "carbs": 60.9,
    "fibre": 4.3,
    "sugar": 7.4,
    "protein": 18.2,
    "potassium": 389,
    "calcium": 124,
    "iron": 4.3,
    "phosphorus": 210,
    "info": null
  },
  {
    "id": 289,
    "disabled": null,
    "type": "meal",
    "name": "Mee rebus",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mee rebus",
    "weight-g": 571,
    "volume-ml": null,
    "calories": 571,
    "fat": 17.1,
    "saturated": 5.8,
    "polyunsaturated": 3.8,
    "monounsaturated": 6.5,
    "trans": null,
    "cholesterol": 206,
    "sodium": 2164,
    "carbs": 82.2,
    "fibre": 8,
    "sugar": 37.7,
    "protein": 22.8,
    "potassium": 246,
    "calcium": 223,
    "iron": 2.3,
    "phosphorus": 291,
    "info": null
  },
  {
    "id": 125,
    "disabled": null,
    "type": "meal",
    "name": "Mee siam, with gravy",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mee siam, with gravy",
    "weight-g": 655,
    "volume-ml": null,
    "calories": 694,
    "fat": 24.2,
    "saturated": 8.7,
    "polyunsaturated": 4.9,
    "monounsaturated": 9.1,
    "trans": null,
    "cholesterol": 138,
    "sodium": 2659,
    "carbs": 91.7,
    "fibre": 9.2,
    "sugar": 65.5,
    "protein": 28.2,
    "potassium": 275,
    "calcium": 164,
    "iron": 2,
    "phosphorus": 269,
    "info": null
  },
  {
    "id": 742,
    "disabled": null,
    "type": "meal",
    "name": "Mee siam, without gravy",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mee siam, without gravy",
    "weight-g": 368,
    "volume-ml": null,
    "calories": 562.5,
    "fat": 19.3,
    "saturated": 8.2,
    "polyunsaturated": 2.3,
    "monounsaturated": 8.2,
    "trans": null,
    "cholesterol": 96,
    "sodium": 1008,
    "carbs": 81.7,
    "fibre": 9.2,
    "sugar": 55.5,
    "protein": 15.5,
    "potassium": 155,
    "calcium": 99,
    "iron": 2.6,
    "phosphorus": 236,
    "info": null
  },
  {
    "id": 319,
    "disabled": null,
    "type": "meal",
    "name": "Mee soto",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mee soto",
    "weight-g": 541,
    "volume-ml": null,
    "calories": 435.4,
    "fat": 13,
    "saturated": 5.4,
    "polyunsaturated": 2.2,
    "monounsaturated": 4.9,
    "trans": null,
    "cholesterol": 38,
    "sodium": 2678,
    "carbs": 60.1,
    "fibre": 3.8,
    "sugar": 6,
    "protein": 19.5,
    "potassium": null,
    "calcium": 173,
    "iron": 3.8,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 792,
    "disabled": null,
    "type": "meal",
    "name": "Mee sua, stir-fried",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mee sua, stir-fried",
    "weight-g": 450,
    "volume-ml": null,
    "calories": 631.7,
    "fat": 33.7,
    "saturated": 7.5,
    "polyunsaturated": 9.7,
    "monounsaturated": 12.7,
    "trans": null,
    "cholesterol": 266,
    "sodium": 1531,
    "carbs": 62.1,
    "fibre": 5.1,
    "sugar": 7.4,
    "protein": 20,
    "potassium": 635,
    "calcium": 122,
    "iron": 8.3,
    "phosphorus": 275,
    "info": null
  },
  {
    "id": 507,
    "disabled": null,
    "type": "snack",
    "name": "Meringue, cookie",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "meringue, cookie",
    "weight-g": 5,
    "volume-ml": null,
    "calories": 17.4,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 12.2,
    "carbs": 4,
    "fibre": 0,
    "sugar": 4,
    "protein": 0.3,
    "potassium": 7.7,
    "calcium": 0.3,
    "iron": 0,
    "phosphorus": 0.4,
    "info": null
  },
  {
    "id": 159,
    "disabled": null,
    "type": "drink",
    "name": "Milk powder, low fat",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": "Anlene",
    "info-examples": null,
    "info-portion-aid": "standard dilution (4 tablespoons with water)",
    "info-include-exclude": null,
    "search-text": "milk powder, low fat anlene",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 110,
    "fat": 0.8,
    "saturated": 0.5,
    "polyunsaturated": 0,
    "monounsaturated": 0.3,
    "trans": null,
    "cholesterol": 7,
    "sodium": 96,
    "carbs": 18.6,
    "fibre": 0.4,
    "sugar": 13.1,
    "protein": 7.1,
    "potassium": 350,
    "calcium": 500,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Anlene (standard dilution (4 tablespoons with water))"
  },
  {
    "id": 370,
    "disabled": null,
    "type": "drink",
    "name": "Milk kefir",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": 1,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "milk kefir",
    "weight-g": null,
    "volume-ml": 100,
    "calories": 41.3,
    "fat": 0.9,
    "saturated": 0.7,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.3,
    "trans": null,
    "cholesterol": 5,
    "sodium": 40,
    "carbs": 4.5,
    "fibre": 0,
    "sugar": 4.6,
    "protein": 3.8,
    "potassium": 164,
    "calcium": 130,
    "iron": 0,
    "phosphorus": 105,
    "info": null
  },
  {
    "id": 1003,
    "disabled": null,
    "type": "snack",
    "name": "Milk pudding",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "milk pudding",
    "weight-g": 115,
    "volume-ml": null,
    "calories": 240,
    "fat": 18,
    "saturated": 10,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 60,
    "sodium": 85,
    "carbs": 16,
    "fibre": 0,
    "sugar": 16,
    "protein": 6,
    "potassium": 0,
    "calcium": 144,
    "iron": 3.6,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 3,
    "disabled": null,
    "type": "drink",
    "name": "Milk, condensed",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1.5,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "milk, condensed",
    "weight-g": 20,
    "volume-ml": null,
    "calories": 67.4,
    "fat": 1.8,
    "saturated": 1.1,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.5,
    "trans": null,
    "cholesterol": 7,
    "sodium": 25,
    "carbs": 11,
    "fibre": 0,
    "sugar": 11,
    "protein": 1.8,
    "potassium": 58,
    "calcium": 66,
    "iron": 0.1,
    "phosphorus": 43,
    "info": null
  },
  {
    "id": 974,
    "disabled": null,
    "type": "drink",
    "name": "Milk, evaporated",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "milk, evaporated",
    "weight-g": null,
    "volume-ml": 32,
    "calories": 43.4,
    "fat": 2.4,
    "saturated": 1.5,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.7,
    "trans": null,
    "cholesterol": 9.3,
    "sodium": 34,
    "carbs": 3.2,
    "fibre": 0,
    "sugar": 3.2,
    "protein": 2.2,
    "potassium": 97,
    "calcium": 83.6,
    "iron": 0.1,
    "phosphorus": 65,
    "info": null
  },
  {
    "id": 357,
    "disabled": null,
    "type": "drink",
    "name": "Milk, full cream / whole",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "fresh milk, UHT milk",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "milk, full cream / whole fresh milk, uht milk",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 166.2,
    "fat": 9,
    "saturated": 6,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 33,
    "sodium": 88,
    "carbs": 12.5,
    "fibre": 0,
    "sugar": 12.5,
    "protein": 8.8,
    "potassium": null,
    "calcium": 300,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. fresh milk, UHT milk"
  },
  {
    "id": 16,
    "disabled": null,
    "type": "drink",
    "name": "Milkshake",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "milkshake",
    "weight-g": null,
    "volume-ml": 355,
    "calories": 376.4,
    "fat": 16.3,
    "saturated": 9.9,
    "polyunsaturated": 0.8,
    "monounsaturated": 4.5,
    "trans": null,
    "cholesterol": 57.5,
    "sodium": 202,
    "carbs": 49,
    "fibre": 2.3,
    "sugar": 34.1,
    "protein": 8.4,
    "potassium": 415,
    "calcium": 288,
    "iron": 1.2,
    "phosphorus": 245,
    "info": null
  },
  {
    "id": 1035,
    "disabled": null,
    "type": "snack",
    "name": "Millefeuille",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "silce",
    "info-translation": "Vanila custard slice",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "millefeuille vanila custard slice",
    "weight-g": 156,
    "volume-ml": null,
    "calories": 401,
    "fat": 24,
    "saturated": 15,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 204,
    "carbs": 39,
    "fibre": 0,
    "sugar": 16,
    "protein": 4,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "Vanila custard slice"
  },
  {
    "id": 13,
    "disabled": null,
    "type": "drink",
    "name": "Milo dinosaur",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "milo dinosaur",
    "weight-g": null,
    "volume-ml": 450,
    "calories": 356.9,
    "fat": 9.7,
    "saturated": 6.4,
    "polyunsaturated": 0.3,
    "monounsaturated": 2.5,
    "trans": 0.1,
    "cholesterol": 28,
    "sodium": 153,
    "carbs": 56.1,
    "fibre": 3,
    "sugar": 46.4,
    "protein": 11.3,
    "potassium": 776,
    "calcium": 383,
    "iron": 382.2,
    "phosphorus": 512,
    "info": null
  },
  {
    "id": 12,
    "disabled": null,
    "type": "drink",
    "name": "Milo, 3-in-1 Active-Go",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "sachet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "milo, 3-in-1 active-go",
    "weight-g": null,
    "volume-ml": 200,
    "calories": 105.9,
    "fat": 2.3,
    "saturated": 1.2,
    "polyunsaturated": 0.2,
    "monounsaturated": 1,
    "trans": null,
    "cholesterol": 2,
    "sodium": 86,
    "carbs": 18.7,
    "fibre": 1.1,
    "sugar": 13.8,
    "protein": 2.6,
    "potassium": null,
    "calcium": 150,
    "iron": null,
    "phosphorus": 135,
    "info": null
  },
  {
    "id": 170,
    "disabled": null,
    "type": "drink",
    "name": "Milo O Kosong",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "without milk or sugar",
    "search-text": "milo o kosong",
    "weight-g": null,
    "volume-ml": 200,
    "calories": 126.2,
    "fat": 3,
    "saturated": 4.2,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.8,
    "trans": null,
    "cholesterol": 8,
    "sodium": 87,
    "carbs": 20.8,
    "fibre": 0,
    "sugar": 16.4,
    "protein": 4,
    "potassium": 285,
    "calcium": 173,
    "iron": 4.5,
    "phosphorus": 194,
    "info": "without milk or sugar"
  },
  {
    "id": 897,
    "disabled": null,
    "type": "drink",
    "name": "Milo, Activ-Go, 2 in 1 Calcium Plus",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "sachet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "milo, activ-go, 2 in 1 calcium plus",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 116.8,
    "fat": 2.8,
    "saturated": 1.4,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 2,
    "sodium": 51,
    "carbs": 19.1,
    "fibre": 1.2,
    "sugar": 12.5,
    "protein": 3.8,
    "potassium": 330,
    "calcium": 330,
    "iron": 4.1,
    "phosphorus": 270,
    "info": null
  },
  {
    "id": 290,
    "disabled": null,
    "type": "meal",
    "name": "Minced meat noodle, dry",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Bak Chor Mee / Mee Pok",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "minced meat noodle, dry bak chor mee / pok",
    "weight-g": 311,
    "volume-ml": null,
    "calories": 510.3,
    "fat": 22.7,
    "saturated": 9.2,
    "polyunsaturated": 3.5,
    "monounsaturated": 9.1,
    "trans": null,
    "cholesterol": 28,
    "sodium": 1490,
    "carbs": 56.9,
    "fibre": 5,
    "sugar": 2.5,
    "protein": 19.6,
    "potassium": 193,
    "calcium": 53,
    "iron": 1.2,
    "phosphorus": 143,
    "info": "Bak Chor Mee / Mee Pok"
  },
  {
    "id": 856,
    "disabled": null,
    "type": "meal",
    "name": "Miso ramen",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": 1,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "miso ramen",
    "weight-g": 553,
    "volume-ml": null,
    "calories": 315,
    "fat": 6.1,
    "saturated": 1.5,
    "polyunsaturated": 0.9,
    "monounsaturated": 1.9,
    "trans": null,
    "cholesterol": 72,
    "sodium": 1714,
    "carbs": 48,
    "fibre": 3.3,
    "sugar": 1.1,
    "protein": 17.3,
    "potassium": 271,
    "calcium": 116,
    "iron": 0,
    "phosphorus": 127,
    "info": null
  },
  {
    "id": 587,
    "disabled": null,
    "type": "drink",
    "name": "Mixed grains, 3-in-1 cereal drink",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "sachet",
    "info-translation": null,
    "info-brands": "Quaker, Unisoy",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mixed grains, 3-in-1 cereal drink quaker, unisoy",
    "weight-g": 35,
    "volume-ml": null,
    "calories": 139.4,
    "fat": 1.8,
    "saturated": 1,
    "polyunsaturated": 0.4,
    "monounsaturated": 0.4,
    "trans": null,
    "cholesterol": 0,
    "sodium": 86,
    "carbs": 26.7,
    "fibre": 0.8,
    "sugar": 12.9,
    "protein": 4.1,
    "potassium": null,
    "calcium": 79,
    "iron": 0.8,
    "phosphorus": null,
    "info": "e.g. Quaker, Unisoy"
  },
  {
    "id": 1027,
    "disabled": null,
    "type": "snack",
    "name": "Mocha cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mocha cake",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 474,
    "fat": 26.3,
    "saturated": 13.2,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 26,
    "sodium": 145,
    "carbs": 63.2,
    "fibre": 2.6,
    "sugar": 50,
    "protein": 2.6,
    "potassium": null,
    "calcium": 0,
    "iron": 1.8,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 155,
    "disabled": null,
    "type": "drink",
    "name": "Mocktail",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "glass",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mocktail",
    "weight-g": 250,
    "volume-ml": null,
    "calories": 90.4,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 22.6,
    "fibre": 0,
    "sugar": 20.7,
    "protein": 0,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1036,
    "disabled": null,
    "type": "snack",
    "name": "Mont blanc cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mont blanc cake",
    "weight-g": 187,
    "volume-ml": null,
    "calories": 404.9,
    "fat": 19.3,
    "saturated": 12.8,
    "polyunsaturated": 0.9,
    "monounsaturated": 4.3,
    "trans": 0.4,
    "cholesterol": 76,
    "sodium": 186,
    "carbs": 50.3,
    "fibre": 1.4,
    "sugar": 19,
    "protein": 7.5,
    "potassium": 214,
    "calcium": 52,
    "iron": 1.5,
    "phosphorus": 134,
    "info": null
  },
  {
    "id": 91,
    "disabled": null,
    "type": "snack",
    "name": "Mooncake, durian paste",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 0.3,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mooncake, durian paste",
    "weight-g": 50,
    "volume-ml": null,
    "calories": 205,
    "fat": 10.6,
    "saturated": 4.1,
    "polyunsaturated": 1.6,
    "monounsaturated": 4.4,
    "trans": null,
    "cholesterol": 1.5,
    "sodium": 93,
    "carbs": 24.9,
    "fibre": 1.4,
    "sugar": 16.2,
    "protein": 2.5,
    "potassium": 83,
    "calcium": 29,
    "iron": 0.9,
    "phosphorus": 72.5,
    "info": null
  },
  {
    "id": 88,
    "disabled": null,
    "type": "snack",
    "name": "Mooncake, lotus seed paste",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 0.3,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "traditional",
    "info-include-exclude": "0-1 egg yolk",
    "search-text": "mooncake, lotus seed paste",
    "weight-g": 47,
    "volume-ml": null,
    "calories": 196.8,
    "fat": 11.2,
    "saturated": 2.8,
    "polyunsaturated": 3,
    "monounsaturated": 4.9,
    "trans": null,
    "cholesterol": 62.5,
    "sodium": 70,
    "carbs": 20.8,
    "fibre": 1,
    "sugar": 14.5,
    "protein": 3.2,
    "potassium": 76.3,
    "calcium": 30.5,
    "iron": 1.1,
    "phosphorus": 85,
    "info": "0-1 egg yolk (traditional)"
  },
  {
    "id": 89,
    "disabled": null,
    "type": "snack",
    "name": "Mooncake, lotus seed paste",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 0.3,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "traditional",
    "info-include-exclude": "2-4 egg yolks",
    "search-text": "mooncake, lotus seed paste",
    "weight-g": 52,
    "volume-ml": null,
    "calories": 244.2,
    "fat": 17,
    "saturated": 5,
    "polyunsaturated": 3,
    "monounsaturated": 8.2,
    "trans": null,
    "cholesterol": 203.8,
    "sodium": 109.3,
    "carbs": 17.6,
    "fibre": 0.7,
    "sugar": 11.6,
    "protein": 5.2,
    "potassium": 71.8,
    "calcium": 58.3,
    "iron": 1.9,
    "phosphorus": 164.3,
    "info": "2-4 egg yolks (traditional)"
  },
  {
    "id": 90,
    "disabled": null,
    "type": "snack",
    "name": "Mooncake, snowskin",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 0.3,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mooncake, snowskin",
    "weight-g": 66,
    "volume-ml": null,
    "calories": 245.8,
    "fat": 10.6,
    "saturated": 3.4,
    "polyunsaturated": 2.2,
    "monounsaturated": 4.6,
    "trans": null,
    "cholesterol": 3.3,
    "sodium": 41,
    "carbs": 34.8,
    "fibre": 1.5,
    "sugar": 25.4,
    "protein": 2.8,
    "potassium": 87,
    "calcium": 21.8,
    "iron": 0.7,
    "phosphorus": 50.8,
    "info": null
  },
  {
    "id": 688,
    "disabled": null,
    "type": "meal",
    "name": "Moringa seed pods, stir-fried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "moringa seed pods, stir-fried",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 72.1,
    "fat": 4.1,
    "saturated": 0.6,
    "polyunsaturated": 2.6,
    "monounsaturated": 0.8,
    "trans": null,
    "cholesterol": 0,
    "sodium": 313,
    "carbs": 5.7,
    "fibre": 2.7,
    "sugar": 0.1,
    "protein": 3.1,
    "potassium": 154,
    "calcium": 26,
    "iron": 0.2,
    "phosphorus": 31,
    "info": null
  },
  {
    "id": 63,
    "disabled": null,
    "type": "snack",
    "name": "Muah chee",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Mochi",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[muah|muar] chee mochi",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 254.2,
    "fat": 9.4,
    "saturated": 2.5,
    "polyunsaturated": 3,
    "monounsaturated": 3.5,
    "trans": null,
    "cholesterol": 0,
    "sodium": 7,
    "carbs": 34.8,
    "fibre": 3.8,
    "sugar": 8,
    "protein": 7.6,
    "potassium": null,
    "calcium": 17,
    "iron": 0.6,
    "phosphorus": null,
    "info": "Mochi"
  },
  {
    "id": 207,
    "disabled": null,
    "type": "snack",
    "name": "Muffin",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "cinnamon, apple",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "muffin cinnamon, apple",
    "weight-g": 130,
    "volume-ml": null,
    "calories": 488.2,
    "fat": 26.2,
    "saturated": 14.2,
    "polyunsaturated": 2.6,
    "monounsaturated": 8.1,
    "trans": null,
    "cholesterol": 117,
    "sodium": 722,
    "carbs": 56.3,
    "fibre": 2,
    "sugar": 26.8,
    "protein": 6.8,
    "potassium": 154,
    "calcium": 208,
    "iron": 1.5,
    "phosphorus": 298,
    "info": "e.g. cinnamon, apple"
  },
  {
    "id": 346,
    "disabled": null,
    "type": "meal",
    "name": "Mui fan",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Rice in seafood, meat and vegatable gravy",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[mui|lam|mun] fan rice in seafood, meat and vegatable gravy",
    "weight-g": 712,
    "volume-ml": null,
    "calories": 755.5,
    "fat": 25.1,
    "saturated": 9.2,
    "polyunsaturated": 3.8,
    "monounsaturated": 9.3,
    "trans": null,
    "cholesterol": 234,
    "sodium": 2105,
    "carbs": 87.2,
    "fibre": 3.9,
    "sugar": 5.5,
    "protein": 45.2,
    "potassium": 570,
    "calcium": 152,
    "iron": 6.7,
    "phosphorus": 380,
    "info": "Rice in seafood, meat and vegatable gravy"
  },
  {
    "id": 126,
    "disabled": null,
    "type": "meal",
    "name": "Murtabak with meat filling",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "whole",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "mutton / sardine / chicken",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[murtabak|martabak] with meat filling mutton / sardine chicken",
    "weight-g": 357,
    "volume-ml": null,
    "calories": 788,
    "fat": 39.6,
    "saturated": 17.5,
    "polyunsaturated": 4.1,
    "monounsaturated": 16.3,
    "trans": null,
    "cholesterol": 325,
    "sodium": 1514,
    "carbs": 69.3,
    "fibre": 5.4,
    "sugar": 10,
    "protein": 38.6,
    "potassium": 557,
    "calcium": 475,
    "iron": 5.4,
    "phosphorus": 557,
    "info": "e.g. mutton / sardine / chicken"
  },
  {
    "id": 975,
    "disabled": null,
    "type": "meal",
    "name": "Mussel / oyster in wine / butter",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "oysters rockefeller",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mussel / oyster in wine butter oysters rockefeller",
    "weight-g": 24,
    "volume-ml": null,
    "calories": 31,
    "fat": 1.7,
    "saturated": 0.8,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.5,
    "trans": null,
    "cholesterol": 8.2,
    "sodium": 102,
    "carbs": 2.2,
    "fibre": 0.4,
    "sugar": 0.3,
    "protein": 1.8,
    "potassium": 64.8,
    "calcium": 23,
    "iron": 0.8,
    "phosphorus": 25.4,
    "info": "e.g. oysters rockefeller"
  },
  {
    "id": 976,
    "disabled": null,
    "type": "meal",
    "name": "Mussel / oyster in stew / sauces",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mussel / oyster in stew sauces",
    "weight-g": 12,
    "volume-ml": null,
    "calories": 10.8,
    "fat": 0.3,
    "saturated": 0.1,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 3.4,
    "sodium": 48.5,
    "carbs": 0.6,
    "fibre": 0,
    "sugar": 0.1,
    "protein": 1.5,
    "potassium": 43.4,
    "calcium": 3.6,
    "iron": 0.5,
    "phosphorus": 18.5,
    "info": null
  },
  {
    "id": 796,
    "disabled": null,
    "type": "meal",
    "name": "Mussel, lightly seasoned / garlic / herbs / raw",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 4,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mussel, lightly seasoned / garlic herbs raw",
    "weight-g": 36,
    "volume-ml": null,
    "calories": 81,
    "fat": 4,
    "saturated": 0.3,
    "polyunsaturated": 0.4,
    "monounsaturated": 0.4,
    "trans": null,
    "cholesterol": 20,
    "sodium": 132.8,
    "carbs": 2.7,
    "fibre": 0,
    "sugar": 0,
    "protein": 8.6,
    "potassium": 96.4,
    "calcium": 12,
    "iron": 2.4,
    "phosphorus": 102.4,
    "info": null
  },
  {
    "id": 452,
    "disabled": null,
    "type": "meal",
    "name": "Mutton soup",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "Chinese style",
    "search-text": "mutton soup",
    "weight-g": 450,
    "volume-ml": null,
    "calories": 329.4,
    "fat": 23,
    "saturated": 11.3,
    "polyunsaturated": 1.8,
    "monounsaturated": 9,
    "trans": null,
    "cholesterol": 153,
    "sodium": 1233,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 30.6,
    "potassium": 347,
    "calcium": 50,
    "iron": 0,
    "phosphorus": 194,
    "info": "Chinese style"
  },
  {
    "id": 720,
    "disabled": null,
    "type": "snack",
    "name": "Mycoprotein",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "3\" x 3\" x 1\"",
    "info-include-exclude": null,
    "search-text": "mycoprotein",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 76.2,
    "fat": 2.6,
    "saturated": 0.6,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 1.7,
    "fibre": 6,
    "sugar": 0.8,
    "protein": 11.5,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "(3\" x 3\" x 1\")"
  },
  {
    "id": 151,
    "disabled": null,
    "type": "meal",
    "name": "Naan, flavored",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "garlic, butter",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "naan, flavored garlic, butter",
    "weight-g": 103,
    "volume-ml": null,
    "calories": 368.6,
    "fat": 21,
    "saturated": 12.5,
    "polyunsaturated": 1.3,
    "monounsaturated": 6.2,
    "trans": null,
    "cholesterol": 57,
    "sodium": 353,
    "carbs": 38,
    "fibre": 2.2,
    "sugar": 4.7,
    "protein": 6.9,
    "potassium": 26,
    "calcium": 37,
    "iron": 0.5,
    "phosphorus": 133,
    "info": "e.g. garlic, butter"
  },
  {
    "id": 150,
    "disabled": null,
    "type": "meal",
    "name": "Naan, plain",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "naan, plain",
    "weight-g": 90,
    "volume-ml": null,
    "calories": 262,
    "fat": 5.1,
    "saturated": 1.3,
    "polyunsaturated": 1.9,
    "monounsaturated": 1.6,
    "trans": 0,
    "cholesterol": null,
    "sodium": 418,
    "carbs": 45.4,
    "fibre": 2,
    "sugar": 3.2,
    "protein": 8.7,
    "potassium": 112,
    "calcium": 75.6,
    "iron": 2.9,
    "phosphorus": 90,
    "info": null
  },
  {
    "id": 291,
    "disabled": null,
    "type": "meal",
    "name": "Naengmyeon",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Korean cold noodles",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "naengmyeon korean cold noodles",
    "weight-g": 550,
    "volume-ml": null,
    "calories": 451.6,
    "fat": 4.8,
    "saturated": 1.1,
    "polyunsaturated": 1.2,
    "monounsaturated": 1.6,
    "trans": null,
    "cholesterol": 106,
    "sodium": 2000,
    "carbs": 87.8,
    "fibre": 7,
    "sugar": 28.4,
    "protein": 14.3,
    "potassium": null,
    "calcium": 112,
    "iron": 3.7,
    "phosphorus": 315,
    "info": "Korean cold noodles"
  },
  {
    "id": 650,
    "disabled": null,
    "type": "meal",
    "name": "Nai bai, stir-fried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": "Pak Choi",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "nai bai, stir-fried [pak|bak|bok|pok] [choi|choy|chye]",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 69.3,
    "fat": 4.5,
    "saturated": 0.7,
    "polyunsaturated": 2.8,
    "monounsaturated": 0.8,
    "trans": null,
    "cholesterol": null,
    "sodium": 350,
    "carbs": 5.1,
    "fibre": 1.5,
    "sugar": 3.2,
    "protein": 2.1,
    "potassium": 276,
    "calcium": 117,
    "iron": 0.5,
    "phosphorus": 7,
    "info": "Pak Choi"
  },
  {
    "id": 553,
    "disabled": null,
    "type": "meal",
    "name": "Nasi goreng pattaya",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Fried rice wrapped in omelette",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "nasi goreng pattaya fried rice wrapped in omelette",
    "weight-g": 383,
    "volume-ml": null,
    "calories": 804.9,
    "fat": 42.1,
    "saturated": 16.6,
    "polyunsaturated": 5.5,
    "monounsaturated": 18.2,
    "trans": null,
    "cholesterol": 441,
    "sodium": 2436,
    "carbs": 80.1,
    "fibre": 4.2,
    "sugar": 8,
    "protein": 26.4,
    "potassium": 337,
    "calcium": 111,
    "iron": 4.6,
    "phosphorus": 306,
    "info": "Fried rice wrapped in omelette"
  },
  {
    "id": 292,
    "disabled": null,
    "type": "meal",
    "name": "Nasi lemak",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Coconut rice with chicken wing, egg, ikan billis, peanuts and sambal chilli",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "nasi lemak coconut rice with chicken wing, egg, ikan billis, peanuts and sambal chilli",
    "weight-g": 375,
    "volume-ml": null,
    "calories": 847.4,
    "fat": 38.6,
    "saturated": 14.4,
    "polyunsaturated": 7.8,
    "monounsaturated": 13.6,
    "trans": null,
    "cholesterol": 179,
    "sodium": 1455,
    "carbs": 79.1,
    "fibre": 3.8,
    "sugar": 4.1,
    "protein": 45.9,
    "potassium": 615,
    "calcium": 231,
    "iron": 4.5,
    "phosphorus": 591,
    "info": "Coconut rice with chicken wing, egg, ikan billis, peanuts and sambal chilli"
  },
  {
    "id": 293,
    "disabled": null,
    "type": "meal",
    "name": "Nasi padang",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Malay mixed rice",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "1 meat and 1 vegetable",
    "search-text": "nasi padang malay mixed rice",
    "weight-g": 400,
    "volume-ml": null,
    "calories": 588.3,
    "fat": 23.9,
    "saturated": 8.8,
    "polyunsaturated": 5,
    "monounsaturated": 8.9,
    "trans": null,
    "cholesterol": 110,
    "sodium": 1038,
    "carbs": 68.2,
    "fibre": 4.3,
    "sugar": 1.4,
    "protein": 25.1,
    "potassium": 222,
    "calcium": 129,
    "iron": 7.4,
    "phosphorus": 62,
    "info": "Malay mixed rice – 1 meat and 1 vegetable"
  },
  {
    "id": 1058,
    "disabled": null,
    "type": "meal",
    "name": "Nasi padang",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Malay mixed rice",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "sambal goreng",
    "search-text": "nasi padang malay mixed rice",
    "weight-g": 522,
    "volume-ml": null,
    "calories": 705.9,
    "fat": 30.7,
    "saturated": 7.4,
    "polyunsaturated": 8,
    "monounsaturated": 10,
    "trans": null,
    "cholesterol": 81,
    "sodium": 961,
    "carbs": 68.8,
    "fibre": 12,
    "sugar": 6.3,
    "protein": 38.6,
    "potassium": 467,
    "calcium": 229,
    "iron": 11.1,
    "phosphorus": 383,
    "info": "Malay mixed rice – sambal goreng"
  },
  {
    "id": 1057,
    "disabled": 1,
    "type": "meal",
    "name": "Nasi padang",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Malay mixed rice",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "nasi padang malay mixed rice",
    "weight-g": 400,
    "volume-ml": null,
    "calories": 588.3,
    "fat": 23.9,
    "saturated": 8.8,
    "polyunsaturated": 5,
    "monounsaturated": 8.9,
    "trans": null,
    "cholesterol": 110,
    "sodium": 1038,
    "carbs": 68.2,
    "fibre": 4.3,
    "sugar": 1.4,
    "protein": 25.1,
    "potassium": 222,
    "calcium": 129,
    "iron": 7.4,
    "phosphorus": 62,
    "info": "Malay mixed rice"
  },
  {
    "id": 1059,
    "disabled": null,
    "type": "meal",
    "name": "Nasi padang",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Malay mixed rice",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "sambal goreng and 1 meat",
    "search-text": "nasi padang malay mixed rice",
    "weight-g": 622,
    "volume-ml": null,
    "calories": 887.4,
    "fat": 39.8,
    "saturated": 12.1,
    "polyunsaturated": 8.5,
    "monounsaturated": 13.2,
    "trans": null,
    "cholesterol": 132,
    "sodium": 1603,
    "carbs": 73,
    "fibre": 12.1,
    "sugar": 7.3,
    "protein": 59.3,
    "potassium": 467,
    "calcium": 263,
    "iron": 14.4,
    "phosphorus": 383,
    "info": "Malay mixed rice – sambal goreng and 1 meat"
  },
  {
    "id": 1060,
    "disabled": null,
    "type": "meal",
    "name": "Nasi padang",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Malay mixed rice",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "sambal goreng and 1 fried meat",
    "search-text": "nasi padang malay mixed rice",
    "weight-g": 618,
    "volume-ml": null,
    "calories": 870,
    "fat": 42,
    "saturated": 10.5,
    "polyunsaturated": 12.8,
    "monounsaturated": 18.5,
    "trans": 0.1,
    "cholesterol": 159,
    "sodium": 1001,
    "carbs": 71.1,
    "fibre": 12,
    "sugar": 6.3,
    "protein": 51.9,
    "potassium": 637,
    "calcium": 243,
    "iron": 12.1,
    "phosphorus": 527,
    "info": "Malay mixed rice – sambal goreng and 1 fried meat"
  },
  {
    "id": 497,
    "disabled": null,
    "type": "meal",
    "name": "Noodle casserole with cheese",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "noodle casserole with cheese",
    "weight-g": 300,
    "volume-ml": null,
    "calories": 216.5,
    "fat": 0.5,
    "saturated": 12,
    "polyunsaturated": 1.2,
    "monounsaturated": 11.8,
    "trans": null,
    "cholesterol": 105,
    "sodium": 1350,
    "carbs": 30,
    "fibre": 5,
    "sugar": 11,
    "protein": 23,
    "potassium": null,
    "calcium": 500,
    "iron": 3.6,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 294,
    "disabled": 1,
    "type": "meal",
    "name": "Noodles (soup)",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": null,
    "unit": null,
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "noodles (soup)",
    "weight-g": null,
    "volume-ml": null,
    "calories": null,
    "fat": null,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": null,
    "fibre": null,
    "sugar": null,
    "protein": null,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 36,
    "disabled": null,
    "type": "snack",
    "name": "Nougat with almonds",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "nougat with almonds",
    "weight-g": 14,
    "volume-ml": null,
    "calories": 55.6,
    "fat": 0.2,
    "saturated": 0.2,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 4.6,
    "carbs": 12.9,
    "fibre": 0.5,
    "sugar": 6.3,
    "protein": 0.5,
    "potassium": 14.7,
    "calcium": 4.5,
    "iron": 0.1,
    "phosphorus": 7.7,
    "info": null
  },
  {
    "id": 412,
    "disabled": null,
    "type": "snack",
    "name": "Nut butter spread, unsweetened",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "cashew, almond",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "nut butter spread, unsweetened cashew, almond",
    "weight-g": 16,
    "volume-ml": null,
    "calories": 105.4,
    "fat": 8.9,
    "saturated": 1.1,
    "polyunsaturated": 2.2,
    "monounsaturated": 5.2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 36.3,
    "carbs": 3,
    "fibre": 1.7,
    "sugar": 1,
    "protein": 3.4,
    "potassium": 120,
    "calcium": 55.5,
    "iron": 0.6,
    "phosphorus": 81.3,
    "info": "e.g. cashew, almond"
  },
  {
    "id": 1033,
    "disabled": null,
    "type": "snack",
    "name": "Mixed nut tart",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "pecan, almond",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mixed nut tart pecan, almond",
    "weight-g": 85,
    "volume-ml": null,
    "calories": 346,
    "fat": 14.2,
    "saturated": 2.3,
    "polyunsaturated": 3,
    "monounsaturated": 7.1,
    "trans": null,
    "cholesterol": 36,
    "sodium": 234,
    "carbs": 50.7,
    "fibre": 1.8,
    "sugar": 21.4,
    "protein": 3.8,
    "potassium": 84.2,
    "calcium": 18.7,
    "iron": 0.8,
    "phosphorus": 70.6,
    "info": "e.g. pecan, almond"
  },
  {
    "id": 374,
    "disabled": null,
    "type": "snack",
    "name": "Mixed nuts, unsalted",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "almonds, cashews, walnuts, macadamia",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mixed nuts, unsalted almonds, cashews, walnuts, macadamia",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 189.8,
    "fat": 16.6,
    "saturated": 2.2,
    "polyunsaturated": 4.5,
    "monounsaturated": 9.2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 3.9,
    "fibre": 2.1,
    "sugar": 1.4,
    "protein": 6.2,
    "potassium": 188,
    "calcium": 21,
    "iron": 0.8,
    "phosphorus": 125,
    "info": "e.g. almonds, cashews, walnuts, macadamia"
  },
  {
    "id": 24,
    "disabled": null,
    "type": "snack",
    "name": "Nyonya kuih",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "kueh dadar, kueh talam",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[nyonya|nonya] [kuih|kueh] kueh dadar, talam",
    "weight-g": 131,
    "volume-ml": null,
    "calories": 243.3,
    "fat": 7.3,
    "saturated": 6.5,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.4,
    "trans": null,
    "cholesterol": 3,
    "sodium": 152,
    "carbs": 42.6,
    "fibre": 1.6,
    "sugar": 23.8,
    "protein": 1.8,
    "potassium": 93,
    "calcium": 21,
    "iron": 0,
    "phosphorus": 35,
    "info": "e.g. kueh dadar, kueh talam"
  },
  {
    "id": 368,
    "disabled": null,
    "type": "drink",
    "name": "Oat bran, cooked",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "oat bran, cooked",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 66.2,
    "fat": 0.9,
    "saturated": 0.2,
    "polyunsaturated": 0.3,
    "monounsaturated": 0.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1,
    "carbs": 11.4,
    "fibre": 2.6,
    "sugar": null,
    "protein": 3.2,
    "potassium": 92,
    "calcium": 10,
    "iron": 0.9,
    "phosphorus": 119,
    "info": null
  },
  {
    "id": 167,
    "disabled": null,
    "type": "drink",
    "name": "Oat milk, sweetened",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "oat milk, sweetened",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 120,
    "fat": 3.8,
    "saturated": 0.5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 250,
    "carbs": 16.3,
    "fibre": 2,
    "sugar": 10,
    "protein": 2.5,
    "potassium": null,
    "calcium": 300,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 466,
    "disabled": null,
    "type": "drink",
    "name": "Oat milk, unsweetened",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "oat milk, unsweetened",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 100,
    "fat": 4.8,
    "saturated": 0.8,
    "polyunsaturated": 1.2,
    "monounsaturated": 2.6,
    "trans": null,
    "cholesterol": 0,
    "sodium": 95,
    "carbs": 19.3,
    "fibre": 0,
    "sugar": 9.3,
    "protein": 5.3,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 295,
    "disabled": null,
    "type": "meal",
    "name": "Okonomiyaki",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "okonomiyaki",
    "weight-g": 323,
    "volume-ml": null,
    "calories": 471,
    "fat": 25.2,
    "saturated": 7.5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 1457,
    "carbs": 41,
    "fibre": 8.7,
    "sugar": null,
    "protein": 20.4,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 508,
    "disabled": null,
    "type": "meal",
    "name": "Okowa",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Japanese steamed glutinous rice with meat or vegetables",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "okowa japanese steamed glutinous rice with meat or vegetables",
    "weight-g": null,
    "volume-ml": null,
    "calories": 259,
    "fat": 16.8,
    "saturated": 4.7,
    "polyunsaturated": 3.8,
    "monounsaturated": 6.6,
    "trans": null,
    "cholesterol": 92,
    "sodium": 187,
    "carbs": 23.9,
    "fibre": 0.9,
    "sugar": 1.4,
    "protein": 28.6,
    "potassium": 293,
    "calcium": 22,
    "iron": 2.7,
    "phosphorus": 213,
    "info": "Japanese steamed glutinous rice with meat or vegetables"
  },
  {
    "id": 296,
    "disabled": null,
    "type": "meal",
    "name": "Olive rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "olive rice",
    "weight-g": 205,
    "volume-ml": null,
    "calories": 356.5,
    "fat": 11.7,
    "saturated": 3.5,
    "polyunsaturated": 1.7,
    "monounsaturated": 6.4,
    "trans": null,
    "cholesterol": 0,
    "sodium": 278,
    "carbs": 56.8,
    "fibre": 1.8,
    "sugar": 0.5,
    "protein": 6,
    "potassium": 18,
    "calcium": 0,
    "iron": 4.1,
    "phosphorus": 20,
    "info": null
  },
  {
    "id": 637,
    "disabled": null,
    "type": "meal",
    "name": "Olive",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "olive",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 242,
    "fat": 26,
    "saturated": 0.4,
    "polyunsaturated": 0.2,
    "monounsaturated": 1.8,
    "trans": null,
    "cholesterol": 0,
    "sodium": 60,
    "carbs": 0,
    "fibre": 2,
    "sugar": 0,
    "protein": 2,
    "potassium": 44,
    "calcium": 6,
    "iron": 0.2,
    "phosphorus": 2,
    "info": null
  },
  {
    "id": 1095,
    "disabled": null,
    "type": "meal",
    "name": "Omelette with cheese",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "omelette with cheese",
    "weight-g": 67,
    "volume-ml": null,
    "calories": 156,
    "fat": 5.8,
    "saturated": 5.9,
    "polyunsaturated": 0.9,
    "monounsaturated": 4.4,
    "trans": null,
    "cholesterol": 215,
    "sodium": 334,
    "carbs": 0.2,
    "fibre": 0,
    "sugar": 0.2,
    "protein": 11,
    "potassium": 84,
    "calcium": 160,
    "iron": 1.3,
    "phosphorus": 151,
    "info": null
  },
  {
    "id": 1096,
    "disabled": null,
    "type": "meal",
    "name": "Omelette with onion",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "omelette with onion",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 152,
    "fat": 10,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 130,
    "sodium": 496,
    "carbs": 7,
    "fibre": 2.4,
    "sugar": 3.1,
    "protein": 8.3,
    "potassium": 167,
    "calcium": 57,
    "iron": 1.2,
    "phosphorus": 202,
    "info": null
  },
  {
    "id": 551,
    "disabled": null,
    "type": "meal",
    "name": "Omelette with vegetables",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "omelette with vegetables",
    "weight-g": 95,
    "volume-ml": null,
    "calories": 106.2,
    "fat": 7.8,
    "saturated": 1.8,
    "polyunsaturated": 2.1,
    "monounsaturated": 3.3,
    "trans": null,
    "cholesterol": 124,
    "sodium": 685,
    "carbs": 4,
    "fibre": 1,
    "sugar": 2.4,
    "protein": 5,
    "potassium": 155,
    "calcium": 27,
    "iron": 0.8,
    "phosphorus": 81,
    "info": null
  },
  {
    "id": 552,
    "disabled": null,
    "type": "meal",
    "name": "Omurice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Japanese omelette rice",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "omurice japanese omelette rice",
    "weight-g": 340,
    "volume-ml": null,
    "calories": 540.6,
    "fat": 17,
    "saturated": 4.8,
    "polyunsaturated": 3.2,
    "monounsaturated": 6.2,
    "trans": null,
    "cholesterol": 252,
    "sodium": 748,
    "carbs": 74.8,
    "fibre": 0,
    "sugar": 8.8,
    "protein": 22.1,
    "potassium": 313,
    "calcium": 78,
    "iron": 1,
    "phosphorus": 197,
    "info": "Japanese omelette rice"
  },
  {
    "id": 58,
    "disabled": null,
    "type": "snack",
    "name": "Ondeh ondeh",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 4,
    "unit": "piece",
    "info-translation": "Klepon",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ondeh ondeh klepon",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 278.8,
    "fat": 10,
    "saturated": 7.6,
    "polyunsaturated": 0.4,
    "monounsaturated": 1.6,
    "trans": null,
    "cholesterol": 0,
    "sodium": 76,
    "carbs": 44.4,
    "fibre": 2.4,
    "sugar": 15,
    "protein": 2.8,
    "potassium": 240,
    "calcium": 20,
    "iron": 1.2,
    "phosphorus": 48,
    "info": "Klepon"
  },
  {
    "id": 1040,
    "disabled": null,
    "type": "snack",
    "name": "Ondeh ondeh cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ondeh ondeh cake",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 323,
    "fat": 15,
    "saturated": 11,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 41,
    "sodium": 466,
    "carbs": 42,
    "fibre": 2,
    "sugar": 30,
    "protein": 5,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 297,
    "disabled": null,
    "type": "meal",
    "name": "Onigiri",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Japanese rice balls",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "onigiri japanese rice balls",
    "weight-g": 73,
    "volume-ml": null,
    "calories": 124.1,
    "fat": 2.1,
    "saturated": 0.3,
    "polyunsaturated": 1.3,
    "monounsaturated": 0.4,
    "trans": null,
    "cholesterol": 2,
    "sodium": 157,
    "carbs": 22.6,
    "fibre": 0.8,
    "sugar": 1.5,
    "protein": 3.7,
    "potassium": 53,
    "calcium": 5,
    "iron": 0.2,
    "phosphorus": 35,
    "info": "Japanese rice balls"
  },
  {
    "id": 83,
    "disabled": null,
    "type": "snack",
    "name": "Onion rings",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "pack",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "small",
    "info-include-exclude": null,
    "search-text": "onion rings",
    "weight-g": 117,
    "volume-ml": null,
    "calories": 487.6,
    "fat": 29.5,
    "saturated": 2.9,
    "polyunsaturated": 14.2,
    "monounsaturated": 8.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 908,
    "carbs": 51,
    "fibre": 3.2,
    "sugar": 6.4,
    "protein": 4.5,
    "potassium": 195,
    "calcium": 135,
    "iron": 0.9,
    "phosphorus": 185,
    "info": "(small)"
  },
  {
    "id": 824,
    "disabled": 1,
    "type": "snack",
    "name": "Onion rings",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "pack",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "onion rings",
    "weight-g": 117,
    "volume-ml": null,
    "calories": 481,
    "fat": 29.5,
    "saturated": 4.9,
    "polyunsaturated": 14.2,
    "monounsaturated": 8.1,
    "trans": 0.2,
    "cholesterol": 0,
    "sodium": 908,
    "carbs": 51,
    "fibre": 3.2,
    "sugar": 6.4,
    "protein": 4.5,
    "potassium": 195,
    "calcium": 135,
    "iron": 0.9,
    "phosphorus": 185,
    "info": null
  },
  {
    "id": 904,
    "disabled": null,
    "type": "snack",
    "name": "Opera cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "opera cake",
    "weight-g": 66,
    "volume-ml": null,
    "calories": 234,
    "fat": 5.8,
    "saturated": 1.8,
    "polyunsaturated": 0.8,
    "monounsaturated": 2.8,
    "trans": null,
    "cholesterol": 46,
    "sodium": 328,
    "carbs": 43.9,
    "fibre": 0.5,
    "sugar": 31.5,
    "protein": 2.7,
    "potassium": 95,
    "calcium": 34,
    "iron": 1.6,
    "phosphorus": 83,
    "info": null
  },
  {
    "id": 393,
    "disabled": null,
    "type": "snack",
    "name": "Orange",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "whole",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "orange",
    "weight-g": 160,
    "volume-ml": null,
    "calories": 57.4,
    "fat": 0.2,
    "saturated": 0,
    "polyunsaturated": 0.3,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 3,
    "carbs": 12.3,
    "fibre": 9.3,
    "sugar": 12.3,
    "protein": 1.6,
    "potassium": 224,
    "calcium": 51,
    "iron": 0.6,
    "phosphorus": 27,
    "info": null
  },
  {
    "id": 1014,
    "disabled": null,
    "type": "snack",
    "name": "Pound cake, orange",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pound cake, orange",
    "weight-g": 38,
    "volume-ml": null,
    "calories": 181,
    "fat": 10.5,
    "saturated": 6.9,
    "polyunsaturated": 0.1,
    "monounsaturated": 3.1,
    "trans": null,
    "cholesterol": 48,
    "sodium": 90,
    "carbs": 19.3,
    "fibre": null,
    "sugar": 8.2,
    "protein": 2.2,
    "potassium": 28,
    "calcium": 29,
    "iron": 0.3,
    "phosphorus": 40,
    "info": null
  },
  {
    "id": 484,
    "disabled": null,
    "type": "snack",
    "name": "Osmanthus / Konnyaku jelly",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "jelly",
    "info-translation": "Gui Hua Gao",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "osmanthus / [konnyaku|konjac] jelly gui hua gao",
    "weight-g": 42,
    "volume-ml": null,
    "calories": 32,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 54,
    "carbs": 7.5,
    "fibre": 0.3,
    "sugar": 6.4,
    "protein": 0.3,
    "potassium": 277,
    "calcium": 10,
    "iron": null,
    "phosphorus": null,
    "info": "Gui Hua Gao"
  },
  {
    "id": 533,
    "disabled": null,
    "type": "meal",
    "name": "Osso buco / bucco",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "shank",
    "info-translation": "Braised veal shanks with vegetable",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "osso buco / bucco braised veal shanks with vegetable",
    "weight-g": 187,
    "volume-ml": null,
    "calories": 340.1,
    "fat": 14.5,
    "saturated": 5.1,
    "polyunsaturated": 0.9,
    "monounsaturated": 6.6,
    "trans": 0.5,
    "cholesterol": 172,
    "sodium": 168,
    "carbs": 0.2,
    "fibre": 0,
    "sugar": 0,
    "protein": 52.2,
    "potassium": 376,
    "calcium": 41.1,
    "iron": 3.7,
    "phosphorus": 400,
    "info": "Braised veal shanks with vegetable"
  },
  {
    "id": 457,
    "disabled": null,
    "type": "snack",
    "name": "Otah",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[otah|otak]",
    "weight-g": 29,
    "volume-ml": null,
    "calories": 38,
    "fat": 2.4,
    "saturated": 2,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 15,
    "sodium": 203,
    "carbs": 2.2,
    "fibre": 0.8,
    "sugar": 2,
    "protein": 1.7,
    "potassium": 45,
    "calcium": 19,
    "iron": 1,
    "phosphorus": 110,
    "info": null
  },
  {
    "id": 849,
    "disabled": null,
    "type": "meal",
    "name": "Oyakodon",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Japanese rice bowl with chicken and egg",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "oyakodon japanese rice bowl with chicken and egg",
    "weight-g": 400,
    "volume-ml": null,
    "calories": 540,
    "fat": 14.9,
    "saturated": 5.6,
    "polyunsaturated": 2.7,
    "monounsaturated": 6.4,
    "trans": 0.2,
    "cholesterol": null,
    "sodium": 1132,
    "carbs": 69.6,
    "fibre": 2,
    "sugar": 2.4,
    "protein": 32,
    "potassium": null,
    "calcium": 52,
    "iron": 2.5,
    "phosphorus": null,
    "info": "Japanese rice bowl with chicken and egg"
  },
  {
    "id": 298,
    "disabled": null,
    "type": "meal",
    "name": "Oyster omelette with starch",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Orh Luak",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "oyster omelette with starch [orh|or] [luak|jian]",
    "weight-g": 253,
    "volume-ml": null,
    "calories": 645.3,
    "fat": 49.3,
    "saturated": 20,
    "polyunsaturated": 7.6,
    "monounsaturated": 19.5,
    "trans": null,
    "cholesterol": 354,
    "sodium": 746,
    "carbs": 32.4,
    "fibre": 0,
    "sugar": 0,
    "protein": 18,
    "potassium": null,
    "calcium": 83,
    "iron": 6.1,
    "phosphorus": null,
    "info": "Orh Luak"
  },
  {
    "id": 509,
    "disabled": null,
    "type": "meal",
    "name": "Oyster omelette, without starch",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Orh Nerng",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "oyster omelette, without starch [orh|or] [nerng|neng]",
    "weight-g": 253,
    "volume-ml": null,
    "calories": 645,
    "fat": 49.3,
    "saturated": 20,
    "polyunsaturated": 7.6,
    "monounsaturated": 19.5,
    "trans": null,
    "cholesterol": 354,
    "sodium": 746,
    "carbs": 32.4,
    "fibre": 0,
    "sugar": 0,
    "protein": 18,
    "potassium": null,
    "calcium": 83,
    "iron": 6.1,
    "phosphorus": null,
    "info": "Orh Nerng"
  },
  {
    "id": 575,
    "disabled": null,
    "type": "drink",
    "name": "Packet drink with added sugar",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "packet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "chrysanthemum, sugarcane, apple, lychee, winter melon",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "packet drink with added sugar chrysanthemum, sugarcane, apple, lychee, winter melon",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 120,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": null,
    "carbs": 30,
    "fibre": null,
    "sugar": 28,
    "protein": 0,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. chrysanthemum, sugarcane, apple, lychee, winter melon"
  },
  {
    "id": 977,
    "disabled": null,
    "type": "drink",
    "name": "Packet drink, reduced sugar",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "packet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "chrysanthemum, lemon barley, winter melon",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "packet drink, reduced sugar chrysanthemum, lemon barley, winter melon",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 58,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 3,
    "carbs": 14.5,
    "fibre": null,
    "sugar": 14.5,
    "protein": 0,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. chrysanthemum, lemon barley, winter melon"
  },
  {
    "id": 299,
    "disabled": null,
    "type": "meal",
    "name": "Pad thai",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Thai-style fried noodles",
    "info-brands": null,
    "info-examples": "beef / chicken / seafood",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[pad|phat|phad] thai thai-style fried noodles beef / chicken seafood",
    "weight-g": 405,
    "volume-ml": null,
    "calories": 582.9,
    "fat": 16.9,
    "saturated": 5.9,
    "polyunsaturated": 1.7,
    "monounsaturated": 6.1,
    "trans": null,
    "cholesterol": 154,
    "sodium": 475,
    "carbs": 69.2,
    "fibre": 9.7,
    "sugar": 4.7,
    "protein": 38.5,
    "potassium": 530,
    "calcium": 91,
    "iron": 7.3,
    "phosphorus": 251,
    "info": "Thai-style fried noodles, e.g. beef / chicken / seafood"
  },
  {
    "id": 839,
    "disabled": null,
    "type": "meal",
    "name": "Tau Kwa, pan-fried",
    "rating": 3,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Firm tofu",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tau kwa, pan-fried firm tofu",
    "weight-g": 142,
    "volume-ml": null,
    "calories": 206,
    "fat": 14.4,
    "saturated": 2.8,
    "polyunsaturated": 6.8,
    "monounsaturated": 4,
    "trans": null,
    "cholesterol": 0,
    "sodium": 600,
    "carbs": 0.8,
    "fibre": 1.2,
    "sugar": 0.2,
    "protein": 18.4,
    "potassium": 130,
    "calcium": 102,
    "iron": 2,
    "phosphorus": 150,
    "info": "Firm tofu"
  },
  {
    "id": 209,
    "disabled": null,
    "type": "snack",
    "name": "Pancake with syrup",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pancake with syrup",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 297.5,
    "fat": 11.9,
    "saturated": 6.2,
    "polyunsaturated": 1.1,
    "monounsaturated": 3.4,
    "trans": null,
    "cholesterol": 34,
    "sodium": 100,
    "carbs": 41.2,
    "fibre": 0.6,
    "sugar": 18.8,
    "protein": 6.4,
    "potassium": 261,
    "calcium": 125,
    "iron": 1.2,
    "phosphorus": 114,
    "info": null
  },
  {
    "id": 193,
    "disabled": null,
    "type": "snack",
    "name": "Pancake, without syrup",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pancake, without syrup",
    "weight-g": 80,
    "volume-ml": null,
    "calories": 237.1,
    "fat": 11.9,
    "saturated": 6.2,
    "polyunsaturated": 1.1,
    "monounsaturated": 3.4,
    "trans": null,
    "cholesterol": 34,
    "sodium": 74,
    "carbs": 26.2,
    "fibre": 0.6,
    "sugar": 3.8,
    "protein": 6.3,
    "potassium": 139,
    "calcium": 79,
    "iron": 0.5,
    "phosphorus": 114,
    "info": null
  },
  {
    "id": 55,
    "disabled": 1,
    "type": "snack",
    "name": "Pancake with peanut or red bean filling",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Mee Chang Kuih",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pancake with peanut or red bean filling [mee|mi] [chang|jiang] [kuih|kueh|kue]",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 276,
    "fat": 6.9,
    "saturated": 3,
    "polyunsaturated": 1,
    "monounsaturated": 2.6,
    "trans": null,
    "cholesterol": 11,
    "sodium": 91,
    "carbs": 46.6,
    "fibre": 3.7,
    "sugar": 15.2,
    "protein": 7.1,
    "potassium": 104,
    "calcium": 26,
    "iron": 1,
    "phosphorus": 105,
    "info": "Mee Chang Kuih"
  },
  {
    "id": 492,
    "disabled": null,
    "type": "snack",
    "name": "Blinis",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Russian buckwheat pancake",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[blinis|blintz] russian buckwheat pancake",
    "weight-g": 50,
    "volume-ml": null,
    "calories": 136.6,
    "fat": 6.3,
    "saturated": 1.6,
    "polyunsaturated": 2,
    "monounsaturated": 2.3,
    "trans": null,
    "cholesterol": 31,
    "sodium": 171,
    "carbs": 15.9,
    "fibre": 1.8,
    "sugar": 2.8,
    "protein": 4,
    "potassium": 112,
    "calcium": 132,
    "iron": 1.1,
    "phosphorus": 224,
    "info": "Russian buckwheat pancake"
  },
  {
    "id": 67,
    "disabled": null,
    "type": "snack",
    "name": "Panna cotta",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "panna cotta",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 240,
    "fat": 14,
    "saturated": 11,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 35,
    "sodium": 35,
    "carbs": 25,
    "fibre": 0,
    "sugar": 23,
    "protein": 3,
    "potassium": 134,
    "calcium": 80,
    "iron": 0,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 510,
    "disabled": null,
    "type": "meal",
    "name": "Papadam",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[papadam|papadum|poppadom|papad|appalam]",
    "weight-g": 6,
    "volume-ml": null,
    "calories": 31,
    "fat": 2.2,
    "saturated": 1,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.9,
    "trans": null,
    "cholesterol": 0,
    "sodium": 125,
    "carbs": 1.9,
    "fibre": 0.4,
    "sugar": 0.5,
    "protein": 0.9,
    "potassium": 4,
    "calcium": 4,
    "iron": 0.3,
    "phosphorus": 14,
    "info": null
  },
  {
    "id": 394,
    "disabled": null,
    "type": "snack",
    "name": "Papaya",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "papaya",
    "weight-g": 145,
    "volume-ml": null,
    "calories": 68.9,
    "fat": 0.4,
    "saturated": 0.1,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 11.6,
    "carbs": 15.7,
    "fibre": 2.5,
    "sugar": 11.3,
    "protein": 0.7,
    "potassium": 264,
    "calcium": 29,
    "iron": 0.4,
    "phosphorus": 14.5,
    "info": null
  },
  {
    "id": 674,
    "disabled": null,
    "type": "meal",
    "name": "Parsnip, roasted / grilled",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "parsnip, roasted / grilled",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 111.5,
    "fat": 6.3,
    "saturated": 1,
    "polyunsaturated": 1,
    "monounsaturated": 3.6,
    "trans": null,
    "cholesterol": null,
    "sodium": 192,
    "carbs": 11.6,
    "fibre": 4.2,
    "sugar": 5.5,
    "protein": 2.1,
    "potassium": 486,
    "calcium": 44,
    "iron": 0.3,
    "phosphorus": 69,
    "info": null
  },
  {
    "id": 395,
    "disabled": null,
    "type": "snack",
    "name": "Passion fruit",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "whole",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "passion fruit",
    "weight-g": 18,
    "volume-ml": null,
    "calories": 19.6,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 5,
    "carbs": 4.2,
    "fibre": 1.9,
    "sugar": 2,
    "protein": 0.4,
    "potassium": 62.6,
    "calcium": 2.2,
    "iron": 0.3,
    "phosphorus": 12.2,
    "info": null
  },
  {
    "id": 234,
    "disabled": null,
    "type": "meal",
    "name": "Pasta, aglio e olio",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "spaghetti, linguine, macaroni",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pasta, aglio e olio spaghetti, linguine, macaroni",
    "weight-g": 162,
    "volume-ml": null,
    "calories": 325.7,
    "fat": 10.9,
    "saturated": 1.6,
    "polyunsaturated": 1.1,
    "monounsaturated": 7.3,
    "trans": null,
    "cholesterol": 3,
    "sodium": 7,
    "carbs": 49,
    "fibre": 4.5,
    "sugar": 1.7,
    "protein": 7.9,
    "potassium": 187,
    "calcium": 4,
    "iron": 0.8,
    "phosphorus": 187,
    "info": "e.g. spaghetti, linguine, macaroni"
  },
  {
    "id": 300,
    "disabled": null,
    "type": "meal",
    "name": "Pasta, cream based with meat",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "spaghetti, linguine, macaroni",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pasta, cream based with meat spaghetti, linguine, macaroni",
    "weight-g": 403,
    "volume-ml": null,
    "calories": 654.9,
    "fat": 26.5,
    "saturated": 14.3,
    "polyunsaturated": 4.9,
    "monounsaturated": 5.7,
    "trans": null,
    "cholesterol": 66,
    "sodium": 790,
    "carbs": 73.3,
    "fibre": 2.7,
    "sugar": 5.2,
    "protein": 30.8,
    "potassium": 113,
    "calcium": 130,
    "iron": 0.8,
    "phosphorus": 166,
    "info": "e.g. spaghetti, linguine, macaroni"
  },
  {
    "id": 301,
    "disabled": null,
    "type": "meal",
    "name": "Pasta, tomato based with meat",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "spaghetti, linguine, macaroni",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pasta, tomato based with meat spaghetti, linguine, macaroni",
    "weight-g": 497,
    "volume-ml": null,
    "calories": 586.6,
    "fat": 12.6,
    "saturated": 2.6,
    "polyunsaturated": 2.1,
    "monounsaturated": 7.5,
    "trans": null,
    "cholesterol": 63,
    "sodium": 659,
    "carbs": 83.6,
    "fibre": 11.6,
    "sugar": 8.9,
    "protein": 34.7,
    "potassium": 376,
    "calcium": 191,
    "iron": 4.7,
    "phosphorus": 321,
    "info": "e.g. spaghetti, linguine, macaroni"
  },
  {
    "id": 128,
    "disabled": null,
    "type": "meal",
    "name": "Pastry, sweet",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "Danish, cinnamon roll",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pastry, sweet danish, cinnamon roll",
    "weight-g": 71,
    "volume-ml": null,
    "calories": 202.9,
    "fat": 10.1,
    "saturated": 6.1,
    "polyunsaturated": 0.5,
    "monounsaturated": 3.1,
    "trans": null,
    "cholesterol": 23,
    "sodium": 113,
    "carbs": 23.1,
    "fibre": 0.9,
    "sugar": 10.7,
    "protein": 4.9,
    "potassium": 100,
    "calcium": 17,
    "iron": 1,
    "phosphorus": 39,
    "info": "e.g. Danish, cinnamon roll"
  },
  {
    "id": 396,
    "disabled": null,
    "type": "snack",
    "name": "Peach / nectarine / plum",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "whole",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "peach / nectarine plum",
    "weight-g": 130,
    "volume-ml": null,
    "calories": 57.3,
    "fat": 0.3,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 12.4,
    "fibre": 2,
    "sugar": 10.9,
    "protein": 1.2,
    "potassium": 247,
    "calcium": 7.8,
    "iron": 0.3,
    "phosphorus": 26,
    "info": null
  },
  {
    "id": 129,
    "disabled": null,
    "type": "meal",
    "name": "Peanut butter, sweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": 1,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": "Skippy",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "peanut butter, sweetened skippy",
    "weight-g": 36,
    "volume-ml": null,
    "calories": 214.6,
    "fat": 17,
    "saturated": 3.9,
    "polyunsaturated": 5,
    "monounsaturated": 8.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 154,
    "carbs": 6.7,
    "fibre": 2,
    "sugar": 3,
    "protein": 8.7,
    "potassium": 183,
    "calcium": 14,
    "iron": 0.6,
    "phosphorus": 120,
    "info": "e.g. Skippy"
  },
  {
    "id": 302,
    "disabled": null,
    "type": "meal",
    "name": "Peanut butter, unsweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "peanut butter, unsweetened",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 182.3,
    "fat": 15.5,
    "saturated": 2.6,
    "polyunsaturated": 5,
    "monounsaturated": 7.1,
    "trans": 0.1,
    "cholesterol": 0,
    "sodium": 90,
    "carbs": 2.4,
    "fibre": 3.3,
    "sugar": 1.3,
    "protein": 8.3,
    "potassium": 183,
    "calcium": 14,
    "iron": 0.6,
    "phosphorus": 120,
    "info": null
  },
  {
    "id": 214,
    "disabled": null,
    "type": "snack",
    "name": "Peanut paste",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Hua Sheng Hu",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "peanut paste [hua|fa] [sheng|sang] [hu|wu|woo]",
    "weight-g": 274,
    "volume-ml": null,
    "calories": 342.4,
    "fat": 20.4,
    "saturated": 4.4,
    "polyunsaturated": 6.9,
    "monounsaturated": 8.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 273,
    "carbs": 25.3,
    "fibre": 3,
    "sugar": 25.3,
    "protein": 14.4,
    "potassium": 419,
    "calcium": 64,
    "iron": 0,
    "phosphorus": 204,
    "info": "Hua Sheng Hu"
  },
  {
    "id": 86,
    "disabled": null,
    "type": "snack",
    "name": "Peanuts",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "salted, roasted",
    "search-text": "peanuts",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 180.4,
    "fat": 14.8,
    "saturated": 2.1,
    "polyunsaturated": 4.7,
    "monounsaturated": 7.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 130,
    "carbs": 3.8,
    "fibre": 2.1,
    "sugar": 1.8,
    "protein": 8,
    "potassium": 211,
    "calcium": 26,
    "iron": 0.6,
    "phosphorus": 152,
    "info": "salted, roasted"
  },
  {
    "id": 397,
    "disabled": null,
    "type": "snack",
    "name": "Pear",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "small",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pear",
    "weight-g": 178,
    "volume-ml": null,
    "calories": 113.2,
    "fat": 0.2,
    "saturated": 0,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1.8,
    "carbs": 27.1,
    "fibre": 5.5,
    "sugar": 17.4,
    "protein": 0.6,
    "potassium": 206,
    "calcium": 16,
    "iron": 0.3,
    "phosphorus": 21.4,
    "info": null
  },
  {
    "id": 635,
    "disabled": null,
    "type": "meal",
    "name": "Peas",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "peas",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 119.8,
    "fat": 3.4,
    "saturated": 0.1,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 2,
    "carbs": 17.2,
    "fibre": 7,
    "sugar": 0,
    "protein": 5.1,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 398,
    "disabled": null,
    "type": "snack",
    "name": "Persimmon",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "fruit",
    "info-translation": "Kaki / Sharon fruit",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[persimmon|persimon] kaki / sharon fruit",
    "weight-g": 168,
    "volume-ml": null,
    "calories": 120.4,
    "fat": 0.3,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1.7,
    "carbs": 28.4,
    "fibre": 31.2,
    "sugar": 21,
    "protein": 1,
    "potassium": 270,
    "calcium": 13.4,
    "iron": 0.3,
    "phosphorus": 28.6,
    "info": "Kaki / Sharon fruit"
  },
  {
    "id": 697,
    "disabled": null,
    "type": "meal",
    "name": "Petai",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": "Stink / bitter bean",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "petai stink / bitter bean",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 53.9,
    "fat": 0.7,
    "saturated": 0.2,
    "polyunsaturated": 1.1,
    "monounsaturated": 0.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 5,
    "carbs": 7.5,
    "fibre": 1,
    "sugar": 4.1,
    "protein": 4.4,
    "potassium": 451,
    "calcium": 151,
    "iron": 4.1,
    "phosphorus": 4,
    "info": "Stink / bitter bean"
  },
  {
    "id": 130,
    "disabled": null,
    "type": "meal",
    "name": "Pie, savoury",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "mushroom, chicken, shepherd's",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pie, savoury mushroom, chicken, shepherd's",
    "weight-g": 112,
    "volume-ml": null,
    "calories": 316.2,
    "fat": 19,
    "saturated": 6.8,
    "polyunsaturated": 3.6,
    "monounsaturated": 7.5,
    "trans": 0.1,
    "cholesterol": 32,
    "sodium": 379,
    "carbs": 29.9,
    "fibre": 2.9,
    "sugar": 4.4,
    "protein": 6.4,
    "potassium": 292,
    "calcium": 54,
    "iron": 2.7,
    "phosphorus": 95,
    "info": "e.g. mushroom, chicken, shepherd's"
  },
  {
    "id": 131,
    "disabled": null,
    "type": "meal",
    "name": "Pie, sweet",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "pecan, apple, key lime",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pie, sweet pecan, apple, key lime",
    "weight-g": 117,
    "volume-ml": null,
    "calories": 294,
    "fat": 14,
    "saturated": 7,
    "polyunsaturated": 4,
    "monounsaturated": 3,
    "trans": 4.5,
    "cholesterol": 5,
    "sodium": 250,
    "carbs": 40,
    "fibre": 1,
    "sugar": 20,
    "protein": 2,
    "potassium": 81,
    "calcium": 20,
    "iron": 1.4,
    "phosphorus": 37,
    "info": "e.g. pecan, apple, key lime"
  },
  {
    "id": 453,
    "disabled": null,
    "type": "meal",
    "name": "Pig organ soup with intestine and salted vegetables",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pig organ soup with intestine and salted vegetables",
    "weight-g": 444,
    "volume-ml": null,
    "calories": 160.6,
    "fat": 5.8,
    "saturated": 2.2,
    "polyunsaturated": 0.4,
    "monounsaturated": 1.8,
    "trans": null,
    "cholesterol": 142,
    "sodium": 1545,
    "carbs": 4.9,
    "fibre": 1.3,
    "sugar": 2,
    "protein": 22.2,
    "potassium": null,
    "calcium": 40,
    "iron": 11.1,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 399,
    "disabled": null,
    "type": "snack",
    "name": "Pineapple",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pineapple",
    "weight-g": 84,
    "volume-ml": null,
    "calories": 46.7,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0.8,
    "carbs": 11,
    "fibre": 1.2,
    "sugar": 8.3,
    "protein": 0.5,
    "potassium": 91.6,
    "calcium": 10.9,
    "iron": 0.2,
    "phosphorus": 6.7,
    "info": null
  },
  {
    "id": 132,
    "disabled": null,
    "type": "meal",
    "name": "Pineapple fried rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pineapple fried rice",
    "weight-g": 474,
    "volume-ml": null,
    "calories": 598,
    "fat": 18.8,
    "saturated": 7.8,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 137,
    "sodium": 2349,
    "carbs": 83.4,
    "fibre": null,
    "sugar": null,
    "protein": 23.8,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 38,
    "disabled": null,
    "type": "snack",
    "name": "Pineapple tart, cookie",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pineapple tart, cookie",
    "weight-g": 40,
    "volume-ml": null,
    "calories": 163.1,
    "fat": 7.1,
    "saturated": 4.5,
    "polyunsaturated": 0.3,
    "monounsaturated": 1.9,
    "trans": null,
    "cholesterol": 29,
    "sodium": 116,
    "carbs": 22.4,
    "fibre": 0.9,
    "sugar": 12.3,
    "protein": 2.4,
    "potassium": 53,
    "calcium": 16,
    "iron": 0.8,
    "phosphorus": 36,
    "info": null
  },
  {
    "id": 1028,
    "disabled": null,
    "type": "snack",
    "name": "Pineapple upside down cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pineapple upside down cake",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 294.4,
    "fat": 11.4,
    "saturated": 6.4,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 68,
    "sodium": 250,
    "carbs": 44.3,
    "fibre": 0.7,
    "sugar": 33.6,
    "protein": 3.6,
    "potassium": null,
    "calcium": 43,
    "iron": 1,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1029,
    "disabled": null,
    "type": "snack",
    "name": "Pistachio cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pistachio cake",
    "weight-g": 45,
    "volume-ml": null,
    "calories": 242,
    "fat": 9,
    "saturated": 1,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 361,
    "carbs": 35,
    "fibre": null,
    "sugar": 21,
    "protein": 3,
    "potassium": 50,
    "calcium": 91,
    "iron": 1.4,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 589,
    "disabled": null,
    "type": "meal",
    "name": "Pita bread, whole wheat",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "pita",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pita bread, whole wheat",
    "weight-g": 64,
    "volume-ml": null,
    "calories": 178.1,
    "fat": 1.1,
    "saturated": 0.1,
    "polyunsaturated": 0.7,
    "monounsaturated": 0.2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 269,
    "carbs": 35.8,
    "fibre": 3.9,
    "sugar": 1.8,
    "protein": 6.3,
    "potassium": 109,
    "calcium": 9.6,
    "iron": 2,
    "phosphorus": 115,
    "info": null
  },
  {
    "id": 554,
    "disabled": null,
    "type": "meal",
    "name": "Pizza, thin crust",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with meat topping",
    "search-text": "pizza, thin crust",
    "weight-g": 92,
    "volume-ml": null,
    "calories": 296.3,
    "fat": 15.5,
    "saturated": 6.2,
    "polyunsaturated": 3.1,
    "monounsaturated": 5,
    "trans": null,
    "cholesterol": 34,
    "sodium": 772,
    "carbs": 26.4,
    "fibre": 2.2,
    "sugar": 2.4,
    "protein": 12.8,
    "potassium": 202,
    "calcium": 178,
    "iron": 2.2,
    "phosphorus": 219,
    "info": "with meat topping"
  },
  {
    "id": 303,
    "disabled": null,
    "type": "meal",
    "name": "Pizza, thick crust",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with meat topping",
    "search-text": "pizza, thick crust",
    "weight-g": 119,
    "volume-ml": null,
    "calories": 341.7,
    "fat": 16.1,
    "saturated": 6.5,
    "polyunsaturated": 2.8,
    "monounsaturated": 5.6,
    "trans": null,
    "cholesterol": 30.9,
    "sodium": 798,
    "carbs": 35,
    "fibre": 2.7,
    "sugar": 3.2,
    "protein": 14.2,
    "potassium": 251,
    "calcium": 178,
    "iron": 2.9,
    "phosphorus": 227,
    "info": "with meat topping"
  },
  {
    "id": 555,
    "disabled": null,
    "type": "meal",
    "name": "Pizza, stuffed crust",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with cheese",
    "search-text": "pizza, stuffed crust",
    "weight-g": 117,
    "volume-ml": null,
    "calories": 320,
    "fat": 13.6,
    "saturated": 6.8,
    "polyunsaturated": 1.8,
    "monounsaturated": 3.2,
    "trans": null,
    "cholesterol": 35.1,
    "sodium": 720,
    "carbs": 35.1,
    "fibre": 2,
    "sugar": 3.4,
    "protein": 14.3,
    "potassium": 268,
    "calcium": 278,
    "iron": 2.4,
    "phosphorus": 291,
    "info": "with cheese"
  },
  {
    "id": 359,
    "disabled": null,
    "type": "drink",
    "name": "Plain water",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "plain water",
    "weight-g": null,
    "volume-ml": 236,
    "calories": 0,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 0,
    "potassium": 0,
    "calcium": 0,
    "iron": 0,
    "phosphorus": 0,
    "info": null
  },
  {
    "id": 725,
    "disabled": null,
    "type": "meal",
    "name": "Plant based / vegetarian meats",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "patty",
    "info-translation": null,
    "info-brands": "Impossible, Beyond Meat, Plant Pioneers, Omnimeat",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "plant based / vegetarian meats impossible, beyond meat, pioneers, omnimeat",
    "weight-g": 113,
    "volume-ml": null,
    "calories": 238,
    "fat": 14,
    "saturated": 8,
    "polyunsaturated": 1.3,
    "monounsaturated": 4.7,
    "trans": null,
    "cholesterol": null,
    "sodium": 370,
    "carbs": 9,
    "fibre": 3,
    "sugar": 1,
    "protein": 19,
    "potassium": 610,
    "calcium": 170,
    "iron": 4.2,
    "phosphorus": 180,
    "info": "e.g. Impossible, Beyond Meat, Plant Pioneers, Omnimeat"
  },
  {
    "id": 339,
    "disabled": null,
    "type": "meal",
    "name": "Polenta",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": "Cornmeal",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "polenta cornmeal",
    "weight-g": 240,
    "volume-ml": null,
    "calories": 166.7,
    "fat": 4.3,
    "saturated": 1.9,
    "polyunsaturated": 0.9,
    "monounsaturated": 1.2,
    "trans": null,
    "cholesterol": 7.2,
    "sodium": 199,
    "carbs": 29.4,
    "fibre": 4.3,
    "sugar": 0.6,
    "protein": 2.7,
    "potassium": 50.4,
    "calcium": 9.6,
    "iron": 1.5,
    "phosphorus": 36,
    "info": "Cornmeal"
  },
  {
    "id": 707,
    "disabled": null,
    "type": "snack",
    "name": "Pomegranate",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "fruit",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pomegranate",
    "weight-g": 282,
    "volume-ml": null,
    "calories": 259.3,
    "fat": 3.3,
    "saturated": 0.3,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 8,
    "carbs": 52.7,
    "fibre": 11.3,
    "sugar": 38.5,
    "protein": 4.7,
    "potassium": 666,
    "calcium": 28,
    "iron": 0.8,
    "phosphorus": 102,
    "info": null
  },
  {
    "id": 400,
    "disabled": null,
    "type": "snack",
    "name": "Pomelo",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 4,
    "unit": "segment",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pomelo",
    "weight-g": 156,
    "volume-ml": null,
    "calories": 60.7,
    "fat": 0.3,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 5,
    "carbs": 14.2,
    "fibre": 0.6,
    "sugar": 14,
    "protein": 0.3,
    "potassium": 186,
    "calcium": 6,
    "iron": 0.5,
    "phosphorus": 37,
    "info": null
  },
  {
    "id": 87,
    "disabled": null,
    "type": "snack",
    "name": "Popcorn, sweetened",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "popcorn, sweetened",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 149.6,
    "fat": 6,
    "saturated": 0.6,
    "polyunsaturated": 2.8,
    "monounsaturated": 2,
    "trans": null,
    "cholesterol": 5,
    "sodium": 17,
    "carbs": 23.3,
    "fibre": 1.3,
    "sugar": 18.6,
    "protein": 0.6,
    "potassium": 23,
    "calcium": 2,
    "iron": 0.1,
    "phosphorus": 17,
    "info": null
  },
  {
    "id": 343,
    "disabled": null,
    "type": "snack",
    "name": "Popcorn, buttered and salted",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "popcorn, buttered and salted",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 140.5,
    "fat": 7.3,
    "saturated": 4.8,
    "polyunsaturated": 0.8,
    "monounsaturated": 1.7,
    "trans": null,
    "cholesterol": 2,
    "sodium": 294,
    "carbs": 16,
    "fibre": 2.6,
    "sugar": 0.2,
    "protein": 2.7,
    "potassium": 63,
    "calcium": 3,
    "iron": 0.7,
    "phosphorus": 78,
    "info": null
  },
  {
    "id": 823,
    "disabled": null,
    "type": "snack",
    "name": "Popcorn chicken",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "box",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "popcorn chicken",
    "weight-g": 150,
    "volume-ml": null,
    "calories": 446,
    "fat": 28,
    "saturated": 11,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 1169,
    "carbs": 27,
    "fibre": 0,
    "sugar": 0,
    "protein": 21,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 539,
    "disabled": null,
    "type": "snack",
    "name": "Popcorn, plain",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "popcorn, plain",
    "weight-g": 8,
    "volume-ml": null,
    "calories": 41.7,
    "fat": 2.3,
    "saturated": 0.4,
    "polyunsaturated": 1.1,
    "monounsaturated": 0.7,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0.2,
    "carbs": 4.7,
    "fibre": 0.8,
    "sugar": 0,
    "protein": 0.7,
    "potassium": 18,
    "calcium": 0.8,
    "iron": 0.2,
    "phosphorus": 20,
    "info": null
  },
  {
    "id": 223,
    "disabled": null,
    "type": "snack",
    "name": "Popiah with sauce",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "roll",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "popiah with sauce",
    "weight-g": 160,
    "volume-ml": null,
    "calories": 214.8,
    "fat": 12.8,
    "saturated": 4.2,
    "polyunsaturated": 1.8,
    "monounsaturated": 4.6,
    "trans": null,
    "cholesterol": 51,
    "sodium": 773,
    "carbs": 16.3,
    "fibre": 4.6,
    "sugar": 5.6,
    "protein": 8.6,
    "potassium": 234,
    "calcium": 83,
    "iron": 2.2,
    "phosphorus": 114,
    "info": null
  },
  {
    "id": 978,
    "disabled": null,
    "type": "snack",
    "name": "Popiah, without sauce",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "popiah, without sauce",
    "weight-g": 98,
    "volume-ml": null,
    "calories": 118.9,
    "fat": 8.9,
    "saturated": 3.6,
    "polyunsaturated": 1.3,
    "monounsaturated": 3.4,
    "trans": null,
    "cholesterol": 17,
    "sodium": 150,
    "carbs": 3.5,
    "fibre": 1,
    "sugar": 2.7,
    "protein": 6.2,
    "potassium": 218,
    "calcium": 74,
    "iron": 0.9,
    "phosphorus": 56,
    "info": null
  },
  {
    "id": 304,
    "disabled": null,
    "type": "meal",
    "name": "Pork, roasted / grilled",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pork, roasted / grilled",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 314.3,
    "fat": 21.1,
    "saturated": 7.6,
    "polyunsaturated": 2,
    "monounsaturated": 9.5,
    "trans": null,
    "cholesterol": 103,
    "sodium": 54,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 31.1,
    "potassium": 194,
    "calcium": 10,
    "iron": 1.4,
    "phosphorus": 194,
    "info": null
  },
  {
    "id": 921,
    "disabled": null,
    "type": "meal",
    "name": "Braised pork belly buns",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Kong Bak Bao",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "braised pork belly buns [kong|kung] bak [bao|pau|pao]",
    "weight-g": 102,
    "volume-ml": null,
    "calories": 325.7,
    "fat": 17.3,
    "saturated": 7.3,
    "polyunsaturated": 2.6,
    "monounsaturated": 6.6,
    "trans": null,
    "cholesterol": 49,
    "sodium": 319,
    "carbs": 27.9,
    "fibre": 1,
    "sugar": 4.8,
    "protein": 14.6,
    "potassium": 44,
    "calcium": 29,
    "iron": 0.6,
    "phosphorus": 48,
    "info": "Kong Bak Bao"
  },
  {
    "id": 456,
    "disabled": null,
    "type": "meal",
    "name": "Pork chop, pan-fried",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "chop",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pork chop, pan-fried",
    "weight-g": 142,
    "volume-ml": null,
    "calories": 267.6,
    "fat": 11.2,
    "saturated": 4.3,
    "polyunsaturated": 1.8,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 99.4,
    "sodium": 122,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 41.7,
    "potassium": 534,
    "calcium": 17,
    "iron": 1.2,
    "phosphorus": 402,
    "info": null
  },
  {
    "id": 917,
    "disabled": null,
    "type": "meal",
    "name": "Fried glutinous rice pork dumplings",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Ham Sui Kok",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fried glutinous rice pork dumplings ham sui [kok|gok]",
    "weight-g": 73,
    "volume-ml": null,
    "calories": 216.2,
    "fat": 11.4,
    "saturated": 6,
    "polyunsaturated": 1.2,
    "monounsaturated": 4.4,
    "trans": null,
    "cholesterol": 11,
    "sodium": 163,
    "carbs": 25.9,
    "fibre": 1.5,
    "sugar": 5.3,
    "protein": 2.5,
    "potassium": 30,
    "calcium": 15,
    "iron": 0.4,
    "phosphorus": 33,
    "info": "Ham Sui Kok"
  },
  {
    "id": 133,
    "disabled": null,
    "type": "meal",
    "name": "Pork ribs, boiled",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 3,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pork ribs, boiled",
    "weight-g": 108,
    "volume-ml": null,
    "calories": 194.1,
    "fat": 12.1,
    "saturated": 4.7,
    "polyunsaturated": 1,
    "monounsaturated": 5.5,
    "trans": null,
    "cholesterol": 58,
    "sodium": 32,
    "carbs": 0,
    "fibre": 0,
    "sugar": 3.1,
    "protein": 21.3,
    "potassium": 141,
    "calcium": 20,
    "iron": 0.7,
    "phosphorus": 141,
    "info": null
  },
  {
    "id": 766,
    "disabled": null,
    "type": "meal",
    "name": "Pork, sweet and sour",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pork, sweet and sour",
    "weight-g": 113,
    "volume-ml": null,
    "calories": 330.9,
    "fat": 22.9,
    "saturated": 6.9,
    "polyunsaturated": 1.9,
    "monounsaturated": 7.5,
    "trans": null,
    "cholesterol": 37.5,
    "sodium": 550.5,
    "carbs": 15.7,
    "fibre": 3.2,
    "sugar": 8.2,
    "protein": 15.5,
    "potassium": 170.3,
    "calcium": 17.3,
    "iron": 1.2,
    "phosphorus": 94.5,
    "info": null
  },
  {
    "id": 230,
    "disabled": null,
    "type": "snack",
    "name": "Porridge with meat",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "beef, pork, chicken, fish",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[porridge|congee] with meat beef, pork, chicken, fish",
    "weight-g": 636,
    "volume-ml": null,
    "calories": 364.1,
    "fat": 15.3,
    "saturated": 5.7,
    "polyunsaturated": 2.5,
    "monounsaturated": 5.7,
    "trans": null,
    "cholesterol": 64,
    "sodium": 1501,
    "carbs": 37.5,
    "fibre": 0,
    "sugar": 0,
    "protein": 19.1,
    "potassium": null,
    "calcium": 57,
    "iron": 3.8,
    "phosphorus": null,
    "info": "e.g. beef, pork, chicken, fish"
  },
  {
    "id": 51,
    "disabled": null,
    "type": "snack",
    "name": "Porridge, plain",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[porridge|congee], plain",
    "weight-g": 253,
    "volume-ml": null,
    "calories": 166.7,
    "fat": 0.3,
    "saturated": 0.1,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 8,
    "carbs": 38,
    "fibre": 0.3,
    "sugar": 0,
    "protein": 3,
    "potassium": 9,
    "calcium": 8,
    "iron": 0.3,
    "phosphorus": 8,
    "info": null
  },
  {
    "id": 665,
    "disabled": null,
    "type": "meal",
    "name": "Portobello mushroom",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "whole",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[portobello|portabella] mushroom",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 34,
    "fat": 0.8,
    "saturated": 0.2,
    "polyunsaturated": 0.2,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 14,
    "carbs": 5.2,
    "fibre": 2.6,
    "sugar": 2.8,
    "protein": 4,
    "potassium": 524,
    "calcium": 4,
    "iron": 0.4,
    "phosphorus": 162,
    "info": null
  },
  {
    "id": 197,
    "disabled": null,
    "type": "snack",
    "name": "Portuguese egg tart",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "portuguese egg tart",
    "weight-g": 45,
    "volume-ml": null,
    "calories": 171,
    "fat": 11,
    "saturated": 6,
    "polyunsaturated": 1.8,
    "monounsaturated": 0.7,
    "trans": null,
    "cholesterol": 0,
    "sodium": 32,
    "carbs": 15,
    "fibre": 1.4,
    "sugar": 5,
    "protein": 3,
    "potassium": null,
    "calcium": 15,
    "iron": 0.3,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 528,
    "disabled": null,
    "type": "meal",
    "name": "Potato, baked, without cream",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "potato",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "potato, baked, without cream",
    "weight-g": 156,
    "volume-ml": null,
    "calories": 148,
    "fat": 0.2,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 376,
    "carbs": 33.6,
    "fibre": 2.3,
    "sugar": 2.7,
    "protein": 3.1,
    "potassium": 610,
    "calcium": 7.8,
    "iron": 0.5,
    "phosphorus": 78,
    "info": null
  },
  {
    "id": 531,
    "disabled": null,
    "type": "meal",
    "name": "Potato, boiled",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "potato",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "potato, boiled",
    "weight-g": 167,
    "volume-ml": null,
    "calories": 146.5,
    "fat": 0.2,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 402,
    "carbs": 33.4,
    "fibre": 3.3,
    "sugar": 1.5,
    "protein": 2.9,
    "potassium": 548,
    "calcium": 13.4,
    "iron": 0.5,
    "phosphorus": 66.8,
    "info": null
  },
  {
    "id": 902,
    "disabled": null,
    "type": "snack",
    "name": "Pound cake, vanilla",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pound cake, vanilla",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 136.8,
    "fat": 8.8,
    "saturated": 6.6,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.8,
    "trans": null,
    "cholesterol": 12.7,
    "sodium": 96,
    "carbs": 12.7,
    "fibre": 0.6,
    "sugar": 9.7,
    "protein": 1.7,
    "potassium": 28,
    "calcium": 11,
    "iron": 0.5,
    "phosphorus": 49,
    "info": null
  },
  {
    "id": 511,
    "disabled": null,
    "type": "snack",
    "name": "Praline",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "praline",
    "weight-g": 39,
    "volume-ml": null,
    "calories": 188.9,
    "fat": 10.1,
    "saturated": 0.9,
    "polyunsaturated": 3,
    "monounsaturated": 5.7,
    "trans": null,
    "cholesterol": 0,
    "sodium": 18.7,
    "carbs": 23.2,
    "fibre": 1.4,
    "sugar": 21.8,
    "protein": 1.3,
    "potassium": 84.6,
    "calcium": 16.8,
    "iron": 0.5,
    "phosphorus": 40.6,
    "info": null
  },
  {
    "id": 592,
    "disabled": null,
    "type": "meal",
    "name": "Prata with filling",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "mushroom, onion, cheese, egg",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "prata with filling mushroom, onion, cheese, egg",
    "weight-g": 127,
    "volume-ml": null,
    "calories": 304.5,
    "fat": 14.1,
    "saturated": 6.7,
    "polyunsaturated": 1.2,
    "monounsaturated": 5.6,
    "trans": null,
    "cholesterol": 210,
    "sodium": 429,
    "carbs": 33.2,
    "fibre": 3.9,
    "sugar": 3.4,
    "protein": 11.2,
    "potassium": 151,
    "calcium": 36,
    "iron": 1.1,
    "phosphorus": 141,
    "info": "e.g. mushroom, onion, cheese, egg"
  },
  {
    "id": 1050,
    "disabled": null,
    "type": "snack",
    "name": "Prawn crackers",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": "Double Decker, Calbee",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "prawn crackers double decker, calbee",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 130,
    "fat": 3.6,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 170,
    "carbs": 20,
    "fibre": null,
    "sugar": 3,
    "protein": 1.6,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Double Decker, Calbee"
  },
  {
    "id": 1094,
    "disabled": null,
    "type": "meal",
    "name": "Prawn noodle, dry",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[prawn|hae] [noodle|mee], dry",
    "weight-g": 345,
    "volume-ml": null,
    "calories": 460,
    "fat": 13.3,
    "saturated": 6.3,
    "polyunsaturated": 2.1,
    "monounsaturated": 4.6,
    "trans": null,
    "cholesterol": 49,
    "sodium": 1964,
    "carbs": 65.2,
    "fibre": 5.3,
    "sugar": 6,
    "protein": 20.4,
    "potassium": null,
    "calcium": 84,
    "iron": 2.5,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 305,
    "disabled": null,
    "type": "meal",
    "name": "Prawn noodle, soup",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[prawn|hae] [noodle|mee], soup",
    "weight-g": 600,
    "volume-ml": null,
    "calories": 307.2,
    "fat": 2.4,
    "saturated": 1.2,
    "polyunsaturated": 0.6,
    "monounsaturated": 0.6,
    "trans": null,
    "cholesterol": 42,
    "sodium": 2532,
    "carbs": 51.6,
    "fibre": 4.2,
    "sugar": 5,
    "protein": 19.8,
    "potassium": null,
    "calcium": 108,
    "iron": 1.8,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 737,
    "disabled": null,
    "type": "snack",
    "name": "Prawn nugget on stick, fried",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "stick",
    "info-translation": null,
    "info-brands": "Old Chang Kee",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "prawn nugget on stick, fried old chang kee",
    "weight-g": 59,
    "volume-ml": null,
    "calories": 90.2,
    "fat": 5,
    "saturated": 6.8,
    "polyunsaturated": 1.2,
    "monounsaturated": 5.8,
    "trans": null,
    "cholesterol": null,
    "sodium": 316,
    "carbs": 6.3,
    "fibre": 1.4,
    "sugar": 1.5,
    "protein": 5,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Old Chang Kee"
  },
  {
    "id": 461,
    "disabled": null,
    "type": "meal",
    "name": "Prawn paste chicken",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 3,
    "unit": "piece",
    "info-translation": "Har Cheong Gai",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "prawn paste chicken har cheong gai",
    "weight-g": 126,
    "volume-ml": null,
    "calories": 219.6,
    "fat": 12.8,
    "saturated": 3.6,
    "polyunsaturated": 3.1,
    "monounsaturated": 4.8,
    "trans": null,
    "cholesterol": 104,
    "sodium": 4389,
    "carbs": 2.3,
    "fibre": 0,
    "sugar": 0.6,
    "protein": 23.8,
    "potassium": 360,
    "calcium": 38,
    "iron": 1.4,
    "phosphorus": 148,
    "info": "Har Cheong Gai"
  },
  {
    "id": 765,
    "disabled": null,
    "type": "meal",
    "name": "Prawn, sweet and sour",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 3,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "prawn, sweet and sour",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 186.9,
    "fat": 9.7,
    "saturated": 3.1,
    "polyunsaturated": 0.7,
    "monounsaturated": 3,
    "trans": null,
    "cholesterol": 48,
    "sodium": 96,
    "carbs": 16,
    "fibre": 3.5,
    "sugar": 9,
    "protein": 8.9,
    "potassium": 208,
    "calcium": 73,
    "iron": 2.4,
    "phosphorus": 366,
    "info": null
  },
  {
    "id": 980,
    "disabled": null,
    "type": "meal",
    "name": "Prawn / shrimp, buttered",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "prawn / shrimp, buttered",
    "weight-g": 145,
    "volume-ml": null,
    "calories": 156.4,
    "fat": 6.1,
    "saturated": 3.2,
    "polyunsaturated": 0.7,
    "monounsaturated": 1.5,
    "trans": null,
    "cholesterol": 229,
    "sodium": 683,
    "carbs": 1.7,
    "fibre": 0,
    "sugar": 0.1,
    "protein": 23.6,
    "potassium": 199,
    "calcium": 94.2,
    "iron": 0.4,
    "phosphorus": 423,
    "info": null
  },
  {
    "id": 981,
    "disabled": null,
    "type": "meal",
    "name": "Prawn / shrimp, cheese baked",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 6,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "prawn / shrimp, cheese baked",
    "weight-g": 180,
    "volume-ml": null,
    "calories": 343,
    "fat": 34,
    "saturated": 4.7,
    "polyunsaturated": 13.2,
    "monounsaturated": 5.8,
    "trans": null,
    "cholesterol": 238,
    "sodium": 929,
    "carbs": 2.2,
    "fibre": 0,
    "sugar": 0,
    "protein": 26,
    "potassium": 214,
    "calcium": 161,
    "iron": 0.5,
    "phosphorus": 477,
    "info": null
  },
  {
    "id": 306,
    "disabled": null,
    "type": "meal",
    "name": "Prawn / shrimp, boiled / steamed / grilled",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 6,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "large",
    "info-include-exclude": null,
    "search-text": "prawn / shrimp, boiled steamed grilled",
    "weight-g": 60,
    "volume-ml": null,
    "calories": 59.6,
    "fat": 0.2,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 113.4,
    "sodium": 66.6,
    "carbs": 0.1,
    "fibre": null,
    "sugar": null,
    "protein": 14.4,
    "potassium": 155.4,
    "calcium": 42,
    "iron": 0.3,
    "phosphorus": 142.2,
    "info": "(large)"
  },
  {
    "id": 512,
    "disabled": null,
    "type": "snack",
    "name": "Profiterole",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Cream puff",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "profiterole cream puff",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 110,
    "fat": 6.1,
    "saturated": 2.3,
    "polyunsaturated": 1.4,
    "monounsaturated": 2,
    "trans": null,
    "cholesterol": 22,
    "sodium": 87,
    "carbs": 12.3,
    "fibre": 0.3,
    "sugar": 7.3,
    "protein": 1.5,
    "potassium": 22,
    "calcium": 12,
    "iron": 0.3,
    "phosphorus": 23,
    "info": "Cream puff"
  },
  {
    "id": 513,
    "disabled": null,
    "type": "meal",
    "name": "Prosciutto ham",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[prosciutto|proscuitto] ham",
    "weight-g": 9.2,
    "volume-ml": null,
    "calories": 17.2,
    "fat": 0.8,
    "saturated": 0.3,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.4,
    "trans": null,
    "cholesterol": 6.4,
    "sodium": 248,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 2.6,
    "potassium": 46.9,
    "calcium": 0.9,
    "iron": 0.1,
    "phosphorus": 29.3,
    "info": null
  },
  {
    "id": 180,
    "disabled": 1,
    "type": "drink",
    "name": "Protein powder, flavoured",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "scoop",
    "info-translation": null,
    "info-brands": "MyProtein",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "protein powder, flavoured myprotein",
    "weight-g": 32,
    "volume-ml": null,
    "calories": 130,
    "fat": 1,
    "saturated": 0.5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 60,
    "carbs": 5,
    "fibre": 1,
    "sugar": 2,
    "protein": 24,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. MyProtein"
  },
  {
    "id": 181,
    "disabled": 1,
    "type": "drink",
    "name": "Protein powder, unflavored",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "scoop",
    "info-translation": null,
    "info-brands": "MyProtein",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "protein powder, unflavored myprotein",
    "weight-g": 32,
    "volume-ml": null,
    "calories": 120,
    "fat": 1.5,
    "saturated": 1,
    "polyunsaturated": 0.3,
    "monounsaturated": 0.2,
    "trans": 0,
    "cholesterol": 40,
    "sodium": 130,
    "carbs": 3,
    "fibre": 0,
    "sugar": 2,
    "protein": 24,
    "potassium": null,
    "calcium": 80,
    "iron": 0.4,
    "phosphorus": null,
    "info": "e.g. MyProtein"
  },
  {
    "id": 704,
    "disabled": null,
    "type": "snack",
    "name": "Prunes, dried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 3,
    "unit": "pieces",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "prunes, dried",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 44,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 3,
    "carbs": 10.2,
    "fibre": 1.7,
    "sugar": 10.2,
    "protein": 0.8,
    "potassium": 228,
    "calcium": 10,
    "iron": 0.8,
    "phosphorus": 22,
    "info": null
  },
  {
    "id": 633,
    "disabled": null,
    "type": "meal",
    "name": "Pumpkin, roasted",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pumpkin, roasted",
    "weight-g": 150,
    "volume-ml": null,
    "calories": 119,
    "fat": 3.9,
    "saturated": 0.7,
    "polyunsaturated": 2.3,
    "monounsaturated": 0.7,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 262,
    "carbs": 19.9,
    "fibre": 0.7,
    "sugar": 15.3,
    "protein": 1.3,
    "potassium": 162,
    "calcium": 30,
    "iron": 1,
    "phosphorus": 56,
    "info": null
  },
  {
    "id": 75,
    "disabled": null,
    "type": "snack",
    "name": "Putu mayam",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Iddayapam / string hoppers",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with grated coconut and gula melaka",
    "search-text": "putu mayam iddayapam / string hoppers",
    "weight-g": 104,
    "volume-ml": null,
    "calories": 195.1,
    "fat": 3.5,
    "saturated": 3,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 302,
    "carbs": 38.6,
    "fibre": 2.9,
    "sugar": 6.8,
    "protein": 2.3,
    "potassium": 58,
    "calcium": 7,
    "iron": 0.9,
    "phosphorus": 25,
    "info": "Iddayapam / string hoppers – with grated coconut and gula melaka"
  },
  {
    "id": 77,
    "disabled": null,
    "type": "snack",
    "name": "Putu piring",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with grated coconut and gula melaka",
    "search-text": "putu piring",
    "weight-g": 20,
    "volume-ml": null,
    "calories": 47.9,
    "fat": 0.7,
    "saturated": 0.6,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 65,
    "carbs": 9.8,
    "fibre": 1,
    "sugar": 4.5,
    "protein": 0.6,
    "potassium": 40,
    "calcium": 8,
    "iron": 0.1,
    "phosphorus": 17,
    "info": "with grated coconut and gula melaka"
  },
  {
    "id": 307,
    "disabled": null,
    "type": "meal",
    "name": "Quesadilla",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": 1,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with meat",
    "search-text": "quesadilla",
    "weight-g": 110,
    "volume-ml": null,
    "calories": 227.4,
    "fat": 13.4,
    "saturated": 7.5,
    "polyunsaturated": 3,
    "monounsaturated": 3,
    "trans": null,
    "cholesterol": 26,
    "sodium": 421.5,
    "carbs": 10.9,
    "fibre": 1.4,
    "sugar": 1.3,
    "protein": 15.8,
    "potassium": 164.5,
    "calcium": 133,
    "iron": 1.8,
    "phosphorus": 281.5,
    "info": "with meat"
  },
  {
    "id": 892,
    "disabled": null,
    "type": "meal",
    "name": "Quiche Lorraine",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "quiche lorraine",
    "weight-g": 45,
    "volume-ml": null,
    "calories": 162,
    "fat": 10,
    "saturated": 5,
    "polyunsaturated": 0.6,
    "monounsaturated": 3.3,
    "trans": null,
    "cholesterol": 72,
    "sodium": 270,
    "carbs": 12,
    "fibre": 0.5,
    "sugar": 0.5,
    "protein": 6,
    "potassium": 30,
    "calcium": 58,
    "iron": 0.5,
    "phosphorus": 44,
    "info": null
  },
  {
    "id": 514,
    "disabled": null,
    "type": "meal",
    "name": "Quiche",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with filling",
    "search-text": "quiche",
    "weight-g": 124,
    "volume-ml": null,
    "calories": 287.3,
    "fat": 20.1,
    "saturated": 7.7,
    "polyunsaturated": 3.3,
    "monounsaturated": 7.4,
    "trans": null,
    "cholesterol": 24,
    "sodium": 322,
    "carbs": 22.8,
    "fibre": 1.7,
    "sugar": 0.9,
    "protein": 3.8,
    "potassium": 176,
    "calcium": 65,
    "iron": 1.5,
    "phosphorus": 55,
    "info": "with filling"
  },
  {
    "id": 423,
    "disabled": null,
    "type": "meal",
    "name": "Quinoa",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "quinoa",
    "weight-g": 185,
    "volume-ml": null,
    "calories": 222.1,
    "fat": 3.6,
    "saturated": 0.4,
    "polyunsaturated": 2,
    "monounsaturated": 1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 13,
    "carbs": 39.4,
    "fibre": 5.2,
    "sugar": 1.6,
    "protein": 8.1,
    "potassium": 318,
    "calcium": 31.4,
    "iron": 2.8,
    "phosphorus": 281,
    "info": null
  },
  {
    "id": 661,
    "disabled": null,
    "type": "meal",
    "name": "Radish, all types",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "radish, all types",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 15,
    "fat": 0.2,
    "saturated": 0.5,
    "polyunsaturated": 2.3,
    "monounsaturated": 0.7,
    "trans": null,
    "cholesterol": 0,
    "sodium": 242,
    "carbs": 2.4,
    "fibre": 0.8,
    "sugar": 2.3,
    "protein": 0.9,
    "potassium": 81,
    "calcium": 36,
    "iron": 0.9,
    "phosphorus": 20,
    "info": null
  },
  {
    "id": 1044,
    "disabled": null,
    "type": "snack",
    "name": "Rainbow cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "rainbow cake",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 269,
    "fat": 12,
    "saturated": 4,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 0,
    "carbs": 36,
    "fibre": 0,
    "sugar": 25,
    "protein": 2,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 401,
    "disabled": null,
    "type": "snack",
    "name": "Rambutan",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 4,
    "unit": "fruit",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "rambutan",
    "weight-g": 140,
    "volume-ml": null,
    "calories": 40.1,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 4,
    "carbs": 9.3,
    "fibre": 0.4,
    "sugar": 9.3,
    "protein": 0.5,
    "potassium": 33.3,
    "calcium": 4.7,
    "iron": 1.7,
    "phosphorus": 4,
    "info": null
  },
  {
    "id": 887,
    "disabled": null,
    "type": "meal",
    "name": "Ramen, dry curry with meat",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ramen, dry curry with meat",
    "weight-g": 181,
    "volume-ml": null,
    "calories": 610.7,
    "fat": 27.5,
    "saturated": 10.1,
    "polyunsaturated": 4.9,
    "monounsaturated": 10.4,
    "trans": null,
    "cholesterol": 85,
    "sodium": 1778,
    "carbs": 57.8,
    "fibre": 2.6,
    "sugar": 1.6,
    "protein": 33,
    "potassium": 348,
    "calcium": 37,
    "iron": 4.6,
    "phosphorus": 278,
    "info": null
  },
  {
    "id": 714,
    "disabled": null,
    "type": "snack",
    "name": "Raspberries",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 25,
    "unit": "fruit",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "raspberries",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 27.5,
    "fat": 0.5,
    "saturated": 0,
    "polyunsaturated": 0.3,
    "monounsaturated": 0.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 4.8,
    "fibre": 6.8,
    "sugar": 4.8,
    "protein": 1,
    "potassium": 152.5,
    "calcium": 22.5,
    "iron": 0.5,
    "phosphorus": 12.5,
    "info": null
  },
  {
    "id": 515,
    "disabled": null,
    "type": "meal",
    "name": "Ratatouille",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ratatouille",
    "weight-g": 214,
    "volume-ml": null,
    "calories": 148.3,
    "fat": 9.8,
    "saturated": 1.4,
    "polyunsaturated": 1.2,
    "monounsaturated": 6.7,
    "trans": null,
    "cholesterol": 0,
    "sodium": 323,
    "carbs": 12.8,
    "fibre": 3.4,
    "sugar": 6.2,
    "protein": 2.1,
    "potassium": 385,
    "calcium": 40.7,
    "iron": 0.9,
    "phosphorus": 53.3,
    "info": null
  },
  {
    "id": 50,
    "disabled": null,
    "type": "snack",
    "name": "Red ruby dessert",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "red ruby dessert",
    "weight-g": 390,
    "volume-ml": null,
    "calories": 295.8,
    "fat": 21.8,
    "saturated": 20.5,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 70,
    "carbs": 22.6,
    "fibre": 6.2,
    "sugar": 16.7,
    "protein": 2.3,
    "potassium": 261,
    "calcium": 35,
    "iron": 0,
    "phosphorus": 54,
    "info": null
  },
  {
    "id": 928,
    "disabled": null,
    "type": "meal",
    "name": "Red sweet sauce",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "for yong tau foo",
    "search-text": "red sweet sauce",
    "weight-g": 17,
    "volume-ml": null,
    "calories": 23.5,
    "fat": 0.7,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 273,
    "carbs": 3.9,
    "fibre": 0.3,
    "sugar": 3.9,
    "protein": 0.4,
    "potassium": 3,
    "calcium": 8,
    "iron": 0.4,
    "phosphorus": 3,
    "info": "for yong tau foo"
  },
  {
    "id": 1013,
    "disabled": null,
    "type": "snack",
    "name": "Red velvet cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "red velvet cake",
    "weight-g": 75,
    "volume-ml": null,
    "calories": 308,
    "fat": 18.8,
    "saturated": 6.8,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 42,
    "sodium": 135,
    "carbs": 30,
    "fibre": 0.8,
    "sugar": 21.8,
    "protein": 3.8,
    "potassium": null,
    "calcium": 30,
    "iron": 0.5,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 684,
    "disabled": null,
    "type": "meal",
    "name": "Rhubarb",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "rhubarb",
    "weight-g": 140,
    "volume-ml": null,
    "calories": 21.6,
    "fat": 0,
    "saturated": 0.1,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 4,
    "carbs": 4.5,
    "fibre": 1.8,
    "sugar": 1.1,
    "protein": 0.9,
    "potassium": 259,
    "calcium": 82,
    "iron": 0.2,
    "phosphorus": 14,
    "info": null
  },
  {
    "id": 576,
    "disabled": null,
    "type": "drink",
    "name": "Ribena",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "packet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ribena",
    "weight-g": null,
    "volume-ml": 200,
    "calories": 84,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 21,
    "fibre": null,
    "sugar": 20.8,
    "protein": 0,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 577,
    "disabled": null,
    "type": "drink",
    "name": "Ribena, less sweet",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "packet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ribena, less sweet",
    "weight-g": null,
    "volume-ml": 200,
    "calories": 67.2,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 16.8,
    "fibre": null,
    "sugar": 16.8,
    "protein": 0,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 982,
    "disabled": null,
    "type": "snack",
    "name": "Rice ball, without filling",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "piece",
    "info-translation": "Tang Yuan",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "rice ball, without filling [tang|tong] [yuan|yuen]",
    "weight-g": 40,
    "volume-ml": null,
    "calories": 48.9,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": "0,1",
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 11.2,
    "fibre": 0,
    "sugar": 0,
    "protein": 0.8,
    "potassium": 0,
    "calcium": 5,
    "iron": 1.3,
    "phosphorus": 2,
    "info": "Tang Yuan"
  },
  {
    "id": 57,
    "disabled": null,
    "type": "snack",
    "name": "Rice balls with filling",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "piece",
    "info-translation": "Tang Yuan",
    "info-brands": null,
    "info-examples": "peanut, black sesame",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "rice balls with filling [tang|tong] [yuan|yuen] peanut, black sesame",
    "weight-g": 46,
    "volume-ml": null,
    "calories": 179.7,
    "fat": 9.3,
    "saturated": 4.8,
    "polyunsaturated": 0.8,
    "monounsaturated": 3.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 2,
    "carbs": 21.5,
    "fibre": 1,
    "sugar": 11.8,
    "protein": 2.5,
    "potassium": 55,
    "calcium": 14,
    "iron": 0.3,
    "phosphorus": 43,
    "info": "Tang Yuan, e.g. peanut, black sesame"
  },
  {
    "id": 821,
    "disabled": null,
    "type": "meal",
    "name": "Rice bowl with meat",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bucket",
    "info-translation": null,
    "info-brands": "KFC",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "rice bowl with meat kfc",
    "weight-g": 294,
    "volume-ml": null,
    "calories": 515,
    "fat": 19,
    "saturated": 10,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 1511,
    "carbs": 72,
    "fibre": null,
    "sugar": null,
    "protein": 14,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. KFC"
  },
  {
    "id": 844,
    "disabled": null,
    "type": "meal",
    "name": "Rice burger with meat or seafood",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "burger",
    "info-translation": null,
    "info-brands": "MOS",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "rice burger with meat or seafood mos",
    "weight-g": 205,
    "volume-ml": null,
    "calories": 439.5,
    "fat": 16.3,
    "saturated": 7.8,
    "polyunsaturated": 7.1,
    "monounsaturated": 9.9,
    "trans": null,
    "cholesterol": 40,
    "sodium": 1153,
    "carbs": 50.6,
    "fibre": 3,
    "sugar": 0.5,
    "protein": 22.6,
    "potassium": 83,
    "calcium": 160,
    "iron": 2.5,
    "phosphorus": 330,
    "info": "e.g. MOS"
  },
  {
    "id": 607,
    "disabled": null,
    "type": "snack",
    "name": "Rice crackers",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "packet",
    "info-translation": null,
    "info-brands": "Bin Bin, Senbei",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "rice crackers bin bin, senbei",
    "weight-g": 15,
    "volume-ml": null,
    "calories": 26.2,
    "fat": 1.8,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 50,
    "carbs": null,
    "fibre": null,
    "sugar": 3,
    "protein": 2.5,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Bin Bin, Senbei"
  },
  {
    "id": 983,
    "disabled": null,
    "type": "drink",
    "name": "Rice milk, sweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "rice milk, sweetened",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 173.5,
    "fat": 3.1,
    "saturated": 0,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 94,
    "carbs": 35.4,
    "fibre": 0.5,
    "sugar": 29.2,
    "protein": 1,
    "potassium": null,
    "calcium": 78,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 168,
    "disabled": null,
    "type": "drink",
    "name": "Rice milk, unsweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "rice milk, unsweetened",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 139.5,
    "fat": 2.7,
    "saturated": 0.3,
    "polyunsaturated": 0.3,
    "monounsaturated": 2.2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 159,
    "carbs": 27.7,
    "fibre": null,
    "sugar": 14.4,
    "protein": 1.1,
    "potassium": null,
    "calcium": 3,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 35,
    "disabled": null,
    "type": "snack",
    "name": "Rice pudding",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "rice pudding",
    "weight-g": 218,
    "volume-ml": null,
    "calories": 353,
    "fat": 13.7,
    "saturated": 6.7,
    "polyunsaturated": 1.1,
    "monounsaturated": 5.9,
    "trans": null,
    "cholesterol": 26,
    "sodium": 137,
    "carbs": 43,
    "fibre": 3.1,
    "sugar": 15.5,
    "protein": 14.2,
    "potassium": 390,
    "calcium": 438,
    "iron": 0.7,
    "phosphorus": 268,
    "info": null
  },
  {
    "id": 309,
    "disabled": null,
    "type": "meal",
    "name": "Rice, brown / red",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "rice, brown / red",
    "weight-g": 180,
    "volume-ml": null,
    "calories": 272,
    "fat": 1.8,
    "saturated": 0.5,
    "polyunsaturated": 0.7,
    "monounsaturated": 0.5,
    "trans": null,
    "cholesterol": 0,
    "sodium": 4,
    "carbs": 57.2,
    "fibre": 2.7,
    "sugar": 0.5,
    "protein": 5.8,
    "potassium": 0.7,
    "calcium": 9,
    "iron": 0.9,
    "phosphorus": 558,
    "info": null
  },
  {
    "id": 516,
    "disabled": null,
    "type": "meal",
    "name": "Risotto",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "risotto",
    "weight-g": 400,
    "volume-ml": null,
    "calories": 664.3,
    "fat": 17.9,
    "saturated": 7.5,
    "polyunsaturated": 2.5,
    "monounsaturated": 6.6,
    "trans": 0.4,
    "cholesterol": 22,
    "sodium": 819,
    "carbs": 108.4,
    "fibre": 5.1,
    "sugar": 2.5,
    "protein": 17.4,
    "potassium": 380,
    "calcium": 227,
    "iron": 1.8,
    "phosphorus": 373,
    "info": null
  },
  {
    "id": 988,
    "disabled": null,
    "type": "meal",
    "name": "Roast chicken and char siew rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "roast chicken and [char|cha] [siew|shao|siu] rice",
    "weight-g": 427,
    "volume-ml": null,
    "calories": 809.6,
    "fat": 28,
    "saturated": 15.6,
    "polyunsaturated": 2.9,
    "monounsaturated": 5.8,
    "trans": null,
    "cholesterol": 170,
    "sodium": 1272,
    "carbs": 92.1,
    "fibre": 5.9,
    "sugar": 14,
    "protein": 47.3,
    "potassium": 456,
    "calcium": 55,
    "iron": 1.1,
    "phosphorus": 333,
    "info": null
  },
  {
    "id": 987,
    "disabled": null,
    "type": "meal",
    "name": "Roast chicken and roast pork rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "roast chicken and roast pork rice",
    "weight-g": 380,
    "volume-ml": null,
    "calories": 662,
    "fat": 28.8,
    "saturated": 10.9,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 88,
    "sodium": 1142,
    "carbs": 64.5,
    "fibre": 1.7,
    "sugar": null,
    "protein": 35.2,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 985,
    "disabled": null,
    "type": "meal",
    "name": "Roast duck and char siew rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "roast duck and [char|cha] [siew|shao|siu] rice",
    "weight-g": 427,
    "volume-ml": null,
    "calories": 844.8,
    "fat": 32.4,
    "saturated": 17.8,
    "polyunsaturated": 2.6,
    "monounsaturated": 8.1,
    "trans": null,
    "cholesterol": 186,
    "sodium": 1088,
    "carbs": 92.7,
    "fibre": 5.9,
    "sugar": 13.1,
    "protein": 45.6,
    "potassium": 206,
    "calcium": 71,
    "iron": 3.1,
    "phosphorus": 193,
    "info": null
  },
  {
    "id": 986,
    "disabled": null,
    "type": "meal",
    "name": "Roast duck and roast chicken rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "roast duck and roast chicken rice",
    "weight-g": 380,
    "volume-ml": null,
    "calories": 644,
    "fat": 28,
    "saturated": 10.6,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 106,
    "sodium": 1216,
    "carbs": 64.8,
    "fibre": 1.7,
    "sugar": null,
    "protein": 32.9,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 984,
    "disabled": null,
    "type": "meal",
    "name": "Roast duck and roast pork rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "roast duck and roast pork rice",
    "weight-g": 450,
    "volume-ml": null,
    "calories": 869,
    "fat": 36.2,
    "saturated": 13.9,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 128,
    "sodium": 863,
    "carbs": 97,
    "fibre": 0.9,
    "sugar": null,
    "protein": 37.3,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 762,
    "disabled": null,
    "type": "meal",
    "name": "Roast duck rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "roast duck rice",
    "weight-g": 350,
    "volume-ml": null,
    "calories": 626.1,
    "fat": 24.9,
    "saturated": 3.9,
    "polyunsaturated": 2,
    "monounsaturated": 5.1,
    "trans": null,
    "cholesterol": 84,
    "sodium": 347,
    "carbs": 88.9,
    "fibre": 3.5,
    "sugar": 1.8,
    "protein": 11.6,
    "potassium": 259,
    "calcium": 158,
    "iron": 1.8,
    "phosphorus": 291,
    "info": null
  },
  {
    "id": 760,
    "disabled": null,
    "type": "meal",
    "name": "Roast pork rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Siew Yok Rice",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "roast pork rice [siew|shao|siu] [yok|yoke|yuk] rice",
    "weight-g": 356,
    "volume-ml": null,
    "calories": 604.2,
    "fat": 27.3,
    "saturated": 9.5,
    "polyunsaturated": 3.3,
    "monounsaturated": 12.4,
    "trans": null,
    "cholesterol": 234,
    "sodium": 1164.8,
    "carbs": 59,
    "fibre": 2.2,
    "sugar": 9,
    "protein": 30.6,
    "potassium": 464.3,
    "calcium": 73.5,
    "iron": 5.8,
    "phosphorus": 126.8,
    "info": "Siew Yok Rice"
  },
  {
    "id": 718,
    "disabled": null,
    "type": "snack",
    "name": "Rock melon",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "wedge",
    "info-translation": "Cantaloupe",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "rock melon cantaloupe",
    "weight-g": 102,
    "volume-ml": null,
    "calories": 35,
    "fat": 0.2,
    "saturated": 0.1,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 16,
    "carbs": 8.3,
    "fibre": 0.9,
    "sugar": 8,
    "protein": 0.9,
    "potassium": 272,
    "calcium": 9.2,
    "iron": 0.2,
    "phosphorus": 15,
    "info": "Cantaloupe"
  },
  {
    "id": 801,
    "disabled": null,
    "type": "meal",
    "name": "Oats, rolled / steel cut, dry, unsweetened",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 3,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "oats, rolled / steel cut, dry, unsweetened",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 105,
    "fat": 1.9,
    "saturated": 0,
    "polyunsaturated": 0.7,
    "monounsaturated": 0.7,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 20.3,
    "fibre": 3,
    "sugar": 0,
    "protein": 3.8,
    "potassium": 105,
    "calcium": 15,
    "iron": 1.3,
    "phosphorus": 163,
    "info": null
  },
  {
    "id": 800,
    "disabled": null,
    "type": "meal",
    "name": "Rolled oats / steel cut oats, cooked, sweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "rolled oats / steel cut oats, cooked, sweetened",
    "weight-g": 260,
    "volume-ml": null,
    "calories": 172,
    "fat": 2.9,
    "saturated": 0.5,
    "polyunsaturated": 1,
    "monounsaturated": 1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 3,
    "carbs": 32.5,
    "fibre": 3.4,
    "sugar": 10,
    "protein": 4.2,
    "potassium": 107,
    "calcium": 18,
    "iron": 1.8,
    "phosphorus": 122,
    "info": null
  },
  {
    "id": 651,
    "disabled": null,
    "type": "meal",
    "name": "Romaine lettuce, raw",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "romaine lettuce, raw",
    "weight-g": 150,
    "volume-ml": null,
    "calories": 30.7,
    "fat": 0.3,
    "saturated": 0,
    "polyunsaturated": 0.3,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 13,
    "carbs": 5.1,
    "fibre": 3.2,
    "sugar": 1.9,
    "protein": 1.9,
    "potassium": 371,
    "calcium": 51,
    "iron": 1.6,
    "phosphorus": 45,
    "info": null
  },
  {
    "id": 581,
    "disabled": null,
    "type": "drink",
    "name": "Soda float",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "root beer, coke",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soda float root beer, coke",
    "weight-g": 240,
    "volume-ml": null,
    "calories": 205.9,
    "fat": 7,
    "saturated": 4.3,
    "polyunsaturated": 0.3,
    "monounsaturated": 1.9,
    "trans": null,
    "cholesterol": 28.8,
    "sodium": 74.4,
    "carbs": 33.6,
    "fibre": 0.5,
    "sugar": 32.2,
    "protein": 2.2,
    "potassium": 127,
    "calcium": 88.8,
    "iron": 0.1,
    "phosphorus": 67.2,
    "info": "e.g. root beer, coke"
  },
  {
    "id": 136,
    "disabled": null,
    "type": "meal",
    "name": "Roti john",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "whole",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "roti john",
    "weight-g": 253,
    "volume-ml": null,
    "calories": 722.3,
    "fat": 34.7,
    "saturated": 18.2,
    "polyunsaturated": 3,
    "monounsaturated": 11.9,
    "trans": null,
    "cholesterol": 243,
    "sodium": 2019,
    "carbs": 77.2,
    "fibre": 3,
    "sugar": 0.2,
    "protein": 25.3,
    "potassium": null,
    "calcium": 89,
    "iron": 4.5,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 134,
    "disabled": null,
    "type": "meal",
    "name": "Roti prata, plain",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "roti [prata|canai], plain",
    "weight-g": 70,
    "volume-ml": null,
    "calories": 204.3,
    "fat": 7.1,
    "saturated": 3.4,
    "polyunsaturated": 0.9,
    "monounsaturated": 2.8,
    "trans": null,
    "cholesterol": 0,
    "sodium": 260,
    "carbs": 30,
    "fibre": 1.3,
    "sugar": 3,
    "protein": 5.1,
    "potassium": 47,
    "calcium": 11,
    "iron": 1,
    "phosphorus": 35,
    "info": null
  },
  {
    "id": 56,
    "disabled": null,
    "type": "snack",
    "name": "Sago gula melaka",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Pudding with palm sugar",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sago gula melaka pudding with palm sugar",
    "weight-g": 159,
    "volume-ml": null,
    "calories": 283.3,
    "fat": 17.7,
    "saturated": 15.7,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.7,
    "trans": null,
    "cholesterol": null,
    "sodium": 143,
    "carbs": 29.2,
    "fibre": 1.4,
    "sugar": 18.3,
    "protein": 1.8,
    "potassium": 259,
    "calcium": 41,
    "iron": 2.3,
    "phosphorus": 70,
    "info": "Pudding with palm sugar"
  },
  {
    "id": 18,
    "disabled": null,
    "type": "drink",
    "name": "Sake",
    "rating": 1,
    "alcohol": 1,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "glass",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sake",
    "weight-g": null,
    "volume-ml": 30,
    "calories": 40,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0.6,
    "carbs": 1.5,
    "fibre": 0,
    "sugar": 0,
    "protein": 0.1,
    "potassium": 7.3,
    "calcium": 1.5,
    "iron": 0,
    "phosphorus": 1.8,
    "info": null
  },
  {
    "id": 809,
    "disabled": null,
    "type": "meal",
    "name": "Salad with meat and dressing",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "Caesar",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "salad with meat and dressing caesar",
    "weight-g": 226,
    "volume-ml": null,
    "calories": 334,
    "fat": 18,
    "saturated": 3.5,
    "polyunsaturated": 7.1,
    "monounsaturated": 5.6,
    "trans": null,
    "cholesterol": 25,
    "sodium": 1100,
    "carbs": 13.3,
    "fibre": 0.9,
    "sugar": 9.3,
    "protein": 31,
    "potassium": 468,
    "calcium": 38.4,
    "iron": 1.1,
    "phosphorus": 289,
    "info": "e.g. Caesar"
  },
  {
    "id": 810,
    "disabled": null,
    "type": "meal",
    "name": "Salad with processed meat and dressing",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "ham, bacon",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "salad with processed meat and dressing ham, bacon",
    "weight-g": 182,
    "volume-ml": null,
    "calories": 419,
    "fat": 32.9,
    "saturated": 6.1,
    "polyunsaturated": 17.1,
    "monounsaturated": 8.6,
    "trans": null,
    "cholesterol": 67.3,
    "sodium": 1790,
    "carbs": 5,
    "fibre": 0.7,
    "sugar": 3.8,
    "protein": 24.2,
    "potassium": 411,
    "calcium": 25.5,
    "iron": 1.1,
    "phosphorus": 233,
    "info": "e.g. ham, bacon"
  },
  {
    "id": 424,
    "disabled": null,
    "type": "meal",
    "name": "Salad, plain",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "without dressing or meat",
    "search-text": "salad, plain",
    "weight-g": 75,
    "volume-ml": null,
    "calories": 17.3,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 22,
    "carbs": 3.2,
    "fibre": 1.3,
    "sugar": 1.4,
    "protein": 0.9,
    "potassium": 190,
    "calcium": 26,
    "iron": 0.5,
    "phosphorus": 21,
    "info": "without dressing or meat"
  },
  {
    "id": 311,
    "disabled": null,
    "type": "meal",
    "name": "Salad with dressing",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "Greek",
    "info-portion-aid": null,
    "info-include-exclude": "without meat",
    "search-text": "salad with dressing greek",
    "weight-g": 337,
    "volume-ml": null,
    "calories": 269,
    "fat": 35.4,
    "saturated": 8.4,
    "polyunsaturated": 2.5,
    "monounsaturated": 7.6,
    "trans": null,
    "cholesterol": 23,
    "sodium": 518,
    "carbs": 33.4,
    "fibre": 8.2,
    "sugar": 6,
    "protein": 12.7,
    "potassium": 701,
    "calcium": 272,
    "iron": 2.6,
    "phosphorus": 291,
    "info": "without meat, e.g. Greek"
  },
  {
    "id": 848,
    "disabled": null,
    "type": "meal",
    "name": "Salmon don",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "salmon don",
    "weight-g": 277,
    "volume-ml": null,
    "calories": 393,
    "fat": 8.7,
    "saturated": 2.4,
    "polyunsaturated": 2.2,
    "monounsaturated": 3.6,
    "trans": null,
    "cholesterol": 21,
    "sodium": 22,
    "carbs": 59.4,
    "fibre": 0.5,
    "sugar": 0.2,
    "protein": 17.3,
    "potassium": 317,
    "calcium": 5,
    "iron": 4.2,
    "phosphorus": 223,
    "info": null
  },
  {
    "id": 425,
    "disabled": null,
    "type": "meal",
    "name": "Salmon, grilled / baked / pan fried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "salmon, grilled / baked pan fried",
    "weight-g": 85,
    "volume-ml": null,
    "calories": 169.7,
    "fat": 10.5,
    "saturated": 2,
    "polyunsaturated": 3.9,
    "monounsaturated": 3.6,
    "trans": null,
    "cholesterol": 53.6,
    "sodium": 51.8,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 18.8,
    "potassium": 326,
    "calcium": 12.8,
    "iron": 0.3,
    "phosphorus": 214,
    "info": null
  },
  {
    "id": 1052,
    "disabled": null,
    "type": "snack",
    "name": "Salsa sauce",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "salsa sauce",
    "weight-g": 106,
    "volume-ml": null,
    "calories": 33,
    "fat": 0.2,
    "saturated": 0.1,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 726,
    "carbs": 5.9,
    "fibre": 1.3,
    "sugar": 3,
    "protein": 1.1,
    "potassium": 374,
    "calcium": 34,
    "iron": 0.5,
    "phosphorus": 41,
    "info": null
  },
  {
    "id": 910,
    "disabled": null,
    "type": "meal",
    "name": "Salted egg yolk bun",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Liu Sha Bao",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "salted egg yolk bun [liu|lau] sha [bao|pau|pao]",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 325.6,
    "fat": 16,
    "saturated": 6.6,
    "polyunsaturated": 3.5,
    "monounsaturated": 5.9,
    "trans": null,
    "cholesterol": 377,
    "sodium": null,
    "carbs": 38,
    "fibre": 1.3,
    "sugar": 1,
    "protein": 7.4,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "Liu Sha Bao"
  },
  {
    "id": 789,
    "disabled": null,
    "type": "meal",
    "name": "Sam loh fish horfun",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[sam|san] [loh|lao|lou] fish horfun",
    "weight-g": 372,
    "volume-ml": null,
    "calories": 514.8,
    "fat": 16.4,
    "saturated": 2.5,
    "polyunsaturated": 7.8,
    "monounsaturated": 4,
    "trans": 0.1,
    "cholesterol": 12,
    "sodium": 2034,
    "carbs": 69.7,
    "fibre": 2.7,
    "sugar": 1.1,
    "protein": 22.1,
    "potassium": 485,
    "calcium": 50,
    "iron": 6.7,
    "phosphorus": 309,
    "info": null
  },
  {
    "id": 896,
    "disabled": null,
    "type": "meal",
    "name": "Sambal fried rice",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sambal fried rice",
    "weight-g": 370,
    "volume-ml": null,
    "calories": 667.6,
    "fat": 24.8,
    "saturated": 8.9,
    "polyunsaturated": 4.4,
    "monounsaturated": 10,
    "trans": null,
    "cholesterol": 207,
    "sodium": 1645,
    "carbs": 82.9,
    "fibre": 2.2,
    "sugar": 18.5,
    "protein": 28.2,
    "potassium": 298,
    "calcium": 120,
    "iron": 2.9,
    "phosphorus": 212,
    "info": null
  },
  {
    "id": 962,
    "disabled": null,
    "type": "meal",
    "name": "Sambal kang kung",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": "Water spinach fried with belacan",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sambal kang [kung|kong] water spinach fried with belacan",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 80.1,
    "fat": 4.5,
    "saturated": 1.8,
    "polyunsaturated": 0.5,
    "monounsaturated": 1.9,
    "trans": null,
    "cholesterol": 20,
    "sodium": 527,
    "carbs": 5.2,
    "fibre": 3,
    "sugar": 5,
    "protein": 4.7,
    "potassium": 180,
    "calcium": 155,
    "iron": 5.5,
    "phosphorus": 59,
    "info": "Water spinach fried with belacan"
  },
  {
    "id": 454,
    "disabled": null,
    "type": "meal",
    "name": "Sambal stingray",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sambal stingray",
    "weight-g": 150,
    "volume-ml": null,
    "calories": 185.8,
    "fat": 6.6,
    "saturated": 2.3,
    "polyunsaturated": 0.6,
    "monounsaturated": 1.8,
    "trans": null,
    "cholesterol": 154.4,
    "sodium": 943.5,
    "carbs": 1.9,
    "fibre": 0,
    "sugar": 0,
    "protein": 29.7,
    "potassium": null,
    "calcium": 175.5,
    "iron": 2.7,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 891,
    "disabled": null,
    "type": "meal",
    "name": "Sandwich with fillings",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "egg, ham, cheese, tuna, chicken",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sandwich with fillings egg, ham, cheese, tuna, chicken",
    "weight-g": 150,
    "volume-ml": null,
    "calories": 473,
    "fat": 20.3,
    "saturated": 4.8,
    "polyunsaturated": 9.7,
    "monounsaturated": 4.6,
    "trans": null,
    "cholesterol": 39,
    "sodium": 826,
    "carbs": 25.6,
    "fibre": 1.5,
    "sugar": 5.4,
    "protein": 15.9,
    "potassium": 246,
    "calcium": 294,
    "iron": 2.4,
    "phosphorus": 244,
    "info": "e.g. egg, ham, cheese, tuna, chicken"
  },
  {
    "id": 201,
    "disabled": null,
    "type": "snack",
    "name": "Sardine puff",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sardine puff",
    "weight-g": 72,
    "volume-ml": null,
    "calories": 225.3,
    "fat": 12.5,
    "saturated": 5.6,
    "polyunsaturated": 1.3,
    "monounsaturated": 5,
    "trans": null,
    "cholesterol": 171,
    "sodium": 292,
    "carbs": 22.2,
    "fibre": 1.9,
    "sugar": 2.2,
    "protein": 6,
    "potassium": 121,
    "calcium": 43,
    "iron": 0.9,
    "phosphorus": 70,
    "info": null
  },
  {
    "id": 540,
    "disabled": null,
    "type": "meal",
    "name": "Sashimi, raw fish",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "salmon, tuna",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sashimi, raw fish salmon, tuna",
    "weight-g": 28.4,
    "volume-ml": null,
    "calories": 34.5,
    "fat": 1.3,
    "saturated": 0.2,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.4,
    "trans": null,
    "cholesterol": 13,
    "sodium": 21.3,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 5.8,
    "potassium": 104,
    "calcium": 2,
    "iron": 0.1,
    "phosphorus": 74,
    "info": "e.g. salmon, tuna"
  },
  {
    "id": 138,
    "disabled": null,
    "type": "meal",
    "name": "Satay beehoon",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Rice vermicelli",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "satay beehoon rice vermicelli",
    "weight-g": 452,
    "volume-ml": null,
    "calories": 760.7,
    "fat": 37.5,
    "saturated": 14.5,
    "polyunsaturated": 7.2,
    "monounsaturated": 14,
    "trans": null,
    "cholesterol": 95,
    "sodium": 1573,
    "carbs": 75.5,
    "fibre": 3,
    "sugar": 9,
    "protein": 30.3,
    "potassium": 649,
    "calcium": 199,
    "iron": 7.7,
    "phosphorus": 366,
    "info": "Rice vermicelli"
  },
  {
    "id": 137,
    "disabled": null,
    "type": "meal",
    "name": "Satay with sauce",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "satay with sauce",
    "weight-g": 18,
    "volume-ml": null,
    "calories": 50,
    "fat": 2.8,
    "saturated": 1.1,
    "polyunsaturated": 0.4,
    "monounsaturated": 1.2,
    "trans": null,
    "cholesterol": 12,
    "sodium": 66.5,
    "carbs": 3.1,
    "fibre": 0.2,
    "sugar": 3,
    "protein": 3.1,
    "potassium": 44.8,
    "calcium": 5,
    "iron": 0.6,
    "phosphorus": 27.8,
    "info": null
  },
  {
    "id": 312,
    "disabled": null,
    "type": "meal",
    "name": "Satay, without sauce",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "satay, without sauce",
    "weight-g": 11,
    "volume-ml": null,
    "calories": 30.8,
    "fat": 1.2,
    "saturated": 0.6,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.5,
    "trans": null,
    "cholesterol": 12,
    "sodium": 32,
    "carbs": 2.3,
    "fibre": 0,
    "sugar": 2.3,
    "protein": 2.7,
    "potassium": 31,
    "calcium": 3,
    "iron": 0.5,
    "phosphorus": 21,
    "info": null
  },
  {
    "id": 873,
    "disabled": null,
    "type": "meal",
    "name": "Sauce, BBQ / habanero",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sauce, bbq / habanero",
    "weight-g": 17,
    "volume-ml": null,
    "calories": 29.2,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 175,
    "carbs": 6.9,
    "fibre": 0.2,
    "sugar": 5.7,
    "protein": 0.1,
    "potassium": 39.4,
    "calcium": 5.6,
    "iron": 0.1,
    "phosphorus": 3.4,
    "info": null
  },
  {
    "id": 350,
    "disabled": null,
    "type": "snack",
    "name": "Sauce, satay",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sauce, satay",
    "weight-g": 29,
    "volume-ml": null,
    "calories": 77.3,
    "fat": 6.5,
    "saturated": 2,
    "polyunsaturated": 1.4,
    "monounsaturated": 2.7,
    "trans": null,
    "cholesterol": 0,
    "sodium": 138,
    "carbs": 3.1,
    "fibre": 0.9,
    "sugar": 2.8,
    "protein": 1.6,
    "potassium": 55,
    "calcium": 8,
    "iron": 0.4,
    "phosphorus": 27,
    "info": null
  },
  {
    "id": 874,
    "disabled": null,
    "type": "meal",
    "name": "Sauce, sweet Thai chilli",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sauce, sweet thai chilli",
    "weight-g": 20,
    "volume-ml": null,
    "calories": 40.4,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 268,
    "carbs": 9.9,
    "fibre": 0.3,
    "sugar": 9,
    "protein": 0.2,
    "potassium": 14,
    "calcium": 1,
    "iron": 0.1,
    "phosphorus": 4,
    "info": null
  },
  {
    "id": 139,
    "disabled": null,
    "type": "meal",
    "name": "Sausage, boiled",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sausage, boiled",
    "weight-g": 68,
    "volume-ml": null,
    "calories": 210,
    "fat": 19.2,
    "saturated": 6.3,
    "polyunsaturated": 2.2,
    "monounsaturated": 7.6,
    "trans": 0.1,
    "cholesterol": 42,
    "sodium": 562,
    "carbs": 0.6,
    "fibre": 0,
    "sugar": 0.6,
    "protein": 8.2,
    "potassium": 328,
    "calcium": 7.5,
    "iron": 0.4,
    "phosphorus": 107,
    "info": null
  },
  {
    "id": 140,
    "disabled": null,
    "type": "meal",
    "name": "Sausage, fried",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sausage, fried",
    "weight-g": 87,
    "volume-ml": null,
    "calories": 282,
    "fat": 23,
    "saturated": 7.9,
    "polyunsaturated": 4.3,
    "monounsaturated": 10.1,
    "trans": 0.1,
    "cholesterol": 71,
    "sodium": 697,
    "carbs": 1.6,
    "fibre": 0,
    "sugar": 1.2,
    "protein": 16,
    "potassium": 295,
    "calcium": 11,
    "iron": 1.1,
    "phosphorus": 132,
    "info": null
  },
  {
    "id": 313,
    "disabled": null,
    "type": "meal",
    "name": "Savory crepe",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "cheese, salmon",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "savory crepe cheese, salmon",
    "weight-g": 200,
    "volume-ml": null,
    "calories": 393.1,
    "fat": 19.9,
    "saturated": 11.4,
    "polyunsaturated": 1.8,
    "monounsaturated": 5.4,
    "trans": null,
    "cholesterol": 96,
    "sodium": 1337,
    "carbs": 25.4,
    "fibre": 1.6,
    "sugar": 4.5,
    "protein": 28.1,
    "potassium": 367,
    "calcium": 342,
    "iron": 2.8,
    "phosphorus": 287,
    "info": "e.g. cheese, salmon"
  },
  {
    "id": 814,
    "disabled": null,
    "type": "meal",
    "name": "Savoury muffin with patty",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "muffin",
    "info-translation": null,
    "info-brands": "McMuffin",
    "info-examples": "sausage, chicken",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "savoury muffin with patty mcmuffin sausage, chicken",
    "weight-g": 163,
    "volume-ml": null,
    "calories": 356,
    "fat": 16,
    "saturated": 6.6,
    "polyunsaturated": 5,
    "monounsaturated": 4,
    "trans": null,
    "cholesterol": 362,
    "sodium": 750,
    "carbs": 30,
    "fibre": 3,
    "sugar": 1,
    "protein": 23,
    "potassium": null,
    "calcium": 256,
    "iron": 3.3,
    "phosphorus": null,
    "info": "e.g. McMuffin, sausage, chicken"
  },
  {
    "id": 314,
    "disabled": null,
    "type": "meal",
    "name": "Sayur lodeh",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": 1,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sayur lodeh",
    "weight-g": 250,
    "volume-ml": null,
    "calories": 163.9,
    "fat": 14.3,
    "saturated": 9.5,
    "polyunsaturated": 1.5,
    "monounsaturated": 2.8,
    "trans": null,
    "cholesterol": 10,
    "sodium": 1175,
    "carbs": 1.8,
    "fibre": 2.8,
    "sugar": 0,
    "protein": 7,
    "potassium": 280,
    "calcium": 80,
    "iron": 0.6,
    "phosphorus": 998,
    "info": null
  },
  {
    "id": 657,
    "disabled": 1,
    "type": "meal",
    "name": "Scallion",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[scallion|green|onion|spring]",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 118.6,
    "fat": 9,
    "saturated": 1.2,
    "polyunsaturated": 0.5,
    "monounsaturated": 0.7,
    "trans": null,
    "cholesterol": 0,
    "sodium": 273,
    "carbs": 7.5,
    "fibre": 2.6,
    "sugar": 2.4,
    "protein": 1.9,
    "potassium": 253,
    "calcium": 70,
    "iron": 1.4,
    "phosphorus": 34,
    "info": null
  },
  {
    "id": 192,
    "disabled": null,
    "type": "snack",
    "name": "Scone, plain",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "scone, plain",
    "weight-g": 65,
    "volume-ml": null,
    "calories": 219.1,
    "fat": 5.5,
    "saturated": 2,
    "polyunsaturated": 0.5,
    "monounsaturated": 2.3,
    "trans": 0.2,
    "cholesterol": 7,
    "sodium": 495,
    "carbs": 37.3,
    "fibre": 1.4,
    "sugar": 4.2,
    "protein": 5.1,
    "potassium": 72,
    "calcium": 46,
    "iron": 0.6,
    "phosphorus": 68,
    "info": null
  },
  {
    "id": 1063,
    "disabled": null,
    "type": "meal",
    "name": "Seafood claypot noodle",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "seafood claypot noodle",
    "weight-g": 555,
    "volume-ml": null,
    "calories": 678.5,
    "fat": 43.3,
    "saturated": 18.3,
    "polyunsaturated": 5,
    "monounsaturated": 18.3,
    "trans": null,
    "cholesterol": 211,
    "sodium": 3413,
    "carbs": 42.2,
    "fibre": 5,
    "sugar": 2,
    "protein": 30,
    "potassium": 655,
    "calcium": 183,
    "iron": 1.7,
    "phosphorus": 194,
    "info": null
  },
  {
    "id": 315,
    "disabled": null,
    "type": "meal",
    "name": "Seafood hor fun",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "seafood hor fun",
    "weight-g": 694,
    "volume-ml": null,
    "calories": 749,
    "fat": 27,
    "saturated": 11.2,
    "polyunsaturated": 3.3,
    "monounsaturated": 10.6,
    "trans": null,
    "cholesterol": 77,
    "sodium": 1886,
    "carbs": 91.8,
    "fibre": 7.2,
    "sugar": 2,
    "protein": 34.7,
    "potassium": 180,
    "calcium": 62,
    "iron": 2.1,
    "phosphorus": 236,
    "info": null
  },
  {
    "id": 790,
    "disabled": null,
    "type": "meal",
    "name": "Seafood white beehoon",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "seafood white beehoon",
    "weight-g": 554,
    "volume-ml": null,
    "calories": 526.6,
    "fat": 24.6,
    "saturated": 9.7,
    "polyunsaturated": 2.7,
    "monounsaturated": 9.2,
    "trans": null,
    "cholesterol": 314,
    "sodium": 1182,
    "carbs": 51.9,
    "fibre": 3.6,
    "sugar": 5.1,
    "protein": 24.4,
    "potassium": 753,
    "calcium": 360,
    "iron": 1.4,
    "phosphorus": 703,
    "info": null
  },
  {
    "id": 414,
    "disabled": null,
    "type": "snack",
    "name": "Seaweed salad",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "seaweed salad",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 69.6,
    "fat": 2.8,
    "saturated": 0,
    "polyunsaturated": 1.4,
    "monounsaturated": 1.4,
    "trans": null,
    "cholesterol": 0,
    "sodium": 811,
    "carbs": 11.1,
    "fibre": 4.4,
    "sugar": 5.6,
    "protein": 0,
    "potassium": null,
    "calcium": 111,
    "iron": 0.8,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 427,
    "disabled": null,
    "type": "meal",
    "name": "Seaweed soup",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "seaweed soup",
    "weight-g": 200,
    "volume-ml": null,
    "calories": 37.6,
    "fat": 2,
    "saturated": 0.6,
    "polyunsaturated": 0.5,
    "monounsaturated": 0.8,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1223,
    "carbs": 0.6,
    "fibre": 0,
    "sugar": 0,
    "protein": 4.3,
    "potassium": 198,
    "calcium": 29,
    "iron": 0.7,
    "phosphorus": 51,
    "info": null
  },
  {
    "id": 699,
    "disabled": null,
    "type": "meal",
    "name": "Seaweed, roasted",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "minipack",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "seaweed, roasted",
    "weight-g": 5,
    "volume-ml": null,
    "calories": 8.8,
    "fat": 0,
    "saturated": 0.2,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 56,
    "carbs": 1.1,
    "fibre": 0,
    "sugar": 0,
    "protein": 1.1,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 608,
    "disabled": null,
    "type": "snack",
    "name": "Seeded crackers",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "packet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "flaxseed, sesame",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "seeded crackers flaxseed, sesame",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 100.4,
    "fat": 3.6,
    "saturated": 1,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 160,
    "carbs": 11,
    "fibre": 8,
    "sugar": 0,
    "protein": 6,
    "potassium": 85,
    "calcium": 72,
    "iron": 2,
    "phosphorus": null,
    "info": "e.g. flaxseed, sesame"
  },
  {
    "id": 722,
    "disabled": null,
    "type": "meal",
    "name": "Seitan, fried gluten",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "3\" x 3\" x 1\"",
    "info-include-exclude": null,
    "search-text": "seitan, fried gluten",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 145.4,
    "fat": 2.4,
    "saturated": 0.2,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 417,
    "carbs": 11.9,
    "fibre": 1.2,
    "sugar": 3.6,
    "protein": 19.1,
    "potassium": null,
    "calcium": 48,
    "iron": 2.1,
    "phosphorus": null,
    "info": "(3\" x 3\" x 1\")"
  },
  {
    "id": 316,
    "disabled": null,
    "type": "meal",
    "name": "Seolleongtang / Sokkoritang",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Korean oxtail soup",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "seolleongtang / sokkoritang korean oxtail soup",
    "weight-g": 375,
    "volume-ml": null,
    "calories": 291,
    "fat": 13.1,
    "saturated": 8.2,
    "polyunsaturated": 3.9,
    "monounsaturated": 11,
    "trans": null,
    "cholesterol": 41,
    "sodium": 698,
    "carbs": 8.1,
    "fibre": 1.8,
    "sugar": 2.9,
    "protein": 13.1,
    "potassium": 310,
    "calcium": 43,
    "iron": 2.3,
    "phosphorus": 33,
    "info": "Korean oxtail soup"
  },
  {
    "id": 317,
    "disabled": null,
    "type": "meal",
    "name": "Shabu shabu",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 3,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "pork, beef, lamb",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "shabu shabu pork, beef, lamb",
    "weight-g": 90,
    "volume-ml": null,
    "calories": 159.6,
    "fat": 8.8,
    "saturated": 4.5,
    "polyunsaturated": 0.3,
    "monounsaturated": 4,
    "trans": null,
    "cholesterol": 64,
    "sodium": 91,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 20.1,
    "potassium": 288,
    "calcium": 15,
    "iron": 1.6,
    "phosphorus": 288,
    "info": "e.g. pork, beef, lamb"
  },
  {
    "id": 647,
    "disabled": 1,
    "type": "meal",
    "name": "Shanghai green, stir-fried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": "Bok Choy",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "shanghai green, stir-fried bok choy",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 91.7,
    "fat": 6.5,
    "saturated": 0.7,
    "polyunsaturated": 2.8,
    "monounsaturated": 0.8,
    "trans": null,
    "cholesterol": null,
    "sodium": 350,
    "carbs": 5.8,
    "fibre": 1.9,
    "sugar": 3.6,
    "protein": 2.5,
    "potassium": 300,
    "calcium": 110,
    "iron": 0.4,
    "phosphorus": 7,
    "info": "Bok Choy"
  },
  {
    "id": 914,
    "disabled": null,
    "type": "meal",
    "name": "Shark fin dumpling, steamed",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "shark fin dumpling, steamed",
    "weight-g": 27,
    "volume-ml": null,
    "calories": 128.2,
    "fat": 2.2,
    "saturated": 0.9,
    "polyunsaturated": 0.3,
    "monounsaturated": 0.9,
    "trans": null,
    "cholesterol": 9,
    "sodium": 423,
    "carbs": 24,
    "fibre": 0.3,
    "sugar": 0.5,
    "protein": 3.1,
    "potassium": 28,
    "calcium": 7,
    "iron": 0,
    "phosphorus": 19,
    "info": null
  },
  {
    "id": 1001,
    "disabled": null,
    "type": "snack",
    "name": "Shaved ice dessert with toppings",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Snow ice",
    "info-brands": null,
    "info-examples": "mango, lychee, mocha, almond",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "shaved ice dessert with toppings snow mango, lychee, mocha, almond",
    "weight-g": 370,
    "volume-ml": null,
    "calories": 131.3,
    "fat": 1.7,
    "saturated": 0.6,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.4,
    "trans": null,
    "cholesterol": 2,
    "sodium": 29,
    "carbs": 23.7,
    "fibre": 2.3,
    "sugar": 21.4,
    "protein": 5.3,
    "potassium": 207,
    "calcium": 98,
    "iron": 0.4,
    "phosphorus": 74,
    "info": "Snow ice, e.g. mango, lychee, mocha, almond"
  },
  {
    "id": 517,
    "disabled": null,
    "type": "meal",
    "name": "Shepherd's pie, with potato",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "shepherd's pie, with potato",
    "weight-g": 250,
    "volume-ml": null,
    "calories": 290,
    "fat": 13,
    "saturated": 7.6,
    "polyunsaturated": 0.8,
    "monounsaturated": 4.3,
    "trans": 0.4,
    "cholesterol": 25,
    "sodium": 1155,
    "carbs": 27.5,
    "fibre": 4.5,
    "sugar": 5.8,
    "protein": 16,
    "potassium": 1108,
    "calcium": 98,
    "iron": 3.3,
    "phosphorus": 195,
    "info": null
  },
  {
    "id": 922,
    "disabled": null,
    "type": "meal",
    "name": "Shiitake mushroom with fish paste",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "shiitake mushroom with fish paste",
    "weight-g": 25,
    "volume-ml": null,
    "calories": 34,
    "fat": 2.8,
    "saturated": 0.9,
    "polyunsaturated": 1.2,
    "monounsaturated": 0.8,
    "trans": null,
    "cholesterol": 1,
    "sodium": 70,
    "carbs": 1,
    "fibre": 0.3,
    "sugar": 0,
    "protein": 1.2,
    "potassium": 59,
    "calcium": 4,
    "iron": 0.2,
    "phosphorus": 9,
    "info": null
  },
  {
    "id": 308,
    "disabled": null,
    "type": "meal",
    "name": "Shio ramen with char siew",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "shio ramen with char siew",
    "weight-g": 676,
    "volume-ml": null,
    "calories": 622,
    "fat": 25.5,
    "saturated": 9.5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 3137,
    "carbs": 59.5,
    "fibre": 2,
    "sugar": null,
    "protein": 39.2,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 662,
    "disabled": null,
    "type": "meal",
    "name": "Shitake mushroom",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "shitake mushroom",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 65.4,
    "fat": 0.2,
    "saturated": 0.1,
    "polyunsaturated": 0,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 4,
    "carbs": 14.3,
    "fibre": 2.3,
    "sugar": 0,
    "protein": 1.6,
    "potassium": 117,
    "calcium": 3,
    "iron": 0.4,
    "phosphorus": 29,
    "info": null
  },
  {
    "id": 855,
    "disabled": null,
    "type": "meal",
    "name": "Shoyu ramen with char siew",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "shoyu ramen with [char|cha] [siew|shao|siu]",
    "weight-g": 659,
    "volume-ml": null,
    "calories": 633,
    "fat": 27.9,
    "saturated": 10.2,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 3124,
    "carbs": 61.3,
    "fibre": 1.3,
    "sugar": null,
    "protein": 33.6,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 923,
    "disabled": null,
    "type": "meal",
    "name": "Shrimp / pork wanton, fried",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "shrimp / pork wanton, fried",
    "weight-g": 13,
    "volume-ml": null,
    "calories": 55.1,
    "fat": 3.9,
    "saturated": 1.8,
    "polyunsaturated": 0.6,
    "monounsaturated": 1.3,
    "trans": null,
    "cholesterol": 4,
    "sodium": 96,
    "carbs": 3.5,
    "fibre": 0.2,
    "sugar": 0,
    "protein": 1.5,
    "potassium": null,
    "calcium": 5,
    "iron": 0.1,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 990,
    "disabled": null,
    "type": "meal",
    "name": "Shrimp and pork wanton, boiled",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "shrimp and pork [wanton|wonton], boiled",
    "weight-g": 14,
    "volume-ml": null,
    "calories": 19,
    "fat": 1,
    "saturated": 0.4,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.4,
    "trans": null,
    "cholesterol": 3,
    "sodium": 48,
    "carbs": 1.7,
    "fibre": 0.1,
    "sugar": 0,
    "protein": 0.8,
    "potassium": null,
    "calcium": 2,
    "iron": 0.1,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 909,
    "disabled": null,
    "type": "meal",
    "name": "Siew mai, steamed (dim sum)",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Shumai",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[siew|shao|shu] mai, steamed (dim sum) shumai",
    "weight-g": 16,
    "volume-ml": null,
    "calories": 28.5,
    "fat": 1.3,
    "saturated": 0.4,
    "polyunsaturated": 0.3,
    "monounsaturated": 0.5,
    "trans": null,
    "cholesterol": 4,
    "sodium": 99,
    "carbs": 2.8,
    "fibre": 0.3,
    "sugar": 1.2,
    "protein": 1.4,
    "potassium": 12,
    "calcium": 7,
    "iron": 0.1,
    "phosphorus": 12,
    "info": "Shumai"
  },
  {
    "id": 788,
    "disabled": null,
    "type": "meal",
    "name": "Sin chow beehoon",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sin [chow|chew] beehoon",
    "weight-g": 463,
    "volume-ml": null,
    "calories": 701.2,
    "fat": 25.3,
    "saturated": 8.2,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 128.9,
    "sodium": 1318.8,
    "carbs": 93.6,
    "fibre": 6.7,
    "sugar": null,
    "protein": 24.8,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 640,
    "disabled": 1,
    "type": "meal",
    "name": "Sio peck chye (stir-fied)",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sio peck chye (stir-fied)",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 63.9,
    "fat": 3.9,
    "saturated": 0.6,
    "polyunsaturated": 2.4,
    "monounsaturated": 0.7,
    "trans": null,
    "cholesterol": null,
    "sodium": 304.3,
    "carbs": 5,
    "fibre": 1.7,
    "sugar": 3.1,
    "protein": 2.2,
    "potassium": 260.9,
    "calcium": 95.7,
    "iron": 0.3,
    "phosphorus": 6.1,
    "info": null
  },
  {
    "id": 428,
    "disabled": null,
    "type": "meal",
    "name": "Sliced fish soup with milk",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sliced fish soup with milk",
    "weight-g": 550,
    "volume-ml": null,
    "calories": 203,
    "fat": 8,
    "saturated": 3.9,
    "polyunsaturated": 0.7,
    "monounsaturated": 3.2,
    "trans": null,
    "cholesterol": 30,
    "sodium": 1442,
    "carbs": 9,
    "fibre": 3.4,
    "sugar": 3.2,
    "protein": 24,
    "potassium": 485,
    "calcium": 125,
    "iron": 0.8,
    "phosphorus": 272,
    "info": null
  },
  {
    "id": 1055,
    "disabled": null,
    "type": "meal",
    "name": "Sliced fish soup, without milk",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sliced fish soup, without milk",
    "weight-g": 500,
    "volume-ml": null,
    "calories": 152.4,
    "fat": 4.8,
    "saturated": 2.1,
    "polyunsaturated": 0.6,
    "monounsaturated": 1,
    "trans": null,
    "cholesterol": 21,
    "sodium": 1188,
    "carbs": 6,
    "fibre": 3.1,
    "sugar": 1.6,
    "protein": 21.3,
    "potassium": 1957,
    "calcium": 94,
    "iron": 0.2,
    "phosphorus": 237,
    "info": null
  },
  {
    "id": 318,
    "disabled": null,
    "type": "meal",
    "name": "Sliced fish soup with noodles / rice and milk",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sliced fish soup with noodles / rice and milk",
    "weight-g": 780,
    "volume-ml": null,
    "calories": 400,
    "fat": 12,
    "saturated": 4.5,
    "polyunsaturated": 2,
    "monounsaturated": 2.9,
    "trans": null,
    "cholesterol": 26,
    "sodium": 1544,
    "carbs": 51.2,
    "fibre": 3.2,
    "sugar": 9.4,
    "protein": 25,
    "potassium": 306,
    "calcium": 153,
    "iron": 2.8,
    "phosphorus": 246,
    "info": null
  },
  {
    "id": 1054,
    "disabled": null,
    "type": "meal",
    "name": "Sliced fish soup with noodles / rice, without milk",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sliced fish soup with noodles / rice, without milk",
    "weight-g": 686,
    "volume-ml": null,
    "calories": 350.7,
    "fat": 7.5,
    "saturated": 3.4,
    "polyunsaturated": 0.7,
    "monounsaturated": 3,
    "trans": null,
    "cholesterol": 27,
    "sodium": 1422,
    "carbs": 48.2,
    "fibre": 4.6,
    "sugar": 0,
    "protein": 22.6,
    "potassium": 384,
    "calcium": 47,
    "iron": 0.8,
    "phosphorus": 192,
    "info": null
  },
  {
    "id": 501,
    "disabled": null,
    "type": "meal",
    "name": "Smoked sausage",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "chorizo",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "smoked sausage chorizo",
    "weight-g": 68,
    "volume-ml": null,
    "calories": 203.3,
    "fat": 19.2,
    "saturated": 6.3,
    "polyunsaturated": 2.5,
    "monounsaturated": 7.6,
    "trans": null,
    "cholesterol": 41.5,
    "sodium": 562,
    "carbs": 0.6,
    "fibre": 0,
    "sugar": 0.6,
    "protein": 7,
    "potassium": 328,
    "calcium": 7.5,
    "iron": 0.4,
    "phosphorus": 107,
    "info": "e.g. chorizo"
  },
  {
    "id": 17,
    "disabled": null,
    "type": "drink",
    "name": "Smoothie",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": 1,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with fruit and yogurt",
    "search-text": "smoothie",
    "weight-g": null,
    "volume-ml": 355,
    "calories": 225.1,
    "fat": 0.5,
    "saturated": 0,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.2,
    "trans": null,
    "cholesterol": 3.5,
    "sodium": 48.6,
    "carbs": 52.2,
    "fibre": 3.1,
    "sugar": 44,
    "protein": 3,
    "potassium": 455,
    "calcium": 69.4,
    "iron": 0.5,
    "phosphorus": 38.2,
    "info": "with fruit and yogurt"
  },
  {
    "id": 141,
    "disabled": null,
    "type": "meal",
    "name": "Soba, noodles only",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Buckwheat noodles",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soba, noodles only buckwheat",
    "weight-g": 275,
    "volume-ml": null,
    "calories": 171,
    "fat": 1.9,
    "saturated": 0.8,
    "polyunsaturated": 0.3,
    "monounsaturated": 0.8,
    "trans": null,
    "cholesterol": 0,
    "sodium": 558,
    "carbs": 28.1,
    "fibre": 2.8,
    "sugar": 3,
    "protein": 9.9,
    "potassium": 83,
    "calcium": 44,
    "iron": 2.5,
    "phosphorus": 77,
    "info": "Buckwheat noodles"
  },
  {
    "id": 868,
    "disabled": null,
    "type": "meal",
    "name": "Soba with soup and egg / mushrooms / vegetables",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soba with soup and egg / mushrooms vegetables",
    "weight-g": 630,
    "volume-ml": null,
    "calories": 332,
    "fat": 6,
    "saturated": 1,
    "polyunsaturated": 1,
    "monounsaturated": 2,
    "trans": null,
    "cholesterol": 207,
    "sodium": 2612,
    "carbs": 50,
    "fibre": 0,
    "sugar": 16,
    "protein": 15,
    "potassium": 162,
    "calcium": 109,
    "iron": 2,
    "phosphorus": 176,
    "info": null
  },
  {
    "id": 869,
    "disabled": null,
    "type": "meal",
    "name": "Soba, with soup and meat",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soba, with soup and meat",
    "weight-g": 630,
    "volume-ml": null,
    "calories": 350,
    "fat": 4,
    "saturated": 1,
    "polyunsaturated": 0,
    "monounsaturated": 3,
    "trans": null,
    "cholesterol": 37,
    "sodium": 2641,
    "carbs": 50,
    "fibre": 0,
    "sugar": 16,
    "protein": 24,
    "potassium": 280,
    "calcium": 92,
    "iron": 3,
    "phosphorus": 211,
    "info": null
  },
  {
    "id": 870,
    "disabled": null,
    "type": "meal",
    "name": "Soba, with soup with seafood",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soba, with soup seafood",
    "weight-g": 630,
    "volume-ml": null,
    "calories": 277,
    "fat": 1,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": null,
    "sodium": 2746,
    "carbs": 52,
    "fibre": 0,
    "sugar": 16,
    "protein": 11,
    "potassium": 149,
    "calcium": 98,
    "iron": 1,
    "phosphorus": 106,
    "info": null
  },
  {
    "id": 163,
    "disabled": null,
    "type": "drink",
    "name": "Soft drink, diet",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "can",
    "info-translation": null,
    "info-brands": "Coke zero, Coke light, Pepsi black, 7-Up Diet",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soft drink, diet coke zero, light, pepsi black, 7-up diet",
    "weight-g": null,
    "volume-ml": 330,
    "calories": 2,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 36,
    "carbs": 0.3,
    "fibre": 0,
    "sugar": 0,
    "protein": 0.2,
    "potassium": 10,
    "calcium": null,
    "iron": null,
    "phosphorus": 24,
    "info": "e.g. Coke zero, Coke light, Pepsi black, 7-Up Diet"
  },
  {
    "id": 8,
    "disabled": null,
    "type": "drink",
    "name": "Soft drink, regular",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "can",
    "info-translation": null,
    "info-brands": "Coke, Pepsi, 7-Up",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soft drink, regular coke, pepsi, 7-up",
    "weight-g": null,
    "volume-ml": 355,
    "calories": 161.5,
    "fat": 0.9,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 11.1,
    "carbs": 38.3,
    "fibre": 0,
    "sugar": 36.8,
    "protein": 0,
    "potassium": 18.5,
    "calcium": 3.7,
    "iron": 0.1,
    "phosphorus": 33.3,
    "info": "e.g. Coke, Pepsi, 7-Up"
  },
  {
    "id": 816,
    "disabled": null,
    "type": "snack",
    "name": "Soft-serve ice cream on cone",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cone",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soft-serve ice cream on cone",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 196,
    "fat": 5.8,
    "saturated": 3.1,
    "polyunsaturated": 0.4,
    "monounsaturated": 1.5,
    "trans": 0.3,
    "cholesterol": 18,
    "sodium": 97,
    "carbs": 31.6,
    "fibre": 0.1,
    "sugar": 23.4,
    "protein": 5.1,
    "potassium": 232,
    "calcium": 155,
    "iron": 0.4,
    "phosphorus": 133,
    "info": null
  },
  {
    "id": 817,
    "disabled": null,
    "type": "snack",
    "name": "Soft-serve ice cream with sauce / topping",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "chocolate / caramel / strawberry sundae",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soft-serve ice cream with sauce / topping chocolate caramel strawberry sundae",
    "weight-g": 165,
    "volume-ml": null,
    "calories": 333,
    "fat": 10,
    "saturated": 6.1,
    "polyunsaturated": 0.4,
    "monounsaturated": 2.6,
    "trans": 0.4,
    "cholesterol": 22,
    "sodium": 180,
    "carbs": 54.9,
    "fibre": 0.4,
    "sugar": 49.8,
    "protein": 6.6,
    "potassium": 205,
    "calcium": 161,
    "iron": 1,
    "phosphorus": 238,
    "info": "e.g. chocolate / caramel / strawberry sundae"
  },
  {
    "id": 911,
    "disabled": null,
    "type": "meal",
    "name": "Soon kueh, steamed",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soon [kueh|kuih|kue], steamed",
    "weight-g": 72,
    "volume-ml": null,
    "calories": 71.2,
    "fat": 1.2,
    "saturated": 0.6,
    "polyunsaturated": 0.1,
    "monounsaturated": 4.1,
    "trans": null,
    "cholesterol": 1,
    "sodium": 430,
    "carbs": 14.2,
    "fibre": 1.3,
    "sugar": 1,
    "protein": 0.9,
    "potassium": 158,
    "calcium": 15,
    "iron": 1.5,
    "phosphorus": 93,
    "info": null
  },
  {
    "id": 875,
    "disabled": null,
    "type": "meal",
    "name": "Soondubu Jjigae, meat",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Korean spicy tofu soup",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[soondubu|sundubu] jjigae, meat korean spicy tofu soup",
    "weight-g": 629,
    "volume-ml": null,
    "calories": 363.5,
    "fat": 20.3,
    "saturated": 7,
    "polyunsaturated": 4.9,
    "monounsaturated": 7.1,
    "trans": null,
    "cholesterol": 78,
    "sodium": 2407,
    "carbs": 10.7,
    "fibre": 8.8,
    "sugar": 9.7,
    "protein": 34.5,
    "potassium": 863,
    "calcium": 183,
    "iron": 5.2,
    "phosphorus": 254,
    "info": "Korean spicy tofu soup"
  },
  {
    "id": 733,
    "disabled": null,
    "type": "snack",
    "name": "Sotong / squid, fried",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 0.7,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sotong / squid, fried",
    "weight-g": "100g",
    "volume-ml": null,
    "calories": 200.2,
    "fat": 9,
    "saturated": 2,
    "polyunsaturated": 4.7,
    "monounsaturated": 4.4,
    "trans": null,
    "cholesterol": 242,
    "sodium": 382,
    "carbs": 12.9,
    "fibre": 0.6,
    "sugar": 0.9,
    "protein": 16.9,
    "potassium": 259,
    "calcium": 60,
    "iron": 1.5,
    "phosphorus": 238,
    "info": null
  },
  {
    "id": 78,
    "disabled": null,
    "type": "snack",
    "name": "Souffle pancake with whipped cream and syrup",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "souffle pancake with whipped cream and syrup",
    "weight-g": 151,
    "volume-ml": null,
    "calories": 390.6,
    "fat": 22.2,
    "saturated": 11.5,
    "polyunsaturated": 0.8,
    "monounsaturated": 3.6,
    "trans": null,
    "cholesterol": 180,
    "sodium": 149,
    "carbs": 42.4,
    "fibre": 0.4,
    "sugar": 33.4,
    "protein": 5.3,
    "potassium": 93,
    "calcium": 74,
    "iron": 0.8,
    "phosphorus": 54,
    "info": null
  },
  {
    "id": 229,
    "disabled": null,
    "type": "snack",
    "name": "Souffle pancake, plain",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "souffle pancake, plain",
    "weight-g": 83,
    "volume-ml": null,
    "calories": 184,
    "fat": 8.8,
    "saturated": 3.5,
    "polyunsaturated": 0.8,
    "monounsaturated": 3.6,
    "trans": null,
    "cholesterol": 140,
    "sodium": 134,
    "carbs": 20.9,
    "fibre": 0.4,
    "sugar": 11.9,
    "protein": 5.3,
    "potassium": 58,
    "calcium": 52,
    "iron": 0.6,
    "phosphorus": 54,
    "info": null
  },
  {
    "id": 429,
    "disabled": null,
    "type": "meal",
    "name": "Soup / broth, clear, with ingredients",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "chicken, carrot, onion, corn",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soup / broth, clear, with ingredients chicken, carrot, onion, corn",
    "weight-g": 285,
    "volume-ml": null,
    "calories": 143.4,
    "fat": 6.3,
    "saturated": 1.2,
    "polyunsaturated": 1.6,
    "monounsaturated": 2.4,
    "trans": null,
    "cholesterol": 59.8,
    "sodium": 1150,
    "carbs": 6.2,
    "fibre": 1.7,
    "sugar": 2.6,
    "protein": 15.5,
    "potassium": 351,
    "calcium": 54.2,
    "iron": 0.9,
    "phosphorus": 154,
    "info": "e.g. chicken, carrot, onion, corn"
  },
  {
    "id": 430,
    "disabled": null,
    "type": "meal",
    "name": "Soup / broth, clear, without ingredients",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soup / broth, clear, without ingredients",
    "weight-g": 249,
    "volume-ml": null,
    "calories": 50,
    "fat": 0.5,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 5,
    "sodium": 924,
    "carbs": 1.1,
    "fibre": 0,
    "sugar": 1.1,
    "protein": 1.6,
    "potassium": 44.8,
    "calcium": 10,
    "iron": 0.2,
    "phosphorus": 10,
    "info": null
  },
  {
    "id": 142,
    "disabled": null,
    "type": "meal",
    "name": "Soup with cream",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "broccoli, mushroom, pumpkin / squash, corn",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soup with cream broccoli, mushroom, pumpkin / squash, corn",
    "weight-g": 246,
    "volume-ml": null,
    "calories": 149,
    "fat": 5,
    "saturated": 2,
    "polyunsaturated": 1,
    "monounsaturated": 1,
    "trans": null,
    "cholesterol": 10,
    "sodium": 620,
    "carbs": 20,
    "fibre": 1,
    "sugar": 2,
    "protein": 6,
    "potassium": 261,
    "calcium": 39,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. broccoli, mushroom, pumpkin / squash, corn"
  },
  {
    "id": 752,
    "disabled": null,
    "type": "meal",
    "name": "Soup with bittergourd and sliced fish",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soup with bittergourd and sliced fish",
    "weight-g": 450,
    "volume-ml": null,
    "calories": 362.5,
    "fat": 7.7,
    "saturated": 3.5,
    "polyunsaturated": 0.8,
    "monounsaturated": 3,
    "trans": null,
    "cholesterol": 27,
    "sodium": 1416,
    "carbs": 50.3,
    "fibre": 4.4,
    "sugar": 0,
    "protein": 23,
    "potassium": 1944,
    "calcium": 53,
    "iron": 0.9,
    "phosphorus": 210,
    "info": null
  },
  {
    "id": 756,
    "disabled": null,
    "type": "meal",
    "name": "Soup with black beans and chicken / pork",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soup with black beans and chicken / pork",
    "weight-g": 450,
    "volume-ml": null,
    "calories": 147.1,
    "fat": 1.5,
    "saturated": 0.5,
    "polyunsaturated": 0.4,
    "monounsaturated": 0.4,
    "trans": null,
    "cholesterol": 27,
    "sodium": 295,
    "carbs": 16.6,
    "fibre": 6.4,
    "sugar": 1.6,
    "protein": 16.8,
    "potassium": 353,
    "calcium": 55,
    "iron": 5.2,
    "phosphorus": 152,
    "info": null
  },
  {
    "id": 754,
    "disabled": null,
    "type": "meal",
    "name": "Soup, Buddha jumps over the wall",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Fo Tiao Qiang",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soup, buddha jumps over the wall fo tiao qiang",
    "weight-g": 450,
    "volume-ml": null,
    "calories": 236.6,
    "fat": 3.8,
    "saturated": 1.1,
    "polyunsaturated": 0.6,
    "monounsaturated": 1,
    "trans": null,
    "cholesterol": 158,
    "sodium": 854,
    "carbs": 10.1,
    "fibre": 2.1,
    "sugar": 1.3,
    "protein": 40.5,
    "potassium": 726,
    "calcium": 155,
    "iron": 4.2,
    "phosphorus": 262,
    "info": "Fo Tiao Qiang"
  },
  {
    "id": 753,
    "disabled": null,
    "type": "meal",
    "name": "Soup with carrot, potato, onion, pork / chicken",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "ABC Soup",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soup with carrot, potato, onion, pork / chicken abc",
    "weight-g": 450,
    "volume-ml": null,
    "calories": 282,
    "fat": 9.2,
    "saturated": 3.8,
    "polyunsaturated": 1.5,
    "monounsaturated": 3.1,
    "trans": null,
    "cholesterol": 100,
    "sodium": 1563,
    "carbs": 19.2,
    "fibre": 6.1,
    "sugar": 1,
    "protein": 30.6,
    "potassium": null,
    "calcium": 207,
    "iron": 2.3,
    "phosphorus": null,
    "info": "e.g. ABC Soup"
  },
  {
    "id": 745,
    "disabled": null,
    "type": "meal",
    "name": "Soup with chicken and herbs",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soup with chicken and herbs",
    "weight-g": 450,
    "volume-ml": null,
    "calories": 183.7,
    "fat": 6.9,
    "saturated": 2.3,
    "polyunsaturated": 1,
    "monounsaturated": 3,
    "trans": null,
    "cholesterol": 150,
    "sodium": 1153,
    "carbs": 0.5,
    "fibre": 1.1,
    "sugar": 0,
    "protein": 29.9,
    "potassium": 390,
    "calcium": 32,
    "iron": 1.1,
    "phosphorus": 198,
    "info": null
  },
  {
    "id": 744,
    "disabled": null,
    "type": "meal",
    "name": "Soup with duck and salted vegetables",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soup with duck and salted vegetables",
    "weight-g": 251,
    "volume-ml": null,
    "calories": 151.5,
    "fat": 9.9,
    "saturated": 2,
    "polyunsaturated": 0.8,
    "monounsaturated": 2.5,
    "trans": null,
    "cholesterol": 108,
    "sodium": 1170,
    "carbs": 0.5,
    "fibre": 0.3,
    "sugar": 0,
    "protein": 15.1,
    "potassium": 112,
    "calcium": 78,
    "iron": 1.5,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 785,
    "disabled": null,
    "type": "meal",
    "name": "Soup with fish maw with egg",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soup with fish maw egg",
    "weight-g": 308,
    "volume-ml": null,
    "calories": 106.9,
    "fat": 3.3,
    "saturated": 0.5,
    "polyunsaturated": 0.8,
    "monounsaturated": 1.3,
    "trans": null,
    "cholesterol": 23,
    "sodium": 340,
    "carbs": 0.7,
    "fibre": 0.2,
    "sugar": 0.6,
    "protein": 18.6,
    "potassium": 184,
    "calcium": 44,
    "iron": 0.7,
    "phosphorus": 68,
    "info": null
  },
  {
    "id": 755,
    "disabled": null,
    "type": "meal",
    "name": "Soup with herbal black chicken",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soup with herbal black chicken",
    "weight-g": 450,
    "volume-ml": null,
    "calories": 219.6,
    "fat": 10.8,
    "saturated": 3.8,
    "polyunsaturated": 2.1,
    "monounsaturated": 3.8,
    "trans": null,
    "cholesterol": 1.5,
    "sodium": 1046,
    "carbs": 7.1,
    "fibre": 0.4,
    "sugar": 3,
    "protein": 23.5,
    "potassium": null,
    "calcium": 34,
    "iron": 1.7,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 746,
    "disabled": null,
    "type": "meal",
    "name": "Soup with lotus root and pork ribs / chicken",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soup with lotus root and pork ribs / chicken",
    "weight-g": 497,
    "volume-ml": null,
    "calories": 114.3,
    "fat": 2.3,
    "saturated": 3.8,
    "polyunsaturated": 1.5,
    "monounsaturated": 3.1,
    "trans": null,
    "cholesterol": 80,
    "sodium": 875,
    "carbs": 9,
    "fibre": 4.5,
    "sugar": 0,
    "protein": 14.4,
    "potassium": null,
    "calcium": 89,
    "iron": 1.5,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 748,
    "disabled": null,
    "type": "meal",
    "name": "Soup with peanuts and pork ribs / chicken",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soup with peanuts and pork ribs / chicken",
    "weight-g": 450,
    "volume-ml": null,
    "calories": 343.1,
    "fat": 20.7,
    "saturated": 5.4,
    "polyunsaturated": 6.8,
    "monounsaturated": 7.7,
    "trans": null,
    "cholesterol": 77,
    "sodium": 981,
    "carbs": 4.5,
    "fibre": 7.7,
    "sugar": 0,
    "protein": 34.7,
    "potassium": null,
    "calcium": 68,
    "iron": 2.3,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 747,
    "disabled": null,
    "type": "meal",
    "name": "Soup with pig stomach and pepper",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soup with pig stomach and pepper",
    "weight-g": 313,
    "volume-ml": null,
    "calories": 139.6,
    "fat": 8,
    "saturated": 1.2,
    "polyunsaturated": 0.3,
    "monounsaturated": 0.9,
    "trans": null,
    "cholesterol": 38,
    "sodium": 632,
    "carbs": 0.3,
    "fibre": 0.6,
    "sugar": 0,
    "protein": 16.6,
    "potassium": 38,
    "calcium": 50,
    "iron": 1.6,
    "phosphorus": 135,
    "info": null
  },
  {
    "id": 751,
    "disabled": null,
    "type": "meal",
    "name": "Soup with pork and crab meat ball",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Bakwan Kepitang",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soup with pork and crab meat ball bakwan kepitang",
    "weight-g": 450,
    "volume-ml": null,
    "calories": 134.5,
    "fat": 8.1,
    "saturated": 3,
    "polyunsaturated": 1.3,
    "monounsaturated": 3.8,
    "trans": null,
    "cholesterol": 35,
    "sodium": 1511,
    "carbs": 0.5,
    "fibre": 1,
    "sugar": 0.5,
    "protein": 14.9,
    "potassium": 199,
    "calcium": 80,
    "iron": 0,
    "phosphorus": 124,
    "info": "Bakwan Kepitang"
  },
  {
    "id": 750,
    "disabled": null,
    "type": "meal",
    "name": "Soup with salted vegetables and tofu",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soup with salted vegetables and tofu",
    "weight-g": 450,
    "volume-ml": null,
    "calories": 95,
    "fat": 5,
    "saturated": 0.8,
    "polyunsaturated": 2.7,
    "monounsaturated": 0.9,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1628,
    "carbs": 1.7,
    "fibre": 1.7,
    "sugar": 1.3,
    "protein": 10.8,
    "potassium": 299,
    "calcium": 152,
    "iron": 1.3,
    "phosphorus": 117,
    "info": null
  },
  {
    "id": 757,
    "disabled": null,
    "type": "meal",
    "name": "Soup, Szechuan hot and sour",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soup, szechuan hot and sour",
    "weight-g": 450,
    "volume-ml": null,
    "calories": 193.7,
    "fat": 11.3,
    "saturated": 2.9,
    "polyunsaturated": 1.8,
    "monounsaturated": 4,
    "trans": null,
    "cholesterol": 79,
    "sodium": 1314,
    "carbs": 9,
    "fibre": 2.9,
    "sugar": 2.5,
    "protein": 14,
    "potassium": 119,
    "calcium": 58,
    "iron": 0,
    "phosphorus": 108,
    "info": null
  },
  {
    "id": 784,
    "disabled": null,
    "type": "meal",
    "name": "Soup, tom yum seafood",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "fish, prawn",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soup, tom yum seafood fish, prawn",
    "weight-g": 450,
    "volume-ml": null,
    "calories": 193,
    "fat": 12.6,
    "saturated": 5.9,
    "polyunsaturated": 1.1,
    "monounsaturated": 4.9,
    "trans": null,
    "cholesterol": 162,
    "sodium": 1778,
    "carbs": 5,
    "fibre": 1.8,
    "sugar": null,
    "protein": 14.9,
    "potassium": 333,
    "calcium": 167,
    "iron": 5,
    "phosphorus": 162,
    "info": "e.g. fish, prawn"
  },
  {
    "id": 830,
    "disabled": null,
    "type": "meal",
    "name": "Soup, tomato bisque",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soup, tomato bisque",
    "weight-g": 350,
    "volume-ml": null,
    "calories": 277,
    "fat": 9.2,
    "saturated": 4.4,
    "polyunsaturated": 1.7,
    "monounsaturated": 2.6,
    "trans": null,
    "cholesterol": 32,
    "sodium": 1554,
    "carbs": 41,
    "fibre": 0.7,
    "sugar": null,
    "protein": 8.8,
    "potassium": 847,
    "calcium": 260,
    "iron": 1.2,
    "phosphorus": 242,
    "info": null
  },
  {
    "id": 749,
    "disabled": null,
    "type": "meal",
    "name": "Soup with watercress and pork ribs / chicken",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soup with watercress and pork ribs / chicken",
    "weight-g": 450,
    "volume-ml": null,
    "calories": 116,
    "fat": 7.2,
    "saturated": 3.7,
    "polyunsaturated": 0.9,
    "monounsaturated": 2.3,
    "trans": null,
    "cholesterol": 41,
    "sodium": 1366,
    "carbs": 0,
    "fibre": 1.4,
    "sugar": 0,
    "protein": 15.1,
    "potassium": null,
    "calcium": 49.2,
    "iron": 2.3,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 871,
    "disabled": null,
    "type": "meal",
    "name": "Sour cream",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sour cream",
    "weight-g": 12,
    "volume-ml": null,
    "calories": 24.3,
    "fat": 2.3,
    "saturated": 1.2,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.6,
    "trans": 0.1,
    "cholesterol": 7.1,
    "sodium": 3.7,
    "carbs": 0.6,
    "fibre": 0,
    "sugar": 0.4,
    "protein": 0.3,
    "potassium": 15,
    "calcium": 12.1,
    "iron": 0,
    "phosphorus": 9.1,
    "info": null
  },
  {
    "id": 402,
    "disabled": null,
    "type": "snack",
    "name": "Soursop",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "pulp",
    "search-text": "soursop",
    "weight-g": 225,
    "volume-ml": null,
    "calories": 166.7,
    "fat": 0.7,
    "saturated": 0.1,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 31.5,
    "carbs": 37.9,
    "fibre": 7.4,
    "sugar": 30.5,
    "protein": 2.3,
    "potassium": 626,
    "calcium": 31.5,
    "iron": 1.4,
    "phosphorus": 60.8,
    "info": "pulp"
  },
  {
    "id": 190,
    "disabled": null,
    "type": "snack",
    "name": "Soy beancurd, sweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Tau Huay",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with sugar",
    "search-text": "soy beancurd, sweetened [tau|dou] [huay|hua|huey]",
    "weight-g": 240,
    "volume-ml": null,
    "calories": 141,
    "fat": 1.4,
    "saturated": 0.3,
    "polyunsaturated": 0.8,
    "monounsaturated": 0.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 14,
    "carbs": 26.6,
    "fibre": 0.7,
    "sugar": 24.7,
    "protein": 5.5,
    "potassium": 125,
    "calcium": 36,
    "iron": 0.5,
    "phosphorus": 29,
    "info": "Tau Huay – with sugar"
  },
  {
    "id": 165,
    "disabled": null,
    "type": "drink",
    "name": "Soy milk, sweetened (hawker centre)",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with sugar",
    "search-text": "[soy|soya] milk, sweetened (hawker centre)",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 139.2,
    "fat": 4,
    "saturated": 0.7,
    "polyunsaturated": 1.9,
    "monounsaturated": 0.8,
    "trans": null,
    "cholesterol": 0,
    "sodium": 35,
    "carbs": 19.3,
    "fibre": 1.5,
    "sugar": 19,
    "protein": 6.5,
    "potassium": 245,
    "calcium": 25,
    "iron": 1,
    "phosphorus": 120,
    "info": "with sugar"
  },
  {
    "id": 361,
    "disabled": null,
    "type": "drink",
    "name": "Soy milk, unsweetened (hawker centre)",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "without sugar",
    "search-text": "[soy|soya] milk, unsweetened (hawker centre)",
    "weight-g": 244,
    "volume-ml": null,
    "calories": 83,
    "fat": 5,
    "saturated": 0.7,
    "polyunsaturated": 2.3,
    "monounsaturated": 0.9,
    "trans": null,
    "cholesterol": 0,
    "sodium": 30,
    "carbs": 3,
    "fibre": 2,
    "sugar": 2.8,
    "protein": 6.8,
    "potassium": 283,
    "calcium": 25,
    "iron": 1,
    "phosphorus": 130,
    "info": "without sugar"
  },
  {
    "id": 320,
    "disabled": null,
    "type": "meal",
    "name": "Spaghetti bolognese",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with minced meat sauce",
    "search-text": "spaghetti bolognese",
    "weight-g": 554,
    "volume-ml": null,
    "calories": 671.1,
    "fat": 19.9,
    "saturated": 5.9,
    "polyunsaturated": 2.8,
    "monounsaturated": 0.3,
    "trans": 0,
    "cholesterol": 49.9,
    "sodium": 1270,
    "carbs": 90.9,
    "fibre": 8.9,
    "sugar": 10.1,
    "protein": 32.1,
    "potassium": 953,
    "calcium": 94.2,
    "iron": 5.4,
    "phosphorus": 327,
    "info": "with minced meat sauce"
  },
  {
    "id": 143,
    "disabled": null,
    "type": "meal",
    "name": "Spaghetti carbonara",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "spaghetti carbonara",
    "weight-g": 360,
    "volume-ml": null,
    "calories": 719.1,
    "fat": 36.3,
    "saturated": 18.7,
    "polyunsaturated": 5.3,
    "monounsaturated": 10.5,
    "trans": null,
    "cholesterol": 70,
    "sodium": 1367,
    "carbs": 73.3,
    "fibre": 2.7,
    "sugar": 5.2,
    "protein": 24.8,
    "potassium": 110,
    "calcium": 128,
    "iron": 0.5,
    "phosphorus": 138,
    "info": null
  },
  {
    "id": 724,
    "disabled": null,
    "type": "meal",
    "name": "Spelt / teff",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "spelt / teff",
    "weight-g": 194,
    "volume-ml": null,
    "calories": 246,
    "fat": 1.7,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 10,
    "carbs": 51.3,
    "fibre": 7.6,
    "sugar": null,
    "protein": 10.7,
    "potassium": 277,
    "calcium": 19,
    "iron": 3.2,
    "phosphorus": 291,
    "info": null
  },
  {
    "id": 676,
    "disabled": null,
    "type": "meal",
    "name": "Spinach, all types, stir-fried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "spinach, all types, stir-fried",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 29.9,
    "fat": 0.3,
    "saturated": 2.3,
    "polyunsaturated": 0.5,
    "monounsaturated": 2.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 70,
    "carbs": 3.8,
    "fibre": 2.4,
    "sugar": 0,
    "protein": 3,
    "potassium": 466,
    "calcium": 136,
    "iron": 3.6,
    "phosphorus": 56,
    "info": null
  },
  {
    "id": 719,
    "disabled": null,
    "type": "snack",
    "name": "Spirulina, powder",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "spirulina, powder",
    "weight-g": 15,
    "volume-ml": null,
    "calories": 50.1,
    "fat": 0.5,
    "saturated": 0.4,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 146,
    "carbs": 3.4,
    "fibre": 0.6,
    "sugar": 0.4,
    "protein": 8,
    "potassium": 190,
    "calcium": 16,
    "iron": 4,
    "phosphorus": 16,
    "info": null
  },
  {
    "id": 900,
    "disabled": null,
    "type": "snack",
    "name": "Sponge cake with cream",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sponge cake with cream",
    "weight-g": 42,
    "volume-ml": null,
    "calories": 157,
    "fat": 4.9,
    "saturated": 1.7,
    "polyunsaturated": 0.8,
    "monounsaturated": 2.1,
    "trans": 0.7,
    "cholesterol": 17,
    "sodium": 197,
    "carbs": 26.9,
    "fibre": 0.4,
    "sugar": 15.7,
    "protein": 1.5,
    "potassium": 30,
    "calcium": 10,
    "iron": 0.6,
    "phosphorus": 78,
    "info": null
  },
  {
    "id": 98,
    "disabled": null,
    "type": "meal",
    "name": "Sponge cake, plain",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sponge cake, plain",
    "weight-g": 38,
    "volume-ml": null,
    "calories": 110,
    "fat": 1,
    "saturated": 0.3,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.4,
    "trans": null,
    "cholesterol": 39,
    "sodium": 237,
    "carbs": 23.2,
    "fibre": 0.2,
    "sugar": 13.9,
    "protein": 2.1,
    "potassium": 38,
    "calcium": 27,
    "iron": 1,
    "phosphorus": 52,
    "info": null
  },
  {
    "id": 619,
    "disabled": null,
    "type": "drink",
    "name": "Sports drink",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "can",
    "info-translation": null,
    "info-brands": "Gatorade, 100 Plus, Pocari",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sports drink gatorade, 100 plus, pocari",
    "weight-g": null,
    "volume-ml": 330,
    "calories": 88,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 139,
    "carbs": 22,
    "fibre": 0,
    "sugar": 22,
    "protein": 0,
    "potassium": 63,
    "calcium": 13,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Gatorade, 100 Plus, Pocari"
  },
  {
    "id": 679,
    "disabled": 1,
    "type": "meal",
    "name": "Spring onion",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "spring onion",
    "weight-g": null,
    "volume-ml": null,
    "calories": 32,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 16,
    "carbs": 7,
    "fibre": 2,
    "sugar": 2,
    "protein": 1,
    "potassium": 276,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 42,
    "disabled": null,
    "type": "snack",
    "name": "Spring roll, fried",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "roll",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "spring roll, fried",
    "weight-g": 45,
    "volume-ml": null,
    "calories": 139.4,
    "fat": 10.6,
    "saturated": 5.4,
    "polyunsaturated": 1,
    "monounsaturated": 3.8,
    "trans": null,
    "cholesterol": 24,
    "sodium": 232,
    "carbs": 8.4,
    "fibre": 0.6,
    "sugar": 0.8,
    "protein": 2.6,
    "potassium": 68,
    "calcium": 34,
    "iron": 0.5,
    "phosphorus": 32,
    "info": null
  },
  {
    "id": 675,
    "disabled": 1,
    "type": "meal",
    "name": "Sprout",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sprout",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 25.7,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 10,
    "carbs": 4.2,
    "fibre": 0.8,
    "sugar": 0,
    "protein": 2,
    "potassium": 101,
    "calcium": 12,
    "iron": 0.7,
    "phosphorus": 28,
    "info": null
  },
  {
    "id": 321,
    "disabled": null,
    "type": "meal",
    "name": "Sotong / squid, grilled or steamed",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sotong / squid, grilled or steamed",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 147.6,
    "fat": 7.6,
    "saturated": 1.4,
    "polyunsaturated": 1.6,
    "monounsaturated": 3.7,
    "trans": 0.1,
    "cholesterol": 237,
    "sodium": 340,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 19.8,
    "potassium": 185,
    "calcium": 13,
    "iron": 1.5,
    "phosphorus": 306,
    "info": null
  },
  {
    "id": 403,
    "disabled": null,
    "type": "snack",
    "name": "Starfruit",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "fruit",
    "info-translation": "Carambola",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "starfruit carambola",
    "weight-g": 91,
    "volume-ml": null,
    "calories": 31,
    "fat": 0.3,
    "saturated": 0,
    "polyunsaturated": 0.2,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1.8,
    "carbs": 6.1,
    "fibre": 2.6,
    "sugar": 3.6,
    "protein": 0.9,
    "potassium": 121,
    "calcium": 2.7,
    "iron": 0.1,
    "phosphorus": 10.9,
    "info": "Carambola"
  },
  {
    "id": 255,
    "disabled": null,
    "type": "meal",
    "name": "Steamboat, full meal",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "steamboat, full meal",
    "weight-g": 400,
    "volume-ml": null,
    "calories": 483,
    "fat": 21.4,
    "saturated": 8.1,
    "polyunsaturated": 5.2,
    "monounsaturated": 7.7,
    "trans": null,
    "cholesterol": 50,
    "sodium": 700,
    "carbs": 52,
    "fibre": 4.5,
    "sugar": 3,
    "protein": 20.6,
    "potassium": 300,
    "calcium": 140,
    "iron": 4.3,
    "phosphorus": 250,
    "info": null
  },
  {
    "id": 340,
    "disabled": null,
    "type": "meal",
    "name": "Steamboat, heavy meal",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1.5,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "steamboat, heavy meal",
    "weight-g": 683,
    "volume-ml": null,
    "calories": 705,
    "fat": 30.5,
    "saturated": 11.7,
    "polyunsaturated": 7.3,
    "monounsaturated": 9.3,
    "trans": null,
    "cholesterol": 100,
    "sodium": 2205,
    "carbs": 68.7,
    "fibre": 13,
    "sugar": 4,
    "protein": 37.6,
    "potassium": 400,
    "calcium": 375,
    "iron": 11.5,
    "phosphorus": 300,
    "info": null
  },
  {
    "id": 439,
    "disabled": null,
    "type": "meal",
    "name": "Steamboat, light meal",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "steamboat, light meal",
    "weight-g": 351,
    "volume-ml": null,
    "calories": 405.1,
    "fat": 15.5,
    "saturated": 5.2,
    "polyunsaturated": 3.8,
    "monounsaturated": 4.9,
    "trans": null,
    "cholesterol": 12,
    "sodium": 531,
    "carbs": 47.8,
    "fibre": 3,
    "sugar": 2.6,
    "protein": 18.6,
    "potassium": 269,
    "calcium": 100,
    "iron": 4,
    "phosphorus": 150,
    "info": null
  },
  {
    "id": 838,
    "disabled": null,
    "type": "meal",
    "name": "Steamed egg with tofu",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "steamed egg with tofu",
    "weight-g": 132,
    "volume-ml": null,
    "calories": 128,
    "fat": 9.9,
    "saturated": 2.6,
    "polyunsaturated": 2.6,
    "monounsaturated": 4.2,
    "trans": null,
    "cholesterol": 187,
    "sodium": 520,
    "carbs": 0.7,
    "fibre": 0.5,
    "sugar": 0.3,
    "protein": 9.1,
    "potassium": 125,
    "calcium": 36,
    "iron": 1.6,
    "phosphorus": 124,
    "info": null
  },
  {
    "id": 148,
    "disabled": null,
    "type": "meal",
    "name": "Steamed glutinous rice with meat",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Lor Mai Gai / Fan Choy",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "steamed glutinous rice with meat [lor|lo] mai [gai|kai] / fan [choy|choi|chye]",
    "weight-g": 195,
    "volume-ml": null,
    "calories": 422,
    "fat": 9.6,
    "saturated": 3.5,
    "polyunsaturated": 1.8,
    "monounsaturated": 3.7,
    "trans": null,
    "cholesterol": 43,
    "sodium": 778,
    "carbs": 72.2,
    "fibre": 8.8,
    "sugar": 3.3,
    "protein": 11.7,
    "potassium": 131,
    "calcium": 39,
    "iron": 1,
    "phosphorus": 106,
    "info": "Lor Mai Gai / Fan Choy"
  },
  {
    "id": 337,
    "disabled": null,
    "type": "meal",
    "name": "Steamed pork ribs (dim sum)",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with black bean sauce",
    "search-text": "steamed pork ribs (dim sum)",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 159.3,
    "fat": 11.3,
    "saturated": 4.1,
    "polyunsaturated": 2.1,
    "monounsaturated": 4,
    "trans": null,
    "cholesterol": 77,
    "sodium": 752,
    "carbs": 2.4,
    "fibre": 0,
    "sugar": 2,
    "protein": 12,
    "potassium": null,
    "calcium": 69,
    "iron": 0.9,
    "phosphorus": null,
    "info": "with black bean sauce"
  },
  {
    "id": 338,
    "disabled": null,
    "type": "meal",
    "name": "Steamed prawn dumpling (dim sum)",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Har Gow",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "steamed prawn dumpling (dim sum) [har|ha] [gow|gau]",
    "weight-g": 18,
    "volume-ml": null,
    "calories": 27.1,
    "fat": 0.7,
    "saturated": 0.3,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.3,
    "trans": null,
    "cholesterol": 5,
    "sodium": 74,
    "carbs": 4.4,
    "fibre": 0.4,
    "sugar": 0.4,
    "protein": 0.8,
    "potassium": 3,
    "calcium": 7,
    "iron": 0.1,
    "phosphorus": 9,
    "info": "Har Gow"
  },
  {
    "id": 835,
    "disabled": null,
    "type": "meal",
    "name": "Steamed tofu with minced pork",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 0.3,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "steamed tofu with minced pork",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 184,
    "fat": 14,
    "saturated": 4.2,
    "polyunsaturated": 4.4,
    "monounsaturated": 4.1,
    "trans": null,
    "cholesterol": 16,
    "sodium": 240,
    "carbs": 3.8,
    "fibre": 0.4,
    "sugar": 0,
    "protein": 10.3,
    "potassium": null,
    "calcium": 127,
    "iron": 1.4,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 836,
    "disabled": null,
    "type": "meal",
    "name": "Steamed tofu with minced pork and mushroom",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "steamed tofu with minced pork and mushroom",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 112,
    "fat": 6.2,
    "saturated": 1.5,
    "polyunsaturated": 2.6,
    "monounsaturated": 1.9,
    "trans": null,
    "cholesterol": 14,
    "sodium": 377,
    "carbs": 2.3,
    "fibre": 1.4,
    "sugar": 1.6,
    "protein": 11.4,
    "potassium": 272,
    "calcium": 78,
    "iron": 1.1,
    "phosphorus": 119,
    "info": null
  },
  {
    "id": 322,
    "disabled": null,
    "type": "meal",
    "name": "Stew, beef",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "stew, beef",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 211.1,
    "fat": 11.9,
    "saturated": 4.3,
    "polyunsaturated": 0.8,
    "monounsaturated": 5.6,
    "trans": 0.5,
    "cholesterol": 72,
    "sodium": 66,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 26,
    "potassium": 416,
    "calcium": 5,
    "iron": 2.3,
    "phosphorus": 241,
    "info": null
  },
  {
    "id": 323,
    "disabled": null,
    "type": "meal",
    "name": "Stew, chicken",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "stew, chicken",
    "weight-g": 126,
    "volume-ml": null,
    "calories": 220.7,
    "fat": 15.5,
    "saturated": 4.1,
    "polyunsaturated": 4,
    "monounsaturated": 6.6,
    "trans": null,
    "cholesterol": 10,
    "sodium": 316,
    "carbs": 4.5,
    "fibre": 1.4,
    "sugar": 0.9,
    "protein": 15.8,
    "potassium": 256,
    "calcium": 57,
    "iron": 2.1,
    "phosphorus": 139,
    "info": null
  },
  {
    "id": 529,
    "disabled": null,
    "type": "meal",
    "name": "Stir fried beehoon with meat",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Rice vermicelli",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "stir fried beehoon with meat rice vermicelli",
    "weight-g": 284,
    "volume-ml": null,
    "calories": 364.7,
    "fat": 11.1,
    "saturated": 4,
    "polyunsaturated": 2.4,
    "monounsaturated": 4.1,
    "trans": null,
    "cholesterol": 94,
    "sodium": 346,
    "carbs": 44.9,
    "fibre": 4.8,
    "sugar": 1.4,
    "protein": 21.3,
    "potassium": 358,
    "calcium": 40,
    "iron": 2.3,
    "phosphorus": 210,
    "info": "Rice vermicelli"
  },
  {
    "id": 432,
    "disabled": null,
    "type": "meal",
    "name": "Stir fried mixed vegetables",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "stir fried mixed vegetables",
    "weight-g": 150,
    "volume-ml": null,
    "calories": 67,
    "fat": 6.8,
    "saturated": 1.3,
    "polyunsaturated": 3.3,
    "monounsaturated": 2.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 150,
    "carbs": 4.9,
    "fibre": 3.6,
    "sugar": 1.5,
    "protein": 3.6,
    "potassium": 265.5,
    "calcium": 40.5,
    "iron": 0.6,
    "phosphorus": 64.5,
    "info": null
  },
  {
    "id": 523,
    "disabled": null,
    "type": "meal",
    "name": "Stir fried tang hoon, plain",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Mung bean glass noodles",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "stir fried [tang|dong] [hoon|fen], plain mung bean glass noodles",
    "weight-g": 437,
    "volume-ml": null,
    "calories": 497.2,
    "fat": 23.6,
    "saturated": 10.1,
    "polyunsaturated": 2.6,
    "monounsaturated": 10.1,
    "trans": null,
    "cholesterol": 54.2,
    "sodium": 1381,
    "carbs": 54.2,
    "fibre": 10.9,
    "sugar": 0.9,
    "protein": 17,
    "potassium": 433,
    "calcium": 105,
    "iron": 1.8,
    "phosphorus": 201,
    "info": "Mung bean glass noodles"
  },
  {
    "id": 770,
    "disabled": null,
    "type": "meal",
    "name": "Stir-fried beef with ginger and spring onion",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "stir-fried beef with ginger and spring onion",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 144.3,
    "fat": 10,
    "saturated": 3.2,
    "polyunsaturated": 0.8,
    "monounsaturated": 2.9,
    "trans": null,
    "cholesterol": 18.8,
    "sodium": 573.8,
    "carbs": 2.7,
    "fibre": 2,
    "sugar": 1.4,
    "protein": 10.9,
    "potassium": 264.8,
    "calcium": 18.8,
    "iron": 0.9,
    "phosphorus": 67.5,
    "info": null
  },
  {
    "id": 772,
    "disabled": null,
    "type": "meal",
    "name": "Stir-fried chicken with ginger",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "stir-fried chicken with ginger",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 202.8,
    "fat": 14.4,
    "saturated": 5,
    "polyunsaturated": 2.9,
    "monounsaturated": 5.7,
    "trans": null,
    "cholesterol": 124,
    "sodium": 659,
    "carbs": 3.7,
    "fibre": 0,
    "sugar": 0,
    "protein": 14.6,
    "potassium": 130,
    "calcium": 66,
    "iron": 1.1,
    "phosphorus": 84,
    "info": null
  },
  {
    "id": 768,
    "disabled": null,
    "type": "meal",
    "name": "Stir-fried sliced fish with ginger and spring onion",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "stir-fried sliced fish with ginger and spring onion",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 117.6,
    "fat": 8,
    "saturated": 2.5,
    "polyunsaturated": 3,
    "monounsaturated": 2.5,
    "trans": null,
    "cholesterol": 49,
    "sodium": 516,
    "carbs": 0.4,
    "fibre": 0.5,
    "sugar": 0,
    "protein": 11,
    "potassium": 238,
    "calcium": 34,
    "iron": 0.7,
    "phosphorus": 86,
    "info": null
  },
  {
    "id": 769,
    "disabled": null,
    "type": "meal",
    "name": "Stir-fried sliced pork with ginger and spring onion",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "stir-fried sliced pork with ginger and spring onion",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 167.6,
    "fat": 8,
    "saturated": 2.7,
    "polyunsaturated": 1.9,
    "monounsaturated": 3.2,
    "trans": null,
    "cholesterol": 53,
    "sodium": 412,
    "carbs": 3.6,
    "fibre": 2.4,
    "sugar": 3,
    "protein": 20.3,
    "potassium": 324,
    "calcium": 48,
    "iron": 0.9,
    "phosphorus": 185,
    "info": null
  },
  {
    "id": 715,
    "disabled": null,
    "type": "snack",
    "name": "Strawberries",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 8,
    "unit": "fruit",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "strawberries",
    "weight-g": 96,
    "volume-ml": null,
    "calories": 21,
    "fat": 0.2,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 6.8,
    "carbs": 2.9,
    "fibre": 2,
    "sugar": 2.9,
    "protein": 1.9,
    "potassium": 141.8,
    "calcium": 14.3,
    "iron": 0.7,
    "phosphorus": 21,
    "info": null
  },
  {
    "id": 1016,
    "disabled": null,
    "type": "snack",
    "name": "Strawberry shortcake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "strawberry shortcake",
    "weight-g": 150,
    "volume-ml": null,
    "calories": 270,
    "fat": 12,
    "saturated": 6,
    "polyunsaturated": 0.6,
    "monounsaturated": 5.4,
    "trans": null,
    "cholesterol": 40,
    "sodium": null,
    "carbs": 23,
    "fibre": 1,
    "sugar": 7,
    "protein": 5,
    "potassium": null,
    "calcium": 60,
    "iron": 0.7,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 636,
    "disabled": null,
    "type": "meal",
    "name": "String beans",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "string beans",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 21.4,
    "fat": 0.2,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 2,
    "carbs": 2.6,
    "fibre": 2.9,
    "sugar": 1.2,
    "protein": 2.3,
    "potassium": 80,
    "calcium": 46,
    "iron": 1,
    "phosphorus": 40,
    "info": null
  },
  {
    "id": 69,
    "disabled": null,
    "type": "snack",
    "name": "Strudel with fruit fillings",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "apple, pineapple",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "strudel with fruit fillings apple, pineapple",
    "weight-g": 71,
    "volume-ml": null,
    "calories": 197.7,
    "fat": 8,
    "saturated": 1.5,
    "polyunsaturated": 3.8,
    "monounsaturated": 2.3,
    "trans": null,
    "cholesterol": 4.3,
    "sodium": 95.8,
    "carbs": 29.2,
    "fibre": 1.6,
    "sugar": 18.3,
    "protein": 2.3,
    "potassium": 106,
    "calcium": 10.6,
    "iron": 0.3,
    "phosphorus": 23.4,
    "info": "e.g. apple, pineapple"
  },
  {
    "id": 808,
    "disabled": null,
    "type": "meal",
    "name": "Subway flatbread",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 3,
    "unit": "inch",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "sausage, ham, bacon, egg, cheese",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "subway flatbread sausage, ham, bacon, egg, cheese",
    "weight-g": 134,
    "volume-ml": null,
    "calories": 281,
    "fat": 15,
    "saturated": 5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 232,
    "sodium": 666,
    "carbs": 20,
    "fibre": 1,
    "sugar": 1,
    "protein": 17,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. sausage, ham, bacon, egg, cheese"
  },
  {
    "id": 802,
    "disabled": null,
    "type": "meal",
    "name": "Subway sandwich with chicken or turkey",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 6,
    "unit": "inch",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "subway sandwich with chicken or turkey",
    "weight-g": 198,
    "volume-ml": null,
    "calories": 311,
    "fat": 6.2,
    "saturated": 1.4,
    "polyunsaturated": 1.9,
    "monounsaturated": 1.8,
    "trans": 0,
    "cholesterol": 46,
    "sodium": 531,
    "carbs": 42.3,
    "fibre": 2.4,
    "sugar": 5.9,
    "protein": 21.5,
    "potassium": 392,
    "calcium": 305,
    "iron": 3.4,
    "phosphorus": 287,
    "info": null
  },
  {
    "id": 804,
    "disabled": null,
    "type": "meal",
    "name": "Subway sandwich with meatless option",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 6,
    "unit": "inch",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "veggie delite, egg mayo, veggie patty",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "subway sandwich with meatless option veggie delite, egg mayo, patty",
    "weight-g": 247,
    "volume-ml": null,
    "calories": 435,
    "fat": 23,
    "saturated": 5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 299,
    "sodium": 477,
    "carbs": 42,
    "fibre": 5,
    "sugar": 6,
    "protein": 19,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. veggie delite, egg mayo, veggie patty"
  },
  {
    "id": 803,
    "disabled": null,
    "type": "meal",
    "name": "Subway sandwich with processed meat",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 6,
    "unit": "inch",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "ham, bacon",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "subway sandwich with processed meat ham, bacon",
    "weight-g": 240,
    "volume-ml": null,
    "calories": 360,
    "fat": 11,
    "saturated": 5.3,
    "polyunsaturated": 1,
    "monounsaturated": 4.8,
    "trans": null,
    "cholesterol": 46,
    "sodium": 1404,
    "carbs": 39,
    "fibre": 4,
    "sugar": 7,
    "protein": 24,
    "potassium": 478,
    "calcium": 214,
    "iron": 5.3,
    "phosphorus": 250,
    "info": "e.g. ham, bacon"
  },
  {
    "id": 805,
    "disabled": null,
    "type": "meal",
    "name": "Subway wrap with chicken or turkey",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "wrap",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "subway wrap with chicken or turkey",
    "weight-g": 252,
    "volume-ml": null,
    "calories": 345,
    "fat": 8.3,
    "saturated": 3,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 64,
    "sodium": 1013,
    "carbs": 40,
    "fibre": 2.3,
    "sugar": 3.8,
    "protein": 28.5,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 807,
    "disabled": null,
    "type": "meal",
    "name": "Subway wrap with meatless option",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "veggie delite, egg mayo, veggie patty",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "subway wrap with meatless option veggie delite, egg mayo, patty",
    "weight-g": 214,
    "volume-ml": null,
    "calories": 248,
    "fat": 6,
    "saturated": 2.6,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 606,
    "carbs": 42,
    "fibre": 3,
    "sugar": 3.8,
    "protein": 7.5,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. veggie delite, egg mayo, veggie patty"
  },
  {
    "id": 806,
    "disabled": null,
    "type": "meal",
    "name": "Subway wrap with processed meat",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "wrap",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "ham, bacon",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "subway wrap with processed meat ham, bacon",
    "weight-g": 265,
    "volume-ml": null,
    "calories": 478,
    "fat": 17,
    "saturated": 7.3,
    "polyunsaturated": 1,
    "monounsaturated": 4.8,
    "trans": null,
    "cholesterol": 46,
    "sodium": 1701,
    "carbs": 52,
    "fibre": 1,
    "sugar": 4,
    "protein": 23,
    "potassium": 478,
    "calcium": 214,
    "iron": 5.3,
    "phosphorus": 250,
    "info": "e.g. ham, bacon"
  },
  {
    "id": 172,
    "disabled": null,
    "type": "drink",
    "name": "Sugarcane juice, fresh",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sugarcane juice, fresh",
    "weight-g": null,
    "volume-ml": 263,
    "calories": 107.2,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 3,
    "carbs": 26,
    "fibre": 1.6,
    "sugar": 25.8,
    "protein": 0.8,
    "potassium": 195,
    "calcium": null,
    "iron": 0.3,
    "phosphorus": 18,
    "info": null
  },
  {
    "id": 39,
    "disabled": null,
    "type": "snack",
    "name": "Sun cake pastry",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Tai Yang Bing",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sun cake pastry tai yang bing",
    "weight-g": 60,
    "volume-ml": null,
    "calories": 240,
    "fat": 4,
    "saturated": 2,
    "polyunsaturated": 1,
    "monounsaturated": 1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 73,
    "carbs": 48,
    "fibre": 0,
    "sugar": 14,
    "protein": 3,
    "potassium": null,
    "calcium": 0,
    "iron": 0.9,
    "phosphorus": null,
    "info": "Tai Yang Bing"
  },
  {
    "id": 433,
    "disabled": null,
    "type": "meal",
    "name": "Soondubu jjigae, seafood",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Korean spicy tofu soup",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[soondubu|sundubu] jjigae, seafood korean spicy tofu soup",
    "weight-g": 456,
    "volume-ml": null,
    "calories": 239.9,
    "fat": 13.9,
    "saturated": 4.7,
    "polyunsaturated": 3.9,
    "monounsaturated": 4.3,
    "trans": null,
    "cholesterol": 3.7,
    "sodium": 1041,
    "carbs": 6,
    "fibre": 1,
    "sugar": 3.2,
    "protein": 22.7,
    "potassium": 571,
    "calcium": 102,
    "iron": 3.4,
    "phosphorus": 356,
    "info": "Korean spicy tofu soup"
  },
  {
    "id": 324,
    "disabled": null,
    "type": "meal",
    "name": "Sup tulang",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Beef bone soup",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sup tulang beef bone soup",
    "weight-g": 375,
    "volume-ml": null,
    "calories": 184.3,
    "fat": 7.5,
    "saturated": 2.8,
    "polyunsaturated": 0.3,
    "monounsaturated": 4.1,
    "trans": null,
    "cholesterol": 34,
    "sodium": 1448,
    "carbs": 11.6,
    "fibre": 5.6,
    "sugar": 6.4,
    "protein": 17.6,
    "potassium": 270,
    "calcium": 229,
    "iron": 0.8,
    "phosphorus": 1283,
    "info": "Beef bone soup"
  },
  {
    "id": 847,
    "disabled": null,
    "type": "meal",
    "name": "Sushi rice stuffed in sweet beancurd skin",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sushi rice stuffed in sweet beancurd skin",
    "weight-g": 68,
    "volume-ml": null,
    "calories": 128,
    "fat": 3.4,
    "saturated": 0.4,
    "polyunsaturated": 1.4,
    "monounsaturated": 1.4,
    "trans": null,
    "cholesterol": 0,
    "sodium": 294,
    "carbs": 20.2,
    "fibre": 1,
    "sugar": 5.4,
    "protein": 4,
    "potassium": 22,
    "calcium": 48,
    "iron": 0.4,
    "phosphorus": 46,
    "info": null
  },
  {
    "id": 236,
    "disabled": null,
    "type": "meal",
    "name": "Sushi, maki, small",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 3,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "kappa (cucumber), kanikama (crab), tamago (egg)",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sushi, maki, small kappa (cucumber), kanikama (crab), tamago (egg)",
    "weight-g": 27,
    "volume-ml": null,
    "calories": 38.2,
    "fat": 0.2,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 92.5,
    "carbs": 8.3,
    "fibre": 0.2,
    "sugar": 1.5,
    "protein": 0.8,
    "potassium": 21.5,
    "calcium": 3.5,
    "iron": 0,
    "phosphorus": 8.5,
    "info": "e.g. kappa (cucumber), kanikama (crab), tamago (egg)"
  },
  {
    "id": 237,
    "disabled": null,
    "type": "meal",
    "name": "Sushi, nigiri",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "raw salmon, prawn, scallop",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sushi, nigiri raw salmon, prawn, scallop",
    "weight-g": 34,
    "volume-ml": null,
    "calories": 60.4,
    "fat": 1.2,
    "saturated": 0.7,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.3,
    "trans": null,
    "cholesterol": 3,
    "sodium": 90,
    "carbs": 10,
    "fibre": 0.2,
    "sugar": 0.6,
    "protein": 2.4,
    "potassium": 25,
    "calcium": 4,
    "iron": 0,
    "phosphorus": 22,
    "info": "e.g. raw salmon, prawn, scallop"
  },
  {
    "id": 238,
    "disabled": null,
    "type": "meal",
    "name": "Sushi, uramaki",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Inside-out roll",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sushi, uramaki inside-out roll",
    "weight-g": 35,
    "volume-ml": null,
    "calories": 48,
    "fat": 1.2,
    "saturated": 0.3,
    "polyunsaturated": 0.4,
    "monounsaturated": 0.4,
    "trans": null,
    "cholesterol": 17,
    "sodium": 162,
    "carbs": 7.8,
    "fibre": 0.4,
    "sugar": 1.3,
    "protein": 1.5,
    "potassium": 27,
    "calcium": 8,
    "iron": 0,
    "phosphorus": 17,
    "info": "Inside-out roll"
  },
  {
    "id": 80,
    "disabled": null,
    "type": "snack",
    "name": "Sweet crepe",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with sauce and fruits",
    "search-text": "sweet crepe",
    "weight-g": 324,
    "volume-ml": null,
    "calories": 447,
    "fat": 11,
    "saturated": 6,
    "polyunsaturated": 1.1,
    "monounsaturated": 3,
    "trans": null,
    "cholesterol": 132,
    "sodium": 285,
    "carbs": 78.2,
    "fibre": 5,
    "sugar": 48.1,
    "protein": 8.8,
    "potassium": 350,
    "calcium": 60,
    "iron": 1.4,
    "phosphorus": 26,
    "info": "with sauce and fruits"
  },
  {
    "id": 678,
    "disabled": null,
    "type": "meal",
    "name": "Sweet potato leaves / shoots",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sweet potato leaves / shoots",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 82,
    "fat": 4.8,
    "saturated": 2.2,
    "polyunsaturated": 0.5,
    "monounsaturated": 1.9,
    "trans": null,
    "cholesterol": 0,
    "sodium": 383,
    "carbs": 7.2,
    "fibre": 1.7,
    "sugar": 5.3,
    "protein": 2.5,
    "potassium": 286,
    "calcium": 33,
    "iron": 0.6,
    "phosphorus": 38,
    "info": null
  },
  {
    "id": 217,
    "disabled": null,
    "type": "snack",
    "name": "Sweet potato soup (dessert)",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sweet potato soup (dessert)",
    "weight-g": 240,
    "volume-ml": null,
    "calories": 146,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 22,
    "carbs": 35.5,
    "fibre": 4.1,
    "sugar": 15.8,
    "protein": 1,
    "potassium": 247,
    "calcium": 19,
    "iron": 0.7,
    "phosphorus": 36,
    "info": null
  },
  {
    "id": 204,
    "disabled": null,
    "type": "snack",
    "name": "Sweet potato, baked",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "potato",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sweet potato, baked",
    "weight-g": 114,
    "volume-ml": null,
    "calories": 105.4,
    "fat": 0.2,
    "saturated": 0.1,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 41,
    "carbs": 23.6,
    "fibre": 3.8,
    "sugar": 7.4,
    "protein": 2.3,
    "potassium": 542,
    "calcium": 43,
    "iron": 0.8,
    "phosphorus": 62,
    "info": null
  },
  {
    "id": 325,
    "disabled": null,
    "type": "meal",
    "name": "Sweet potato, boiled",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "potato",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sweet potato, boiled",
    "weight-g": 151,
    "volume-ml": null,
    "calories": 117.4,
    "fat": 0.2,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 40.8,
    "carbs": 26.8,
    "fibre": 3.8,
    "sugar": 8.7,
    "protein": 2.1,
    "potassium": 347,
    "calcium": 40.8,
    "iron": 1.1,
    "phosphorus": 48.3,
    "info": null
  },
  {
    "id": 638,
    "disabled": null,
    "type": "meal",
    "name": "Sweetcorn, canned",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sweetcorn, canned",
    "weight-g": 128,
    "volume-ml": null,
    "calories": 105.9,
    "fat": 0.5,
    "saturated": 0.2,
    "polyunsaturated": 0.3,
    "monounsaturated": 0.2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 365,
    "carbs": 23.2,
    "fibre": 3.2,
    "sugar": 3.5,
    "protein": 2.2,
    "potassium": 171.5,
    "calcium": 4,
    "iron": 0.5,
    "phosphorus": 65.5,
    "info": null
  },
  {
    "id": 79,
    "disabled": null,
    "type": "snack",
    "name": "Swiss roll",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "swiss roll",
    "weight-g": 56,
    "volume-ml": null,
    "calories": 202.2,
    "fat": 11.8,
    "saturated": 7.5,
    "polyunsaturated": 0.6,
    "monounsaturated": 3.2,
    "trans": null,
    "cholesterol": 62,
    "sodium": 95,
    "carbs": 19.9,
    "fibre": 0.8,
    "sugar": 11.3,
    "protein": 4.1,
    "potassium": 52,
    "calcium": 20,
    "iron": 1.2,
    "phosphorus": 79,
    "info": null
  },
  {
    "id": 326,
    "disabled": null,
    "type": "meal",
    "name": "Taco",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with cheese, meat",
    "search-text": "taco",
    "weight-g": 110,
    "volume-ml": null,
    "calories": 215.7,
    "fat": 11.3,
    "saturated": 3.8,
    "polyunsaturated": 3.8,
    "monounsaturated": 3.8,
    "trans": 0.4,
    "cholesterol": 29,
    "sodium": 343.5,
    "carbs": 13.4,
    "fibre": 1.9,
    "sugar": 1.5,
    "protein": 15.1,
    "potassium": 216.5,
    "calcium": 92,
    "iron": 1.3,
    "phosphorus": 184,
    "info": "with cheese, meat"
  },
  {
    "id": 410,
    "disabled": null,
    "type": "snack",
    "name": "Tahini",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tsp",
    "info-translation": "Sesame seed paste",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[tahini|tahina] sesame seed paste",
    "weight-g": 5,
    "volume-ml": null,
    "calories": 34.2,
    "fat": 3,
    "saturated": 0.4,
    "polyunsaturated": 1.4,
    "monounsaturated": 1.1,
    "trans": 0.1,
    "cholesterol": 0,
    "sodium": 4,
    "carbs": 0.8,
    "fibre": 0.7,
    "sugar": 0,
    "protein": 1,
    "potassium": 10,
    "calcium": 17,
    "iron": 0.3,
    "phosphorus": 37,
    "info": "Sesame seed paste"
  },
  {
    "id": 463,
    "disabled": null,
    "type": "meal",
    "name": "Tahu telor",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 0.5,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[tahu|tauhu] [telor|telur]",
    "weight-g": 159,
    "volume-ml": null,
    "calories": 406,
    "fat": 32.8,
    "saturated": 13.3,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 112,
    "sodium": 266,
    "carbs": 10.4,
    "fibre": 4.8,
    "sugar": null,
    "protein": 17.1,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 504,
    "disabled": null,
    "type": "meal",
    "name": "Taiwanese oyster vermicelli",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Orh Ah Mee Sua",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "taiwanese oyster vermicelli orh ah mee sua",
    "weight-g": 587,
    "volume-ml": null,
    "calories": 316.1,
    "fat": 3.3,
    "saturated": 1.4,
    "polyunsaturated": 1.2,
    "monounsaturated": 1.1,
    "trans": null,
    "cholesterol": 41,
    "sodium": 664,
    "carbs": 52.8,
    "fibre": 3.1,
    "sugar": 7.9,
    "protein": 18.8,
    "potassium": 536,
    "calcium": 79,
    "iron": 6.3,
    "phosphorus": 174,
    "info": "Orh Ah Mee Sua"
  },
  {
    "id": 70,
    "disabled": null,
    "type": "snack",
    "name": "Takoyaki",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "ball",
    "info-translation": "Octopus Balls",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "takoyaki octopus balls",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 55.9,
    "fat": 3.1,
    "saturated": 0.9,
    "polyunsaturated": 0.5,
    "monounsaturated": 2.4,
    "trans": null,
    "cholesterol": 6,
    "sodium": 137,
    "carbs": 5,
    "fibre": 1,
    "sugar": 2,
    "protein": 2,
    "potassium": 56,
    "calcium": 12,
    "iron": 0.1,
    "phosphorus": 20,
    "info": "Octopus Balls"
  },
  {
    "id": 669,
    "disabled": null,
    "type": "meal",
    "name": "Tang oh, boiled/ steamed",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": "Garland chrysanthemum",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tang [oh|ho], boiled/ steamed garland chrysanthemum",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 23.8,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 53,
    "carbs": 4.3,
    "fibre": 2.3,
    "sugar": 0,
    "protein": 1.6,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "Garland chrysanthemum"
  },
  {
    "id": 703,
    "disabled": null,
    "type": "snack",
    "name": "Tangerine",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "fruit",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tangerine",
    "weight-g": 116,
    "volume-ml": null,
    "calories": 50.3,
    "fat": 0.3,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 3,
    "carbs": 10.6,
    "fibre": 2.2,
    "sugar": 10.6,
    "protein": 1.3,
    "potassium": 164,
    "calcium": 21,
    "iron": 0.2,
    "phosphorus": 20,
    "info": null
  },
  {
    "id": 518,
    "disabled": null,
    "type": "meal",
    "name": "Tapas",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 3,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tapas",
    "weight-g": 220,
    "volume-ml": null,
    "calories": 469,
    "fat": 29.5,
    "saturated": 6.5,
    "polyunsaturated": 12,
    "monounsaturated": 9.5,
    "trans": 0.3,
    "cholesterol": 152,
    "sodium": 1379,
    "carbs": 32,
    "fibre": 2.5,
    "sugar": 2.5,
    "protein": 19,
    "potassium": 685,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 659,
    "disabled": null,
    "type": "meal",
    "name": "Tapioca, steamed",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tapioca, steamed",
    "weight-g": 85,
    "volume-ml": null,
    "calories": 70.6,
    "fat": 0.2,
    "saturated": 0.1,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 150,
    "carbs": 16.6,
    "fibre": 1,
    "sugar": 1,
    "protein": 0.6,
    "potassium": 147,
    "calcium": 11,
    "iron": 0.4,
    "phosphorus": 38,
    "info": null
  },
  {
    "id": 519,
    "disabled": null,
    "type": "meal",
    "name": "Tarte flambée",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": "Flammekueche",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tarte flambée flammekueche",
    "weight-g": 145,
    "volume-ml": null,
    "calories": 380,
    "fat": 15,
    "saturated": 5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 15,
    "sodium": 780,
    "carbs": 46,
    "fibre": 2,
    "sugar": 5,
    "protein": 13,
    "potassium": 210,
    "calcium": 59,
    "iron": 1.1,
    "phosphorus": null,
    "info": "Flammekueche"
  },
  {
    "id": 195,
    "disabled": null,
    "type": "snack",
    "name": "Tau suan with dough fritters",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[tau|dou|tao] [suan|shuang] with dough fritters",
    "weight-g": 240,
    "volume-ml": null,
    "calories": 232.3,
    "fat": 1.9,
    "saturated": 1.6,
    "polyunsaturated": 1.6,
    "monounsaturated": 0.7,
    "trans": null,
    "cholesterol": 0,
    "sodium": 79,
    "carbs": 47.8,
    "fibre": 4,
    "sugar": 15.6,
    "protein": 6,
    "potassium": 110,
    "calcium": 17,
    "iron": 1.4,
    "phosphorus": 84,
    "info": null
  },
  {
    "id": 477,
    "disabled": null,
    "type": "snack",
    "name": "Tau suan, without dough fritters",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[tau|dou|tao] [suan|shuang], without dough fritters",
    "weight-g": 229,
    "volume-ml": null,
    "calories": 189.8,
    "fat": 0.2,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 10,
    "carbs": 42,
    "fibre": 4,
    "sugar": 15.6,
    "protein": 5,
    "potassium": 110,
    "calcium": 14,
    "iron": 1.4,
    "phosphorus": 70,
    "info": null
  },
  {
    "id": 613,
    "disabled": null,
    "type": "drink",
    "name": "Tea, flavored and sweetened",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "honey, mango, raspberry",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tea, flavored and sweetened honey, mango, raspberry",
    "weight-g": null,
    "volume-ml": 240,
    "calories": 72,
    "fat": 0,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 18,
    "fibre": null,
    "sugar": 17,
    "protein": null,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. honey, mango, raspberry"
  },
  {
    "id": 162,
    "disabled": null,
    "type": "drink",
    "name": "Tea with fresh milk, sweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tea with fresh milk, sweetened",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 109,
    "fat": 2.4,
    "saturated": 0.6,
    "polyunsaturated": 0.3,
    "monounsaturated": 1.4,
    "trans": null,
    "cholesterol": 5,
    "sodium": 21,
    "carbs": 20,
    "fibre": 0,
    "sugar": 20,
    "protein": 1.7,
    "potassium": 52,
    "calcium": 55,
    "iron": 0,
    "phosphorus": 43,
    "info": null
  },
  {
    "id": 161,
    "disabled": null,
    "type": "drink",
    "name": "Tea with fresh milk, unsweetened",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tea with fresh milk, unsweetened",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 40.4,
    "fat": 2.4,
    "saturated": 0.6,
    "polyunsaturated": 0.3,
    "monounsaturated": 1.4,
    "trans": null,
    "cholesterol": 5,
    "sodium": null,
    "carbs": 3,
    "fibre": 0,
    "sugar": 3,
    "protein": 1.7,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 358,
    "disabled": null,
    "type": "drink",
    "name": "Tea, unsweetened",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "black, green, red",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tea, unsweetened black, green, red",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 4,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 8,
    "carbs": 0.5,
    "fibre": 0,
    "sugar": 0,
    "protein": 0.5,
    "potassium": 68,
    "calcium": 8,
    "iron": 0.5,
    "phosphorus": 5,
    "info": "e.g. black, green, red"
  },
  {
    "id": 563,
    "disabled": null,
    "type": "drink",
    "name": "Teh",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": "Tea with condensed milk",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "teh tea with condensed milk",
    "weight-g": 260,
    "volume-ml": null,
    "calories": 108.4,
    "fat": 2.4,
    "saturated": 0.5,
    "polyunsaturated": 0.3,
    "monounsaturated": 1.4,
    "trans": null,
    "cholesterol": 5,
    "sodium": 21,
    "carbs": 20,
    "fibre": 0,
    "sugar": 20,
    "protein": 1.7,
    "potassium": 52,
    "calcium": 55,
    "iron": 0,
    "phosphorus": 43,
    "info": "Tea with condensed milk"
  },
  {
    "id": 564,
    "disabled": null,
    "type": "drink",
    "name": "Teh C",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": "Tea with evaporated milk and sugar",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "teh [c|si] tea with evaporated milk and sugar",
    "weight-g": 278,
    "volume-ml": null,
    "calories": 89,
    "fat": 2.6,
    "saturated": 1.7,
    "polyunsaturated": 0.5,
    "monounsaturated": 0.4,
    "trans": null,
    "cholesterol": 9,
    "sodium": 2,
    "carbs": 14.2,
    "fibre": 0,
    "sugar": 10,
    "protein": 2.2,
    "potassium": 124,
    "calcium": 5,
    "iron": 0.1,
    "phosphorus": 7,
    "info": "Tea with evaporated milk and sugar"
  },
  {
    "id": 569,
    "disabled": null,
    "type": "drink",
    "name": "Teh C kosong",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": "Tea with evaporated milk, without sugar",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "teh [c|si] kosong tea with evaporated milk, without sugar",
    "weight-g": 250,
    "volume-ml": null,
    "calories": 44.9,
    "fat": 2.5,
    "saturated": 1.6,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.7,
    "trans": null,
    "cholesterol": 8,
    "sodium": 32,
    "carbs": 3.1,
    "fibre": 0,
    "sugar": 3,
    "protein": 2.5,
    "potassium": 154,
    "calcium": 80,
    "iron": 0.1,
    "phosphorus": 69,
    "info": "Tea with evaporated milk, without sugar"
  },
  {
    "id": 566,
    "disabled": null,
    "type": "drink",
    "name": "Teh C siew dai",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": "Tea with evaporated milk, less sugar",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "teh [c|si] [siew|siu] dai tea with evaporated milk, less sugar",
    "weight-g": 250,
    "volume-ml": null,
    "calories": 68.9,
    "fat": 2.5,
    "saturated": 1.6,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.7,
    "trans": null,
    "cholesterol": 8,
    "sodium": 32,
    "carbs": 9.1,
    "fibre": 0,
    "sugar": 9.1,
    "protein": 2.5,
    "potassium": 154,
    "calcium": 80,
    "iron": 0.1,
    "phosphorus": 69,
    "info": "Tea with evaporated milk, less sugar"
  },
  {
    "id": 567,
    "disabled": null,
    "type": "drink",
    "name": "Teh O",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": "Tea with sugar",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "teh o tea with sugar",
    "weight-g": 250,
    "volume-ml": null,
    "calories": 60,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": null,
    "carbs": 15,
    "fibre": 0,
    "sugar": 15,
    "protein": 0.3,
    "potassium": 43,
    "calcium": 0,
    "iron": 0,
    "phosphorus": 3,
    "info": "Tea with sugar"
  },
  {
    "id": 568,
    "disabled": null,
    "type": "drink",
    "name": "Teh O kosong",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": "Tea without sugar",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "teh o kosong tea without sugar",
    "weight-g": 250,
    "volume-ml": null,
    "calories": 1.2,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 0.3,
    "potassium": 43,
    "calcium": 0,
    "iron": 0,
    "phosphorus": 3,
    "info": "Tea without sugar"
  },
  {
    "id": 565,
    "disabled": null,
    "type": "drink",
    "name": "Teh siew dai",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": "Tea with less condensed milk",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "teh [siew|siu] dai tea with less condensed milk",
    "weight-g": 250,
    "volume-ml": null,
    "calories": 54.4,
    "fat": 1.2,
    "saturated": 0.3,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.7,
    "trans": null,
    "cholesterol": 3,
    "sodium": 11,
    "carbs": 10,
    "fibre": 0,
    "sugar": 10,
    "protein": 0.9,
    "potassium": 48,
    "calcium": 28,
    "iron": 0,
    "phosphorus": 22,
    "info": "Tea with less condensed milk"
  },
  {
    "id": 541,
    "disabled": null,
    "type": "meal",
    "name": "Temaki",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Hand rolled sushi",
    "info-brands": null,
    "info-examples": "california temaki",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "temaki hand rolled sushi california temaki",
    "weight-g": 60,
    "volume-ml": null,
    "calories": 105.7,
    "fat": 2.1,
    "saturated": 1.1,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.6,
    "trans": null,
    "cholesterol": 5,
    "sodium": 158,
    "carbs": 17.6,
    "fibre": 0.6,
    "sugar": 1.1,
    "protein": 4.1,
    "potassium": 44,
    "calcium": 7,
    "iron": 0,
    "phosphorus": 39,
    "info": "Hand rolled sushi, e.g. california temaki"
  },
  {
    "id": 434,
    "disabled": null,
    "type": "meal",
    "name": "Tempeh, fried",
    "rating": 3,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": 1,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tempeh, fried",
    "weight-g": 35,
    "volume-ml": null,
    "calories": 102.4,
    "fat": 8,
    "saturated": 1.3,
    "polyunsaturated": 4.7,
    "monounsaturated": 1.7,
    "trans": null,
    "cholesterol": 0,
    "sodium": 84,
    "carbs": 2.5,
    "fibre": 2.5,
    "sugar": 0,
    "protein": 5.1,
    "potassium": 28,
    "calcium": 18,
    "iron": 0.4,
    "phosphorus": 54,
    "info": null
  },
  {
    "id": 327,
    "disabled": null,
    "type": "meal",
    "name": "Tempura, prawn",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tempura, prawn",
    "weight-g": 33,
    "volume-ml": null,
    "calories": 122.2,
    "fat": 8.2,
    "saturated": 3.7,
    "polyunsaturated": 0.8,
    "monounsaturated": 3.4,
    "trans": null,
    "cholesterol": 12.3,
    "sodium": 42.7,
    "carbs": 8.6,
    "fibre": 0.4,
    "sugar": 0,
    "protein": 3.5,
    "potassium": 51.3,
    "calcium": 11.3,
    "iron": 0.3,
    "phosphorus": 43.7,
    "info": null
  },
  {
    "id": 991,
    "disabled": null,
    "type": "meal",
    "name": "Tempura, seafood",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "prawns, fish",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tempura, seafood prawns, fish",
    "weight-g": 25,
    "volume-ml": null,
    "calories": 52.3,
    "fat": 2.9,
    "saturated": 0.5,
    "polyunsaturated": 1.1,
    "monounsaturated": 1.1,
    "trans": null,
    "cholesterol": 37,
    "sodium": 112,
    "carbs": 2.8,
    "fibre": 0.2,
    "sugar": 0.2,
    "protein": 3.8,
    "potassium": 35.5,
    "calcium": 19.8,
    "iron": 0.3,
    "phosphorus": 64.5,
    "info": "e.g. prawns, fish"
  },
  {
    "id": 328,
    "disabled": null,
    "type": "meal",
    "name": "Tempura, vegetable",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tempura, vegetable",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 69.4,
    "fat": 4.6,
    "saturated": 0.7,
    "polyunsaturated": 1.8,
    "monounsaturated": 1.9,
    "trans": null,
    "cholesterol": 10.7,
    "sodium": 3.7,
    "carbs": 5.9,
    "fibre": 0.5,
    "sugar": 0.5,
    "protein": 1.1,
    "potassium": 48.3,
    "calcium": 6.3,
    "iron": 0.4,
    "phosphorus": 17.7,
    "info": null
  },
  {
    "id": 29,
    "disabled": null,
    "type": "snack",
    "name": "Teochew yam paste with ginkgo nut and pumpkin",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Orh Ni",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "teochew yam paste with ginkgo nut and pumpkin [orh|or] [ni|nee]",
    "weight-g": 200,
    "volume-ml": null,
    "calories": 475.6,
    "fat": 24.4,
    "saturated": 10.2,
    "polyunsaturated": 3.2,
    "monounsaturated": 10,
    "trans": null,
    "cholesterol": 18,
    "sodium": 2,
    "carbs": 61,
    "fibre": 4.8,
    "sugar": 41.2,
    "protein": 3,
    "potassium": 496,
    "calcium": 52,
    "iron": 1,
    "phosphorus": 74,
    "info": "Orh Ni"
  },
  {
    "id": 520,
    "disabled": null,
    "type": "meal",
    "name": "Ter kar beehoon",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Pig trotters rice vermicelli",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ter kar beehoon pig trotters rice vermicelli",
    "weight-g": 300,
    "volume-ml": null,
    "calories": 457,
    "fat": 18.6,
    "saturated": 7,
    "polyunsaturated": 2.3,
    "monounsaturated": 8.6,
    "trans": null,
    "cholesterol": 128,
    "sodium": 1150,
    "carbs": 48,
    "fibre": 6.7,
    "sugar": 2.2,
    "protein": 23,
    "potassium": 204,
    "calcium": 63,
    "iron": 2.1,
    "phosphorus": 148,
    "info": "Pig trotters rice vermicelli"
  },
  {
    "id": 329,
    "disabled": null,
    "type": "meal",
    "name": "Teriyaki chicken don",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Japanese marinated chicken with rice",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "teriyaki chicken don japanese marinated with rice",
    "weight-g": 406,
    "volume-ml": null,
    "calories": 641.2,
    "fat": 23.6,
    "saturated": 7.8,
    "polyunsaturated": 3.7,
    "monounsaturated": 11.1,
    "trans": null,
    "cholesterol": 179,
    "sodium": 946,
    "carbs": 73.5,
    "fibre": 5.7,
    "sugar": 8.1,
    "protein": 33.7,
    "potassium": 353,
    "calcium": 85,
    "iron": 0.8,
    "phosphorus": 260,
    "info": "Japanese marinated chicken with rice"
  },
  {
    "id": 526,
    "disabled": null,
    "type": "drink",
    "name": "Thai milk tea",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "thai milk tea",
    "weight-g": 527,
    "volume-ml": null,
    "calories": 300,
    "fat": 10.4,
    "saturated": 6.8,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 79,
    "carbs": 49.5,
    "fibre": 1.6,
    "sugar": null,
    "protein": 2.6,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 602,
    "disabled": null,
    "type": "snack",
    "name": "Thin egg wafer rolls",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 7,
    "unit": "piece",
    "info-translation": null,
    "info-brands": "Julie's love letter",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "thin egg wafer rolls julie's love letter",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 141,
    "fat": 5,
    "saturated": 2.5,
    "polyunsaturated": 1.5,
    "monounsaturated": 1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 65,
    "carbs": 22,
    "fibre": 0,
    "sugar": 12,
    "protein": 2,
    "potassium": null,
    "calcium": 20,
    "iron": 0,
    "phosphorus": null,
    "info": "e.g. Julie's love letter"
  },
  {
    "id": 885,
    "disabled": null,
    "type": "meal",
    "name": "Thosai, egg",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[thosai|dosa|tosei], egg",
    "weight-g": 98,
    "volume-ml": null,
    "calories": 167.9,
    "fat": 7.1,
    "saturated": 2.4,
    "polyunsaturated": 0.8,
    "monounsaturated": 3.3,
    "trans": null,
    "cholesterol": 204,
    "sodium": 320,
    "carbs": 18,
    "fibre": 0.6,
    "sugar": 1.5,
    "protein": 8,
    "potassium": 110,
    "calcium": 37,
    "iron": 1.3,
    "phosphorus": 124,
    "info": null
  },
  {
    "id": 883,
    "disabled": null,
    "type": "meal",
    "name": "Thosai, masala",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[thosai|dosa|tosei], masala",
    "weight-g": 225,
    "volume-ml": null,
    "calories": 363.7,
    "fat": 13.3,
    "saturated": 5.8,
    "polyunsaturated": 1.5,
    "monounsaturated": 5.4,
    "trans": null,
    "cholesterol": 0,
    "sodium": 779,
    "carbs": 52.4,
    "fibre": 4.5,
    "sugar": 3.2,
    "protein": 8.6,
    "potassium": 488,
    "calcium": 32,
    "iron": 3.1,
    "phosphorus": 122,
    "info": null
  },
  {
    "id": 884,
    "disabled": null,
    "type": "meal",
    "name": "Thosai, onion",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[thosai|dosa|tosei], onion",
    "weight-g": 65,
    "volume-ml": null,
    "calories": 101.2,
    "fat": 2,
    "saturated": 0.9,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.8,
    "trans": null,
    "cholesterol": 0,
    "sodium": 266,
    "carbs": 18.5,
    "fibre": 1,
    "sugar": 1.9,
    "protein": 2.3,
    "potassium": 70,
    "calcium": 12,
    "iron": 0.1,
    "phosphorus": 37,
    "info": null
  },
  {
    "id": 886,
    "disabled": null,
    "type": "meal",
    "name": "Thosai, paper",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[thosai|dosa|tosei], paper",
    "weight-g": 50,
    "volume-ml": null,
    "calories": 145,
    "fat": 5,
    "saturated": 2,
    "polyunsaturated": 0,
    "monounsaturated": 1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 219,
    "carbs": 22,
    "fibre": 1,
    "sugar": 0,
    "protein": 3,
    "potassium": 83,
    "calcium": 7,
    "iron": 0,
    "phosphorus": 49,
    "info": null
  },
  {
    "id": 264,
    "disabled": null,
    "type": "meal",
    "name": "Thosai, plain",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[thosai|dosa|tosei], plain",
    "weight-g": 45,
    "volume-ml": null,
    "calories": 96.4,
    "fat": 2,
    "saturated": 0.9,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.8,
    "trans": null,
    "cholesterol": 0,
    "sodium": 264,
    "carbs": 17.6,
    "fibre": 0.6,
    "sugar": 1,
    "protein": 2,
    "potassium": 37,
    "calcium": 8,
    "iron": 0,
    "phosphorus": 29,
    "info": null
  },
  {
    "id": 793,
    "disabled": null,
    "type": "meal",
    "name": "Thunder tea rice",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "thunder tea rice",
    "weight-g": 440,
    "volume-ml": null,
    "calories": 498,
    "fat": 18,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 43,
    "carbs": 66,
    "fibre": 11,
    "sugar": 0,
    "protein": 18,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 663,
    "disabled": null,
    "type": "meal",
    "name": "Ti wan chye",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": "Emperor vegetable",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[ti|di] [wan|wang] [chye|choy|cai] emperor vegetable",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 83.4,
    "fat": 5,
    "saturated": 0.7,
    "polyunsaturated": 3.2,
    "monounsaturated": 1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 350,
    "carbs": 5.8,
    "fibre": 3.1,
    "sugar": 3.4,
    "protein": 3.8,
    "potassium": 369,
    "calcium": 107,
    "iron": 2,
    "phosphorus": 48,
    "info": "Emperor vegetable"
  },
  {
    "id": 72,
    "disabled": null,
    "type": "snack",
    "name": "Tiramisu",
    "rating": 2,
    "alcohol": 1,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tiramisu",
    "weight-g": 131,
    "volume-ml": null,
    "calories": 344.7,
    "fat": 19.5,
    "saturated": 11.4,
    "polyunsaturated": 1.7,
    "monounsaturated": 4.8,
    "trans": null,
    "cholesterol": 68,
    "sodium": 148,
    "carbs": 35.2,
    "fibre": 1.2,
    "sugar": 14.3,
    "protein": 7.1,
    "potassium": 157,
    "calcium": 69,
    "iron": 0.7,
    "phosphorus": 157,
    "info": null
  },
  {
    "id": 837,
    "disabled": null,
    "type": "meal",
    "name": "Agedashi tofu (fried)",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "piece",
    "info-translation": "Japanese fried beancurd",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "agedashi tofu (fried) japanese fried beancurd",
    "weight-g": 26,
    "volume-ml": null,
    "calories": 70,
    "fat": 5.2,
    "saturated": 0.8,
    "polyunsaturated": 3,
    "monounsaturated": 1.2,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 4,
    "carbs": 2.3,
    "fibre": 1,
    "sugar": 0.7,
    "protein": 4.9,
    "potassium": 38,
    "calcium": 97,
    "iron": 1.2,
    "phosphorus": 75,
    "info": "Japanese fried beancurd"
  },
  {
    "id": 782,
    "disabled": null,
    "type": "meal",
    "name": "Tofu, claypot",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "3\" x 3\" x 1\"",
    "info-include-exclude": null,
    "search-text": "tofu, claypot",
    "weight-g": 150,
    "volume-ml": null,
    "calories": 208.9,
    "fat": 15.3,
    "saturated": 3.1,
    "polyunsaturated": 1.1,
    "monounsaturated": 3.2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 341,
    "carbs": 12.4,
    "fibre": 0.9,
    "sugar": 1.4,
    "protein": 5.4,
    "potassium": 69,
    "calcium": 42,
    "iron": 0.6,
    "phosphorus": 67.5,
    "info": "(3\" x 3\" x 1\")"
  },
  {
    "id": 781,
    "disabled": null,
    "type": "meal",
    "name": "Tofu, hot plate",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "3\" x 3\" x 1\"",
    "info-include-exclude": null,
    "search-text": "tofu, hot plate",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 224.5,
    "fat": 20.5,
    "saturated": 8.2,
    "polyunsaturated": 2.9,
    "monounsaturated": 8.8,
    "trans": null,
    "cholesterol": 168,
    "sodium": 456,
    "carbs": 1.1,
    "fibre": 0.6,
    "sugar": 0,
    "protein": 8.9,
    "potassium": 118,
    "calcium": 36,
    "iron": 0,
    "phosphorus": 142,
    "info": "(3\" x 3\" x 1\")"
  },
  {
    "id": 840,
    "disabled": null,
    "type": "meal",
    "name": "Tom yum fried rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tom yum fried rice",
    "weight-g": 272,
    "volume-ml": null,
    "calories": 441,
    "fat": 16.8,
    "saturated": 7,
    "polyunsaturated": 1.6,
    "monounsaturated": 6.4,
    "trans": null,
    "cholesterol": 92,
    "sodium": 788,
    "carbs": 54.3,
    "fibre": 2.4,
    "sugar": 7.5,
    "protein": 17.5,
    "potassium": 259,
    "calcium": 122,
    "iron": 4.5,
    "phosphorus": 54,
    "info": null
  },
  {
    "id": 330,
    "disabled": null,
    "type": "meal",
    "name": "Soup, tom yum chicken",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soup, tom yum chicken",
    "weight-g": 539,
    "volume-ml": null,
    "calories": 263,
    "fat": 9,
    "saturated": 1,
    "polyunsaturated": 1,
    "monounsaturated": 2,
    "trans": null,
    "cholesterol": 34,
    "sodium": 550,
    "carbs": 23,
    "fibre": 3,
    "sugar": 6,
    "protein": 20,
    "potassium": 605,
    "calcium": 32,
    "iron": 1,
    "phosphorus": 186,
    "info": null
  },
  {
    "id": 841,
    "disabled": null,
    "type": "meal",
    "name": "Tom yum noodle soup",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tom yum noodle soup",
    "weight-g": 342,
    "volume-ml": null,
    "calories": 421,
    "fat": 4.4,
    "saturated": 3,
    "polyunsaturated": 0.5,
    "monounsaturated": 0.2,
    "trans": null,
    "cholesterol": 155,
    "sodium": 1215,
    "carbs": 64.2,
    "fibre": 2.9,
    "sugar": 5.4,
    "protein": 29.8,
    "potassium": 123,
    "calcium": 24,
    "iron": 4.5,
    "phosphorus": 63,
    "info": null
  },
  {
    "id": 700,
    "disabled": null,
    "type": "meal",
    "name": "Tomato, paste / puree",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tomato, paste / puree",
    "weight-g": 44,
    "volume-ml": null,
    "calories": 30,
    "fat": 0.2,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 280,
    "carbs": 4.6,
    "fibre": 1.8,
    "sugar": 4.4,
    "protein": 1.4,
    "potassium": 426,
    "calcium": 12,
    "iron": 0.8,
    "phosphorus": 30,
    "info": null
  },
  {
    "id": 631,
    "disabled": null,
    "type": "meal",
    "name": "Tomatoes, raw",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tomato",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tomatoes, raw",
    "weight-g": 111,
    "volume-ml": null,
    "calories": 18,
    "fat": 0.2,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 47,
    "carbs": 3.5,
    "fibre": 1,
    "sugar": null,
    "protein": 1.3,
    "potassium": 235,
    "calcium": 6,
    "iron": 0.5,
    "phosphorus": 32,
    "info": null
  },
  {
    "id": 144,
    "disabled": null,
    "type": "meal",
    "name": "Tonkatsu",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Japanese deep fried pork cutlet",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tonkatsu japanese deep fried pork cutlet",
    "weight-g": 89,
    "volume-ml": null,
    "calories": 311.5,
    "fat": 22.3,
    "saturated": 9.1,
    "polyunsaturated": 2.3,
    "monounsaturated": 9.8,
    "trans": null,
    "cholesterol": 43,
    "sodium": 241,
    "carbs": 10.8,
    "fibre": 0,
    "sugar": 0.2,
    "protein": 16.9,
    "potassium": 259,
    "calcium": 26,
    "iron": 1.3,
    "phosphorus": 174,
    "info": "Japanese deep fried pork cutlet"
  },
  {
    "id": 854,
    "disabled": null,
    "type": "meal",
    "name": "Tonkotsu ramen with pork",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tonkotsu ramen with pork",
    "weight-g": 500,
    "volume-ml": null,
    "calories": 552,
    "fat": 20,
    "saturated": 10,
    "polyunsaturated": 6,
    "monounsaturated": 4,
    "trans": null,
    "cholesterol": 100,
    "sodium": 520,
    "carbs": 75,
    "fibre": 2,
    "sugar": 8,
    "protein": 18,
    "potassium": 75,
    "calcium": 40,
    "iron": 1.2,
    "phosphorus": 63,
    "info": null
  },
  {
    "id": 858,
    "disabled": null,
    "type": "meal",
    "name": "Tori katsu",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Japanese deep fried chicken cutlet",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tori katsu japanese deep fried chicken cutlet",
    "weight-g": 137,
    "volume-ml": null,
    "calories": 332,
    "fat": 11,
    "saturated": 2,
    "polyunsaturated": 2.5,
    "monounsaturated": 5.9,
    "trans": null,
    "cholesterol": 125,
    "sodium": 281,
    "carbs": 23,
    "fibre": 1.3,
    "sugar": 1.7,
    "protein": 32,
    "potassium": 297,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "Japanese deep fried chicken cutlet"
  },
  {
    "id": 1051,
    "disabled": null,
    "type": "snack",
    "name": "Tortilla chips",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 14,
    "unit": "piece",
    "info-translation": null,
    "info-brands": "Doritos",
    "info-examples": "nacho cheese, smoking BBQ",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tortilla chips doritos nacho cheese, smoking bbq",
    "weight-g": 33,
    "volume-ml": null,
    "calories": 168,
    "fat": 7.9,
    "saturated": 4,
    "polyunsaturated": 2,
    "monounsaturated": 1.9,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 282,
    "carbs": 21.8,
    "fibre": 0.5,
    "sugar": 3,
    "protein": 2.3,
    "potassium": 59,
    "calcium": 48,
    "iron": 0.6,
    "phosphorus": 55,
    "info": "e.g. Doritos, nacho cheese, smoking BBQ"
  },
  {
    "id": 194,
    "disabled": null,
    "type": "snack",
    "name": "Traditional ice cream with bread",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "traditional ice cream with bread",
    "weight-g": 128,
    "volume-ml": null,
    "calories": 283,
    "fat": 12,
    "saturated": 7,
    "polyunsaturated": 0.7,
    "monounsaturated": 3.3,
    "trans": null,
    "cholesterol": 34,
    "sodium": 210,
    "carbs": 40,
    "fibre": 3.8,
    "sugar": 27,
    "protein": 6.8,
    "potassium": 285,
    "calcium": 301,
    "iron": 2.3,
    "phosphorus": 136,
    "info": null
  },
  {
    "id": 212,
    "disabled": null,
    "type": "snack",
    "name": "Traditional ice cream with wafer",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "traditional ice cream with wafer",
    "weight-g": 129,
    "volume-ml": null,
    "calories": 337,
    "fat": 13,
    "saturated": 7.2,
    "polyunsaturated": 1.3,
    "monounsaturated": 3.7,
    "trans": null,
    "cholesterol": 34,
    "sodium": 150,
    "carbs": 51.1,
    "fibre": 2.1,
    "sugar": 27.1,
    "protein": 6.2,
    "potassium": 277,
    "calcium": 116,
    "iron": 1.9,
    "phosphorus": 135,
    "info": null
  },
  {
    "id": 46,
    "disabled": null,
    "type": "snack",
    "name": "Traditional waffle with filling",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "peanut butter, chocolate, kaya,  jam",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "traditional waffle with filling peanut butter, chocolate, kaya,  jam",
    "weight-g": 125,
    "volume-ml": null,
    "calories": 499.9,
    "fat": 27.1,
    "saturated": 7.2,
    "polyunsaturated": 7.4,
    "monounsaturated": 11,
    "trans": null,
    "cholesterol": 53,
    "sodium": 408,
    "carbs": 55,
    "fibre": 1.2,
    "sugar": 36.9,
    "protein": 9,
    "potassium": 314,
    "calcium": 256,
    "iron": 2.8,
    "phosphorus": 233,
    "info": "e.g. peanut butter, chocolate, kaya,  jam"
  },
  {
    "id": 685,
    "disabled": null,
    "type": "meal",
    "name": "Turnip, boiled / steamed",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "turnip, boiled / steamed",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 31,
    "fat": 0,
    "saturated": 0.1,
    "polyunsaturated": 0.2,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 255,
    "carbs": 4.7,
    "fibre": 3.1,
    "sugar": 1,
    "protein": 3,
    "potassium": 216,
    "calcium": 128,
    "iron": 1.8,
    "phosphorus": 32,
    "info": null
  },
  {
    "id": 219,
    "disabled": null,
    "type": "snack",
    "name": "Tutu kueh, coconut",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tutu [kueh|kuih|kue], coconut",
    "weight-g": 20,
    "volume-ml": null,
    "calories": 55.9,
    "fat": 1.1,
    "saturated": 1,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1,
    "carbs": 10.7,
    "fibre": 0.3,
    "sugar": 4.5,
    "protein": 0.8,
    "potassium": null,
    "calcium": 1,
    "iron": 0.1,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 220,
    "disabled": null,
    "type": "snack",
    "name": "Tutu kueh, peanut",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tutu [kueh|kuih|kue], peanut",
    "weight-g": 18,
    "volume-ml": null,
    "calories": 57.3,
    "fat": 1.3,
    "saturated": 0.3,
    "polyunsaturated": 0.5,
    "monounsaturated": 0.5,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1,
    "carbs": 10,
    "fibre": 0.3,
    "sugar": 4.5,
    "protein": 1.4,
    "potassium": null,
    "calcium": 2,
    "iron": 0.1,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1049,
    "disabled": null,
    "type": "snack",
    "name": "Twisties",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "minipack",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "chicken, BBQ curry, cheddar cheese flavours",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "twisties chicken, bbq curry, cheddar cheese flavours",
    "weight-g": 15,
    "volume-ml": null,
    "calories": 80,
    "fat": 4.4,
    "saturated": 3,
    "polyunsaturated": 0.7,
    "monounsaturated": 0.7,
    "trans": null,
    "cholesterol": null,
    "sodium": 93,
    "carbs": 9.2,
    "fibre": null,
    "sugar": 1,
    "protein": 1,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. chicken, BBQ curry, cheddar cheese flavours"
  },
  {
    "id": 262,
    "disabled": null,
    "type": "meal",
    "name": "Udang pedas nenas",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Pineapple prawn curry",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "16.5cm bowl",
    "info-include-exclude": null,
    "search-text": "udang pedas nenas pineapple prawn curry",
    "weight-g": 176,
    "volume-ml": null,
    "calories": 121.3,
    "fat": 9.9,
    "saturated": 4.9,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 47,
    "sodium": 469,
    "carbs": 2.8,
    "fibre": 1.5,
    "sugar": null,
    "protein": 5.1,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "Pineapple prawn curry (16.5cm bowl)"
  },
  {
    "id": 145,
    "disabled": null,
    "type": "meal",
    "name": "Udon with tempura",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "udon with tempura",
    "weight-g": 616,
    "volume-ml": null,
    "calories": 576.6,
    "fat": 29,
    "saturated": 12.2,
    "polyunsaturated": 4.2,
    "monounsaturated": 11,
    "trans": null,
    "cholesterol": 43,
    "sodium": 1830,
    "carbs": 63.5,
    "fibre": 5.5,
    "sugar": 7.4,
    "protein": 15.4,
    "potassium": 333,
    "calcium": 111,
    "iron": 3.1,
    "phosphorus": 129,
    "info": null
  },
  {
    "id": 335,
    "disabled": null,
    "type": "meal",
    "name": "Unagi don",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Japanese marinated eel with rice",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "unagi don japanese marinated eel with rice",
    "weight-g": 344,
    "volume-ml": null,
    "calories": 627,
    "fat": 18.6,
    "saturated": 6.1,
    "polyunsaturated": 0.7,
    "monounsaturated": 9.8,
    "trans": null,
    "cholesterol": 120,
    "sodium": 925,
    "carbs": 91.2,
    "fibre": 5.2,
    "sugar": 6.2,
    "protein": 23.7,
    "potassium": 248,
    "calcium": 151,
    "iron": 0.7,
    "phosphorus": 230,
    "info": "Japanese marinated eel with rice"
  },
  {
    "id": 521,
    "disabled": null,
    "type": "meal",
    "name": "Uttapam",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Indian thick pancake",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "uttapam indian thick pancake",
    "weight-g": 110,
    "volume-ml": null,
    "calories": 262.4,
    "fat": 5.2,
    "saturated": 1.2,
    "polyunsaturated": 0.7,
    "monounsaturated": 2.8,
    "trans": null,
    "cholesterol": 4,
    "sodium": 281,
    "carbs": 43.8,
    "fibre": 4,
    "sugar": 5.5,
    "protein": 10.1,
    "potassium": 537,
    "calcium": 142,
    "iron": 1.4,
    "phosphorus": 178,
    "info": "Indian thick pancake"
  },
  {
    "id": 436,
    "disabled": 1,
    "type": "meal",
    "name": "Vegetable (purple / brown e.g. beetroot, radish)",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": null,
    "unit": null,
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "vegetable (purple / brown e.g. beetroot, radish)",
    "weight-g": null,
    "volume-ml": null,
    "calories": null,
    "fat": null,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": null,
    "fibre": null,
    "sugar": null,
    "protein": null,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 437,
    "disabled": 1,
    "type": "meal",
    "name": "Vegetable (red / orange e.g. carrot)",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": null,
    "unit": null,
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "vegetable (red / orange e.g. carrot)",
    "weight-g": null,
    "volume-ml": null,
    "calories": null,
    "fat": null,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": null,
    "fibre": null,
    "sugar": null,
    "protein": null,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 506,
    "disabled": null,
    "type": "meal",
    "name": "Vongole",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Pasta with clams",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "vongole pasta with clams",
    "weight-g": 402,
    "volume-ml": null,
    "calories": 233,
    "fat": 6.4,
    "saturated": 2.7,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 844,
    "carbs": 35,
    "fibre": 4.5,
    "sugar": null,
    "protein": 9,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "Pasta with clams"
  },
  {
    "id": 609,
    "disabled": null,
    "type": "snack",
    "name": "Wafer crackers, plain",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 6,
    "unit": "piece",
    "info-translation": null,
    "info-brands": "Waterthins, Carr's",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "wafer crackers, plain waterthins, carr's",
    "weight-g": 10,
    "volume-ml": null,
    "calories": 39.8,
    "fat": 0.6,
    "saturated": 0.3,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 61,
    "carbs": 7.7,
    "fibre": 0,
    "sugar": 0.3,
    "protein": 0.9,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Waterthins, Carr's"
  },
  {
    "id": 45,
    "disabled": null,
    "type": "snack",
    "name": "Waffle with ice cream with syrup",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "waffle",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "waffle with ice cream syrup",
    "weight-g": 270,
    "volume-ml": null,
    "calories": 758.7,
    "fat": 49.5,
    "saturated": 27.2,
    "polyunsaturated": 3.8,
    "monounsaturated": 16.7,
    "trans": null,
    "cholesterol": 176,
    "sodium": 482,
    "carbs": 69.1,
    "fibre": 1,
    "sugar": 41.1,
    "protein": 9.2,
    "potassium": 194,
    "calcium": 86,
    "iron": 2.9,
    "phosphorus": 258,
    "info": null
  },
  {
    "id": 993,
    "disabled": null,
    "type": "snack",
    "name": "Waffle with ice cream, without syrup",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "waffle",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "waffle with ice cream, without syrup",
    "weight-g": 275,
    "volume-ml": null,
    "calories": 455.2,
    "fat": 20.8,
    "saturated": 10,
    "polyunsaturated": 2.4,
    "monounsaturated": 7.3,
    "trans": null,
    "cholesterol": 44,
    "sodium": 152,
    "carbs": 58.8,
    "fibre": 2,
    "sugar": 27.2,
    "protein": 8.2,
    "potassium": 222,
    "calcium": 95,
    "iron": 1,
    "phosphorus": 164,
    "info": null
  },
  {
    "id": 228,
    "disabled": null,
    "type": "snack",
    "name": "Waffles, plain, without syrup",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "waffle",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "waffles, plain, without syrup",
    "weight-g": 95,
    "volume-ml": null,
    "calories": 353.1,
    "fat": 15.1,
    "saturated": 5.9,
    "polyunsaturated": 1.9,
    "monounsaturated": 6.3,
    "trans": null,
    "cholesterol": 32,
    "sodium": 121,
    "carbs": 47.9,
    "fibre": 1.7,
    "sugar": 16.3,
    "protein": 6.4,
    "potassium": 136,
    "calcium": 35,
    "iron": 0.9,
    "phosphorus": 114,
    "info": null
  },
  {
    "id": 534,
    "disabled": null,
    "type": "snack",
    "name": "Waffles, plain with syrup",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "waffle",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "waffles, plain with syrup",
    "weight-g": 170,
    "volume-ml": null,
    "calories": 545.9,
    "fat": 33.5,
    "saturated": 16.2,
    "polyunsaturated": 3.3,
    "monounsaturated": 12.2,
    "trans": null,
    "cholesterol": 96,
    "sodium": 437,
    "carbs": 54.9,
    "fibre": 1,
    "sugar": 22.1,
    "protein": 6.2,
    "potassium": 194,
    "calcium": 86,
    "iron": 2.9,
    "phosphorus": 258,
    "info": null
  },
  {
    "id": 1024,
    "disabled": null,
    "type": "snack",
    "name": "Walnut cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "walnut cake",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 333,
    "fat": 8.3,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 56,
    "sodium": 167,
    "carbs": 55.6,
    "fibre": 0,
    "sugar": 27.8,
    "protein": 11.1,
    "potassium": 111,
    "calcium": 56,
    "iron": 0.6,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 189,
    "disabled": null,
    "type": "snack",
    "name": "Walnut paste",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "He Tao Hu",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "walnut paste [he|hup] [tao|tul] [hu|wu|woo]",
    "weight-g": 259,
    "volume-ml": null,
    "calories": 321.9,
    "fat": 21.9,
    "saturated": 2.4,
    "polyunsaturated": 15,
    "monounsaturated": 4.5,
    "trans": null,
    "cholesterol": null,
    "sodium": 9,
    "carbs": 24.1,
    "fibre": 3.1,
    "sugar": 16.1,
    "protein": 7,
    "potassium": null,
    "calcium": 35,
    "iron": 1.2,
    "phosphorus": null,
    "info": "He Tao Hu"
  },
  {
    "id": 331,
    "disabled": null,
    "type": "meal",
    "name": "Wanton noodle / mian, dry",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[wanton|yuntun] noodle / [mian|mee], dry",
    "weight-g": 350,
    "volume-ml": null,
    "calories": 431.4,
    "fat": 12.6,
    "saturated": 5.3,
    "polyunsaturated": 2.1,
    "monounsaturated": 4.6,
    "trans": null,
    "cholesterol": 39,
    "sodium": 1593,
    "carbs": 58.8,
    "fibre": 5.6,
    "sugar": 10.6,
    "protein": 20.7,
    "potassium": 250,
    "calcium": 42,
    "iron": 2.5,
    "phosphorus": 150,
    "info": null
  },
  {
    "id": 344,
    "disabled": null,
    "type": "meal",
    "name": "Wanton noodle / mian, soup",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[wanton|yuntun] noodle / [mian|mee], soup",
    "weight-g": 600,
    "volume-ml": null,
    "calories": 318,
    "fat": 5.4,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 12,
    "sodium": 1986,
    "carbs": 45.6,
    "fibre": 1.8,
    "sugar": 4.6,
    "protein": 21,
    "potassium": null,
    "calcium": 48,
    "iron": 1.8,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 758,
    "disabled": null,
    "type": "meal",
    "name": "Wanton with vinegar and chilli oil",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 4,
    "unit": "piece",
    "info-translation": "Hong You Chao Shou",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[wanton|yuntun] with vinegar and chilli oil hong you chao shou",
    "weight-g": 114,
    "volume-ml": null,
    "calories": 259.9,
    "fat": 19.1,
    "saturated": 4.4,
    "polyunsaturated": 2.2,
    "monounsaturated": 5.8,
    "trans": null,
    "cholesterol": 36,
    "sodium": 1318,
    "carbs": 10.9,
    "fibre": 0,
    "sugar": 2.7,
    "protein": 11.1,
    "potassium": 172,
    "calcium": 16,
    "iron": 0.7,
    "phosphorus": 31,
    "info": "Hong You Chao Shou"
  },
  {
    "id": 360,
    "disabled": 1,
    "type": "drink",
    "name": "Water (flavoured, unsweetened)",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": null,
    "unit": null,
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "water (flavoured, unsweetened)",
    "weight-g": null,
    "volume-ml": null,
    "calories": null,
    "fat": null,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": null,
    "fibre": null,
    "sugar": null,
    "protein": null,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 404,
    "disabled": null,
    "type": "snack",
    "name": "Water apple",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "fruit",
    "info-translation": "Jambu / rose apple / wax apple",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "water apple jambu / rose wax",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 22.6,
    "fat": 0.2,
    "saturated": 0.1,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1,
    "carbs": 4.5,
    "fibre": 0.2,
    "sugar": 4.5,
    "protein": 0.7,
    "potassium": 38,
    "calcium": 13,
    "iron": 0.8,
    "phosphorus": 5,
    "info": "Jambu / rose apple / wax apple"
  },
  {
    "id": 698,
    "disabled": null,
    "type": "meal",
    "name": "Water chestnut",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 10,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "water chestnut",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 78,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 12,
    "carbs": 19.2,
    "fibre": 3.9,
    "sugar": 3.8,
    "protein": 1.4,
    "potassium": 184,
    "calcium": 6,
    "iron": 1.4,
    "phosphorus": 30,
    "info": null
  },
  {
    "id": 915,
    "disabled": null,
    "type": "meal",
    "name": "Water chestnut cake, steamed",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Ma Tai Gao",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "water chestnut cake, steamed ma [tai|ti] [gao|go]",
    "weight-g": 89,
    "volume-ml": null,
    "calories": 86.9,
    "fat": 0.1,
    "saturated": null,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 5,
    "carbs": 21.4,
    "fibre": 1.6,
    "sugar": 8.9,
    "protein": 0.1,
    "potassium": 52,
    "calcium": 1,
    "iron": 0.2,
    "phosphorus": 6,
    "info": "Ma Tai Gao"
  },
  {
    "id": 174,
    "disabled": null,
    "type": "drink",
    "name": "Water chestnut drink",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": "Ma Tai Shui",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "water chestnut drink ma [tai|ti] shui",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 95.2,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 40,
    "carbs": 23.5,
    "fibre": null,
    "sugar": 23.5,
    "protein": 0.3,
    "potassium": null,
    "calcium": null,
    "iron": 0,
    "phosphorus": 53,
    "info": "Ma Tai Shui"
  },
  {
    "id": 1005,
    "disabled": null,
    "type": "snack",
    "name": "Water chestnut with sweet corn dessert",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "water chestnut with sweet corn dessert",
    "weight-g": 250,
    "volume-ml": null,
    "calories": 360,
    "fat": 4,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 271,
    "carbs": 76,
    "fibre": 4,
    "sugar": 44,
    "protein": 5,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 369,
    "disabled": null,
    "type": "drink",
    "name": "Water kefir",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": 1,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "water kefir",
    "weight-g": null,
    "volume-ml": 100,
    "calories": 12,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 3,
    "carbs": 3,
    "fibre": 0,
    "sugar": 3,
    "protein": 0,
    "potassium": 5,
    "calcium": 0,
    "iron": 0,
    "phosphorus": 0,
    "info": null
  },
  {
    "id": 658,
    "disabled": null,
    "type": "meal",
    "name": "Watercress",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": "Agriao, Berro, Cresson",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "watercress agriao, berro, cresson",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 13.9,
    "fat": 0,
    "saturated": 1.1,
    "polyunsaturated": 0.4,
    "monounsaturated": 0.6,
    "trans": null,
    "cholesterol": 0,
    "sodium": 284,
    "carbs": 1.3,
    "fibre": 0.5,
    "sugar": 0.2,
    "protein": 2.2,
    "potassium": 319,
    "calcium": 116,
    "iron": 0.2,
    "phosphorus": 58,
    "info": "Agriao, Berro, Cresson"
  },
  {
    "id": 405,
    "disabled": null,
    "type": "snack",
    "name": "Watermelon",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "watermelon",
    "weight-g": 140,
    "volume-ml": null,
    "calories": 41.6,
    "fat": 0.4,
    "saturated": 0,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 6,
    "carbs": 8.4,
    "fibre": 0.3,
    "sugar": 7.4,
    "protein": 1.1,
    "potassium": 109,
    "calcium": 8,
    "iron": 0.3,
    "phosphorus": 15,
    "info": null
  },
  {
    "id": 218,
    "disabled": null,
    "type": "snack",
    "name": "Wheat porridge soup",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Mai Zhou, Bubur Gandum",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "wheat porridge soup mai zhou, [bubur|bubor|bobo] gandum",
    "weight-g": 250,
    "volume-ml": null,
    "calories": 233,
    "fat": 2.8,
    "saturated": 2.5,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 49.3,
    "fibre": 2.3,
    "sugar": 24.5,
    "protein": 2.5,
    "potassium": null,
    "calcium": 20,
    "iron": 0.8,
    "phosphorus": null,
    "info": "Mai Zhou, Bubur Gandum"
  },
  {
    "id": 1009,
    "disabled": null,
    "type": "snack",
    "name": "White fungus soup",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with papaya, longan or lotus seed",
    "search-text": "white fungus soup",
    "weight-g": 240,
    "volume-ml": null,
    "calories": 134,
    "fat": 1.2,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 22,
    "carbs": 30.7,
    "fibre": 1.2,
    "sugar": 28.1,
    "protein": 0.5,
    "potassium": 130,
    "calcium": 26,
    "iron": 0.2,
    "phosphorus": 14,
    "info": "with papaya, longan or lotus seed"
  },
  {
    "id": 135,
    "disabled": null,
    "type": "meal",
    "name": "Rice, white",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "rice, white",
    "weight-g": 180,
    "volume-ml": null,
    "calories": 252,
    "fat": 0.9,
    "saturated": 0.2,
    "polyunsaturated": 0.4,
    "monounsaturated": 0.2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 9,
    "carbs": 56,
    "fibre": 1.8,
    "sugar": 0.2,
    "protein": 5,
    "potassium": 18,
    "calcium": 0,
    "iron": 4.1,
    "phosphorus": 20,
    "info": null
  },
  {
    "id": 588,
    "disabled": null,
    "type": "meal",
    "name": "Whole grain cereal slice",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 2,
    "unit": "piece",
    "info-translation": null,
    "info-brands": "Weet-Bix",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "whole grain cereal slice weet-bix",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 70,
    "fat": 0.3,
    "saturated": 0.1,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 56.7,
    "carbs": 13.4,
    "fibre": 2.1,
    "sugar": 0.5,
    "protein": 2.4,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Weet-Bix"
  },
  {
    "id": 31,
    "disabled": null,
    "type": "snack",
    "name": "Whole peanut soup with Tang Yuan",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "whole peanut soup with [tang|tong] [yuan|yuen]",
    "weight-g": 250,
    "volume-ml": null,
    "calories": 444,
    "fat": 24.4,
    "saturated": 11.1,
    "polyunsaturated": 3.7,
    "monounsaturated": 8.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 25,
    "carbs": 44.4,
    "fibre": 6.8,
    "sugar": 22.5,
    "protein": 11.7,
    "potassium": 167,
    "calcium": 19,
    "iron": 3.5,
    "phosphorus": 111,
    "info": null
  },
  {
    "id": 216,
    "disabled": null,
    "type": "snack",
    "name": "Whole peanut soup, without Tang Yuan",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "whole peanut soup, without [tang|tong] [yuan|yuen]",
    "weight-g": 202,
    "volume-ml": null,
    "calories": 254.8,
    "fat": 18.4,
    "saturated": 4,
    "polyunsaturated": 5.9,
    "monounsaturated": 7.9,
    "trans": null,
    "cholesterol": 0,
    "sodium": 48,
    "carbs": 15.6,
    "fibre": 4,
    "sugar": 14.3,
    "protein": 6.7,
    "potassium": 149,
    "calcium": 28,
    "iron": 0.4,
    "phosphorus": 105,
    "info": null
  },
  {
    "id": 611,
    "disabled": null,
    "type": "meal",
    "name": "Wholemeal sandwich",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": 1,
    "whole-grain": 1,
    "serving": 1,
    "unit": "sandwich",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "chicken, tuna, egg, cheese, ham",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "wholemeal sandwich chicken, tuna, egg, cheese, ham",
    "weight-g": 124,
    "volume-ml": null,
    "calories": 305.5,
    "fat": 21.7,
    "saturated": 3.7,
    "polyunsaturated": 3.2,
    "monounsaturated": 3.5,
    "trans": null,
    "cholesterol": 125.5,
    "sodium": 671.5,
    "carbs": 14,
    "fibre": 2.4,
    "sugar": 2.2,
    "protein": 13.6,
    "potassium": 170,
    "calcium": 108,
    "iron": 2.2,
    "phosphorus": 164.5,
    "info": "e.g. chicken, tuna, egg, cheese, ham"
  },
  {
    "id": 593,
    "disabled": null,
    "type": "meal",
    "name": "Wholemeal wrap with fillings",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "wrap",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "chicken, tuna, egg, cheese, ham",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "wholemeal wrap with fillings chicken, tuna, egg, cheese, ham",
    "weight-g": 117,
    "volume-ml": null,
    "calories": 380.9,
    "fat": 22.9,
    "saturated": 11.8,
    "polyunsaturated": 1.1,
    "monounsaturated": 7,
    "trans": null,
    "cholesterol": 60,
    "sodium": 884,
    "carbs": 24.6,
    "fibre": 1.5,
    "sugar": 2.3,
    "protein": 19.1,
    "potassium": 86,
    "calcium": 286,
    "iron": 0.4,
    "phosphorus": 224,
    "info": "e.g. chicken, tuna, egg, cheese, ham"
  },
  {
    "id": 672,
    "disabled": null,
    "type": "meal",
    "name": "Salad / garden rocket",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": "Arugula",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "salad / garden rocket arugula",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 12,
    "fat": 0.4,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 4,
    "fibre": 2,
    "sugar": 2,
    "protein": 3,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "Arugula"
  },
  {
    "id": 6,
    "disabled": null,
    "type": "drink",
    "name": "Wine, dessert",
    "rating": 1,
    "alcohol": 1,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "glass",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "wine, dessert",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 140,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 9.3,
    "carbs": 14,
    "fibre": 0,
    "sugar": 14,
    "protein": 1,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 160,
    "disabled": null,
    "type": "drink",
    "name": "Wine, red",
    "rating": 2,
    "alcohol": 1,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "glass",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "wine, red",
    "weight-g": null,
    "volume-ml": 148,
    "calories": 82,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 5.9,
    "carbs": 3.8,
    "fibre": 0,
    "sugar": 0.9,
    "protein": 0.1,
    "potassium": 187,
    "calcium": 11.8,
    "iron": 0.7,
    "phosphorus": 33.8,
    "info": null
  },
  {
    "id": 5,
    "disabled": null,
    "type": "drink",
    "name": "Wine, white",
    "rating": 2,
    "alcohol": 1,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "glass",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "wine, white",
    "weight-g": null,
    "volume-ml": 120,
    "calories": 79,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 29,
    "carbs": 1.3,
    "fibre": 0,
    "sugar": 1.3,
    "protein": 0.2,
    "potassium": 55,
    "calcium": 8,
    "iron": 0.2,
    "phosphorus": 23,
    "info": null
  },
  {
    "id": 680,
    "disabled": null,
    "type": "meal",
    "name": "Winter melon",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": "Wax gourd, Dong Gua",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "winter melon wax gourd, dong gua",
    "weight-g": 150,
    "volume-ml": null,
    "calories": 81,
    "fat": 6.5,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 327,
    "carbs": 3,
    "fibre": 1,
    "sugar": 1.2,
    "protein": 0.4,
    "potassium": 5,
    "calcium": 18,
    "iron": 0.4,
    "phosphorus": 17,
    "info": "Wax gourd, Dong Gua"
  },
  {
    "id": 815,
    "disabled": null,
    "type": "meal",
    "name": "Wrap with meat",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "wrap",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "wrap with meat",
    "weight-g": 227,
    "volume-ml": null,
    "calories": 405,
    "fat": 18,
    "saturated": 5.2,
    "polyunsaturated": 7,
    "monounsaturated": 6,
    "trans": 0.2,
    "cholesterol": 82,
    "sodium": 1050,
    "carbs": 37,
    "fibre": 3,
    "sugar": 0,
    "protein": 24,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 677,
    "disabled": 1,
    "type": "meal",
    "name": "Xiao bai cai, stir-fried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "xiao bai cai, stir-fried",
    "weight-g": 115,
    "volume-ml": null,
    "calories": 69,
    "fat": 4.5,
    "saturated": 0.7,
    "polyunsaturated": 0.8,
    "monounsaturated": 2.8,
    "trans": null,
    "cholesterol": 0,
    "sodium": 350,
    "carbs": 5.8,
    "fibre": 1.9,
    "sugar": 3.6,
    "protein": 2.5,
    "potassium": 300,
    "calcium": 110,
    "iron": 0.4,
    "phosphorus": 7,
    "info": null
  },
  {
    "id": 485,
    "disabled": null,
    "type": "meal",
    "name": "Xiao long bao",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 3,
    "unit": "piece",
    "info-translation": "Soup dumplings",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "xiao long bao soup dumplings",
    "weight-g": 63,
    "volume-ml": null,
    "calories": 207.6,
    "fat": 8.4,
    "saturated": 2.7,
    "polyunsaturated": 1.2,
    "monounsaturated": 6.9,
    "trans": null,
    "cholesterol": 30,
    "sodium": 420,
    "carbs": 22.5,
    "fibre": 1.5,
    "sugar": 0,
    "protein": 10.5,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "Soup dumplings"
  },
  {
    "id": 864,
    "disabled": null,
    "type": "meal",
    "name": "Yaki soba with meat",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": 1,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Japanese stir-fried noodles",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "yaki soba with meat japanese stir-fried noodles",
    "weight-g": 404,
    "volume-ml": null,
    "calories": 641,
    "fat": 18.8,
    "saturated": 6.6,
    "polyunsaturated": 1.5,
    "monounsaturated": 6.9,
    "trans": null,
    "cholesterol": 101,
    "sodium": 1043,
    "carbs": 70.6,
    "fibre": 4.4,
    "sugar": 7.9,
    "protein": 47.6,
    "potassium": 856,
    "calcium": 88,
    "iron": 6.6,
    "phosphorus": 160,
    "info": "Japanese stir-fried noodles"
  },
  {
    "id": 865,
    "disabled": null,
    "type": "meal",
    "name": "Yaki soba with seafood",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Japanese stir-fried noodles",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "yaki soba with seafood japanese stir-fried noodles",
    "weight-g": 404,
    "volume-ml": null,
    "calories": 547,
    "fat": 13.7,
    "saturated": 4.8,
    "polyunsaturated": 1.2,
    "monounsaturated": 4.2,
    "trans": null,
    "cholesterol": 216,
    "sodium": 1194,
    "carbs": 72.3,
    "fibre": 4.4,
    "sugar": 9.7,
    "protein": 33.1,
    "potassium": 743,
    "calcium": 163,
    "iron": 5.4,
    "phosphorus": 157,
    "info": "Japanese stir-fried noodles"
  },
  {
    "id": 866,
    "disabled": null,
    "type": "meal",
    "name": "Yaki soba with vegetables",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Japanese stir-fried noodles",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "yaki soba with vegetables japanese stir-fried noodles",
    "weight-g": 404,
    "volume-ml": null,
    "calories": 465,
    "fat": 13.1,
    "saturated": 4.5,
    "polyunsaturated": 1,
    "monounsaturated": 4.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1012,
    "carbs": 73.9,
    "fibre": 5.9,
    "sugar": 10.3,
    "protein": 14.4,
    "potassium": 776,
    "calcium": 140,
    "iron": 5.7,
    "phosphorus": 55,
    "info": "Japanese stir-fried noodles"
  },
  {
    "id": 332,
    "disabled": null,
    "type": "meal",
    "name": "Yakitori",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "stick",
    "info-translation": "Japanese skewered chicken",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "yakitori japanese skewered chicken",
    "weight-g": 60,
    "volume-ml": null,
    "calories": 142,
    "fat": 8,
    "saturated": 2.8,
    "polyunsaturated": 1.7,
    "monounsaturated": 3,
    "trans": null,
    "cholesterol": 47,
    "sodium": 313,
    "carbs": 6.2,
    "fibre": 0.3,
    "sugar": 2.6,
    "protein": 11.3,
    "potassium": 184,
    "calcium": 41,
    "iron": 0.7,
    "phosphorus": 158,
    "info": "Japanese skewered chicken"
  },
  {
    "id": 701,
    "disabled": null,
    "type": "meal",
    "name": "Yam",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "yam",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[yam|taro]",
    "weight-g": 136,
    "volume-ml": null,
    "calories": 158,
    "fat": 0.2,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 11,
    "carbs": 37.4,
    "fibre": 5.3,
    "sugar": 0.7,
    "protein": 2,
    "potassium": 911,
    "calcium": 19,
    "iron": 0.7,
    "phosphorus": 67,
    "info": null
  },
  {
    "id": 918,
    "disabled": null,
    "type": "meal",
    "name": "Yam pastry with minced meat filling, fried",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Yu Jiao / Wu Kok",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[yam|taro] pastry with minced meat filling, fried yu jiao / wu [kok|gok]",
    "weight-g": 114,
    "volume-ml": null,
    "calories": 459,
    "fat": 11.1,
    "saturated": 5.7,
    "polyunsaturated": 1,
    "monounsaturated": 3.9,
    "trans": null,
    "cholesterol": 10,
    "sodium": 601,
    "carbs": 26.2,
    "fibre": 3.3,
    "sugar": 0,
    "protein": 2.5,
    "potassium": null,
    "calcium": 24,
    "iron": 1,
    "phosphorus": null,
    "info": "Yu Jiao / Wu Kok"
  },
  {
    "id": 178,
    "disabled": null,
    "type": "drink",
    "name": "Yogurt drink, sweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": 1,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "glass",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[yogurt|yoghurt] drink, sweetened",
    "weight-g": null,
    "volume-ml": 200,
    "calories": 92,
    "fat": 0.8,
    "saturated": 0.5,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.2,
    "trans": null,
    "cholesterol": 4,
    "sodium": 74,
    "carbs": 20,
    "fibre": null,
    "sugar": 18,
    "protein": 1.2,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 206,
    "disabled": null,
    "type": "snack",
    "name": "Yogurt, flavoured",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tub",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[yogurt|yoghurt], flavoured",
    "weight-g": 170,
    "volume-ml": null,
    "calories": 125,
    "fat": 9.3,
    "saturated": 6.1,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 74,
    "carbs": 19.5,
    "fibre": null,
    "sugar": 18.2,
    "protein": 5.9,
    "potassium": null,
    "calcium": 227,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 375,
    "disabled": null,
    "type": "snack",
    "name": "Yogurt, plain, whole milk",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": 1,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tub",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[yogurt|yoghurt], plain, whole milk",
    "weight-g": 150,
    "volume-ml": null,
    "calories": 92,
    "fat": 5,
    "saturated": 3.2,
    "polyunsaturated": 0.1,
    "monounsaturated": 1.3,
    "trans": null,
    "cholesterol": 20,
    "sodium": 69,
    "carbs": 7,
    "fibre": 0,
    "sugar": 7,
    "protein": 5.2,
    "potassium": 233,
    "calcium": 182,
    "iron": 0.1,
    "phosphorus": 143,
    "info": null
  },
  {
    "id": 438,
    "disabled": null,
    "type": "meal",
    "name": "Yong tau foo only, without sauce",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 6,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "without rice / noodle",
    "search-text": "yong [tau|tou] [foo|fu|hu] only, without sauce",
    "weight-g": 198,
    "volume-ml": null,
    "calories": 210,
    "fat": 14.5,
    "saturated": 4.8,
    "polyunsaturated": 3.5,
    "monounsaturated": 4.6,
    "trans": null,
    "cholesterol": 3,
    "sodium": 195,
    "carbs": 5.6,
    "fibre": 2.3,
    "sugar": 2.2,
    "protein": 14.9,
    "potassium": 324,
    "calcium": 190,
    "iron": 4,
    "phosphorus": 212,
    "info": "without rice / noodle"
  },
  {
    "id": 996,
    "disabled": null,
    "type": "meal",
    "name": "Yong tau foo only with sauce",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 6,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "without rice / noodle",
    "search-text": "yong [tau|tou] [foo|fu|hu] only with sauce",
    "weight-g": 215,
    "volume-ml": null,
    "calories": 236,
    "fat": 15.2,
    "saturated": 4.8,
    "polyunsaturated": 3.5,
    "monounsaturated": 4.6,
    "trans": null,
    "cholesterol": 3,
    "sodium": 468,
    "carbs": 9.5,
    "fibre": 2.6,
    "sugar": 6.1,
    "protein": 15.3,
    "potassium": 327,
    "calcium": 198,
    "iron": 4.4,
    "phosphorus": 215,
    "info": "without rice / noodle"
  },
  {
    "id": 333,
    "disabled": null,
    "type": "meal",
    "name": "Yong tau foo, dry, without sauce",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with rice / noodle",
    "search-text": "yong [tau|tou] [foo|fu|hu], dry, without sauce",
    "weight-g": 313,
    "volume-ml": null,
    "calories": 351,
    "fat": 14.2,
    "saturated": 5.6,
    "polyunsaturated": 2,
    "monounsaturated": 5.2,
    "trans": null,
    "cholesterol": 8,
    "sodium": 292,
    "carbs": 46.8,
    "fibre": 3.6,
    "sugar": 2.5,
    "protein": 8.4,
    "potassium": 237,
    "calcium": 78,
    "iron": 2.4,
    "phosphorus": 137,
    "info": "with rice / noodle"
  },
  {
    "id": 926,
    "disabled": 1,
    "type": "meal",
    "name": "Yong tau foo, dry, without sauce",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 6,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "without rice / noodle",
    "search-text": "yong [tau|tou] [foo|fu|hu], dry, without sauce",
    "weight-g": 198,
    "volume-ml": null,
    "calories": 212.5,
    "fat": 14.5,
    "saturated": 4.8,
    "polyunsaturated": 3.5,
    "monounsaturated": 4.6,
    "trans": null,
    "cholesterol": 3,
    "sodium": 195,
    "carbs": 5.6,
    "fibre": 2.3,
    "sugar": 2.2,
    "protein": 14.9,
    "potassium": 324,
    "calcium": 190,
    "iron": 4,
    "phosphorus": 212,
    "info": "without rice / noodle"
  },
  {
    "id": 994,
    "disabled": null,
    "type": "meal",
    "name": "Yong tau foo, dry with sauce",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "5 items with rice / noodle",
    "search-text": "yong [tau|tou] [foo|fu|hu], dry with sauce",
    "weight-g": 346,
    "volume-ml": null,
    "calories": 397,
    "fat": 15.6,
    "saturated": 5.6,
    "polyunsaturated": 2,
    "monounsaturated": 5.2,
    "trans": null,
    "cholesterol": 8,
    "sodium": 788,
    "carbs": 54.6,
    "fibre": 4.2,
    "sugar": 10.3,
    "protein": 9.2,
    "potassium": 243,
    "calcium": 94,
    "iron": 3.2,
    "phosphorus": 143,
    "info": "5 items with rice / noodle"
  },
  {
    "id": 347,
    "disabled": null,
    "type": "meal",
    "name": "Yong tau foo, laksa",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "5 items",
    "search-text": "yong [tau|tou] [foo|fu|hu], laksa",
    "weight-g": 602,
    "volume-ml": null,
    "calories": 632.1,
    "fat": 37.3,
    "saturated": 23,
    "polyunsaturated": 4.8,
    "monounsaturated": 7.4,
    "trans": null,
    "cholesterol": 60,
    "sodium": 2215,
    "carbs": 50.6,
    "fibre": 3.6,
    "sugar": 3,
    "protein": 23.5,
    "potassium": 385,
    "calcium": 193,
    "iron": 3.6,
    "phosphorus": 259,
    "info": "5 items"
  },
  {
    "id": 995,
    "disabled": null,
    "type": "meal",
    "name": "Yong tau foo, soup with sauce",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "5 items with rice / noodle",
    "search-text": "yong [tau|tou] [foo|fu|hu], soup with sauce",
    "weight-g": 793,
    "volume-ml": null,
    "calories": 371,
    "fat": 14.2,
    "saturated": 5.6,
    "polyunsaturated": 2,
    "monounsaturated": 5.2,
    "trans": null,
    "cholesterol": 8,
    "sodium": 1890,
    "carbs": 46.8,
    "fibre": 3.6,
    "sugar": 2.5,
    "protein": 10.4,
    "potassium": 237,
    "calcium": 98,
    "iron": 2.4,
    "phosphorus": 137,
    "info": "5 items with rice / noodle"
  },
  {
    "id": 334,
    "disabled": null,
    "type": "meal",
    "name": "Yong tau foo, soup, without sauce",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with rice / noodle",
    "search-text": "yong [tau|tou] [foo|fu|hu], soup, without sauce",
    "weight-g": 689,
    "volume-ml": null,
    "calories": 344,
    "fat": 8,
    "saturated": 2.2,
    "polyunsaturated": 2.4,
    "monounsaturated": 2.2,
    "trans": null,
    "cholesterol": 12,
    "sodium": 1678,
    "carbs": 46.4,
    "fibre": 3.6,
    "sugar": 2.5,
    "protein": 17,
    "potassium": 136,
    "calcium": 130,
    "iron": 2.8,
    "phosphorus": 206,
    "info": "with rice / noodle"
  },
  {
    "id": 927,
    "disabled": null,
    "type": "meal",
    "name": "Yong tau foo, soup, without sauce",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 6,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "without rice / noodle",
    "search-text": "yong [tau|tou] [foo|fu|hu], soup, without sauce",
    "weight-g": 678,
    "volume-ml": null,
    "calories": 230,
    "fat": 7.7,
    "saturated": 4.8,
    "polyunsaturated": 3.5,
    "monounsaturated": 4.6,
    "trans": null,
    "cholesterol": null,
    "sodium": 1793,
    "carbs": 5.6,
    "fibre": 2.3,
    "sugar": 0,
    "protein": 16.9,
    "potassium": null,
    "calcium": 210,
    "iron": 4,
    "phosphorus": null,
    "info": "without rice / noodle"
  },
  {
    "id": 920,
    "disabled": null,
    "type": "meal",
    "name": "You tiao salad",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with fish paste filling",
    "search-text": "you tiao salad",
    "weight-g": 80,
    "volume-ml": null,
    "calories": 243.1,
    "fat": 15.1,
    "saturated": 6.8,
    "polyunsaturated": 2.7,
    "monounsaturated": 5,
    "trans": null,
    "cholesterol": 34,
    "sodium": 563,
    "carbs": 18.8,
    "fibre": 1.4,
    "sugar": 0,
    "protein": 8,
    "potassium": null,
    "calcium": 21,
    "iron": 1.3,
    "phosphorus": null,
    "info": "with fish paste filling"
  },
  {
    "id": 639,
    "disabled": null,
    "type": "meal",
    "name": "Zucchini / courgettes, baked",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "zucchini / courgettes, baked",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 87,
    "fat": 7.5,
    "saturated": 1.1,
    "polyunsaturated": 0.7,
    "monounsaturated": 5,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1,
    "carbs": 3.8,
    "fibre": 1.5,
    "sugar": 2.3,
    "protein": 1.2,
    "potassium": 255,
    "calcium": 24,
    "iron": 0.7,
    "phosphorus": 46,
    "info": null
  },
  {
    "id": 925,
    "disabled": null,
    "type": "meal",
    "name": "Dim sum (fried sesame ball with peanut filling)",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "dim sum (fried sesame ball with peanut filling)",
    "weight-g": 27,
    "volume-ml": null,
    "calories": 109,
    "fat": 6.2,
    "saturated": 3,
    "polyunsaturated": 0.3,
    "monounsaturated": 1.6,
    "trans": null,
    "cholesterol": 0,
    "sodium": 18,
    "carbs": 11.4,
    "fibre": 1.4,
    "sugar": 3.1,
    "protein": 1.9,
    "potassium": null,
    "calcium": 7,
    "iron": 0.3,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1097,
    "disabled": null,
    "type": "meal",
    "name": "Balsamic vinegar",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "balsamic vinegar",
    "weight-g": null,
    "volume-ml": 5,
    "calories": 4.7,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 1.2,
    "carbs": 0.9,
    "fibre": null,
    "sugar": 0.8,
    "protein": 0,
    "potassium": 5.9,
    "calcium": 1.4,
    "iron": 0,
    "phosphorus": 1,
    "info": null
  },
  {
    "id": 1098,
    "disabled": null,
    "type": "meal",
    "name": "Olive oil",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "olive oil",
    "weight-g": null,
    "volume-ml": 5,
    "calories": 39.8,
    "fat": 4.5,
    "saturated": 0.6,
    "polyunsaturated": 0.5,
    "monounsaturated": 3.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0.1,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 0,
    "potassium": 0.1,
    "calcium": 0.1,
    "iron": 0,
    "phosphorus": 0,
    "info": null
  },
  {
    "id": 1099,
    "disabled": null,
    "type": "meal",
    "name": "Spinach, raw",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "spinach, raw",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 6.9,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 23.7,
    "carbs": 1.1,
    "fibre": 0.7,
    "sugar": 0.1,
    "protein": 0.9,
    "potassium": 167,
    "calcium": 29.7,
    "iron": 0.8,
    "phosphorus": 14.7,
    "info": null
  },
  {
    "id": 1100,
    "disabled": null,
    "type": "snack",
    "name": "Yogurt, plain, low fat",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": 1,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tub",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[yogurt|yoghurt], plain, low fat",
    "weight-g": 150,
    "volume-ml": null,
    "calories": 95,
    "fat": 2.3,
    "saturated": 1.5,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.6,
    "trans": null,
    "cholesterol": 9,
    "sodium": 105,
    "carbs": 10.6,
    "fibre": 0,
    "sugar": 10.6,
    "protein": 7.9,
    "potassium": 351,
    "calcium": 275,
    "iron": 0.1,
    "phosphorus": 216,
    "info": null
  },
  {
    "id": 1101,
    "disabled": null,
    "type": "drink",
    "name": "Milk, low fat",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "fresh milk, UHT milk",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "milk, low fat fresh milk, uht milk",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 105,
    "fat": 2.3,
    "saturated": 1.4,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.5,
    "trans": null,
    "cholesterol": 12,
    "sodium": 95,
    "carbs": 12.7,
    "fibre": 0,
    "sugar": 12.1,
    "protein": 8.3,
    "potassium": 388,
    "calcium": 307,
    "iron": 0,
    "phosphorus": 251,
    "info": "e.g. fresh milk, UHT milk"
  },
  {
    "id": 1102,
    "disabled": null,
    "type": "drink",
    "name": "Milk, skim",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "fresh milk, UHT milk",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "milk, skim fresh milk, uht milk",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 83,
    "fat": 0.2,
    "saturated": 0.1,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 7.3,
    "sodium": 100,
    "carbs": 12,
    "fibre": 0,
    "sugar": 12.3,
    "protein": 8.4,
    "potassium": 407,
    "calcium": 322,
    "iron": 0,
    "phosphorus": 261,
    "info": "e.g. fresh milk, UHT milk"
  },
  {
    "id": 1103,
    "disabled": null,
    "type": "meal",
    "name": "Pasta, soup based with meat",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "spaghetti, linguine, macaroni",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pasta, soup based with meat spaghetti, linguine, macaroni",
    "weight-g": 483,
    "volume-ml": null,
    "calories": 198.6,
    "fat": 1.5,
    "saturated": 0.5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 29,
    "sodium": 1710,
    "carbs": 31,
    "fibre": 1,
    "sugar": null,
    "protein": 15,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. spaghetti, linguine, macaroni"
  },
  {
    "id": 1104,
    "disabled": null,
    "type": "meal",
    "name": "Rice, basmati / low GI / parboiled",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "rice, basmati / low gi parboiled",
    "weight-g": 180,
    "volume-ml": null,
    "calories": 239,
    "fat": 0.8,
    "saturated": 0.3,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 1,
    "carbs": 52.9,
    "fibre": 0.9,
    "sugar": 0.2,
    "protein": 5.3,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1105,
    "disabled": null,
    "type": "snack",
    "name": "Porridge with pork liver",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[porridge|congee] with pork liver",
    "weight-g": 546,
    "volume-ml": null,
    "calories": 360,
    "fat": 10.4,
    "saturated": 2.7,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 229,
    "sodium": 900,
    "carbs": 40,
    "fibre": 1.6,
    "sugar": null,
    "protein": 25.6,
    "potassium": null,
    "calcium": null,
    "iron": 11,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1107,
    "disabled": null,
    "type": "meal",
    "name": "Pho, with meat",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Vietnamese noodles soup",
    "info-brands": null,
    "info-examples": "beef, chicken",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pho, with meat vietnamese noodles soup beef, chicken",
    "weight-g": 650,
    "volume-ml": null,
    "calories": 429,
    "fat": 8,
    "saturated": 2,
    "polyunsaturated": 2,
    "monounsaturated": 3,
    "trans": null,
    "cholesterol": 85,
    "sodium": 1100,
    "carbs": 70,
    "fibre": 4,
    "sugar": 1,
    "protein": 24,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "Vietnamese noodles soup, e.g. beef, chicken"
  },
  {
    "id": 1108,
    "disabled": null,
    "type": "meal",
    "name": "Overnight oats with milk, unsweetened",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "overnight oats with milk, unsweetened",
    "weight-g": 295,
    "volume-ml": null,
    "calories": 310,
    "fat": 10,
    "saturated": 5,
    "polyunsaturated": 1.5,
    "monounsaturated": 3.5,
    "trans": 0.3,
    "cholesterol": 30,
    "sodium": 105,
    "carbs": 40,
    "fibre": 2.7,
    "sugar": 13,
    "protein": 13.6,
    "potassium": 529,
    "calcium": 345,
    "iron": 1.9,
    "phosphorus": 363,
    "info": null
  },
  {
    "id": 1109,
    "disabled": null,
    "type": "snack",
    "name": "Biscuit, high fibre",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 3,
    "unit": "pieces",
    "info-translation": null,
    "info-brands": "Wasa / Ryvita Crispbread",
    "info-examples": "rye, buckwheat",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "biscuit, high fibre wasa / ryvita crispbread rye, buckwheat",
    "weight-g": 33,
    "volume-ml": null,
    "calories": 110,
    "fat": 0.7,
    "saturated": 0,
    "polyunsaturated": 0.3,
    "monounsaturated": 0.3,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 250,
    "carbs": 20,
    "fibre": 5,
    "sugar": 1,
    "protein": 3,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Wasa / Ryvita Crispbread, rye, buckwheat"
  },
  {
    "id": 1110,
    "disabled": null,
    "type": "snack",
    "name": "Maternal milk",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": "Enfamama, Mamil, Anmum",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "maternal milk enfamama, mamil, anmum",
    "weight-g": 50,
    "volume-ml": null,
    "calories": 190,
    "fat": 2.3,
    "saturated": 1.8,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.5,
    "trans": 0.3,
    "cholesterol": null,
    "sodium": 180,
    "carbs": 30,
    "fibre": 1.5,
    "sugar": 15,
    "protein": 12,
    "potassium": 400,
    "calcium": 400,
    "iron": 5,
    "phosphorus": 310,
    "info": "e.g. Enfamama, Mamil, Anmum"
  },
  {
    "id": 1111,
    "disabled": null,
    "type": "snack",
    "name": "Protein shake, ready to drink",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bottle",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "generic",
    "search-text": "protein shake, ready to drink",
    "weight-g": 400,
    "volume-ml": null,
    "calories": 215,
    "fat": 5,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 12,
    "fibre": null,
    "sugar": 10,
    "protein": 29,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "generic"
  },
  {
    "id": 1112,
    "disabled": null,
    "type": "snack",
    "name": "Chocolate spread, sweetened",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": "Nutella",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chocolate spread, sweetened nutella",
    "weight-g": 19,
    "volume-ml": null,
    "calories": 102,
    "fat": 6,
    "saturated": 1.5,
    "polyunsaturated": 1.4,
    "monounsaturated": 3.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 9,
    "carbs": 10.9,
    "fibre": 0.7,
    "sugar": 10.8,
    "protein": 1.1,
    "potassium": 82,
    "calcium": 25,
    "iron": null,
    "phosphorus": 36,
    "info": "e.g. Nutella"
  },
  {
    "id": 1113,
    "disabled": null,
    "type": "snack",
    "name": "Soy beancurd, unsweetened",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Tau Huay",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "without sugar",
    "search-text": "soy beancurd, unsweetened [tau|dou] [huay|hua|huey]",
    "weight-g": 286,
    "volume-ml": null,
    "calories": 83,
    "fat": 4.5,
    "saturated": 0.7,
    "polyunsaturated": 2.8,
    "monounsaturated": 0.8,
    "trans": null,
    "cholesterol": 0,
    "sodium": 4,
    "carbs": 4,
    "fibre": 0.8,
    "sugar": 2,
    "protein": 6.7,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "Tau Huay – without sugar"
  },
  {
    "id": 1114,
    "disabled": null,
    "type": "snack",
    "name": "Spring roll, steamed",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "roll",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with meat or prawn",
    "search-text": "spring roll, steamed",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 170,
    "fat": 1.9,
    "saturated": 0.6,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.9,
    "trans": 0,
    "cholesterol": 32,
    "sodium": 111,
    "carbs": 24.8,
    "fibre": 1.1,
    "sugar": 2,
    "protein": 12.9,
    "potassium": 219,
    "calcium": 26.5,
    "iron": 1.3,
    "phosphorus": 60,
    "info": "with meat or prawn"
  },
  {
    "id": 1115,
    "disabled": null,
    "type": "meal",
    "name": "Vegetarian fried beehoon",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Rice vermicelli",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with bean sprouts and mock meat",
    "search-text": "vegetarian fried beehoon rice vermicelli",
    "weight-g": 362,
    "volume-ml": null,
    "calories": 549,
    "fat": 16.7,
    "saturated": 8,
    "polyunsaturated": 2.9,
    "monounsaturated": 4.7,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1539,
    "carbs": 82.9,
    "fibre": 8.3,
    "sugar": 14.7,
    "protein": 15.9,
    "potassium": 100,
    "calcium": 60,
    "iron": 3,
    "phosphorus": 70,
    "info": "Rice vermicelli – with bean sprouts and mock meat"
  },
  {
    "id": 1116,
    "disabled": null,
    "type": "meal",
    "name": "Shirataki noodles",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": "Konjac noodles",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "shirataki noodles [konjac|konnyaku]",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 5,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 3,
    "fibre": 2,
    "sugar": 0,
    "protein": 0,
    "potassium": 33,
    "calcium": 43,
    "iron": 0.4,
    "phosphorus": null,
    "info": "Konjac noodles"
  },
  {
    "id": 1117,
    "disabled": null,
    "type": "meal",
    "name": "Bread, with fruit",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "raisin, fig",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bread, with fruit raisin, fig",
    "weight-g": 40,
    "volume-ml": null,
    "calories": 113,
    "fat": 0.9,
    "saturated": 0.5,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.2,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 115,
    "carbs": 22.7,
    "fibre": 1.7,
    "sugar": 5.8,
    "protein": 3.5,
    "potassium": null,
    "calcium": 110,
    "iron": 2.1,
    "phosphorus": null,
    "info": "e.g. raisin, fig"
  },
  {
    "id": 1118,
    "disabled": null,
    "type": "snack",
    "name": "Chia seeds",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[chia|chai] seeds",
    "weight-g": 15,
    "volume-ml": null,
    "calories": 73,
    "fat": 4.6,
    "saturated": 0.5,
    "polyunsaturated": 3.6,
    "monounsaturated": 0.3,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 2,
    "carbs": 1.1,
    "fibre": 5.2,
    "sugar": 0,
    "protein": 2.5,
    "potassium": 61,
    "calcium": 95,
    "iron": 1.2,
    "phosphorus": 129,
    "info": null
  },
  {
    "id": 1119,
    "disabled": null,
    "type": "meal",
    "name": "Egg and tomato, stir fried",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "egg and tomato, stir fried",
    "weight-g": 99,
    "volume-ml": null,
    "calories": 130,
    "fat": 10.4,
    "saturated": 3.9,
    "polyunsaturated": 1,
    "monounsaturated": 4.3,
    "trans": 0,
    "cholesterol": 208,
    "sodium": 66,
    "carbs": 2.5,
    "fibre": 0.5,
    "sugar": 2.5,
    "protein": 7,
    "potassium": 101,
    "calcium": 26,
    "iron": 1.2,
    "phosphorus": 80,
    "info": null
  },
  {
    "id": 1120,
    "disabled": null,
    "type": "snack",
    "name": "Wafers, flavoured",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 3,
    "unit": "pieces",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "chocolate, strawberry",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "wafers, flavoured chocolate, strawberry",
    "weight-g": 27,
    "volume-ml": null,
    "calories": 127,
    "fat": 4.6,
    "saturated": 2,
    "polyunsaturated": 0.5,
    "monounsaturated": 1.9,
    "trans": 0,
    "cholesterol": null,
    "sodium": 69,
    "carbs": 20,
    "fibre": 1.1,
    "sugar": 10.8,
    "protein": 1.2,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. chocolate, strawberry"
  },
  {
    "id": 1121,
    "disabled": null,
    "type": "meal",
    "name": "Wholemeal pasta, plain",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "durum wheat, spaghetti, linguine, macaroni",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[wholemeal|wholewheat] pasta, plain durum wheat, spaghetti, linguine, macaroni",
    "weight-g": 140,
    "volume-ml": null,
    "calories": 283,
    "fat": 1.6,
    "saturated": 0.2,
    "polyunsaturated": 0.7,
    "monounsaturated": 0.7,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 56,
    "fibre": 3.2,
    "sugar": 1.6,
    "protein": 9.6,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. durum wheat, spaghetti, linguine, macaroni"
  },
  {
    "id": 1122,
    "disabled": null,
    "type": "meal",
    "name": "Beef / beef ball soup",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "beef / beef ball soup",
    "weight-g": 400,
    "volume-ml": null,
    "calories": 175,
    "fat": 8,
    "saturated": 3.6,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0.2,
    "cholesterol": 40,
    "sodium": 1584,
    "carbs": 7,
    "fibre": 1,
    "sugar": 2,
    "protein": 15,
    "potassium": 150,
    "calcium": 20,
    "iron": 0.5,
    "phosphorus": 34,
    "info": null
  },
  {
    "id": 1123,
    "disabled": null,
    "type": "meal",
    "name": "Wholemeal pasta, stir fried with meat and vegetables",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "without sauce",
    "search-text": "[wholemeal|wholewheat] pasta, stir fried with meat and vegetables",
    "weight-g": 265,
    "volume-ml": null,
    "calories": 454,
    "fat": 13.3,
    "saturated": 2.2,
    "polyunsaturated": 5.5,
    "monounsaturated": 4.4,
    "trans": 0,
    "cholesterol": 30,
    "sodium": 500,
    "carbs": 58.8,
    "fibre": 4.3,
    "sugar": 3.1,
    "protein": 24.5,
    "potassium": 283,
    "calcium": 40,
    "iron": 0.7,
    "phosphorus": 143,
    "info": "without sauce"
  },
  {
    "id": 1124,
    "disabled": null,
    "type": "meal",
    "name": "Toast with avocado",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "toast with avocado",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 250,
    "fat": 17.1,
    "saturated": 3.6,
    "polyunsaturated": 2.1,
    "monounsaturated": 10.1,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 461,
    "carbs": 22,
    "fibre": 6.6,
    "sugar": 1.3,
    "protein": 5.3,
    "potassium": 461,
    "calcium": 13,
    "iron": 1.3,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1125,
    "disabled": null,
    "type": "meal",
    "name": "Sandwich, grilled",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "panini",
    "info-portion-aid": null,
    "info-include-exclude": "with meat fillings and sauce",
    "search-text": "sandwich, grilled panini",
    "weight-g": 200,
    "volume-ml": null,
    "calories": 650,
    "fat": 25,
    "saturated": 14,
    "polyunsaturated": 1,
    "monounsaturated": 10,
    "trans": 0,
    "cholesterol": 95,
    "sodium": null,
    "carbs": 62,
    "fibre": 2,
    "sugar": 1,
    "protein": 41,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "with meat fillings and sauce, e.g. panini"
  },
  {
    "id": 1126,
    "disabled": null,
    "type": "meal",
    "name": "Confit",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "duck, lamb",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "confit duck, lamb",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 304,
    "fat": 17.9,
    "saturated": 7,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 170,
    "sodium": 250,
    "carbs": 1.8,
    "fibre": null,
    "sugar": null,
    "protein": 35.7,
    "potassium": null,
    "calcium": null,
    "iron": 3.2,
    "phosphorus": null,
    "info": "e.g. duck, lamb"
  },
  {
    "id": 1127,
    "disabled": null,
    "type": "meal",
    "name": "Shepherd's pie, without potato",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "cauliflower with meat",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "shepherd's pie, without potato cauliflower with meat",
    "weight-g": 283,
    "volume-ml": null,
    "calories": 260,
    "fat": 15,
    "saturated": 7,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 65,
    "sodium": 931,
    "carbs": 11,
    "fibre": 4,
    "sugar": 3,
    "protein": 19,
    "potassium": null,
    "calcium": 99,
    "iron": 1.8,
    "phosphorus": null,
    "info": "e.g. cauliflower with meat"
  },
  {
    "id": 1128,
    "disabled": null,
    "type": "snack",
    "name": "Biscuit stick, plain",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "packet",
    "info-translation": null,
    "info-brands": "Mitsuya Tasty, Pretz",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "biscuit stick, plain mitsuya tasty, pretz",
    "weight-g": 40,
    "volume-ml": null,
    "calories": 195,
    "fat": 8.2,
    "saturated": 2.5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 188,
    "carbs": 27.4,
    "fibre": null,
    "sugar": 10,
    "protein": 2.8,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Mitsuya Tasty, Pretz"
  },
  {
    "id": 1129,
    "disabled": null,
    "type": "meal",
    "name": "Tamago sushi",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tamago sushi",
    "weight-g": 39,
    "volume-ml": null,
    "calories": 63,
    "fat": 1.5,
    "saturated": 0.4,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.6,
    "trans": null,
    "cholesterol": 35,
    "sodium": 148.5,
    "carbs": 10.4,
    "fibre": 0.4,
    "sugar": 0.9,
    "protein": 2,
    "potassium": 24,
    "calcium": 8.5,
    "iron": 0,
    "phosphorus": 25,
    "info": null
  },
  {
    "id": 1130,
    "disabled": null,
    "type": "meal",
    "name": "Char siew",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": "Barbecued pork",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[char|cha] [siew|shao|siu] barbecued pork",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 254,
    "fat": 12.7,
    "saturated": 5.4,
    "polyunsaturated": 1.4,
    "monounsaturated": 4.7,
    "trans": null,
    "cholesterol": 97,
    "sodium": 476,
    "carbs": 6.2,
    "fibre": 0,
    "sugar": 6.2,
    "protein": 28.6,
    "potassium": null,
    "calcium": 13,
    "iron": 1.1,
    "phosphorus": null,
    "info": "Barbecued pork"
  },
  {
    "id": 1131,
    "disabled": null,
    "type": "meal",
    "name": "Sambal lala",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 10,
    "unit": "piece",
    "info-translation": "Sambal clams",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sambal lala clams",
    "weight-g": 50,
    "volume-ml": null,
    "calories": 59,
    "fat": 4,
    "saturated": 1.8,
    "polyunsaturated": 0.6,
    "monounsaturated": 1.6,
    "trans": 0,
    "cholesterol": 53,
    "sodium": 333,
    "carbs": 2,
    "fibre": 1,
    "sugar": 1.4,
    "protein": 4,
    "potassium": null,
    "calcium": 51,
    "iron": 2.1,
    "phosphorus": null,
    "info": "Sambal clams"
  },
  {
    "id": 1132,
    "disabled": null,
    "type": "meal",
    "name": "Bulgur",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bulgur",
    "weight-g": 265,
    "volume-ml": null,
    "calories": 261,
    "fat": 1.1,
    "saturated": 0.2,
    "polyunsaturated": 0.5,
    "monounsaturated": 1,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 27,
    "carbs": 54.6,
    "fibre": 9,
    "sugar": 0,
    "protein": 7.7,
    "potassium": 127,
    "calcium": 32,
    "iron": 2.6,
    "phosphorus": 191,
    "info": null
  },
  {
    "id": 1133,
    "disabled": null,
    "type": "snack",
    "name": "Blackberries",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 10,
    "unit": "fruit",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "blackberries",
    "weight-g": 50,
    "volume-ml": null,
    "calories": 21.5,
    "fat": 0.3,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 0.5,
    "carbs": 4.8,
    "fibre": 2.7,
    "sugar": 2.4,
    "protein": 0.7,
    "potassium": 81,
    "calcium": 14.5,
    "iron": 0.3,
    "phosphorus": 11,
    "info": null
  },
  {
    "id": 1134,
    "disabled": null,
    "type": "meal",
    "name": "Hot cross bun",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "hot cross bun",
    "weight-g": 59,
    "volume-ml": null,
    "calories": 200,
    "fat": 5,
    "saturated": 2.5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": null,
    "sodium": 125,
    "carbs": 35,
    "fibre": 2,
    "sugar": 16,
    "protein": 3,
    "potassium": null,
    "calcium": 40,
    "iron": 1.1,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1135,
    "disabled": null,
    "type": "meal",
    "name": "Baked beans",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": 1,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "canned",
    "search-text": "baked beans",
    "weight-g": 260,
    "volume-ml": null,
    "calories": 273,
    "fat": 2.3,
    "saturated": 0.6,
    "polyunsaturated": 1,
    "monounsaturated": 0.6,
    "trans": null,
    "cholesterol": 18.2,
    "sodium": 1020,
    "carbs": 56.1,
    "fibre": 11.4,
    "sugar": 21.4,
    "protein": 11.8,
    "potassium": 601,
    "calcium": 101,
    "iron": 3.6,
    "phosphorus": 229,
    "info": "canned"
  },
  {
    "id": 1136,
    "disabled": null,
    "type": "meal",
    "name": "Shirataki rice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": "Konjac rice",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "shirataki rice [konjac|konnyaku]",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 23,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 1,
    "carbs": 11.1,
    "fibre": 11.1,
    "sugar": 0,
    "protein": 0.1,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "Konjac rice"
  },
  {
    "id": 1137,
    "disabled": null,
    "type": "meal",
    "name": "Mee hoon kueh, soup",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[mee|mi] hoon [kueh|kuih|kue], soup",
    "weight-g": 528,
    "volume-ml": null,
    "calories": 475,
    "fat": 21.7,
    "saturated": 8.5,
    "polyunsaturated": 3.2,
    "monounsaturated": 9.5,
    "trans": null,
    "cholesterol": 232,
    "sodium": 2196,
    "carbs": 48.1,
    "fibre": 3.2,
    "sugar": 0,
    "protein": 21.7,
    "potassium": 491,
    "calcium": 84,
    "iron": 1.1,
    "phosphorus": 174,
    "info": null
  },
  {
    "id": 1138,
    "disabled": null,
    "type": "meal",
    "name": "Clams / cockles, steamed",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "clams / cockles, steamed",
    "weight-g": 258,
    "volume-ml": null,
    "calories": 87,
    "fat": 0.7,
    "saturated": 0.3,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.3,
    "trans": 0,
    "cholesterol": 36,
    "sodium": 265,
    "carbs": 0.9,
    "fibre": 0,
    "sugar": 0,
    "protein": 19,
    "potassium": 276,
    "calcium": 397,
    "iron": 0.5,
    "phosphorus": 642,
    "info": null
  },
  {
    "id": 1139,
    "disabled": null,
    "type": "meal",
    "name": "Minced meat noodle, soup",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Bak Chor Mee / Mee Pok",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "minced meat noodle, soup bak chor [mee|mian] / pok",
    "weight-g": 600,
    "volume-ml": null,
    "calories": 374,
    "fat": 12.2,
    "saturated": 4.8,
    "polyunsaturated": 2.3,
    "monounsaturated": 4.3,
    "trans": 0,
    "cholesterol": 29,
    "sodium": 1425,
    "carbs": 53.1,
    "fibre": 7.9,
    "sugar": 4.2,
    "protein": 11.8,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "Bak Chor Mee / Mee Pok"
  },
  {
    "id": 1140,
    "disabled": null,
    "type": "snack",
    "name": "Honey",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "honey",
    "weight-g": 7,
    "volume-ml": null,
    "calories": 21.3,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0.3,
    "carbs": 5.8,
    "fibre": 0,
    "sugar": 5.8,
    "protein": 0,
    "potassium": 3.6,
    "calcium": 0.4,
    "iron": 0,
    "phosphorus": 0.3,
    "info": null
  },
  {
    "id": 1141,
    "disabled": null,
    "type": "meal",
    "name": "Bao with vegetable filling",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[bao|pau|pao] with vegetable filling",
    "weight-g": 77,
    "volume-ml": null,
    "calories": 150,
    "fat": 4.7,
    "saturated": 2,
    "polyunsaturated": 0.6,
    "monounsaturated": 1.9,
    "trans": null,
    "cholesterol": 0,
    "sodium": 281,
    "carbs": 22.9,
    "fibre": 2.5,
    "sugar": 3.9,
    "protein": 4.2,
    "potassium": 86,
    "calcium": 26,
    "iron": 0.7,
    "phosphorus": 45,
    "info": null
  },
  {
    "id": 1142,
    "disabled": null,
    "type": "meal",
    "name": "Pork trotter with egg, vinegar and ginger",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Te Ka",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pork trotter with egg, vinegar and ginger te ka",
    "weight-g": 275,
    "volume-ml": null,
    "calories": 311,
    "fat": 16.8,
    "saturated": 4.7,
    "polyunsaturated": 1.5,
    "monounsaturated": 9,
    "trans": 0,
    "cholesterol": 263,
    "sodium": 958,
    "carbs": 13.8,
    "fibre": 0.3,
    "sugar": 13.8,
    "protein": 26.1,
    "potassium": 505,
    "calcium": 42,
    "iron": 9.7,
    "phosphorus": 587,
    "info": "Te Ka"
  },
  {
    "id": 1143,
    "disabled": null,
    "type": "snack",
    "name": "Feta cheese",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "square",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "5cm square",
    "info-include-exclude": null,
    "search-text": "feta cheese",
    "weight-g": 17,
    "volume-ml": null,
    "calories": 45,
    "fat": 3.6,
    "saturated": 2.5,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.8,
    "trans": null,
    "cholesterol": 15,
    "sodium": 156,
    "carbs": 0.7,
    "fibre": 0,
    "sugar": 0.7,
    "protein": 2.4,
    "potassium": 11,
    "calcium": 84,
    "iron": 0.1,
    "phosphorus": 57,
    "info": "(5cm square)"
  },
  {
    "id": 1144,
    "disabled": null,
    "type": "meal",
    "name": "Pork, braised",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": "Lor Bak",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pork, braised [lor|lou] bak",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 213,
    "fat": 15,
    "saturated": 6.5,
    "polyunsaturated": 3.5,
    "monounsaturated": 5.5,
    "trans": null,
    "cholesterol": 61,
    "sodium": 212,
    "carbs": 1.5,
    "fibre": 0.2,
    "sugar": 1.5,
    "protein": 18,
    "potassium": 80,
    "calcium": 19,
    "iron": 0.9,
    "phosphorus": 99,
    "info": "Lor Bak"
  },
  {
    "id": 1145,
    "disabled": null,
    "type": "drink",
    "name": "Tonic water",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "can",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with sugar and quinine",
    "search-text": "tonic water",
    "weight-g": 325,
    "volume-ml": null,
    "calories": 111,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": null,
    "carbs": 27.6,
    "fibre": null,
    "sugar": 27.6,
    "protein": 0,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "with sugar and quinine"
  },
  {
    "id": 1146,
    "disabled": null,
    "type": "snack",
    "name": "Poong tor kueh",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[poong|png|peng|pung] tor [kueh|kuih|kue]",
    "weight-g": 107,
    "volume-ml": null,
    "calories": 200,
    "fat": 4.8,
    "saturated": 1.8,
    "polyunsaturated": 0.8,
    "monounsaturated": 2,
    "trans": null,
    "cholesterol": 4,
    "sodium": 415,
    "carbs": 35.5,
    "fibre": 1,
    "sugar": null,
    "protein": 4,
    "potassium": 39,
    "calcium": 14,
    "iron": 1.4,
    "phosphorus": 36,
    "info": null
  },
  {
    "id": 1147,
    "disabled": null,
    "type": "meal",
    "name": "Pizza, vegetarian thin crust",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with vegetables and cheese",
    "search-text": "pizza, vegetarian thin crust",
    "weight-g": 62,
    "volume-ml": null,
    "calories": 108,
    "fat": 3.4,
    "saturated": 1.9,
    "polyunsaturated": 0.3,
    "monounsaturated": 1.1,
    "trans": 0,
    "cholesterol": 12,
    "sodium": 171,
    "carbs": 14.6,
    "fibre": 1.3,
    "sugar": 3.9,
    "protein": 4.9,
    "potassium": 84,
    "calcium": 88,
    "iron": 0.4,
    "phosphorus": 106,
    "info": "with vegetables and cheese"
  },
  {
    "id": 1148,
    "disabled": null,
    "type": "meal",
    "name": "Pizza, vegetarian thick crust",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with vegetables and cheese",
    "search-text": "pizza, vegetarian thick crust",
    "weight-g": 110,
    "volume-ml": null,
    "calories": 264,
    "fat": 10.4,
    "saturated": 4.1,
    "polyunsaturated": 2.6,
    "monounsaturated": 2.8,
    "trans": null,
    "cholesterol": 16.5,
    "sodium": 592,
    "carbs": 30.1,
    "fibre": 2.3,
    "sugar": 3.6,
    "protein": 10.4,
    "potassium": 187,
    "calcium": 174,
    "iron": 2.7,
    "phosphorus": 202,
    "info": "with vegetables and cheese"
  },
  {
    "id": 1149,
    "disabled": null,
    "type": "meal",
    "name": "Chicken hor fun",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chicken hor fun",
    "weight-g": 449,
    "volume-ml": null,
    "calories": 437,
    "fat": 13.1,
    "saturated": 4,
    "polyunsaturated": 2.8,
    "monounsaturated": 6.1,
    "trans": 0,
    "cholesterol": 67,
    "sodium": 1582,
    "carbs": 65.2,
    "fibre": 6.7,
    "sugar": 0,
    "protein": 14.2,
    "potassium": 252,
    "calcium": 31,
    "iron": 0,
    "phosphorus": 140,
    "info": null
  },
  {
    "id": 1150,
    "disabled": null,
    "type": "snack",
    "name": "Sesame oil",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sesame oil",
    "weight-g": 15,
    "volume-ml": null,
    "calories": 135,
    "fat": 15,
    "saturated": 2.1,
    "polyunsaturated": 6.6,
    "monounsaturated": 5.6,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 2,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 0,
    "potassium": 3,
    "calcium": 1,
    "iron": 0,
    "phosphorus": 0,
    "info": null
  },
  {
    "id": 1151,
    "disabled": null,
    "type": "drink",
    "name": "Chocolate drink, sweetened",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "sachet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chocolate drink, sweetened",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 114,
    "fat": 1.8,
    "saturated": 1.1,
    "polyunsaturated": 0.3,
    "monounsaturated": 0.4,
    "trans": 0,
    "cholesterol": 2,
    "sodium": 99,
    "carbs": 22.5,
    "fibre": 1,
    "sugar": 21.7,
    "protein": 1.9,
    "potassium": 330,
    "calcium": 120,
    "iron": 1.7,
    "phosphorus": 280,
    "info": null
  },
  {
    "id": 1152,
    "disabled": null,
    "type": "snack",
    "name": "Bird nest, without sugar",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bottle",
    "info-translation": null,
    "info-brands": "Brand's",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with sugar alcohols",
    "search-text": "bird nest, without sugar brand's",
    "weight-g": 68,
    "volume-ml": null,
    "calories": 32,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 5,
    "carbs": 7.4,
    "fibre": 0,
    "sugar": 0,
    "protein": 0.5,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "with sugar alcohols, e.g. Brand's"
  },
  {
    "id": 1153,
    "disabled": null,
    "type": "snack",
    "name": "Chee cheong fun, with sweet sauce",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chee [cheong|cheung] fun, with sweet sauce",
    "weight-g": 101,
    "volume-ml": null,
    "calories": 131,
    "fat": 2.2,
    "saturated": 1,
    "polyunsaturated": 0.4,
    "monounsaturated": 0.7,
    "trans": null,
    "cholesterol": 0,
    "sodium": 198,
    "carbs": 25.3,
    "fibre": 1.2,
    "sugar": 8,
    "protein": 2.5,
    "potassium": 24,
    "calcium": 6,
    "iron": 0.4,
    "phosphorus": 0,
    "info": null
  },
  {
    "id": 1154,
    "disabled": null,
    "type": "meal",
    "name": "Miso soup",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "miso soup",
    "weight-g": 102,
    "volume-ml": null,
    "calories": 12,
    "fat": 0.7,
    "saturated": 0.1,
    "polyunsaturated": 0.4,
    "monounsaturated": 0.1,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 323,
    "carbs": 0.4,
    "fibre": 0.4,
    "sugar": 0.4,
    "protein": 1.1,
    "potassium": 34,
    "calcium": 9,
    "iron": 0,
    "phosphorus": 13,
    "info": null
  },
  {
    "id": 1155,
    "disabled": null,
    "type": "snack",
    "name": "Potato salad",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "potato salad",
    "weight-g": 168,
    "volume-ml": null,
    "calories": 350,
    "fat": 26.8,
    "saturated": 2.2,
    "polyunsaturated": 8.2,
    "monounsaturated": 3.4,
    "trans": 0,
    "cholesterol": 12,
    "sodium": 342,
    "carbs": 25.8,
    "fibre": 2.6,
    "sugar": 7.6,
    "protein": 5,
    "potassium": 324,
    "calcium": 26,
    "iron": 2.6,
    "phosphorus": 50,
    "info": null
  },
  {
    "id": 1156,
    "disabled": null,
    "type": "meal",
    "name": "Stir fried pork rib with bitter gourd",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "stir fried pork rib with bitter gourd",
    "weight-g": 150,
    "volume-ml": null,
    "calories": 190,
    "fat": 12.4,
    "saturated": 4.8,
    "polyunsaturated": 1.4,
    "monounsaturated": 4.8,
    "trans": 0.3,
    "cholesterol": 19,
    "sodium": 125,
    "carbs": 11.2,
    "fibre": 3.3,
    "sugar": 8,
    "protein": 8.3,
    "potassium": 239,
    "calcium": 44,
    "iron": 1.5,
    "phosphorus": 72,
    "info": null
  },
  {
    "id": 1157,
    "disabled": null,
    "type": "meal",
    "name": "Minced pork, stir fried",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "minced pork, stir fried",
    "weight-g": 115,
    "volume-ml": null,
    "calories": 314,
    "fat": 26,
    "saturated": 9,
    "polyunsaturated": 5,
    "monounsaturated": 10,
    "trans": 0,
    "cholesterol": 72,
    "sodium": 421,
    "carbs": 2,
    "fibre": 0,
    "sugar": 2,
    "protein": 18,
    "potassium": 306,
    "calcium": 17,
    "iron": 1,
    "phosphorus": 182,
    "info": null
  },
  {
    "id": 1158,
    "disabled": null,
    "type": "meal",
    "name": "Minced pork, steamed",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "minced pork, steamed",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 318,
    "fat": 25,
    "saturated": 8.7,
    "polyunsaturated": 4.2,
    "monounsaturated": 11.9,
    "trans": null,
    "cholesterol": 74,
    "sodium": 366,
    "carbs": 0.4,
    "fibre": 0,
    "sugar": 0.2,
    "protein": 18.6,
    "potassium": 203,
    "calcium": 12,
    "iron": 0.9,
    "phosphorus": 101,
    "info": null
  },
  {
    "id": 1159,
    "disabled": null,
    "type": "meal",
    "name": "Salted fish",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "salted fish",
    "weight-g": 25,
    "volume-ml": null,
    "calories": 46,
    "fat": 0.4,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 433,
    "carbs": 0.1,
    "fibre": 0,
    "sugar": 0,
    "protein": 10.5,
    "potassium": 61,
    "calcium": 44,
    "iron": 0.4,
    "phosphorus": 18,
    "info": null
  },
  {
    "id": 1160,
    "disabled": null,
    "type": "meal",
    "name": "Zha jiang mian",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Fried sauce noodles",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "zha jiang mian fried sauce noodles",
    "weight-g": 462,
    "volume-ml": null,
    "calories": 670,
    "fat": 28.4,
    "saturated": 11.5,
    "polyunsaturated": 5.5,
    "monounsaturated": 11.5,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1404,
    "carbs": 77.6,
    "fibre": 14.8,
    "sugar": 4.6,
    "protein": 26.3,
    "potassium": null,
    "calcium": 79,
    "iron": 3.5,
    "phosphorus": null,
    "info": "Fried sauce noodles"
  },
  {
    "id": 1161,
    "disabled": null,
    "type": "meal",
    "name": "Mee sua, cooked",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "plain",
    "search-text": "mee [sua|suah], cooked",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 190,
    "fat": 0.9,
    "saturated": 0.6,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 846,
    "carbs": 31.6,
    "fibre": 4.4,
    "sugar": null,
    "protein": 13.9,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "plain"
  },
  {
    "id": 1162,
    "disabled": null,
    "type": "snack",
    "name": "Egg tofu",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "pack",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "6\" long",
    "info-include-exclude": null,
    "search-text": "egg tofu",
    "weight-g": 150,
    "volume-ml": null,
    "calories": 99,
    "fat": 6.3,
    "saturated": 2,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 94.8,
    "sodium": 513,
    "carbs": null,
    "fibre": 2.3,
    "sugar": null,
    "protein": 10.5,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "(6\" long)"
  },
  {
    "id": 1163,
    "disabled": null,
    "type": "meal",
    "name": "Pasta, pesto sauce",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pasta, pesto sauce",
    "weight-g": 314,
    "volume-ml": null,
    "calories": 580.9,
    "fat": 32.9,
    "saturated": 10.3,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 1033,
    "carbs": 51.8,
    "fibre": 3.7,
    "sugar": null,
    "protein": 18.8,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1164,
    "disabled": null,
    "type": "drink",
    "name": "Champagne",
    "rating": 2,
    "alcohol": 1,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "glass",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "Prosecco, Cava, sparkling wine",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "champagne prosecco, cava, sparkling wine",
    "weight-g": 150,
    "volume-ml": null,
    "calories": 98,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 15,
    "carbs": 1.5,
    "fibre": 0,
    "sugar": 1.5,
    "protein": 0.3,
    "potassium": 53,
    "calcium": 12,
    "iron": 0.3,
    "phosphorus": 26,
    "info": "e.g. Prosecco, Cava, sparkling wine"
  },
  {
    "id": 1165,
    "disabled": null,
    "type": "snack",
    "name": "Chocolate egg",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": "Cadbury",
    "info-examples": "Easter",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chocolate egg cadbury easter",
    "weight-g": 31,
    "volume-ml": null,
    "calories": 150,
    "fat": 8,
    "saturated": 6,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 35,
    "carbs": 18,
    "fibre": 0.1,
    "sugar": 14,
    "protein": 1,
    "potassium": 0,
    "calcium": 20.2,
    "iron": 0.7,
    "phosphorus": null,
    "info": "e.g. Cadbury, Easter"
  },
  {
    "id": 1166,
    "disabled": null,
    "type": "snack",
    "name": "Salmon, smoked",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "salmon, smoked",
    "weight-g": 25,
    "volume-ml": null,
    "calories": 34,
    "fat": 1.2,
    "saturated": 0.2,
    "polyunsaturated": 0.4,
    "monounsaturated": 0.5,
    "trans": null,
    "cholesterol": 12,
    "sodium": 428,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 5.8,
    "potassium": 66,
    "calcium": 4,
    "iron": 0.1,
    "phosphorus": 63,
    "info": null
  },
  {
    "id": 1167,
    "disabled": null,
    "type": "snack",
    "name": "Bak kwa bun",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bun",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bak kwa bun",
    "weight-g": 62,
    "volume-ml": null,
    "calories": 196,
    "fat": 8.8,
    "saturated": 1.3,
    "polyunsaturated": 3.2,
    "monounsaturated": 2.1,
    "trans": null,
    "cholesterol": 13,
    "sodium": 612,
    "carbs": 37.1,
    "fibre": 4,
    "sugar": 10.3,
    "protein": 11,
    "potassium": 192,
    "calcium": 61,
    "iron": 0.3,
    "phosphorus": 41,
    "info": null
  },
  {
    "id": 1168,
    "disabled": null,
    "type": "meal",
    "name": "Frittata",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with cheese and vegetables",
    "search-text": "frittata",
    "weight-g": 135,
    "volume-ml": null,
    "calories": 234,
    "fat": 16.3,
    "saturated": 7.7,
    "polyunsaturated": 1.4,
    "monounsaturated": 5.2,
    "trans": 0.3,
    "cholesterol": 457,
    "sodium": 275,
    "carbs": 4.1,
    "fibre": 1.3,
    "sugar": 2.7,
    "protein": 17.8,
    "potassium": 421,
    "calcium": 242,
    "iron": 3,
    "phosphorus": 299,
    "info": "with cheese and vegetables"
  },
  {
    "id": 1169,
    "disabled": null,
    "type": "drink",
    "name": "Soy milk, reduced sugar (Healthier Choice Symbol, high calcium)",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": "Nutrisoy",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soy milk, reduced sugar (healthier choice symbol, high calcium) nutrisoy",
    "weight-g": 250,
    "volume-ml": null,
    "calories": 165,
    "fat": 6,
    "saturated": 1,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 8,
    "carbs": 17,
    "fibre": 1.3,
    "sugar": 10.5,
    "protein": 10.8,
    "potassium": null,
    "calcium": 500,
    "iron": 0,
    "phosphorus": null,
    "info": "e.g. Nutrisoy"
  },
  {
    "id": 1170,
    "disabled": null,
    "type": "snack",
    "name": "Keto cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "chocolate, banana, vanilla",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "keto cake chocolate, banana, vanilla",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 249,
    "fat": 15.5,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 4.5,
    "fibre": 2.3,
    "sugar": null,
    "protein": 10.2,
    "potassium": null,
    "calcium": 20,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. chocolate, banana, vanilla"
  },
  {
    "id": 1171,
    "disabled": null,
    "type": "snack",
    "name": "Sugar",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sugar",
    "weight-g": 4.2,
    "volume-ml": null,
    "calories": 16.3,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 4.2,
    "fibre": 0,
    "sugar": 4.2,
    "protein": 0,
    "potassium": 0.1,
    "calcium": 0,
    "iron": 0,
    "phosphorus": 0,
    "info": null
  },
  {
    "id": 1172,
    "disabled": null,
    "type": "meal",
    "name": "Alfalfa sprouts",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "alfalfa sprouts",
    "weight-g": 33,
    "volume-ml": null,
    "calories": 8,
    "fat": 0.2,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 2,
    "carbs": 0.7,
    "fibre": 0.6,
    "sugar": 0.1,
    "protein": 1.3,
    "potassium": 26,
    "calcium": 11,
    "iron": 0.3,
    "phosphorus": 23,
    "info": null
  },
  {
    "id": 1173,
    "disabled": null,
    "type": "meal",
    "name": "Fish sauce",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fish sauce",
    "weight-g": 16,
    "volume-ml": null,
    "calories": 9,
    "fat": 0.2,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 1013,
    "carbs": 3,
    "fibre": 0,
    "sugar": 1.8,
    "protein": 0.8,
    "potassium": 3,
    "calcium": 61,
    "iron": 0.5,
    "phosphorus": 50,
    "info": null
  },
  {
    "id": 1174,
    "disabled": null,
    "type": "meal",
    "name": "Beehoon, boiled",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Rice vermicelli",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "plain",
    "search-text": "beehoon, boiled rice vermicelli",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 153,
    "fat": 0.1,
    "saturated": 0.1,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 1,
    "carbs": 35.4,
    "fibre": 0.9,
    "sugar": 0,
    "protein": 2.5,
    "potassium": 2,
    "calcium": 1,
    "iron": 0.4,
    "phosphorus": 3,
    "info": "Rice vermicelli – plain"
  },
  {
    "id": 1175,
    "disabled": null,
    "type": "meal",
    "name": "Couscous",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "couscous",
    "weight-g": 157,
    "volume-ml": null,
    "calories": 176,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 8,
    "carbs": 36,
    "fibre": 2,
    "sugar": 0,
    "protein": 5,
    "potassium": 91,
    "calcium": 13,
    "iron": 0,
    "phosphorus": 35,
    "info": null
  },
  {
    "id": 1176,
    "disabled": null,
    "type": "meal",
    "name": "Carrot, raw",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "medium",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "carrot, raw",
    "weight-g": 81,
    "volume-ml": null,
    "calories": 19,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 35,
    "carbs": 4,
    "fibre": 2.1,
    "sugar": 2,
    "protein": 0.7,
    "potassium": 211,
    "calcium": 25,
    "iron": 0.2,
    "phosphorus": 12,
    "info": null
  },
  {
    "id": 1177,
    "disabled": null,
    "type": "meal",
    "name": "Idli",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "idli",
    "weight-g": 75,
    "volume-ml": null,
    "calories": 80,
    "fat": 0.5,
    "saturated": 0.2,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 281,
    "carbs": 15.5,
    "fibre": 2,
    "sugar": 0,
    "protein": 3.4,
    "potassium": 118,
    "calcium": 14,
    "iron": 0.5,
    "phosphorus": 54,
    "info": null
  },
  {
    "id": 1178,
    "disabled": null,
    "type": "snack",
    "name": "Biscuit with cheese filling",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 6,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "biscuit with cheese filling",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 144,
    "fat": 6.6,
    "saturated": 4.2,
    "polyunsaturated": 0.5,
    "monounsaturated": 1.5,
    "trans": null,
    "cholesterol": 4,
    "sodium": 286,
    "carbs": 17.3,
    "fibre": 1,
    "sugar": 1,
    "protein": 3.9,
    "potassium": 70,
    "calcium": 39,
    "iron": 0.4,
    "phosphorus": 71,
    "info": null
  },
  {
    "id": 1179,
    "disabled": null,
    "type": "snack",
    "name": "Mixed fruit salad",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "without dressing",
    "search-text": "mixed fruit salad",
    "weight-g": 175,
    "volume-ml": null,
    "calories": 91,
    "fat": 0.4,
    "saturated": 0.1,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": null,
    "sodium": 4,
    "carbs": 23,
    "fibre": 3.3,
    "sugar": 16.7,
    "protein": 1.3,
    "potassium": 348,
    "calcium": 28,
    "iron": 0.4,
    "phosphorus": 28,
    "info": "without dressing"
  },
  {
    "id": 1180,
    "disabled": null,
    "type": "snack",
    "name": "Pretzel, with cinnamon sugar",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "pretzel",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pretzel, with cinnamon sugar",
    "weight-g": 147,
    "volume-ml": null,
    "calories": 470,
    "fat": 12,
    "saturated": 7,
    "polyunsaturated": 0.5,
    "monounsaturated": 5,
    "trans": 0,
    "cholesterol": 25,
    "sodium": 400,
    "carbs": 84,
    "fibre": 2,
    "sugar": 29,
    "protein": 8,
    "potassium": null,
    "calcium": 20,
    "iron": 0.7,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1181,
    "disabled": null,
    "type": "meal",
    "name": "Luo han zhai",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": "Buddha's Delight",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[luo|lo] han [zhai|chai|jai] buddha's delight",
    "weight-g": 174,
    "volume-ml": null,
    "calories": 156.6,
    "fat": 12.5,
    "saturated": 5.2,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 765.6,
    "carbs": 5.7,
    "fibre": 2.6,
    "sugar": null,
    "protein": 5,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "Buddha's Delight"
  },
  {
    "id": 1182,
    "disabled": null,
    "type": "drink",
    "name": "Coffee, decaffinated",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "coffee, decaffinated",
    "weight-g": 237,
    "volume-ml": null,
    "calories": 0,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 5,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 0.2,
    "potassium": 128,
    "calcium": 2,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1183,
    "disabled": null,
    "type": "meal",
    "name": "Non dairy creamer",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "sachet",
    "info-translation": null,
    "info-brands": "Coffee Mate",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "non dairy creamer coffee mate",
    "weight-g": 3,
    "volume-ml": null,
    "calories": 16,
    "fat": 1.1,
    "saturated": 1,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 5,
    "carbs": 1.7,
    "fibre": 0,
    "sugar": 1.7,
    "protein": 0.1,
    "potassium": 24,
    "calcium": 1,
    "iron": 0,
    "phosphorus": 13,
    "info": "e.g. Coffee Mate"
  },
  {
    "id": 1184,
    "disabled": null,
    "type": "drink",
    "name": "Citron tea, paste",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": "Yuja Cha",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "citron tea, paste [yuja|yuzu] cha",
    "weight-g": 15,
    "volume-ml": null,
    "calories": 40,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 1,
    "carbs": 9.8,
    "fibre": 0.4,
    "sugar": 9.1,
    "protein": 0.1,
    "potassium": null,
    "calcium": 2,
    "iron": null,
    "phosphorus": null,
    "info": "Yuja Cha"
  },
  {
    "id": 1185,
    "disabled": null,
    "type": "meal",
    "name": "Oyster sauce",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "oyster sauce",
    "weight-g": 18,
    "volume-ml": null,
    "calories": 9.2,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 491,
    "carbs": 2.7,
    "fibre": 2,
    "sugar": 0,
    "protein": 0.2,
    "potassium": 9.7,
    "calcium": 5.8,
    "iron": 0,
    "phosphorus": 4,
    "info": null
  },
  {
    "id": 1186,
    "disabled": null,
    "type": "meal",
    "name": "Udon soup, with vegetables",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "udon soup, with vegetables",
    "weight-g": 220,
    "volume-ml": null,
    "calories": 319,
    "fat": 2.5,
    "saturated": 0.5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 1200,
    "carbs": 66,
    "fibre": 3,
    "sugar": 5,
    "protein": 8,
    "potassium": null,
    "calcium": 40,
    "iron": 3,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1187,
    "disabled": null,
    "type": "meal",
    "name": "Wakame, stir fried",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": "Kelp",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "wakame, stir fried kelp",
    "weight-g": 105,
    "volume-ml": null,
    "calories": 66.2,
    "fat": 3.2,
    "saturated": 0.9,
    "polyunsaturated": 0.9,
    "monounsaturated": 1,
    "trans": null,
    "cholesterol": 2.1,
    "sodium": 402,
    "carbs": 8.1,
    "fibre": 0.7,
    "sugar": 0.6,
    "protein": 3.6,
    "potassium": 169,
    "calcium": 132,
    "iron": 2.3,
    "phosphorus": 62,
    "info": "Kelp"
  },
  {
    "id": 1188,
    "disabled": null,
    "type": "meal",
    "name": "Bread, sourdough",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bread, sourdough",
    "weight-g": 50,
    "volume-ml": null,
    "calories": 115,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": null,
    "sodium": 210,
    "carbs": 25,
    "fibre": 1,
    "sugar": 1,
    "protein": 4,
    "potassium": null,
    "calcium": 0,
    "iron": 0.4,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1189,
    "disabled": null,
    "type": "meal",
    "name": "Mee tai mak, stir fried",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Silver needle noodle",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[mee|bee] tai mak, stir fried silver needle noodle",
    "weight-g": 260,
    "volume-ml": null,
    "calories": 405,
    "fat": 13,
    "saturated": 2,
    "polyunsaturated": 7,
    "monounsaturated": 3,
    "trans": 0,
    "cholesterol": 4,
    "sodium": 879,
    "carbs": 70,
    "fibre": 2,
    "sugar": 8,
    "protein": 3,
    "potassium": 87,
    "calcium": 57,
    "iron": 2,
    "phosphorus": 40,
    "info": "Silver needle noodle"
  },
  {
    "id": 1190,
    "disabled": null,
    "type": "meal",
    "name": "Garlic sauce",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "garlic sauce",
    "weight-g": 20,
    "volume-ml": null,
    "calories": 10,
    "fat": 1,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 440,
    "carbs": 1,
    "fibre": 0,
    "sugar": 1,
    "protein": 0,
    "potassium": 0,
    "calcium": 0,
    "iron": 0,
    "phosphorus": 0,
    "info": null
  },
  {
    "id": 1191,
    "disabled": null,
    "type": "meal",
    "name": "Shimeji mushroom, raw",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "shimeji mushroom, raw",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 20,
    "fat": 0.3,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": null,
    "carbs": 3.7,
    "fibre": 1.4,
    "sugar": 0.6,
    "protein": 1.9,
    "potassium": null,
    "calcium": 0,
    "iron": 0.7,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1192,
    "disabled": null,
    "type": "meal",
    "name": "Carrot halwa",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Indian carrot pudding",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "carrot halwa indian carrot pudding",
    "weight-g": 246,
    "volume-ml": null,
    "calories": 282,
    "fat": 10.8,
    "saturated": 5.9,
    "polyunsaturated": 0.7,
    "monounsaturated": 3.6,
    "trans": 0,
    "cholesterol": 27,
    "sodium": 87,
    "carbs": 41.9,
    "fibre": 2.6,
    "sugar": 38.8,
    "protein": 5.7,
    "potassium": 460,
    "calcium": 175,
    "iron": 0.6,
    "phosphorus": 154,
    "info": "Indian carrot pudding"
  },
  {
    "id": 1193,
    "disabled": null,
    "type": "meal",
    "name": "Pork kidney, boiled",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pork kidney, boiled",
    "weight-g": 11.3,
    "volume-ml": null,
    "calories": 17.1,
    "fat": 0.5,
    "saturated": 0.2,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 54.4,
    "sodium": 9,
    "carbs": null,
    "fibre": null,
    "sugar": null,
    "protein": 3,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1194,
    "disabled": null,
    "type": "snack",
    "name": "Figs, fresh",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "figs, fresh",
    "weight-g": 50,
    "volume-ml": null,
    "calories": 37,
    "fat": 0.2,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 1,
    "carbs": 9.6,
    "fibre": 1.5,
    "sugar": 8.2,
    "protein": 0.4,
    "potassium": 117,
    "calcium": 18,
    "iron": 0.2,
    "phosphorus": 7,
    "info": null
  },
  {
    "id": 1195,
    "disabled": null,
    "type": "drink",
    "name": "Spinach and avocado smoothie",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with milk",
    "search-text": "spinach and avocado smoothie",
    "weight-g": 216,
    "volume-ml": null,
    "calories": 190,
    "fat": 14.1,
    "saturated": 2.9,
    "polyunsaturated": 1.6,
    "monounsaturated": 8.4,
    "trans": null,
    "cholesterol": 8.6,
    "sodium": 71.3,
    "carbs": 13.1,
    "fibre": 6.3,
    "sugar": 5.6,
    "protein": 6,
    "potassium": 745,
    "calcium": 168,
    "iron": 1.4,
    "phosphorus": 162,
    "info": "with milk"
  },
  {
    "id": 1196,
    "disabled": null,
    "type": "snack",
    "name": "Banana cake",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "banana cake",
    "weight-g": 45,
    "volume-ml": null,
    "calories": 146,
    "fat": 7.8,
    "saturated": 3.2,
    "polyunsaturated": 2,
    "monounsaturated": 2.3,
    "trans": null,
    "cholesterol": 32,
    "sodium": 64,
    "carbs": 16.3,
    "fibre": 0.6,
    "sugar": 9.5,
    "protein": 2.5,
    "potassium": 86,
    "calcium": 14,
    "iron": 0.3,
    "phosphorus": 36,
    "info": null
  },
  {
    "id": 1197,
    "disabled": null,
    "type": "meal",
    "name": "Prawn / shrimp, raw",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 4,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "prawn / shrimp, raw",
    "weight-g": 28,
    "volume-ml": null,
    "calories": 19.9,
    "fat": 0.3,
    "saturated": 0.1,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.1,
    "trans": 0,
    "cholesterol": 35.3,
    "sodium": 158,
    "carbs": 0.3,
    "fibre": 0,
    "sugar": 0,
    "protein": 3.8,
    "potassium": 31.6,
    "calcium": 15.1,
    "iron": 0.1,
    "phosphorus": 68.3,
    "info": null
  },
  {
    "id": 1198,
    "disabled": null,
    "type": "snack",
    "name": "Haw flakes",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "packet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "haw flakes",
    "weight-g": 12,
    "volume-ml": null,
    "calories": 39,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 2,
    "carbs": 9,
    "fibre": 0.5,
    "sugar": 8.8,
    "protein": 0.6,
    "potassium": 21,
    "calcium": 7,
    "iron": 0.3,
    "phosphorus": 2,
    "info": null
  },
  {
    "id": 1199,
    "disabled": null,
    "type": "meal",
    "name": "Stir fried french bean with egg",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "stir fried french bean with egg",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 96,
    "fat": 7.6,
    "saturated": 2.3,
    "polyunsaturated": 3,
    "monounsaturated": 2.1,
    "trans": 0,
    "cholesterol": 90,
    "sodium": 395,
    "carbs": 2.2,
    "fibre": 2.1,
    "sugar": 1.4,
    "protein": 4.6,
    "potassium": 159,
    "calcium": 54,
    "iron": 1.1,
    "phosphorus": 75,
    "info": null
  },
  {
    "id": 1200,
    "disabled": null,
    "type": "meal",
    "name": "Teriyaki chicken",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": "Japanese marinated chicken",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "teriyaki chicken japanese marinated",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 163,
    "fat": 6,
    "saturated": 1.5,
    "polyunsaturated": 0.9,
    "monounsaturated": 2.5,
    "trans": null,
    "cholesterol": 105,
    "sodium": 723,
    "carbs": 1.6,
    "fibre": 0,
    "sugar": 1.5,
    "protein": 25.7,
    "potassium": 285,
    "calcium": 16,
    "iron": 0.9,
    "phosphorus": 239,
    "info": "Japanese marinated chicken"
  },
  {
    "id": 1201,
    "disabled": null,
    "type": "meal",
    "name": "Pork knuckle, braised",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pork knuckle, braised",
    "weight-g": 403,
    "volume-ml": null,
    "calories": 281,
    "fat": 13.8,
    "saturated": 4.8,
    "polyunsaturated": 2.4,
    "monounsaturated": 5.9,
    "trans": 0.1,
    "cholesterol": 90,
    "sodium": 1262,
    "carbs": 6.7,
    "fibre": 1.1,
    "sugar": 5.6,
    "protein": 29.6,
    "potassium": 167,
    "calcium": 50,
    "iron": 1.8,
    "phosphorus": 57,
    "info": null
  },
  {
    "id": 1202,
    "disabled": null,
    "type": "meal",
    "name": "Sauerkraut",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": 1,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sauerkraut",
    "weight-g": 12,
    "volume-ml": null,
    "calories": 3,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 48,
    "carbs": 0.4,
    "fibre": 0.3,
    "sugar": 0.1,
    "protein": 0.1,
    "potassium": 22,
    "calcium": 6,
    "iron": 0.2,
    "phosphorus": 3,
    "info": null
  },
  {
    "id": 1203,
    "disabled": null,
    "type": "meal",
    "name": "Spatzle with cheese",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "spatzle with cheese",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 390,
    "fat": 8.3,
    "saturated": 3.3,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": null,
    "sodium": 1160,
    "carbs": 61.7,
    "fibre": 3,
    "sugar": 3.3,
    "protein": 15,
    "potassium": null,
    "calcium": null,
    "iron": 0,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1204,
    "disabled": null,
    "type": "meal",
    "name": "Apple crumble",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "apple crumble",
    "weight-g": 146,
    "volume-ml": null,
    "calories": 353.5,
    "fat": 14.9,
    "saturated": 11.5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 11.8,
    "sodium": 92,
    "carbs": 49.1,
    "fibre": 6,
    "sugar": null,
    "protein": 5.8,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1205,
    "disabled": null,
    "type": "snack",
    "name": "Potato wedges, fried",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 5,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "potato wedges, fried",
    "weight-g": 105,
    "volume-ml": null,
    "calories": 200,
    "fat": 12,
    "saturated": 2.5,
    "polyunsaturated": 3.5,
    "monounsaturated": 6,
    "trans": 0.1,
    "cholesterol": 0,
    "sodium": 302,
    "carbs": 20.9,
    "fibre": 2.3,
    "sugar": 0.3,
    "protein": 2.3,
    "potassium": 383,
    "calcium": 18,
    "iron": 0.8,
    "phosphorus": 140,
    "info": null
  },
  {
    "id": 1206,
    "disabled": null,
    "type": "snack",
    "name": "Achar",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "achar",
    "weight-g": 20,
    "volume-ml": null,
    "calories": 24,
    "fat": 0.4,
    "saturated": 0.1,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 121,
    "carbs": 4.6,
    "fibre": 0.4,
    "sugar": 4,
    "protein": 0.4,
    "potassium": null,
    "calcium": 7,
    "iron": 0.2,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1207,
    "disabled": null,
    "type": "meal",
    "name": "Aloo matar",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "aloo [matar|mutter]",
    "weight-g": 140,
    "volume-ml": null,
    "calories": 99,
    "fat": 4.3,
    "saturated": 1.2,
    "polyunsaturated": 1.4,
    "monounsaturated": 1.6,
    "trans": null,
    "cholesterol": 0,
    "sodium": 382,
    "carbs": 10.6,
    "fibre": 4.3,
    "sugar": 3.6,
    "protein": 4.5,
    "potassium": 529,
    "calcium": 50,
    "iron": 1,
    "phosphorus": 66,
    "info": null
  },
  {
    "id": 1208,
    "disabled": null,
    "type": "meal",
    "name": "Ayam masak merah",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ayam masak merah",
    "weight-g": 150,
    "volume-ml": null,
    "calories": 185,
    "fat": 11.3,
    "saturated": 2.9,
    "polyunsaturated": 2.8,
    "monounsaturated": 4.9,
    "trans": null,
    "cholesterol": 59,
    "sodium": 287,
    "carbs": 5,
    "fibre": 5.4,
    "sugar": 4.3,
    "protein": 15.8,
    "potassium": 377,
    "calcium": 99,
    "iron": 6.3,
    "phosphorus": 140,
    "info": null
  },
  {
    "id": 1209,
    "disabled": null,
    "type": "meal",
    "name": "Baby corn, boiled",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "baby corn, boiled",
    "weight-g": 145,
    "volume-ml": null,
    "calories": 42,
    "fat": 0.3,
    "saturated": 0,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 8.7,
    "fibre": 3.9,
    "sugar": 1.6,
    "protein": 3.3,
    "potassium": 426,
    "calcium": 28,
    "iron": 0.6,
    "phosphorus": 91,
    "info": null
  },
  {
    "id": 1210,
    "disabled": null,
    "type": "meal",
    "name": "Bagel, wholemeal",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "whole",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bagel, [wholemeal|wholewheat]",
    "weight-g": 81,
    "volume-ml": null,
    "calories": 195,
    "fat": 1,
    "saturated": 0,
    "polyunsaturated": 0.6,
    "monounsaturated": 0.3,
    "trans": 0,
    "cholesterol": 4.9,
    "sodium": 291,
    "carbs": 38.5,
    "fibre": 5,
    "sugar": 7,
    "protein": 8,
    "potassium": 165,
    "calcium": 100,
    "iron": 1.8,
    "phosphorus": 152,
    "info": null
  },
  {
    "id": 1211,
    "disabled": null,
    "type": "meal",
    "name": "Chicken thigh, baked / grilled",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "whole",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chicken thigh, baked / grilled",
    "weight-g": 95,
    "volume-ml": null,
    "calories": 130,
    "fat": 8.2,
    "saturated": 2.3,
    "polyunsaturated": 1.8,
    "monounsaturated": 3.2,
    "trans": null,
    "cholesterol": 49,
    "sodium": 44,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 13.2,
    "potassium": 117,
    "calcium": 6,
    "iron": 0.7,
    "phosphorus": 92,
    "info": null
  },
  {
    "id": 1212,
    "disabled": null,
    "type": "meal",
    "name": "Banh mi",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "whole",
    "info-translation": "Vietnamese baguette with filling",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "banh mi vietnamese baguette with filling",
    "weight-g": 433,
    "volume-ml": null,
    "calories": 431,
    "fat": 5.3,
    "saturated": 1,
    "polyunsaturated": 1.7,
    "monounsaturated": 2.6,
    "trans": null,
    "cholesterol": 61,
    "sodium": 3125,
    "carbs": 66.9,
    "fibre": 4.7,
    "sugar": 23.5,
    "protein": 30,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "Vietnamese baguette with filling"
  },
  {
    "id": 1213,
    "disabled": null,
    "type": "meal",
    "name": "Pesto sauce",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pesto sauce",
    "weight-g": 15,
    "volume-ml": null,
    "calories": 45,
    "fat": 4.5,
    "saturated": 0.8,
    "polyunsaturated": 1,
    "monounsaturated": 2.7,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 0.5,
    "fibre": 0.5,
    "sugar": 0,
    "protein": 0.5,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1214,
    "disabled": null,
    "type": "meal",
    "name": "Black fungus, boiled",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "black fungus, boiled",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 20,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 9,
    "carbs": 6,
    "fibre": 2,
    "sugar": 0,
    "protein": 4,
    "potassium": 500,
    "calcium": 34,
    "iron": 5.5,
    "phosphorus": 86,
    "info": null
  },
  {
    "id": 1215,
    "disabled": null,
    "type": "snack",
    "name": "Seaweed cracker",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "seaweed cracker",
    "weight-g": 14,
    "volume-ml": null,
    "calories": 59,
    "fat": 1.4,
    "saturated": 0.8,
    "polyunsaturated": 0.3,
    "monounsaturated": 0.3,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 50,
    "carbs": 10.5,
    "fibre": 0,
    "sugar": 3,
    "protein": 1.3,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1216,
    "disabled": null,
    "type": "meal",
    "name": "Chicken, braised",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chicken, braised",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 201,
    "fat": 13.8,
    "saturated": 4.2,
    "polyunsaturated": 2.5,
    "monounsaturated": 6.3,
    "trans": null,
    "cholesterol": 67,
    "sodium": 576,
    "carbs": 0.6,
    "fibre": 0,
    "sugar": 0.4,
    "protein": 18.7,
    "potassium": 176,
    "calcium": 21,
    "iron": 0.9,
    "phosphorus": 116,
    "info": null
  },
  {
    "id": 1217,
    "disabled": null,
    "type": "meal",
    "name": "Braised pork belly",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": "Hong Shao Ruo / Lor Bak",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "braised pork belly hong shao ruo / [lor|lou] bak",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 460,
    "fat": 41,
    "saturated": 13.9,
    "polyunsaturated": 5.1,
    "monounsaturated": 19.5,
    "trans": null,
    "cholesterol": 70,
    "sodium": 664,
    "carbs": 1.9,
    "fibre": 0.5,
    "sugar": 0,
    "protein": 20.8,
    "potassium": 82,
    "calcium": 49,
    "iron": 0.6,
    "phosphorus": 104,
    "info": "Hong Shao Ruo / Lor Bak"
  },
  {
    "id": 1218,
    "disabled": null,
    "type": "meal",
    "name": "Tau pok",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Fried beancurd puff",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[tau|dou] pok fried beancurd puff",
    "weight-g": 16,
    "volume-ml": null,
    "calories": 36,
    "fat": 2.5,
    "saturated": 0.3,
    "polyunsaturated": 0.4,
    "monounsaturated": 1.8,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1,
    "carbs": 0.5,
    "fibre": null,
    "sugar": 0.2,
    "protein": 3.1,
    "potassium": null,
    "calcium": 11,
    "iron": null,
    "phosphorus": null,
    "info": "Fried beancurd puff"
  },
  {
    "id": 1219,
    "disabled": null,
    "type": "meal",
    "name": "Sea cucumber, boiled",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sea cucumber, boiled",
    "weight-g": 337,
    "volume-ml": null,
    "calories": 91,
    "fat": 0.3,
    "saturated": 0.1,
    "polyunsaturated": 0,
    "monounsaturated": 0.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 138,
    "carbs": 0.3,
    "fibre": 0,
    "sugar": 0,
    "protein": 21.6,
    "potassium": 37,
    "calcium": 10,
    "iron": 1,
    "phosphorus": 10,
    "info": null
  },
  {
    "id": 1220,
    "disabled": null,
    "type": "meal",
    "name": "Bread, brioche",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bread, brioche",
    "weight-g": 36,
    "volume-ml": null,
    "calories": 125,
    "fat": 4,
    "saturated": 1.5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 20,
    "sodium": 30,
    "carbs": 20,
    "fibre": 0.5,
    "sugar": 4,
    "protein": 3,
    "potassium": null,
    "calcium": 20,
    "iron": 0.2,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1221,
    "disabled": null,
    "type": "meal",
    "name": "Century egg",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "whole",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "century egg",
    "weight-g": 115,
    "volume-ml": null,
    "calories": 177,
    "fat": 11.1,
    "saturated": 3.4,
    "polyunsaturated": 1.5,
    "monounsaturated": 6.2,
    "trans": null,
    "cholesterol": 629,
    "sodium": 566,
    "carbs": 4.7,
    "fibre": 0,
    "sugar": 4.7,
    "protein": 14.7,
    "potassium": 157,
    "calcium": 65,
    "iron": 3.4,
    "phosphorus": 171,
    "info": null
  },
  {
    "id": 1222,
    "disabled": null,
    "type": "meal",
    "name": "Cereal chicken",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cereal chicken",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 234,
    "fat": 13,
    "saturated": 8.2,
    "polyunsaturated": 0.5,
    "monounsaturated": 3.8,
    "trans": 0.5,
    "cholesterol": 87,
    "sodium": 353,
    "carbs": 7.1,
    "fibre": 0.5,
    "sugar": 4.3,
    "protein": 20.7,
    "potassium": 167,
    "calcium": 21,
    "iron": 1.1,
    "phosphorus": 117,
    "info": null
  },
  {
    "id": 1223,
    "disabled": null,
    "type": "meal",
    "name": "Cheese tofu",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cheese tofu",
    "weight-g": 25,
    "volume-ml": null,
    "calories": 51.8,
    "fat": 3.9,
    "saturated": 1.5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 3.1,
    "sodium": 205.5,
    "carbs": 1.6,
    "fibre": 0.3,
    "sugar": null,
    "protein": 2.7,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1224,
    "disabled": null,
    "type": "snack",
    "name": "Chestnut, roasted",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chestnut, roasted",
    "weight-g": 50,
    "volume-ml": null,
    "calories": 120,
    "fat": 0.6,
    "saturated": 0.1,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 2,
    "carbs": 26.2,
    "fibre": 1.1,
    "sugar": 1,
    "protein": 2.3,
    "potassium": 239,
    "calcium": 10,
    "iron": 0.8,
    "phosphorus": 51,
    "info": null
  },
  {
    "id": 1225,
    "disabled": null,
    "type": "meal",
    "name": "Chicken drumlets, grilled",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chicken drumlets, grilled",
    "weight-g": 24,
    "volume-ml": null,
    "calories": 64,
    "fat": 4.1,
    "saturated": 1.2,
    "polyunsaturated": 0.5,
    "monounsaturated": 2.1,
    "trans": null,
    "cholesterol": 40,
    "sodium": 20,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 6.9,
    "potassium": 62,
    "calcium": 6,
    "iron": 0.2,
    "phosphorus": 59,
    "info": null
  },
  {
    "id": 1226,
    "disabled": null,
    "type": "meal",
    "name": "Chicken patty, grilled",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chicken patty, grilled",
    "weight-g": 85,
    "volume-ml": null,
    "calories": 154,
    "fat": 6.9,
    "saturated": 1.9,
    "polyunsaturated": 1.1,
    "monounsaturated": 3.3,
    "trans": null,
    "cholesterol": 90,
    "sodium": 47,
    "carbs": 1.4,
    "fibre": 0,
    "sugar": 0,
    "protein": 21.7,
    "potassium": 254,
    "calcium": 11,
    "iron": 0.7,
    "phosphorus": 199,
    "info": null
  },
  {
    "id": 1227,
    "disabled": null,
    "type": "meal",
    "name": "Stir fried chives with egg",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "stir fried chives with egg",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 128,
    "fat": 10.4,
    "saturated": 2.1,
    "polyunsaturated": 3.7,
    "monounsaturated": 3.5,
    "trans": 0,
    "cholesterol": 178,
    "sodium": 383,
    "carbs": 1.6,
    "fibre": 0.5,
    "sugar": 1.6,
    "protein": 7,
    "potassium": 249,
    "calcium": 27,
    "iron": 1.4,
    "phosphorus": 100,
    "info": null
  },
  {
    "id": 1228,
    "disabled": 1,
    "type": "drink",
    "name": "Protein powder, vegan",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": "Nutrilite",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "protein powder, vegan nutrilite",
    "weight-g": 12.5,
    "volume-ml": null,
    "calories": 50,
    "fat": 0.5,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 125,
    "carbs": null,
    "fibre": null,
    "sugar": null,
    "protein": 10,
    "potassium": 0,
    "calcium": 25,
    "iron": 1,
    "phosphorus": null,
    "info": "e.g. Nutrilite"
  },
  {
    "id": 1229,
    "disabled": null,
    "type": "snack",
    "name": "Coffee bun",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "coffee bun",
    "weight-g": 90,
    "volume-ml": null,
    "calories": 356,
    "fat": 18.2,
    "saturated": 10.8,
    "polyunsaturated": 1.5,
    "monounsaturated": 5.5,
    "trans": 0.3,
    "cholesterol": 33,
    "sodium": 426,
    "carbs": 39.9,
    "fibre": 0.9,
    "sugar": 15.7,
    "protein": 8.1,
    "potassium": 131,
    "calcium": 49,
    "iron": 0.7,
    "phosphorus": 86,
    "info": null
  },
  {
    "id": 1230,
    "disabled": null,
    "type": "snack",
    "name": "Corn thins",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "corn thins",
    "weight-g": 12,
    "volume-ml": null,
    "calories": 47,
    "fat": 0.4,
    "saturated": 0.1,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": null,
    "sodium": 31,
    "carbs": 8.7,
    "fibre": 1.2,
    "sugar": 0.1,
    "protein": 1.3,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1231,
    "disabled": null,
    "type": "snack",
    "name": "Cottage cheese",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cottage cheese",
    "weight-g": 20,
    "volume-ml": null,
    "calories": 29,
    "fat": 1.9,
    "saturated": 1.3,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.5,
    "trans": null,
    "cholesterol": 7,
    "sodium": 40,
    "carbs": 0.1,
    "fibre": 0,
    "sugar": 0.1,
    "protein": 3.1,
    "potassium": 19,
    "calcium": 13,
    "iron": 0,
    "phosphorus": 39,
    "info": null
  },
  {
    "id": 1232,
    "disabled": null,
    "type": "meal",
    "name": "Dou miao, stir fried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "handful",
    "info-translation": "Pea shoots",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[dou|tow] [miao|meow], stir fried pea shoots",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 129,
    "fat": 12,
    "saturated": 5.1,
    "polyunsaturated": 1.4,
    "monounsaturated": 4.9,
    "trans": null,
    "cholesterol": 0,
    "sodium": 352,
    "carbs": 1.4,
    "fibre": 1.1,
    "sugar": 0,
    "protein": 3.8,
    "potassium": 124,
    "calcium": 17,
    "iron": 0.5,
    "phosphorus": 43,
    "info": "Pea shoots"
  },
  {
    "id": 1233,
    "disabled": null,
    "type": "meal",
    "name": "Duck noodle, soup",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "duck noodle, soup",
    "weight-g": 539,
    "volume-ml": null,
    "calories": 507,
    "fat": 18.9,
    "saturated": 6.5,
    "polyunsaturated": 3.2,
    "monounsaturated": 8.1,
    "trans": null,
    "cholesterol": 108,
    "sodium": 1926,
    "carbs": 62,
    "fibre": 3.2,
    "sugar": 1.8,
    "protein": 22.1,
    "potassium": 302,
    "calcium": 70,
    "iron": 1.4,
    "phosphorus": 199,
    "info": null
  },
  {
    "id": 1234,
    "disabled": null,
    "type": "meal",
    "name": "Egg mayonnaise",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "egg mayonnaise",
    "weight-g": 65,
    "volume-ml": null,
    "calories": 110,
    "fat": 8.5,
    "saturated": 2,
    "polyunsaturated": 2.6,
    "monounsaturated": 3.1,
    "trans": null,
    "cholesterol": 211,
    "sodium": 141,
    "carbs": 2.2,
    "fibre": 0,
    "sugar": 1.6,
    "protein": 6.5,
    "potassium": 53,
    "calcium": 22,
    "iron": 0.9,
    "phosphorus": 72,
    "info": null
  },
  {
    "id": 1235,
    "disabled": null,
    "type": "meal",
    "name": "Fajita, with meat and vegetables",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fajita, with meat and vegetables",
    "weight-g": 142,
    "volume-ml": null,
    "calories": 234,
    "fat": 10.1,
    "saturated": 3.6,
    "polyunsaturated": 1.9,
    "monounsaturated": 3.1,
    "trans": null,
    "cholesterol": 38.3,
    "sodium": 498,
    "carbs": 20.9,
    "fibre": 2.1,
    "sugar": 3.9,
    "protein": 14.7,
    "potassium": 317,
    "calcium": 80.9,
    "iron": 2.1,
    "phosphorus": 182,
    "info": null
  },
  {
    "id": 1236,
    "disabled": null,
    "type": "snack",
    "name": "Figs, preserved",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": 1,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with sugar",
    "search-text": "figs, preserved",
    "weight-g": 20,
    "volume-ml": null,
    "calories": 47,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": null,
    "sodium": 1.8,
    "carbs": 13,
    "fibre": null,
    "sugar": 12,
    "protein": 0,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "with sugar"
  },
  {
    "id": 1237,
    "disabled": null,
    "type": "meal",
    "name": "Stew, fish",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "stew, fish",
    "weight-g": 227,
    "volume-ml": null,
    "calories": 225,
    "fat": 8.5,
    "saturated": 2,
    "polyunsaturated": 1.3,
    "monounsaturated": 3.9,
    "trans": null,
    "cholesterol": 93.1,
    "sodium": 506,
    "carbs": 4.9,
    "fibre": 0.7,
    "sugar": 1.4,
    "protein": 30.5,
    "potassium": 638,
    "calcium": 74.9,
    "iron": 1.6,
    "phosphorus": 384,
    "info": null
  },
  {
    "id": 1238,
    "disabled": null,
    "type": "meal",
    "name": "Focaccia",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "focaccia",
    "weight-g": 53,
    "volume-ml": null,
    "calories": 163,
    "fat": 4.2,
    "saturated": 0.6,
    "polyunsaturated": 0.5,
    "monounsaturated": 2.9,
    "trans": null,
    "cholesterol": 0,
    "sodium": 340,
    "carbs": 25.6,
    "fibre": 1.4,
    "sugar": 1,
    "protein": 5.6,
    "potassium": 68,
    "calcium": 34,
    "iron": 0.8,
    "phosphorus": 60,
    "info": null
  },
  {
    "id": 1239,
    "disabled": null,
    "type": "meal",
    "name": "Cucumber, stir fried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cucumber, stir fried",
    "weight-g": 185,
    "volume-ml": null,
    "calories": 81.4,
    "fat": 5.8,
    "saturated": 1.7,
    "polyunsaturated": 1.8,
    "monounsaturated": 2,
    "trans": null,
    "cholesterol": 3.7,
    "sodium": 270,
    "carbs": 7.7,
    "fibre": 1.1,
    "sugar": 3.5,
    "protein": 1.4,
    "potassium": 311,
    "calcium": 35.2,
    "iron": 0.6,
    "phosphorus": 51.8,
    "info": null
  },
  {
    "id": 1240,
    "disabled": null,
    "type": "meal",
    "name": "Brown rice, fried",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with vegetables",
    "search-text": "brown rice, fried",
    "weight-g": 315,
    "volume-ml": null,
    "calories": 564,
    "fat": 16.6,
    "saturated": 6.7,
    "polyunsaturated": 2.6,
    "monounsaturated": 6.6,
    "trans": 0,
    "cholesterol": 7,
    "sodium": 1270,
    "carbs": 89,
    "fibre": 3.7,
    "sugar": 1.3,
    "protein": 14.6,
    "potassium": 303,
    "calcium": 96,
    "iron": 0.2,
    "phosphorus": 663,
    "info": "with vegetables"
  },
  {
    "id": 1241,
    "disabled": null,
    "type": "meal",
    "name": "Udon, stir fried",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with meat",
    "search-text": "udon, stir fried",
    "weight-g": 445,
    "volume-ml": null,
    "calories": 516,
    "fat": 23.9,
    "saturated": 9,
    "polyunsaturated": 5.1,
    "monounsaturated": 9.7,
    "trans": 0.1,
    "cholesterol": null,
    "sodium": 1927,
    "carbs": 52.1,
    "fibre": 5.8,
    "sugar": 7.1,
    "protein": 23.6,
    "potassium": null,
    "calcium": 91,
    "iron": 3.8,
    "phosphorus": null,
    "info": "with meat"
  },
  {
    "id": 1242,
    "disabled": null,
    "type": "meal",
    "name": "Bread, with nut",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "walnut",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bread, with nut walnut",
    "weight-g": 22,
    "volume-ml": null,
    "calories": 50,
    "fat": 1.1,
    "saturated": 0.2,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 0.5,
    "sodium": 100,
    "carbs": 8.4,
    "fibre": 0.8,
    "sugar": 0.3,
    "protein": 2,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. walnut"
  },
  {
    "id": 1243,
    "disabled": null,
    "type": "drink",
    "name": "Wheatgrass juice",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "without sugar",
    "search-text": "wheatgrass juice",
    "weight-g": 200,
    "volume-ml": null,
    "calories": 4,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 1,
    "carbs": 1.1,
    "fibre": 0.2,
    "sugar": 1.1,
    "protein": 0,
    "potassium": 706,
    "calcium": 15,
    "iron": 1.5,
    "phosphorus": 204,
    "info": "without sugar"
  },
  {
    "id": 1244,
    "disabled": null,
    "type": "meal",
    "name": "Vegetarian noodles, soup",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "vegetarian noodles, soup",
    "weight-g": 601,
    "volume-ml": null,
    "calories": 281,
    "fat": 3,
    "saturated": 1,
    "polyunsaturated": 1,
    "monounsaturated": 1,
    "trans": 0,
    "cholesterol": 5,
    "sodium": 1363,
    "carbs": 57,
    "fibre": 5,
    "sugar": 1,
    "protein": 9,
    "potassium": 305,
    "calcium": 23,
    "iron": 1,
    "phosphorus": 145,
    "info": null
  },
  {
    "id": 1245,
    "disabled": null,
    "type": "meal",
    "name": "Vegetarian dumpling, soup",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "vegetarian dumpling, soup",
    "weight-g": 209,
    "volume-ml": null,
    "calories": 196,
    "fat": 4,
    "saturated": 0,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 690,
    "carbs": 41,
    "fibre": 4,
    "sugar": 4,
    "protein": 8,
    "potassium": 514,
    "calcium": 81.5,
    "iron": 1,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1246,
    "disabled": null,
    "type": "meal",
    "name": "Pasta, stir fried with vegetables",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pasta, stir fried with vegetables",
    "weight-g": 235,
    "volume-ml": null,
    "calories": 424,
    "fat": 11.9,
    "saturated": 1.6,
    "polyunsaturated": 5.1,
    "monounsaturated": 3,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 400,
    "carbs": 67.3,
    "fibre": 3.8,
    "sugar": 3.8,
    "protein": 12.1,
    "potassium": 117,
    "calcium": 36,
    "iron": 0.3,
    "phosphorus": 26,
    "info": null
  },
  {
    "id": 1247,
    "disabled": null,
    "type": "meal",
    "name": "Vada",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[vada|bada|wada|vade|vadai]",
    "weight-g": 60,
    "volume-ml": null,
    "calories": 181,
    "fat": 10.6,
    "saturated": 4.3,
    "polyunsaturated": 1.8,
    "monounsaturated": 4.1,
    "trans": null,
    "cholesterol": 0,
    "sodium": 317,
    "carbs": 13.9,
    "fibre": 4.4,
    "sugar": 0.7,
    "protein": 7.4,
    "potassium": 31,
    "calcium": 28,
    "iron": 1.4,
    "phosphorus": 105,
    "info": null
  },
  {
    "id": 1248,
    "disabled": null,
    "type": "meal",
    "name": "Unagi",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": "Japanese marinated eel",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "unagi japanese marinated eel",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 296,
    "fat": 22.3,
    "saturated": 6.5,
    "polyunsaturated": 0.5,
    "monounsaturated": 12,
    "trans": null,
    "cholesterol": 22,
    "sodium": 358,
    "carbs": 5.5,
    "fibre": 0.1,
    "sugar": 4.7,
    "protein": 18.4,
    "potassium": 925,
    "calcium": 164,
    "iron": 0.5,
    "phosphorus": 1120,
    "info": "Japanese marinated eel"
  },
  {
    "id": 1249,
    "disabled": null,
    "type": "meal",
    "name": "Wholemeal wrap, without fillings",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "wholemeal wrap, without fillings",
    "weight-g": 45,
    "volume-ml": null,
    "calories": 134,
    "fat": 3,
    "saturated": 1.6,
    "polyunsaturated": 0.2,
    "monounsaturated": 1.2,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 280,
    "carbs": 23.2,
    "fibre": 1.5,
    "sugar": 1.7,
    "protein": 3.6,
    "potassium": null,
    "calcium": 45,
    "iron": 0.4,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1250,
    "disabled": null,
    "type": "meal",
    "name": "Tomato, boiled",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tomato, boiled",
    "weight-g": 240,
    "volume-ml": null,
    "calories": 43,
    "fat": 0.3,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 26,
    "carbs": 9.6,
    "fibre": 1.7,
    "sugar": 6,
    "protein": 2.3,
    "potassium": 523,
    "calcium": 26,
    "iron": 1.6,
    "phosphorus": 67,
    "info": null
  },
  {
    "id": 1251,
    "disabled": null,
    "type": "meal",
    "name": "Salad with seafood",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "without dressing",
    "search-text": "salad with seafood",
    "weight-g": 78,
    "volume-ml": null,
    "calories": 52,
    "fat": 1.3,
    "saturated": 0.3,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.6,
    "trans": 0,
    "cholesterol": 46,
    "sodium": 196,
    "carbs": 2.9,
    "fibre": 1,
    "sugar": 1.2,
    "protein": 7,
    "potassium": 153,
    "calcium": 36,
    "iron": 0.5,
    "phosphorus": 68,
    "info": "without dressing"
  },
  {
    "id": 1252,
    "disabled": null,
    "type": "snack",
    "name": "Tau sar piah",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Red bean pastry",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "sweet",
    "search-text": "[tau|dou] [sar|sha] [piah|bing] red bean pastry",
    "weight-g": 47,
    "volume-ml": null,
    "calories": 215,
    "fat": 12.1,
    "saturated": 5,
    "polyunsaturated": 1.7,
    "monounsaturated": 4.9,
    "trans": null,
    "cholesterol": 10,
    "sodium": 9,
    "carbs": 23.4,
    "fibre": 0.6,
    "sugar": 9.7,
    "protein": 3.2,
    "potassium": 79,
    "calcium": 7,
    "iron": 0.5,
    "phosphorus": 39,
    "info": "Red bean pastry – sweet"
  },
  {
    "id": 1253,
    "disabled": null,
    "type": "meal",
    "name": "Taiyaki, red bean",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Japanese fish shaped cake",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "taiyaki, red bean japanese fish shaped cake",
    "weight-g": 76,
    "volume-ml": null,
    "calories": 204,
    "fat": 5.2,
    "saturated": 2.1,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 8.4,
    "sodium": 212.8,
    "carbs": 34.9,
    "fibre": 0.3,
    "sugar": null,
    "protein": 4.3,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "Japanese fish shaped cake"
  },
  {
    "id": 1254,
    "disabled": null,
    "type": "meal",
    "name": "Sweetener, non-caloric",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "sachet",
    "info-translation": null,
    "info-brands": "Equal, Splenda, Stevia",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sweetener, non-caloric equal, splenda, stevia",
    "weight-g": 2,
    "volume-ml": null,
    "calories": 0,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 0,
    "potassium": 0,
    "calcium": 0,
    "iron": 0,
    "phosphorus": 0,
    "info": "e.g. Equal, Splenda, Stevia"
  },
  {
    "id": 1255,
    "disabled": null,
    "type": "meal",
    "name": "Prawn / shrimp, stir fried",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 6,
    "unit": "pieces",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "prawn / shrimp, stir fried",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 35,
    "fat": 1.4,
    "saturated": 0.2,
    "polyunsaturated": 0.5,
    "monounsaturated": 0.5,
    "trans": null,
    "cholesterol": 45,
    "sodium": 134,
    "carbs": 0.4,
    "fibre": 0,
    "sugar": 0,
    "protein": 4.9,
    "potassium": 41,
    "calcium": 20,
    "iron": 0.1,
    "phosphorus": 87,
    "info": null
  },
  {
    "id": 1256,
    "disabled": null,
    "type": "meal",
    "name": "Stir fried chicken with mushrooms",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "stir fried chicken with mushrooms",
    "weight-g": 121,
    "volume-ml": null,
    "calories": 165,
    "fat": 7.1,
    "saturated": 2,
    "polyunsaturated": 2,
    "monounsaturated": 2.8,
    "trans": null,
    "cholesterol": 25,
    "sodium": 587,
    "carbs": 3.2,
    "fibre": 1.8,
    "sugar": 1.5,
    "protein": 21.9,
    "potassium": 323,
    "calcium": 11,
    "iron": 0.6,
    "phosphorus": 200,
    "info": null
  },
  {
    "id": 1257,
    "disabled": null,
    "type": "meal",
    "name": "Bean curd, steamed / boiled",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "block",
    "info-translation": "Tofu",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bean curd, steamed / boiled tofu",
    "weight-g": 147,
    "volume-ml": null,
    "calories": 150,
    "fat": 6.9,
    "saturated": 1.2,
    "polyunsaturated": 3.4,
    "monounsaturated": 1.5,
    "trans": null,
    "cholesterol": 0,
    "sodium": 3,
    "carbs": 5.9,
    "fibre": 0.6,
    "sugar": 2.9,
    "protein": 16,
    "potassium": 184,
    "calcium": 241,
    "iron": 3.8,
    "phosphorus": 209,
    "info": "Tofu"
  },
  {
    "id": 1258,
    "disabled": null,
    "type": "meal",
    "name": "Chicken, steamed",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chicken, steamed",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 193,
    "fat": 12.3,
    "saturated": 4.7,
    "polyunsaturated": 1.8,
    "monounsaturated": 5,
    "trans": null,
    "cholesterol": 89,
    "sodium": 169,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 20.6,
    "potassium": 200,
    "calcium": 27,
    "iron": 1.2,
    "phosphorus": 150,
    "info": null
  },
  {
    "id": 1259,
    "disabled": null,
    "type": "meal",
    "name": "Pumpkin, steamed",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pumpkin, steamed",
    "weight-g": 200,
    "volume-ml": null,
    "calories": 40,
    "fat": 0.2,
    "saturated": 0.1,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 2,
    "carbs": 9.8,
    "fibre": 2.2,
    "sugar": 0,
    "protein": 1.4,
    "potassium": 460,
    "calcium": 30,
    "iron": 1.2,
    "phosphorus": 60,
    "info": null
  },
  {
    "id": 1260,
    "disabled": null,
    "type": "snack",
    "name": "Fruit jelly",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fruit jelly",
    "weight-g": 42,
    "volume-ml": null,
    "calories": 28,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 37,
    "carbs": 6.2,
    "fibre": 0.3,
    "sugar": 5.3,
    "protein": 0.3,
    "potassium": 210,
    "calcium": 8,
    "iron": 0,
    "phosphorus": 2,
    "info": null
  },
  {
    "id": 1261,
    "disabled": null,
    "type": "drink",
    "name": "Vitamin water",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bottle",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "vitamin water",
    "weight-g": 500,
    "volume-ml": null,
    "calories": 90,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 22,
    "fibre": 0,
    "sugar": 21.5,
    "protein": 0,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1262,
    "disabled": null,
    "type": "drink",
    "name": "Honey lemon drink",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "glass",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "honey lemon drink",
    "weight-g": 250,
    "volume-ml": null,
    "calories": 95,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 28,
    "carbs": 23.8,
    "fibre": 0,
    "sugar": 22.5,
    "protein": 0,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1263,
    "disabled": null,
    "type": "meal",
    "name": "Instant soup, sachet",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "sachet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "mushroom",
    "info-portion-aid": null,
    "info-include-exclude": "with cream",
    "search-text": "instant soup, sachet mushroom",
    "weight-g": 21,
    "volume-ml": null,
    "calories": 89,
    "fat": 3.8,
    "saturated": 0.8,
    "polyunsaturated": 2,
    "monounsaturated": 1,
    "trans": null,
    "cholesterol": null,
    "sodium": 375,
    "carbs": 13,
    "fibre": null,
    "sugar": 3.5,
    "protein": 0.9,
    "potassium": null,
    "calcium": 170,
    "iron": 2,
    "phosphorus": null,
    "info": "with cream, e.g. mushroom"
  },
  {
    "id": 1264,
    "disabled": null,
    "type": "snack",
    "name": "Lemon meringue tart",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tart",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "lemon meringue tart",
    "weight-g": 117,
    "volume-ml": null,
    "calories": 314,
    "fat": 10.2,
    "saturated": 2.1,
    "polyunsaturated": 4.3,
    "monounsaturated": 3.1,
    "trans": null,
    "cholesterol": 52.6,
    "sodium": 201,
    "carbs": 55.2,
    "fibre": 1.4,
    "sugar": 27.9,
    "protein": 1.8,
    "potassium": 104,
    "calcium": 65.5,
    "iron": 0.7,
    "phosphorus": 123,
    "info": null
  },
  {
    "id": 1265,
    "disabled": null,
    "type": "meal",
    "name": "Lobster roll",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "roll",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "lobster roll",
    "weight-g": 238,
    "volume-ml": null,
    "calories": 560,
    "fat": 37.9,
    "saturated": 4.9,
    "polyunsaturated": 15.1,
    "monounsaturated": 17.9,
    "trans": null,
    "cholesterol": 222,
    "sodium": 1063,
    "carbs": 25.5,
    "fibre": 0.8,
    "sugar": 4.1,
    "protein": 30,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1266,
    "disabled": null,
    "type": "meal",
    "name": "Fish pao fan",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Soup rice",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fish pao fan soup rice",
    "weight-g": 613,
    "volume-ml": null,
    "calories": 597,
    "fat": 18.6,
    "saturated": 6.6,
    "polyunsaturated": 4.1,
    "monounsaturated": 6.4,
    "trans": 0,
    "cholesterol": 62,
    "sodium": 1478,
    "carbs": 70.4,
    "fibre": 3.7,
    "sugar": 0.8,
    "protein": 37,
    "potassium": 606,
    "calcium": 178,
    "iron": 2.1,
    "phosphorus": 392,
    "info": "Soup rice"
  },
  {
    "id": 1267,
    "disabled": null,
    "type": "snack",
    "name": "Green bean soup, sweetened",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "mung bean",
    "info-portion-aid": null,
    "info-include-exclude": "without coconut milk",
    "search-text": "green bean soup, sweetened mung",
    "weight-g": 240,
    "volume-ml": null,
    "calories": 132,
    "fat": 1,
    "saturated": 0,
    "polyunsaturated": 0.5,
    "monounsaturated": 0.5,
    "trans": null,
    "cholesterol": 0,
    "sodium": 2,
    "carbs": 22.6,
    "fibre": 6,
    "sugar": 8.2,
    "protein": 8.2,
    "potassium": 382,
    "calcium": 43,
    "iron": 1.2,
    "phosphorus": 115,
    "info": "without coconut milk, e.g. mung bean"
  },
  {
    "id": 1268,
    "disabled": null,
    "type": "snack",
    "name": "Green bean soup, unsweetened",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "mung bean",
    "info-portion-aid": null,
    "info-include-exclude": "without coconut milk",
    "search-text": "green bean soup, unsweetened mung",
    "weight-g": 240,
    "volume-ml": null,
    "calories": 107,
    "fat": 1,
    "saturated": 0,
    "polyunsaturated": 0.5,
    "monounsaturated": 0.5,
    "trans": null,
    "cholesterol": 0,
    "sodium": 2,
    "carbs": 16.4,
    "fibre": 6,
    "sugar": 2,
    "protein": 8.2,
    "potassium": 382,
    "calcium": 43,
    "iron": 1.2,
    "phosphorus": 115,
    "info": "without coconut milk, e.g. mung bean"
  },
  {
    "id": 1269,
    "disabled": null,
    "type": "meal",
    "name": "Oden",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": "Japanese stew",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with fish cakes, daikon, potatoes, eggs and konbu",
    "search-text": "oden japanese stew",
    "weight-g": 300,
    "volume-ml": null,
    "calories": 373,
    "fat": 16.7,
    "saturated": 2.6,
    "polyunsaturated": 5.8,
    "monounsaturated": 8.3,
    "trans": 0,
    "cholesterol": 238,
    "sodium": 975,
    "carbs": 32.2,
    "fibre": 1.8,
    "sugar": 1.7,
    "protein": 21.9,
    "potassium": 515,
    "calcium": 60,
    "iron": 2.2,
    "phosphorus": 80,
    "info": "Japanese stew – with fish cakes, daikon, potatoes, eggs and konbu"
  },
  {
    "id": 1270,
    "disabled": null,
    "type": "meal",
    "name": "Soup with old cucumber",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soup with old cucumber",
    "weight-g": 450,
    "volume-ml": null,
    "calories": 41,
    "fat": 2.3,
    "saturated": 0.9,
    "polyunsaturated": 0.2,
    "monounsaturated": 1.1,
    "trans": null,
    "cholesterol": null,
    "sodium": 545,
    "carbs": 2.3,
    "fibre": null,
    "sugar": 1.8,
    "protein": 3.6,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1271,
    "disabled": null,
    "type": "snack",
    "name": "Otah bun",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[otah|otak|otar] bun",
    "weight-g": 67,
    "volume-ml": null,
    "calories": 190,
    "fat": 5.5,
    "saturated": 3.8,
    "polyunsaturated": 0.3,
    "monounsaturated": 1.1,
    "trans": null,
    "cholesterol": 27,
    "sodium": 264,
    "carbs": 28.1,
    "fibre": 1.3,
    "sugar": 0.5,
    "protein": 7.1,
    "potassium": null,
    "calcium": 29,
    "iron": 1,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1272,
    "disabled": null,
    "type": "meal",
    "name": "Chicken breast, without skin, pan fried",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chicken breast, without skin, pan fried",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 187,
    "fat": 4.7,
    "saturated": 1.3,
    "polyunsaturated": 1,
    "monounsaturated": 1.7,
    "trans": null,
    "cholesterol": 91,
    "sodium": 79,
    "carbs": 0.5,
    "fibre": 0,
    "sugar": 0,
    "protein": 33.4,
    "potassium": 276,
    "calcium": 16,
    "iron": 1.1,
    "phosphorus": 246,
    "info": null
  },
  {
    "id": 1273,
    "disabled": null,
    "type": "meal",
    "name": "Curd, plain",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "curd, plain",
    "weight-g": 225,
    "volume-ml": null,
    "calories": 135,
    "fat": 9,
    "saturated": 5.9,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 72,
    "carbs": 6.5,
    "fibre": null,
    "sugar": null,
    "protein": 6.9,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1274,
    "disabled": 1,
    "type": "meal",
    "name": "Poke rice bowl",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "poke rice bowl",
    "weight-g": 378,
    "volume-ml": null,
    "calories": 490,
    "fat": 22,
    "saturated": 3,
    "polyunsaturated": 10,
    "monounsaturated": 7,
    "trans": null,
    "cholesterol": null,
    "sodium": 1000,
    "carbs": 51,
    "fibre": 2,
    "sugar": 4,
    "protein": 22,
    "potassium": 344,
    "calcium": 80,
    "iron": 2,
    "phosphorus": 273,
    "info": null
  },
  {
    "id": 1275,
    "disabled": null,
    "type": "meal",
    "name": "Porridge with sweet potato",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "porridge with sweet potato",
    "weight-g": 362,
    "volume-ml": null,
    "calories": 167,
    "fat": 1.5,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 7,
    "carbs": 34.8,
    "fibre": 1.5,
    "sugar": 2.2,
    "protein": 4.3,
    "potassium": 177,
    "calcium": 18,
    "iron": 0,
    "phosphorus": 22,
    "info": null
  },
  {
    "id": 1276,
    "disabled": null,
    "type": "meal",
    "name": "Quail egg",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "egg",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "quail egg",
    "weight-g": 12,
    "volume-ml": null,
    "calories": 18,
    "fat": 1.3,
    "saturated": 0.6,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.6,
    "trans": null,
    "cholesterol": 54,
    "sodium": 12,
    "carbs": 0.1,
    "fibre": 0,
    "sugar": 0,
    "protein": 1.3,
    "potassium": 14,
    "calcium": 6,
    "iron": 0.4,
    "phosphorus": 26,
    "info": null
  },
  {
    "id": 1277,
    "disabled": null,
    "type": "meal",
    "name": "Rosti potatoes",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "rosti potatoes",
    "weight-g": 103,
    "volume-ml": null,
    "calories": 166,
    "fat": 11.6,
    "saturated": 5.1,
    "polyunsaturated": 1,
    "monounsaturated": 10.8,
    "trans": null,
    "cholesterol": 0,
    "sodium": 20,
    "carbs": 13.1,
    "fibre": 1.9,
    "sugar": 3.7,
    "protein": 2.3,
    "potassium": 174,
    "calcium": 8,
    "iron": 0.1,
    "phosphorus": 18,
    "info": null
  },
  {
    "id": 1278,
    "disabled": null,
    "type": "meal",
    "name": "Salmon roe",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "salmon roe",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 167,
    "fat": 9.7,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 300,
    "sodium": null,
    "carbs": 6.7,
    "fibre": 0,
    "sugar": 0,
    "protein": 13.3,
    "potassium": null,
    "calcium": 133,
    "iron": 3.6,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1279,
    "disabled": null,
    "type": "meal",
    "name": "Sambal ikan bilis",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sambal ikan bilis",
    "weight-g": 15,
    "volume-ml": null,
    "calories": 31,
    "fat": 2.1,
    "saturated": 0.3,
    "polyunsaturated": 1.4,
    "monounsaturated": 0.4,
    "trans": 0,
    "cholesterol": 6,
    "sodium": 251,
    "carbs": 1.5,
    "fibre": 0.6,
    "sugar": 1.3,
    "protein": 1.6,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1280,
    "disabled": null,
    "type": "meal",
    "name": "Scallop, pan fried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "scallop, pan fried",
    "weight-g": 13,
    "volume-ml": null,
    "calories": 15,
    "fat": 0.6,
    "saturated": 0.1,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.2,
    "trans": null,
    "cholesterol": 3.8,
    "sodium": 64.6,
    "carbs": 0.5,
    "fibre": 0,
    "sugar": 0,
    "protein": 1.9,
    "potassium": 32,
    "calcium": 1,
    "iron": 0.1,
    "phosphorus": 51.7,
    "info": null
  },
  {
    "id": 1281,
    "disabled": null,
    "type": "meal",
    "name": "Schnitzel",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "schnitzel",
    "weight-g": 170,
    "volume-ml": null,
    "calories": 462,
    "fat": 23.9,
    "saturated": 4.6,
    "polyunsaturated": 4.9,
    "monounsaturated": 12.9,
    "trans": null,
    "cholesterol": 171,
    "sodium": 511,
    "carbs": 22.6,
    "fibre": 1.3,
    "sugar": 0.9,
    "protein": 38.2,
    "potassium": 455,
    "calcium": 40,
    "iron": 1.6,
    "phosphorus": 374,
    "info": null
  },
  {
    "id": 1282,
    "disabled": null,
    "type": "meal",
    "name": "Seafood stew",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "seafood stew",
    "weight-g": 456,
    "volume-ml": null,
    "calories": 240,
    "fat": 13.9,
    "saturated": 4.7,
    "polyunsaturated": 3.9,
    "monounsaturated": 4.3,
    "trans": 0,
    "cholesterol": 37,
    "sodium": 1041,
    "carbs": 6,
    "fibre": 1,
    "sugar": 3.2,
    "protein": 22.7,
    "potassium": 571,
    "calcium": 102,
    "iron": 3.4,
    "phosphorus": 356,
    "info": null
  },
  {
    "id": 1283,
    "disabled": null,
    "type": "meal",
    "name": "Yam ring, with chicken",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[yam|taro] ring, with chicken",
    "weight-g": 257,
    "volume-ml": null,
    "calories": 388,
    "fat": 18.4,
    "saturated": 6.1,
    "polyunsaturated": 2.9,
    "monounsaturated": 8.4,
    "trans": 0,
    "cholesterol": 15,
    "sodium": 1006,
    "carbs": 44.8,
    "fibre": 7,
    "sugar": 8.9,
    "protein": 12.4,
    "potassium": 972,
    "calcium": 48,
    "iron": 2.2,
    "phosphorus": 201,
    "info": null
  },
  {
    "id": 1284,
    "disabled": null,
    "type": "snack",
    "name": "Soy chunks, dried",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Meal maker",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[soy|soya] chunks, dried meal maker",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 104,
    "fat": 2.2,
    "saturated": 0.3,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 6,
    "carbs": 4.6,
    "fibre": 0.6,
    "sugar": 3,
    "protein": 13.4,
    "potassium": 651,
    "calcium": 72,
    "iron": 2.8,
    "phosphorus": 202,
    "info": "Meal maker"
  },
  {
    "id": 1285,
    "disabled": null,
    "type": "meal",
    "name": "Chinese spinach with trio eggs",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with century and salted eggs",
    "search-text": "chinese spinach with trio eggs",
    "weight-g": 146,
    "volume-ml": null,
    "calories": 125,
    "fat": 8.6,
    "saturated": 3,
    "polyunsaturated": 1.1,
    "monounsaturated": 3.9,
    "trans": null,
    "cholesterol": 243,
    "sodium": 613,
    "carbs": 4,
    "fibre": 1.4,
    "sugar": 1,
    "protein": 9.1,
    "potassium": 404,
    "calcium": 123,
    "iron": 3.4,
    "phosphorus": 296,
    "info": "with century and salted eggs"
  },
  {
    "id": 1286,
    "disabled": null,
    "type": "meal",
    "name": "Soup with spinach and meat",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "soup with spinach and meat",
    "weight-g": 250,
    "volume-ml": null,
    "calories": 86,
    "fat": 6,
    "saturated": 2,
    "polyunsaturated": 2,
    "monounsaturated": 2,
    "trans": null,
    "cholesterol": 35,
    "sodium": 375,
    "carbs": 1,
    "fibre": 0.8,
    "sugar": 0,
    "protein": 7,
    "potassium": 175,
    "calcium": 25,
    "iron": 0.5,
    "phosphorus": 28,
    "info": null
  },
  {
    "id": 1287,
    "disabled": null,
    "type": "meal",
    "name": "Salad with seafood, with dressing",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": 1,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": 1,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "salad with seafood, dressing",
    "weight-g": 208,
    "volume-ml": null,
    "calories": 395,
    "fat": 33.3,
    "saturated": 5.2,
    "polyunsaturated": 19.6,
    "monounsaturated": 7.4,
    "trans": null,
    "cholesterol": 191,
    "sodium": 1200,
    "carbs": 1.6,
    "fibre": 0.8,
    "sugar": 1.2,
    "protein": 21,
    "potassium": 345,
    "calcium": 139,
    "iron": 1.2,
    "phosphorus": 237,
    "info": null
  },
  {
    "id": 1288,
    "disabled": null,
    "type": "meal",
    "name": "Tau kwa, braised",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tau kwa, braised",
    "weight-g": 83,
    "volume-ml": null,
    "calories": 129,
    "fat": 7,
    "saturated": 1.3,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 216,
    "carbs": 0.7,
    "fibre": 3.1,
    "sugar": 0,
    "protein": 16,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1289,
    "disabled": null,
    "type": "meal",
    "name": "Steamed mixed vegetables",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "steamed mixed vegetables",
    "weight-g": 226,
    "volume-ml": null,
    "calories": 90,
    "fat": 1,
    "saturated": 0,
    "polyunsaturated": 0.5,
    "monounsaturated": 0.5,
    "trans": 0,
    "cholesterol": 0,
    "sodium": null,
    "carbs": 16,
    "fibre": 8,
    "sugar": 5,
    "protein": 3,
    "potassium": null,
    "calcium": 60,
    "iron": 1.8,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1290,
    "disabled": null,
    "type": "meal",
    "name": "Beancurd, silken (Japanese style)",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": "Tofu",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "beancurd, silken (japanese style) tofu",
    "weight-g": 90,
    "volume-ml": null,
    "calories": 40,
    "fat": 2,
    "saturated": 1,
    "polyunsaturated": 1,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 1,
    "fibre": 0,
    "sugar": 0,
    "protein": 4,
    "potassium": 115,
    "calcium": 20,
    "iron": 0.7,
    "phosphorus": null,
    "info": "Tofu"
  },
  {
    "id": 1291,
    "disabled": null,
    "type": "snack",
    "name": "Fromage frais, fat free",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fromage frais, fat free",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 50,
    "fat": 0.2,
    "saturated": 0.1,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 4.4,
    "fibre": 0.3,
    "sugar": 3.3,
    "protein": 7.6,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1292,
    "disabled": null,
    "type": "meal",
    "name": "Galaktoboureko",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "galaktoboureko",
    "weight-g": 170,
    "volume-ml": null,
    "calories": 573,
    "fat": 25.7,
    "saturated": 15.5,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 112.6,
    "sodium": null,
    "carbs": 63.9,
    "fibre": 1.1,
    "sugar": 58.5,
    "protein": 6.9,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1293,
    "disabled": null,
    "type": "snack",
    "name": "Friand",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cake",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "friand",
    "weight-g": 60,
    "volume-ml": null,
    "calories": 163,
    "fat": 10.2,
    "saturated": 0.9,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 52,
    "carbs": 17.1,
    "fibre": 1.7,
    "sugar": 8.4,
    "protein": 4.2,
    "potassium": null,
    "calcium": 34,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1294,
    "disabled": null,
    "type": "meal",
    "name": "Green curry with meat",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "green curry with meat",
    "weight-g": 150,
    "volume-ml": null,
    "calories": 255,
    "fat": 19.8,
    "saturated": 14.5,
    "polyunsaturated": 0.9,
    "monounsaturated": 3.3,
    "trans": null,
    "cholesterol": 48,
    "sodium": 711,
    "carbs": 3.6,
    "fibre": 1.1,
    "sugar": 3,
    "protein": 15.5,
    "potassium": 414,
    "calcium": 36,
    "iron": 1.4,
    "phosphorus": 165,
    "info": null
  },
  {
    "id": 1295,
    "disabled": null,
    "type": "meal",
    "name": "Dumpling noodle soup",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with shrimps",
    "search-text": "dumpling noodle soup",
    "weight-g": 600,
    "volume-ml": null,
    "calories": 414,
    "fat": 9.6,
    "saturated": 3.6,
    "polyunsaturated": 1.7,
    "monounsaturated": 4,
    "trans": null,
    "cholesterol": 30,
    "sodium": 2088,
    "carbs": 58.2,
    "fibre": 4.2,
    "sugar": 3.6,
    "protein": 24,
    "potassium": 534,
    "calcium": 192,
    "iron": 3,
    "phosphorus": 156,
    "info": "with shrimps"
  },
  {
    "id": 1296,
    "disabled": null,
    "type": "drink",
    "name": "Margarita",
    "rating": 1,
    "alcohol": 1,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "glass",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "margarita",
    "weight-g": 240,
    "volume-ml": null,
    "calories": 173,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 17,
    "carbs": 43,
    "fibre": 0,
    "sugar": 40,
    "protein": 0,
    "potassium": 0,
    "calcium": 0,
    "iron": 0,
    "phosphorus": 0,
    "info": null
  },
  {
    "id": 1297,
    "disabled": null,
    "type": "meal",
    "name": "Bread, multigrain",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bread, multigrain",
    "weight-g": 32,
    "volume-ml": null,
    "calories": 85,
    "fat": 1.1,
    "saturated": 0.3,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 93.1,
    "carbs": 15,
    "fibre": 1.8,
    "sugar": null,
    "protein": 3.8,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1298,
    "disabled": null,
    "type": "meal",
    "name": "Pan-fried potato slice",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pan-fried potato slice",
    "weight-g": 10,
    "volume-ml": null,
    "calories": 12,
    "fat": 0.5,
    "saturated": 0.1,
    "polyunsaturated": 0.3,
    "monounsaturated": 0.1,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 0.3,
    "carbs": 1.8,
    "fibre": 0.2,
    "sugar": 0.1,
    "protein": 0.2,
    "potassium": 34.3,
    "calcium": 0.5,
    "iron": 0,
    "phosphorus": 4,
    "info": null
  },
  {
    "id": 1299,
    "disabled": null,
    "type": "meal",
    "name": "Pastrami Reuben sandwich",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with white bread",
    "search-text": "pastrami reuben sandwich",
    "weight-g": 400,
    "volume-ml": null,
    "calories": 730,
    "fat": 30,
    "saturated": 9,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 100,
    "sodium": null,
    "carbs": 67,
    "fibre": 5,
    "sugar": 7,
    "protein": 39,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "with white bread"
  },
  {
    "id": 1300,
    "disabled": null,
    "type": "meal",
    "name": "Polvoron",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "polvoron",
    "weight-g": 25,
    "volume-ml": null,
    "calories": 120,
    "fat": 5,
    "saturated": 4.5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": null,
    "carbs": 16,
    "fibre": 1,
    "sugar": 9,
    "protein": 2,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1301,
    "disabled": null,
    "type": "meal",
    "name": "Prawns, baked in garlic butter sauce",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "prawns, baked in garlic butter sauce",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 272,
    "fat": 13.4,
    "saturated": 7.8,
    "polyunsaturated": 1.1,
    "monounsaturated": 3.7,
    "trans": null,
    "cholesterol": 269.8,
    "sodium": null,
    "carbs": 5.2,
    "fibre": 0,
    "sugar": 0,
    "protein": 26,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1302,
    "disabled": null,
    "type": "meal",
    "name": "Pumpkin soup without cream",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "squash",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pumpkin soup without cream squash",
    "weight-g": 242,
    "volume-ml": null,
    "calories": 101,
    "fat": 2,
    "saturated": 0,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 420,
    "carbs": 18,
    "fibre": 2.5,
    "sugar": 4,
    "protein": 2,
    "potassium": null,
    "calcium": 40,
    "iron": 0.5,
    "phosphorus": null,
    "info": "e.g. squash"
  },
  {
    "id": 1303,
    "disabled": null,
    "type": "meal",
    "name": "Sans rival",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sans rival",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 470,
    "fat": 33.1,
    "saturated": 15.7,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 36.8,
    "fibre": 0.8,
    "sugar": 30.5,
    "protein": 7.6,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1304,
    "disabled": null,
    "type": "meal",
    "name": "Sesame oil chicken",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": "Ma You Ji",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sesame oil chicken ma you ji",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 208,
    "fat": 15.2,
    "saturated": 3.3,
    "polyunsaturated": 4.8,
    "monounsaturated": 5.9,
    "trans": null,
    "cholesterol": 49,
    "sodium": 594,
    "carbs": 1.3,
    "fibre": 0.2,
    "sugar": 0.9,
    "protein": 14.2,
    "potassium": 118,
    "calcium": 13,
    "iron": 1,
    "phosphorus": 86,
    "info": "Ma You Ji"
  },
  {
    "id": 1305,
    "disabled": null,
    "type": "meal",
    "name": "Shishamo, baked",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "shishamo, baked",
    "weight-g": 20,
    "volume-ml": null,
    "calories": 31.9,
    "fat": 1.4,
    "saturated": 0.3,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 54,
    "sodium": 115.2,
    "carbs": 0,
    "fibre": null,
    "sugar": null,
    "protein": 4.4,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1306,
    "disabled": null,
    "type": "meal",
    "name": "Chicken sausage breakfast wrap",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": "McDonald's",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chicken sausage breakfast wrap mcdonald's",
    "weight-g": 157,
    "volume-ml": null,
    "calories": 552,
    "fat": 30,
    "saturated": 10.1,
    "polyunsaturated": 6.6,
    "monounsaturated": 10.4,
    "trans": null,
    "cholesterol": 528,
    "sodium": 1368,
    "carbs": 42.6,
    "fibre": 3,
    "sugar": 1.2,
    "protein": 27.9,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. McDonald's"
  },
  {
    "id": 1307,
    "disabled": null,
    "type": "meal",
    "name": "Chicken chop, grilled, with skin",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chicken chop, grilled, with skin",
    "weight-g": 172,
    "volume-ml": null,
    "calories": 545,
    "fat": 45.6,
    "saturated": 13.3,
    "polyunsaturated": 6.4,
    "monounsaturated": 23.3,
    "trans": 0.2,
    "cholesterol": 185,
    "sodium": 98,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 34.7,
    "potassium": 400,
    "calcium": 22,
    "iron": 1.4,
    "phosphorus": 316,
    "info": null
  },
  {
    "id": 1308,
    "disabled": null,
    "type": "meal",
    "name": "Chicken chop, grilled, without skin",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chicken chop, grilled, without skin",
    "weight-g": 131,
    "volume-ml": null,
    "calories": 233,
    "fat": 12,
    "saturated": 3.2,
    "polyunsaturated": 2,
    "monounsaturated": 6.2,
    "trans": 0.1,
    "cholesterol": 136,
    "sodium": 87,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 31.3,
    "potassium": 383,
    "calcium": 16,
    "iron": 1.1,
    "phosphorus": 301,
    "info": null
  },
  {
    "id": 1309,
    "disabled": null,
    "type": "snack",
    "name": "Falafel",
    "rating": 2,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "falafel",
    "weight-g": 60,
    "volume-ml": null,
    "calories": 148,
    "fat": 6.4,
    "saturated": 1.2,
    "polyunsaturated": 2.4,
    "monounsaturated": 2.5,
    "trans": 0.1,
    "cholesterol": 0,
    "sodium": 350.3,
    "carbs": 17.2,
    "fibre": 7.1,
    "sugar": 2.8,
    "protein": 5.3,
    "potassium": 204,
    "calcium": 40.7,
    "iron": 2.2,
    "phosphorus": 60,
    "info": null
  },
  {
    "id": 1310,
    "disabled": null,
    "type": "meal",
    "name": "Chutney",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chutney",
    "weight-g": 25,
    "volume-ml": null,
    "calories": 46,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 173,
    "carbs": 11.4,
    "fibre": 0.6,
    "sugar": 10.4,
    "protein": 0.1,
    "potassium": 53,
    "calcium": 6,
    "iron": 0.2,
    "phosphorus": 5,
    "info": null
  },
  {
    "id": 1311,
    "disabled": null,
    "type": "meal",
    "name": "Vegetable cutlet",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cutlet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "vegetable cutlet",
    "weight-g": 53,
    "volume-ml": null,
    "calories": 150,
    "fat": 6,
    "saturated": 2,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": null,
    "carbs": 16,
    "fibre": 2,
    "sugar": 2,
    "protein": 5,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1312,
    "disabled": null,
    "type": "snack",
    "name": "Samosa, with vegetable filling",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "samosa, with vegetable filling",
    "weight-g": 68,
    "volume-ml": null,
    "calories": 206,
    "fat": 11.4,
    "saturated": 5.3,
    "polyunsaturated": 1.2,
    "monounsaturated": 4.5,
    "trans": null,
    "cholesterol": 12,
    "sodium": 311,
    "carbs": 22.2,
    "fibre": 1.8,
    "sugar": 1.1,
    "protein": 3.5,
    "potassium": 148,
    "calcium": 25,
    "iron": 0.7,
    "phosphorus": 43,
    "info": null
  },
  {
    "id": 1313,
    "disabled": null,
    "type": "meal",
    "name": "Roasted mixed vegetables",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "roasted mixed vegetables",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 117,
    "fat": 6.7,
    "saturated": 1,
    "polyunsaturated": 0.7,
    "monounsaturated": 4.6,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 184,
    "carbs": 10.4,
    "fibre": 4.5,
    "sugar": 4.8,
    "protein": 2.1,
    "potassium": 356,
    "calcium": 25,
    "iron": 0.5,
    "phosphorus": 54,
    "info": null
  },
  {
    "id": 1314,
    "disabled": null,
    "type": "meal",
    "name": "Pasta, aglio e olio with seafood or meat",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "spaghetti, linguine, macaroni",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pasta, aglio e olio with seafood or meat spaghetti, linguine, macaroni",
    "weight-g": 232,
    "volume-ml": null,
    "calories": 408,
    "fat": 9.3,
    "saturated": 1.7,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 786.5,
    "carbs": 64,
    "fibre": null,
    "sugar": null,
    "protein": 17.4,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. spaghetti, linguine, macaroni"
  },
  {
    "id": 1315,
    "disabled": null,
    "type": "meal",
    "name": "Sandwich with added sugar peanut butter",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "set",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sandwich with added sugar peanut butter",
    "weight-g": 45,
    "volume-ml": null,
    "calories": 200,
    "fat": 7.7,
    "saturated": 1.4,
    "polyunsaturated": 2.5,
    "monounsaturated": 3.5,
    "trans": null,
    "cholesterol": 0,
    "sodium": 147,
    "carbs": 25.6,
    "fibre": 2.4,
    "sugar": 10.7,
    "protein": 6.9,
    "potassium": 109,
    "calcium": 19,
    "iron": 1.2,
    "phosphorus": 83,
    "info": null
  },
  {
    "id": 1316,
    "disabled": null,
    "type": "meal",
    "name": "Pork katsu don",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pork katsu don",
    "weight-g": 599,
    "volume-ml": null,
    "calories": 910,
    "fat": 35.8,
    "saturated": 14.7,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 1533.4,
    "carbs": 103,
    "fibre": 3.6,
    "sugar": null,
    "protein": 44.3,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1317,
    "disabled": null,
    "type": "meal",
    "name": "Soy sauce chicken",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[soy|soya] sauce chicken",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 203,
    "fat": 14.4,
    "saturated": 5,
    "polyunsaturated": 2.9,
    "monounsaturated": 5.7,
    "trans": null,
    "cholesterol": 124,
    "sodium": 659,
    "carbs": 3.7,
    "fibre": 0,
    "sugar": 0,
    "protein": 14.6,
    "potassium": 130,
    "calcium": 66,
    "iron": 1.1,
    "phosphorus": 84,
    "info": null
  },
  {
    "id": 1318,
    "disabled": null,
    "type": "meal",
    "name": "Soy sauce chicken noodles, dry",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[soy|soya] sauce chicken noodles, dry",
    "weight-g": 350,
    "volume-ml": null,
    "calories": 518,
    "fat": 22.1,
    "saturated": 7.7,
    "polyunsaturated": 3.5,
    "monounsaturated": 9.7,
    "trans": null,
    "cholesterol": 70,
    "sodium": 994,
    "carbs": 47.6,
    "fibre": 4.6,
    "sugar": 4.2,
    "protein": 32.2,
    "potassium": 525,
    "calcium": 116,
    "iron": 1.1,
    "phosphorus": 207,
    "info": null
  },
  {
    "id": 1319,
    "disabled": null,
    "type": "meal",
    "name": "Roasted pork noodles",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "roasted pork noodles",
    "weight-g": 412,
    "volume-ml": null,
    "calories": 724,
    "fat": 32.7,
    "saturated": 10.5,
    "polyunsaturated": 9.6,
    "monounsaturated": 11.3,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 3763,
    "carbs": 101.5,
    "fibre": 6.8,
    "sugar": 0.6,
    "protein": 7.4,
    "potassium": 478,
    "calcium": 109,
    "iron": 3.9,
    "phosphorus": 193,
    "info": null
  },
  {
    "id": 1320,
    "disabled": null,
    "type": "snack",
    "name": "Corn dog",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "corn dog",
    "weight-g": 88,
    "volume-ml": null,
    "calories": 220,
    "fat": 10.6,
    "saturated": 3.1,
    "polyunsaturated": 3,
    "monounsaturated": 4.2,
    "trans": null,
    "cholesterol": 38.7,
    "sodium": 588,
    "carbs": 23.8,
    "fibre": 0.9,
    "sugar": 6.6,
    "protein": 7.5,
    "potassium": 110,
    "calcium": 63.4,
    "iron": 1.7,
    "phosphorus": 197,
    "info": null
  },
  {
    "id": 1321,
    "disabled": null,
    "type": "meal",
    "name": "Buckwheat, boiled",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "buckwheat, boiled",
    "weight-g": 168,
    "volume-ml": null,
    "calories": 155,
    "fat": 1,
    "saturated": 0.2,
    "polyunsaturated": 0.3,
    "monounsaturated": 0.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 7,
    "carbs": 33.5,
    "fibre": 4.5,
    "sugar": 1.5,
    "protein": 5.7,
    "potassium": 148,
    "calcium": 12,
    "iron": 1.3,
    "phosphorus": 118,
    "info": null
  },
  {
    "id": 1322,
    "disabled": null,
    "type": "drink",
    "name": "Nutren Diabetes (powder)",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "standard dilution (7 scoops with water)",
    "info-include-exclude": null,
    "search-text": "nutren diabetes (powder)",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 253,
    "fat": 11,
    "saturated": 1,
    "polyunsaturated": 1.8,
    "monounsaturated": 7.1,
    "trans": null,
    "cholesterol": null,
    "sodium": 237,
    "carbs": 24.5,
    "fibre": 4.8,
    "sugar": 2.6,
    "protein": 11.3,
    "potassium": 240,
    "calcium": 204,
    "iron": 3,
    "phosphorus": 138,
    "info": "(standard dilution (7 scoops with water))"
  },
  {
    "id": 1323,
    "disabled": null,
    "type": "drink",
    "name": "Fibrosol",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "sachet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "fibrosol",
    "weight-g": 5,
    "volume-ml": null,
    "calories": 8,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 0.1,
    "carbs": 0.3,
    "fibre": 4.5,
    "sugar": 0.1,
    "protein": 0,
    "potassium": 0,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1324,
    "disabled": null,
    "type": "snack",
    "name": "Psyllium husk",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "psyllium husk",
    "weight-g": 6,
    "volume-ml": null,
    "calories": 20,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": null,
    "carbs": 5.1,
    "fibre": 5.1,
    "sugar": 0,
    "protein": 0,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1325,
    "disabled": null,
    "type": "drink",
    "name": "GNC Lean Shake",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "scoop",
    "info-translation": "Meal replacement / shake",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "standard dilution (1 scoop with water)",
    "info-include-exclude": null,
    "search-text": "gnc lean shake meal replacement / shake",
    "weight-g": 52,
    "volume-ml": null,
    "calories": 200,
    "fat": 3,
    "saturated": 2,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 55,
    "sodium": 120,
    "carbs": 19,
    "fibre": 8,
    "sugar": 3,
    "protein": 25,
    "potassium": 200,
    "calcium": 500,
    "iron": 3.6,
    "phosphorus": 300,
    "info": "Meal replacement / shake (standard dilution (1 scoop with water))"
  },
  {
    "id": 1326,
    "disabled": null,
    "type": "drink",
    "name": "USANA Nutrimeal",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": "Meal replacement / shake",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "standard dilution (1 pouch with water)",
    "info-include-exclude": null,
    "search-text": "usana nutrimeal meal replacement / shake",
    "weight-g": 60,
    "volume-ml": null,
    "calories": 259,
    "fat": 7.4,
    "saturated": 0.8,
    "polyunsaturated": 0.8,
    "monounsaturated": 5.6,
    "trans": 0,
    "cholesterol": 0.1,
    "sodium": 315.6,
    "carbs": 24.6,
    "fibre": 8.3,
    "sugar": 18,
    "protein": 15,
    "potassium": null,
    "calcium": 311.5,
    "iron": 5.1,
    "phosphorus": null,
    "info": "Meal replacement / shake (standard dilution (1 pouch with water))"
  },
  {
    "id": 1327,
    "disabled": null,
    "type": "drink",
    "name": "Amway Nutrilite BodyKey",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": "Meal replacement / shake",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "standard dilution (1 pouch with water)",
    "info-include-exclude": null,
    "search-text": "amway nutrilite bodykey meal replacement / shake",
    "weight-g": 28,
    "volume-ml": null,
    "calories": 112,
    "fat": 4.5,
    "saturated": 0.5,
    "polyunsaturated": 2.7,
    "monounsaturated": 1.1,
    "trans": null,
    "cholesterol": null,
    "sodium": 110,
    "carbs": 14.5,
    "fibre": 5,
    "sugar": 5.9,
    "protein": 6.3,
    "potassium": 125,
    "calcium": 85,
    "iron": 5.3,
    "phosphorus": 85,
    "info": "Meal replacement / shake (standard dilution (1 pouch with water))"
  },
  {
    "id": 1328,
    "disabled": null,
    "type": "drink",
    "name": "Garden of Life Raw Organic Meal",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "scoop",
    "info-translation": "Meal replacement / shake",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "standard dilution (1 scoop with water)",
    "info-include-exclude": null,
    "search-text": "garden of life raw organic meal replacement / shake",
    "weight-g": 35,
    "volume-ml": null,
    "calories": 130,
    "fat": 2,
    "saturated": 0,
    "polyunsaturated": 1,
    "monounsaturated": 0.5,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 190,
    "carbs": 10,
    "fibre": 2,
    "sugar": 1,
    "protein": 20,
    "potassium": 55,
    "calcium": 40,
    "iron": 4,
    "phosphorus": null,
    "info": "Meal replacement / shake (standard dilution (1 scoop with water))"
  },
  {
    "id": 1329,
    "disabled": null,
    "type": "snack",
    "name": "Flaxseed",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "flaxseed",
    "weight-g": 10,
    "volume-ml": null,
    "calories": 55,
    "fat": 4.4,
    "saturated": 0.4,
    "polyunsaturated": 3,
    "monounsaturated": 0.8,
    "trans": null,
    "cholesterol": 0,
    "sodium": 3.1,
    "carbs": 3,
    "fibre": 2.8,
    "sugar": 0.2,
    "protein": 1.9,
    "potassium": 83.7,
    "calcium": 26.3,
    "iron": 0.6,
    "phosphorus": 66.1,
    "info": null
  },
  {
    "id": 1330,
    "disabled": null,
    "type": "meal",
    "name": "Rawa thosai, large",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[rawa|rava] [thosai|dosa|tosei], large",
    "weight-g": 152,
    "volume-ml": null,
    "calories": 365,
    "fat": 10.5,
    "saturated": 5,
    "polyunsaturated": 1,
    "monounsaturated": 4.1,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 664,
    "carbs": 59.9,
    "fibre": 1.5,
    "sugar": 1.7,
    "protein": 7.5,
    "potassium": 122,
    "calcium": 20,
    "iron": 1.7,
    "phosphorus": 100,
    "info": null
  },
  {
    "id": 1331,
    "disabled": null,
    "type": "meal",
    "name": "Ven pongal, savoury",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[ven|venn] [pongal|pongali|huggi], savoury",
    "weight-g": 200,
    "volume-ml": null,
    "calories": 273,
    "fat": 5.6,
    "saturated": 3.4,
    "polyunsaturated": 0.3,
    "monounsaturated": 1.5,
    "trans": 0,
    "cholesterol": 15,
    "sodium": 9,
    "carbs": 47.4,
    "fibre": 5.3,
    "sugar": 2.3,
    "protein": 8.4,
    "potassium": 30,
    "calcium": 31,
    "iron": 4.3,
    "phosphorus": 14,
    "info": null
  },
  {
    "id": 1332,
    "disabled": null,
    "type": "meal",
    "name": "Sakkarai pongal, sweet",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[sakkarai|chakkara] [pongal|pongali|huggi], sweet",
    "weight-g": 200,
    "volume-ml": null,
    "calories": 408,
    "fat": 12.6,
    "saturated": 7.2,
    "polyunsaturated": 0.7,
    "monounsaturated": 3.9,
    "trans": 0,
    "cholesterol": 30,
    "sodium": 19,
    "carbs": 68,
    "fibre": 4,
    "sugar": 36.6,
    "protein": 6.4,
    "potassium": 72,
    "calcium": 24,
    "iron": 3.2,
    "phosphorus": 33,
    "info": null
  },
  {
    "id": 1333,
    "disabled": null,
    "type": "snack",
    "name": "Egg tart, large",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Dan Tat",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "egg tart, large dan tat",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 280,
    "fat": 15.2,
    "saturated": 8.6,
    "polyunsaturated": 0.9,
    "monounsaturated": 5,
    "trans": null,
    "cholesterol": 84,
    "sodium": 117,
    "carbs": 28.8,
    "fibre": 1.1,
    "sugar": 11.6,
    "protein": 6.7,
    "potassium": 119,
    "calcium": 55,
    "iron": 1.1,
    "phosphorus": 109,
    "info": "Dan Tat"
  },
  {
    "id": 1334,
    "disabled": null,
    "type": "meal",
    "name": "Rasam",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "rasam",
    "weight-g": 420,
    "volume-ml": null,
    "calories": 168,
    "fat": 9.2,
    "saturated": 4.2,
    "polyunsaturated": 0.8,
    "monounsaturated": 3.4,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 3725,
    "carbs": 17.2,
    "fibre": 0,
    "sugar": 1,
    "protein": 4.2,
    "potassium": 0,
    "calcium": 67,
    "iron": 3.8,
    "phosphorus": 0,
    "info": null
  },
  {
    "id": 1335,
    "disabled": null,
    "type": "meal",
    "name": "Curry, egg",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "curry, egg",
    "weight-g": 236,
    "volume-ml": null,
    "calories": 425,
    "fat": 36.3,
    "saturated": 14.8,
    "polyunsaturated": 5.7,
    "monounsaturated": 10.1,
    "trans": null,
    "cholesterol": 354.8,
    "sodium": null,
    "carbs": 10,
    "fibre": 1.9,
    "sugar": 7.1,
    "protein": 14.5,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1336,
    "disabled": null,
    "type": "meal",
    "name": "Millet, whole",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "millet, whole",
    "weight-g": 174,
    "volume-ml": null,
    "calories": 207,
    "fat": 1.7,
    "saturated": 0.3,
    "polyunsaturated": 0.9,
    "monounsaturated": 0.3,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 3,
    "carbs": 41.2,
    "fibre": 2.3,
    "sugar": 0.2,
    "protein": 6.1,
    "potassium": 108,
    "calcium": 5,
    "iron": 1.1,
    "phosphorus": 174,
    "info": null
  },
  {
    "id": 1337,
    "disabled": null,
    "type": "meal",
    "name": "Cocoa powder, unsweetened",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[cocoa|cacao] powder, unsweetened",
    "weight-g": 7,
    "volume-ml": null,
    "calories": 22,
    "fat": 1.5,
    "saturated": 0.9,
    "polyunsaturated": 0,
    "monounsaturated": 0.5,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 67,
    "carbs": 0.8,
    "fibre": 0.3,
    "sugar": 0.8,
    "protein": 1.3,
    "potassium": 105,
    "calcium": 9,
    "iron": 0.7,
    "phosphorus": 46,
    "info": null
  },
  {
    "id": 1338,
    "disabled": null,
    "type": "meal",
    "name": "Nutritional yeast",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "nutritional yeast",
    "weight-g": 5.3,
    "volume-ml": null,
    "calories": 20,
    "fat": 0.2,
    "saturated": 0.1,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 10,
    "carbs": 1.6,
    "fibre": 1,
    "sugar": 0,
    "protein": 2.6,
    "potassium": 114,
    "calcium": 1.9,
    "iron": 0.3,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1339,
    "disabled": null,
    "type": "meal",
    "name": "Arugula, raw",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": "Rocket leaves",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "arugula, raw rocket leaves",
    "weight-g": 80,
    "volume-ml": null,
    "calories": 12,
    "fat": 0.4,
    "saturated": 0,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.2,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 22,
    "carbs": 2,
    "fibre": 1,
    "sugar": 1.4,
    "protein": 0.6,
    "potassium": 296,
    "calcium": 128,
    "iron": 1.2,
    "phosphorus": 30,
    "info": "Rocket leaves"
  },
  {
    "id": 1340,
    "disabled": null,
    "type": "snack",
    "name": "Gelato",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "scoop",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "assorted flavours",
    "search-text": "gelato",
    "weight-g": 69,
    "volume-ml": null,
    "calories": 126,
    "fat": 5.1,
    "saturated": 2.6,
    "polyunsaturated": 0.1,
    "monounsaturated": 1.9,
    "trans": 0.3,
    "cholesterol": 8,
    "sodium": 39,
    "carbs": 17.6,
    "fibre": 0.1,
    "sugar": 15.7,
    "protein": 3.2,
    "potassium": 106,
    "calcium": 61,
    "iron": 0.1,
    "phosphorus": 58,
    "info": "assorted flavours"
  },
  {
    "id": 1341,
    "disabled": null,
    "type": "meal",
    "name": "Upma, broken wheat",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "upma, broken wheat",
    "weight-g": 155,
    "volume-ml": null,
    "calories": 321,
    "fat": 8.4,
    "saturated": 0.1,
    "polyunsaturated": 5.8,
    "monounsaturated": 2.5,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 250,
    "carbs": 54,
    "fibre": 4.2,
    "sugar": 0.2,
    "protein": 7.2,
    "potassium": 223,
    "calcium": 0,
    "iron": 0,
    "phosphorus": 0,
    "info": null
  },
  {
    "id": 1342,
    "disabled": null,
    "type": "meal",
    "name": "Ravioli, cheese filled",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with cream sauce",
    "search-text": "ravioli, cheese filled",
    "weight-g": 38,
    "volume-ml": null,
    "calories": 60.4,
    "fat": 2.9,
    "saturated": 1.2,
    "polyunsaturated": 0.5,
    "monounsaturated": 1,
    "trans": null,
    "cholesterol": 23.9,
    "sodium": 181,
    "carbs": 6.1,
    "fibre": 0.2,
    "sugar": 0.7,
    "protein": 2.3,
    "potassium": 50.9,
    "calcium": 40.7,
    "iron": 0.4,
    "phosphorus": 43.3,
    "info": "with cream sauce"
  },
  {
    "id": 1343,
    "disabled": null,
    "type": "meal",
    "name": "Spam musubi",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "spam musubi",
    "weight-g": 61,
    "volume-ml": null,
    "calories": 103,
    "fat": 2.9,
    "saturated": 1.1,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 7.5,
    "sodium": 258,
    "carbs": 16,
    "fibre": 0.2,
    "sugar": 2.4,
    "protein": 2.8,
    "potassium": 69,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1344,
    "disabled": null,
    "type": "snack",
    "name": "Dairy free ice cream",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": "Magnum",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "dairy free ice cream magnum",
    "weight-g": 72,
    "volume-ml": null,
    "calories": 178.6,
    "fat": 15.9,
    "saturated": 9.8,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 28.8,
    "carbs": 23.3,
    "fibre": null,
    "sugar": 18.9,
    "protein": 1.9,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Magnum"
  },
  {
    "id": 1345,
    "disabled": null,
    "type": "drink",
    "name": "Glucerna Plus 1.5 Kcal (liquid)",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bottle",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "glucerna plus 1.5 kcal (liquid)",
    "weight-g": null,
    "volume-ml": 220,
    "calories": 333,
    "fat": 16.5,
    "saturated": 1.2,
    "polyunsaturated": null,
    "monounsaturated": 11.2,
    "trans": null,
    "cholesterol": null,
    "sodium": 308,
    "carbs": 28.1,
    "fibre": 3.3,
    "sugar": 15,
    "protein": 16.5,
    "potassium": 363,
    "calcium": 220,
    "iron": 1.9,
    "phosphorus": 220,
    "info": null
  },
  {
    "id": 1346,
    "disabled": null,
    "type": "meal",
    "name": "Crab cake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cake",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "crab cake",
    "weight-g": 95.3,
    "volume-ml": null,
    "calories": 260,
    "fat": 15.8,
    "saturated": 4,
    "polyunsaturated": 3.3,
    "monounsaturated": 7.4,
    "trans": null,
    "cholesterol": 148.2,
    "sodium": null,
    "carbs": 12.3,
    "fibre": 0.9,
    "sugar": 1.8,
    "protein": 15.7,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1347,
    "disabled": null,
    "type": "snack",
    "name": "Keto bread",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "keto bread",
    "weight-g": 32,
    "volume-ml": null,
    "calories": 110,
    "fat": 6,
    "saturated": 0.5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 25,
    "sodium": 180,
    "carbs": 4,
    "fibre": 4,
    "sugar": 0,
    "protein": 4,
    "potassium": 120,
    "calcium": 49.9,
    "iron": 0.7,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1348,
    "disabled": null,
    "type": "meal",
    "name": "Tuna ceviche",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "tuna ceviche",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 162,
    "fat": 3.7,
    "saturated": 0.5,
    "polyunsaturated": 0.5,
    "monounsaturated": 2.2,
    "trans": null,
    "cholesterol": 50.6,
    "sodium": null,
    "carbs": 8.9,
    "fibre": 3.5,
    "sugar": 2,
    "protein": 23.4,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1349,
    "disabled": null,
    "type": "meal",
    "name": "Parsnip, boiled",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "parsnip, boiled",
    "weight-g": 160,
    "volume-ml": null,
    "calories": 146,
    "fat": 4.5,
    "saturated": 1.2,
    "polyunsaturated": 1.3,
    "monounsaturated": 1.7,
    "trans": null,
    "cholesterol": 3.2,
    "sodium": 16,
    "carbs": 20.8,
    "fibre": 5.6,
    "sugar": 7.4,
    "protein": 2.1,
    "potassium": 570,
    "calcium": 57.6,
    "iron": 0.9,
    "phosphorus": 107,
    "info": null
  },
  {
    "id": 1350,
    "disabled": null,
    "type": "snack",
    "name": "Sesame seeds",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sesame seeds",
    "weight-g": 14,
    "volume-ml": null,
    "calories": 80,
    "fat": 6.8,
    "saturated": 1,
    "polyunsaturated": 3,
    "monounsaturated": 2.6,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1.6,
    "carbs": 1.7,
    "fibre": 2,
    "sugar": null,
    "protein": 2.4,
    "potassium": 67.5,
    "calcium": 140,
    "iron": 2.1,
    "phosphorus": 90.5,
    "info": null
  },
  {
    "id": 1351,
    "disabled": null,
    "type": "snack",
    "name": "Sesame powder, unsweetened",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sesame powder, unsweetened",
    "weight-g": 10,
    "volume-ml": null,
    "calories": 62,
    "fat": 5.2,
    "saturated": 0.8,
    "polyunsaturated": 2.4,
    "monounsaturated": 2,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 1,
    "carbs": 1.6,
    "fibre": 1.3,
    "sugar": 0,
    "protein": 2.2,
    "potassium": 53,
    "calcium": 141,
    "iron": 2.5,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1352,
    "disabled": null,
    "type": "snack",
    "name": "Protein ball",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "ball",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with dates and nuts",
    "search-text": "protein ball",
    "weight-g": 40,
    "volume-ml": null,
    "calories": 160,
    "fat": 7,
    "saturated": 1.5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 84.8,
    "carbs": 18,
    "fibre": 5,
    "sugar": 9,
    "protein": 5,
    "potassium": null,
    "calcium": 20,
    "iron": 1.1,
    "phosphorus": null,
    "info": "with dates and nuts"
  },
  {
    "id": 1353,
    "disabled": null,
    "type": "snack",
    "name": "Cacao nibs",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[cacao|cocoa] nibs",
    "weight-g": 9.3,
    "volume-ml": null,
    "calories": 63,
    "fat": 5,
    "saturated": 3,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": null,
    "carbs": 3.7,
    "fibre": 2.7,
    "sugar": 0,
    "protein": 1.3,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1354,
    "disabled": null,
    "type": "snack",
    "name": "Carrot cake, with icing",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "carrot cake, with icing",
    "weight-g": 133,
    "volume-ml": null,
    "calories": 571,
    "fat": 35.5,
    "saturated": 11.6,
    "polyunsaturated": 10.7,
    "monounsaturated": 11,
    "trans": null,
    "cholesterol": 75.8,
    "sodium": 310,
    "carbs": 59.1,
    "fibre": 1.7,
    "sugar": 45.2,
    "protein": 5.4,
    "potassium": 162,
    "calcium": 43.9,
    "iron": 1.3,
    "phosphorus": 91.8,
    "info": null
  },
  {
    "id": 1355,
    "disabled": null,
    "type": "drink",
    "name": "Optifast",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": "Meal replacement",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "standard dilution (1 pouch with water)",
    "info-include-exclude": null,
    "search-text": "optifast meal replacement",
    "weight-g": 53,
    "volume-ml": null,
    "calories": 201,
    "fat": 4.5,
    "saturated": 0.9,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 215,
    "carbs": 18.2,
    "fibre": 3.6,
    "sugar": 10,
    "protein": 20,
    "potassium": 955,
    "calcium": 420,
    "iron": 8,
    "phosphorus": 360,
    "info": "Meal replacement (standard dilution (1 pouch with water))"
  },
  {
    "id": 1356,
    "disabled": null,
    "type": "meal",
    "name": "Saba Shioyaki",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": 1,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "serve",
    "info-translation": "Japanese grilled mackerel",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "saba shioyaki japanese grilled mackerel",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 200,
    "fat": 11,
    "saturated": 2.4,
    "polyunsaturated": 1.2,
    "monounsaturated": 7.1,
    "trans": 0,
    "cholesterol": 59,
    "sodium": 271,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 26,
    "potassium": 435,
    "calcium": 24,
    "iron": 1.5,
    "phosphorus": null,
    "info": "Japanese grilled mackerel"
  },
  {
    "id": 1357,
    "disabled": null,
    "type": "meal",
    "name": "Artichoke",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": 1,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "whole",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "artichoke",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 63.6,
    "fat": 0.4,
    "saturated": 0.1,
    "polyunsaturated": 0.2,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 72,
    "carbs": 7.6,
    "fibre": 6.8,
    "sugar": 1.2,
    "protein": 3.5,
    "potassium": 343,
    "calcium": 25.2,
    "iron": 0.7,
    "phosphorus": 87.6,
    "info": null
  },
  {
    "id": 1358,
    "disabled": null,
    "type": "drink",
    "name": "Ginger lemon tea, sweetened",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "sachet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ginger lemon tea, sweetened",
    "weight-g": 18,
    "volume-ml": null,
    "calories": 70,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 17,
    "fibre": 0,
    "sugar": 16,
    "protein": 0,
    "potassium": 23,
    "calcium": 2,
    "iron": 0,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1359,
    "disabled": null,
    "type": "drink",
    "name": "Ginger lemon tea, unsweetened",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "sachet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ginger lemon tea, unsweetened",
    "weight-g": 3,
    "volume-ml": null,
    "calories": 0,
    "fat": 0,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 5,
    "carbs": 0,
    "fibre": null,
    "sugar": 0,
    "protein": 0,
    "potassium": 50,
    "calcium": null,
    "iron": 0.3,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1360,
    "disabled": null,
    "type": "snack",
    "name": "Digestive biscuit, plain",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "digestive biscuit, plain",
    "weight-g": 10,
    "volume-ml": null,
    "calories": 48.8,
    "fat": 2.1,
    "saturated": 1,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0.2,
    "sodium": 61.1,
    "carbs": 6.9,
    "fibre": 0.4,
    "sugar": null,
    "protein": 0.6,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1361,
    "disabled": null,
    "type": "snack",
    "name": "Digestive biscuit, chocolate coated",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "digestive biscuit, chocolate coated",
    "weight-g": 14.3,
    "volume-ml": null,
    "calories": 70,
    "fat": 3.4,
    "saturated": 1.7,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 53,
    "carbs": 9,
    "fibre": 0.4,
    "sugar": 4,
    "protein": 0.9,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1362,
    "disabled": null,
    "type": "snack",
    "name": "Pretzel, with chocolate",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pretzel, with chocolate",
    "weight-g": 12,
    "volume-ml": null,
    "calories": 55,
    "fat": 2.2,
    "saturated": 1.3,
    "polyunsaturated": 0.2,
    "monounsaturated": 0.6,
    "trans": null,
    "cholesterol": 1.6,
    "sodium": null,
    "carbs": 7.9,
    "fibre": 0.4,
    "sugar": 3.6,
    "protein": 1,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1363,
    "disabled": null,
    "type": "meal",
    "name": "Protein pancake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "protein pancake",
    "weight-g": 50,
    "volume-ml": null,
    "calories": 170,
    "fat": 13.5,
    "saturated": 0.5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 10,
    "sodium": 350,
    "carbs": 23,
    "fibre": 4,
    "sugar": 3,
    "protein": 16,
    "potassium": 243,
    "calcium": 117,
    "iron": 2,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1364,
    "disabled": null,
    "type": "drink",
    "name": "Lemon tea, sweetened",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": 1,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "lemon tea, sweetened",
    "weight-g": 300,
    "volume-ml": null,
    "calories": 65,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 15.3,
    "fibre": 0.4,
    "sugar": 15.3,
    "protein": 0.4,
    "potassium": 63,
    "calcium": 3,
    "iron": 0.1,
    "phosphorus": 6,
    "info": null
  },
  {
    "id": 1365,
    "disabled": null,
    "type": "drink",
    "name": "Lemon tea, reduced sugar",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "lemon tea, reduced sugar",
    "weight-g": 300,
    "volume-ml": null,
    "calories": 33,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 7.3,
    "fibre": 0.4,
    "sugar": 7.3,
    "protein": 0.3,
    "potassium": 52,
    "calcium": 3,
    "iron": 0.1,
    "phosphorus": 5,
    "info": null
  },
  {
    "id": 1366,
    "disabled": null,
    "type": "drink",
    "name": "Lemon tea, unsweetened",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "lemon tea, unsweetened",
    "weight-g": 300,
    "volume-ml": null,
    "calories": 5,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 0.6,
    "fibre": 0.3,
    "sugar": 0.3,
    "protein": 0.4,
    "potassium": 63,
    "calcium": 3,
    "iron": 0.1,
    "phosphorus": 6,
    "info": null
  },
  {
    "id": 1367,
    "disabled": null,
    "type": "meal",
    "name": "Meatball",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "meatball",
    "weight-g": 36,
    "volume-ml": null,
    "calories": 96,
    "fat": 7.1,
    "saturated": 2.5,
    "polyunsaturated": 1.4,
    "monounsaturated": 3,
    "trans": 0,
    "cholesterol": 29,
    "sodium": 127,
    "carbs": 2.6,
    "fibre": 0.1,
    "sugar": 0.5,
    "protein": 5.5,
    "potassium": 82,
    "calcium": 35,
    "iron": 0.4,
    "phosphorus": 51,
    "info": null
  },
  {
    "id": 1368,
    "disabled": null,
    "type": "snack",
    "name": "Pumpkin seeds, plain",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pumpkin seeds, plain",
    "weight-g": 14.2,
    "volume-ml": null,
    "calories": 79,
    "fat": 7,
    "saturated": 1.2,
    "polyunsaturated": 3,
    "monounsaturated": 2.3,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 1,
    "carbs": 0.7,
    "fibre": 0.9,
    "sugar": 0.2,
    "protein": 4.3,
    "potassium": 114.5,
    "calcium": 6.5,
    "iron": 1.3,
    "phosphorus": 174.5,
    "info": null
  },
  {
    "id": 1369,
    "disabled": null,
    "type": "snack",
    "name": "Sunflower seeds, plain",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sunflower seeds, plain",
    "weight-g": 12,
    "volume-ml": null,
    "calories": 68.8,
    "fat": 6.1,
    "saturated": 0.5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 0,
    "carbs": 0.3,
    "fibre": 1.3,
    "sugar": null,
    "protein": 2.7,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1370,
    "disabled": null,
    "type": "snack",
    "name": "Almonds, plain",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 0.3,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "almonds, plain",
    "weight-g": 35.8,
    "volume-ml": null,
    "calories": 207,
    "fat": 17.9,
    "saturated": 1.4,
    "polyunsaturated": 4.4,
    "monounsaturated": 11.3,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0.4,
    "carbs": 3.3,
    "fibre": 4.5,
    "sugar": 1.6,
    "protein": 7.6,
    "potassium": 262.5,
    "calcium": 96.3,
    "iron": 1.3,
    "phosphorus": 172,
    "info": null
  },
  {
    "id": 1371,
    "disabled": null,
    "type": "snack",
    "name": "Cashews,plain",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 0.3,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "cashews,plain",
    "weight-g": 34.3,
    "volume-ml": null,
    "calories": 196.5,
    "fat": 15.9,
    "saturated": 3.1,
    "polyunsaturated": 2.7,
    "monounsaturated": 9.4,
    "trans": null,
    "cholesterol": 0,
    "sodium": 5.5,
    "carbs": 10.2,
    "fibre": 1,
    "sugar": 1.7,
    "protein": 5.3,
    "potassium": 193.5,
    "calcium": 15.4,
    "iron": 2.1,
    "phosphorus": 167.8,
    "info": null
  },
  {
    "id": 1372,
    "disabled": null,
    "type": "snack",
    "name": "Walnuts, plain",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 0.3,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "walnuts, plain",
    "weight-g": 30,
    "volume-ml": null,
    "calories": 196.3,
    "fat": 19.6,
    "saturated": 1.8,
    "polyunsaturated": 14.2,
    "monounsaturated": 2.7,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0.6,
    "carbs": 2.1,
    "fibre": 2,
    "sugar": 0.8,
    "protein": 4.6,
    "potassium": 132.3,
    "calcium": 29.5,
    "iron": 0.9,
    "phosphorus": 103.8,
    "info": null
  },
  {
    "id": 1373,
    "disabled": null,
    "type": "snack",
    "name": "Pistachios, plain",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 0.3,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pistachios, plain",
    "weight-g": 30.8,
    "volume-ml": null,
    "calories": 172.3,
    "fat": 13.9,
    "saturated": 1.8,
    "polyunsaturated": 4.4,
    "monounsaturated": 7.2,
    "trans": null,
    "cholesterol": 0,
    "sodium": 0.3,
    "carbs": 5.1,
    "fibre": 3.3,
    "sugar": 2.4,
    "protein": 6.2,
    "potassium": 312.5,
    "calcium": 32.3,
    "iron": 1.2,
    "phosphorus": 150.8,
    "info": null
  },
  {
    "id": 1374,
    "disabled": null,
    "type": "meal",
    "name": "Salted egg",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "whole",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "salted egg",
    "weight-g": 67,
    "volume-ml": null,
    "calories": 111,
    "fat": 7.4,
    "saturated": 2.6,
    "polyunsaturated": 0.8,
    "monounsaturated": 3.9,
    "trans": 0,
    "cholesterol": 377,
    "sodium": 1577,
    "carbs": 3.7,
    "fibre": 0,
    "sugar": 0,
    "protein": 7.4,
    "potassium": 107,
    "calcium": 69,
    "iron": 2.1,
    "phosphorus": 135,
    "info": null
  },
  {
    "id": 1375,
    "disabled": null,
    "type": "meal",
    "name": "Alchemy fibre",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "alchemy fibre",
    "weight-g": 5,
    "volume-ml": null,
    "calories": 2.3,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 0.9,
    "carbs": 0.6,
    "fibre": 4.2,
    "sugar": 0.4,
    "protein": 0,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1376,
    "disabled": null,
    "type": "meal",
    "name": "Bean curd skin, fried",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": "Taukee",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bean curd skin, fried taukee",
    "weight-g": 8,
    "volume-ml": null,
    "calories": 57.4,
    "fat": 5.4,
    "saturated": 2.2,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 11,
    "carbs": 0.2,
    "fibre": 0.1,
    "sugar": null,
    "protein": 2,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "Taukee"
  },
  {
    "id": 1377,
    "disabled": null,
    "type": "meal",
    "name": "Pork floss",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pork floss",
    "weight-g": 11,
    "volume-ml": null,
    "calories": 44,
    "fat": 0.8,
    "saturated": 0.3,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.4,
    "trans": 0,
    "cholesterol": 8,
    "sodium": 206.7,
    "carbs": 5.5,
    "fibre": 0,
    "sugar": 5.5,
    "protein": 3.6,
    "potassium": 22.7,
    "calcium": 5.7,
    "iron": 0.4,
    "phosphorus": 22.7,
    "info": null
  },
  {
    "id": 1378,
    "disabled": null,
    "type": "meal",
    "name": "Up and Go Liquid Breakfast",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "pack",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "up and go liquid breakfast",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 195,
    "fat": 4.3,
    "saturated": 0.6,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 158,
    "carbs": 28.4,
    "fibre": 4,
    "sugar": 15.8,
    "protein": 8.3,
    "potassium": 450,
    "calcium": 300,
    "iron": null,
    "phosphorus": 250,
    "info": null
  },
  {
    "id": 1379,
    "disabled": null,
    "type": "meal",
    "name": "Up and Go Protein Energize",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "pack",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "up and go protein energize",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 217,
    "fat": 4.5,
    "saturated": 0.6,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 226,
    "carbs": 25,
    "fibre": 2.4,
    "sugar": 16,
    "protein": 17.5,
    "potassium": 500,
    "calcium": 300,
    "iron": null,
    "phosphorus": 250,
    "info": null
  },
  {
    "id": 1380,
    "disabled": null,
    "type": "meal",
    "name": "Up and Go Liquid Breakfast No Added Sugar",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "pack",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "up and go liquid breakfast no added sugar",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 137,
    "fat": 3.7,
    "saturated": 0.6,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 171,
    "carbs": 15.5,
    "fibre": 4,
    "sugar": 10.5,
    "protein": 8.1,
    "potassium": 644,
    "calcium": 300,
    "iron": null,
    "phosphorus": 250,
    "info": null
  },
  {
    "id": 1381,
    "disabled": null,
    "type": "snack",
    "name": "Biscuit with chocolate coating",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": "Tim Tam",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "biscuit with chocolate coating tim tam",
    "weight-g": 18.3,
    "volume-ml": null,
    "calories": 95.4,
    "fat": 4.9,
    "saturated": 2.7,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 29,
    "carbs": 11.8,
    "fibre": 0.3,
    "sugar": 8.2,
    "protein": 0.8,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Tim Tam"
  },
  {
    "id": 1382,
    "disabled": null,
    "type": "drink",
    "name": "Soy milk, sweetened (Healthier Choice Symbol, high calcium)",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "glass",
    "info-translation": null,
    "info-brands": "Nutrisoy",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with sugar",
    "search-text": "[soy|soya] milk, sweetened (healthier choice symbol, high calcium) nutrisoy",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 148,
    "fat": 3,
    "saturated": 0.5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 15,
    "carbs": 19.8,
    "fibre": 1.8,
    "sugar": 16.8,
    "protein": 10.5,
    "potassium": null,
    "calcium": 500,
    "iron": null,
    "phosphorus": null,
    "info": "with sugar, e.g. Nutrisoy"
  },
  {
    "id": 1383,
    "disabled": null,
    "type": "drink",
    "name": "Soy milk, unsweetened (Healthier Choice Symbol, high calcium)",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "glass",
    "info-translation": null,
    "info-brands": "Nutrisoy",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "without sugar",
    "search-text": "[soy|soya] milk, unsweetened (healthier choice symbol, high calcium) nutrisoy",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 105,
    "fat": 5,
    "saturated": 1.3,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 8,
    "carbs": 4.5,
    "fibre": 0.3,
    "sugar": 2,
    "protein": 10,
    "potassium": null,
    "calcium": 500,
    "iron": null,
    "phosphorus": null,
    "info": "without sugar, e.g. Nutrisoy"
  },
  {
    "id": 1384,
    "disabled": null,
    "type": "meal",
    "name": "Puri",
    "rating": 1,
    "alcohol": null,
    "fried": 1,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[puri|poori]",
    "weight-g": 147,
    "volume-ml": null,
    "calories": 323,
    "fat": 14.3,
    "saturated": 7.8,
    "polyunsaturated": 1.5,
    "monounsaturated": 4.4,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 1010,
    "carbs": 41.9,
    "fibre": 8.8,
    "sugar": 0,
    "protein": 6.9,
    "potassium": 0,
    "calcium": 38,
    "iron": 2.8,
    "phosphorus": 0,
    "info": null
  },
  {
    "id": 1385,
    "disabled": null,
    "type": "meal",
    "name": "Poke bowl",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "rice/quinoa base with meat, vegetables and creamy sauce",
    "search-text": "poke bowl",
    "weight-g": 400,
    "volume-ml": null,
    "calories": 481,
    "fat": 15,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 54,
    "fibre": null,
    "sugar": null,
    "protein": 35,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "rice/quinoa base with meat, vegetables and creamy sauce"
  },
  {
    "id": 1386,
    "disabled": null,
    "type": "meal",
    "name": "Poke bowl",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "rice/quinoa base with meat, vegetables and non-creamy sauce",
    "search-text": "poke bowl",
    "weight-g": 400,
    "volume-ml": null,
    "calories": 418,
    "fat": 7,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 47,
    "fibre": null,
    "sugar": null,
    "protein": 31,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "rice/quinoa base with meat, vegetables and non-creamy sauce"
  },
  {
    "id": 1387,
    "disabled": null,
    "type": "meal",
    "name": "Poke bowl",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "lettuce base with meat, vegetables and creamy sauce",
    "search-text": "poke bowl",
    "weight-g": 300,
    "volume-ml": null,
    "calories": 341,
    "fat": 13,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 27,
    "fibre": null,
    "sugar": null,
    "protein": 31,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "lettuce base with meat, vegetables and creamy sauce"
  },
  {
    "id": 1388,
    "disabled": null,
    "type": "meal",
    "name": "Poke bowl",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "lettuce base with meat, vegetables and non-creamy sauce",
    "search-text": "poke bowl",
    "weight-g": 300,
    "volume-ml": null,
    "calories": 278,
    "fat": 5,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 20,
    "fibre": null,
    "sugar": null,
    "protein": 27,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "lettuce base with meat, vegetables and non-creamy sauce"
  },
  {
    "id": 1389,
    "disabled": null,
    "type": "meal",
    "name": "Poke bowl",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "pasta base with meat, vegetables and creamy sauce",
    "search-text": "poke bowl",
    "weight-g": 400,
    "volume-ml": null,
    "calories": 447,
    "fat": 14,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 48,
    "fibre": null,
    "sugar": null,
    "protein": 36,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "pasta base with meat, vegetables and creamy sauce"
  },
  {
    "id": 1390,
    "disabled": null,
    "type": "meal",
    "name": "Poke bowl",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "pasta base with meat, vegetables and non-creamy sauce",
    "search-text": "poke bowl",
    "weight-g": 400,
    "volume-ml": null,
    "calories": 385,
    "fat": 6,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 41,
    "fibre": null,
    "sugar": null,
    "protein": 32,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "pasta base with meat, vegetables and non-creamy sauce"
  },
  {
    "id": 1391,
    "disabled": null,
    "type": "drink",
    "name": "Diben Drink (1.5 kcal)",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bottle",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "diben drink (1.5 kcal)",
    "weight-g": null,
    "volume-ml": 200,
    "calories": 300,
    "fat": 14,
    "saturated": 3.4,
    "polyunsaturated": 3,
    "monounsaturated": 7.6,
    "trans": null,
    "cholesterol": 12,
    "sodium": 140,
    "carbs": 26.2,
    "fibre": 4,
    "sugar": 5,
    "protein": 15,
    "potassium": 260,
    "calcium": 300,
    "iron": 4,
    "phosphorus": 190,
    "info": null
  },
  {
    "id": 1392,
    "disabled": null,
    "type": "drink",
    "name": "Nepro LP (liquid)",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bottle",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "nepro lp (liquid)",
    "weight-g": null,
    "volume-ml": 220,
    "calories": 401,
    "fat": 21,
    "saturated": 1.6,
    "polyunsaturated": 3.1,
    "monounsaturated": 16,
    "trans": null,
    "cholesterol": null,
    "sodium": 176,
    "carbs": 40.8,
    "fibre": 2.8,
    "sugar": 13,
    "protein": 9.9,
    "potassium": 251,
    "calcium": 161,
    "iron": 4.2,
    "phosphorus": 143,
    "info": null
  },
  {
    "id": 1393,
    "disabled": null,
    "type": "drink",
    "name": "Pulmocare (liquid)",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pulmocare (liquid)",
    "weight-g": null,
    "volume-ml": 237,
    "calories": 355,
    "fat": 22.1,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 310,
    "carbs": 25,
    "fibre": null,
    "sugar": 14,
    "protein": 14.8,
    "potassium": 422,
    "calcium": 250,
    "iron": 4.5,
    "phosphorus": 250,
    "info": null
  },
  {
    "id": 1394,
    "disabled": null,
    "type": "drink",
    "name": "Ensure Max Protein (liquid)",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "carton",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ensure max protein (liquid)",
    "weight-g": null,
    "volume-ml": 330,
    "calories": 150,
    "fat": 1.5,
    "saturated": 0.5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 20,
    "sodium": 140,
    "carbs": 6,
    "fibre": 2,
    "sugar": 1,
    "protein": 30,
    "potassium": 528,
    "calcium": 650,
    "iron": 5.6,
    "phosphorus": 624,
    "info": null
  },
  {
    "id": 1395,
    "disabled": null,
    "type": "snack",
    "name": "Beneprotein (powder)",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "scoop",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "beneprotein (powder)",
    "weight-g": 7,
    "volume-ml": null,
    "calories": 25,
    "fat": 0,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 15,
    "carbs": 0,
    "fibre": null,
    "sugar": null,
    "protein": 6,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1396,
    "disabled": null,
    "type": "snack",
    "name": "Myotein (powder)",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "scoop",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "myotein (powder)",
    "weight-g": 6.3,
    "volume-ml": null,
    "calories": 26,
    "fat": 0.5,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 12.6,
    "carbs": 0.4,
    "fibre": null,
    "sugar": null,
    "protein": 5,
    "potassium": 29.6,
    "calcium": 25.8,
    "iron": null,
    "phosphorus": 12.6,
    "info": null
  },
  {
    "id": 1397,
    "disabled": null,
    "type": "drink",
    "name": "Herbalife Formula 1 Nutritional Shake Mix",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": "Meal replacement",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "standard dilution (2 heaping tablespoons with water)",
    "info-include-exclude": null,
    "search-text": "herbalife formula 1 nutritional shake mix meal replacement",
    "weight-g": 26,
    "volume-ml": null,
    "calories": 93,
    "fat": 2.3,
    "saturated": 0.5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 135,
    "carbs": 9,
    "fibre": 2.5,
    "sugar": 8.5,
    "protein": 9,
    "potassium": 130,
    "calcium": 100,
    "iron": 5.2,
    "phosphorus": 171,
    "info": "Meal replacement (standard dilution (2 heaping tablespoons with water))"
  },
  {
    "id": 1398,
    "disabled": null,
    "type": "drink",
    "name": "Susenji Chocolate Shake",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": "Meal replacement",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": "standard dilution (1 sachet with water)",
    "info-include-exclude": null,
    "search-text": "susenji chocolate shake meal replacement",
    "weight-g": 35,
    "volume-ml": null,
    "calories": 140,
    "fat": 2,
    "saturated": 1,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 130,
    "carbs": 12,
    "fibre": 4,
    "sugar": 5,
    "protein": 13,
    "potassium": 380,
    "calcium": 40,
    "iron": 4.7,
    "phosphorus": 150,
    "info": "Meal replacement (standard dilution (1 sachet with water))"
  },
  {
    "id": 1399,
    "disabled": null,
    "type": "drink",
    "name": "Quest Protein Shake",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "carton",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "quest protein shake",
    "weight-g": null,
    "volume-ml": 325,
    "calories": 160,
    "fat": 3.5,
    "saturated": 0,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 20,
    "sodium": 250,
    "carbs": 3,
    "fibre": 0,
    "sugar": 1,
    "protein": 30,
    "potassium": 130,
    "calcium": 840,
    "iron": 1.7,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1400,
    "disabled": null,
    "type": "drink",
    "name": "Arla Protein Milk drink",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bottle",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "arla protein milk drink",
    "weight-g": null,
    "volume-ml": 500,
    "calories": 250,
    "fat": 4.5,
    "saturated": 3,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 349,
    "carbs": 24.5,
    "fibre": null,
    "sugar": 23.5,
    "protein": 28,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1401,
    "disabled": null,
    "type": "drink",
    "name": "Labrada Lean Body Protein Shake",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "carton",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "labrada lean body protein shake",
    "weight-g": null,
    "volume-ml": 500,
    "calories": 280,
    "fat": 9,
    "saturated": 1,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 25,
    "sodium": 440,
    "carbs": 4,
    "fibre": 5,
    "sugar": 0,
    "protein": 40,
    "potassium": 600,
    "calcium": 400,
    "iron": 0,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1402,
    "disabled": null,
    "type": "drink",
    "name": "Premier Protein Shake",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bottle",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "premier protein shake",
    "weight-g": null,
    "volume-ml": 340,
    "calories": 160,
    "fat": 3,
    "saturated": 1,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 20,
    "sodium": 230,
    "carbs": 3,
    "fibre": 1,
    "sugar": 1,
    "protein": 30,
    "potassium": 180,
    "calcium": 650,
    "iron": 1.8,
    "phosphorus": 550,
    "info": null
  },
  {
    "id": 1403,
    "disabled": null,
    "type": "drink",
    "name": "Magnolia Plus High Protein Milk",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bottle",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "magnolia plus high protein milk",
    "weight-g": null,
    "volume-ml": 375,
    "calories": 221,
    "fat": 2.3,
    "saturated": 1.5,
    "polyunsaturated": 0,
    "monounsaturated": 0.8,
    "trans": 0,
    "cholesterol": 23,
    "sodium": 401,
    "carbs": 24.8,
    "fibre": 0,
    "sugar": 22.1,
    "protein": 25.1,
    "potassium": null,
    "calcium": 488,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1404,
    "disabled": null,
    "type": "drink",
    "name": "Rokeby Farm Whole Protein Smoothie",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bottle",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "rokeby farm whole protein smoothie",
    "weight-g": null,
    "volume-ml": 425,
    "calories": 253,
    "fat": 6.4,
    "saturated": 4.7,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 111,
    "carbs": 18.7,
    "fibre": null,
    "sugar": 16.6,
    "protein": 30,
    "potassium": null,
    "calcium": 833,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1405,
    "disabled": null,
    "type": "drink",
    "name": "Ensure Plant Based Protein Nutrition Shake",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "carton",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "ensure plant based protein nutrition shake",
    "weight-g": null,
    "volume-ml": 330,
    "calories": 180,
    "fat": 6,
    "saturated": 1,
    "polyunsaturated": 1.5,
    "monounsaturated": 3.5,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 300,
    "carbs": 8,
    "fibre": 5,
    "sugar": 7,
    "protein": 20,
    "potassium": 470,
    "calcium": 400,
    "iron": 7.2,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1406,
    "disabled": null,
    "type": "meal",
    "name": "Bread, low GI",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": "Smart Carb",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bread, low gi smart carb",
    "weight-g": 36,
    "volume-ml": null,
    "calories": 81.5,
    "fat": 2.1,
    "saturated": 0.6,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 114.5,
    "carbs": 9.3,
    "fibre": 3.2,
    "sugar": 0.9,
    "protein": 6.6,
    "potassium": null,
    "calcium": 73,
    "iron": 0.9,
    "phosphorus": null,
    "info": "e.g. Smart Carb"
  },
  {
    "id": 1407,
    "disabled": null,
    "type": "snack",
    "name": "Upgrain brioche loaf",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "upgrain brioche loaf",
    "weight-g": 50,
    "volume-ml": null,
    "calories": 111,
    "fat": 3.6,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 14.2,
    "fibre": 6.6,
    "sugar": null,
    "protein": 5.5,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1408,
    "disabled": null,
    "type": "snack",
    "name": "Upgrain multigrain bun",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bun",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "upgrain multigrain bun",
    "weight-g": 80,
    "volume-ml": null,
    "calories": 206,
    "fat": 9.1,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 21.8,
    "fibre": 8.5,
    "sugar": null,
    "protein": 9.1,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1409,
    "disabled": null,
    "type": "snack",
    "name": "Upgrain pita bread",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "upgrain pita bread",
    "weight-g": 70,
    "volume-ml": null,
    "calories": 110,
    "fat": 3.4,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 11,
    "fibre": 15.5,
    "sugar": null,
    "protein": 9,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1410,
    "disabled": null,
    "type": "snack",
    "name": "Upgrain raisin cinnamon demi\nloaf",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "upgrain raisin cinnamon demi loaf",
    "weight-g": 70,
    "volume-ml": null,
    "calories": 133,
    "fat": 3,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 18.6,
    "fibre": 13.7,
    "sugar": 6,
    "protein": 7.9,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1411,
    "disabled": null,
    "type": "snack",
    "name": "Upgrain signature bun",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bun",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "upgrain signature bun",
    "weight-g": 70,
    "volume-ml": null,
    "calories": 155,
    "fat": 5,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 19.8,
    "fibre": 9.2,
    "sugar": null,
    "protein": 7.7,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1412,
    "disabled": null,
    "type": "snack",
    "name": "Upgrain sundried tomato bun",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bun",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "upgrain sundried tomato bun",
    "weight-g": 70,
    "volume-ml": null,
    "calories": 161,
    "fat": 7,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 17.8,
    "fibre": 8.3,
    "sugar": 3.4,
    "protein": 6.7,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1413,
    "disabled": null,
    "type": "snack",
    "name": "Upgrain vegan bun",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bun",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "upgrain vegan bun",
    "weight-g": 70,
    "volume-ml": null,
    "calories": 110,
    "fat": 2.7,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 12.9,
    "fibre": 15.9,
    "sugar": null,
    "protein": 8.6,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1414,
    "disabled": null,
    "type": "snack",
    "name": "Upgrain walnut raisin bread",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "slice",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "upgrain walnut raisin bread",
    "weight-g": 50,
    "volume-ml": null,
    "calories": 140,
    "fat": 5.6,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 17.4,
    "fibre": 4.2,
    "sugar": 5.9,
    "protein": 5.1,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1415,
    "disabled": null,
    "type": "snack",
    "name": "Upgrain mee kia",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "upgrain [mee|mian] kia",
    "weight-g": 70,
    "volume-ml": null,
    "calories": 93,
    "fat": 0.8,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 254.5,
    "carbs": 14,
    "fibre": 17.9,
    "sugar": null,
    "protein": 7.4,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1416,
    "disabled": null,
    "type": "snack",
    "name": "Upgrain mee pok",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "upgrain [mee|mian] pok",
    "weight-g": 70,
    "volume-ml": null,
    "calories": 93,
    "fat": 0.8,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 254.5,
    "carbs": 14,
    "fibre": 17.9,
    "sugar": null,
    "protein": 7.4,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1417,
    "disabled": null,
    "type": "snack",
    "name": "Upgrain ramen",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "upgrain ramen",
    "weight-g": 70,
    "volume-ml": null,
    "calories": 73,
    "fat": 0.8,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 390.6,
    "carbs": 9,
    "fibre": 23.9,
    "sugar": null,
    "protein": 7.4,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1418,
    "disabled": null,
    "type": "snack",
    "name": "Upgrain wonton noodles",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "upgrain [wonton|wanton] noodles",
    "weight-g": 70,
    "volume-ml": null,
    "calories": 93,
    "fat": 0.8,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 254.5,
    "carbs": 14,
    "fibre": 17.9,
    "sugar": null,
    "protein": 7.4,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1419,
    "disabled": null,
    "type": "snack",
    "name": "Upgrain cranberry rose cookie",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "upgrain cranberry rose cookie",
    "weight-g": 5.9,
    "volume-ml": null,
    "calories": 28,
    "fat": 1.9,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 2.5,
    "fibre": 1,
    "sugar": 1.2,
    "protein": 0.4,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1420,
    "disabled": null,
    "type": "drink",
    "name": "Teh O siew dai",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": "Tea with less sugar",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "teh o [siew|siu] dai tea with less sugar",
    "weight-g": 194,
    "volume-ml": null,
    "calories": 28,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 0,
    "carbs": 7,
    "fibre": 0,
    "sugar": 7,
    "protein": 0.2,
    "potassium": 32,
    "calcium": 0,
    "iron": 0,
    "phosphorus": 2,
    "info": "Tea with less sugar"
  },
  {
    "id": 1421,
    "disabled": null,
    "type": "meal",
    "name": "Edamame pasta, plain (dry)",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "spaghetti, linguine, macaroni",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "edamame pasta, plain (dry) spaghetti, linguine, macaroni",
    "weight-g": 57,
    "volume-ml": null,
    "calories": 200,
    "fat": 3,
    "saturated": 0.5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": null,
    "sodium": 0,
    "carbs": 9,
    "fibre": 12,
    "sugar": 3,
    "protein": 24,
    "potassium": 1120,
    "calcium": 110,
    "iron": 8,
    "phosphorus": null,
    "info": "e.g. spaghetti, linguine, macaroni"
  },
  {
    "id": 1422,
    "disabled": null,
    "type": "meal",
    "name": "Chickpea pasta, plain (dry)",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": "spaghetti, linguine, macaroni",
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chickpea pasta, plain (dry) spaghetti, linguine, macaroni",
    "weight-g": 50,
    "volume-ml": null,
    "calories": 182.5,
    "fat": 3.1,
    "saturated": 0.8,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 0,
    "carbs": 25.5,
    "fibre": 4.4,
    "sugar": 1.2,
    "protein": 11,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. spaghetti, linguine, macaroni"
  },
  {
    "id": 1423,
    "disabled": null,
    "type": "snack",
    "name": "Yogurt, Greek, whole milk",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": 1,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[yogurt|yoghurt], greek, whole milk",
    "weight-g": 160,
    "volume-ml": null,
    "calories": 138,
    "fat": 6.4,
    "saturated": 4.2,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 52,
    "carbs": 6.2,
    "fibre": null,
    "sugar": 4.3,
    "protein": 13.7,
    "potassium": null,
    "calcium": 160,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1424,
    "disabled": null,
    "type": "snack",
    "name": "Yogurt, Greek, low fat",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": 1,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[yogurt|yoghurt], greek, low fat",
    "weight-g": 170,
    "volume-ml": null,
    "calories": 99,
    "fat": 0.3,
    "saturated": 0.2,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 54,
    "carbs": 7.1,
    "fibre": null,
    "sugar": 5.6,
    "protein": 16.5,
    "potassium": null,
    "calcium": 204,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1425,
    "disabled": null,
    "type": "drink",
    "name": "Vitaplus Benecol",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bottle",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "with plant stanols",
    "search-text": "vitaplus benecol",
    "weight-g": null,
    "volume-ml": 70,
    "calories": 42,
    "fat": 2.9,
    "saturated": 0.4,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 25,
    "carbs": 3.2,
    "fibre": 0.4,
    "sugar": 1.3,
    "protein": 0.6,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "with plant stanols"
  },
  {
    "id": 1426,
    "disabled": null,
    "type": "meal",
    "name": "Beef tendon",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "beef tendon",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 147,
    "fat": 0.6,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 51,
    "sodium": 99.3,
    "carbs": 0.1,
    "fibre": null,
    "sugar": null,
    "protein": 35.2,
    "potassium": 48,
    "calcium": 13,
    "iron": 1.7,
    "phosphorus": 22,
    "info": null
  },
  {
    "id": 1427,
    "disabled": null,
    "type": "meal",
    "name": "Beef brisket, braised",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "beef brisket, braised",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 331,
    "fat": 24.5,
    "saturated": 9.4,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 93,
    "sodium": 64,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 25.9,
    "potassium": 251,
    "calcium": 7,
    "iron": 2.5,
    "phosphorus": 207,
    "info": null
  },
  {
    "id": 1428,
    "disabled": null,
    "type": "meal",
    "name": "Pork liver, braised",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": 1,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "portion",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pork liver, braised",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 165,
    "fat": 4.4,
    "saturated": 1.4,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 355,
    "sodium": 49,
    "carbs": 3.8,
    "fibre": 0,
    "sugar": null,
    "protein": 26,
    "potassium": 150,
    "calcium": 10,
    "iron": 18,
    "phosphorus": 241,
    "info": null
  },
  {
    "id": 1429,
    "disabled": null,
    "type": "drink",
    "name": "Keto coffee",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 2,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "keto coffee",
    "weight-g": 15,
    "volume-ml": null,
    "calories": 100,
    "fat": 9,
    "saturated": 8,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 95,
    "carbs": 3,
    "fibre": 0,
    "sugar": 1,
    "protein": 1,
    "potassium": 70,
    "calcium": 20,
    "iron": 0,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1430,
    "disabled": null,
    "type": "meal",
    "name": "Lentil, with spinach and spices, stewed",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "lentil, with spinach and spices, stewed",
    "weight-g": 379,
    "volume-ml": null,
    "calories": 227,
    "fat": 8.7,
    "saturated": 3.2,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 787,
    "carbs": 24.6,
    "fibre": 7.2,
    "sugar": null,
    "protein": 12.5,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1431,
    "disabled": null,
    "type": "meal",
    "name": "Chicken, black pepper",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "palm",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chicken, black pepper",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 150,
    "fat": 6.1,
    "saturated": 2.1,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 60,
    "sodium": 634,
    "carbs": 6.9,
    "fibre": 0,
    "sugar": 1,
    "protein": 16.9,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1432,
    "disabled": null,
    "type": "meal",
    "name": "Rava idli",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "rava idli",
    "weight-g": 51,
    "volume-ml": null,
    "calories": 107,
    "fat": 3.9,
    "saturated": 2.2,
    "polyunsaturated": 0.1,
    "monounsaturated": 0.7,
    "trans": 0.4,
    "cholesterol": 0,
    "sodium": 321,
    "carbs": 12.6,
    "fibre": 1.6,
    "sugar": 1.1,
    "protein": 3.1,
    "potassium": 49,
    "calcium": 57,
    "iron": 0.6,
    "phosphorus": 29,
    "info": null
  },
  {
    "id": 1433,
    "disabled": null,
    "type": "snack",
    "name": "Coconut, grated and desiccated",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "coconut, grated and desiccated",
    "weight-g": 75,
    "volume-ml": null,
    "calories": 497,
    "fat": 48.8,
    "saturated": 43.3,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 11.3,
    "carbs": 5.5,
    "fibre": 11.6,
    "sugar": null,
    "protein": 4.7,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1434,
    "disabled": null,
    "type": "meal",
    "name": "Paneer",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "paneer",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 293,
    "fat": 23,
    "saturated": 16,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 2,
    "fibre": null,
    "sugar": 0,
    "protein": 17,
    "potassium": null,
    "calcium": 480,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1435,
    "disabled": null,
    "type": "meal",
    "name": "Paneer, low fat",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "paneer, low fat",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 157,
    "fat": 5,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 3,
    "fibre": null,
    "sugar": null,
    "protein": 25,
    "potassium": null,
    "calcium": 554,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1436,
    "disabled": null,
    "type": "meal",
    "name": "Acai bowl",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "acai bowl",
    "weight-g": 347,
    "volume-ml": null,
    "calories": 375,
    "fat": 14,
    "saturated": 4.2,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 63.5,
    "carbs": 54,
    "fibre": 8,
    "sugar": null,
    "protein": 8,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1437,
    "disabled": null,
    "type": "meal",
    "name": "Chicken wing, grilled / BBQ",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "chicken wing, grilled / [bbq|barbecue]",
    "weight-g": 98,
    "volume-ml": null,
    "calories": 154,
    "fat": 10.6,
    "saturated": 3,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 80,
    "sodium": 325,
    "carbs": 0,
    "fibre": 0,
    "sugar": null,
    "protein": 14.9,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1438,
    "disabled": null,
    "type": "snack",
    "name": "Olive, preserved",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "olive, preserved",
    "weight-g": 17,
    "volume-ml": null,
    "calories": 34,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 63,
    "carbs": 6,
    "fibre": 1,
    "sugar": 5.7,
    "protein": 0.1,
    "potassium": 4,
    "calcium": 1,
    "iron": 0.1,
    "phosphorus": 1,
    "info": null
  },
  {
    "id": 1439,
    "disabled": null,
    "type": "snack",
    "name": "Sambal chilli",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sambal chilli",
    "weight-g": 12,
    "volume-ml": null,
    "calories": 18.8,
    "fat": 1.2,
    "saturated": 0.3,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 1.8,
    "sodium": 195,
    "carbs": 1.6,
    "fibre": 0.6,
    "sugar": null,
    "protein": 0.4,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1440,
    "disabled": null,
    "type": "snack",
    "name": "Coleslaw sauce",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "coleslaw sauce",
    "weight-g": 20,
    "volume-ml": null,
    "calories": 78,
    "fat": 6,
    "saturated": 0.7,
    "polyunsaturated": 3.9,
    "monounsaturated": 1.4,
    "trans": null,
    "cholesterol": 8,
    "sodium": 194,
    "carbs": 6.1,
    "fibre": 0,
    "sugar": 6.1,
    "protein": 0.2,
    "potassium": 6,
    "calcium": 3,
    "iron": 0.1,
    "phosphorus": 7,
    "info": null
  },
  {
    "id": 1441,
    "disabled": null,
    "type": "snack",
    "name": "Mushroom sauce",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "mushroom sauce",
    "weight-g": 15,
    "volume-ml": null,
    "calories": 69,
    "fat": 6.5,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 398,
    "carbs": 0.5,
    "fibre": 0.1,
    "sugar": 0.5,
    "protein": 1.2,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1442,
    "disabled": null,
    "type": "snack",
    "name": "Table salt",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "table salt",
    "weight-g": 5,
    "volume-ml": null,
    "calories": 0,
    "fat": 0,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1959,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 0,
    "potassium": 5,
    "calcium": 1,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1443,
    "disabled": null,
    "type": "snack",
    "name": "K-Salt lower sodium salt",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "k-salt lower sodium salt",
    "weight-g": 4.8,
    "volume-ml": null,
    "calories": 0,
    "fat": 0,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1320,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 0,
    "potassium": 600,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1444,
    "disabled": null,
    "type": "snack",
    "name": "Pagoda lower sodium salt",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pagoda lower sodium salt",
    "weight-g": 5,
    "volume-ml": null,
    "calories": 0,
    "fat": 0,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1205,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 0,
    "potassium": 468.5,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1445,
    "disabled": null,
    "type": "snack",
    "name": "Pansalt lower sodium salt",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pansalt lower sodium salt",
    "weight-g": 4.8,
    "volume-ml": null,
    "calories": 0,
    "fat": 0,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 1072,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 0,
    "potassium": 560,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1446,
    "disabled": null,
    "type": "snack",
    "name": "Light soy sauce",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "light soy sauce",
    "weight-g": 17,
    "volume-ml": null,
    "calories": 13,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": 0,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 1241,
    "carbs": 1.6,
    "fibre": 0,
    "sugar": 0.9,
    "protein": 1.6,
    "potassium": 8,
    "calcium": 9,
    "iron": 0,
    "phosphorus": 4,
    "info": null
  },
  {
    "id": 1447,
    "disabled": null,
    "type": "snack",
    "name": "Dark soy sauce",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "dark soy sauce",
    "weight-g": 17,
    "volume-ml": null,
    "calories": 15,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 689,
    "carbs": 2.2,
    "fibre": 0,
    "sugar": 1.4,
    "protein": 1.3,
    "potassium": 102,
    "calcium": 13,
    "iron": 0.8,
    "phosphorus": 39,
    "info": null
  },
  {
    "id": 1448,
    "disabled": null,
    "type": "snack",
    "name": "Sweet soy sauce",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sweet soy sauce",
    "weight-g": 20,
    "volume-ml": null,
    "calories": 36,
    "fat": 0.1,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": 0,
    "cholesterol": 7,
    "sodium": 561,
    "carbs": 7.4,
    "fibre": 0,
    "sugar": 4.5,
    "protein": 1.3,
    "potassium": 30,
    "calcium": 13,
    "iron": 0.8,
    "phosphorus": 5,
    "info": null
  },
  {
    "id": 1449,
    "disabled": null,
    "type": "snack",
    "name": "Bragg liquid amino",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "bragg liquid amino",
    "weight-g": null,
    "volume-ml": 5,
    "calories": 5,
    "fat": 0,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 310,
    "carbs": 0,
    "fibre": null,
    "sugar": null,
    "protein": 1,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1450,
    "disabled": null,
    "type": "snack",
    "name": "Sesame sauce dressing",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "sesame sauce dressing",
    "weight-g": 15,
    "volume-ml": null,
    "calories": 65,
    "fat": 6.6,
    "saturated": 0.9,
    "polyunsaturated": 3.7,
    "monounsaturated": 1.8,
    "trans": null,
    "cholesterol": 0,
    "sodium": 147,
    "carbs": 1.2,
    "fibre": 0.1,
    "sugar": 1.2,
    "protein": 0.5,
    "potassium": 23,
    "calcium": 3,
    "iron": 0.1,
    "phosphorus": 5,
    "info": null
  },
  {
    "id": 1451,
    "disabled": null,
    "type": "snack",
    "name": "Wafu salad dressing",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "wafu salad dressing",
    "weight-g": 15.5,
    "volume-ml": null,
    "calories": 55,
    "fat": 5.5,
    "saturated": 0.5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 245,
    "carbs": 1,
    "fibre": 0,
    "sugar": 1,
    "protein": 0.5,
    "potassium": 0,
    "calcium": 0,
    "iron": 0,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1452,
    "disabled": null,
    "type": "snack",
    "name": "Butter, unsalted",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "butter, unsalted",
    "weight-g": 5,
    "volume-ml": null,
    "calories": 37,
    "fat": 4.2,
    "saturated": 2.8,
    "polyunsaturated": 0.1,
    "monounsaturated": 1.1,
    "trans": 0.2,
    "cholesterol": 12.5,
    "sodium": 0.5,
    "carbs": 0.1,
    "fibre": 0,
    "sugar": 0.1,
    "protein": 0.1,
    "potassium": 1,
    "calcium": 1,
    "iron": 0,
    "phosphorus": 1,
    "info": null
  },
  {
    "id": 1453,
    "disabled": null,
    "type": "snack",
    "name": "Margarine, soft",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tsp",
    "info-translation": null,
    "info-brands": "Naturel",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "margarine, soft naturel",
    "weight-g": 5,
    "volume-ml": null,
    "calories": 37.5,
    "fat": 4.1,
    "saturated": 0.8,
    "polyunsaturated": 1.2,
    "monounsaturated": 2.1,
    "trans": 0.1,
    "cholesterol": 0,
    "sodium": 35,
    "carbs": 0.1,
    "fibre": 0,
    "sugar": null,
    "protein": 0.1,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Naturel"
  },
  {
    "id": 1454,
    "disabled": null,
    "type": "meal",
    "name": "Margarine, multi-purpose",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tsp",
    "info-translation": null,
    "info-brands": "Planta",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "margarine, multi-purpose planta",
    "weight-g": 5,
    "volume-ml": null,
    "calories": 37,
    "fat": 4.1,
    "saturated": 2.5,
    "polyunsaturated": 0.3,
    "monounsaturated": 1.2,
    "trans": 0.5,
    "cholesterol": 0,
    "sodium": 39,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 0,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Planta"
  },
  {
    "id": 1455,
    "disabled": null,
    "type": "meal",
    "name": "Olive spread",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "olive spread",
    "weight-g": 5,
    "volume-ml": null,
    "calories": 28.7,
    "fat": 3.2,
    "saturated": 0.8,
    "polyunsaturated": 0.7,
    "monounsaturated": 1.6,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 17,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 0,
    "potassium": 2,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1456,
    "disabled": null,
    "type": "meal",
    "name": "Rice, multigrain",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": 1,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": 1,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "rice, multigrain",
    "weight-g": 200,
    "volume-ml": null,
    "calories": 234,
    "fat": 3.6,
    "saturated": 0.5,
    "polyunsaturated": 1.6,
    "monounsaturated": 1,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 3,
    "carbs": 42.7,
    "fibre": 5.1,
    "sugar": 0.3,
    "protein": 7.5,
    "potassium": 280,
    "calcium": 15,
    "iron": 1.2,
    "phosphorus": 261,
    "info": null
  },
  {
    "id": 1457,
    "disabled": null,
    "type": "meal",
    "name": "Reduced carb wrap, without fillings",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "piece",
    "info-translation": null,
    "info-brands": "Mission",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "reduced carb wrap, without fillings mission",
    "weight-g": 45,
    "volume-ml": null,
    "calories": 130,
    "fat": 3,
    "saturated": 2,
    "polyunsaturated": 0.3,
    "monounsaturated": 0.8,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 225,
    "carbs": 16.6,
    "fibre": 8.6,
    "sugar": 1.1,
    "protein": 9,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Mission"
  },
  {
    "id": 1458,
    "disabled": null,
    "type": "drink",
    "name": "Milk powder, full cream",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": 1,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": "Nespray",
    "info-examples": null,
    "info-portion-aid": "standard dilution (3 tablespoons with water)",
    "info-include-exclude": null,
    "search-text": "milk powder, full cream nespray",
    "weight-g": 33,
    "volume-ml": null,
    "calories": 168,
    "fat": 9.3,
    "saturated": 5.3,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 111,
    "carbs": 13.2,
    "fibre": null,
    "sugar": 13.2,
    "protein": 7.8,
    "potassium": null,
    "calcium": 396,
    "iron": 4.2,
    "phosphorus": 185,
    "info": "e.g. Nespray (standard dilution (3 tablespoons with water))"
  },
  {
    "id": 1459,
    "disabled": null,
    "type": "drink",
    "name": "Milk powder, low fat with plant sterols",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": "Nestle Omega Plus ActiCol",
    "info-examples": null,
    "info-portion-aid": "standard dilution (3 tablespoons with water)",
    "info-include-exclude": null,
    "search-text": "milk powder, low fat with plant sterols nestle omega plus acticol",
    "weight-g": 31,
    "volume-ml": null,
    "calories": 121,
    "fat": 2.9,
    "saturated": 0.8,
    "polyunsaturated": 0.8,
    "monounsaturated": 1.1,
    "trans": 0.1,
    "cholesterol": 9,
    "sodium": 105,
    "carbs": 18,
    "fibre": 0,
    "sugar": 13.2,
    "protein": 6.2,
    "potassium": null,
    "calcium": 496,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Nestle Omega Plus ActiCol (standard dilution (3 tablespoons with water))"
  },
  {
    "id": 1460,
    "disabled": null,
    "type": "drink",
    "name": "Milk, low fat with plant sterols",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "glass",
    "info-translation": null,
    "info-brands": "Marigold",
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "milk, low fat with plant sterols marigold",
    "weight-g": null,
    "volume-ml": 250,
    "calories": 122,
    "fat": 1.8,
    "saturated": 1.3,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 13,
    "sodium": 138,
    "carbs": 16,
    "fibre": 0,
    "sugar": 12.8,
    "protein": 10.5,
    "potassium": null,
    "calcium": 500,
    "iron": null,
    "phosphorus": null,
    "info": "e.g. Marigold"
  },
  {
    "id": 1461,
    "disabled": null,
    "type": "drink",
    "name": "Milk, Anlene Gold Concentrate 4X",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "pack",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "milk, anlene gold concentrate 4x",
    "weight-g": null,
    "volume-ml": 125,
    "calories": 72,
    "fat": 1.9,
    "saturated": 1.3,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0.1,
    "cholesterol": 8,
    "sodium": 66,
    "carbs": 9.5,
    "fibre": 0,
    "sugar": 9.5,
    "protein": 4.4,
    "potassium": null,
    "calcium": 500,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1462,
    "disabled": null,
    "type": "drink",
    "name": "Optimum Nutrition Gold Standard 100% Whey Protein",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "scoop",
    "info-translation": "Protein powder",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "optimum nutrition gold standard 100% whey protein powder",
    "weight-g": 30.4,
    "volume-ml": null,
    "calories": 120,
    "fat": 1.5,
    "saturated": 1,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 35,
    "sodium": 55,
    "carbs": 3,
    "fibre": null,
    "sugar": 1,
    "protein": 24,
    "potassium": 210,
    "calcium": 130,
    "iron": 0.7,
    "phosphorus": null,
    "info": "Protein powder"
  },
  {
    "id": 1463,
    "disabled": null,
    "type": "drink",
    "name": "Optimum Nutrition Gold Standard 100% Isolate",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "scoop",
    "info-translation": "Protein powder",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "optimum nutrition gold standard 100% isolate protein powder",
    "weight-g": 31,
    "volume-ml": null,
    "calories": 110,
    "fat": 0.5,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 5,
    "sodium": 190,
    "carbs": 1,
    "fibre": null,
    "sugar": null,
    "protein": 25,
    "potassium": 240,
    "calcium": 150,
    "iron": 0.5,
    "phosphorus": null,
    "info": "Protein powder"
  },
  {
    "id": 1464,
    "disabled": null,
    "type": "drink",
    "name": "Optimum Nutrition Gold Standard 100% Casein",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": 1,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "scoop",
    "info-translation": "Protein powder",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "optimum nutrition gold standard 100% casein protein powder",
    "weight-g": 34,
    "volume-ml": null,
    "calories": 120,
    "fat": 1,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 15,
    "sodium": 260,
    "carbs": 3,
    "fibre": 1,
    "sugar": 1,
    "protein": 24,
    "potassium": 230,
    "calcium": 500,
    "iron": 1.5,
    "phosphorus": null,
    "info": "Protein powder"
  },
  {
    "id": 1465,
    "disabled": null,
    "type": "drink",
    "name": "MyProtein Impact Whey Protein",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "pack",
    "info-translation": "Protein powder",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "myprotein impact whey protein powder",
    "weight-g": 35,
    "volume-ml": null,
    "calories": 158,
    "fat": 6.2,
    "saturated": 4.9,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 8,
    "carbs": 10,
    "fibre": 1,
    "sugar": 5.8,
    "protein": 15,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "Protein powder"
  },
  {
    "id": 1466,
    "disabled": null,
    "type": "drink",
    "name": "MyProtein Impact Whey Isolate",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "scoop",
    "info-translation": "Protein powder",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "myprotein impact whey isolate protein powder",
    "weight-g": 25,
    "volume-ml": null,
    "calories": 93,
    "fat": 0.1,
    "saturated": 0.1,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 52,
    "carbs": 0.6,
    "fibre": null,
    "sugar": 0.6,
    "protein": 23,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "Protein powder"
  },
  {
    "id": 1467,
    "disabled": null,
    "type": "drink",
    "name": "PranaOn Power Plant Protein",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": "Protein powder",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "pranaon power plant protein powder",
    "weight-g": 44,
    "volume-ml": null,
    "calories": 181,
    "fat": 3.5,
    "saturated": 1.5,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 399,
    "carbs": 5.6,
    "fibre": null,
    "sugar": 0.6,
    "protein": 30.3,
    "potassium": null,
    "calcium": null,
    "iron": 6,
    "phosphorus": null,
    "info": "Protein powder"
  },
  {
    "id": 1468,
    "disabled": null,
    "type": "drink",
    "name": "Isopure Whey Protein Isolate",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "scoop",
    "info-translation": "Protein powder",
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "isopure whey protein isolate powder",
    "weight-g": 29,
    "volume-ml": null,
    "calories": 100,
    "fat": 0,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 10,
    "sodium": 230,
    "carbs": 0,
    "fibre": null,
    "sugar": 0,
    "protein": 25,
    "potassium": null,
    "calcium": 80,
    "iron": null,
    "phosphorus": null,
    "info": "Protein powder"
  },
  {
    "id": 1469,
    "disabled": null,
    "type": "drink",
    "name": "Herbalife Formula 3 Personalized Protein Powder",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "tbsp",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "herbalife formula 3 personalized protein powder",
    "weight-g": 6,
    "volume-ml": null,
    "calories": 20,
    "fat": 0,
    "saturated": 0,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 50,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 5,
    "potassium": 30,
    "calcium": null,
    "iron": 0,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1470,
    "disabled": null,
    "type": "drink",
    "name": "Nutrilite All Plant Protein Powder",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": 1,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "nutrilite all plant protein powder",
    "weight-g": 12.5,
    "volume-ml": null,
    "calories": 50,
    "fat": 0.5,
    "saturated": 0,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 0,
    "sodium": 125,
    "carbs": 0.4,
    "fibre": null,
    "sugar": 0,
    "protein": 10,
    "potassium": null,
    "calcium": 25,
    "iron": 1,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1471,
    "disabled": null,
    "type": "snack",
    "name": "Protein bar, 10g protein",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bar",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "generic",
    "search-text": "protein bar, 10g protein",
    "weight-g": 40,
    "volume-ml": null,
    "calories": 165,
    "fat": 8,
    "saturated": 3.1,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 165,
    "carbs": 11,
    "fibre": 6,
    "sugar": 4,
    "protein": 10,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "generic"
  },
  {
    "id": 1472,
    "disabled": null,
    "type": "snack",
    "name": "Protein bar, 15g protein",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bar",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "generic",
    "search-text": "protein bar, 15g protein",
    "weight-g": 48,
    "volume-ml": null,
    "calories": 162,
    "fat": 6,
    "saturated": 3.3,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 190,
    "carbs": 12,
    "fibre": 8,
    "sugar": 2,
    "protein": 15,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "generic"
  },
  {
    "id": 1473,
    "disabled": null,
    "type": "snack",
    "name": "Protein bar, 20g protein",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bar",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "generic",
    "search-text": "protein bar, 20g protein",
    "weight-g": 59,
    "volume-ml": null,
    "calories": 204,
    "fat": 8,
    "saturated": 3.4,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 136,
    "carbs": 15,
    "fibre": 7,
    "sugar": 1,
    "protein": 20,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "generic"
  },
  {
    "id": 1474,
    "disabled": null,
    "type": "drink",
    "name": "Protein powder",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": "generic",
    "search-text": "protein powder",
    "weight-g": 33,
    "volume-ml": null,
    "calories": 126,
    "fat": 2,
    "saturated": null,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": null,
    "carbs": 3,
    "fibre": null,
    "sugar": null,
    "protein": 24,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": "generic"
  },
  {
    "id": 1475,
    "disabled": null,
    "type": "drink",
    "name": "3-in-1 coffee, regular",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": 1,
    "probiotic": null,
    "plant-protein": null,
    "processed": 1,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "sachet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "3-in-1 coffee, regular",
    "weight-g": 20,
    "volume-ml": null,
    "calories": 84,
    "fat": 1.7,
    "saturated": 1.7,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": 0,
    "cholesterol": 0,
    "sodium": 13,
    "carbs": 16,
    "fibre": 0.6,
    "sugar": 10.5,
    "protein": 0.5,
    "potassium": null,
    "calcium": null,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1476,
    "disabled": null,
    "type": "meal",
    "name": "Char siew and roast pork rice",
    "rating": 1,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": 1,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": 1,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": 1,
    "refined-grain": 1,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "plate",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[char|cha] [siew|shao|siu] and roast pork rice",
    "weight-g": 449,
    "volume-ml": null,
    "calories": 927,
    "fat": 39,
    "saturated": 16,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": 155,
    "sodium": 1570,
    "carbs": 93,
    "fibre": 2.9,
    "sugar": 5.5,
    "protein": 49,
    "potassium": null,
    "calcium": null,
    "iron": 2.1,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1477,
    "disabled": null,
    "type": "snack",
    "name": "Yogurt, Greek, fat free",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[yogurt|yoghurt], greek, fat free",
    "weight-g": 160,
    "volume-ml": null,
    "calories": 89,
    "fat": 0.3,
    "saturated": 0.2,
    "polyunsaturated": null,
    "monounsaturated": null,
    "trans": null,
    "cholesterol": null,
    "sodium": 51,
    "carbs": 6.4,
    "fibre": null,
    "sugar": 5.3,
    "protein": 14.9,
    "potassium": null,
    "calcium": 192,
    "iron": null,
    "phosphorus": null,
    "info": null
  },
  {
    "id": 1478,
    "disabled": null,
    "type": "meal",
    "name": "Chicken breast, with skin, steamed",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[chicken breast|chicken], with skin, steamed",
    "weight-g": 130,
    "volume-ml": null,
    "calories": 287,
    "fat": 14.6,
    "saturated": 3.4,
    "polyunsaturated": 4.2,
    "monounsaturated": 5.7,
    "trans": 0,
    "cholesterol": 98,
    "sodium": 392,
    "carbs": 0.4,
    "fibre": 0,
    "sugar": 0.2,
    "protein": 36,
    "potassium": 234,
    "calcium": 20,
    "iron": 1.2,
    "phosphorus": 204,
    "info": null
  },
  {
    "id": 1479,
    "disabled": null,
    "type": "meal",
    "name": "Chicken breast, without skin, steamed",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[chicken breast|chicken], without skin, steamed",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 229,
    "fat": 8.6,
    "saturated": 1.7,
    "polyunsaturated": 3,
    "monounsaturated": 3.1,
    "trans": 0,
    "cholesterol": 92,
    "sodium": 387,
    "carbs": 0.4,
    "fibre": 0,
    "sugar": 0.2,
    "protein": 35.2,
    "potassium": 227,
    "calcium": 19,
    "iron": 1.1,
    "phosphorus": 199,
    "info": null
  },
  {
    "id": 1480,
    "disabled": null,
    "type": "meal",
    "name": "Chicken breast, with skin, grilled",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[chicken breast|chicken], with skin, grilled",
    "weight-g": 138,
    "volume-ml": null,
    "calories": 210,
    "fat": 10,
    "saturated": 4.4,
    "polyunsaturated": 1.6,
    "monounsaturated": 4,
    "trans": null,
    "cholesterol": 130,
    "sodium": 710,
    "carbs": 2,
    "fibre": 0,
    "sugar": 2,
    "protein": 28.1,
    "potassium": 283,
    "calcium": 30,
    "iron": 0.9,
    "phosphorus": 220,
    "info": null
  },
  {
    "id": 1481,
    "disabled": null,
    "type": "meal",
    "name": "Chicken breast, without skin, grilled",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[chicken breast|chicken], without skin, grilled",
    "weight-g": 120,
    "volume-ml": null,
    "calories": 170,
    "fat": 6,
    "saturated": 3.4,
    "polyunsaturated": 0.6,
    "monounsaturated": 3,
    "trans": null,
    "cholesterol": 93,
    "sodium": 400,
    "carbs": 1,
    "fibre": 0,
    "sugar": 1,
    "protein": 28,
    "potassium": 234,
    "calcium": 32,
    "iron": 0.7,
    "phosphorus": 141,
    "info": null
  },
  {
    "id": 1482,
    "disabled": null,
    "type": "meal",
    "name": "Salmon, steamed",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": 1,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": null,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": 1,
    "whole-grain": null,
    "serving": 1,
    "unit": "fillet",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "salmon, steamed",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 184,
    "fat": 7.5,
    "saturated": 1.6,
    "polyunsaturated": 2.5,
    "monounsaturated": 2.7,
    "trans": null,
    "cholesterol": 57,
    "sodium": 53,
    "carbs": 0,
    "fibre": 0,
    "sugar": 0,
    "protein": 27.4,
    "potassium": 455,
    "calcium": 46,
    "iron": 0.7,
    "phosphorus": 298,
    "info": null
  },
  {
    "id": 1483,
    "disabled": null,
    "type": "snack",
    "name": "Hummus, home-made (USDA)",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": 1,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "serving",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "hummus, home-made (usda)",
    "weight-g": 100,
    "volume-ml": null,
    "calories": 144,
    "fat": 8.6,
    "saturated": 1.1,
    "polyunsaturated": 2.1,
    "monounsaturated": 4.9,
    "trans": null,
    "cholesterol": 0,
    "sodium": 242,
    "carbs": 20.1,
    "fibre": 4,
    "sugar": 0.3,
    "protein": 4.9,
    "potassium": 173,
    "calcium": 49,
    "iron": 1.6,
    "phosphorus": 110,
    "info": null
  },
  {
    "id": 1484,
    "disabled": null,
    "type": "meal",
    "name": "Rice, chicken (rice only)",
    "rating": 2,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": null,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": 1,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "bowl",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "[rice|chicken rice], chicken ([rice|chicken rice] only)",
    "weight-g": 200,
    "volume-ml": null,
    "calories": 343,
    "fat": 8.2,
    "saturated": 4.7,
    "polyunsaturated": 0.7,
    "monounsaturated": 2.8,
    "trans": null,
    "cholesterol": 16,
    "sodium": 556,
    "carbs": 63,
    "fibre": 4,
    "sugar": 6.4,
    "protein": 4.2,
    "potassium": 40,
    "calcium": 6,
    "iron": 0.2,
    "phosphorus": 64,
    "info": null
  },
  {
    "id": 1485,
    "disabled": null,
    "type": "meal",
    "name": "Kale, Raw",
    "rating": 3,
    "alcohol": null,
    "fried": null,
    "fruit": null,
    "healthy-fats": null,
    "high-calcium": null,
    "high-cholesterol": null,
    "high-fat": 1,
    "high-fibre": 1,
    "high-gi": null,
    "high-iodine": null,
    "high-iron": null,
    "high-phosphate": null,
    "high-potassium": null,
    "high-purine": null,
    "high-salt": null,
    "high-sugar": null,
    "probiotic": null,
    "plant-protein": null,
    "processed": null,
    "red-meat": null,
    "refined-grain": null,
    "vegetable": null,
    "white-meat": null,
    "whole-grain": null,
    "serving": 1,
    "unit": "cup",
    "info-translation": null,
    "info-brands": null,
    "info-examples": null,
    "info-portion-aid": null,
    "info-include-exclude": null,
    "search-text": "kale, raw",
    "weight-g": 21,
    "volume-ml": null,
    "calories": 7.4,
    "fat": 0.3,
    "saturated": 0,
    "polyunsaturated": 0.1,
    "monounsaturated": 0,
    "trans": null,
    "cholesterol": 0,
    "sodium": 11.1,
    "carbs": 0.9,
    "fibre": 0.9,
    "sugar": 0.2,
    "protein": 0.6,
    "potassium": 73.1,
    "calcium": 53.3,
    "iron": 0.3,
    "phosphorus": 11.6,
    "info": null
  }
]
    });
  