define("ember-simple-set-helper/helpers/set", ["exports", "@ember/component/helper", "@ember/debug", "@ember/object"], function (_exports, _helper, _debug, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function set(positional) {
    let [target, key, maybeValue] = positional;
    (false && !(Boolean(target) && typeof key === 'string' || typeof key === 'symbol') && (0, _debug.assert)('you must pass a path to {{set}}', Boolean(target) && typeof key === 'string' || typeof key === 'symbol'));
    return positional.length === 3 ? () => (0, _object.set)(target, key, maybeValue) : value => (0, _object.set)(target, key, value);
  }

  var _default = (0, _helper.helper)(set);

  _exports.default = _default;
});