define("ember-math-helpers/helpers/lcm", ["exports", "@ember/component/helper", "ember-math-helpers/helpers/gcd"], function (_exports, _helper, _gcd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.lcm = lcm;

  /**
   * Returns the smallest positive integer that is divisible by both number1 and number2
   *
   * ```hbs
   * {{lcm a b}}
   * ```
   *
   * @param {number} number1 The first number
   * @param {number} number2 The second number
   * @return {number} The LCM of the two numbers passed
   */
  function lcm(_ref) {
    let [number1 = 0, number2 = 0] = _ref;
    return number1 === 0 || number2 === 0 ? 0 : Math.abs(number1 * number2) / (0, _gcd.gcd)([number1, number2]);
  }

  var _default = (0, _helper.helper)(lcm);

  _exports.default = _default;
});