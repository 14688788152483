define("ember-math-helpers/helpers/expm1", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.expm1 = expm1;

  /**
   * Executes `Math.expm1` on the number passed to the helper.
   *
   * ```hbs
   * {{expm1 a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.expm1`
   * @return {number} The expm1 of the passed number
   */
  function expm1(_ref) {
    let [number] = _ref;
    return Math.expm1(number);
  }

  var _default = (0, _helper.helper)(expm1);

  _exports.default = _default;
});