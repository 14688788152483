define("ember-animated-routes/components/route", ["exports", "@glimmer/component", "ember-animated-routes/lib/animation-queue", "ember-animated-routes/templates/components/route", "@ember/component", "@ember/service", "@ember/runloop", "@glimmer/tracking"], function (_exports, _component, _animationQueue, _route, _component2, _service, _runloop, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let RouteComponent = (_class = class RouteComponent extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queue", void 0);

      _initializerDefineProperty(this, "isActive", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      this.router.on('routeDidChange', transition => this.computeIsActive(transition));
      this.queue = this.args.queue || new _animationQueue.default();
      (0, _runloop.scheduleOnce)('afterRender', this, 'computeIsActive');
    }

    get componentName() {
      return this.args.component || this.routeName.replace(/\./g, '/');
    }

    get routeName() {
      return this.args.name.replace(/\//g, '.');
    }

    get routeHandler() {
      return this.router.currentRoute.find(handler => handler.name === this.routeName);
    }

    get renderTask() {
      return this.queue.renderTaskFor(this);
    }

    get shouldPreserve() {
      if (!this.args.preserveOn) {
        return false;
      }

      return this.args.preserveOn.split(' ').some(route => this.router.isActive(route));
    }

    get inDOM() {
      return this.renderTask && this.renderTask.inDOM || this.args.alwaysRendered;
    }

    computeIsActive(transition) {
      const isActive = this.router.isActive(this.routeName);

      if (isActive && !this.isActive) {
        const fromRoute = transition ? transition.from : null;
        this.queue.queueRender(this, fromRoute, this.routeHandler.attributes);
        this.isActive = true;
      } else if (!isActive && this.isActive && !this.shouldPreserve) {
        const toRoute = transition.to;
        this.queue.queueTeardown(this, toRoute);
        this.isActive = false;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isActive", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);

  var _default = (0, _component2.setComponentTemplate)(_route.default, RouteComponent);

  _exports.default = _default;
});