
    ;define("novi-data/food-bank.backup", ["exports"], function (_exports) {
      "use strict";

      Object.defineProperty(_exports, "__esModule", {
        value: true
      });
      _exports.default = [
  {
    "id": "435",
    "disabled": "1",
    "type": "meal",
    "|| name                                               ||": "Vegetable (green e.g. kale, spinach)",
    "rating": "3"
  },
  {
    "id": "621",
    "type": "drink",
    "|| name                                               ||": "2-in-1 coffee, unsweetened",
    "rating": "2",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "sachet",
    "|| info                                                             ||": "e.g. Old Town, Nescafe",
    "weight-g": "9",
    "calories": "45.2",
    "fat": "2.4",
    "cal-from-fat": "21.6",
    "per-cal-from-fat": "48",
    "carbs": "5.2",
    "sugar": "1.3",
    "protein": "0.7",
    "cal-from-protein": "3",
    "per-cal-from-protein": "6",
    "source": "https://www.fairprice.com.sg/product/nescafe-zero-sugar-added-2-in-1-instant-coffee-35s-13083287",
    "old units (ref)": "sachet"
  },
  {
    "id": "620",
    "type": "drink",
    "|| name                                               ||": "3-in-1 coffee, reduced sugar",
    "rating": "2",
    "high-sugar": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "sachet",
    "|| info                                                             ||": "e.g. Old Town, Nescafe",
    "weight-g": "15",
    "calories": "66",
    "fat": "2.1",
    "cal-from-fat": "18.9",
    "per-cal-from-fat": "29",
    "saturated": "2",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "16",
    "carbs": "11",
    "fibre": "0.4",
    "sugar": "6.2",
    "protein": "0.6",
    "cal-from-protein": "2",
    "per-cal-from-protein": "4",
    "source": "N Buddy",
    "old units (ref)": "sachet"
  },
  {
    "id": "474",
    "type": "snack",
    "|| name                                               ||": "Agar agar",
    "rating": "2",
    "high-gi": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "with sugar and coconut milk",
    "weight-g": "111",
    "calories": "84.6",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "2",
    "saturated": "0.2",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "20.5",
    "fibre": "0.6",
    "sugar": "17.3",
    "protein": "0.2",
    "cal-from-protein": "1",
    "per-cal-from-protein": "1",
    "potassium": "6",
    "calcium": "2",
    "iron": "0.3",
    "phosphorus": "1",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "1025",
    "type": "snack",
    "|| name                                               ||": "Almond cake",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "108",
    "calories": "450",
    "fat": "26",
    "cal-from-fat": "234",
    "per-cal-from-fat": "52",
    "saturated": "11",
    "trans": "0.5",
    "cholesterol": "130",
    "sodium": "291",
    "carbs": "49",
    "fibre": "3",
    "sugar": "38",
    "protein": "8",
    "cal-from-protein": "32",
    "per-cal-from-protein": "7",
    "calcium": "100",
    "source": "USDA",
    "old units (ref)": "slice"
  },
  {
    "id": "166",
    "type": "drink",
    "|| name                                               ||": "Almond milk, sweetened",
    "rating": "2",
    "high-gi": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "volume-ml": "240",
    "calories": "60.6",
    "fat": "2.6",
    "cal-from-fat": "23.4",
    "per-cal-from-fat": "39",
    "saturated": "0",
    "cholesterol": "0",
    "sodium": "167",
    "carbs": "8.3",
    "fibre": "0.5",
    "sugar": "7.3",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "7",
    "calcium": "118",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "362",
    "type": "drink",
    "|| name                                               ||": "Almond milk, unsweetened",
    "rating": "3",
    "high-potassium": "1",
    "high-purine": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "volume-ml": "250",
    "calories": "31.4",
    "fat": "2.6",
    "cal-from-fat": "23.4",
    "per-cal-from-fat": "75",
    "saturated": "0",
    "cholesterol": "0",
    "sodium": "177",
    "carbs": "1",
    "fibre": "0.5",
    "sugar": "0",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "13",
    "calcium": "118",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "188",
    "type": "snack",
    "|| name                                               ||": "Almond paste",
    "rating": "2",
    "healthy-fats": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Xin Ren Hu",
    "info-translation": "Xin Ren Hu",
    "alternate-spellings": "Hu: Wu, Woo",
    "weight-g": "262",
    "calories": "216.1",
    "fat": "11.3",
    "cal-from-fat": "101.7",
    "per-cal-from-fat": "47",
    "saturated": "0.71",
    "polyunsaturated": "2.7",
    "monounsaturated": "7.9",
    "cholesterol": "0",
    "sodium": "30.65",
    "carbs": "23.1",
    "fibre": "1.57",
    "sugar": "18.1",
    "protein": "5.5",
    "cal-from-protein": "22",
    "per-cal-from-protein": "10",
    "calcium": "41",
    "iron": "0.9",
    "source": "USDA",
    "old units (ref)": "bowl"
  },
  {
    "id": "221",
    "type": "snack",
    "|| name                                               ||": "Almond pudding / jelly",
    "rating": "2",
    "high-gi": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Almond Jelly",
    "weight-g": "223",
    "calories": "109",
    "fat": "0.5",
    "cal-from-fat": "4.5",
    "per-cal-from-fat": "4",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "2",
    "carbs": "36.6",
    "fibre": "1.3",
    "sugar": "34.6",
    "protein": "0.9",
    "cal-from-protein": "4",
    "per-cal-from-protein": "3",
    "calcium": "25",
    "iron": "0.2",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "673",
    "type": "snack",
    "|| name                                               ||": "Aloe vera",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "100",
    "calories": "2.4",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "16",
    "carbs": "0.6",
    "fibre": "0",
    "sugar": "0.6",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "24",
    "calcium": "27",
    "iron": "0",
    "phosphorus": "3",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "61",
    "type": "snack",
    "|| name                                               ||": "Ang ku kueh with mung bean filling",
    "rating": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "alternate-spellings": "Ku: Koo \nKueh: Kuih, Kue\nMung: Green",
    "weight-g": "70",
    "calories": "140.9",
    "fat": "1.7",
    "cal-from-fat": "15.3",
    "per-cal-from-fat": "11",
    "saturated": "1",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.4",
    "cholesterol": "0",
    "sodium": "26",
    "carbs": "28.1",
    "fibre": "2.1",
    "sugar": "5.7",
    "protein": "3.3",
    "cal-from-protein": "13",
    "per-cal-from-protein": "9",
    "calcium": "8",
    "iron": "0.7",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "62",
    "type": "snack",
    "|| name                                               ||": "Ang ku kueh with peanut filling",
    "rating": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "alternate-spellings": "Ku: Koo \nKueh: Kuih, Kue",
    "weight-g": "68",
    "calories": "239.8",
    "fat": "12.2",
    "cal-from-fat": "109.8",
    "per-cal-from-fat": "46",
    "saturated": "4.1",
    "polyunsaturated": "3.3",
    "monounsaturated": "4.4",
    "cholesterol": "0",
    "sodium": "36",
    "carbs": "26.4",
    "fibre": "3.3",
    "sugar": "5",
    "protein": "6.1",
    "cal-from-protein": "24",
    "per-cal-from-protein": "10",
    "calcium": "15",
    "iron": "0.7",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "479",
    "type": "snack",
    "|| name                                               ||": "Apam balik",
    "rating": "2",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Ban Chang Kueh with peanut/red bean fillings",
    "info-translation": "Ban Chang Kueh",
    "info-include-exclude": "with peanut / red bean",
    "alternate-spellings": "Ban: Mee, Meen, MIn  \nChang: Chien, Chiang, Jiang\nKueh: Kuih, Kue",
    "weight-g": "120",
    "calories": "262.4",
    "fat": "5.2",
    "cal-from-fat": "46.8",
    "per-cal-from-fat": "18",
    "saturated": "1.4",
    "polyunsaturated": "1.6",
    "monounsaturated": "1.9",
    "cholesterol": "0",
    "sodium": "7",
    "carbs": "46.2",
    "fibre": "3.2",
    "sugar": "8.8",
    "protein": "7.7",
    "cal-from-protein": "31",
    "per-cal-from-protein": "12",
    "calcium": "35",
    "iron": "1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "379",
    "type": "snack",
    "|| name                                               ||": "Apple",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "serving": "1",
    "|| unit ||": "fruit",
    "weight-g": "160",
    "calories": "92",
    "fat": "0.4",
    "cal-from-fat": "3.6",
    "per-cal-from-fat": "4",
    "saturated": "0.1",
    "polyunsaturated": "0.1",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "21.8",
    "fibre": "2.7",
    "sugar": "21.8",
    "protein": "0.3",
    "cal-from-protein": "1",
    "per-cal-from-protein": "1",
    "potassium": "166",
    "calcium": "6",
    "iron": "0.1",
    "phosphorus": "10",
    "source": "N Buddy",
    "old units (ref)": "small fruit"
  },
  {
    "id": "706",
    "type": "snack",
    "|| name                                               ||": "Apricot",
    "rating": "3",
    "fruit": "1",
    "high-potassium": "1",
    "serving": "3",
    "|| unit ||": "fruit",
    "weight-g": "114",
    "calories": "56.8",
    "fat": "0.4",
    "cal-from-fat": "3.6",
    "per-cal-from-fat": "6",
    "saturated": "0",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.2",
    "cholesterol": "0",
    "sodium": "1",
    "carbs": "11.8",
    "fibre": "2.1",
    "sugar": "9.8",
    "protein": "1.5",
    "cal-from-protein": "6",
    "per-cal-from-protein": "11",
    "potassium": "275",
    "calcium": "14",
    "iron": "0.4",
    "phosphorus": "24",
    "source": "N Buddy",
    "old units (ref)": "whole"
  },
  {
    "id": "664",
    "type": "meal",
    "|| name                                               ||": "Asparagus",
    "rating": "3",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "6",
    "|| unit ||": "stalk",
    "weight-g": "100",
    "calories": "33.8",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "5",
    "saturated": "1",
    "polyunsaturated": "1",
    "monounsaturated": "5",
    "cholesterol": "0",
    "sodium": "492",
    "carbs": "6",
    "fibre": "2",
    "sugar": "4",
    "protein": "2",
    "cal-from-protein": "8",
    "per-cal-from-protein": "24",
    "potassium": "221",
    "calcium": "25",
    "iron": "2",
    "phosphorus": "59",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "407",
    "type": "snack",
    "|| name                                               ||": "Avocado",
    "rating": "3",
    "fruit": "1",
    "healthy-fats": "1",
    "high-fibre": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "fruit",
    "weight-g": "201",
    "calories": "295.98",
    "fat": "29.5",
    "cal-from-fat": "265.5",
    "per-cal-from-fat": "90",
    "saturated": "4.27",
    "polyunsaturated": "3.65",
    "monounsaturated": "19.7",
    "cholesterol": "0",
    "sodium": "14.1",
    "carbs": "3.6",
    "fibre": "13.5",
    "sugar": "1.33",
    "protein": "4.02",
    "cal-from-protein": "16",
    "per-cal-from-protein": "5",
    "potassium": "975",
    "calcium": "24.1",
    "iron": "1.1",
    "phosphorus": "105",
    "source": "N Buddy",
    "old units (ref)": "whole fruit"
  },
  {
    "id": "459",
    "type": "meal",
    "|| name                                               ||": "Ayam penyet",
    "rating": "1",
    "fried": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "probiotic": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "includes tempeh, tauhu, sambal",
    "info-include-exclude": "with tempeh, tauhu, sambal",
    "weight-g": "462",
    "calories": "917",
    "fat": "40.6",
    "cal-from-fat": "365.4",
    "per-cal-from-fat": "40",
    "saturated": "12.5",
    "polyunsaturated": "3.4",
    "monounsaturated": "7.2",
    "cholesterol": "136",
    "sodium": "2209",
    "carbs": "104.7",
    "fibre": "10.9",
    "sugar": "12",
    "protein": "33.2",
    "cal-from-protein": "133",
    "per-cal-from-protein": "14",
    "potassium": "400",
    "calcium": "101",
    "iron": "2.3",
    "phosphorus": "414",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "649",
    "type": "meal",
    "|| name                                               ||": "Baby fine bean / french bean",
    "rating": "3",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "100",
    "calories": "109.6",
    "fat": "7.6",
    "cal-from-fat": "68.4",
    "per-cal-from-fat": "62",
    "saturated": "3.4",
    "polyunsaturated": "0.7",
    "monounsaturated": "3.1",
    "cholesterol": "0",
    "sodium": "941",
    "carbs": "8.9",
    "fibre": "2.8",
    "sugar": "3.6",
    "protein": "1.4",
    "cal-from-protein": "6",
    "per-cal-from-protein": "5",
    "potassium": "18",
    "calcium": "44",
    "iron": "0.3",
    "phosphorus": "8",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "880",
    "type": "meal",
    "|| name                                               ||": "Baby octopus, seasoned",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "serving": "0.5",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Chuka Iidako",
    "info-translation": "Chuka Iidako",
    "weight-g": "100",
    "calories": "143",
    "fat": "5.5",
    "cal-from-fat": "49.5",
    "per-cal-from-fat": "35",
    "saturated": "0.8",
    "polyunsaturated": "2.7",
    "monounsaturated": "1.7",
    "cholesterol": "109",
    "sodium": "668",
    "carbs": "8.3",
    "fibre": "1",
    "sugar": "4.1",
    "protein": "15.2",
    "cal-from-protein": "61",
    "per-cal-from-protein": "43",
    "potassium": "49",
    "calcium": "23",
    "iron": "0.5",
    "phosphorus": "87",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "879",
    "type": "meal",
    "|| name                                               ||": "Baby octopus, stir-fried / grilled",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "serving": "0.5",
    "|| unit ||": "plate",
    "weight-g": "100",
    "calories": "222",
    "fat": "11.6",
    "cal-from-fat": "104.4",
    "per-cal-from-fat": "47",
    "saturated": "1.9",
    "polyunsaturated": "4.4",
    "monounsaturated": "4.4",
    "cholesterol": "79",
    "sodium": "547",
    "carbs": "12.2",
    "fibre": "0.6",
    "sugar": "0.9",
    "protein": "16.3",
    "cal-from-protein": "65",
    "per-cal-from-protein": "29",
    "potassium": "351",
    "calcium": "78",
    "iron": "5.6",
    "phosphorus": "207",
    "source": "USDA",
    "old units (ref)": "portion"
  },
  {
    "id": "92",
    "type": "meal",
    "|| name                                               ||": "Bacon",
    "rating": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "30",
    "calories": "121.3",
    "fat": "10.1",
    "cal-from-fat": "90.9",
    "per-cal-from-fat": "75",
    "saturated": "4",
    "polyunsaturated": "1.1",
    "monounsaturated": "4.6",
    "cholesterol": "38",
    "sodium": "606",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "7.6",
    "cal-from-protein": "30",
    "per-cal-from-protein": "25",
    "potassium": "87",
    "calcium": "4",
    "iron": "0.5",
    "phosphorus": "48",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "1106",
    "type": "snack",
    "|| name                                               ||": "Bagel, plain",
    "rating": "2",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bagel",
    "weight-g": "114",
    "calories": "314",
    "fat": "1.8",
    "cal-from-fat": "16.2",
    "per-cal-from-fat": "5",
    "saturated": "0.3",
    "polyunsaturated": "0.8",
    "monounsaturated": "1.3",
    "sodium": "609",
    "carbs": "60.9",
    "fibre": "2.6",
    "protein": "12",
    "cal-from-protein": "48",
    "per-cal-from-protein": "15",
    "potassium": "115",
    "calcium": "84",
    "iron": "4.1",
    "phosphorus": "109",
    "source": "N Buddy"
  },
  {
    "id": "890",
    "type": "meal",
    "|| name                                               ||": "Baguette, plain",
    "rating": "2",
    "high-gi": "1",
    "refined-grain": "1",
    "serving": "0.5",
    "|| unit ||": "mini",
    "|| info                                                             ||": "4.5 inches",
    "info-portion-aid": "4.5\"",
    "weight-g": "76",
    "calories": "207",
    "fat": "1.8",
    "cal-from-fat": "16.2",
    "per-cal-from-fat": "8",
    "saturated": "0.4",
    "polyunsaturated": "0.7",
    "monounsaturated": "0.3",
    "cholesterol": "0",
    "sodium": "458",
    "carbs": "39.5",
    "fibre": "1.7",
    "sugar": "3.5",
    "protein": "8.2",
    "cal-from-protein": "33",
    "per-cal-from-protein": "16",
    "potassium": "89",
    "calcium": "40",
    "iron": "3",
    "phosphorus": "80",
    "source": "USDA",
    "old units (ref)": "portion"
  },
  {
    "id": "445",
    "type": "meal",
    "|| name                                               ||": "Bak kut teh with rice",
    "rating": "2",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "weight-g": "638",
    "calories": "576",
    "fat": "24.3",
    "cal-from-fat": "218.7",
    "per-cal-from-fat": "38",
    "saturated": "9.6",
    "polyunsaturated": "0.6",
    "monounsaturated": "8.7",
    "cholesterol": "153",
    "sodium": "1325",
    "carbs": "56",
    "fibre": "1.8",
    "sugar": "0.2",
    "protein": "33",
    "cal-from-protein": "132",
    "per-cal-from-protein": "23",
    "calcium": "336",
    "iron": "7",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "1053",
    "type": "meal",
    "|| name                                               ||": "Bak kut teh, without rice",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "weight-g": "458",
    "calories": "346.71",
    "fat": "25.19",
    "cal-from-fat": "226.71",
    "per-cal-from-fat": "65",
    "saturated": "10.08",
    "cholesterol": "164.88",
    "sodium": "1415",
    "protein": "30",
    "cal-from-protein": "120",
    "per-cal-from-protein": "35",
    "source": "HPB",
    "old units (ref)": "portion"
  },
  {
    "id": "488",
    "type": "snack",
    "|| name                                               ||": "Bak zhang",
    "rating": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Glutinous rice dumplings with meat filling",
    "info-translation": "Glutinous rice dumplings with meat filling",
    "weight-g": "165",
    "calories": "314",
    "fat": "7.9",
    "cal-from-fat": "71.1",
    "per-cal-from-fat": "23",
    "saturated": "3",
    "polyunsaturated": "1.3",
    "monounsaturated": "3.3",
    "cholesterol": "18",
    "sodium": "495",
    "carbs": "52.3",
    "fibre": "1.8",
    "sugar": "3",
    "protein": "8.4",
    "cal-from-protein": "34",
    "per-cal-from-protein": "11",
    "calcium": "25",
    "iron": "1.6",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "227",
    "type": "snack",
    "|| name                                               ||": "Baked puff with savoury filling",
    "rating": "2",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. baked sardine / tuna puff",
    "info-examples": "sardine, tuna",
    "weight-g": "36",
    "calories": "112.2",
    "fat": "6.2",
    "cal-from-fat": "55.8",
    "per-cal-from-fat": "50",
    "saturated": "2.8",
    "polyunsaturated": "0.7",
    "monounsaturated": "2.5",
    "cholesterol": "8",
    "sodium": "146",
    "carbs": "11.1",
    "fibre": "1",
    "sugar": "1.1",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "11",
    "potassium": "60",
    "calcium": "22",
    "iron": "0.5",
    "phosphorus": "35",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "40",
    "type": "snack",
    "|| name                                               ||": "Baklava",
    "rating": "1",
    "high-gi": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "2\" x 2\" x 1\"",
    "info-portion-aid": "2\" x 2\" x 1\"",
    "weight-g": "78",
    "calories": "342.3",
    "fat": "22.7",
    "cal-from-fat": "204.3",
    "per-cal-from-fat": "60",
    "saturated": "9.5",
    "polyunsaturated": "4.1",
    "monounsaturated": "7.7",
    "cholesterol": "35",
    "sodium": "263",
    "carbs": "29.3",
    "fibre": "2",
    "sugar": "9.9",
    "protein": "5.2",
    "cal-from-protein": "21",
    "per-cal-from-protein": "6",
    "potassium": "130",
    "calcium": "31",
    "iron": "1.5",
    "phosphorus": "90",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "692",
    "type": "meal",
    "|| name                                               ||": "Bamboo shoot, braised / canned, drained",
    "rating": "3",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "102",
    "calories": "73.1",
    "fat": "5.1",
    "cal-from-fat": "45.9",
    "per-cal-from-fat": "63",
    "cholesterol": "0",
    "sodium": "782",
    "carbs": "5.1",
    "fibre": "1.7",
    "sugar": "0",
    "protein": "1.7",
    "cal-from-protein": "7",
    "per-cal-from-protein": "9",
    "potassium": "17",
    "calcium": "17",
    "iron": "3.4",
    "phosphorus": "34",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "446",
    "type": "meal",
    "|| name                                               ||": "Ban mian, dry",
    "rating": "2",
    "high-potassium": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "562",
    "calories": "502.6",
    "fat": "21.8",
    "cal-from-fat": "196.2",
    "per-cal-from-fat": "39",
    "saturated": "8.5",
    "polyunsaturated": "3.3",
    "monounsaturated": "9.5",
    "cholesterol": "232",
    "sodium": "2885",
    "carbs": "54.6",
    "fibre": "3.2",
    "sugar": "2.3",
    "protein": "22",
    "cal-from-protein": "88",
    "per-cal-from-protein": "18",
    "potassium": "601",
    "calcium": "106",
    "iron": "1.9",
    "phosphorus": "217",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "239",
    "type": "meal",
    "|| name                                               ||": "Ban mian, soup",
    "rating": "2",
    "high-gi": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "528",
    "calories": "474.5",
    "fat": "21.7",
    "cal-from-fat": "195.3",
    "per-cal-from-fat": "41",
    "saturated": "8.5",
    "polyunsaturated": "3.2",
    "monounsaturated": "9.5",
    "cholesterol": "232",
    "sodium": "2196",
    "carbs": "48.1",
    "fibre": "3.2",
    "sugar": "0",
    "protein": "21.7",
    "cal-from-protein": "87",
    "per-cal-from-protein": "18",
    "potassium": "491",
    "calcium": "84",
    "iron": "1.1",
    "phosphorus": "174",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "380",
    "type": "snack",
    "|| name                                               ||": "Banana",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "fruit",
    "weight-g": "136",
    "calories": "116.661",
    "fat": "0.389",
    "cal-from-fat": "3.501",
    "per-cal-from-fat": "3",
    "saturated": "0.132",
    "polyunsaturated": "0.086",
    "monounsaturated": "0.038",
    "cholesterol": "0",
    "sodium": "1.18",
    "carbs": "27",
    "fibre": "3.07",
    "sugar": "14.4",
    "protein": "1.29",
    "cal-from-protein": "5",
    "per-cal-from-protein": "4",
    "potassium": "422",
    "calcium": "5.9",
    "iron": "0.307",
    "phosphorus": "26",
    "source": "USDA",
    "old units (ref)": "whole fruit"
  },
  {
    "id": "1041",
    "type": "snack",
    "|| name                                               ||": "Banana bread",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "57",
    "calories": "240",
    "fat": "9",
    "cal-from-fat": "81",
    "per-cal-from-fat": "34",
    "saturated": "1.2",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "25.1",
    "sodium": "170",
    "carbs": "39",
    "fibre": "1",
    "sugar": "24",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "5",
    "iron": "1",
    "source": "USDA",
    "old units (ref)": "slice"
  },
  {
    "id": "15",
    "type": "drink",
    "|| name                                               ||": "Bandung",
    "rating": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "rose syrup with evaporated milk",
    "alternate-spellings": "Bandung: Bandong",
    "volume-ml": "240",
    "calories": "153.2",
    "fat": "2.4",
    "cal-from-fat": "21.6",
    "per-cal-from-fat": "14",
    "saturated": "1.7",
    "polyunsaturated": "0",
    "monounsaturated": "0.5",
    "cholesterol": "2",
    "sodium": "17",
    "carbs": "31.9",
    "fibre": "0",
    "sugar": "31.9",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "3",
    "potassium": "44",
    "calcium": "44",
    "iron": "0",
    "phosphorus": "24",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "1070",
    "type": "snack",
    "|| name                                               ||": "Bao with chicken filling",
    "rating": "2",
    "high-cholesterol": "1",
    "high-gi": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bao",
    "|| info                                                             ||": "small",
    "info-translation": "Chicken Bao",
    "info-portion-aid": "small",
    "alternate-spellings": "Bao: Pau, Pao",
    "weight-g": "70",
    "calories": "165",
    "fat": "7.8",
    "cal-from-fat": "70.2",
    "per-cal-from-fat": "43",
    "saturated": "2.7",
    "polyunsaturated": "1.4",
    "monounsaturated": "3.3",
    "cholesterol": "54",
    "sodium": "192",
    "carbs": "17.5",
    "fibre": "1.1",
    "sugar": "3",
    "protein": "6.2",
    "cal-from-protein": "25",
    "per-cal-from-protein": "15",
    "potassium": "122",
    "calcium": "18",
    "iron": "0.7",
    "phosphorus": "67",
    "source": "N Buddy",
    "old units (ref)": "bao"
  },
  {
    "id": "1071",
    "type": "snack",
    "|| name                                               ||": "Bao with chicken filling",
    "rating": "2",
    "high-cholesterol": "1",
    "high-gi": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bao",
    "|| info                                                             ||": "big",
    "info-translation": "Chicken Bao",
    "info-portion-aid": "big",
    "alternate-spellings": "Bao: Pau, Pao",
    "weight-g": "120",
    "calories": "283.4",
    "fat": "13.4",
    "cal-from-fat": "120.6",
    "per-cal-from-fat": "43",
    "saturated": "4.6",
    "polyunsaturated": "2.4",
    "monounsaturated": "5.6",
    "cholesterol": "92",
    "sodium": "330",
    "carbs": "30",
    "fibre": "1.8",
    "sugar": "5.2",
    "protein": "10.7",
    "cal-from-protein": "43",
    "per-cal-from-protein": "15",
    "potassium": "209",
    "calcium": "31",
    "iron": "1.2",
    "phosphorus": "115",
    "source": "N Buddy",
    "old units (ref)": "bao"
  },
  {
    "id": "74",
    "type": "snack",
    "|| name                                               ||": "Bao with lotus seed filling",
    "rating": "2",
    "high-gi": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bao",
    "|| info                                                             ||": "Lian rong bao",
    "info-translation": "Lian Rong Bao",
    "alternate-spellings": "Bao: Pau, Pao",
    "weight-g": "56",
    "calories": "169.6",
    "fat": "4.4",
    "cal-from-fat": "39.6",
    "per-cal-from-fat": "23",
    "saturated": "2",
    "polyunsaturated": "0.5",
    "monounsaturated": "1.7",
    "cholesterol": "2",
    "sodium": "55",
    "carbs": "29",
    "fibre": "1",
    "sugar": "13.8",
    "protein": "3.5",
    "cal-from-protein": "14",
    "per-cal-from-protein": "8",
    "potassium": "88",
    "calcium": "18",
    "iron": "0.6",
    "phosphorus": "49",
    "source": "N Buddy",
    "old units (ref)": "bao"
  },
  {
    "id": "1069",
    "type": "snack",
    "|| name                                               ||": "Bao with red bean paste",
    "rating": "2",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bao",
    "info-translation": "Tau Sar Bao",
    "alternate-spellings": "Bao: Pau, Pao\nTau: Dou\nSar: Sha",
    "weight-g": "67",
    "calories": "205.6",
    "fat": "6.4",
    "cal-from-fat": "57.6",
    "per-cal-from-fat": "28",
    "saturated": "2.8",
    "polyunsaturated": "0.7",
    "monounsaturated": "2.5",
    "cholesterol": "2",
    "sodium": "90",
    "carbs": "33",
    "fibre": "2.1",
    "sugar": "15.2",
    "protein": "4",
    "cal-from-protein": "16",
    "per-cal-from-protein": "8",
    "potassium": "139",
    "calcium": "42",
    "iron": "1.4",
    "phosphorus": "56",
    "source": "N Buddy",
    "old units (ref)": "bao"
  },
  {
    "id": "73",
    "type": "snack",
    "|| name                                               ||": "Bao with roast pork filling",
    "rating": "2",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bao",
    "|| info                                                             ||": "Char siew bao",
    "info-translation": "Char Siew Bao",
    "alternate-spellings": "Char: Cha \nSiew: Shao, Siu \nBao: Pau, Pao",
    "weight-g": "70",
    "calories": "211.3",
    "fat": "8.5",
    "cal-from-fat": "76.5",
    "per-cal-from-fat": "36",
    "saturated": "3.2",
    "polyunsaturated": "1.5",
    "monounsaturated": "3.1",
    "cholesterol": "13",
    "sodium": "276",
    "carbs": "26.1",
    "fibre": "1",
    "sugar": "3.8",
    "protein": "7.6",
    "cal-from-protein": "30",
    "per-cal-from-protein": "14",
    "calcium": "13",
    "iron": "0.6",
    "source": "N Buddy",
    "old units (ref)": "bao"
  },
  {
    "id": "1004",
    "type": "snack",
    "|| name                                               ||": "Barley soup",
    "rating": "3",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "with ginko nut and beancurd",
    "info-translation": "Fu Chok",
    "info-include-exclude": "with gingko nut and beancurd",
    "weight-g": "250",
    "calories": "197.2",
    "fat": "3.2",
    "cal-from-fat": "28.8",
    "per-cal-from-fat": "15",
    "saturated": "0.6",
    "polyunsaturated": "1.5",
    "monounsaturated": "0.9",
    "cholesterol": "24",
    "sodium": "55",
    "carbs": "33.8",
    "fibre": "2.3",
    "sugar": "21",
    "protein": "8.3",
    "cal-from-protein": "33",
    "per-cal-from-protein": "17",
    "potassium": "255",
    "calcium": "51",
    "iron": "0.36",
    "phosphorus": "135",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "173",
    "type": "drink",
    "|| name                                               ||": "Barley water, sweetened",
    "rating": "2",
    "high-gi": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "info-translation": "Yi Mi Shui",
    "alternate-spellings": "Mi: Mee",
    "volume-ml": "237",
    "calories": "56",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "2",
    "carbs": "14",
    "fibre": "0",
    "sugar": "14",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "21",
    "calcium": "24",
    "iron": "0",
    "phosphorus": "2",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "365",
    "type": "drink",
    "|| name                                               ||": "Barley water, unsweetened",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "cup",
    "info-translation": "Yi Mi Shui",
    "alternate-spellings": "Mi: Mee",
    "volume-ml": "237",
    "calories": "20",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "2",
    "carbs": "5",
    "fibre": "0",
    "sugar": "1",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "21",
    "calcium": "24",
    "iron": "0",
    "phosphorus": "2",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "240",
    "type": "meal",
    "|| name                                               ||": "BBQ pork ribs",
    "rating": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "28",
    "calories": "73.5",
    "fat": "5.9",
    "cal-from-fat": "53.1",
    "per-cal-from-fat": "72",
    "saturated": "2.4",
    "polyunsaturated": "0.5",
    "monounsaturated": "2.7",
    "cholesterol": "17",
    "sodium": "101",
    "carbs": "0.9",
    "fibre": "0",
    "sugar": "0.9",
    "protein": "4.2",
    "cal-from-protein": "17",
    "per-cal-from-protein": "23",
    "potassium": "60",
    "calcium": "13",
    "iron": "0.3",
    "phosphorus": "24",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "761",
    "type": "meal",
    "|| name                                               ||": "BBQ pork ribs with rice",
    "rating": "2",
    "high-cholesterol": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-purine": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "320",
    "calories": "626.03",
    "fat": "26.27",
    "cal-from-fat": "236.43",
    "per-cal-from-fat": "38",
    "saturated": "6.1",
    "polyunsaturated": "2.2",
    "monounsaturated": "6.8",
    "cholesterol": "36",
    "sodium": "446",
    "carbs": "67.7",
    "fibre": "2",
    "sugar": "2.4",
    "protein": "29.7",
    "cal-from-protein": "119",
    "per-cal-from-protein": "19",
    "potassium": "392",
    "calcium": "44",
    "iron": "6.4",
    "phosphorus": "263",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "1072",
    "type": "snack",
    "|| name                                               ||": "Bao with pork filling",
    "rating": "2",
    "high-cholesterol": "1",
    "high-gi": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bao",
    "|| info                                                             ||": "Bak bao, big",
    "info-translation": "Bak Bao",
    "info-portion-aid": "big",
    "weight-g": "124",
    "calories": "358.9",
    "fat": "16.1",
    "cal-from-fat": "144.9",
    "per-cal-from-fat": "40",
    "saturated": "5.6",
    "polyunsaturated": "3",
    "monounsaturated": "6.7",
    "cholesterol": "40",
    "sodium": "645",
    "carbs": "42.2",
    "fibre": "5.8",
    "sugar": "2",
    "protein": "11.3",
    "cal-from-protein": "45",
    "per-cal-from-protein": "13",
    "calcium": "11",
    "iron": "1",
    "source": "N Buddy",
    "old units (ref)": "bao"
  },
  {
    "id": "1073",
    "type": "snack",
    "|| name                                               ||": "Bao with pork filling",
    "rating": "2",
    "high-cholesterol": "1",
    "high-gi": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bao",
    "|| info                                                             ||": "Bak bao, small",
    "info-translation": "Bak Bao",
    "info-portion-aid": "small",
    "alternate-spellings": "Bao: Pau, Pao\nBak: Bah, Ba",
    "weight-g": "70",
    "calories": "202.7",
    "fat": "9.1",
    "cal-from-fat": "81.9",
    "per-cal-from-fat": "40",
    "saturated": "3.1",
    "polyunsaturated": "1.7",
    "monounsaturated": "3.8",
    "cholesterol": "22",
    "sodium": "364",
    "carbs": "23.8",
    "fibre": "3.3",
    "sugar": "1.1",
    "protein": "6.4",
    "cal-from-protein": "26",
    "per-cal-from-protein": "13",
    "calcium": "6",
    "iron": "0.6",
    "source": "N Buddy",
    "old units (ref)": "bao"
  },
  {
    "id": "415",
    "type": "meal",
    "|| name                                               ||": "Bean curd, fried / grilled / braised",
    "rating": "3",
    "high-calcium": "1",
    "high-phosphate": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "tofu fried / grilled / braised",
    "info-translation": "Tofu",
    "weight-g": "13",
    "calories": "37.98",
    "fat": "2.62",
    "cal-from-fat": "23.58",
    "per-cal-from-fat": "62",
    "saturated": "0.379",
    "polyunsaturated": "1.48",
    "monounsaturated": "0.579",
    "cholesterol": "0",
    "sodium": "2.08",
    "carbs": "1.15",
    "fibre": "0.507",
    "sugar": "0.354",
    "protein": "2.45",
    "cal-from-protein": "10",
    "per-cal-from-protein": "26",
    "potassium": "19",
    "calcium": "48.4",
    "iron": "0.633",
    "phosphorus": "37.3",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "646",
    "type": "meal",
    "|| name                                               ||": "Bean sprouts, boiled / steamed",
    "rating": "3",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "|| info                                                             ||": "Tau geh",
    "info-translation": "Taugeh",
    "weight-g": "100",
    "calories": "11",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "8",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "10",
    "carbs": "4.2",
    "fibre": "0.8",
    "sugar": "0",
    "protein": "2",
    "cal-from-protein": "8",
    "per-cal-from-protein": "73",
    "potassium": "101",
    "calcium": "12",
    "iron": "0.7",
    "phosphorus": "28",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "1093",
    "type": "meal",
    "|| name                                               ||": "Bean sprouts, stir fried",
    "rating": "3",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "|| info                                                             ||": "Tau geh",
    "info-translation": "Taugeh",
    "info-include-exclude": "with garlic",
    "weight-g": "100",
    "calories": "86",
    "fat": "5.2",
    "cal-from-fat": "46.8",
    "per-cal-from-fat": "54",
    "saturated": "2.2",
    "polyunsaturated": "0.5",
    "monounsaturated": "2",
    "sodium": "597",
    "carbs": "7",
    "fibre": "1",
    "sugar": "5",
    "protein": "2.8",
    "cal-from-protein": "11",
    "per-cal-from-protein": "13",
    "potassium": "229",
    "calcium": "30",
    "iron": "0.8",
    "phosphorus": "52",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "908",
    "type": "meal",
    "|| name                                               ||": "Beancurd skin roll, fried",
    "rating": "2",
    "high-phosphate": "1",
    "plant-protein": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Fu pei guen",
    "info-translation": "Fu Pei Guen",
    "alternate-spellings": "Pei: Pi\nGuen: Zhuan",
    "weight-g": "33",
    "calories": "66",
    "fat": "4.8",
    "cal-from-fat": "43.2",
    "per-cal-from-fat": "65",
    "saturated": "1.9",
    "polyunsaturated": "0.7",
    "monounsaturated": "2",
    "cholesterol": "5",
    "sodium": "181",
    "carbs": "1.1",
    "fibre": "0.2",
    "sugar": "0.7",
    "protein": "4.6",
    "cal-from-protein": "18",
    "per-cal-from-protein": "28",
    "potassium": "50",
    "calcium": "14",
    "iron": "0",
    "phosphorus": "36",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "411",
    "type": "snack",
    "|| name                                               ||": "Beans, boiled",
    "rating": "3",
    "high-fibre": "1",
    "high-phosphate": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "handful",
    "|| info                                                             ||": "e.g. chickpeas, kidney beans, black beans, pinto beans, fava beans",
    "info-examples": "chickpeas, kidney / black / pinto / fava beans / green",
    "weight-g": "50",
    "calories": "73.1",
    "fat": "0.3",
    "cal-from-fat": "2.7",
    "per-cal-from-fat": "4",
    "saturated": "0.1",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.1",
    "cholesterol": "0",
    "sodium": "0.5",
    "carbs": "13.1",
    "fibre": "4.5",
    "sugar": "0.2",
    "protein": "4.5",
    "cal-from-protein": "18",
    "per-cal-from-protein": "25",
    "potassium": "218",
    "calcium": "23",
    "iron": "1",
    "phosphorus": "73.5",
    "source": "N Buddy",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "525",
    "type": "meal",
    "|| name                                               ||": "Beef boat noodles",
    "rating": "2",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "515",
    "calories": "411",
    "fat": "4.7",
    "cal-from-fat": "42.3",
    "per-cal-from-fat": "10",
    "saturated": "2",
    "polyunsaturated": "0.3",
    "monounsaturated": "2",
    "cholesterol": "68",
    "sodium": "795",
    "carbs": "63.1",
    "fibre": "4",
    "sugar": "0",
    "protein": "29.1",
    "cal-from-protein": "116",
    "per-cal-from-protein": "28",
    "potassium": "483",
    "calcium": "16",
    "iron": "3.8",
    "phosphorus": "178",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "243",
    "type": "meal",
    "|| name                                               ||": "Beef hor fun, fried",
    "rating": "2",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "481",
    "calories": "696.5",
    "fat": "26.5",
    "cal-from-fat": "238.5",
    "per-cal-from-fat": "34",
    "saturated": "11.5",
    "polyunsaturated": "2.9",
    "monounsaturated": "11.5",
    "cholesterol": "38",
    "sodium": "1717",
    "carbs": "94.8",
    "fibre": "6.3",
    "sugar": "0",
    "protein": "19.7",
    "cal-from-protein": "79",
    "per-cal-from-protein": "11",
    "potassium": "298",
    "calcium": "77",
    "iron": "1.9",
    "phosphorus": "183",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "93",
    "type": "meal",
    "|| name                                               ||": "Beef patty, grilled",
    "rating": "2",
    "fried": "1",
    "high-purine": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "patty",
    "weight-g": "90",
    "calories": "255.9",
    "fat": "16.3",
    "cal-from-fat": "146.7",
    "per-cal-from-fat": "57",
    "saturated": "7.3",
    "polyunsaturated": "0.3",
    "monounsaturated": "7",
    "trans": "0.6",
    "cholesterol": "83",
    "sodium": "54",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "27.3",
    "cal-from-protein": "109",
    "per-cal-from-protein": "43",
    "potassium": "323",
    "calcium": "5",
    "iron": "2.1",
    "phosphorus": "182",
    "source": "N Buddy",
    "old units (ref)": "patty"
  },
  {
    "id": "487",
    "type": "meal",
    "|| name                                               ||": "Beef rendang",
    "rating": "2",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "236",
    "calories": "527.932",
    "fat": "30.444",
    "cal-from-fat": "273.996",
    "per-cal-from-fat": "52",
    "saturated": "14.632",
    "polyunsaturated": "2.832",
    "monounsaturated": "11.564",
    "cholesterol": "210.04",
    "sodium": "1182.36",
    "carbs": "3.304",
    "fibre": "6.608",
    "sugar": "1.888",
    "protein": "60.18",
    "cal-from-protein": "241",
    "per-cal-from-protein": "46",
    "potassium": "710.36",
    "calcium": "59",
    "iron": "7.316",
    "phosphorus": "379.96",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "94",
    "type": "meal",
    "|| name                                               ||": "Beef steak, fatty cut",
    "rating": "2",
    "high-fat": "1",
    "high-iron": "1",
    "high-purine": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "200g, e.g. beef ribs, short ribs, prime ribs, T-bone, porterhouse steak, skirt / flank steak",
    "info-examples": "T-bone, ribs, skirt, flank",
    "info-portion-aid": "7\" x 4\" x 1\"",
    "weight-g": "200",
    "calories": "496",
    "fat": "38.2",
    "cal-from-fat": "343.8",
    "per-cal-from-fat": "69",
    "saturated": "16",
    "polyunsaturated": "1.5",
    "monounsaturated": "17.9",
    "trans": "1.4",
    "cholesterol": "148",
    "sodium": "122",
    "carbs": "1.2",
    "fibre": "0",
    "sugar": "0",
    "protein": "37",
    "cal-from-protein": "148",
    "per-cal-from-protein": "30",
    "calcium": "4",
    "iron": "2.4",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "118",
    "type": "meal",
    "|| name                                               ||": "Beef steak, lean cut",
    "rating": "2",
    "high-iron": "1",
    "high-purine": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "200g, e.g. sirloin, tenderloin, striploin",
    "info-examples": "sirloin, tenderloin, striploin",
    "info-portion-aid": "7\" x 4\" x 1\"",
    "weight-g": "200",
    "calories": "385.6",
    "fat": "17.6",
    "cal-from-fat": "158.4",
    "per-cal-from-fat": "41",
    "saturated": "9",
    "polyunsaturated": "0.8",
    "monounsaturated": "7.8",
    "cholesterol": "134",
    "sodium": "150",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "56.8",
    "cal-from-protein": "227",
    "per-cal-from-protein": "59",
    "potassium": "460",
    "calcium": "6",
    "iron": "6",
    "phosphorus": "460",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "771",
    "type": "meal",
    "|| name                                               ||": "Beef, black pepper",
    "rating": "2",
    "high-iron": "1",
    "high-purine": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "|| info                                                             ||": "100g",
    "weight-g": "100",
    "calories": "191.3",
    "fat": "12.1",
    "cal-from-fat": "108.9",
    "per-cal-from-fat": "57",
    "saturated": "4",
    "polyunsaturated": "2.9",
    "monounsaturated": "4.7",
    "cholesterol": "55",
    "sodium": "594",
    "carbs": "2.5",
    "fibre": "1.5",
    "sugar": "1.5",
    "protein": "18.1",
    "cal-from-protein": "72",
    "per-cal-from-protein": "38",
    "potassium": "327",
    "calcium": "34",
    "iron": "6.3",
    "phosphorus": "175",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "241",
    "type": "meal",
    "|| name                                               ||": "Beef, roasted / boiled / grilled",
    "rating": "2",
    "high-iron": "1",
    "high-purine": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "|| info                                                             ||": "100g",
    "weight-g": "100",
    "calories": "150.5",
    "fat": "4.9",
    "cal-from-fat": "44.1",
    "per-cal-from-fat": "29",
    "saturated": "2.2",
    "polyunsaturated": "0.3",
    "monounsaturated": "2.3",
    "cholesterol": "66",
    "sodium": "57",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "26.6",
    "cal-from-protein": "106",
    "per-cal-from-protein": "71",
    "potassium": "210",
    "calcium": "3",
    "iron": "2.5",
    "phosphorus": "210",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "127",
    "type": "meal",
    "|| name                                               ||": "Economical fried beehoon",
    "rating": "1",
    "high-gi": "1",
    "high-purine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "e.g. economical bee hoon",
    "info-translation": "Rice vermicelli",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "300",
    "calories": "247.4",
    "fat": "5",
    "cal-from-fat": "45",
    "per-cal-from-fat": "18",
    "saturated": "2.4",
    "polyunsaturated": "0.4",
    "monounsaturated": "2",
    "cholesterol": "6",
    "sodium": "820",
    "carbs": "45",
    "fibre": "3.6",
    "sugar": "0.8",
    "protein": "5.6",
    "cal-from-protein": "22",
    "per-cal-from-protein": "9",
    "potassium": "44",
    "calcium": "16",
    "iron": "1.8",
    "phosphorus": "46",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "881",
    "type": "drink",
    "|| name                                               ||": "Beer, light",
    "rating": "1",
    "alcohol": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "serving": "1",
    "|| unit ||": "can",
    "|| info                                                             ||": "360ml",
    "volume-ml": "360",
    "calories": "29",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "14.4",
    "carbs": "5.9",
    "fibre": "0",
    "sugar": "0.324",
    "protein": "0.864",
    "cal-from-protein": "3",
    "per-cal-from-protein": "12",
    "potassium": "75.6",
    "calcium": "14.4",
    "iron": "0.108",
    "phosphorus": "43.2",
    "source": "USDA",
    "old units (ref)": "can"
  },
  {
    "id": "4",
    "type": "drink",
    "|| name                                               ||": "Beer, regular",
    "rating": "1",
    "alcohol": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "serving": "1",
    "|| unit ||": "can",
    "|| info                                                             ||": "360ml",
    "volume-ml": "360",
    "calories": "155",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "14.2",
    "carbs": "12.6",
    "fibre": "0",
    "sugar": "0",
    "protein": "1.64",
    "cal-from-protein": "7",
    "per-cal-from-protein": "4",
    "potassium": "96.1",
    "calcium": "14.2",
    "iron": "0.071",
    "phosphorus": "49.8",
    "source": "USDA",
    "old units (ref)": "can"
  },
  {
    "id": "671",
    "type": "meal",
    "|| name                                               ||": "Beetroot, raw",
    "rating": "3",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "medium",
    "weight-g": "135",
    "calories": "45.7",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "2",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0.1",
    "cholesterol": "0",
    "sodium": "58",
    "carbs": "9.1",
    "fibre": "3",
    "sugar": "0",
    "protein": "2.1",
    "cal-from-protein": "8",
    "per-cal-from-protein": "18",
    "potassium": "313",
    "calcium": "9",
    "iron": "1",
    "phosphorus": "55",
    "source": "N Buddy",
    "old units (ref)": "whole"
  },
  {
    "id": "632",
    "type": "meal",
    "|| name                                               ||": "Bell peppers (any color)",
    "rating": "3",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "|| info                                                             ||": "Capsicum",
    "info-translation": "Capsicum",
    "weight-g": "160",
    "calories": "39.1",
    "fat": "0.3",
    "cal-from-fat": "2.7",
    "per-cal-from-fat": "7",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "2",
    "carbs": "6.4",
    "fibre": "2.6",
    "sugar": "6.2",
    "protein": "2.7",
    "cal-from-protein": "11",
    "per-cal-from-protein": "28",
    "potassium": "288",
    "calcium": "3",
    "iron": "0.5",
    "phosphorus": "35",
    "source": "N Buddy",
    "old units (ref)": "whole"
  },
  {
    "id": "406",
    "type": "snack",
    "|| name                                               ||": "Mixed berries, frozen",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "handful",
    "|| info                                                             ||": "strawberries, raspberries, blueberries, blackberries",
    "weight-g": "98",
    "calories": "50",
    "fat": "0.6",
    "cal-from-fat": "5.4",
    "per-cal-from-fat": "11",
    "saturated": "0",
    "polyunsaturated": "0.3",
    "monounsaturated": "0.1",
    "cholesterol": "0",
    "sodium": "1",
    "carbs": "11.9",
    "fibre": "2.7",
    "sugar": "8.3",
    "protein": "0.4",
    "cal-from-protein": "2",
    "per-cal-from-protein": "3",
    "potassium": "53",
    "calcium": "7.8",
    "iron": "0.2",
    "phosphorus": "11",
    "source": "USDA",
    "old units (ref)": "cup"
  },
  {
    "id": "1034",
    "type": "snack",
    "|| name                                               ||": "Berry tart",
    "rating": "2",
    "high-calcium": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. strawberry, blueberry, cherry or mixed",
    "info-examples": "strawberry, blueberry, cherry, mixed",
    "weight-g": "160",
    "calories": "357",
    "fat": "17.7",
    "cal-from-fat": "159.3",
    "per-cal-from-fat": "45",
    "saturated": "8.9",
    "polyunsaturated": "0.9",
    "monounsaturated": "6.9",
    "cholesterol": "73",
    "sodium": "366",
    "carbs": "41.6",
    "fibre": "1.8",
    "sugar": "15.4",
    "protein": "8.9",
    "cal-from-protein": "36",
    "per-cal-from-protein": "10",
    "potassium": "150",
    "calcium": "103",
    "iron": "0.8",
    "phosphorus": "137",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "244",
    "type": "meal",
    "|| name                                               ||": "Bibimbap",
    "rating": "3",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Korean mixed rice with egg",
    "info-translation": "Korean mixed rice with egg",
    "weight-g": "350",
    "calories": "499.1",
    "fat": "21.5",
    "cal-from-fat": "193.5",
    "per-cal-from-fat": "39",
    "saturated": "6",
    "polyunsaturated": "7.5",
    "monounsaturated": "8",
    "cholesterol": "200",
    "sodium": "376",
    "carbs": "41.8",
    "fibre": "2",
    "sugar": "2",
    "protein": "25.6",
    "cal-from-protein": "102",
    "per-cal-from-protein": "21",
    "potassium": "400",
    "calcium": "20",
    "iron": "1.4",
    "phosphorus": "150",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "491",
    "type": "snack",
    "|| name                                               ||": "Bingsu with ice cream",
    "rating": "2",
    "high-gi": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "with ice cream",
    "alternate-spellings": "Bingsu: bingsoo",
    "weight-g": "319",
    "calories": "397",
    "fat": "14",
    "cal-from-fat": "126",
    "per-cal-from-fat": "32",
    "saturated": "8.3",
    "polyunsaturated": "0.7",
    "monounsaturated": "3.7",
    "cholesterol": "53",
    "sodium": "178",
    "carbs": "62",
    "fibre": "4.1",
    "sugar": "48",
    "protein": "7.8",
    "cal-from-protein": "31",
    "per-cal-from-protein": "8",
    "potassium": "534",
    "source": "Nutritionix",
    "old units (ref)": "bowl"
  },
  {
    "|| name                                               ||": "Bingsu, without ice cream",
    "|| info                                                             ||": "no ice cream",
    "alternate-spellings": "Bingsu: bingsoo"
  },
  {
    "id": "997",
    "type": "snack",
    "|| name                                               ||": "Bird nest, reduced sugar",
    "rating": "2",
    "serving": "1",
    "|| unit ||": "bottle",
    "|| info                                                             ||": "yan wo",
    "info-translation": "Yan Wo",
    "volume-ml": "70",
    "calories": "19",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "3",
    "carbs": "4.2",
    "fibre": "0",
    "sugar": "3.7",
    "protein": "0.4",
    "cal-from-protein": "2",
    "per-cal-from-protein": "8",
    "source": "N Buddy",
    "old units (ref)": "bottle"
  },
  {
    "id": "998",
    "type": "snack",
    "|| name                                               ||": "Bird nest, with sugar",
    "rating": "2",
    "high-gi": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bottle",
    "|| info                                                             ||": "yan wo",
    "info-translation": "Yan Wo",
    "volume-ml": "70",
    "calories": "26",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "6",
    "carbs": "6.4",
    "fibre": "0",
    "sugar": "6",
    "protein": "0.1",
    "cal-from-protein": "0",
    "per-cal-from-protein": "2",
    "source": "N Buddy",
    "old units (ref)": "bottle"
  },
  {
    "id": "222",
    "type": "snack",
    "|| name                                               ||": "Biscotti",
    "rating": "2",
    "processed": "1",
    "serving": "2",
    "|| unit ||": "piece",
    "info-translation": "Cantucci",
    "weight-g": "15",
    "calories": "71",
    "fat": "3.4",
    "cal-from-fat": "30.6",
    "per-cal-from-fat": "43",
    "saturated": "0.5",
    "cholesterol": "0",
    "sodium": "41",
    "carbs": "8",
    "fibre": "0.8",
    "sugar": "3.1",
    "protein": "2.3",
    "cal-from-protein": "9",
    "per-cal-from-protein": "13",
    "potassium": "70",
    "calcium": "10",
    "iron": "0.5",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "1074",
    "type": "snack",
    "|| name                                               ||": "Biscuit with flavoured filling",
    "rating": "2",
    "high-cholesterol": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "5",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. Hello Panda biscuits with flavoured filling",
    "info-brands": "Hello Panda",
    "weight-g": "30",
    "calories": "162",
    "fat": "9",
    "cal-from-fat": "81",
    "per-cal-from-fat": "50",
    "saturated": "4.9",
    "trans": "0.1",
    "sodium": "87",
    "carbs": "18.3",
    "fibre": "0.3",
    "sugar": "8.8",
    "protein": "1.8",
    "cal-from-protein": "7",
    "per-cal-from-protein": "4",
    "source": "N Buddy",
    "old units (ref)": "packet"
  },
  {
    "id": "599",
    "type": "snack",
    "|| name                                               ||": "Biscuit stick with cream / frosting",
    "rating": "1",
    "high-gi": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "packet",
    "|| info                                                             ||": "e.g. Pocky stick, Yan Yan",
    "info-brands": "Glico Pocky, Yan Yan",
    "weight-g": "50",
    "calories": "239",
    "fat": "13",
    "cal-from-fat": "117",
    "per-cal-from-fat": "49",
    "saturated": "5",
    "trans": "0.2",
    "sodium": "158",
    "carbs": "32",
    "fibre": "2",
    "sugar": "12",
    "protein": "4",
    "cal-from-protein": "16",
    "per-cal-from-protein": "7",
    "source": "N Buddy",
    "old units (ref)": "packet"
  },
  {
    "id": "22",
    "type": "snack",
    "|| name                                               ||": "Biscuit with cream filling",
    "rating": "2",
    "high-gi": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "2",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. Julie's peanut butter sandwich",
    "info-brands": "Julie's",
    "info-examples": "peanut butter, lemon",
    "weight-g": "24",
    "calories": "109.8",
    "fat": "4.2",
    "cal-from-fat": "37.8",
    "per-cal-from-fat": "34",
    "saturated": "2.4",
    "polyunsaturated": "0.2",
    "monounsaturated": "1.2",
    "cholesterol": "6",
    "sodium": "34",
    "carbs": "17",
    "fibre": "0.2",
    "sugar": "7.4",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "4",
    "potassium": "12",
    "calcium": "4",
    "iron": "0",
    "phosphorus": "16",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "549",
    "type": "snack",
    "|| name                                               ||": "Biscuit, plain",
    "rating": "2",
    "high-gi": "1",
    "serving": "5",
    "|| unit ||": "pieces",
    "|| info                                                             ||": "e.g. Meiji cracker, Jacob's, Khong Guan, Marie",
    "info-brands": "Meiji, Jacob's, Khong Guan, Marie",
    "info-examples": "soda crackers",
    "weight-g": "28",
    "calories": "113.72",
    "fat": "2.96",
    "cal-from-fat": "26.64",
    "per-cal-from-fat": "23",
    "saturated": "1.48",
    "polyunsaturated": "0.468",
    "monounsaturated": "0.754",
    "cholesterol": "0",
    "sodium": "104",
    "carbs": "19.8",
    "fibre": "0.98",
    "sugar": "5.92",
    "protein": "1.97",
    "cal-from-protein": "8",
    "per-cal-from-protein": "7",
    "potassium": "86",
    "calcium": "0",
    "iron": "0",
    "phosphorus": "54",
    "source": "USDA",
    "old units (ref)": "packet"
  },
  {
    "id": "1011",
    "type": "snack",
    "|| name                                               ||": "Black forest cake",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "info-translation": "Gateau",
    "weight-g": "120",
    "calories": "321.7",
    "fat": "15.3",
    "cal-from-fat": "137.7",
    "per-cal-from-fat": "43",
    "saturated": "9.2",
    "polyunsaturated": "0.9",
    "monounsaturated": "4.5",
    "cholesterol": "45",
    "sodium": "220",
    "carbs": "43",
    "fibre": "2.5",
    "sugar": "35",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "4",
    "potassium": "129",
    "calcium": "45",
    "iron": "2",
    "phosphorus": "119",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "215",
    "type": "snack",
    "|| name                                               ||": "Black glutinous rice paste",
    "rating": "2",
    "high-gi": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Pulut Hitam with coconut milk",
    "info-translation": "Pulut Hitam",
    "info-include-exclude": "with coconut milk",
    "weight-g": "250",
    "calories": "220.2",
    "fat": "3.8",
    "cal-from-fat": "34.2",
    "per-cal-from-fat": "16",
    "saturated": "3.4",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "44.5",
    "fibre": "7",
    "sugar": "25",
    "protein": "2",
    "cal-from-protein": "8",
    "per-cal-from-protein": "4",
    "calcium": "10",
    "iron": "0.5",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "187",
    "type": "snack",
    "|| name                                               ||": "Black sesame paste",
    "rating": "2",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Zhi Ma Hu",
    "info-translation": "Zhi Ma Hu",
    "alternate-spellings": "Zhi: Ji\nHu: Wu, Woo",
    "volume-ml": "306",
    "calories": "286.7",
    "fat": "9.5",
    "cal-from-fat": "85.5",
    "per-cal-from-fat": "30",
    "saturated": "1.6",
    "polyunsaturated": "4",
    "monounsaturated": "3.6",
    "cholesterol": "0",
    "sodium": "13",
    "carbs": "47.5",
    "fibre": "2.3",
    "sugar": "41.8",
    "protein": "2.8",
    "cal-from-protein": "11",
    "per-cal-from-protein": "4",
    "potassium": "50",
    "calcium": "133",
    "iron": "0",
    "phosphorus": "70",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "709",
    "type": "snack",
    "|| name                                               ||": "Blueberries",
    "rating": "3",
    "fruit": "1",
    "serving": "1",
    "|| unit ||": "handful",
    "weight-g": "30",
    "calories": "16",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "6",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "3.5",
    "fibre": "0.7",
    "sugar": "3",
    "protein": "0.2",
    "cal-from-protein": "1",
    "per-cal-from-protein": "5",
    "potassium": "23",
    "calcium": "2",
    "iron": "0.1",
    "phosphorus": "4",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "1017",
    "type": "snack",
    "|| name                                               ||": "Blueberry cake",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "50",
    "calories": "145",
    "fat": "9",
    "cal-from-fat": "81",
    "per-cal-from-fat": "56",
    "saturated": "5",
    "polyunsaturated": "0.4",
    "monounsaturated": "3.6",
    "sodium": "69",
    "carbs": "14.5",
    "sugar": "11",
    "protein": "6.1",
    "cal-from-protein": "24",
    "per-cal-from-protein": "17",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "627",
    "type": "meal",
    "|| name                                               ||": "Spinach, boiled",
    "rating": "3",
    "high-calcium": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "info-translation": "Bo Cai, Phuay Leng",
    "alternate-spellings": "Phuay: Puay\nBo: Po, Poh\nCai: Chye, Choy",
    "weight-g": "100",
    "calories": "29.9",
    "fat": "0.3",
    "cal-from-fat": "2.7",
    "per-cal-from-fat": "9",
    "saturated": "0",
    "polyunsaturated": "0.1",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "70",
    "carbs": "3.8",
    "fibre": "2.4",
    "sugar": "0",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "40",
    "potassium": "466",
    "calcium": "136",
    "iron": "3.6",
    "phosphorus": "56",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "1075",
    "type": "meal",
    "|| name                                               ||": "Shanghai green, stir-fried",
    "rating": "3",
    "high-calcium": "1",
    "high-cholesterol": "1",
    "high-fibre": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "|| info                                                             ||": "Xiao bai cai",
    "info-translation": "Xiao Bai Cai, Chinese cabbage",
    "alternate-spellings": "Bai: Bok, Pak, Pok\nCai: Choy, Chye",
    "weight-g": "115",
    "calories": "69",
    "fat": "4.5",
    "cal-from-fat": "40.5",
    "per-cal-from-fat": "59",
    "saturated": "0.7",
    "polyunsaturated": "2.8",
    "monounsaturated": "0.8",
    "sodium": "350",
    "carbs": "1.72",
    "fibre": "1.9",
    "sugar": "3.6",
    "protein": "2.5",
    "cal-from-protein": "10",
    "per-cal-from-protein": "14",
    "potassium": "300",
    "calcium": "110",
    "iron": "0.4",
    "phosphorus": "7",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "625",
    "type": "meal",
    "|| name                                               ||": "Shanghai green, boiled",
    "rating": "3",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "|| info                                                             ||": "Xiao bai cai",
    "info-translation": "Xiao Bai Cai, Chinese cabbage",
    "alternate-spellings": "Bai: Bok, Pak, Pok\r\nCai: Choy, Chye",
    "weight-g": "100",
    "calories": "13.8",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "13",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "45",
    "carbs": "2",
    "fibre": "1",
    "sugar": "0",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "29",
    "potassium": "300",
    "calcium": "60",
    "iron": "0.2",
    "phosphorus": "15",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "493",
    "type": "meal",
    "|| name                                               ||": "Borscht soup",
    "rating": "2",
    "high-potassium": "1",
    "high-salt": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "Beetroot soup",
    "info-examples": "beetroot",
    "alternate-spellings": "Borscht: Borsch",
    "weight-g": "245",
    "calories": "105.9",
    "fat": "4.34",
    "cal-from-fat": "39.06",
    "per-cal-from-fat": "37",
    "saturated": "2.18",
    "polyunsaturated": "0.255",
    "monounsaturated": "1.05",
    "cholesterol": "12.2",
    "sodium": "380",
    "carbs": "13.3",
    "fibre": "2.7",
    "sugar": "8.45",
    "protein": "3.41",
    "cal-from-protein": "14",
    "per-cal-from-protein": "13",
    "potassium": "402",
    "calcium": "61.2",
    "iron": "0.809",
    "phosphorus": "76",
    "source": "USDA",
    "old units (ref)": "bowl"
  },
  {
    "id": "530",
    "type": "meal",
    "|| name                                               ||": "Braised bee hoon with crabmeat",
    "rating": "2",
    "high-gi": "1",
    "high-salt": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Brasied rice vermicelli noodles with crabmeat",
    "info-translation": "Rice vermicelli",
    "weight-g": "224",
    "calories": "383.8",
    "fat": "5.4",
    "cal-from-fat": "48.6",
    "per-cal-from-fat": "13",
    "saturated": "1",
    "polyunsaturated": "1.5",
    "monounsaturated": "2.4",
    "cholesterol": "41",
    "sodium": "544",
    "carbs": "58",
    "fibre": "8.3",
    "sugar": "4.1",
    "protein": "25.8",
    "cal-from-protein": "103",
    "per-cal-from-protein": "27",
    "potassium": "625",
    "calcium": "175",
    "iron": "1.8",
    "phosphorus": "238",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "895",
    "type": "meal",
    "|| name                                               ||": "Braised beef brisket noodles, dry",
    "rating": "2",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "111",
    "calories": "416",
    "fat": "19.2",
    "cal-from-fat": "172.8",
    "per-cal-from-fat": "42",
    "saturated": "9.2",
    "polyunsaturated": "0.3",
    "monounsaturated": "4.1",
    "cholesterol": "28",
    "sodium": "407",
    "carbs": "43.7",
    "fibre": "0.5",
    "sugar": "0",
    "protein": "17.1",
    "cal-from-protein": "68",
    "per-cal-from-protein": "16",
    "potassium": "63",
    "calcium": "3",
    "iron": "0.9",
    "phosphorus": "57",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "245",
    "type": "meal",
    "|| name                                               ||": "Braised duck rice (white rice)",
    "rating": "2",
    "high-iron": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "300",
    "calories": "451",
    "fat": "11",
    "cal-from-fat": "99",
    "per-cal-from-fat": "22",
    "saturated": "8.6",
    "polyunsaturated": "2.7",
    "monounsaturated": "7.3",
    "cholesterol": "109",
    "sodium": "352",
    "carbs": "62.8",
    "fibre": "2",
    "sugar": "0.2",
    "protein": "25.2",
    "cal-from-protein": "101",
    "per-cal-from-protein": "22",
    "potassium": "20",
    "calcium": "20",
    "iron": "7.6",
    "phosphorus": "22",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "1076",
    "type": "meal",
    "|| name                                               ||": "Braised duck rice (yam rice)",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "410",
    "calories": "693",
    "fat": "20.5",
    "cal-from-fat": "184.5",
    "per-cal-from-fat": "27",
    "saturated": "6.1",
    "polyunsaturated": "4.5",
    "monounsaturated": "8.5",
    "cholesterol": "94",
    "sodium": "562",
    "carbs": "102.1",
    "fibre": "2",
    "sugar": "29.1",
    "protein": "21",
    "cal-from-protein": "84",
    "per-cal-from-protein": "12",
    "potassium": "201",
    "calcium": "25",
    "iron": "2.9",
    "phosphorus": "324",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "431",
    "type": "meal",
    "|| name                                               ||": "Braised mixed vegetables",
    "rating": "3",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "|| info                                                             ||": "Buddha's Delight / Loh Hon Chai",
    "info-translation": "Buddha's Delight, Loh Hon Chai",
    "alternate-spellings": "Loh: Lo\nHon: Han\nChai: Cai, Jai, Zai",
    "weight-g": "200",
    "calories": "142",
    "fat": "5.6",
    "cal-from-fat": "50.4",
    "per-cal-from-fat": "35",
    "saturated": "1.8",
    "polyunsaturated": "1.4",
    "monounsaturated": "2",
    "cholesterol": "0",
    "sodium": "298",
    "carbs": "5.2",
    "fibre": "5.4",
    "sugar": "4",
    "protein": "1.6",
    "cal-from-protein": "6",
    "per-cal-from-protein": "5",
    "potassium": "288",
    "calcium": "38",
    "iron": "1.6",
    "phosphorus": "66",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "723",
    "type": "meal",
    "|| name                                               ||": "Bread, ezekiel / sprouted",
    "rating": "3",
    "plant-protein": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "34",
    "calories": "76",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "sodium": "75",
    "carbs": "15",
    "fibre": "3",
    "sugar": "0",
    "protein": "4",
    "cal-from-protein": "16",
    "per-cal-from-protein": "21",
    "source": "https://www.eatthis.com/ezekiel-bread/",
    "old units (ref)": "slice"
  },
  {
    "id": "34",
    "type": "snack",
    "|| name                                               ||": "Bread pudding",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "square",
    "|| info                                                             ||": "10cm square",
    "info-portion-aid": "10cm square",
    "weight-g": "56",
    "calories": "86.8",
    "fat": "2.7",
    "cal-from-fat": "24.3",
    "per-cal-from-fat": "28",
    "saturated": "0.9",
    "polyunsaturated": "0.6",
    "monounsaturated": "0.9",
    "cholesterol": "31",
    "sodium": "121",
    "carbs": "13.2",
    "fibre": "0.6",
    "sugar": "8.2",
    "protein": "3.1",
    "cal-from-protein": "12",
    "per-cal-from-protein": "14",
    "potassium": "114",
    "calcium": "66.8",
    "iron": "0.6",
    "phosphorus": "62",
    "source": "USDA",
    "old units (ref)": "cup"
  },
  {
    "id": "582",
    "type": "meal",
    "|| name                                               ||": "Bread with filling",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. tuna bun, ham cheese bun, sardine bun",
    "info-translation": "Bun",
    "info-examples": "tuna, ham, cheese, sardine",
    "weight-g": "85",
    "calories": "251.1",
    "fat": "9.9",
    "cal-from-fat": "89.1",
    "per-cal-from-fat": "35",
    "saturated": "5.1",
    "polyunsaturated": "1.7",
    "monounsaturated": "3.1",
    "cholesterol": "21",
    "sodium": "587",
    "carbs": "30.6",
    "fibre": "3.4",
    "sugar": "7.1",
    "protein": "9.9",
    "cal-from-protein": "40",
    "per-cal-from-protein": "16",
    "potassium": "123",
    "calcium": "76",
    "iron": "0.9",
    "phosphorus": "132",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "898",
    "type": "meal",
    "|| name                                               ||": "Bread, paleo",
    "rating": "3",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "43",
    "calories": "60.2",
    "fat": "3",
    "cal-from-fat": "27",
    "per-cal-from-fat": "45",
    "saturated": "1",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "115",
    "carbs": "6",
    "fibre": "5",
    "sugar": "1",
    "protein": "7",
    "cal-from-protein": "28",
    "per-cal-from-protein": "47",
    "potassium": "200",
    "calcium": "40",
    "iron": "0.4",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "95",
    "type": "meal",
    "|| name                                               ||": "Bread, white",
    "rating": "2",
    "high-gi": "1",
    "high-purine": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "|| info                                                             ||": "e.g. Gardenia enriched white bread, Hokkaido milk bread",
    "info-brands": "Gardenia, Hokkaido, Sunshine",
    "weight-g": "32",
    "calories": "88.3",
    "fat": "1.5",
    "cal-from-fat": "13.5",
    "per-cal-from-fat": "15",
    "saturated": "0.9",
    "cholesterol": "0",
    "sodium": "48.5",
    "carbs": "15.4",
    "fibre": "0.9",
    "sugar": "0.3",
    "protein": "3.3",
    "cal-from-protein": "13",
    "per-cal-from-protein": "15",
    "calcium": "262",
    "iron": "1.8",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "246",
    "type": "meal",
    "|| name                                               ||": "Bread, wholemeal",
    "rating": "3",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "|| info                                                             ||": "includes whole grain, low GI bread",
    "info-brands": "Gardenia, Sunshine",
    "info-examples": "whole grain, softmeal",
    "weight-g": "30",
    "calories": "66.5",
    "fat": "0.9",
    "cal-from-fat": "8.1",
    "per-cal-from-fat": "12",
    "saturated": "0.3",
    "polyunsaturated": "0.3",
    "monounsaturated": "0.2",
    "cholesterol": "0",
    "sodium": "141",
    "carbs": "11.6",
    "fibre": "2",
    "sugar": "0.4",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "18",
    "potassium": "81",
    "calcium": "16",
    "iron": "0.7",
    "phosphorus": "45",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "686",
    "type": "meal",
    "|| name                                               ||": "Breadfruit",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "high-potassium": "1",
    "serving": "2",
    "|| unit ||": "square",
    "|| info                                                             ||": "5cm square, cooked",
    "info-portion-aid": "5cm sqaure",
    "info-include-exclude": "cooked",
    "weight-g": "72",
    "calories": "94.8",
    "fat": "2",
    "cal-from-fat": "18",
    "per-cal-from-fat": "19",
    "saturated": "0.9",
    "polyunsaturated": "0.4",
    "monounsaturated": "0.5",
    "cholesterol": "3",
    "sodium": "196",
    "carbs": "20.8",
    "fibre": "3.8",
    "sugar": "8.4",
    "protein": "0.8",
    "cal-from-protein": "3",
    "per-cal-from-protein": "3",
    "potassium": "339",
    "calcium": "13",
    "iron": "0.4",
    "phosphorus": "21",
    "source": "US FDA",
    "old units (ref)": "serving"
  },
  {
    "id": "655",
    "type": "meal",
    "|| name                                               ||": "Brinjal, stir fried",
    "rating": "3",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "|| info                                                             ||": "Aubergine, Eggplant",
    "info-translation": "Aubergine, Eggplant",
    "weight-g": "100",
    "calories": "80.7",
    "fat": "5.1",
    "cal-from-fat": "45.9",
    "per-cal-from-fat": "57",
    "saturated": "0.8",
    "polyunsaturated": "1.6",
    "monounsaturated": "2.3",
    "cholesterol": "0",
    "sodium": "199",
    "carbs": "6.8",
    "fibre": "1.2",
    "sugar": "4.9",
    "protein": "1.9",
    "cal-from-protein": "8",
    "per-cal-from-protein": "9",
    "potassium": "71",
    "calcium": "24",
    "iron": "0.6",
    "phosphorus": "26",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "96",
    "type": "meal",
    "|| name                                               ||": "Briyani with meat",
    "rating": "2",
    "high-fat": "1",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "with chicken / mutton",
    "info-include-exclude": "with chicken / mutton",
    "weight-g": "488",
    "calories": "876.8",
    "fat": "34.8",
    "cal-from-fat": "313.2",
    "per-cal-from-fat": "36",
    "saturated": "14.8",
    "polyunsaturated": "5.1",
    "monounsaturated": "13",
    "cholesterol": "158",
    "sodium": "1655",
    "carbs": "102",
    "fibre": "8.3",
    "sugar": "0",
    "protein": "38.9",
    "cal-from-protein": "156",
    "per-cal-from-protein": "18",
    "potassium": "441",
    "calcium": "93",
    "iron": "4.6",
    "phosphorus": "293",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "1079",
    "type": "meal",
    "|| name                                               ||": "Broccoli, stir fried",
    "rating": "3",
    "high-cholesterol": "1",
    "high-potassium": "1",
    "plant-protein": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "100",
    "calories": "57",
    "fat": "3.9",
    "cal-from-fat": "35.1",
    "per-cal-from-fat": "62",
    "saturated": "1.2",
    "polyunsaturated": "1.2",
    "monounsaturated": "1.4",
    "sodium": "184",
    "carbs": "2.5",
    "fibre": "2",
    "sugar": "2",
    "protein": "2.9",
    "cal-from-protein": "12",
    "per-cal-from-protein": "20",
    "potassium": "211",
    "calcium": "37",
    "iron": "0.5",
    "phosphorus": "53",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "641",
    "type": "meal",
    "|| name                                               ||": "Broccoli, boiled / steamed",
    "rating": "3",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "100",
    "calories": "35.1",
    "fat": "0.3",
    "cal-from-fat": "2.7",
    "per-cal-from-fat": "8",
    "saturated": "0.1",
    "polyunsaturated": "0.2",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "26",
    "carbs": "5.1",
    "fibre": "2.9",
    "sugar": "0",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "34",
    "potassium": "292",
    "calcium": "46",
    "iron": "0.8",
    "phosphorus": "59",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "9",
    "type": "drink",
    "|| name                                               ||": "Bubble tea, brown sugar, fresh milk, with pearls",
    "rating": "1",
    "high-calcium": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "420ml",
    "info-include-exclude": "75%-100% sweetness",
    "volume-ml": "420",
    "calories": "418",
    "fat": "12.8",
    "cal-from-fat": "115.2",
    "per-cal-from-fat": "28",
    "saturated": "8.5",
    "polyunsaturated": "0.6",
    "monounsaturated": "3.7",
    "trans": "0.4",
    "cholesterol": "49",
    "sodium": "144",
    "carbs": "65",
    "fibre": "2.6",
    "sugar": "36.9",
    "protein": "10.7",
    "cal-from-protein": "43",
    "per-cal-from-protein": "10",
    "potassium": "536",
    "calcium": "428",
    "iron": "0.6",
    "phosphorus": "285",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "494",
    "type": "snack",
    "|| name                                               ||": "Bruschetta",
    "rating": "2",
    "high-potassium": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "|| info                                                             ||": "with tomato and basil",
    "info-include-exclude": "with tomato and basil",
    "weight-g": "43",
    "calories": "74.87",
    "fat": "3.31",
    "cal-from-fat": "29.79",
    "per-cal-from-fat": "40",
    "saturated": "0.375",
    "polyunsaturated": "0.538",
    "monounsaturated": "2.09",
    "cholesterol": "0",
    "sodium": "102",
    "carbs": "9.54",
    "fibre": "0.86",
    "sugar": "1.18",
    "protein": "1.73",
    "cal-from-protein": "7",
    "per-cal-from-protein": "9",
    "potassium": "78.3",
    "calcium": "16.8",
    "iron": "0.593",
    "phosphorus": "24.1",
    "source": "USDA",
    "old units (ref)": "slice"
  },
  {
    "id": "681",
    "type": "meal",
    "|| name                                               ||": "Brussell sprouts, stir-fried",
    "rating": "3",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "5",
    "|| unit ||": "sprout",
    "weight-g": "110",
    "calories": "36",
    "fat": "0.5",
    "cal-from-fat": "4.5",
    "per-cal-from-fat": "13",
    "saturated": "1.6",
    "polyunsaturated": "0.3",
    "monounsaturated": "0.6",
    "cholesterol": "0",
    "sodium": "275",
    "carbs": "8",
    "fibre": "4",
    "sugar": "2",
    "protein": "3.5",
    "cal-from-protein": "14",
    "per-cal-from-protein": "39",
    "potassium": "280",
    "calcium": "26",
    "iron": "0.5",
    "phosphorus": "55",
    "source": "US FDA",
    "old units (ref)": "sprouts"
  },
  {
    "id": "798",
    "type": "drink",
    "|| name                                               ||": "Bubble tea, reduced sugar, without pearls",
    "rating": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "25%-50% sweetness level",
    "info-include-exclude": "25%-50% sweetness",
    "volume-ml": "440",
    "calories": "252.9",
    "fat": "13.3",
    "cal-from-fat": "119.7",
    "per-cal-from-fat": "47",
    "saturated": "12.4",
    "polyunsaturated": "0",
    "monounsaturated": "0.4",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "32.6",
    "fibre": "0",
    "sugar": "17.6",
    "protein": "0.7",
    "cal-from-protein": "3",
    "per-cal-from-protein": "1",
    "potassium": "207",
    "calcium": "189",
    "iron": "1.2",
    "phosphorus": "78",
    "source": "N Buddy",
    "old units (ref)": "regular cup"
  },
  {
    "id": "797",
    "type": "drink",
    "|| name                                               ||": "Bubble tea, sweetened, without pearls",
    "rating": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "75%-100% sweetness level",
    "info-include-exclude": "75%-100% sweetness",
    "volume-ml": "440",
    "calories": "281.8",
    "fat": "12.6",
    "cal-from-fat": "113.4",
    "per-cal-from-fat": "40",
    "saturated": "11.7",
    "polyunsaturated": "0",
    "monounsaturated": "0.4",
    "cholesterol": "0",
    "sodium": "28",
    "carbs": "41.4",
    "fibre": "0",
    "sugar": "28",
    "protein": "0.7",
    "cal-from-protein": "3",
    "per-cal-from-protein": "1",
    "potassium": "196",
    "calcium": "179",
    "iron": "1.1",
    "phosphorus": "74",
    "source": "N Buddy",
    "old units (ref)": "regular cup"
  },
  {
    "id": "799",
    "type": "drink",
    "|| name                                               ||": "Bubble tea, unsweetened, without pearls",
    "rating": "2",
    "high-phosphate": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "0% sweetness level",
    "info-include-exclude": "0% sweetness",
    "volume-ml": "440",
    "calories": "224.4",
    "fat": "14",
    "cal-from-fat": "126",
    "per-cal-from-fat": "56",
    "saturated": "13",
    "polyunsaturated": "0",
    "monounsaturated": "0.4",
    "cholesterol": "0",
    "sodium": "31",
    "carbs": "23.8",
    "fibre": "0",
    "sugar": "0",
    "protein": "0.8",
    "cal-from-protein": "3",
    "per-cal-from-protein": "1",
    "potassium": "218",
    "calcium": "199",
    "iron": "1.2",
    "phosphorus": "82",
    "source": "N Buddy",
    "old units (ref)": "regular cup"
  },
  {
    "id": "164",
    "type": "drink",
    "|| name                                               ||": "Bubble tea, no sugar, with pearls",
    "rating": "2",
    "high-calcium": "1",
    "high-gi": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "0% sweetness level",
    "info-include-exclude": "0% sweetness",
    "volume-ml": "450",
    "calories": "318.3",
    "fat": "11.1",
    "cal-from-fat": "99.9",
    "per-cal-from-fat": "31",
    "saturated": "10.3",
    "polyunsaturated": "0",
    "monounsaturated": "0.3",
    "cholesterol": "0",
    "sodium": "40",
    "carbs": "54",
    "fibre": "1",
    "sugar": "26",
    "protein": "0.6",
    "cal-from-protein": "2",
    "per-cal-from-protein": "1",
    "potassium": "180",
    "calcium": "166",
    "iron": "1.1",
    "phosphorus": "67",
    "source": "N Buddy",
    "old units (ref)": "regular cup"
  },
  {
    "id": "11",
    "type": "drink",
    "|| name                                               ||": "Bubble tea, reduced sugar, with pearls",
    "rating": "1",
    "high-calcium": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "25%-50% sweetness level",
    "info-include-exclude": "25%-50% sweetness",
    "volume-ml": "450",
    "calories": "341.8",
    "fat": "10.6",
    "cal-from-fat": "95.4",
    "per-cal-from-fat": "28",
    "saturated": "9.7",
    "polyunsaturated": "0",
    "monounsaturated": "0.3",
    "cholesterol": "0",
    "sodium": "15",
    "carbs": "61",
    "fibre": "1",
    "sugar": "33",
    "protein": "0.6",
    "cal-from-protein": "2",
    "per-cal-from-protein": "1",
    "potassium": "172",
    "calcium": "158",
    "iron": "1.1",
    "phosphorus": "64",
    "source": "N Buddy",
    "old units (ref)": "regular cup"
  },
  {
    "id": "10",
    "type": "drink",
    "|| name                                               ||": "Bubble tea, sweetened, with pearls",
    "rating": "1",
    "high-calcium": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "75%-100% sweetness level",
    "info-include-exclude": "75%-100% sweetness",
    "volume-ml": "450",
    "calories": "364.4",
    "fat": "10",
    "cal-from-fat": "90",
    "per-cal-from-fat": "25",
    "saturated": "9.3",
    "polyunsaturated": "0",
    "monounsaturated": "0.3",
    "cholesterol": "0",
    "sodium": "37",
    "carbs": "68",
    "fibre": "1",
    "sugar": "40",
    "protein": "0.6",
    "cal-from-protein": "2",
    "per-cal-from-protein": "1",
    "potassium": "163",
    "calcium": "150",
    "iron": "1",
    "phosphorus": "61",
    "source": "N Buddy",
    "old units (ref)": "regular cup"
  },
  {
    "id": "199",
    "type": "snack",
    "|| name                                               ||": "Bubur cha cha",
    "rating": "2",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "alternate-spellings": "Bubur: Bubor, Bobo",
    "weight-g": "250",
    "calories": "380.6",
    "fat": "23.8",
    "cal-from-fat": "214.2",
    "per-cal-from-fat": "56",
    "saturated": "21.2",
    "polyunsaturated": "0.5",
    "monounsaturated": "7.3",
    "cholesterol": "0",
    "sodium": "90",
    "carbs": "36.8",
    "fibre": "5.8",
    "sugar": "26.8",
    "protein": "4.8",
    "cal-from-protein": "19",
    "per-cal-from-protein": "5",
    "potassium": "418",
    "calcium": "23",
    "iron": "1.3",
    "phosphorus": "113",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "97",
    "type": "meal",
    "|| name                                               ||": "Bulgogi",
    "rating": "2",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "|| info                                                             ||": "marinated beef barbeque",
    "info-translation": "Korean barbecue beef",
    "weight-g": "142",
    "calories": "257.9",
    "fat": "12.7",
    "cal-from-fat": "114.3",
    "per-cal-from-fat": "44",
    "saturated": "4.9",
    "polyunsaturated": "1.6",
    "monounsaturated": "5.3",
    "cholesterol": "70",
    "sodium": "1290",
    "carbs": "12.9",
    "fibre": "0.8",
    "sugar": "11.7",
    "protein": "23",
    "cal-from-protein": "92",
    "per-cal-from-protein": "36",
    "potassium": "392",
    "calcium": "19",
    "iron": "3.5",
    "phosphorus": "188",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "1077",
    "type": "snack",
    "|| name                                               ||": "Bun with filling",
    "rating": "2",
    "high-cholesterol": "1",
    "high-gi": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bun",
    "|| info                                                             ||": "e.g. chicken floss bun",
    "info-examples": "chicken / pork floss, olive",
    "weight-g": "90",
    "calories": "388",
    "fat": "22.1",
    "cal-from-fat": "198.9",
    "per-cal-from-fat": "51",
    "saturated": "4.2",
    "polyunsaturated": "9.8",
    "monounsaturated": "6.5",
    "cholesterol": "43",
    "sodium": "1328",
    "carbs": "36.2",
    "fibre": "1.5",
    "sugar": "7.6",
    "protein": "11.5",
    "cal-from-protein": "46",
    "per-cal-from-protein": "12",
    "potassium": "135",
    "calcium": "41",
    "iron": "0.5",
    "phosphorus": "112",
    "source": "N Buddy",
    "old units (ref)": "bun"
  },
  {
    "id": "812",
    "type": "meal",
    "|| name                                               ||": "Burger with double patty",
    "rating": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "burger",
    "info-brands": "Big Mac, Double Cheeseburger / Whopper",
    "weight-g": "155",
    "calories": "437",
    "fat": "25.1",
    "cal-from-fat": "225.9",
    "per-cal-from-fat": "52",
    "saturated": "10.8",
    "polyunsaturated": "2.04",
    "monounsaturated": "9.3",
    "cholesterol": "77.5",
    "sodium": "956",
    "carbs": "27.9",
    "fibre": "1.6",
    "sugar": "6.5",
    "protein": "25.2",
    "cal-from-protein": "101",
    "per-cal-from-protein": "23",
    "potassium": "350",
    "calcium": "232",
    "iron": "3.6",
    "phosphorus": "256",
    "source": "USDA",
    "old units (ref)": "burger"
  },
  {
    "id": "813",
    "type": "meal",
    "|| name                                               ||": "Burger with plant-based protein",
    "rating": "2",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "plant-protein": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "burger",
    "|| info                                                             ||": "e.g. Impossible burger",
    "info-brands": "Impossible / Beyond Meat",
    "weight-g": "242",
    "calories": "566",
    "fat": "28.5",
    "cal-from-fat": "256.5",
    "per-cal-from-fat": "45",
    "saturated": "15.6",
    "polyunsaturated": "2.9",
    "monounsaturated": "9.3",
    "cholesterol": "16",
    "sodium": "820",
    "carbs": "45.2",
    "fibre": "5.4",
    "sugar": "6.2",
    "protein": "31.6",
    "cal-from-protein": "126",
    "per-cal-from-protein": "22",
    "potassium": "801",
    "calcium": "302",
    "iron": "6.6",
    "phosphorus": "274",
    "source": "N Buddy",
    "old units (ref)": "whole"
  },
  {
    "id": "811",
    "type": "meal",
    "|| name                                               ||": "Burger with single patty",
    "rating": "2",
    "high-fat": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "burger",
    "info-brands": "Cheeseburger, Angus, Whopper",
    "info-examples": "hamburger",
    "weight-g": "127",
    "calories": "343",
    "fat": "16.4",
    "cal-from-fat": "147.6",
    "per-cal-from-fat": "43",
    "saturated": "7.4",
    "polyunsaturated": "1.7",
    "monounsaturated": "6.4",
    "cholesterol": "49.5",
    "sodium": "798",
    "carbs": "32.3",
    "fibre": "2.4",
    "sugar": "6.7",
    "protein": "17.1",
    "cal-from-protein": "68",
    "per-cal-from-protein": "20",
    "potassium": "234",
    "calcium": "156",
    "iron": "2.9",
    "phosphorus": "180",
    "source": "USDA",
    "old units (ref)": "burger"
  },
  {
    "|| name                                               ||": "Burger, double patty, fried",
    "|| info                                                             ||": "e.g. McSpicy Double",
    "info-brands": "McSpicy Double, Double Filet-O-Fish"
  },
  {
    "id": "111",
    "disabled": "1",
    "type": "meal",
    "|| name                                               ||": "Burger, fried chicken",
    "rating": "2",
    "fried": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "burger",
    "weight-g": "205",
    "calories": "522",
    "fat": "25",
    "cal-from-fat": "225",
    "per-cal-from-fat": "43",
    "saturated": "5.1",
    "polyunsaturated": "7.1",
    "monounsaturated": "9.9",
    "cholesterol": "75",
    "sodium": "1280",
    "carbs": "50",
    "fibre": "3",
    "sugar": "0.5",
    "protein": "24",
    "cal-from-protein": "96",
    "per-cal-from-protein": "18",
    "potassium": "330",
    "calcium": "160",
    "iron": "2.5",
    "phosphorus": "330",
    "source": "N Buddy",
    "old units (ref)": "burger"
  },
  {
    "id": "272",
    "type": "meal",
    "|| name                                               ||": "Burger, fried fish",
    "rating": "2",
    "fried": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "burger",
    "|| info                                                             ||": "e.g. Filet-o-Fish",
    "info-brands": "Filet-O-Fish",
    "weight-g": "136",
    "calories": "391",
    "fat": "21.3",
    "cal-from-fat": "191.7",
    "per-cal-from-fat": "49",
    "saturated": "8.6",
    "polyunsaturated": "4.8",
    "monounsaturated": "5.4",
    "cholesterol": "31",
    "sodium": "646",
    "carbs": "34.6",
    "fibre": "0.8",
    "sugar": "4.3",
    "protein": "16.2",
    "cal-from-protein": "65",
    "per-cal-from-protein": "17",
    "potassium": "74",
    "calcium": "84",
    "iron": "2.3",
    "phosphorus": "182",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "burger"
  },
  {
    "id": "242",
    "type": "meal",
    "|| name                                               ||": "Burger, grilled beef",
    "rating": "2",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "burger",
    "weight-g": "305",
    "calories": "300",
    "fat": "32.4",
    "cal-from-fat": "291.6",
    "per-cal-from-fat": "97",
    "saturated": "10.6",
    "sodium": "1540",
    "carbs": "59.3",
    "sugar": "11.9",
    "protein": "25.9",
    "cal-from-protein": "104",
    "per-cal-from-protein": "35",
    "source": "N Buddy",
    "old units (ref)": "burger"
  },
  {
    "id": "277",
    "type": "meal",
    "|| name                                               ||": "Burger, grilled chicken",
    "rating": "2",
    "high-salt": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "burger",
    "weight-g": "225",
    "calories": "264",
    "fat": "11.2",
    "cal-from-fat": "100.8",
    "per-cal-from-fat": "38",
    "saturated": "2.32",
    "polyunsaturated": "4.07",
    "monounsaturated": "3.28",
    "cholesterol": "90",
    "sodium": "961",
    "carbs": "53.1",
    "fibre": "2.48",
    "protein": "33.1",
    "cal-from-protein": "132",
    "per-cal-from-protein": "50",
    "potassium": "497",
    "calcium": "56.2",
    "iron": "3.49",
    "phosphorus": "378",
    "source": "USDA",
    "old units (ref)": "burger"
  },
  {
    "id": "899",
    "type": "meal",
    "|| name                                               ||": "Burger, single patty, fried",
    "rating": "1",
    "fried": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "processed": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "burger",
    "|| info                                                             ||": "e.g. McSpicy, KFC Zinger",
    "info-brands": "McSpicy, Zinger",
    "weight-g": "264",
    "calories": "633",
    "fat": "35.9",
    "cal-from-fat": "323.1",
    "per-cal-from-fat": "51",
    "saturated": "6.5",
    "polyunsaturated": "14.9",
    "monounsaturated": "10.8",
    "cholesterol": "76.6",
    "sodium": "1630",
    "carbs": "72.3",
    "fibre": "3.7",
    "sugar": "8.98",
    "protein": "28.9",
    "cal-from-protein": "116",
    "per-cal-from-protein": "18",
    "potassium": "473",
    "calcium": "190",
    "iron": "4.51",
    "phosphorus": "380",
    "source": "USDA",
    "old units (ref)": "burger"
  },
  {
    "id": "495",
    "type": "snack",
    "|| name                                               ||": "Burrata",
    "rating": "3",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Italian mozeralla cheese and cream",
    "info-translation": "Italian mozeralla cheese and cream",
    "weight-g": "28",
    "calories": "70",
    "fat": "6",
    "cal-from-fat": "54",
    "per-cal-from-fat": "77",
    "saturated": "4",
    "cholesterol": "19.9",
    "sodium": "59.9",
    "carbs": "1",
    "fibre": "0",
    "sugar": "0",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "17",
    "potassium": "0",
    "calcium": "59.9",
    "iron": "0",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "247",
    "type": "meal",
    "|| name                                               ||": "Burrito",
    "rating": "2",
    "high-fibre": "1",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "|| info                                                             ||": "with meat and beans",
    "info-translation": "Tortillas",
    "info-include-exclude": "with meat and beans",
    "weight-g": "241",
    "calories": "444",
    "fat": "18",
    "cal-from-fat": "162",
    "per-cal-from-fat": "36",
    "saturated": "7",
    "polyunsaturated": "1.4",
    "monounsaturated": "7.8",
    "cholesterol": "63",
    "sodium": "1374",
    "carbs": "47",
    "fibre": "7.2",
    "sugar": "5.3",
    "protein": "27.8",
    "cal-from-protein": "111",
    "per-cal-from-protein": "25",
    "potassium": "648",
    "calcium": "217",
    "iron": "4.6",
    "phosphorus": "393",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "1042",
    "type": "snack",
    "|| name                                               ||": "Butter cake",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "36",
    "calories": "180",
    "fat": "10.6",
    "cal-from-fat": "95.4",
    "per-cal-from-fat": "53",
    "saturated": "6.9",
    "polyunsaturated": "0.1",
    "monounsaturated": "3.1",
    "cholesterol": "48",
    "sodium": "90",
    "carbs": "19.1",
    "fibre": "0",
    "sugar": "7.9",
    "protein": "2.2",
    "cal-from-protein": "9",
    "per-cal-from-protein": "5",
    "potassium": "27",
    "calcium": "29",
    "iron": "0.3",
    "phosphorus": "40",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "480",
    "type": "meal",
    "|| name                                               ||": "Butter chicken",
    "rating": "2",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-translation": "Murgh Makhani",
    "weight-g": "300",
    "calories": "574",
    "fat": "27.4",
    "cal-from-fat": "246.6",
    "per-cal-from-fat": "43",
    "saturated": "12",
    "sodium": "1490",
    "carbs": "57.8",
    "fibre": "2.2",
    "protein": "26",
    "cal-from-protein": "104",
    "per-cal-from-protein": "18",
    "calcium": "0",
    "iron": "0",
    "source": "N Buddy",
    "old units (ref)": "serve"
  },
  {
    "id": "603",
    "type": "snack",
    "|| name                                               ||": "Butter cookies",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "3",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. Danish butter cookie",
    "info-brands": "Kjeldsens, Walkers",
    "info-examples": "Danish, shortbread",
    "weight-g": "50",
    "calories": "242",
    "fat": "10",
    "cal-from-fat": "90",
    "per-cal-from-fat": "37",
    "saturated": "4",
    "trans": "0",
    "cholesterol": "15",
    "sodium": "110",
    "carbs": "34",
    "fibre": "0",
    "sugar": "8",
    "protein": "4",
    "cal-from-protein": "16",
    "per-cal-from-protein": "7",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "545",
    "type": "snack",
    "|| name                                               ||": "Butter, salted",
    "rating": "2",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "14.2",
    "calories": "104.02",
    "fat": "11.5",
    "cal-from-fat": "103.5",
    "per-cal-from-fat": "100",
    "saturated": "7.29",
    "polyunsaturated": "0.432",
    "monounsaturated": "2.98",
    "trans": "0.465",
    "cholesterol": "30.5",
    "sodium": "91.3",
    "carbs": "0.009",
    "fibre": "0",
    "sugar": "0.009",
    "protein": "0.121",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "3.41",
    "calcium": "3.41",
    "iron": "0.003",
    "phosphorus": "3.41",
    "source": "USDA",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "668",
    "type": "meal",
    "|| name                                               ||": "Button mushrooms, canned, drained",
    "rating": "3",
    "high-iron": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "can",
    "|| info                                                             ||": "13 pieces",
    "weight-g": "156",
    "calories": "16",
    "fat": "0.47",
    "cal-from-fat": "4.23",
    "per-cal-from-fat": "26",
    "saturated": "0.06",
    "sodium": "592.8",
    "carbs": "7.74",
    "fibre": "4.68",
    "protein": "2.96",
    "cal-from-protein": "12",
    "per-cal-from-protein": "74",
    "source": "HPB",
    "old units (ref)": "cup"
  },
  {
    "id": "660",
    "type": "meal",
    "|| name                                               ||": "Cabbage, boiled",
    "rating": "3",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "100",
    "calories": "25.6",
    "fat": "0.4",
    "cal-from-fat": "3.6",
    "per-cal-from-fat": "14",
    "saturated": "0.1",
    "polyunsaturated": "0.2",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "8",
    "carbs": "4.5",
    "fibre": "2.3",
    "sugar": "0",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "16",
    "potassium": "97",
    "calcium": "31",
    "iron": "0.2",
    "phosphorus": "15",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "208",
    "type": "snack",
    "|| name                                               ||": "Cake, chiffon",
    "rating": "2",
    "high-gi": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. pandan chiffon cake",
    "info-examples": "pandan, orange, vanilla",
    "weight-g": "41",
    "calories": "112.6",
    "fat": "7",
    "cal-from-fat": "63",
    "per-cal-from-fat": "56",
    "saturated": "3.2",
    "polyunsaturated": "0.5",
    "monounsaturated": "2.1",
    "cholesterol": "43",
    "sodium": "74",
    "carbs": "9.1",
    "fibre": "0.9",
    "sugar": "6.5",
    "protein": "3.3",
    "cal-from-protein": "13",
    "per-cal-from-protein": "12",
    "potassium": "63",
    "calcium": "20",
    "iron": "0.6",
    "phosphorus": "76",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "47",
    "type": "snack",
    "|| name                                               ||": "Cake, chocolate lava",
    "rating": "2",
    "high-gi": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "128",
    "calories": "430.7",
    "fat": "27.1",
    "cal-from-fat": "243.9",
    "per-cal-from-fat": "57",
    "saturated": "16.8",
    "polyunsaturated": "1.5",
    "monounsaturated": "8.2",
    "trans": "0.6",
    "sodium": "67",
    "carbs": "39",
    "fibre": "3.2",
    "sugar": "30.5",
    "protein": "7.7",
    "cal-from-protein": "31",
    "per-cal-from-protein": "7",
    "calcium": "54",
    "iron": "4.2",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "903",
    "type": "snack",
    "|| name                                               ||": "Cake, fruitcake",
    "rating": "2",
    "high-gi": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "info-include-exclude": "with candied / dried fruit, nuts and spices",
    "weight-g": "43",
    "calories": "146.19",
    "fat": "3.91",
    "cal-from-fat": "35.19",
    "per-cal-from-fat": "24",
    "saturated": "0.451",
    "polyunsaturated": "1.43",
    "monounsaturated": "1.81",
    "cholesterol": "2.15",
    "sodium": "43.4",
    "carbs": "26.5",
    "fibre": "1.59",
    "sugar": "11.8",
    "protein": "1.25",
    "cal-from-protein": "5",
    "per-cal-from-protein": "3",
    "potassium": "65.8",
    "calcium": "14.2",
    "iron": "0.89",
    "phosphorus": "22.4",
    "source": "USDA",
    "old units (ref)": "slice"
  },
  {
    "id": "81",
    "type": "snack",
    "|| name                                               ||": "Cake, mille crepe",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "74",
    "calories": "186.8",
    "fat": "13.2",
    "cal-from-fat": "118.8",
    "per-cal-from-fat": "64",
    "saturated": "8.7",
    "polyunsaturated": "0.6",
    "monounsaturated": "3.5",
    "trans": "0.4",
    "sodium": "42",
    "carbs": "12.9",
    "fibre": "0.4",
    "sugar": "6.3",
    "protein": "4.1",
    "cal-from-protein": "16",
    "per-cal-from-protein": "9",
    "calcium": "70",
    "iron": "0.5",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "30",
    "type": "snack",
    "|| name                                               ||": "Cake, sugee",
    "rating": "2",
    "high-cholesterol": "1",
    "high-gi": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "info-include-exclude": "with semolina, almond",
    "alternate-spellings": "Sugee: Suji, Sujee, Sugi",
    "weight-g": "45",
    "calories": "209.2",
    "fat": "14.8",
    "cal-from-fat": "133.2",
    "per-cal-from-fat": "64",
    "saturated": "7.7",
    "polyunsaturated": "1.6",
    "monounsaturated": "4.9",
    "cholesterol": "84",
    "sodium": "81",
    "carbs": "15.1",
    "fibre": "0.8",
    "sugar": "5.4",
    "protein": "3.9",
    "cal-from-protein": "16",
    "per-cal-from-protein": "7",
    "calcium": "27",
    "iron": "0.5",
    "phosphorus": "51",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "612",
    "type": "drink",
    "|| name                                               ||": "Canned coffee",
    "rating": "2",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "can",
    "|| info                                                             ||": "e.g. Nescafe mocha, double espresso, vanilla latte",
    "info-include-exclude": "with sugar",
    "volume-ml": "240",
    "calories": "100.7",
    "fat": "1.9",
    "cal-from-fat": "17.1",
    "per-cal-from-fat": "17",
    "saturated": "1.5",
    "carbs": "17.5",
    "sugar": "15.6",
    "protein": "3.4",
    "cal-from-protein": "14",
    "per-cal-from-protein": "14",
    "source": "N Buddy",
    "old units (ref)": "can"
  },
  {
    "id": "538",
    "type": "meal",
    "|| name                                               ||": "Canned fish in sauce",
    "rating": "2",
    "healthy-fats": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "|| info                                                             ||": "in mayo, chilli sauce, curry, etc",
    "info-examples": "tuna, sardine",
    "info-include-exclude": "with mayo / chilli / curry",
    "weight-g": "100",
    "calories": "150.8",
    "fat": "10",
    "cal-from-fat": "90",
    "per-cal-from-fat": "60",
    "saturated": "1.6",
    "polyunsaturated": "5.5",
    "monounsaturated": "2.8",
    "trans": "0.1",
    "cholesterol": "17",
    "sodium": "488",
    "carbs": "2.8",
    "fibre": "1.4",
    "sugar": "2.8",
    "protein": "12.4",
    "cal-from-protein": "50",
    "per-cal-from-protein": "33",
    "potassium": "95",
    "calcium": "9",
    "iron": "1.9",
    "phosphorus": "126",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "233",
    "type": "snack",
    "|| name                                               ||": "Canned fish in brine / water",
    "rating": "3",
    "healthy-fats": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "|| info                                                             ||": "in water",
    "info-examples": "tuna, sardine",
    "weight-g": "100",
    "calories": "121.4",
    "fat": "3",
    "cal-from-fat": "27",
    "per-cal-from-fat": "22",
    "saturated": "0.8",
    "polyunsaturated": "1.1",
    "monounsaturated": "0.8",
    "cholesterol": "42",
    "sodium": "50",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "23.6",
    "cal-from-protein": "94",
    "per-cal-from-protein": "78",
    "potassium": "237",
    "calcium": "14",
    "iron": "1",
    "phosphorus": "217",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "1081",
    "type": "snack",
    "|| name                                               ||": "Canned fruits, drained",
    "rating": "1",
    "high-gi": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "0.2",
    "|| unit ||": "can",
    "|| info                                                             ||": "drained, e.g. pineapple, sea coconut, lychee, longan",
    "info-examples": "pineapple, lychee, longan",
    "weight-g": "160",
    "calories": "91",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "1",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "10",
    "carbs": "23.9",
    "fibre": "1.6",
    "sugar": "22.3",
    "protein": "0.6",
    "cal-from-protein": "2",
    "per-cal-from-protein": "3",
    "potassium": "142",
    "calcium": "9.6",
    "iron": "0.5",
    "phosphorus": "18",
    "source": "USDA",
    "old units (ref)": "cup"
  },
  {
    "id": "85",
    "type": "snack",
    "|| name                                               ||": "Canned fruits in syrup",
    "rating": "2",
    "fruit": "1",
    "high-gi": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "0.2",
    "|| unit ||": "can",
    "|| info                                                             ||": "e.g. pineapple, peach",
    "info-examples": "pineapple, lychee, longan",
    "weight-g": "165",
    "calories": "120",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "1",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "10",
    "carbs": "31.2",
    "fibre": "1.7",
    "sugar": "29.6",
    "protein": "0.6",
    "cal-from-protein": "2",
    "per-cal-from-protein": "2",
    "potassium": "145",
    "calcium": "9.9",
    "iron": "0.5",
    "phosphorus": "18",
    "source": "USDA",
    "old units (ref)": "cup"
  },
  {
    "id": "623",
    "type": "meal",
    "|| name                                               ||": "Canned mushroom, drained",
    "rating": "3",
    "high-fibre": "1",
    "high-iron": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "156",
    "calories": "37",
    "fat": "0.5",
    "cal-from-fat": "4.5",
    "per-cal-from-fat": "12",
    "saturated": "0.1",
    "polyunsaturated": "0.2",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "593",
    "carbs": "7.6",
    "fibre": "4.7",
    "sugar": "0",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "32",
    "potassium": "156",
    "calcium": "8",
    "iron": "1.3",
    "phosphorus": "162",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "1015",
    "type": "snack",
    "|| name                                               ||": "Caramel sea salt cake",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "|| info                                                             ||": "salted caramel cake",
    "weight-g": "163",
    "calories": "454.6",
    "fat": "23.8",
    "cal-from-fat": "214.2",
    "per-cal-from-fat": "47",
    "saturated": "12.7",
    "polyunsaturated": "2.2",
    "monounsaturated": "7.5",
    "trans": "0.7",
    "cholesterol": "73",
    "sodium": "279",
    "carbs": "53.8",
    "fibre": "2.2",
    "sugar": "43.7",
    "protein": "6.3",
    "cal-from-protein": "25",
    "per-cal-from-protein": "6",
    "potassium": "218",
    "calcium": "92",
    "iron": "1.4",
    "phosphorus": "176",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "496",
    "type": "meal",
    "|| name                                               ||": "Carpaccio",
    "rating": "2",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "|| info                                                             ||": "sliced raw meat or fish with sauce (40g)",
    "info-portion-aid": "4 slices",
    "weight-g": "39",
    "calories": "90.5",
    "fat": "6.5",
    "cal-from-fat": "58.5",
    "per-cal-from-fat": "65",
    "saturated": "3",
    "polyunsaturated": "0.6",
    "monounsaturated": "2.9",
    "cholesterol": "27",
    "sodium": "133",
    "carbs": "1",
    "fibre": "0.1",
    "sugar": "0.4",
    "protein": "6.5",
    "cal-from-protein": "26",
    "per-cal-from-protein": "29",
    "potassium": "113",
    "source": "Nutritionix",
    "old units (ref)": "portion"
  },
  {
    "id": "1012",
    "type": "snack",
    "|| name                                               ||": "Carrot cake, without icing",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "info-include-exclude": "with walnut and raisins",
    "weight-g": "88",
    "calories": "346.3",
    "fat": "26.3",
    "cal-from-fat": "236.7",
    "per-cal-from-fat": "68",
    "saturated": "3.5",
    "polyunsaturated": "13.4",
    "monounsaturated": "2.5",
    "trans": "0.9",
    "cholesterol": "28",
    "sodium": "106",
    "carbs": "21.9",
    "fibre": "2.5",
    "sugar": "13.7",
    "protein": "5.5",
    "cal-from-protein": "22",
    "per-cal-from-protein": "6",
    "potassium": "125",
    "calcium": "28",
    "iron": "0.8",
    "phosphorus": "98",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "1082",
    "type": "meal",
    "|| name                                               ||": "Carrots, roasted",
    "rating": "3",
    "high-fibre": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "|| info                                                             ||": "1 medium",
    "weight-g": "100",
    "calories": "91",
    "fat": "6.2",
    "cal-from-fat": "55.8",
    "per-cal-from-fat": "61",
    "saturated": "0.9",
    "polyunsaturated": "0.6",
    "monounsaturated": "4.3",
    "sodium": "211",
    "carbs": "5.8",
    "fibre": "4.6",
    "sugar": "5.8",
    "protein": "0.9",
    "cal-from-protein": "4",
    "per-cal-from-protein": "4",
    "potassium": "313",
    "calcium": "35",
    "iron": "0.3",
    "phosphorus": "41",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "643",
    "type": "meal",
    "|| name                                               ||": "Carrots, boiled / steamed",
    "rating": "3",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "|| info                                                             ||": "1 medium",
    "weight-g": "100",
    "calories": "19.7",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "5",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "35",
    "carbs": "4",
    "fibre": "2.1",
    "sugar": "4",
    "protein": "0.7",
    "cal-from-protein": "3",
    "per-cal-from-protein": "14",
    "potassium": "211",
    "calcium": "25",
    "iron": "0.2",
    "phosphorus": "12",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "1083",
    "type": "meal",
    "|| name                                               ||": "Cauliflower, stir fried",
    "rating": "3",
    "high-fibre": "1",
    "plant-protein": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "100",
    "calories": "63",
    "fat": "5",
    "cal-from-fat": "45",
    "per-cal-from-fat": "71",
    "saturated": "0.6",
    "polyunsaturated": "3.2",
    "monounsaturated": "1.3",
    "sodium": "15",
    "carbs": "4.1",
    "fibre": "2.7",
    "sugar": "0",
    "protein": "1.8",
    "cal-from-protein": "7",
    "per-cal-from-protein": "11",
    "potassium": "142",
    "calcium": "16",
    "iron": "0.3",
    "phosphorus": "32",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "682",
    "type": "meal",
    "|| name                                               ||": "Cauliflower, boiled / steamed",
    "rating": "3",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "100",
    "calories": "28.1",
    "fat": "0.5",
    "cal-from-fat": "4.5",
    "per-cal-from-fat": "16",
    "saturated": "0.1",
    "polyunsaturated": "0.2",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "15",
    "carbs": "4.1",
    "fibre": "2.7",
    "sugar": "0",
    "protein": "1.8",
    "cal-from-protein": "7",
    "per-cal-from-protein": "26",
    "potassium": "142",
    "calcium": "16",
    "iron": "0.3",
    "phosphorus": "32",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "1084",
    "type": "meal",
    "|| name                                               ||": "Celery, stir fried",
    "rating": "3",
    "high-fibre": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "100",
    "calories": "73",
    "fat": "6.1",
    "cal-from-fat": "54.9",
    "per-cal-from-fat": "75",
    "saturated": "1",
    "polyunsaturated": "1.1",
    "monounsaturated": "3.6",
    "cholesterol": "1",
    "sodium": "117",
    "carbs": "3",
    "fibre": "1.6",
    "sugar": "1.4",
    "protein": "0.7",
    "cal-from-protein": "3",
    "per-cal-from-protein": "4",
    "potassium": "309",
    "calcium": "51",
    "iron": "0.3",
    "phosphorus": "35",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "642",
    "type": "meal",
    "|| name                                               ||": "Celery, raw / boiled / steamed",
    "rating": "3",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "120",
    "calories": "12",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "45",
    "carbs": "1.8",
    "fibre": "2.4",
    "sugar": "1.8",
    "protein": "1.2",
    "cal-from-protein": "5",
    "per-cal-from-protein": "40",
    "potassium": "366",
    "calcium": "114",
    "iron": "0.3",
    "phosphorus": "48",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "381",
    "type": "snack",
    "|| name                                               ||": "Cempedak",
    "rating": "3",
    "high-fibre": "1",
    "serving": "1",
    "|| unit ||": "seed",
    "alternate-spellings": "Cempedak: Chempedak, Champada",
    "weight-g": "8",
    "calories": "12.4",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "3",
    "carbs": "2.6",
    "fibre": "1",
    "sugar": "2.5",
    "protein": "0.5",
    "cal-from-protein": "2",
    "per-cal-from-protein": "16",
    "potassium": "8",
    "calcium": "17",
    "iron": "0.2",
    "phosphorus": "0",
    "source": "N Buddy",
    "old units (ref)": "seed"
  },
  {
    "id": "586",
    "type": "meal",
    "|| name                                               ||": "Cereal bar, low sugar",
    "rating": "2",
    "high-potassium": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "bar",
    "|| info                                                             ||": "e.g. special K red berry cereal bar, Myprotein cereal bar",
    "info-examples": "energy / muesli / granola",
    "weight-g": "22",
    "calories": "84",
    "fat": "1.7",
    "cal-from-fat": "15.3",
    "per-cal-from-fat": "18",
    "saturated": "1",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.6",
    "sodium": "56",
    "carbs": "15",
    "fibre": "1.9",
    "sugar": "6.5",
    "protein": "1.4",
    "cal-from-protein": "6",
    "per-cal-from-protein": "7",
    "source": "N Buddy",
    "old units (ref)": "bar"
  },
  {
    "id": "585",
    "type": "meal",
    "|| name                                               ||": "Cereal bar, regular",
    "rating": "2",
    "high-potassium": "1",
    "high-sugar": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "bar",
    "|| info                                                             ||": "inludes protein / energy / sports / fruit yogurt / muesli / granola bar",
    "info-examples": "energy / muesli / granola",
    "weight-g": "37",
    "calories": "135.13",
    "fat": "3.21",
    "cal-from-fat": "28.89",
    "per-cal-from-fat": "21",
    "saturated": "0.534",
    "polyunsaturated": "1.68",
    "monounsaturated": "0.003",
    "trans": "0.008",
    "cholesterol": "0",
    "sodium": "131",
    "carbs": "25",
    "fibre": "1.11",
    "sugar": "10.7",
    "protein": "1.56",
    "cal-from-protein": "6",
    "per-cal-from-protein": "5",
    "potassium": "91.4",
    "calcium": "213",
    "iron": "2.27",
    "phosphorus": "49.6",
    "source": "USDA",
    "old units (ref)": "bar"
  },
  {
    "id": "763",
    "type": "meal",
    "|| name                                               ||": "Cereal prawn",
    "rating": "2",
    "fried": "1",
    "high-calcium": "1",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "3",
    "|| unit ||": "piece",
    "|| info                                                             ||": "3 prawns",
    "info-translation": "Mai Pian Xia",
    "weight-g": "75",
    "calories": "185.2",
    "fat": "13.4",
    "cal-from-fat": "120.6",
    "per-cal-from-fat": "65",
    "saturated": "6.9",
    "polyunsaturated": "1.45",
    "monounsaturated": "5",
    "trans": "0.03",
    "cholesterol": "65.5",
    "sodium": "430",
    "carbs": "0.55",
    "fibre": "2.1",
    "sugar": "0.15",
    "protein": "15.6",
    "cal-from-protein": "62",
    "per-cal-from-protein": "34",
    "potassium": "163.5",
    "calcium": "169.5",
    "iron": "4.65",
    "phosphorus": "161.5",
    "source": "N Buddy",
    "old units (ref)": "large piece"
  },
  {
    "id": "583",
    "type": "meal",
    "|| name                                               ||": "Cereal with dried fruits, sweetened",
    "rating": "2",
    "high-fibre": "1",
    "high-gi": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "e.g. POST Raisin Bran Cereal; 6 tablespoons",
    "info-brands": "POST Raisin Bran",
    "info-portion-aid": "8 heaped tablespoons",
    "weight-g": "59",
    "calories": "225",
    "fat": "4.3",
    "cal-from-fat": "38.7",
    "per-cal-from-fat": "17",
    "saturated": "0",
    "polyunsaturated": "1.1",
    "monounsaturated": "2.2",
    "cholesterol": "0",
    "sodium": "145",
    "carbs": "44",
    "fibre": "5.4",
    "sugar": "14",
    "protein": "4.3",
    "cal-from-protein": "17",
    "per-cal-from-protein": "8",
    "potassium": "225",
    "calcium": "20",
    "iron": "9.7",
    "phosphorus": "100",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "248",
    "type": "meal",
    "|| name                                               ||": "Cereal, reduced sugar",
    "rating": "2",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "e.g. Special K, Cheerios, Granola; 6 tablespoons",
    "info-brands": "Special K, Cheerios",
    "info-examples": "granola",
    "info-portion-aid": "8 heaped tablespoons",
    "weight-g": "60",
    "calories": "214",
    "fat": "0.4",
    "cal-from-fat": "3.6",
    "per-cal-from-fat": "2",
    "saturated": "0.2",
    "polyunsaturated": "0.2",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "216",
    "carbs": "40",
    "fibre": "4",
    "sugar": "8.8",
    "protein": "12",
    "cal-from-protein": "48",
    "per-cal-from-protein": "22",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "584",
    "type": "meal",
    "|| name                                               ||": "Cereal, plain",
    "rating": "3",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "refined-grain": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "e.g. bran, muesli, and granola; 6 tablespoons",
    "info-brands": "Weet-Bix",
    "info-examples": "muesli, corn flakes original",
    "info-portion-aid": "8 heaped tablespoons",
    "weight-g": "56",
    "calories": "208",
    "fat": "3.4",
    "cal-from-fat": "30.6",
    "per-cal-from-fat": "15",
    "saturated": "0.4",
    "polyunsaturated": "1.6",
    "monounsaturated": "1.4",
    "cholesterol": "0",
    "sodium": "100",
    "carbs": "36.2",
    "fibre": "4.4",
    "sugar": "9",
    "protein": "6.2",
    "cal-from-protein": "25",
    "per-cal-from-protein": "12",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "99",
    "type": "meal",
    "|| name                                               ||": "Cereal, sweetened",
    "rating": "1",
    "high-gi": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "e.g. Banana nut crunch, Honey cheerios, Fruit Loops, KoKo Krunch; 6 tablespoons",
    "info-brands": "Banana Nut Crunch, Honey Cheerios, Frosties, Fruit Loops, KoKo Krunch",
    "info-portion-aid": "8 heaped tablespoons",
    "weight-g": "59",
    "calories": "230",
    "fat": "5",
    "cal-from-fat": "45",
    "per-cal-from-fat": "20",
    "saturated": "0.5",
    "polyunsaturated": "2",
    "monounsaturated": "2",
    "cholesterol": "0",
    "sodium": "140",
    "carbs": "43",
    "fibre": "7",
    "sugar": "10",
    "protein": "6",
    "cal-from-protein": "24",
    "per-cal-from-protein": "10",
    "potassium": "210",
    "calcium": "20",
    "iron": "16.2",
    "phosphorus": "140",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "498",
    "type": "meal",
    "|| name                                               ||": "Chap chye",
    "rating": "3",
    "high-fibre": "1",
    "high-salt": "1",
    "plant-protein": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "|| info                                                             ||": "stewed mixed vegetable with bean curd skins",
    "info-translation": "Braised mixed vegetables",
    "info-include-exclude": "with bean curd skins",
    "alternate-spellings": "Chap: Cap\nChye: Cai, Chai",
    "weight-g": "100",
    "calories": "88.3",
    "fat": "6.7",
    "cal-from-fat": "60.3",
    "per-cal-from-fat": "68",
    "saturated": "3.1",
    "polyunsaturated": "1",
    "monounsaturated": "2.4",
    "cholesterol": "9",
    "sodium": "362",
    "carbs": "5.1",
    "fibre": "2.1",
    "sugar": "2",
    "protein": "1.9",
    "cal-from-protein": "8",
    "per-cal-from-protein": "9",
    "calcium": "37",
    "iron": "0.9",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "249",
    "type": "meal",
    "|| name                                               ||": "Chapati",
    "rating": "2",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "plant-protein": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "alternate-spellings": "Chapati: Chappati, Chapatti, Chapathi, Phulka",
    "weight-g": "46",
    "calories": "143.4",
    "fat": "5",
    "cal-from-fat": "45",
    "per-cal-from-fat": "31",
    "saturated": "2.4",
    "polyunsaturated": "0.7",
    "monounsaturated": "1.8",
    "cholesterol": "0",
    "sodium": "119",
    "carbs": "21.5",
    "fibre": "3.2",
    "sugar": "1",
    "protein": "3.1",
    "cal-from-protein": "12",
    "per-cal-from-protein": "9",
    "potassium": "102",
    "calcium": "13",
    "iron": "0.8",
    "phosphorus": "70",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "759",
    "type": "meal",
    "|| name                                               ||": "Char siew rice",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "info-translation": "Barbecued pork rice",
    "alternate-spellings": "Char: Cha \nSiew: Shao, Siu \nBarbecued: BBQ",
    "weight-g": "327",
    "calories": "606.4",
    "fat": "16.4",
    "cal-from-fat": "147.6",
    "per-cal-from-fat": "24",
    "saturated": "11.9",
    "polyunsaturated": "0.4",
    "monounsaturated": "1.1",
    "cholesterol": "56",
    "sodium": "880",
    "carbs": "91.2",
    "fibre": "5.9",
    "sugar": "13.1",
    "protein": "23.5",
    "cal-from-protein": "94",
    "per-cal-from-protein": "16",
    "potassium": "206",
    "calcium": "23",
    "iron": "0.3",
    "phosphorus": "193",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "913",
    "type": "meal",
    "|| name                                               ||": "Char siew soh, baked",
    "rating": "2",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Barbequed pork tart",
    "info-translation": "Barbecued pork puff",
    "alternate-spellings": "Char: Cha \nSiew: Shao, Siu \nSoh: Sou\nBarbecued: BBQ",
    "weight-g": "44",
    "calories": "165.5",
    "fat": "11.1",
    "cal-from-fat": "99.9",
    "per-cal-from-fat": "60",
    "saturated": "5.8",
    "polyunsaturated": "1.1",
    "monounsaturated": "3.7",
    "cholesterol": "34",
    "sodium": "156",
    "carbs": "12.2",
    "fibre": "0.8",
    "sugar": "1.6",
    "protein": "4.2",
    "cal-from-protein": "17",
    "per-cal-from-protein": "10",
    "potassium": "64",
    "calcium": "12",
    "iron": "0.6",
    "phosphorus": "44",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "499",
    "type": "meal",
    "|| name                                               ||": "Charcuterie",
    "rating": "1",
    "high-cholesterol": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "|| info                                                             ||": "meat only (30g)",
    "info-portion-aid": "4 slices",
    "info-include-exclude": "meat only",
    "weight-g": "28",
    "calories": "89.9",
    "fat": "6.5",
    "cal-from-fat": "58.5",
    "per-cal-from-fat": "65",
    "saturated": "2.5",
    "cholesterol": "24.9",
    "sodium": "470",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "8",
    "cal-from-protein": "32",
    "per-cal-from-protein": "36",
    "potassium": "155",
    "iron": "2",
    "source": "USDA",
    "old units (ref)": "portion"
  },
  {
    "id": "349",
    "type": "snack",
    "|| name                                               ||": "Chawanmushi",
    "rating": "3",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "|| info                                                             ||": "Japanese steamed egg",
    "info-translation": "Japanese steamed egg",
    "weight-g": "100",
    "calories": "74.1",
    "fat": "5.3",
    "cal-from-fat": "47.7",
    "per-cal-from-fat": "64",
    "saturated": "1.6",
    "polyunsaturated": "0.5",
    "monounsaturated": "2.2",
    "cholesterol": "208",
    "sodium": "360",
    "carbs": "0.2",
    "fibre": "0",
    "sugar": "0.1",
    "protein": "6.4",
    "cal-from-protein": "26",
    "per-cal-from-protein": "35",
    "potassium": "52",
    "calcium": "21",
    "iron": "0.9",
    "phosphorus": "70",
    "source": "N Buddy",
    "old units (ref)": "cup / glass"
  },
  {
    "id": "100",
    "type": "meal",
    "|| name                                               ||": "Chee cheong fun with filling",
    "rating": "2",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "shrimp / charsiew / scallops",
    "info-translation": "Steamed rice rolls",
    "info-include-exclude": "with shrimps / char siew / scallops",
    "alternate-spellings": "Cheong: Cheung",
    "weight-g": "129",
    "calories": "132.2",
    "fat": "2.6",
    "cal-from-fat": "23.4",
    "per-cal-from-fat": "18",
    "saturated": "1.2",
    "polyunsaturated": "0.6",
    "monounsaturated": "0.8",
    "cholesterol": "17",
    "sodium": "239",
    "carbs": "18.5",
    "fibre": "1.2",
    "sugar": "1.2",
    "protein": "8.7",
    "cal-from-protein": "35",
    "per-cal-from-protein": "26",
    "potassium": "51",
    "calcium": "6",
    "iron": "0.8",
    "phosphorus": "0",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "843",
    "type": "meal",
    "|| name                                               ||": "Cheese baked rice",
    "rating": "2",
    "high-fat": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "260",
    "calories": "348",
    "fat": "4.8",
    "cal-from-fat": "43.2",
    "per-cal-from-fat": "12",
    "saturated": "2.6",
    "polyunsaturated": "0.5",
    "monounsaturated": "0.2",
    "cholesterol": "15",
    "sodium": "127",
    "carbs": "64.2",
    "fibre": "2.9",
    "sugar": "0.2",
    "protein": "12.8",
    "cal-from-protein": "51",
    "per-cal-from-protein": "15",
    "potassium": "161",
    "calcium": "2",
    "iron": "5.3",
    "phosphorus": "56",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "1086",
    "type": "snack",
    "|| name                                               ||": "Cheese cake, light (Japanese style)",
    "rating": "2",
    "high-fat": "1",
    "high-phosphate": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "|| info                                                             ||": "light, Japanese style",
    "weight-g": "32",
    "calories": "87",
    "fat": "3.6",
    "cal-from-fat": "32.4",
    "per-cal-from-fat": "37",
    "saturated": "0.6",
    "polyunsaturated": "0.3",
    "monounsaturated": "2.8",
    "carbs": "11.4",
    "fibre": "0.1",
    "sugar": "7.7",
    "protein": "2.1",
    "cal-from-protein": "8",
    "per-cal-from-protein": "10",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "1085",
    "type": "snack",
    "|| name                                               ||": "Cheese cake, fruit flavoured",
    "rating": "2",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "|| info                                                             ||": "fruit flavoured",
    "info-examples": "blueberry, strawberry, chocolate",
    "weight-g": "75",
    "calories": "252",
    "fat": "15",
    "cal-from-fat": "135",
    "per-cal-from-fat": "54",
    "saturated": "8.1",
    "polyunsaturated": "1.1",
    "monounsaturated": "5",
    "cholesterol": "28",
    "sodium": "183",
    "carbs": "25.9",
    "fibre": "0.5",
    "sugar": "18.3",
    "protein": "4.3",
    "cal-from-protein": "17",
    "per-cal-from-protein": "7",
    "potassium": "119",
    "calcium": "88",
    "iron": "1.4",
    "phosphorus": "122",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "48",
    "type": "snack",
    "|| name                                               ||": "Cheese cake, plain",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "|| info                                                             ||": "e.g. new york cheesecake, chicago cheesecake, basque burnt cheesecake",
    "info-examples": "New York, Chicago, basque burnt",
    "weight-g": "125",
    "calories": "408.1",
    "fat": "28.1",
    "cal-from-fat": "252.9",
    "per-cal-from-fat": "62",
    "saturated": "12.4",
    "polyunsaturated": "2",
    "monounsaturated": "10.8",
    "cholesterol": "69",
    "sodium": "548",
    "carbs": "31.9",
    "fibre": "0.5",
    "sugar": "27.3",
    "protein": "6.9",
    "cal-from-protein": "28",
    "per-cal-from-protein": "7",
    "potassium": "112",
    "calcium": "64",
    "iron": "0.8",
    "phosphorus": "116",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "822",
    "type": "snack",
    "|| name                                               ||": "Cheese fries",
    "rating": "1",
    "fried": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "weight-g": "216",
    "calories": "592",
    "fat": "36",
    "cal-from-fat": "324",
    "per-cal-from-fat": "55",
    "saturated": "7",
    "polyunsaturated": "2.7",
    "monounsaturated": "26.3",
    "sodium": "1438",
    "carbs": "61",
    "sugar": "1.5",
    "protein": "8",
    "cal-from-protein": "32",
    "per-cal-from-protein": "5",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "605",
    "type": "snack",
    "|| name                                               ||": "Cheese rings",
    "rating": "1",
    "high-fat": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "minipack",
    "|| info                                                             ||": "e.g. Cheezels, Super Ring",
    "info-brands": "Cheezels, Super Ring",
    "weight-g": "15",
    "calories": "86",
    "fat": "5.6",
    "cal-from-fat": "50.4",
    "per-cal-from-fat": "59",
    "saturated": "4",
    "polyunsaturated": "0.6",
    "monounsaturated": "0.8",
    "sodium": "162",
    "carbs": "7.9",
    "sugar": "1",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "5",
    "source": "N Buddy",
    "old units (ref)": "packet"
  },
  {
    "id": "740",
    "type": "snack",
    "|| name                                               ||": "Cheese sausage",
    "rating": "1",
    "fried": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "stick",
    "weight-g": "70",
    "calories": "211.2",
    "fat": "16.8",
    "cal-from-fat": "151.2",
    "per-cal-from-fat": "72",
    "saturated": "6.6",
    "polyunsaturated": "2.8",
    "monounsaturated": "7.4",
    "trans": "0.06",
    "cholesterol": "27",
    "sodium": "564",
    "carbs": "4.2",
    "fibre": "0.8",
    "sugar": "0.8",
    "protein": "10.8",
    "cal-from-protein": "43",
    "per-cal-from-protein": "20",
    "potassium": "184",
    "calcium": "56",
    "iron": "0.7",
    "phosphorus": "176",
    "source": "N Buddy",
    "old units (ref)": "stick"
  },
  {
    "id": "542",
    "type": "snack",
    "|| name                                               ||": "Cheese, natural, hard",
    "rating": "3",
    "high-calcium": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "square",
    "|| info                                                             ||": "e.g. Colby, cheddar, parmesan; 5cm square",
    "info-examples": "Colby, Cheddar, Parmesan, Swiss",
    "info-portion-aid": "5cm square",
    "weight-g": "20.6",
    "calories": "80.8",
    "fat": "5.2",
    "cal-from-fat": "46.8",
    "per-cal-from-fat": "58",
    "saturated": "3.1",
    "polyunsaturated": "0.1",
    "monounsaturated": "1.5",
    "cholesterol": "14",
    "sodium": "242",
    "carbs": "0.7",
    "fibre": "0",
    "sugar": "0",
    "protein": "7.4",
    "cal-from-protein": "30",
    "per-cal-from-protein": "37",
    "potassium": "19",
    "calcium": "244",
    "iron": "0.2",
    "phosphorus": "143",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "543",
    "type": "snack",
    "|| name                                               ||": "Cheese, natural, semi-hard",
    "rating": "3",
    "high-calcium": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "square",
    "|| info                                                             ||": "e.g. brick, Muenster, Roquefort, Stilton; 5cm square",
    "info-examples": "Muenster, Roquefort, Stilton, Gouda",
    "info-portion-aid": "5cm square",
    "weight-g": "34",
    "calories": "126",
    "fat": "10.1",
    "cal-from-fat": "90.9",
    "per-cal-from-fat": "72",
    "saturated": "6.4",
    "polyunsaturated": "0.3",
    "monounsaturated": "2.9",
    "cholesterol": "32",
    "sodium": "190",
    "carbs": "0.9",
    "fibre": "0",
    "sugar": "0.2",
    "protein": "7.9",
    "cal-from-protein": "32",
    "per-cal-from-protein": "25",
    "potassium": "46",
    "calcium": "230",
    "iron": "0.1",
    "phosphorus": "153",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "416",
    "type": "meal",
    "|| name                                               ||": "Cheese, natural, soft",
    "rating": "3",
    "high-calcium": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "square",
    "|| info                                                             ||": "e.g. Ricotta, Brie, Camembert; 5cm square",
    "info-examples": "Ricotta, Brie, Camembert, Mozzarella",
    "info-portion-aid": "5cm square",
    "weight-g": "34",
    "calories": "114",
    "fat": "9.4",
    "cal-from-fat": "84.6",
    "per-cal-from-fat": "74",
    "saturated": "5.9",
    "polyunsaturated": "0.3",
    "monounsaturated": "2.7",
    "cholesterol": "34",
    "sodium": "214",
    "carbs": "0.2",
    "fibre": "0",
    "sugar": "0.2",
    "protein": "7.1",
    "cal-from-protein": "28",
    "per-cal-from-protein": "25",
    "potassium": "52",
    "calcium": "62.6",
    "iron": "0.2",
    "phosphorus": "64",
    "iodine": "1",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "250",
    "type": "meal",
    "|| name                                               ||": "Cheese, processed",
    "rating": "2",
    "high-calcium": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "|| info                                                             ||": "e.g. processed cheddar cheese",
    "info-brands": "Kraft",
    "weight-g": "21",
    "calories": "70.6",
    "fat": "5.8",
    "cal-from-fat": "52.2",
    "per-cal-from-fat": "74",
    "saturated": "4",
    "polyunsaturated": "0.2",
    "monounsaturated": "1.6",
    "cholesterol": "17",
    "sodium": "284",
    "carbs": "0.1",
    "fibre": "0",
    "sugar": "0.1",
    "protein": "4.5",
    "cal-from-protein": "18",
    "per-cal-from-protein": "25",
    "potassium": "13",
    "calcium": "131",
    "iron": "0.1",
    "phosphorus": "59",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "53",
    "type": "snack",
    "|| name                                               ||": "Chendol",
    "rating": "2",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "alternate-spellings": "Chendol: Cendol",
    "weight-g": "250",
    "calories": "208.4",
    "fat": "8.8",
    "cal-from-fat": "79.2",
    "per-cal-from-fat": "38",
    "saturated": "8",
    "polyunsaturated": "0",
    "monounsaturated": "0.3",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "29.3",
    "fibre": "8",
    "sugar": "29.8",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "6",
    "calcium": "38",
    "iron": "1.3",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "210",
    "type": "snack",
    "|| name                                               ||": "Cheng tng",
    "rating": "2",
    "high-gi": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "alternate-spellings": "Cheng: Qing \nTng: Teng, Tang",
    "weight-g": "248",
    "calories": "108",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "26.3",
    "fibre": "1.7",
    "sugar": "24.8",
    "protein": "0.7",
    "cal-from-protein": "3",
    "per-cal-from-protein": "3",
    "calcium": "12",
    "iron": "0.3",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "382",
    "type": "snack",
    "|| name                                               ||": "Cherries",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "serving": "10",
    "|| unit ||": "cherry",
    "weight-g": "82",
    "calories": "57.32",
    "fat": "0.16",
    "cal-from-fat": "1.44",
    "per-cal-from-fat": "3",
    "saturated": "0.03",
    "polyunsaturated": "0.04",
    "monounsaturated": "0.04",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "13.1",
    "fibre": "1.72",
    "sugar": "10.5",
    "protein": "0.87",
    "cal-from-protein": "3",
    "per-cal-from-protein": "6",
    "potassium": "182",
    "calcium": "10.7",
    "iron": "0.3",
    "phosphorus": "17.2",
    "source": "USDA",
    "old units (ref)": "medium"
  },
  {
    "id": "645",
    "type": "meal",
    "|| name                                               ||": "Cherry tomato",
    "rating": "3",
    "fruit": "1",
    "high-potassium": "1",
    "serving": "12",
    "|| unit ||": "tomato",
    "weight-g": "96",
    "calories": "19.2",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "13.5",
    "carbs": "3.9",
    "fibre": "1.5",
    "sugar": "3.9",
    "protein": "0.9",
    "cal-from-protein": "4",
    "per-cal-from-protein": "19",
    "potassium": "240",
    "calcium": "15",
    "iron": "0.3",
    "phosphorus": "30",
    "source": "N Buddy",
    "old units (ref)": "small"
  },
  {
    "id": "532",
    "type": "snack",
    "|| name                                               ||": "Chia seed pudding, flavoured",
    "rating": "2",
    "high-iodine": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "|| info                                                             ||": "e.g. Chocolate Chia Pudding",
    "info-examples": "chocolate",
    "weight-g": "200",
    "calories": "393",
    "fat": "19",
    "cal-from-fat": "171",
    "per-cal-from-fat": "44",
    "saturated": "9.5",
    "polyunsaturated": "4.8",
    "monounsaturated": "4.7",
    "cholesterol": "0",
    "sodium": "312",
    "carbs": "47.5",
    "fibre": "9.5",
    "sugar": "28.3",
    "protein": "8",
    "cal-from-protein": "32",
    "per-cal-from-protein": "8",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "524",
    "type": "meal",
    "|| name                                               ||": "Chicken boat noodles",
    "rating": "2",
    "high-potassium": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "540",
    "calories": "493",
    "fat": "4.6",
    "cal-from-fat": "41.4",
    "per-cal-from-fat": "8",
    "saturated": "1.2",
    "polyunsaturated": "0.9",
    "monounsaturated": "1.5",
    "cholesterol": "112",
    "sodium": "830",
    "carbs": "63.9",
    "fibre": "4",
    "sugar": "0.9",
    "protein": "46.9",
    "cal-from-protein": "188",
    "per-cal-from-protein": "38",
    "potassium": "298",
    "calcium": "26",
    "iron": "1.4",
    "phosphorus": "239",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "894",
    "type": "meal",
    "|| name                                               ||": "Chicken chop & egg noodles, dry",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "172",
    "calories": "436.6",
    "fat": "18.6",
    "cal-from-fat": "167.4",
    "per-cal-from-fat": "38",
    "saturated": "7.4",
    "polyunsaturated": "1.7",
    "monounsaturated": "3.6",
    "cholesterol": "149",
    "sodium": "148",
    "carbs": "43.7",
    "fibre": "0.5",
    "sugar": "0.1",
    "protein": "23.6",
    "cal-from-protein": "94",
    "per-cal-from-protein": "22",
    "potassium": "161",
    "calcium": "21",
    "iron": "1.3",
    "phosphorus": "142",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "500",
    "type": "meal",
    "|| name                                               ||": "Chicken cotoletta",
    "rating": "2",
    "high-phosphate": "1",
    "high-salt": "1",
    "processed": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "|| info                                                             ||": "breaded chicken cutlet",
    "info-translation": "Breaded cutlets",
    "weight-g": "120",
    "calories": "288",
    "fat": "16.3",
    "cal-from-fat": "146.7",
    "per-cal-from-fat": "51",
    "saturated": "2.9",
    "polyunsaturated": "7.5",
    "monounsaturated": "4.9",
    "cholesterol": "43",
    "sodium": "632",
    "carbs": "17.8",
    "fibre": "2",
    "sugar": "0.7",
    "protein": "17.5",
    "cal-from-protein": "70",
    "per-cal-from-protein": "24",
    "potassium": "337",
    "calcium": "47",
    "iron": "1",
    "phosphorus": "240",
    "source": "USDA",
    "old units (ref)": "portion"
  },
  {
    "id": "408",
    "type": "snack",
    "|| name                                               ||": "Chicken essence, bottled",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "bottle",
    "volume-ml": "68",
    "calories": "25.2",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "55",
    "carbs": "0.3",
    "fibre": "0.5",
    "protein": "6",
    "cal-from-protein": "24",
    "per-cal-from-protein": "95",
    "source": "N Buddy",
    "old units (ref)": "bottle"
  },
  {
    "id": "907",
    "type": "meal",
    "|| name                                               ||": "Chicken feet in black sauce, steamed",
    "rating": "2",
    "high-phosphate": "1",
    "high-salt": "1",
    "serving": "3",
    "|| unit ||": "feet",
    "weight-g": "108",
    "calories": "156.4",
    "fat": "10.8",
    "cal-from-fat": "97.2",
    "per-cal-from-fat": "62",
    "saturated": "3.4",
    "polyunsaturated": "2.1",
    "monounsaturated": "4.9",
    "cholesterol": "23",
    "sodium": "471",
    "carbs": "0.5",
    "fibre": "2.3",
    "sugar": "0.5",
    "protein": "14.3",
    "cal-from-protein": "57",
    "per-cal-from-protein": "37",
    "potassium": "29",
    "calcium": "279",
    "iron": "1.2",
    "phosphorus": "159",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "738",
    "type": "snack",
    "|| name                                               ||": "Chicken mushroom puff, fried",
    "rating": "1",
    "fried": "1",
    "high-cholesterol": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "info-brands": "Old Chang Kee",
    "weight-g": "76",
    "calories": "341.4",
    "fat": "25",
    "cal-from-fat": "225",
    "per-cal-from-fat": "66",
    "saturated": "8.6",
    "polyunsaturated": "4.9",
    "monounsaturated": "9.9",
    "cholesterol": "214",
    "sodium": "358",
    "carbs": "21.7",
    "fibre": "1.2",
    "sugar": "0.4",
    "protein": "7.4",
    "cal-from-protein": "30",
    "per-cal-from-protein": "9",
    "potassium": "503",
    "calcium": "69",
    "iron": "2.8",
    "phosphorus": "440",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "818",
    "type": "snack",
    "|| name                                               ||": "Chicken nugget",
    "rating": "1",
    "fried": "1",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "2",
    "|| unit ||": "piece",
    "weight-g": "60",
    "calories": "174",
    "fat": "9.9",
    "cal-from-fat": "89.1",
    "per-cal-from-fat": "51",
    "saturated": "3.3",
    "polyunsaturated": "2.1",
    "monounsaturated": "4.2",
    "cholesterol": "15",
    "sodium": "390",
    "carbs": "12.6",
    "fibre": "0.3",
    "sugar": "4.2",
    "protein": "8.4",
    "cal-from-protein": "34",
    "per-cal-from-protein": "19",
    "potassium": "150",
    "calcium": "18",
    "iron": "0.3",
    "phosphorus": "168",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "741",
    "disabled": "1",
    "type": "snack",
    "|| name                                               ||": "Chicken nugget on stick",
    "rating": "1",
    "fried": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "stick",
    "|| info                                                             ||": "3 pieces",
    "info-portion-aid": "3 pieces",
    "weight-g": "60",
    "calories": "173.2",
    "fat": "10",
    "cal-from-fat": "90",
    "per-cal-from-fat": "52",
    "saturated": "3.4",
    "polyunsaturated": "1.9",
    "monounsaturated": "4.1",
    "trans": "0",
    "cholesterol": "14",
    "sodium": "389",
    "carbs": "12.5",
    "fibre": "0.2",
    "sugar": "4.3",
    "protein": "8.3",
    "cal-from-protein": "33",
    "per-cal-from-protein": "19",
    "potassium": "149",
    "calcium": "19",
    "iron": "0.4",
    "phosphorus": "168",
    "source": "N Buddy",
    "old units (ref)": "stick"
  },
  {
    "id": "252",
    "type": "meal",
    "|| name                                               ||": "Chicken rice, with steamed chicken",
    "rating": "2",
    "high-salt": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "330",
    "calories": "557.7",
    "fat": "13.9",
    "cal-from-fat": "125.1",
    "per-cal-from-fat": "22",
    "saturated": "5",
    "cholesterol": "36.6",
    "sodium": "698",
    "carbs": "80.2",
    "fibre": "3.3",
    "protein": "28.1",
    "cal-from-protein": "112",
    "per-cal-from-protein": "20",
    "source": "HPB",
    "old units (ref)": "plate"
  },
  {
    "id": "426",
    "type": "meal",
    "|| name                                               ||": "Chicken soup, non-creamy",
    "rating": "3",
    "high-potassium": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "with herbs (e.g. ginseng, chinese herbs)",
    "info-examples": "ginseng, chinese herbs",
    "weight-g": "420",
    "calories": "214",
    "fat": "10.1",
    "cal-from-fat": "90.9",
    "per-cal-from-fat": "42",
    "saturated": "3.8",
    "polyunsaturated": "2.1",
    "monounsaturated": "3.8",
    "cholesterol": "105",
    "sodium": "1046",
    "carbs": "7.1",
    "fibre": "0.4",
    "sugar": "3",
    "protein": "23.5",
    "cal-from-protein": "94",
    "per-cal-from-protein": "44",
    "calcium": "34",
    "iron": "1.7",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "820",
    "type": "meal",
    "|| name                                               ||": "Chicken tenders, breaded",
    "rating": "2",
    "fried": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "3",
    "|| unit ||": "strips",
    "weight-g": "90",
    "calories": "244",
    "fat": "12.5",
    "cal-from-fat": "112.5",
    "per-cal-from-fat": "46",
    "saturated": "2.2",
    "polyunsaturated": "5.2",
    "monounsaturated": "4.4",
    "trans": "0.1",
    "cholesterol": "43.2",
    "sodium": "693",
    "carbs": "15.5",
    "fibre": "1.1",
    "sugar": "0.4",
    "protein": "17.3",
    "cal-from-protein": "69",
    "per-cal-from-protein": "28",
    "potassium": "336",
    "calcium": "15.3",
    "iron": "0.7",
    "phosphorus": "254",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "253",
    "type": "meal",
    "|| name                                               ||": "Chicken wing, fried",
    "rating": "2",
    "fried": "1",
    "high-fat": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "96",
    "calories": "163",
    "fat": "11.3",
    "cal-from-fat": "101.7",
    "per-cal-from-fat": "62",
    "saturated": "3.1",
    "polyunsaturated": "2.5",
    "monounsaturated": "4.5",
    "cholesterol": "41",
    "sodium": "39",
    "carbs": "1.2",
    "fibre": "0",
    "sugar": "0",
    "protein": "13.3",
    "cal-from-protein": "53",
    "per-cal-from-protein": "33",
    "potassium": "90",
    "calcium": "8",
    "iron": "0.5",
    "phosphorus": "76",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "101",
    "type": "meal",
    "|| name                                               ||": "Chicken, fried, breaded",
    "rating": "1",
    "fried": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "processed": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. KFC, Texas Chicken, Popeyes",
    "info-brands": "KFC, Texas Chicken, Popeyes",
    "info-portion-aid": "5\" x 3\" x 1\"",
    "weight-g": "136",
    "calories": "373.4",
    "fat": "24.6",
    "cal-from-fat": "221.4",
    "per-cal-from-fat": "59",
    "saturated": "6.57",
    "polyunsaturated": "4",
    "monounsaturated": "11.5",
    "trans": "0.343",
    "cholesterol": "144",
    "sodium": "1020",
    "carbs": "11.8",
    "fibre": "0.136",
    "sugar": "0",
    "protein": "26.2",
    "cal-from-protein": "105",
    "per-cal-from-protein": "28",
    "potassium": "326",
    "calcium": "73.4",
    "iron": "1.36",
    "phosphorus": "286",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "774",
    "type": "meal",
    "|| name                                               ||": "Chicken with dried chilli",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "info-translation": "Gong Bao Ji",
    "alternate-spellings": "Chilli: Chili\nGong: Kung \nBao: Po, Pao",
    "weight-g": "100",
    "calories": "241.1",
    "fat": "17.1",
    "cal-from-fat": "153.9",
    "per-cal-from-fat": "64",
    "saturated": "6.1",
    "polyunsaturated": "2.5",
    "monounsaturated": "7.6",
    "cholesterol": "97",
    "sodium": "367",
    "carbs": "3.4",
    "fibre": "0",
    "sugar": "0.8",
    "protein": "18.4",
    "cal-from-protein": "74",
    "per-cal-from-protein": "31",
    "potassium": "285",
    "calcium": "17",
    "iron": "2.9",
    "phosphorus": "363",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "|| name                                               ||": "Chicken, fried, not breaded",
    "|| info                                                             ||": "not breaded"
  },
  {
    "id": "251",
    "type": "meal",
    "|| name                                               ||": "Chicken, roasted / boiled / grilled",
    "rating": "3",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "roasted / boiled / grilled",
    "info-include-exclude": "skin removed",
    "weight-g": "170",
    "calories": "170",
    "fat": "6",
    "cal-from-fat": "54",
    "per-cal-from-fat": "32",
    "saturated": "3.4",
    "polyunsaturated": "0.6",
    "monounsaturated": "3",
    "cholesterol": "9.3",
    "sodium": "400",
    "carbs": "1",
    "fibre": "1.2",
    "sugar": "1",
    "protein": "28",
    "cal-from-protein": "112",
    "per-cal-from-protein": "66",
    "potassium": "234",
    "calcium": "32",
    "iron": "0.7",
    "phosphorus": "141",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "767",
    "type": "meal",
    "|| name                                               ||": "Chicken, sweet and sour",
    "rating": "2",
    "fried": "1",
    "high-iodine": "1",
    "high-sugar": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "weight-g": "120",
    "calories": "243",
    "fat": "12.6",
    "cal-from-fat": "113.4",
    "per-cal-from-fat": "47",
    "saturated": "6.4",
    "polyunsaturated": "1.8",
    "monounsaturated": "4",
    "cholesterol": "36",
    "sodium": "364",
    "carbs": "19.3",
    "fibre": "0.1",
    "sugar": "9",
    "protein": "13.1",
    "cal-from-protein": "52",
    "per-cal-from-protein": "22",
    "potassium": "240",
    "calcium": "17",
    "iron": "1.7",
    "phosphorus": "180",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "694",
    "type": "meal",
    "|| name                                               ||": "Chickpeas, boiled",
    "rating": "3",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "handful",
    "weight-g": "50",
    "calories": "82",
    "fat": "1.3",
    "cal-from-fat": "11.7",
    "per-cal-from-fat": "14",
    "saturated": "0.1",
    "polyunsaturated": "0.6",
    "monounsaturated": "0.3",
    "cholesterol": "0",
    "sodium": "4",
    "carbs": "13.7",
    "fibre": "3.8",
    "sugar": "0",
    "protein": "4.5",
    "cal-from-protein": "18",
    "per-cal-from-protein": "22",
    "potassium": "146",
    "calcium": "25",
    "iron": "1.4",
    "phosphorus": "84",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "708",
    "type": "snack",
    "|| name                                               ||": "Chiku",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "fruit",
    "info-translation": "Sapota",
    "alternate-spellings": "Chiku: Chikoo \nSapota: Zapota",
    "weight-g": "170",
    "calories": "155.9",
    "fat": "1.9",
    "cal-from-fat": "17.1",
    "per-cal-from-fat": "11",
    "saturated": "0.3",
    "polyunsaturated": "0",
    "monounsaturated": "0.9",
    "cholesterol": "0",
    "sodium": "20",
    "carbs": "33.9",
    "fibre": "9",
    "protein": "0.8",
    "cal-from-protein": "3",
    "per-cal-from-protein": "2",
    "potassium": "328",
    "calcium": "36",
    "iron": "1.4",
    "phosphorus": "20",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "732",
    "type": "snack",
    "|| name                                               ||": "Chilli crab puff, fried",
    "rating": "1",
    "fried": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. Old Chang Kee crab puff",
    "info-brands": "Old Chang Kee",
    "weight-g": "64",
    "calories": "200.5",
    "fat": "12.5",
    "cal-from-fat": "112.5",
    "per-cal-from-fat": "56",
    "saturated": "5",
    "polyunsaturated": "3.8",
    "monounsaturated": "3.7",
    "cholesterol": "29",
    "sodium": "684",
    "carbs": "17.4",
    "fibre": "0.9",
    "sugar": "4.5",
    "protein": "4.6",
    "cal-from-protein": "18",
    "per-cal-from-protein": "9",
    "potassium": "63",
    "calcium": "71",
    "iron": "0.7",
    "phosphorus": "51",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "929",
    "type": "meal",
    "|| name                                               ||": "Chilli sauce",
    "rating": "2",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "16",
    "calories": "21",
    "fat": "1.8",
    "cal-from-fat": "16.2",
    "per-cal-from-fat": "77",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "228",
    "carbs": "0.7",
    "fibre": "0.3",
    "sugar": "0.6",
    "protein": "0.5",
    "cal-from-protein": "2",
    "per-cal-from-protein": "10",
    "potassium": "25",
    "calcium": "14",
    "iron": "0.6",
    "phosphorus": "9",
    "source": "N Buddy",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "687",
    "type": "meal",
    "|| name                                               ||": "Chinese cabbage, boiled / steamed",
    "rating": "3",
    "high-calcium": "1",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "100",
    "calories": "15.4",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "12",
    "saturated": "0",
    "polyunsaturated": "0.1",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "34",
    "carbs": "1.8",
    "fibre": "0.6",
    "sugar": "0",
    "protein": "1.6",
    "cal-from-protein": "6",
    "per-cal-from-protein": "42",
    "potassium": "371",
    "calcium": "93",
    "iron": "1",
    "phosphorus": "29",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "226",
    "type": "snack",
    "|| name                                               ||": "Chinese carrot cake, pan-fried",
    "rating": "2",
    "high-gi": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "steamed / pan-fried",
    "info-translation": "Lo Bak Go",
    "info-include-exclude": "with radish / turnip",
    "alternate-spellings": "Lo: Luo\nBak: Bo\nGo: Gou, Gao",
    "weight-g": "130",
    "calories": "180",
    "fat": "6.4",
    "cal-from-fat": "57.6",
    "per-cal-from-fat": "32",
    "saturated": "2.7",
    "polyunsaturated": "0.9",
    "monounsaturated": "2.7",
    "cholesterol": "5",
    "sodium": "476",
    "carbs": "27.2",
    "fibre": "1.2",
    "sugar": "1.4",
    "protein": "3.4",
    "cal-from-protein": "14",
    "per-cal-from-protein": "8",
    "potassium": "48",
    "calcium": "35",
    "iron": "1.4",
    "phosphorus": "38",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "310",
    "type": "meal",
    "|| name                                               ||": "Chinese fruit rojak, without you tiao",
    "rating": "2",
    "fruit": "1",
    "high-fibre": "1",
    "high-gi": "1",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "without you tiao and tau pok",
    "weight-g": "240",
    "calories": "345.8",
    "fat": "11.4",
    "cal-from-fat": "102.6",
    "per-cal-from-fat": "30",
    "saturated": "2.1",
    "polyunsaturated": "5.2",
    "monounsaturated": "3.4",
    "cholesterol": "35",
    "sodium": "419",
    "carbs": "47.2",
    "fibre": "9",
    "sugar": "3",
    "protein": "13.6",
    "cal-from-protein": "54",
    "per-cal-from-protein": "16",
    "calcium": "124",
    "iron": "7.7",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "743",
    "type": "meal",
    "|| name                                               ||": "Chinese fruit rojak, with you tiao",
    "rating": "2",
    "fruit": "1",
    "high-calcium": "1",
    "high-fibre": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "315",
    "calories": "557.8",
    "fat": "24.6",
    "cal-from-fat": "221.4",
    "per-cal-from-fat": "40",
    "saturated": "9.8",
    "polyunsaturated": "6.6",
    "monounsaturated": "6.9",
    "cholesterol": "35",
    "sodium": "857",
    "carbs": "64.6",
    "fibre": "9.8",
    "sugar": "5",
    "protein": "19.5",
    "cal-from-protein": "78",
    "per-cal-from-protein": "14",
    "calcium": "192",
    "iron": "9.1",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "225",
    "type": "snack",
    "|| name                                               ||": "Chinese pumpkin cake, steamed",
    "rating": "2",
    "high-gi": "1",
    "high-iron": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "steamed / pan-fried",
    "weight-g": "130",
    "calories": "146.2",
    "fat": "0.6",
    "cal-from-fat": "5.4",
    "per-cal-from-fat": "4",
    "saturated": "0.2",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.2",
    "cholesterol": "6",
    "sodium": "353",
    "carbs": "28.4",
    "fibre": "2.1",
    "sugar": "1.9",
    "protein": "6.8",
    "cal-from-protein": "27",
    "per-cal-from-protein": "19",
    "potassium": "242",
    "calcium": "57",
    "iron": "3.6",
    "phosphorus": "70",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "1087",
    "disabled": "1",
    "type": "meal",
    "|| name                                               ||": "Steamboat, Chinese style",
    "rating": "2",
    "high-fat": "1",
    "high-purine": "1",
    "high-salt": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "light meal with meat and vegetables",
    "info-include-exclude": "light meal with meat and vegetables",
    "weight-g": "351",
    "calories": "405",
    "fat": "15.5",
    "cal-from-fat": "139.5",
    "per-cal-from-fat": "34",
    "saturated": "5.2",
    "polyunsaturated": "3.8",
    "monounsaturated": "4.9",
    "cholesterol": "12",
    "sodium": "531",
    "carbs": "47.8",
    "fibre": "3",
    "sugar": "2.6",
    "protein": "18.6",
    "cal-from-protein": "74",
    "per-cal-from-protein": "18",
    "potassium": "269",
    "calcium": "100",
    "iron": "4",
    "phosphorus": "150",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "1088",
    "type": "meal",
    "|| name                                               ||": "Chinese style steamboat (heavy meal with meat and vegetables)",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "heavy meal with meat and vegetables",
    "weight-g": "683",
    "calories": "705",
    "fat": "30.5",
    "cal-from-fat": "274.5",
    "per-cal-from-fat": "39",
    "saturated": "11.7",
    "polyunsaturated": "7.3",
    "monounsaturated": "9.3",
    "cholesterol": "100",
    "sodium": "2205",
    "carbs": "68.7",
    "fibre": "13",
    "sugar": "4",
    "protein": "37.6",
    "cal-from-protein": "150",
    "per-cal-from-protein": "21",
    "potassium": "400",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "372",
    "type": "drink",
    "|| name                                               ||": "Chinese tea",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "cup",
    "volume-ml": "250",
    "calories": "3.2",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "5",
    "carbs": "0.3",
    "fibre": "0",
    "sugar": "0",
    "protein": "0.5",
    "cal-from-protein": "2",
    "per-cal-from-protein": "63",
    "potassium": "40",
    "calcium": "5",
    "iron": "0",
    "phosphorus": "8",
    "source": "USDA",
    "old units (ref)": "glass"
  },
  {
    "id": "224",
    "type": "snack",
    "|| name                                               ||": "Chinese yam cake, pan-fried",
    "rating": "2",
    "high-gi": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "steamed / pan-fried",
    "alternate-spellings": "Yam: Taro",
    "weight-g": "133",
    "calories": "193.2",
    "fat": "3.2",
    "cal-from-fat": "28.8",
    "per-cal-from-fat": "15",
    "saturated": "1.3",
    "polyunsaturated": "0.5",
    "monounsaturated": "1.2",
    "cholesterol": "3",
    "sodium": "580",
    "carbs": "37.8",
    "fibre": "1.3",
    "sugar": "0.3",
    "protein": "3.3",
    "cal-from-protein": "13",
    "per-cal-from-protein": "7",
    "potassium": "36",
    "calcium": "17",
    "iron": "0.4",
    "phosphorus": "35",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "600",
    "type": "snack",
    "|| name                                               ||": "Chips, vegetable, baked",
    "rating": "3",
    "high-potassium": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "packet",
    "|| info                                                             ||": "1 small packet",
    "info-portion-aid": "small",
    "weight-g": "30",
    "calories": "130.2",
    "fat": "3.8",
    "cal-from-fat": "34.2",
    "per-cal-from-fat": "26",
    "saturated": "0.25",
    "cholesterol": "0",
    "sodium": "155",
    "carbs": "22",
    "fibre": "2",
    "sugar": "3",
    "protein": "2",
    "cal-from-protein": "8",
    "per-cal-from-protein": "6",
    "calcium": "40",
    "iron": "5.5",
    "source": "https://www.eatthismuch.com/food/nutrition/baked-vegetable-crisps,442078/",
    "old units (ref)": "piece"
  },
  {
    "id": "601",
    "type": "snack",
    "|| name                                               ||": "Chips, vegetable, fried",
    "rating": "2",
    "fried": "1",
    "high-fat": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "packet",
    "|| info                                                             ||": "1 small packet",
    "info-portion-aid": "small",
    "weight-g": "30",
    "calories": "149.6",
    "fat": "6.4",
    "cal-from-fat": "57.6",
    "per-cal-from-fat": "39",
    "saturated": "2.1",
    "polyunsaturated": "0.5",
    "monounsaturated": "2",
    "cholesterol": "0",
    "sodium": "180",
    "carbs": "21.5",
    "fibre": "0.03",
    "sugar": "8.5",
    "protein": "1.5",
    "cal-from-protein": "6",
    "per-cal-from-protein": "4",
    "source": "https://shopee.sg/DJ-A-Veggie-Crisps-90g-i.74409681.1241530042?gclid=CjwKCAjwkPX0BRBKEiwA7THxiKtwMP2N9RnosL-tL3fFdCXZPJ5OpT56rBO3cNVitePngLhz0BeSEBoC_xsQAvD_BwE",
    "old units (ref)": "piece"
  },
  {
    "id": "606",
    "disabled": "1",
    "type": "snack",
    "|| name                                               ||": "Chips, potato and wheat",
    "rating": "1",
    "high-fat": "1",
    "high-potassium": "1",
    "processed": "1",
    "serving": "15",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. Pringles potato chips, Jack n Jill's roller coaster potato chips",
    "weight-g": "25",
    "calories": "137.2",
    "fat": "8.4",
    "cal-from-fat": "75.6",
    "per-cal-from-fat": "55",
    "saturated": "3.7",
    "polyunsaturated": "0.8",
    "monounsaturated": "3.9",
    "cholesterol": "0",
    "sodium": "110",
    "carbs": "14.1",
    "fibre": "1.5",
    "sugar": "0.3",
    "protein": "1.3",
    "cal-from-protein": "5",
    "per-cal-from-protein": "4",
    "potassium": "299",
    "calcium": "6",
    "iron": "0.6",
    "phosphorus": "30",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "191",
    "type": "snack",
    "|| name                                               ||": "Chips, potato, baked",
    "rating": "2",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "15",
    "|| unit ||": "chip",
    "info-brands": "Lay's, Ruffles",
    "weight-g": "25",
    "calories": "112.7",
    "fat": "3.1",
    "cal-from-fat": "27.9",
    "per-cal-from-fat": "25",
    "saturated": "0",
    "polyunsaturated": "1.8",
    "monounsaturated": "0.9",
    "cholesterol": "0",
    "sodium": "141.2",
    "carbs": "19.4",
    "fibre": "0.9",
    "sugar": "1.8",
    "protein": "1.8",
    "cal-from-protein": "7",
    "per-cal-from-protein": "6",
    "potassium": "202.9",
    "calcium": "8.8",
    "iron": "0.3",
    "source": "N Buddy",
    "old units (ref)": "chips"
  },
  {
    "id": "20",
    "type": "snack",
    "|| name                                               ||": "Chips, potato, fried",
    "rating": "2",
    "fried": "1",
    "high-fat": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "15",
    "|| unit ||": "chip",
    "info-brands": "Pringles, Jack n Jill's",
    "weight-g": "25",
    "calories": "137.2",
    "fat": "8.4",
    "cal-from-fat": "75.6",
    "per-cal-from-fat": "55",
    "saturated": "3.7",
    "polyunsaturated": "0.8",
    "monounsaturated": "3.9",
    "cholesterol": "0",
    "sodium": "110",
    "carbs": "14.1",
    "fibre": "1.5",
    "sugar": "0.3",
    "protein": "1.3",
    "cal-from-protein": "5",
    "per-cal-from-protein": "4",
    "potassium": "299",
    "calcium": "6",
    "iron": "0.6",
    "phosphorus": "30",
    "source": "N Buddy",
    "old units (ref)": "chips"
  },
  {
    "id": "184",
    "type": "snack",
    "|| name                                               ||": "Chips, vegetable, dehydrated",
    "rating": "2",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "pack",
    "|| info                                                             ||": "e.g. Inspiral Wheatgrass Kale Chips",
    "info-examples": "kale, broccoli, okra",
    "weight-g": "30",
    "calories": "143.2",
    "fat": "11.2",
    "cal-from-fat": "100.8",
    "per-cal-from-fat": "70",
    "saturated": "1.9",
    "sodium": "340",
    "carbs": "3.7",
    "fibre": "4.9",
    "sugar": "1.7",
    "protein": "6.9",
    "cal-from-protein": "28",
    "per-cal-from-protein": "19",
    "source": "N Buddy",
    "old units (ref)": "small packet"
  },
  {
    "id": "205",
    "type": "snack",
    "|| name                                               ||": "Chips, whole grain, baked",
    "rating": "2",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "whole-grain": "1",
    "serving": "15",
    "|| unit ||": "chip",
    "|| info                                                             ||": "e.g. lentil chips",
    "info-examples": "lentil",
    "weight-g": "28",
    "calories": "89.9",
    "fat": "2.3",
    "cal-from-fat": "20.7",
    "per-cal-from-fat": "23",
    "saturated": "0",
    "cholesterol": "0",
    "sodium": "101.3",
    "carbs": "14.3",
    "fibre": "2.3",
    "sugar": "0",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "13",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "chips"
  },
  {
    "id": "256",
    "type": "meal",
    "|| name                                               ||": "Chirashi don",
    "rating": "2",
    "healthy-fats": "1",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "409",
    "calories": "611.2",
    "fat": "15.2",
    "cal-from-fat": "136.8",
    "per-cal-from-fat": "22",
    "saturated": "5.7",
    "polyunsaturated": "2.8",
    "monounsaturated": "6.5",
    "sodium": "1472",
    "carbs": "85.9",
    "fibre": "10.6",
    "sugar": "5.3",
    "protein": "32.7",
    "cal-from-protein": "131",
    "per-cal-from-protein": "21",
    "calcium": "52",
    "iron": "1.3",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "912",
    "type": "meal",
    "|| name                                               ||": "Chive with minced prawn dumpling, steamed",
    "rating": "2",
    "high-cholesterol": "1",
    "high-purine": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Gao choi gao",
    "info-translation": "Gao Choi Gao",
    "alternate-spellings": "Gao: Jiu, Jiao\nChoi: Cai",
    "weight-g": "34",
    "calories": "47.9",
    "fat": "1.9",
    "cal-from-fat": "17.1",
    "per-cal-from-fat": "36",
    "saturated": "0.6",
    "polyunsaturated": "0.4",
    "monounsaturated": "0.8",
    "cholesterol": "10",
    "sodium": "164",
    "carbs": "5.1",
    "fibre": "0.8",
    "sugar": "0",
    "protein": "2.6",
    "cal-from-protein": "10",
    "per-cal-from-protein": "22",
    "potassium": "61",
    "calcium": "14",
    "iron": "0.2",
    "phosphorus": "22",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "924",
    "type": "meal",
    "|| name                                               ||": "Chives pork bun, pan-fried",
    "rating": "2",
    "fried": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Sheng jian bao",
    "info-translation": "Sheng Jian Bao",
    "alternate-spellings": "Sheng: Shui\nBao: Pau, Pao",
    "weight-g": "91",
    "calories": "205.6",
    "fat": "8.4",
    "cal-from-fat": "75.6",
    "per-cal-from-fat": "37",
    "saturated": "3.4",
    "polyunsaturated": "0.9",
    "monounsaturated": "3.4",
    "cholesterol": "69",
    "sodium": "185",
    "carbs": "26.9",
    "fibre": "1",
    "sugar": "0.6",
    "protein": "5.6",
    "cal-from-protein": "22",
    "per-cal-from-protein": "11",
    "potassium": "111",
    "calcium": "13",
    "iron": "0.9",
    "phosphorus": "62",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "1022",
    "type": "snack",
    "|| name                                               ||": "Chocolate banana cake",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "80",
    "calories": "259",
    "fat": "11",
    "cal-from-fat": "99",
    "per-cal-from-fat": "38",
    "saturated": "5",
    "cholesterol": "35",
    "sodium": "330",
    "carbs": "37",
    "fibre": "1",
    "sugar": "25",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "5",
    "calcium": "26",
    "iron": "1",
    "old units (ref)": "slice"
  },
  {
    "id": "49",
    "type": "snack",
    "|| name                                               ||": "Chocolate brownie",
    "rating": "2",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "square",
    "weight-g": "56",
    "calories": "236.13",
    "fat": "9.13",
    "cal-from-fat": "82.17",
    "per-cal-from-fat": "35",
    "saturated": "2.37",
    "polyunsaturated": "1.26",
    "monounsaturated": "5.02",
    "cholesterol": "9.52",
    "sodium": "160",
    "carbs": "35.8",
    "fibre": "1.18",
    "sugar": "20.5",
    "protein": "2.69",
    "cal-from-protein": "11",
    "per-cal-from-protein": "5",
    "potassium": "83.4",
    "calcium": "16.2",
    "iron": "1.26",
    "phosphorus": "56.6",
    "iodine": "1",
    "source": "USDA",
    "old units (ref)": "serving"
  },
  {
    "id": "1021",
    "type": "snack",
    "|| name                                               ||": "Chocolate fudge cake",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "145",
    "calories": "644.9",
    "fat": "40.5",
    "cal-from-fat": "364.5",
    "per-cal-from-fat": "57",
    "saturated": "21.9",
    "polyunsaturated": "2.7",
    "monounsaturated": "13.4",
    "trans": "0.4",
    "cholesterol": "96",
    "sodium": "289",
    "carbs": "61",
    "fibre": "1.9",
    "sugar": "41.8",
    "protein": "9.1",
    "cal-from-protein": "36",
    "per-cal-from-protein": "6",
    "potassium": "332",
    "calcium": "108",
    "iron": "2.3",
    "phosphorus": "345",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "1019",
    "type": "snack",
    "|| name                                               ||": "Chocolate mousse cake",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "150",
    "calories": "513",
    "fat": "29.7",
    "cal-from-fat": "267.3",
    "per-cal-from-fat": "52",
    "saturated": "17.5",
    "polyunsaturated": "1.4",
    "monounsaturated": "9",
    "trans": "0.5",
    "cholesterol": "144",
    "sodium": "232",
    "carbs": "55.7",
    "fibre": "1.1",
    "sugar": "41",
    "protein": "7.5",
    "cal-from-protein": "30",
    "per-cal-from-protein": "6",
    "potassium": "271",
    "calcium": "115",
    "iron": "1.8",
    "phosphorus": "285",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "1023",
    "type": "snack",
    "|| name                                               ||": "Chocolate praline cake",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "73",
    "calories": "297",
    "fat": "17",
    "cal-from-fat": "153",
    "per-cal-from-fat": "52",
    "saturated": "5",
    "polyunsaturated": "2",
    "monounsaturated": "8",
    "sodium": "160",
    "carbs": "33",
    "fibre": "1",
    "sugar": "22",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "4",
    "old units (ref)": "slice"
  },
  {
    "id": "65",
    "type": "snack",
    "|| name                                               ||": "Chocolate pudding",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "100g",
    "weight-g": "98",
    "calories": "139.19",
    "fat": "4.51",
    "cal-from-fat": "40.59",
    "per-cal-from-fat": "29",
    "saturated": "1.24",
    "polyunsaturated": "0.103",
    "monounsaturated": "2.68",
    "cholesterol": "0.98",
    "sodium": "149",
    "carbs": "22.6",
    "fibre": "0",
    "sugar": "16.8",
    "protein": "2.05",
    "cal-from-protein": "8",
    "per-cal-from-protein": "6",
    "potassium": "180",
    "calcium": "50",
    "iron": "1.24",
    "phosphorus": "54.9",
    "iodine": "1",
    "source": "USDA",
    "old units (ref)": "cup"
  },
  {
    "id": "1031",
    "type": "snack",
    "|| name                                               ||": "Chocolate tart",
    "rating": "2",
    "high-calcium": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "117",
    "calories": "338",
    "fat": "16.2",
    "cal-from-fat": "145.8",
    "per-cal-from-fat": "43",
    "saturated": "5.44",
    "polyunsaturated": "3.56",
    "monounsaturated": "6.24",
    "cholesterol": "44.5",
    "sodium": "303",
    "carbs": "42.3",
    "fibre": "1.76",
    "sugar": "20.8",
    "protein": "6.2",
    "cal-from-protein": "25",
    "per-cal-from-protein": "7",
    "potassium": "178",
    "calcium": "86.6",
    "iron": "2.5",
    "phosphorus": "129",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "1020",
    "type": "snack",
    "|| name                                               ||": "Chocolate truffle cake",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "120",
    "calories": "389",
    "fat": "25",
    "cal-from-fat": "225",
    "per-cal-from-fat": "58",
    "saturated": "14",
    "polyunsaturated": "1",
    "monounsaturated": "10",
    "cholesterol": "65",
    "carbs": "37",
    "fibre": "1",
    "sugar": "28",
    "protein": "4",
    "cal-from-protein": "16",
    "per-cal-from-protein": "4",
    "calcium": "40",
    "iron": "1.8",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "19",
    "type": "snack",
    "|| name                                               ||": "Chocolate, sweetened",
    "rating": "2",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "2",
    "|| unit ||": "square",
    "|| info                                                             ||": "e.g. milk / white chocolate",
    "info-examples": "milk, white",
    "weight-g": "30",
    "calories": "158.2",
    "fat": "8.2",
    "cal-from-fat": "73.8",
    "per-cal-from-fat": "47",
    "saturated": "5.1",
    "polyunsaturated": "0.3",
    "monounsaturated": "2.5",
    "cholesterol": "5.5",
    "sodium": "27",
    "carbs": "18.6",
    "fibre": "0.3",
    "sugar": "16.6",
    "protein": "2.5",
    "cal-from-protein": "10",
    "per-cal-from-protein": "6",
    "potassium": "135",
    "calcium": "75",
    "iron": "0.4",
    "phosphorus": "78",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "squares"
  },
  {
    "id": "203",
    "type": "snack",
    "|| name                                               ||": "Chocolate, unsweetened",
    "rating": "2",
    "high-phosphate": "1",
    "high-potassium": "1",
    "serving": "2",
    "|| unit ||": "square",
    "|| info                                                             ||": "e.g. dark chocolate",
    "info-examples": "dark",
    "weight-g": "25",
    "calories": "132.4",
    "fat": "7.2",
    "cal-from-fat": "64.8",
    "per-cal-from-fat": "49",
    "saturated": "4.3",
    "polyunsaturated": "0.3",
    "monounsaturated": "2.3",
    "cholesterol": "0",
    "sodium": "14",
    "carbs": "15.6",
    "fibre": "0.3",
    "sugar": "13",
    "protein": "1.3",
    "cal-from-protein": "5",
    "per-cal-from-protein": "4",
    "potassium": "110",
    "calcium": "13",
    "iron": "1.1",
    "phosphorus": "50",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "squares"
  },
  {
    "id": "570",
    "type": "drink",
    "|| name                                               ||": "Chrysanthemum tea, sweetened",
    "rating": "2",
    "high-gi": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "packet",
    "volume-ml": "250",
    "calories": "87.2",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "carbs": "21.8",
    "sugar": "20.3",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "source": "N Buddy",
    "old units (ref)": "packet"
  },
  {
    "id": "571",
    "type": "drink",
    "|| name                                               ||": "Chrysanthemum tea, unsweetened",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "packet",
    "volume-ml": "250",
    "calories": "10",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "53",
    "carbs": "2",
    "fibre": "0",
    "sugar": "0.2",
    "protein": "0.5",
    "cal-from-protein": "2",
    "per-cal-from-protein": "20",
    "potassium": "347",
    "calcium": "0",
    "iron": "0",
    "phosphorus": "10",
    "source": "N Buddy",
    "old units (ref)": "packet"
  },
  {
    "id": "52",
    "type": "snack",
    "|| name                                               ||": "Churros",
    "rating": "1",
    "fried": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "churro",
    "weight-g": "26",
    "calories": "125",
    "fat": "8",
    "cal-from-fat": "72",
    "per-cal-from-fat": "58",
    "saturated": "2.3",
    "polyunsaturated": "2.1",
    "monounsaturated": "3.2",
    "cholesterol": "1.8",
    "sodium": "42",
    "carbs": "12.9",
    "fibre": "0.2",
    "sugar": "7.1",
    "protein": "0.8",
    "cal-from-protein": "3",
    "per-cal-from-protein": "3",
    "potassium": "9",
    "calcium": "1.8",
    "iron": "0.4",
    "phosphorus": "8",
    "source": "USDA",
    "old units (ref)": "stick"
  },
  {
    "id": "76",
    "type": "snack",
    "|| name                                               ||": "Chwee kueh with chye poh",
    "rating": "1",
    "high-gi": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Steamed rice cake with preserved radish",
    "info-translation": "Steamed rice cake with preserved radish",
    "alternate-spellings": "Kueh:  Kway, Kuih\nChye: Cai, Chai",
    "weight-g": "60",
    "calories": "91.8",
    "fat": "5",
    "cal-from-fat": "45",
    "per-cal-from-fat": "49",
    "saturated": "3.3",
    "polyunsaturated": "0.3",
    "monounsaturated": "1.3",
    "cholesterol": "1",
    "sodium": "229",
    "carbs": "10.7",
    "fibre": "1.1",
    "sugar": "0",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "4",
    "calcium": "8",
    "iron": "0.4",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "693",
    "type": "meal",
    "|| name                                               ||": "Chye sim, stir-fried",
    "rating": "3",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "|| info                                                             ||": "Mustard green",
    "info-translation": "Mustard green",
    "alternate-spellings": "Chye: Choy, Cai, Sawi, Sio\nSim: Sum, Xin",
    "weight-g": "100",
    "calories": "14.72",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "12",
    "saturated": "0.4",
    "polyunsaturated": "1.14",
    "monounsaturated": "1.1",
    "cholesterol": "0",
    "sodium": "254",
    "carbs": "1.72",
    "fibre": "1",
    "sugar": "0.8",
    "protein": "1.51",
    "cal-from-protein": "6",
    "per-cal-from-protein": "41",
    "potassium": "359",
    "calcium": "90",
    "iron": "1.01",
    "phosphorus": "28",
    "source": "US FDA",
    "old units (ref)": "cup"
  },
  {
    "id": "169",
    "type": "drink",
    "|| name                                               ||": "Cider",
    "rating": "2",
    "alcohol": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bottle",
    "|| info                                                             ||": "e.g. Somersby Pear Cider",
    "info-brands": "Somersby",
    "info-examples": "apple, pear",
    "volume-ml": "330",
    "calories": "118.8",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "29.7",
    "sugar": "29.7",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "source": "N Buddy",
    "old units (ref)": "bottle"
  },
  {
    "id": "363",
    "type": "drink",
    "|| name                                               ||": "Cider vinegar",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "tsp",
    "volume-ml": "5",
    "calories": "0.4",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "0.1",
    "fibre": "0",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "4",
    "calcium": "0",
    "iron": "0",
    "phosphorus": "0",
    "source": "N Buddy",
    "old units (ref)": "teaspoon"
  },
  {
    "id": "791",
    "type": "meal",
    "|| name                                               ||": "Claypot noodle",
    "rating": "2",
    "high-gi": "1",
    "high-iron": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "claypot",
    "info-include-exclude": "with mixed vegetables",
    "weight-g": "502",
    "calories": "421.3",
    "fat": "18.1",
    "cal-from-fat": "162.9",
    "per-cal-from-fat": "39",
    "saturated": "7.9",
    "polyunsaturated": "1.9",
    "monounsaturated": "7.7",
    "cholesterol": "0",
    "sodium": "1270",
    "carbs": "55.6",
    "fibre": "4",
    "sugar": "3.5",
    "protein": "9",
    "cal-from-protein": "36",
    "per-cal-from-protein": "9",
    "potassium": "266",
    "calcium": "85",
    "iron": "2",
    "phosphorus": "105",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "447",
    "type": "meal",
    "|| name                                               ||": "Claypot rice",
    "rating": "2",
    "high-iron": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "with chicken, sausage, mushroom",
    "info-include-exclude": "with chicken, sausage, mushroom",
    "weight-g": "400",
    "calories": "602.4",
    "fat": "24.8",
    "cal-from-fat": "223.2",
    "per-cal-from-fat": "37",
    "saturated": "10.4",
    "polyunsaturated": "3.6",
    "monounsaturated": "9.6",
    "cholesterol": "112",
    "sodium": "1464",
    "carbs": "62.4",
    "fibre": "4",
    "sugar": "4.5",
    "protein": "32.4",
    "cal-from-protein": "130",
    "per-cal-from-protein": "22",
    "potassium": "300",
    "calcium": "80",
    "iron": "2",
    "phosphorus": "240",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "831",
    "type": "meal",
    "|| name                                               ||": "Clear soup",
    "rating": "3",
    "high-fibre": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-include-exclude": "with meat and vegetables",
    "weight-g": "350",
    "calories": "286",
    "fat": "13",
    "cal-from-fat": "117",
    "per-cal-from-fat": "41",
    "saturated": "4.5",
    "polyunsaturated": "1.7",
    "monounsaturated": "6.8",
    "sodium": "1000",
    "carbs": "31",
    "protein": "19",
    "cal-from-protein": "76",
    "per-cal-from-protein": "27",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "7",
    "type": "drink",
    "|| name                                               ||": "Cocktail",
    "rating": "1",
    "alcohol": "1",
    "high-gi": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "glass",
    "volume-ml": "250",
    "calories": "257",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "sodium": "0",
    "carbs": "17",
    "fibre": "0",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "source": "N Buddy",
    "old units (ref)": "glass"
  },
  {
    "id": "465",
    "type": "drink",
    "|| name                                               ||": "Coconut milk, sweetened",
    "rating": "2",
    "healthy-fats": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "volume-ml": "240",
    "calories": "70",
    "fat": "4.99",
    "cal-from-fat": "44.91",
    "per-cal-from-fat": "64",
    "saturated": "5",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "45.6",
    "carbs": "7.01",
    "fibre": "0",
    "sugar": "6",
    "protein": "0.504",
    "cal-from-protein": "2",
    "per-cal-from-protein": "3",
    "potassium": "45.6",
    "calcium": "451",
    "iron": "0.72",
    "source": "USDA",
    "old units (ref)": "cup"
  },
  {
    "id": "464",
    "type": "drink",
    "|| name                                               ||": "Coconut milk, unsweetened",
    "rating": "3",
    "healthy-fats": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "volume-ml": "250",
    "calories": "45",
    "fat": "6.8",
    "cal-from-fat": "61.2",
    "per-cal-from-fat": "136",
    "saturated": "6.3",
    "sodium": "100",
    "carbs": "5",
    "sugar": "0.7",
    "protein": "0.5",
    "cal-from-protein": "2",
    "per-cal-from-protein": "4",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "1010",
    "type": "snack",
    "|| name                                               ||": "Coconut pudding",
    "rating": "2",
    "high-calcium": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "255",
    "calories": "283",
    "fat": "8.75",
    "cal-from-fat": "78.75",
    "per-cal-from-fat": "28",
    "saturated": "7",
    "polyunsaturated": "0.219",
    "monounsaturated": "1.16",
    "cholesterol": "0",
    "sodium": "454",
    "carbs": "44.3",
    "fibre": "1.28",
    "sugar": "40.3",
    "protein": "7.37",
    "cal-from-protein": "29",
    "per-cal-from-protein": "10",
    "potassium": "347",
    "calcium": "258",
    "iron": "0.331",
    "phosphorus": "357",
    "source": "USDA",
    "old units (ref)": "serving"
  },
  {
    "id": "198",
    "type": "snack",
    "|| name                                               ||": "Coconut tart",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "100",
    "calories": "298",
    "fat": "16.6",
    "cal-from-fat": "149.4",
    "per-cal-from-fat": "50",
    "saturated": "7",
    "polyunsaturated": "1.5",
    "monounsaturated": "7",
    "cholesterol": "0",
    "sodium": "204",
    "carbs": "37.3",
    "fibre": "1.3",
    "sugar": "18.5",
    "protein": "2.1",
    "cal-from-protein": "8",
    "per-cal-from-protein": "3",
    "potassium": "65",
    "calcium": "29",
    "iron": "0.8",
    "phosphorus": "85",
    "iodine": "1",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "615",
    "type": "drink",
    "|| name                                               ||": "Coconut water (bottled / packet)",
    "rating": "2",
    "high-phosphate": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "packet",
    "volume-ml": "330",
    "calories": "62.7",
    "fat": "0.3",
    "cal-from-fat": "2.7",
    "per-cal-from-fat": "4",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "132",
    "carbs": "15",
    "fibre": "0",
    "sugar": "15",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "source": "N Buddy",
    "old units (ref)": "packet"
  },
  {
    "id": "417",
    "type": "meal",
    "|| name                                               ||": "Cod, baked / grilled / steamed",
    "rating": "3",
    "healthy-fats": "1",
    "high-iodine": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "|| info                                                             ||": "baked, grilled, steamed",
    "weight-g": "85",
    "calories": "84.179",
    "fat": "0.731",
    "cal-from-fat": "6.579",
    "per-cal-from-fat": "8",
    "saturated": "0.143",
    "polyunsaturated": "0.248",
    "monounsaturated": "0.105",
    "cholesterol": "46.8",
    "sodium": "66.3",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "19.4",
    "cal-from-protein": "78",
    "per-cal-from-protein": "92",
    "potassium": "207",
    "calcium": "11.9",
    "iron": "0.416",
    "phosphorus": "117",
    "iodine": "1",
    "source": "USDA",
    "old units (ref)": "portion"
  },
  {
    "id": "441",
    "type": "meal",
    "|| name                                               ||": "Cod, pan fried",
    "rating": "3",
    "healthy-fats": "1",
    "high-iodine": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "|| info                                                             ||": "pan fried",
    "weight-g": "85",
    "calories": "133.96",
    "fat": "6.8",
    "cal-from-fat": "61.2",
    "per-cal-from-fat": "46",
    "saturated": "1.02",
    "polyunsaturated": "3.655",
    "monounsaturated": "1.19",
    "cholesterol": "40.8",
    "sodium": "289",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "18.19",
    "cal-from-protein": "73",
    "per-cal-from-protein": "54",
    "potassium": "297.5",
    "calcium": "18.7",
    "iron": "0.34",
    "phosphorus": "161.5",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "1089",
    "disabled": "1",
    "type": "meal",
    "|| name                                               ||": "Cod (pan fried)",
    "rating": "3",
    "healthy-fats": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "weight-g": "100",
    "calories": "157",
    "fat": "8",
    "cal-from-fat": "72",
    "per-cal-from-fat": "46",
    "saturated": "1.2",
    "polyunsaturated": "4.3",
    "monounsaturated": "1.4",
    "cholesterol": "48",
    "sodium": "340",
    "carbs": "0",
    "protein": "21.4",
    "cal-from-protein": "86",
    "per-cal-from-protein": "55",
    "potassium": "350",
    "calcium": "22",
    "iron": "0.4",
    "phosphorus": "190",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "971",
    "type": "drink",
    "|| name                                               ||": "Coffee, almond milk, sweetened",
    "rating": "2",
    "high-gi": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "sweetened with sugar",
    "info-examples": "latte, flat white, cappuccino",
    "info-include-exclude": "with syrup/sugar",
    "weight-g": "240",
    "calories": "70",
    "fat": "1.9",
    "cal-from-fat": "17.1",
    "per-cal-from-fat": "24",
    "saturated": "0",
    "cholesterol": "0",
    "sodium": "135",
    "carbs": "9.6",
    "fibre": "0.4",
    "sugar": "8.4",
    "protein": "1.5",
    "cal-from-protein": "6",
    "per-cal-from-protein": "9",
    "calcium": "93.5",
    "source": "USDA",
    "old units (ref)": "cup"
  },
  {
    "id": "970",
    "type": "drink",
    "|| name                                               ||": "Coffee, almond milk, unsweetened",
    "rating": "3",
    "high-potassium": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "e.g. latte, flat white, capuccino",
    "info-examples": "latte, flat white, cappuccino",
    "weight-g": "240",
    "calories": "28",
    "fat": "1.9",
    "cal-from-fat": "17.1",
    "per-cal-from-fat": "61",
    "saturated": "0",
    "cholesterol": "0",
    "sodium": "135",
    "carbs": "1.2",
    "fibre": "0.4",
    "sugar": "0",
    "protein": "1.5",
    "cal-from-protein": "6",
    "per-cal-from-protein": "21",
    "calcium": "93.5",
    "source": "USDA",
    "old units (ref)": "cup"
  },
  {
    "id": "1",
    "type": "drink",
    "|| name                                               ||": "Coffee, black",
    "rating": "3",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "e.g. espresso, cold brew coffee",
    "info-examples": "espresso, cold brew coffee",
    "volume-ml": "248",
    "calories": "4.664",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "2.48",
    "carbs": "0.422",
    "fibre": "0",
    "sugar": "0",
    "protein": "0.744",
    "cal-from-protein": "3",
    "per-cal-from-protein": "64",
    "potassium": "124",
    "calcium": "4.96",
    "iron": "0.05",
    "phosphorus": "7.44",
    "source": "USDA",
    "old units (ref)": "cup"
  },
  {
    "id": "157",
    "type": "drink",
    "|| name                                               ||": "Coffee, fresh milk, sweetened",
    "rating": "2",
    "high-calcium": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "e.g. latte with syrup / sugar",
    "info-examples": "latte, flat white, cappuccino",
    "info-include-exclude": "with syrup/sugar",
    "volume-ml": "233",
    "calories": "164",
    "fat": "8.4",
    "cal-from-fat": "75.6",
    "per-cal-from-fat": "46",
    "saturated": "5.3",
    "polyunsaturated": "0.3",
    "monounsaturated": "2.4",
    "cholesterol": "28",
    "sodium": "63",
    "carbs": "11.9",
    "fibre": "0",
    "sugar": "16.9",
    "protein": "5.1",
    "cal-from-protein": "20",
    "per-cal-from-protein": "12",
    "potassium": "375",
    "calcium": "212",
    "iron": "0.2",
    "phosphorus": "137",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "156",
    "type": "drink",
    "|| name                                               ||": "Coffee, fresh milk, unsweetened",
    "rating": "3",
    "high-calcium": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "e.g. latte, flat white",
    "info-examples": "latte, flat white, cappuccino",
    "volume-ml": "233",
    "calories": "124",
    "fat": "8.4",
    "cal-from-fat": "75.6",
    "per-cal-from-fat": "61",
    "saturated": "5.3",
    "polyunsaturated": "0.7",
    "monounsaturated": "2.4",
    "cholesterol": "28",
    "sodium": "63",
    "carbs": "7",
    "fibre": "0",
    "sugar": "7",
    "protein": "5.1",
    "cal-from-protein": "20",
    "per-cal-from-protein": "16",
    "potassium": "375",
    "calcium": "212",
    "iron": "0.2",
    "phosphorus": "137",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "967",
    "type": "drink",
    "|| name                                               ||": "Coffee, low-fat milk, sweetened",
    "rating": "2",
    "high-calcium": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "e.g. skinny latte with sugar / syrup",
    "info-examples": "skinny latte, flat white, cappuccino",
    "info-include-exclude": "with syrup/sugar",
    "weight-g": "225",
    "calories": "130",
    "fat": "4.5",
    "cal-from-fat": "40.5",
    "per-cal-from-fat": "31",
    "saturated": "3",
    "polyunsaturated": "0.2",
    "monounsaturated": "1.2",
    "cholesterol": "25",
    "sodium": "90",
    "carbs": "10.3",
    "fibre": "0",
    "sugar": "15.4",
    "protein": "6.8",
    "cal-from-protein": "27",
    "per-cal-from-protein": "21",
    "potassium": "425",
    "calcium": "286",
    "iron": "0",
    "phosphorus": "189",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "966",
    "type": "drink",
    "|| name                                               ||": "Coffee, low-fat milk, unsweetened",
    "rating": "3",
    "high-calcium": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "e.g. skinny latte, flat white, capuccino",
    "info-examples": "skinny latte, flat white, cappuccino",
    "weight-g": "211",
    "calories": "89",
    "fat": "2.5",
    "cal-from-fat": "22.5",
    "per-cal-from-fat": "25",
    "saturated": "1.9",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.5",
    "cholesterol": "8",
    "sodium": "82",
    "carbs": "9.6",
    "sugar": "9",
    "protein": "6.8",
    "cal-from-protein": "27",
    "per-cal-from-protein": "31",
    "potassium": "334",
    "calcium": "244",
    "phosphorus": "206",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "973",
    "type": "drink",
    "|| name                                               ||": "Coffee, oat milk, sweetened",
    "rating": "2",
    "high-gi": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "e.g. sweetened latte, flat white, cappucino",
    "info-examples": "latte, flat white, cappuccino",
    "info-include-exclude": "with syrup/sugar",
    "weight-g": "8.4",
    "calories": "124",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "carbs": "22.5",
    "fibre": "0",
    "sugar": "22.5",
    "protein": "1.4",
    "cal-from-protein": "6",
    "per-cal-from-protein": "5",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "972",
    "type": "drink",
    "|| name                                               ||": "Coffee, oat milk, unsweetened",
    "rating": "3",
    "high-potassium": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "e.g. latte, flat white, capuccino",
    "info-examples": "latte, flat white, cappuccino",
    "weight-g": "240",
    "calories": "90",
    "fat": "3",
    "cal-from-fat": "27",
    "per-cal-from-fat": "30",
    "carbs": "14",
    "sugar": "14",
    "protein": "1.4",
    "cal-from-protein": "6",
    "per-cal-from-protein": "6",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "969",
    "type": "drink",
    "|| name                                               ||": "Coffee, soy milk, sweetened",
    "rating": "2",
    "high-calcium": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "e.g. latte with syrup / sugar",
    "info-examples": "latte, flat white, cappuccino",
    "info-include-exclude": "with syrup/sugar",
    "alternate-spellings": "Soy: Soya",
    "weight-g": "247",
    "calories": "103",
    "fat": "2.5",
    "cal-from-fat": "22.5",
    "per-cal-from-fat": "22",
    "saturated": "0.4",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "65",
    "carbs": "14.4",
    "fibre": "1",
    "sugar": "12.4",
    "protein": "5",
    "cal-from-protein": "20",
    "per-cal-from-protein": "19",
    "calcium": "472",
    "iron": "1",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "968",
    "type": "drink",
    "|| name                                               ||": "Coffee, soy milk, unsweetened",
    "rating": "3",
    "high-calcium": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "e.g. latte, flat white, capuccino",
    "info-examples": "latte, flat white, cappuccino",
    "alternate-spellings": "Soy: Soya",
    "weight-g": "236",
    "calories": "70",
    "fat": "2.5",
    "cal-from-fat": "22.5",
    "per-cal-from-fat": "32",
    "saturated": "0.4",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "65",
    "carbs": "6",
    "fibre": "1",
    "sugar": "4",
    "protein": "5",
    "cal-from-protein": "20",
    "per-cal-from-protein": "29",
    "calcium": "472",
    "iron": "1",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "574",
    "type": "drink",
    "|| name                                               ||": "Coffee, with flavored syrup",
    "rating": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "e.g. Starbucks / Coffee Bean caramel latte, small cup",
    "info-examples": "caramel latte, mocha",
    "volume-ml": "355",
    "calories": "263",
    "fat": "3",
    "cal-from-fat": "27",
    "per-cal-from-fat": "10",
    "saturated": "2.5",
    "cholesterol": "10",
    "sodium": "280",
    "carbs": "46",
    "fibre": "0",
    "sugar": "40",
    "protein": "13",
    "cal-from-protein": "52",
    "per-cal-from-protein": "20",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "867",
    "type": "meal",
    "|| name                                               ||": "Cold soba",
    "rating": "3",
    "high-iron": "1",
    "high-salt": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "e.g. chasoba, Japanese chilled noodles",
    "info-examples": "chasoba",
    "weight-g": "275",
    "calories": "171",
    "fat": "1.9",
    "cal-from-fat": "17.1",
    "per-cal-from-fat": "10",
    "saturated": "0.8",
    "polyunsaturated": "0.3",
    "monounsaturated": "0.8",
    "cholesterol": "0",
    "sodium": "558",
    "carbs": "28.1",
    "fibre": "2.8",
    "sugar": "3",
    "protein": "9.9",
    "cal-from-protein": "40",
    "per-cal-from-protein": "23",
    "potassium": "83",
    "calcium": "44",
    "iron": "2.5",
    "phosphorus": "77",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "257",
    "type": "meal",
    "|| name                                               ||": "Coleslaw",
    "rating": "2",
    "high-iodine": "1",
    "high-salt": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "weight-g": "97",
    "calories": "138",
    "fat": "6",
    "cal-from-fat": "54",
    "per-cal-from-fat": "39",
    "saturated": "0.7",
    "polyunsaturated": "3.8",
    "monounsaturated": "1.6",
    "cholesterol": "0",
    "sodium": "260",
    "carbs": "20",
    "fibre": "3",
    "sugar": "0",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "3",
    "potassium": "200",
    "calcium": "33",
    "iron": "0.7",
    "phosphorus": "30",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "604",
    "type": "snack",
    "|| name                                               ||": "Cookie, large",
    "rating": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. Subway cookies, Pepperidge Farm",
    "info-brands": "Subway, Pepperidge Farm",
    "weight-g": "45",
    "calories": "217.2",
    "fat": "10",
    "cal-from-fat": "90",
    "per-cal-from-fat": "41",
    "saturated": "5.6",
    "polyunsaturated": "0.6",
    "monounsaturated": "2.6",
    "cholesterol": "15",
    "sodium": "150",
    "carbs": "30",
    "fibre": "1",
    "sugar": "18",
    "protein": "1.8",
    "cal-from-protein": "7",
    "per-cal-from-protein": "3",
    "potassium": "67",
    "calcium": "16",
    "iron": "1.2",
    "phosphorus": "43",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "1090",
    "type": "snack",
    "|| name                                               ||": "Cookie, mini",
    "rating": "1",
    "high-fat": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "4",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. Famous Amos",
    "info-brands": "Famous Amos",
    "weight-g": "20",
    "calories": "150",
    "fat": "7",
    "cal-from-fat": "63",
    "per-cal-from-fat": "42",
    "saturated": "3",
    "cholesterol": "5",
    "sodium": "105",
    "carbs": "20",
    "fibre": "0.5",
    "sugar": "9",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "3",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "21",
    "type": "snack",
    "|| name                                               ||": "Cookie, regular",
    "rating": "2",
    "high-gi": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "3",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. Chips More original, Oreo",
    "info-brands": "Chips More, Oreo",
    "weight-g": "30",
    "calories": "147.66",
    "fat": "6.78",
    "cal-from-fat": "61.02",
    "per-cal-from-fat": "41",
    "saturated": "2.244",
    "polyunsaturated": "0.711",
    "monounsaturated": "3.51",
    "cholesterol": "0",
    "sodium": "94.5",
    "carbs": "20.04",
    "fibre": "0.75",
    "protein": "1.62",
    "cal-from-protein": "6",
    "per-cal-from-protein": "4",
    "potassium": "40.5",
    "calcium": "7.5",
    "iron": "0.3",
    "phosphorus": "34.4",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "1045",
    "type": "snack",
    "|| name                                               ||": "Cookies and cream cake",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "147",
    "calories": "449",
    "fat": "31.9",
    "cal-from-fat": "287.1",
    "per-cal-from-fat": "64",
    "saturated": "15.6",
    "polyunsaturated": "0.9",
    "monounsaturated": "5.4",
    "trans": "0.4",
    "cholesterol": "92",
    "sodium": "428",
    "carbs": "54.8",
    "fibre": "2.2",
    "sugar": "29.7",
    "protein": "6.3",
    "cal-from-protein": "25",
    "per-cal-from-protein": "6",
    "potassium": "179",
    "calcium": "79",
    "iron": "1",
    "phosphorus": "232",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "1065",
    "type": "snack",
    "|| name                                               ||": "Corn cup",
    "rating": "3",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "e.g. Mcdonald's",
    "weight-g": "85",
    "calories": "59.8",
    "fat": "1",
    "cal-from-fat": "9",
    "per-cal-from-fat": "15",
    "saturated": "0.3",
    "sodium": "160",
    "carbs": "11",
    "fibre": "4",
    "sugar": "0",
    "protein": "1.7",
    "cal-from-protein": "7",
    "per-cal-from-protein": "11",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "258",
    "disabled": "1",
    "type": "meal",
    "|| name                                               ||": "Corn on the cob, grilled",
    "rating": "3",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "cob",
    "|| info                                                             ||": "grilled",
    "weight-g": "161",
    "calories": "129.5",
    "fat": "4.7",
    "cal-from-fat": "42.3",
    "per-cal-from-fat": "33",
    "saturated": "0.9",
    "polyunsaturated": "2.1",
    "monounsaturated": "1.4",
    "cholesterol": "0",
    "sodium": "43",
    "carbs": "19.1",
    "fibre": "2.3",
    "sugar": "0",
    "protein": "2.7",
    "cal-from-protein": "11",
    "per-cal-from-protein": "8",
    "potassium": "239",
    "calcium": "4",
    "iron": "0.6",
    "phosphorus": "71",
    "source": "N Buddy",
    "old units (ref)": "cob"
  },
  {
    "id": "440",
    "type": "snack",
    "|| name                                               ||": "Corn on the cob, grilled / buttered and salted",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "cob",
    "|| info                                                             ||": "buttered and salted",
    "weight-g": "161",
    "calories": "115",
    "fat": "4.7",
    "cal-from-fat": "42.3",
    "per-cal-from-fat": "37",
    "saturated": "0.9",
    "polyunsaturated": "2.1",
    "monounsaturated": "1.4",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "100",
    "carbs": "19.1",
    "fibre": "2.3",
    "sugar": "0",
    "protein": "2.7",
    "cal-from-protein": "11",
    "per-cal-from-protein": "9",
    "potassium": "239",
    "calcium": "4",
    "iron": "0.6",
    "phosphorus": "71",
    "source": "N Buddy",
    "old units (ref)": "cob"
  },
  {
    "id": "1091",
    "type": "meal",
    "|| name                                               ||": "Corn on the cob, boiled / steamed",
    "rating": "3",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "cob",
    "|| info                                                             ||": "boiled or steamed",
    "weight-g": "156",
    "calories": "80",
    "fat": "0.63",
    "cal-from-fat": "5.67",
    "per-cal-from-fat": "7",
    "saturated": "0.1",
    "polyunsaturated": "0.3",
    "monounsaturated": "0.2",
    "cholesterol": "0",
    "sodium": "4",
    "carbs": "19.1",
    "fibre": "2.3",
    "sugar": "0",
    "protein": "2.63",
    "cal-from-protein": "11",
    "per-cal-from-protein": "13",
    "potassium": "238",
    "calcium": "3",
    "iron": "0.6",
    "phosphorus": "71",
    "source": "N Buddy",
    "old units (ref)": "cob"
  },
  {
    "id": "735",
    "type": "snack",
    "|| name                                               ||": "Crab nugget, fried",
    "rating": "1",
    "fried": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "stick",
    "weight-g": "60",
    "calories": "171",
    "fat": "7",
    "cal-from-fat": "63",
    "per-cal-from-fat": "37",
    "saturated": "3.5",
    "sodium": "668",
    "carbs": "20",
    "fibre": "0",
    "sugar": "3",
    "protein": "7",
    "cal-from-protein": "28",
    "per-cal-from-protein": "16",
    "source": "N Buddy",
    "old units (ref)": "stick"
  },
  {
    "id": "731",
    "type": "snack",
    "|| name                                               ||": "Crab stick, boiled",
    "rating": "2",
    "high-iodine": "1",
    "serving": "2",
    "|| unit ||": "piece",
    "weight-g": "32",
    "calories": "31.3",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "3",
    "saturated": "0.2",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "218",
    "carbs": "4",
    "fibre": "0.6",
    "sugar": "0",
    "protein": "3.6",
    "cal-from-protein": "14",
    "per-cal-from-protein": "46",
    "calcium": "42",
    "iron": "0.2",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "727",
    "type": "meal",
    "|| name                                               ||": "Crab, black pepper",
    "rating": "2",
    "high-calcium": "1",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "|| info                                                             ||": "1 pincer and 1 leg",
    "info-portion-aid": "1 pincer and 1 leg",
    "weight-g": "133",
    "calories": "173",
    "fat": "8.2",
    "cal-from-fat": "73.8",
    "per-cal-from-fat": "43",
    "saturated": "3",
    "polyunsaturated": "0.5",
    "monounsaturated": "2.2",
    "cholesterol": "115",
    "sodium": "1165",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "24.8",
    "cal-from-protein": "99",
    "per-cal-from-protein": "57",
    "potassium": "481",
    "calcium": "592",
    "iron": "2.7",
    "phosphorus": "471",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "729",
    "type": "meal",
    "|| name                                               ||": "Crab, butter",
    "rating": "2",
    "high-calcium": "1",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "|| info                                                             ||": "1 pincer and 1 leg",
    "info-portion-aid": "1 pincer and 1 leg",
    "weight-g": "144",
    "calories": "321.62",
    "fat": "28.38",
    "cal-from-fat": "255.42",
    "per-cal-from-fat": "79",
    "saturated": "6.75",
    "polyunsaturated": "1.25",
    "monounsaturated": "5.65",
    "trans": "0.3",
    "cholesterol": "63",
    "sodium": "672",
    "carbs": "6.35",
    "fibre": "0",
    "sugar": "0.2",
    "protein": "10.2",
    "cal-from-protein": "41",
    "per-cal-from-protein": "13",
    "potassium": "249",
    "calcium": "191",
    "iron": "1.15",
    "phosphorus": "241.5",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "254",
    "type": "meal",
    "|| name                                               ||": "Crab, chilli",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "|| info                                                             ||": "1 pincer and 1 leg",
    "info-portion-aid": "1 pincer and 1 leg",
    "weight-g": "198",
    "calories": "141",
    "fat": "8.5",
    "cal-from-fat": "76.5",
    "per-cal-from-fat": "54",
    "saturated": "3.9",
    "polyunsaturated": "1.1",
    "monounsaturated": "3.1",
    "cholesterol": "147",
    "sodium": "534",
    "carbs": "4.9",
    "fibre": "0",
    "sugar": "3",
    "protein": "11.3",
    "cal-from-protein": "45",
    "per-cal-from-protein": "32",
    "calcium": "183",
    "iron": "2.5",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "728",
    "type": "meal",
    "|| name                                               ||": "Crab, salted egg yolk",
    "rating": "2",
    "high-calcium": "1",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "|| info                                                             ||": "1 pincer and 1 leg",
    "info-portion-aid": "1 pincer and 1 leg",
    "weight-g": "152",
    "calories": "410.4",
    "fat": "37.4",
    "cal-from-fat": "336.6",
    "per-cal-from-fat": "82",
    "saturated": "8.3",
    "polyunsaturated": "1.7",
    "monounsaturated": "7.95",
    "trans": "0.3",
    "cholesterol": "242",
    "sodium": "698",
    "carbs": "6.35",
    "fibre": "0",
    "sugar": "0.2",
    "protein": "12.1",
    "cal-from-protein": "48",
    "per-cal-from-protein": "12",
    "potassium": "255.5",
    "calcium": "207",
    "iron": "1.9",
    "phosphorus": "299",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "730",
    "type": "meal",
    "|| name                                               ||": "Crab, steamed",
    "rating": "2",
    "high-calcium": "1",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "|| info                                                             ||": "1 pincer and 1 leg",
    "info-portion-aid": "1 pincer and 1 leg",
    "weight-g": "119",
    "calories": "54.9",
    "fat": "2.1",
    "cal-from-fat": "18.9",
    "per-cal-from-fat": "34",
    "saturated": "0.4",
    "polyunsaturated": "0",
    "monounsaturated": "0.25",
    "cholesterol": "51.5",
    "sodium": "240.5",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "9",
    "cal-from-protein": "36",
    "per-cal-from-protein": "66",
    "potassium": "151",
    "calcium": "81.5",
    "iron": "0.4",
    "phosphorus": "123.5",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "1092",
    "type": "snack",
    "|| name                                               ||": "Cranberries, raw",
    "rating": "3",
    "fruit": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "1 cup = 240mL",
    "weight-g": "100",
    "calories": "46",
    "fat": "0.13",
    "cal-from-fat": "1.17",
    "per-cal-from-fat": "3",
    "saturated": "0.008",
    "polyunsaturated": "0.055",
    "monounsaturated": "0.018",
    "sodium": "2",
    "carbs": "12",
    "fibre": "3.6",
    "sugar": "4.27",
    "protein": "0.46",
    "cal-from-protein": "2",
    "per-cal-from-protein": "4",
    "potassium": "80",
    "calcium": "8",
    "iron": "0.23",
    "phosphorus": "11",
    "source": "USDA",
    "old units (ref)": "cup"
  },
  {
    "id": "710",
    "type": "snack",
    "|| name                                               ||": "Cranberries, dried",
    "rating": "3",
    "fruit": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "15",
    "calories": "48",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "4",
    "saturated": "0",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.1",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "12.7",
    "fibre": "0.9",
    "sugar": "11.2",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "9",
    "calcium": "0",
    "iron": "0.1",
    "phosphorus": "0",
    "source": "N Buddy",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "548",
    "type": "snack",
    "|| name                                               ||": "Cream cheese",
    "rating": "2",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "14.5",
    "calories": "51.678",
    "fat": "4.99",
    "cal-from-fat": "44.91",
    "per-cal-from-fat": "87",
    "saturated": "2.93",
    "polyunsaturated": "0.215",
    "monounsaturated": "0.137",
    "cholesterol": "14.6",
    "sodium": "45.5",
    "carbs": "0.8",
    "fibre": "0",
    "sugar": "0.545",
    "protein": "0.892",
    "cal-from-protein": "4",
    "per-cal-from-protein": "7",
    "potassium": "19.1",
    "calcium": "14.1",
    "iron": "0.016",
    "phosphorus": "15.5",
    "source": "USDA",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "84",
    "type": "snack",
    "|| name                                               ||": "Cream / custard puff",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "large, e.g. Beard Papa",
    "info-brands": "Beard Papa",
    "info-portion-aid": "large",
    "weight-g": "64",
    "calories": "216",
    "fat": "15.9",
    "cal-from-fat": "143.1",
    "per-cal-from-fat": "66",
    "saturated": "9.7",
    "polyunsaturated": "0.7",
    "monounsaturated": "4.9",
    "cholesterol": "90",
    "sodium": "112",
    "carbs": "14.5",
    "fibre": "1.1",
    "sugar": "4.4",
    "protein": "3.7",
    "cal-from-protein": "15",
    "per-cal-from-protein": "7",
    "potassium": "58",
    "calcium": "52",
    "iron": "0.5",
    "phosphorus": "70",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "231",
    "type": "snack",
    "|| name                                               ||": "Cream / custard puff",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "small",
    "info-portion-aid": "small",
    "weight-g": "34",
    "calories": "79.4",
    "fat": "5",
    "cal-from-fat": "45",
    "per-cal-from-fat": "57",
    "saturated": "3",
    "polyunsaturated": "0.3",
    "monounsaturated": "1.5",
    "cholesterol": "23.5",
    "sodium": "71",
    "carbs": "7.1",
    "fibre": "0.8",
    "sugar": "4",
    "protein": "1.5",
    "cal-from-protein": "6",
    "per-cal-from-protein": "8",
    "potassium": "45",
    "calcium": "15",
    "iron": "0.2",
    "phosphorus": "25",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "833",
    "type": "meal",
    "|| name                                               ||": "Cream soup with meat or seafood",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "E.g. clam chowder, creamy chicken soup",
    "info-examples": "clam chowder, creamy chicken",
    "weight-g": "350",
    "calories": "315",
    "fat": "20",
    "cal-from-fat": "180",
    "per-cal-from-fat": "57",
    "saturated": "6",
    "polyunsaturated": "3.7",
    "monounsaturated": "7.2",
    "cholesterol": "28",
    "sodium": "2461",
    "carbs": "25",
    "fibre": "0",
    "sugar": "1.9",
    "protein": "8.3",
    "cal-from-protein": "33",
    "per-cal-from-protein": "11",
    "potassium": "171",
    "calcium": "53",
    "iron": "3.7",
    "phosphorus": "109",
    "source": "USDA",
    "old units (ref)": "bowl"
  },
  {
    "id": "832",
    "type": "meal",
    "|| name                                               ||": "Cream soup with vegetables",
    "rating": "3",
    "high-fat": "1",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "350",
    "calories": "230",
    "fat": "12.6",
    "cal-from-fat": "113.4",
    "per-cal-from-fat": "49",
    "saturated": "3.9",
    "polyunsaturated": "5.1",
    "monounsaturated": "3.1",
    "trans": "0.2",
    "cholesterol": "14",
    "sodium": "1260",
    "carbs": "20",
    "fibre": "1.1",
    "sugar": "10",
    "protein": "8.3",
    "cal-from-protein": "33",
    "per-cal-from-protein": "14",
    "potassium": "364",
    "calcium": "239",
    "iron": "0.4",
    "phosphorus": "209",
    "source": "USDA",
    "old units (ref)": "bowl"
  },
  {
    "id": "32",
    "type": "snack",
    "|| name                                               ||": "Creme brulee",
    "rating": "2",
    "high-cholesterol": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "226",
    "calories": "343",
    "fat": "27",
    "cal-from-fat": "243",
    "per-cal-from-fat": "71",
    "saturated": "17",
    "sodium": "30",
    "carbs": "22",
    "fibre": "0",
    "sugar": "15",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "3",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "502",
    "type": "snack",
    "|| name                                               ||": "Crepe suzette",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "crepe",
    "weight-g": "32",
    "calories": "140",
    "fat": "5",
    "cal-from-fat": "45",
    "per-cal-from-fat": "32",
    "saturated": "2",
    "cholesterol": "15",
    "sodium": "130",
    "carbs": "19",
    "fibre": "1",
    "sugar": "12",
    "protein": "2",
    "cal-from-protein": "8",
    "per-cal-from-protein": "6",
    "source": "USDA",
    "old units (ref)": "portion"
  },
  {
    "id": "787",
    "type": "meal",
    "|| name                                               ||": "Crispy noodle in egg gravy",
    "rating": "2",
    "high-calcium": "1",
    "high-cholesterol": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "408",
    "calories": "692.7",
    "fat": "37.1",
    "cal-from-fat": "333.9",
    "per-cal-from-fat": "48",
    "saturated": "14.3",
    "polyunsaturated": "5.3",
    "monounsaturated": "15.9",
    "cholesterol": "257",
    "sodium": "1563",
    "carbs": "62.8",
    "fibre": "8.2",
    "sugar": "0.8",
    "protein": "26.9",
    "cal-from-protein": "108",
    "per-cal-from-protein": "16",
    "potassium": "437",
    "calcium": "196",
    "iron": "3.7",
    "phosphorus": "302",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "490",
    "type": "snack",
    "|| name                                               ||": "Crispy peanut or red bean pancake",
    "rating": "2",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "info-brands": "Jollibean, Mr Bean",
    "weight-g": "115",
    "calories": "448",
    "fat": "28.9",
    "cal-from-fat": "260.1",
    "per-cal-from-fat": "58",
    "saturated": "10",
    "polyunsaturated": "6.2",
    "monounsaturated": "11.5",
    "cholesterol": "34",
    "sodium": "227",
    "carbs": "32.6",
    "fibre": "2.6",
    "sugar": "10",
    "protein": "15",
    "cal-from-protein": "60",
    "per-cal-from-protein": "13",
    "potassium": "138",
    "calcium": "78",
    "iron": "0.4",
    "phosphorus": "138",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "610",
    "type": "meal",
    "|| name                                               ||": "Rye bread",
    "rating": "3",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "30",
    "calories": "78.85",
    "fat": "0.65",
    "cal-from-fat": "5.85",
    "per-cal-from-fat": "7",
    "saturated": "0.05",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.05",
    "cholesterol": "0",
    "sodium": "119",
    "carbs": "15.1",
    "fibre": "0.1",
    "sugar": "0.6",
    "protein": "3.15",
    "cal-from-protein": "13",
    "per-cal-from-protein": "16",
    "potassium": "29",
    "calcium": "15.5",
    "iron": "1",
    "phosphorus": "43",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "825",
    "type": "meal",
    "|| name                                               ||": "Croissant sandwich with meat fillings",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "info-examples": "ham, bacon, chicken",
    "weight-g": "150",
    "calories": "452",
    "fat": "29.3",
    "cal-from-fat": "263.7",
    "per-cal-from-fat": "58",
    "saturated": "10.6",
    "polyunsaturated": "9.1",
    "monounsaturated": "9.2",
    "trans": "0",
    "cholesterol": "28",
    "sodium": "647",
    "carbs": "27.4",
    "fibre": "2",
    "sugar": "6.4",
    "protein": "20.3",
    "cal-from-protein": "81",
    "per-cal-from-protein": "18",
    "potassium": "255",
    "calcium": "42",
    "iron": "1.2",
    "phosphorus": "178",
    "source": "N Buddy",
    "old units (ref)": "whole"
  },
  {
    "id": "889",
    "type": "meal",
    "|| name                                               ||": "Croissant with savoury fillings",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. egg, ham, cheese, tuna, bacon",
    "info-examples": "egg, cheese, tuna",
    "weight-g": "113",
    "calories": "368.7",
    "fat": "23.1",
    "cal-from-fat": "207.9",
    "per-cal-from-fat": "56",
    "saturated": "10.5",
    "polyunsaturated": "2.29",
    "monounsaturated": "7.66",
    "cholesterol": "254",
    "sodium": "542",
    "carbs": "25",
    "fibre": "1.36",
    "sugar": "6.63",
    "protein": "15.2",
    "cal-from-protein": "61",
    "per-cal-from-protein": "16",
    "potassium": "194",
    "calcium": "48.6",
    "iron": "2.43",
    "phosphorus": "220",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "888",
    "type": "meal",
    "|| name                                               ||": "Croissant with sweet fillings",
    "rating": "2",
    "high-gi": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. almond, chocolate, matcha",
    "info-examples": "almond, chocolate, matcha",
    "weight-g": "84",
    "calories": "379.2",
    "fat": "24",
    "cal-from-fat": "216",
    "per-cal-from-fat": "57",
    "saturated": "10.8",
    "polyunsaturated": "2.3",
    "monounsaturated": "9.8",
    "cholesterol": "45",
    "sodium": "255",
    "carbs": "33",
    "fibre": "2.1",
    "sugar": "14.4",
    "protein": "7.8",
    "cal-from-protein": "31",
    "per-cal-from-protein": "8",
    "potassium": "132",
    "calcium": "66",
    "iron": "1.1",
    "phosphorus": "106",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "102",
    "type": "meal",
    "|| name                                               ||": "Croissant, plain",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-purine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "65",
    "calories": "256.1",
    "fat": "15.3",
    "cal-from-fat": "137.7",
    "per-cal-from-fat": "54",
    "saturated": "8.5",
    "polyunsaturated": "0.9",
    "monounsaturated": "5.4",
    "cholesterol": "38.2",
    "sodium": "241",
    "carbs": "23.1",
    "fibre": "1.9",
    "sugar": "3.3",
    "protein": "6.5",
    "cal-from-protein": "26",
    "per-cal-from-protein": "10",
    "potassium": "85",
    "calcium": "34",
    "iron": "0.7",
    "phosphorus": "59",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "103",
    "type": "meal",
    "|| name                                               ||": "Croquette",
    "rating": "2",
    "fried": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Fried potato cake",
    "info-translation": "Fried potato cake",
    "weight-g": "84",
    "calories": "213.5",
    "fat": "15.9",
    "cal-from-fat": "143.1",
    "per-cal-from-fat": "67",
    "saturated": "7.7",
    "polyunsaturated": "1.6",
    "monounsaturated": "5.8",
    "cholesterol": "38",
    "sodium": "162",
    "carbs": "15",
    "fibre": "1",
    "sugar": "1.2",
    "protein": "2.6",
    "cal-from-protein": "10",
    "per-cal-from-protein": "5",
    "potassium": "184",
    "calcium": "27",
    "iron": "0.5",
    "phosphorus": "51",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "916",
    "type": "meal",
    "|| name                                               ||": "Crystal dumpling, steamed",
    "rating": "2",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "shui jing bao",
    "info-translation": "\nShui Jing Bao\n",
    "alternate-spellings": "Shui: Sui\nBao: Pau, Pao",
    "weight-g": "42",
    "calories": "48",
    "fat": "0.8",
    "cal-from-fat": "7.2",
    "per-cal-from-fat": "15",
    "saturated": "0.8",
    "polyunsaturated": "0.3",
    "monounsaturated": "0.8",
    "cholesterol": "5",
    "sodium": "172",
    "carbs": "9.2",
    "fibre": "1.1",
    "sugar": "1.5",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "8",
    "potassium": "26",
    "calcium": "12",
    "iron": "0.3",
    "phosphorus": "13",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "634",
    "type": "meal",
    "|| name                                               ||": "Cucumber, raw",
    "rating": "3",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "medium",
    "weight-g": "200",
    "calories": "31.29",
    "fat": "0.01",
    "cal-from-fat": "0.09",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "26",
    "carbs": "6.8",
    "fibre": "1",
    "sugar": "6.8",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "13",
    "potassium": "152",
    "calcium": "28",
    "iron": "0.4",
    "phosphorus": "42",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "617",
    "type": "drink",
    "|| name                                               ||": "Cultured milk bottle drinks (low sugar)",
    "rating": "2",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "probiotic": "1",
    "serving": "1",
    "|| unit ||": "bottle",
    "|| info                                                             ||": "e.g. Yakult's Ace Light, Vitagen less sugar",
    "info-brands": "Yakult Ace Light, Vitagen less sugar",
    "volume-ml": "80",
    "calories": "46",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "16",
    "carbs": "10.6",
    "fibre": "0",
    "sugar": "6.8",
    "protein": "0.9",
    "cal-from-protein": "4",
    "per-cal-from-protein": "8",
    "source": "N Buddy",
    "old units (ref)": "bottle"
  },
  {
    "id": "616",
    "type": "drink",
    "|| name                                               ||": "Cultured milk bottle drinks (original)",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "probiotic": "1",
    "serving": "1",
    "|| unit ||": "bottle",
    "|| info                                                             ||": "e.g. Yakult, Vitagen",
    "info-brands": "Yakult, Vitagen",
    "volume-ml": "80",
    "calories": "52",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "carbs": "12",
    "fibre": "0",
    "sugar": "11",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "8",
    "source": "N Buddy",
    "old units (ref)": "bottle"
  },
  {
    "id": "26",
    "type": "snack",
    "|| name                                               ||": "Cupcake, without frosting",
    "rating": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "55",
    "calories": "207.5",
    "fat": "9.9",
    "cal-from-fat": "89.1",
    "per-cal-from-fat": "43",
    "saturated": "6.1",
    "polyunsaturated": "0.4",
    "monounsaturated": "2.7",
    "cholesterol": "58",
    "sodium": "275",
    "carbs": "25.8",
    "fibre": "0.8",
    "sugar": "10.7",
    "protein": "3.8",
    "cal-from-protein": "15",
    "per-cal-from-protein": "7",
    "potassium": "84",
    "calcium": "42",
    "iron": "0.7",
    "phosphorus": "211",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "|| name                                               ||": "Cupcake with frosting",
    "serving": "1",
    "|| unit ||": "piece"
  },
  {
    "id": "842",
    "type": "meal",
    "|| name                                               ||": "Curry baked rice",
    "rating": "2",
    "high-fat": "1",
    "high-iodine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "340",
    "calories": "564",
    "fat": "23.1",
    "cal-from-fat": "207.9",
    "per-cal-from-fat": "37",
    "saturated": "10.2",
    "cholesterol": "27",
    "sodium": "870",
    "carbs": "69",
    "fibre": "6.1",
    "sugar": "6.8",
    "protein": "20.4",
    "cal-from-protein": "82",
    "per-cal-from-protein": "14",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "449",
    "type": "meal",
    "|| name                                               ||": "Curry chicken noodles",
    "rating": "2",
    "high-fat": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "532",
    "calories": "696",
    "fat": "44.8",
    "cal-from-fat": "403.2",
    "per-cal-from-fat": "58",
    "saturated": "21.7",
    "polyunsaturated": "5.4",
    "monounsaturated": "15.6",
    "cholesterol": "87",
    "sodium": "2219",
    "carbs": "41.6",
    "fibre": "6.4",
    "sugar": "0.5",
    "protein": "31.6",
    "cal-from-protein": "126",
    "per-cal-from-protein": "18",
    "potassium": "202",
    "calcium": "160",
    "iron": "2.8",
    "phosphorus": "202",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "448",
    "type": "meal",
    "|| name                                               ||": "Curry chicken rice",
    "rating": "2",
    "high-fat": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "400",
    "calories": "553.6",
    "fat": "18.4",
    "cal-from-fat": "165.6",
    "per-cal-from-fat": "30",
    "saturated": "7.7",
    "polyunsaturated": "3.2",
    "monounsaturated": "6.3",
    "cholesterol": "47",
    "sodium": "498",
    "carbs": "76.6",
    "fibre": "6.2",
    "sugar": "4.1",
    "protein": "20.4",
    "cal-from-protein": "82",
    "per-cal-from-protein": "15",
    "potassium": "249",
    "calcium": "28",
    "iron": "5.1",
    "phosphorus": "171",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "44",
    "type": "snack",
    "|| name                                               ||": "Curry puff, fried",
    "rating": "2",
    "fried": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "regular",
    "info-examples": "potato",
    "info-portion-aid": "regular",
    "weight-g": "138",
    "calories": "400.7",
    "fat": "21.9",
    "cal-from-fat": "197.1",
    "per-cal-from-fat": "49",
    "saturated": "10.7",
    "polyunsaturated": "1.5",
    "monounsaturated": "8.7",
    "cholesterol": "22",
    "sodium": "800",
    "carbs": "42.5",
    "fibre": "3.7",
    "sugar": "2.2",
    "protein": "8.4",
    "cal-from-protein": "34",
    "per-cal-from-protein": "8",
    "potassium": "393",
    "calcium": "35",
    "iron": "2.2",
    "phosphorus": "93",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "200",
    "type": "snack",
    "|| name                                               ||": "Curry puff, fried",
    "rating": "2",
    "fried": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "small, e.g. epok epok",
    "info-examples": "potato",
    "info-portion-aid": "small",
    "weight-g": "40",
    "calories": "131.3",
    "fat": "8.1",
    "cal-from-fat": "72.9",
    "per-cal-from-fat": "56",
    "saturated": "3.8",
    "polyunsaturated": "0.7",
    "monounsaturated": "3.1",
    "cholesterol": "0",
    "sodium": "168",
    "carbs": "10.6",
    "fibre": "0.6",
    "sugar": "4.2",
    "protein": "4",
    "cal-from-protein": "16",
    "per-cal-from-protein": "12",
    "potassium": "53",
    "calcium": "6",
    "iron": "0.3",
    "phosphorus": "23",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "259",
    "type": "meal",
    "|| name                                               ||": "Curry, beef",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "|| info                                                             ||": "with 2/3 palm size beef",
    "info-portion-aid": "12cm bowl",
    "info-include-exclude": "with coconut milk",
    "weight-g": "100",
    "calories": "144",
    "fat": "5.7",
    "cal-from-fat": "51.3",
    "per-cal-from-fat": "36",
    "saturated": "2.8",
    "polyunsaturated": "0.5",
    "monounsaturated": "2.2",
    "cholesterol": "65",
    "sodium": "74",
    "carbs": "8.6",
    "fibre": "1.4",
    "sugar": "7.1",
    "protein": "14.6",
    "cal-from-protein": "58",
    "per-cal-from-protein": "41",
    "potassium": "147",
    "calcium": "22",
    "iron": "2.5",
    "phosphorus": "349",
    "source": "USDA",
    "old units (ref)": "portion"
  },
  {
    "id": "260",
    "type": "meal",
    "|| name                                               ||": "Curry, chicken",
    "rating": "2",
    "high-iron": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "|| info                                                             ||": "with 2/3 palm size chicken",
    "info-portion-aid": "12cm bowl",
    "info-include-exclude": "with coconut milk",
    "weight-g": "150",
    "calories": "270",
    "fat": "20",
    "cal-from-fat": "180",
    "per-cal-from-fat": "67",
    "saturated": "7.2",
    "polyunsaturated": "2.9",
    "monounsaturated": "8.8",
    "cholesterol": "86",
    "sodium": "245",
    "carbs": "2.3",
    "fibre": "2",
    "sugar": "1.7",
    "protein": "20.3",
    "cal-from-protein": "81",
    "per-cal-from-protein": "30",
    "potassium": "353",
    "calcium": "155",
    "iron": "3.8",
    "phosphorus": "245",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "261",
    "type": "meal",
    "|| name                                               ||": "Curry, fish",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "|| info                                                             ||": "with 2/3 palm size fish",
    "info-portion-aid": "12cm bowl",
    "info-include-exclude": "with coconut milk",
    "weight-g": "150",
    "calories": "213",
    "fat": "10.2",
    "cal-from-fat": "91.8",
    "per-cal-from-fat": "43",
    "saturated": "5.3",
    "polyunsaturated": "1.3",
    "monounsaturated": "2.7",
    "trans": "0.1",
    "cholesterol": "80",
    "sodium": "163",
    "carbs": "2.1",
    "fibre": "1.1",
    "sugar": "1.8",
    "protein": "27.6",
    "cal-from-protein": "110",
    "per-cal-from-protein": "52",
    "potassium": "514",
    "calcium": "47",
    "iron": "1",
    "phosphorus": "273",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "263",
    "type": "meal",
    "|| name                                               ||": "Curry, vegetable",
    "rating": "2",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "with coconut milk. Bowl-rice (11.5cm)",
    "info-include-exclude": "with coconut milk",
    "weight-g": "278",
    "calories": "159",
    "fat": "12.6",
    "cal-from-fat": "113.4",
    "per-cal-from-fat": "71",
    "saturated": "8.4",
    "polyunsaturated": "1.9",
    "monounsaturated": "1.7",
    "cholesterol": "0",
    "sodium": "625",
    "carbs": "9.3",
    "fibre": "2.6",
    "sugar": "6.7",
    "protein": "2.8",
    "cal-from-protein": "11",
    "per-cal-from-protein": "7",
    "potassium": "393",
    "calcium": "48",
    "iron": "1.1",
    "phosphorus": "72",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "716",
    "type": "snack",
    "|| name                                               ||": "Custard apple",
    "rating": "3",
    "fruit": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "fruit",
    "|| info                                                             ||": "1 medium size",
    "info-portion-aid": "medium",
    "weight-g": "145",
    "calories": "62.6",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "3",
    "saturated": "0.1",
    "polyunsaturated": "0.1",
    "monounsaturated": "0",
    "sodium": "1",
    "carbs": "13.6",
    "fibre": "2",
    "sugar": "10.9",
    "protein": "1.6",
    "cal-from-protein": "6",
    "per-cal-from-protein": "10",
    "potassium": "224",
    "calcium": "11",
    "iron": "0.4",
    "phosphorus": "41",
    "source": "N Buddy",
    "old units (ref)": "whole"
  },
  {
    "id": "1047",
    "type": "snack",
    "|| name                                               ||": "Cuttlefish snack",
    "rating": "1",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "packet",
    "|| info                                                             ||": "thin and crispy",
    "info-include-exclude": "thin and crispy",
    "weight-g": "85",
    "calories": "134",
    "fat": "1",
    "cal-from-fat": "9",
    "per-cal-from-fat": "7",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "190",
    "sodium": "632",
    "carbs": "12",
    "fibre": "0",
    "sugar": "12",
    "protein": "15",
    "cal-from-protein": "60",
    "per-cal-from-protein": "45",
    "potassium": "541",
    "old units (ref)": "packet"
  },
  {
    "id": "1048",
    "type": "snack",
    "|| name                                               ||": "Cuttlefish snack",
    "rating": "1",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "packet",
    "|| info                                                             ||": "thick and chewy",
    "info-include-exclude": "thick and chewy",
    "weight-g": "40",
    "calories": "141.1",
    "fat": "0.3",
    "cal-from-fat": "2.7",
    "per-cal-from-fat": "2",
    "sodium": "369",
    "carbs": "19.6",
    "fibre": "0",
    "sugar": "10",
    "protein": "15",
    "cal-from-protein": "60",
    "per-cal-from-protein": "43",
    "potassium": "109",
    "calcium": "20",
    "iron": "1.8",
    "phosphorus": "146",
    "source": "N Buddy",
    "old units (ref)": "packet"
  },
  {
    "id": "878",
    "type": "meal",
    "|| name                                               ||": "Daenjang",
    "rating": "2",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Korean soya bean paste soup",
    "info-translation": "Korean soya bean paste soup",
    "alternate-spellings": "Daenjang: Doenjang",
    "weight-g": "480",
    "calories": "154",
    "fat": "7.4",
    "cal-from-fat": "66.6",
    "per-cal-from-fat": "43",
    "saturated": "1",
    "polyunsaturated": "3",
    "monounsaturated": "2.2",
    "cholesterol": "19",
    "sodium": "2920",
    "carbs": "11.9",
    "fibre": "1.9",
    "sugar": "4.3",
    "protein": "11.9",
    "cal-from-protein": "48",
    "per-cal-from-protein": "31",
    "potassium": "254",
    "calcium": "77",
    "iron": "1.6",
    "phosphorus": "139",
    "source": "USDA",
    "old units (ref)": "bowl"
  },
  {
    "id": "202",
    "type": "snack",
    "|| name                                               ||": "Red / medjool date, pitted",
    "rating": "2",
    "high-gi": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. Medjool Date",
    "info-examples": "Medjool",
    "weight-g": "24",
    "calories": "73.6",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "18",
    "fibre": "1.6",
    "sugar": "16",
    "protein": "0.4",
    "cal-from-protein": "2",
    "per-cal-from-protein": "2",
    "potassium": "167",
    "calcium": "15",
    "iron": "0.2",
    "phosphorus": "15",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "783",
    "type": "meal",
    "|| name                                               ||": "Deep fried tofu with seafood / crabmeat",
    "rating": "2",
    "fried": "1",
    "high-calcium": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "134",
    "calories": "246.2",
    "fat": "20.2",
    "cal-from-fat": "181.8",
    "per-cal-from-fat": "74",
    "saturated": "6",
    "polyunsaturated": "6",
    "monounsaturated": "7.3",
    "cholesterol": "58",
    "sodium": "214",
    "carbs": "4.3",
    "fibre": "1.7",
    "sugar": "0.4",
    "protein": "11.8",
    "cal-from-protein": "47",
    "per-cal-from-protein": "19",
    "potassium": "143",
    "calcium": "225",
    "iron": "3.5",
    "phosphorus": "143",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "828",
    "type": "snack",
    "|| name                                               ||": "Dessert pies, fried",
    "rating": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "pie",
    "|| info                                                             ||": "e.g. apple pie, taro pie",
    "info-examples": "apple, taro",
    "weight-g": "85",
    "calories": "203",
    "fat": "9.5",
    "cal-from-fat": "85.5",
    "per-cal-from-fat": "42",
    "saturated": "4.5",
    "polyunsaturated": "0.9",
    "monounsaturated": "3.4",
    "cholesterol": "12",
    "sodium": "133",
    "carbs": "27.9",
    "fibre": "1.4",
    "sugar": "8",
    "protein": "3.2",
    "cal-from-protein": "13",
    "per-cal-from-protein": "6",
    "potassium": "54",
    "calcium": "11",
    "iron": "1.2",
    "phosphorus": "37",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "418",
    "type": "meal",
    "|| name                                               ||": "Dhal with coconut milk",
    "rating": "3",
    "high-calcium": "1",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "plant-protein": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Dessert bowl - 15cm",
    "alternate-spellings": "Dhal: Dal",
    "weight-g": "170",
    "calories": "190",
    "fat": "11.7",
    "cal-from-fat": "105.3",
    "per-cal-from-fat": "55",
    "saturated": "8.5",
    "polyunsaturated": "1.2",
    "monounsaturated": "1.4",
    "cholesterol": "0",
    "sodium": "329",
    "carbs": "12.2",
    "fibre": "6.5",
    "sugar": "1.2",
    "protein": "9.3",
    "cal-from-protein": "37",
    "per-cal-from-protein": "20",
    "potassium": "553",
    "calcium": "56",
    "iron": "1.8",
    "phosphorus": "179",
    "source": "N Buddy",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "442",
    "type": "snack",
    "|| name                                               ||": "Dhal, without coconut milk",
    "rating": "3",
    "high-calcium": "1",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Dessert bowl - 15cm",
    "alternate-spellings": "Dhal: Dal",
    "weight-g": "140",
    "calories": "106",
    "fat": "3.2",
    "cal-from-fat": "28.8",
    "per-cal-from-fat": "27",
    "saturated": "0.9",
    "polyunsaturated": "1.1",
    "monounsaturated": "1",
    "cholesterol": "0",
    "sodium": "325",
    "carbs": "11.3",
    "fibre": "6.2",
    "sugar": "0.3",
    "protein": "8.4",
    "cal-from-protein": "34",
    "per-cal-from-protein": "32",
    "potassium": "433",
    "calcium": "49",
    "iron": "1.4",
    "phosphorus": "147",
    "source": "N Buddy",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "147",
    "disabled": "1",
    "type": "meal",
    "|| name                                               ||": "Dim sum (pastry-based, e.g. barbequed pork tart, yam puff)",
    "rating": "2"
  },
  {
    "id": "27",
    "type": "snack",
    "|| name                                               ||": "Doughnut",
    "rating": "1",
    "fried": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "sugared or glazed",
    "alternate-spellings": "Doughnut: Donut",
    "weight-g": "42",
    "calories": "164.5",
    "fat": "8.5",
    "cal-from-fat": "76.5",
    "per-cal-from-fat": "47",
    "saturated": "4.2",
    "polyunsaturated": "0.7",
    "monounsaturated": "3.2",
    "trans": "0.1",
    "cholesterol": "8",
    "sodium": "112",
    "carbs": "18.7",
    "fibre": "1",
    "sugar": "5.7",
    "protein": "3.3",
    "cal-from-protein": "13",
    "per-cal-from-protein": "8",
    "potassium": "42",
    "calcium": "15",
    "iron": "0.3",
    "phosphorus": "37",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "|| name                                               ||": "Doughnut with filling / frosting",
    "alternate-spellings": "Doughnut: Donut"
  },
  {
    "id": "383",
    "type": "snack",
    "|| name                                               ||": "Dragonfruit",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "serving": "1",
    "|| unit ||": "whole",
    "weight-g": "300",
    "calories": "106",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "2",
    "saturated": "0",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.1",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "26",
    "carbs": "22.9",
    "fibre": "5.8",
    "sugar": "14.3",
    "protein": "2.8",
    "cal-from-protein": "11",
    "per-cal-from-protein": "11",
    "potassium": "127",
    "calcium": "13",
    "iron": "1.9",
    "phosphorus": "102",
    "source": "N Buddy",
    "old units (ref)": "whole fruit"
  },
  {
    "id": "41",
    "type": "snack",
    "|| name                                               ||": "Dried fruit",
    "rating": "2",
    "fruit": "1",
    "high-fibre": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "handful",
    "|| info                                                             ||": "e.g. dried guava, dried mango, dried plum",
    "info-examples": "plum, mango, gauva",
    "weight-g": "50",
    "calories": "167.37",
    "fat": "0.59",
    "cal-from-fat": "5.31",
    "per-cal-from-fat": "3",
    "saturated": "0.287",
    "polyunsaturated": "0.111",
    "monounsaturated": "0.2195",
    "cholesterol": "0",
    "sodium": "81",
    "carbs": "39.29",
    "fibre": "1.2",
    "sugar": "33.135",
    "protein": "1.225",
    "cal-from-protein": "5",
    "per-cal-from-protein": "3",
    "potassium": "139.5",
    "calcium": "0",
    "iron": "0.115",
    "phosphorus": "25",
    "source": "USDA",
    "old units (ref)": "handful"
  },
  {
    "id": "717",
    "type": "snack",
    "|| name                                               ||": "Raisins",
    "rating": "3",
    "fruit": "1",
    "high-gi": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "2",
    "|| unit ||": "tbsp",
    "alternate-spellings": "Raisins: Sultanas",
    "weight-g": "30",
    "calories": "95",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "2",
    "saturated": "0.2",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "6",
    "carbs": "23.1",
    "fibre": "1.8",
    "sugar": "21.4",
    "protein": "0.9",
    "cal-from-protein": "4",
    "per-cal-from-protein": "4",
    "source": "N Buddy",
    "old units (ref)": "handful"
  },
  {
    "id": "794",
    "type": "meal",
    "|| name                                               ||": "Dry ramen",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Mazesoba",
    "info-translation": "Mazesoba",
    "weight-g": "150",
    "calories": "617.16",
    "fat": "21.6",
    "cal-from-fat": "194.4",
    "per-cal-from-fat": "31",
    "saturated": "12",
    "polyunsaturated": "3.15",
    "monounsaturated": "9",
    "cholesterol": "0",
    "sodium": "1855",
    "carbs": "90.39",
    "fibre": "4.4",
    "sugar": "3",
    "protein": "15.3",
    "cal-from-protein": "61",
    "per-cal-from-protein": "10",
    "potassium": "273",
    "calcium": "31",
    "iron": "6.15",
    "phosphorus": "172.5",
    "source": "US FDA",
    "old units (ref)": "bowl"
  },
  {
    "id": "544",
    "type": "meal",
    "|| name                                               ||": "Duck, braised",
    "rating": "2",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "|| info                                                             ||": "without skin",
    "info-portion-aid": "3\" x 3' x 1\" ",
    "info-include-exclude": "without skin",
    "weight-g": "100",
    "calories": "252",
    "fat": "19",
    "cal-from-fat": "171",
    "per-cal-from-fat": "68",
    "saturated": "8.4",
    "polyunsaturated": "2.3",
    "monounsaturated": "7.1",
    "cholesterol": "109",
    "sodium": "342",
    "carbs": "0.6",
    "fibre": "0",
    "sugar": "0",
    "protein": "19.6",
    "cal-from-protein": "78",
    "per-cal-from-protein": "31",
    "calcium": "20",
    "iron": "3",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "265",
    "type": "meal",
    "|| name                                               ||": "Duck, roasted",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iron": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "info-portion-aid": "3\" x 3' x 1\" ",
    "weight-g": "100",
    "calories": "238",
    "fat": "16",
    "cal-from-fat": "144",
    "per-cal-from-fat": "61",
    "saturated": "5.9",
    "polyunsaturated": "2.2",
    "monounsaturated": "7",
    "cholesterol": "130",
    "sodium": "208",
    "carbs": "1.5",
    "fibre": "0",
    "sugar": "0",
    "protein": "22.1",
    "cal-from-protein": "88",
    "per-cal-from-protein": "37",
    "calcium": "48",
    "iron": "2.8",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "105",
    "type": "meal",
    "|| name                                               ||": "Duck, smoked",
    "rating": "2",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "info-portion-aid": "3\" x 3' x 1\"",
    "weight-g": "100",
    "calories": "142",
    "fat": "4.4",
    "cal-from-fat": "39.6",
    "per-cal-from-fat": "28",
    "saturated": "1.3",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "84",
    "sodium": "1327",
    "carbs": "0.9",
    "fibre": "0",
    "sugar": "0.9",
    "protein": "22",
    "cal-from-protein": "88",
    "per-cal-from-protein": "62",
    "iron": "4.8",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "104",
    "type": "meal",
    "|| name                                               ||": "Dukbokki",
    "rating": "2",
    "high-gi": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Korean spicy rice cake",
    "info-translation": "Korean spicy rice cake",
    "alternate-spellings": "Dukbokki: Tteok-bokki, Tteokbokki",
    "weight-g": "210",
    "calories": "317.1",
    "fat": "1.1",
    "cal-from-fat": "9.9",
    "per-cal-from-fat": "3",
    "saturated": "0.4",
    "polyunsaturated": "0.4",
    "monounsaturated": "0.3",
    "cholesterol": "0",
    "sodium": "893",
    "carbs": "69.9",
    "fibre": "0.6",
    "sugar": "9.7",
    "protein": "6.9",
    "cal-from-protein": "28",
    "per-cal-from-protein": "9",
    "potassium": "176",
    "calcium": "27",
    "iron": "0.8",
    "phosphorus": "120",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "384",
    "type": "snack",
    "|| name                                               ||": "Duku",
    "rating": "3",
    "fruit": "1",
    "serving": "5",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Langsat",
    "info-translation": "Langsat",
    "alternate-spellings": "Longsat: Longkong, Lanzones",
    "weight-g": "70",
    "calories": "15.6",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "5.4",
    "carbs": "3.7",
    "fibre": "0.8",
    "sugar": "3.7",
    "protein": "0.2",
    "cal-from-protein": "1",
    "per-cal-from-protein": "5",
    "potassium": "103.1",
    "calcium": "4.6",
    "iron": "0.5",
    "phosphorus": "8.8",
    "source": "N Buddy",
    "old units (ref)": "whole"
  },
  {
    "id": "919",
    "type": "meal",
    "|| name                                               ||": "Dumplings with minced meat filling, steamed",
    "rating": "2",
    "high-purine": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "31",
    "calories": "68.5",
    "fat": "3.7",
    "cal-from-fat": "33.3",
    "per-cal-from-fat": "49",
    "saturated": "1.2",
    "polyunsaturated": "0.7",
    "monounsaturated": "1.5",
    "cholesterol": "13",
    "sodium": "97",
    "carbs": "6.2",
    "fibre": "0.5",
    "sugar": "0.6",
    "protein": "2.6",
    "cal-from-protein": "10",
    "per-cal-from-protein": "15",
    "potassium": "45",
    "calcium": "6",
    "iron": "0.2",
    "phosphorus": "23",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "182",
    "type": "snack",
    "|| name                                               ||": "Durian",
    "rating": "2",
    "fruit": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "seed",
    "weight-g": "41",
    "calories": "66",
    "fat": "1.2",
    "cal-from-fat": "10.8",
    "per-cal-from-fat": "16",
    "saturated": "0.4",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.5",
    "cholesterol": "0",
    "sodium": "3",
    "carbs": "12.8",
    "fibre": "1.3",
    "sugar": "9.8",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "6",
    "potassium": "22",
    "calcium": "2",
    "iron": "0.3",
    "phosphorus": "18",
    "source": "N Buddy",
    "old units (ref)": "seed"
  },
  {
    "id": "1039",
    "type": "snack",
    "|| name                                               ||": "Durian cake",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "128",
    "calories": "346",
    "fat": "16",
    "cal-from-fat": "144",
    "per-cal-from-fat": "42",
    "saturated": "13.3",
    "polyunsaturated": "2.3",
    "monounsaturated": "3.7",
    "cholesterol": "58",
    "sodium": "154",
    "carbs": "45",
    "fibre": "2.3",
    "sugar": "35",
    "protein": "5.5",
    "cal-from-protein": "22",
    "per-cal-from-protein": "6",
    "potassium": "191",
    "calcium": "36",
    "iron": "1.2",
    "phosphorus": "119",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "1007",
    "type": "snack",
    "|| name                                               ||": "Durian mochi",
    "rating": "2",
    "high-gi": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "35",
    "calories": "164",
    "fat": "4",
    "cal-from-fat": "36",
    "per-cal-from-fat": "22",
    "saturated": "2",
    "cholesterol": "0",
    "sodium": "13",
    "carbs": "29",
    "fibre": "0",
    "sugar": "21",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "7",
    "old units (ref)": "piece"
  },
  {
    "id": "59",
    "type": "snack",
    "|| name                                               ||": "Durian puff",
    "rating": "2",
    "high-gi": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "31",
    "calories": "79.4",
    "fat": "5",
    "cal-from-fat": "45",
    "per-cal-from-fat": "57",
    "saturated": "3",
    "polyunsaturated": "0.3",
    "monounsaturated": "1.5",
    "cholesterol": "23.5",
    "sodium": "71",
    "carbs": "7.1",
    "fibre": "0.78",
    "sugar": "4",
    "protein": "1.5",
    "cal-from-protein": "6",
    "per-cal-from-protein": "8",
    "potassium": "43",
    "calcium": "15",
    "iron": "0.2",
    "phosphorus": "25",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "851",
    "type": "meal",
    "|| name                                               ||": "Ebi ikura don",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-purine": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "315",
    "calories": "384",
    "fat": "2.6",
    "cal-from-fat": "23.4",
    "per-cal-from-fat": "6",
    "saturated": "0.7",
    "polyunsaturated": "0.9",
    "monounsaturated": "0.7",
    "cholesterol": "229",
    "sodium": "305",
    "carbs": "59.8",
    "fibre": "0.5",
    "sugar": "0.2",
    "protein": "28.1",
    "cal-from-protein": "112",
    "per-cal-from-protein": "29",
    "potassium": "311",
    "calcium": "96",
    "iron": "4.6",
    "phosphorus": "182",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "64",
    "type": "snack",
    "|| name                                               ||": "Eclair",
    "rating": "2",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "info-translation": "a choux pastry filled with cream and topped with chocolate icing",
    "weight-g": "38",
    "calories": "196.7",
    "fat": "16.3",
    "cal-from-fat": "146.7",
    "per-cal-from-fat": "75",
    "saturated": "11.2",
    "polyunsaturated": "0.7",
    "monounsaturated": "3.7",
    "cholesterol": "54",
    "sodium": "111",
    "carbs": "10.1",
    "fibre": "0.6",
    "sugar": "5.4",
    "protein": "2.4",
    "cal-from-protein": "10",
    "per-cal-from-protein": "5",
    "potassium": "78",
    "calcium": "21",
    "iron": "0.7",
    "phosphorus": "49",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "106",
    "disabled": "1",
    "type": "meal",
    "|| name                                               ||": "Economic fried bee hoon / noodles",
    "rating": "1",
    "high-cholesterol": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "299",
    "calories": "452.81",
    "fat": "8.97",
    "cal-from-fat": "80.73",
    "per-cal-from-fat": "18",
    "saturated": "3.74",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "86.71",
    "sodium": "1118.26",
    "carbs": "86.74",
    "fibre": "5.08",
    "sugar": "0",
    "protein": "6.28",
    "cal-from-protein": "25",
    "per-cal-from-protein": "6",
    "potassium": "358",
    "calcium": "40",
    "iron": "2.3",
    "phosphorus": "210",
    "old units (ref)": "plate"
  },
  {
    "id": "353",
    "type": "meal",
    "|| name                                               ||": "Economical rice",
    "rating": "2",
    "fried": "1",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "1 deep fried meat and 2 vegetables",
    "info-include-exclude": "1 deep fried meat and 2 vegetables",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "500",
    "calories": "727",
    "fat": "31",
    "cal-from-fat": "279",
    "per-cal-from-fat": "38",
    "saturated": "13",
    "polyunsaturated": "5",
    "monounsaturated": "12",
    "cholesterol": "90",
    "sodium": "870",
    "carbs": "86",
    "fibre": "9",
    "sugar": "12.7",
    "protein": "26",
    "cal-from-protein": "104",
    "per-cal-from-protein": "14",
    "potassium": "647",
    "calcium": "77",
    "iron": "9.7",
    "phosphorus": "277",
    "old units (ref)": "plate"
  },
  {
    "id": "354",
    "type": "meal",
    "|| name                                               ||": "Economical rice",
    "rating": "2",
    "fried": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "1 deep fried meat and 1 vegetable",
    "info-include-exclude": "1 deep fried meat and 1 vegetable",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "400",
    "calories": "684",
    "fat": "28",
    "cal-from-fat": "252",
    "per-cal-from-fat": "37",
    "saturated": "11",
    "polyunsaturated": "4",
    "monounsaturated": "11",
    "cholesterol": "90",
    "sodium": "700",
    "carbs": "83",
    "fibre": "6.8",
    "sugar": "10.4",
    "protein": "25",
    "cal-from-protein": "100",
    "per-cal-from-protein": "15",
    "potassium": "443",
    "calcium": "48",
    "iron": "8.9",
    "phosphorus": "226",
    "old units (ref)": "plate"
  },
  {
    "id": "355",
    "type": "meal",
    "|| name                                               ||": "Economical rice",
    "rating": "2",
    "high-iodine": "1",
    "high-iron": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "1 non-fried meat and 1 vegetable",
    "info-include-exclude": "1 non-fried meat and 1 vegetable",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "400",
    "calories": "554",
    "fat": "18",
    "cal-from-fat": "162",
    "per-cal-from-fat": "29",
    "saturated": "5",
    "polyunsaturated": "4",
    "monounsaturated": "8",
    "cholesterol": "67",
    "sodium": "756",
    "carbs": "73",
    "fibre": "6",
    "sugar": "2.9",
    "protein": "25",
    "cal-from-protein": "100",
    "per-cal-from-protein": "18",
    "potassium": "400",
    "calcium": "50",
    "iron": "6.3",
    "phosphorus": "189",
    "old units (ref)": "plate"
  },
  {
    "id": "356",
    "type": "meal",
    "|| name                                               ||": "Economical rice",
    "rating": "2",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "2 vegetables",
    "info-include-exclude": "2 vegetables",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "400",
    "calories": "394.5",
    "fat": "6.5",
    "cal-from-fat": "58.5",
    "per-cal-from-fat": "15",
    "saturated": "2",
    "polyunsaturated": "2.5",
    "monounsaturated": "2",
    "cholesterol": "0",
    "sodium": "350",
    "carbs": "76",
    "fibre": "10",
    "sugar": "4.8",
    "protein": "8",
    "cal-from-protein": "32",
    "per-cal-from-protein": "8",
    "potassium": "428",
    "calcium": "58",
    "iron": "6.2",
    "phosphorus": "124",
    "old units (ref)": "plate"
  },
  {
    "id": "443",
    "type": "meal",
    "|| name                                               ||": "Economical rice",
    "rating": "3",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "1 non-fried meat and 2 vegetables",
    "info-include-exclude": "1 non-fried meat and 2 vegetables",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "500",
    "calories": "597",
    "fat": "21",
    "cal-from-fat": "189",
    "per-cal-from-fat": "32",
    "saturated": "6",
    "polyunsaturated": "5.5",
    "monounsaturated": "8",
    "cholesterol": "67",
    "sodium": "926",
    "carbs": "76",
    "fibre": "9",
    "sugar": "5.2",
    "protein": "26",
    "cal-from-protein": "104",
    "per-cal-from-protein": "17",
    "potassium": "604",
    "calcium": "79",
    "iron": "7.1",
    "phosphorus": "240",
    "old units (ref)": "plate"
  },
  {
    "id": "444",
    "type": "meal",
    "|| name                                               ||": "Economical rice",
    "rating": "2",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "3 vegetables",
    "info-include-exclude": "3 vegetables",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "500",
    "calories": "446",
    "fat": "10",
    "cal-from-fat": "90",
    "per-cal-from-fat": "20",
    "saturated": "2.5",
    "polyunsaturated": "3.1",
    "monounsaturated": "2.9",
    "cholesterol": "0",
    "sodium": "520",
    "carbs": "79",
    "fibre": "14",
    "sugar": "7.1",
    "protein": "10",
    "cal-from-protein": "40",
    "per-cal-from-protein": "9",
    "potassium": "632",
    "calcium": "87",
    "iron": "7",
    "phosphorus": "175",
    "old units (ref)": "plate"
  },
  {
    "id": "468",
    "type": "meal",
    "|| name                                               ||": "Economical rice",
    "rating": "3",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "2 non-fried meat and 2 vegetables",
    "info-include-exclude": "2 non-fried meat and 2 vegetables",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "600",
    "calories": "732",
    "fat": "28",
    "cal-from-fat": "252",
    "per-cal-from-fat": "34",
    "saturated": "10",
    "polyunsaturated": "5",
    "monounsaturated": "13",
    "cholesterol": "134",
    "sodium": "1502",
    "carbs": "76",
    "fibre": "10",
    "sugar": "5.6",
    "protein": "44",
    "cal-from-protein": "176",
    "per-cal-from-protein": "24",
    "potassium": "780",
    "calcium": "100",
    "iron": "8",
    "phosphorus": "356",
    "old units (ref)": "plate"
  },
  {
    "id": "469",
    "type": "meal",
    "|| name                                               ||": "Economical rice",
    "rating": "2",
    "fried": "1",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "1 deep fried, 1 non-fried meat and 2 vegetables",
    "info-include-exclude": "1 deep fried, 1 non-fried meat and 2 vegetables",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "600",
    "calories": "898",
    "fat": "42",
    "cal-from-fat": "378",
    "per-cal-from-fat": "42",
    "saturated": "16",
    "polyunsaturated": "7",
    "monounsaturated": "18",
    "cholesterol": "157",
    "sodium": "1446",
    "carbs": "86",
    "fibre": "9",
    "sugar": "13.1",
    "protein": "44",
    "cal-from-protein": "176",
    "per-cal-from-protein": "20",
    "potassium": "813",
    "calcium": "98",
    "iron": "10.6",
    "phosphorus": "393",
    "old units (ref)": "plate"
  },
  {
    "id": "470",
    "type": "meal",
    "|| name                                               ||": "Economical rice",
    "rating": "2",
    "fried": "1",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "1 non-fried meat, 2 fried meat and 3 vegetables",
    "info-include-exclude": "1 non-fried meat, 2 fried meat and 3 vegetables",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "800",
    "calories": "1206",
    "fat": "62",
    "cal-from-fat": "558",
    "per-cal-from-fat": "46",
    "saturated": "26",
    "polyunsaturated": "5",
    "monounsaturated": "30",
    "cholesterol": "247",
    "sodium": "2136",
    "carbs": "99",
    "fibre": "13.8",
    "sugar": "23.3",
    "protein": "63",
    "cal-from-protein": "252",
    "per-cal-from-protein": "21",
    "potassium": "1246",
    "calcium": "146",
    "iron": "14.9",
    "phosphorus": "597",
    "old units (ref)": "plate"
  },
  {
    "id": "471",
    "type": "meal",
    "|| name                                               ||": "Economical rice",
    "rating": "2",
    "fried": "1",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "2 non-fried meat, 1 fried meat and 3 vegetables",
    "info-include-exclude": "2 non-fried meat, 1 fried meat and 3 vegetables",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "800",
    "calories": "1022",
    "fat": "46",
    "cal-from-fat": "414",
    "per-cal-from-fat": "41",
    "saturated": "16",
    "polyunsaturated": "9",
    "monounsaturated": "20",
    "cholesterol": "157",
    "sodium": "1616",
    "carbs": "89",
    "fibre": "13",
    "sugar": "15.4",
    "protein": "63",
    "cal-from-protein": "252",
    "per-cal-from-protein": "25",
    "potassium": "872",
    "calcium": "127",
    "iron": "11.4",
    "phosphorus": "444",
    "old units (ref)": "plate"
  },
  {
    "id": "472",
    "type": "meal",
    "|| name                                               ||": "Economical rice",
    "rating": "1",
    "fried": "1",
    "high-fat": "1",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "3 fried meat and 3 vegetables",
    "info-include-exclude": "3 fried meat and 3 vegetables",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "965",
    "calories": "1390",
    "fat": "78",
    "cal-from-fat": "702",
    "per-cal-from-fat": "51",
    "saturated": "31",
    "polyunsaturated": "12",
    "monounsaturated": "34",
    "cholesterol": "270",
    "sodium": "2080",
    "carbs": "109",
    "fibre": "22.6",
    "sugar": "30.8",
    "protein": "63",
    "cal-from-protein": "252",
    "per-cal-from-protein": "18",
    "potassium": "1289",
    "calcium": "144",
    "iron": "17.5",
    "phosphorus": "634",
    "old units (ref)": "plate"
  },
  {
    "id": "473",
    "type": "meal",
    "|| name                                               ||": "Economical rice",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "3 non-fried meat and 3 vegetables",
    "info-include-exclude": "3 non-fried meat and 3 vegetables",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "800",
    "calories": "1108",
    "fat": "60",
    "cal-from-fat": "540",
    "per-cal-from-fat": "49",
    "saturated": "19",
    "polyunsaturated": "13",
    "monounsaturated": "28",
    "cholesterol": "268",
    "sodium": "2654",
    "carbs": "79",
    "fibre": "10",
    "sugar": "6.4",
    "protein": "63",
    "cal-from-protein": "252",
    "per-cal-from-protein": "23",
    "potassium": "1132",
    "calcium": "142",
    "iron": "9.8",
    "phosphorus": "588",
    "old units (ref)": "plate"
  },
  {
    "id": "951",
    "type": "meal",
    "|| name                                               ||": "Economical rice",
    "rating": "1",
    "fried": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "1 deep fried meat and 1 non-fried meat",
    "info-include-exclude": "1 deep fried meat and 1 non-fried meat",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "455",
    "calories": "821",
    "fat": "37",
    "cal-from-fat": "333",
    "per-cal-from-fat": "41",
    "saturated": "13",
    "polyunsaturated": "6",
    "monounsaturated": "17",
    "cholesterol": "157",
    "sodium": "1106",
    "carbs": "80",
    "fibre": "2.8",
    "sugar": "8.5",
    "protein": "42",
    "cal-from-protein": "168",
    "per-cal-from-protein": "20",
    "potassium": "415",
    "calcium": "40",
    "iron": "9",
    "phosphorus": "291",
    "old units (ref)": "plate"
  },
  {
    "id": "952",
    "type": "meal",
    "|| name                                               ||": "Economical rice",
    "rating": "1",
    "fried": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "2 deep fried meat",
    "info-include-exclude": "2 deep fried meat",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "555",
    "calories": "933",
    "fat": "45",
    "cal-from-fat": "405",
    "per-cal-from-fat": "43",
    "saturated": "18",
    "polyunsaturated": "6",
    "monounsaturated": "21",
    "cholesterol": "180",
    "sodium": "1050",
    "carbs": "90",
    "fibre": "7.5",
    "sugar": "16",
    "protein": "42",
    "cal-from-protein": "168",
    "per-cal-from-protein": "18",
    "potassium": "457",
    "calcium": "38",
    "iron": "11.3",
    "phosphorus": "327",
    "old units (ref)": "plate"
  },
  {
    "id": "953",
    "type": "meal",
    "|| name                                               ||": "Economical rice",
    "rating": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "2 non-fried meat",
    "info-include-exclude": "2 non-fried meat",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "400",
    "calories": "691",
    "fat": "27",
    "cal-from-fat": "243",
    "per-cal-from-fat": "35",
    "saturated": "9",
    "polyunsaturated": "5",
    "monounsaturated": "13",
    "cholesterol": "134",
    "sodium": "1162",
    "carbs": "70",
    "fibre": "2",
    "sugar": "1",
    "protein": "42",
    "cal-from-protein": "168",
    "per-cal-from-protein": "24",
    "potassium": "372",
    "calcium": "42",
    "iron": "6.4",
    "phosphorus": "254",
    "old units (ref)": "plate"
  },
  {
    "id": "954",
    "type": "meal",
    "|| name                                               ||": "Economical rice",
    "rating": "1",
    "fried": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "1 deep fried meat and 2 non-fried meat",
    "info-include-exclude": "1 deep fried meat and 2 non-fried meat",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "500",
    "calories": "1010",
    "fat": "50",
    "cal-from-fat": "450",
    "per-cal-from-fat": "45",
    "saturated": "18",
    "polyunsaturated": "8",
    "monounsaturated": "23",
    "cholesterol": "224",
    "sodium": "1682",
    "carbs": "80",
    "fibre": "2.8",
    "sugar": "8.9",
    "protein": "60",
    "cal-from-protein": "240",
    "per-cal-from-protein": "24",
    "potassium": "591",
    "calcium": "61",
    "iron": "9.9",
    "phosphorus": "407",
    "old units (ref)": "plate"
  },
  {
    "id": "955",
    "type": "meal",
    "|| name                                               ||": "Economical rice",
    "rating": "1",
    "fried": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "2 deep fried meat and 1 non-fried meat",
    "info-include-exclude": "2 deep fried meat and 1 non-fried meat",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "655",
    "calories": "1140",
    "fat": "60",
    "cal-from-fat": "540",
    "per-cal-from-fat": "47",
    "saturated": "23",
    "polyunsaturated": "9",
    "monounsaturated": "27",
    "cholesterol": "247",
    "sodium": "1626",
    "carbs": "90",
    "fibre": "7.5",
    "sugar": "16.4",
    "protein": "60",
    "cal-from-protein": "240",
    "per-cal-from-protein": "21",
    "potassium": "633",
    "calcium": "59",
    "iron": "12.5",
    "phosphorus": "425",
    "old units (ref)": "plate"
  },
  {
    "id": "956",
    "type": "meal",
    "|| name                                               ||": "Economical rice",
    "rating": "1",
    "fried": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "3 deep fried meat",
    "info-include-exclude": "3 deep fried meat",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "765",
    "calories": "1243",
    "fat": "67",
    "cal-from-fat": "603",
    "per-cal-from-fat": "49",
    "saturated": "26",
    "polyunsaturated": "8",
    "monounsaturated": "31",
    "cholesterol": "270",
    "sodium": "1570",
    "carbs": "100",
    "fibre": "12.3",
    "sugar": "23.9",
    "protein": "60",
    "cal-from-protein": "240",
    "per-cal-from-protein": "19",
    "potassium": "666",
    "calcium": "57",
    "iron": "14.8",
    "phosphorus": "480",
    "old units (ref)": "plate"
  },
  {
    "id": "957",
    "type": "meal",
    "|| name                                               ||": "Economical rice",
    "rating": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "3 non-fried meat",
    "info-include-exclude": "3 non-fried meat",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "500",
    "calories": "628",
    "fat": "12",
    "cal-from-fat": "108",
    "per-cal-from-fat": "17",
    "saturated": "13",
    "polyunsaturated": "8",
    "monounsaturated": "20",
    "cholesterol": "201",
    "sodium": "1738",
    "carbs": "70",
    "fibre": "2",
    "sugar": "1.4",
    "protein": "60",
    "cal-from-protein": "240",
    "per-cal-from-protein": "38",
    "potassium": "538",
    "calcium": "63",
    "iron": "7.3",
    "phosphorus": "370",
    "old units (ref)": "plate"
  },
  {
    "id": "931",
    "type": "meal",
    "|| name                                               ||": "Economical rice, less rice",
    "rating": "2",
    "fried": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "1 deep fried meat and 1 vegetable",
    "info-include-exclude": "1 deep fried meat and 1 vegetable",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "300",
    "calories": "562",
    "fat": "26",
    "cal-from-fat": "234",
    "per-cal-from-fat": "42",
    "saturated": "10",
    "polyunsaturated": "4",
    "monounsaturated": "11",
    "cholesterol": "90",
    "sodium": "695",
    "carbs": "59",
    "fibre": "5.8",
    "sugar": "10.3",
    "protein": "23",
    "cal-from-protein": "92",
    "per-cal-from-protein": "16",
    "potassium": "433",
    "calcium": "48",
    "iron": "6.6",
    "phosphorus": "215",
    "old units (ref)": "plate"
  },
  {
    "id": "932",
    "type": "meal",
    "|| name                                               ||": "Economical rice, less rice",
    "rating": "2",
    "fried": "1",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "1 deep fried meat and 2 vegetables",
    "info-include-exclude": "1 deep fried meat and 2 vegetables",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "400",
    "calories": "605",
    "fat": "29",
    "cal-from-fat": "261",
    "per-cal-from-fat": "43",
    "saturated": "11",
    "polyunsaturated": "5",
    "monounsaturated": "12",
    "cholesterol": "90",
    "sodium": "865",
    "carbs": "62",
    "fibre": "9.8",
    "sugar": "12.6",
    "protein": "24",
    "cal-from-protein": "96",
    "per-cal-from-protein": "16",
    "potassium": "637",
    "calcium": "77",
    "iron": "7.4",
    "phosphorus": "266",
    "old units (ref)": "plate"
  },
  {
    "id": "933",
    "type": "meal",
    "|| name                                               ||": "Economical rice, less rice",
    "rating": "2",
    "fried": "1",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "1 deep fried, 1 non-fried meat and 2 vegetables",
    "info-include-exclude": "1 deep fried, 1 non-fried meat and 2 vegetables",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "500",
    "calories": "785",
    "fat": "41",
    "cal-from-fat": "369",
    "per-cal-from-fat": "47",
    "saturated": "15",
    "polyunsaturated": "7",
    "monounsaturated": "18",
    "cholesterol": "157",
    "sodium": "1441",
    "carbs": "62",
    "fibre": "9.8",
    "sugar": "13",
    "protein": "42",
    "cal-from-protein": "168",
    "per-cal-from-protein": "21",
    "potassium": "813",
    "calcium": "98",
    "iron": "8.3",
    "phosphorus": "382",
    "old units (ref)": "plate"
  },
  {
    "id": "934",
    "type": "meal",
    "|| name                                               ||": "Economical rice, less rice",
    "rating": "2",
    "high-iodine": "1",
    "high-iron": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "1 non-fried meat and 1 vegetable",
    "info-include-exclude": "1 non-fried meat and 1 vegetable",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "300",
    "calories": "441",
    "fat": "17",
    "cal-from-fat": "153",
    "per-cal-from-fat": "35",
    "saturated": "5",
    "polyunsaturated": "4",
    "monounsaturated": "7",
    "cholesterol": "67",
    "sodium": "751",
    "carbs": "49",
    "fibre": "5",
    "sugar": "2.8",
    "protein": "23",
    "cal-from-protein": "92",
    "per-cal-from-protein": "21",
    "potassium": "390",
    "calcium": "50",
    "iron": "4",
    "phosphorus": "178",
    "old units (ref)": "plate"
  },
  {
    "id": "935",
    "type": "meal",
    "|| name                                               ||": "Economical rice, less rice",
    "rating": "3",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "1 non-fried meat and 2 vegetables",
    "info-include-exclude": "1 non-fried meat and 2 vegetables",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "400",
    "calories": "484",
    "fat": "20",
    "cal-from-fat": "180",
    "per-cal-from-fat": "37",
    "saturated": "6",
    "polyunsaturated": "5",
    "monounsaturated": "8",
    "cholesterol": "67",
    "sodium": "921",
    "carbs": "52",
    "fibre": "9",
    "sugar": "5.1",
    "protein": "24",
    "cal-from-protein": "96",
    "per-cal-from-protein": "20",
    "potassium": "604",
    "calcium": "79",
    "iron": "4.8",
    "phosphorus": "229",
    "old units (ref)": "plate"
  },
  {
    "id": "936",
    "type": "meal",
    "|| name                                               ||": "Economical rice, less rice",
    "rating": "2",
    "fried": "1",
    "high-fat": "1",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "1 non-fried meat, 2 deep fried meat and 3 vegetables",
    "info-include-exclude": "1 non-fried meat, 2 deep fried meat and 3 vegetables",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "865",
    "calories": "1156",
    "fat": "68",
    "cal-from-fat": "612",
    "per-cal-from-fat": "53",
    "saturated": "26",
    "polyunsaturated": "11",
    "monounsaturated": "30",
    "cholesterol": "211",
    "sodium": "2129",
    "carbs": "75",
    "fibre": "24.6",
    "sugar": "23",
    "protein": "61",
    "cal-from-protein": "244",
    "per-cal-from-protein": "21",
    "potassium": "1296",
    "calcium": "152",
    "iron": "10.7",
    "phosphorus": "682",
    "old units (ref)": "plate"
  },
  {
    "id": "937",
    "type": "meal",
    "|| name                                               ||": "Economical rice, less rice",
    "rating": "1",
    "fried": "1",
    "high-fat": "1",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "2 deep fried meat and 2 vegetables",
    "info-include-exclude": "2 deep fried meat and 2 vegetables",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "555",
    "calories": "924",
    "fat": "52",
    "cal-from-fat": "468",
    "per-cal-from-fat": "51",
    "saturated": "21",
    "polyunsaturated": "8",
    "monounsaturated": "22",
    "cholesterol": "180",
    "sodium": "1385",
    "carbs": "72",
    "fibre": "14.6",
    "sugar": "20.5",
    "protein": "42",
    "cal-from-protein": "168",
    "per-cal-from-protein": "18",
    "potassium": "856",
    "calcium": "96",
    "iron": "10.9",
    "phosphorus": "419",
    "old units (ref)": "plate"
  },
  {
    "id": "938",
    "type": "meal",
    "|| name                                               ||": "Economical rice, less rice",
    "rating": "3",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "2 non-fried meat and 2 vegetables",
    "info-include-exclude": "2 non-fried meat and 2 vegetables",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "500",
    "calories": "673",
    "fat": "33",
    "cal-from-fat": "297",
    "per-cal-from-fat": "44",
    "saturated": "10",
    "polyunsaturated": "7",
    "monounsaturated": "15",
    "cholesterol": "134",
    "sodium": "1497",
    "carbs": "52",
    "fibre": "9",
    "sugar": "5.5",
    "protein": "42",
    "cal-from-protein": "168",
    "per-cal-from-protein": "25",
    "potassium": "770",
    "calcium": "100",
    "iron": "5.7",
    "phosphorus": "770",
    "old units (ref)": "plate"
  },
  {
    "id": "939",
    "type": "meal",
    "|| name                                               ||": "Economical rice, less rice",
    "rating": "2",
    "fried": "1",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "2 non-fried meat, 1 deep fried meat and 3 vegetables",
    "info-include-exclude": "2 non-fried meat, 1 deep fried meat and 3 vegetables",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "700",
    "calories": "1026",
    "fat": "58",
    "cal-from-fat": "522",
    "per-cal-from-fat": "51",
    "saturated": "20",
    "polyunsaturated": "11",
    "monounsaturated": "26",
    "cholesterol": "224",
    "sodium": "2187",
    "carbs": "65",
    "fibre": "13.8",
    "sugar": "15.7",
    "protein": "61",
    "cal-from-protein": "244",
    "per-cal-from-protein": "24",
    "potassium": "1183",
    "calcium": "148",
    "iron": "10",
    "phosphorus": "549",
    "old units (ref)": "plate"
  },
  {
    "id": "940",
    "type": "meal",
    "|| name                                               ||": "Economical rice, less rice",
    "rating": "2",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "2 vegetables",
    "info-include-exclude": "2 vegetables",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "300",
    "calories": "295",
    "fat": "7",
    "cal-from-fat": "63",
    "per-cal-from-fat": "21",
    "saturated": "2",
    "polyunsaturated": "2",
    "monounsaturated": "2",
    "cholesterol": "0",
    "sodium": "345",
    "carbs": "52",
    "fibre": "9",
    "sugar": "4.7",
    "protein": "6",
    "cal-from-protein": "24",
    "per-cal-from-protein": "8",
    "potassium": "428",
    "calcium": "58",
    "iron": "3.9",
    "phosphorus": "113",
    "old units (ref)": "plate"
  },
  {
    "id": "941",
    "type": "meal",
    "|| name                                               ||": "Economical rice, less rice",
    "rating": "1",
    "fried": "1",
    "high-fat": "1",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "3 deep fried meat and 3 vegetables",
    "info-include-exclude": "3 deep fried meat and 3 vegetables",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "810",
    "calories": "1259",
    "fat": "75",
    "cal-from-fat": "675",
    "per-cal-from-fat": "54",
    "saturated": "30",
    "polyunsaturated": "11",
    "monounsaturated": "33",
    "cholesterol": "270",
    "sodium": "2075",
    "carbs": "85",
    "fibre": "19.4",
    "sugar": "30.7",
    "protein": "61",
    "cal-from-protein": "244",
    "per-cal-from-protein": "19",
    "potassium": "1279",
    "calcium": "144",
    "iron": "15.2",
    "phosphorus": "623",
    "old units (ref)": "plate"
  },
  {
    "id": "942",
    "type": "meal",
    "|| name                                               ||": "Economical rice, less rice",
    "rating": "2",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "3 non-fried meat and 3 vegetables",
    "info-include-exclude": "3 non-fried meat and 3 vegetables",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "700",
    "calories": "896",
    "fat": "48",
    "cal-from-fat": "432",
    "per-cal-from-fat": "48",
    "saturated": "15",
    "polyunsaturated": "10",
    "monounsaturated": "22",
    "cholesterol": "201",
    "sodium": "2243",
    "carbs": "55",
    "fibre": "13",
    "sugar": "8.2",
    "protein": "61",
    "cal-from-protein": "244",
    "per-cal-from-protein": "27",
    "potassium": "1140",
    "calcium": "150",
    "iron": "7.4",
    "phosphorus": "512",
    "old units (ref)": "plate"
  },
  {
    "id": "943",
    "type": "meal",
    "|| name                                               ||": "Economical rice, less rice",
    "rating": "2",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "3 vegetables",
    "info-include-exclude": "3 vegetables",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "400",
    "calories": "329",
    "fat": "9",
    "cal-from-fat": "81",
    "per-cal-from-fat": "25",
    "saturated": "2",
    "polyunsaturated": "3",
    "monounsaturated": "3",
    "cholesterol": "0",
    "sodium": "515",
    "carbs": "55",
    "fibre": "13",
    "sugar": "7",
    "protein": "7",
    "cal-from-protein": "28",
    "per-cal-from-protein": "9",
    "potassium": "632",
    "calcium": "87",
    "iron": "4.7",
    "phosphorus": "164",
    "old units (ref)": "plate"
  },
  {
    "id": "944",
    "type": "meal",
    "|| name                                               ||": "Economical rice, less rice",
    "rating": "2",
    "fried": "1",
    "high-iron": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "1 deep fried meat and 1 non-fried meat",
    "info-include-exclude": "1 deep fried meat and 1 non-fried meat",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "355",
    "calories": "726",
    "fat": "38",
    "cal-from-fat": "342",
    "per-cal-from-fat": "47",
    "saturated": "14",
    "polyunsaturated": "6",
    "monounsaturated": "17",
    "cholesterol": "157",
    "sodium": "1101",
    "carbs": "56",
    "fibre": "1.8",
    "sugar": "8.4",
    "protein": "40",
    "cal-from-protein": "160",
    "per-cal-from-protein": "22",
    "potassium": "405",
    "calcium": "40",
    "iron": "6.7",
    "phosphorus": "280",
    "old units (ref)": "plate"
  },
  {
    "id": "945",
    "type": "meal",
    "|| name                                               ||": "Economical rice, less rice",
    "rating": "1",
    "fried": "1",
    "high-iron": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "2 deep fried meat",
    "info-include-exclude": "2 deep fried meat",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "465",
    "calories": "838",
    "fat": "46",
    "cal-from-fat": "414",
    "per-cal-from-fat": "49",
    "saturated": "19",
    "polyunsaturated": "6",
    "monounsaturated": "20",
    "cholesterol": "180",
    "sodium": "1045",
    "carbs": "66",
    "fibre": "6.6",
    "sugar": "15.9",
    "protein": "40",
    "cal-from-protein": "160",
    "per-cal-from-protein": "19",
    "potassium": "448",
    "calcium": "38",
    "iron": "9.3",
    "phosphorus": "317",
    "old units (ref)": "plate"
  },
  {
    "id": "946",
    "type": "meal",
    "|| name                                               ||": "Economical rice, less rice",
    "rating": "1",
    "high-iron": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "2 non-fried meat",
    "info-include-exclude": "2 non-fried meat",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "300",
    "calories": "605",
    "fat": "29",
    "cal-from-fat": "261",
    "per-cal-from-fat": "43",
    "saturated": "9",
    "polyunsaturated": "5",
    "monounsaturated": "13",
    "cholesterol": "134",
    "sodium": "1157",
    "carbs": "46",
    "fibre": "1",
    "sugar": "0.9",
    "protein": "40",
    "cal-from-protein": "160",
    "per-cal-from-protein": "26",
    "potassium": "362",
    "calcium": "42",
    "iron": "4.1",
    "phosphorus": "243",
    "old units (ref)": "plate"
  },
  {
    "id": "947",
    "type": "meal",
    "|| name                                               ||": "Economical rice, less rice",
    "rating": "1",
    "fried": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "1 deep fried meat and 2 non-fried meat",
    "info-include-exclude": "1 deep fried meat and 2 non-fried meat",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "510",
    "calories": "924",
    "fat": "52",
    "cal-from-fat": "468",
    "per-cal-from-fat": "51",
    "saturated": "21",
    "polyunsaturated": "8",
    "monounsaturated": "24",
    "cholesterol": "228",
    "sodium": "1677",
    "carbs": "56",
    "fibre": "6.4",
    "sugar": "15.5",
    "protein": "58",
    "cal-from-protein": "232",
    "per-cal-from-protein": "25",
    "potassium": "499",
    "calcium": "54",
    "iron": "7.6",
    "phosphorus": "398",
    "old units (ref)": "plate"
  },
  {
    "id": "948",
    "type": "meal",
    "|| name                                               ||": "Economical rice, less rice",
    "rating": "1",
    "fried": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "2 deep fried meat and 1 non-fried meat",
    "info-include-exclude": "2 deep fried meat and 1 non-fried meat",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "565",
    "calories": "1027",
    "fat": "59",
    "cal-from-fat": "531",
    "per-cal-from-fat": "52",
    "saturated": "23",
    "polyunsaturated": "8",
    "monounsaturated": "27",
    "cholesterol": "247",
    "sodium": "1621",
    "carbs": "66",
    "fibre": "6.6",
    "sugar": "16.3",
    "protein": "58",
    "cal-from-protein": "232",
    "per-cal-from-protein": "23",
    "potassium": "624",
    "calcium": "59",
    "iron": "10.2",
    "phosphorus": "415",
    "old units (ref)": "plate"
  },
  {
    "id": "949",
    "type": "meal",
    "|| name                                               ||": "Economical rice, less rice",
    "rating": "1",
    "fried": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "3 deep fried meat",
    "info-include-exclude": "3 deep fried meat",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "675",
    "calories": "1121",
    "fat": "65",
    "cal-from-fat": "585",
    "per-cal-from-fat": "52",
    "saturated": "26",
    "polyunsaturated": "8",
    "monounsaturated": "30",
    "cholesterol": "270",
    "sodium": "1565",
    "carbs": "76",
    "fibre": "11.4",
    "sugar": "23.8",
    "protein": "58",
    "cal-from-protein": "232",
    "per-cal-from-protein": "21",
    "potassium": "667",
    "calcium": "57",
    "iron": "12.8",
    "phosphorus": "470",
    "old units (ref)": "plate"
  },
  {
    "id": "950",
    "type": "meal",
    "|| name                                               ||": "Economical rice, less rice",
    "rating": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "3 non-fried meat",
    "info-include-exclude": "3 non-fried meat",
    "alternate-spellings": "Economical: Economy",
    "weight-g": "400",
    "calories": "794",
    "fat": "42",
    "cal-from-fat": "378",
    "per-cal-from-fat": "48",
    "saturated": "13",
    "polyunsaturated": "8",
    "monounsaturated": "20",
    "cholesterol": "201",
    "sodium": "1733",
    "carbs": "46",
    "fibre": "1",
    "sugar": "1.3",
    "protein": "58",
    "cal-from-protein": "232",
    "per-cal-from-protein": "29",
    "potassium": "528",
    "calcium": "63",
    "iron": "5",
    "phosphorus": "359",
    "old units (ref)": "plate"
  },
  {
    "id": "413",
    "type": "snack",
    "|| name                                               ||": "Edamame",
    "rating": "3",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "plant-protein": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "handful",
    "weight-g": "100",
    "calories": "148",
    "fat": "4.7",
    "cal-from-fat": "42.3",
    "per-cal-from-fat": "29",
    "saturated": "0.4",
    "polyunsaturated": "5.1",
    "monounsaturated": "2",
    "cholesterol": "0",
    "sodium": "343",
    "carbs": "9.5",
    "fibre": "7",
    "sugar": "0",
    "protein": "16.9",
    "cal-from-protein": "68",
    "per-cal-from-protein": "46",
    "source": "N Buddy",
    "old units (ref)": "handful"
  },
  {
    "id": "481",
    "type": "meal",
    "|| name                                               ||": "Ee fu noodles, stir fried",
    "rating": "2",
    "high-gi": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "alternate-spellings": "Ee fu: E Fu",
    "weight-g": "418",
    "calories": "605.3",
    "fat": "31.3",
    "cal-from-fat": "281.7",
    "per-cal-from-fat": "47",
    "saturated": "11.6",
    "polyunsaturated": "8.7",
    "monounsaturated": "11",
    "cholesterol": "35",
    "sodium": "2218",
    "carbs": "55.4",
    "fibre": "3.5",
    "sugar": "4.5",
    "protein": "25.5",
    "cal-from-protein": "102",
    "per-cal-from-protein": "17",
    "potassium": "255",
    "calcium": "57",
    "iron": "4.5",
    "phosphorus": "213",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "1078",
    "type": "snack",
    "|| name                                               ||": "Egg mayo sandwich",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "refined-grain": "1",
    "serving": "2",
    "|| unit ||": "slice",
    "alternate-spellings": "Mayo: Mayonnaise",
    "weight-g": "125",
    "calories": "264",
    "fat": "9.1",
    "cal-from-fat": "81.9",
    "per-cal-from-fat": "31",
    "saturated": "2.2",
    "polyunsaturated": "2.8",
    "monounsaturated": "3.2",
    "cholesterol": "211",
    "sodium": "345",
    "carbs": "33.4",
    "fibre": "1.6",
    "sugar": "2.9",
    "protein": "12.3",
    "cal-from-protein": "49",
    "per-cal-from-protein": "19",
    "potassium": "88",
    "calcium": "46",
    "iron": "2.7",
    "phosphorus": "119",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "1002",
    "type": "snack",
    "|| name                                               ||": "Egg pudding with ginger",
    "rating": "2",
    "high-calcium": "1",
    "high-cholesterol": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "125",
    "calories": "203",
    "fat": "7",
    "cal-from-fat": "63",
    "per-cal-from-fat": "31",
    "saturated": "4",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "200",
    "sodium": "78",
    "carbs": "23",
    "fibre": "0",
    "sugar": "14",
    "protein": "12",
    "cal-from-protein": "48",
    "per-cal-from-protein": "24",
    "potassium": "138",
    "calcium": "429",
    "iron": "2.16",
    "old units (ref)": "bowl"
  },
  {
    "id": "196",
    "type": "snack",
    "|| name                                               ||": "Egg tart, small",
    "rating": "2",
    "high-cholesterol": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Dan Tat",
    "info-translation": "Dan Tat",
    "weight-g": "64",
    "calories": "178.1",
    "fat": "9.7",
    "cal-from-fat": "87.3",
    "per-cal-from-fat": "49",
    "saturated": "5.5",
    "polyunsaturated": "0.6",
    "monounsaturated": "3.2",
    "cholesterol": "54",
    "sodium": "75",
    "carbs": "18.4",
    "fibre": "0.7",
    "sugar": "7.4",
    "protein": "4.3",
    "cal-from-protein": "17",
    "per-cal-from-protein": "10",
    "potassium": "76",
    "calcium": "35",
    "iron": "0.7",
    "phosphorus": "70",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "266",
    "type": "meal",
    "|| name                                               ||": "Egg, fried",
    "rating": "3",
    "fried": "1",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. sunny side up / bullseye",
    "info-examples": "sunny side up / bullseye",
    "weight-g": "46",
    "calories": "84.4",
    "fat": "6.4",
    "cal-from-fat": "57.6",
    "per-cal-from-fat": "68",
    "saturated": "2",
    "polyunsaturated": "0.7",
    "monounsaturated": "2.8",
    "cholesterol": "198",
    "sodium": "150",
    "carbs": "0.1",
    "fibre": "0",
    "sugar": "0.1",
    "protein": "6.6",
    "cal-from-protein": "26",
    "per-cal-from-protein": "31",
    "potassium": "71",
    "calcium": "29",
    "iron": "1.2",
    "phosphorus": "92",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "780",
    "type": "meal",
    "|| name                                               ||": "Egg, fried with bitter gourd / french bean",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "200",
    "calories": "341",
    "fat": "30.6",
    "cal-from-fat": "275.4",
    "per-cal-from-fat": "81",
    "saturated": "13.8",
    "polyunsaturated": "3.6",
    "monounsaturated": "11.8",
    "cholesterol": "292",
    "sodium": "1262",
    "carbs": "6.8",
    "fibre": "4.6",
    "sugar": "0",
    "protein": "9.6",
    "cal-from-protein": "38",
    "per-cal-from-protein": "11",
    "calcium": "54",
    "iron": "2.4",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "779",
    "type": "meal",
    "|| name                                               ||": "Egg, fried with cai pu / preserved radish",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "alternate-spellings": "Cai: Chai\nPu: Poh",
    "weight-g": "120",
    "calories": "251.2",
    "fat": "20",
    "cal-from-fat": "180",
    "per-cal-from-fat": "72",
    "saturated": "4",
    "polyunsaturated": "1.4",
    "monounsaturated": "5.6",
    "cholesterol": "396",
    "sodium": "906",
    "carbs": "4.2",
    "fibre": "0.2",
    "sugar": "4.2",
    "protein": "13.6",
    "cal-from-protein": "54",
    "per-cal-from-protein": "22",
    "potassium": "210",
    "calcium": "58",
    "iron": "3.2",
    "phosphorus": "206",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "775",
    "type": "meal",
    "|| name                                               ||": "Egg, fried fu rong",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "alternate-spellings": "Fu: Foo\nRong: Yong",
    "weight-g": "160",
    "calories": "413.6",
    "fat": "36",
    "cal-from-fat": "324",
    "per-cal-from-fat": "78",
    "saturated": "17.6",
    "polyunsaturated": "3.6",
    "monounsaturated": "13.2",
    "cholesterol": "530",
    "sodium": "592",
    "carbs": "3.6",
    "fibre": "1",
    "sugar": "0",
    "protein": "18.8",
    "cal-from-protein": "75",
    "per-cal-from-protein": "18",
    "calcium": "76",
    "iron": "3",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "776",
    "type": "meal",
    "|| name                                               ||": "Egg, fried with minced meat",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "200",
    "calories": "479.6",
    "fat": "40.4",
    "cal-from-fat": "363.6",
    "per-cal-from-fat": "76",
    "saturated": "12.2",
    "polyunsaturated": "8.6",
    "monounsaturated": "15.6",
    "cholesterol": "428",
    "sodium": "1000",
    "carbs": "0.2",
    "fibre": "0",
    "sugar": "0.2",
    "protein": "28.8",
    "cal-from-protein": "115",
    "per-cal-from-protein": "24",
    "potassium": "414",
    "calcium": "66",
    "iron": "3.2",
    "phosphorus": "340",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "778",
    "type": "meal",
    "|| name                                               ||": "Egg, fried with onion",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "200",
    "calories": "297.1",
    "fat": "23.5",
    "cal-from-fat": "211.5",
    "per-cal-from-fat": "71",
    "saturated": "8.1",
    "polyunsaturated": "2.7",
    "monounsaturated": "8.6",
    "cholesterol": "130",
    "sodium": "496",
    "carbs": "6.8",
    "fibre": "2.4",
    "sugar": "3.1",
    "protein": "14.6",
    "cal-from-protein": "58",
    "per-cal-from-protein": "20",
    "potassium": "167",
    "calcium": "57",
    "iron": "1.2",
    "phosphorus": "202",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "419",
    "type": "meal",
    "|| name                                               ||": "Egg, hard boiled",
    "rating": "3",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "serving": "1",
    "|| unit ||": "egg",
    "weight-g": "50",
    "calories": "75.1",
    "fat": "5.3",
    "cal-from-fat": "47.7",
    "per-cal-from-fat": "64",
    "saturated": "1.63",
    "polyunsaturated": "0.707",
    "monounsaturated": "2.04",
    "cholesterol": "187",
    "sodium": "62",
    "carbs": "0.56",
    "fibre": "0",
    "sugar": "0.56",
    "protein": "6.29",
    "cal-from-protein": "25",
    "per-cal-from-protein": "34",
    "potassium": "63",
    "calcium": "25",
    "iron": "0.595",
    "phosphorus": "86",
    "iodine": "1",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "550",
    "type": "meal",
    "|| name                                               ||": "Egg, scrambled",
    "rating": "3",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "serving": "1",
    "|| unit ||": "egg",
    "|| info                                                             ||": "no toppings",
    "weight-g": "80",
    "calories": "135",
    "fat": "10.8",
    "cal-from-fat": "97.2",
    "per-cal-from-fat": "72",
    "saturated": "4.8",
    "polyunsaturated": "2",
    "monounsaturated": "4",
    "cholesterol": "251",
    "sodium": "113",
    "carbs": "1",
    "fibre": "0",
    "sugar": "1",
    "protein": "8.3",
    "cal-from-protein": "33",
    "per-cal-from-protein": "25",
    "potassium": "102",
    "calcium": "59",
    "iron": "1.2",
    "phosphorus": "130",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "726",
    "type": "meal",
    "|| name                                               ||": "Egg, soft boiled / steamed / half boiled",
    "rating": "3",
    "healthy-fats": "1",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "serving": "1",
    "|| unit ||": "egg",
    "|| info                                                             ||": "e.g. onsen egg",
    "weight-g": "55",
    "calories": "74.1",
    "fat": "5.3",
    "cal-from-fat": "47.7",
    "per-cal-from-fat": "64",
    "saturated": "1.6",
    "polyunsaturated": "0.5",
    "monounsaturated": "2.2",
    "cholesterol": "208",
    "sodium": "60",
    "carbs": "0.2",
    "fibre": "0",
    "sugar": "0.2",
    "protein": "6.4",
    "cal-from-protein": "26",
    "per-cal-from-protein": "35",
    "potassium": "52",
    "calcium": "21",
    "iron": "0.9",
    "phosphorus": "70",
    "source": "N Buddy",
    "old units (ref)": "whole"
  },
  {
    "id": "958",
    "type": "meal",
    "|| name                                               ||": "Egg white",
    "rating": "3",
    "high-iodine": "1",
    "serving": "1",
    "|| unit ||": "egg",
    "|| info                                                             ||": "e.g. egg white omelette",
    "info-include-exclude": "without yolk",
    "weight-g": "33",
    "calories": "35.668",
    "fat": "2.16",
    "cal-from-fat": "19.44",
    "per-cal-from-fat": "55",
    "saturated": "0.601",
    "polyunsaturated": "0.653",
    "monounsaturated": "0.758",
    "cholesterol": "1.65",
    "sodium": "167",
    "carbs": "0.257",
    "fibre": "0",
    "sugar": "0.248",
    "protein": "3.8",
    "cal-from-protein": "15",
    "per-cal-from-protein": "43",
    "potassium": "57.1",
    "calcium": "2.64",
    "iron": "0.03",
    "phosphorus": "5.61",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "863",
    "type": "meal",
    "|| name                                               ||": "Eggs benedict",
    "rating": "2",
    "high-calcium": "1",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "|| info                                                             ||": "English muffin topped with a poached egg and hollandaise sauce",
    "weight-g": "291",
    "calories": "669",
    "fat": "49.4",
    "cal-from-fat": "444.6",
    "per-cal-from-fat": "66",
    "saturated": "23.7",
    "polyunsaturated": "6.1",
    "monounsaturated": "19.4",
    "trans": "0.2",
    "sodium": "1016",
    "carbs": "24.4",
    "fibre": "4.1",
    "sugar": "4.7",
    "protein": "31.7",
    "cal-from-protein": "127",
    "per-cal-from-protein": "19",
    "calcium": "249",
    "iron": "3.4",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "267",
    "type": "meal",
    "|| name                                               ||": "Enchiladas with beef / pork",
    "rating": "2",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "beef / pork",
    "weight-g": "114",
    "calories": "192",
    "fat": "10.4",
    "cal-from-fat": "93.6",
    "per-cal-from-fat": "49",
    "saturated": "3.4",
    "polyunsaturated": "2.2",
    "monounsaturated": "3.9",
    "cholesterol": "32",
    "sodium": "237",
    "carbs": "14.1",
    "fibre": "2.3",
    "sugar": "2.4",
    "protein": "11.2",
    "cal-from-protein": "45",
    "per-cal-from-protein": "23",
    "potassium": "241",
    "calcium": "91",
    "iron": "1.5",
    "phosphorus": "179",
    "source": "USDA",
    "old units (ref)": "enchilada"
  },
  {
    "id": "351",
    "type": "meal",
    "|| name                                               ||": "Enchiladas with chicken",
    "rating": "2",
    "high-fat": "1",
    "high-fibre": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "chicken",
    "weight-g": "114",
    "calories": "171",
    "fat": "8.7",
    "cal-from-fat": "78.3",
    "per-cal-from-fat": "46",
    "saturated": "2.6",
    "polyunsaturated": "2.3",
    "monounsaturated": "3.2",
    "cholesterol": "34",
    "sodium": "226",
    "carbs": "14.1",
    "fibre": "2.3",
    "sugar": "2.4",
    "protein": "10.3",
    "cal-from-protein": "41",
    "per-cal-from-protein": "24",
    "potassium": "245",
    "calcium": "91",
    "iron": "0.8",
    "phosphorus": "180",
    "source": "USDA",
    "old units (ref)": "enchilada"
  },
  {
    "id": "629",
    "type": "meal",
    "|| name                                               ||": "Endives, stir-fried",
    "rating": "3",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "100",
    "calories": "61",
    "fat": "5.2",
    "cal-from-fat": "46.8",
    "per-cal-from-fat": "77",
    "saturated": "0.8",
    "polyunsaturated": "3.1",
    "monounsaturated": "1.1",
    "cholesterol": "0",
    "sodium": "22",
    "carbs": "0.3",
    "fibre": "3.1",
    "sugar": "0",
    "protein": "1.3",
    "cal-from-protein": "5",
    "per-cal-from-protein": "9",
    "potassium": "314",
    "calcium": "52",
    "iron": "0.8",
    "phosphorus": "28",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "618",
    "type": "drink",
    "|| name                                               ||": "Energy drink",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "can",
    "|| info                                                             ||": "e.g. Red Bull, Monster Energy",
    "info-brands": "Red Bull, Monster",
    "volume-ml": "250",
    "calories": "110",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "carbs": "27.5",
    "fibre": "0",
    "sugar": "27.5",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "source": "N Buddy",
    "old units (ref)": "can"
  },
  {
    "id": "648",
    "type": "meal",
    "|| name                                               ||": "Enoki mushroom",
    "rating": "3",
    "high-potassium": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "100",
    "calories": "40",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "8",
    "fibre": "3",
    "sugar": "2",
    "protein": "2",
    "cal-from-protein": "8",
    "per-cal-from-protein": "20",
    "potassium": "410",
    "calcium": "10",
    "iron": "1.1",
    "phosphorus": "100",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "268",
    "type": "meal",
    "|| name                                               ||": "Fajita, chicken",
    "rating": "2",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "info-translation": "Mexican grilled chicken in tortilla",
    "weight-g": "100",
    "calories": "149.1",
    "fat": "3.5",
    "cal-from-fat": "31.5",
    "per-cal-from-fat": "21",
    "saturated": "0.6",
    "polyunsaturated": "1.1",
    "monounsaturated": "1.8",
    "cholesterol": "82",
    "sodium": "800",
    "carbs": "4.7",
    "fibre": "0",
    "sugar": "0",
    "protein": "24.7",
    "cal-from-protein": "99",
    "per-cal-from-protein": "66",
    "calcium": "0",
    "iron": "0.8",
    "source": "N Buddy",
    "old units (ref)": "fajita"
  },
  {
    "id": "695",
    "type": "meal",
    "|| name                                               ||": "Fern shoot",
    "rating": "3",
    "high-calcium": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "alternate-spellings": "Fern: Pucuk, Paku",
    "weight-g": "100",
    "calories": "77",
    "fat": "5",
    "cal-from-fat": "45",
    "per-cal-from-fat": "58",
    "saturated": "1.8",
    "polyunsaturated": "1.3",
    "monounsaturated": "1.9",
    "cholesterol": "20",
    "sodium": "300",
    "carbs": "6",
    "fibre": "3",
    "sugar": "2",
    "protein": "2",
    "cal-from-protein": "8",
    "per-cal-from-protein": "10",
    "potassium": "500",
    "calcium": "155",
    "iron": "5.5",
    "phosphorus": "59",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "1018",
    "type": "snack",
    "|| name                                               ||": "Financier",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "french almond cake",
    "info-translation": "French almond cake",
    "weight-g": "30",
    "calories": "141",
    "fat": "8",
    "cal-from-fat": "72",
    "per-cal-from-fat": "51",
    "sodium": "13",
    "carbs": "15",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "3",
    "calcium": "104",
    "old units (ref)": "piece"
  },
  {
    "id": "107",
    "type": "meal",
    "|| name                                               ||": "Fish and chips",
    "rating": "2",
    "fried": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "268",
    "calories": "923.8",
    "fat": "47.4",
    "cal-from-fat": "426.6",
    "per-cal-from-fat": "46",
    "saturated": "20.1",
    "polyunsaturated": "4.6",
    "monounsaturated": "20.6",
    "cholesterol": "121",
    "sodium": "624",
    "carbs": "76.9",
    "fibre": "4.6",
    "sugar": "2.4",
    "protein": "47.4",
    "cal-from-protein": "190",
    "per-cal-from-protein": "21",
    "potassium": "1142",
    "calcium": "70",
    "iron": "3.2",
    "phosphorus": "456",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "734",
    "type": "snack",
    "|| name                                               ||": "Fish ball on stick, fried",
    "rating": "2",
    "fried": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "stick",
    "|| info                                                             ||": "3 pieces of fishball",
    "info-brands": "Old Chang Kee",
    "info-portion-aid": "3 pieces",
    "weight-g": "69",
    "calories": "131.2",
    "fat": "9.6",
    "cal-from-fat": "86.4",
    "per-cal-from-fat": "66",
    "saturated": "4.8",
    "polyunsaturated": "0.9",
    "monounsaturated": "3.9",
    "cholesterol": "18",
    "sodium": "390",
    "carbs": "2.7",
    "fibre": "0",
    "sugar": "0.2",
    "protein": "8.5",
    "cal-from-protein": "34",
    "per-cal-from-protein": "26",
    "potassium": "29",
    "calcium": "26",
    "iron": "1.7",
    "phosphorus": "49",
    "source": "N Buddy",
    "old units (ref)": "stick"
  },
  {
    "id": "736",
    "type": "snack",
    "|| name                                               ||": "Fish fillet on stick, fried",
    "rating": "1",
    "fried": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "processed": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "stick",
    "info-brands": "Old Chang Kee",
    "info-portion-aid": "3 pieces",
    "weight-g": "113",
    "calories": "236.8",
    "fat": "11.2",
    "cal-from-fat": "100.8",
    "per-cal-from-fat": "43",
    "saturated": "2.1",
    "polyunsaturated": "6",
    "monounsaturated": "2.6",
    "trans": "0.12",
    "cholesterol": "41",
    "sodium": "634",
    "carbs": "19",
    "fibre": "1",
    "sugar": "0.5",
    "protein": "15",
    "cal-from-protein": "60",
    "per-cal-from-protein": "25",
    "potassium": "284",
    "calcium": "16",
    "iron": "0.4",
    "phosphorus": "234",
    "source": "N Buddy",
    "old units (ref)": "stick"
  },
  {
    "id": "108",
    "type": "meal",
    "|| name                                               ||": "Fish head curry",
    "rating": "2",
    "high-iodine": "1",
    "high-iron": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "350",
    "calories": "366.6",
    "fat": "24.2",
    "cal-from-fat": "217.8",
    "per-cal-from-fat": "59",
    "saturated": "16.1",
    "polyunsaturated": "2.1",
    "monounsaturated": "4.9",
    "cholesterol": "119",
    "sodium": "1271",
    "carbs": "13",
    "fibre": "4.9",
    "sugar": "0.5",
    "protein": "24.2",
    "cal-from-protein": "97",
    "per-cal-from-protein": "26",
    "potassium": "300",
    "calcium": "175",
    "iron": "6",
    "phosphorus": "150",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "273",
    "type": "meal",
    "|| name                                               ||": "Fish head steamboat",
    "rating": "2",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "weight-g": "696",
    "calories": "493.6",
    "fat": "14.8",
    "cal-from-fat": "133.2",
    "per-cal-from-fat": "27",
    "saturated": "7",
    "polyunsaturated": "1.2",
    "monounsaturated": "4.7",
    "cholesterol": "56",
    "sodium": "1494",
    "carbs": "56.5",
    "fibre": "4.2",
    "sugar": "17.5",
    "protein": "33.6",
    "cal-from-protein": "134",
    "per-cal-from-protein": "27",
    "potassium": "1278",
    "calcium": "343",
    "iron": "3.7",
    "phosphorus": "502",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "serve"
  },
  {
    "id": "269",
    "type": "meal",
    "|| name                                               ||": "Fish, fried, breaded / battered",
    "rating": "2",
    "fried": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "breaded / battered",
    "info-portion-aid": "3\" x 3\" x 1\" ",
    "weight-g": "113",
    "calories": "245.8",
    "fat": "12.2",
    "cal-from-fat": "109.8",
    "per-cal-from-fat": "45",
    "saturated": "2.1",
    "polyunsaturated": "6",
    "monounsaturated": "2.6",
    "trans": "0.1",
    "cholesterol": "41",
    "sodium": "634",
    "carbs": "19",
    "fibre": "1",
    "sugar": "0.5",
    "protein": "15",
    "cal-from-protein": "60",
    "per-cal-from-protein": "24",
    "potassium": "284",
    "calcium": "16",
    "iron": "0.4",
    "phosphorus": "234",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "1066",
    "type": "meal",
    "|| name                                               ||": "Fish, grilled / pan fried",
    "rating": "2",
    "healthy-fats": "1",
    "high-iodine": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "info-portion-aid": "3\" x 3\" x 1\"",
    "weight-g": "117",
    "calories": "184",
    "fat": "11",
    "cal-from-fat": "99",
    "per-cal-from-fat": "54",
    "saturated": "1",
    "polyunsaturated": "1",
    "monounsaturated": "3",
    "cholesterol": "65",
    "sodium": "80",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "20",
    "cal-from-protein": "80",
    "per-cal-from-protein": "43",
    "potassium": "391",
    "calcium": "40",
    "iron": "1",
    "phosphorus": "186",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "420",
    "type": "meal",
    "|| name                                               ||": "Fish, steamed",
    "rating": "3",
    "high-iodine": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "info-portion-aid": "3\" x 3\" x 1\" ",
    "weight-g": "100",
    "calories": "110.5",
    "fat": "3.3",
    "cal-from-fat": "29.7",
    "per-cal-from-fat": "27",
    "saturated": "1.6",
    "cholesterol": "40",
    "sodium": "297",
    "fibre": "0.2",
    "protein": "20.2",
    "cal-from-protein": "81",
    "per-cal-from-protein": "73",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "764",
    "type": "meal",
    "|| name                                               ||": "Fish, sweet and sour",
    "rating": "2",
    "fried": "1",
    "high-iodine": "1",
    "high-sugar": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "weight-g": "120",
    "calories": "274",
    "fat": "13.8",
    "cal-from-fat": "124.2",
    "per-cal-from-fat": "45",
    "saturated": "6.6",
    "polyunsaturated": "1.7",
    "monounsaturated": "6.4",
    "cholesterol": "70",
    "sodium": "887",
    "carbs": "17.3",
    "fibre": "4.8",
    "sugar": "11.2",
    "protein": "28.3",
    "cal-from-protein": "113",
    "per-cal-from-protein": "41",
    "potassium": "464",
    "calcium": "20",
    "iron": "1.2",
    "phosphorus": "254",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "270",
    "type": "meal",
    "|| name                                               ||": "Fishball noodle, dry",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-salt": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "420",
    "calories": "474.7",
    "fat": "13.5",
    "cal-from-fat": "121.5",
    "per-cal-from-fat": "26",
    "saturated": "5.7",
    "polyunsaturated": "2.2",
    "monounsaturated": "5.5",
    "cholesterol": "55",
    "sodium": "2552",
    "carbs": "63.5",
    "fibre": "5",
    "sugar": "9.5",
    "protein": "24.8",
    "cal-from-protein": "99",
    "per-cal-from-protein": "21",
    "potassium": "113",
    "calcium": "80",
    "iron": "2.5",
    "phosphorus": "248",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "959",
    "type": "meal",
    "|| name                                               ||": "Fishball noodle, soup",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "600",
    "calories": "420",
    "fat": "7.2",
    "cal-from-fat": "64.8",
    "per-cal-from-fat": "15",
    "saturated": "1.8",
    "polyunsaturated": "1",
    "monounsaturated": "1.7",
    "cholesterol": "30",
    "sodium": "2190",
    "carbs": "66",
    "fibre": "6.6",
    "sugar": "5",
    "protein": "22.8",
    "cal-from-protein": "91",
    "per-cal-from-protein": "22",
    "potassium": "156",
    "calcium": "42",
    "iron": "2.4",
    "phosphorus": "144",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "271",
    "type": "meal",
    "|| name                                               ||": "Fishball, boiled",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "3",
    "|| unit ||": "piece",
    "|| info                                                             ||": "boiled",
    "info-examples": "fish cakes, sotong balls",
    "weight-g": "75",
    "calories": "49.8",
    "fat": "0.6",
    "cal-from-fat": "5.4",
    "per-cal-from-fat": "11",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "24",
    "sodium": "495",
    "carbs": "3.6",
    "fibre": "0",
    "sugar": "0.9",
    "protein": "7.5",
    "cal-from-protein": "30",
    "per-cal-from-protein": "60",
    "potassium": "36",
    "calcium": "24",
    "iron": "0.3",
    "phosphorus": "66",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "113",
    "type": "meal",
    "|| name                                               ||": "Fishball, fried",
    "rating": "2",
    "fried": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "3",
    "|| unit ||": "piece",
    "|| info                                                             ||": "fried",
    "info-examples": "fish cakes, sotong balls",
    "weight-g": "69",
    "calories": "131.2",
    "fat": "9.6",
    "cal-from-fat": "86.4",
    "per-cal-from-fat": "66",
    "saturated": "4.8",
    "polyunsaturated": "0.9",
    "monounsaturated": "3.9",
    "cholesterol": "18",
    "sodium": "390",
    "carbs": "2.7",
    "fibre": "0",
    "sugar": "0.2",
    "protein": "8.5",
    "cal-from-protein": "34",
    "per-cal-from-protein": "26",
    "potassium": "29",
    "calcium": "26",
    "iron": "1.7",
    "phosphorus": "49",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "115",
    "type": "meal",
    "|| name                                               ||": "Five spice prawn roll, fried",
    "rating": "2",
    "fried": "1",
    "high-fat": "1",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "roll",
    "|| info                                                             ||": "Ngor Hiang / Loh Bak",
    "info-translation": "Ngor Hiang, Loh Bak, Wu Xiang",
    "alternate-spellings": "Ngor: Ngo, Ngoh\nLoh: Lor",
    "weight-g": "59",
    "calories": "228.4",
    "fat": "15.2",
    "cal-from-fat": "136.8",
    "per-cal-from-fat": "60",
    "saturated": "6.7",
    "polyunsaturated": "2",
    "monounsaturated": "5.8",
    "cholesterol": "14",
    "sodium": "291",
    "carbs": "15.8",
    "fibre": "0.8",
    "sugar": "1.7",
    "protein": "7.1",
    "cal-from-protein": "28",
    "per-cal-from-protein": "12",
    "potassium": "116",
    "calcium": "17",
    "iron": "0.9",
    "phosphorus": "58",
    "source": "N Buddy",
    "old units (ref)": "roll"
  },
  {
    "id": "901",
    "type": "snack",
    "|| name                                               ||": "Flourless cake",
    "rating": "2",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "82",
    "calories": "375",
    "fat": "30",
    "cal-from-fat": "270",
    "per-cal-from-fat": "72",
    "saturated": "18",
    "polyunsaturated": "1.3",
    "monounsaturated": "8.9",
    "trans": "0.5",
    "cholesterol": "101",
    "sodium": "163",
    "carbs": "19",
    "fibre": "5.4",
    "sugar": "9.7",
    "protein": "7.1",
    "cal-from-protein": "28",
    "per-cal-from-protein": "8",
    "potassium": "300",
    "calcium": "47",
    "iron": "6",
    "source": "Nutritionix",
    "old units (ref)": "slice"
  },
  {
    "id": "503",
    "type": "meal",
    "|| name                                               ||": "Foie gras, pate",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "13",
    "calories": "59.648",
    "fat": "5.7",
    "cal-from-fat": "51.3",
    "per-cal-from-fat": "86",
    "saturated": "1.88",
    "polyunsaturated": "0.109",
    "monounsaturated": "3.33",
    "cholesterol": "19.5",
    "sodium": "90.6",
    "carbs": "0.607",
    "fibre": "0",
    "protein": "1.48",
    "cal-from-protein": "6",
    "per-cal-from-protein": "10",
    "potassium": "17.9",
    "calcium": "9.1",
    "iron": "0.715",
    "phosphorus": "26",
    "old units (ref)": "portion"
  },
  {
    "id": "683",
    "type": "meal",
    "|| name                                               ||": "Four angle bean",
    "rating": "3",
    "high-calcium": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "|| info                                                             ||": "Winged bean, asparagus bean, goa bean, princess pea",
    "info-translation": "Winged / goa / princess bean",
    "weight-g": "100",
    "calories": "86.7",
    "fat": "2.3",
    "cal-from-fat": "20.7",
    "per-cal-from-fat": "24",
    "saturated": "0.85",
    "polyunsaturated": "1.5",
    "monounsaturated": "2.1",
    "cholesterol": "0",
    "sodium": "249",
    "carbs": "15",
    "fibre": "1.25",
    "protein": "1.5",
    "cal-from-protein": "6",
    "per-cal-from-protein": "7",
    "potassium": "280",
    "calcium": "142",
    "iron": "4.33",
    "phosphorus": "153",
    "source": "US FDA",
    "old units (ref)": "serving"
  },
  {
    "id": "598",
    "type": "snack",
    "|| name                                               ||": "Freeze dried fruit snack",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "serving": "1",
    "|| unit ||": "bag",
    "|| info                                                             ||": "e.g. freeze dried banana snack",
    "info-examples": "banana, strawberry",
    "weight-g": "15",
    "calories": "57.4",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "3",
    "saturated": "0.1",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.1",
    "cholesterol": "0",
    "sodium": "1",
    "carbs": "13.2",
    "sugar": "10.8",
    "protein": "0.7",
    "cal-from-protein": "3",
    "per-cal-from-protein": "5",
    "calcium": "2",
    "iron": "0.4",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "644",
    "type": "meal",
    "|| name                                               ||": "French bean",
    "rating": "3",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "100",
    "calories": "67.3",
    "fat": "5.3",
    "cal-from-fat": "47.7",
    "per-cal-from-fat": "71",
    "saturated": "0.8",
    "polyunsaturated": "3.2",
    "monounsaturated": "0.9",
    "cholesterol": "0",
    "sodium": "496",
    "carbs": "2.6",
    "fibre": "2.9",
    "sugar": "1.5",
    "protein": "2.3",
    "cal-from-protein": "9",
    "per-cal-from-protein": "14",
    "potassium": "172",
    "calcium": "46",
    "iron": "1",
    "phosphorus": "40",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "109",
    "type": "meal",
    "|| name                                               ||": "French fries",
    "rating": "2",
    "fried": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "pack",
    "|| info                                                             ||": "medium size",
    "info-portion-aid": "medium",
    "weight-g": "116",
    "calories": "349",
    "fat": "19.5",
    "cal-from-fat": "175.5",
    "per-cal-from-fat": "50",
    "saturated": "7.6",
    "polyunsaturated": "4.8",
    "monounsaturated": "7.1",
    "trans": "0.1",
    "cholesterol": "0",
    "sodium": "290",
    "carbs": "47",
    "fibre": "4",
    "sugar": "1.2",
    "protein": "3.5",
    "cal-from-protein": "14",
    "per-cal-from-protein": "4",
    "potassium": "650",
    "calcium": "20",
    "iron": "1",
    "phosphorus": "153",
    "source": "N Buddy",
    "old units (ref)": "medium size"
  },
  {
    "|| name                                               ||": "French fries",
    "|| info                                                             ||": "small size",
    "info-portion-aid": "small"
  },
  {
    "|| name                                               ||": "French fries",
    "|| info                                                             ||": "large size",
    "info-portion-aid": "large"
  },
  {
    "id": "992",
    "type": "meal",
    "|| name                                               ||": "French onion soup",
    "rating": "3",
    "high-calcium": "1",
    "high-fat": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "301",
    "calories": "477.9",
    "fat": "19.9",
    "cal-from-fat": "179.1",
    "per-cal-from-fat": "37",
    "saturated": "9.41",
    "polyunsaturated": "1.72",
    "monounsaturated": "7.57",
    "cholesterol": "45.2",
    "sodium": "1290",
    "carbs": "52.3",
    "fibre": "3.61",
    "sugar": "6.71",
    "protein": "22.4",
    "cal-from-protein": "90",
    "per-cal-from-protein": "19",
    "potassium": "476",
    "calcium": "400",
    "iron": "3.31",
    "phosphorus": "352",
    "source": "USDA",
    "old units (ref)": "bowl"
  },
  {
    "id": "274",
    "type": "meal",
    "|| name                                               ||": "French toast, multigrain",
    "rating": "2",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "65",
    "calories": "134.5",
    "fat": "4.9",
    "cal-from-fat": "44.1",
    "per-cal-from-fat": "33",
    "saturated": "1.1",
    "polyunsaturated": "0.7",
    "monounsaturated": "1.2",
    "cholesterol": "48",
    "sodium": "303",
    "carbs": "16.2",
    "fibre": "2.5",
    "sugar": "1.5",
    "protein": "6.4",
    "cal-from-protein": "26",
    "per-cal-from-protein": "19",
    "potassium": "79",
    "calcium": "100",
    "iron": "1.8",
    "phosphorus": "104",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "110",
    "type": "meal",
    "|| name                                               ||": "French toast, white bread",
    "rating": "1",
    "high-gi": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "59",
    "calories": "125.6",
    "fat": "3.6",
    "cal-from-fat": "32.4",
    "per-cal-from-fat": "26",
    "saturated": "0.9",
    "polyunsaturated": "0.7",
    "monounsaturated": "1.2",
    "cholesterol": "48",
    "sodium": "292",
    "carbs": "18.9",
    "fibre": "0.7",
    "sugar": "0",
    "protein": "4.4",
    "cal-from-protein": "18",
    "per-cal-from-protein": "14",
    "potassium": "79",
    "calcium": "63",
    "iron": "1.3",
    "phosphorus": "82",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "1064",
    "type": "drink",
    "|| name                                               ||": "Fresh coconut water",
    "rating": "3",
    "high-phosphate": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "fruit",
    "|| info                                                             ||": "without flesh",
    "weight-g": "250",
    "calories": "48.9",
    "fat": "0.5",
    "cal-from-fat": "4.5",
    "per-cal-from-fat": "9",
    "saturated": "0.4",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "262",
    "carbs": "9.3",
    "fibre": "2.8",
    "sugar": "6.5",
    "protein": "1.8",
    "cal-from-protein": "7",
    "per-cal-from-protein": "15",
    "potassium": "625",
    "calcium": "59",
    "iron": "0.7",
    "phosphorus": "50",
    "source": "N Buddy",
    "old units (ref)": "whole"
  },
  {
    "id": "705",
    "type": "snack",
    "|| name                                               ||": "Fresh coconut water with flesh",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "fruit",
    "weight-g": "300",
    "calories": "295",
    "fat": "27",
    "cal-from-fat": "243",
    "per-cal-from-fat": "82",
    "saturated": "24",
    "cholesterol": "0",
    "carbs": "10",
    "fibre": "7",
    "sugar": "5",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "4",
    "potassium": "200",
    "iron": "1.9",
    "phosphorus": "91",
    "source": "https://www.healthline.com/nutrition/coconut-meat#nutrients",
    "old units (ref)": "whole"
  },
  {
    "id": "1080",
    "type": "snack",
    "|| name                                               ||": "Fresh fruit cream cake",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "161",
    "calories": "451",
    "fat": "21.3",
    "cal-from-fat": "191.7",
    "per-cal-from-fat": "43",
    "saturated": "12.6",
    "polyunsaturated": "1.8",
    "monounsaturated": "3.3",
    "trans": "0.7",
    "cholesterol": "43",
    "sodium": "196",
    "carbs": "61.4",
    "fibre": "1.9",
    "sugar": "42.6",
    "protein": "5.2",
    "cal-from-protein": "21",
    "per-cal-from-protein": "5",
    "potassium": "202",
    "calcium": "68",
    "iron": "1.6",
    "phosphorus": "138",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "235",
    "type": "meal",
    "|| name                                               ||": "Fried anchovies",
    "rating": "2",
    "fried": "1",
    "high-calcium": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "|| info                                                             ||": "Ikan Bilis",
    "info-translation": "Ikan Bilis",
    "weight-g": "60",
    "calories": "125.6",
    "fat": "7.6",
    "cal-from-fat": "68.4",
    "per-cal-from-fat": "54",
    "saturated": "3.4",
    "polyunsaturated": "0.8",
    "monounsaturated": "3.1",
    "cholesterol": "20",
    "sodium": "1245",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "14.3",
    "cal-from-protein": "57",
    "per-cal-from-protein": "46",
    "potassium": "211",
    "calcium": "160",
    "iron": "0.7",
    "phosphorus": "195",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "23",
    "type": "snack",
    "|| name                                               ||": "Fried banana",
    "rating": "2",
    "fried": "1",
    "fruit": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Pisang goreng",
    "info-translation": "Pisang goreng",
    "weight-g": "65",
    "calories": "196.9",
    "fat": "5.3",
    "cal-from-fat": "47.7",
    "per-cal-from-fat": "24",
    "saturated": "3.5",
    "polyunsaturated": "0.3",
    "monounsaturated": "1.4",
    "cholesterol": "0",
    "sodium": "103",
    "carbs": "35.9",
    "fibre": "2.3",
    "sugar": "13.6",
    "protein": "1.4",
    "cal-from-protein": "6",
    "per-cal-from-protein": "3",
    "potassium": "218",
    "calcium": "31",
    "iron": "0.5",
    "phosphorus": "29",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "275",
    "type": "meal",
    "|| name                                               ||": "Fried basil with meat",
    "rating": "2",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "|| info                                                             ||": "pork / chicken / beef",
    "info-examples": "pork / chicken / beef",
    "weight-g": "123",
    "calories": "229.8",
    "fat": "15",
    "cal-from-fat": "135",
    "per-cal-from-fat": "59",
    "saturated": "5",
    "polyunsaturated": "4",
    "monounsaturated": "5",
    "cholesterol": "76",
    "sodium": "431",
    "carbs": "2.4",
    "fibre": "0.3",
    "sugar": "2.4",
    "protein": "21.3",
    "cal-from-protein": "85",
    "per-cal-from-protein": "37",
    "potassium": "384",
    "calcium": "20",
    "iron": "2.2",
    "phosphorus": "214",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "905",
    "type": "meal",
    "|| name                                               ||": "Fried carrot cake with dark sauce)",
    "rating": "2",
    "fried": "1",
    "high-cholesterol": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Chai tau kway (black)",
    "info-translation": "Chai Tau Kway (black)",
    "alternate-spellings": "Chai: Cai\nTau: Tow, Tao\nKway: Kueh, Kuey, Kuih",
    "weight-g": "295",
    "calories": "493.4",
    "fat": "35.4",
    "cal-from-fat": "318.6",
    "per-cal-from-fat": "65",
    "saturated": "14.1",
    "polyunsaturated": "4.2",
    "monounsaturated": "14.6",
    "cholesterol": "109",
    "sodium": "1289",
    "carbs": "41.6",
    "fibre": "5.9",
    "sugar": "26.6",
    "protein": "2.1",
    "cal-from-protein": "8",
    "per-cal-from-protein": "2",
    "potassium": "490",
    "calcium": "94",
    "iron": "3.3",
    "phosphorus": "156",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "43",
    "type": "snack",
    "|| name                                               ||": "Fried carrot cake with egg",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Chai tau kway (white)",
    "info-translation": "Chai Tau Kway (white)",
    "alternate-spellings": "Chai: Cai\nTau: Tow, Tao\nKway: Kueh, Kuey, Kuih",
    "weight-g": "292",
    "calories": "469.3",
    "fat": "24.5",
    "cal-from-fat": "220.5",
    "per-cal-from-fat": "47",
    "saturated": "10.5",
    "polyunsaturated": "4.1",
    "monounsaturated": "8.8",
    "cholesterol": "41",
    "sodium": "1291",
    "carbs": "51.1",
    "fibre": "5",
    "sugar": "0",
    "protein": "11.1",
    "cal-from-protein": "44",
    "per-cal-from-protein": "9",
    "calcium": "158",
    "iron": "6.1",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "112",
    "type": "meal",
    "|| name                                               ||": "Fried chicken cutlet",
    "rating": "1",
    "fried": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "weight-g": "105",
    "calories": "235",
    "fat": "14.1",
    "cal-from-fat": "126.9",
    "per-cal-from-fat": "54",
    "saturated": "2.6",
    "polyunsaturated": "1.9",
    "monounsaturated": "9.6",
    "cholesterol": "39",
    "sodium": "668",
    "carbs": "12.3",
    "fibre": "1.1",
    "sugar": "0.9",
    "protein": "13.4",
    "cal-from-protein": "54",
    "per-cal-from-protein": "23",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "458",
    "type": "snack",
    "|| name                                               ||": "Fried crackers",
    "rating": "1",
    "fried": "1",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Keropok prawn / fish",
    "info-translation": "Keropok",
    "info-examples": "prawn, fish",
    "alternate-spellings": "Keropok: Krupuk, Kerupuk",
    "weight-g": "7",
    "calories": "32.8",
    "fat": "1.6",
    "cal-from-fat": "14.4",
    "per-cal-from-fat": "44",
    "saturated": "0.7",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.7",
    "cholesterol": "1.3",
    "sodium": "84.3",
    "carbs": "4.1",
    "fibre": "0.1",
    "sugar": "0",
    "protein": "0.5",
    "cal-from-protein": "2",
    "per-cal-from-protein": "6",
    "potassium": "6",
    "calcium": "10",
    "iron": "0.1",
    "phosphorus": "6.5",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "25",
    "type": "snack",
    "|| name                                               ||": "Fried dough fritters",
    "rating": "1",
    "fried": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-salt": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. you tiao, butterfly fritter, ham chim beng, you char kway, beh her ji",
    "info-examples": "you tiao / you char kway, butterfly fritter, ham chim beng",
    "weight-g": "75",
    "calories": "283.9",
    "fat": "12.7",
    "cal-from-fat": "114.3",
    "per-cal-from-fat": "40",
    "saturated": "10.6",
    "polyunsaturated": "0.4",
    "monounsaturated": "1.3",
    "cholesterol": "0",
    "sodium": "602",
    "carbs": "36.3",
    "fibre": "1",
    "sugar": "0.3",
    "protein": "6.1",
    "cal-from-protein": "24",
    "per-cal-from-protein": "9",
    "calcium": "16",
    "iron": "0.5",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "345",
    "type": "meal",
    "|| name                                               ||": "Fried fish soup with noodles / rice, with evaporated milk",
    "rating": "2",
    "fried": "1",
    "healthy-fats": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "with milk",
    "weight-g": "646",
    "calories": "534.2",
    "fat": "16.2",
    "cal-from-fat": "145.8",
    "per-cal-from-fat": "27",
    "saturated": "5.8",
    "polyunsaturated": "2.6",
    "monounsaturated": "5.7",
    "cholesterol": "3",
    "sodium": "2259",
    "carbs": "73.7",
    "fibre": "6.3",
    "sugar": "15.1",
    "protein": "23.4",
    "cal-from-protein": "94",
    "per-cal-from-protein": "18",
    "potassium": "316",
    "calcium": "168",
    "iron": "0.3",
    "phosphorus": "352",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "786",
    "type": "meal",
    "|| name                                               ||": "Fried Hong Kong egg noodle",
    "rating": "2",
    "high-calcium": "1",
    "high-cholesterol": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "408",
    "calories": "692.7",
    "fat": "37.1",
    "cal-from-fat": "333.9",
    "per-cal-from-fat": "48",
    "saturated": "14.3",
    "polyunsaturated": "5.3",
    "monounsaturated": "15.9",
    "cholesterol": "257",
    "sodium": "1563",
    "carbs": "62.8",
    "fibre": "8.2",
    "sugar": "0.8",
    "protein": "26.9",
    "cal-from-protein": "108",
    "per-cal-from-protein": "16",
    "potassium": "437",
    "calcium": "196",
    "iron": "3.7",
    "phosphorus": "302",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "114",
    "type": "meal",
    "|| name                                               ||": "Fried kuey tiao",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Char Kway Teow",
    "info-translation": "Char Kway Teow",
    "alternate-spellings": "Kuey: Kueh, Kuih",
    "weight-g": "400",
    "calories": "771.2",
    "fat": "40",
    "cal-from-fat": "360",
    "per-cal-from-fat": "47",
    "saturated": "30.4",
    "polyunsaturated": "3.2",
    "monounsaturated": "4.8",
    "cholesterol": "244",
    "sodium": "1520",
    "carbs": "79.2",
    "fibre": "2",
    "sugar": "0",
    "protein": "23.6",
    "cal-from-protein": "94",
    "per-cal-from-protein": "12",
    "calcium": "204",
    "iron": "6.8",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "846",
    "type": "snack",
    "|| name                                               ||": "Fried mussel nuggets, breaded",
    "rating": "1",
    "fried": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "processed": "1",
    "serving": "4",
    "|| unit ||": "piece",
    "weight-g": "150",
    "calories": "303",
    "fat": "16.8",
    "cal-from-fat": "151.2",
    "per-cal-from-fat": "50",
    "saturated": "4",
    "polyunsaturated": "4.3",
    "monounsaturated": "6.8",
    "cholesterol": "92",
    "sodium": "546",
    "carbs": "15.5",
    "protein": "8.5",
    "cal-from-protein": "34",
    "per-cal-from-protein": "11",
    "potassium": "489",
    "calcium": "95",
    "iron": "21",
    "phosphorus": "282",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "116",
    "type": "meal",
    "|| name                                               ||": "Fried pandan chicken rolls",
    "rating": "2",
    "fried": "1",
    "high-fat": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "81",
    "calories": "163",
    "fat": "12.3",
    "cal-from-fat": "110.7",
    "per-cal-from-fat": "68",
    "saturated": "5.3",
    "polyunsaturated": "3.8",
    "monounsaturated": "3.2",
    "cholesterol": "49",
    "sodium": "44",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "13.2",
    "cal-from-protein": "53",
    "per-cal-from-protein": "32",
    "potassium": "117",
    "calcium": "6",
    "iron": "0.7",
    "phosphorus": "92",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "845",
    "type": "snack",
    "|| name                                               ||": "Fried prawns, breaded / battered",
    "rating": "2",
    "fried": "1",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "processed": "1",
    "serving": "2",
    "|| unit ||": "piece",
    "weight-g": "34",
    "calories": "105",
    "fat": "6.3",
    "cal-from-fat": "56.7",
    "per-cal-from-fat": "54",
    "saturated": "1.1",
    "polyunsaturated": "3.2",
    "monounsaturated": "1.5",
    "cholesterol": "30",
    "sodium": "382",
    "carbs": "7.6",
    "fibre": "0.5",
    "sugar": "0.3",
    "protein": "4.3",
    "cal-from-protein": "17",
    "per-cal-from-protein": "16",
    "potassium": "37",
    "calcium": "14",
    "iron": "0.4",
    "phosphorus": "102",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "117",
    "type": "meal",
    "|| name                                               ||": "Fried rice",
    "rating": "2",
    "high-cholesterol": "1",
    "high-gi": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "250",
    "calories": "530.9",
    "fat": "19.3",
    "cal-from-fat": "173.7",
    "per-cal-from-fat": "33",
    "saturated": "8",
    "polyunsaturated": "2.3",
    "monounsaturated": "8",
    "cholesterol": "103",
    "sodium": "728",
    "carbs": "72.8",
    "fibre": "0.5",
    "sugar": "13.3",
    "protein": "16.5",
    "cal-from-protein": "66",
    "per-cal-from-protein": "12",
    "potassium": "148",
    "calcium": "45",
    "iron": "1.5",
    "phosphorus": "130",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "777",
    "type": "meal",
    "|| name                                               ||": "Fried shrimps",
    "rating": "2",
    "fried": "1",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "|| info                                                             ||": "6 shrimps",
    "weight-g": "126",
    "calories": "314.4",
    "fat": "16.8",
    "cal-from-fat": "151.2",
    "per-cal-from-fat": "48",
    "saturated": "3",
    "cholesterol": "102",
    "sodium": "300",
    "carbs": "25.8",
    "fibre": "0",
    "sugar": "1.8",
    "protein": "15",
    "cal-from-protein": "60",
    "per-cal-from-protein": "19",
    "calcium": "60",
    "iron": "2.4",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "1056",
    "type": "meal",
    "|| name                                               ||": "Fried fish soup, without evaporated milk",
    "rating": "2",
    "fried": "1",
    "healthy-fats": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "without milk",
    "weight-g": "465",
    "calories": "275.7",
    "fat": "19.7",
    "cal-from-fat": "177.3",
    "per-cal-from-fat": "64",
    "saturated": "8.9",
    "polyunsaturated": "1.8",
    "monounsaturated": "7.2",
    "cholesterol": "21",
    "sodium": "1186",
    "carbs": "3.7",
    "fibre": "2.1",
    "sugar": "1.6",
    "protein": "20.9",
    "cal-from-protein": "84",
    "per-cal-from-protein": "30",
    "potassium": "397",
    "calcium": "90",
    "iron": "0.2",
    "phosphorus": "219",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "1067",
    "type": "meal",
    "|| name                                               ||": "Fried fish soup with evaporated milk",
    "rating": "2",
    "fried": "1",
    "healthy-fats": "1",
    "high-calcium": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "with milk",
    "weight-g": "616",
    "calories": "357.4",
    "fat": "22.2",
    "cal-from-fat": "199.8",
    "per-cal-from-fat": "56",
    "saturated": "4.6",
    "polyunsaturated": "1.9",
    "monounsaturated": "7.9",
    "cholesterol": "29",
    "sodium": "332",
    "carbs": "28.9",
    "fibre": "2.1",
    "sugar": "4.9",
    "protein": "17.3",
    "cal-from-protein": "69",
    "per-cal-from-protein": "19",
    "potassium": "111",
    "calcium": "140",
    "iron": "0.3",
    "phosphorus": "66",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "1068",
    "type": "meal",
    "|| name                                               ||": "Fried fish soup with noodles / rice, without evaporated milk",
    "rating": "2",
    "fried": "1",
    "healthy-fats": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "without milk",
    "weight-g": "616",
    "calories": "510",
    "fat": "15.6",
    "cal-from-fat": "140.4",
    "per-cal-from-fat": "28",
    "saturated": "5.4",
    "polyunsaturated": "2.6",
    "monounsaturated": "5.5",
    "cholesterol": "27",
    "sodium": "2230",
    "carbs": "70.5",
    "fibre": "6.4",
    "sugar": "11.9",
    "protein": "21.1",
    "cal-from-protein": "84",
    "per-cal-from-protein": "17",
    "potassium": "215",
    "calcium": "91",
    "iron": "0.2",
    "phosphorus": "272",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "773",
    "type": "meal",
    "|| name                                               ||": "Frog leg, herbal or with ginger and spring onion",
    "rating": "2",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "weight-g": "279",
    "calories": "391",
    "fat": "26",
    "cal-from-fat": "234",
    "per-cal-from-fat": "60",
    "saturated": "11",
    "polyunsaturated": "3",
    "monounsaturated": "11",
    "cholesterol": "64",
    "sodium": "1417",
    "carbs": "3",
    "fibre": "0.5",
    "sugar": "3",
    "protein": "36",
    "cal-from-protein": "144",
    "per-cal-from-protein": "37",
    "potassium": "407",
    "calcium": "340",
    "iron": "2",
    "phosphorus": "282",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "614",
    "type": "drink",
    "|| name                                               ||": "Frozen carbonated drink",
    "rating": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "e.g. 7-11 Slurpee medium cup",
    "info-brands": "Slurpee",
    "info-portion-aid": "medium",
    "volume-ml": "375",
    "calories": "104",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "50",
    "carbs": "26",
    "fibre": "0",
    "sugar": "32",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "source": "https://www.7eleven.com.au/slurpee/",
    "old units (ref)": "can"
  },
  {
    "id": "826",
    "type": "snack",
    "|| name                                               ||": "Frozen yogurt, plain",
    "rating": "2",
    "high-calcium": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "probiotic": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "alternate-spellings": "Yogurt: Yoghurt",
    "weight-g": "144",
    "calories": "228",
    "fat": "8.1",
    "cal-from-fat": "72.9",
    "per-cal-from-fat": "32",
    "saturated": "4.9",
    "polyunsaturated": "0.3",
    "monounsaturated": "2.3",
    "cholesterol": "2.8",
    "sodium": "125",
    "carbs": "35",
    "fibre": "0",
    "sugar": "34.6",
    "protein": "5.8",
    "cal-from-protein": "23",
    "per-cal-from-protein": "10",
    "potassium": "304",
    "calcium": "206",
    "iron": "0.4",
    "phosphorus": "186",
    "source": "USDA",
    "old units (ref)": "medium cup"
  },
  {
    "id": "827",
    "type": "snack",
    "|| name                                               ||": "Frozen yogurt, with toppings",
    "rating": "2",
    "high-calcium": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "probiotic": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "alternate-spellings": "Yogurt: Yoghurt",
    "weight-g": "170",
    "calories": "460",
    "fat": "14",
    "cal-from-fat": "126",
    "per-cal-from-fat": "27",
    "saturated": "10.3",
    "polyunsaturated": "0.4",
    "monounsaturated": "2.7",
    "cholesterol": "2.8",
    "sodium": "131",
    "carbs": "41",
    "fibre": "0",
    "sugar": "40",
    "protein": "6.1",
    "cal-from-protein": "24",
    "per-cal-from-protein": "5",
    "potassium": "309",
    "calcium": "208",
    "iron": "0.4",
    "phosphorus": "188",
    "source": "USDA",
    "old units (ref)": "medium cup"
  },
  {
    "id": "376",
    "disabled": "1",
    "type": "snack",
    "|| name                                               ||": "Fruit (green, edible with / without skin e.g. guava, green apple, kiwi)",
    "rating": "3"
  },
  {
    "id": "378",
    "disabled": "1",
    "type": "snack",
    "|| name                                               ||": "Fruit (red, edible without skin e.g. watermelon)",
    "rating": "3",
    "fruit": "1",
    "healthy-fats": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "wedge",
    "calories": "88",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "2",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "2",
    "carbs": "21",
    "fibre": "1",
    "sugar": "17",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "5",
    "old units (ref)": "wedge"
  },
  {
    "id": "377",
    "disabled": "1",
    "type": "snack",
    "|| name                                               ||": "Fruit (yellow / purple / brown, edible without skin e.g. orange, dragonfruit, mangosteen)",
    "rating": "3",
    "fruit": "1",
    "healthy-fats": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "fruit",
    "calories": "96",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "1",
    "carbs": "23",
    "fibre": "2",
    "sugar": "12",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "4",
    "old units (ref)": "whole fruit"
  },
  {
    "id": "1043",
    "type": "snack",
    "|| name                                               ||": "Fruit flan cake",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "300",
    "calories": "528.3",
    "fat": "24.3",
    "cal-from-fat": "218.7",
    "per-cal-from-fat": "41",
    "saturated": "7.1",
    "polyunsaturated": "4.4",
    "monounsaturated": "11.4",
    "trans": "0.4",
    "cholesterol": "42",
    "sodium": "180",
    "carbs": "64.5",
    "fibre": "6.6",
    "sugar": "35.3",
    "protein": "12.9",
    "cal-from-protein": "52",
    "per-cal-from-protein": "10",
    "potassium": "462",
    "calcium": "112",
    "iron": "2.4",
    "phosphorus": "254",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "1032",
    "type": "snack",
    "|| name                                               ||": "Mixed Fruit Tart",
    "rating": "2",
    "high-calcium": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. pear, peach, mango, apple",
    "weight-g": "195",
    "calories": "376.3",
    "fat": "17.9",
    "cal-from-fat": "161.1",
    "per-cal-from-fat": "43",
    "saturated": "8.9",
    "polyunsaturated": "1",
    "monounsaturated": "6.9",
    "cholesterol": "73",
    "sodium": "367",
    "carbs": "44.6",
    "fibre": "2.2",
    "sugar": "18.2",
    "protein": "9.2",
    "cal-from-protein": "37",
    "per-cal-from-protein": "10",
    "potassium": "211",
    "calcium": "109",
    "iron": "0.9",
    "phosphorus": "144",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "482",
    "type": "meal",
    "|| name                                               ||": "Gado gado with sauce",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "plant-protein": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Indonesian salad",
    "info-translation": "Indonesian salad",
    "weight-g": "421",
    "calories": "656.4",
    "fat": "40",
    "cal-from-fat": "360",
    "per-cal-from-fat": "55",
    "saturated": "14.3",
    "polyunsaturated": "10.5",
    "monounsaturated": "13.5",
    "cholesterol": "88",
    "sodium": "1082",
    "carbs": "44.2",
    "fibre": "19.4",
    "sugar": "15",
    "protein": "29.9",
    "cal-from-protein": "120",
    "per-cal-from-protein": "18",
    "calcium": "244",
    "iron": "5",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "626",
    "disabled": "1",
    "type": "meal",
    "|| name                                               ||": "Gai choy/ mustard leaf",
    "rating": "3",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "100",
    "calories": "28.3",
    "fat": "0.7",
    "cal-from-fat": "6.3",
    "per-cal-from-fat": "22",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "carbs": "3.5",
    "fibre": "1",
    "sugar": "0",
    "protein": "2",
    "cal-from-protein": "8",
    "per-cal-from-protein": "28",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "591",
    "type": "meal",
    "|| name                                               ||": "Garlic bread, multigrain",
    "rating": "2",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. Gardenia garlic multigrain bread",
    "weight-g": "50",
    "calories": "49.5",
    "fat": "2.3",
    "cal-from-fat": "20.7",
    "per-cal-from-fat": "42",
    "saturated": "1",
    "cholesterol": "6",
    "sodium": "117",
    "carbs": "3.7",
    "fibre": "0.1",
    "sugar": "0.3",
    "protein": "3.5",
    "cal-from-protein": "14",
    "per-cal-from-protein": "28",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "590",
    "type": "meal",
    "|| name                                               ||": "Garlic bread, white",
    "rating": "2",
    "high-gi": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "|| info                                                             ||": "e.g. Sunshine garlic bread with onion flavour",
    "weight-g": "67",
    "calories": "74.2",
    "fat": "3.4",
    "cal-from-fat": "30.6",
    "per-cal-from-fat": "41",
    "saturated": "1.7",
    "polyunsaturated": "0.9",
    "monounsaturated": "0.9",
    "cholesterol": "0",
    "sodium": "111.3",
    "carbs": "9.1",
    "fibre": "0.5",
    "sugar": "0.4",
    "protein": "1.8",
    "cal-from-protein": "7",
    "per-cal-from-protein": "10",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "475",
    "type": "snack",
    "|| name                                               ||": "Gingko nuts",
    "rating": "3",
    "high-fibre": "1",
    "high-potassium": "1",
    "serving": "14",
    "|| unit ||": "nut",
    "weight-g": "28.35",
    "calories": "47.527",
    "fat": "0.459",
    "cal-from-fat": "4.131",
    "per-cal-from-fat": "9",
    "saturated": "0.088",
    "polyunsaturated": "0.169",
    "monounsaturated": "0.17",
    "cholesterol": "0",
    "sodium": "87",
    "carbs": "10.2",
    "fibre": "2.64",
    "protein": "0.649",
    "cal-from-protein": "3",
    "per-cal-from-protein": "5",
    "potassium": "51",
    "calcium": "1.13",
    "iron": "0.082",
    "phosphorus": "15.3",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "179",
    "type": "drink",
    "|| name                                               ||": "Ginseng tea, sweetened",
    "rating": "2",
    "high-gi": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "volume-ml": "180",
    "calories": "74",
    "fat": "2.3",
    "cal-from-fat": "20.7",
    "per-cal-from-fat": "28",
    "saturated": "1.1",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.9",
    "cholesterol": "2",
    "sodium": "113",
    "carbs": "12",
    "fibre": "0",
    "sugar": "12",
    "protein": "1.3",
    "cal-from-protein": "5",
    "per-cal-from-protein": "7",
    "potassium": "113",
    "calcium": "41",
    "iron": "0",
    "phosphorus": "43",
    "source": "N Buddy",
    "old units (ref)": "glass"
  },
  {
    "id": "371",
    "type": "drink",
    "|| name                                               ||": "Ginseng tea, unsweetened",
    "rating": "3",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "volume-ml": "200",
    "calories": "8",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "2",
    "sugar": "1",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "source": "N Buddy",
    "old units (ref)": "glass"
  },
  {
    "id": "489",
    "type": "snack",
    "|| name                                               ||": "Glutinous rice dumpling, vegetarian",
    "rating": "1",
    "high-fat": "1",
    "high-gi": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "197",
    "calories": "396",
    "fat": "8.1",
    "cal-from-fat": "72.9",
    "per-cal-from-fat": "18",
    "saturated": "3.2",
    "polyunsaturated": "1.2",
    "monounsaturated": "3.5",
    "cholesterol": "0",
    "sodium": "896",
    "carbs": "69",
    "fibre": "4.5",
    "sugar": "6.1",
    "protein": "11.6",
    "cal-from-protein": "46",
    "per-cal-from-protein": "12",
    "potassium": "179",
    "calcium": "35",
    "iron": "2.2",
    "phosphorus": "89",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "467",
    "type": "meal",
    "|| name                                               ||": "Gnocchi",
    "rating": "2",
    "high-potassium": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "e.g. potato / cheese gnocchi",
    "info-examples": "potato, cheese",
    "weight-g": "188",
    "calories": "250",
    "fat": "11.7",
    "cal-from-fat": "105.3",
    "per-cal-from-fat": "42",
    "saturated": "7.3",
    "polyunsaturated": "0.6",
    "monounsaturated": "3",
    "cholesterol": "30",
    "sodium": "543",
    "carbs": "32.1",
    "fibre": "1.9",
    "sugar": "1.8",
    "protein": "4.4",
    "cal-from-protein": "18",
    "per-cal-from-protein": "7",
    "potassium": "252",
    "calcium": "39.5",
    "iron": "1.3",
    "phosphorus": "75",
    "source": "USDA",
    "old units (ref)": "cup"
  },
  {
    "id": "711",
    "type": "snack",
    "|| name                                               ||": "Gooseberries",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "1 cup = 240mL",
    "weight-g": "150",
    "calories": "66",
    "fat": "0.9",
    "cal-from-fat": "8.1",
    "per-cal-from-fat": "12",
    "saturated": "0.06",
    "polyunsaturated": "0.5",
    "monounsaturated": "0.08",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "1.5",
    "carbs": "15.3",
    "fibre": "6.5",
    "protein": "1.3",
    "cal-from-protein": "5",
    "per-cal-from-protein": "8",
    "potassium": "297",
    "calcium": "37.5",
    "iron": "0.5",
    "phosphorus": "41",
    "source": "USDA",
    "old units (ref)": "cup"
  },
  {
    "id": "656",
    "type": "meal",
    "|| name                                               ||": "Bitter gourd, boiled/ steamed",
    "rating": "3",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "info-translation": "Bitter melon",
    "weight-g": "100",
    "calories": "19.3",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "5",
    "saturated": "0",
    "polyunsaturated": "0.1",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "21",
    "carbs": "3.6",
    "fibre": "1.8",
    "sugar": "3.6",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "21",
    "potassium": "145",
    "calcium": "70",
    "iron": "0.9",
    "phosphorus": "13",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "183",
    "type": "snack",
    "|| name                                               ||": "Grapefruit",
    "rating": "2",
    "fruit": "1",
    "high-fibre": "1",
    "serving": "0.5",
    "|| unit ||": "fruit",
    "weight-g": "98",
    "calories": "38",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "2",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "1",
    "carbs": "9",
    "protein": "0.5",
    "cal-from-protein": "2",
    "per-cal-from-protein": "5",
    "potassium": "159",
    "calcium": "8.8",
    "iron": "0.2",
    "phosphorus": "15",
    "source": "USDA",
    "old units (ref)": "whole fruit"
  },
  {
    "id": "385",
    "type": "snack",
    "|| name                                               ||": "Grapes",
    "rating": "3",
    "fruit": "1",
    "serving": "10",
    "|| unit ||": "grape",
    "weight-g": "101",
    "calories": "65.7",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "1",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "4",
    "carbs": "15",
    "fibre": "0.4",
    "sugar": "15",
    "protein": "1.2",
    "cal-from-protein": "5",
    "per-cal-from-protein": "7",
    "potassium": "165",
    "calcium": "9",
    "iron": "0.2",
    "phosphorus": "10",
    "source": "N Buddy",
    "old units (ref)": "small piece"
  },
  {
    "id": "171",
    "type": "drink",
    "|| name                                               ||": "Grass jelly drink, sweetened",
    "rating": "2",
    "high-gi": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "Cincau",
    "info-translation": "Cincau",
    "volume-ml": "231",
    "calories": "135.6",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "53",
    "carbs": "33.9",
    "fibre": "1.2",
    "sugar": "32.2",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "25",
    "calcium": "18",
    "iron": "0.2",
    "phosphorus": "0",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "364",
    "type": "drink",
    "|| name                                               ||": "Grass jelly drink, unsweetened",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "Cincau",
    "info-translation": "Cincau",
    "weight-g": "231",
    "calories": "14",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "53",
    "carbs": "3",
    "fibre": "0.1",
    "sugar": "1",
    "protein": "0.5",
    "cal-from-protein": "2",
    "per-cal-from-protein": "14",
    "potassium": "25",
    "calcium": "18",
    "iron": "0.2",
    "phosphorus": "0",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "961",
    "type": "meal",
    "|| name                                               ||": "Gravy, braised sauce",
    "rating": "2",
    "high-purine": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "ladle",
    "|| info                                                             ||": "e.g. for economical rice",
    "info-include-exclude": "for economical rice",
    "weight-g": "45",
    "calories": "43.8",
    "fat": "2.6",
    "cal-from-fat": "23.4",
    "per-cal-from-fat": "53",
    "saturated": "1.2",
    "polyunsaturated": "0.2",
    "monounsaturated": "1.1",
    "sodium": "272",
    "carbs": "3.2",
    "fibre": "0.4",
    "sugar": "2.6",
    "protein": "1.9",
    "cal-from-protein": "8",
    "per-cal-from-protein": "17",
    "potassium": "53",
    "calcium": "3",
    "iron": "0.7",
    "phosphorus": "18",
    "source": "N Buddy",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "960",
    "type": "meal",
    "|| name                                               ||": "Gravy, curry sauce",
    "rating": "2",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "ladle",
    "|| info                                                             ||": "e.g. for economical rice",
    "info-include-exclude": "for economical rice",
    "weight-g": "45",
    "calories": "86.9",
    "fat": "7.7",
    "cal-from-fat": "69.3",
    "per-cal-from-fat": "80",
    "saturated": "3.8",
    "polyunsaturated": "1.4",
    "monounsaturated": "2.7",
    "sodium": "218",
    "carbs": "3.8",
    "fibre": "0.5",
    "sugar": "2.9",
    "protein": "0.6",
    "cal-from-protein": "2",
    "per-cal-from-protein": "3",
    "calcium": "30",
    "iron": "1.2",
    "source": "N Buddy",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "930",
    "type": "meal",
    "|| name                                               ||": "Gravy, hakka",
    "rating": "1",
    "high-purine": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "e.g. for yong tau foo",
    "info-include-exclude": "for yong tau foo",
    "weight-g": "216",
    "calories": "174.2",
    "fat": "11.4",
    "cal-from-fat": "102.6",
    "per-cal-from-fat": "59",
    "saturated": "4.2",
    "polyunsaturated": "1.2",
    "monounsaturated": "4.8",
    "cholesterol": "36",
    "sodium": "1148",
    "carbs": "7.2",
    "fibre": "0.2",
    "sugar": "3.5",
    "protein": "10.7",
    "cal-from-protein": "43",
    "per-cal-from-protein": "25",
    "potassium": "333",
    "calcium": "46",
    "iron": "1.2",
    "phosphorus": "459",
    "source": "N Buddy",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "185",
    "type": "snack",
    "|| name                                               ||": "Green / red bean soup, without coconut milk",
    "rating": "3",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "without coconut milk",
    "volume-ml": "240",
    "calories": "132.2",
    "fat": "1",
    "cal-from-fat": "9",
    "per-cal-from-fat": "7",
    "saturated": "0",
    "polyunsaturated": "0.5",
    "monounsaturated": "0.5",
    "cholesterol": "0",
    "sodium": "2",
    "carbs": "22.6",
    "fibre": "10.3",
    "sugar": "8.2",
    "protein": "8.2",
    "cal-from-protein": "33",
    "per-cal-from-protein": "25",
    "potassium": "382",
    "calcium": "43",
    "iron": "1.2",
    "phosphorus": "127",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "186",
    "type": "snack",
    "|| name                                               ||": "Green / red bean soup with coconut milk",
    "rating": "2",
    "high-fibre": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "with coconut milk",
    "volume-ml": "240",
    "calories": "252.9",
    "fat": "7.7",
    "cal-from-fat": "69.3",
    "per-cal-from-fat": "27",
    "saturated": "6.2",
    "polyunsaturated": "0.7",
    "monounsaturated": "0.4",
    "cholesterol": "0",
    "sodium": "45",
    "carbs": "34.8",
    "fibre": "10.4",
    "sugar": "15.4",
    "protein": "11.1",
    "cal-from-protein": "44",
    "per-cal-from-protein": "18",
    "potassium": "698",
    "calcium": "43",
    "iron": "2.7",
    "phosphorus": "204",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "654",
    "type": "meal",
    "|| name                                               ||": "Green marrow, stir-fried",
    "rating": "3",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "info-translation": "Mao Gua / hairy melon",
    "alternate-spellings": "\nGua: Gourd",
    "weight-g": "100",
    "calories": "12.6",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "14",
    "saturated": "0.7",
    "polyunsaturated": "0.8",
    "monounsaturated": "2.8",
    "cholesterol": "57.3",
    "sodium": "492",
    "carbs": "2",
    "fibre": "1.7",
    "sugar": "1.7",
    "protein": "0.7",
    "cal-from-protein": "3",
    "per-cal-from-protein": "22",
    "potassium": "251",
    "calcium": "12",
    "iron": "0.3",
    "phosphorus": "0",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "276",
    "type": "meal",
    "|| name                                               ||": "Green thai salad",
    "rating": "2",
    "fruit": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "|| info                                                             ||": "Green papaya / mango salad",
    "info-examples": "green papaya / mango",
    "weight-g": "201",
    "calories": "211.6",
    "fat": "8.4",
    "cal-from-fat": "75.6",
    "per-cal-from-fat": "36",
    "saturated": "1.7",
    "polyunsaturated": "1.5",
    "monounsaturated": "4.8",
    "cholesterol": "0",
    "sodium": "178",
    "carbs": "30.1",
    "fibre": "3.3",
    "sugar": "20.6",
    "protein": "3.9",
    "cal-from-protein": "16",
    "per-cal-from-protein": "7",
    "iron": "0.2",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "421",
    "type": "meal",
    "|| name                                               ||": "Guacamole",
    "rating": "3",
    "healthy-fats": "1",
    "high-potassium": "1",
    "serving": "2",
    "|| unit ||": "tbsp",
    "weight-g": "30",
    "calories": "60.7",
    "fat": "6.3",
    "cal-from-fat": "56.7",
    "per-cal-from-fat": "93",
    "saturated": "1.4",
    "polyunsaturated": "2.5",
    "monounsaturated": "2.5",
    "cholesterol": "5",
    "sodium": "178",
    "carbs": "0.4",
    "fibre": "0.9",
    "sugar": "0.4",
    "protein": "0.6",
    "cal-from-protein": "2",
    "per-cal-from-protein": "4",
    "potassium": "30",
    "calcium": "0",
    "iron": "0",
    "phosphorus": "0",
    "source": "N Buddy",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "386",
    "type": "snack",
    "|| name                                               ||": "Guava",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "serving": "1",
    "|| unit ||": "fruit",
    "weight-g": "100",
    "calories": "46.2",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "4",
    "saturated": "0.1",
    "polyunsaturated": "0.1",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "23",
    "carbs": "10",
    "fibre": "4.9",
    "sugar": "9.8",
    "protein": "1.1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "10",
    "potassium": "29",
    "calcium": "33",
    "iron": "1.2",
    "phosphorus": "15",
    "source": "N Buddy",
    "old units (ref)": "small"
  },
  {
    "id": "522",
    "type": "snack",
    "|| name                                               ||": "Gulab jamun",
    "rating": "1",
    "fried": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Deep-fried milk balls with sugar syrup",
    "info-translation": "Deep-fried milk balls with sugar syrup",
    "weight-g": "77",
    "calories": "190.9",
    "fat": "4.5",
    "cal-from-fat": "40.5",
    "per-cal-from-fat": "21",
    "saturated": "2",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.9",
    "cholesterol": "10",
    "sodium": "113",
    "carbs": "33.9",
    "fibre": "0.4",
    "sugar": "31.5",
    "protein": "3.7",
    "cal-from-protein": "15",
    "per-cal-from-protein": "8",
    "potassium": "169",
    "calcium": "107",
    "iron": "0.2",
    "phosphorus": "74",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "505",
    "type": "meal",
    "|| name                                               ||": "Gyoza",
    "rating": "2",
    "high-purine": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Japanese pan-fried dumplings, guo tie",
    "info-translation": "Japanese pan-fried dumplings / Guo Tie",
    "weight-g": "18",
    "calories": "31.8",
    "fat": "1.4",
    "cal-from-fat": "12.6",
    "per-cal-from-fat": "40",
    "saturated": "0.5",
    "polyunsaturated": "0.3",
    "monounsaturated": "0.6",
    "cholesterol": "8",
    "sodium": "68",
    "carbs": "3.3",
    "fibre": "0.4",
    "sugar": "0.2",
    "protein": "1.5",
    "cal-from-protein": "6",
    "per-cal-from-protein": "19",
    "potassium": "27",
    "calcium": "6",
    "iron": "0.1",
    "phosphorus": "12",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "278",
    "type": "meal",
    "|| name                                               ||": "Gyudon",
    "rating": "2",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Japanese beef bowl",
    "info-translation": "Japanese beef bowl",
    "weight-g": "422",
    "calories": "567.4",
    "fat": "12.6",
    "cal-from-fat": "113.4",
    "per-cal-from-fat": "20",
    "saturated": "4.4",
    "polyunsaturated": "2.6",
    "monounsaturated": "4.5",
    "trans": "0.4",
    "cholesterol": "93",
    "sodium": "1376",
    "carbs": "76",
    "fibre": "2.8",
    "sugar": "7.8",
    "protein": "37.5",
    "cal-from-protein": "150",
    "per-cal-from-protein": "26",
    "potassium": "688",
    "calcium": "33",
    "iron": "8.2",
    "phosphorus": "391",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "546",
    "type": "meal",
    "|| name                                               ||": "Hae bee hiam",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "serving": "2",
    "|| unit ||": "tsp",
    "|| info                                                             ||": "Spicy dried shrimps sambal",
    "info-translation": "Spicy dried shrimps sambal",
    "weight-g": "12",
    "calories": "54.9",
    "fat": "4.5",
    "cal-from-fat": "40.5",
    "per-cal-from-fat": "74",
    "saturated": "1.9",
    "polyunsaturated": "0.5",
    "monounsaturated": "1.9",
    "cholesterol": "22",
    "sodium": "301",
    "carbs": "0.9",
    "fibre": "0.2",
    "sugar": "0.9",
    "protein": "2.7",
    "cal-from-protein": "11",
    "per-cal-from-protein": "20",
    "potassium": "26",
    "calcium": "35",
    "iron": "0.4",
    "phosphorus": "18",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "279",
    "disabled": "1",
    "type": "meal",
    "|| name                                               ||": "Haemul pajeon",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Korean seafood vegetable pancake, cut into 8 slices",
    "info-translation": "Korean seafood vegetable pancake",
    "weight-g": "39",
    "calories": "78.1",
    "fat": "3.3",
    "cal-from-fat": "29.7",
    "per-cal-from-fat": "38",
    "saturated": "1.5",
    "polyunsaturated": "0.5",
    "monounsaturated": "1.4",
    "sodium": "159",
    "carbs": "9.2",
    "sugar": "0.6",
    "protein": "2.9",
    "cal-from-protein": "12",
    "per-cal-from-protein": "15",
    "calcium": "16",
    "iron": "0.3",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "280",
    "type": "meal",
    "|| name                                               ||": "Hainanese beef noodle",
    "rating": "2",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "391",
    "calories": "395.1",
    "fat": "4.3",
    "cal-from-fat": "38.7",
    "per-cal-from-fat": "10",
    "saturated": "2",
    "polyunsaturated": "0.8",
    "monounsaturated": "1.2",
    "cholesterol": "35",
    "sodium": "1775",
    "carbs": "72.3",
    "fibre": "5.5",
    "sugar": "3",
    "protein": "16.8",
    "cal-from-protein": "67",
    "per-cal-from-protein": "17",
    "calcium": "47",
    "iron": "4.3",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "450",
    "type": "meal",
    "|| name                                               ||": "Hainanese curry rice",
    "rating": "1",
    "high-fat": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "400",
    "calories": "614",
    "fat": "25.9",
    "cal-from-fat": "233.1",
    "per-cal-from-fat": "38",
    "saturated": "8.7",
    "polyunsaturated": "4.2",
    "monounsaturated": "12.9",
    "sodium": "1051",
    "carbs": "75.5",
    "fibre": "4",
    "sugar": "5.7",
    "protein": "20",
    "cal-from-protein": "80",
    "per-cal-from-protein": "13",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "119",
    "type": "meal",
    "|| name                                               ||": "Ham",
    "rating": "1",
    "high-cholesterol": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "red-meat": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "32",
    "calories": "30.3",
    "fat": "1.5",
    "cal-from-fat": "13.5",
    "per-cal-from-fat": "45",
    "saturated": "0.6",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.7",
    "cholesterol": "10",
    "sodium": "369",
    "carbs": "0.4",
    "fibre": "0",
    "sugar": "0",
    "protein": "3.8",
    "cal-from-protein": "15",
    "per-cal-from-protein": "50",
    "potassium": "36",
    "calcium": "16",
    "iron": "0.7",
    "phosphorus": "40",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "547",
    "type": "drink",
    "|| name                                               ||": "Hard liquor",
    "rating": "1",
    "alcohol": "1",
    "high-purine": "1",
    "serving": "1",
    "|| unit ||": "shot",
    "|| info                                                             ||": "e.g. whisky, tequila, brandy, gin, vodka, rum",
    "info-examples": "whisky, tequila, brandy, gin, vodka, rum",
    "volume-ml": "42",
    "calories": "70",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "0.042",
    "fibre": "0",
    "sugar": "0.042",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "0.42",
    "calcium": "0",
    "iron": "0.008",
    "phosphorus": "1.26",
    "source": "USDA",
    "old units (ref)": "shot"
  },
  {
    "id": "819",
    "type": "snack",
    "|| name                                               ||": "Hashbrown",
    "rating": "2",
    "fried": "1",
    "high-fat": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "53",
    "calories": "155",
    "fat": "10",
    "cal-from-fat": "90",
    "per-cal-from-fat": "58",
    "saturated": "4.1",
    "polyunsaturated": "1.1",
    "monounsaturated": "4.5",
    "cholesterol": "0",
    "sodium": "330",
    "carbs": "14.9",
    "fibre": "2",
    "sugar": "0.2",
    "protein": "1.1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "3",
    "potassium": "197",
    "calcium": "6",
    "iron": "0.3",
    "phosphorus": "51",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "1000",
    "type": "snack",
    "|| name                                               ||": "Hashima, reduced sugar",
    "rating": "2",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "snow jelly soup, xue ge",
    "info-translation": "Snow jelly soup, Xue Ge",
    "alternate-spellings": "Hashima: Hasma, Harsmar",
    "weight-g": "175",
    "calories": "66.2",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "3",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "1.9",
    "carbs": "15.6",
    "fibre": "0.9",
    "sugar": "7.4",
    "protein": "0.5",
    "cal-from-protein": "2",
    "per-cal-from-protein": "3",
    "potassium": "32",
    "calcium": "0",
    "iron": "0",
    "phosphorus": "4",
    "source": "N Buddy",
    "old units (ref)": "bottle"
  },
  {
    "id": "999",
    "type": "snack",
    "|| name                                               ||": "Hashima, with sugar",
    "rating": "2",
    "high-gi": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "snow jelly soup, xue ge",
    "info-translation": "Snow jelly soup, Xue Ge",
    "alternate-spellings": "Hashima: Hasma, Harsmar",
    "weight-g": "175",
    "calories": "101",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "2",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "1.8",
    "carbs": "23.9",
    "fibre": "0.5",
    "sugar": "15.6",
    "protein": "0.9",
    "cal-from-protein": "4",
    "per-cal-from-protein": "4",
    "potassium": "32",
    "calcium": "0",
    "iron": "0",
    "phosphorus": "4",
    "source": "HPB",
    "old units (ref)": "bottle"
  },
  {
    "id": "1026",
    "type": "snack",
    "|| name                                               ||": "Hazelnut cake",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "61",
    "calories": "220",
    "fat": "12",
    "cal-from-fat": "108",
    "per-cal-from-fat": "49",
    "saturated": "5.7",
    "polyunsaturated": "1",
    "monounsaturated": "4.8",
    "cholesterol": "39",
    "sodium": "131",
    "carbs": "26.2",
    "fibre": "1.2",
    "sugar": "18.1",
    "protein": "3.6",
    "cal-from-protein": "14",
    "per-cal-from-protein": "7",
    "potassium": "105",
    "calcium": "29",
    "iron": "1.2",
    "phosphorus": "311",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "721",
    "type": "snack",
    "|| name                                               ||": "Seeds, mixed",
    "rating": "3",
    "healthy-fats": "1",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "|| info                                                             ||": "Hemp seeds, pumpkin seeds",
    "weight-g": "15",
    "calories": "100",
    "fat": "8",
    "cal-from-fat": "72",
    "per-cal-from-fat": "72",
    "saturated": "1",
    "cholesterol": "0",
    "sodium": "2",
    "carbs": "1.8",
    "fibre": "0.6",
    "sugar": "0.2",
    "protein": "4.2",
    "cal-from-protein": "17",
    "per-cal-from-protein": "17",
    "source": "N Buddy",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "1006",
    "type": "snack",
    "|| name                                               ||": "Herbal jelly",
    "rating": "3",
    "high-iron": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-translation": "Gui Ling Gao",
    "weight-g": "237",
    "calories": "70",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "21",
    "carbs": "17.3",
    "fibre": "0",
    "sugar": "15.6",
    "protein": "0.2",
    "cal-from-protein": "1",
    "per-cal-from-protein": "1",
    "potassium": "192",
    "calcium": "26",
    "iron": "2.8",
    "phosphorus": "14",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "176",
    "type": "drink",
    "|| name                                               ||": "Herbal tea, sweetened",
    "rating": "2",
    "high-gi": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bottle",
    "|| info                                                             ||": "e.g. Luo han guo drink",
    "info-examples": "chrysanthemum, ginseng",
    "volume-ml": "500",
    "calories": "140",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "trans": "0",
    "carbs": "34",
    "sugar": "34",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "367",
    "type": "drink",
    "|| name                                               ||": "Herbal tea, unsweetened",
    "rating": "3",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "bottle",
    "info-examples": "chamomile, peppermint",
    "volume-ml": "500",
    "calories": "4",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "4",
    "carbs": "1.1",
    "fibre": "0",
    "sugar": "0",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "44",
    "calcium": "11",
    "iron": "0.4",
    "phosphorus": "0",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "281",
    "type": "meal",
    "|| name                                               ||": "Hokkien mee",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Singapore style hokkien mee",
    "info-translation": "Fried prawn noodles",
    "weight-g": "442",
    "calories": "521",
    "fat": "19",
    "cal-from-fat": "171",
    "per-cal-from-fat": "33",
    "saturated": "7.3",
    "polyunsaturated": "2.7",
    "monounsaturated": "7.7",
    "cholesterol": "133",
    "sodium": "1423",
    "carbs": "69.4",
    "fibre": "4.4",
    "sugar": "17.2",
    "protein": "18.1",
    "cal-from-protein": "72",
    "per-cal-from-protein": "14",
    "potassium": "212",
    "calcium": "102",
    "iron": "3.1",
    "phosphorus": "181",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "476",
    "type": "snack",
    "|| name                                               ||": "Honey sea coconut",
    "rating": "2",
    "high-gi": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "240",
    "calories": "193.6",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "16",
    "carbs": "47.8",
    "fibre": "0.3",
    "sugar": "35.4",
    "protein": "0.6",
    "cal-from-protein": "2",
    "per-cal-from-protein": "1",
    "potassium": "50",
    "calcium": "10",
    "iron": "0.4",
    "phosphorus": "9",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "387",
    "type": "snack",
    "|| name                                               ||": "Honeydew, cantaloupe, rockmelon",
    "rating": "3",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "125",
    "calories": "49.875",
    "fat": "0.175",
    "cal-from-fat": "1.575",
    "per-cal-from-fat": "3",
    "saturated": "0.048",
    "polyunsaturated": "0.074",
    "monounsaturated": "0.004",
    "cholesterol": "0",
    "sodium": "22.5",
    "carbs": "11.4",
    "fibre": "1",
    "sugar": "10.2",
    "protein": "0.675",
    "cal-from-protein": "3",
    "per-cal-from-protein": "5",
    "potassium": "285",
    "calcium": "7.5",
    "iron": "0.212",
    "phosphorus": "13.8",
    "source": "USDA",
    "old units (ref)": "slice"
  },
  {
    "|| name                                               ||": "Horlicks",
    "|| info                                                             ||": "with condensed milk",
    "info-include-exclude": "with condensed milk"
  },
  {
    "id": "579",
    "type": "drink",
    "|| name                                               ||": "Horlicks C",
    "rating": "2",
    "healthy-fats": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "with evaporated milk",
    "info-include-exclude": "with evaporated milk",
    "weight-g": "250",
    "calories": "95.7",
    "fat": "1.7",
    "cal-from-fat": "15.3",
    "per-cal-from-fat": "16",
    "saturated": "1",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.5",
    "cholesterol": "3",
    "sodium": "92",
    "carbs": "16.8",
    "fibre": "0",
    "sugar": "11.8",
    "protein": "3.3",
    "cal-from-protein": "13",
    "per-cal-from-protein": "14",
    "potassium": "134",
    "calcium": "86",
    "iron": "0.3",
    "phosphorus": "60",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "578",
    "type": "drink",
    "|| name                                               ||": "Horlicks O",
    "rating": "2",
    "healthy-fats": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "no milk or sugar",
    "info-include-exclude": "without milk or sugar",
    "weight-g": "250",
    "calories": "127.6",
    "fat": "0.8",
    "cal-from-fat": "7.2",
    "per-cal-from-fat": "6",
    "saturated": "0.4",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.2",
    "cholesterol": "0",
    "sodium": "92",
    "carbs": "27.6",
    "fibre": "0",
    "sugar": "22.6",
    "protein": "2.5",
    "cal-from-protein": "10",
    "per-cal-from-protein": "8",
    "potassium": "134",
    "calcium": "86",
    "iron": "0.3",
    "phosphorus": "60",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "14",
    "type": "drink",
    "|| name                                               ||": "Horlicks, 3-in-1",
    "rating": "1",
    "high-calcium": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "sachet",
    "|| info                                                             ||": "3-in-1",
    "weight-g": "25",
    "volume-ml": "200",
    "calories": "106.7",
    "fat": "1.1",
    "cal-from-fat": "9.9",
    "per-cal-from-fat": "9",
    "saturated": "0.8",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.1",
    "cholesterol": "0",
    "sodium": "46",
    "carbs": "21.1",
    "fibre": "0.5",
    "sugar": "14.8",
    "protein": "3.1",
    "cal-from-protein": "12",
    "per-cal-from-protein": "12",
    "potassium": "170",
    "calcium": "532",
    "iron": "3.6",
    "phosphorus": "250",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "sachet"
  },
  {
    "id": "153",
    "type": "snack",
    "|| name                                               ||": "Hot dog with sauce",
    "rating": "1",
    "high-cholesterol": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "includes bun",
    "info-include-exclude": "with bun",
    "weight-g": "183",
    "calories": "372.2",
    "fat": "15.4",
    "cal-from-fat": "138.6",
    "per-cal-from-fat": "37",
    "saturated": "3.6",
    "polyunsaturated": "6.3",
    "monounsaturated": "5",
    "cholesterol": "21",
    "sodium": "1896",
    "carbs": "40.7",
    "fibre": "3",
    "sugar": "9.5",
    "protein": "17.7",
    "cal-from-protein": "71",
    "per-cal-from-protein": "19",
    "potassium": "707",
    "calcium": "66",
    "iron": "3.7",
    "phosphorus": "722",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "120",
    "type": "meal",
    "|| name                                               ||": "Hot dog, without sauce",
    "rating": "1",
    "high-cholesterol": "1",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "includes bun",
    "info-include-exclude": "with bun",
    "weight-g": "123",
    "calories": "261.1",
    "fat": "8.3",
    "cal-from-fat": "74.7",
    "per-cal-from-fat": "29",
    "saturated": "2.9",
    "polyunsaturated": "2.1",
    "monounsaturated": "2.9",
    "cholesterol": "14",
    "sodium": "1249",
    "carbs": "30.7",
    "fibre": "1.8",
    "sugar": "1.5",
    "protein": "15.9",
    "cal-from-protein": "64",
    "per-cal-from-protein": "24",
    "potassium": "648",
    "calcium": "43",
    "iron": "3",
    "phosphorus": "684",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "409",
    "type": "snack",
    "|| name                                               ||": "Hummus",
    "rating": "3",
    "plant-protein": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "15",
    "calories": "37.71",
    "fat": "2.67",
    "cal-from-fat": "24.03",
    "per-cal-from-fat": "64",
    "saturated": "0.384",
    "polyunsaturated": "1.32",
    "monounsaturated": "0.801",
    "cholesterol": "0",
    "sodium": "63.9",
    "carbs": "2.25",
    "fibre": "0.825",
    "sugar": "0.093",
    "protein": "1.17",
    "cal-from-protein": "5",
    "per-cal-from-protein": "12",
    "potassium": "46.8",
    "calcium": "7.05",
    "iron": "0.381",
    "phosphorus": "27.2",
    "source": "USDA",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "348",
    "disabled": "1",
    "type": "meal",
    "|| name                                               ||": "Ice",
    "rating": "2"
  },
  {
    "id": "573",
    "type": "drink",
    "|| name                                               ||": "Ice blended coffee with cream",
    "rating": "1",
    "high-calcium": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "glass",
    "|| info                                                             ||": "e.g. Starbucks frappucino, small cup",
    "info-examples": "frappuccino",
    "weight-g": "372",
    "calories": "299.98",
    "fat": "10.1",
    "cal-from-fat": "90.9",
    "per-cal-from-fat": "30",
    "saturated": "6.44",
    "polyunsaturated": "0.465",
    "monounsaturated": "2.62",
    "cholesterol": "33.5",
    "sodium": "138",
    "carbs": "46.5",
    "fibre": "0",
    "sugar": "43.7",
    "protein": "5.77",
    "cal-from-protein": "23",
    "per-cal-from-protein": "8",
    "potassium": "339",
    "calcium": "205",
    "iron": "0.112",
    "phosphorus": "179",
    "source": "USDA",
    "old units (ref)": "glass"
  },
  {
    "id": "572",
    "type": "drink",
    "|| name                                               ||": "Ice blended coffee, without cream",
    "rating": "1",
    "high-calcium": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "glass",
    "|| info                                                             ||": "e.g. Starbucks frappucino, small cup",
    "info-examples": "frappuccino",
    "weight-g": "372",
    "calories": "246.46",
    "fat": "3.38",
    "cal-from-fat": "30.42",
    "per-cal-from-fat": "12",
    "saturated": "2.13",
    "polyunsaturated": "0.171",
    "monounsaturated": "0.926",
    "cholesterol": "14.9",
    "sodium": "138",
    "carbs": "48.5",
    "fibre": "0",
    "sugar": "45.5",
    "protein": "5.51",
    "cal-from-protein": "22",
    "per-cal-from-protein": "9",
    "potassium": "339",
    "calcium": "201",
    "iron": "0.112",
    "phosphorus": "175",
    "source": "USDA",
    "old units (ref)": "glass"
  },
  {
    "id": "829",
    "type": "snack",
    "|| name                                               ||": "Ice cream pie",
    "rating": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "|| info                                                             ||": "e.g. Hershey's sundae pie",
    "info-brands": "Hershey's sundae pie",
    "weight-g": "79",
    "calories": "310",
    "fat": "19",
    "cal-from-fat": "171",
    "per-cal-from-fat": "55",
    "saturated": "12",
    "cholesterol": "10",
    "sodium": "220",
    "carbs": "32",
    "fibre": "1",
    "sugar": "22",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "4",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "213",
    "type": "snack",
    "|| name                                               ||": "Ice cream with cone",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cone",
    "|| info                                                             ||": "e.g. Cornetto Royale, single scoop with cone",
    "info-brands": "Cornetto Royale",
    "weight-g": "88",
    "calories": "233",
    "fat": "9",
    "cal-from-fat": "81",
    "per-cal-from-fat": "35",
    "saturated": "8",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "sodium": "80",
    "carbs": "35",
    "fibre": "1",
    "sugar": "22",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "5",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "cone"
  },
  {
    "id": "37",
    "type": "snack",
    "|| name                                               ||": "Ice cream, fried",
    "rating": "1",
    "fried": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "133",
    "calories": "375",
    "fat": "20.2",
    "cal-from-fat": "181.8",
    "per-cal-from-fat": "48",
    "saturated": "8.9",
    "polyunsaturated": "3.6",
    "monounsaturated": "6.3",
    "cholesterol": "45",
    "sodium": "198",
    "carbs": "43.6",
    "fibre": "1.6",
    "sugar": "27.8",
    "protein": "5.2",
    "cal-from-protein": "21",
    "per-cal-from-protein": "6",
    "potassium": "245",
    "calcium": "149",
    "iron": "1.1",
    "phosphorus": "153",
    "iodine": "1",
    "source": "USDA",
    "old units (ref)": "scoop"
  },
  {
    "id": "535",
    "type": "snack",
    "|| name                                               ||": "Ice cream, low calorie",
    "rating": "2",
    "high-iodine": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "scoop",
    "|| info                                                             ||": "e.g. Callery's ice-cream",
    "info-brands": "Callery's",
    "weight-g": "100",
    "calories": "100",
    "fat": "6.4",
    "cal-from-fat": "57.6",
    "per-cal-from-fat": "58",
    "saturated": "4.2",
    "trans": "0.1",
    "cholesterol": "9.7",
    "sodium": "107",
    "carbs": "24.3",
    "fibre": "7.8",
    "sugar": "4.2",
    "protein": "3.8",
    "cal-from-protein": "15",
    "per-cal-from-protein": "15",
    "source": "N Buddy",
    "old units (ref)": "scoop"
  },
  {
    "id": "536",
    "type": "snack",
    "|| name                                               ||": "Ice cream, low sugar",
    "rating": "2",
    "high-iodine": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "scoop",
    "weight-g": "100",
    "calories": "144",
    "fat": "8.6",
    "cal-from-fat": "77.4",
    "per-cal-from-fat": "54",
    "saturated": "5.7",
    "trans": "0",
    "cholesterol": "7",
    "carbs": "13",
    "fibre": "6.5",
    "sugar": "5.6",
    "protein": "3.8",
    "cal-from-protein": "15",
    "per-cal-from-protein": "11",
    "source": "Sugalight website",
    "old units (ref)": "scoop"
  },
  {
    "id": "211",
    "type": "snack",
    "|| name                                               ||": "Ice cream, regular",
    "rating": "2",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "scoop",
    "weight-g": "50",
    "calories": "104",
    "fat": "5.5",
    "cal-from-fat": "49.5",
    "per-cal-from-fat": "48",
    "saturated": "3.4",
    "polyunsaturated": "0.2",
    "monounsaturated": "1.5",
    "cholesterol": "22",
    "sodium": "40",
    "carbs": "11.8",
    "fibre": "0.4",
    "sugar": "10.6",
    "protein": "1.8",
    "cal-from-protein": "7",
    "per-cal-from-protein": "7",
    "potassium": "100",
    "calcium": "64",
    "iron": "0",
    "phosphorus": "53",
    "iodine": "1",
    "source": "USDA",
    "old units (ref)": "scoop"
  },
  {
    "id": "537",
    "type": "snack",
    "|| name                                               ||": "Ice cream, sorbet",
    "rating": "2",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "200",
    "calories": "189.2",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "16",
    "carbs": "46.3",
    "fibre": "0.2",
    "sugar": "40",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "2",
    "potassium": "200",
    "calcium": "18",
    "iron": "0.94",
    "phosphorus": "26",
    "source": "USDA",
    "old units (ref)": "scoop"
  },
  {
    "id": "1008",
    "type": "snack",
    "|| name                                               ||": "Ice jelly",
    "rating": "2",
    "high-gi": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Lemongrass / aiyu / lime jelly",
    "info-examples": "lemongrass, aiyu, lime",
    "weight-g": "250",
    "calories": "95",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "23.5",
    "fibre": "1.5",
    "sugar": "17.5",
    "protein": "0.3",
    "cal-from-protein": "1",
    "per-cal-from-protein": "1",
    "calcium": "15",
    "iron": "0.5",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "28",
    "type": "snack",
    "|| name                                               ||": "Ice kacang",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "350",
    "calories": "181.6",
    "fat": "0.4",
    "cal-from-fat": "3.6",
    "per-cal-from-fat": "2",
    "saturated": "0.2",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.1",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "40.3",
    "fibre": "4",
    "sugar": "33.3",
    "protein": "4.2",
    "cal-from-protein": "17",
    "per-cal-from-protein": "9",
    "calcium": "70",
    "iron": "1.4",
    "phosphorus": "70",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "460",
    "type": "meal",
    "|| name                                               ||": "Ikan assam pedas",
    "rating": "2",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "|| info                                                             ||": "Sour and spicy fish stew",
    "info-translation": "Sour and spicy fish stew",
    "alternate-spellings": "Assam: Asam",
    "weight-g": "227",
    "calories": "219.25",
    "fat": "6.49",
    "cal-from-fat": "58.41",
    "per-cal-from-fat": "27",
    "saturated": "3.73",
    "polyunsaturated": "0.32",
    "monounsaturated": "1.62",
    "cholesterol": "107.01",
    "sodium": "937.19",
    "carbs": "3.57",
    "fibre": "3.24",
    "sugar": "3.57",
    "protein": "36.64",
    "cal-from-protein": "147",
    "per-cal-from-protein": "67",
    "potassium": "630.74",
    "calcium": "95.66",
    "iron": "1.135",
    "phosphorus": "316.18",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "462",
    "type": "meal",
    "|| name                                               ||": "Ikan bakar",
    "rating": "2",
    "high-iodine": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Grilled fish",
    "info-translation": "Grilled fish",
    "weight-g": "94",
    "calories": "153.1",
    "fat": "4.7",
    "cal-from-fat": "42.3",
    "per-cal-from-fat": "28",
    "saturated": "0.9",
    "polyunsaturated": "2.4",
    "monounsaturated": "1.4",
    "cholesterol": "70",
    "sodium": "225",
    "carbs": "0.5",
    "fibre": "0",
    "sugar": "0.5",
    "protein": "27.2",
    "cal-from-protein": "109",
    "per-cal-from-protein": "71",
    "potassium": "416",
    "calcium": "127",
    "iron": "1.3",
    "phosphorus": "211",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "66",
    "type": "snack",
    "|| name                                               ||": "Rice kheer",
    "rating": "1",
    "high-calcium": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Rice kheer",
    "info-translation": "Indian rice pudding",
    "weight-g": "218",
    "calories": "352.1",
    "fat": "13.7",
    "cal-from-fat": "123.3",
    "per-cal-from-fat": "35",
    "saturated": "6.7",
    "polyunsaturated": "1.1",
    "monounsaturated": "5.9",
    "cholesterol": "26",
    "sodium": "137",
    "carbs": "43",
    "fibre": "3.1",
    "sugar": "15.5",
    "protein": "14.2",
    "cal-from-protein": "57",
    "per-cal-from-protein": "16",
    "potassium": "390",
    "calcium": "438",
    "iron": "0.7",
    "phosphorus": "268",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "154",
    "type": "meal",
    "|| name                                               ||": "Indian rojak, deep fried",
    "rating": "1",
    "fried": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "120",
    "calories": "203",
    "fat": "15.7",
    "cal-from-fat": "141.3",
    "per-cal-from-fat": "70",
    "saturated": "4",
    "polyunsaturated": "6.7",
    "monounsaturated": "4.4",
    "sodium": "198",
    "carbs": "20",
    "fibre": "1",
    "sugar": "0",
    "protein": "10",
    "cal-from-protein": "40",
    "per-cal-from-protein": "20",
    "potassium": "112",
    "calcium": "217",
    "iron": "2",
    "phosphorus": "178",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "177",
    "type": "drink",
    "|| name                                               ||": "Instant cereal oat drink, 3-in-1",
    "rating": "2",
    "high-phosphate": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "sachet",
    "|| info                                                             ||": "e.g. Quaker 3-in-1 oat cereal drink",
    "info-brands": "Quaker, Super NutreMill",
    "weight-g": "28",
    "volume-ml": "150",
    "calories": "112.4",
    "fat": "2.8",
    "cal-from-fat": "25.2",
    "per-cal-from-fat": "22",
    "saturated": "0.9",
    "polyunsaturated": "0.6",
    "monounsaturated": "1.1",
    "cholesterol": "0",
    "sodium": "88",
    "carbs": "20",
    "fibre": "1.1",
    "sugar": "9.1",
    "protein": "1.8",
    "cal-from-protein": "7",
    "per-cal-from-protein": "6",
    "calcium": "360",
    "iron": "0.6",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "sachet"
  },
  {
    "id": "597",
    "type": "meal",
    "|| name                                               ||": "Instant bowl noodle",
    "rating": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-salt": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "e.g. Nongshim Kimchi Ramyun Flavour Instant Noodles",
    "info-brands": "Nissin, Myojo, Kang Shi Fu",
    "weight-g": "120",
    "calories": "505",
    "fat": "18",
    "cal-from-fat": "162",
    "per-cal-from-fat": "32",
    "saturated": "10",
    "sodium": "2576",
    "carbs": "76",
    "sugar": "2",
    "protein": "10",
    "cal-from-protein": "40",
    "per-cal-from-protein": "8",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "595",
    "type": "meal",
    "|| name                                               ||": "Instant cup noodle",
    "rating": "1",
    "high-gi": "1",
    "high-iron": "1",
    "high-salt": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "e.g. Nissin instant cup noodles seafood",
    "info-brands": "Nissin",
    "weight-g": "75",
    "calories": "335.2",
    "fat": "16.8",
    "cal-from-fat": "151.2",
    "per-cal-from-fat": "45",
    "saturated": "9.2",
    "polyunsaturated": "1.6",
    "monounsaturated": "5",
    "sodium": "1830",
    "carbs": "38.8",
    "fibre": "1",
    "sugar": "6",
    "protein": "7.2",
    "cal-from-protein": "29",
    "per-cal-from-protein": "9",
    "potassium": "160",
    "calcium": "40",
    "iron": "2.8",
    "phosphorus": "110",
    "source": "N Buddy",
    "old units (ref)": "packet"
  },
  {
    "id": "596",
    "type": "meal",
    "|| name                                               ||": "Instant noodle, korean style",
    "rating": "1",
    "high-gi": "1",
    "high-salt": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "packet",
    "|| info                                                             ||": "e.g. Samyang instant noodles",
    "info-brands": "Samyang, Nongshim",
    "weight-g": "470",
    "calories": "532",
    "fat": "16",
    "cal-from-fat": "144",
    "per-cal-from-fat": "27",
    "saturated": "8",
    "polyunsaturated": "4",
    "monounsaturated": "4",
    "sodium": "1200",
    "carbs": "85",
    "fibre": "1",
    "sugar": "7",
    "protein": "12",
    "cal-from-protein": "48",
    "per-cal-from-protein": "9",
    "potassium": "160",
    "calcium": "88",
    "iron": "2.8",
    "phosphorus": "110",
    "source": "N Buddy",
    "old units (ref)": "packet"
  },
  {
    "id": "594",
    "type": "meal",
    "|| name                                               ||": "Instant noodle, non-fried",
    "rating": "1",
    "high-gi": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "packet",
    "|| info                                                             ||": "e.g. Koka non-fried plain cjilli and lime instant noodles",
    "info-brands": "Koka, Woh Hup",
    "weight-g": "60",
    "calories": "253.6",
    "fat": "6",
    "cal-from-fat": "54",
    "per-cal-from-fat": "21",
    "saturated": "0.2",
    "polyunsaturated": "1.6",
    "monounsaturated": "3.4",
    "cholesterol": "0",
    "sodium": "570",
    "carbs": "41",
    "fibre": "2",
    "sugar": "4",
    "protein": "8.9",
    "cal-from-protein": "36",
    "per-cal-from-protein": "14",
    "potassium": "160",
    "calcium": "20",
    "iron": "1.1",
    "phosphorus": "110",
    "source": "N Buddy",
    "old units (ref)": "packet"
  },
  {
    "|| name                                               ||": "Instant noodles, dry"
  },
  {
    "id": "146",
    "type": "meal",
    "|| name                                               ||": "Instant noodles, soup",
    "rating": "1",
    "high-gi": "1",
    "high-iron": "1",
    "high-salt": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "e.g. Indomie, Myojo, Koka",
    "info-brands": "Indomie, Myojo, Koka",
    "weight-g": "85",
    "calories": "363",
    "fat": "15",
    "cal-from-fat": "135",
    "per-cal-from-fat": "37",
    "saturated": "6",
    "polyunsaturated": "4.5",
    "monounsaturated": "4.5",
    "sodium": "900",
    "carbs": "49",
    "fibre": "3",
    "sugar": "8",
    "protein": "8",
    "cal-from-protein": "32",
    "per-cal-from-protein": "9",
    "potassium": "160",
    "calcium": "88",
    "iron": "2.8",
    "phosphorus": "110",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "527",
    "type": "meal",
    "|| name                                               ||": "Ipoh hor fun",
    "rating": "2",
    "high-potassium": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "340",
    "calories": "342.1",
    "fat": "10.5",
    "cal-from-fat": "94.5",
    "per-cal-from-fat": "28",
    "saturated": "3.4",
    "polyunsaturated": "2",
    "monounsaturated": "4.8",
    "cholesterol": "54",
    "sodium": "1241",
    "carbs": "50",
    "fibre": "5.1",
    "sugar": "0",
    "protein": "11.9",
    "cal-from-protein": "48",
    "per-cal-from-protein": "14",
    "potassium": "173",
    "calcium": "24",
    "iron": "0",
    "phosphorus": "92",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "702",
    "type": "snack",
    "|| name                                               ||": "Jackfruit",
    "rating": "3",
    "fruit": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "seed",
    "|| info                                                             ||": "Jak, Jaca, Nangka",
    "info-translation": "Nangka",
    "alternate-spellings": "Jackfruit: Jak, Jaca",
    "weight-g": "41",
    "calories": "33.9",
    "fat": "0.3",
    "cal-from-fat": "2.7",
    "per-cal-from-fat": "8",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "7",
    "carbs": "7.6",
    "fibre": "0.8",
    "sugar": "6.3",
    "protein": "0.2",
    "cal-from-protein": "1",
    "per-cal-from-protein": "2",
    "potassium": "103",
    "calcium": "6",
    "iron": "0.2",
    "phosphorus": "9",
    "source": "N Buddy",
    "old units (ref)": "seeds"
  },
  {
    "id": "121",
    "type": "meal",
    "|| name                                               ||": "Jam",
    "rating": "2",
    "high-gi": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "20",
    "calories": "55.622",
    "fat": "0.014",
    "cal-from-fat": "0.126",
    "per-cal-from-fat": "0",
    "saturated": "0.002",
    "polyunsaturated": "0",
    "monounsaturated": "0.008",
    "cholesterol": "0",
    "sodium": "6.4",
    "carbs": "13.8",
    "fibre": "0.22",
    "sugar": "9.7",
    "protein": "0.074",
    "cal-from-protein": "0",
    "per-cal-from-protein": "1",
    "potassium": "15.4",
    "calcium": "4",
    "iron": "0.098",
    "phosphorus": "3.8",
    "source": "USDA",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "853",
    "type": "meal",
    "|| name                                               ||": "Japanese curry katsu udon",
    "rating": "2",
    "fried": "1",
    "high-fat": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "257",
    "calories": "572",
    "fat": "15.5",
    "cal-from-fat": "139.5",
    "per-cal-from-fat": "24",
    "saturated": "5.7",
    "polyunsaturated": "3.1",
    "monounsaturated": "5.5",
    "cholesterol": "85",
    "sodium": "980",
    "carbs": "71",
    "fibre": "1.1",
    "sugar": "1.5",
    "protein": "32.1",
    "cal-from-protein": "128",
    "per-cal-from-protein": "22",
    "potassium": "356",
    "calcium": "40",
    "iron": "1.9",
    "phosphorus": "250",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "352",
    "type": "meal",
    "|| name                                               ||": "Japanese curry rice with chicken",
    "rating": "2",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "546",
    "calories": "509",
    "fat": "20.1",
    "cal-from-fat": "180.9",
    "per-cal-from-fat": "36",
    "saturated": "8.9",
    "polyunsaturated": "2",
    "monounsaturated": "8.1",
    "trans": "0.3",
    "cholesterol": "109",
    "sodium": "978",
    "carbs": "74.1",
    "fibre": "5.6",
    "sugar": "12.7",
    "protein": "31",
    "cal-from-protein": "124",
    "per-cal-from-protein": "24",
    "potassium": "654",
    "calcium": "63",
    "iron": "2.3",
    "phosphorus": "318",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "122",
    "type": "meal",
    "|| name                                               ||": "Japanese curry rice with chicken katsu",
    "rating": "2",
    "high-phosphate": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "597",
    "calories": "701",
    "fat": "14.1",
    "cal-from-fat": "126.9",
    "per-cal-from-fat": "18",
    "saturated": "5.7",
    "polyunsaturated": "1.4",
    "monounsaturated": "2.2",
    "cholesterol": "109",
    "sodium": "922",
    "carbs": "70.3",
    "fibre": "1.6",
    "sugar": "3.6",
    "protein": "46.2",
    "cal-from-protein": "185",
    "per-cal-from-protein": "26",
    "potassium": "459",
    "calcium": "27",
    "iron": "4.3",
    "phosphorus": "371",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "852",
    "type": "meal",
    "|| name                                               ||": "Japanese curry udon",
    "rating": "2",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "524",
    "calories": "383",
    "fat": "10",
    "cal-from-fat": "90",
    "per-cal-from-fat": "23",
    "saturated": "5.8",
    "sodium": "1478",
    "carbs": "60.3",
    "fibre": "6.3",
    "protein": "13",
    "cal-from-protein": "52",
    "per-cal-from-protein": "14",
    "source": "HPB",
    "old units (ref)": "bowl"
  },
  {
    "id": "71",
    "type": "snack",
    "|| name                                               ||": "Japanese sweet mochi",
    "rating": "1",
    "high-gi": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "stick",
    "|| info                                                             ||": "with soy / green tea powder",
    "info-translation": "Daifuku",
    "info-examples": "soy, green tea, red bean",
    "weight-g": "51",
    "calories": "112.7",
    "fat": "0.3",
    "cal-from-fat": "2.7",
    "per-cal-from-fat": "2",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "83",
    "carbs": "25.3",
    "fibre": "0.7",
    "sugar": "11.7",
    "protein": "2.2",
    "cal-from-protein": "9",
    "per-cal-from-protein": "8",
    "potassium": "53",
    "calcium": "9",
    "iron": "0.2",
    "phosphorus": "31",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "282",
    "type": "meal",
    "|| name                                               ||": "Japchae",
    "rating": "2",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "|| info                                                             ||": "Korean stir-fried noodles",
    "info-translation": "Korean stir-fried glass noodles",
    "weight-g": "442",
    "calories": "503.9",
    "fat": "17.1",
    "cal-from-fat": "153.9",
    "per-cal-from-fat": "31",
    "saturated": "5.5",
    "polyunsaturated": "4.6",
    "monounsaturated": "7",
    "sodium": "2033",
    "carbs": "62.3",
    "fibre": "11.9",
    "sugar": "12.8",
    "protein": "25.2",
    "cal-from-protein": "101",
    "per-cal-from-protein": "20",
    "calcium": "77",
    "iron": "6.1",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "483",
    "type": "snack",
    "|| name                                               ||": "Jemput jemput pisang",
    "rating": "1",
    "fried": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Fried banana balls / cekodok pisang",
    "info-translation": "Fried banana balls / cekodok pisang",
    "weight-g": "76",
    "calories": "219.8",
    "fat": "7.8",
    "cal-from-fat": "70.2",
    "per-cal-from-fat": "32",
    "saturated": "3.5",
    "polyunsaturated": "0.8",
    "monounsaturated": "3.2",
    "cholesterol": "0",
    "sodium": "73",
    "carbs": "33.4",
    "fibre": "1.6",
    "sugar": "10.3",
    "protein": "4",
    "cal-from-protein": "16",
    "per-cal-from-protein": "7",
    "potassium": "161",
    "calcium": "13",
    "iron": "0.2",
    "phosphorus": "37",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "876",
    "type": "meal",
    "|| name                                               ||": "Jajangmyeon",
    "rating": "2",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Korean noodles in blackbean sauce",
    "info-translation": "Korean noodles in black bean sauce",
    "weight-g": "484",
    "calories": "627.8",
    "fat": "22.6",
    "cal-from-fat": "203.4",
    "per-cal-from-fat": "32",
    "saturated": "4.9",
    "polyunsaturated": "7.6",
    "monounsaturated": "8.1",
    "cholesterol": "80",
    "sodium": "1724",
    "carbs": "77.2",
    "fibre": "5.3",
    "sugar": "8.2",
    "protein": "28.9",
    "cal-from-protein": "116",
    "per-cal-from-protein": "18",
    "potassium": "847",
    "calcium": "58",
    "iron": "1.2",
    "phosphorus": "839",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "877",
    "type": "meal",
    "|| name                                               ||": "Jjamppong",
    "rating": "2",
    "high-cholesterol": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Korean spicy seafood noodle soup",
    "info-translation": "Korean spicy seafood noodle soup",
    "weight-g": "658",
    "calories": "478",
    "fat": "18.4",
    "cal-from-fat": "165.6",
    "per-cal-from-fat": "35",
    "saturated": "7.1",
    "polyunsaturated": "1.7",
    "monounsaturated": "6.5",
    "cholesterol": "145",
    "sodium": "1720",
    "carbs": "52",
    "fibre": "2.2",
    "sugar": "5.1",
    "protein": "26.1",
    "cal-from-protein": "104",
    "per-cal-from-protein": "22",
    "potassium": "610",
    "calcium": "144",
    "iron": "4",
    "phosphorus": "223",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "158",
    "type": "drink",
    "|| name                                               ||": "Juice, natural, unsweetened",
    "rating": "2",
    "fruit": "1",
    "high-gi": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "e.g. cold pressed juice",
    "info-include-exclude": "blended, cold pressed",
    "volume-ml": "366",
    "calories": "109.9",
    "fat": "0.3",
    "cal-from-fat": "2.7",
    "per-cal-from-fat": "2",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0.1",
    "cholesterol": "0",
    "sodium": "157",
    "carbs": "22.1",
    "fibre": "1",
    "sugar": "8.2",
    "protein": "4.7",
    "cal-from-protein": "19",
    "per-cal-from-protein": "17",
    "potassium": "892",
    "calcium": "64",
    "iron": "1.9",
    "phosphorus": "107",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "2",
    "type": "drink",
    "|| name                                               ||": "Juice, sweetened",
    "rating": "2",
    "high-gi": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "bottled / boxed / processed",
    "volume-ml": "249",
    "calories": "135.556",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0.025",
    "monounsaturated": "0.025",
    "cholesterol": "0",
    "sodium": "4.98",
    "carbs": "33.4",
    "fibre": "0.498",
    "sugar": "23.3",
    "protein": "0.489",
    "cal-from-protein": "2",
    "per-cal-from-protein": "1",
    "potassium": "105",
    "calcium": "4.98",
    "iron": "0.274",
    "phosphorus": "9.96",
    "source": "USDA",
    "old units (ref)": "cup"
  },
  {
    "id": "630",
    "type": "meal",
    "|| name                                               ||": "Kai lan, stir-fried",
    "rating": "3",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "alternate-spellings": "Kai: Gai",
    "weight-g": "100",
    "calories": "76",
    "fat": "5.4",
    "cal-from-fat": "48.6",
    "per-cal-from-fat": "64",
    "saturated": "0.6",
    "polyunsaturated": "3.2",
    "monounsaturated": "1.3",
    "cholesterol": "0",
    "sodium": "123",
    "carbs": "5.6",
    "fibre": "0.8",
    "sugar": "0",
    "protein": "1.9",
    "cal-from-protein": "8",
    "per-cal-from-protein": "10",
    "potassium": "228",
    "calcium": "72",
    "iron": "0.9",
    "phosphorus": "28",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "850",
    "type": "meal",
    "|| name                                               ||": "Kaisen don",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-translation": "Japanese seafood rice bowl",
    "weight-g": "409",
    "calories": "552",
    "fat": "15.2",
    "cal-from-fat": "136.8",
    "per-cal-from-fat": "25",
    "saturated": "5.7",
    "polyunsaturated": "2.8",
    "monounsaturated": "6.5",
    "trans": "0.1",
    "sodium": "1472",
    "carbs": "85.9",
    "fibre": "10.6",
    "sugar": "5.3",
    "protein": "32.7",
    "cal-from-protein": "131",
    "per-cal-from-protein": "24",
    "calcium": "52",
    "iron": "1.3",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "670",
    "type": "meal",
    "|| name                                               ||": "Kale, stir-fried",
    "rating": "3",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "100",
    "calories": "76",
    "fat": "5.4",
    "cal-from-fat": "48.6",
    "per-cal-from-fat": "64",
    "saturated": "0.6",
    "polyunsaturated": "3.2",
    "monounsaturated": "1.3",
    "cholesterol": "0",
    "sodium": "123",
    "carbs": "5.6",
    "fibre": "0.8",
    "sugar": "0",
    "protein": "1.9",
    "cal-from-protein": "8",
    "per-cal-from-protein": "10",
    "potassium": "228",
    "calcium": "72",
    "iron": "0.9",
    "phosphorus": "28",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "628",
    "type": "meal",
    "|| name                                               ||": "Kang kung, stir-fried",
    "rating": "3",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "info-translation": "Water spinach",
    "alternate-spellings": "Kung: Kong",
    "weight-g": "100",
    "calories": "76",
    "fat": "5.2",
    "cal-from-fat": "46.8",
    "per-cal-from-fat": "62",
    "saturated": "0.5",
    "polyunsaturated": "3",
    "monounsaturated": "1.3",
    "cholesterol": "0",
    "sodium": "382",
    "carbs": "4.8",
    "fibre": "1.3",
    "sugar": "4.4",
    "protein": "2.8",
    "cal-from-protein": "11",
    "per-cal-from-protein": "15",
    "potassium": "83",
    "calcium": "75",
    "iron": "4.4",
    "phosphorus": "13",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "859",
    "type": "meal",
    "|| name                                               ||": "Karaage",
    "rating": "2",
    "fried": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "processed": "1",
    "white-meat": "1",
    "serving": "2",
    "|| unit ||": "piece",
    "info-translation": "Japanese fried chicken",
    "weight-g": "74",
    "calories": "212",
    "fat": "14",
    "cal-from-fat": "126",
    "per-cal-from-fat": "59",
    "saturated": "2.6",
    "polyunsaturated": "6.2",
    "monounsaturated": "4.8",
    "trans": "0.2",
    "cholesterol": "60",
    "sodium": "292",
    "carbs": "9.8",
    "fibre": "0.3",
    "sugar": "0.7",
    "protein": "12",
    "cal-from-protein": "48",
    "per-cal-from-protein": "23",
    "potassium": "154",
    "source": "Nutritionix",
    "old units (ref)": "piece"
  },
  {
    "id": "123",
    "type": "meal",
    "|| name                                               ||": "Kaya",
    "rating": "2",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "20",
    "calories": "63.2",
    "fat": "2.4",
    "cal-from-fat": "21.6",
    "per-cal-from-fat": "34",
    "saturated": "1.8",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.4",
    "cholesterol": "23",
    "sodium": "8",
    "carbs": "9.6",
    "fibre": "0.2",
    "sugar": "8.7",
    "protein": "0.8",
    "cal-from-protein": "3",
    "per-cal-from-protein": "5",
    "potassium": "24",
    "calcium": "5",
    "iron": "0.2",
    "phosphorus": "14",
    "source": "N Buddy",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "739",
    "type": "snack",
    "|| name                                               ||": "Kaya butter puff, fried",
    "rating": "1",
    "fried": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "90",
    "calories": "302",
    "fat": "18",
    "cal-from-fat": "162",
    "per-cal-from-fat": "54",
    "saturated": "5",
    "sodium": "34",
    "carbs": "31",
    "fibre": "1",
    "protein": "4",
    "cal-from-protein": "16",
    "per-cal-from-protein": "5",
    "source": "https://cookidoo.thermomix.com/recipes/recipe/en-US/r591616",
    "old units (ref)": "piece"
  },
  {
    "id": "451",
    "type": "snack",
    "|| name                                               ||": "Kaya butter toast, traditional",
    "rating": "2",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "|| info                                                             ||": "e.g. Ya Kun kaya toast",
    "info-brands": "Ya Kun",
    "weight-g": "53",
    "calories": "205.3",
    "fat": "11.3",
    "cal-from-fat": "101.7",
    "per-cal-from-fat": "50",
    "saturated": "7.4",
    "polyunsaturated": "0.6",
    "monounsaturated": "2.8",
    "cholesterol": "48.5",
    "sodium": "204",
    "carbs": "22.7",
    "fibre": "0.9",
    "sugar": "9.3",
    "protein": "3.2",
    "cal-from-protein": "13",
    "per-cal-from-protein": "6",
    "potassium": "56.5",
    "calcium": "20.5",
    "iron": "0.5",
    "phosphorus": "40",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "283",
    "type": "meal",
    "|| name                                               ||": "Kebab, grilled meat",
    "rating": "2",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "white-meat": "1",
    "serving": "2",
    "|| unit ||": "skewer",
    "weight-g": "145",
    "calories": "403.4",
    "fat": "32.6",
    "cal-from-fat": "293.4",
    "per-cal-from-fat": "73",
    "saturated": "10.8",
    "polyunsaturated": "2.1",
    "monounsaturated": "18.8",
    "cholesterol": "64",
    "sodium": "69",
    "carbs": "4.1",
    "fibre": "0.8",
    "sugar": "1.9",
    "protein": "23.4",
    "cal-from-protein": "94",
    "per-cal-from-protein": "23",
    "potassium": "261",
    "calcium": "14",
    "iron": "2.6",
    "phosphorus": "197",
    "source": "N Buddy",
    "old units (ref)": "skewers"
  },
  {
    "id": "989",
    "type": "meal",
    "|| name                                               ||": "Ketupat",
    "rating": "2",
    "serving": "1",
    "|| unit ||": "piece",
    "info-translation": "Indonesian rice dumplings",
    "alternate-spellings": "Ketupat: Kupat",
    "weight-g": "95",
    "calories": "88",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "4",
    "carbs": "19.8",
    "fibre": "1",
    "sugar": "0",
    "protein": "2.2",
    "cal-from-protein": "9",
    "per-cal-from-protein": "10",
    "calcium": "4",
    "iron": "0.2",
    "source": "N Buddy",
    "old units (ref)": "whole"
  },
  {
    "id": "422",
    "type": "meal",
    "|| name                                               ||": "Kimchi",
    "rating": "3",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-salt": "1",
    "probiotic": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "handful",
    "weight-g": "75",
    "calories": "13.875",
    "fat": "0.375",
    "cal-from-fat": "3.375",
    "per-cal-from-fat": "24",
    "saturated": "0.05",
    "polyunsaturated": "0.181",
    "monounsaturated": "0.028",
    "cholesterol": "0",
    "sodium": "373.5",
    "carbs": "1.8",
    "fibre": "1.2",
    "sugar": "0.795",
    "protein": "0.825",
    "cal-from-protein": "3",
    "per-cal-from-protein": "24",
    "potassium": "133",
    "calcium": "24.75",
    "iron": "1.875",
    "phosphorus": "18",
    "source": "USDA",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "860",
    "type": "meal",
    "|| name                                               ||": "Kimchi pancake",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-salt": "1",
    "probiotic": "1",
    "serving": "0.25",
    "|| unit ||": "pancake",
    "weight-g": "76",
    "calories": "204",
    "fat": "12.8",
    "cal-from-fat": "115.2",
    "per-cal-from-fat": "56",
    "saturated": "4.8",
    "polyunsaturated": "2.2",
    "monounsaturated": "5.8",
    "sodium": "388",
    "carbs": "19.4",
    "fibre": "1.6",
    "sugar": "2",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "6",
    "calcium": "24",
    "iron": "0.6",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "857",
    "type": "meal",
    "|| name                                               ||": "Kimchi ramen",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "probiotic": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "519",
    "calories": "559",
    "fat": "15.8",
    "cal-from-fat": "142.2",
    "per-cal-from-fat": "25",
    "saturated": "6.6",
    "polyunsaturated": "1.7",
    "monounsaturated": "9",
    "cholesterol": "89",
    "sodium": "1522",
    "carbs": "56.7",
    "fibre": "6.7",
    "sugar": "4.9",
    "protein": "47.1",
    "cal-from-protein": "188",
    "per-cal-from-protein": "34",
    "potassium": "751",
    "calcium": "140",
    "iron": "5",
    "phosphorus": "342",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "862",
    "type": "meal",
    "|| name                                               ||": "Kimchi soup",
    "rating": "2",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "probiotic": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-include-exclude": "without noodles, with pork",
    "weight-g": "462",
    "calories": "222",
    "fat": "12.9",
    "cal-from-fat": "116.1",
    "per-cal-from-fat": "52",
    "saturated": "4.5",
    "polyunsaturated": "2.7",
    "monounsaturated": "5",
    "cholesterol": "28",
    "sodium": "2365",
    "carbs": "9.7",
    "fibre": "8.8",
    "sugar": "9.7",
    "protein": "16.6",
    "cal-from-protein": "66",
    "per-cal-from-protein": "30",
    "potassium": "647",
    "calcium": "157",
    "iron": "3.7",
    "phosphorus": "171",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "666",
    "type": "meal",
    "|| name                                               ||": "King oyster mushroom",
    "rating": "3",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "large",
    "|| info                                                             ||": "Eryngii",
    "info-translation": "Eryngii",
    "weight-g": "148",
    "calories": "59.2",
    "fat": "0.4",
    "cal-from-fat": "3.6",
    "per-cal-from-fat": "6",
    "saturated": "0.1",
    "polyunsaturated": "0.18",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "26.6",
    "carbs": "9",
    "fibre": "3.4",
    "sugar": "1.64",
    "protein": "4.9",
    "cal-from-protein": "20",
    "per-cal-from-protein": "33",
    "potassium": "622",
    "calcium": "4.44",
    "iron": "1.97",
    "phosphorus": "178",
    "source": "US FDA",
    "old units (ref)": "serving"
  },
  {
    "id": "388",
    "type": "snack",
    "|| name                                               ||": "Kiwifruit",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "kiwi",
    "weight-g": "81",
    "calories": "56.547",
    "fat": "0.227",
    "cal-from-fat": "2.043",
    "per-cal-from-fat": "4",
    "saturated": "0.053",
    "polyunsaturated": "0.09",
    "monounsaturated": "0.019",
    "cholesterol": "0",
    "sodium": "2.43",
    "carbs": "12.8",
    "fibre": "1.13",
    "sugar": "9.96",
    "protein": "0.826",
    "cal-from-protein": "3",
    "per-cal-from-protein": "6",
    "potassium": "255",
    "calcium": "13.8",
    "iron": "0.17",
    "phosphorus": "20.2",
    "source": "USDA",
    "old units (ref)": "whole fruit"
  },
  {
    "id": "963",
    "type": "drink",
    "|| name                                               ||": "Kombucha, sweetened",
    "rating": "2",
    "high-sugar": "1",
    "probiotic": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "volume-ml": "240",
    "calories": "45.6",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "9.6",
    "carbs": "11",
    "sugar": "10",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "373",
    "type": "drink",
    "|| name                                               ||": "Kombucha, unsweetened",
    "rating": "3",
    "probiotic": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "volume-ml": "236",
    "calories": "30.7",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "sodium": "9",
    "carbs": "4",
    "sugar": "4",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "iron": "0.7",
    "source": "USDA",
    "old units (ref)": "cup"
  },
  {
    "id": "556",
    "type": "drink",
    "|| name                                               ||": "Kopi",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "Coffee with condensed milk",
    "info-translation": "Coffee with condensed milk",
    "weight-g": "250",
    "calories": "152.9",
    "fat": "6.5",
    "cal-from-fat": "58.5",
    "per-cal-from-fat": "38",
    "saturated": "3.7",
    "polyunsaturated": "1.6",
    "monounsaturated": "1.6",
    "cholesterol": "5",
    "sodium": "88",
    "carbs": "20",
    "fibre": "0",
    "sugar": "20",
    "protein": "3.6",
    "cal-from-protein": "14",
    "per-cal-from-protein": "9",
    "potassium": "124",
    "calcium": "113",
    "iron": "0.1",
    "phosphorus": "7",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "557",
    "type": "drink",
    "|| name                                               ||": "Kopi C",
    "rating": "2",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "Coffee with evaporated milk with sugar",
    "info-translation": "Coffee with evaporated milk and sugar",
    "alternate-spellings": "C: Si",
    "weight-g": "288",
    "calories": "92.2",
    "fat": "2.6",
    "cal-from-fat": "23.4",
    "per-cal-from-fat": "25",
    "saturated": "1.7",
    "polyunsaturated": "0.5",
    "monounsaturated": "0.4",
    "cholesterol": "9",
    "sodium": "55",
    "carbs": "14.4",
    "fibre": "0",
    "sugar": "10",
    "protein": "2.8",
    "cal-from-protein": "11",
    "per-cal-from-protein": "12",
    "potassium": "124",
    "calcium": "80",
    "iron": "0.1",
    "phosphorus": "20",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "562",
    "type": "drink",
    "|| name                                               ||": "Kopi C kosong",
    "rating": "3",
    "high-iodine": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "Coffee with evaporated milk, without sugar",
    "info-translation": "Coffee with evaporated milk, without sugar",
    "alternate-spellings": "C: Si",
    "weight-g": "250",
    "calories": "47.7",
    "fat": "2.5",
    "cal-from-fat": "22.5",
    "per-cal-from-fat": "47",
    "saturated": "1.6",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.7",
    "cholesterol": "8",
    "sodium": "34",
    "carbs": "3.9",
    "fibre": "0",
    "sugar": "3",
    "protein": "2.4",
    "cal-from-protein": "10",
    "per-cal-from-protein": "20",
    "potassium": "115",
    "calcium": "81",
    "iron": "0.1",
    "phosphorus": "70",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "559",
    "type": "drink",
    "|| name                                               ||": "Kopi C siew dai",
    "rating": "2",
    "high-iodine": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "Coffee with evaporated milk, less sugar",
    "info-translation": "Coffee with evaporated milk, less sugar",
    "alternate-spellings": "C: Si\nSiew: Siu",
    "weight-g": "250",
    "calories": "71.7",
    "fat": "2.5",
    "cal-from-fat": "22.5",
    "per-cal-from-fat": "31",
    "saturated": "1.6",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.7",
    "cholesterol": "8",
    "sodium": "34",
    "carbs": "9.9",
    "fibre": "0",
    "sugar": "9.1",
    "protein": "2.4",
    "cal-from-protein": "10",
    "per-cal-from-protein": "13",
    "potassium": "115",
    "calcium": "81",
    "iron": "0.1",
    "phosphorus": "70",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "560",
    "type": "drink",
    "|| name                                               ||": "Kopi O",
    "rating": "2",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "Black coffee with sugar",
    "info-translation": "Black coffee with sugar",
    "weight-g": "260",
    "calories": "149.3",
    "fat": "5.3",
    "cal-from-fat": "47.7",
    "per-cal-from-fat": "32",
    "saturated": "3",
    "polyunsaturated": "1.1",
    "monounsaturated": "1.2",
    "cholesterol": "1",
    "sodium": "3",
    "carbs": "22.4",
    "fibre": "0",
    "sugar": "19",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "8",
    "potassium": "83",
    "calcium": "3",
    "iron": "0",
    "phosphorus": "6",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "561",
    "type": "drink",
    "|| name                                               ||": "Kopi O kosong",
    "rating": "3",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "Black coffee without sugar",
    "info-translation": "Black coffee without sugar",
    "weight-g": "155",
    "calories": "3.2",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "3",
    "carbs": "0.6",
    "fibre": "0",
    "sugar": "0.6",
    "protein": "0.2",
    "cal-from-protein": "1",
    "per-cal-from-protein": "25",
    "potassium": "84",
    "calcium": "3",
    "iron": "0.6",
    "phosphorus": "2",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "906",
    "type": "drink",
    "|| name                                               ||": "Kopi O siew dai",
    "rating": "2",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "Black coffee with less sugar",
    "info-translation": "Black coffee with less sugar",
    "alternate-spellings": "Siew: Siu",
    "weight-g": "194",
    "calories": "45.8",
    "fat": "1.8",
    "cal-from-fat": "16.2",
    "per-cal-from-fat": "35",
    "saturated": "0.6",
    "polyunsaturated": "0.1",
    "monounsaturated": "1.1",
    "cholesterol": "0",
    "sodium": "17",
    "carbs": "7",
    "fibre": "0",
    "sugar": "7",
    "protein": "0.4",
    "cal-from-protein": "2",
    "per-cal-from-protein": "3",
    "potassium": "89",
    "calcium": "4",
    "iron": "0",
    "phosphorus": "14",
    "old units (ref)": "cup"
  },
  {
    "id": "558",
    "type": "drink",
    "|| name                                               ||": "Kopi siew dai",
    "rating": "2",
    "high-iodine": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "Coffee with less condensed milk",
    "info-translation": "Coffee with less condensed milk",
    "alternate-spellings": "Siew: Siu",
    "weight-g": "194",
    "calories": "45.8",
    "fat": "1.8",
    "cal-from-fat": "16.2",
    "per-cal-from-fat": "35",
    "saturated": "0.6",
    "polyunsaturated": "0.1",
    "monounsaturated": "1.1",
    "cholesterol": "0",
    "sodium": "17",
    "carbs": "7",
    "fibre": "0",
    "sugar": "7",
    "protein": "0.4",
    "cal-from-protein": "2",
    "per-cal-from-protein": "3",
    "potassium": "89",
    "calcium": "4",
    "iron": "0",
    "phosphorus": "14",
    "old units (ref)": "cup"
  },
  {
    "id": "861",
    "type": "meal",
    "|| name                                               ||": "Haemul Pajeon",
    "rating": "2",
    "high-cholesterol": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "serving": "0.25",
    "|| unit ||": "pancake",
    "|| info                                                             ||": "Korean seafood pancake",
    "info-translation": "Korean seafood pancake",
    "weight-g": "78",
    "calories": "158",
    "fat": "6.6",
    "cal-from-fat": "59.4",
    "per-cal-from-fat": "38",
    "saturated": "3",
    "polyunsaturated": "1",
    "monounsaturated": "2.8",
    "sodium": "318",
    "carbs": "18.4",
    "sugar": "1.2",
    "protein": "5.8",
    "cal-from-protein": "23",
    "per-cal-from-protein": "15",
    "calcium": "32",
    "iron": "0.6",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "882",
    "type": "meal",
    "|| name                                               ||": "Korean sweet crispy chicken",
    "rating": "2",
    "fried": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "99",
    "calories": "271.5",
    "fat": "13.9",
    "cal-from-fat": "125.1",
    "per-cal-from-fat": "46",
    "saturated": "3.5",
    "polyunsaturated": "3.2",
    "monounsaturated": "6.4",
    "trans": "0.1",
    "cholesterol": "94",
    "sodium": "486",
    "carbs": "20.1",
    "fibre": "0.3",
    "sugar": "14.8",
    "protein": "16.5",
    "cal-from-protein": "66",
    "per-cal-from-protein": "24",
    "potassium": "170",
    "calcium": "30",
    "iron": "0.7",
    "phosphorus": "148",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "667",
    "type": "meal",
    "|| name                                               ||": "Kow kee chye, stir-fried",
    "rating": "3",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "|| info                                                             ||": "Wolfberry leaf soup",
    "info-translation": "Wolfberry leaf",
    "alternate-spellings": "Kow: Gao, Kau\nKee: Kei\nChye: Cai, Choy, Chai",
    "weight-g": "100",
    "calories": "20",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "cholesterol": "0",
    "sodium": "2",
    "carbs": "2",
    "fibre": "1.3",
    "sugar": "0",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "60",
    "source": "HPB",
    "old units (ref)": "serving"
  },
  {
    "id": "60",
    "type": "snack",
    "|| name                                               ||": "Kueh lapis, baked",
    "rating": "1",
    "high-cholesterol": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "alternate-spellings": "Kueh: Kuih, Kue",
    "weight-g": "31",
    "calories": "147.3",
    "fat": "10.5",
    "cal-from-fat": "94.5",
    "per-cal-from-fat": "64",
    "saturated": "6.5",
    "polyunsaturated": "0.7",
    "monounsaturated": "2.8",
    "cholesterol": "69",
    "sodium": "99",
    "carbs": "10.7",
    "fibre": "0.4",
    "sugar": "10",
    "protein": "2.5",
    "cal-from-protein": "10",
    "per-cal-from-protein": "7",
    "calcium": "10",
    "iron": "0.4",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "149",
    "type": "meal",
    "|| name                                               ||": "Kuey chap",
    "rating": "1",
    "high-cholesterol": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "e.g. intestine, pork belly",
    "info-include-exclude": "with meat, innards and tau pok",
    "alternate-spellings": "Kuey: Kuay, Kway",
    "weight-g": "730",
    "calories": "703.2",
    "fat": "26.4",
    "cal-from-fat": "237.6",
    "per-cal-from-fat": "34",
    "saturated": "9.9",
    "polyunsaturated": "4.3",
    "monounsaturated": "10.7",
    "cholesterol": "293",
    "sodium": "3206",
    "carbs": "82",
    "fibre": "5.9",
    "sugar": "15",
    "protein": "34.4",
    "cal-from-protein": "138",
    "per-cal-from-protein": "20",
    "potassium": "417",
    "calcium": "234",
    "iron": "3.7",
    "phosphorus": "425",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "284",
    "type": "meal",
    "|| name                                               ||": "Kuih pie tee",
    "rating": "2",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "alternate-spellings": "Kuih: Kueh\nPie: Pai\nTee: Ti",
    "weight-g": "37",
    "calories": "88.1",
    "fat": "6.1",
    "cal-from-fat": "54.9",
    "per-cal-from-fat": "62",
    "saturated": "3",
    "polyunsaturated": "0.7",
    "monounsaturated": "2",
    "cholesterol": "10",
    "sodium": "121",
    "carbs": "6.6",
    "fibre": "0.8",
    "sugar": "4",
    "protein": "1.7",
    "cal-from-protein": "7",
    "per-cal-from-protein": "8",
    "calcium": "13",
    "iron": "0.4",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "653",
    "type": "meal",
    "|| name                                               ||": "Ladies fingers, stir-fried",
    "rating": "3",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "|| info                                                             ||": "Okra",
    "info-translation": "Okra",
    "alternate-spellings": "Ladies: Lady, Lady's",
    "weight-g": "100",
    "calories": "73",
    "fat": "4.7",
    "cal-from-fat": "42.3",
    "per-cal-from-fat": "58",
    "saturated": "0.6",
    "polyunsaturated": "0.5",
    "monounsaturated": "3.3",
    "cholesterol": "0",
    "sodium": "123",
    "carbs": "7.5",
    "fibre": "3.2",
    "sugar": "1.5",
    "protein": "1.9",
    "cal-from-protein": "8",
    "per-cal-from-protein": "10",
    "potassium": "299",
    "calcium": "82",
    "iron": "0.7",
    "phosphorus": "61",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "964",
    "type": "meal",
    "|| name                                               ||": "Laksa, assam",
    "rating": "2",
    "high-iodine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "alternate-spellings": "Assam: Asam",
    "weight-g": "660",
    "calories": "432.4",
    "fat": "2",
    "cal-from-fat": "18",
    "per-cal-from-fat": "4",
    "saturated": "0.7",
    "polyunsaturated": "0.7",
    "monounsaturated": "0.7",
    "cholesterol": "13",
    "sodium": "2653",
    "carbs": "82.5",
    "fibre": "4.6",
    "sugar": "16",
    "protein": "21.1",
    "cal-from-protein": "84",
    "per-cal-from-protein": "20",
    "calcium": "158",
    "iron": "7.3",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "124",
    "type": "meal",
    "|| name                                               ||": "Laksa, curry",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "585",
    "calories": "768.6",
    "fat": "47.4",
    "cal-from-fat": "426.6",
    "per-cal-from-fat": "56",
    "saturated": "34",
    "polyunsaturated": "3",
    "monounsaturated": "7.5",
    "cholesterol": "88",
    "sodium": "1591",
    "carbs": "63.8",
    "fibre": "4.7",
    "sugar": "8.2",
    "protein": "21.7",
    "cal-from-protein": "87",
    "per-cal-from-protein": "11",
    "potassium": "450",
    "calcium": "129",
    "iron": "5.8",
    "phosphorus": "234",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "455",
    "type": "meal",
    "|| name                                               ||": "Lamb chop, grilled",
    "rating": "2",
    "high-iron": "1",
    "high-purine": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "chop",
    "|| info                                                             ||": "200g",
    "info-portion-aid": "3\" x 3\" x 1\"",
    "weight-g": "100",
    "calories": "191",
    "fat": "11.4",
    "cal-from-fat": "102.6",
    "per-cal-from-fat": "54",
    "saturated": "5.76",
    "polyunsaturated": "0.664",
    "monounsaturated": "4.55",
    "cholesterol": "49.1",
    "sodium": "42.7",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "22.1",
    "cal-from-protein": "88",
    "per-cal-from-protein": "46",
    "potassium": "158",
    "calcium": "5.94",
    "iron": "1.21",
    "phosphorus": "107",
    "source": "USDA",
    "old units (ref)": "portion"
  },
  {
    "id": "285",
    "type": "meal",
    "|| name                                               ||": "Lasagne, beef / chicken",
    "rating": "2",
    "high-calcium": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "weight-g": "437",
    "calories": "833.9",
    "fat": "48.3",
    "cal-from-fat": "434.7",
    "per-cal-from-fat": "52",
    "saturated": "19.1",
    "polyunsaturated": "3.13",
    "monounsaturated": "15.6",
    "trans": "1.25",
    "cholesterol": "135",
    "sodium": "2290",
    "carbs": "54",
    "fibre": "5.68",
    "sugar": "12.1",
    "protein": "45.8",
    "cal-from-protein": "183",
    "per-cal-from-protein": "22",
    "potassium": "1160",
    "calcium": "808",
    "iron": "3.23",
    "phosphorus": "769",
    "source": "USDA",
    "old units (ref)": "portion"
  },
  {
    "id": "965",
    "type": "meal",
    "|| name                                               ||": "Lasagne, vegetarian",
    "rating": "2",
    "high-calcium": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "weight-g": "400",
    "calories": "498.3",
    "fat": "23.1",
    "cal-from-fat": "207.9",
    "per-cal-from-fat": "42",
    "saturated": "8.9",
    "polyunsaturated": "3.6",
    "monounsaturated": "8.2",
    "trans": "0.6",
    "cholesterol": "37",
    "sodium": "945",
    "carbs": "52",
    "fibre": "8.9",
    "sugar": "14.6",
    "protein": "20.6",
    "cal-from-protein": "82",
    "per-cal-from-protein": "17",
    "potassium": "1142",
    "calcium": "431",
    "iron": "4.5",
    "phosphorus": "395",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "689",
    "type": "meal",
    "|| name                                               ||": "Leek, stir-fried",
    "rating": "3",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "100",
    "calories": "89.6",
    "fat": "6.4",
    "cal-from-fat": "57.6",
    "per-cal-from-fat": "64",
    "saturated": "2",
    "polyunsaturated": "2",
    "monounsaturated": "8",
    "cholesterol": "0",
    "sodium": "392",
    "carbs": "6",
    "fibre": "0",
    "sugar": "6",
    "protein": "2",
    "cal-from-protein": "8",
    "per-cal-from-protein": "9",
    "potassium": "50",
    "calcium": "14",
    "iron": "0",
    "phosphorus": "78",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "652",
    "type": "meal",
    "|| name                                               ||": "Lemongrass",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "108",
    "calories": "29.4",
    "fat": "0.6",
    "cal-from-fat": "5.4",
    "per-cal-from-fat": "18",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "5.4",
    "fibre": "0.6",
    "sugar": "1.8",
    "protein": "0.6",
    "cal-from-protein": "2",
    "per-cal-from-protein": "8",
    "potassium": "312",
    "calcium": "30",
    "iron": "1.2",
    "phosphorus": "30",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "690",
    "type": "meal",
    "|| name                                               ||": "Lentils, boiled / steamed",
    "rating": "3",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "plant-protein": "1",
    "serving": "4",
    "|| unit ||": "tbsp",
    "weight-g": "50",
    "calories": "57.2",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "3",
    "saturated": "0",
    "polyunsaturated": "0.1",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "1",
    "carbs": "9.9",
    "fibre": "3.9",
    "sugar": "0.9",
    "protein": "4.4",
    "cal-from-protein": "18",
    "per-cal-from-protein": "31",
    "potassium": "182",
    "calcium": "9",
    "iron": "1.6",
    "phosphorus": "880",
    "source": "US FDA",
    "old units (ref)": "cup"
  },
  {
    "id": "691",
    "type": "meal",
    "|| name                                               ||": "Lima beans",
    "rating": "3",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "plant-protein": "1",
    "serving": "0.5",
    "|| unit ||": "cup",
    "|| info                                                             ||": "1 cup = 240mL",
    "weight-g": "85",
    "calories": "104.5",
    "fat": "0.3",
    "cal-from-fat": "2.7",
    "per-cal-from-fat": "3",
    "saturated": "0.1",
    "polyunsaturated": "0.1",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "14",
    "carbs": "20",
    "fibre": "4.6",
    "sugar": "1.4",
    "protein": "5.8",
    "cal-from-protein": "23",
    "per-cal-from-protein": "22",
    "potassium": "485",
    "calcium": "27",
    "iron": "2.1",
    "phosphorus": "111",
    "source": "US FDA",
    "old units (ref)": "cup"
  },
  {
    "id": "712",
    "type": "snack",
    "|| name                                               ||": "Lime",
    "rating": "3",
    "fruit": "1",
    "serving": "5",
    "|| unit ||": "lime",
    "info-translation": "Calamansi",
    "weight-g": "110",
    "calories": "36.5",
    "fat": "2.5",
    "cal-from-fat": "22.5",
    "per-cal-from-fat": "62",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "3",
    "fibre": "0.5",
    "sugar": "3",
    "protein": "0.5",
    "cal-from-protein": "2",
    "per-cal-from-protein": "5",
    "potassium": "45",
    "calcium": "10",
    "iron": "0",
    "phosphorus": "5",
    "source": "N Buddy",
    "old units (ref)": "whole"
  },
  {
    "id": "175",
    "type": "drink",
    "|| name                                               ||": "Lime juice, sweetened",
    "rating": "2",
    "high-gi": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "volume-ml": "250",
    "calories": "121.3",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "1",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "1",
    "carbs": "30",
    "fibre": "0.2",
    "sugar": "30",
    "protein": "0.1",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "28",
    "calcium": "5",
    "iron": "0.1",
    "phosphorus": "4",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "366",
    "type": "drink",
    "|| name                                               ||": "Lime juice, unsweetened",
    "rating": "3",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "242",
    "calories": "61",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "4.8",
    "carbs": "20.4",
    "fibre": "1",
    "sugar": "4",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "7",
    "potassium": "283",
    "calcium": "34",
    "iron": "0.2",
    "phosphorus": "34",
    "source": "USDA",
    "old units (ref)": "cup"
  },
  {
    "id": "979",
    "type": "meal",
    "|| name                                               ||": "Lobster thermidor",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "serving": "1.5",
    "|| unit ||": "palm",
    "|| info                                                             ||": "150g - shell removed",
    "info-include-exclude": "without shell",
    "weight-g": "150",
    "calories": "239",
    "fat": "14.7",
    "cal-from-fat": "132.3",
    "per-cal-from-fat": "55",
    "saturated": "8.9",
    "polyunsaturated": "1",
    "monounsaturated": "3.8",
    "cholesterol": "231",
    "sodium": "755",
    "carbs": "8",
    "fibre": "0",
    "sugar": "0",
    "protein": "25.5",
    "cal-from-protein": "102",
    "per-cal-from-protein": "43",
    "potassium": "311",
    "calcium": "132",
    "iron": "0.4",
    "phosphorus": "251",
    "source": "USDA",
    "old units (ref)": "portion"
  },
  {
    "id": "1038",
    "type": "snack",
    "|| name                                               ||": "Log cake",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "100",
    "calories": "356",
    "fat": "24",
    "cal-from-fat": "216",
    "per-cal-from-fat": "61",
    "saturated": "9",
    "polyunsaturated": "8",
    "monounsaturated": "8",
    "trans": "0.1",
    "cholesterol": "70",
    "sodium": "190",
    "carbs": "32",
    "fibre": "1",
    "sugar": "20",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "3",
    "potassium": "129",
    "calcium": "45",
    "iron": "1.8",
    "phosphorus": "119",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "624",
    "type": "meal",
    "|| name                                               ||": "Long bean, stir-fried",
    "rating": "3",
    "high-fibre": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "100",
    "calories": "23.9",
    "fat": "0.3",
    "cal-from-fat": "2.7",
    "per-cal-from-fat": "11",
    "saturated": "0.8",
    "polyunsaturated": "0.9",
    "monounsaturated": "0.9",
    "cholesterol": "0",
    "sodium": "170",
    "carbs": "2.6",
    "fibre": "4",
    "sugar": "2.3",
    "protein": "2.7",
    "cal-from-protein": "11",
    "per-cal-from-protein": "45",
    "potassium": "204",
    "calcium": "29",
    "iron": "0.8",
    "phosphorus": "51",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "389",
    "type": "snack",
    "|| name                                               ||": "Longan",
    "rating": "3",
    "fruit": "1",
    "serving": "10",
    "|| unit ||": "longan",
    "weight-g": "60",
    "calories": "21",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "1.3",
    "carbs": "4.9",
    "fibre": "0.1",
    "sugar": "4.9",
    "protein": "0.3",
    "cal-from-protein": "1",
    "per-cal-from-protein": "6",
    "potassium": "74.7",
    "calcium": "2",
    "iron": "0.1",
    "phosphorus": "9.3",
    "source": "N Buddy",
    "old units (ref)": "whole fruit"
  },
  {
    "id": "580",
    "type": "drink",
    "|| name                                               ||": "Longan red date drink, sweetened",
    "rating": "3",
    "high-gi": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "packet",
    "volume-ml": "250",
    "calories": "95.2",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "13",
    "carbs": "23.8",
    "sugar": "23",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "source": "N Buddy",
    "old units (ref)": "packet"
  },
  {
    "id": "286",
    "type": "meal",
    "|| name                                               ||": "Lontong with sayur lodeh",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "probiotic": "1",
    "plant-protein": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "with sayur lodeh",
    "weight-g": "770",
    "calories": "802.7",
    "fat": "40.3",
    "cal-from-fat": "362.7",
    "per-cal-from-fat": "45",
    "saturated": "21.9",
    "polyunsaturated": "5.4",
    "monounsaturated": "9.8",
    "cholesterol": "264",
    "sodium": "1930",
    "carbs": "64.3",
    "fibre": "6",
    "sugar": "27.9",
    "protein": "45.7",
    "cal-from-protein": "183",
    "per-cal-from-protein": "23",
    "potassium": "411",
    "calcium": "100",
    "iron": "2.3",
    "phosphorus": "488",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "287",
    "type": "meal",
    "|| name                                               ||": "Lor mee",
    "rating": "2",
    "high-gi": "1",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "640",
    "calories": "596.1",
    "fat": "17.3",
    "cal-from-fat": "155.7",
    "per-cal-from-fat": "26",
    "saturated": "6.9",
    "polyunsaturated": "2.6",
    "monounsaturated": "7.9",
    "cholesterol": "109",
    "sodium": "3315",
    "carbs": "84.5",
    "fibre": "4.5",
    "sugar": "14.1",
    "protein": "25.6",
    "cal-from-protein": "102",
    "per-cal-from-protein": "17",
    "potassium": "154",
    "calcium": "70",
    "iron": "5.1",
    "phosphorus": "314",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "696",
    "type": "meal",
    "|| name                                               ||": "Lotus root, boiled",
    "rating": "3",
    "high-fibre": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "6",
    "|| unit ||": "slice",
    "weight-g": "120",
    "calories": "79",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "1",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0.1",
    "cholesterol": "0",
    "sodium": "54",
    "carbs": "19.2",
    "fibre": "3.7",
    "sugar": "0",
    "protein": "1.9",
    "cal-from-protein": "8",
    "per-cal-from-protein": "10",
    "potassium": "436",
    "calcium": "31",
    "iron": "1.1",
    "phosphorus": "94",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "478",
    "type": "snack",
    "|| name                                               ||": "Lotus seed soup dessert",
    "rating": "3",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Lian zi suan",
    "info-translation": "Lian Zi Suan",
    "weight-g": "240",
    "calories": "115",
    "fat": "1",
    "cal-from-fat": "9",
    "per-cal-from-fat": "8",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "5",
    "carbs": "24.5",
    "fibre": "2.6",
    "sugar": "19.9",
    "protein": "1.9",
    "cal-from-protein": "8",
    "per-cal-from-protein": "7",
    "potassium": "101",
    "calcium": "7",
    "iron": "0.7",
    "phosphorus": "36",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "893",
    "type": "meal",
    "|| name                                               ||": "Luncheon meat & egg noodles, dry",
    "rating": "1",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "147",
    "calories": "526.7",
    "fat": "29.9",
    "cal-from-fat": "269.1",
    "per-cal-from-fat": "51",
    "saturated": "12.3",
    "polyunsaturated": "0.4",
    "monounsaturated": "1.4",
    "cholesterol": "131",
    "sodium": "615",
    "carbs": "48",
    "fibre": "0.5",
    "sugar": "0.1",
    "protein": "16.4",
    "cal-from-protein": "66",
    "per-cal-from-protein": "12",
    "potassium": "35",
    "calcium": "15",
    "iron": "0.6",
    "phosphorus": "46",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "795",
    "type": "meal",
    "|| name                                               ||": "Luncheon meat, fried",
    "rating": "1",
    "fried": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "50",
    "calories": "163.4",
    "fat": "14.2",
    "cal-from-fat": "127.8",
    "per-cal-from-fat": "78",
    "saturated": "5.3",
    "polyunsaturated": "1.5",
    "monounsaturated": "6.7",
    "cholesterol": "27",
    "sodium": "428",
    "carbs": "3.6",
    "fibre": "0.7",
    "sugar": "0.1",
    "protein": "5.3",
    "cal-from-protein": "21",
    "per-cal-from-protein": "13",
    "potassium": "104",
    "calcium": "22",
    "iron": "1.9",
    "phosphorus": "58",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "390",
    "type": "snack",
    "|| name                                               ||": "Lychee",
    "rating": "3",
    "fruit": "1",
    "high-potassium": "1",
    "serving": "5",
    "|| unit ||": "fruit",
    "alternate-spellings": "Lychee: Litchi",
    "weight-g": "120",
    "calories": "35.29",
    "fat": "0.21",
    "cal-from-fat": "1.89",
    "per-cal-from-fat": "5",
    "saturated": "0.05",
    "polyunsaturated": "0.065",
    "monounsaturated": "0.06",
    "cholesterol": "0",
    "sodium": "0.48",
    "carbs": "7.95",
    "fibre": "0.625",
    "sugar": "7.3",
    "protein": "0.4",
    "cal-from-protein": "2",
    "per-cal-from-protein": "5",
    "potassium": "82",
    "calcium": "0.24",
    "iron": "0.15",
    "phosphorus": "14.9",
    "source": "USDA",
    "old units (ref)": "medium"
  },
  {
    "id": "336",
    "type": "meal",
    "|| name                                               ||": "Ma la xiang guo",
    "rating": "2",
    "high-salt": "1",
    "red-meat": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Chinese hot and spicy pot with ingredients",
    "info-translation": "Chinese hot and spicy pot",
    "weight-g": "463",
    "calories": "653.6",
    "fat": "50",
    "cal-from-fat": "450",
    "per-cal-from-fat": "69",
    "saturated": "17.3",
    "polyunsaturated": "13.2",
    "monounsaturated": "19.4",
    "trans": "0.2",
    "sodium": "2139",
    "carbs": "13.9",
    "fibre": "13",
    "sugar": "4.2",
    "protein": "37",
    "cal-from-protein": "148",
    "per-cal-from-protein": "23",
    "calcium": "384",
    "iron": "8.3",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "33",
    "type": "snack",
    "|| name                                               ||": "Macaroon",
    "rating": "1",
    "high-gi": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "2",
    "|| unit ||": "piece",
    "weight-g": "36",
    "calories": "165.44",
    "fat": "8.12",
    "cal-from-fat": "73.08",
    "per-cal-from-fat": "44",
    "saturated": "7.24",
    "polyunsaturated": "0.292",
    "monounsaturated": "0.58",
    "cholesterol": "0",
    "sodium": "86.8",
    "carbs": "22",
    "fibre": "1.84",
    "sugar": "16.3",
    "protein": "1.09",
    "cal-from-protein": "4",
    "per-cal-from-protein": "3",
    "potassium": "44.3",
    "calcium": "1.8",
    "iron": "0.295",
    "phosphorus": "24.5",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "1037",
    "type": "snack",
    "|| name                                               ||": "Madeleine",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "30",
    "calories": "135",
    "fat": "7.9",
    "cal-from-fat": "71.1",
    "per-cal-from-fat": "53",
    "saturated": "3.6",
    "polyunsaturated": "2.2",
    "monounsaturated": "2.1",
    "sodium": "0",
    "carbs": "15",
    "fibre": "0.5",
    "sugar": "7.1",
    "protein": "1.4",
    "cal-from-protein": "6",
    "per-cal-from-protein": "4",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "1046",
    "type": "snack",
    "|| name                                               ||": "Mamee noodles",
    "rating": "1",
    "fried": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-salt": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "packet",
    "|| info                                                             ||": "Monster noodle snack",
    "info-brands": "Monster",
    "weight-g": "25",
    "calories": "114",
    "fat": "4.2",
    "cal-from-fat": "37.8",
    "per-cal-from-fat": "33",
    "saturated": "2.1",
    "polyunsaturated": "1.1",
    "monounsaturated": "1.1",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "347",
    "carbs": "16.5",
    "fibre": "0.8",
    "sugar": "0.5",
    "protein": "2.7",
    "cal-from-protein": "11",
    "per-cal-from-protein": "9",
    "potassium": "60",
    "calcium": "3",
    "iron": "0.3",
    "phosphorus": "53",
    "source": "N Buddy",
    "old units (ref)": "packet"
  },
  {
    "id": "713",
    "type": "snack",
    "|| name                                               ||": "Mandarin orange",
    "rating": "3",
    "fruit": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "fruit",
    "weight-g": "116",
    "calories": "50.3",
    "fat": "0.3",
    "cal-from-fat": "2.7",
    "per-cal-from-fat": "5",
    "saturated": "0",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.1",
    "cholesterol": "0",
    "sodium": "3",
    "carbs": "10.6",
    "fibre": "2.2",
    "sugar": "10.6",
    "protein": "1.3",
    "cal-from-protein": "5",
    "per-cal-from-protein": "10",
    "potassium": "164",
    "calcium": "21",
    "iron": "0.2",
    "phosphorus": "20",
    "source": "N Buddy",
    "old units (ref)": "whole"
  },
  {
    "id": "391",
    "type": "snack",
    "|| name                                               ||": "Mango",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "high-potassium": "1",
    "serving": "0.5",
    "|| unit ||": "whole",
    "weight-g": "168",
    "calories": "69",
    "fat": "0.64",
    "cal-from-fat": "5.76",
    "per-cal-from-fat": "8",
    "saturated": "0.1545",
    "polyunsaturated": "0.1195",
    "monounsaturated": "0.235",
    "cholesterol": "0",
    "sodium": "1.68",
    "carbs": "25.15",
    "fibre": "2.26",
    "sugar": "22.95",
    "protein": "1.38",
    "cal-from-protein": "6",
    "per-cal-from-protein": "8",
    "potassium": "282",
    "calcium": "18.5",
    "iron": "0.269",
    "phosphorus": "23.5",
    "source": "USDA",
    "old units (ref)": "mango"
  },
  {
    "id": "1030",
    "type": "snack",
    "|| name                                               ||": "Mango graham cake",
    "rating": "2",
    "high-calcium": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "|| info                                                             ||": "Mango float",
    "info-translation": "Mango float",
    "weight-g": "94",
    "calories": "235",
    "fat": "13",
    "cal-from-fat": "117",
    "per-cal-from-fat": "50",
    "saturated": "7.4",
    "polyunsaturated": "0.5",
    "monounsaturated": "0.8",
    "cholesterol": "39",
    "sodium": "72",
    "carbs": "24.5",
    "fibre": "0.7",
    "sugar": "20.1",
    "protein": "2.9",
    "cal-from-protein": "12",
    "per-cal-from-protein": "5",
    "potassium": "124",
    "calcium": "118",
    "iron": "0.4",
    "phosphorus": "58",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "68",
    "type": "snack",
    "|| name                                               ||": "Mango pomelo sago dessert",
    "rating": "2",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "312",
    "calories": "217",
    "fat": "1.3",
    "cal-from-fat": "11.7",
    "per-cal-from-fat": "5",
    "saturated": "0.6",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.4",
    "trans": "0",
    "cholesterol": "2",
    "sodium": "25",
    "carbs": "48",
    "fibre": "1.1",
    "sugar": "36",
    "protein": "1.6",
    "cal-from-protein": "6",
    "per-cal-from-protein": "3",
    "potassium": "128",
    "calcium": "83",
    "iron": "0.3",
    "phosphorus": "64",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "54",
    "type": "snack",
    "|| name                                               ||": "Mango pudding",
    "rating": "2",
    "high-gi": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "100",
    "calories": "47.1",
    "fat": "0.7",
    "cal-from-fat": "6.3",
    "per-cal-from-fat": "13",
    "saturated": "0.4",
    "polyunsaturated": "0",
    "monounsaturated": "0.2",
    "cholesterol": "2",
    "sodium": "25",
    "carbs": "8.9",
    "fibre": "2.4",
    "sugar": "8.3",
    "protein": "1.3",
    "cal-from-protein": "5",
    "per-cal-from-protein": "11",
    "potassium": "99",
    "calcium": "42",
    "iron": "0",
    "phosphorus": "33",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "82",
    "type": "snack",
    "|| name                                               ||": "Mango sticky rice",
    "rating": "2",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "190",
    "calories": "267.7",
    "fat": "7.3",
    "cal-from-fat": "65.7",
    "per-cal-from-fat": "25",
    "saturated": "3.3",
    "polyunsaturated": "0.3",
    "monounsaturated": "0.5",
    "cholesterol": "0",
    "sodium": "27",
    "carbs": "45.2",
    "fibre": "2.9",
    "sugar": "18.4",
    "protein": "5.3",
    "cal-from-protein": "21",
    "per-cal-from-protein": "8",
    "potassium": "124",
    "calcium": "10",
    "iron": "1.6",
    "phosphorus": "100",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "392",
    "type": "snack",
    "|| name                                               ||": "Mangosteen",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "fruit",
    "weight-g": "69",
    "calories": "5.8",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "31",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "1",
    "carbs": "0.9",
    "fibre": "0.3",
    "sugar": "0.9",
    "protein": "0.1",
    "cal-from-protein": "0",
    "per-cal-from-protein": "7",
    "potassium": "7",
    "calcium": "1",
    "iron": "0.2",
    "phosphorus": "2",
    "source": "N Buddy",
    "old units (ref)": "whole fruit"
  },
  {
    "id": "152",
    "type": "snack",
    "|| name                                               ||": "Mantou, fried",
    "rating": "1",
    "fried": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "52",
    "calories": "206.7",
    "fat": "9.9",
    "cal-from-fat": "89.1",
    "per-cal-from-fat": "43",
    "saturated": "3.6",
    "polyunsaturated": "2.2",
    "monounsaturated": "3.7",
    "cholesterol": "0",
    "sodium": "110",
    "carbs": "26",
    "fibre": "1",
    "sugar": "4.7",
    "protein": "3.4",
    "cal-from-protein": "14",
    "per-cal-from-protein": "7",
    "potassium": "42",
    "calcium": "6",
    "iron": "0.2",
    "phosphorus": "78",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "232",
    "type": "snack",
    "|| name                                               ||": "Mantou, steamed",
    "rating": "2",
    "high-gi": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "52",
    "calories": "133.4",
    "fat": "1.4",
    "cal-from-fat": "12.6",
    "per-cal-from-fat": "9",
    "saturated": "0.5",
    "polyunsaturated": "0.3",
    "monounsaturated": "0.5",
    "cholesterol": "0",
    "sodium": "69",
    "carbs": "27.5",
    "fibre": "1",
    "sugar": "4.8",
    "protein": "2.7",
    "cal-from-protein": "11",
    "per-cal-from-protein": "8",
    "potassium": "44",
    "calcium": "19",
    "iron": "0.2",
    "phosphorus": "48",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "834",
    "type": "meal",
    "|| name                                               ||": "Mapo Tofu",
    "rating": "2",
    "high-calcium": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "160",
    "calories": "226",
    "fat": "17",
    "cal-from-fat": "153",
    "per-cal-from-fat": "68",
    "saturated": "4.5",
    "polyunsaturated": "6.7",
    "monounsaturated": "5.3",
    "cholesterol": "27",
    "sodium": "308",
    "carbs": "1.1",
    "fibre": "1",
    "sugar": "0.5",
    "protein": "16.7",
    "cal-from-protein": "67",
    "per-cal-from-protein": "30",
    "potassium": "249",
    "calcium": "192",
    "iron": "5.1",
    "phosphorus": "185",
    "source": "HPB",
    "old units (ref)": "portion"
  },
  {
    "id": "341",
    "type": "meal",
    "|| name                                               ||": "Masala, meat",
    "rating": "2",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "|| info                                                             ||": "fish / chicken / beef / prawn / mutton",
    "info-examples": "fish, chicken, beef, prawn, mutton",
    "weight-g": "150",
    "calories": "237",
    "fat": "12.9",
    "cal-from-fat": "116.1",
    "per-cal-from-fat": "49",
    "saturated": "6.1",
    "polyunsaturated": "0.9",
    "monounsaturated": "5.8",
    "cholesterol": "39",
    "sodium": "809",
    "carbs": "3.2",
    "fibre": "3.5",
    "sugar": "2.4",
    "protein": "26.9",
    "cal-from-protein": "108",
    "per-cal-from-protein": "45",
    "potassium": "317",
    "calcium": "41",
    "iron": "3.5",
    "phosphorus": "197",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "342",
    "type": "meal",
    "|| name                                               ||": "Masala, vegetable",
    "rating": "3",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "|| info                                                             ||": "e.g.  Chana masala",
    "info-examples": "chana, potatoes",
    "weight-g": "140",
    "calories": "168",
    "fat": "8.5",
    "cal-from-fat": "76.5",
    "per-cal-from-fat": "46",
    "saturated": "4.5",
    "polyunsaturated": "1",
    "monounsaturated": "2.7",
    "cholesterol": "7",
    "sodium": "668",
    "carbs": "12.9",
    "fibre": "8.5",
    "sugar": "0.7",
    "protein": "10.2",
    "cal-from-protein": "41",
    "per-cal-from-protein": "24",
    "potassium": "504",
    "calcium": "122",
    "iron": "8.1",
    "phosphorus": "193",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "288",
    "type": "meal",
    "|| name                                               ||": "Mashed potato, regular",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "weight-g": "136",
    "calories": "126",
    "fat": "6",
    "cal-from-fat": "54",
    "per-cal-from-fat": "43",
    "saturated": "1",
    "polyunsaturated": "1.5",
    "monounsaturated": "2.2",
    "cholesterol": "1",
    "sodium": "441",
    "carbs": "17",
    "fibre": "2",
    "sugar": "0",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "3",
    "potassium": "231",
    "calcium": "15",
    "iron": "0.3",
    "phosphorus": "0",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "872",
    "type": "meal",
    "|| name                                               ||": "Mayonnaise",
    "rating": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "13.8",
    "calories": "93.544",
    "fat": "10.3",
    "cal-from-fat": "92.7",
    "per-cal-from-fat": "99",
    "saturated": "1.62",
    "polyunsaturated": "6.17",
    "monounsaturated": "2.32",
    "cholesterol": "5.8",
    "sodium": "87.6",
    "carbs": "0.079",
    "fibre": "0",
    "sugar": "0.079",
    "protein": "0.132",
    "cal-from-protein": "1",
    "per-cal-from-protein": "1",
    "potassium": "2.76",
    "calcium": "1.1",
    "iron": "0.029",
    "phosphorus": "2.9",
    "source": "USDA",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "622",
    "type": "drink",
    "|| name                                               ||": "Glucerna (powder)",
    "rating": "3",
    "healthy-fats": "1",
    "high-calcium": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "|| info                                                             ||": "e.g. from Glucerna, Usana, Herbalife",
    "info-portion-aid": "standard dilution (5 scoops with water)",
    "volume-ml": "237",
    "calories": "228",
    "fat": "8.7",
    "cal-from-fat": "78.3",
    "per-cal-from-fat": "34",
    "saturated": "0.99",
    "polyunsaturated": "1.96",
    "monounsaturated": "4.05",
    "trans": "0.13",
    "cholesterol": "4",
    "sodium": "211",
    "carbs": "26.1",
    "fibre": "3.9",
    "sugar": "5.6",
    "protein": "10.16",
    "cal-from-protein": "41",
    "per-cal-from-protein": "18",
    "potassium": "370",
    "calcium": "223",
    "iron": "2.1",
    "phosphorus": "168",
    "source": "https://abbottfamily.com.sg/products/page/glucerna-triple-care",
    "old units (ref)": "sachet"
  },
  {
    "id": "1061",
    "type": "drink",
    "|| name                                               ||": "Nepro HP (liquid)",
    "rating": "3",
    "healthy-fats": "1",
    "high-calcium": "1",
    "high-fat": "1",
    "high-iron": "1",
    "serving": "1",
    "|| unit ||": "bottle",
    "|| info                                                             ||": "e.g. Nepro",
    "volume-ml": "220",
    "calories": "394.3",
    "fat": "21.5",
    "cal-from-fat": "193.5",
    "per-cal-from-fat": "49",
    "saturated": "1.6",
    "polyunsaturated": "3.1",
    "monounsaturated": "16",
    "cholesterol": "0",
    "sodium": "154",
    "carbs": "32.4",
    "fibre": "2.8",
    "sugar": "7",
    "protein": "17.8",
    "cal-from-protein": "71",
    "per-cal-from-protein": "18",
    "potassium": "233",
    "calcium": "233",
    "iron": "4.2",
    "phosphorus": "158",
    "source": "N Buddy",
    "old units (ref)": "bottle"
  },
  {
    "id": "1062",
    "type": "drink",
    "|| name                                               ||": "Ensure (liquid)",
    "rating": "2",
    "healthy-fats": "1",
    "high-calcium": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "|| info                                                             ||": "e.g. Ensure, Optavia",
    "weight-g": "250",
    "calories": "267.7",
    "fat": "6.5",
    "cal-from-fat": "58.5",
    "per-cal-from-fat": "22",
    "saturated": "0.6",
    "polyunsaturated": "1.8",
    "monounsaturated": "3.8",
    "cholesterol": "0",
    "sodium": "210",
    "carbs": "43",
    "fibre": "0",
    "sugar": "14.5",
    "protein": "9.3",
    "cal-from-protein": "37",
    "per-cal-from-protein": "14",
    "potassium": "390",
    "calcium": "318",
    "iron": "4.8",
    "phosphorus": "318",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "486",
    "type": "meal",
    "|| name                                               ||": "Mee goreng",
    "rating": "2",
    "high-cholesterol": "1",
    "high-gi": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "309",
    "calories": "500",
    "fat": "20.4",
    "cal-from-fat": "183.6",
    "per-cal-from-fat": "37",
    "saturated": "8.3",
    "polyunsaturated": "2.6",
    "monounsaturated": "8.6",
    "cholesterol": "136",
    "sodium": "1851",
    "carbs": "60.9",
    "fibre": "4.3",
    "sugar": "7.4",
    "protein": "18.2",
    "cal-from-protein": "73",
    "per-cal-from-protein": "15",
    "potassium": "389",
    "calcium": "124",
    "iron": "4.3",
    "phosphorus": "210",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "289",
    "type": "meal",
    "|| name                                               ||": "Mee rebus",
    "rating": "2",
    "high-gi": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "571",
    "calories": "571",
    "fat": "17.1",
    "cal-from-fat": "153.9",
    "per-cal-from-fat": "27",
    "saturated": "5.8",
    "polyunsaturated": "3.8",
    "monounsaturated": "6.5",
    "cholesterol": "206",
    "sodium": "2164",
    "carbs": "82.2",
    "fibre": "8",
    "sugar": "37.7",
    "protein": "22.8",
    "cal-from-protein": "91",
    "per-cal-from-protein": "16",
    "potassium": "246",
    "calcium": "223",
    "iron": "2.3",
    "phosphorus": "291",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "125",
    "type": "meal",
    "|| name                                               ||": "Mee siam, with gravy",
    "rating": "2",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "655",
    "calories": "694",
    "fat": "24.2",
    "cal-from-fat": "217.8",
    "per-cal-from-fat": "31",
    "saturated": "8.7",
    "polyunsaturated": "4.9",
    "monounsaturated": "9.1",
    "cholesterol": "138",
    "sodium": "2659",
    "carbs": "91.7",
    "fibre": "9.2",
    "sugar": "65.5",
    "protein": "28.2",
    "cal-from-protein": "113",
    "per-cal-from-protein": "16",
    "potassium": "275",
    "calcium": "164",
    "iron": "2",
    "phosphorus": "269",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "742",
    "type": "meal",
    "|| name                                               ||": "Mee siam, without gravy",
    "rating": "2",
    "high-cholesterol": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "medium size bowl",
    "weight-g": "368",
    "calories": "562.5",
    "fat": "19.3",
    "cal-from-fat": "173.7",
    "per-cal-from-fat": "31",
    "saturated": "8.2",
    "polyunsaturated": "2.3",
    "monounsaturated": "8.2",
    "cholesterol": "96",
    "sodium": "1008",
    "carbs": "81.7",
    "fibre": "9.2",
    "sugar": "55.5",
    "protein": "15.5",
    "cal-from-protein": "62",
    "per-cal-from-protein": "11",
    "potassium": "155",
    "calcium": "99",
    "iron": "2.6",
    "phosphorus": "236",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "319",
    "type": "meal",
    "|| name                                               ||": "Mee soto",
    "rating": "2",
    "high-iron": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "541",
    "calories": "435.4",
    "fat": "13",
    "cal-from-fat": "117",
    "per-cal-from-fat": "27",
    "saturated": "5.4",
    "polyunsaturated": "2.2",
    "monounsaturated": "4.9",
    "cholesterol": "38",
    "sodium": "2678",
    "carbs": "60.1",
    "fibre": "3.8",
    "sugar": "6",
    "protein": "19.5",
    "cal-from-protein": "78",
    "per-cal-from-protein": "18",
    "calcium": "173",
    "iron": "3.8",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "792",
    "type": "meal",
    "|| name                                               ||": "Mee sua, stir-fried",
    "rating": "2",
    "high-calcium": "1",
    "high-cholesterol": "1",
    "high-gi": "1",
    "high-iron": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "450",
    "calories": "631.7",
    "fat": "33.7",
    "cal-from-fat": "303.3",
    "per-cal-from-fat": "48",
    "saturated": "7.5",
    "polyunsaturated": "9.7",
    "monounsaturated": "12.7",
    "cholesterol": "266",
    "sodium": "1531",
    "carbs": "62.1",
    "fibre": "5.1",
    "sugar": "7.4",
    "protein": "20",
    "cal-from-protein": "80",
    "per-cal-from-protein": "13",
    "potassium": "635",
    "calcium": "122",
    "iron": "8.3",
    "phosphorus": "275",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "507",
    "type": "snack",
    "|| name                                               ||": "Meringue, cookie",
    "rating": "1",
    "high-gi": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "5",
    "calories": "17.357",
    "fat": "0.005",
    "cal-from-fat": "0.045",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "12.2",
    "carbs": "4.04",
    "fibre": "0",
    "sugar": "4.02",
    "protein": "0.288",
    "cal-from-protein": "1",
    "per-cal-from-protein": "7",
    "potassium": "7.65",
    "calcium": "0.25",
    "iron": "0.005",
    "phosphorus": "0.4",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "159",
    "type": "drink",
    "|| name                                               ||": "Milk powder, low fat",
    "rating": "3",
    "high-calcium": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "|| info                                                             ||": "e.g. Anlene",
    "info-brands": "Anlene",
    "info-portion-aid": "standard dilution (4 tablespoons with water)",
    "weight-g": "30",
    "calories": "110",
    "fat": "0.8",
    "cal-from-fat": "7.2",
    "per-cal-from-fat": "7",
    "saturated": "0.5",
    "polyunsaturated": "0",
    "monounsaturated": "0.3",
    "cholesterol": "7",
    "sodium": "96",
    "carbs": "18.6",
    "fibre": "0.4",
    "sugar": "13.1",
    "protein": "7.1",
    "cal-from-protein": "28",
    "per-cal-from-protein": "26",
    "potassium": "350",
    "calcium": "500",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "370",
    "type": "drink",
    "|| name                                               ||": "Milk kefir",
    "rating": "3",
    "high-calcium": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "probiotic": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "volume-ml": "100",
    "calories": "41.3",
    "fat": "0.9",
    "cal-from-fat": "8.1",
    "per-cal-from-fat": "20",
    "saturated": "0.7",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.3",
    "cholesterol": "5",
    "sodium": "40",
    "carbs": "4.5",
    "fibre": "0",
    "sugar": "4.6",
    "protein": "3.8",
    "cal-from-protein": "15",
    "per-cal-from-protein": "37",
    "potassium": "164",
    "calcium": "130",
    "iron": "0",
    "phosphorus": "105",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "small cup"
  },
  {
    "id": "1003",
    "type": "snack",
    "|| name                                               ||": "Milk pudding",
    "rating": "2",
    "high-calcium": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "115",
    "calories": "240",
    "fat": "18",
    "cal-from-fat": "162",
    "per-cal-from-fat": "68",
    "saturated": "10",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "60",
    "sodium": "85",
    "carbs": "16",
    "fibre": "0",
    "sugar": "16",
    "protein": "6",
    "cal-from-protein": "24",
    "per-cal-from-protein": "10",
    "potassium": "0",
    "calcium": "144",
    "iron": "3.6",
    "old units (ref)": "bowl"
  },
  {
    "id": "3",
    "type": "drink",
    "|| name                                               ||": "Milk, condensed",
    "rating": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-sugar": "1",
    "serving": "1.5",
    "|| unit ||": "tbsp",
    "weight-g": "20",
    "calories": "67.4",
    "fat": "1.8",
    "cal-from-fat": "16.2",
    "per-cal-from-fat": "24",
    "saturated": "1.1",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.5",
    "cholesterol": "7",
    "sodium": "25",
    "carbs": "11",
    "fibre": "0",
    "sugar": "11",
    "protein": "1.8",
    "cal-from-protein": "7",
    "per-cal-from-protein": "11",
    "potassium": "58",
    "calcium": "66",
    "iron": "0.1",
    "phosphorus": "43",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "974",
    "type": "drink",
    "|| name                                               ||": "Milk, evaporated",
    "rating": "2",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "serving": "2",
    "|| unit ||": "tbsp",
    "volume-ml": "32",
    "calories": "43.38",
    "fat": "2.42",
    "cal-from-fat": "21.78",
    "per-cal-from-fat": "50",
    "saturated": "1.47",
    "polyunsaturated": "0.078",
    "monounsaturated": "0.748",
    "cholesterol": "9.28",
    "sodium": "34",
    "carbs": "3.22",
    "fibre": "0",
    "sugar": "3.22",
    "protein": "2.18",
    "cal-from-protein": "9",
    "per-cal-from-protein": "20",
    "potassium": "97",
    "calcium": "83.6",
    "iron": "0.06",
    "phosphorus": "65",
    "source": "USDA",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "357",
    "type": "drink",
    "|| name                                               ||": "Milk, full cream / whole",
    "rating": "3",
    "high-calcium": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "info-examples": "fresh milk, UHT milk",
    "volume-ml": "250",
    "calories": "166.2",
    "fat": "9",
    "cal-from-fat": "81",
    "per-cal-from-fat": "49",
    "saturated": "6",
    "cholesterol": "33",
    "sodium": "88",
    "carbs": "12.5",
    "fibre": "0",
    "sugar": "12.5",
    "protein": "8.8",
    "cal-from-protein": "35",
    "per-cal-from-protein": "21",
    "calcium": "300",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "16",
    "type": "drink",
    "|| name                                               ||": "Milkshake",
    "rating": "1",
    "high-calcium": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "small cup (350ml)",
    "volume-ml": "355",
    "calories": "376.38",
    "fat": "16.3",
    "cal-from-fat": "146.7",
    "per-cal-from-fat": "39",
    "saturated": "9.9",
    "polyunsaturated": "0.79",
    "monounsaturated": "4.46",
    "cholesterol": "57.5",
    "sodium": "202",
    "carbs": "49",
    "fibre": "2.25",
    "sugar": "34.1",
    "protein": "8.42",
    "cal-from-protein": "34",
    "per-cal-from-protein": "9",
    "potassium": "415",
    "calcium": "288",
    "iron": "1.15",
    "phosphorus": "245",
    "iodine": "1",
    "source": "USDA",
    "old units (ref)": "cup"
  },
  {
    "id": "1035",
    "type": "snack",
    "|| name                                               ||": "Millefeuille",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "silce",
    "|| info                                                             ||": "e.g. vanila custard slice",
    "info-translation": "Vanila custard slice",
    "weight-g": "156",
    "calories": "401",
    "fat": "24",
    "cal-from-fat": "216",
    "per-cal-from-fat": "54",
    "saturated": "15",
    "sodium": "204",
    "carbs": "39",
    "fibre": "0",
    "sugar": "16",
    "protein": "4",
    "cal-from-protein": "16",
    "per-cal-from-protein": "4",
    "old units (ref)": "silce"
  },
  {
    "id": "13",
    "type": "drink",
    "|| name                                               ||": "Milo dinosaur",
    "rating": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "volume-ml": "450",
    "calories": "356.9",
    "fat": "9.7",
    "cal-from-fat": "87.3",
    "per-cal-from-fat": "24",
    "saturated": "6.4",
    "polyunsaturated": "0.3",
    "monounsaturated": "2.5",
    "trans": "0.1",
    "cholesterol": "28",
    "sodium": "153",
    "carbs": "56.1",
    "fibre": "3",
    "sugar": "46.4",
    "protein": "11.3",
    "cal-from-protein": "45",
    "per-cal-from-protein": "13",
    "potassium": "776",
    "calcium": "383",
    "iron": "382.2",
    "phosphorus": "512",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "12",
    "type": "drink",
    "|| name                                               ||": "Milo, 3-in-1 Active-Go",
    "rating": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "sachet",
    "|| info                                                             ||": "3-in-1",
    "volume-ml": "200",
    "calories": "105.9",
    "fat": "2.3",
    "cal-from-fat": "20.7",
    "per-cal-from-fat": "20",
    "saturated": "1.2",
    "polyunsaturated": "0.2",
    "monounsaturated": "1",
    "cholesterol": "2",
    "sodium": "86",
    "carbs": "18.7",
    "fibre": "1.1",
    "sugar": "13.8",
    "protein": "2.6",
    "cal-from-protein": "10",
    "per-cal-from-protein": "10",
    "calcium": "150",
    "phosphorus": "135",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "sachet"
  },
  {
    "id": "170",
    "type": "drink",
    "|| name                                               ||": "Milo O Kosong",
    "rating": "2",
    "high-gi": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "e.g. Milo o kosong",
    "info-include-exclude": "without milk or sugar",
    "volume-ml": "200",
    "calories": "126.2",
    "fat": "3",
    "cal-from-fat": "27",
    "per-cal-from-fat": "21",
    "saturated": "4.2",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.8",
    "cholesterol": "8",
    "sodium": "87",
    "carbs": "20.8",
    "fibre": "0",
    "sugar": "16.4",
    "protein": "4",
    "cal-from-protein": "16",
    "per-cal-from-protein": "13",
    "potassium": "285",
    "calcium": "173",
    "iron": "4.5",
    "phosphorus": "194",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "sachet"
  },
  {
    "id": "897",
    "type": "drink",
    "|| name                                               ||": "Milo, Activ-Go, 2 in 1 Calcium Plus",
    "rating": "2",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "sachet",
    "|| info                                                             ||": "e.g. Milo 2-in-1",
    "weight-g": "30",
    "calories": "116.8",
    "fat": "2.8",
    "cal-from-fat": "25.2",
    "per-cal-from-fat": "22",
    "saturated": "1.4",
    "cholesterol": "2",
    "sodium": "51",
    "carbs": "19.1",
    "fibre": "1.2",
    "sugar": "12.5",
    "protein": "3.8",
    "cal-from-protein": "15",
    "per-cal-from-protein": "13",
    "potassium": "330",
    "calcium": "330",
    "iron": "4.1",
    "phosphorus": "270",
    "source": "N Buddy",
    "old units (ref)": "sachet"
  },
  {
    "id": "290",
    "type": "meal",
    "|| name                                               ||": "Minced meat noodle, dry",
    "rating": "2",
    "high-gi": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "e.g. Bak chor mee / mee pok",
    "info-translation": "Bak Chor Mee / Mee Pok",
    "weight-g": "311",
    "calories": "510.3",
    "fat": "22.7",
    "cal-from-fat": "204.3",
    "per-cal-from-fat": "40",
    "saturated": "9.2",
    "polyunsaturated": "3.5",
    "monounsaturated": "9.1",
    "cholesterol": "28",
    "sodium": "1490",
    "carbs": "56.9",
    "fibre": "5",
    "sugar": "2.5",
    "protein": "19.6",
    "cal-from-protein": "78",
    "per-cal-from-protein": "15",
    "potassium": "193",
    "calcium": "53",
    "iron": "1.2",
    "phosphorus": "143",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "856",
    "type": "meal",
    "|| name                                               ||": "Miso ramen",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-salt": "1",
    "probiotic": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "553",
    "calories": "315",
    "fat": "6.1",
    "cal-from-fat": "54.9",
    "per-cal-from-fat": "17",
    "saturated": "1.5",
    "polyunsaturated": "0.9",
    "monounsaturated": "1.9",
    "cholesterol": "72",
    "sodium": "1714",
    "carbs": "48",
    "fibre": "3.3",
    "sugar": "1.1",
    "protein": "17.3",
    "cal-from-protein": "69",
    "per-cal-from-protein": "22",
    "potassium": "271",
    "calcium": "116",
    "iron": "0",
    "phosphorus": "127",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "587",
    "type": "drink",
    "|| name                                               ||": "Mixed grains, 3-in-1 cereal drink",
    "rating": "2",
    "high-phosphate": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "sachet",
    "|| info                                                             ||": "e.g. Quaker oat cereal, Unisoy nutritious soya cereal drink",
    "info-brands": "Quaker, Unisoy",
    "weight-g": "35",
    "calories": "139.4",
    "fat": "1.8",
    "cal-from-fat": "16.2",
    "per-cal-from-fat": "12",
    "saturated": "1",
    "polyunsaturated": "0.4",
    "monounsaturated": "0.4",
    "cholesterol": "0",
    "sodium": "86",
    "carbs": "26.7",
    "fibre": "0.8",
    "sugar": "12.9",
    "protein": "4.1",
    "cal-from-protein": "16",
    "per-cal-from-protein": "12",
    "calcium": "79",
    "iron": "0.8",
    "source": "N Buddy",
    "old units (ref)": "sachet"
  },
  {
    "id": "1027",
    "type": "snack",
    "|| name                                               ||": "Mocha cake",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "100",
    "calories": "474",
    "fat": "26.3",
    "cal-from-fat": "236.7",
    "per-cal-from-fat": "50",
    "saturated": "13.16",
    "cholesterol": "26",
    "sodium": "145",
    "carbs": "63.2",
    "fibre": "2.6",
    "sugar": "50",
    "protein": "2.63",
    "cal-from-protein": "11",
    "per-cal-from-protein": "2",
    "calcium": "0",
    "iron": "1.84",
    "source": "USDA",
    "old units (ref)": "slice"
  },
  {
    "id": "155",
    "type": "drink",
    "|| name                                               ||": "Mocktail",
    "rating": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "glass",
    "weight-g": "250",
    "calories": "90.4",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "22.6",
    "fibre": "0",
    "sugar": "20.7",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "source": "N Buddy",
    "old units (ref)": "glass"
  },
  {
    "id": "1036",
    "type": "snack",
    "|| name                                               ||": "Mont blanc cake",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "187",
    "calories": "404.9",
    "fat": "19.3",
    "cal-from-fat": "173.7",
    "per-cal-from-fat": "43",
    "saturated": "12.8",
    "polyunsaturated": "0.9",
    "monounsaturated": "4.3",
    "trans": "0.4",
    "cholesterol": "76",
    "sodium": "186",
    "carbs": "50.3",
    "fibre": "1.4",
    "sugar": "19",
    "protein": "7.5",
    "cal-from-protein": "30",
    "per-cal-from-protein": "7",
    "potassium": "214",
    "calcium": "52",
    "iron": "1.5",
    "phosphorus": "134",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "91",
    "type": "snack",
    "|| name                                               ||": "Mooncake, durian paste",
    "rating": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "0.25",
    "|| unit ||": "piece",
    "weight-g": "50",
    "calories": "205",
    "fat": "10.6",
    "cal-from-fat": "95.4",
    "per-cal-from-fat": "47",
    "saturated": "4.1",
    "polyunsaturated": "1.6",
    "monounsaturated": "4.4",
    "cholesterol": "1.5",
    "sodium": "93",
    "carbs": "24.9",
    "fibre": "1.4",
    "sugar": "16.2",
    "protein": "2.5",
    "cal-from-protein": "10",
    "per-cal-from-protein": "5",
    "potassium": "83",
    "calcium": "29",
    "iron": "0.9",
    "phosphorus": "72.5",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "|| name                                               ||": "Mooncake, durian paste",
    "|| info                                                             ||": "minature"
  },
  {
    "id": "88",
    "type": "snack",
    "|| name                                               ||": "Mooncake, lotus seed paste",
    "rating": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "0.25",
    "|| unit ||": "piece",
    "|| info                                                             ||": "traditional, 0-1 egg yolk",
    "info-portion-aid": "traditional",
    "info-include-exclude": "0-1 egg yolk",
    "weight-g": "47",
    "calories": "196.8",
    "fat": "11.2",
    "cal-from-fat": "100.8",
    "per-cal-from-fat": "51",
    "saturated": "2.8",
    "polyunsaturated": "3",
    "monounsaturated": "4.9",
    "cholesterol": "62.5",
    "sodium": "70",
    "carbs": "20.8",
    "fibre": "1",
    "sugar": "14.5",
    "protein": "3.2",
    "cal-from-protein": "13",
    "per-cal-from-protein": "7",
    "potassium": "76.3",
    "calcium": "30.5",
    "iron": "1.1",
    "phosphorus": "85",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "89",
    "type": "snack",
    "|| name                                               ||": "Mooncake, lotus seed paste",
    "rating": "1",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "0.25",
    "|| unit ||": "piece",
    "|| info                                                             ||": "traditional, 2-4 egg yolks",
    "info-portion-aid": "traditional",
    "info-include-exclude": "2-4 egg yolks",
    "weight-g": "52",
    "calories": "244.2",
    "fat": "17",
    "cal-from-fat": "153",
    "per-cal-from-fat": "63",
    "saturated": "5",
    "polyunsaturated": "3",
    "monounsaturated": "8.2",
    "cholesterol": "203.8",
    "sodium": "109.3",
    "carbs": "17.6",
    "fibre": "0.7",
    "sugar": "11.6",
    "protein": "5.2",
    "cal-from-protein": "21",
    "per-cal-from-protein": "9",
    "potassium": "71.8",
    "calcium": "58.3",
    "iron": "1.9",
    "phosphorus": "164.3",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "|| name                                               ||": "Mooncake, lotus seed paste",
    "|| info                                                             ||": "minature with yolk",
    "info-portion-aid": "miniature",
    "info-include-exclude": "with yolk"
  },
  {
    "|| name                                               ||": "Mooncake, lotus seed paste",
    "|| info                                                             ||": "minature no yolk",
    "info-portion-aid": "miniature",
    "info-include-exclude": "without yolk"
  },
  {
    "id": "90",
    "type": "snack",
    "|| name                                               ||": "Mooncake, snowskin",
    "rating": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "0.25",
    "|| unit ||": "piece",
    "weight-g": "66",
    "calories": "245.8",
    "fat": "10.6",
    "cal-from-fat": "95.4",
    "per-cal-from-fat": "39",
    "saturated": "3.4",
    "polyunsaturated": "2.2",
    "monounsaturated": "4.6",
    "cholesterol": "3.3",
    "sodium": "41",
    "carbs": "34.8",
    "fibre": "1.5",
    "sugar": "25.4",
    "protein": "2.8",
    "cal-from-protein": "11",
    "per-cal-from-protein": "5",
    "potassium": "87",
    "calcium": "21.8",
    "iron": "0.7",
    "phosphorus": "50.8",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "|| name                                               ||": "Mooncake, snowskin",
    "|| info                                                             ||": "minature",
    "info-portion-aid": "miniature"
  },
  {
    "id": "688",
    "type": "meal",
    "|| name                                               ||": "Moringa seed pods, stir-fried",
    "rating": "3",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "100",
    "calories": "72.1",
    "fat": "4.1",
    "cal-from-fat": "36.9",
    "per-cal-from-fat": "51",
    "saturated": "0.6",
    "polyunsaturated": "2.6",
    "monounsaturated": "0.8",
    "cholesterol": "0",
    "sodium": "313",
    "carbs": "5.7",
    "fibre": "2.7",
    "sugar": "0.1",
    "protein": "3.1",
    "cal-from-protein": "12",
    "per-cal-from-protein": "17",
    "potassium": "154",
    "calcium": "26",
    "iron": "0.2",
    "phosphorus": "31",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "63",
    "type": "snack",
    "|| name                                               ||": "Muah chee",
    "rating": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "info-translation": "Mochi",
    "alternate-spellings": "Muah: Muar",
    "weight-g": "100",
    "calories": "254.2",
    "fat": "9.4",
    "cal-from-fat": "84.6",
    "per-cal-from-fat": "33",
    "saturated": "2.5",
    "polyunsaturated": "3",
    "monounsaturated": "3.5",
    "cholesterol": "0",
    "sodium": "7",
    "carbs": "34.8",
    "fibre": "3.8",
    "sugar": "8",
    "protein": "7.6",
    "cal-from-protein": "30",
    "per-cal-from-protein": "12",
    "calcium": "17",
    "iron": "0.6",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "207",
    "type": "snack",
    "|| name                                               ||": "Muffin",
    "rating": "2",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. cinnamon apple muffin",
    "info-examples": "cinnamon, apple",
    "weight-g": "130",
    "calories": "488.2",
    "fat": "26.2",
    "cal-from-fat": "235.8",
    "per-cal-from-fat": "48",
    "saturated": "14.2",
    "polyunsaturated": "2.6",
    "monounsaturated": "8.1",
    "cholesterol": "117",
    "sodium": "722",
    "carbs": "56.3",
    "fibre": "2",
    "sugar": "26.8",
    "protein": "6.8",
    "cal-from-protein": "27",
    "per-cal-from-protein": "6",
    "potassium": "154",
    "calcium": "208",
    "iron": "1.5",
    "phosphorus": "298",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "346",
    "type": "meal",
    "|| name                                               ||": "Mui fan",
    "rating": "2",
    "high-cholesterol": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Rice in seafood, meat and vegatable gravy",
    "info-translation": "Rice in seafood, meat and vegatable gravy",
    "alternate-spellings": "Mui: Lam, Mun",
    "weight-g": "712",
    "calories": "755.5",
    "fat": "25.1",
    "cal-from-fat": "225.9",
    "per-cal-from-fat": "30",
    "saturated": "9.2",
    "polyunsaturated": "3.8",
    "monounsaturated": "9.3",
    "cholesterol": "234",
    "sodium": "2105",
    "carbs": "87.2",
    "fibre": "3.9",
    "sugar": "5.5",
    "protein": "45.2",
    "cal-from-protein": "181",
    "per-cal-from-protein": "24",
    "potassium": "570",
    "calcium": "152",
    "iron": "6.7",
    "phosphorus": "380",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "126",
    "type": "meal",
    "|| name                                               ||": "Murtabak with meat filling",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "whole",
    "|| info                                                             ||": "mutton / sardine / chicken",
    "info-examples": "mutton / sardine / chicken",
    "alternate-spellings": "Murtabak: Martabak",
    "weight-g": "357",
    "calories": "788",
    "fat": "39.6",
    "cal-from-fat": "356.4",
    "per-cal-from-fat": "45",
    "saturated": "17.5",
    "polyunsaturated": "4.1",
    "monounsaturated": "16.3",
    "cholesterol": "325",
    "sodium": "1514",
    "carbs": "69.3",
    "fibre": "5.4",
    "sugar": "10",
    "protein": "38.6",
    "cal-from-protein": "154",
    "per-cal-from-protein": "20",
    "potassium": "557",
    "calcium": "475",
    "iron": "5.4",
    "phosphorus": "557",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "975",
    "type": "meal",
    "|| name                                               ||": "Mussel / oyster in wine / butter",
    "rating": "2",
    "high-iodine": "1",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "cooked in wine / butter / half & half, e.g. oysters rockefeller",
    "info-examples": "oysters rockefeller",
    "weight-g": "24",
    "calories": "31",
    "fat": "1.68",
    "cal-from-fat": "15.12",
    "per-cal-from-fat": "49",
    "saturated": "0.76",
    "polyunsaturated": "0.232",
    "monounsaturated": "0.497",
    "cholesterol": "8.16",
    "sodium": "102",
    "carbs": "2.17",
    "fibre": "0.384",
    "sugar": "0.302",
    "protein": "1.8",
    "cal-from-protein": "7",
    "per-cal-from-protein": "23",
    "potassium": "64.8",
    "calcium": "23",
    "iron": "0.811",
    "phosphorus": "25.4",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "976",
    "type": "meal",
    "|| name                                               ||": "Mussel / oyster in stew / sauces",
    "rating": "2",
    "high-fat": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "cooked in stew / sauces, e.g. mussels in tomato sauce",
    "weight-g": "12",
    "calories": "10.75",
    "fat": "0.278",
    "cal-from-fat": "2.502",
    "per-cal-from-fat": "23",
    "saturated": "0.052",
    "polyunsaturated": "0.076",
    "monounsaturated": "0.062",
    "cholesterol": "3.36",
    "sodium": "48.5",
    "carbs": "0.602",
    "fibre": "0.048",
    "sugar": "0.107",
    "protein": "1.46",
    "cal-from-protein": "6",
    "per-cal-from-protein": "54",
    "potassium": "43.4",
    "calcium": "3.6",
    "iron": "0.456",
    "phosphorus": "18.5",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "796",
    "type": "meal",
    "|| name                                               ||": "Mussel, lightly seasoned / garlic / herbs / raw",
    "rating": "2",
    "high-iron": "1",
    "high-purine": "1",
    "serving": "4",
    "|| unit ||": "piece",
    "weight-g": "36",
    "calories": "80.96",
    "fat": "4",
    "cal-from-fat": "36",
    "per-cal-from-fat": "44",
    "saturated": "0.32",
    "polyunsaturated": "0.44",
    "monounsaturated": "0.36",
    "cholesterol": "20",
    "sodium": "132.8",
    "carbs": "2.68",
    "fibre": "0",
    "sugar": "0",
    "protein": "8.56",
    "cal-from-protein": "34",
    "per-cal-from-protein": "42",
    "potassium": "96.4",
    "calcium": "12",
    "iron": "2.44",
    "phosphorus": "102.4",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "452",
    "type": "meal",
    "|| name                                               ||": "Mutton soup",
    "rating": "2",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "chinese style",
    "info-include-exclude": "Chinese style",
    "weight-g": "450",
    "calories": "329.4",
    "fat": "23",
    "cal-from-fat": "207",
    "per-cal-from-fat": "63",
    "saturated": "11.3",
    "polyunsaturated": "1.8",
    "monounsaturated": "9",
    "cholesterol": "153",
    "sodium": "1233",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "30.6",
    "cal-from-protein": "122",
    "per-cal-from-protein": "37",
    "potassium": "347",
    "calcium": "50",
    "iron": "0",
    "phosphorus": "194",
    "source": "USDA",
    "old units (ref)": "bowl"
  },
  {
    "id": "720",
    "type": "snack",
    "|| name                                               ||": "Mycoprotein",
    "rating": "3",
    "high-fibre": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "|| info                                                             ||": "1 palm-size portion",
    "info-portion-aid": "3\" x 3\" x 1\"",
    "weight-g": "100",
    "calories": "76.2",
    "fat": "2.6",
    "cal-from-fat": "23.4",
    "per-cal-from-fat": "31",
    "saturated": "0.6",
    "carbs": "1.7",
    "fibre": "6",
    "sugar": "0.8",
    "protein": "11.5",
    "cal-from-protein": "46",
    "per-cal-from-protein": "60",
    "source": "https://journals.lww.com/nutritiontodayonline/FullText/2019/01000/Mycoprotein__Nutritional_and_Health_Properties.4.aspx",
    "old units (ref)": "portion"
  },
  {
    "id": "151",
    "type": "meal",
    "|| name                                               ||": "Naan, flavored",
    "rating": "2",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. garlic, butter",
    "info-examples": "garlic, butter",
    "weight-g": "103",
    "calories": "368.6",
    "fat": "21",
    "cal-from-fat": "189",
    "per-cal-from-fat": "51",
    "saturated": "12.5",
    "polyunsaturated": "1.3",
    "monounsaturated": "6.2",
    "cholesterol": "57",
    "sodium": "353",
    "carbs": "38",
    "fibre": "2.2",
    "sugar": "4.7",
    "protein": "6.9",
    "cal-from-protein": "28",
    "per-cal-from-protein": "7",
    "potassium": "26",
    "calcium": "37",
    "iron": "0.5",
    "phosphorus": "133",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "150",
    "type": "meal",
    "|| name                                               ||": "Naan, plain",
    "rating": "2",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "90",
    "calories": "261.96",
    "fat": "5.08",
    "cal-from-fat": "45.72",
    "per-cal-from-fat": "17",
    "saturated": "1.25",
    "polyunsaturated": "1.89",
    "monounsaturated": "1.62",
    "trans": "0.038",
    "sodium": "418",
    "carbs": "45.4",
    "fibre": "1.98",
    "sugar": "3.2",
    "protein": "8.66",
    "cal-from-protein": "35",
    "per-cal-from-protein": "13",
    "potassium": "112",
    "calcium": "75.6",
    "iron": "2.92",
    "phosphorus": "90",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "291",
    "type": "meal",
    "|| name                                               ||": "Naengmyeon",
    "rating": "2",
    "high-purine": "1",
    "high-salt": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Korean cold buckwheat noodles",
    "info-translation": "Korean cold noodles",
    "weight-g": "550",
    "calories": "451.6",
    "fat": "4.8",
    "cal-from-fat": "43.2",
    "per-cal-from-fat": "10",
    "saturated": "1.1",
    "polyunsaturated": "1.2",
    "monounsaturated": "1.6",
    "cholesterol": "106",
    "sodium": "2000",
    "carbs": "87.8",
    "fibre": "7",
    "sugar": "28.4",
    "protein": "14.3",
    "cal-from-protein": "57",
    "per-cal-from-protein": "13",
    "calcium": "112",
    "iron": "3.7",
    "phosphorus": "315",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "650",
    "type": "meal",
    "|| name                                               ||": "Nai bai, stir-fried",
    "rating": "3",
    "high-calcium": "1",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "info-translation": "Pak Choi",
    "alternate-spellings": "Pak: Bak, Bok, Pok \nChoi: Choy, Chye",
    "weight-g": "100",
    "calories": "69.3",
    "fat": "4.5",
    "cal-from-fat": "40.5",
    "per-cal-from-fat": "58",
    "saturated": "0.7",
    "polyunsaturated": "2.8",
    "monounsaturated": "0.8",
    "sodium": "350",
    "carbs": "5.1",
    "fibre": "1.5",
    "sugar": "3.2",
    "protein": "2.1",
    "cal-from-protein": "8",
    "per-cal-from-protein": "12",
    "potassium": "276",
    "calcium": "117",
    "iron": "0.5",
    "phosphorus": "7",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "553",
    "type": "meal",
    "|| name                                               ||": "Nasi goreng pattaya",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Fried rice wrapped in omelette",
    "info-translation": "Fried rice wrapped in omelette",
    "weight-g": "383",
    "calories": "804.9",
    "fat": "42.1",
    "cal-from-fat": "378.9",
    "per-cal-from-fat": "47",
    "saturated": "16.6",
    "polyunsaturated": "5.5",
    "monounsaturated": "18.2",
    "cholesterol": "441",
    "sodium": "2436",
    "carbs": "80.1",
    "fibre": "4.2",
    "sugar": "8",
    "protein": "26.4",
    "cal-from-protein": "106",
    "per-cal-from-protein": "13",
    "potassium": "337",
    "calcium": "111",
    "iron": "4.6",
    "phosphorus": "306",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "292",
    "type": "meal",
    "|| name                                               ||": "Nasi lemak",
    "rating": "2",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Coconut rice with chicken wing, egg, ikan billis, peanuts and sambal chilli",
    "info-translation": "Coconut rice with chicken wing, egg, ikan billis, peanuts and sambal chilli",
    "weight-g": "375",
    "calories": "847.4",
    "fat": "38.6",
    "cal-from-fat": "347.4",
    "per-cal-from-fat": "41",
    "saturated": "14.4",
    "polyunsaturated": "7.8",
    "monounsaturated": "13.6",
    "cholesterol": "179",
    "sodium": "1455",
    "carbs": "79.1",
    "fibre": "3.8",
    "sugar": "4.1",
    "protein": "45.9",
    "cal-from-protein": "184",
    "per-cal-from-protein": "22",
    "potassium": "615",
    "calcium": "231",
    "iron": "4.5",
    "phosphorus": "591",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "293",
    "type": "meal",
    "|| name                                               ||": "Nasi padang",
    "rating": "2",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Malay mixed rice, 1 meat and 1 vegetable",
    "info-translation": "Malay mixed rice",
    "info-include-exclude": "1 meat and 1 vegetable",
    "weight-g": "400",
    "calories": "588.3",
    "fat": "23.9",
    "cal-from-fat": "215.1",
    "per-cal-from-fat": "37",
    "saturated": "8.8",
    "polyunsaturated": "5",
    "monounsaturated": "8.9",
    "cholesterol": "110",
    "sodium": "1038",
    "carbs": "68.2",
    "fibre": "4.3",
    "sugar": "1.4",
    "protein": "25.1",
    "cal-from-protein": "100",
    "per-cal-from-protein": "17",
    "potassium": "222",
    "calcium": "129",
    "iron": "7.4",
    "phosphorus": "62",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "1058",
    "type": "meal",
    "|| name                                               ||": "Nasi padang",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Malay mixed rice, sambal goreng",
    "info-translation": "Malay mixed rice",
    "info-include-exclude": "sambal goreng",
    "weight-g": "522",
    "calories": "705.9",
    "fat": "30.7",
    "cal-from-fat": "276.3",
    "per-cal-from-fat": "39",
    "saturated": "7.4",
    "polyunsaturated": "8",
    "monounsaturated": "10",
    "cholesterol": "81",
    "sodium": "961",
    "carbs": "68.8",
    "fibre": "12",
    "sugar": "6.3",
    "protein": "38.6",
    "cal-from-protein": "154",
    "per-cal-from-protein": "22",
    "potassium": "467",
    "calcium": "229",
    "iron": "11.1",
    "phosphorus": "383",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "1057",
    "disabled": "1",
    "type": "meal",
    "|| name                                               ||": "Nasi padang",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Malay mixed rice, 1 meat and 1 vegetable",
    "info-translation": "Malay mixed rice",
    "weight-g": "400",
    "calories": "588.3",
    "fat": "23.9",
    "cal-from-fat": "215.1",
    "per-cal-from-fat": "37",
    "saturated": "8.8",
    "polyunsaturated": "5",
    "monounsaturated": "8.9",
    "cholesterol": "110",
    "sodium": "1038",
    "carbs": "68.2",
    "fibre": "4.3",
    "sugar": "1.4",
    "protein": "25.1",
    "cal-from-protein": "100",
    "per-cal-from-protein": "17",
    "potassium": "222",
    "calcium": "129",
    "iron": "7.4",
    "phosphorus": "62",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "1059",
    "type": "meal",
    "|| name                                               ||": "Nasi padang",
    "rating": "1",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Malay mixed rice, sambal goreng and 1 meat",
    "info-translation": "Malay mixed rice",
    "info-include-exclude": "sambal goreng and 1 meat",
    "weight-g": "622",
    "calories": "887.4",
    "fat": "39.8",
    "cal-from-fat": "358.2",
    "per-cal-from-fat": "40",
    "saturated": "12.1",
    "polyunsaturated": "8.5",
    "monounsaturated": "13.2",
    "cholesterol": "132",
    "sodium": "1603",
    "carbs": "73",
    "fibre": "12.1",
    "sugar": "7.3",
    "protein": "59.3",
    "cal-from-protein": "237",
    "per-cal-from-protein": "27",
    "potassium": "467",
    "calcium": "263",
    "iron": "14.4",
    "phosphorus": "383",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "1060",
    "type": "meal",
    "|| name                                               ||": "Nasi padang",
    "rating": "1",
    "fried": "1",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Malay mixed rice, sambal goreng and 1 fried meat",
    "info-translation": "Malay mixed rice",
    "info-include-exclude": "sambal goreng and 1 fried meat",
    "weight-g": "618",
    "calories": "870",
    "fat": "42",
    "cal-from-fat": "378",
    "per-cal-from-fat": "43",
    "saturated": "10.5",
    "polyunsaturated": "12.8",
    "monounsaturated": "18.5",
    "trans": "0.1",
    "cholesterol": "159",
    "sodium": "1001",
    "carbs": "71.1",
    "fibre": "12",
    "sugar": "6.3",
    "protein": "51.9",
    "cal-from-protein": "208",
    "per-cal-from-protein": "24",
    "potassium": "637",
    "calcium": "243",
    "iron": "12.1",
    "phosphorus": "527",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "497",
    "type": "meal",
    "|| name                                               ||": "Noodle casserole with cheese",
    "rating": "3",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "300",
    "calories": "216.5",
    "fat": "0.5",
    "cal-from-fat": "4.5",
    "per-cal-from-fat": "2",
    "saturated": "12",
    "polyunsaturated": "1.2",
    "monounsaturated": "11.8",
    "cholesterol": "105",
    "sodium": "1350",
    "carbs": "30",
    "fibre": "5",
    "sugar": "11",
    "protein": "23",
    "cal-from-protein": "92",
    "per-cal-from-protein": "42",
    "calcium": "500",
    "iron": "3.6",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "294",
    "disabled": "1",
    "type": "meal",
    "|| name                                               ||": "Noodles (soup)",
    "rating": "2"
  },
  {
    "id": "36",
    "type": "snack",
    "|| name                                               ||": "Nougat with almonds",
    "rating": "2",
    "high-gi": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "with almonds",
    "weight-g": "14",
    "calories": "55.57",
    "fat": "0.234",
    "cal-from-fat": "2.106",
    "per-cal-from-fat": "4",
    "saturated": "0.233",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "4.62",
    "carbs": "12.9",
    "fibre": "0.462",
    "sugar": "6.3",
    "protein": "0.466",
    "cal-from-protein": "2",
    "per-cal-from-protein": "3",
    "potassium": "14.7",
    "calcium": "4.48",
    "iron": "0.083",
    "phosphorus": "7.7",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "|| name                                               ||": "Nougat, plain",
    "|| info                                                             ||": "plain"
  },
  {
    "id": "412",
    "type": "snack",
    "|| name                                               ||": "Nut butter spread, unsweetened",
    "rating": "3",
    "healthy-fats": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "|| info                                                             ||": "e.g. pistachio, cashew, almond butter",
    "info-examples": "cashew, almond",
    "weight-g": "16",
    "calories": "105.36",
    "fat": "8.88",
    "cal-from-fat": "79.92",
    "per-cal-from-fat": "76",
    "saturated": "1.05",
    "polyunsaturated": "2.18",
    "monounsaturated": "5.19",
    "cholesterol": "0",
    "sodium": "36.3",
    "carbs": "3.01",
    "fibre": "1.65",
    "sugar": "1",
    "protein": "3.35",
    "cal-from-protein": "13",
    "per-cal-from-protein": "13",
    "potassium": "120",
    "calcium": "55.5",
    "iron": "0.558",
    "phosphorus": "81.3",
    "source": "USDA",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "1033",
    "type": "snack",
    "|| name                                               ||": "Mixed nut tart",
    "rating": "2",
    "high-calcium": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "|| info                                                             ||": "e.g. pecan, almond",
    "info-examples": "pecan, almond",
    "weight-g": "85",
    "calories": "346",
    "fat": "14.2",
    "cal-from-fat": "127.8",
    "per-cal-from-fat": "37",
    "saturated": "2.26",
    "polyunsaturated": "3.02",
    "monounsaturated": "7.07",
    "cholesterol": "36",
    "sodium": "234",
    "carbs": "50.7",
    "fibre": "1.78",
    "sugar": "21.4",
    "protein": "3.82",
    "cal-from-protein": "15",
    "per-cal-from-protein": "4",
    "potassium": "84.2",
    "calcium": "18.7",
    "iron": "0.79",
    "phosphorus": "70.6",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "374",
    "type": "snack",
    "|| name                                               ||": "Mixed nuts, unsalted",
    "rating": "3",
    "healthy-fats": "1",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "handful",
    "|| info                                                             ||": "e.g. almonds, cashews, walnuts, macadamia nuts",
    "info-examples": "almonds, cashews, walnuts, macadamia",
    "weight-g": "30",
    "calories": "189.8",
    "fat": "16.6",
    "cal-from-fat": "149.4",
    "per-cal-from-fat": "79",
    "saturated": "2.2",
    "polyunsaturated": "4.5",
    "monounsaturated": "9.2",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "3.9",
    "fibre": "2.1",
    "sugar": "1.4",
    "protein": "6.2",
    "cal-from-protein": "25",
    "per-cal-from-protein": "13",
    "potassium": "188",
    "calcium": "21",
    "iron": "0.8",
    "phosphorus": "125",
    "source": "N Buddy",
    "old units (ref)": "handful"
  },
  {
    "id": "24",
    "type": "snack",
    "|| name                                               ||": "Nyonya kuih",
    "rating": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. kueh dadar, kueh talam",
    "info-examples": "kueh dadar, kueh talam",
    "alternate-spellings": "Nyonya: Nonya\nKuih: Kueh",
    "weight-g": "131",
    "calories": "243.3",
    "fat": "7.3",
    "cal-from-fat": "65.7",
    "per-cal-from-fat": "27",
    "saturated": "6.5",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.4",
    "cholesterol": "3",
    "sodium": "152",
    "carbs": "42.6",
    "fibre": "1.6",
    "sugar": "23.8",
    "protein": "1.8",
    "cal-from-protein": "7",
    "per-cal-from-protein": "3",
    "potassium": "93",
    "calcium": "21",
    "iron": "0",
    "phosphorus": "35",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "368",
    "type": "drink",
    "|| name                                               ||": "Oat bran, cooked",
    "rating": "3",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "plant-protein": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "weight-g": "100",
    "calories": "66.18",
    "fat": "0.86",
    "cal-from-fat": "7.74",
    "per-cal-from-fat": "12",
    "saturated": "0.163",
    "polyunsaturated": "0.339",
    "monounsaturated": "0.291",
    "cholesterol": "0",
    "sodium": "1",
    "carbs": "11.4",
    "fibre": "2.6",
    "protein": "3.21",
    "cal-from-protein": "13",
    "per-cal-from-protein": "19",
    "potassium": "92",
    "calcium": "10",
    "iron": "0.88",
    "phosphorus": "119",
    "iodine": "1",
    "source": "USDA",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "167",
    "type": "drink",
    "|| name                                               ||": "Oat milk, sweetened",
    "rating": "3",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "volume-ml": "250",
    "calories": "120",
    "fat": "3.8",
    "cal-from-fat": "34.2",
    "per-cal-from-fat": "29",
    "saturated": "0.5",
    "sodium": "250",
    "carbs": "16.3",
    "fibre": "2",
    "sugar": "10",
    "protein": "2.5",
    "cal-from-protein": "10",
    "per-cal-from-protein": "8",
    "calcium": "300",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "466",
    "type": "drink",
    "|| name                                               ||": "Oat milk, unsweetened",
    "rating": "3",
    "high-phosphate": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "volume-ml": "250",
    "calories": "100",
    "fat": "4.8",
    "cal-from-fat": "43.2",
    "per-cal-from-fat": "43",
    "saturated": "0.8",
    "polyunsaturated": "1.2",
    "monounsaturated": "2.6",
    "cholesterol": "0",
    "sodium": "95",
    "carbs": "19.3",
    "fibre": "0",
    "sugar": "9.3",
    "protein": "5.3",
    "cal-from-protein": "21",
    "per-cal-from-protein": "21",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "295",
    "type": "meal",
    "|| name                                               ||": "Okonomiyaki",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "323",
    "calories": "471",
    "fat": "25.2",
    "cal-from-fat": "226.8",
    "per-cal-from-fat": "48",
    "saturated": "7.5",
    "sodium": "1457",
    "carbs": "41",
    "fibre": "8.7",
    "protein": "20.4",
    "cal-from-protein": "82",
    "per-cal-from-protein": "17",
    "source": "HPB",
    "old units (ref)": "piece"
  },
  {
    "id": "508",
    "type": "meal",
    "|| name                                               ||": "Okowa",
    "rating": "1",
    "high-gi": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "steamed sticky glutinous rice with beans, chestnuts, vegetables / seafood",
    "info-translation": "Japanese steamed glutinous rice with meat or vegetables",
    "calories": "259",
    "fat": "16.8",
    "cal-from-fat": "151.2",
    "per-cal-from-fat": "58",
    "saturated": "4.7",
    "polyunsaturated": "3.8",
    "monounsaturated": "6.6",
    "cholesterol": "92",
    "sodium": "187",
    "carbs": "23.9",
    "fibre": "0.9",
    "sugar": "1.4",
    "protein": "28.6",
    "cal-from-protein": "114",
    "per-cal-from-protein": "44",
    "potassium": "293",
    "calcium": "22",
    "iron": "2.7",
    "phosphorus": "213",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "296",
    "type": "meal",
    "|| name                                               ||": "Olive rice",
    "rating": "2",
    "high-gi": "1",
    "high-iron": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "205",
    "calories": "356.5",
    "fat": "11.7",
    "cal-from-fat": "105.3",
    "per-cal-from-fat": "30",
    "saturated": "3.5",
    "polyunsaturated": "1.7",
    "monounsaturated": "6.4",
    "cholesterol": "0",
    "sodium": "278",
    "carbs": "56.8",
    "fibre": "1.8",
    "sugar": "0.5",
    "protein": "6",
    "cal-from-protein": "24",
    "per-cal-from-protein": "7",
    "potassium": "18",
    "calcium": "0",
    "iron": "4.1",
    "phosphorus": "20",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "637",
    "type": "meal",
    "|| name                                               ||": "Olive",
    "rating": "3",
    "fruit": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "100",
    "calories": "242",
    "fat": "26",
    "cal-from-fat": "234",
    "per-cal-from-fat": "97",
    "saturated": "0.4",
    "polyunsaturated": "0.2",
    "monounsaturated": "1.8",
    "cholesterol": "0",
    "sodium": "60",
    "carbs": "0",
    "fibre": "2",
    "sugar": "0",
    "protein": "2",
    "cal-from-protein": "8",
    "per-cal-from-protein": "3",
    "potassium": "44",
    "calcium": "6",
    "iron": "0.2",
    "phosphorus": "2",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "1095",
    "type": "meal",
    "|| name                                               ||": "Omelette with cheese",
    "rating": "2",
    "high-calcium": "1",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "67",
    "calories": "156",
    "fat": "5.8",
    "cal-from-fat": "52.2",
    "per-cal-from-fat": "33",
    "saturated": "5.9",
    "polyunsaturated": "0.9",
    "monounsaturated": "4.4",
    "cholesterol": "215",
    "sodium": "334",
    "carbs": "0.2",
    "fibre": "0",
    "sugar": "0.2",
    "protein": "11",
    "cal-from-protein": "44",
    "per-cal-from-protein": "28",
    "potassium": "84",
    "calcium": "160",
    "iron": "1.3",
    "phosphorus": "151",
    "source": "N Buddy",
    "old units (ref)": "omelette"
  },
  {
    "id": "1096",
    "type": "meal",
    "|| name                                               ||": "Omelette with onion",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "100",
    "calories": "152",
    "fat": "10",
    "cal-from-fat": "90",
    "per-cal-from-fat": "59",
    "cholesterol": "130",
    "sodium": "496",
    "carbs": "7",
    "fibre": "2.4",
    "sugar": "3.1",
    "protein": "8.3",
    "cal-from-protein": "33",
    "per-cal-from-protein": "22",
    "potassium": "167",
    "calcium": "57",
    "iron": "1.2",
    "phosphorus": "202",
    "source": "N Buddy with adjustments",
    "old units (ref)": "omelette"
  },
  {
    "id": "551",
    "type": "meal",
    "|| name                                               ||": "Omelette with vegetables",
    "rating": "3",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "95",
    "calories": "106.2",
    "fat": "7.8",
    "cal-from-fat": "70.2",
    "per-cal-from-fat": "66",
    "saturated": "1.8",
    "polyunsaturated": "2.1",
    "monounsaturated": "3.3",
    "cholesterol": "124",
    "sodium": "685",
    "carbs": "4",
    "fibre": "1",
    "sugar": "2.4",
    "protein": "5",
    "cal-from-protein": "20",
    "per-cal-from-protein": "19",
    "potassium": "155",
    "calcium": "27",
    "iron": "0.8",
    "phosphorus": "81",
    "source": "N Buddy",
    "old units (ref)": "omelette"
  },
  {
    "id": "552",
    "type": "meal",
    "|| name                                               ||": "Omurice",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Japanese omelette rice",
    "info-translation": "Japanese omelette rice",
    "weight-g": "340",
    "calories": "540.6",
    "fat": "17",
    "cal-from-fat": "153",
    "per-cal-from-fat": "28",
    "saturated": "4.8",
    "polyunsaturated": "3.2",
    "monounsaturated": "6.2",
    "cholesterol": "252",
    "sodium": "748",
    "carbs": "74.8",
    "fibre": "0",
    "sugar": "8.8",
    "protein": "22.1",
    "cal-from-protein": "88",
    "per-cal-from-protein": "16",
    "potassium": "313",
    "calcium": "78",
    "iron": "1",
    "phosphorus": "197",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "58",
    "type": "snack",
    "|| name                                               ||": "Ondeh ondeh",
    "rating": "2",
    "high-gi": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "4",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Klepon",
    "info-translation": "Klepon",
    "weight-g": "100",
    "calories": "278.8",
    "fat": "10",
    "cal-from-fat": "90",
    "per-cal-from-fat": "32",
    "saturated": "7.6",
    "polyunsaturated": "0.4",
    "monounsaturated": "1.6",
    "cholesterol": "0",
    "sodium": "76",
    "carbs": "44.4",
    "fibre": "2.4",
    "sugar": "15",
    "protein": "2.8",
    "cal-from-protein": "11",
    "per-cal-from-protein": "4",
    "potassium": "240",
    "calcium": "20",
    "iron": "1.2",
    "phosphorus": "48",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "1040",
    "type": "snack",
    "|| name                                               ||": "Ondeh ondeh cake",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "100",
    "calories": "323",
    "fat": "15",
    "cal-from-fat": "135",
    "per-cal-from-fat": "42",
    "saturated": "11",
    "cholesterol": "41",
    "sodium": "466",
    "carbs": "42",
    "fibre": "2",
    "sugar": "30",
    "protein": "5",
    "cal-from-protein": "20",
    "per-cal-from-protein": "6",
    "old units (ref)": "slice"
  },
  {
    "id": "297",
    "type": "meal",
    "|| name                                               ||": "Onigiri",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Japanese rice balls",
    "info-translation": "Japanese rice balls",
    "weight-g": "73",
    "calories": "124.1",
    "fat": "2.1",
    "cal-from-fat": "18.9",
    "per-cal-from-fat": "15",
    "saturated": "0.3",
    "polyunsaturated": "1.3",
    "monounsaturated": "0.4",
    "cholesterol": "2",
    "sodium": "157",
    "carbs": "22.6",
    "fibre": "0.8",
    "sugar": "1.5",
    "protein": "3.7",
    "cal-from-protein": "15",
    "per-cal-from-protein": "12",
    "potassium": "53",
    "calcium": "5",
    "iron": "0.2",
    "phosphorus": "35",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "83",
    "type": "snack",
    "|| name                                               ||": "Onion rings",
    "rating": "2",
    "fried": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "pack",
    "|| info                                                             ||": "small packet",
    "info-portion-aid": "small",
    "weight-g": "117",
    "calories": "487.58",
    "fat": "29.5",
    "cal-from-fat": "265.5",
    "per-cal-from-fat": "54",
    "saturated": "2.91",
    "polyunsaturated": "14.2",
    "monounsaturated": "8.06",
    "cholesterol": "0",
    "sodium": "908",
    "carbs": "51",
    "fibre": "3.16",
    "sugar": "6.35",
    "protein": "4.52",
    "cal-from-protein": "18",
    "per-cal-from-protein": "4",
    "potassium": "195",
    "calcium": "135",
    "iron": "0.913",
    "phosphorus": "185",
    "source": "USDA",
    "old units (ref)": "small pack"
  },
  {
    "id": "824",
    "disabled": "1",
    "type": "snack",
    "|| name                                               ||": "Onion rings",
    "rating": "2",
    "fried": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "pack",
    "|| info                                                             ||": "18 onion rings",
    "weight-g": "117",
    "calories": "481",
    "fat": "29.5",
    "cal-from-fat": "265.5",
    "per-cal-from-fat": "55",
    "saturated": "4.9",
    "polyunsaturated": "14.2",
    "monounsaturated": "8.1",
    "trans": "0.2",
    "cholesterol": "0",
    "sodium": "908",
    "carbs": "51",
    "fibre": "3.2",
    "sugar": "6.4",
    "protein": "4.5",
    "cal-from-protein": "18",
    "per-cal-from-protein": "4",
    "potassium": "195",
    "calcium": "135",
    "iron": "0.9",
    "phosphorus": "185",
    "source": "USDA",
    "old units (ref)": "medium pack"
  },
  {
    "id": "904",
    "type": "snack",
    "|| name                                               ||": "Opera cake",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "66",
    "calories": "234",
    "fat": "5.8",
    "cal-from-fat": "52.2",
    "per-cal-from-fat": "22",
    "saturated": "1.8",
    "polyunsaturated": "0.8",
    "monounsaturated": "2.8",
    "cholesterol": "46",
    "sodium": "328",
    "carbs": "43.9",
    "fibre": "0.5",
    "sugar": "31.5",
    "protein": "2.7",
    "cal-from-protein": "11",
    "per-cal-from-protein": "5",
    "potassium": "95",
    "calcium": "34",
    "iron": "1.6",
    "phosphorus": "83",
    "source": "USDA",
    "old units (ref)": "slice"
  },
  {
    "id": "393",
    "type": "snack",
    "|| name                                               ||": "Orange",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "whole",
    "weight-g": "160",
    "calories": "57.4",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "3",
    "saturated": "0",
    "polyunsaturated": "0.3",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "3",
    "carbs": "12.3",
    "fibre": "9.3",
    "sugar": "12.3",
    "protein": "1.6",
    "cal-from-protein": "6",
    "per-cal-from-protein": "11",
    "potassium": "224",
    "calcium": "51",
    "iron": "0.6",
    "phosphorus": "27",
    "source": "N Buddy",
    "old units (ref)": "small"
  },
  {
    "id": "1014",
    "type": "snack",
    "|| name                                               ||": "Pound cake, orange",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "38",
    "calories": "181",
    "fat": "10.5",
    "cal-from-fat": "94.5",
    "per-cal-from-fat": "52",
    "saturated": "6.9",
    "polyunsaturated": "0.1",
    "monounsaturated": "3.1",
    "cholesterol": "48",
    "sodium": "90",
    "carbs": "19.3",
    "sugar": "8.2",
    "protein": "2.2",
    "cal-from-protein": "9",
    "per-cal-from-protein": "5",
    "potassium": "28",
    "calcium": "29",
    "iron": "0.3",
    "phosphorus": "40",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "484",
    "type": "snack",
    "|| name                                               ||": "Osmanthus / Konnyaku jelly",
    "rating": "2",
    "high-gi": "1",
    "high-sugar": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "jelly",
    "|| info                                                             ||": "Gui Hua Gao",
    "info-translation": "Gui Hua Gao",
    "alternate-spellings": "Konnyaku: Konjac",
    "weight-g": "42",
    "calories": "32",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "3",
    "saturated": "0",
    "cholesterol": "0",
    "sodium": "54",
    "carbs": "7.5",
    "fibre": "0.3",
    "sugar": "6.4",
    "protein": "0.3",
    "cal-from-protein": "1",
    "per-cal-from-protein": "4",
    "potassium": "277",
    "calcium": "10",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "533",
    "type": "meal",
    "|| name                                               ||": "Osso buco / bucco",
    "rating": "2",
    "high-purine": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "shank",
    "|| info                                                             ||": "Braised veal shanks with vegetable",
    "info-translation": "Braised veal shanks with vegetable",
    "weight-g": "187",
    "calories": "340.124",
    "fat": "14.5",
    "cal-from-fat": "130.5",
    "per-cal-from-fat": "38",
    "saturated": "5.07",
    "polyunsaturated": "0.851",
    "monounsaturated": "6.63",
    "trans": "0.516",
    "cholesterol": "172",
    "sodium": "168",
    "carbs": "0.206",
    "fibre": "0",
    "sugar": "0",
    "protein": "52.2",
    "cal-from-protein": "209",
    "per-cal-from-protein": "61",
    "potassium": "376",
    "calcium": "41.1",
    "iron": "3.74",
    "phosphorus": "400",
    "source": "USDA",
    "old units (ref)": "plate"
  },
  {
    "id": "457",
    "type": "snack",
    "|| name                                               ||": "Otah",
    "rating": "2",
    "high-iodine": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "alternate-spellings": "Otah: Otak",
    "weight-g": "29",
    "calories": "38",
    "fat": "2.4",
    "cal-from-fat": "21.6",
    "per-cal-from-fat": "57",
    "saturated": "2",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.1",
    "cholesterol": "15",
    "sodium": "203",
    "carbs": "2.2",
    "fibre": "0.8",
    "sugar": "2",
    "protein": "1.7",
    "cal-from-protein": "7",
    "per-cal-from-protein": "18",
    "potassium": "45",
    "calcium": "19",
    "iron": "1",
    "phosphorus": "110",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "849",
    "type": "meal",
    "|| name                                               ||": "Oyakodon",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-translation": "Japanese rice bowl with chicken and egg",
    "weight-g": "400",
    "calories": "540",
    "fat": "14.9",
    "cal-from-fat": "134.1",
    "per-cal-from-fat": "25",
    "saturated": "5.6",
    "polyunsaturated": "2.7",
    "monounsaturated": "6.4",
    "trans": "0.2",
    "sodium": "1132",
    "carbs": "69.6",
    "fibre": "2",
    "sugar": "2.4",
    "protein": "32",
    "cal-from-protein": "128",
    "per-cal-from-protein": "24",
    "calcium": "52",
    "iron": "2.5",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "298",
    "type": "meal",
    "|| name                                               ||": "Oyster omelette with starch",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Orh Luah",
    "info-translation": "Orh Luak",
    "alternate-spellings": "Orh: Or \nLuak: Jian",
    "weight-g": "253",
    "calories": "645.3",
    "fat": "49.3",
    "cal-from-fat": "443.7",
    "per-cal-from-fat": "69",
    "saturated": "20",
    "polyunsaturated": "7.6",
    "monounsaturated": "19.5",
    "cholesterol": "354",
    "sodium": "746",
    "carbs": "32.4",
    "fibre": "0",
    "sugar": "0",
    "protein": "18",
    "cal-from-protein": "72",
    "per-cal-from-protein": "11",
    "calcium": "83",
    "iron": "6.1",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "509",
    "type": "meal",
    "|| name                                               ||": "Oyster omelette, without starch",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Orh nerng made without added starch",
    "info-translation": "Orh Nerng",
    "alternate-spellings": "Orh: Or\nNerng: Neng",
    "weight-g": "253",
    "calories": "645",
    "fat": "49.3",
    "cal-from-fat": "443.7",
    "per-cal-from-fat": "69",
    "saturated": "20",
    "polyunsaturated": "7.6",
    "monounsaturated": "19.5",
    "cholesterol": "354",
    "sodium": "746",
    "carbs": "32.4",
    "fibre": "0",
    "sugar": "0",
    "protein": "18",
    "cal-from-protein": "72",
    "per-cal-from-protein": "11",
    "calcium": "83",
    "iron": "6.1",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "575",
    "type": "drink",
    "|| name                                               ||": "Packet drink with added sugar",
    "rating": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "packet",
    "|| info                                                             ||": "e.g. sugarcane, apple, lychee, mixed berries, winter melon",
    "info-examples": "chrysanthemum, sugarcane, apple, lychee, winter melon",
    "volume-ml": "250",
    "calories": "120",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "carbs": "30",
    "sugar": "28",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "source": "N Buddy",
    "old units (ref)": "packet"
  },
  {
    "id": "977",
    "type": "drink",
    "|| name                                               ||": "Packet drink, reduced sugar",
    "rating": "2",
    "high-fat": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "packet",
    "|| info                                                             ||": "e.g. chrysanthemum, lemon barley, winter melon, etc",
    "info-examples": "chrysanthemum, lemon barley, winter melon",
    "volume-ml": "250",
    "calories": "58",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "3",
    "carbs": "14.5",
    "sugar": "14.5",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "source": "N Buddy",
    "old units (ref)": "packet"
  },
  {
    "id": "299",
    "type": "meal",
    "|| name                                               ||": "Pad thai",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Thai-style fried noodles, beef / chicken / seafood",
    "info-translation": "Thai-style fried noodles",
    "info-examples": "beef / chicken / seafood",
    "alternate-spellings": "Pad: Phat, Phad",
    "weight-g": "405",
    "calories": "582.9",
    "fat": "16.9",
    "cal-from-fat": "152.1",
    "per-cal-from-fat": "26",
    "saturated": "5.9",
    "polyunsaturated": "1.7",
    "monounsaturated": "6.1",
    "cholesterol": "154",
    "sodium": "475",
    "carbs": "69.2",
    "fibre": "9.7",
    "sugar": "4.7",
    "protein": "38.5",
    "cal-from-protein": "154",
    "per-cal-from-protein": "26",
    "potassium": "530",
    "calcium": "91",
    "iron": "7.3",
    "phosphorus": "251",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "839",
    "type": "meal",
    "|| name                                               ||": "Tau Kwa, pan-fried",
    "rating": "3",
    "fried": "1",
    "high-calcium": "1",
    "high-phosphate": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "info-translation": "Firm tofu",
    "weight-g": "142",
    "calories": "206",
    "fat": "14.4",
    "cal-from-fat": "129.6",
    "per-cal-from-fat": "63",
    "saturated": "2.8",
    "polyunsaturated": "6.8",
    "monounsaturated": "4",
    "cholesterol": "0",
    "sodium": "600",
    "carbs": "0.8",
    "fibre": "1.2",
    "sugar": "0.2",
    "protein": "18.4",
    "cal-from-protein": "74",
    "per-cal-from-protein": "36",
    "potassium": "130",
    "calcium": "102",
    "iron": "2",
    "phosphorus": "150",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "209",
    "type": "snack",
    "|| name                                               ||": "Pancake with syrup",
    "rating": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "with syrup",
    "weight-g": "100",
    "calories": "297.5",
    "fat": "11.9",
    "cal-from-fat": "107.1",
    "per-cal-from-fat": "36",
    "saturated": "6.2",
    "polyunsaturated": "1.1",
    "monounsaturated": "3.4",
    "cholesterol": "34",
    "sodium": "100",
    "carbs": "41.2",
    "fibre": "0.6",
    "sugar": "18.8",
    "protein": "6.4",
    "cal-from-protein": "26",
    "per-cal-from-protein": "9",
    "potassium": "261",
    "calcium": "125",
    "iron": "1.2",
    "phosphorus": "114",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "193",
    "type": "snack",
    "|| name                                               ||": "Pancake, without syrup",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "without syrup",
    "weight-g": "80",
    "calories": "237.1",
    "fat": "11.9",
    "cal-from-fat": "107.1",
    "per-cal-from-fat": "45",
    "saturated": "6.2",
    "polyunsaturated": "1.1",
    "monounsaturated": "3.4",
    "cholesterol": "34",
    "sodium": "74",
    "carbs": "26.2",
    "fibre": "0.6",
    "sugar": "3.8",
    "protein": "6.3",
    "cal-from-protein": "25",
    "per-cal-from-protein": "11",
    "potassium": "139",
    "calcium": "79",
    "iron": "0.5",
    "phosphorus": "114",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "55",
    "disabled": "1",
    "type": "snack",
    "|| name                                               ||": "Pancake with peanut or red bean filling",
    "rating": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Mee Chang Kuih",
    "info-translation": "Mee Chang Kuih",
    "alternate-spellings": "Mee: Mi\nChang: Jiang\nKuih: Kueh, Kue",
    "weight-g": "120",
    "calories": "276",
    "fat": "6.9",
    "cal-from-fat": "62.1",
    "per-cal-from-fat": "23",
    "saturated": "3",
    "polyunsaturated": "1",
    "monounsaturated": "2.6",
    "cholesterol": "11",
    "sodium": "91",
    "carbs": "46.6",
    "fibre": "3.7",
    "sugar": "15.2",
    "protein": "7.1",
    "cal-from-protein": "28",
    "per-cal-from-protein": "10",
    "potassium": "104",
    "calcium": "26",
    "iron": "1",
    "phosphorus": "105",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "492",
    "type": "snack",
    "|| name                                               ||": "Blinis",
    "rating": "2",
    "fried": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. blinis, blintz",
    "info-translation": "Russian buckwheat pancake",
    "alternate-spellings": "Blinis: Blintz",
    "weight-g": "50",
    "calories": "136.57",
    "fat": "6.33",
    "cal-from-fat": "56.97",
    "per-cal-from-fat": "42",
    "saturated": "1.64",
    "polyunsaturated": "1.99",
    "monounsaturated": "2.29",
    "cholesterol": "31",
    "sodium": "171",
    "carbs": "15.9",
    "fibre": "1.75",
    "sugar": "2.83",
    "protein": "4",
    "cal-from-protein": "16",
    "per-cal-from-protein": "12",
    "potassium": "112",
    "calcium": "132",
    "iron": "1.1",
    "phosphorus": "224",
    "source": "USDA",
    "old units (ref)": "slice"
  },
  {
    "id": "67",
    "type": "snack",
    "|| name                                               ||": "Panna cotta",
    "rating": "2",
    "high-calcium": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "100",
    "calories": "240",
    "fat": "14",
    "cal-from-fat": "126",
    "per-cal-from-fat": "53",
    "saturated": "11",
    "cholesterol": "35",
    "sodium": "35",
    "carbs": "25",
    "fibre": "0",
    "sugar": "23",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "5",
    "potassium": "134",
    "calcium": "80",
    "iron": "0",
    "source": "USDA",
    "old units (ref)": "cup"
  },
  {
    "id": "510",
    "type": "meal",
    "|| name                                               ||": "Papadam",
    "rating": "2",
    "fried": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "alternate-spellings": "Papadam: Papadum, Poppadom, Papad, Appalam",
    "weight-g": "6",
    "calories": "31",
    "fat": "2.2",
    "cal-from-fat": "19.8",
    "per-cal-from-fat": "64",
    "saturated": "1",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.9",
    "cholesterol": "0",
    "sodium": "125",
    "carbs": "1.9",
    "fibre": "0.4",
    "sugar": "0.5",
    "protein": "0.9",
    "cal-from-protein": "4",
    "per-cal-from-protein": "12",
    "potassium": "4",
    "calcium": "4",
    "iron": "0.3",
    "phosphorus": "14",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "394",
    "type": "snack",
    "|| name                                               ||": "Papaya",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "145",
    "calories": "68.917",
    "fat": "0.377",
    "cal-from-fat": "3.393",
    "per-cal-from-fat": "5",
    "saturated": "0.117",
    "polyunsaturated": "0.084",
    "monounsaturated": "0.104",
    "cholesterol": "0",
    "sodium": "11.6",
    "carbs": "15.7",
    "fibre": "2.46",
    "sugar": "11.3",
    "protein": "0.681",
    "cal-from-protein": "3",
    "per-cal-from-protein": "4",
    "potassium": "264",
    "calcium": "29",
    "iron": "0.362",
    "phosphorus": "14.5",
    "source": "USDA",
    "old units (ref)": "slice"
  },
  {
    "|| name                                               ||": "Parboiled rice"
  },
  {
    "id": "674",
    "type": "meal",
    "|| name                                               ||": "Parsnip, roasted / grilled",
    "rating": "3",
    "high-fibre": "1",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "100",
    "calories": "111.5",
    "fat": "6.3",
    "cal-from-fat": "56.7",
    "per-cal-from-fat": "51",
    "saturated": "1",
    "polyunsaturated": "1",
    "monounsaturated": "3.6",
    "sodium": "192",
    "carbs": "11.6",
    "fibre": "4.2",
    "sugar": "5.5",
    "protein": "2.1",
    "cal-from-protein": "8",
    "per-cal-from-protein": "8",
    "potassium": "486",
    "calcium": "44",
    "iron": "0.3",
    "phosphorus": "69",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "395",
    "type": "snack",
    "|| name                                               ||": "Passion fruit",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "whole",
    "weight-g": "18",
    "calories": "19.558",
    "fat": "0.126",
    "cal-from-fat": "1.134",
    "per-cal-from-fat": "6",
    "saturated": "0.011",
    "polyunsaturated": "0.074",
    "monounsaturated": "0.015",
    "cholesterol": "0",
    "sodium": "5.04",
    "carbs": "4.21",
    "fibre": "1.87",
    "sugar": "2.02",
    "protein": "0.396",
    "cal-from-protein": "2",
    "per-cal-from-protein": "8",
    "potassium": "62.6",
    "calcium": "2.16",
    "iron": "0.288",
    "phosphorus": "12.2",
    "source": "USDA",
    "old units (ref)": "whole fruit"
  },
  {
    "id": "234",
    "type": "meal",
    "|| name                                               ||": "Pasta, aglio e olio",
    "rating": "2",
    "high-iron": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "e.g. spaghetti, linguine, angel hair, penne, fusilli, tagliolini, rigatoni, pappardelle",
    "info-examples": "spaghetti, linguine, macaroni",
    "weight-g": "162",
    "calories": "325.7",
    "fat": "10.9",
    "cal-from-fat": "98.1",
    "per-cal-from-fat": "30",
    "saturated": "1.6",
    "polyunsaturated": "1.1",
    "monounsaturated": "7.3",
    "cholesterol": "3",
    "sodium": "7",
    "carbs": "49",
    "fibre": "4.5",
    "sugar": "1.7",
    "protein": "7.9",
    "cal-from-protein": "32",
    "per-cal-from-protein": "10",
    "potassium": "187",
    "calcium": "4",
    "iron": "0.8",
    "phosphorus": "187",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "300",
    "type": "meal",
    "|| name                                               ||": "Pasta, cream based with meat",
    "rating": "2",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "e.g. spaghetti, linguine, angel hair, penne, fusilli, tagliolini, rigatoni, pappardelle",
    "info-examples": "spaghetti, linguine, macaroni",
    "weight-g": "403",
    "calories": "654.9",
    "fat": "26.5",
    "cal-from-fat": "238.5",
    "per-cal-from-fat": "36",
    "saturated": "14.3",
    "polyunsaturated": "4.9",
    "monounsaturated": "5.7",
    "cholesterol": "66",
    "sodium": "790",
    "carbs": "73.3",
    "fibre": "2.7",
    "sugar": "5.2",
    "protein": "30.8",
    "cal-from-protein": "123",
    "per-cal-from-protein": "19",
    "potassium": "113",
    "calcium": "130",
    "iron": "0.8",
    "phosphorus": "166",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "301",
    "type": "meal",
    "|| name                                               ||": "Pasta, tomato based with meat",
    "rating": "2",
    "high-iron": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "e.g. spaghetti, linguine, angel hair, penne, fusilli, tagliolini, rigatoni, pappardelle",
    "info-examples": "spaghetti, linguine, macaroni",
    "weight-g": "497",
    "calories": "586.6",
    "fat": "12.6",
    "cal-from-fat": "113.4",
    "per-cal-from-fat": "19",
    "saturated": "2.6",
    "polyunsaturated": "2.1",
    "monounsaturated": "7.5",
    "cholesterol": "63",
    "sodium": "659",
    "carbs": "83.6",
    "fibre": "11.6",
    "sugar": "8.9",
    "protein": "34.7",
    "cal-from-protein": "139",
    "per-cal-from-protein": "24",
    "potassium": "376",
    "calcium": "191",
    "iron": "4.7",
    "phosphorus": "321",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "128",
    "type": "meal",
    "|| name                                               ||": "Pastry, sweet",
    "rating": "2",
    "high-gi": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. danish pastry",
    "info-examples": "Danish, cinnamon roll",
    "weight-g": "71",
    "calories": "202.9",
    "fat": "10.1",
    "cal-from-fat": "90.9",
    "per-cal-from-fat": "45",
    "saturated": "6.1",
    "polyunsaturated": "0.5",
    "monounsaturated": "3.1",
    "cholesterol": "23",
    "sodium": "113",
    "carbs": "23.1",
    "fibre": "0.9",
    "sugar": "10.7",
    "protein": "4.9",
    "cal-from-protein": "20",
    "per-cal-from-protein": "10",
    "potassium": "100",
    "calcium": "17",
    "iron": "1",
    "phosphorus": "39",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "396",
    "type": "snack",
    "|| name                                               ||": "Peach / nectarine / plum",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "whole",
    "weight-g": "130",
    "calories": "57.325",
    "fat": "0.325",
    "cal-from-fat": "2.925",
    "per-cal-from-fat": "5",
    "saturated": "0",
    "polyunsaturated": "0.025",
    "monounsaturated": "0.087",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "12.4",
    "fibre": "1.95",
    "sugar": "10.9",
    "protein": "1.2",
    "cal-from-protein": "5",
    "per-cal-from-protein": "8",
    "potassium": "247",
    "calcium": "7.8",
    "iron": "0.325",
    "phosphorus": "26",
    "source": "USDA",
    "old units (ref)": "medium"
  },
  {
    "id": "129",
    "type": "meal",
    "|| name                                               ||": "Peanut butter, sweetened",
    "rating": "2",
    "healthy-fats": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "plant-protein": "1",
    "processed": "1",
    "serving": "2",
    "|| unit ||": "tbsp",
    "info-brands": "Skippy",
    "weight-g": "36",
    "calories": "214.6",
    "fat": "17",
    "cal-from-fat": "153",
    "per-cal-from-fat": "71",
    "saturated": "3.9",
    "polyunsaturated": "5",
    "monounsaturated": "8.1",
    "cholesterol": "0",
    "sodium": "154",
    "carbs": "6.7",
    "fibre": "2",
    "sugar": "3",
    "protein": "8.7",
    "cal-from-protein": "35",
    "per-cal-from-protein": "16",
    "potassium": "183",
    "calcium": "14",
    "iron": "0.6",
    "phosphorus": "120",
    "source": "N Buddy",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "302",
    "type": "meal",
    "|| name                                               ||": "Peanut butter, unsweetened",
    "rating": "2",
    "healthy-fats": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "plant-protein": "1",
    "serving": "2",
    "|| unit ||": "tbsp",
    "weight-g": "30",
    "calories": "182.3",
    "fat": "15.5",
    "cal-from-fat": "139.5",
    "per-cal-from-fat": "77",
    "saturated": "2.6",
    "polyunsaturated": "5",
    "monounsaturated": "7.1",
    "trans": "0.1",
    "cholesterol": "0",
    "sodium": "90",
    "carbs": "2.4",
    "fibre": "3.3",
    "sugar": "1.3",
    "protein": "8.3",
    "cal-from-protein": "33",
    "per-cal-from-protein": "18",
    "potassium": "183",
    "calcium": "14",
    "iron": "0.6",
    "phosphorus": "120",
    "source": "N Buddy",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "214",
    "type": "snack",
    "|| name                                               ||": "Peanut paste",
    "rating": "2",
    "healthy-fats": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Hua Sheng Hu",
    "info-translation": "Hua Sheng Hu",
    "alternate-spellings": "Hua: Fa\nSheng: Sang\nHu: Wu, Woo",
    "weight-g": "274",
    "calories": "342.4",
    "fat": "20.4",
    "cal-from-fat": "183.6",
    "per-cal-from-fat": "54",
    "saturated": "4.4",
    "polyunsaturated": "6.9",
    "monounsaturated": "8.1",
    "cholesterol": "0",
    "sodium": "273",
    "carbs": "25.3",
    "fibre": "3",
    "sugar": "25.3",
    "protein": "14.4",
    "cal-from-protein": "58",
    "per-cal-from-protein": "17",
    "potassium": "419",
    "calcium": "64",
    "iron": "0",
    "phosphorus": "204",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "86",
    "type": "snack",
    "|| name                                               ||": "Peanuts",
    "rating": "2",
    "healthy-fats": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "handful",
    "|| info                                                             ||": "salted, roasted",
    "info-include-exclude": "salted, roasted",
    "weight-g": "30",
    "calories": "180.4",
    "fat": "14.8",
    "cal-from-fat": "133.2",
    "per-cal-from-fat": "74",
    "saturated": "2.1",
    "polyunsaturated": "4.7",
    "monounsaturated": "7.3",
    "cholesterol": "0",
    "sodium": "130",
    "carbs": "3.8",
    "fibre": "2.1",
    "sugar": "1.8",
    "protein": "8",
    "cal-from-protein": "32",
    "per-cal-from-protein": "18",
    "potassium": "211",
    "calcium": "26",
    "iron": "0.6",
    "phosphorus": "152",
    "source": "N Buddy",
    "old units (ref)": "handful"
  },
  {
    "id": "397",
    "type": "snack",
    "|| name                                               ||": "Pear",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "serving": "1",
    "|| unit ||": "small",
    "weight-g": "178",
    "calories": "113.205",
    "fat": "0.249",
    "cal-from-fat": "2.241",
    "per-cal-from-fat": "2",
    "saturated": "0.039",
    "polyunsaturated": "0.167",
    "monounsaturated": "0.15",
    "cholesterol": "0",
    "sodium": "1.78",
    "carbs": "27.1",
    "fibre": "5.52",
    "sugar": "17.4",
    "protein": "0.641",
    "cal-from-protein": "3",
    "per-cal-from-protein": "2",
    "potassium": "206",
    "calcium": "16",
    "iron": "0.32",
    "phosphorus": "21.4",
    "source": "USDA",
    "old units (ref)": "small"
  },
  {
    "id": "635",
    "type": "meal",
    "|| name                                               ||": "Peas",
    "rating": "3",
    "plant-protein": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "100",
    "calories": "119.8",
    "fat": "3.4",
    "cal-from-fat": "30.6",
    "per-cal-from-fat": "26",
    "saturated": "0.1",
    "cholesterol": "0",
    "sodium": "2",
    "carbs": "17.2",
    "fibre": "7",
    "sugar": "0",
    "protein": "5.1",
    "cal-from-protein": "20",
    "per-cal-from-protein": "17",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "398",
    "type": "snack",
    "|| name                                               ||": "Persimmon",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "fruit",
    "|| info                                                             ||": "Sharon fruit",
    "info-translation": "Kaki / Sharon fruit",
    "alternate-spellings": "Persimmon: Persimon",
    "weight-g": "168",
    "calories": "120.367",
    "fat": "0.319",
    "cal-from-fat": "2.871",
    "per-cal-from-fat": "2",
    "saturated": "0.034",
    "polyunsaturated": "0.072",
    "monounsaturated": "0.062",
    "cholesterol": "0",
    "sodium": "1.68",
    "carbs": "28.4",
    "fibre": "31.2",
    "sugar": "21",
    "protein": "0.974",
    "cal-from-protein": "4",
    "per-cal-from-protein": "3",
    "potassium": "270",
    "calcium": "13.4",
    "iron": "0.252",
    "phosphorus": "28.6",
    "source": "USDA",
    "old units (ref)": "whole fruit"
  },
  {
    "id": "697",
    "type": "meal",
    "|| name                                               ||": "Petai",
    "rating": "3",
    "high-calcium": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "info-translation": "Stink / bitter bean",
    "weight-g": "100",
    "calories": "53.9",
    "fat": "0.7",
    "cal-from-fat": "6.3",
    "per-cal-from-fat": "12",
    "saturated": "0.2",
    "polyunsaturated": "1.1",
    "monounsaturated": "0.3",
    "cholesterol": "0",
    "sodium": "5",
    "carbs": "7.5",
    "fibre": "1",
    "sugar": "4.1",
    "protein": "4.4",
    "cal-from-protein": "18",
    "per-cal-from-protein": "33",
    "potassium": "451",
    "calcium": "151",
    "iron": "4.1",
    "phosphorus": "4",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "130",
    "type": "meal",
    "|| name                                               ||": "Pie, savoury",
    "rating": "2",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. mushroom pie, shepherd pie, chicken pie",
    "info-examples": "mushroom, chicken, shepherd's",
    "weight-g": "112",
    "calories": "316.2",
    "fat": "19",
    "cal-from-fat": "171",
    "per-cal-from-fat": "54",
    "saturated": "6.8",
    "polyunsaturated": "3.6",
    "monounsaturated": "7.5",
    "trans": "0.1",
    "cholesterol": "32",
    "sodium": "379",
    "carbs": "29.9",
    "fibre": "2.9",
    "sugar": "4.4",
    "protein": "6.4",
    "cal-from-protein": "26",
    "per-cal-from-protein": "8",
    "potassium": "292",
    "calcium": "54",
    "iron": "2.7",
    "phosphorus": "95",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "131",
    "type": "meal",
    "|| name                                               ||": "Pie, sweet",
    "rating": "2",
    "high-gi": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. pecan pie, apple pie, key lime pie",
    "info-examples": "pecan, apple, key lime",
    "weight-g": "117",
    "calories": "294",
    "fat": "14",
    "cal-from-fat": "126",
    "per-cal-from-fat": "43",
    "saturated": "7",
    "polyunsaturated": "4",
    "monounsaturated": "3",
    "trans": "4.5",
    "cholesterol": "5",
    "sodium": "250",
    "carbs": "40",
    "fibre": "1",
    "sugar": "20",
    "protein": "2",
    "cal-from-protein": "8",
    "per-cal-from-protein": "3",
    "potassium": "81",
    "calcium": "20",
    "iron": "1.4",
    "phosphorus": "37",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "453",
    "type": "meal",
    "|| name                                               ||": "Pig organ soup with intestine and salted vegetables",
    "rating": "1",
    "high-cholesterol": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "made with salted vegetables, and intestines",
    "weight-g": "444",
    "calories": "160.6",
    "fat": "5.8",
    "cal-from-fat": "52.2",
    "per-cal-from-fat": "33",
    "saturated": "2.2",
    "polyunsaturated": "0.4",
    "monounsaturated": "1.8",
    "cholesterol": "142",
    "sodium": "1545",
    "carbs": "4.9",
    "fibre": "1.3",
    "sugar": "2",
    "protein": "22.2",
    "cal-from-protein": "89",
    "per-cal-from-protein": "55",
    "calcium": "40",
    "iron": "11.1",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "399",
    "type": "snack",
    "|| name                                               ||": "Pineapple",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "84",
    "calories": "46.725",
    "fat": "0.101",
    "cal-from-fat": "0.909",
    "per-cal-from-fat": "2",
    "saturated": "0.008",
    "polyunsaturated": "0.034",
    "monounsaturated": "0.011",
    "cholesterol": "0",
    "sodium": "0.84",
    "carbs": "11",
    "fibre": "1.18",
    "sugar": "8.27",
    "protein": "0.454",
    "cal-from-protein": "2",
    "per-cal-from-protein": "4",
    "potassium": "91.6",
    "calcium": "10.9",
    "iron": "0.244",
    "phosphorus": "6.72",
    "source": "USDA",
    "old units (ref)": "slice"
  },
  {
    "id": "132",
    "type": "meal",
    "|| name                                               ||": "Pineapple fried rice",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "474",
    "calories": "598",
    "fat": "18.8",
    "cal-from-fat": "169.2",
    "per-cal-from-fat": "28",
    "saturated": "7.8",
    "cholesterol": "137",
    "sodium": "2349",
    "carbs": "83.4",
    "protein": "23.8",
    "cal-from-protein": "95",
    "per-cal-from-protein": "16",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "38",
    "type": "snack",
    "|| name                                               ||": "Pineapple tart, cookie",
    "rating": "1",
    "high-gi": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "2",
    "|| unit ||": "piece",
    "weight-g": "40",
    "calories": "163.1",
    "fat": "7.1",
    "cal-from-fat": "63.9",
    "per-cal-from-fat": "39",
    "saturated": "4.5",
    "polyunsaturated": "0.3",
    "monounsaturated": "1.9",
    "cholesterol": "29",
    "sodium": "116",
    "carbs": "22.4",
    "fibre": "0.9",
    "sugar": "12.3",
    "protein": "2.4",
    "cal-from-protein": "10",
    "per-cal-from-protein": "6",
    "potassium": "53",
    "calcium": "16",
    "iron": "0.8",
    "phosphorus": "36",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "1028",
    "type": "snack",
    "|| name                                               ||": "Pineapple upside down cake",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "100",
    "calories": "294.35",
    "fat": "11.43",
    "cal-from-fat": "102.87",
    "per-cal-from-fat": "35",
    "saturated": "6.43",
    "cholesterol": "68",
    "sodium": "250",
    "carbs": "44.3",
    "fibre": "0.7",
    "sugar": "33.57",
    "protein": "3.57",
    "cal-from-protein": "14",
    "per-cal-from-protein": "5",
    "calcium": "43",
    "iron": "1",
    "source": "USDA",
    "old units (ref)": "slice"
  },
  {
    "id": "1029",
    "type": "snack",
    "|| name                                               ||": "Pistachio cake",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "45",
    "calories": "242",
    "fat": "9",
    "cal-from-fat": "81",
    "per-cal-from-fat": "33",
    "saturated": "1",
    "sodium": "361",
    "carbs": "35",
    "sugar": "21",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "5",
    "potassium": "50",
    "calcium": "91",
    "iron": "1.44",
    "old units (ref)": "slice"
  },
  {
    "id": "589",
    "type": "meal",
    "|| name                                               ||": "Pita bread, whole wheat",
    "rating": "2",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "pita",
    "|| info                                                             ||": "plain",
    "weight-g": "64",
    "calories": "178.09",
    "fat": "1.09",
    "cal-from-fat": "9.81",
    "per-cal-from-fat": "6",
    "saturated": "0.133",
    "polyunsaturated": "0.675",
    "monounsaturated": "0.223",
    "cholesterol": "0",
    "sodium": "269",
    "carbs": "35.8",
    "fibre": "3.9",
    "sugar": "1.84",
    "protein": "6.27",
    "cal-from-protein": "25",
    "per-cal-from-protein": "14",
    "potassium": "109",
    "calcium": "9.6",
    "iron": "1.96",
    "phosphorus": "115",
    "source": "USDA",
    "old units (ref)": "whole"
  },
  {
    "id": "554",
    "type": "meal",
    "|| name                                               ||": "Pizza, thin crust",
    "rating": "2",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "|| info                                                             ||": "meat topping",
    "info-include-exclude": "with meat topping",
    "weight-g": "92",
    "calories": "296.3",
    "fat": "15.5",
    "cal-from-fat": "139.5",
    "per-cal-from-fat": "47",
    "saturated": "6.16",
    "polyunsaturated": "3.06",
    "monounsaturated": "5.01",
    "cholesterol": "34",
    "sodium": "772",
    "carbs": "26.4",
    "fibre": "2.21",
    "sugar": "2.41",
    "protein": "12.8",
    "cal-from-protein": "51",
    "per-cal-from-protein": "17",
    "potassium": "202",
    "calcium": "178",
    "iron": "2.19",
    "phosphorus": "219",
    "source": "USDA",
    "old units (ref)": "slice"
  },
  {
    "id": "303",
    "type": "meal",
    "|| name                                               ||": "Pizza, thick crust",
    "rating": "2",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "|| info                                                             ||": "meat topping",
    "info-include-exclude": "with meat topping",
    "weight-g": "119",
    "calories": "341.7",
    "fat": "16.1",
    "cal-from-fat": "144.9",
    "per-cal-from-fat": "42",
    "saturated": "6.5",
    "polyunsaturated": "2.76",
    "monounsaturated": "5.63",
    "cholesterol": "30.9",
    "sodium": "798",
    "carbs": "35",
    "fibre": "2.74",
    "sugar": "3.24",
    "protein": "14.2",
    "cal-from-protein": "57",
    "per-cal-from-protein": "17",
    "potassium": "251",
    "calcium": "178",
    "iron": "2.92",
    "phosphorus": "227",
    "source": "USDA",
    "old units (ref)": "slice"
  },
  {
    "id": "555",
    "type": "meal",
    "|| name                                               ||": "Pizza, stuffed crust",
    "rating": "1",
    "high-calcium": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "|| info                                                             ||": "cheese topping",
    "info-include-exclude": "with cheese",
    "weight-g": "117",
    "calories": "320",
    "fat": "13.6",
    "cal-from-fat": "122.4",
    "per-cal-from-fat": "38",
    "saturated": "6.82",
    "polyunsaturated": "1.81",
    "monounsaturated": "3.24",
    "cholesterol": "35.1",
    "sodium": "720",
    "carbs": "35.1",
    "fibre": "1.99",
    "sugar": "3.39",
    "protein": "14.3",
    "cal-from-protein": "57",
    "per-cal-from-protein": "18",
    "potassium": "268",
    "calcium": "278",
    "iron": "2.39",
    "phosphorus": "291",
    "source": "USDA",
    "old units (ref)": "slice"
  },
  {
    "id": "359",
    "type": "drink",
    "|| name                                               ||": "Plain water",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "no calorie drink",
    "volume-ml": "236",
    "calories": "0",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "1",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "0",
    "calcium": "0",
    "iron": "0",
    "phosphorus": "0",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "725",
    "type": "meal",
    "|| name                                               ||": "Plant based / vegetarian meats",
    "rating": "3",
    "high-calcium": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "patty",
    "info-brands": "Impossible, Beyond Meat, Plant Pioneers, Omnimeat",
    "weight-g": "113",
    "calories": "238",
    "fat": "14",
    "cal-from-fat": "126",
    "per-cal-from-fat": "53",
    "saturated": "8",
    "polyunsaturated": "1.3",
    "monounsaturated": "4.7",
    "sodium": "370",
    "carbs": "9",
    "fibre": "3",
    "sugar": "1",
    "protein": "19",
    "cal-from-protein": "76",
    "per-cal-from-protein": "32",
    "potassium": "610",
    "calcium": "170",
    "iron": "4.2",
    "phosphorus": "180",
    "source": "N Buddy",
    "old units (ref)": "patty"
  },
  {
    "id": "339",
    "type": "meal",
    "|| name                                               ||": "Polenta",
    "rating": "2",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "1 cup = 240mL",
    "info-translation": "Cornmeal",
    "weight-g": "240",
    "calories": "166.67",
    "fat": "4.27",
    "cal-from-fat": "38.43",
    "per-cal-from-fat": "23",
    "saturated": "1.86",
    "polyunsaturated": "0.926",
    "monounsaturated": "1.16",
    "cholesterol": "7.2",
    "sodium": "199",
    "carbs": "29.4",
    "fibre": "4.27",
    "sugar": "0.6",
    "protein": "2.66",
    "cal-from-protein": "11",
    "per-cal-from-protein": "6",
    "potassium": "50.4",
    "calcium": "9.6",
    "iron": "1.54",
    "phosphorus": "36",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "707",
    "type": "snack",
    "|| name                                               ||": "Pomegranate",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "fruit",
    "weight-g": "282",
    "calories": "259.3",
    "fat": "3.3",
    "cal-from-fat": "29.7",
    "per-cal-from-fat": "11",
    "saturated": "0.3",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.3",
    "cholesterol": "0",
    "sodium": "8",
    "carbs": "52.7",
    "fibre": "11.3",
    "sugar": "38.5",
    "protein": "4.7",
    "cal-from-protein": "19",
    "per-cal-from-protein": "7",
    "potassium": "666",
    "calcium": "28",
    "iron": "0.8",
    "phosphorus": "102",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "400",
    "type": "snack",
    "|| name                                               ||": "Pomelo",
    "rating": "3",
    "fruit": "1",
    "high-potassium": "1",
    "serving": "4",
    "|| unit ||": "segment",
    "weight-g": "156",
    "calories": "60.7",
    "fat": "0.3",
    "cal-from-fat": "2.7",
    "per-cal-from-fat": "4",
    "saturated": "0",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.2",
    "cholesterol": "0",
    "sodium": "5",
    "carbs": "14.2",
    "fibre": "0.6",
    "sugar": "14",
    "protein": "0.3",
    "cal-from-protein": "1",
    "per-cal-from-protein": "2",
    "potassium": "186",
    "calcium": "6",
    "iron": "0.5",
    "phosphorus": "37",
    "source": "N Buddy",
    "old units (ref)": "segment"
  },
  {
    "id": "87",
    "type": "snack",
    "|| name                                               ||": "Popcorn, sweetened",
    "rating": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "candied / sweetened",
    "weight-g": "30",
    "calories": "149.6",
    "fat": "6",
    "cal-from-fat": "54",
    "per-cal-from-fat": "36",
    "saturated": "0.6",
    "polyunsaturated": "2.8",
    "monounsaturated": "2",
    "cholesterol": "5",
    "sodium": "17",
    "carbs": "23.3",
    "fibre": "1.3",
    "sugar": "18.6",
    "protein": "0.6",
    "cal-from-protein": "2",
    "per-cal-from-protein": "2",
    "potassium": "23",
    "calcium": "2",
    "iron": "0.1",
    "phosphorus": "17",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "343",
    "type": "snack",
    "|| name                                               ||": "Popcorn, buttered and salted",
    "rating": "2",
    "high-salt": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "buttered and salted",
    "weight-g": "30",
    "calories": "140.5",
    "fat": "7.3",
    "cal-from-fat": "65.7",
    "per-cal-from-fat": "47",
    "saturated": "4.8",
    "polyunsaturated": "0.8",
    "monounsaturated": "1.7",
    "cholesterol": "2",
    "sodium": "294",
    "carbs": "16",
    "fibre": "2.6",
    "sugar": "0.2",
    "protein": "2.7",
    "cal-from-protein": "11",
    "per-cal-from-protein": "8",
    "potassium": "63",
    "calcium": "3",
    "iron": "0.7",
    "phosphorus": "78",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "823",
    "type": "snack",
    "|| name                                               ||": "Popcorn chicken",
    "rating": "2",
    "fried": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "box",
    "weight-g": "150",
    "calories": "446",
    "fat": "28",
    "cal-from-fat": "252",
    "per-cal-from-fat": "57",
    "saturated": "11",
    "sodium": "1169",
    "carbs": "27",
    "fibre": "0",
    "sugar": "0",
    "protein": "21",
    "cal-from-protein": "84",
    "per-cal-from-protein": "19",
    "source": "KFC website",
    "old units (ref)": "box"
  },
  {
    "id": "539",
    "type": "snack",
    "|| name                                               ||": "Popcorn, plain",
    "rating": "3",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "8",
    "calories": "41.73",
    "fat": "2.25",
    "cal-from-fat": "20.25",
    "per-cal-from-fat": "49",
    "saturated": "0.404",
    "polyunsaturated": "1.08",
    "monounsaturated": "0.663",
    "cholesterol": "0",
    "sodium": "0.24",
    "carbs": "4.65",
    "fibre": "0.8",
    "sugar": "0.043",
    "protein": "0.72",
    "cal-from-protein": "3",
    "per-cal-from-protein": "7",
    "potassium": "18",
    "calcium": "0.8",
    "iron": "0.222",
    "phosphorus": "20",
    "source": "USDA",
    "old units (ref)": "cup"
  },
  {
    "id": "223",
    "type": "snack",
    "|| name                                               ||": "Popiah with sauce",
    "rating": "2",
    "high-fibre": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "roll",
    "|| info                                                             ||": "with sweet black sauce",
    "weight-g": "160",
    "calories": "214.8",
    "fat": "12.8",
    "cal-from-fat": "115.2",
    "per-cal-from-fat": "54",
    "saturated": "4.2",
    "polyunsaturated": "1.8",
    "monounsaturated": "4.6",
    "cholesterol": "51",
    "sodium": "773",
    "carbs": "16.3",
    "fibre": "4.6",
    "sugar": "5.6",
    "protein": "8.6",
    "cal-from-protein": "34",
    "per-cal-from-protein": "16",
    "potassium": "234",
    "calcium": "83",
    "iron": "2.2",
    "phosphorus": "114",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "978",
    "type": "meal",
    "|| name                                               ||": "Popiah, without sauce",
    "rating": "3",
    "high-fat": "1",
    "high-fibre": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "|| info                                                             ||": "without sweet black sauce and chilli",
    "weight-g": "98",
    "calories": "118.9",
    "fat": "8.9",
    "cal-from-fat": "80.1",
    "per-cal-from-fat": "67",
    "saturated": "3.6",
    "polyunsaturated": "1.3",
    "monounsaturated": "3.4",
    "cholesterol": "17",
    "sodium": "150",
    "carbs": "3.5",
    "fibre": "1",
    "sugar": "2.7",
    "protein": "6.2",
    "cal-from-protein": "25",
    "per-cal-from-protein": "21",
    "potassium": "218",
    "calcium": "74",
    "iron": "0.9",
    "phosphorus": "56",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "304",
    "type": "meal",
    "|| name                                               ||": "Pork, roasted / grilled",
    "rating": "2",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "roasted / grilled",
    "weight-g": "120",
    "calories": "314.3",
    "fat": "21.1",
    "cal-from-fat": "189.9",
    "per-cal-from-fat": "60",
    "saturated": "7.6",
    "polyunsaturated": "2",
    "monounsaturated": "9.5",
    "cholesterol": "103",
    "sodium": "54",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "31.1",
    "cal-from-protein": "124",
    "per-cal-from-protein": "40",
    "potassium": "194",
    "calcium": "10",
    "iron": "1.4",
    "phosphorus": "194",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "921",
    "type": "meal",
    "|| name                                               ||": "Braised pork belly buns",
    "rating": "2",
    "high-gi": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Kong bak bao",
    "info-translation": "Kong Bak Bao",
    "alternate-spellings": "Kong: Kung \nBao: Pau, Pao",
    "weight-g": "102",
    "calories": "325.7",
    "fat": "17.3",
    "cal-from-fat": "155.7",
    "per-cal-from-fat": "48",
    "saturated": "7.3",
    "polyunsaturated": "2.6",
    "monounsaturated": "6.6",
    "cholesterol": "49",
    "sodium": "319",
    "carbs": "27.9",
    "fibre": "1",
    "sugar": "4.8",
    "protein": "14.6",
    "cal-from-protein": "58",
    "per-cal-from-protein": "18",
    "potassium": "44",
    "calcium": "29",
    "iron": "0.6",
    "phosphorus": "48",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "456",
    "type": "meal",
    "|| name                                               ||": "Pork chop, pan-fried",
    "rating": "2",
    "high-purine": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "chop",
    "|| info                                                             ||": "pan fried",
    "weight-g": "142",
    "calories": "267.6",
    "fat": "11.2",
    "cal-from-fat": "100.8",
    "per-cal-from-fat": "38",
    "saturated": "4.34",
    "polyunsaturated": "1.82",
    "monounsaturated": "0.051",
    "cholesterol": "99.4",
    "sodium": "122",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "41.7",
    "cal-from-protein": "167",
    "per-cal-from-protein": "62",
    "potassium": "534",
    "calcium": "17",
    "iron": "1.16",
    "phosphorus": "402",
    "source": "USDA",
    "old units (ref)": "portion"
  },
  {
    "id": "917",
    "type": "meal",
    "|| name                                               ||": "Fried glutinous rice pork dumplings",
    "rating": "2",
    "fried": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Hum sui kok",
    "info-translation": "Ham Sui Kok",
    "alternate-spellings": "Kok: Gok",
    "weight-g": "73",
    "calories": "216.2",
    "fat": "11.4",
    "cal-from-fat": "102.6",
    "per-cal-from-fat": "47",
    "saturated": "6",
    "polyunsaturated": "1.2",
    "monounsaturated": "4.4",
    "cholesterol": "11",
    "sodium": "163",
    "carbs": "25.9",
    "fibre": "1.5",
    "sugar": "5.3",
    "protein": "2.5",
    "cal-from-protein": "10",
    "per-cal-from-protein": "5",
    "potassium": "30",
    "calcium": "15",
    "iron": "0.4",
    "phosphorus": "33",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "133",
    "type": "meal",
    "|| name                                               ||": "Pork ribs, boiled",
    "rating": "2",
    "high-purine": "1",
    "red-meat": "1",
    "serving": "3",
    "|| unit ||": "piece",
    "weight-g": "108",
    "calories": "194.1",
    "fat": "12.1",
    "cal-from-fat": "108.9",
    "per-cal-from-fat": "56",
    "saturated": "4.7",
    "polyunsaturated": "1",
    "monounsaturated": "5.5",
    "cholesterol": "58",
    "sodium": "32",
    "carbs": "0",
    "fibre": "0",
    "sugar": "3.1",
    "protein": "21.3",
    "cal-from-protein": "85",
    "per-cal-from-protein": "44",
    "potassium": "141",
    "calcium": "20",
    "iron": "0.7",
    "phosphorus": "141",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "766",
    "type": "meal",
    "|| name                                               ||": "Pork, sweet and sour",
    "rating": "2",
    "fried": "1",
    "high-sugar": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "weight-g": "113",
    "calories": "330.9",
    "fat": "22.9",
    "cal-from-fat": "206.1",
    "per-cal-from-fat": "62",
    "saturated": "6.9",
    "polyunsaturated": "1.88",
    "monounsaturated": "7.5",
    "cholesterol": "37.5",
    "sodium": "550.5",
    "carbs": "15.67",
    "fibre": "3.15",
    "sugar": "8.17",
    "protein": "15.53",
    "cal-from-protein": "62",
    "per-cal-from-protein": "19",
    "potassium": "170.25",
    "calcium": "17.25",
    "iron": "1.2",
    "phosphorus": "94.5",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "230",
    "type": "snack",
    "|| name                                               ||": "Porridge with meat",
    "rating": "2",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Congee with beef / pork / chicken / fish",
    "info-examples": "beef, pork, chicken, fish",
    "alternate-spellings": "Porridge: Congee",
    "weight-g": "636",
    "calories": "364.1",
    "fat": "15.3",
    "cal-from-fat": "137.7",
    "per-cal-from-fat": "38",
    "saturated": "5.7",
    "polyunsaturated": "2.5",
    "monounsaturated": "5.7",
    "cholesterol": "64",
    "sodium": "1501",
    "carbs": "37.5",
    "fibre": "0",
    "sugar": "0",
    "protein": "19.1",
    "cal-from-protein": "76",
    "per-cal-from-protein": "21",
    "calcium": "57",
    "iron": "3.8",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "51",
    "type": "snack",
    "|| name                                               ||": "Porridge, plain",
    "rating": "2",
    "high-gi": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Congee",
    "alternate-spellings": "Porridge: Congee",
    "weight-g": "253",
    "calories": "166.7",
    "fat": "0.3",
    "cal-from-fat": "2.7",
    "per-cal-from-fat": "2",
    "saturated": "0.1",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.1",
    "cholesterol": "0",
    "sodium": "8",
    "carbs": "38",
    "fibre": "0.3",
    "sugar": "0",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "7",
    "potassium": "9",
    "calcium": "8",
    "iron": "0.3",
    "phosphorus": "8",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "665",
    "type": "meal",
    "|| name                                               ||": "Portobello mushroom",
    "rating": "3",
    "high-potassium": "1",
    "serving": "2",
    "|| unit ||": "whole",
    "|| info                                                             ||": "Whole mushroom",
    "alternate-spellings": "Portobello: Portabella",
    "weight-g": "120",
    "calories": "34",
    "fat": "0.8",
    "cal-from-fat": "7.2",
    "per-cal-from-fat": "21",
    "saturated": "0.2",
    "polyunsaturated": "0.2",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "14",
    "carbs": "5.2",
    "fibre": "2.6",
    "sugar": "2.8",
    "protein": "4",
    "cal-from-protein": "16",
    "per-cal-from-protein": "47",
    "potassium": "524",
    "calcium": "4",
    "iron": "0.4",
    "phosphorus": "162",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "197",
    "type": "snack",
    "|| name                                               ||": "Portuguese egg tart",
    "rating": "2",
    "high-cholesterol": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "45",
    "calories": "171",
    "fat": "11",
    "cal-from-fat": "99",
    "per-cal-from-fat": "58",
    "saturated": "6",
    "polyunsaturated": "1.8",
    "monounsaturated": "0.7",
    "cholesterol": "0",
    "sodium": "32",
    "carbs": "15",
    "fibre": "1.4",
    "sugar": "5",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "7",
    "calcium": "15",
    "iron": "0.3",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "528",
    "type": "meal",
    "|| name                                               ||": "Potato, baked, without cream",
    "rating": "2",
    "high-fibre": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "potato",
    "|| info                                                             ||": "with no cream",
    "weight-g": "156",
    "calories": "148.044",
    "fat": "0.156",
    "cal-from-fat": "1.404",
    "per-cal-from-fat": "1",
    "saturated": "0.041",
    "polyunsaturated": "0.067",
    "monounsaturated": "0.003",
    "cholesterol": "0",
    "sodium": "376",
    "carbs": "33.6",
    "fibre": "2.34",
    "sugar": "2.65",
    "protein": "3.06",
    "cal-from-protein": "12",
    "per-cal-from-protein": "8",
    "potassium": "610",
    "calcium": "7.8",
    "iron": "0.546",
    "phosphorus": "78",
    "source": "USDA",
    "old units (ref)": "whole"
  },
  {
    "id": "531",
    "type": "meal",
    "|| name                                               ||": "Potato, boiled",
    "rating": "2",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "potato",
    "weight-g": "167",
    "calories": "146.543",
    "fat": "0.167",
    "cal-from-fat": "1.503",
    "per-cal-from-fat": "1",
    "saturated": "0.043",
    "polyunsaturated": "0.072",
    "monounsaturated": "0.003",
    "cholesterol": "0",
    "sodium": "402",
    "carbs": "33.4",
    "fibre": "3.34",
    "sugar": "1.49",
    "protein": "2.86",
    "cal-from-protein": "11",
    "per-cal-from-protein": "8",
    "potassium": "548",
    "calcium": "13.4",
    "iron": "0.518",
    "phosphorus": "66.8",
    "source": "USDA",
    "old units (ref)": "cup"
  },
  {
    "id": "902",
    "type": "snack",
    "|| name                                               ||": "Pound cake, vanilla",
    "rating": "2",
    "high-cholesterol": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "30",
    "calories": "136.8",
    "fat": "8.8",
    "cal-from-fat": "79.2",
    "per-cal-from-fat": "58",
    "saturated": "6.6",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.8",
    "cholesterol": "12.7",
    "sodium": "96",
    "carbs": "12.7",
    "fibre": "0.6",
    "sugar": "9.7",
    "protein": "1.7",
    "cal-from-protein": "7",
    "per-cal-from-protein": "5",
    "potassium": "28",
    "calcium": "11",
    "iron": "0.5",
    "phosphorus": "49",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "511",
    "type": "snack",
    "|| name                                               ||": "Praline",
    "rating": "1",
    "healthy-fats": "1",
    "high-gi": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "39",
    "calories": "188.86",
    "fat": "10.1",
    "cal-from-fat": "90.9",
    "per-cal-from-fat": "48",
    "saturated": "0.867",
    "polyunsaturated": "3.03",
    "monounsaturated": "5.73",
    "cholesterol": "0",
    "sodium": "18.7",
    "carbs": "23.2",
    "fibre": "1.36",
    "sugar": "21.8",
    "protein": "1.29",
    "cal-from-protein": "5",
    "per-cal-from-protein": "3",
    "potassium": "84.6",
    "calcium": "16.8",
    "iron": "0.503",
    "phosphorus": "40.6",
    "source": "USDA",
    "old units (ref)": "portion"
  },
  {
    "id": "592",
    "type": "meal",
    "|| name                                               ||": "Prata with filling",
    "rating": "2",
    "fried": "1",
    "high-gi": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": " e.g. egg, mushroom, onion, cheese",
    "info-examples": "mushroom, onion, cheese, egg",
    "weight-g": "127",
    "calories": "304.5",
    "fat": "14.1",
    "cal-from-fat": "126.9",
    "per-cal-from-fat": "42",
    "saturated": "6.7",
    "polyunsaturated": "1.2",
    "monounsaturated": "5.6",
    "cholesterol": "210",
    "sodium": "429",
    "carbs": "33.2",
    "fibre": "3.9",
    "sugar": "3.4",
    "protein": "11.2",
    "cal-from-protein": "45",
    "per-cal-from-protein": "15",
    "potassium": "151",
    "calcium": "36",
    "iron": "1.1",
    "phosphorus": "141",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "1050",
    "type": "snack",
    "|| name                                               ||": "Prawn crackers",
    "rating": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "|| info                                                             ||": "e.g. Double Decker, Calbee",
    "info-brands": "Double Decker, Calbee",
    "weight-g": "30",
    "calories": "130",
    "fat": "3.6",
    "cal-from-fat": "32.4",
    "per-cal-from-fat": "25",
    "sodium": "170",
    "carbs": "20",
    "sugar": "3",
    "protein": "1.6",
    "cal-from-protein": "6",
    "per-cal-from-protein": "5",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "1094",
    "type": "meal",
    "|| name                                               ||": "Prawn noodle, dry",
    "rating": "1",
    "high-fibre": "1",
    "high-gi": "1",
    "high-purine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Prawn mee / hae mee dry",
    "alternate-spellings": "Prawn: Hae\nNoodle: Mee",
    "weight-g": "345",
    "calories": "460",
    "fat": "13.3",
    "cal-from-fat": "119.7",
    "per-cal-from-fat": "26",
    "saturated": "6.3",
    "polyunsaturated": "2.1",
    "monounsaturated": "4.6",
    "cholesterol": "49",
    "sodium": "1964",
    "carbs": "65.21",
    "fibre": "5.3",
    "sugar": "6",
    "protein": "20.36",
    "cal-from-protein": "81",
    "per-cal-from-protein": "18",
    "calcium": "84",
    "iron": "2.5",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "305",
    "type": "meal",
    "|| name                                               ||": "Prawn noodle, soup",
    "rating": "2",
    "high-cholesterol": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Prawn mee / hae mee dry",
    "alternate-spellings": "Prawn: Hae\r\nNoodle: Mee",
    "weight-g": "600",
    "calories": "307.2",
    "fat": "2.4",
    "cal-from-fat": "21.6",
    "per-cal-from-fat": "7",
    "saturated": "1.2",
    "polyunsaturated": "0.6",
    "monounsaturated": "0.6",
    "cholesterol": "42",
    "sodium": "2532",
    "carbs": "51.6",
    "fibre": "4.2",
    "sugar": "5",
    "protein": "19.8",
    "cal-from-protein": "79",
    "per-cal-from-protein": "26",
    "calcium": "108",
    "iron": "1.8",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "737",
    "type": "snack",
    "|| name                                               ||": "Prawn nugget on stick, fried",
    "rating": "1",
    "fried": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "stick",
    "|| info                                                             ||": "e.g. Old Chang Kee",
    "info-brands": "Old Chang Kee",
    "weight-g": "59",
    "calories": "90.2",
    "fat": "5",
    "cal-from-fat": "45",
    "per-cal-from-fat": "50",
    "saturated": "6.8",
    "polyunsaturated": "1.2",
    "monounsaturated": "5.8",
    "sodium": "316",
    "carbs": "6.3",
    "fibre": "1.4",
    "sugar": "1.5",
    "protein": "5",
    "cal-from-protein": "20",
    "per-cal-from-protein": "22",
    "source": "N Buddy",
    "old units (ref)": "stick"
  },
  {
    "id": "461",
    "type": "meal",
    "|| name                                               ||": "Prawn paste chicken",
    "rating": "1",
    "fried": "1",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "3",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Har cheong gai",
    "info-translation": "Har Cheong Gai",
    "weight-g": "126",
    "calories": "219.6",
    "fat": "12.8",
    "cal-from-fat": "115.2",
    "per-cal-from-fat": "52",
    "saturated": "3.6",
    "polyunsaturated": "3.1",
    "monounsaturated": "4.8",
    "cholesterol": "104",
    "sodium": "4389",
    "carbs": "2.3",
    "fibre": "0",
    "sugar": "0.6",
    "protein": "23.8",
    "cal-from-protein": "95",
    "per-cal-from-protein": "43",
    "potassium": "360",
    "calcium": "38",
    "iron": "1.4",
    "phosphorus": "148",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "765",
    "type": "meal",
    "|| name                                               ||": "Prawn, sweet and sour",
    "rating": "2",
    "fried": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "white-meat": "1",
    "serving": "3",
    "|| unit ||": "piece",
    "|| info                                                             ||": "3 prawns",
    "weight-g": "120",
    "calories": "186.9",
    "fat": "9.7",
    "cal-from-fat": "87.3",
    "per-cal-from-fat": "47",
    "saturated": "3.1",
    "polyunsaturated": "0.7",
    "monounsaturated": "3",
    "cholesterol": "48",
    "sodium": "96",
    "carbs": "16",
    "fibre": "3.5",
    "sugar": "9",
    "protein": "8.9",
    "cal-from-protein": "36",
    "per-cal-from-protein": "19",
    "potassium": "208",
    "calcium": "73",
    "iron": "2.4",
    "phosphorus": "366",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "980",
    "type": "meal",
    "|| name                                               ||": "Prawn / shrimp, buttered",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "145",
    "calories": "156.41",
    "fat": "6.13",
    "cal-from-fat": "55.17",
    "per-cal-from-fat": "35",
    "saturated": "3.23",
    "polyunsaturated": "0.674",
    "monounsaturated": "1.45",
    "cholesterol": "229",
    "sodium": "683",
    "carbs": "1.71",
    "fibre": "0",
    "sugar": "0.058",
    "protein": "23.6",
    "cal-from-protein": "94",
    "per-cal-from-protein": "60",
    "potassium": "199",
    "calcium": "94.2",
    "iron": "0.377",
    "phosphorus": "423",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "981",
    "type": "meal",
    "|| name                                               ||": "Prawn / shrimp, cheese baked",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "serving": "6",
    "|| unit ||": "piece",
    "weight-g": "180",
    "calories": "343",
    "fat": "34",
    "cal-from-fat": "306",
    "per-cal-from-fat": "89",
    "saturated": "4.7",
    "polyunsaturated": "13.2",
    "monounsaturated": "5.8",
    "cholesterol": "238",
    "sodium": "929",
    "carbs": "2.2",
    "fibre": "0",
    "sugar": "0",
    "protein": "26",
    "cal-from-protein": "104",
    "per-cal-from-protein": "30",
    "potassium": "214",
    "calcium": "161",
    "iron": "0.5",
    "phosphorus": "477",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "306",
    "type": "meal",
    "|| name                                               ||": "Prawn / shrimp, boiled / steamed / grilled",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "serving": "6",
    "|| unit ||": "piece",
    "|| info                                                             ||": "steamed / grilled",
    "info-portion-aid": "large",
    "weight-g": "60",
    "calories": "59.61",
    "fat": "0.17",
    "cal-from-fat": "1.53",
    "per-cal-from-fat": "3",
    "saturated": "0.03",
    "polyunsaturated": "0.05",
    "monounsaturated": "0.03",
    "trans": "0",
    "cholesterol": "113.4",
    "sodium": "66.6",
    "carbs": "0.12",
    "protein": "14.4",
    "cal-from-protein": "58",
    "per-cal-from-protein": "97",
    "potassium": "155.4",
    "calcium": "42",
    "iron": "0.3",
    "phosphorus": "142.2",
    "iodine": "1",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "512",
    "type": "snack",
    "|| name                                               ||": "Profiterole",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "info-translation": "Cream puff",
    "weight-g": "30",
    "calories": "110",
    "fat": "6.1",
    "cal-from-fat": "54.9",
    "per-cal-from-fat": "50",
    "saturated": "2.3",
    "polyunsaturated": "1.4",
    "monounsaturated": "2",
    "cholesterol": "22",
    "sodium": "87",
    "carbs": "12.3",
    "fibre": "0.3",
    "sugar": "7.3",
    "protein": "1.5",
    "cal-from-protein": "6",
    "per-cal-from-protein": "5",
    "potassium": "22",
    "calcium": "12",
    "iron": "0.3",
    "phosphorus": "23",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "513",
    "type": "meal",
    "|| name                                               ||": "Prosciutto ham",
    "rating": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "alternate-spellings": "Prosciutto: Proscuitto",
    "weight-g": "9.2",
    "calories": "17.237",
    "fat": "0.765",
    "cal-from-fat": "6.885",
    "per-cal-from-fat": "40",
    "saturated": "0.256",
    "polyunsaturated": "0.089",
    "monounsaturated": "0.352",
    "cholesterol": "6.44",
    "sodium": "248",
    "carbs": "0.028",
    "fibre": "0",
    "sugar": "0",
    "protein": "2.56",
    "cal-from-protein": "10",
    "per-cal-from-protein": "59",
    "potassium": "46.9",
    "calcium": "0.92",
    "iron": "0.102",
    "phosphorus": "29.3",
    "source": "USDA",
    "old units (ref)": "slice"
  },
  {
    "id": "180",
    "disabled": "1",
    "type": "drink",
    "|| name                                               ||": "Protein powder, flavoured",
    "rating": "2",
    "serving": "1",
    "|| unit ||": "scoop",
    "|| info                                                             ||": "e.g. MyProtein soy protein isolate",
    "info-brands": "MyProtein",
    "weight-g": "32",
    "calories": "130",
    "fat": "1",
    "cal-from-fat": "9",
    "per-cal-from-fat": "7",
    "saturated": "0.5",
    "sodium": "60",
    "carbs": "5",
    "fibre": "1",
    "sugar": "2",
    "protein": "24",
    "cal-from-protein": "96",
    "per-cal-from-protein": "74",
    "source": "ON website",
    "old units (ref)": "scoop"
  },
  {
    "id": "181",
    "disabled": "1",
    "type": "drink",
    "|| name                                               ||": "Protein powder, unflavored",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "scoop",
    "info-brands": "MyProtein",
    "weight-g": "32",
    "calories": "120",
    "fat": "1.5",
    "cal-from-fat": "13.5",
    "per-cal-from-fat": "11",
    "saturated": "1",
    "polyunsaturated": "0.3",
    "monounsaturated": "0.2",
    "trans": "0",
    "cholesterol": "40",
    "sodium": "130",
    "carbs": "3",
    "fibre": "0",
    "sugar": "2",
    "protein": "24",
    "cal-from-protein": "96",
    "per-cal-from-protein": "80",
    "calcium": "80",
    "iron": "0.4",
    "source": "N Buddy",
    "old units (ref)": "scoop"
  },
  {
    "id": "704",
    "type": "snack",
    "|| name                                               ||": "Prunes, dried",
    "rating": "3",
    "fruit": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "3",
    "|| unit ||": "pieces",
    "weight-g": "30",
    "calories": "44",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0.1",
    "cholesterol": "0",
    "sodium": "3",
    "carbs": "10.2",
    "fibre": "1.7",
    "sugar": "10.2",
    "protein": "0.8",
    "cal-from-protein": "3",
    "per-cal-from-protein": "7",
    "potassium": "228",
    "calcium": "10",
    "iron": "0.8",
    "phosphorus": "22",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "633",
    "type": "meal",
    "|| name                                               ||": "Pumpkin, roasted",
    "rating": "3",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "150",
    "calories": "119",
    "fat": "3.9",
    "cal-from-fat": "35.1",
    "per-cal-from-fat": "29",
    "saturated": "0.7",
    "polyunsaturated": "2.3",
    "monounsaturated": "0.7",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "262",
    "carbs": "19.9",
    "fibre": "0.7",
    "sugar": "15.3",
    "protein": "1.3",
    "cal-from-protein": "5",
    "per-cal-from-protein": "4",
    "potassium": "162",
    "calcium": "30",
    "iron": "1",
    "phosphorus": "56",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "75",
    "type": "snack",
    "|| name                                               ||": "Putu mayam",
    "rating": "1",
    "high-gi": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Iddayapam / string hoppers with grated coconut and gula melaka",
    "info-translation": "Iddayapam / string hoppers",
    "info-include-exclude": "with grated coconut and gula melaka",
    "weight-g": "104",
    "calories": "195.1",
    "fat": "3.5",
    "cal-from-fat": "31.5",
    "per-cal-from-fat": "16",
    "saturated": "3",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.3",
    "cholesterol": "0",
    "sodium": "302",
    "carbs": "38.6",
    "fibre": "2.9",
    "sugar": "6.8",
    "protein": "2.3",
    "cal-from-protein": "9",
    "per-cal-from-protein": "5",
    "potassium": "58",
    "calcium": "7",
    "iron": "0.9",
    "phosphorus": "25",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "77",
    "type": "snack",
    "|| name                                               ||": "Putu piring",
    "rating": "1",
    "high-gi": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "filled with gula melaka",
    "info-include-exclude": "with grated coconut and gula melaka",
    "weight-g": "20",
    "calories": "47.9",
    "fat": "0.7",
    "cal-from-fat": "6.3",
    "per-cal-from-fat": "13",
    "saturated": "0.6",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "65",
    "carbs": "9.8",
    "fibre": "1",
    "sugar": "4.5",
    "protein": "0.6",
    "cal-from-protein": "2",
    "per-cal-from-protein": "5",
    "potassium": "40",
    "calcium": "8",
    "iron": "0.1",
    "phosphorus": "17",
    "old units (ref)": "piece"
  },
  {
    "id": "307",
    "type": "meal",
    "|| name                                               ||": "Quesadilla",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "white-meat": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "with meat",
    "info-include-exclude": "with meat",
    "weight-g": "110",
    "calories": "227.4",
    "fat": "13.4",
    "cal-from-fat": "120.6",
    "per-cal-from-fat": "53",
    "saturated": "7.5",
    "polyunsaturated": "3",
    "monounsaturated": "3",
    "cholesterol": "26",
    "sodium": "421.5",
    "carbs": "10.9",
    "fibre": "1.4",
    "sugar": "1.3",
    "protein": "15.8",
    "cal-from-protein": "63",
    "per-cal-from-protein": "28",
    "potassium": "164.5",
    "calcium": "133",
    "iron": "1.8",
    "phosphorus": "281.5",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "892",
    "type": "meal",
    "|| name                                               ||": "Quiche Lorraine",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "45",
    "calories": "162",
    "fat": "10",
    "cal-from-fat": "90",
    "per-cal-from-fat": "56",
    "saturated": "5",
    "polyunsaturated": "0.6",
    "monounsaturated": "3.3",
    "cholesterol": "72",
    "sodium": "270",
    "carbs": "12",
    "fibre": "0.5",
    "sugar": "0.5",
    "protein": "6",
    "cal-from-protein": "24",
    "per-cal-from-protein": "15",
    "potassium": "30",
    "calcium": "58",
    "iron": "0.5",
    "phosphorus": "44",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "514",
    "type": "meal",
    "|| name                                               ||": "Quiche",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "info-include-exclude": "with filling",
    "weight-g": "124",
    "calories": "287.3",
    "fat": "20.1",
    "cal-from-fat": "180.9",
    "per-cal-from-fat": "63",
    "saturated": "7.7",
    "polyunsaturated": "3.3",
    "monounsaturated": "7.4",
    "cholesterol": "24",
    "sodium": "322",
    "carbs": "22.8",
    "fibre": "1.7",
    "sugar": "0.9",
    "protein": "3.8",
    "cal-from-protein": "15",
    "per-cal-from-protein": "5",
    "potassium": "176",
    "calcium": "65",
    "iron": "1.5",
    "phosphorus": "55",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "423",
    "type": "meal",
    "|| name                                               ||": "Quinoa",
    "rating": "3",
    "high-fibre": "1",
    "high-phosphate": "1",
    "plant-protein": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "1 cup = 240mL",
    "weight-g": "185",
    "calories": "222.11",
    "fat": "3.55",
    "cal-from-fat": "31.95",
    "per-cal-from-fat": "14",
    "saturated": "0.427",
    "polyunsaturated": "1.99",
    "monounsaturated": "0.977",
    "cholesterol": "0",
    "sodium": "13",
    "carbs": "39.4",
    "fibre": "5.18",
    "sugar": "1.61",
    "protein": "8.14",
    "cal-from-protein": "33",
    "per-cal-from-protein": "15",
    "potassium": "318",
    "calcium": "31.4",
    "iron": "2.76",
    "phosphorus": "281",
    "source": "USDA",
    "old units (ref)": "bowl"
  },
  {
    "id": "661",
    "type": "meal",
    "|| name                                               ||": "Radish, all types",
    "rating": "3",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "100",
    "calories": "15",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "12",
    "saturated": "0.5",
    "polyunsaturated": "2.3",
    "monounsaturated": "0.7",
    "cholesterol": "0",
    "sodium": "242",
    "carbs": "2.4",
    "fibre": "0.8",
    "sugar": "2.3",
    "protein": "0.9",
    "cal-from-protein": "4",
    "per-cal-from-protein": "24",
    "potassium": "81",
    "calcium": "36",
    "iron": "0.9",
    "phosphorus": "20",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "1044",
    "type": "snack",
    "|| name                                               ||": "Rainbow cake",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "120",
    "calories": "269",
    "fat": "12",
    "cal-from-fat": "108",
    "per-cal-from-fat": "40",
    "saturated": "4",
    "sodium": "0",
    "carbs": "36",
    "fibre": "0",
    "sugar": "25",
    "protein": "2",
    "cal-from-protein": "8",
    "per-cal-from-protein": "3",
    "old units (ref)": "slice"
  },
  {
    "id": "401",
    "type": "snack",
    "|| name                                               ||": "Rambutan",
    "rating": "3",
    "fruit": "1",
    "serving": "4",
    "|| unit ||": "fruit",
    "weight-g": "140",
    "calories": "40.1",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "2",
    "saturated": "0",
    "polyunsaturated": "0.1",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "4",
    "carbs": "9.3",
    "fibre": "0.4",
    "sugar": "9.3",
    "protein": "0.5",
    "cal-from-protein": "2",
    "per-cal-from-protein": "5",
    "potassium": "33.3",
    "calcium": "4.7",
    "iron": "1.7",
    "phosphorus": "4",
    "source": "N Buddy",
    "old units (ref)": "medium"
  },
  {
    "id": "887",
    "type": "meal",
    "|| name                                               ||": "Ramen, dry curry with meat",
    "rating": "2",
    "high-cholesterol": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "181",
    "calories": "610.7",
    "fat": "27.5",
    "cal-from-fat": "247.5",
    "per-cal-from-fat": "41",
    "saturated": "10.1",
    "polyunsaturated": "4.9",
    "monounsaturated": "10.4",
    "cholesterol": "85",
    "sodium": "1778",
    "carbs": "57.8",
    "fibre": "2.6",
    "sugar": "1.6",
    "protein": "33",
    "cal-from-protein": "132",
    "per-cal-from-protein": "22",
    "potassium": "348",
    "calcium": "37",
    "iron": "4.6",
    "phosphorus": "278",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "714",
    "type": "snack",
    "|| name                                               ||": "Raspberries",
    "rating": "3",
    "fruit": "1",
    "serving": "25",
    "|| unit ||": "fruit",
    "weight-g": "100",
    "calories": "27.5",
    "fat": "0.5",
    "cal-from-fat": "4.5",
    "per-cal-from-fat": "16",
    "saturated": "0",
    "polyunsaturated": "0.25",
    "monounsaturated": "0.25",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "4.75",
    "fibre": "6.75",
    "sugar": "4.75",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "15",
    "potassium": "152.5",
    "calcium": "22.5",
    "iron": "0.5",
    "phosphorus": "12.5",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "515",
    "type": "meal",
    "|| name                                               ||": "Ratatouille",
    "rating": "3",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "vegetable mixture of eggplant, tomatoes, onions, squash and green peppers",
    "weight-g": "214",
    "calories": "148.32",
    "fat": "9.84",
    "cal-from-fat": "88.56",
    "per-cal-from-fat": "60",
    "saturated": "1.38",
    "polyunsaturated": "1.2",
    "monounsaturated": "6.68",
    "cholesterol": "0",
    "sodium": "323",
    "carbs": "12.8",
    "fibre": "3.42",
    "sugar": "6.16",
    "protein": "2.14",
    "cal-from-protein": "9",
    "per-cal-from-protein": "6",
    "potassium": "385",
    "calcium": "40.7",
    "iron": "0.92",
    "phosphorus": "53.3",
    "source": "USDA",
    "old units (ref)": "slice"
  },
  {
    "id": "50",
    "type": "snack",
    "|| name                                               ||": "Red ruby dessert",
    "rating": "2",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "390",
    "calories": "295.8",
    "fat": "21.8",
    "cal-from-fat": "196.2",
    "per-cal-from-fat": "66",
    "saturated": "20.5",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "70",
    "carbs": "22.6",
    "fibre": "6.2",
    "sugar": "16.7",
    "protein": "2.3",
    "cal-from-protein": "9",
    "per-cal-from-protein": "3",
    "potassium": "261",
    "calcium": "35",
    "iron": "0",
    "phosphorus": "54",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "928",
    "type": "meal",
    "|| name                                               ||": "Red sweet sauce",
    "rating": "1",
    "high-gi": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "|| info                                                             ||": "e.g. for yong tau foo",
    "info-include-exclude": "for yong tau foo",
    "weight-g": "17",
    "calories": "23.5",
    "fat": "0.7",
    "cal-from-fat": "6.3",
    "per-cal-from-fat": "27",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "273",
    "carbs": "3.9",
    "fibre": "0.3",
    "sugar": "3.9",
    "protein": "0.4",
    "cal-from-protein": "2",
    "per-cal-from-protein": "7",
    "potassium": "3",
    "calcium": "8",
    "iron": "0.4",
    "phosphorus": "3",
    "source": "N Buddy",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "1013",
    "type": "snack",
    "|| name                                               ||": "Red velvet cake",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "75",
    "calories": "308",
    "fat": "18.8",
    "cal-from-fat": "169.2",
    "per-cal-from-fat": "55",
    "saturated": "6.8",
    "cholesterol": "42",
    "sodium": "135",
    "carbs": "30",
    "fibre": "0.8",
    "sugar": "21.8",
    "protein": "3.8",
    "cal-from-protein": "15",
    "per-cal-from-protein": "5",
    "calcium": "30",
    "iron": "0.5",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "684",
    "type": "meal",
    "|| name                                               ||": "Rhubarb",
    "rating": "3",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "140",
    "calories": "21.6",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0.05",
    "polyunsaturated": "0.1",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "4",
    "carbs": "4.5",
    "fibre": "1.8",
    "sugar": "1.1",
    "protein": "0.9",
    "cal-from-protein": "4",
    "per-cal-from-protein": "17",
    "potassium": "259",
    "calcium": "82",
    "iron": "0.22",
    "phosphorus": "14",
    "source": "US FDA",
    "old units (ref)": "serving"
  },
  {
    "id": "576",
    "type": "drink",
    "|| name                                               ||": "Ribena",
    "rating": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "packet",
    "volume-ml": "200",
    "calories": "84",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "21",
    "sugar": "20.8",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "source": "N Buddy",
    "old units (ref)": "packet"
  },
  {
    "id": "577",
    "type": "drink",
    "|| name                                               ||": "Ribena, less sweet",
    "rating": "2",
    "high-gi": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "packet",
    "volume-ml": "200",
    "calories": "67.2",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "16.8",
    "sugar": "16.8",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "source": "N Buddy",
    "old units (ref)": "packet"
  },
  {
    "id": "982",
    "type": "snack",
    "|| name                                               ||": "Rice ball, without filling",
    "rating": "2",
    "high-fat": "1",
    "high-iron": "1",
    "high-purine": "1",
    "refined-grain": "1",
    "serving": "2",
    "|| unit ||": "piece",
    "|| info                                                             ||": "tang yuan without filling",
    "info-translation": "Tang Yuan",
    "alternate-spellings": "Tang: Tong\r\nYuan: Yuen",
    "weight-g": "40",
    "calories": "48.9",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "2",
    "saturated": "0",
    "polyunsaturated": "0.1",
    "monounsaturated": "0,1",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "11.2",
    "fibre": "0",
    "sugar": "0",
    "protein": "0.8",
    "cal-from-protein": "3",
    "per-cal-from-protein": "7",
    "potassium": "0",
    "calcium": "5",
    "iron": "1.3",
    "phosphorus": "2",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "57",
    "type": "snack",
    "|| name                                               ||": "Rice balls with filling",
    "rating": "2",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "2",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Tang yuan with peanut / black sesame filling",
    "info-translation": "Tang Yuan",
    "info-examples": "peanut, black sesame",
    "alternate-spellings": "Tang: Tong\nYuan: Yuen",
    "weight-g": "46",
    "calories": "179.7",
    "fat": "9.3",
    "cal-from-fat": "83.7",
    "per-cal-from-fat": "47",
    "saturated": "4.8",
    "polyunsaturated": "0.8",
    "monounsaturated": "3.3",
    "cholesterol": "0",
    "sodium": "2",
    "carbs": "21.5",
    "fibre": "1",
    "sugar": "11.8",
    "protein": "2.5",
    "cal-from-protein": "10",
    "per-cal-from-protein": "6",
    "potassium": "55",
    "calcium": "14",
    "iron": "0.3",
    "phosphorus": "43",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "821",
    "type": "meal",
    "|| name                                               ||": "Rice bowl with meat",
    "rating": "1",
    "fried": "1",
    "high-fat": "1",
    "high-purine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bucket",
    "|| info                                                             ||": "e.g. KFC Orginal Recipe Rice Bowl, Curry Rice Bowl",
    "info-brands": "KFC",
    "weight-g": "294",
    "calories": "515",
    "fat": "19",
    "cal-from-fat": "171",
    "per-cal-from-fat": "33",
    "saturated": "10",
    "sodium": "1511",
    "carbs": "72",
    "protein": "14",
    "cal-from-protein": "56",
    "per-cal-from-protein": "11",
    "source": "KFC website",
    "old units (ref)": "bowl"
  },
  {
    "id": "844",
    "type": "meal",
    "|| name                                               ||": "Rice burger with meat or seafood",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "burger",
    "info-brands": "MOS",
    "weight-g": "205",
    "calories": "439.5",
    "fat": "16.3",
    "cal-from-fat": "146.7",
    "per-cal-from-fat": "33",
    "saturated": "7.8",
    "polyunsaturated": "7.1",
    "monounsaturated": "9.9",
    "cholesterol": "40",
    "sodium": "1153",
    "carbs": "50.6",
    "fibre": "3",
    "sugar": "0.5",
    "protein": "22.6",
    "cal-from-protein": "90",
    "per-cal-from-protein": "21",
    "potassium": "83",
    "calcium": "160",
    "iron": "2.5",
    "phosphorus": "330",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "607",
    "type": "snack",
    "|| name                                               ||": "Rice crackers",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "packet",
    "|| info                                                             ||": "e.g. Bin Bin rice crackers, Senbei rice crackers, Ceres organics black rice crackers",
    "info-brands": "Bin Bin, Senbei",
    "weight-g": "15",
    "calories": "26.2",
    "fat": "1.8",
    "cal-from-fat": "16.2",
    "per-cal-from-fat": "62",
    "cholesterol": "0",
    "sodium": "50",
    "sugar": "3",
    "protein": "2.5",
    "cal-from-protein": "10",
    "per-cal-from-protein": "38",
    "source": "N Buddy",
    "old units (ref)": "packet"
  },
  {
    "id": "983",
    "type": "drink",
    "|| name                                               ||": "Rice milk, sweetened",
    "rating": "2",
    "high-fat": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "volume-ml": "250",
    "calories": "173.5",
    "fat": "3.1",
    "cal-from-fat": "27.9",
    "per-cal-from-fat": "16",
    "saturated": "0",
    "cholesterol": "0",
    "sodium": "94",
    "carbs": "35.4",
    "fibre": "0.5",
    "sugar": "29.2",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "2",
    "calcium": "78",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "168",
    "type": "drink",
    "|| name                                               ||": "Rice milk, unsweetened",
    "rating": "2",
    "serving": "1",
    "|| unit ||": "cup",
    "volume-ml": "250",
    "calories": "139.5",
    "fat": "2.7",
    "cal-from-fat": "24.3",
    "per-cal-from-fat": "17",
    "saturated": "0.3",
    "polyunsaturated": "0.3",
    "monounsaturated": "2.2",
    "cholesterol": "0",
    "sodium": "159",
    "carbs": "27.7",
    "sugar": "14.4",
    "protein": "1.1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "3",
    "calcium": "3",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "35",
    "type": "snack",
    "|| name                                               ||": "Rice pudding",
    "rating": "2",
    "high-calcium": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "218",
    "calories": "353",
    "fat": "13.7",
    "cal-from-fat": "123.3",
    "per-cal-from-fat": "35",
    "saturated": "6.7",
    "polyunsaturated": "1.1",
    "monounsaturated": "5.9",
    "cholesterol": "26",
    "sodium": "137",
    "carbs": "43",
    "fibre": "3.1",
    "sugar": "15.5",
    "protein": "14.2",
    "cal-from-protein": "57",
    "per-cal-from-protein": "16",
    "potassium": "390",
    "calcium": "438",
    "iron": "0.7",
    "phosphorus": "268",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "309",
    "type": "meal",
    "|| name                                               ||": "Rice, brown / red",
    "rating": "3",
    "high-fibre": "1",
    "high-phosphate": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "180",
    "calories": "272",
    "fat": "1.8",
    "cal-from-fat": "16.2",
    "per-cal-from-fat": "6",
    "saturated": "0.5",
    "polyunsaturated": "0.7",
    "monounsaturated": "0.5",
    "cholesterol": "0",
    "sodium": "4",
    "carbs": "57.2",
    "fibre": "2.7",
    "sugar": "0.5",
    "protein": "5.8",
    "cal-from-protein": "23",
    "per-cal-from-protein": "9",
    "potassium": "0.7",
    "calcium": "9",
    "iron": "0.9",
    "phosphorus": "558",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "516",
    "type": "meal",
    "|| name                                               ||": "Risotto",
    "rating": "2",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "400",
    "calories": "664.3",
    "fat": "17.9",
    "cal-from-fat": "161.1",
    "per-cal-from-fat": "24",
    "saturated": "7.5",
    "polyunsaturated": "2.5",
    "monounsaturated": "6.6",
    "trans": "0.4",
    "cholesterol": "22",
    "sodium": "819",
    "carbs": "108.4",
    "fibre": "5.1",
    "sugar": "2.5",
    "protein": "17.4",
    "cal-from-protein": "70",
    "per-cal-from-protein": "10",
    "potassium": "380",
    "calcium": "227",
    "iron": "1.8",
    "phosphorus": "373",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "988",
    "type": "meal",
    "|| name                                               ||": "Roast chicken and char siew rice",
    "rating": "2",
    "high-fat": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Roast chicken and BBQ pork rice",
    "alternate-spellings": "Char: Cha \nSiew: Shao, Siu",
    "weight-g": "427",
    "calories": "809.6",
    "fat": "28",
    "cal-from-fat": "252",
    "per-cal-from-fat": "31",
    "saturated": "15.6",
    "polyunsaturated": "2.9",
    "monounsaturated": "5.8",
    "cholesterol": "170",
    "sodium": "1272",
    "carbs": "92.1",
    "fibre": "5.9",
    "sugar": "14",
    "protein": "47.3",
    "cal-from-protein": "189",
    "per-cal-from-protein": "23",
    "potassium": "456",
    "calcium": "55",
    "iron": "1.1",
    "phosphorus": "333",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "987",
    "type": "meal",
    "|| name                                               ||": "Roast chicken and roast pork rice",
    "rating": "2",
    "high-fat": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Roast chicken and siew yok rice",
    "weight-g": "380",
    "calories": "662",
    "fat": "28.8",
    "cal-from-fat": "259.2",
    "per-cal-from-fat": "39",
    "saturated": "10.9",
    "cholesterol": "88",
    "sodium": "1142",
    "carbs": "64.5",
    "fibre": "1.7",
    "protein": "35.2",
    "cal-from-protein": "141",
    "per-cal-from-protein": "21",
    "source": "HPB",
    "old units (ref)": "plate"
  },
  {
    "id": "985",
    "type": "meal",
    "|| name                                               ||": "Roast duck and char siew rice",
    "rating": "2",
    "high-fat": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Roast duck and BBQ pork rice",
    "alternate-spellings": "Char: Cha \nSiew: Shao, Siu",
    "weight-g": "427",
    "calories": "844.8",
    "fat": "32.4",
    "cal-from-fat": "291.6",
    "per-cal-from-fat": "35",
    "saturated": "17.8",
    "polyunsaturated": "2.6",
    "monounsaturated": "8.1",
    "cholesterol": "186",
    "sodium": "1088",
    "carbs": "92.7",
    "fibre": "5.9",
    "sugar": "13.1",
    "protein": "45.6",
    "cal-from-protein": "182",
    "per-cal-from-protein": "22",
    "potassium": "206",
    "calcium": "71",
    "iron": "3.1",
    "phosphorus": "193",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "986",
    "type": "meal",
    "|| name                                               ||": "Roast duck and roast chicken rice",
    "rating": "2",
    "high-fat": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "380",
    "calories": "644",
    "fat": "28",
    "cal-from-fat": "252",
    "per-cal-from-fat": "39",
    "saturated": "10.6",
    "cholesterol": "106",
    "sodium": "1216",
    "carbs": "64.8",
    "fibre": "1.7",
    "protein": "32.9",
    "cal-from-protein": "132",
    "per-cal-from-protein": "20",
    "source": "HPB",
    "old units (ref)": "plate"
  },
  {
    "id": "984",
    "type": "meal",
    "|| name                                               ||": "Roast duck and roast pork rice",
    "rating": "2",
    "high-fat": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Roast duck and siew yok rice",
    "weight-g": "450",
    "calories": "869",
    "fat": "36.2",
    "cal-from-fat": "325.8",
    "per-cal-from-fat": "37",
    "saturated": "13.9",
    "cholesterol": "128",
    "sodium": "863",
    "carbs": "97",
    "fibre": "0.9",
    "protein": "37.3",
    "cal-from-protein": "149",
    "per-cal-from-protein": "17",
    "source": "HPB",
    "old units (ref)": "plate"
  },
  {
    "id": "762",
    "type": "meal",
    "|| name                                               ||": "Roast duck rice",
    "rating": "2",
    "high-calcium": "1",
    "high-cholesterol": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-purine": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "350",
    "calories": "626.1",
    "fat": "24.9",
    "cal-from-fat": "224.1",
    "per-cal-from-fat": "36",
    "saturated": "3.9",
    "polyunsaturated": "2",
    "monounsaturated": "5.1",
    "cholesterol": "84",
    "sodium": "347",
    "carbs": "88.9",
    "fibre": "3.5",
    "sugar": "1.8",
    "protein": "11.6",
    "cal-from-protein": "46",
    "per-cal-from-protein": "7",
    "potassium": "259",
    "calcium": "158",
    "iron": "1.8",
    "phosphorus": "291",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "760",
    "type": "meal",
    "|| name                                               ||": "Roast pork rice",
    "rating": "2",
    "high-cholesterol": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-purine": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Siew yok rice",
    "info-translation": "Siew Yok Rice",
    "alternate-spellings": "Siew: Shao, Siu \nYok: Yoke, Yuk",
    "weight-g": "356",
    "calories": "604.18",
    "fat": "27.3",
    "cal-from-fat": "245.7",
    "per-cal-from-fat": "41",
    "saturated": "9.522",
    "polyunsaturated": "3.3",
    "monounsaturated": "12.38",
    "cholesterol": "234",
    "sodium": "1164.75",
    "carbs": "59.02",
    "fibre": "2.18",
    "sugar": "9",
    "protein": "30.6",
    "cal-from-protein": "122",
    "per-cal-from-protein": "20",
    "potassium": "464.25",
    "calcium": "73.5",
    "iron": "5.77",
    "phosphorus": "126.75",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "718",
    "type": "snack",
    "|| name                                               ||": "Rock melon",
    "rating": "3",
    "fruit": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "wedge",
    "|| info                                                             ||": "Cantaloupe",
    "info-translation": "Cantaloupe",
    "weight-g": "102",
    "calories": "35",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "5",
    "saturated": "0.1",
    "polyunsaturated": "0.1",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "16",
    "carbs": "8.3",
    "fibre": "0.9",
    "sugar": "8",
    "protein": "0.9",
    "cal-from-protein": "4",
    "per-cal-from-protein": "10",
    "potassium": "272",
    "calcium": "9.2",
    "iron": "0.2",
    "phosphorus": "15",
    "source": "USDA",
    "old units (ref)": "portion"
  },
  {
    "id": "801",
    "type": "meal",
    "|| name                                               ||": "Oats, rolled / steel cut, dry, unsweetened",
    "rating": "3",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "whole-grain": "1",
    "serving": "3",
    "|| unit ||": "tbsp",
    "|| info                                                             ||": "dry, unsweetened",
    "weight-g": "30",
    "calories": "105",
    "fat": "1.9",
    "cal-from-fat": "17.1",
    "per-cal-from-fat": "16",
    "saturated": "0",
    "polyunsaturated": "0.7",
    "monounsaturated": "0.7",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "20.3",
    "fibre": "3",
    "sugar": "0",
    "protein": "3.8",
    "cal-from-protein": "15",
    "per-cal-from-protein": "14",
    "potassium": "105",
    "calcium": "15",
    "iron": "1.3",
    "phosphorus": "163",
    "source": "USDA",
    "old units (ref)": "bowl"
  },
  {
    "id": "800",
    "type": "meal",
    "|| name                                               ||": "Rolled oats / steel cut oats, cooked, sweetened",
    "rating": "2",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "with added honey/sugar",
    "weight-g": "260",
    "calories": "172",
    "fat": "2.9",
    "cal-from-fat": "26.1",
    "per-cal-from-fat": "15",
    "saturated": "0.5",
    "polyunsaturated": "1",
    "monounsaturated": "1",
    "cholesterol": "0",
    "sodium": "3",
    "carbs": "32.5",
    "fibre": "3.4",
    "sugar": "10",
    "protein": "4.2",
    "cal-from-protein": "17",
    "per-cal-from-protein": "10",
    "potassium": "107",
    "calcium": "18",
    "iron": "1.8",
    "phosphorus": "122",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "651",
    "type": "meal",
    "|| name                                               ||": "Romaine lettuce, raw",
    "rating": "3",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "150",
    "calories": "30.7",
    "fat": "0.3",
    "cal-from-fat": "2.7",
    "per-cal-from-fat": "9",
    "saturated": "0",
    "polyunsaturated": "0.3",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "13",
    "carbs": "5.1",
    "fibre": "3.2",
    "sugar": "1.9",
    "protein": "1.9",
    "cal-from-protein": "8",
    "per-cal-from-protein": "25",
    "potassium": "371",
    "calcium": "51",
    "iron": "1.6",
    "phosphorus": "45",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "581",
    "type": "drink",
    "|| name                                               ||": "Soda float",
    "rating": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "e.g. Root beer float",
    "info-examples": "root beer, coke",
    "weight-g": "240",
    "calories": "205.88",
    "fat": "6.96",
    "cal-from-fat": "62.64",
    "per-cal-from-fat": "30",
    "saturated": "4.29",
    "polyunsaturated": "0.286",
    "monounsaturated": "1.87",
    "cholesterol": "28.8",
    "sodium": "74.4",
    "carbs": "33.6",
    "fibre": "0.48",
    "sugar": "32.2",
    "protein": "2.21",
    "cal-from-protein": "9",
    "per-cal-from-protein": "4",
    "potassium": "127",
    "calcium": "88.8",
    "iron": "0.144",
    "phosphorus": "67.2",
    "source": "USDA",
    "old units (ref)": "cup"
  },
  {
    "id": "136",
    "type": "meal",
    "|| name                                               ||": "Roti john",
    "rating": "2",
    "high-cholesterol": "1",
    "high-gi": "1",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "whole",
    "weight-g": "253",
    "calories": "722.3",
    "fat": "34.7",
    "cal-from-fat": "312.3",
    "per-cal-from-fat": "43",
    "saturated": "18.2",
    "polyunsaturated": "3",
    "monounsaturated": "11.9",
    "cholesterol": "243",
    "sodium": "2019",
    "carbs": "77.2",
    "fibre": "3",
    "sugar": "0.2",
    "protein": "25.3",
    "cal-from-protein": "101",
    "per-cal-from-protein": "14",
    "calcium": "89",
    "iron": "4.5",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "134",
    "type": "meal",
    "|| name                                               ||": "Roti prata, plain",
    "rating": "2",
    "fried": "1",
    "high-gi": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "alternate-spellings": "Prata: Canai",
    "weight-g": "70",
    "calories": "204.3",
    "fat": "7.1",
    "cal-from-fat": "63.9",
    "per-cal-from-fat": "31",
    "saturated": "3.4",
    "polyunsaturated": "0.9",
    "monounsaturated": "2.8",
    "cholesterol": "0",
    "sodium": "260",
    "carbs": "30",
    "fibre": "1.3",
    "sugar": "3",
    "protein": "5.1",
    "cal-from-protein": "20",
    "per-cal-from-protein": "10",
    "potassium": "47",
    "calcium": "11",
    "iron": "1",
    "phosphorus": "35",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "56",
    "type": "snack",
    "|| name                                               ||": "Sago gula melaka",
    "rating": "1",
    "high-gi": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Sago pudding with palm sugar",
    "info-translation": "Pudding with palm sugar",
    "weight-g": "159",
    "calories": "283.3",
    "fat": "17.7",
    "cal-from-fat": "159.3",
    "per-cal-from-fat": "56",
    "saturated": "15.7",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.7",
    "sodium": "143",
    "carbs": "29.2",
    "fibre": "1.4",
    "sugar": "18.3",
    "protein": "1.8",
    "cal-from-protein": "7",
    "per-cal-from-protein": "3",
    "potassium": "259",
    "calcium": "41",
    "iron": "2.3",
    "phosphorus": "70",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "18",
    "type": "drink",
    "|| name                                               ||": "Sake",
    "rating": "1",
    "alcohol": "1",
    "high-purine": "1",
    "serving": "1",
    "|| unit ||": "glass",
    "volume-ml": "30",
    "calories": "40",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "0.582",
    "carbs": "1.46",
    "fibre": "0",
    "sugar": "0",
    "protein": "0.146",
    "cal-from-protein": "1",
    "per-cal-from-protein": "1",
    "potassium": "7.28",
    "calcium": "1.46",
    "iron": "0.029",
    "phosphorus": "1.75",
    "source": "USDA",
    "old units (ref)": "small cup"
  },
  {
    "id": "809",
    "type": "meal",
    "|| name                                               ||": "Salad with meat and dressing",
    "rating": "3",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "e.g. Caesar salad",
    "info-examples": "Caesar",
    "weight-g": "226",
    "calories": "334",
    "fat": "18",
    "cal-from-fat": "162",
    "per-cal-from-fat": "49",
    "saturated": "3.5",
    "polyunsaturated": "7.1",
    "monounsaturated": "5.6",
    "cholesterol": "25",
    "sodium": "1100",
    "carbs": "13.3",
    "fibre": "0.9",
    "sugar": "9.3",
    "protein": "31",
    "cal-from-protein": "124",
    "per-cal-from-protein": "37",
    "potassium": "468",
    "calcium": "38.4",
    "iron": "1.1",
    "phosphorus": "289",
    "source": "USDA",
    "old units (ref)": "medium bowl"
  },
  {
    "id": "810",
    "type": "meal",
    "|| name                                               ||": "Salad with processed meat and dressing",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "e.g. Ham salad",
    "info-examples": "ham, bacon",
    "weight-g": "182",
    "calories": "419",
    "fat": "32.9",
    "cal-from-fat": "296.1",
    "per-cal-from-fat": "71",
    "saturated": "6.1",
    "polyunsaturated": "17.1",
    "monounsaturated": "8.6",
    "cholesterol": "67.3",
    "sodium": "1790",
    "carbs": "5",
    "fibre": "0.7",
    "sugar": "3.8",
    "protein": "24.2",
    "cal-from-protein": "97",
    "per-cal-from-protein": "23",
    "potassium": "411",
    "calcium": "25.5",
    "iron": "1.1",
    "phosphorus": "233",
    "source": "USDA",
    "old units (ref)": "medium bowl"
  },
  {
    "id": "424",
    "type": "meal",
    "|| name                                               ||": "Salad, plain",
    "rating": "3",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "|| info                                                             ||": "vegetable only, without dressing, without meat",
    "info-include-exclude": "without dressing or meat",
    "weight-g": "75",
    "calories": "17.3",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "5",
    "saturated": "0",
    "polyunsaturated": "0.1",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "22",
    "carbs": "3.2",
    "fibre": "1.3",
    "sugar": "1.4",
    "protein": "0.9",
    "cal-from-protein": "4",
    "per-cal-from-protein": "21",
    "potassium": "190",
    "calcium": "26",
    "iron": "0.5",
    "phosphorus": "21",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "311",
    "type": "meal",
    "|| name                                               ||": "Salad with dressing",
    "rating": "2",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "e.g. Greek Salad",
    "info-examples": "Greek",
    "info-include-exclude": "without meat",
    "weight-g": "337",
    "calories": "269",
    "fat": "35.4",
    "cal-from-fat": "318.6",
    "per-cal-from-fat": "118",
    "saturated": "8.4",
    "polyunsaturated": "2.5",
    "monounsaturated": "7.6",
    "cholesterol": "23",
    "sodium": "518",
    "carbs": "33.4",
    "fibre": "8.2",
    "sugar": "6",
    "protein": "12.7",
    "cal-from-protein": "51",
    "per-cal-from-protein": "19",
    "potassium": "701",
    "calcium": "272",
    "iron": "2.6",
    "phosphorus": "291",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "848",
    "type": "meal",
    "|| name                                               ||": "Salmon don",
    "rating": "2",
    "healthy-fats": "1",
    "high-iodine": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "277",
    "calories": "393",
    "fat": "8.7",
    "cal-from-fat": "78.3",
    "per-cal-from-fat": "20",
    "saturated": "2.4",
    "polyunsaturated": "2.2",
    "monounsaturated": "3.6",
    "cholesterol": "21",
    "sodium": "22",
    "carbs": "59.4",
    "fibre": "0.5",
    "sugar": "0.2",
    "protein": "17.3",
    "cal-from-protein": "69",
    "per-cal-from-protein": "18",
    "potassium": "317",
    "calcium": "5",
    "iron": "4.2",
    "phosphorus": "223",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "425",
    "type": "meal",
    "|| name                                               ||": "Salmon, grilled / baked / pan fried",
    "rating": "3",
    "healthy-fats": "1",
    "high-iodine": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "weight-g": "85",
    "calories": "169.7",
    "fat": "10.5",
    "cal-from-fat": "94.5",
    "per-cal-from-fat": "56",
    "saturated": "2.04",
    "polyunsaturated": "3.87",
    "monounsaturated": "3.55",
    "cholesterol": "53.6",
    "sodium": "51.8",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "18.8",
    "cal-from-protein": "75",
    "per-cal-from-protein": "44",
    "potassium": "326",
    "calcium": "12.8",
    "iron": "0.289",
    "phosphorus": "214",
    "iodine": "1",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "1052",
    "type": "snack",
    "|| name                                               ||": "Salsa sauce",
    "rating": "3",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "|| info                                                             ||": "tomato salsa, salsa dip",
    "weight-g": "106",
    "calories": "33",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "5",
    "saturated": "0.1",
    "polyunsaturated": "0.1",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "726",
    "carbs": "5.9",
    "fibre": "1.3",
    "sugar": "3",
    "protein": "1.1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "13",
    "potassium": "374",
    "calcium": "34",
    "iron": "0.5",
    "phosphorus": "41",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "910",
    "type": "meal",
    "|| name                                               ||": "Salted egg yolk bun",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Liu sha bao",
    "info-translation": "Liu Sha Bao",
    "alternate-spellings": "Liu: Lau\nBao: Pau, Pao",
    "weight-g": "100",
    "calories": "325.6",
    "fat": "16",
    "cal-from-fat": "144",
    "per-cal-from-fat": "44",
    "saturated": "6.6",
    "polyunsaturated": "3.5",
    "monounsaturated": "5.9",
    "cholesterol": "377",
    "carbs": "38",
    "fibre": "1.3",
    "sugar": "1",
    "protein": "7.4",
    "cal-from-protein": "30",
    "per-cal-from-protein": "9",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "789",
    "type": "meal",
    "|| name                                               ||": "Sam loh fish horfun",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "alternate-spellings": "Sam: San \nLoh: Lao, Lou",
    "weight-g": "372",
    "calories": "514.8",
    "fat": "16.4",
    "cal-from-fat": "147.6",
    "per-cal-from-fat": "29",
    "saturated": "2.5",
    "polyunsaturated": "7.8",
    "monounsaturated": "4",
    "trans": "0.06",
    "cholesterol": "12",
    "sodium": "2034",
    "carbs": "69.7",
    "fibre": "2.7",
    "sugar": "1.1",
    "protein": "22.1",
    "cal-from-protein": "88",
    "per-cal-from-protein": "17",
    "potassium": "485",
    "calcium": "50",
    "iron": "6.7",
    "phosphorus": "309",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "896",
    "type": "meal",
    "|| name                                               ||": "Sambal fried rice",
    "rating": "1",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "370",
    "calories": "667.6",
    "fat": "24.8",
    "cal-from-fat": "223.2",
    "per-cal-from-fat": "33",
    "saturated": "8.9",
    "polyunsaturated": "4.4",
    "monounsaturated": "10",
    "cholesterol": "207",
    "sodium": "1645",
    "carbs": "82.9",
    "fibre": "2.2",
    "sugar": "18.5",
    "protein": "28.2",
    "cal-from-protein": "113",
    "per-cal-from-protein": "17",
    "potassium": "298",
    "calcium": "120",
    "iron": "2.9",
    "phosphorus": "212",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "962",
    "type": "meal",
    "|| name                                               ||": "Sambal kang kung",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fibre": "1",
    "high-salt": "1",
    "serving": "2",
    "|| unit ||": "\r\nhandful",
    "|| info                                                             ||": "Water spinach fried with belacan",
    "info-translation": "Water spinach fried with belacan",
    "alternate-spellings": "Kung: Kong",
    "weight-g": "100",
    "calories": "80.1",
    "fat": "4.5",
    "cal-from-fat": "40.5",
    "per-cal-from-fat": "51",
    "saturated": "1.8",
    "polyunsaturated": "0.5",
    "monounsaturated": "1.9",
    "cholesterol": "20",
    "sodium": "527",
    "carbs": "5.2",
    "fibre": "3",
    "sugar": "5",
    "protein": "4.7",
    "cal-from-protein": "19",
    "per-cal-from-protein": "23",
    "potassium": "180",
    "calcium": "155",
    "iron": "5.5",
    "phosphorus": "59",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "454",
    "type": "meal",
    "|| name                                               ||": "Sambal stingray",
    "rating": "2",
    "high-iodine": "1",
    "high-iron": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "weight-g": "150",
    "calories": "185.8",
    "fat": "6.6",
    "cal-from-fat": "59.4",
    "per-cal-from-fat": "32",
    "saturated": "2.3",
    "polyunsaturated": "0.6",
    "monounsaturated": "1.8",
    "cholesterol": "154.4",
    "sodium": "943.5",
    "carbs": "1.9",
    "fibre": "0",
    "sugar": "0",
    "protein": "29.7",
    "cal-from-protein": "119",
    "per-cal-from-protein": "64",
    "calcium": "175.5",
    "iron": "2.7",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "891",
    "type": "meal",
    "|| name                                               ||": "Sandwich with fillings",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": " e.g. egg, ham, cheese, tuna",
    "info-examples": "egg, ham, cheese, tuna, chicken",
    "weight-g": "150",
    "calories": "473",
    "fat": "20.3",
    "cal-from-fat": "182.7",
    "per-cal-from-fat": "39",
    "saturated": "4.81",
    "polyunsaturated": "9.73",
    "monounsaturated": "4.56",
    "cholesterol": "39",
    "sodium": "826",
    "carbs": "25.6",
    "fibre": "1.5",
    "sugar": "5.43",
    "protein": "15.9",
    "cal-from-protein": "64",
    "per-cal-from-protein": "13",
    "potassium": "246",
    "calcium": "294",
    "iron": "2.38",
    "phosphorus": "244",
    "source": "USDA",
    "old units (ref)": "sandwich"
  },
  {
    "id": "201",
    "type": "snack",
    "|| name                                               ||": "Sardine puff",
    "rating": "2",
    "fried": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "72",
    "calories": "225.3",
    "fat": "12.5",
    "cal-from-fat": "112.5",
    "per-cal-from-fat": "50",
    "saturated": "5.6",
    "polyunsaturated": "1.3",
    "monounsaturated": "5",
    "cholesterol": "171",
    "sodium": "292",
    "carbs": "22.2",
    "fibre": "1.9",
    "sugar": "2.2",
    "protein": "6",
    "cal-from-protein": "24",
    "per-cal-from-protein": "11",
    "potassium": "121",
    "calcium": "43",
    "iron": "0.9",
    "phosphorus": "70",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "540",
    "type": "meal",
    "|| name                                               ||": "Sashimi, raw fish",
    "rating": "3",
    "healthy-fats": "1",
    "high-iodine": "1",
    "serving": "2",
    "|| unit ||": "slice",
    "|| info                                                             ||": "e.g. salmon, tuna belly",
    "info-examples": "salmon, tuna",
    "weight-g": "28.35",
    "calories": "34.49",
    "fat": "1.25",
    "cal-from-fat": "11.25",
    "per-cal-from-fat": "33",
    "saturated": "0.23",
    "polyunsaturated": "0.23",
    "monounsaturated": "0.381",
    "cholesterol": "13",
    "sodium": "21.3",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "5.81",
    "cal-from-protein": "23",
    "per-cal-from-protein": "67",
    "potassium": "104",
    "calcium": "1.98",
    "iron": "0.108",
    "phosphorus": "74",
    "source": "USDA",
    "old units (ref)": "portion"
  },
  {
    "id": "138",
    "type": "meal",
    "|| name                                               ||": "Satay beehoon",
    "rating": "2",
    "high-gi": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "info-translation": "Rice vermicelli",
    "weight-g": "452",
    "calories": "760.7",
    "fat": "37.5",
    "cal-from-fat": "337.5",
    "per-cal-from-fat": "44",
    "saturated": "14.5",
    "polyunsaturated": "7.2",
    "monounsaturated": "14",
    "cholesterol": "95",
    "sodium": "1573",
    "carbs": "75.5",
    "fibre": "3",
    "sugar": "9",
    "protein": "30.3",
    "cal-from-protein": "121",
    "per-cal-from-protein": "16",
    "potassium": "649",
    "calcium": "199",
    "iron": "7.7",
    "phosphorus": "366",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "137",
    "type": "meal",
    "|| name                                               ||": "Satay with sauce",
    "rating": "2",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "red-meat": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "18",
    "calories": "50",
    "fat": "2.8",
    "cal-from-fat": "25.2",
    "per-cal-from-fat": "50",
    "saturated": "1.1",
    "polyunsaturated": "0.4",
    "monounsaturated": "1.2",
    "cholesterol": "12",
    "sodium": "66.5",
    "carbs": "3.1",
    "fibre": "0.2",
    "sugar": "3",
    "protein": "3.1",
    "cal-from-protein": "12",
    "per-cal-from-protein": "25",
    "potassium": "44.8",
    "calcium": "5",
    "iron": "0.6",
    "phosphorus": "27.8",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "312",
    "type": "meal",
    "|| name                                               ||": "Satay, without sauce",
    "rating": "2",
    "high-purine": "1",
    "red-meat": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "11",
    "calories": "30.8",
    "fat": "1.2",
    "cal-from-fat": "10.8",
    "per-cal-from-fat": "35",
    "saturated": "0.6",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.5",
    "cholesterol": "12",
    "sodium": "32",
    "carbs": "2.3",
    "fibre": "0",
    "sugar": "2.3",
    "protein": "2.7",
    "cal-from-protein": "11",
    "per-cal-from-protein": "35",
    "potassium": "31",
    "calcium": "3",
    "iron": "0.5",
    "phosphorus": "21",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "873",
    "type": "meal",
    "|| name                                               ||": "Sauce, BBQ / habanero",
    "rating": "2",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "17",
    "calories": "29.239",
    "fat": "0.107",
    "cal-from-fat": "0.963",
    "per-cal-from-fat": "3",
    "saturated": "0.008",
    "polyunsaturated": "0.017",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "175",
    "carbs": "6.93",
    "fibre": "0.153",
    "sugar": "5.65",
    "protein": "0.139",
    "cal-from-protein": "1",
    "per-cal-from-protein": "2",
    "potassium": "39.4",
    "calcium": "5.61",
    "iron": "0.109",
    "phosphorus": "3.4",
    "source": "USDA",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "350",
    "type": "snack",
    "|| name                                               ||": "Sauce, satay",
    "rating": "2",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "29",
    "calories": "77.3",
    "fat": "6.5",
    "cal-from-fat": "58.5",
    "per-cal-from-fat": "76",
    "saturated": "2",
    "polyunsaturated": "1.4",
    "monounsaturated": "2.7",
    "cholesterol": "0",
    "sodium": "138",
    "carbs": "3.1",
    "fibre": "0.9",
    "sugar": "2.8",
    "protein": "1.6",
    "cal-from-protein": "6",
    "per-cal-from-protein": "8",
    "potassium": "55",
    "calcium": "8",
    "iron": "0.4",
    "phosphorus": "27",
    "source": "N Buddy",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "874",
    "type": "meal",
    "|| name                                               ||": "Sauce, sweet Thai chilli",
    "rating": "2",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "20",
    "calories": "40.4",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "268",
    "carbs": "9.9",
    "fibre": "0.3",
    "sugar": "9",
    "protein": "0.2",
    "cal-from-protein": "1",
    "per-cal-from-protein": "2",
    "potassium": "14",
    "calcium": "1",
    "iron": "0.1",
    "phosphorus": "4",
    "source": "N Buddy",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "139",
    "type": "meal",
    "|| name                                               ||": "Sausage, boiled",
    "rating": "1",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "red-meat": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "68",
    "calories": "210",
    "fat": "19.2",
    "cal-from-fat": "172.8",
    "per-cal-from-fat": "82",
    "saturated": "6.3",
    "polyunsaturated": "2.2",
    "monounsaturated": "7.6",
    "trans": "0.1",
    "cholesterol": "42",
    "sodium": "562",
    "carbs": "0.6",
    "fibre": "0",
    "sugar": "0.6",
    "protein": "8.2",
    "cal-from-protein": "33",
    "per-cal-from-protein": "16",
    "potassium": "328",
    "calcium": "7.5",
    "iron": "0.4",
    "phosphorus": "107",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "140",
    "type": "meal",
    "|| name                                               ||": "Sausage, fried",
    "rating": "1",
    "fried": "1",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "red-meat": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "87",
    "calories": "282",
    "fat": "23",
    "cal-from-fat": "207",
    "per-cal-from-fat": "73",
    "saturated": "7.9",
    "polyunsaturated": "4.3",
    "monounsaturated": "10.1",
    "trans": "0.1",
    "cholesterol": "71",
    "sodium": "697",
    "carbs": "1.6",
    "fibre": "0",
    "sugar": "1.2",
    "protein": "16",
    "cal-from-protein": "64",
    "per-cal-from-protein": "23",
    "potassium": "295",
    "calcium": "11",
    "iron": "1.1",
    "phosphorus": "132",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "313",
    "type": "meal",
    "|| name                                               ||": "Savory crepe",
    "rating": "2",
    "high-cholesterol": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. salmon and cheese crepe",
    "info-examples": "cheese, salmon",
    "weight-g": "200",
    "calories": "393.1",
    "fat": "19.9",
    "cal-from-fat": "179.1",
    "per-cal-from-fat": "46",
    "saturated": "11.4",
    "polyunsaturated": "1.8",
    "monounsaturated": "5.4",
    "cholesterol": "96",
    "sodium": "1337",
    "carbs": "25.4",
    "fibre": "1.6",
    "sugar": "4.5",
    "protein": "28.1",
    "cal-from-protein": "112",
    "per-cal-from-protein": "29",
    "potassium": "367",
    "calcium": "342",
    "iron": "2.8",
    "phosphorus": "287",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "814",
    "type": "meal",
    "|| name                                               ||": "Savoury muffin with patty",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "muffin",
    "|| info                                                             ||": "e.g. McDonald's Sausage McMuffin with egg, chicken muffin with egg",
    "info-brands": "McMuffin",
    "info-examples": " sausage, chicken",
    "weight-g": "163",
    "calories": "356",
    "fat": "16",
    "cal-from-fat": "144",
    "per-cal-from-fat": "40",
    "saturated": "6.6",
    "polyunsaturated": "5",
    "monounsaturated": "4",
    "cholesterol": "362",
    "sodium": "750",
    "carbs": "30",
    "fibre": "3",
    "sugar": "1",
    "protein": "23",
    "cal-from-protein": "92",
    "per-cal-from-protein": "26",
    "calcium": "256",
    "iron": "3.3",
    "source": "N Buddy",
    "old units (ref)": "whole"
  },
  {
    "id": "314",
    "type": "meal",
    "|| name                                               ||": "Sayur lodeh",
    "rating": "2",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "probiotic": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "250",
    "calories": "163.9",
    "fat": "14.3",
    "cal-from-fat": "128.7",
    "per-cal-from-fat": "79",
    "saturated": "9.5",
    "polyunsaturated": "1.5",
    "monounsaturated": "2.8",
    "cholesterol": "10",
    "sodium": "1175",
    "carbs": "1.8",
    "fibre": "2.8",
    "sugar": "0",
    "protein": "7",
    "cal-from-protein": "28",
    "per-cal-from-protein": "17",
    "potassium": "280",
    "calcium": "80",
    "iron": "0.6",
    "phosphorus": "998",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "657",
    "disabled": "1",
    "type": "meal",
    "|| name                                               ||": "Scallion",
    "rating": "3",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "alternate-spellings": "Scallion: Green, Onion, Spring",
    "weight-g": "100",
    "calories": "118.6",
    "fat": "9",
    "cal-from-fat": "81",
    "per-cal-from-fat": "68",
    "saturated": "1.2",
    "polyunsaturated": "0.5",
    "monounsaturated": "0.7",
    "cholesterol": "0",
    "sodium": "273",
    "carbs": "7.5",
    "fibre": "2.6",
    "sugar": "2.4",
    "protein": "1.9",
    "cal-from-protein": "8",
    "per-cal-from-protein": "6",
    "potassium": "253",
    "calcium": "70",
    "iron": "1.43",
    "phosphorus": "34",
    "source": "US FDA",
    "old units (ref)": "serving"
  },
  {
    "id": "192",
    "type": "snack",
    "|| name                                               ||": "Scone, plain",
    "rating": "2",
    "high-gi": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "65",
    "calories": "219.1",
    "fat": "5.5",
    "cal-from-fat": "49.5",
    "per-cal-from-fat": "23",
    "saturated": "2",
    "polyunsaturated": "0.5",
    "monounsaturated": "2.3",
    "trans": "0.2",
    "cholesterol": "7",
    "sodium": "495",
    "carbs": "37.3",
    "fibre": "1.4",
    "sugar": "4.2",
    "protein": "5.1",
    "cal-from-protein": "20",
    "per-cal-from-protein": "9",
    "potassium": "72",
    "calcium": "46",
    "iron": "0.6",
    "phosphorus": "68",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "1063",
    "type": "meal",
    "|| name                                               ||": "Seafood claypot noodle",
    "rating": "2",
    "high-calcium": "1",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "555",
    "calories": "678.5",
    "fat": "43.3",
    "cal-from-fat": "389.7",
    "per-cal-from-fat": "57",
    "saturated": "18.3",
    "polyunsaturated": "5",
    "monounsaturated": "18.3",
    "cholesterol": "211",
    "sodium": "3413",
    "carbs": "42.2",
    "fibre": "5",
    "sugar": "2",
    "protein": "30",
    "cal-from-protein": "120",
    "per-cal-from-protein": "18",
    "potassium": "655",
    "calcium": "183",
    "iron": "1.7",
    "phosphorus": "194",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "315",
    "type": "meal",
    "|| name                                               ||": "Seafood hor fun",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "694",
    "calories": "749",
    "fat": "27",
    "cal-from-fat": "243",
    "per-cal-from-fat": "32",
    "saturated": "11.2",
    "polyunsaturated": "3.3",
    "monounsaturated": "10.6",
    "cholesterol": "77",
    "sodium": "1886",
    "carbs": "91.8",
    "fibre": "7.2",
    "sugar": "2",
    "protein": "34.7",
    "cal-from-protein": "139",
    "per-cal-from-protein": "19",
    "potassium": "180",
    "calcium": "62",
    "iron": "2.11",
    "phosphorus": "236",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "790",
    "type": "meal",
    "|| name                                               ||": "Seafood white beehoon",
    "rating": "2",
    "high-calcium": "1",
    "high-cholesterol": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "554",
    "calories": "526.6",
    "fat": "24.6",
    "cal-from-fat": "221.4",
    "per-cal-from-fat": "42",
    "saturated": "9.7",
    "polyunsaturated": "2.7",
    "monounsaturated": "9.2",
    "cholesterol": "314",
    "sodium": "1182",
    "carbs": "51.9",
    "fibre": "3.6",
    "sugar": "5.1",
    "protein": "24.4",
    "cal-from-protein": "98",
    "per-cal-from-protein": "19",
    "potassium": "753",
    "calcium": "360",
    "iron": "1.4",
    "phosphorus": "703",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "414",
    "type": "snack",
    "|| name                                               ||": "Seaweed salad",
    "rating": "3",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "|| info                                                             ||": "100g",
    "weight-g": "100",
    "calories": "69.6",
    "fat": "2.8",
    "cal-from-fat": "25.2",
    "per-cal-from-fat": "36",
    "saturated": "0",
    "polyunsaturated": "1.4",
    "monounsaturated": "1.4",
    "cholesterol": "0",
    "sodium": "811",
    "carbs": "11.1",
    "fibre": "4.4",
    "sugar": "5.6",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "calcium": "111",
    "iron": "0.8",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "427",
    "type": "meal",
    "|| name                                               ||": "Seaweed soup",
    "rating": "3",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "200",
    "calories": "37.6",
    "fat": "2",
    "cal-from-fat": "18",
    "per-cal-from-fat": "48",
    "saturated": "0.6",
    "polyunsaturated": "0.5",
    "monounsaturated": "0.8",
    "cholesterol": "0",
    "sodium": "1223",
    "carbs": "0.6",
    "fibre": "0",
    "sugar": "0",
    "protein": "4.3",
    "cal-from-protein": "17",
    "per-cal-from-protein": "46",
    "potassium": "198",
    "calcium": "29",
    "iron": "0.7",
    "phosphorus": "51",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "699",
    "type": "meal",
    "|| name                                               ||": "Seaweed, roasted",
    "rating": "3",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "minipack",
    "weight-g": "5",
    "calories": "8.8",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0.2",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "56",
    "carbs": "1.1",
    "fibre": "0",
    "sugar": "0",
    "protein": "1.1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "50",
    "source": "N Buddy",
    "old units (ref)": "sheet"
  },
  {
    "id": "608",
    "type": "snack",
    "|| name                                               ||": "Seeded crackers",
    "rating": "3",
    "healthy-fats": "1",
    "high-fat": "1",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "plant-protein": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "packet",
    "|| info                                                             ||": "e.g. flaxseed, sesame seed",
    "info-examples": "flaxseed, sesame",
    "weight-g": "30",
    "calories": "100.4",
    "fat": "3.6",
    "cal-from-fat": "32.4",
    "per-cal-from-fat": "32",
    "saturated": "1",
    "cholesterol": "0",
    "sodium": "160",
    "carbs": "11",
    "fibre": "8",
    "sugar": "0",
    "protein": "6",
    "cal-from-protein": "24",
    "per-cal-from-protein": "24",
    "potassium": "85",
    "calcium": "72",
    "iron": "2",
    "source": "https://www.amazon.com/Doctor-Kitchen-Flackers-Crackers-Flaxseed/dp/B0078DQ7PE?th=1",
    "old units (ref)": "serving"
  },
  {
    "id": "722",
    "type": "meal",
    "|| name                                               ||": "Seitan, fried gluten",
    "rating": "3",
    "high-iron": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "|| info                                                             ||": "1 palm-size portion",
    "info-portion-aid": "3\" x 3\" x 1\"",
    "weight-g": "100",
    "calories": "145.4",
    "fat": "2.4",
    "cal-from-fat": "21.6",
    "per-cal-from-fat": "15",
    "saturated": "0.24",
    "cholesterol": "0",
    "sodium": "417",
    "carbs": "11.9",
    "fibre": "1.2",
    "sugar": "3.57",
    "protein": "19.05",
    "cal-from-protein": "76",
    "per-cal-from-protein": "52",
    "calcium": "48",
    "iron": "2.14",
    "source": "US FDA",
    "old units (ref)": "piece"
  },
  {
    "id": "316",
    "type": "meal",
    "|| name                                               ||": "Seolleongtang / Sokkoritang",
    "rating": "2",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Korean ox oxtail soup",
    "info-translation": "Korean oxtail soup",
    "weight-g": "375",
    "calories": "291",
    "fat": "13.1",
    "cal-from-fat": "117.9",
    "per-cal-from-fat": "41",
    "saturated": "8.2",
    "polyunsaturated": "3.9",
    "monounsaturated": "11",
    "cholesterol": "41",
    "sodium": "698",
    "carbs": "8.1",
    "fibre": "1.8",
    "sugar": "2.9",
    "protein": "13.1",
    "cal-from-protein": "52",
    "per-cal-from-protein": "18",
    "potassium": "310",
    "calcium": "43",
    "iron": "2.3",
    "phosphorus": "33",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "317",
    "type": "meal",
    "|| name                                               ||": "Shabu shabu",
    "rating": "2",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "white-meat": "1",
    "serving": "3",
    "|| unit ||": "piece",
    "|| info                                                             ||": "pork / beef / lamb",
    "info-examples": "pork, beef, lamb",
    "weight-g": "90",
    "calories": "159.6",
    "fat": "8.8",
    "cal-from-fat": "79.2",
    "per-cal-from-fat": "50",
    "saturated": "4.5",
    "polyunsaturated": "0.3",
    "monounsaturated": "4",
    "cholesterol": "64",
    "sodium": "91",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "20.1",
    "cal-from-protein": "80",
    "per-cal-from-protein": "50",
    "potassium": "288",
    "calcium": "15",
    "iron": "1.6",
    "phosphorus": "288",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "647",
    "disabled": "1",
    "type": "meal",
    "|| name                                               ||": "Shanghai green, stir-fried",
    "rating": "3",
    "high-calcium": "1",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "|| info                                                             ||": "Bok choy, stir-fried",
    "info-translation": "Bok Choy",
    "weight-g": "100",
    "calories": "91.7",
    "fat": "6.5",
    "cal-from-fat": "58.5",
    "per-cal-from-fat": "64",
    "saturated": "0.7",
    "polyunsaturated": "2.8",
    "monounsaturated": "0.8",
    "sodium": "350",
    "carbs": "5.8",
    "fibre": "1.9",
    "sugar": "3.6",
    "protein": "2.5",
    "cal-from-protein": "10",
    "per-cal-from-protein": "11",
    "potassium": "300",
    "calcium": "110",
    "iron": "0.4",
    "phosphorus": "7",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "914",
    "type": "meal",
    "|| name                                               ||": "Shark fin dumpling, steamed",
    "rating": "2",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "27",
    "calories": "128.2",
    "fat": "2.2",
    "cal-from-fat": "19.8",
    "per-cal-from-fat": "15",
    "saturated": "0.9",
    "polyunsaturated": "0.3",
    "monounsaturated": "0.9",
    "cholesterol": "9",
    "sodium": "423",
    "carbs": "24",
    "fibre": "0.3",
    "sugar": "0.5",
    "protein": "3.1",
    "cal-from-protein": "12",
    "per-cal-from-protein": "10",
    "potassium": "28",
    "calcium": "7",
    "iron": "0",
    "phosphorus": "19",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "1001",
    "type": "snack",
    "|| name                                               ||": "Shaved ice dessert with toppings",
    "rating": "2",
    "high-gi": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": " e.g. Snow ice with mango, lychee, mocha, almond",
    "info-translation": "Snow ice",
    "info-examples": "mango, lychee, mocha, almond",
    "weight-g": "370",
    "calories": "131.3",
    "fat": "1.7",
    "cal-from-fat": "15.3",
    "per-cal-from-fat": "12",
    "saturated": "0.6",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.4",
    "cholesterol": "2",
    "sodium": "29",
    "carbs": "23.7",
    "fibre": "2.3",
    "sugar": "21.4",
    "protein": "5.3",
    "cal-from-protein": "21",
    "per-cal-from-protein": "16",
    "potassium": "207",
    "calcium": "98",
    "iron": "0.4",
    "phosphorus": "74",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "517",
    "type": "meal",
    "|| name                                               ||": "Shepherd's pie, with potato",
    "rating": "2",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "250g",
    "weight-g": "250",
    "calories": "290",
    "fat": "13",
    "cal-from-fat": "117",
    "per-cal-from-fat": "40",
    "saturated": "7.6",
    "polyunsaturated": "0.8",
    "monounsaturated": "4.3",
    "trans": "0.4",
    "cholesterol": "25",
    "sodium": "1155",
    "carbs": "27.5",
    "fibre": "4.5",
    "sugar": "5.8",
    "protein": "16",
    "cal-from-protein": "64",
    "per-cal-from-protein": "22",
    "potassium": "1108",
    "calcium": "98",
    "iron": "3.3",
    "phosphorus": "195",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "922",
    "type": "meal",
    "|| name                                               ||": "Shiitake mushroom with fish paste",
    "rating": "3",
    "high-iodine": "1",
    "high-potassium": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "25",
    "calories": "34",
    "fat": "2.8",
    "cal-from-fat": "25.2",
    "per-cal-from-fat": "74",
    "saturated": "0.9",
    "polyunsaturated": "1.2",
    "monounsaturated": "0.8",
    "cholesterol": "1",
    "sodium": "70",
    "carbs": "1",
    "fibre": "0.3",
    "sugar": "0",
    "protein": "1.2",
    "cal-from-protein": "5",
    "per-cal-from-protein": "14",
    "potassium": "59",
    "calcium": "4",
    "iron": "0.2",
    "phosphorus": "9",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "308",
    "type": "meal",
    "|| name                                               ||": "Shio ramen with char siew",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "676",
    "calories": "622",
    "fat": "25.5",
    "cal-from-fat": "229.5",
    "per-cal-from-fat": "37",
    "saturated": "9.5",
    "sodium": "3137",
    "carbs": "59.5",
    "fibre": "2",
    "protein": "39.2",
    "cal-from-protein": "157",
    "per-cal-from-protein": "25",
    "source": "HPB",
    "old units (ref)": "bowl"
  },
  {
    "id": "662",
    "type": "meal",
    "|| name                                               ||": "Shitake mushroom",
    "rating": "3",
    "high-potassium": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "100",
    "calories": "65.4",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "3",
    "saturated": "0.1",
    "polyunsaturated": "0",
    "monounsaturated": "0.1",
    "cholesterol": "0",
    "sodium": "4",
    "carbs": "14.3",
    "fibre": "2.3",
    "sugar": "0",
    "protein": "1.6",
    "cal-from-protein": "6",
    "per-cal-from-protein": "10",
    "potassium": "117",
    "calcium": "3",
    "iron": "0.4",
    "phosphorus": "29",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "855",
    "type": "meal",
    "|| name                                               ||": "Shoyu ramen with char siew",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "alternate-spellings": "Char: Cha \r\nSiew: Shao, Siu",
    "weight-g": "659",
    "calories": "633",
    "fat": "27.9",
    "cal-from-fat": "251.1",
    "per-cal-from-fat": "40",
    "saturated": "10.2",
    "sodium": "3124",
    "carbs": "61.3",
    "fibre": "1.3",
    "protein": "33.6",
    "cal-from-protein": "134",
    "per-cal-from-protein": "21",
    "source": "HPB",
    "old units (ref)": "bowl"
  },
  {
    "id": "923",
    "type": "meal",
    "|| name                                               ||": "Shrimp / pork wanton, fried",
    "rating": "2",
    "fried": "1",
    "high-cholesterol": "1",
    "high-purine": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "13",
    "calories": "55.1",
    "fat": "3.9",
    "cal-from-fat": "35.1",
    "per-cal-from-fat": "64",
    "saturated": "1.8",
    "polyunsaturated": "0.6",
    "monounsaturated": "1.3",
    "cholesterol": "4",
    "sodium": "96",
    "carbs": "3.5",
    "fibre": "0.2",
    "sugar": "0",
    "protein": "1.5",
    "cal-from-protein": "6",
    "per-cal-from-protein": "11",
    "calcium": "5",
    "iron": "0.1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "990",
    "type": "meal",
    "|| name                                               ||": "Shrimp and pork wanton, boiled",
    "rating": "3",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-purine": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "alternate-spellings": "Wanton: Wonton",
    "weight-g": "14",
    "calories": "19",
    "fat": "1",
    "cal-from-fat": "9",
    "per-cal-from-fat": "47",
    "saturated": "0.4",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.4",
    "cholesterol": "3",
    "sodium": "48",
    "carbs": "1.7",
    "fibre": "0.1",
    "sugar": "0",
    "protein": "0.8",
    "cal-from-protein": "3",
    "per-cal-from-protein": "17",
    "calcium": "2",
    "iron": "0.1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "909",
    "type": "meal",
    "|| name                                               ||": "Siew mai, steamed (dim sum)",
    "rating": "2",
    "high-purine": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Dim sum, shumai",
    "info-translation": "Shumai",
    "alternate-spellings": "Siew: Shao, Shu",
    "weight-g": "16",
    "calories": "28.5",
    "fat": "1.3",
    "cal-from-fat": "11.7",
    "per-cal-from-fat": "41",
    "saturated": "0.4",
    "polyunsaturated": "0.3",
    "monounsaturated": "0.5",
    "cholesterol": "4",
    "sodium": "99",
    "carbs": "2.8",
    "fibre": "0.3",
    "sugar": "1.2",
    "protein": "1.4",
    "cal-from-protein": "6",
    "per-cal-from-protein": "20",
    "potassium": "12",
    "calcium": "7",
    "iron": "0.1",
    "phosphorus": "12",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "788",
    "type": "meal",
    "|| name                                               ||": "Sin chow beehoon",
    "rating": "2",
    "high-calcium": "1",
    "high-cholesterol": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "alternate-spellings": "Chow: Chew",
    "weight-g": "463",
    "calories": "701.18",
    "fat": "25.3",
    "cal-from-fat": "227.7",
    "per-cal-from-fat": "32",
    "saturated": "8.2",
    "cholesterol": "128.89",
    "sodium": "1318.82",
    "carbs": "93.61",
    "fibre": "6.67",
    "protein": "24.76",
    "cal-from-protein": "99",
    "per-cal-from-protein": "14",
    "source": "HPB",
    "old units (ref)": "plate"
  },
  {
    "id": "640",
    "disabled": "1",
    "type": "meal",
    "|| name                                               ||": "Sio peck chye (stir-fied)",
    "rating": "3",
    "high-calcium": "1",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "100",
    "calories": "63.9",
    "fat": "3.9",
    "cal-from-fat": "35.1",
    "per-cal-from-fat": "55",
    "saturated": "0.6",
    "polyunsaturated": "2.4",
    "monounsaturated": "0.7",
    "sodium": "304.3",
    "carbs": "5",
    "fibre": "1.7",
    "sugar": "3.1",
    "protein": "2.2",
    "cal-from-protein": "9",
    "per-cal-from-protein": "14",
    "potassium": "260.9",
    "calcium": "95.7",
    "iron": "0.3",
    "phosphorus": "6.1",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "428",
    "type": "meal",
    "|| name                                               ||": "Sliced fish soup with milk",
    "rating": "3",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "with milk",
    "weight-g": "550",
    "calories": "203",
    "fat": "8",
    "cal-from-fat": "72",
    "per-cal-from-fat": "35",
    "saturated": "3.9",
    "polyunsaturated": "0.7",
    "monounsaturated": "3.2",
    "cholesterol": "30",
    "sodium": "1442",
    "carbs": "9",
    "fibre": "3.4",
    "sugar": "3.2",
    "protein": "24",
    "cal-from-protein": "96",
    "per-cal-from-protein": "47",
    "potassium": "485",
    "calcium": "125",
    "iron": "0.8",
    "phosphorus": "272",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "1055",
    "type": "meal",
    "|| name                                               ||": "Sliced fish soup, without milk",
    "rating": "3",
    "healthy-fats": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "without milk",
    "weight-g": "500",
    "calories": "152.4",
    "fat": "4.8",
    "cal-from-fat": "43.2",
    "per-cal-from-fat": "28",
    "saturated": "2.1",
    "polyunsaturated": "0.6",
    "monounsaturated": "1",
    "cholesterol": "21",
    "sodium": "1188",
    "carbs": "6",
    "fibre": "3.1",
    "sugar": "1.6",
    "protein": "21.3",
    "cal-from-protein": "85",
    "per-cal-from-protein": "56",
    "potassium": "1957",
    "calcium": "94",
    "iron": "0.2",
    "phosphorus": "237",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "318",
    "type": "meal",
    "|| name                                               ||": "Sliced fish soup with noodles / rice and milk",
    "rating": "2",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "with milk",
    "weight-g": "780",
    "calories": "400",
    "fat": "12",
    "cal-from-fat": "108",
    "per-cal-from-fat": "27",
    "saturated": "4.5",
    "polyunsaturated": "2",
    "monounsaturated": "2.9",
    "cholesterol": "26",
    "sodium": "1544",
    "carbs": "51.2",
    "fibre": "3.2",
    "sugar": "9.4",
    "protein": "25",
    "cal-from-protein": "100",
    "per-cal-from-protein": "25",
    "potassium": "306",
    "calcium": "153",
    "iron": "2.8",
    "phosphorus": "246",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "1054",
    "type": "meal",
    "|| name                                               ||": "Sliced fish soup with noodles / rice, without milk",
    "rating": "2",
    "healthy-fats": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "without milk",
    "weight-g": "686",
    "calories": "350.7",
    "fat": "7.5",
    "cal-from-fat": "67.5",
    "per-cal-from-fat": "19",
    "saturated": "3.4",
    "polyunsaturated": "0.7",
    "monounsaturated": "3",
    "cholesterol": "27",
    "sodium": "1422",
    "carbs": "48.2",
    "fibre": "4.6",
    "sugar": "0",
    "protein": "22.6",
    "cal-from-protein": "90",
    "per-cal-from-protein": "26",
    "potassium": "384",
    "calcium": "47",
    "iron": "0.8",
    "phosphorus": "192",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "501",
    "type": "meal",
    "|| name                                               ||": "Smoked sausage",
    "rating": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. Chorizo, Spanish smoked pork sausage",
    "info-examples": "chorizo",
    "weight-g": "68",
    "calories": "203.316",
    "fat": "19.2",
    "cal-from-fat": "172.8",
    "per-cal-from-fat": "85",
    "saturated": "6.33",
    "polyunsaturated": "2.47",
    "monounsaturated": "7.62",
    "cholesterol": "41.5",
    "sodium": "562",
    "carbs": "0.629",
    "fibre": "0",
    "sugar": "0.639",
    "protein": "7",
    "cal-from-protein": "28",
    "per-cal-from-protein": "14",
    "potassium": "328",
    "calcium": "7.48",
    "iron": "0.401",
    "phosphorus": "107",
    "source": "USDA",
    "old units (ref)": "portion"
  },
  {
    "id": "17",
    "type": "drink",
    "|| name                                               ||": "Smoothie",
    "rating": "1",
    "high-calcium": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-sugar": "1",
    "probiotic": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "made with fruit and yogurt",
    "info-include-exclude": "with fruit and yogurt",
    "volume-ml": "355",
    "calories": "225.094",
    "fat": "0.486",
    "cal-from-fat": "4.374",
    "per-cal-from-fat": "2",
    "saturated": "0",
    "polyunsaturated": "0.18",
    "monounsaturated": "0.17",
    "cholesterol": "3.47",
    "sodium": "48.6",
    "carbs": "52.2",
    "fibre": "3.12",
    "sugar": "44",
    "protein": "2.98",
    "cal-from-protein": "12",
    "per-cal-from-protein": "5",
    "potassium": "455",
    "calcium": "69.4",
    "iron": "0.486",
    "phosphorus": "38.2",
    "iodine": "1",
    "source": "USDA",
    "old units (ref)": "cup"
  },
  {
    "id": "141",
    "type": "meal",
    "|| name                                               ||": "Soba, noodles only",
    "rating": "2",
    "high-gi": "1",
    "high-phosphate": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "noodles only",
    "info-translation": "Buckwheat noodles",
    "weight-g": "275",
    "calories": "171",
    "fat": "1.9",
    "cal-from-fat": "17.1",
    "per-cal-from-fat": "10",
    "saturated": "0.8",
    "polyunsaturated": "0.3",
    "monounsaturated": "0.8",
    "cholesterol": "0",
    "sodium": "558",
    "carbs": "28.1",
    "fibre": "2.8",
    "sugar": "3",
    "protein": "9.9",
    "cal-from-protein": "40",
    "per-cal-from-protein": "23",
    "potassium": "83",
    "calcium": "44",
    "iron": "2.5",
    "phosphorus": "77",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "868",
    "type": "meal",
    "|| name                                               ||": "Soba with soup and egg / mushrooms / vegetables",
    "rating": "3",
    "high-cholesterol": "1",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "630",
    "calories": "332",
    "fat": "6",
    "cal-from-fat": "54",
    "per-cal-from-fat": "16",
    "saturated": "1",
    "polyunsaturated": "1",
    "monounsaturated": "2",
    "cholesterol": "207",
    "sodium": "2612",
    "carbs": "50",
    "fibre": "0",
    "sugar": "16",
    "protein": "15",
    "cal-from-protein": "60",
    "per-cal-from-protein": "18",
    "potassium": "162",
    "calcium": "109",
    "iron": "2",
    "phosphorus": "176",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "869",
    "type": "meal",
    "|| name                                               ||": "Soba, with soup and meat",
    "rating": "2",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "630",
    "calories": "350",
    "fat": "4",
    "cal-from-fat": "36",
    "per-cal-from-fat": "10",
    "saturated": "1",
    "polyunsaturated": "0",
    "monounsaturated": "3",
    "cholesterol": "37",
    "sodium": "2641",
    "carbs": "50",
    "fibre": "0",
    "sugar": "16",
    "protein": "24",
    "cal-from-protein": "96",
    "per-cal-from-protein": "27",
    "potassium": "280",
    "calcium": "92",
    "iron": "3",
    "phosphorus": "211",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "870",
    "type": "meal",
    "|| name                                               ||": "Soba, with soup with seafood",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "630",
    "calories": "277",
    "fat": "1",
    "cal-from-fat": "9",
    "per-cal-from-fat": "3",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "sodium": "2746",
    "carbs": "52",
    "fibre": "0",
    "sugar": "16",
    "protein": "11",
    "cal-from-protein": "44",
    "per-cal-from-protein": "16",
    "potassium": "149",
    "calcium": "98",
    "iron": "1",
    "phosphorus": "106",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "163",
    "type": "drink",
    "|| name                                               ||": "Soft drink, diet",
    "rating": "2",
    "high-phosphate": "1",
    "serving": "1",
    "|| unit ||": "can",
    "|| info                                                             ||": "e.g. Coke zero, Coke light, Pepsi black",
    "info-brands": "Coke zero, Coke light, Pepsi black, 7-Up Diet",
    "volume-ml": "330",
    "calories": "2",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "36",
    "carbs": "0.3",
    "fibre": "0",
    "sugar": "0",
    "protein": "0.2",
    "cal-from-protein": "1",
    "per-cal-from-protein": "40",
    "potassium": "10",
    "phosphorus": "24",
    "source": "N Buddy",
    "old units (ref)": "can"
  },
  {
    "id": "8",
    "type": "drink",
    "|| name                                               ||": "Soft drink, regular",
    "rating": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "can",
    "|| info                                                             ||": "e.g. coke, root beer, 7-up",
    "info-brands": "Coke, Pepsi, 7-Up",
    "volume-ml": "355",
    "calories": "161.525",
    "fat": "0.925",
    "cal-from-fat": "8.325",
    "per-cal-from-fat": "5",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "11.1",
    "carbs": "38.3",
    "fibre": "0",
    "sugar": "36.8",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "18.5",
    "calcium": "3.7",
    "iron": "0.074",
    "phosphorus": "33.3",
    "source": "USDA",
    "old units (ref)": "can"
  },
  {
    "id": "816",
    "type": "snack",
    "|| name                                               ||": "Soft-serve ice cream on cone",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cone",
    "weight-g": "120",
    "calories": "196",
    "fat": "5.8",
    "cal-from-fat": "52.2",
    "per-cal-from-fat": "27",
    "saturated": "3.1",
    "polyunsaturated": "0.4",
    "monounsaturated": "1.5",
    "trans": "0.3",
    "cholesterol": "18",
    "sodium": "97",
    "carbs": "31.6",
    "fibre": "0.1",
    "sugar": "23.4",
    "protein": "5.1",
    "cal-from-protein": "20",
    "per-cal-from-protein": "10",
    "potassium": "232",
    "calcium": "155",
    "iron": "0.4",
    "phosphorus": "133",
    "source": "USDA",
    "old units (ref)": "cone"
  },
  {
    "id": "817",
    "type": "snack",
    "|| name                                               ||": "Soft-serve ice cream with sauce / topping",
    "rating": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "e.g. chocolate/ caramel/ strawberry sundae",
    "info-examples": "chocolate / caramel / strawberry sundae",
    "weight-g": "165",
    "calories": "333",
    "fat": "10",
    "cal-from-fat": "90",
    "per-cal-from-fat": "27",
    "saturated": "6.1",
    "polyunsaturated": "0.4",
    "monounsaturated": "2.6",
    "trans": "0.4",
    "cholesterol": "22",
    "sodium": "180",
    "carbs": "54.9",
    "fibre": "0.4",
    "sugar": "49.8",
    "protein": "6.6",
    "cal-from-protein": "26",
    "per-cal-from-protein": "8",
    "potassium": "205",
    "calcium": "161",
    "iron": "1",
    "phosphorus": "238",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "911",
    "type": "meal",
    "|| name                                               ||": "Soon kueh, steamed",
    "rating": "2",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "alternate-spellings": "Kueh: Kuih, Kue",
    "weight-g": "72",
    "calories": "71.2",
    "fat": "1.2",
    "cal-from-fat": "10.8",
    "per-cal-from-fat": "15",
    "saturated": "0.6",
    "polyunsaturated": "0.1",
    "monounsaturated": "4.1",
    "cholesterol": "1",
    "sodium": "430",
    "carbs": "14.2",
    "fibre": "1.3",
    "sugar": "1",
    "protein": "0.9",
    "cal-from-protein": "4",
    "per-cal-from-protein": "5",
    "potassium": "158",
    "calcium": "15",
    "iron": "1.5",
    "phosphorus": "93",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "875",
    "type": "meal",
    "|| name                                               ||": "Soondubu Jjigae, meat",
    "rating": "3",
    "high-calcium": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Korean spicy tofu soup, with meat",
    "info-translation": "Korean spicy tofu soup",
    "alternate-spellings": "Soondubu: Sundubu",
    "weight-g": "629",
    "calories": "363.5",
    "fat": "20.3",
    "cal-from-fat": "182.7",
    "per-cal-from-fat": "50",
    "saturated": "7",
    "polyunsaturated": "4.9",
    "monounsaturated": "7.1",
    "cholesterol": "78",
    "sodium": "2407",
    "carbs": "10.7",
    "fibre": "8.8",
    "sugar": "9.7",
    "protein": "34.5",
    "cal-from-protein": "138",
    "per-cal-from-protein": "38",
    "potassium": "863",
    "calcium": "183",
    "iron": "5.2",
    "phosphorus": "254",
    "source": "N Buddy",
    "old units (ref)": "medium bowl"
  },
  {
    "id": "733",
    "type": "snack",
    "|| name                                               ||": "Sotong / squid, fried",
    "rating": "2",
    "fried": "1",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "serving": "0.7",
    "|| unit ||": "cup",
    "|| info                                                             ||": "1 palm-size portion",
    "weight-g": "100g",
    "calories": "200.2",
    "fat": "9",
    "cal-from-fat": "81",
    "per-cal-from-fat": "40",
    "saturated": "2.02",
    "polyunsaturated": "4.66",
    "monounsaturated": "4.38",
    "cholesterol": "242",
    "sodium": "382",
    "carbs": "12.9",
    "fibre": "0.6",
    "sugar": "0.91",
    "protein": "16.9",
    "cal-from-protein": "68",
    "per-cal-from-protein": "34",
    "potassium": "259",
    "calcium": "60",
    "iron": "1.47",
    "phosphorus": "238",
    "source": "US FDA",
    "old units (ref)": "portion"
  },
  {
    "id": "78",
    "type": "snack",
    "|| name                                               ||": "Souffle pancake with whipped cream and syrup",
    "rating": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "|| info                                                             ||": "with whipped cream and maple syrup",
    "weight-g": "151",
    "calories": "390.6",
    "fat": "22.2",
    "cal-from-fat": "199.8",
    "per-cal-from-fat": "51",
    "saturated": "11.5",
    "polyunsaturated": "0.8",
    "monounsaturated": "3.6",
    "cholesterol": "180",
    "sodium": "149",
    "carbs": "42.4",
    "fibre": "0.4",
    "sugar": "33.4",
    "protein": "5.3",
    "cal-from-protein": "21",
    "per-cal-from-protein": "5",
    "potassium": "93",
    "calcium": "74",
    "iron": "0.8",
    "phosphorus": "54",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "229",
    "type": "snack",
    "|| name                                               ||": "Souffle pancake, plain",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "no topping",
    "weight-g": "83",
    "calories": "184",
    "fat": "8.8",
    "cal-from-fat": "79.2",
    "per-cal-from-fat": "43",
    "saturated": "3.5",
    "polyunsaturated": "0.8",
    "monounsaturated": "3.6",
    "cholesterol": "140",
    "sodium": "134",
    "carbs": "20.9",
    "fibre": "0.4",
    "sugar": "11.9",
    "protein": "5.3",
    "cal-from-protein": "21",
    "per-cal-from-protein": "12",
    "potassium": "58",
    "calcium": "52",
    "iron": "0.6",
    "phosphorus": "54",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "429",
    "type": "meal",
    "|| name                                               ||": "Soup / broth, clear, with ingredients",
    "rating": "3",
    "high-iron": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "with ingredients, e.g. soup with chicken, carrot, onion bits",
    "info-examples": "chicken, carrot, onion, corn",
    "weight-g": "285",
    "calories": "143.42",
    "fat": "6.3",
    "cal-from-fat": "56.7",
    "per-cal-from-fat": "40",
    "saturated": "1.19",
    "polyunsaturated": "1.61",
    "monounsaturated": "2.42",
    "cholesterol": "59.8",
    "sodium": "1150",
    "carbs": "6.18",
    "fibre": "1.71",
    "sugar": "2.59",
    "protein": "15.5",
    "cal-from-protein": "62",
    "per-cal-from-protein": "43",
    "potassium": "351",
    "calcium": "54.2",
    "iron": "0.884",
    "phosphorus": "154",
    "source": "USDA",
    "old units (ref)": "bowl"
  },
  {
    "id": "430",
    "type": "meal",
    "|| name                                               ||": "Soup / broth, clear, without ingredients",
    "rating": "3",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "no ingredients, e.g. chicken broth only",
    "weight-g": "249",
    "calories": "50",
    "fat": "0.523",
    "cal-from-fat": "4.707",
    "per-cal-from-fat": "9",
    "saturated": "0.032",
    "polyunsaturated": "0.022",
    "monounsaturated": "0.047",
    "cholesterol": "4.98",
    "sodium": "924",
    "carbs": "1.1",
    "fibre": "0",
    "sugar": "1.07",
    "protein": "1.59",
    "cal-from-protein": "6",
    "per-cal-from-protein": "13",
    "potassium": "44.8",
    "calcium": "9.96",
    "iron": "0.174",
    "phosphorus": "9.96",
    "source": "USDA",
    "old units (ref)": "bowl"
  },
  {
    "id": "142",
    "type": "meal",
    "|| name                                               ||": "Soup with cream",
    "rating": "2",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "e.g. cream of broccoli soup, mushroom soup",
    "info-examples": "broccoli, mushroom, pumpkin / squash, corn",
    "weight-g": "246",
    "calories": "149",
    "fat": "5",
    "cal-from-fat": "45",
    "per-cal-from-fat": "30",
    "saturated": "2",
    "polyunsaturated": "1",
    "monounsaturated": "1",
    "cholesterol": "10",
    "sodium": "620",
    "carbs": "20",
    "fibre": "1",
    "sugar": "2",
    "protein": "6",
    "cal-from-protein": "24",
    "per-cal-from-protein": "16",
    "potassium": "261",
    "calcium": "39",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "752",
    "type": "meal",
    "|| name                                               ||": "Soup with bittergourd and sliced fish",
    "rating": "3",
    "high-fibre": "1",
    "high-purine": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "450",
    "calories": "362.5",
    "fat": "7.7",
    "cal-from-fat": "69.3",
    "per-cal-from-fat": "19",
    "saturated": "3.5",
    "polyunsaturated": "0.8",
    "monounsaturated": "3",
    "cholesterol": "27",
    "sodium": "1416",
    "carbs": "50.3",
    "fibre": "4.4",
    "sugar": "0",
    "protein": "23",
    "cal-from-protein": "92",
    "per-cal-from-protein": "25",
    "potassium": "1944",
    "calcium": "53",
    "iron": "0.9",
    "phosphorus": "210",
    "source": "N Buddy",
    "old units (ref)": "medium bowl"
  },
  {
    "id": "756",
    "type": "meal",
    "|| name                                               ||": "Soup with black beans and chicken / pork",
    "rating": "3",
    "high-fibre": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "450",
    "calories": "147.1",
    "fat": "1.5",
    "cal-from-fat": "13.5",
    "per-cal-from-fat": "9",
    "saturated": "0.5",
    "polyunsaturated": "0.4",
    "monounsaturated": "0.4",
    "cholesterol": "27",
    "sodium": "295",
    "carbs": "16.6",
    "fibre": "6.4",
    "sugar": "1.6",
    "protein": "16.8",
    "cal-from-protein": "67",
    "per-cal-from-protein": "46",
    "potassium": "353",
    "calcium": "55",
    "iron": "5.2",
    "phosphorus": "152",
    "source": "N Buddy",
    "old units (ref)": "medium bowl"
  },
  {
    "id": "754",
    "type": "meal",
    "|| name                                               ||": "Soup, Buddha jumps over the wall",
    "rating": "3",
    "high-calcium": "1",
    "high-cholesterol": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Fo tiao qiang",
    "info-translation": "Fo Tiao Qiang",
    "weight-g": "450",
    "calories": "236.6",
    "fat": "3.8",
    "cal-from-fat": "34.2",
    "per-cal-from-fat": "14",
    "saturated": "1.1",
    "polyunsaturated": "0.6",
    "monounsaturated": "1",
    "cholesterol": "158",
    "sodium": "854",
    "carbs": "10.1",
    "fibre": "2.1",
    "sugar": "1.3",
    "protein": "40.5",
    "cal-from-protein": "162",
    "per-cal-from-protein": "68",
    "potassium": "726",
    "calcium": "155",
    "iron": "4.2",
    "phosphorus": "262",
    "source": "N Buddy",
    "old units (ref)": "medium bowl"
  },
  {
    "id": "753",
    "type": "meal",
    "|| name                                               ||": "Soup with carrot, potato, onion, pork / chicken",
    "rating": "3",
    "high-calcium": "1",
    "high-cholesterol": "1",
    "high-fibre": "1",
    "high-purine": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "ABC Soup",
    "info-examples": "ABC Soup",
    "weight-g": "450",
    "calories": "282",
    "fat": "9.2",
    "cal-from-fat": "82.8",
    "per-cal-from-fat": "29",
    "saturated": "3.8",
    "polyunsaturated": "1.5",
    "monounsaturated": "3.1",
    "cholesterol": "100",
    "sodium": "1563",
    "carbs": "19.2",
    "fibre": "6.1",
    "sugar": "1",
    "protein": "30.6",
    "cal-from-protein": "122",
    "per-cal-from-protein": "43",
    "calcium": "207",
    "iron": "2.3",
    "source": "N Buddy",
    "old units (ref)": "medium bowl"
  },
  {
    "id": "745",
    "type": "meal",
    "|| name                                               ||": "Soup with chicken and herbs",
    "rating": "3",
    "high-cholesterol": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "450",
    "calories": "183.7",
    "fat": "6.9",
    "cal-from-fat": "62.1",
    "per-cal-from-fat": "34",
    "saturated": "2.3",
    "polyunsaturated": "1",
    "monounsaturated": "3",
    "cholesterol": "150",
    "sodium": "1153",
    "carbs": "0.5",
    "fibre": "1.1",
    "sugar": "0",
    "protein": "29.9",
    "cal-from-protein": "120",
    "per-cal-from-protein": "65",
    "potassium": "390",
    "calcium": "32",
    "iron": "1.1",
    "phosphorus": "198",
    "source": "N Buddy",
    "old units (ref)": "medium bowl"
  },
  {
    "id": "744",
    "type": "meal",
    "|| name                                               ||": "Soup with duck and salted vegetables",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "251",
    "calories": "151.5",
    "fat": "9.9",
    "cal-from-fat": "89.1",
    "per-cal-from-fat": "59",
    "saturated": "2",
    "polyunsaturated": "0.8",
    "monounsaturated": "2.5",
    "cholesterol": "108",
    "sodium": "1170",
    "carbs": "0.5",
    "fibre": "0.3",
    "sugar": "0",
    "protein": "15.1",
    "cal-from-protein": "60",
    "per-cal-from-protein": "40",
    "potassium": "112",
    "calcium": "78",
    "iron": "1.5",
    "source": "N Buddy",
    "old units (ref)": "medium bowl"
  },
  {
    "id": "785",
    "type": "meal",
    "|| name                                               ||": "Soup with fish maw with egg",
    "rating": "2",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "308",
    "calories": "106.9",
    "fat": "3.3",
    "cal-from-fat": "29.7",
    "per-cal-from-fat": "28",
    "saturated": "0.5",
    "polyunsaturated": "0.8",
    "monounsaturated": "1.3",
    "cholesterol": "23",
    "sodium": "340",
    "carbs": "0.7",
    "fibre": "0.2",
    "sugar": "0.6",
    "protein": "18.6",
    "cal-from-protein": "74",
    "per-cal-from-protein": "70",
    "potassium": "184",
    "calcium": "44",
    "iron": "0.7",
    "phosphorus": "68",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "755",
    "type": "meal",
    "|| name                                               ||": "Soup with herbal black chicken",
    "rating": "3",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "450",
    "calories": "219.6",
    "fat": "10.8",
    "cal-from-fat": "97.2",
    "per-cal-from-fat": "44",
    "saturated": "3.8",
    "polyunsaturated": "2.1",
    "monounsaturated": "3.8",
    "cholesterol": "1.5",
    "sodium": "1046",
    "carbs": "7.1",
    "fibre": "0.4",
    "sugar": "3",
    "protein": "23.5",
    "cal-from-protein": "94",
    "per-cal-from-protein": "43",
    "calcium": "34",
    "iron": "1.7",
    "source": "N Buddy",
    "old units (ref)": "medium bowl"
  },
  {
    "id": "746",
    "type": "meal",
    "|| name                                               ||": "Soup with lotus root and pork ribs / chicken",
    "rating": "3",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "497",
    "calories": "114.3",
    "fat": "2.3",
    "cal-from-fat": "20.7",
    "per-cal-from-fat": "18",
    "saturated": "3.8",
    "polyunsaturated": "1.5",
    "monounsaturated": "3.1",
    "cholesterol": "80",
    "sodium": "875",
    "carbs": "9",
    "fibre": "4.5",
    "sugar": "0",
    "protein": "14.4",
    "cal-from-protein": "58",
    "per-cal-from-protein": "50",
    "calcium": "89",
    "iron": "1.5",
    "source": "N Buddy",
    "old units (ref)": "medium bowl"
  },
  {
    "id": "748",
    "type": "meal",
    "|| name                                               ||": "Soup with peanuts and pork ribs / chicken",
    "rating": "3",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "450",
    "calories": "343.1",
    "fat": "20.7",
    "cal-from-fat": "186.3",
    "per-cal-from-fat": "54",
    "saturated": "5.4",
    "polyunsaturated": "6.8",
    "monounsaturated": "7.7",
    "cholesterol": "77",
    "sodium": "981",
    "carbs": "4.5",
    "fibre": "7.7",
    "sugar": "0",
    "protein": "34.7",
    "cal-from-protein": "139",
    "per-cal-from-protein": "40",
    "calcium": "68",
    "iron": "2.3",
    "source": "N Buddy",
    "old units (ref)": "medium bowl"
  },
  {
    "id": "747",
    "type": "meal",
    "|| name                                               ||": "Soup with pig stomach and pepper",
    "rating": "2",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "313",
    "calories": "139.6",
    "fat": "8",
    "cal-from-fat": "72",
    "per-cal-from-fat": "52",
    "saturated": "1.2",
    "polyunsaturated": "0.3",
    "monounsaturated": "0.9",
    "cholesterol": "38",
    "sodium": "632",
    "carbs": "0.3",
    "fibre": "0.6",
    "sugar": "0",
    "protein": "16.6",
    "cal-from-protein": "66",
    "per-cal-from-protein": "48",
    "potassium": "38",
    "calcium": "50",
    "iron": "1.6",
    "phosphorus": "135",
    "source": "N Buddy",
    "old units (ref)": "medium bowl"
  },
  {
    "id": "751",
    "type": "meal",
    "|| name                                               ||": "Soup with pork and crab meat ball",
    "rating": "3",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Bakwan kepitang",
    "info-translation": "Bakwan Kepitang",
    "weight-g": "450",
    "calories": "134.5",
    "fat": "8.1",
    "cal-from-fat": "72.9",
    "per-cal-from-fat": "54",
    "saturated": "3",
    "polyunsaturated": "1.3",
    "monounsaturated": "3.8",
    "cholesterol": "35",
    "sodium": "1511",
    "carbs": "0.5",
    "fibre": "1",
    "sugar": "0.5",
    "protein": "14.9",
    "cal-from-protein": "60",
    "per-cal-from-protein": "44",
    "potassium": "199",
    "calcium": "80",
    "iron": "0",
    "phosphorus": "124",
    "source": "N Buddy",
    "old units (ref)": "medium bowl"
  },
  {
    "id": "750",
    "type": "meal",
    "|| name                                               ||": "Soup with salted vegetables and tofu",
    "rating": "2",
    "high-calcium": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "450",
    "calories": "95",
    "fat": "5",
    "cal-from-fat": "45",
    "per-cal-from-fat": "47",
    "saturated": "0.8",
    "polyunsaturated": "2.7",
    "monounsaturated": "0.9",
    "cholesterol": "0",
    "sodium": "1628",
    "carbs": "1.7",
    "fibre": "1.7",
    "sugar": "1.3",
    "protein": "10.8",
    "cal-from-protein": "43",
    "per-cal-from-protein": "45",
    "potassium": "299",
    "calcium": "152",
    "iron": "1.3",
    "phosphorus": "117",
    "source": "N Buddy",
    "old units (ref)": "medium bowl"
  },
  {
    "id": "757",
    "type": "meal",
    "|| name                                               ||": "Soup, Szechuan hot and sour",
    "rating": "2",
    "high-cholesterol": "1",
    "high-purine": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "450",
    "calories": "193.7",
    "fat": "11.3",
    "cal-from-fat": "101.7",
    "per-cal-from-fat": "53",
    "saturated": "2.9",
    "polyunsaturated": "1.8",
    "monounsaturated": "4",
    "cholesterol": "79",
    "sodium": "1314",
    "carbs": "9",
    "fibre": "2.9",
    "sugar": "2.5",
    "protein": "14",
    "cal-from-protein": "56",
    "per-cal-from-protein": "29",
    "potassium": "119",
    "calcium": "58",
    "iron": "0",
    "phosphorus": "108",
    "source": "N Buddy",
    "old units (ref)": "medium bowl"
  },
  {
    "id": "784",
    "type": "meal",
    "|| name                                               ||": "Soup, tom yum seafood",
    "rating": "2",
    "high-calcium": "1",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-examples": "fish, prawn",
    "weight-g": "450",
    "calories": "193",
    "fat": "12.6",
    "cal-from-fat": "113.4",
    "per-cal-from-fat": "59",
    "saturated": "5.9",
    "polyunsaturated": "1.1",
    "monounsaturated": "4.9",
    "cholesterol": "162",
    "sodium": "1778",
    "carbs": "5",
    "fibre": "1.8",
    "protein": "14.9",
    "cal-from-protein": "60",
    "per-cal-from-protein": "31",
    "potassium": "333",
    "calcium": "167",
    "iron": "5",
    "phosphorus": "162",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "830",
    "type": "meal",
    "|| name                                               ||": "Soup, tomato bisque",
    "rating": "3",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "350",
    "calories": "277",
    "fat": "9.2",
    "cal-from-fat": "82.8",
    "per-cal-from-fat": "30",
    "saturated": "4.4",
    "polyunsaturated": "1.7",
    "monounsaturated": "2.6",
    "cholesterol": "32",
    "sodium": "1554",
    "carbs": "41",
    "fibre": "0.7",
    "protein": "8.8",
    "cal-from-protein": "35",
    "per-cal-from-protein": "13",
    "potassium": "847",
    "calcium": "260",
    "iron": "1.2",
    "phosphorus": "242",
    "source": "USDA",
    "old units (ref)": "bowl"
  },
  {
    "id": "749",
    "type": "meal",
    "|| name                                               ||": "Soup with watercress and pork ribs / chicken",
    "rating": "3",
    "high-purine": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "450",
    "calories": "116",
    "fat": "7.2",
    "cal-from-fat": "64.8",
    "per-cal-from-fat": "56",
    "saturated": "3.7",
    "polyunsaturated": "0.9",
    "monounsaturated": "2.3",
    "cholesterol": "41",
    "sodium": "1366",
    "carbs": "0",
    "fibre": "1.4",
    "sugar": "0",
    "protein": "15.1",
    "cal-from-protein": "60",
    "per-cal-from-protein": "52",
    "calcium": "49.2",
    "iron": "2.3",
    "source": "N Buddy",
    "old units (ref)": "medium bowl"
  },
  {
    "id": "871",
    "type": "meal",
    "|| name                                               ||": "Sour cream",
    "rating": "2",
    "high-iodine": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "12",
    "calories": "24.276",
    "fat": "2.32",
    "cal-from-fat": "20.88",
    "per-cal-from-fat": "86",
    "saturated": "1.22",
    "polyunsaturated": "0.096",
    "monounsaturated": "0.551",
    "trans": "0.096",
    "cholesterol": "7.08",
    "sodium": "3.72",
    "carbs": "0.556",
    "fibre": "0",
    "sugar": "0.409",
    "protein": "0.293",
    "cal-from-protein": "1",
    "per-cal-from-protein": "5",
    "potassium": "15",
    "calcium": "12.1",
    "iron": "0.008",
    "phosphorus": "9.12",
    "source": "USDA",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "402",
    "type": "snack",
    "|| name                                               ||": "Soursop",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "pulp",
    "info-include-exclude": "pulp",
    "weight-g": "225",
    "calories": "166.675",
    "fat": "0.675",
    "cal-from-fat": "6.075",
    "per-cal-from-fat": "4",
    "saturated": "0.115",
    "polyunsaturated": "0.155",
    "monounsaturated": "0.202",
    "cholesterol": "0",
    "sodium": "31.5",
    "carbs": "37.9",
    "fibre": "7.42",
    "sugar": "30.5",
    "protein": "2.25",
    "cal-from-protein": "9",
    "per-cal-from-protein": "5",
    "potassium": "626",
    "calcium": "31.5",
    "iron": "1.35",
    "phosphorus": "60.8",
    "source": "USDA",
    "old units (ref)": "portion"
  },
  {
    "id": "190",
    "type": "snack",
    "|| name                                               ||": "Soy beancurd, sweetened",
    "rating": "2",
    "high-calcium": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Tau Huay with sugar",
    "info-translation": "Tau Huay",
    "info-include-exclude": "with sugar",
    "alternate-spellings": "Tau: Dou\r\nHuay: Hua, Huey",
    "weight-g": "240",
    "calories": "141",
    "fat": "1.4",
    "cal-from-fat": "12.6",
    "per-cal-from-fat": "9",
    "saturated": "0.3",
    "polyunsaturated": "0.8",
    "monounsaturated": "0.3",
    "cholesterol": "0",
    "sodium": "14",
    "carbs": "26.6",
    "fibre": "0.7",
    "sugar": "24.7",
    "protein": "5.5",
    "cal-from-protein": "22",
    "per-cal-from-protein": "16",
    "potassium": "125",
    "calcium": "36",
    "iron": "0.5",
    "phosphorus": "29",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "165",
    "type": "drink",
    "|| name                                               ||": "Soy milk, sweetened (hawker centre)",
    "rating": "2",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "info-include-exclude": "with sugar",
    "alternate-spellings": "Soy: Soya",
    "volume-ml": "250",
    "calories": "139.2",
    "fat": "4",
    "cal-from-fat": "36",
    "per-cal-from-fat": "26",
    "saturated": "0.7",
    "polyunsaturated": "1.9",
    "monounsaturated": "0.8",
    "cholesterol": "0",
    "sodium": "35",
    "carbs": "19.3",
    "fibre": "1.5",
    "sugar": "19",
    "protein": "6.5",
    "cal-from-protein": "26",
    "per-cal-from-protein": "19",
    "potassium": "245",
    "calcium": "25",
    "iron": "1",
    "phosphorus": "120",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "361",
    "type": "drink",
    "|| name                                               ||": "Soy milk, unsweetened (hawker centre)",
    "rating": "3",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "info-include-exclude": "without sugar",
    "alternate-spellings": "Soy: Soya",
    "weight-g": "244",
    "calories": "83",
    "fat": "5",
    "cal-from-fat": "45",
    "per-cal-from-fat": "54",
    "saturated": "0.7",
    "polyunsaturated": "2.3",
    "monounsaturated": "0.9",
    "cholesterol": "0",
    "sodium": "30",
    "carbs": "3",
    "fibre": "2",
    "sugar": "2.8",
    "protein": "6.8",
    "cal-from-protein": "27",
    "per-cal-from-protein": "33",
    "potassium": "283",
    "calcium": "25",
    "iron": "1",
    "phosphorus": "130",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "320",
    "type": "meal",
    "|| name                                               ||": "Spaghetti bolognese",
    "rating": "2",
    "high-iron": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Spaghetti with minced meat sauce",
    "info-include-exclude": "with minced meat sauce",
    "weight-g": "554",
    "calories": "671.1",
    "fat": "19.9",
    "cal-from-fat": "179.1",
    "per-cal-from-fat": "27",
    "saturated": "5.88",
    "polyunsaturated": "2.84",
    "monounsaturated": "0.321",
    "trans": "0.044",
    "cholesterol": "49.9",
    "sodium": "1270",
    "carbs": "90.9",
    "fibre": "8.86",
    "sugar": "10.1",
    "protein": "32.1",
    "cal-from-protein": "128",
    "per-cal-from-protein": "19",
    "potassium": "953",
    "calcium": "94.2",
    "iron": "5.37",
    "phosphorus": "327",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "143",
    "type": "meal",
    "|| name                                               ||": "Spaghetti carbonara",
    "rating": "2",
    "high-cholesterol": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "360",
    "calories": "719.1",
    "fat": "36.3",
    "cal-from-fat": "326.7",
    "per-cal-from-fat": "45",
    "saturated": "18.7",
    "polyunsaturated": "5.3",
    "monounsaturated": "10.5",
    "cholesterol": "70",
    "sodium": "1367",
    "carbs": "73.3",
    "fibre": "2.7",
    "sugar": "5.2",
    "protein": "24.8",
    "cal-from-protein": "99",
    "per-cal-from-protein": "14",
    "potassium": "110",
    "calcium": "128",
    "iron": "0.5",
    "phosphorus": "138",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "724",
    "type": "meal",
    "|| name                                               ||": "Spelt / teff",
    "rating": "3",
    "plant-protein": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "1 cup = 240mL",
    "weight-g": "194",
    "calories": "246",
    "fat": "1.7",
    "cal-from-fat": "15.3",
    "per-cal-from-fat": "6",
    "sodium": "10",
    "carbs": "51.3",
    "fibre": "7.6",
    "protein": "10.7",
    "cal-from-protein": "43",
    "per-cal-from-protein": "17",
    "potassium": "277",
    "calcium": "19",
    "iron": "3.2",
    "phosphorus": "291",
    "source": "US FDA",
    "old units (ref)": "slice"
  },
  {
    "id": "676",
    "type": "meal",
    "|| name                                               ||": "Spinach, all types, stir-fried",
    "rating": "3",
    "high-calcium": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "100",
    "calories": "29.9",
    "fat": "0.3",
    "cal-from-fat": "2.7",
    "per-cal-from-fat": "9",
    "saturated": "2.3",
    "polyunsaturated": "0.5",
    "monounsaturated": "2.1",
    "cholesterol": "0",
    "sodium": "70",
    "carbs": "3.8",
    "fibre": "2.4",
    "sugar": "0",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "40",
    "potassium": "466",
    "calcium": "136",
    "iron": "3.6",
    "phosphorus": "56",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "719",
    "type": "snack",
    "|| name                                               ||": "Spirulina, powder",
    "rating": "3",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "plant-protein": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "15",
    "calories": "50.1",
    "fat": "0.5",
    "cal-from-fat": "4.5",
    "per-cal-from-fat": "9",
    "saturated": "0.4",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.2",
    "cholesterol": "0",
    "sodium": "146",
    "carbs": "3.4",
    "fibre": "0.6",
    "sugar": "0.4",
    "protein": "8",
    "cal-from-protein": "32",
    "per-cal-from-protein": "64",
    "potassium": "190",
    "calcium": "16",
    "iron": "4",
    "phosphorus": "16",
    "source": "N Buddy",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "900",
    "type": "snack",
    "|| name                                               ||": "Sponge cake with cream",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "42",
    "calories": "157",
    "fat": "4.9",
    "cal-from-fat": "44.1",
    "per-cal-from-fat": "28",
    "saturated": "1.7",
    "polyunsaturated": "0.8",
    "monounsaturated": "2.1",
    "trans": "0.7",
    "cholesterol": "17",
    "sodium": "197",
    "carbs": "26.9",
    "fibre": "0.4",
    "sugar": "15.7",
    "protein": "1.5",
    "cal-from-protein": "6",
    "per-cal-from-protein": "4",
    "potassium": "30",
    "calcium": "10",
    "iron": "0.6",
    "phosphorus": "78",
    "source": "USDA",
    "old units (ref)": "slice"
  },
  {
    "id": "98",
    "type": "meal",
    "|| name                                               ||": "Sponge cake, plain",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "|| info                                                             ||": "without cream",
    "weight-g": "38",
    "calories": "110",
    "fat": "1",
    "cal-from-fat": "9",
    "per-cal-from-fat": "8",
    "saturated": "0.3",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.4",
    "cholesterol": "39",
    "sodium": "237",
    "carbs": "23.2",
    "fibre": "0.2",
    "sugar": "13.9",
    "protein": "2.1",
    "cal-from-protein": "8",
    "per-cal-from-protein": "8",
    "potassium": "38",
    "calcium": "27",
    "iron": "1",
    "phosphorus": "52",
    "source": "USDA",
    "old units (ref)": "slice"
  },
  {
    "id": "619",
    "type": "drink",
    "|| name                                               ||": "Sports drink",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "serving": "1",
    "|| unit ||": "can",
    "|| info                                                             ||": "e.g. Gatorade, 100 Plus",
    "info-brands": "Gatorade, 100 Plus, Pocari",
    "volume-ml": "330",
    "calories": "88",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "139",
    "carbs": "22",
    "fibre": "0",
    "sugar": "22",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "63",
    "calcium": "13",
    "source": "N Buddy",
    "old units (ref)": "can"
  },
  {
    "id": "679",
    "disabled": "1",
    "type": "meal",
    "|| name                                               ||": "Spring onion",
    "rating": "3",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "calories": "32",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "16",
    "carbs": "7",
    "fibre": "2",
    "sugar": "2",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "13",
    "potassium": "276",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "42",
    "type": "snack",
    "|| name                                               ||": "Spring roll, fried",
    "rating": "2",
    "fried": "1",
    "high-fat": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "roll",
    "weight-g": "45",
    "calories": "139.4",
    "fat": "10.6",
    "cal-from-fat": "95.4",
    "per-cal-from-fat": "68",
    "saturated": "5.4",
    "polyunsaturated": "1",
    "monounsaturated": "3.8",
    "cholesterol": "24",
    "sodium": "232",
    "carbs": "8.4",
    "fibre": "0.6",
    "sugar": "0.8",
    "protein": "2.6",
    "cal-from-protein": "10",
    "per-cal-from-protein": "7",
    "potassium": "68",
    "calcium": "34",
    "iron": "0.5",
    "phosphorus": "32",
    "source": "N Buddy",
    "old units (ref)": "whole"
  },
  {
    "id": "675",
    "disabled": "1",
    "type": "meal",
    "|| name                                               ||": "Sprout",
    "rating": "3",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "100",
    "calories": "25.7",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "4",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "10",
    "carbs": "4.2",
    "fibre": "0.8",
    "sugar": "0",
    "protein": "2",
    "cal-from-protein": "8",
    "per-cal-from-protein": "31",
    "potassium": "101",
    "calcium": "12",
    "iron": "0.7",
    "phosphorus": "28",
    "old units (ref)": "serving"
  },
  {
    "id": "321",
    "type": "meal",
    "|| name                                               ||": "Sotong / squid, grilled or steamed",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "weight-g": "100",
    "calories": "147.6",
    "fat": "7.6",
    "cal-from-fat": "68.4",
    "per-cal-from-fat": "46",
    "saturated": "1.4",
    "polyunsaturated": "1.6",
    "monounsaturated": "3.7",
    "trans": "0.1",
    "cholesterol": "237",
    "sodium": "340",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "19.8",
    "cal-from-protein": "79",
    "per-cal-from-protein": "54",
    "potassium": "185",
    "calcium": "13",
    "iron": "1.5",
    "phosphorus": "306",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "403",
    "type": "snack",
    "|| name                                               ||": "Starfruit",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "serving": "1",
    "|| unit ||": "fruit",
    "|| info                                                             ||": "Carambola",
    "info-translation": "Carambola",
    "weight-g": "91",
    "calories": "30.964",
    "fat": "0.3",
    "cal-from-fat": "2.7",
    "per-cal-from-fat": "9",
    "saturated": "0.017",
    "polyunsaturated": "0.167",
    "monounsaturated": "0.027",
    "cholesterol": "0",
    "sodium": "1.82",
    "carbs": "6.12",
    "fibre": "2.55",
    "sugar": "3.62",
    "protein": "0.946",
    "cal-from-protein": "4",
    "per-cal-from-protein": "12",
    "potassium": "121",
    "calcium": "2.73",
    "iron": "0.073",
    "phosphorus": "10.9",
    "source": "USDA",
    "old units (ref)": "small"
  },
  {
    "id": "255",
    "type": "meal",
    "|| name                                               ||": "Steamboat, full meal",
    "rating": "2",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "1 big bowl",
    "weight-g": "400",
    "calories": "483",
    "fat": "21.4",
    "cal-from-fat": "192.6",
    "per-cal-from-fat": "40",
    "saturated": "8.1",
    "polyunsaturated": "5.2",
    "monounsaturated": "7.7",
    "cholesterol": "50",
    "sodium": "700",
    "carbs": "52",
    "fibre": "4.5",
    "sugar": "3",
    "protein": "20.6",
    "cal-from-protein": "82",
    "per-cal-from-protein": "17",
    "potassium": "300",
    "calcium": "140",
    "iron": "4.3",
    "phosphorus": "250",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "340",
    "type": "meal",
    "|| name                                               ||": "Steamboat, heavy meal",
    "rating": "2",
    "high-iodine": "1",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "red-meat": "1",
    "white-meat": "1",
    "serving": "1.5",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "1.5 big bowls",
    "weight-g": "683",
    "calories": "705",
    "fat": "30.5",
    "cal-from-fat": "274.5",
    "per-cal-from-fat": "39",
    "saturated": "11.7",
    "polyunsaturated": "7.3",
    "monounsaturated": "9.3",
    "cholesterol": "100",
    "sodium": "2205",
    "carbs": "68.7",
    "fibre": "13",
    "sugar": "4",
    "protein": "37.6",
    "cal-from-protein": "150",
    "per-cal-from-protein": "21",
    "potassium": "400",
    "calcium": "375",
    "iron": "11.5",
    "phosphorus": "300",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "439",
    "type": "meal",
    "|| name                                               ||": "Steamboat, light meal",
    "rating": "3",
    "high-iodine": "1",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "red-meat": "1",
    "vegetable": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "1 medium bowl",
    "weight-g": "351",
    "calories": "405.1",
    "fat": "15.5",
    "cal-from-fat": "139.5",
    "per-cal-from-fat": "34",
    "saturated": "5.2",
    "polyunsaturated": "3.8",
    "monounsaturated": "4.9",
    "cholesterol": "12",
    "sodium": "531",
    "carbs": "47.8",
    "fibre": "3",
    "sugar": "2.6",
    "protein": "18.6",
    "cal-from-protein": "74",
    "per-cal-from-protein": "18",
    "potassium": "269",
    "calcium": "100",
    "iron": "4",
    "phosphorus": "150",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "838",
    "type": "meal",
    "|| name                                               ||": "Steamed egg with tofu",
    "rating": "3",
    "high-calcium": "1",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "16cm plate",
    "weight-g": "132",
    "calories": "128",
    "fat": "9.9",
    "cal-from-fat": "89.1",
    "per-cal-from-fat": "70",
    "saturated": "2.6",
    "polyunsaturated": "2.6",
    "monounsaturated": "4.2",
    "cholesterol": "187",
    "sodium": "520",
    "carbs": "0.7",
    "fibre": "0.5",
    "sugar": "0.3",
    "protein": "9.1",
    "cal-from-protein": "36",
    "per-cal-from-protein": "28",
    "potassium": "125",
    "calcium": "36",
    "iron": "1.6",
    "phosphorus": "124",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "148",
    "type": "meal",
    "|| name                                               ||": "Steamed glutinous rice with meat",
    "rating": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. lor mai gai, glutinous rice in lotus leaf, fan cho",
    "info-translation": "Lor Mai Gai / Fan Choy",
    "alternate-spellings": "Lor: Lo\nGai: Kai\nChoy: Choi, Chye",
    "weight-g": "195",
    "calories": "422",
    "fat": "9.6",
    "cal-from-fat": "86.4",
    "per-cal-from-fat": "20",
    "saturated": "3.5",
    "polyunsaturated": "1.8",
    "monounsaturated": "3.7",
    "cholesterol": "43",
    "sodium": "778",
    "carbs": "72.2",
    "fibre": "8.8",
    "sugar": "3.3",
    "protein": "11.7",
    "cal-from-protein": "47",
    "per-cal-from-protein": "11",
    "potassium": "131",
    "calcium": "39",
    "iron": "1",
    "phosphorus": "106",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "337",
    "type": "meal",
    "|| name                                               ||": "Steamed pork ribs (dim sum)",
    "rating": "2",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "|| info                                                             ||": "e.g. pork ribs with black bean sauce",
    "info-include-exclude": "with black bean sauce",
    "weight-g": "100",
    "calories": "159.3",
    "fat": "11.3",
    "cal-from-fat": "101.7",
    "per-cal-from-fat": "64",
    "saturated": "4.1",
    "polyunsaturated": "2.1",
    "monounsaturated": "4",
    "cholesterol": "77",
    "sodium": "752",
    "carbs": "2.4",
    "fibre": "0",
    "sugar": "2",
    "protein": "12",
    "cal-from-protein": "48",
    "per-cal-from-protein": "30",
    "calcium": "69",
    "iron": "0.9",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "338",
    "type": "meal",
    "|| name                                               ||": "Steamed prawn dumpling (dim sum)",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Har Gow",
    "info-translation": "Har Gow",
    "alternate-spellings": "Har: Ha\nGow: Gau",
    "weight-g": "18",
    "calories": "27.1",
    "fat": "0.7",
    "cal-from-fat": "6.3",
    "per-cal-from-fat": "23",
    "saturated": "0.3",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.3",
    "cholesterol": "5",
    "sodium": "74",
    "carbs": "4.4",
    "fibre": "0.4",
    "sugar": "0.4",
    "protein": "0.8",
    "cal-from-protein": "3",
    "per-cal-from-protein": "12",
    "potassium": "3",
    "calcium": "7",
    "iron": "0.1",
    "phosphorus": "9",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "835",
    "type": "meal",
    "|| name                                               ||": "Steamed tofu with minced pork",
    "rating": "3",
    "high-calcium": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "plant-protein": "1",
    "serving": "0.3",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Plate - 16cm",
    "weight-g": "120",
    "calories": "184",
    "fat": "14",
    "cal-from-fat": "126",
    "per-cal-from-fat": "68",
    "saturated": "4.2",
    "polyunsaturated": "4.4",
    "monounsaturated": "4.1",
    "cholesterol": "16",
    "sodium": "240",
    "carbs": "3.8",
    "fibre": "0.4",
    "sugar": "0",
    "protein": "10.3",
    "cal-from-protein": "41",
    "per-cal-from-protein": "22",
    "calcium": "127",
    "iron": "1.4",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "836",
    "type": "meal",
    "|| name                                               ||": "Steamed tofu with minced pork and mushroom",
    "rating": "3",
    "high-calcium": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Plate - 16cm",
    "weight-g": "120",
    "calories": "112",
    "fat": "6.2",
    "cal-from-fat": "55.8",
    "per-cal-from-fat": "50",
    "saturated": "1.5",
    "polyunsaturated": "2.6",
    "monounsaturated": "1.9",
    "cholesterol": "14",
    "sodium": "377",
    "carbs": "2.3",
    "fibre": "1.4",
    "sugar": "1.6",
    "protein": "11.4",
    "cal-from-protein": "46",
    "per-cal-from-protein": "41",
    "potassium": "272",
    "calcium": "78",
    "iron": "1.1",
    "phosphorus": "119",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "322",
    "type": "meal",
    "|| name                                               ||": "Stew, beef",
    "rating": "2",
    "high-iron": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "weight-g": "100",
    "calories": "211.1",
    "fat": "11.9",
    "cal-from-fat": "107.1",
    "per-cal-from-fat": "51",
    "saturated": "4.3",
    "polyunsaturated": "0.8",
    "monounsaturated": "5.6",
    "trans": "0.5",
    "cholesterol": "72",
    "sodium": "66",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "26",
    "cal-from-protein": "104",
    "per-cal-from-protein": "49",
    "potassium": "416",
    "calcium": "5",
    "iron": "2.3",
    "phosphorus": "241",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "323",
    "type": "meal",
    "|| name                                               ||": "Stew, chicken",
    "rating": "2",
    "high-iron": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "weight-g": "126",
    "calories": "220.7",
    "fat": "15.5",
    "cal-from-fat": "139.5",
    "per-cal-from-fat": "63",
    "saturated": "4.1",
    "polyunsaturated": "4",
    "monounsaturated": "6.6",
    "cholesterol": "10",
    "sodium": "316",
    "carbs": "4.5",
    "fibre": "1.4",
    "sugar": "0.9",
    "protein": "15.8",
    "cal-from-protein": "63",
    "per-cal-from-protein": "29",
    "potassium": "256",
    "calcium": "57",
    "iron": "2.1",
    "phosphorus": "139",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "529",
    "type": "meal",
    "|| name                                               ||": "Stir fried beehoon with meat",
    "rating": "2",
    "high-gi": "1",
    "high-iron": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "info-translation": "Rice vermicelli",
    "weight-g": "284",
    "calories": "364.7",
    "fat": "11.1",
    "cal-from-fat": "99.9",
    "per-cal-from-fat": "27",
    "saturated": "4",
    "polyunsaturated": "2.4",
    "monounsaturated": "4.1",
    "cholesterol": "94",
    "sodium": "346",
    "carbs": "44.9",
    "fibre": "4.8",
    "sugar": "1.4",
    "protein": "21.3",
    "cal-from-protein": "85",
    "per-cal-from-protein": "23",
    "potassium": "358",
    "calcium": "40",
    "iron": "2.3",
    "phosphorus": "210",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "432",
    "type": "meal",
    "|| name                                               ||": "Stir fried mixed vegetables",
    "rating": "3",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "plant-protein": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "150",
    "calories": "67",
    "fat": "6.8",
    "cal-from-fat": "61.2",
    "per-cal-from-fat": "91",
    "saturated": "1.3",
    "polyunsaturated": "3.3",
    "monounsaturated": "2.1",
    "cholesterol": "0",
    "sodium": "150",
    "carbs": "4.9",
    "fibre": "3.6",
    "sugar": "1.5",
    "protein": "3.6",
    "cal-from-protein": "14",
    "per-cal-from-protein": "21",
    "potassium": "265.5",
    "calcium": "40.5",
    "iron": "0.6",
    "phosphorus": "64.5",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "523",
    "type": "meal",
    "|| name                                               ||": "Stir fried tang hoon, plain",
    "rating": "2",
    "high-iron": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Stir fried mung bean glass noodles",
    "info-translation": "Mung bean glass noodles",
    "alternate-spellings": "Tang: Dong\nHoon: Fen",
    "weight-g": "437",
    "calories": "497.2",
    "fat": "23.6",
    "cal-from-fat": "212.4",
    "per-cal-from-fat": "43",
    "saturated": "10.1",
    "polyunsaturated": "2.6",
    "monounsaturated": "10.1",
    "cholesterol": "54.2",
    "sodium": "1381",
    "carbs": "54.2",
    "fibre": "10.9",
    "sugar": "0.9",
    "protein": "17",
    "cal-from-protein": "68",
    "per-cal-from-protein": "14",
    "potassium": "433",
    "calcium": "105",
    "iron": "1.8",
    "phosphorus": "201",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "770",
    "type": "meal",
    "|| name                                               ||": "Stir-fried beef with ginger and spring onion",
    "rating": "2",
    "high-purine": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "|| info                                                             ||": "1 palm-size portion",
    "weight-g": "100",
    "calories": "144.32",
    "fat": "10",
    "cal-from-fat": "90",
    "per-cal-from-fat": "62",
    "saturated": "3.15",
    "polyunsaturated": "0.83",
    "monounsaturated": "2.93",
    "cholesterol": "18.75",
    "sodium": "573.75",
    "carbs": "2.7",
    "fibre": "2.03",
    "sugar": "1.42",
    "protein": "10.88",
    "cal-from-protein": "44",
    "per-cal-from-protein": "30",
    "potassium": "264.75",
    "calcium": "18.75",
    "iron": "0.9",
    "phosphorus": "67.5",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "772",
    "type": "meal",
    "|| name                                               ||": "Stir-fried chicken with ginger",
    "rating": "2",
    "high-cholesterol": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "|| info                                                             ||": "1 palm-size portion",
    "weight-g": "100",
    "calories": "202.8",
    "fat": "14.4",
    "cal-from-fat": "129.6",
    "per-cal-from-fat": "64",
    "saturated": "5",
    "polyunsaturated": "2.9",
    "monounsaturated": "5.7",
    "cholesterol": "124",
    "sodium": "659",
    "carbs": "3.7",
    "fibre": "0",
    "sugar": "0",
    "protein": "14.6",
    "cal-from-protein": "58",
    "per-cal-from-protein": "29",
    "potassium": "130",
    "calcium": "66",
    "iron": "1.1",
    "phosphorus": "84",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "768",
    "type": "meal",
    "|| name                                               ||": "Stir-fried sliced fish with ginger and spring onion",
    "rating": "2",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "|| info                                                             ||": "1 palm-size portion",
    "weight-g": "100",
    "calories": "117.6",
    "fat": "8",
    "cal-from-fat": "72",
    "per-cal-from-fat": "61",
    "saturated": "2.5",
    "polyunsaturated": "3",
    "monounsaturated": "2.5",
    "cholesterol": "49",
    "sodium": "516",
    "carbs": "0.4",
    "fibre": "0.5",
    "sugar": "0",
    "protein": "11",
    "cal-from-protein": "44",
    "per-cal-from-protein": "37",
    "potassium": "238",
    "calcium": "34",
    "iron": "0.7",
    "phosphorus": "86",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "769",
    "type": "meal",
    "|| name                                               ||": "Stir-fried sliced pork with ginger and spring onion",
    "rating": "2",
    "high-purine": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "|| info                                                             ||": "1 palm-size portion",
    "weight-g": "100",
    "calories": "167.6",
    "fat": "8",
    "cal-from-fat": "72",
    "per-cal-from-fat": "43",
    "saturated": "2.7",
    "polyunsaturated": "1.9",
    "monounsaturated": "3.2",
    "cholesterol": "53",
    "sodium": "412",
    "carbs": "3.6",
    "fibre": "2.4",
    "sugar": "3",
    "protein": "20.3",
    "cal-from-protein": "81",
    "per-cal-from-protein": "48",
    "potassium": "324",
    "calcium": "48",
    "iron": "0.9",
    "phosphorus": "185",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "715",
    "type": "snack",
    "|| name                                               ||": "Strawberries",
    "rating": "3",
    "fruit": "1",
    "high-potassium": "1",
    "serving": "8",
    "|| unit ||": "fruit",
    "weight-g": "96",
    "calories": "21.04",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "9",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "6.75",
    "carbs": "2.93",
    "fibre": "1.95",
    "sugar": "2.93",
    "protein": "1.88",
    "cal-from-protein": "8",
    "per-cal-from-protein": "36",
    "potassium": "141.75",
    "calcium": "14.25",
    "iron": "0.67",
    "phosphorus": "21",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "1016",
    "type": "snack",
    "|| name                                               ||": "Strawberry shortcake",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "150",
    "calories": "270",
    "fat": "12",
    "cal-from-fat": "108",
    "per-cal-from-fat": "40",
    "saturated": "6",
    "polyunsaturated": "0.6",
    "monounsaturated": "5.4",
    "cholesterol": "40",
    "carbs": "23",
    "fibre": "1",
    "sugar": "7",
    "protein": "5",
    "cal-from-protein": "20",
    "per-cal-from-protein": "7",
    "calcium": "60",
    "iron": "0.7",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "636",
    "type": "meal",
    "|| name                                               ||": "String beans",
    "rating": "3",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "100",
    "calories": "21.4",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "8",
    "saturated": "0",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.1",
    "cholesterol": "0",
    "sodium": "2",
    "carbs": "2.6",
    "fibre": "2.9",
    "sugar": "1.2",
    "protein": "2.3",
    "cal-from-protein": "9",
    "per-cal-from-protein": "43",
    "potassium": "80",
    "calcium": "46",
    "iron": "1",
    "phosphorus": "40",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "69",
    "type": "snack",
    "|| name                                               ||": "Strudel with fruit fillings",
    "rating": "2",
    "high-gi": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. apple / pineapple strudel",
    "info-examples": "apple, pineapple",
    "weight-g": "71",
    "calories": "197.71",
    "fat": "7.95",
    "cal-from-fat": "71.55",
    "per-cal-from-fat": "36",
    "saturated": "1.45",
    "polyunsaturated": "3.77",
    "monounsaturated": "2.32",
    "cholesterol": "4.26",
    "sodium": "95.8",
    "carbs": "29.2",
    "fibre": "1.56",
    "sugar": "18.3",
    "protein": "2.34",
    "cal-from-protein": "9",
    "per-cal-from-protein": "5",
    "potassium": "106",
    "calcium": "10.6",
    "iron": "0.298",
    "phosphorus": "23.4",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "808",
    "type": "meal",
    "|| name                                               ||": "Subway flatbread",
    "rating": "2",
    "high-fat": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "3",
    "|| unit ||": "inch",
    "|| info                                                             ||": "With sausage, ham, bacon, egg & cheese filling",
    "info-examples": "sausage, ham, bacon, egg, cheese",
    "weight-g": "134",
    "calories": "281",
    "fat": "15",
    "cal-from-fat": "135",
    "per-cal-from-fat": "48",
    "saturated": "5",
    "cholesterol": "232",
    "sodium": "666",
    "carbs": "20",
    "fibre": "1",
    "sugar": "1",
    "protein": "17",
    "cal-from-protein": "68",
    "per-cal-from-protein": "24",
    "source": "N Buddy",
    "old units (ref)": "whole"
  },
  {
    "id": "802",
    "type": "meal",
    "|| name                                               ||": "Subway sandwich with chicken or turkey",
    "rating": "2",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "6",
    "|| unit ||": "inch",
    "|| info                                                             ||": "e.g. chicken teriyaki, oven roasted chicken breast, turkey breast",
    "weight-g": "198",
    "calories": "311",
    "fat": "6.2",
    "cal-from-fat": "55.8",
    "per-cal-from-fat": "18",
    "saturated": "1.4",
    "polyunsaturated": "1.9",
    "monounsaturated": "1.8",
    "trans": "0",
    "cholesterol": "46",
    "sodium": "531",
    "carbs": "42.3",
    "fibre": "2.4",
    "sugar": "5.9",
    "protein": "21.5",
    "cal-from-protein": "86",
    "per-cal-from-protein": "28",
    "potassium": "392",
    "calcium": "305",
    "iron": "3.4",
    "phosphorus": "287",
    "source": "USDA",
    "old units (ref)": "whole"
  },
  {
    "id": "804",
    "type": "meal",
    "|| name                                               ||": "Subway sandwich with meatless option",
    "rating": "2",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "plant-protein": "1",
    "whole-grain": "1",
    "serving": "6",
    "|| unit ||": "inch",
    "|| info                                                             ||": "e.g. veggie delite, egg mayo, veggie patty",
    "info-examples": "veggie delite, egg mayo, veggie patty",
    "weight-g": "247",
    "calories": "435",
    "fat": "23",
    "cal-from-fat": "207",
    "per-cal-from-fat": "48",
    "saturated": "5",
    "cholesterol": "299",
    "sodium": "477",
    "carbs": "42",
    "fibre": "5",
    "sugar": "6",
    "protein": "19",
    "cal-from-protein": "76",
    "per-cal-from-protein": "17",
    "source": "N Buddy",
    "old units (ref)": "whole"
  },
  {
    "id": "803",
    "type": "meal",
    "|| name                                               ||": "Subway sandwich with processed meat",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "6",
    "|| unit ||": "inch",
    "|| info                                                             ||": "e.g. ham sandwich, subway melt, BLT",
    "info-examples": "ham, bacon",
    "weight-g": "240",
    "calories": "360",
    "fat": "11",
    "cal-from-fat": "99",
    "per-cal-from-fat": "28",
    "saturated": "5.3",
    "polyunsaturated": "1",
    "monounsaturated": "4.8",
    "cholesterol": "46",
    "sodium": "1404",
    "carbs": "39",
    "fibre": "4",
    "sugar": "7",
    "protein": "24",
    "cal-from-protein": "96",
    "per-cal-from-protein": "27",
    "potassium": "478",
    "calcium": "214",
    "iron": "5.3",
    "phosphorus": "250",
    "source": "N Buddy",
    "old units (ref)": "whole"
  },
  {
    "id": "805",
    "type": "meal",
    "|| name                                               ||": "Subway wrap with chicken or turkey",
    "rating": "2",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "wrap",
    "|| info                                                             ||": "e.g. chicken teriyaki, oven roasted chicken breast, turkey breast",
    "weight-g": "252",
    "calories": "345",
    "fat": "8.3",
    "cal-from-fat": "74.7",
    "per-cal-from-fat": "22",
    "saturated": "3",
    "cholesterol": "64",
    "sodium": "1013",
    "carbs": "40",
    "fibre": "2.3",
    "sugar": "3.8",
    "protein": "28.5",
    "cal-from-protein": "114",
    "per-cal-from-protein": "33",
    "source": "Subway website",
    "old units (ref)": "portion"
  },
  {
    "id": "807",
    "type": "meal",
    "|| name                                               ||": "Subway wrap with meatless option",
    "rating": "2",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "|| info                                                             ||": "e.g. veggie delite, egg mayo, veggie patty",
    "info-examples": "veggie delite, egg mayo, veggie patty",
    "weight-g": "214",
    "calories": "248",
    "fat": "6",
    "cal-from-fat": "54",
    "per-cal-from-fat": "22",
    "saturated": "2.6",
    "cholesterol": "0",
    "sodium": "606",
    "carbs": "42",
    "fibre": "3",
    "sugar": "3.8",
    "protein": "7.5",
    "cal-from-protein": "30",
    "per-cal-from-protein": "12",
    "source": "Subway website",
    "old units (ref)": "whole"
  },
  {
    "id": "806",
    "type": "meal",
    "|| name                                               ||": "Subway wrap with processed meat",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "wrap",
    "|| info                                                             ||": "e.g. ham, subway club, BLT",
    "info-examples": "ham, bacon",
    "weight-g": "265",
    "calories": "478",
    "fat": "17",
    "cal-from-fat": "153",
    "per-cal-from-fat": "32",
    "saturated": "7.3",
    "polyunsaturated": "1",
    "monounsaturated": "4.8",
    "cholesterol": "46",
    "sodium": "1701",
    "carbs": "52",
    "fibre": "1",
    "sugar": "4",
    "protein": "23",
    "cal-from-protein": "92",
    "per-cal-from-protein": "19",
    "potassium": "478",
    "calcium": "214",
    "iron": "5.3",
    "phosphorus": "250",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "172",
    "type": "drink",
    "|| name                                               ||": "Sugarcane juice, fresh",
    "rating": "2",
    "high-gi": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "volume-ml": "263",
    "calories": "107.2",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "3",
    "carbs": "26",
    "fibre": "1.6",
    "sugar": "25.8",
    "protein": "0.8",
    "cal-from-protein": "3",
    "per-cal-from-protein": "3",
    "potassium": "195",
    "iron": "0.3",
    "phosphorus": "18",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "39",
    "type": "snack",
    "|| name                                               ||": "Sun cake pastry",
    "rating": "1",
    "high-gi": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Taiyang bing",
    "info-translation": "Tai Yang Bing",
    "weight-g": "60",
    "calories": "240",
    "fat": "4",
    "cal-from-fat": "36",
    "per-cal-from-fat": "15",
    "saturated": "2",
    "polyunsaturated": "1",
    "monounsaturated": "1",
    "cholesterol": "0",
    "sodium": "73",
    "carbs": "48",
    "fibre": "0",
    "sugar": "14",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "5",
    "calcium": "0",
    "iron": "0.9",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "433",
    "type": "meal",
    "|| name                                               ||": "Soondubu jjigae, seafood",
    "rating": "3",
    "high-calcium": "1",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Korean seafood soft tofu stew",
    "info-translation": "Korean spicy tofu soup",
    "alternate-spellings": "Soondubu: Sundubu",
    "weight-g": "456",
    "calories": "239.9",
    "fat": "13.9",
    "cal-from-fat": "125.1",
    "per-cal-from-fat": "52",
    "saturated": "4.7",
    "polyunsaturated": "3.9",
    "monounsaturated": "4.3",
    "cholesterol": "3.7",
    "sodium": "1041",
    "carbs": "6",
    "fibre": "1",
    "sugar": "3.2",
    "protein": "22.7",
    "cal-from-protein": "91",
    "per-cal-from-protein": "38",
    "potassium": "571",
    "calcium": "102",
    "iron": "3.4",
    "phosphorus": "356",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "324",
    "type": "meal",
    "|| name                                               ||": "Sup tulang",
    "rating": "2",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Beef bone soup",
    "info-translation": "Beef bone soup",
    "weight-g": "375",
    "calories": "184.3",
    "fat": "7.5",
    "cal-from-fat": "67.5",
    "per-cal-from-fat": "37",
    "saturated": "2.8",
    "polyunsaturated": "0.3",
    "monounsaturated": "4.1",
    "cholesterol": "34",
    "sodium": "1448",
    "carbs": "11.6",
    "fibre": "5.6",
    "sugar": "6.4",
    "protein": "17.6",
    "cal-from-protein": "70",
    "per-cal-from-protein": "38",
    "potassium": "270",
    "calcium": "229",
    "iron": "0.8",
    "phosphorus": "1283",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "847",
    "type": "meal",
    "|| name                                               ||": "Sushi rice stuffed in sweet beancurd skin",
    "rating": "2",
    "high-phosphate": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "2",
    "|| unit ||": "piece",
    "weight-g": "68",
    "calories": "128",
    "fat": "3.4",
    "cal-from-fat": "30.6",
    "per-cal-from-fat": "24",
    "saturated": "0.4",
    "polyunsaturated": "1.4",
    "monounsaturated": "1.4",
    "cholesterol": "0",
    "sodium": "294",
    "carbs": "20.2",
    "fibre": "1",
    "sugar": "5.4",
    "protein": "4",
    "cal-from-protein": "16",
    "per-cal-from-protein": "13",
    "potassium": "22",
    "calcium": "48",
    "iron": "0.4",
    "phosphorus": "46",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "236",
    "type": "meal",
    "|| name                                               ||": "Sushi, maki, small",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "3",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. kappa (cucumber) maki, kanikama (crab) maki, tamago (egg) maki",
    "info-examples": "kappa (cucumber), kanikama (crab), tamago (egg)",
    "weight-g": "27",
    "calories": "38.2",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "5",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "92.5",
    "carbs": "8.3",
    "fibre": "0.2",
    "sugar": "1.5",
    "protein": "0.8",
    "cal-from-protein": "3",
    "per-cal-from-protein": "8",
    "potassium": "21.5",
    "calcium": "3.5",
    "iron": "0",
    "phosphorus": "8.5",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "237",
    "type": "meal",
    "|| name                                               ||": "Sushi, nigiri",
    "rating": "2",
    "high-cholesterol": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "with raw salmon / prawn / scallop on top of rice",
    "info-examples": "raw salmon, prawn, scallop",
    "weight-g": "34",
    "calories": "60.4",
    "fat": "1.2",
    "cal-from-fat": "10.8",
    "per-cal-from-fat": "18",
    "saturated": "0.7",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.3",
    "cholesterol": "3",
    "sodium": "90",
    "carbs": "10",
    "fibre": "0.2",
    "sugar": "0.6",
    "protein": "2.4",
    "cal-from-protein": "10",
    "per-cal-from-protein": "16",
    "potassium": "25",
    "calcium": "4",
    "iron": "0",
    "phosphorus": "22",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "238",
    "type": "meal",
    "|| name                                               ||": "Sushi, uramaki",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "with rice on the outside and fillings inside the seaweed, e.g. salmon",
    "info-translation": "Inside-out roll",
    "weight-g": "35",
    "calories": "48",
    "fat": "1.2",
    "cal-from-fat": "10.8",
    "per-cal-from-fat": "23",
    "saturated": "0.3",
    "polyunsaturated": "0.4",
    "monounsaturated": "0.4",
    "cholesterol": "17",
    "sodium": "162",
    "carbs": "7.8",
    "fibre": "0.4",
    "sugar": "1.3",
    "protein": "1.5",
    "cal-from-protein": "6",
    "per-cal-from-protein": "13",
    "potassium": "27",
    "calcium": "8",
    "iron": "0",
    "phosphorus": "17",
    "source": "N Buddy",
    "old units (ref)": "roll"
  },
  {
    "id": "80",
    "type": "snack",
    "|| name                                               ||": "Sweet crepe",
    "rating": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "|| info                                                             ||": "with sauce and fruits topping",
    "info-include-exclude": "with sauce and fruits",
    "weight-g": "324",
    "calories": "447",
    "fat": "11",
    "cal-from-fat": "99",
    "per-cal-from-fat": "22",
    "saturated": "6",
    "polyunsaturated": "1.1",
    "monounsaturated": "3",
    "cholesterol": "132",
    "sodium": "285",
    "carbs": "78.2",
    "fibre": "5",
    "sugar": "48.1",
    "protein": "8.8",
    "cal-from-protein": "35",
    "per-cal-from-protein": "8",
    "potassium": "350",
    "calcium": "60",
    "iron": "1.4",
    "phosphorus": "26",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "678",
    "type": "meal",
    "|| name                                               ||": "Sweet potato leaves / shoots",
    "rating": "3",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "100",
    "calories": "82",
    "fat": "4.8",
    "cal-from-fat": "43.2",
    "per-cal-from-fat": "53",
    "saturated": "2.2",
    "polyunsaturated": "0.5",
    "monounsaturated": "1.9",
    "cholesterol": "0",
    "sodium": "383",
    "carbs": "7.2",
    "fibre": "1.7",
    "sugar": "5.3",
    "protein": "2.5",
    "cal-from-protein": "10",
    "per-cal-from-protein": "12",
    "potassium": "286",
    "calcium": "33",
    "iron": "0.6",
    "phosphorus": "38",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "217",
    "type": "snack",
    "|| name                                               ||": "Sweet potato soup (dessert)",
    "rating": "2",
    "high-fibre": "1",
    "high-gi": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "dessert",
    "weight-g": "240",
    "calories": "146",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "22",
    "carbs": "35.5",
    "fibre": "4.1",
    "sugar": "15.8",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "3",
    "potassium": "247",
    "calcium": "19",
    "iron": "0.7",
    "phosphorus": "36",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "204",
    "type": "snack",
    "|| name                                               ||": "Sweet potato, baked",
    "rating": "2",
    "high-fibre": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "potato",
    "weight-g": "114",
    "calories": "105.4",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "2",
    "saturated": "0.1",
    "polyunsaturated": "0.1",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "41",
    "carbs": "23.6",
    "fibre": "3.8",
    "sugar": "7.4",
    "protein": "2.3",
    "cal-from-protein": "9",
    "per-cal-from-protein": "9",
    "potassium": "542",
    "calcium": "43",
    "iron": "0.8",
    "phosphorus": "62",
    "source": "N Buddy",
    "old units (ref)": "medium"
  },
  {
    "id": "325",
    "type": "meal",
    "|| name                                               ||": "Sweet potato, boiled",
    "rating": "3",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "potato",
    "weight-g": "151",
    "calories": "117.379",
    "fat": "0.211",
    "cal-from-fat": "1.899",
    "per-cal-from-fat": "2",
    "saturated": "0.047",
    "polyunsaturated": "0.092",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "40.8",
    "carbs": "26.8",
    "fibre": "3.78",
    "sugar": "8.67",
    "protein": "2.07",
    "cal-from-protein": "8",
    "per-cal-from-protein": "7",
    "potassium": "347",
    "calcium": "40.8",
    "iron": "1.09",
    "phosphorus": "48.3",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "638",
    "type": "meal",
    "|| name                                               ||": "Sweetcorn, canned",
    "rating": "3",
    "high-fibre": "1",
    "vegetable": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "128",
    "calories": "105.9",
    "fat": "0.5",
    "cal-from-fat": "4.5",
    "per-cal-from-fat": "4",
    "saturated": "0.15",
    "polyunsaturated": "0.25",
    "monounsaturated": "0.15",
    "cholesterol": "0",
    "sodium": "365",
    "carbs": "23.15",
    "fibre": "3.2",
    "sugar": "3.45",
    "protein": "2.2",
    "cal-from-protein": "9",
    "per-cal-from-protein": "8",
    "potassium": "171.5",
    "calcium": "4",
    "iron": "0.5",
    "phosphorus": "65.5",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "79",
    "type": "snack",
    "|| name                                               ||": "Swiss roll",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "56",
    "calories": "202.2",
    "fat": "11.8",
    "cal-from-fat": "106.2",
    "per-cal-from-fat": "53",
    "saturated": "7.5",
    "polyunsaturated": "0.6",
    "monounsaturated": "3.2",
    "cholesterol": "62",
    "sodium": "95",
    "carbs": "19.9",
    "fibre": "0.8",
    "sugar": "11.3",
    "protein": "4.1",
    "cal-from-protein": "16",
    "per-cal-from-protein": "8",
    "potassium": "52",
    "calcium": "20",
    "iron": "1.2",
    "phosphorus": "79",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "326",
    "type": "meal",
    "|| name                                               ||": "Taco",
    "rating": "2",
    "high-gi": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "with cheese, meat",
    "info-include-exclude": "with cheese, meat",
    "weight-g": "110",
    "calories": "215.7",
    "fat": "11.3",
    "cal-from-fat": "101.7",
    "per-cal-from-fat": "47",
    "saturated": "3.8",
    "polyunsaturated": "3.8",
    "monounsaturated": "3.8",
    "trans": "0.4",
    "cholesterol": "29",
    "sodium": "343.5",
    "carbs": "13.4",
    "fibre": "1.9",
    "sugar": "1.5",
    "protein": "15.1",
    "cal-from-protein": "60",
    "per-cal-from-protein": "28",
    "potassium": "216.5",
    "calcium": "92",
    "iron": "1.3",
    "phosphorus": "184",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "410",
    "type": "snack",
    "|| name                                               ||": "Tahini",
    "rating": "3",
    "high-potassium": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "tsp",
    "info-translation": "Sesame seed paste",
    "alternate-spellings": "Tahini: Tahina",
    "weight-g": "5",
    "calories": "34.2",
    "fat": "3",
    "cal-from-fat": "27",
    "per-cal-from-fat": "79",
    "saturated": "0.4",
    "polyunsaturated": "1.4",
    "monounsaturated": "1.1",
    "trans": "0.1",
    "cholesterol": "0",
    "sodium": "4",
    "carbs": "0.8",
    "fibre": "0.7",
    "sugar": "0",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "12",
    "potassium": "10",
    "calcium": "17",
    "iron": "0.3",
    "phosphorus": "37",
    "source": "N Buddy",
    "old units (ref)": "teaspoon"
  },
  {
    "id": "463",
    "type": "meal",
    "|| name                                               ||": "Tahu telor",
    "rating": "2",
    "fried": "1",
    "high-calcium": "1",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "plant-protein": "1",
    "serving": "0.5",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Plate - 20cm",
    "alternate-spellings": "Tahu: Tauhu\nTelor: Telur",
    "weight-g": "159",
    "calories": "406",
    "fat": "32.8",
    "cal-from-fat": "295.2",
    "per-cal-from-fat": "73",
    "saturated": "13.3",
    "cholesterol": "112",
    "sodium": "266",
    "carbs": "10.4",
    "fibre": "4.8",
    "protein": "17.1",
    "cal-from-protein": "68",
    "per-cal-from-protein": "17",
    "source": "HPB",
    "old units (ref)": "portion"
  },
  {
    "id": "504",
    "type": "meal",
    "|| name                                               ||": "Taiwanese oyster vermicelli",
    "rating": "2",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Orh ah mee sua",
    "info-translation": "Orh Ah Mee Sua",
    "weight-g": "587",
    "calories": "316.1",
    "fat": "3.3",
    "cal-from-fat": "29.7",
    "per-cal-from-fat": "9",
    "saturated": "1.4",
    "polyunsaturated": "1.2",
    "monounsaturated": "1.1",
    "cholesterol": "41",
    "sodium": "664",
    "carbs": "52.8",
    "fibre": "3.1",
    "sugar": "7.9",
    "protein": "18.8",
    "cal-from-protein": "75",
    "per-cal-from-protein": "24",
    "potassium": "536",
    "calcium": "79",
    "iron": "6.3",
    "phosphorus": "174",
    "source": "N Buddy",
    "old units (ref)": "small bowl"
  },
  {
    "id": "70",
    "type": "snack",
    "|| name                                               ||": "Takoyaki",
    "rating": "2",
    "high-purine": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "ball",
    "info-translation": "Octopus Balls",
    "weight-g": "30",
    "calories": "55.9",
    "fat": "3.1",
    "cal-from-fat": "27.9",
    "per-cal-from-fat": "50",
    "saturated": "0.9",
    "polyunsaturated": "0.5",
    "monounsaturated": "2.4",
    "cholesterol": "6",
    "sodium": "137",
    "carbs": "5",
    "fibre": "1",
    "sugar": "2",
    "protein": "2",
    "cal-from-protein": "8",
    "per-cal-from-protein": "14",
    "potassium": "56",
    "calcium": "12",
    "iron": "0.1",
    "phosphorus": "20",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "669",
    "type": "meal",
    "|| name                                               ||": "Tang oh, boiled/ steamed",
    "rating": "3",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "|| info                                                             ||": "Garland chrysanthemum",
    "info-translation": "Garland chrysanthemum",
    "alternate-spellings": "Oh: Ho",
    "weight-g": "100",
    "calories": "23.76",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0.02",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "53",
    "carbs": "4.3",
    "fibre": "2.3",
    "sugar": "0",
    "protein": "1.64",
    "cal-from-protein": "7",
    "per-cal-from-protein": "28",
    "source": "HPB",
    "old units (ref)": "serving"
  },
  {
    "id": "703",
    "type": "snack",
    "|| name                                               ||": "Tangerine",
    "rating": "3",
    "fruit": "1",
    "serving": "1",
    "|| unit ||": "fruit",
    "weight-g": "116",
    "calories": "50.3",
    "fat": "0.3",
    "cal-from-fat": "2.7",
    "per-cal-from-fat": "5",
    "saturated": "0",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.1",
    "cholesterol": "0",
    "sodium": "3",
    "carbs": "10.6",
    "fibre": "2.2",
    "sugar": "10.6",
    "protein": "1.3",
    "cal-from-protein": "5",
    "per-cal-from-protein": "10",
    "potassium": "164",
    "calcium": "21",
    "iron": "0.2",
    "phosphorus": "20",
    "source": "N Buddy",
    "old units (ref)": "whole"
  },
  {
    "id": "518",
    "type": "meal",
    "|| name                                               ||": "Tapas",
    "rating": "2",
    "high-salt": "1",
    "serving": "3",
    "|| unit ||": "piece",
    "weight-g": "220",
    "calories": "469",
    "fat": "29.5",
    "cal-from-fat": "265.5",
    "per-cal-from-fat": "57",
    "saturated": "6.5",
    "polyunsaturated": "12",
    "monounsaturated": "9.5",
    "trans": "0.3",
    "cholesterol": "152",
    "sodium": "1379",
    "carbs": "32",
    "fibre": "2.5",
    "sugar": "2.5",
    "protein": "19",
    "cal-from-protein": "76",
    "per-cal-from-protein": "16",
    "potassium": "685",
    "source": "Nutritionix",
    "old units (ref)": "piece"
  },
  {
    "id": "659",
    "type": "meal",
    "|| name                                               ||": "Tapioca, steamed",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "85",
    "calories": "70.6",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "3",
    "saturated": "0.1",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.1",
    "cholesterol": "0",
    "sodium": "150",
    "carbs": "16.6",
    "fibre": "1",
    "sugar": "1",
    "protein": "0.6",
    "cal-from-protein": "2",
    "per-cal-from-protein": "3",
    "potassium": "147",
    "calcium": "11",
    "iron": "0.4",
    "phosphorus": "38",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "519",
    "type": "meal",
    "|| name                                               ||": "Tarte flambée",
    "rating": "2",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "|| info                                                             ||": "1/3 pizza (145g)",
    "info-translation": "Flammekueche",
    "weight-g": "145",
    "calories": "380",
    "fat": "15",
    "cal-from-fat": "135",
    "per-cal-from-fat": "36",
    "saturated": "5",
    "cholesterol": "15",
    "sodium": "780",
    "carbs": "46",
    "fibre": "2",
    "sugar": "5",
    "protein": "13",
    "cal-from-protein": "52",
    "per-cal-from-protein": "14",
    "potassium": "210",
    "calcium": "59",
    "iron": "1.1",
    "source": "USDA",
    "old units (ref)": "portion"
  },
  {
    "id": "195",
    "type": "snack",
    "|| name                                               ||": "Tau suan with dough fritters",
    "rating": "2",
    "fried": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "with dough fritters",
    "alternate-spellings": "Tau: Dou, Tao \nSuan: Shuang",
    "weight-g": "240",
    "calories": "232.3",
    "fat": "1.9",
    "cal-from-fat": "17.1",
    "per-cal-from-fat": "7",
    "saturated": "1.6",
    "polyunsaturated": "1.6",
    "monounsaturated": "0.7",
    "cholesterol": "0",
    "sodium": "79",
    "carbs": "47.8",
    "fibre": "4",
    "sugar": "15.6",
    "protein": "6",
    "cal-from-protein": "24",
    "per-cal-from-protein": "10",
    "potassium": "110",
    "calcium": "17",
    "iron": "1.4",
    "phosphorus": "84",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "477",
    "type": "snack",
    "|| name                                               ||": "Tau suan, without dough fritters",
    "rating": "2",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "without dough fritters",
    "alternate-spellings": "Tau: Dou, Tao \nSuan: Shuang",
    "weight-g": "229",
    "calories": "189.8",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "1",
    "saturated": "0",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.1",
    "cholesterol": "0",
    "sodium": "10",
    "carbs": "42",
    "fibre": "4",
    "sugar": "15.6",
    "protein": "5",
    "cal-from-protein": "20",
    "per-cal-from-protein": "11",
    "potassium": "110",
    "calcium": "14",
    "iron": "1.4",
    "phosphorus": "70",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "613",
    "type": "drink",
    "|| name                                               ||": "Tea, flavored and sweetened",
    "rating": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "e.g. honey black tea, mango green tea, raspberry tea",
    "info-examples": "honey, mango, raspberry",
    "volume-ml": "240",
    "calories": "72",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "carbs": "18",
    "sugar": "17",
    "source": "US FDA",
    "old units (ref)": "cup"
  },
  {
    "id": "162",
    "type": "drink",
    "|| name                                               ||": "Tea with fresh milk, sweetened",
    "rating": "2",
    "high-gi": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "volume-ml": "250",
    "calories": "109",
    "fat": "2.4",
    "cal-from-fat": "21.6",
    "per-cal-from-fat": "20",
    "saturated": "0.6",
    "polyunsaturated": "0.3",
    "monounsaturated": "1.4",
    "cholesterol": "5",
    "sodium": "21",
    "carbs": "20",
    "fibre": "0",
    "sugar": "20",
    "protein": "1.7",
    "cal-from-protein": "7",
    "per-cal-from-protein": "6",
    "potassium": "52",
    "calcium": "55",
    "iron": "0",
    "phosphorus": "43",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "161",
    "type": "drink",
    "|| name                                               ||": "Tea with fresh milk, unsweetened",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "cup",
    "volume-ml": "250",
    "calories": "40.4",
    "fat": "2.4",
    "cal-from-fat": "21.6",
    "per-cal-from-fat": "53",
    "saturated": "0.6",
    "polyunsaturated": "0.3",
    "monounsaturated": "1.4",
    "cholesterol": "5",
    "carbs": "3",
    "fibre": "0",
    "sugar": "3",
    "protein": "1.7",
    "cal-from-protein": "7",
    "per-cal-from-protein": "17",
    "iodine": "1",
    "source": "USDA",
    "old units (ref)": "cup"
  },
  {
    "id": "358",
    "type": "drink",
    "|| name                                               ||": "Tea, unsweetened",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "black / green / red",
    "info-examples": "black, green, red",
    "volume-ml": "250",
    "calories": "4",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "8",
    "carbs": "0.5",
    "fibre": "0",
    "sugar": "0",
    "protein": "0.5",
    "cal-from-protein": "2",
    "per-cal-from-protein": "50",
    "potassium": "68",
    "calcium": "8",
    "iron": "0.5",
    "phosphorus": "5",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "563",
    "type": "drink",
    "|| name                                               ||": "Teh",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "tea with condensed milk",
    "info-translation": "Tea with condensed milk",
    "weight-g": "260",
    "calories": "108.4",
    "fat": "2.4",
    "cal-from-fat": "21.6",
    "per-cal-from-fat": "20",
    "saturated": "0.5",
    "polyunsaturated": "0.3",
    "monounsaturated": "1.4",
    "cholesterol": "5",
    "sodium": "21",
    "carbs": "20",
    "fibre": "0",
    "sugar": "20",
    "protein": "1.7",
    "cal-from-protein": "7",
    "per-cal-from-protein": "6",
    "potassium": "52",
    "calcium": "55",
    "iron": "0",
    "phosphorus": "43",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "564",
    "type": "drink",
    "|| name                                               ||": "Teh C",
    "rating": "2",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "tea with evaporated milk, with sugar",
    "info-translation": "Tea with evaporated milk and sugar",
    "alternate-spellings": "C: Si",
    "weight-g": "278",
    "calories": "89",
    "fat": "2.6",
    "cal-from-fat": "23.4",
    "per-cal-from-fat": "26",
    "saturated": "1.7",
    "polyunsaturated": "0.5",
    "monounsaturated": "0.4",
    "cholesterol": "9",
    "sodium": "2",
    "carbs": "14.2",
    "fibre": "0",
    "sugar": "10",
    "protein": "2.2",
    "cal-from-protein": "9",
    "per-cal-from-protein": "10",
    "potassium": "124",
    "calcium": "5",
    "iron": "0.1",
    "phosphorus": "7",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "569",
    "type": "drink",
    "|| name                                               ||": "Teh C kosong",
    "rating": "3",
    "high-iodine": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "tea with evaporated milk, without sugar",
    "info-translation": "Tea with evaporated milk, without sugar",
    "alternate-spellings": "C: Si",
    "weight-g": "250",
    "calories": "44.9",
    "fat": "2.5",
    "cal-from-fat": "22.5",
    "per-cal-from-fat": "50",
    "saturated": "1.6",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.7",
    "cholesterol": "8",
    "sodium": "32",
    "carbs": "3.1",
    "fibre": "0",
    "sugar": "3",
    "protein": "2.5",
    "cal-from-protein": "10",
    "per-cal-from-protein": "22",
    "potassium": "154",
    "calcium": "80",
    "iron": "0.1",
    "phosphorus": "69",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "566",
    "type": "drink",
    "|| name                                               ||": "Teh C siew dai",
    "rating": "2",
    "high-iodine": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "tea with evaporated milk, less sugar",
    "info-translation": "Tea with evaporated milk, less sugar",
    "alternate-spellings": "C: Si\nSiew: Siu",
    "weight-g": "250",
    "calories": "68.9",
    "fat": "2.5",
    "cal-from-fat": "22.5",
    "per-cal-from-fat": "33",
    "saturated": "1.6",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.7",
    "cholesterol": "8",
    "sodium": "32",
    "carbs": "9.1",
    "fibre": "0",
    "sugar": "9.1",
    "protein": "2.5",
    "cal-from-protein": "10",
    "per-cal-from-protein": "15",
    "potassium": "154",
    "calcium": "80",
    "iron": "0.1",
    "phosphorus": "69",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "567",
    "type": "drink",
    "|| name                                               ||": "Teh O",
    "rating": "2",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "black tea with sugar",
    "info-translation": "Tea with sugar",
    "weight-g": "250",
    "calories": "60",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "carbs": "15",
    "fibre": "0",
    "sugar": "15",
    "protein": "0.3",
    "cal-from-protein": "1",
    "per-cal-from-protein": "2",
    "potassium": "43",
    "calcium": "0",
    "iron": "0",
    "phosphorus": "3",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "568",
    "type": "drink",
    "|| name                                               ||": "Teh O kosong",
    "rating": "3",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "black tea without sugar",
    "info-translation": "Tea without sugar",
    "weight-g": "250",
    "calories": "1.2",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "0.3",
    "cal-from-protein": "1",
    "per-cal-from-protein": "100",
    "potassium": "43",
    "calcium": "0",
    "iron": "0",
    "phosphorus": "3",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "565",
    "type": "drink",
    "|| name                                               ||": "Teh siew dai",
    "rating": "2",
    "high-iodine": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "tea with less condensed milk",
    "info-translation": "Tea with less condensed milk",
    "alternate-spellings": "Siew: Siu",
    "weight-g": "250",
    "calories": "54.4",
    "fat": "1.2",
    "cal-from-fat": "10.8",
    "per-cal-from-fat": "20",
    "saturated": "0.3",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.7",
    "cholesterol": "3",
    "sodium": "11",
    "carbs": "10",
    "fibre": "0",
    "sugar": "10",
    "protein": "0.9",
    "cal-from-protein": "4",
    "per-cal-from-protein": "7",
    "potassium": "48",
    "calcium": "28",
    "iron": "0",
    "phosphorus": "22",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "541",
    "type": "meal",
    "|| name                                               ||": "Temaki",
    "rating": "2",
    "high-iodine": "1",
    "high-potassium": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "cone-shaped hand roll, e.g. california temaki",
    "info-translation": "Hand rolled sushi",
    "info-examples": "california temaki",
    "weight-g": "60",
    "calories": "105.7",
    "fat": "2.1",
    "cal-from-fat": "18.9",
    "per-cal-from-fat": "18",
    "saturated": "1.1",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.6",
    "cholesterol": "5",
    "sodium": "158",
    "carbs": "17.6",
    "fibre": "0.6",
    "sugar": "1.1",
    "protein": "4.1",
    "cal-from-protein": "16",
    "per-cal-from-protein": "16",
    "potassium": "44",
    "calcium": "7",
    "iron": "0",
    "phosphorus": "39",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "434",
    "type": "meal",
    "|| name                                               ||": "Tempeh, fried",
    "rating": "3",
    "fried": "1",
    "high-phosphate": "1",
    "probiotic": "1",
    "plant-protein": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "35",
    "calories": "102.4",
    "fat": "8",
    "cal-from-fat": "72",
    "per-cal-from-fat": "70",
    "saturated": "1.3",
    "polyunsaturated": "4.7",
    "monounsaturated": "1.7",
    "cholesterol": "0",
    "sodium": "84",
    "carbs": "2.5",
    "fibre": "2.5",
    "sugar": "0",
    "protein": "5.1",
    "cal-from-protein": "20",
    "per-cal-from-protein": "20",
    "potassium": "28",
    "calcium": "18",
    "iron": "0.4",
    "phosphorus": "54",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "327",
    "type": "meal",
    "|| name                                               ||": "Tempura, prawn",
    "rating": "2",
    "fried": "1",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "33",
    "calories": "122.2",
    "fat": "8.2",
    "cal-from-fat": "73.8",
    "per-cal-from-fat": "60",
    "saturated": "3.7",
    "polyunsaturated": "0.8",
    "monounsaturated": "3.4",
    "cholesterol": "12.3",
    "sodium": "42.7",
    "carbs": "8.6",
    "fibre": "0.4",
    "sugar": "0",
    "protein": "3.5",
    "cal-from-protein": "14",
    "per-cal-from-protein": "11",
    "potassium": "51.3",
    "calcium": "11.3",
    "iron": "0.3",
    "phosphorus": "43.7",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "991",
    "type": "meal",
    "|| name                                               ||": "Tempura, seafood",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "processed": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "includes prawns / fish",
    "info-examples": "prawns, fish",
    "weight-g": "25",
    "calories": "52.3",
    "fat": "2.9",
    "cal-from-fat": "26.1",
    "per-cal-from-fat": "50",
    "saturated": "0.485",
    "polyunsaturated": "1.11",
    "monounsaturated": "1.11",
    "cholesterol": "37",
    "sodium": "112",
    "carbs": "2.76",
    "fibre": "0.15",
    "sugar": "0.228",
    "protein": "3.79",
    "cal-from-protein": "15",
    "per-cal-from-protein": "29",
    "potassium": "35.5",
    "calcium": "19.8",
    "iron": "0.265",
    "phosphorus": "64.5",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "328",
    "type": "meal",
    "|| name                                               ||": "Tempura, vegetable",
    "rating": "2",
    "fried": "1",
    "high-iodine": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "30",
    "calories": "69.4",
    "fat": "4.6",
    "cal-from-fat": "41.4",
    "per-cal-from-fat": "60",
    "saturated": "0.7",
    "polyunsaturated": "1.8",
    "monounsaturated": "1.9",
    "cholesterol": "10.7",
    "sodium": "3.7",
    "carbs": "5.9",
    "fibre": "0.5",
    "sugar": "0.5",
    "protein": "1.1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "6",
    "potassium": "48.3",
    "calcium": "6.3",
    "iron": "0.4",
    "phosphorus": "17.7",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "29",
    "type": "snack",
    "|| name                                               ||": "Teochew yam paste with ginkgo nut and pumpkin",
    "rating": "2",
    "high-gi": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Orh ni",
    "info-translation": "Orh Ni",
    "alternate-spellings": "Orh: Or  \nNi: Nee",
    "weight-g": "200",
    "calories": "475.6",
    "fat": "24.4",
    "cal-from-fat": "219.6",
    "per-cal-from-fat": "46",
    "saturated": "10.2",
    "polyunsaturated": "3.2",
    "monounsaturated": "10",
    "cholesterol": "18",
    "sodium": "2",
    "carbs": "61",
    "fibre": "4.8",
    "sugar": "41.2",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "3",
    "potassium": "496",
    "calcium": "52",
    "iron": "1",
    "phosphorus": "74",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "520",
    "type": "meal",
    "|| name                                               ||": "Ter kar beehoon",
    "rating": "2",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "pig trotters rice vermicelli",
    "info-translation": "Pig trotters rice vermicelli",
    "weight-g": "300",
    "calories": "457",
    "fat": "18.6",
    "cal-from-fat": "167.4",
    "per-cal-from-fat": "37",
    "saturated": "7",
    "polyunsaturated": "2.3",
    "monounsaturated": "8.6",
    "cholesterol": "128",
    "sodium": "1150",
    "carbs": "48",
    "fibre": "6.7",
    "sugar": "2.2",
    "protein": "23",
    "cal-from-protein": "92",
    "per-cal-from-protein": "20",
    "potassium": "204",
    "calcium": "63",
    "iron": "2.1",
    "phosphorus": "148",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "329",
    "type": "meal",
    "|| name                                               ||": "Teriyaki chicken don",
    "rating": "2",
    "high-salt": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Teriyaki chicken with rice",
    "info-translation": "Japanese marinated chicken with rice",
    "weight-g": "406",
    "calories": "641.2",
    "fat": "23.6",
    "cal-from-fat": "212.4",
    "per-cal-from-fat": "33",
    "saturated": "7.8",
    "polyunsaturated": "3.7",
    "monounsaturated": "11.1",
    "cholesterol": "179",
    "sodium": "946",
    "carbs": "73.5",
    "fibre": "5.7",
    "sugar": "8.1",
    "protein": "33.7",
    "cal-from-protein": "135",
    "per-cal-from-protein": "21",
    "potassium": "353",
    "calcium": "85",
    "iron": "0.8",
    "phosphorus": "260",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "526",
    "type": "drink",
    "|| name                                               ||": "Thai milk tea",
    "rating": "1",
    "high-calcium": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "527",
    "calories": "300",
    "fat": "10.4",
    "cal-from-fat": "93.6",
    "per-cal-from-fat": "31",
    "saturated": "6.8",
    "sodium": "79",
    "carbs": "49.5",
    "fibre": "1.6",
    "protein": "2.6",
    "cal-from-protein": "10",
    "per-cal-from-protein": "3",
    "source": "HPB",
    "old units (ref)": "glass"
  },
  {
    "id": "602",
    "type": "snack",
    "|| name                                               ||": "Thin egg wafer rolls",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "processed": "1",
    "serving": "7",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. Julie's love letter",
    "info-brands": "Julie's love letter",
    "weight-g": "30",
    "calories": "141",
    "fat": "5",
    "cal-from-fat": "45",
    "per-cal-from-fat": "32",
    "saturated": "2.5",
    "polyunsaturated": "1.5",
    "monounsaturated": "1",
    "cholesterol": "0",
    "sodium": "65",
    "carbs": "22",
    "fibre": "0",
    "sugar": "12",
    "protein": "2",
    "cal-from-protein": "8",
    "per-cal-from-protein": "6",
    "calcium": "20",
    "iron": "0",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "885",
    "type": "meal",
    "|| name                                               ||": "Thosai, egg",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "alternate-spellings": "Thosai: Dosa, Tosei",
    "weight-g": "98",
    "calories": "167.9",
    "fat": "7.1",
    "cal-from-fat": "63.9",
    "per-cal-from-fat": "38",
    "saturated": "2.4",
    "polyunsaturated": "0.8",
    "monounsaturated": "3.3",
    "cholesterol": "204",
    "sodium": "320",
    "carbs": "18",
    "fibre": "0.6",
    "sugar": "1.5",
    "protein": "8",
    "cal-from-protein": "32",
    "per-cal-from-protein": "19",
    "potassium": "110",
    "calcium": "37",
    "iron": "1.3",
    "phosphorus": "124",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "883",
    "type": "meal",
    "|| name                                               ||": "Thosai, masala",
    "rating": "2",
    "high-iron": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "alternate-spellings": "Thosai: Dosa, Tosei",
    "weight-g": "225",
    "calories": "363.7",
    "fat": "13.3",
    "cal-from-fat": "119.7",
    "per-cal-from-fat": "33",
    "saturated": "5.8",
    "polyunsaturated": "1.5",
    "monounsaturated": "5.4",
    "cholesterol": "0",
    "sodium": "779",
    "carbs": "52.4",
    "fibre": "4.5",
    "sugar": "3.2",
    "protein": "8.6",
    "cal-from-protein": "34",
    "per-cal-from-protein": "9",
    "potassium": "488",
    "calcium": "32",
    "iron": "3.1",
    "phosphorus": "122",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "884",
    "type": "meal",
    "|| name                                               ||": "Thosai, onion",
    "rating": "2",
    "serving": "1",
    "|| unit ||": "piece",
    "alternate-spellings": "Thosai: Dosa, Tosei",
    "weight-g": "65",
    "calories": "101.2",
    "fat": "2",
    "cal-from-fat": "18",
    "per-cal-from-fat": "18",
    "saturated": "0.9",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.8",
    "cholesterol": "0",
    "sodium": "266",
    "carbs": "18.5",
    "fibre": "1",
    "sugar": "1.9",
    "protein": "2.3",
    "cal-from-protein": "9",
    "per-cal-from-protein": "9",
    "potassium": "70",
    "calcium": "12",
    "iron": "0.1",
    "phosphorus": "37",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "886",
    "type": "meal",
    "|| name                                               ||": "Thosai, paper",
    "rating": "2",
    "serving": "1",
    "|| unit ||": "piece",
    "alternate-spellings": "Thosai: Dosa, Tosei",
    "weight-g": "50",
    "calories": "145",
    "fat": "5",
    "cal-from-fat": "45",
    "per-cal-from-fat": "31",
    "saturated": "2",
    "polyunsaturated": "0",
    "monounsaturated": "1",
    "cholesterol": "0",
    "sodium": "219",
    "carbs": "22",
    "fibre": "1",
    "sugar": "0",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "8",
    "potassium": "83",
    "calcium": "7",
    "iron": "0",
    "phosphorus": "49",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "264",
    "type": "meal",
    "|| name                                               ||": "Thosai, plain",
    "rating": "2",
    "high-potassium": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "alternate-spellings": "Thosai: Dosa, Tosei",
    "weight-g": "45",
    "calories": "96.4",
    "fat": "2",
    "cal-from-fat": "18",
    "per-cal-from-fat": "19",
    "saturated": "0.9",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.8",
    "cholesterol": "0",
    "sodium": "264",
    "carbs": "17.6",
    "fibre": "0.6",
    "sugar": "1",
    "protein": "2",
    "cal-from-protein": "8",
    "per-cal-from-protein": "8",
    "potassium": "37",
    "calcium": "8",
    "iron": "0",
    "phosphorus": "29",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "793",
    "type": "meal",
    "|| name                                               ||": "Thunder tea rice",
    "rating": "3",
    "high-fibre": "1",
    "plant-protein": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "440",
    "calories": "498",
    "fat": "18",
    "cal-from-fat": "162",
    "per-cal-from-fat": "33",
    "cholesterol": "0",
    "sodium": "43",
    "carbs": "66",
    "fibre": "11",
    "sugar": "0",
    "protein": "18",
    "cal-from-protein": "72",
    "per-cal-from-protein": "14",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "663",
    "type": "meal",
    "|| name                                               ||": "Ti wan chye",
    "rating": "3",
    "high-calcium": "1",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "info-translation": "Emperor vegetable",
    "alternate-spellings": "Ti: Di \nWan: Wang\nChye: Choy, Cai",
    "weight-g": "100",
    "calories": "83.4",
    "fat": "5",
    "cal-from-fat": "45",
    "per-cal-from-fat": "54",
    "saturated": "0.7",
    "polyunsaturated": "3.2",
    "monounsaturated": "1",
    "cholesterol": "0",
    "sodium": "350",
    "carbs": "5.8",
    "fibre": "3.1",
    "sugar": "3.4",
    "protein": "3.8",
    "cal-from-protein": "15",
    "per-cal-from-protein": "18",
    "potassium": "369",
    "calcium": "107",
    "iron": "2",
    "phosphorus": "48",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "72",
    "type": "snack",
    "|| name                                               ||": "Tiramisu",
    "rating": "2",
    "alcohol": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "131",
    "calories": "344.7",
    "fat": "19.5",
    "cal-from-fat": "175.5",
    "per-cal-from-fat": "51",
    "saturated": "11.4",
    "polyunsaturated": "1.7",
    "monounsaturated": "4.8",
    "cholesterol": "68",
    "sodium": "148",
    "carbs": "35.2",
    "fibre": "1.2",
    "sugar": "14.3",
    "protein": "7.1",
    "cal-from-protein": "28",
    "per-cal-from-protein": "8",
    "potassium": "157",
    "calcium": "69",
    "iron": "0.7",
    "phosphorus": "157",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "837",
    "type": "meal",
    "|| name                                               ||": "Agedashi tofu (fried)",
    "rating": "2",
    "fried": "1",
    "high-calcium": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "plant-protein": "1",
    "serving": "2",
    "|| unit ||": "piece",
    "info-translation": "Japanese fried beancurd",
    "weight-g": "26",
    "calories": "70",
    "fat": "5.2",
    "cal-from-fat": "46.8",
    "per-cal-from-fat": "67",
    "saturated": "0.8",
    "polyunsaturated": "3",
    "monounsaturated": "1.2",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "4",
    "carbs": "2.3",
    "fibre": "1",
    "sugar": "0.7",
    "protein": "4.9",
    "cal-from-protein": "20",
    "per-cal-from-protein": "28",
    "potassium": "38",
    "calcium": "97",
    "iron": "1.2",
    "phosphorus": "75",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "782",
    "type": "meal",
    "|| name                                               ||": "Tofu, claypot",
    "rating": "3",
    "high-calcium": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "|| info                                                             ||": "1/2 claypot",
    "info-portion-aid": "3\" x 3\" x 1\" ",
    "weight-g": "150",
    "calories": "208.9",
    "fat": "15.3",
    "cal-from-fat": "137.7",
    "per-cal-from-fat": "66",
    "saturated": "3.1",
    "polyunsaturated": "1.1",
    "monounsaturated": "3.15",
    "cholesterol": "0",
    "sodium": "341",
    "carbs": "12.4",
    "fibre": "0.9",
    "sugar": "1.35",
    "protein": "5.4",
    "cal-from-protein": "22",
    "per-cal-from-protein": "10",
    "potassium": "69",
    "calcium": "42",
    "iron": "0.6",
    "phosphorus": "67.5",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "781",
    "type": "meal",
    "|| name                                               ||": "Tofu, hot plate",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "|| info                                                             ||": "1/2 hot plate",
    "info-portion-aid": "3\" x 3\" x 1\" ",
    "weight-g": "120",
    "calories": "224.5",
    "fat": "20.5",
    "cal-from-fat": "184.5",
    "per-cal-from-fat": "82",
    "saturated": "8.2",
    "polyunsaturated": "2.9",
    "monounsaturated": "8.8",
    "cholesterol": "168",
    "sodium": "456",
    "carbs": "1.1",
    "fibre": "0.6",
    "sugar": "0",
    "protein": "8.9",
    "cal-from-protein": "36",
    "per-cal-from-protein": "16",
    "potassium": "118",
    "calcium": "36",
    "iron": "0",
    "phosphorus": "142",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "840",
    "type": "meal",
    "|| name                                               ||": "Tom yum fried rice",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "272",
    "calories": "441",
    "fat": "16.8",
    "cal-from-fat": "151.2",
    "per-cal-from-fat": "34",
    "saturated": "7",
    "polyunsaturated": "1.6",
    "monounsaturated": "6.4",
    "cholesterol": "92",
    "sodium": "788",
    "carbs": "54.3",
    "fibre": "2.4",
    "sugar": "7.5",
    "protein": "17.5",
    "cal-from-protein": "70",
    "per-cal-from-protein": "16",
    "potassium": "259",
    "calcium": "122",
    "iron": "4.5",
    "phosphorus": "54",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "330",
    "type": "meal",
    "|| name                                               ||": "Soup, tom yum chicken",
    "rating": "2",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "with chicken / prawn / seafood",
    "weight-g": "539",
    "calories": "263",
    "fat": "9",
    "cal-from-fat": "81",
    "per-cal-from-fat": "31",
    "saturated": "1",
    "polyunsaturated": "1",
    "monounsaturated": "2",
    "cholesterol": "34",
    "sodium": "550",
    "carbs": "23",
    "fibre": "3",
    "sugar": "6",
    "protein": "20",
    "cal-from-protein": "80",
    "per-cal-from-protein": "30",
    "potassium": "605",
    "calcium": "32",
    "iron": "1",
    "phosphorus": "186",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "841",
    "type": "meal",
    "|| name                                               ||": "Tom yum noodle soup",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "342",
    "calories": "421",
    "fat": "4.4",
    "cal-from-fat": "39.6",
    "per-cal-from-fat": "9",
    "saturated": "3",
    "polyunsaturated": "0.5",
    "monounsaturated": "0.2",
    "cholesterol": "155",
    "sodium": "1215",
    "carbs": "64.2",
    "fibre": "2.9",
    "sugar": "5.4",
    "protein": "29.8",
    "cal-from-protein": "119",
    "per-cal-from-protein": "28",
    "potassium": "123",
    "calcium": "24",
    "iron": "4.5",
    "phosphorus": "63",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "700",
    "type": "meal",
    "|| name                                               ||": "Tomato, paste / puree",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "serving": "2",
    "|| unit ||": "tbsp",
    "weight-g": "44",
    "calories": "30",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "6",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "280",
    "carbs": "4.6",
    "fibre": "1.8",
    "sugar": "4.4",
    "protein": "1.4",
    "cal-from-protein": "6",
    "per-cal-from-protein": "19",
    "potassium": "426",
    "calcium": "12",
    "iron": "0.8",
    "phosphorus": "30",
    "source": "N Buddy",
    "old units (ref)": "tablespoon"
  },
  {
    "id": "631",
    "type": "meal",
    "|| name                                               ||": "Tomatoes, raw",
    "rating": "3",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "tomato",
    "|| info                                                             ||": "Raw",
    "weight-g": "111",
    "calories": "18",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "10",
    "saturated": "0",
    "polyunsaturated": "0.1",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "47",
    "carbs": "3.5",
    "fibre": "1",
    "protein": "1.3",
    "cal-from-protein": "5",
    "per-cal-from-protein": "29",
    "potassium": "235",
    "calcium": "6",
    "iron": "0.5",
    "phosphorus": "32",
    "source": "USDA",
    "old units (ref)": "whole"
  },
  {
    "id": "144",
    "type": "meal",
    "|| name                                               ||": "Tonkatsu",
    "rating": "2",
    "fried": "1",
    "high-cholesterol": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Japanese deep fried pork cutlet",
    "info-translation": "Japanese deep fried pork cutlet",
    "weight-g": "89",
    "calories": "311.5",
    "fat": "22.3",
    "cal-from-fat": "200.7",
    "per-cal-from-fat": "64",
    "saturated": "9.1",
    "polyunsaturated": "2.3",
    "monounsaturated": "9.8",
    "cholesterol": "43",
    "sodium": "241",
    "carbs": "10.8",
    "fibre": "0",
    "sugar": "0.2",
    "protein": "16.9",
    "cal-from-protein": "68",
    "per-cal-from-protein": "22",
    "potassium": "259",
    "calcium": "26",
    "iron": "1.3",
    "phosphorus": "174",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "854",
    "type": "meal",
    "|| name                                               ||": "Tonkotsu ramen with pork",
    "rating": "2",
    "high-fat": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "500",
    "calories": "552",
    "fat": "20",
    "cal-from-fat": "180",
    "per-cal-from-fat": "33",
    "saturated": "10",
    "polyunsaturated": "6",
    "monounsaturated": "4",
    "cholesterol": "100",
    "sodium": "520",
    "carbs": "75",
    "fibre": "2",
    "sugar": "8",
    "protein": "18",
    "cal-from-protein": "72",
    "per-cal-from-protein": "13",
    "potassium": "75",
    "calcium": "40",
    "iron": "1.2",
    "phosphorus": "63",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "858",
    "type": "meal",
    "|| name                                               ||": "Tori katsu",
    "rating": "2",
    "fried": "1",
    "high-fat": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "processed": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "info-translation": "Japanese deep fried chicken cutlet",
    "weight-g": "137",
    "calories": "332",
    "fat": "11",
    "cal-from-fat": "99",
    "per-cal-from-fat": "30",
    "saturated": "2",
    "polyunsaturated": "2.5",
    "monounsaturated": "5.9",
    "cholesterol": "125",
    "sodium": "281",
    "carbs": "23",
    "fibre": "1.3",
    "sugar": "1.7",
    "protein": "32",
    "cal-from-protein": "128",
    "per-cal-from-protein": "39",
    "potassium": "297",
    "source": "Nutritionix",
    "old units (ref)": "plate"
  },
  {
    "id": "1051",
    "type": "snack",
    "|| name                                               ||": "Tortilla chips",
    "rating": "1",
    "fried": "1",
    "high-fat": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "14",
    "|| unit ||": "piece",
    "|| info                                                             ||": " e.g. Doritos nacho cheese, smokin' BBQ",
    "info-brands": "Doritos",
    "info-examples": "nacho cheese, smoking BBQ",
    "weight-g": "33",
    "calories": "168",
    "fat": "7.9",
    "cal-from-fat": "71.1",
    "per-cal-from-fat": "42",
    "saturated": "4",
    "polyunsaturated": "2",
    "monounsaturated": "1.9",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "282",
    "carbs": "21.8",
    "fibre": "0.5",
    "sugar": "3",
    "protein": "2.3",
    "cal-from-protein": "9",
    "per-cal-from-protein": "5",
    "potassium": "59",
    "calcium": "48",
    "iron": "0.6",
    "phosphorus": "55",
    "source": "N Buddy",
    "old units (ref)": "chips"
  },
  {
    "id": "194",
    "type": "snack",
    "|| name                                               ||": "Traditional ice cream with bread",
    "rating": "2",
    "fried": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "128",
    "calories": "283",
    "fat": "12",
    "cal-from-fat": "108",
    "per-cal-from-fat": "38",
    "saturated": "7",
    "polyunsaturated": "0.7",
    "monounsaturated": "3.3",
    "cholesterol": "34",
    "sodium": "210",
    "carbs": "40",
    "fibre": "3.8",
    "sugar": "27",
    "protein": "6.8",
    "cal-from-protein": "27",
    "per-cal-from-protein": "10",
    "potassium": "285",
    "calcium": "301",
    "iron": "2.3",
    "phosphorus": "136",
    "iodine": "1",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "212",
    "type": "snack",
    "|| name                                               ||": "Traditional ice cream with wafer",
    "rating": "2",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "129",
    "calories": "337",
    "fat": "13",
    "cal-from-fat": "117",
    "per-cal-from-fat": "35",
    "saturated": "7.2",
    "polyunsaturated": "1.3",
    "monounsaturated": "3.7",
    "cholesterol": "34",
    "sodium": "150",
    "carbs": "51.1",
    "fibre": "2.1",
    "sugar": "27.1",
    "protein": "6.2",
    "cal-from-protein": "25",
    "per-cal-from-protein": "7",
    "potassium": "277",
    "calcium": "116",
    "iron": "1.9",
    "phosphorus": "135",
    "iodine": "1",
    "source": "USDA",
    "old units (ref)": "piece"
  },
  {
    "id": "46",
    "type": "snack",
    "|| name                                               ||": "Traditional waffle with filling",
    "rating": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. peanut butter / chocolate / kaya / jam",
    "info-examples": "peanut butter, chocolate, kaya,  jam",
    "weight-g": "125",
    "calories": "499.9",
    "fat": "27.1",
    "cal-from-fat": "243.9",
    "per-cal-from-fat": "49",
    "saturated": "7.2",
    "polyunsaturated": "7.4",
    "monounsaturated": "11",
    "cholesterol": "53",
    "sodium": "408",
    "carbs": "55",
    "fibre": "1.2",
    "sugar": "36.9",
    "protein": "9",
    "cal-from-protein": "36",
    "per-cal-from-protein": "7",
    "potassium": "314",
    "calcium": "256",
    "iron": "2.8",
    "phosphorus": "233",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "685",
    "type": "meal",
    "|| name                                               ||": "Turnip, boiled / steamed",
    "rating": "3",
    "high-calcium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "100",
    "calories": "30.96",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0.1",
    "polyunsaturated": "0.16",
    "monounsaturated": "0.02",
    "cholesterol": "0",
    "sodium": "255",
    "carbs": "4.74",
    "fibre": "3.1",
    "sugar": "0.95",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "39",
    "potassium": "216",
    "calcium": "128",
    "iron": "1.75",
    "phosphorus": "32",
    "source": "US FDA",
    "old units (ref)": "serving"
  },
  {
    "id": "219",
    "type": "snack",
    "|| name                                               ||": "Tutu kueh, coconut",
    "rating": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "alternate-spellings": "Kueh: Kuih, Kue",
    "weight-g": "20",
    "calories": "55.9",
    "fat": "1.1",
    "cal-from-fat": "9.9",
    "per-cal-from-fat": "18",
    "saturated": "1",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "1",
    "carbs": "10.7",
    "fibre": "0.3",
    "sugar": "4.5",
    "protein": "0.8",
    "cal-from-protein": "3",
    "per-cal-from-protein": "6",
    "calcium": "1",
    "iron": "0.1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "220",
    "type": "snack",
    "|| name                                               ||": "Tutu kueh, peanut",
    "rating": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "alternate-spellings": "Kueh: Kuih, Kue",
    "weight-g": "18",
    "calories": "57.3",
    "fat": "1.3",
    "cal-from-fat": "11.7",
    "per-cal-from-fat": "20",
    "saturated": "0.3",
    "polyunsaturated": "0.5",
    "monounsaturated": "0.5",
    "cholesterol": "0",
    "sodium": "1",
    "carbs": "10",
    "fibre": "0.3",
    "sugar": "4.5",
    "protein": "1.4",
    "cal-from-protein": "6",
    "per-cal-from-protein": "10",
    "calcium": "2",
    "iron": "0.1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "1049",
    "type": "snack",
    "|| name                                               ||": "Twisties",
    "rating": "1",
    "high-fat": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "minipack",
    "|| info                                                             ||": "e.g. chicken flavor, BBQ curry, cheddar cheese",
    "info-examples": "chicken, BBQ curry, cheddar cheese flavours",
    "weight-g": "15",
    "calories": "80",
    "fat": "4.4",
    "cal-from-fat": "39.6",
    "per-cal-from-fat": "50",
    "saturated": "3",
    "polyunsaturated": "0.7",
    "monounsaturated": "0.7",
    "sodium": "93",
    "carbs": "9.2",
    "sugar": "1",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "5",
    "source": "N Buddy",
    "old units (ref)": "packet"
  },
  {
    "id": "262",
    "type": "meal",
    "|| name                                               ||": "Udang pedas nenas",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Curry with prawn / shrimp and pineapple (bowl-soup 16.5cm)",
    "info-translation": "Pineapple prawn curry",
    "info-portion-aid": "16.5cm bowl",
    "weight-g": "176",
    "calories": "121.3",
    "fat": "9.9",
    "cal-from-fat": "89.1",
    "per-cal-from-fat": "73",
    "saturated": "4.9",
    "cholesterol": "47",
    "sodium": "469",
    "carbs": "2.8",
    "fibre": "1.5",
    "protein": "5.1",
    "cal-from-protein": "20",
    "per-cal-from-protein": "17",
    "iodine": "1",
    "source": "HPB",
    "old units (ref)": "portion"
  },
  {
    "id": "145",
    "type": "meal",
    "|| name                                               ||": "Udon with tempura",
    "rating": "2",
    "fried": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "616",
    "calories": "576.6",
    "fat": "29",
    "cal-from-fat": "261",
    "per-cal-from-fat": "45",
    "saturated": "12.2",
    "polyunsaturated": "4.2",
    "monounsaturated": "11",
    "cholesterol": "43",
    "sodium": "1830",
    "carbs": "63.5",
    "fibre": "5.5",
    "sugar": "7.4",
    "protein": "15.4",
    "cal-from-protein": "62",
    "per-cal-from-protein": "11",
    "potassium": "333",
    "calcium": "111",
    "iron": "3.1",
    "phosphorus": "129",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "335",
    "type": "meal",
    "|| name                                               ||": "Unagi don",
    "rating": "2",
    "high-iodine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Marinated eel with rice",
    "info-translation": "Japanese marinated eel with rice",
    "weight-g": "344",
    "calories": "627",
    "fat": "18.6",
    "cal-from-fat": "167.4",
    "per-cal-from-fat": "27",
    "saturated": "6.1",
    "polyunsaturated": "0.7",
    "monounsaturated": "9.8",
    "cholesterol": "120",
    "sodium": "925",
    "carbs": "91.2",
    "fibre": "5.2",
    "sugar": "6.2",
    "protein": "23.7",
    "cal-from-protein": "95",
    "per-cal-from-protein": "15",
    "potassium": "248",
    "calcium": "151",
    "iron": "0.7",
    "phosphorus": "230",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "521",
    "type": "meal",
    "|| name                                               ||": "Uttapam",
    "rating": "2",
    "high-phosphate": "1",
    "high-potassium": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Indian thick pancake",
    "info-translation": "Indian thick pancake",
    "weight-g": "110",
    "calories": "262.4",
    "fat": "5.2",
    "cal-from-fat": "46.8",
    "per-cal-from-fat": "18",
    "saturated": "1.2",
    "polyunsaturated": "0.7",
    "monounsaturated": "2.8",
    "cholesterol": "4",
    "sodium": "281",
    "carbs": "43.8",
    "fibre": "4",
    "sugar": "5.5",
    "protein": "10.1",
    "cal-from-protein": "40",
    "per-cal-from-protein": "15",
    "potassium": "537",
    "calcium": "142",
    "iron": "1.4",
    "phosphorus": "178",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "436",
    "disabled": "1",
    "type": "meal",
    "|| name                                               ||": "Vegetable (purple / brown e.g. beetroot, radish)",
    "rating": "3"
  },
  {
    "id": "437",
    "disabled": "1",
    "type": "meal",
    "|| name                                               ||": "Vegetable (red / orange e.g. carrot)",
    "rating": "3"
  },
  {
    "id": "506",
    "type": "meal",
    "|| name                                               ||": "Vongole",
    "rating": "2",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Linguine / spaghetti with clams",
    "info-translation": "Pasta with clams",
    "weight-g": "402",
    "calories": "233",
    "fat": "6.4",
    "cal-from-fat": "57.6",
    "per-cal-from-fat": "25",
    "saturated": "2.7",
    "sodium": "844",
    "carbs": "35",
    "fibre": "4.5",
    "protein": "9",
    "cal-from-protein": "36",
    "per-cal-from-protein": "15",
    "source": "HPB",
    "old units (ref)": "plate"
  },
  {
    "id": "609",
    "type": "snack",
    "|| name                                               ||": "Wafer crackers, plain",
    "rating": "2",
    "processed": "1",
    "serving": "6",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. Waterthins, Carr's table water crackers",
    "info-brands": "Waterthins, Carr's",
    "weight-g": "10",
    "calories": "39.8",
    "fat": "0.6",
    "cal-from-fat": "5.4",
    "per-cal-from-fat": "14",
    "saturated": "0.3",
    "sodium": "61",
    "carbs": "7.7",
    "fibre": "0",
    "sugar": "0.3",
    "protein": "0.9",
    "cal-from-protein": "4",
    "per-cal-from-protein": "9",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "45",
    "type": "snack",
    "|| name                                               ||": "Waffle with ice cream with syrup",
    "rating": "1",
    "high-fat": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "waffle",
    "|| info                                                             ||": "with syrup",
    "weight-g": "270",
    "calories": "758.7",
    "fat": "49.5",
    "cal-from-fat": "445.5",
    "per-cal-from-fat": "59",
    "saturated": "27.2",
    "polyunsaturated": "3.8",
    "monounsaturated": "16.7",
    "cholesterol": "176",
    "sodium": "482",
    "carbs": "69.1",
    "fibre": "1",
    "sugar": "41.1",
    "protein": "9.2",
    "cal-from-protein": "37",
    "per-cal-from-protein": "5",
    "potassium": "194",
    "calcium": "86",
    "iron": "2.9",
    "phosphorus": "258",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "serving"
  },
  {
    "id": "993",
    "type": "snack",
    "|| name                                               ||": "Waffle with ice cream, without syrup",
    "rating": "2",
    "high-fat": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "waffle",
    "|| info                                                             ||": "without syrup",
    "weight-g": "275",
    "calories": "455.2",
    "fat": "20.8",
    "cal-from-fat": "187.2",
    "per-cal-from-fat": "41",
    "saturated": "10",
    "polyunsaturated": "2.4",
    "monounsaturated": "7.3",
    "cholesterol": "44",
    "sodium": "152",
    "carbs": "58.8",
    "fibre": "2",
    "sugar": "27.2",
    "protein": "8.2",
    "cal-from-protein": "33",
    "per-cal-from-protein": "7",
    "potassium": "222",
    "calcium": "95",
    "iron": "1",
    "phosphorus": "164",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "228",
    "type": "snack",
    "|| name                                               ||": "Waffles, plain, without syrup",
    "rating": "2",
    "high-gi": "1",
    "high-phosphate": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "waffle",
    "|| info                                                             ||": "without syrup",
    "weight-g": "95",
    "calories": "353.1",
    "fat": "15.1",
    "cal-from-fat": "135.9",
    "per-cal-from-fat": "38",
    "saturated": "5.9",
    "polyunsaturated": "1.9",
    "monounsaturated": "6.3",
    "cholesterol": "32",
    "sodium": "121",
    "carbs": "47.9",
    "fibre": "1.7",
    "sugar": "16.3",
    "protein": "6.4",
    "cal-from-protein": "26",
    "per-cal-from-protein": "7",
    "potassium": "136",
    "calcium": "35",
    "iron": "0.9",
    "phosphorus": "114",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "534",
    "type": "snack",
    "|| name                                               ||": "Waffles, plain with syrup",
    "rating": "1",
    "high-gi": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "waffle",
    "|| info                                                             ||": "with syrup",
    "weight-g": "170",
    "calories": "545.9",
    "fat": "33.5",
    "cal-from-fat": "301.5",
    "per-cal-from-fat": "55",
    "saturated": "16.2",
    "polyunsaturated": "3.3",
    "monounsaturated": "12.2",
    "cholesterol": "96",
    "sodium": "437",
    "carbs": "54.9",
    "fibre": "1",
    "sugar": "22.1",
    "protein": "6.2",
    "cal-from-protein": "25",
    "per-cal-from-protein": "5",
    "potassium": "194",
    "calcium": "86",
    "iron": "2.9",
    "phosphorus": "258",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "1024",
    "type": "snack",
    "|| name                                               ||": "Walnut cake",
    "rating": "2",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "100",
    "calories": "333",
    "fat": "8.33",
    "cal-from-fat": "74.97",
    "per-cal-from-fat": "23",
    "cholesterol": "56",
    "sodium": "167",
    "carbs": "55.56",
    "fibre": "0",
    "sugar": "27.8",
    "protein": "11.11",
    "cal-from-protein": "44",
    "per-cal-from-protein": "13",
    "potassium": "111",
    "calcium": "56",
    "iron": "0.56",
    "source": "USDA",
    "old units (ref)": "slice"
  },
  {
    "id": "189",
    "type": "snack",
    "|| name                                               ||": "Walnut paste",
    "rating": "2",
    "healthy-fats": "1",
    "high-gi": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "He Tao Hu",
    "info-translation": "He Tao Hu",
    "alternate-spellings": "He: Hup\nTao: Tul\nHu: Wu, Woo",
    "weight-g": "259",
    "calories": "321.86",
    "fat": "21.94",
    "cal-from-fat": "197.46",
    "per-cal-from-fat": "61",
    "saturated": "2.4",
    "polyunsaturated": "15",
    "monounsaturated": "4.5",
    "sodium": "9",
    "carbs": "24.1",
    "fibre": "3.1",
    "sugar": "16.1",
    "protein": "7",
    "cal-from-protein": "28",
    "per-cal-from-protein": "9",
    "calcium": "35",
    "iron": "1.2",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "331",
    "type": "meal",
    "|| name                                               ||": "Wanton noodle / mian, dry",
    "rating": "2",
    "high-gi": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "alternate-spellings": "Wanton: Yuntun \nMian: Mee",
    "weight-g": "350",
    "calories": "431.4",
    "fat": "12.6",
    "cal-from-fat": "113.4",
    "per-cal-from-fat": "26",
    "saturated": "5.3",
    "polyunsaturated": "2.1",
    "monounsaturated": "4.6",
    "cholesterol": "39",
    "sodium": "1593",
    "carbs": "58.8",
    "fibre": "5.6",
    "sugar": "10.6",
    "protein": "20.7",
    "cal-from-protein": "83",
    "per-cal-from-protein": "19",
    "potassium": "250",
    "calcium": "42",
    "iron": "2.5",
    "phosphorus": "150",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "344",
    "type": "meal",
    "|| name                                               ||": "Wanton noodle / mian, soup",
    "rating": "2",
    "high-gi": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "alternate-spellings": "Wanton: Yuntun \nMian: Mee",
    "weight-g": "600",
    "calories": "318",
    "fat": "5.4",
    "cal-from-fat": "48.6",
    "per-cal-from-fat": "15",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "12",
    "sodium": "1986",
    "carbs": "45.6",
    "fibre": "1.8",
    "sugar": "4.6",
    "protein": "21",
    "cal-from-protein": "84",
    "per-cal-from-protein": "26",
    "calcium": "48",
    "iron": "1.8",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "758",
    "type": "meal",
    "|| name                                               ||": "Wanton with vinegar and chilli oil",
    "rating": "2",
    "high-salt": "1",
    "serving": "4",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Hong you chao shou",
    "info-translation": "Hong You Chao Shou",
    "alternate-spellings": "Wanton: Yuntun",
    "weight-g": "114",
    "calories": "259.9",
    "fat": "19.1",
    "cal-from-fat": "171.9",
    "per-cal-from-fat": "66",
    "saturated": "4.4",
    "polyunsaturated": "2.2",
    "monounsaturated": "5.8",
    "cholesterol": "36",
    "sodium": "1318",
    "carbs": "10.9",
    "fibre": "0",
    "sugar": "2.7",
    "protein": "11.1",
    "cal-from-protein": "44",
    "per-cal-from-protein": "17",
    "potassium": "172",
    "calcium": "16",
    "iron": "0.7",
    "phosphorus": "31",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "360",
    "disabled": "1",
    "type": "drink",
    "|| name                                               ||": "Water (flavoured, unsweetened)",
    "rating": "3"
  },
  {
    "id": "404",
    "type": "snack",
    "|| name                                               ||": "Water apple",
    "rating": "3",
    "fruit": "1",
    "serving": "2",
    "|| unit ||": "fruit",
    "|| info                                                             ||": "Jambu air, waxed apple",
    "info-translation": "Jambu / rose apple / wax apple",
    "weight-g": "100",
    "calories": "22.6",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "8",
    "saturated": "0.1",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.1",
    "cholesterol": "0",
    "sodium": "1",
    "carbs": "4.5",
    "fibre": "0.2",
    "sugar": "4.5",
    "protein": "0.7",
    "cal-from-protein": "3",
    "per-cal-from-protein": "12",
    "potassium": "38",
    "calcium": "13",
    "iron": "0.8",
    "phosphorus": "5",
    "source": "N Buddy",
    "old units (ref)": "medium"
  },
  {
    "id": "698",
    "type": "meal",
    "|| name                                               ||": "Water chestnut",
    "rating": "3",
    "fruit": "1",
    "high-potassium": "1",
    "serving": "10",
    "|| unit ||": "piece",
    "weight-g": "100",
    "calories": "78",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "1",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "12",
    "carbs": "19.2",
    "fibre": "3.9",
    "sugar": "3.8",
    "protein": "1.4",
    "cal-from-protein": "6",
    "per-cal-from-protein": "7",
    "potassium": "184",
    "calcium": "6",
    "iron": "1.4",
    "phosphorus": "30",
    "source": "USDA",
    "old units (ref)": "serving"
  },
  {
    "id": "915",
    "type": "meal",
    "|| name                                               ||": "Water chestnut cake, steamed",
    "rating": "2",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Ma tai gao",
    "info-translation": "Ma Tai Gao",
    "alternate-spellings": "Gao: Go\nTai: Ti",
    "weight-g": "89",
    "calories": "86.9",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "1",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "5",
    "carbs": "21.4",
    "fibre": "1.6",
    "sugar": "8.9",
    "protein": "0.1",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "52",
    "calcium": "1",
    "iron": "0.2",
    "phosphorus": "6",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "174",
    "type": "drink",
    "|| name                                               ||": "Water chestnut drink",
    "rating": "2",
    "high-gi": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "Ma tai shui",
    "info-translation": "Ma Tai Shui",
    "alternate-spellings": "Tai: Ti",
    "volume-ml": "250",
    "calories": "95.2",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "40",
    "carbs": "23.5",
    "sugar": "23.5",
    "protein": "0.3",
    "cal-from-protein": "1",
    "per-cal-from-protein": "1",
    "iron": "0",
    "phosphorus": "53",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "1005",
    "type": "snack",
    "|| name                                               ||": "Water chestnut with sweet corn dessert",
    "rating": "2",
    "high-gi": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "250",
    "calories": "360",
    "fat": "4",
    "cal-from-fat": "36",
    "per-cal-from-fat": "10",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "271",
    "carbs": "76",
    "fibre": "4",
    "sugar": "44",
    "protein": "5",
    "cal-from-protein": "20",
    "per-cal-from-protein": "6",
    "old units (ref)": "bowl"
  },
  {
    "id": "369",
    "type": "drink",
    "|| name                                               ||": "Water kefir",
    "rating": "3",
    "high-gi": "1",
    "probiotic": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "volume-ml": "100",
    "calories": "12",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "3",
    "carbs": "3",
    "fibre": "0",
    "sugar": "3",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "5",
    "calcium": "0",
    "iron": "0",
    "phosphorus": "0",
    "source": "N Buddy",
    "old units (ref)": "small cup"
  },
  {
    "id": "658",
    "type": "meal",
    "|| name                                               ||": "Watercress",
    "rating": "3",
    "high-calcium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "|| info                                                             ||": "Agriao, Berro, Cresson",
    "info-translation": "Agriao, Berro, Cresson",
    "weight-g": "100",
    "calories": "13.92",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "1.1",
    "polyunsaturated": "0.4",
    "monounsaturated": "0.6",
    "cholesterol": "0",
    "sodium": "284",
    "carbs": "1.25",
    "fibre": "0.5",
    "sugar": "0.2",
    "protein": "2.23",
    "cal-from-protein": "9",
    "per-cal-from-protein": "64",
    "potassium": "319",
    "calcium": "116",
    "iron": "0.2",
    "phosphorus": "58",
    "source": "US FDA",
    "old units (ref)": "serving"
  },
  {
    "id": "405",
    "type": "snack",
    "|| name                                               ||": "Watermelon",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "140",
    "calories": "41.6",
    "fat": "0.4",
    "cal-from-fat": "3.6",
    "per-cal-from-fat": "9",
    "saturated": "0",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.2",
    "cholesterol": "0",
    "sodium": "6",
    "carbs": "8.4",
    "fibre": "0.3",
    "sugar": "7.4",
    "protein": "1.1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "11",
    "potassium": "109",
    "calcium": "8",
    "iron": "0.3",
    "phosphorus": "15",
    "source": "N Buddy",
    "old units (ref)": "slice"
  },
  {
    "id": "218",
    "type": "snack",
    "|| name                                               ||": "Wheat porridge soup",
    "rating": "1",
    "high-gi": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Mai zhou, bubur gandum",
    "info-translation": "Mai Zhou, Bubur Gandum",
    "alternate-spellings": "Bubur: Bubor, Bobo",
    "weight-g": "250",
    "calories": "233",
    "fat": "2.8",
    "cal-from-fat": "25.2",
    "per-cal-from-fat": "11",
    "saturated": "2.5",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "49.3",
    "fibre": "2.3",
    "sugar": "24.5",
    "protein": "2.5",
    "cal-from-protein": "10",
    "per-cal-from-protein": "4",
    "calcium": "20",
    "iron": "0.8",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "1009",
    "type": "snack",
    "|| name                                               ||": "White fungus soup",
    "rating": "3",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "with papaya, longan or lotus seed",
    "info-include-exclude": "with papaya, longan or lotus seed",
    "weight-g": "240",
    "calories": "134",
    "fat": "1.2",
    "cal-from-fat": "10.8",
    "per-cal-from-fat": "8",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "22",
    "carbs": "30.7",
    "fibre": "1.2",
    "sugar": "28.1",
    "protein": "0.5",
    "cal-from-protein": "2",
    "per-cal-from-protein": "1",
    "potassium": "130",
    "calcium": "26",
    "iron": "0.2",
    "phosphorus": "14",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "135",
    "type": "meal",
    "|| name                                               ||": "Rice, white",
    "rating": "2",
    "high-gi": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Standard rice bowl",
    "weight-g": "180",
    "calories": "252",
    "fat": "0.9",
    "cal-from-fat": "8.1",
    "per-cal-from-fat": "3",
    "saturated": "0.2",
    "polyunsaturated": "0.4",
    "monounsaturated": "0.2",
    "cholesterol": "0",
    "sodium": "9",
    "carbs": "56",
    "fibre": "1.8",
    "sugar": "0.2",
    "protein": "5",
    "cal-from-protein": "20",
    "per-cal-from-protein": "8",
    "potassium": "18",
    "calcium": "0",
    "iron": "4.1",
    "phosphorus": "20",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "588",
    "type": "meal",
    "|| name                                               ||": "Whole grain cereal slice",
    "rating": "3",
    "high-phosphate": "1",
    "high-potassium": "1",
    "whole-grain": "1",
    "serving": "2",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. Sanitarium Weet-Bix",
    "info-brands": "Weet-Bix",
    "weight-g": "30",
    "calories": "70",
    "fat": "0.3",
    "cal-from-fat": "2.7",
    "per-cal-from-fat": "4",
    "saturated": "0.1",
    "sodium": "56.7",
    "carbs": "13.4",
    "fibre": "2.1",
    "sugar": "0.5",
    "protein": "2.4",
    "cal-from-protein": "10",
    "per-cal-from-protein": "14",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "31",
    "type": "snack",
    "|| name                                               ||": "Whole peanut soup with Tang Yuan",
    "rating": "2",
    "high-fibre": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "with tang yuan",
    "alternate-spellings": "Tang: Tong\r\nYuan: Yuen",
    "weight-g": "250",
    "calories": "444",
    "fat": "24.4",
    "cal-from-fat": "219.6",
    "per-cal-from-fat": "49",
    "saturated": "11.1",
    "polyunsaturated": "3.7",
    "monounsaturated": "8.3",
    "cholesterol": "0",
    "sodium": "25",
    "carbs": "44.4",
    "fibre": "6.8",
    "sugar": "22.5",
    "protein": "11.7",
    "cal-from-protein": "47",
    "per-cal-from-protein": "11",
    "potassium": "167",
    "calcium": "19",
    "iron": "3.5",
    "phosphorus": "111",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "216",
    "type": "snack",
    "|| name                                               ||": "Whole peanut soup, without Tang Yuan",
    "rating": "2",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "without tang yuan",
    "alternate-spellings": "Tang: Tong\r\nYuan: Yuen",
    "weight-g": "202",
    "calories": "254.8",
    "fat": "18.4",
    "cal-from-fat": "165.6",
    "per-cal-from-fat": "65",
    "saturated": "4",
    "polyunsaturated": "5.9",
    "monounsaturated": "7.9",
    "cholesterol": "0",
    "sodium": "48",
    "carbs": "15.6",
    "fibre": "4",
    "sugar": "14.3",
    "protein": "6.7",
    "cal-from-protein": "27",
    "per-cal-from-protein": "11",
    "potassium": "149",
    "calcium": "28",
    "iron": "0.4",
    "phosphorus": "105",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "611",
    "type": "meal",
    "|| name                                               ||": "Wholemeal sandwich",
    "rating": "3",
    "high-calcium": "1",
    "high-fat": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "vegetable": "1",
    "white-meat": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "sandwich",
    "|| info                                                             ||": "with chicken / tuna / egg and cheese / ham",
    "info-examples": "chicken, tuna, egg, cheese, ham",
    "weight-g": "124",
    "calories": "305.5",
    "fat": "21.7",
    "cal-from-fat": "195.3",
    "per-cal-from-fat": "64",
    "saturated": "3.7",
    "polyunsaturated": "3.15",
    "monounsaturated": "3.5",
    "cholesterol": "125.5",
    "sodium": "671.5",
    "carbs": "13.95",
    "fibre": "2.35",
    "sugar": "2.2",
    "protein": "13.6",
    "cal-from-protein": "54",
    "per-cal-from-protein": "18",
    "potassium": "170",
    "calcium": "108",
    "iron": "2.15",
    "phosphorus": "164.5",
    "source": "N Buddy",
    "old units (ref)": "sandwich"
  },
  {
    "id": "593",
    "type": "meal",
    "|| name                                               ||": "Wholemeal wrap with fillings",
    "rating": "3",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "wrap",
    "|| info                                                             ||": "with chicken / tuna / egg and cheese / ham",
    "info-examples": "chicken, tuna, egg, cheese, ham",
    "weight-g": "117",
    "calories": "380.9",
    "fat": "22.9",
    "cal-from-fat": "206.1",
    "per-cal-from-fat": "54",
    "saturated": "11.8",
    "polyunsaturated": "1.1",
    "monounsaturated": "7",
    "cholesterol": "60",
    "sodium": "884",
    "carbs": "24.6",
    "fibre": "1.5",
    "sugar": "2.3",
    "protein": "19.1",
    "cal-from-protein": "76",
    "per-cal-from-protein": "20",
    "potassium": "86",
    "calcium": "286",
    "iron": "0.4",
    "phosphorus": "224",
    "source": "N Buddy",
    "old units (ref)": "wrap"
  },
  {
    "id": "672",
    "type": "meal",
    "|| name                                               ||": "Salad / garden rocket",
    "rating": "3",
    "high-calcium": "1",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "|| info                                                             ||": "Arugula",
    "info-translation": "Arugula",
    "weight-g": "100",
    "calories": "12",
    "fat": "0.4",
    "cal-from-fat": "3.6",
    "per-cal-from-fat": "30",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "4",
    "fibre": "2",
    "sugar": "2",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "100",
    "source": "https://www.checkyourfood.com/ingredients/ingredient/917/wild-rocket-arugula",
    "old units (ref)": "cup"
  },
  {
    "id": "6",
    "type": "drink",
    "|| name                                               ||": "Wine, dessert",
    "rating": "1",
    "alcohol": "1",
    "high-gi": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "glass",
    "volume-ml": "250",
    "calories": "140",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "9.3",
    "carbs": "14",
    "fibre": "0",
    "sugar": "14",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "3",
    "source": "N Buddy",
    "old units (ref)": "glass"
  },
  {
    "id": "160",
    "type": "drink",
    "|| name                                               ||": "Wine, red",
    "rating": "2",
    "alcohol": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "serving": "1",
    "|| unit ||": "glass",
    "volume-ml": "148",
    "calories": "82",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "5.88",
    "carbs": "3.84",
    "fibre": "0",
    "sugar": "0.911",
    "protein": "0.103",
    "cal-from-protein": "0",
    "per-cal-from-protein": "1",
    "potassium": "187",
    "calcium": "11.8",
    "iron": "0.676",
    "phosphorus": "33.8",
    "source": "USDA",
    "old units (ref)": "glass"
  },
  {
    "id": "5",
    "type": "drink",
    "|| name                                               ||": "Wine, white",
    "rating": "2",
    "alcohol": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "serving": "1",
    "|| unit ||": "glass",
    "volume-ml": "120",
    "calories": "79",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "29",
    "carbs": "1.3",
    "fibre": "0",
    "sugar": "1.3",
    "protein": "0.2",
    "cal-from-protein": "1",
    "per-cal-from-protein": "1",
    "potassium": "55",
    "calcium": "8",
    "iron": "0.2",
    "phosphorus": "23",
    "source": "N Buddy",
    "old units (ref)": "glass"
  },
  {
    "id": "680",
    "type": "meal",
    "|| name                                               ||": "Winter melon",
    "rating": "3",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "info-translation": "Wax gourd, Dong Gua",
    "weight-g": "150",
    "calories": "81",
    "fat": "6.5",
    "cal-from-fat": "58.5",
    "per-cal-from-fat": "72",
    "saturated": "0",
    "polyunsaturated": "0.1",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "327",
    "carbs": "3",
    "fibre": "1",
    "sugar": "1.2",
    "protein": "0.4",
    "cal-from-protein": "2",
    "per-cal-from-protein": "2",
    "potassium": "5",
    "calcium": "18",
    "iron": "0.4",
    "phosphorus": "17",
    "source": "US FDA",
    "old units (ref)": "cup"
  },
  {
    "id": "815",
    "type": "meal",
    "|| name                                               ||": "Wrap with meat",
    "rating": "2",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "wrap",
    "weight-g": "227",
    "calories": "405",
    "fat": "18",
    "cal-from-fat": "162",
    "per-cal-from-fat": "40",
    "saturated": "5.2",
    "polyunsaturated": "7",
    "monounsaturated": "6",
    "trans": "0.2",
    "cholesterol": "82",
    "sodium": "1050",
    "carbs": "37",
    "fibre": "3",
    "sugar": "0",
    "protein": "24",
    "cal-from-protein": "96",
    "per-cal-from-protein": "24",
    "source": "N Buddy",
    "old units (ref)": "whole"
  },
  {
    "id": "677",
    "disabled": "1",
    "type": "meal",
    "|| name                                               ||": "Xiao bai cai, stir-fried",
    "rating": "3",
    "high-calcium": "1",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "115",
    "calories": "69",
    "fat": "4.5",
    "cal-from-fat": "40.5",
    "per-cal-from-fat": "59",
    "saturated": "0.7",
    "polyunsaturated": "0.8",
    "monounsaturated": "2.8",
    "cholesterol": "0",
    "sodium": "350",
    "carbs": "5.8",
    "fibre": "1.9",
    "sugar": "3.6",
    "protein": "2.5",
    "cal-from-protein": "10",
    "per-cal-from-protein": "14",
    "potassium": "300",
    "calcium": "110",
    "iron": "0.4",
    "phosphorus": "7",
    "source": "N Buddy",
    "old units (ref)": "cup"
  },
  {
    "id": "485",
    "type": "meal",
    "|| name                                               ||": "Xiao long bao",
    "rating": "2",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "serving": "3",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Soup dumplings",
    "info-translation": "Soup dumplings",
    "weight-g": "63",
    "calories": "207.6",
    "fat": "8.4",
    "cal-from-fat": "75.6",
    "per-cal-from-fat": "36",
    "saturated": "2.7",
    "polyunsaturated": "1.2",
    "monounsaturated": "6.9",
    "cholesterol": "30",
    "sodium": "420",
    "carbs": "22.5",
    "fibre": "1.5",
    "sugar": "0",
    "protein": "10.5",
    "cal-from-protein": "42",
    "per-cal-from-protein": "20",
    "source": "N Buddy",
    "old units (ref)": "dumping"
  },
  {
    "id": "864",
    "type": "meal",
    "|| name                                               ||": "Yaki soba with meat",
    "rating": "2",
    "high-iron": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "white-meat": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Japanese stir-fried noodles",
    "info-translation": "Japanese stir-fried noodles",
    "weight-g": "404",
    "calories": "641",
    "fat": "18.8",
    "cal-from-fat": "169.2",
    "per-cal-from-fat": "26",
    "saturated": "6.6",
    "polyunsaturated": "1.5",
    "monounsaturated": "6.9",
    "cholesterol": "101",
    "sodium": "1043",
    "carbs": "70.6",
    "fibre": "4.4",
    "sugar": "7.9",
    "protein": "47.6",
    "cal-from-protein": "190",
    "per-cal-from-protein": "30",
    "potassium": "856",
    "calcium": "88",
    "iron": "6.6",
    "phosphorus": "160",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "865",
    "type": "meal",
    "|| name                                               ||": "Yaki soba with seafood",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Japanese stir-fried noodles",
    "info-translation": "Japanese stir-fried noodles",
    "weight-g": "404",
    "calories": "547",
    "fat": "13.7",
    "cal-from-fat": "123.3",
    "per-cal-from-fat": "23",
    "saturated": "4.8",
    "polyunsaturated": "1.2",
    "monounsaturated": "4.2",
    "cholesterol": "216",
    "sodium": "1194",
    "carbs": "72.3",
    "fibre": "4.4",
    "sugar": "9.7",
    "protein": "33.1",
    "cal-from-protein": "132",
    "per-cal-from-protein": "24",
    "potassium": "743",
    "calcium": "163",
    "iron": "5.4",
    "phosphorus": "157",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "866",
    "type": "meal",
    "|| name                                               ||": "Yaki soba with vegetables",
    "rating": "2",
    "high-fibre": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "Japanese stir-fried noodles",
    "info-translation": "Japanese stir-fried noodles",
    "weight-g": "404",
    "calories": "465",
    "fat": "13.1",
    "cal-from-fat": "117.9",
    "per-cal-from-fat": "25",
    "saturated": "4.5",
    "polyunsaturated": "1",
    "monounsaturated": "4.1",
    "cholesterol": "0",
    "sodium": "1012",
    "carbs": "73.9",
    "fibre": "5.9",
    "sugar": "10.3",
    "protein": "14.4",
    "cal-from-protein": "58",
    "per-cal-from-protein": "12",
    "potassium": "776",
    "calcium": "140",
    "iron": "5.7",
    "phosphorus": "55",
    "source": "N Buddy",
    "old units (ref)": "plate"
  },
  {
    "id": "332",
    "type": "meal",
    "|| name                                               ||": "Yakitori",
    "rating": "2",
    "high-purine": "1",
    "high-salt": "1",
    "red-meat": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "stick",
    "info-translation": "Japanese skewered chicken",
    "weight-g": "60",
    "calories": "142",
    "fat": "8",
    "cal-from-fat": "72",
    "per-cal-from-fat": "51",
    "saturated": "2.8",
    "polyunsaturated": "1.7",
    "monounsaturated": "3",
    "cholesterol": "47",
    "sodium": "313",
    "carbs": "6.2",
    "fibre": "0.3",
    "sugar": "2.6",
    "protein": "11.3",
    "cal-from-protein": "45",
    "per-cal-from-protein": "32",
    "potassium": "184",
    "calcium": "41",
    "iron": "0.7",
    "phosphorus": "158",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "701",
    "type": "meal",
    "|| name                                               ||": "Yam",
    "rating": "3",
    "high-fibre": "1",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "yam",
    "alternate-spellings": "Yam: Taro",
    "weight-g": "136",
    "calories": "158",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "1",
    "saturated": "0",
    "polyunsaturated": "0.1",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "11",
    "carbs": "37.4",
    "fibre": "5.3",
    "sugar": "0.7",
    "protein": "2",
    "cal-from-protein": "8",
    "per-cal-from-protein": "5",
    "potassium": "911",
    "calcium": "19",
    "iron": "0.7",
    "phosphorus": "67",
    "source": "US FDA",
    "old units (ref)": "cup"
  },
  {
    "id": "918",
    "type": "meal",
    "|| name                                               ||": "Yam pastry with minced meat filling, fried",
    "rating": "2",
    "fried": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "Yu jiao / wu kok",
    "info-translation": "Yu Jiao / Wu Kok",
    "alternate-spellings": "Yam: Taro \nKok: Gok",
    "weight-g": "114",
    "calories": "459",
    "fat": "11.1",
    "cal-from-fat": "99.9",
    "per-cal-from-fat": "22",
    "saturated": "5.7",
    "polyunsaturated": "1",
    "monounsaturated": "3.9",
    "cholesterol": "10",
    "sodium": "601",
    "carbs": "26.2",
    "fibre": "3.3",
    "sugar": "0",
    "protein": "2.5",
    "cal-from-protein": "10",
    "per-cal-from-protein": "2",
    "calcium": "24",
    "iron": "1",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "178",
    "type": "drink",
    "|| name                                               ||": "Yogurt drink, sweetened",
    "rating": "2",
    "high-calcium": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "probiotic": "1",
    "serving": "1",
    "|| unit ||": "glass",
    "alternate-spellings": "Yogurt : Yoghurt",
    "volume-ml": "200",
    "calories": "92",
    "fat": "0.8",
    "cal-from-fat": "7.2",
    "per-cal-from-fat": "8",
    "saturated": "0.5",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.2",
    "cholesterol": "4",
    "sodium": "74",
    "carbs": "20",
    "sugar": "18",
    "protein": "1.2",
    "cal-from-protein": "5",
    "per-cal-from-protein": "5",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "glass"
  },
  {
    "id": "206",
    "type": "snack",
    "|| name                                               ||": "Yogurt, flavoured",
    "rating": "2",
    "high-calcium": "1",
    "high-gi": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "tub",
    "|| info                                                             ||": "single serve pot",
    "alternate-spellings": "Yogurt : Yoghurt",
    "weight-g": "170",
    "calories": "125",
    "fat": "9.3",
    "cal-from-fat": "83.7",
    "per-cal-from-fat": "67",
    "saturated": "6.1",
    "sodium": "74",
    "carbs": "19.5",
    "sugar": "18.2",
    "protein": "5.9",
    "cal-from-protein": "24",
    "per-cal-from-protein": "19",
    "calcium": "227",
    "iodine": "1",
    "source": "N Buddy",
    "old units (ref)": "g"
  },
  {
    "id": "375",
    "type": "snack",
    "|| name                                               ||": "Yogurt, plain, whole milk",
    "rating": "2",
    "high-calcium": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "probiotic": "1",
    "serving": "1",
    "|| unit ||": "tub",
    "|| info                                                             ||": "e.g. greek yogurt",
    "alternate-spellings": "Yogurt : Yoghurt",
    "weight-g": "150",
    "calories": "92",
    "fat": "5",
    "cal-from-fat": "45",
    "per-cal-from-fat": "49",
    "saturated": "3.2",
    "polyunsaturated": "0.1",
    "monounsaturated": "1.3",
    "cholesterol": "20",
    "sodium": "69",
    "carbs": "7",
    "fibre": "0",
    "sugar": "7",
    "protein": "5.2",
    "cal-from-protein": "21",
    "per-cal-from-protein": "23",
    "potassium": "233",
    "calcium": "182",
    "iron": "0.1",
    "phosphorus": "143",
    "iodine": "1",
    "source": "USDA",
    "old units (ref)": "g"
  },
  {
    "id": "438",
    "type": "meal",
    "|| name                                               ||": "Yong tau foo only, without sauce",
    "rating": "3",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "plant-protein": "1",
    "vegetable": "1",
    "serving": "6",
    "|| unit ||": "piece",
    "|| info                                                             ||": "without rice / noodle",
    "info-include-exclude": "without rice / noodle",
    "alternate-spellings": "Tau: Tou \nFoo: Fu, Hu",
    "weight-g": "198",
    "calories": "210",
    "fat": "14.5",
    "cal-from-fat": "130.5",
    "per-cal-from-fat": "62",
    "saturated": "4.8",
    "polyunsaturated": "3.5",
    "monounsaturated": "4.6",
    "cholesterol": "3",
    "sodium": "195",
    "carbs": "5.6",
    "fibre": "2.3",
    "sugar": "2.2",
    "protein": "14.9",
    "cal-from-protein": "60",
    "per-cal-from-protein": "28",
    "potassium": "324",
    "calcium": "190",
    "iron": "4",
    "phosphorus": "212",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "996",
    "type": "meal",
    "|| name                                               ||": "Yong tau foo only with sauce",
    "rating": "2",
    "high-calcium": "1",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "plant-protein": "1",
    "white-meat": "1",
    "serving": "6",
    "|| unit ||": "piece",
    "|| info                                                             ||": "without noodle / rice",
    "info-include-exclude": "without rice / noodle",
    "alternate-spellings": "Tau: Tou \nFoo: Fu, Hu",
    "weight-g": "215",
    "calories": "236",
    "fat": "15.2",
    "cal-from-fat": "136.8",
    "per-cal-from-fat": "58",
    "saturated": "4.8",
    "polyunsaturated": "3.5",
    "monounsaturated": "4.6",
    "cholesterol": "3",
    "sodium": "468",
    "carbs": "9.5",
    "fibre": "2.6",
    "sugar": "6.1",
    "protein": "15.3",
    "cal-from-protein": "61",
    "per-cal-from-protein": "26",
    "potassium": "327",
    "calcium": "198",
    "iron": "4.4",
    "phosphorus": "215",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "333",
    "type": "meal",
    "|| name                                               ||": "Yong tau foo, dry, without sauce",
    "rating": "2",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "plant-protein": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Yong tau foo with rice / noodle, without sauce",
    "info-include-exclude": "with rice / noodle",
    "alternate-spellings": "Tau: Tou \nFoo: Fu, Hu",
    "weight-g": "313",
    "calories": "351",
    "fat": "14.2",
    "cal-from-fat": "127.8",
    "per-cal-from-fat": "36",
    "saturated": "5.6",
    "polyunsaturated": "2",
    "monounsaturated": "5.2",
    "cholesterol": "8",
    "sodium": "292",
    "carbs": "46.8",
    "fibre": "3.6",
    "sugar": "2.5",
    "protein": "8.4",
    "cal-from-protein": "34",
    "per-cal-from-protein": "10",
    "potassium": "237",
    "calcium": "78",
    "iron": "2.4",
    "phosphorus": "137",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "926",
    "disabled": "1",
    "type": "meal",
    "|| name                                               ||": "Yong tau foo, dry, without sauce",
    "rating": "3",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "plant-protein": "1",
    "serving": "6",
    "|| unit ||": "piece",
    "|| info                                                             ||": "without noodle / rice",
    "info-include-exclude": "without rice / noodle",
    "alternate-spellings": "Tau: Tou \nFoo: Fu, Hu",
    "weight-g": "198",
    "calories": "212.5",
    "fat": "14.5",
    "cal-from-fat": "130.5",
    "per-cal-from-fat": "61",
    "saturated": "4.8",
    "polyunsaturated": "3.5",
    "monounsaturated": "4.6",
    "cholesterol": "3",
    "sodium": "195",
    "carbs": "5.6",
    "fibre": "2.3",
    "sugar": "2.2",
    "protein": "14.9",
    "cal-from-protein": "60",
    "per-cal-from-protein": "28",
    "potassium": "324",
    "calcium": "190",
    "iron": "4",
    "phosphorus": "212",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "994",
    "type": "meal",
    "|| name                                               ||": "Yong tau foo, dry with sauce",
    "rating": "2",
    "high-calcium": "1",
    "high-fat": "1",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "plant-protein": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "5 items with noodle / rice",
    "info-include-exclude": "5 items with rice / noodle",
    "alternate-spellings": "Tau: Tou \nFoo: Fu, Hu",
    "weight-g": "346",
    "calories": "397",
    "fat": "15.6",
    "cal-from-fat": "140.4",
    "per-cal-from-fat": "35",
    "saturated": "5.6",
    "polyunsaturated": "2",
    "monounsaturated": "5.2",
    "cholesterol": "8",
    "sodium": "788",
    "carbs": "54.6",
    "fibre": "4.2",
    "sugar": "10.3",
    "protein": "9.2",
    "cal-from-protein": "37",
    "per-cal-from-protein": "9",
    "potassium": "243",
    "calcium": "94",
    "iron": "3.2",
    "phosphorus": "143",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "347",
    "type": "meal",
    "|| name                                               ||": "Yong tau foo, laksa",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "plant-protein": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "5 items",
    "info-include-exclude": "5 items",
    "alternate-spellings": "Tau: Tou \nFoo: Fu, Hu",
    "weight-g": "602",
    "calories": "632.1",
    "fat": "37.3",
    "cal-from-fat": "335.7",
    "per-cal-from-fat": "53",
    "saturated": "23",
    "polyunsaturated": "4.8",
    "monounsaturated": "7.4",
    "cholesterol": "60",
    "sodium": "2215",
    "carbs": "50.6",
    "fibre": "3.6",
    "sugar": "3",
    "protein": "23.5",
    "cal-from-protein": "94",
    "per-cal-from-protein": "15",
    "potassium": "385",
    "calcium": "193",
    "iron": "3.6",
    "phosphorus": "259",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "995",
    "type": "meal",
    "|| name                                               ||": "Yong tau foo, soup with sauce",
    "rating": "2",
    "high-calcium": "1",
    "high-fat": "1",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "plant-protein": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "5 items with noodle / rice",
    "info-include-exclude": "5 items with rice / noodle",
    "alternate-spellings": "Tau: Tou \nFoo: Fu, Hu",
    "weight-g": "793",
    "calories": "371",
    "fat": "14.2",
    "cal-from-fat": "127.8",
    "per-cal-from-fat": "34",
    "saturated": "5.6",
    "polyunsaturated": "2",
    "monounsaturated": "5.2",
    "cholesterol": "8",
    "sodium": "1890",
    "carbs": "46.8",
    "fibre": "3.6",
    "sugar": "2.5",
    "protein": "10.4",
    "cal-from-protein": "42",
    "per-cal-from-protein": "11",
    "potassium": "237",
    "calcium": "98",
    "iron": "2.4",
    "phosphorus": "137",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "334",
    "type": "meal",
    "|| name                                               ||": "Yong tau foo, soup, without sauce",
    "rating": "2",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "plant-protein": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Yong tau foo with rice / noodle",
    "info-include-exclude": "with rice / noodle",
    "alternate-spellings": "Tau: Tou \nFoo: Fu, Hu",
    "weight-g": "689",
    "calories": "344",
    "fat": "8",
    "cal-from-fat": "72",
    "per-cal-from-fat": "21",
    "saturated": "2.2",
    "polyunsaturated": "2.4",
    "monounsaturated": "2.2",
    "cholesterol": "12",
    "sodium": "1678",
    "carbs": "46.4",
    "fibre": "3.6",
    "sugar": "2.5",
    "protein": "17",
    "cal-from-protein": "68",
    "per-cal-from-protein": "20",
    "potassium": "136",
    "calcium": "130",
    "iron": "2.8",
    "phosphorus": "206",
    "source": "N Buddy",
    "old units (ref)": "bowl"
  },
  {
    "id": "927",
    "type": "meal",
    "|| name                                               ||": "Yong tau foo, soup, without sauce",
    "rating": "3",
    "high-fibre": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "high-salt": "1",
    "plant-protein": "1",
    "serving": "6",
    "|| unit ||": "piece",
    "|| info                                                             ||": "without noodle / rice",
    "info-include-exclude": "without rice / noodle",
    "alternate-spellings": "Tau: Tou \nFoo: Fu, Hu",
    "weight-g": "678",
    "calories": "230",
    "fat": "7.7",
    "cal-from-fat": "69.3",
    "per-cal-from-fat": "30",
    "saturated": "4.8",
    "polyunsaturated": "3.5",
    "monounsaturated": "4.6",
    "sodium": "1793",
    "carbs": "5.6",
    "fibre": "2.3",
    "sugar": "0",
    "protein": "16.9",
    "cal-from-protein": "68",
    "per-cal-from-protein": "29",
    "calcium": "210",
    "iron": "4",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "920",
    "type": "meal",
    "|| name                                               ||": "You tiao salad",
    "rating": "2",
    "fried": "1",
    "high-iodine": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "with fish paste filling",
    "info-include-exclude": "with fish paste filling",
    "weight-g": "80",
    "calories": "243.1",
    "fat": "15.1",
    "cal-from-fat": "135.9",
    "per-cal-from-fat": "56",
    "saturated": "6.8",
    "polyunsaturated": "2.7",
    "monounsaturated": "5",
    "cholesterol": "34",
    "sodium": "563",
    "carbs": "18.8",
    "fibre": "1.4",
    "sugar": "0",
    "protein": "8",
    "cal-from-protein": "32",
    "per-cal-from-protein": "13",
    "calcium": "21",
    "iron": "1.3",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "639",
    "type": "meal",
    "|| name                                               ||": "Zucchini / courgettes, baked",
    "rating": "3",
    "fruit": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "weight-g": "100",
    "calories": "87",
    "fat": "7.5",
    "cal-from-fat": "67.5",
    "per-cal-from-fat": "78",
    "saturated": "1.1",
    "polyunsaturated": "0.7",
    "monounsaturated": "5",
    "cholesterol": "0",
    "sodium": "1",
    "carbs": "3.8",
    "fibre": "1.5",
    "sugar": "2.3",
    "protein": "1.2",
    "cal-from-protein": "5",
    "per-cal-from-protein": "6",
    "potassium": "255",
    "calcium": "24",
    "iron": "0.7",
    "phosphorus": "46",
    "source": "N Buddy",
    "old units (ref)": "portion"
  },
  {
    "id": "925",
    "type": "meal",
    "|| name                                               ||": "Dim sum (fried sesame ball with peanut filling)",
    "rating": "1",
    "fried": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "27",
    "calories": "109",
    "fat": "6.2",
    "cal-from-fat": "55.8",
    "per-cal-from-fat": "51",
    "saturated": "3",
    "polyunsaturated": "0.3",
    "monounsaturated": "1.6",
    "cholesterol": "0",
    "sodium": "18",
    "carbs": "11.4",
    "fibre": "1.4",
    "sugar": "3.1",
    "protein": "1.9",
    "cal-from-protein": "8",
    "per-cal-from-protein": "7",
    "calcium": "7",
    "iron": "0.3",
    "source": "N Buddy",
    "old units (ref)": "piece"
  },
  {
    "id": "1097",
    "type": "meal",
    "|| name                                               ||": "Balsamic vinegar",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "tsp",
    "volume-ml": "5",
    "calories": "4.7",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "1.22",
    "carbs": "0.9",
    "sugar": "0.8",
    "protein": "0.03",
    "cal-from-protein": "0",
    "per-cal-from-protein": "3",
    "potassium": "5.9",
    "calcium": "1.4",
    "iron": "0.04",
    "phosphorus": "1",
    "source": "USDA"
  },
  {
    "id": "1098",
    "type": "meal",
    "|| name                                               ||": "Olive oil",
    "rating": "2",
    "healthy-fats": "1",
    "high-fat": "1",
    "serving": "1",
    "|| unit ||": "tsp",
    "volume-ml": "5",
    "calories": "39.8",
    "fat": "4.5",
    "cal-from-fat": "40.5",
    "per-cal-from-fat": "102",
    "saturated": "0.6",
    "polyunsaturated": "0.5",
    "monounsaturated": "3.3",
    "cholesterol": "0",
    "sodium": "0.1",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "0.05",
    "calcium": "0.05",
    "iron": "0.03",
    "phosphorus": "0",
    "source": "USDA"
  },
  {
    "id": "1099",
    "type": "meal",
    "|| name                                               ||": "Spinach, raw",
    "rating": "3",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "30",
    "calories": "6.9",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "13",
    "saturated": "0.02",
    "polyunsaturated": "0.05",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "23.7",
    "carbs": "1.1",
    "fibre": "0.7",
    "sugar": "0.1",
    "protein": "0.9",
    "cal-from-protein": "4",
    "per-cal-from-protein": "52",
    "potassium": "167",
    "calcium": "29.7",
    "iron": "0.8",
    "phosphorus": "14.7",
    "source": "USDA"
  },
  {
    "id": "1100",
    "type": "snack",
    "|| name                                               ||": "Yogurt, plain, low fat",
    "rating": "3",
    "high-calcium": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "probiotic": "1",
    "serving": "1",
    "|| unit ||": "tub",
    "alternate-spellings": "Yogurt : Yoghurt",
    "weight-g": "150",
    "calories": "95",
    "fat": "2.3",
    "cal-from-fat": "20.7",
    "per-cal-from-fat": "22",
    "saturated": "1.5",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.6",
    "cholesterol": "9",
    "sodium": "105",
    "carbs": "10.6",
    "fibre": "0",
    "sugar": "10.6",
    "protein": "7.9",
    "cal-from-protein": "32",
    "per-cal-from-protein": "33",
    "potassium": "351",
    "calcium": "275",
    "iron": "0.1",
    "phosphorus": "216",
    "source": "USDA"
  },
  {
    "id": "1101",
    "type": "drink",
    "|| name                                               ||": "Milk, low fat",
    "rating": "3",
    "high-calcium": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "info-examples": "fresh milk, UHT milk",
    "volume-ml": "250",
    "calories": "105",
    "fat": "2.3",
    "cal-from-fat": "20.7",
    "per-cal-from-fat": "20",
    "saturated": "1.39",
    "polyunsaturated": "0.08",
    "monounsaturated": "0.51",
    "cholesterol": "12",
    "sodium": "95",
    "carbs": "12.7",
    "fibre": "0",
    "sugar": "12.1",
    "protein": "8.3",
    "cal-from-protein": "33",
    "per-cal-from-protein": "32",
    "potassium": "388",
    "calcium": "307",
    "iron": "0",
    "phosphorus": "251",
    "source": "USDA"
  },
  {
    "id": "1102",
    "type": "drink",
    "|| name                                               ||": "Milk, skim",
    "rating": "3",
    "high-calcium": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "info-examples": "fresh milk, UHT milk",
    "volume-ml": "250",
    "calories": "83",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "2",
    "saturated": "0.12",
    "polyunsaturated": "0.02",
    "monounsaturated": "0.04",
    "cholesterol": "7.3",
    "sodium": "100",
    "carbs": "12",
    "fibre": "0",
    "sugar": "12.3",
    "protein": "8.4",
    "cal-from-protein": "34",
    "per-cal-from-protein": "40",
    "potassium": "407",
    "calcium": "322",
    "iron": "0",
    "phosphorus": "261",
    "source": "USDA"
  },
  {
    "id": "1103",
    "type": "meal",
    "|| name                                               ||": "Pasta, soup based with meat",
    "rating": "3",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-examples": "spaghetti, linguine, macaroni",
    "weight-g": "483",
    "calories": "198.6",
    "fat": "1.5",
    "cal-from-fat": "13.5",
    "per-cal-from-fat": "7",
    "saturated": "0.48",
    "cholesterol": "29",
    "sodium": "1710",
    "carbs": "31",
    "fibre": "1",
    "protein": "15",
    "cal-from-protein": "60",
    "per-cal-from-protein": "30",
    "source": "HPB"
  },
  {
    "id": "1104",
    "type": "meal",
    "|| name                                               ||": "Rice, basmati / low GI / parboiled",
    "rating": "2",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "180",
    "calories": "239",
    "fat": "0.8",
    "cal-from-fat": "7.2",
    "per-cal-from-fat": "3",
    "saturated": "0.3",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "1",
    "carbs": "52.9",
    "fibre": "0.9",
    "sugar": "0.2",
    "protein": "5.3",
    "cal-from-protein": "21",
    "per-cal-from-protein": "9",
    "source": "Company webiste & food label"
  },
  {
    "id": "1105",
    "type": "snack",
    "|| name                                               ||": "Porridge with pork liver",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iron": "1",
    "high-purine": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "alternate-spellings": "Porridge: Congee",
    "weight-g": "546",
    "calories": "360",
    "fat": "10.37",
    "cal-from-fat": "93.33",
    "per-cal-from-fat": "26",
    "saturated": "2.67",
    "cholesterol": "229",
    "sodium": "900",
    "carbs": "40",
    "fibre": "1.64",
    "protein": "25.6",
    "cal-from-protein": "102",
    "per-cal-from-protein": "28",
    "iron": "11",
    "source": "HPB"
  },
  {
    "id": "1107",
    "type": "meal",
    "|| name                                               ||": "Pho, with meat",
    "rating": "2",
    "high-phosphate": "1",
    "high-salt": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Pho noodles soup with beef / chicken",
    "info-translation": "Vietnamese noodles soup",
    "info-examples": "beef, chicken",
    "weight-g": "650",
    "calories": "429",
    "fat": "8",
    "cal-from-fat": "72",
    "per-cal-from-fat": "17",
    "saturated": "2",
    "polyunsaturated": "2",
    "monounsaturated": "3",
    "cholesterol": "85",
    "sodium": "1100",
    "carbs": "70",
    "fibre": "4",
    "sugar": "1",
    "protein": "24",
    "cal-from-protein": "96",
    "per-cal-from-protein": "22",
    "source": "N Buddy"
  },
  {
    "id": "1108",
    "type": "meal",
    "|| name                                               ||": "Overnight oats with milk, unsweetened",
    "rating": "3",
    "high-calcium": "1",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "295",
    "calories": "310",
    "fat": "10",
    "cal-from-fat": "90",
    "per-cal-from-fat": "29",
    "saturated": "5",
    "polyunsaturated": "1.5",
    "monounsaturated": "3.5",
    "trans": "0.3",
    "cholesterol": "30",
    "sodium": "105",
    "carbs": "40",
    "fibre": "2.7",
    "sugar": "13",
    "protein": "13.6",
    "cal-from-protein": "54",
    "per-cal-from-protein": "18",
    "potassium": "529",
    "calcium": "345",
    "iron": "1.9",
    "phosphorus": "363",
    "source": "N Buddy"
  },
  {
    "id": "1109",
    "type": "snack",
    "|| name                                               ||": "Biscuit, high fibre",
    "rating": "2",
    "high-fibre": "1",
    "whole-grain": "1",
    "serving": "3",
    "|| unit ||": "pieces",
    "|| info                                                             ||": "e.g. Wasa Crispbread, Ryvita Crispbread, Crisp Fibre Biscuit",
    "info-brands": "Wasa / Ryvita Crispbread",
    "info-examples": "rye, buckwheat",
    "weight-g": "33",
    "calories": "110",
    "fat": "0.7",
    "cal-from-fat": "6.3",
    "per-cal-from-fat": "6",
    "saturated": "0",
    "polyunsaturated": "0.3",
    "monounsaturated": "0.3",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "250",
    "carbs": "20",
    "fibre": "5",
    "sugar": "1",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "11",
    "source": "N Buddy"
  },
  {
    "id": "1110",
    "type": "snack",
    "|| name                                               ||": "Maternal milk",
    "rating": "3",
    "high-calcium": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "|| info                                                             ||": "e.g. Enfamama, Mamil, Anmum",
    "info-brands": "Enfamama, Mamil, Anmum",
    "weight-g": "50",
    "calories": "190",
    "fat": "2.3",
    "cal-from-fat": "20.7",
    "per-cal-from-fat": "11",
    "saturated": "1.8",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.5",
    "trans": "0.3",
    "sodium": "180",
    "carbs": "30",
    "fibre": "1.5",
    "sugar": "15",
    "protein": "12",
    "cal-from-protein": "48",
    "per-cal-from-protein": "25",
    "potassium": "400",
    "calcium": "400",
    "iron": "5",
    "phosphorus": "310",
    "source": "N Buddy"
  },
  {
    "id": "1111",
    "type": "snack",
    "|| name                                               ||": "Protein shake, ready to drink",
    "rating": "2",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "bottle",
    "info-include-exclude": "generic",
    "weight-g": "400",
    "calories": "215",
    "fat": "5",
    "cal-from-fat": "45",
    "per-cal-from-fat": "21",
    "carbs": "12",
    "sugar": "10",
    "protein": "29",
    "cal-from-protein": "116",
    "per-cal-from-protein": "54",
    "old units (ref)": "estimated"
  },
  {
    "id": "1112",
    "type": "snack",
    "|| name                                               ||": "Chocolate spread, sweetened",
    "rating": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "|| info                                                             ||": "e.g. Nutella, chocolate hazelnut spread",
    "info-brands": "Nutella",
    "weight-g": "19",
    "calories": "102",
    "fat": "6",
    "cal-from-fat": "54",
    "per-cal-from-fat": "53",
    "saturated": "1.5",
    "polyunsaturated": "1.4",
    "monounsaturated": "3.1",
    "cholesterol": "0",
    "sodium": "9",
    "carbs": "10.9",
    "fibre": "0.7",
    "sugar": "10.8",
    "protein": "1.1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "4",
    "potassium": "82",
    "calcium": "25",
    "phosphorus": "36",
    "source": "N Buddy"
  },
  {
    "id": "1113",
    "type": "snack",
    "|| name                                               ||": "Soy beancurd, unsweetened",
    "rating": "2",
    "high-calcium": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "|| info                                                             ||": "Tau Huay without sugar",
    "info-translation": "Tau Huay",
    "info-include-exclude": "without sugar",
    "alternate-spellings": "Tau: Dou\nHuay: Hua, Huey",
    "weight-g": "286",
    "calories": "83",
    "fat": "4.5",
    "cal-from-fat": "40.5",
    "per-cal-from-fat": "49",
    "saturated": "0.7",
    "polyunsaturated": "2.8",
    "monounsaturated": "0.8",
    "cholesterol": "0",
    "sodium": "4",
    "carbs": "4",
    "fibre": "0.8",
    "sugar": "2",
    "protein": "6.7",
    "cal-from-protein": "27",
    "per-cal-from-protein": "32",
    "source": "N Buddy"
  },
  {
    "id": "1114",
    "type": "snack",
    "|| name                                               ||": "Spring roll, steamed",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "roll",
    "|| info                                                             ||": "with meat, chicken or prawn",
    "info-include-exclude": "with meat or prawn",
    "weight-g": "120",
    "calories": "170",
    "fat": "1.9",
    "cal-from-fat": "17.1",
    "per-cal-from-fat": "10",
    "saturated": "0.6",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.9",
    "trans": "0",
    "cholesterol": "32",
    "sodium": "111",
    "carbs": "24.8",
    "fibre": "1.1",
    "sugar": "2",
    "protein": "12.9",
    "cal-from-protein": "52",
    "per-cal-from-protein": "30",
    "potassium": "219",
    "calcium": "26.5",
    "iron": "1.3",
    "phosphorus": "60",
    "source": "N Buddy"
  },
  {
    "id": "1115",
    "type": "meal",
    "|| name                                               ||": "Vegetarian fried beehoon",
    "rating": "1",
    "fried": "1",
    "high-fat": "1",
    "high-gi": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "with bean sprouts and mock meat",
    "info-translation": "Rice vermicelli",
    "info-include-exclude": "with bean sprouts and mock meat",
    "weight-g": "362",
    "calories": "549",
    "fat": "16.7",
    "cal-from-fat": "150.3",
    "per-cal-from-fat": "27",
    "saturated": "8",
    "polyunsaturated": "2.9",
    "monounsaturated": "4.7",
    "cholesterol": "0",
    "sodium": "1539",
    "carbs": "82.9",
    "fibre": "8.3",
    "sugar": "14.7",
    "protein": "15.9",
    "cal-from-protein": "64",
    "per-cal-from-protein": "12",
    "potassium": "100",
    "calcium": "60",
    "iron": "3",
    "phosphorus": "70",
    "source": "N Buddy"
  },
  {
    "id": "1116",
    "type": "meal",
    "|| name                                               ||": "Shirataki noodles",
    "rating": "2",
    "serving": "1",
    "|| unit ||": "serving",
    "|| info                                                             ||": "Konjac / konnyaku noodles",
    "info-translation": "Konjac noodles",
    "alternate-spellings": "Konjac: Konnyaku",
    "weight-g": "100",
    "calories": "5",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "3",
    "fibre": "2",
    "sugar": "0",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "33",
    "calcium": "43",
    "iron": "0.4",
    "source": "N Buddy"
  },
  {
    "id": "1117",
    "type": "meal",
    "|| name                                               ||": "Bread, with fruit",
    "rating": "2",
    "high-gi": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "|| info                                                             ||": "e.g. raisin bread",
    "info-examples": "raisin, fig",
    "weight-g": "40",
    "calories": "113",
    "fat": "0.9",
    "cal-from-fat": "8.1",
    "per-cal-from-fat": "7",
    "saturated": "0.5",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.2",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "115",
    "carbs": "22.7",
    "fibre": "1.7",
    "sugar": "5.8",
    "protein": "3.5",
    "cal-from-protein": "14",
    "per-cal-from-protein": "12",
    "calcium": "110",
    "iron": "2.1",
    "source": "N Buddy"
  },
  {
    "id": "1118",
    "type": "snack",
    "|| name                                               ||": "Chia seeds",
    "rating": "3",
    "healthy-fats": "1",
    "high-fibre": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "alternate-spellings": "Chia: Chai",
    "weight-g": "15",
    "calories": "73",
    "fat": "4.6",
    "cal-from-fat": "41.4",
    "per-cal-from-fat": "57",
    "saturated": "0.5",
    "polyunsaturated": "3.6",
    "monounsaturated": "0.3",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "2",
    "carbs": "1.1",
    "fibre": "5.2",
    "sugar": "0",
    "protein": "2.5",
    "cal-from-protein": "10",
    "per-cal-from-protein": "14",
    "potassium": "61",
    "calcium": "95",
    "iron": "1.2",
    "phosphorus": "129",
    "source": "N Buddy"
  },
  {
    "id": "1119",
    "type": "meal",
    "|| name                                               ||": "Egg and tomato, stir fried",
    "rating": "2",
    "serving": "1",
    "|| unit ||": "serving",
    "weight-g": "99",
    "calories": "130",
    "fat": "10.4",
    "cal-from-fat": "93.6",
    "per-cal-from-fat": "72",
    "saturated": "3.9",
    "polyunsaturated": "1",
    "monounsaturated": "4.3",
    "trans": "0",
    "cholesterol": "208",
    "sodium": "66",
    "carbs": "2.5",
    "fibre": "0.5",
    "sugar": "2.5",
    "protein": "7",
    "cal-from-protein": "28",
    "per-cal-from-protein": "22",
    "potassium": "101",
    "calcium": "26",
    "iron": "1.2",
    "phosphorus": "80",
    "source": "N Buddy"
  },
  {
    "id": "1120",
    "type": "snack",
    "|| name                                               ||": "Wafers, flavoured",
    "rating": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "3",
    "|| unit ||": "pieces",
    "|| info                                                             ||": "e.g. chocolate / strawberry wafers",
    "info-examples": "chocolate, strawberry",
    "weight-g": "27",
    "calories": "127",
    "fat": "4.6",
    "cal-from-fat": "41.4",
    "per-cal-from-fat": "33",
    "saturated": "2",
    "polyunsaturated": "0.5",
    "monounsaturated": "1.9",
    "trans": "0",
    "sodium": "69",
    "carbs": "20",
    "fibre": "1.1",
    "sugar": "10.8",
    "protein": "1.2",
    "cal-from-protein": "5",
    "per-cal-from-protein": "4",
    "source": "N Buddy"
  },
  {
    "id": "1121",
    "type": "meal",
    "|| name                                               ||": "Wholemeal pasta, plain",
    "rating": "3",
    "high-fibre": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "Wholemeal pasta / durum wheat pasta",
    "info-examples": "durum wheat, spaghetti, linguine, macaroni",
    "alternate-spellings": "Wholemeal: Wholewheat",
    "weight-g": "140",
    "calories": "283",
    "fat": "1.6",
    "cal-from-fat": "14.4",
    "per-cal-from-fat": "5",
    "saturated": "0.2",
    "polyunsaturated": "0.7",
    "monounsaturated": "0.7",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "56",
    "fibre": "3.2",
    "sugar": "1.6",
    "protein": "9.6",
    "cal-from-protein": "38",
    "per-cal-from-protein": "14",
    "source": "N Buddy"
  },
  {
    "id": "1122",
    "type": "meal",
    "|| name                                               ||": "Beef / beef ball soup",
    "rating": "2",
    "high-salt": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "400",
    "calories": "175",
    "fat": "8",
    "cal-from-fat": "72",
    "per-cal-from-fat": "41",
    "saturated": "3.6",
    "trans": "0.2",
    "cholesterol": "40",
    "sodium": "1584",
    "carbs": "7",
    "fibre": "1",
    "sugar": "2",
    "protein": "15",
    "cal-from-protein": "60",
    "per-cal-from-protein": "34",
    "potassium": "150",
    "calcium": "20",
    "iron": "0.5",
    "phosphorus": "34",
    "source": "N Buddy"
  },
  {
    "id": "1123",
    "type": "meal",
    "|| name                                               ||": "Wholemeal pasta, stir fried with meat and vegetables",
    "rating": "2",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "|| info                                                             ||": "stir fried with chicken / meat / seafood and vegetables, without sauce",
    "info-include-exclude": "without sauce",
    "alternate-spellings": "Wholemeal: Wholewheat",
    "weight-g": "265",
    "calories": "454",
    "fat": "13.3",
    "cal-from-fat": "119.7",
    "per-cal-from-fat": "26",
    "saturated": "2.2",
    "polyunsaturated": "5.5",
    "monounsaturated": "4.4",
    "trans": "0",
    "cholesterol": "30",
    "sodium": "500",
    "carbs": "58.8",
    "fibre": "4.3",
    "sugar": "3.1",
    "protein": "24.5",
    "cal-from-protein": "98",
    "per-cal-from-protein": "22",
    "potassium": "283",
    "calcium": "40",
    "iron": "0.7",
    "phosphorus": "143",
    "source": "N Buddy"
  },
  {
    "id": "1124",
    "type": "meal",
    "|| name                                               ||": "Toast with avocado",
    "rating": "2",
    "healthy-fats": "1",
    "high-fat": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "100",
    "calories": "250",
    "fat": "17.1",
    "cal-from-fat": "153.9",
    "per-cal-from-fat": "62",
    "saturated": "3.6",
    "polyunsaturated": "2.1",
    "monounsaturated": "10.1",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "461",
    "carbs": "22",
    "fibre": "6.6",
    "sugar": "1.3",
    "protein": "5.3",
    "cal-from-protein": "21",
    "per-cal-from-protein": "8",
    "potassium": "461",
    "calcium": "13",
    "iron": "1.3",
    "source": "USDA"
  },
  {
    "id": "1125",
    "type": "meal",
    "|| name                                               ||": "Sandwich, grilled",
    "rating": "2",
    "high-fat": "1",
    "serving": "2",
    "|| unit ||": "slice",
    "|| info                                                             ||": "e.g. panini with meat filling and sauce",
    "info-examples": "panini",
    "info-include-exclude": "with meat fillings and sauce",
    "weight-g": "200",
    "calories": "650",
    "fat": "25",
    "cal-from-fat": "225",
    "per-cal-from-fat": "35",
    "saturated": "14",
    "polyunsaturated": "1",
    "monounsaturated": "10",
    "trans": "0",
    "cholesterol": "95",
    "carbs": "62",
    "fibre": "2",
    "sugar": "1",
    "protein": "41",
    "cal-from-protein": "164",
    "per-cal-from-protein": "25",
    "source": "N Buddy"
  },
  {
    "id": "1126",
    "type": "meal",
    "|| name                                               ||": "Confit",
    "rating": "2",
    "high-fat": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "info-examples": "duck, lamb",
    "weight-g": "100",
    "calories": "304",
    "fat": "17.9",
    "cal-from-fat": "161.1",
    "per-cal-from-fat": "53",
    "saturated": "7",
    "trans": "0",
    "cholesterol": "170",
    "sodium": "250",
    "carbs": "1.8",
    "protein": "35.7",
    "cal-from-protein": "143",
    "per-cal-from-protein": "47",
    "iron": "3.2",
    "source": "USDA"
  },
  {
    "id": "1127",
    "type": "meal",
    "|| name                                               ||": "Shepherd's pie, without potato",
    "rating": "2",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "|| info                                                             ||": "e.g. Cauliflower shepherd's pie with meat/chicken",
    "info-examples": "cauliflower with meat",
    "weight-g": "283",
    "calories": "260",
    "fat": "15",
    "cal-from-fat": "135",
    "per-cal-from-fat": "52",
    "saturated": "7",
    "trans": "0",
    "cholesterol": "65",
    "sodium": "931",
    "carbs": "11",
    "fibre": "4",
    "sugar": "3",
    "protein": "19",
    "cal-from-protein": "76",
    "per-cal-from-protein": "29",
    "calcium": "99",
    "iron": "1.8",
    "source": "USDA"
  },
  {
    "id": "1128",
    "type": "snack",
    "|| name                                               ||": "Biscuit stick, plain",
    "rating": "2",
    "high-gi": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "packet",
    "|| info                                                             ||": "e.g. Mitsuya",
    "info-brands": "Mitsuya Tasty, Pretz",
    "weight-g": "40",
    "calories": "195",
    "fat": "8.2",
    "cal-from-fat": "73.8",
    "per-cal-from-fat": "38",
    "saturated": "2.5",
    "sodium": "188",
    "carbs": "27.4",
    "sugar": "10",
    "protein": "2.8",
    "cal-from-protein": "11",
    "per-cal-from-protein": "6",
    "source": "N Buddy"
  },
  {
    "id": "1129",
    "type": "meal",
    "|| name                                               ||": "Tamago sushi",
    "rating": "2",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "39",
    "calories": "63",
    "fat": "1.5",
    "cal-from-fat": "13.5",
    "per-cal-from-fat": "21",
    "saturated": "0.4",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.6",
    "cholesterol": "35",
    "sodium": "148.5",
    "carbs": "10.4",
    "fibre": "0.4",
    "sugar": "0.9",
    "protein": "2",
    "cal-from-protein": "8",
    "per-cal-from-protein": "13",
    "potassium": "24",
    "calcium": "8.5",
    "iron": "0",
    "phosphorus": "25",
    "source": "N Buddy",
    "old units (ref)": "Umisushi, Tamago Sushi"
  },
  {
    "id": "1130",
    "type": "meal",
    "|| name                                               ||": "Char siew",
    "rating": "1",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-salt": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "info-translation": "Barbecued pork",
    "alternate-spellings": "Char: Cha \r\nSiew: Shao, Siu",
    "weight-g": "100",
    "calories": "254",
    "fat": "12.7",
    "cal-from-fat": "114.3",
    "per-cal-from-fat": "45",
    "saturated": "5.4",
    "polyunsaturated": "1.4",
    "monounsaturated": "4.7",
    "cholesterol": "97",
    "sodium": "476",
    "carbs": "6.2",
    "fibre": "0",
    "sugar": "6.2",
    "protein": "28.6",
    "cal-from-protein": "114",
    "per-cal-from-protein": "45",
    "calcium": "13",
    "iron": "1.1",
    "source": "N Buddy",
    "old units (ref)": "Char Siew, BBQ Pork\nGI: 66\nGI Link: https://www.nature.com/articles/s41387-020-00145-w.pdf"
  },
  {
    "id": "1131",
    "type": "meal",
    "|| name                                               ||": "Sambal lala",
    "rating": "2",
    "high-fat": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-salt": "1",
    "serving": "10",
    "|| unit ||": "piece",
    "info-translation": "Sambal clams",
    "weight-g": "50",
    "calories": "59",
    "fat": "4",
    "cal-from-fat": "36",
    "per-cal-from-fat": "61",
    "saturated": "1.8",
    "polyunsaturated": "0.6",
    "monounsaturated": "1.6",
    "trans": "0",
    "cholesterol": "53",
    "sodium": "333",
    "carbs": "2",
    "fibre": "1",
    "sugar": "1.4",
    "protein": "4",
    "cal-from-protein": "16",
    "per-cal-from-protein": "27",
    "calcium": "51",
    "iron": "2.1",
    "source": "N Buddy",
    "old units (ref)": "Sambal Lala, without shell"
  },
  {
    "id": "1132",
    "type": "meal",
    "|| name                                               ||": "Bulgur",
    "rating": "2",
    "high-fibre": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "plant-protein": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "cooked in water",
    "weight-g": "265",
    "calories": "261",
    "fat": "1.1",
    "cal-from-fat": "9.9",
    "per-cal-from-fat": "4",
    "saturated": "0.2",
    "polyunsaturated": "0.5",
    "monounsaturated": "1",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "27",
    "carbs": "54.6",
    "fibre": "9",
    "sugar": "0",
    "protein": "7.7",
    "cal-from-protein": "31",
    "per-cal-from-protein": "12",
    "potassium": "127",
    "calcium": "32",
    "iron": "2.6",
    "phosphorus": "191",
    "source": "N Buddy",
    "old units (ref)": "Bulgur, cooked in water"
  },
  {
    "id": "1133",
    "type": "snack",
    "|| name                                               ||": "Blackberries",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "serving": "10",
    "|| unit ||": "fruit",
    "weight-g": "50",
    "calories": "21.5",
    "fat": "0.25",
    "cal-from-fat": "2.25",
    "per-cal-from-fat": "10",
    "saturated": "0.01",
    "polyunsaturated": "0.14",
    "monounsaturated": "0.02",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "0.5",
    "carbs": "4.8",
    "fibre": "2.7",
    "sugar": "2.4",
    "protein": "0.7",
    "cal-from-protein": "3",
    "per-cal-from-protein": "13",
    "potassium": "81",
    "calcium": "14.5",
    "iron": "0.3",
    "phosphorus": "11",
    "source": "USDA",
    "old units (ref)": "Blackberries, raw \nGI: 46 (link page 1, \"baked beans, canned\")\nGI Link: https://glycemicindex.com/gi-search/?food_name=blackberry&product_category=&country=&gi=&gi_filter=&serving_size_(g)=&serving_size_(g)_filter=&carbs_per_serve_(g)=&carbs_per_serve_(g)_filter=&gl=&gl_filter=\nBlackberry 100% Fruit Spread, Cottees™ brand"
  },
  {
    "id": "1134",
    "type": "meal",
    "|| name                                               ||": "Hot cross bun",
    "rating": "2",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "59",
    "calories": "200",
    "fat": "5",
    "cal-from-fat": "45",
    "per-cal-from-fat": "23",
    "saturated": "2.5",
    "trans": "0",
    "sodium": "125",
    "carbs": "35",
    "fibre": "2",
    "sugar": "16",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "6",
    "calcium": "40",
    "iron": "1.1",
    "source": "N Buddy",
    "old units (ref)": "Hot Cross Bun"
  },
  {
    "id": "1135",
    "type": "meal",
    "|| name                                               ||": "Baked beans",
    "rating": "2",
    "high-calcium": "1",
    "high-fibre": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "plant-protein": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "|| info                                                             ||": "canned",
    "info-include-exclude": "canned",
    "weight-g": "260",
    "calories": "273",
    "fat": "2.3",
    "cal-from-fat": "20.7",
    "per-cal-from-fat": "8",
    "saturated": "0.6",
    "polyunsaturated": "1",
    "monounsaturated": "0.6",
    "cholesterol": "18.2",
    "sodium": "1020",
    "carbs": "56.1",
    "fibre": "11.4",
    "sugar": "21.4",
    "protein": "11.8",
    "cal-from-protein": "47",
    "per-cal-from-protein": "17",
    "potassium": "601",
    "calcium": "101",
    "iron": "3.6",
    "phosphorus": "229",
    "source": "USDA",
    "old units (ref)": "Baked beans\nGI: 40\nhttps://glycemicindex.com/gi-search/?food_name=baked+beans&product_category=&country=&gi=&gi_filter=&serving_size_(g)=&serving_size_(g)_filter=&carbs_per_serve_(g)=&carbs_per_serve_(g)_filter=&gl=&gl_filter="
  },
  {
    "id": "1136",
    "type": "meal",
    "|| name                                               ||": "Shirataki rice",
    "rating": "2",
    "high-fibre": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "info-translation": "Konjac rice",
    "alternate-spellings": "Konjac: Konnyaku",
    "weight-g": "100",
    "calories": "23",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "1",
    "carbs": "11.1",
    "fibre": "11.1",
    "sugar": "0",
    "protein": "0.1",
    "cal-from-protein": "0",
    "per-cal-from-protein": "2",
    "source": "N Buddy",
    "old units (ref)": "Asahiya Shirataki Konjac Crystal Rice"
  },
  {
    "id": "1137",
    "type": "meal",
    "|| name                                               ||": "Mee hoon kueh, soup",
    "rating": "2",
    "high-cholesterol": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "alternate-spellings": "Mee: Mi \nKueh: Kuih, Kue",
    "weight-g": "528",
    "calories": "475",
    "fat": "21.7",
    "cal-from-fat": "195.3",
    "per-cal-from-fat": "41",
    "saturated": "8.5",
    "polyunsaturated": "3.2",
    "monounsaturated": "9.5",
    "cholesterol": "232",
    "sodium": "2196",
    "carbs": "48.1",
    "fibre": "3.2",
    "sugar": "0",
    "protein": "21.7",
    "cal-from-protein": "87",
    "per-cal-from-protein": "18",
    "potassium": "491",
    "calcium": "84",
    "iron": "1.1",
    "phosphorus": "174",
    "source": "N Buddy",
    "old units (ref)": "Mee Hoon Kueh Soup"
  },
  {
    "id": "1138",
    "type": "meal",
    "|| name                                               ||": "Clams / cockles, steamed",
    "rating": "2",
    "high-calcium": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "258",
    "calories": "87",
    "fat": "0.7",
    "cal-from-fat": "6.3",
    "per-cal-from-fat": "7",
    "saturated": "0.3",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.3",
    "trans": "0",
    "cholesterol": "36",
    "sodium": "265",
    "carbs": "0.9",
    "fibre": "0",
    "sugar": "0",
    "protein": "19",
    "cal-from-protein": "76",
    "per-cal-from-protein": "87",
    "potassium": "276",
    "calcium": "397",
    "iron": "0.5",
    "phosphorus": "642",
    "source": "N Buddy",
    "old units (ref)": "Boiled Clams with shells"
  },
  {
    "id": "1139",
    "type": "meal",
    "|| name                                               ||": "Minced meat noodle, soup",
    "rating": "2",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-translation": "Bak Chor Mee / Mee Pok",
    "alternate-spellings": "Mee: Mian",
    "weight-g": "600",
    "calories": "374",
    "fat": "12.2",
    "cal-from-fat": "109.8",
    "per-cal-from-fat": "29",
    "saturated": "4.8",
    "polyunsaturated": "2.3",
    "monounsaturated": "4.3",
    "trans": "0",
    "cholesterol": "29",
    "sodium": "1425",
    "carbs": "53.1",
    "fibre": "7.9",
    "sugar": "4.2",
    "protein": "11.8",
    "cal-from-protein": "47",
    "per-cal-from-protein": "13",
    "source": "N Buddy",
    "old units (ref)": "Noodles Soup with minced meat"
  },
  {
    "id": "1140",
    "type": "snack",
    "|| name                                               ||": "Honey",
    "rating": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "tsp",
    "weight-g": "7",
    "calories": "21.3",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "0.3",
    "carbs": "5.8",
    "fibre": "0.01",
    "sugar": "5.8",
    "protein": "0.02",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "3.64",
    "calcium": "0.4",
    "iron": "0.03",
    "phosphorus": "0.28",
    "source": "USDA",
    "old units (ref)": "Honey\nGI: 58 (link page 3, \"honey, pure\")\nhttps://glycemicindex.com/gi-search/?food_name=honey&product_category=&country=&gi=&gi_filter=&serving_size_(g)=&serving_size_(g)_filter=&carbs_per_serve_(g)=&carbs_per_serve_(g)_filter=&gl=&gl_filter="
  },
  {
    "id": "1141",
    "type": "meal",
    "|| name                                               ||": "Bao with vegetable filling",
    "rating": "2",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "alternate-spellings": "Bao: Pau, Pao",
    "weight-g": "77",
    "calories": "150",
    "fat": "4.7",
    "cal-from-fat": "42.3",
    "per-cal-from-fat": "28",
    "saturated": "2",
    "polyunsaturated": "0.6",
    "monounsaturated": "1.9",
    "cholesterol": "0",
    "sodium": "281",
    "carbs": "22.9",
    "fibre": "2.5",
    "sugar": "3.9",
    "protein": "4.2",
    "cal-from-protein": "17",
    "per-cal-from-protein": "11",
    "potassium": "86",
    "calcium": "26",
    "iron": "0.7",
    "phosphorus": "45",
    "source": "N Buddy",
    "old units (ref)": "Vegetable Pau"
  },
  {
    "id": "1142",
    "type": "meal",
    "|| name                                               ||": "Pork trotter with egg, vinegar and ginger",
    "rating": "1",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-translation": "Te Ka",
    "weight-g": "275",
    "calories": "311",
    "fat": "16.8",
    "cal-from-fat": "151.2",
    "per-cal-from-fat": "49",
    "saturated": "4.7",
    "polyunsaturated": "1.5",
    "monounsaturated": "9",
    "trans": "0",
    "cholesterol": "263",
    "sodium": "958",
    "carbs": "13.8",
    "fibre": "0.3",
    "sugar": "13.8",
    "protein": "26.1",
    "cal-from-protein": "104",
    "per-cal-from-protein": "34",
    "potassium": "505",
    "calcium": "42",
    "iron": "9.7",
    "phosphorus": "587",
    "source": "N Buddy",
    "old units (ref)": "Pig's Trotters and Egg in Ginger and Sweetened Vinegar"
  },
  {
    "id": "1143",
    "type": "snack",
    "|| name                                               ||": "Feta cheese",
    "rating": "2",
    "high-calcium": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "square",
    "info-portion-aid": "5cm square",
    "weight-g": "17",
    "calories": "45",
    "fat": "3.6",
    "cal-from-fat": "32.4",
    "per-cal-from-fat": "72",
    "saturated": "2.5",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.8",
    "cholesterol": "15",
    "sodium": "156",
    "carbs": "0.7",
    "fibre": "0",
    "sugar": "0.7",
    "protein": "2.4",
    "cal-from-protein": "10",
    "per-cal-from-protein": "21",
    "potassium": "11",
    "calcium": "84",
    "iron": "0.1",
    "phosphorus": "57",
    "source": "N Buddy",
    "old units (ref)": "Feta Cheese"
  },
  {
    "id": "1144",
    "type": "meal",
    "|| name                                               ||": "Pork, braised",
    "rating": "2",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "info-translation": "Lor Bak",
    "alternate-spellings": "Lor: Lou",
    "weight-g": "100",
    "calories": "213",
    "fat": "15",
    "cal-from-fat": "135",
    "per-cal-from-fat": "63",
    "saturated": "6.5",
    "polyunsaturated": "3.5",
    "monounsaturated": "5.5",
    "cholesterol": "61",
    "sodium": "212",
    "carbs": "1.5",
    "fibre": "0.2",
    "sugar": "1.5",
    "protein": "18",
    "cal-from-protein": "72",
    "per-cal-from-protein": "34",
    "potassium": "80",
    "calcium": "19",
    "iron": "0.9",
    "phosphorus": "99",
    "source": "N Buddy",
    "old units (ref)": "Braised Pork, lean"
  },
  {
    "id": "1145",
    "type": "drink",
    "|| name                                               ||": "Tonic water",
    "rating": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "can",
    "info-include-exclude": "with sugar and quinine",
    "weight-g": "325",
    "calories": "111",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "carbs": "27.6",
    "sugar": "27.6",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "source": "N Buddy",
    "old units (ref)": "F&N Extra Dry Tonic Water, can"
  },
  {
    "id": "1146",
    "type": "snack",
    "|| name                                               ||": "Poong tor kueh",
    "rating": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "alternate-spellings": "Poong: Png, Peng, Pung\nKueh: Kuih, Kue",
    "weight-g": "107",
    "calories": "200",
    "fat": "4.8",
    "cal-from-fat": "43.2",
    "per-cal-from-fat": "22",
    "saturated": "1.8",
    "polyunsaturated": "0.8",
    "monounsaturated": "2",
    "cholesterol": "4",
    "sodium": "415",
    "carbs": "35.5",
    "fibre": "1",
    "protein": "4",
    "cal-from-protein": "16",
    "per-cal-from-protein": "8",
    "potassium": "39",
    "calcium": "14",
    "iron": "1.4",
    "phosphorus": "36",
    "source": "N Buddy",
    "old units (ref)": "Pung Kueh"
  },
  {
    "id": "1147",
    "type": "meal",
    "|| name                                               ||": "Pizza, vegetarian thin crust",
    "rating": "2",
    "high-phosphate": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "info-include-exclude": "with vegetables and cheese",
    "weight-g": "62",
    "calories": "108",
    "fat": "3.4",
    "cal-from-fat": "30.6",
    "per-cal-from-fat": "28",
    "saturated": "1.9",
    "polyunsaturated": "0.3",
    "monounsaturated": "1.1",
    "trans": "0",
    "cholesterol": "12",
    "sodium": "171",
    "carbs": "14.6",
    "fibre": "1.3",
    "sugar": "3.9",
    "protein": "4.9",
    "cal-from-protein": "20",
    "per-cal-from-protein": "18",
    "potassium": "84",
    "calcium": "88",
    "iron": "0.4",
    "phosphorus": "106",
    "source": "N Buddy",
    "old units (ref)": "Thin Crust Wholemeal Pizza, cheese and vegetables topping\nGI: 49 (link, page 1, \"Pizza, Vegetarian Supreme, thin and crispy (7.8% fat))\"\nhttps://glycemicindex.com/gi-search/?food_name=pizza&product_category=&country=&gi=&gi_filter=&serving_size_(g)=&serving_size_(g)_filter=&carbs_per_serve_(g)=&carbs_per_serve_(g)_filter=&gl=&gl_filter="
  },
  {
    "id": "1148",
    "type": "meal",
    "|| name                                               ||": "Pizza, vegetarian thick crust",
    "rating": "2",
    "high-fat": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "info-include-exclude": "with vegetables and cheese",
    "weight-g": "110",
    "calories": "264",
    "fat": "10.4",
    "cal-from-fat": "93.6",
    "per-cal-from-fat": "35",
    "saturated": "4.1",
    "polyunsaturated": "2.6",
    "monounsaturated": "2.8",
    "cholesterol": "16.5",
    "sodium": "592",
    "carbs": "30.1",
    "fibre": "2.3",
    "sugar": "3.6",
    "protein": "10.4",
    "cal-from-protein": "42",
    "per-cal-from-protein": "16",
    "potassium": "187",
    "calcium": "174",
    "iron": "2.7",
    "phosphorus": "202",
    "source": "USDA"
  },
  {
    "id": "1149",
    "type": "meal",
    "|| name                                               ||": "Chicken hor fun",
    "rating": "1",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "449",
    "calories": "437",
    "fat": "13.1",
    "cal-from-fat": "117.9",
    "per-cal-from-fat": "27",
    "saturated": "4",
    "polyunsaturated": "2.8",
    "monounsaturated": "6.1",
    "trans": "0",
    "cholesterol": "67",
    "sodium": "1582",
    "carbs": "65.2",
    "fibre": "6.7",
    "sugar": "0",
    "protein": "14.2",
    "cal-from-protein": "57",
    "per-cal-from-protein": "13",
    "potassium": "252",
    "calcium": "31",
    "iron": "0",
    "phosphorus": "140",
    "source": "N Buddy",
    "old units (ref)": "Mushroom with Shredded Chicken Hor Fun"
  },
  {
    "id": "1150",
    "type": "snack",
    "|| name                                               ||": "Sesame oil",
    "rating": "2",
    "healthy-fats": "1",
    "high-fat": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "15",
    "calories": "135",
    "fat": "15",
    "cal-from-fat": "135",
    "per-cal-from-fat": "100",
    "saturated": "2.1",
    "polyunsaturated": "6.6",
    "monounsaturated": "5.6",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "2",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "3",
    "calcium": "1",
    "iron": "0",
    "phosphorus": "0",
    "source": "N Buddy",
    "old units (ref)": "Sesame Oil"
  },
  {
    "id": "1151",
    "type": "drink",
    "|| name                                               ||": "Chocolate drink, sweetened",
    "rating": "1",
    "high-calcium": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "sachet",
    "weight-g": "30",
    "calories": "114",
    "fat": "1.8",
    "cal-from-fat": "16.2",
    "per-cal-from-fat": "14",
    "saturated": "1.1",
    "polyunsaturated": "0.3",
    "monounsaturated": "0.4",
    "trans": "0",
    "cholesterol": "2",
    "sodium": "99",
    "carbs": "22.5",
    "fibre": "1",
    "sugar": "21.7",
    "protein": "1.9",
    "cal-from-protein": "8",
    "per-cal-from-protein": "7",
    "potassium": "330",
    "calcium": "120",
    "iron": "1.7",
    "phosphorus": "280",
    "source": "N Buddy",
    "old units (ref)": "Cadbury Hot Chocolate Drink\nGI: 26 (link, page 1, \"Aussie Bodies Start the Day UHT, Chocolate flavored drink\"\nhttps://glycemicindex.com/gi-search/?food_name=chocolate+drink&product_category=&country=&gi=&gi_filter=&serving_size_(g)=&serving_size_(g)_filter=&carbs_per_serve_(g)=&carbs_per_serve_(g)_filter=&gl=&gl_filter="
  },
  {
    "id": "1152",
    "type": "snack",
    "|| name                                               ||": "Bird nest, without sugar",
    "rating": "2",
    "serving": "1",
    "|| unit ||": "bottle",
    "info-brands": "Brand's",
    "info-include-exclude": "with sugar alcohols",
    "weight-g": "68",
    "calories": "32",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "5",
    "carbs": "7.4",
    "fibre": "0",
    "sugar": "0",
    "protein": "0.5",
    "cal-from-protein": "2",
    "per-cal-from-protein": "6",
    "source": "N Buddy",
    "old units (ref)": "BRAND'S\nBird's Nest Sugar Free\nhttps://www.watsons.com.sg/bird-s-nest-sugar-free-6s-x-68ml/p/BP_20983?gclid=Cj0KCQjwpf2IBhDkARIsAGVo0D1pEYniibcqXvoySyjD_NV7tcVtCXEbMSHTlQetufxS0sSJrR95nSEaAv4aEALw_wcB"
  },
  {
    "id": "1153",
    "type": "snack",
    "|| name                                               ||": "Chee cheong fun, with sweet sauce",
    "rating": "1",
    "high-gi": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "alternate-spellings": "Cheong: Cheung",
    "weight-g": "101",
    "calories": "131",
    "fat": "2.2",
    "cal-from-fat": "19.8",
    "per-cal-from-fat": "15",
    "saturated": "1",
    "polyunsaturated": "0.4",
    "monounsaturated": "0.7",
    "cholesterol": "0",
    "sodium": "198",
    "carbs": "25.3",
    "fibre": "1.2",
    "sugar": "8",
    "protein": "2.5",
    "cal-from-protein": "10",
    "per-cal-from-protein": "8",
    "potassium": "24",
    "calcium": "6",
    "iron": "0.4",
    "phosphorus": "0",
    "source": "N Buddy",
    "old units (ref)": "Chee Cheong Fan, with sweet sauce\nGI: 81 (link. page 3, \"Chee cheong fun\")\nhttps://www.nature.com/articles/s41387-020-00145-w.pdf"
  },
  {
    "id": "1154",
    "type": "meal",
    "|| name                                               ||": "Miso soup",
    "rating": "2",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "102",
    "calories": "12",
    "fat": "0.7",
    "cal-from-fat": "6.3",
    "per-cal-from-fat": "53",
    "saturated": "0.1",
    "polyunsaturated": "0.4",
    "monounsaturated": "0.1",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "323",
    "carbs": "0.4",
    "fibre": "0.4",
    "sugar": "0.4",
    "protein": "1.1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "37",
    "potassium": "34",
    "calcium": "9",
    "iron": "0",
    "phosphorus": "13",
    "source": "N Buddy",
    "old units (ref)": "Soup, Miso\nGI: 71 (link, pg 12, \"Soybean paste soup (miso shiru) and rice\")\nhttps://www.nature.com/articles/s41387-020-00145-w.pdf"
  },
  {
    "id": "1155",
    "type": "snack",
    "|| name                                               ||": "Potato salad",
    "rating": "2",
    "high-fat": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "weight-g": "168",
    "calories": "350",
    "fat": "26.8",
    "cal-from-fat": "241.2",
    "per-cal-from-fat": "69",
    "saturated": "2.2",
    "polyunsaturated": "8.2",
    "monounsaturated": "3.4",
    "trans": "0",
    "cholesterol": "12",
    "sodium": "342",
    "carbs": "25.8",
    "fibre": "2.6",
    "sugar": "7.6",
    "protein": "5",
    "cal-from-protein": "20",
    "per-cal-from-protein": "6",
    "potassium": "324",
    "calcium": "26",
    "iron": "2.6",
    "phosphorus": "50",
    "source": "N Buddy",
    "old units (ref)": "Starbucks, Classic Potato Salad"
  },
  {
    "id": "1156",
    "type": "meal",
    "|| name                                               ||": "Stir fried pork rib with bitter gourd",
    "rating": "2",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "red-meat": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "150",
    "calories": "190",
    "fat": "12.4",
    "cal-from-fat": "111.6",
    "per-cal-from-fat": "59",
    "saturated": "4.8",
    "polyunsaturated": "1.4",
    "monounsaturated": "4.8",
    "trans": "0.3",
    "cholesterol": "19",
    "sodium": "125",
    "carbs": "11.2",
    "fibre": "3.3",
    "sugar": "8",
    "protein": "8.3",
    "cal-from-protein": "33",
    "per-cal-from-protein": "17",
    "potassium": "239",
    "calcium": "44",
    "iron": "1.5",
    "phosphorus": "72",
    "source": "N Buddy",
    "old units (ref)": "Pork Ribs Bittergourd in Spcy Tamarind Gravy, Assam Babi Masak Peria"
  },
  {
    "id": "1157",
    "type": "meal",
    "|| name                                               ||": "Minced pork, stir fried",
    "rating": "2",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "115",
    "calories": "314",
    "fat": "26",
    "cal-from-fat": "234",
    "per-cal-from-fat": "75",
    "saturated": "9",
    "polyunsaturated": "5",
    "monounsaturated": "10",
    "trans": "0",
    "cholesterol": "72",
    "sodium": "421",
    "carbs": "2",
    "fibre": "0",
    "sugar": "2",
    "protein": "18",
    "cal-from-protein": "72",
    "per-cal-from-protein": "23",
    "potassium": "306",
    "calcium": "17",
    "iron": "1",
    "phosphorus": "182",
    "source": "N Buddy",
    "old units (ref)": "Stir-fried Minced Meat, pork"
  },
  {
    "id": "1158",
    "type": "meal",
    "|| name                                               ||": "Minced pork, steamed",
    "rating": "2",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "weight-g": "100",
    "calories": "318",
    "fat": "25",
    "cal-from-fat": "225",
    "per-cal-from-fat": "71",
    "saturated": "8.7",
    "polyunsaturated": "4.2",
    "monounsaturated": "11.9",
    "cholesterol": "74",
    "sodium": "366",
    "carbs": "0.4",
    "fibre": "0",
    "sugar": "0.2",
    "protein": "18.6",
    "cal-from-protein": "74",
    "per-cal-from-protein": "23",
    "potassium": "203",
    "calcium": "12",
    "iron": "0.9",
    "phosphorus": "101",
    "source": "N Buddy",
    "old units (ref)": "Minced Pork, steamed"
  },
  {
    "id": "1159",
    "type": "meal",
    "|| name                                               ||": "Salted fish",
    "rating": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "25",
    "calories": "46",
    "fat": "0.4",
    "cal-from-fat": "3.6",
    "per-cal-from-fat": "8",
    "sodium": "433",
    "carbs": "0.1",
    "fibre": "0",
    "sugar": "0",
    "protein": "10.5",
    "cal-from-protein": "42",
    "per-cal-from-protein": "91",
    "potassium": "61",
    "calcium": "44",
    "iron": "0.4",
    "phosphorus": "18",
    "source": "N Buddy",
    "old units (ref)": "Salted Fish, dried"
  },
  {
    "id": "1160",
    "type": "meal",
    "|| name                                               ||": "Zha jiang mian",
    "rating": "2",
    "high-fat": "1",
    "high-iron": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-translation": "Fried sauce noodles",
    "weight-g": "462",
    "calories": "670",
    "fat": "28.4",
    "cal-from-fat": "255.6",
    "per-cal-from-fat": "38",
    "saturated": "11.5",
    "polyunsaturated": "5.5",
    "monounsaturated": "11.5",
    "cholesterol": "0",
    "sodium": "1404",
    "carbs": "77.6",
    "fibre": "14.8",
    "sugar": "4.6",
    "protein": "26.3",
    "cal-from-protein": "105",
    "per-cal-from-protein": "16",
    "calcium": "79",
    "iron": "3.5",
    "source": "N Buddy",
    "old units (ref)": "Zha Jiang Mian"
  },
  {
    "id": "1161",
    "type": "meal",
    "|| name                                               ||": "Mee sua, cooked",
    "rating": "2",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-include-exclude": "plain",
    "alternate-spellings": "Sua: Suah",
    "weight-g": "100",
    "calories": "190",
    "fat": "0.9",
    "cal-from-fat": "8.1",
    "per-cal-from-fat": "4",
    "saturated": "0.6",
    "sodium": "846",
    "carbs": "31.6",
    "fibre": "4.4",
    "protein": "13.9",
    "cal-from-protein": "56",
    "per-cal-from-protein": "29",
    "source": "HPB",
    "old units (ref)": "Mee sua, cooked"
  },
  {
    "id": "1162",
    "type": "snack",
    "|| name                                               ||": "Egg tofu",
    "rating": "2",
    "high-cholesterol": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "pack",
    "info-portion-aid": "6\" long",
    "weight-g": "150",
    "calories": "99",
    "fat": "6.3",
    "cal-from-fat": "56.7",
    "per-cal-from-fat": "57",
    "saturated": "2",
    "cholesterol": "94.8",
    "sodium": "513",
    "fibre": "2.3",
    "protein": "10.5",
    "cal-from-protein": "42",
    "per-cal-from-protein": "42",
    "source": "HPB",
    "old units (ref)": "Egg tofu"
  },
  {
    "id": "1163",
    "type": "meal",
    "|| name                                               ||": "Pasta, pesto sauce",
    "rating": "2",
    "high-fat": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "314",
    "calories": "580.9",
    "fat": "32.9",
    "cal-from-fat": "296.1",
    "per-cal-from-fat": "51",
    "saturated": "10.3",
    "sodium": "1033",
    "carbs": "51.8",
    "fibre": "3.7",
    "protein": "18.8",
    "cal-from-protein": "75",
    "per-cal-from-protein": "13",
    "source": "N Buddy",
    "old units (ref)": "Plain Pesto Pasta\nGI: 57 (link, page 1, \"Rotini with Chicken Pesto (entrée), President's Choice® Blue Menu™ convenience meals\")\nhttps://glycemicindex.com/gi-search/?food_name=pesto&product_category=&country=&gi=&gi_filter=&serving_size_(g)=&serving_size_(g)_filter=&carbs_per_serve_(g)=&carbs_per_serve_(g)_filter=&gl=&gl_filter="
  },
  {
    "id": "1164",
    "type": "drink",
    "|| name                                               ||": "Champagne",
    "rating": "2",
    "alcohol": "1",
    "serving": "1",
    "|| unit ||": "glass",
    "info-examples": "Prosecco, Cava, sparkling wine",
    "weight-g": "150",
    "calories": "98",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "15",
    "carbs": "1.5",
    "fibre": "0",
    "sugar": "1.5",
    "protein": "0.3",
    "cal-from-protein": "1",
    "per-cal-from-protein": "1",
    "potassium": "53",
    "calcium": "12",
    "iron": "0.3",
    "phosphorus": "26",
    "source": "N Buddy",
    "old units (ref)": "Champagne"
  },
  {
    "id": "1165",
    "type": "snack",
    "|| name                                               ||": "Chocolate egg",
    "rating": "1",
    "high-fat": "1",
    "high-sugar": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "info-brands": "Cadbury",
    "info-examples": "Easter",
    "weight-g": "31",
    "calories": "150",
    "fat": "8",
    "cal-from-fat": "72",
    "per-cal-from-fat": "48",
    "saturated": "6",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "35",
    "carbs": "18",
    "fibre": "0.1",
    "sugar": "14",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "3",
    "potassium": "0",
    "calcium": "20.2",
    "iron": "0.7",
    "source": "USDA",
    "old units (ref)": "MILK CHOCOLATE EGG\nbrand owner: PETER PAUL"
  },
  {
    "id": "1166",
    "type": "snack",
    "|| name                                               ||": "Salmon, smoked",
    "rating": "2",
    "high-fat": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "processed": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "25",
    "calories": "34",
    "fat": "1.2",
    "cal-from-fat": "10.8",
    "per-cal-from-fat": "32",
    "saturated": "0.2",
    "polyunsaturated": "0.4",
    "monounsaturated": "0.5",
    "cholesterol": "12",
    "sodium": "428",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "5.8",
    "cal-from-protein": "23",
    "per-cal-from-protein": "68",
    "potassium": "66",
    "calcium": "4",
    "iron": "0.1",
    "phosphorus": "63",
    "source": "N Buddy",
    "old units (ref)": "Smoke Salmon Fish"
  },
  {
    "id": "1167",
    "type": "snack",
    "|| name                                               ||": "Bak kwa bun",
    "rating": "1",
    "high-fat": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bun",
    "weight-g": "62",
    "calories": "196",
    "fat": "8.8",
    "cal-from-fat": "79.2",
    "per-cal-from-fat": "40",
    "saturated": "1.3",
    "polyunsaturated": "3.2",
    "monounsaturated": "2.1",
    "cholesterol": "13",
    "sodium": "612",
    "carbs": "37.1",
    "fibre": "4",
    "sugar": "10.3",
    "protein": "11",
    "cal-from-protein": "44",
    "per-cal-from-protein": "22",
    "potassium": "192",
    "calcium": "61",
    "iron": "0.3",
    "phosphorus": "41",
    "source": "N Buddy",
    "old units (ref)": "Bak Kwa Bun"
  },
  {
    "id": "1168",
    "type": "meal",
    "|| name                                               ||": "Frittata",
    "rating": "2",
    "high-calcium": "1",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "info-include-exclude": "with cheese and vegetables",
    "weight-g": "135",
    "calories": "234",
    "fat": "16.3",
    "cal-from-fat": "146.7",
    "per-cal-from-fat": "63",
    "saturated": "7.7",
    "polyunsaturated": "1.4",
    "monounsaturated": "5.2",
    "trans": "0.3",
    "cholesterol": "457",
    "sodium": "275",
    "carbs": "4.1",
    "fibre": "1.3",
    "sugar": "2.7",
    "protein": "17.8",
    "cal-from-protein": "71",
    "per-cal-from-protein": "30",
    "potassium": "421",
    "calcium": "242",
    "iron": "3",
    "phosphorus": "299",
    "source": "N Buddy",
    "old units (ref)": "Frittata, cheese and spinach"
  },
  {
    "id": "1169",
    "type": "drink",
    "|| name                                               ||": "Soy milk, reduced sugar (Healthier Choice Symbol, high calcium)",
    "rating": "2",
    "high-calcium": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "info-brands": "Nutrisoy",
    "weight-g": "250",
    "calories": "165",
    "fat": "6",
    "cal-from-fat": "54",
    "per-cal-from-fat": "33",
    "saturated": "1",
    "cholesterol": "0",
    "sodium": "8",
    "carbs": "17",
    "fibre": "1.3",
    "sugar": "10.5",
    "protein": "10.8",
    "cal-from-protein": "43",
    "per-cal-from-protein": "26",
    "calcium": "500",
    "iron": "0",
    "source": "N Buddy",
    "old units (ref)": "soyabean milk, reduced sugar"
  },
  {
    "id": "1170",
    "type": "snack",
    "|| name                                               ||": "Keto cake",
    "rating": "2",
    "high-fat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "info-examples": "chocolate, banana, vanilla",
    "weight-g": "100",
    "calories": "249",
    "fat": "15.5",
    "cal-from-fat": "139.5",
    "per-cal-from-fat": "56",
    "carbs": "4.5",
    "fibre": "2.3",
    "protein": "10.2",
    "cal-from-protein": "41",
    "per-cal-from-protein": "16",
    "calcium": "20",
    "old units (ref)": "https://www.angebakesketo.com/online-store/Keto-Chocolate-Tarts-Box-of-6-p264567390"
  },
  {
    "id": "1171",
    "type": "snack",
    "|| name                                               ||": "Sugar",
    "rating": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "tsp",
    "weight-g": "4.2",
    "calories": "16.3",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "0.04",
    "carbs": "4.2",
    "fibre": "0",
    "sugar": "4.2",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "0.08",
    "calcium": "0.042",
    "iron": "0.002",
    "phosphorus": "0",
    "source": "USDA",
    "old units (ref)": "Sugars, granulated\nGI: 65 (link, page 5, \"Sugar (Sucrose), 50 g portion\")\nhttps://glycemicindex.com/gi-search/?food_name=sugar&product_category=&country=&gi=&gi_filter=&serving_size_(g)=&serving_size_(g)_filter=&carbs_per_serve_(g)=&carbs_per_serve_(g)_filter=&gl=&gl_filter="
  },
  {
    "id": "1172",
    "type": "meal",
    "|| name                                               ||": "Alfalfa sprouts",
    "rating": "3",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "33",
    "calories": "8",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "23",
    "saturated": "0",
    "polyunsaturated": "0.1",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "2",
    "carbs": "0.7",
    "fibre": "0.6",
    "sugar": "0.1",
    "protein": "1.3",
    "cal-from-protein": "5",
    "per-cal-from-protein": "65",
    "potassium": "26",
    "calcium": "11",
    "iron": "0.3",
    "phosphorus": "23",
    "source": "N Buddy",
    "old units (ref)": "Alfalfa Sprouts"
  },
  {
    "id": "1173",
    "type": "meal",
    "|| name                                               ||": "Fish sauce",
    "rating": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "16",
    "calories": "9",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "20",
    "sodium": "1013",
    "carbs": "3",
    "fibre": "0",
    "sugar": "1.8",
    "protein": "0.8",
    "cal-from-protein": "3",
    "per-cal-from-protein": "36",
    "potassium": "3",
    "calcium": "61",
    "iron": "0.5",
    "phosphorus": "50",
    "source": "N Buddy",
    "old units (ref)": "Fish Sauce"
  },
  {
    "id": "1174",
    "type": "meal",
    "|| name                                               ||": "Beehoon, boiled",
    "rating": "2",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-translation": "Rice vermicelli",
    "info-include-exclude": "plain",
    "weight-g": "100",
    "calories": "153",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "1",
    "saturated": "0.1",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "1",
    "carbs": "35.4",
    "fibre": "0.9",
    "sugar": "0",
    "protein": "2.5",
    "cal-from-protein": "10",
    "per-cal-from-protein": "7",
    "potassium": "2",
    "calcium": "1",
    "iron": "0.4",
    "phosphorus": "3",
    "source": "N Buddy",
    "old units (ref)": "Beehoon, wet, raw\n(got too many vermicelli, on nature.com , not sure which to use."
  },
  {
    "id": "1175",
    "type": "meal",
    "|| name                                               ||": "Couscous",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "157",
    "calories": "176",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "8",
    "carbs": "36",
    "fibre": "2",
    "sugar": "0",
    "protein": "5",
    "cal-from-protein": "20",
    "per-cal-from-protein": "11",
    "potassium": "91",
    "calcium": "13",
    "iron": "0",
    "phosphorus": "35",
    "source": "N Buddy",
    "old units (ref)": "Couscous, cooked\nGI: 69 (link, page 1, \"Couscous, boiled 5 min\")\nhttps://glycemicindex.com/gi-search/?food_name=couscous&product_category=&country=&gi=&gi_filter=&serving_size_(g)=&serving_size_(g)_filter=&carbs_per_serve_(g)=&carbs_per_serve_(g)_filter=&gl=&gl_filter="
  },
  {
    "id": "1176",
    "type": "meal",
    "|| name                                               ||": "Carrot, raw",
    "rating": "3",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "medium",
    "weight-g": "81",
    "calories": "19",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "5",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "35",
    "carbs": "4",
    "fibre": "2.1",
    "sugar": "2",
    "protein": "0.7",
    "cal-from-protein": "3",
    "per-cal-from-protein": "15",
    "potassium": "211",
    "calcium": "25",
    "iron": "0.2",
    "phosphorus": "12",
    "source": "N Buddy",
    "old units (ref)": "Raw Carrot, peeled\nGI: 16 (link, page 1, \"Carrots, raw\"\nhttps://glycemicindex.com/gi-search/?food_name=carrot&product_category=&country=&gi=&gi_filter=&serving_size_(g)=&serving_size_(g)_filter=&carbs_per_serve_(g)=&carbs_per_serve_(g)_filter=&gl=&gl_filter="
  },
  {
    "id": "1177",
    "type": "meal",
    "|| name                                               ||": "Idli",
    "rating": "2",
    "high-phosphate": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "75",
    "calories": "80",
    "fat": "0.5",
    "cal-from-fat": "4.5",
    "per-cal-from-fat": "6",
    "saturated": "0.2",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.2",
    "cholesterol": "0",
    "sodium": "281",
    "carbs": "15.5",
    "fibre": "2",
    "sugar": "0",
    "protein": "3.4",
    "cal-from-protein": "14",
    "per-cal-from-protein": "17",
    "potassium": "118",
    "calcium": "14",
    "iron": "0.5",
    "phosphorus": "54",
    "source": "N Buddy",
    "old units (ref)": "Idli\nGI: 85 (link, page 4, \"Rice idli\"\nhttps://www.nature.com/articles/s41387-020-00145-w.pdf"
  },
  {
    "id": "1178",
    "type": "snack",
    "|| name                                               ||": "Biscuit with cheese filling",
    "rating": "2",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "6",
    "|| unit ||": "piece",
    "weight-g": "30",
    "calories": "144",
    "fat": "6.6",
    "cal-from-fat": "59.4",
    "per-cal-from-fat": "41",
    "saturated": "4.2",
    "polyunsaturated": "0.5",
    "monounsaturated": "1.5",
    "cholesterol": "4",
    "sodium": "286",
    "carbs": "17.3",
    "fibre": "1",
    "sugar": "1",
    "protein": "3.9",
    "cal-from-protein": "16",
    "per-cal-from-protein": "11",
    "potassium": "70",
    "calcium": "39",
    "iron": "0.4",
    "phosphorus": "71",
    "source": "N Buddy",
    "old units (ref)": "Cheese Cracker\nGI: 54 (link, page 1, (Combos Snacks Cheddar Cheese Crackers (M&M/Mars, USA))\"\nhttps://glycemicindex.com/gi-search/?food_name=cheese+crackers&product_category=&country=&gi=&gi_filter=&serving_size_(g)=&serving_size_(g)_filter=&carbs_per_serve_(g)=&carbs_per_serve_(g)_filter=&gl=&gl_filter="
  },
  {
    "id": "1179",
    "type": "snack",
    "|| name                                               ||": "Mixed fruit salad",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "info-include-exclude": "without dressing",
    "weight-g": "175",
    "calories": "91",
    "fat": "0.4",
    "cal-from-fat": "3.6",
    "per-cal-from-fat": "4",
    "saturated": "0.1",
    "polyunsaturated": "0.1",
    "monounsaturated": "0",
    "sodium": "4",
    "carbs": "23",
    "fibre": "3.3",
    "sugar": "16.7",
    "protein": "1.3",
    "cal-from-protein": "5",
    "per-cal-from-protein": "6",
    "potassium": "348",
    "calcium": "28",
    "iron": "0.4",
    "phosphorus": "28",
    "source": "N Buddy",
    "old units (ref)": "Fruit Salad, no dressing\nGI: 54 (link, page 1, (\"Fruit Salad canned in fruit juice, containing peach, pear, apricot, pineapple and cherries\")\nhttps://glycemicindex.com/gi-search/?food_name=fruit+salad&product_category=&country=&gi=&gi_filter=&serving_size_(g)=&serving_size_(g)_filter=&carbs_per_serve_(g)=&carbs_per_serve_(g)_filter=&gl=&gl_filter="
  },
  {
    "id": "1180",
    "type": "snack",
    "|| name                                               ||": "Pretzel, with cinnamon sugar",
    "rating": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "pretzel",
    "weight-g": "147",
    "calories": "470",
    "fat": "12",
    "cal-from-fat": "108",
    "per-cal-from-fat": "23",
    "saturated": "7",
    "polyunsaturated": "0.5",
    "monounsaturated": "5",
    "trans": "0",
    "cholesterol": "25",
    "sodium": "400",
    "carbs": "84",
    "fibre": "2",
    "sugar": "29",
    "protein": "8",
    "cal-from-protein": "32",
    "per-cal-from-protein": "7",
    "calcium": "20",
    "iron": "0.7",
    "source": "N Buddy",
    "old units (ref)": "Cinnamon Sugar Pretzel"
  },
  {
    "id": "1181",
    "type": "meal",
    "|| name                                               ||": "Luo han zhai",
    "rating": "2",
    "high-fat": "1",
    "high-salt": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "info-translation": "Buddha's Delight",
    "alternate-spellings": "Luo: Lo \nZhai: Chai, Jai",
    "weight-g": "174",
    "calories": "156.6",
    "fat": "12.5",
    "cal-from-fat": "112.5",
    "per-cal-from-fat": "72",
    "saturated": "5.2",
    "sodium": "765.6",
    "carbs": "5.7",
    "fibre": "2.6",
    "protein": "5",
    "cal-from-protein": "20",
    "per-cal-from-protein": "13",
    "source": "HPB",
    "old units (ref)": "Lo han chai"
  },
  {
    "id": "1182",
    "type": "drink",
    "|| name                                               ||": "Coffee, decaffinated",
    "rating": "2",
    "high-iron": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "237",
    "calories": "0",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "5",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "0.2",
    "cal-from-protein": "1",
    "per-cal-from-protein": "0",
    "potassium": "128",
    "calcium": "2",
    "source": "N Buddy",
    "old units (ref)": "Delifrance, Decaffeinated Coffee Brewed"
  },
  {
    "id": "1183",
    "type": "meal",
    "|| name                                               ||": "Non dairy creamer",
    "rating": "1",
    "high-fat": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "sachet",
    "info-brands": "Coffee Mate",
    "weight-g": "3",
    "calories": "16",
    "fat": "1.1",
    "cal-from-fat": "9.9",
    "per-cal-from-fat": "62",
    "saturated": "1",
    "polyunsaturated": "0.1",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "5",
    "carbs": "1.7",
    "fibre": "0",
    "sugar": "1.7",
    "protein": "0.1",
    "cal-from-protein": "0",
    "per-cal-from-protein": "3",
    "potassium": "24",
    "calcium": "1",
    "iron": "0",
    "phosphorus": "13",
    "source": "N Buddy",
    "old units (ref)": "Non Dairt Creamer, powder"
  },
  {
    "id": "1184",
    "type": "drink",
    "|| name                                               ||": "Citron tea, paste",
    "rating": "2",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "info-translation": "Yuja Cha",
    "alternate-spellings": "Yuja: Yuzu",
    "weight-g": "15",
    "calories": "40",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "2",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "1",
    "carbs": "9.8",
    "fibre": "0.4",
    "sugar": "9.1",
    "protein": "0.1",
    "cal-from-protein": "0",
    "per-cal-from-protein": "1",
    "calcium": "2",
    "source": "N Buddy",
    "old units (ref)": "P&L Jeju Citron, paste"
  },
  {
    "id": "1185",
    "type": "meal",
    "|| name                                               ||": "Oyster sauce",
    "rating": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "18",
    "calories": "9.2",
    "fat": "0.05",
    "cal-from-fat": "0.45",
    "per-cal-from-fat": "5",
    "saturated": "0.008",
    "polyunsaturated": "0.012",
    "monounsaturated": "0.013",
    "cholesterol": "0",
    "sodium": "491",
    "carbs": "2.7",
    "fibre": "2",
    "sugar": "0",
    "protein": "0.2",
    "cal-from-protein": "1",
    "per-cal-from-protein": "9",
    "potassium": "9.7",
    "calcium": "5.8",
    "iron": "0.032",
    "phosphorus": "4",
    "source": "USDA",
    "old units (ref)": "Sauce, oyster, ready-to-serve"
  },
  {
    "id": "1186",
    "type": "meal",
    "|| name                                               ||": "Udon soup, with vegetables",
    "rating": "2",
    "high-salt": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "220",
    "calories": "319",
    "fat": "2.5",
    "cal-from-fat": "22.5",
    "per-cal-from-fat": "7",
    "saturated": "0.5",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "1200",
    "carbs": "66",
    "fibre": "3",
    "sugar": "5",
    "protein": "8",
    "cal-from-protein": "32",
    "per-cal-from-protein": "10",
    "calcium": "40",
    "iron": "3",
    "source": "USDA",
    "old units (ref)": "UDON ORIGINAL JAPANESE-STYLE NOODLES, UDON ORIGINAL \nbrand owner: Nasoya Foods USA, LLC\nGI: 62 (link, page 13, \" Noodle-1 (Simabara-udon)\")\nhttps://www.nature.com/articles/s41387-020-00145-w.pdf"
  },
  {
    "id": "1187",
    "type": "meal",
    "|| name                                               ||": "Wakame, stir fried",
    "rating": "2",
    "high-calcium": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "info-translation": "Kelp",
    "weight-g": "105",
    "calories": "66.2",
    "fat": "3.2",
    "cal-from-fat": "28.8",
    "per-cal-from-fat": "44",
    "saturated": "0.9",
    "polyunsaturated": "0.9",
    "monounsaturated": "1",
    "cholesterol": "2.1",
    "sodium": "402",
    "carbs": "8.1",
    "fibre": "0.7",
    "sugar": "0.6",
    "protein": "3.6",
    "cal-from-protein": "14",
    "per-cal-from-protein": "22",
    "potassium": "169",
    "calcium": "132",
    "iron": "2.3",
    "phosphorus": "62",
    "source": "USDA",
    "old units (ref)": "Seaweed, cooked, fat added"
  },
  {
    "id": "1188",
    "type": "meal",
    "|| name                                               ||": "Bread, sourdough",
    "rating": "2",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "50",
    "calories": "115",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "sodium": "210",
    "carbs": "25",
    "fibre": "1",
    "sugar": "1",
    "protein": "4",
    "cal-from-protein": "16",
    "per-cal-from-protein": "14",
    "calcium": "0",
    "iron": "0.4",
    "source": "N Buddy",
    "old units (ref)": "Sourdough Bread\nGI: 57 (link, page 1, (\"Sourdough rye bread\")\nhttps://glycemicindex.com/gi-search/?food_name=sourdough+bread&product_category=&country=&gi=&gi_filter=&serving_size_(g)=&serving_size_(g)_filter=&carbs_per_serve_(g)=&carbs_per_serve_(g)_filter=&gl=&gl_filter="
  },
  {
    "id": "1189",
    "type": "meal",
    "|| name                                               ||": "Mee tai mak, stir fried",
    "rating": "2",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-translation": "Silver needle noodle",
    "alternate-spellings": "Mee: Bee\n",
    "weight-g": "260",
    "calories": "405",
    "fat": "13",
    "cal-from-fat": "117",
    "per-cal-from-fat": "29",
    "saturated": "2",
    "polyunsaturated": "7",
    "monounsaturated": "3",
    "trans": "0",
    "cholesterol": "4",
    "sodium": "879",
    "carbs": "70",
    "fibre": "2",
    "sugar": "8",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "3",
    "potassium": "87",
    "calcium": "57",
    "iron": "2",
    "phosphorus": "40",
    "source": "N Buddy",
    "old units (ref)": "Fried Mee Tai Mak, plain"
  },
  {
    "id": "1190",
    "type": "meal",
    "|| name                                               ||": "Garlic sauce",
    "rating": "2",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "20",
    "calories": "10",
    "fat": "1",
    "cal-from-fat": "9",
    "per-cal-from-fat": "90",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "440",
    "carbs": "1",
    "fibre": "0",
    "sugar": "1",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "0",
    "calcium": "0",
    "iron": "0",
    "phosphorus": "0",
    "source": "N Buddy",
    "old units (ref)": "Nando's Peri Peri sauce, Garlic"
  },
  {
    "id": "1191",
    "type": "meal",
    "|| name                                               ||": "Shimeji mushroom, raw",
    "rating": "2",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "100",
    "calories": "20",
    "fat": "0.3",
    "cal-from-fat": "2.7",
    "per-cal-from-fat": "14",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "carbs": "3.7",
    "fibre": "1.4",
    "sugar": "0.6",
    "protein": "1.9",
    "cal-from-protein": "8",
    "per-cal-from-protein": "38",
    "calcium": "0",
    "iron": "0.7",
    "source": "N Buddy",
    "old units (ref)": "Shimeiji Mushroom, raw"
  },
  {
    "id": "1192",
    "type": "meal",
    "|| name                                               ||": "Carrot halwa",
    "rating": "2",
    "high-calcium": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-translation": "Indian carrot pudding",
    "weight-g": "246",
    "calories": "282",
    "fat": "10.8",
    "cal-from-fat": "97.2",
    "per-cal-from-fat": "34",
    "saturated": "5.9",
    "polyunsaturated": "0.7",
    "monounsaturated": "3.6",
    "trans": "0",
    "cholesterol": "27",
    "sodium": "87",
    "carbs": "41.9",
    "fibre": "2.6",
    "sugar": "38.8",
    "protein": "5.7",
    "cal-from-protein": "23",
    "per-cal-from-protein": "8",
    "potassium": "460",
    "calcium": "175",
    "iron": "0.6",
    "phosphorus": "154",
    "source": "N Buddy",
    "old units (ref)": "Indian Carrot Pudding"
  },
  {
    "id": "1193",
    "type": "meal",
    "|| name                                               ||": "Pork kidney, boiled",
    "rating": "2",
    "high-cholesterol": "1",
    "high-purine": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "11.3",
    "calories": "17.1",
    "fat": "0.5",
    "cal-from-fat": "4.5",
    "per-cal-from-fat": "26",
    "saturated": "0.2",
    "cholesterol": "54.4",
    "sodium": "9",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "70",
    "source": "HPB",
    "old units (ref)": "Pig's kidney, boiled"
  },
  {
    "id": "1194",
    "type": "snack",
    "|| name                                               ||": "Figs, fresh",
    "rating": "3",
    "fruit": "1",
    "high-fibre": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "50",
    "calories": "37",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "5",
    "saturated": "0",
    "polyunsaturated": "0.1",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "1",
    "carbs": "9.6",
    "fibre": "1.5",
    "sugar": "8.2",
    "protein": "0.4",
    "cal-from-protein": "2",
    "per-cal-from-protein": "4",
    "potassium": "117",
    "calcium": "18",
    "iron": "0.2",
    "phosphorus": "7",
    "source": "N Buddy",
    "old units (ref)": "Fresh Figs, raw"
  },
  {
    "id": "1195",
    "type": "drink",
    "|| name                                               ||": "Spinach and avocado smoothie",
    "rating": "2",
    "high-calcium": "1",
    "high-fat": "1",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "info-include-exclude": "with milk",
    "weight-g": "216",
    "calories": "190",
    "fat": "14.1",
    "cal-from-fat": "126.9",
    "per-cal-from-fat": "67",
    "saturated": "2.9",
    "polyunsaturated": "1.6",
    "monounsaturated": "8.4",
    "cholesterol": "8.6",
    "sodium": "71.3",
    "carbs": "13.1",
    "fibre": "6.3",
    "sugar": "5.6",
    "protein": "6",
    "cal-from-protein": "24",
    "per-cal-from-protein": "13",
    "potassium": "745",
    "calcium": "168",
    "iron": "1.4",
    "phosphorus": "162",
    "source": "USDA",
    "old units (ref)": "Vegetable smoothie"
  },
  {
    "id": "1196",
    "type": "snack",
    "|| name                                               ||": "Banana cake",
    "rating": "1",
    "high-fat": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "45",
    "calories": "146",
    "fat": "7.8",
    "cal-from-fat": "70.2",
    "per-cal-from-fat": "48",
    "saturated": "3.2",
    "polyunsaturated": "2",
    "monounsaturated": "2.3",
    "cholesterol": "32",
    "sodium": "64",
    "carbs": "16.3",
    "fibre": "0.6",
    "sugar": "9.5",
    "protein": "2.5",
    "cal-from-protein": "10",
    "per-cal-from-protein": "7",
    "potassium": "86",
    "calcium": "14",
    "iron": "0.3",
    "phosphorus": "36",
    "source": "N Buddy",
    "old units (ref)": "Banana Cake\nGI: 47 (link, page 1, (\"Banana cake, made with sugar\")\nhttps://glycemicindex.com/gi-search/?food_name=banana+cake&product_category=&country=&gi=&gi_filter=&serving_size_(g)=&serving_size_(g)_filter=&carbs_per_serve_(g)=&carbs_per_serve_(g)_filter=&gl=&gl_filter="
  },
  {
    "id": "1197",
    "type": "meal",
    "|| name                                               ||": "Prawn / shrimp, raw",
    "rating": "3",
    "high-iodine": "1",
    "high-phosphate": "1",
    "white-meat": "1",
    "serving": "4",
    "|| unit ||": "piece",
    "weight-g": "28",
    "calories": "19.9",
    "fat": "0.3",
    "cal-from-fat": "2.7",
    "per-cal-from-fat": "14",
    "saturated": "0.07",
    "polyunsaturated": "0.08",
    "monounsaturated": "0.05",
    "trans": "0",
    "cholesterol": "35.3",
    "sodium": "158",
    "carbs": "0.3",
    "fibre": "0",
    "sugar": "0",
    "protein": "3.8",
    "cal-from-protein": "15",
    "per-cal-from-protein": "76",
    "potassium": "31.6",
    "calcium": "15.1",
    "iron": "0.06",
    "phosphorus": "68.3",
    "source": "USDA",
    "old units (ref)": "Crustaceans, shrimp, mixed species, raw (may contain additives to retain moisture)"
  },
  {
    "id": "1198",
    "type": "snack",
    "|| name                                               ||": "Haw flakes",
    "rating": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "packet",
    "weight-g": "12",
    "calories": "39",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "2",
    "carbs": "9",
    "fibre": "0.5",
    "sugar": "8.8",
    "protein": "0.6",
    "cal-from-protein": "2",
    "per-cal-from-protein": "6",
    "potassium": "21",
    "calcium": "7",
    "iron": "0.3",
    "phosphorus": "2",
    "source": "N Buddy",
    "old units (ref)": "Haw Slices, Haw Flakes"
  },
  {
    "id": "1199",
    "type": "meal",
    "|| name                                               ||": "Stir fried french bean with egg",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "weight-g": "100",
    "calories": "96",
    "fat": "7.6",
    "cal-from-fat": "68.4",
    "per-cal-from-fat": "71",
    "saturated": "2.3",
    "polyunsaturated": "3",
    "monounsaturated": "2.1",
    "trans": "0",
    "cholesterol": "90",
    "sodium": "395",
    "carbs": "2.2",
    "fibre": "2.1",
    "sugar": "1.4",
    "protein": "4.6",
    "cal-from-protein": "18",
    "per-cal-from-protein": "19",
    "potassium": "159",
    "calcium": "54",
    "iron": "1.1",
    "phosphorus": "75",
    "source": "N Buddy",
    "old units (ref)": "Stir Fried French Beans with Egg"
  },
  {
    "id": "1200",
    "type": "meal",
    "|| name                                               ||": "Teriyaki chicken",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "info-translation": "Japanese marinated chicken",
    "weight-g": "100",
    "calories": "163",
    "fat": "6",
    "cal-from-fat": "54",
    "per-cal-from-fat": "33",
    "saturated": "1.5",
    "polyunsaturated": "0.9",
    "monounsaturated": "2.5",
    "cholesterol": "105",
    "sodium": "723",
    "carbs": "1.6",
    "fibre": "0",
    "sugar": "1.5",
    "protein": "25.7",
    "cal-from-protein": "103",
    "per-cal-from-protein": "63",
    "potassium": "285",
    "calcium": "16",
    "iron": "0.9",
    "phosphorus": "239",
    "source": "USDA",
    "old units (ref)": "Chicken or turkey with teriyaki"
  },
  {
    "id": "1201",
    "type": "meal",
    "|| name                                               ||": "Pork knuckle, braised",
    "rating": "1",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "403",
    "calories": "281",
    "fat": "13.8",
    "cal-from-fat": "124.2",
    "per-cal-from-fat": "44",
    "saturated": "4.8",
    "polyunsaturated": "2.4",
    "monounsaturated": "5.9",
    "trans": "0.1",
    "cholesterol": "90",
    "sodium": "1262",
    "carbs": "6.7",
    "fibre": "1.1",
    "sugar": "5.6",
    "protein": "29.6",
    "cal-from-protein": "118",
    "per-cal-from-protein": "42",
    "potassium": "167",
    "calcium": "50",
    "iron": "1.8",
    "phosphorus": "57",
    "source": "N Buddy",
    "old units (ref)": "Braised Pork Knuckle"
  },
  {
    "id": "1202",
    "type": "meal",
    "|| name                                               ||": "Sauerkraut",
    "rating": "2",
    "probiotic": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "12",
    "calories": "3",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "48",
    "carbs": "0.4",
    "fibre": "0.3",
    "sugar": "0.1",
    "protein": "0.1",
    "cal-from-protein": "0",
    "per-cal-from-protein": "13",
    "potassium": "22",
    "calcium": "6",
    "iron": "0.2",
    "phosphorus": "3",
    "source": "N Buddy",
    "old units (ref)": "Sauerkraut"
  },
  {
    "id": "1203",
    "type": "meal",
    "|| name                                               ||": "Spatzle with cheese",
    "rating": "2",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "weight-g": "100",
    "calories": "390",
    "fat": "8.3",
    "cal-from-fat": "74.7",
    "per-cal-from-fat": "19",
    "saturated": "3.3",
    "trans": "0",
    "sodium": "1160",
    "carbs": "61.7",
    "fibre": "3",
    "sugar": "3.3",
    "protein": "15",
    "cal-from-protein": "60",
    "per-cal-from-protein": "15",
    "iron": "0",
    "source": "USDA",
    "old units (ref)": "GERMAN CHEESE SPAETZLE WITH ROASTED ONIONS, GERMAN WITH ROASTED ONIONS"
  },
  {
    "id": "1204",
    "type": "meal",
    "|| name                                               ||": "Apple crumble",
    "rating": "2",
    "high-fat": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "146",
    "calories": "353.5",
    "fat": "14.9",
    "cal-from-fat": "134.1",
    "per-cal-from-fat": "38",
    "saturated": "11.5",
    "cholesterol": "11.8",
    "sodium": "92",
    "carbs": "49.1",
    "fibre": "6",
    "protein": "5.8",
    "cal-from-protein": "23",
    "per-cal-from-protein": "7",
    "source": "HPB",
    "old units (ref)": "Apple crumble\nGI: 41 (link, page 1, (\"Apple Berry crumble cake, President's Choice® Blue Menu™ \")\nhttps://glycemicindex.com/gi-search/?food_name=apple+crumble&product_category=&country=&gi=&gi_filter=&serving_size_(g)=&serving_size_(g)_filter=&carbs_per_serve_(g)=&carbs_per_serve_(g)_filter=&gl=&gl_filter="
  },
  {
    "id": "1205",
    "type": "snack",
    "|| name                                               ||": "Potato wedges, fried",
    "rating": "1",
    "fried": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "5",
    "|| unit ||": "piece",
    "weight-g": "105",
    "calories": "200",
    "fat": "12",
    "cal-from-fat": "108",
    "per-cal-from-fat": "54",
    "saturated": "2.5",
    "polyunsaturated": "3.5",
    "monounsaturated": "6",
    "trans": "0.1",
    "cholesterol": "0",
    "sodium": "302",
    "carbs": "20.9",
    "fibre": "2.3",
    "sugar": "0.3",
    "protein": "2.3",
    "cal-from-protein": "9",
    "per-cal-from-protein": "5",
    "potassium": "383",
    "calcium": "18",
    "iron": "0.8",
    "phosphorus": "140",
    "source": "N Buddy",
    "old units (ref)": "Deep Fried Farmland Spicy Potato Wedges"
  },
  {
    "id": "1206",
    "type": "snack",
    "|| name                                               ||": "Achar",
    "rating": "2",
    "fruit": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "20",
    "calories": "24",
    "fat": "0.4",
    "cal-from-fat": "3.6",
    "per-cal-from-fat": "15",
    "saturated": "0.1",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.1",
    "cholesterol": "0",
    "sodium": "121",
    "carbs": "4.6",
    "fibre": "0.4",
    "sugar": "4",
    "protein": "0.4",
    "cal-from-protein": "2",
    "per-cal-from-protein": "7",
    "calcium": "7",
    "iron": "0.2",
    "source": "N Buddy",
    "old units (ref)": "Achar"
  },
  {
    "id": "1207",
    "type": "meal",
    "|| name                                               ||": "Aloo matar",
    "rating": "2",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "alternate-spellings": "Matar: Mutter",
    "weight-g": "140",
    "calories": "99",
    "fat": "4.3",
    "cal-from-fat": "38.7",
    "per-cal-from-fat": "39",
    "saturated": "1.2",
    "polyunsaturated": "1.4",
    "monounsaturated": "1.6",
    "cholesterol": "0",
    "sodium": "382",
    "carbs": "10.6",
    "fibre": "4.3",
    "sugar": "3.6",
    "protein": "4.5",
    "cal-from-protein": "18",
    "per-cal-from-protein": "18",
    "potassium": "529",
    "calcium": "50",
    "iron": "1",
    "phosphorus": "66",
    "source": "N Buddy",
    "old units (ref)": "Aloo Matar, Potato & Peas Curry"
  },
  {
    "id": "1208",
    "type": "meal",
    "|| name                                               ||": "Ayam masak merah",
    "rating": "2",
    "high-fat": "1",
    "high-fibre": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "150",
    "calories": "185",
    "fat": "11.3",
    "cal-from-fat": "101.7",
    "per-cal-from-fat": "55",
    "saturated": "2.9",
    "polyunsaturated": "2.8",
    "monounsaturated": "4.9",
    "cholesterol": "59",
    "sodium": "287",
    "carbs": "5",
    "fibre": "5.4",
    "sugar": "4.3",
    "protein": "15.8",
    "cal-from-protein": "63",
    "per-cal-from-protein": "34",
    "potassium": "377",
    "calcium": "99",
    "iron": "6.3",
    "phosphorus": "140",
    "source": "N Buddy",
    "old units (ref)": "Ayam Masak Merah"
  },
  {
    "id": "1209",
    "type": "meal",
    "|| name                                               ||": "Baby corn, boiled",
    "rating": "3",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "145",
    "calories": "42",
    "fat": "0.3",
    "cal-from-fat": "2.7",
    "per-cal-from-fat": "6",
    "saturated": "0",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.1",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "8.7",
    "fibre": "3.9",
    "sugar": "1.6",
    "protein": "3.3",
    "cal-from-protein": "13",
    "per-cal-from-protein": "31",
    "potassium": "426",
    "calcium": "28",
    "iron": "0.6",
    "phosphorus": "91",
    "source": "N Buddy",
    "old units (ref)": "Baby Corn"
  },
  {
    "id": "1210",
    "type": "meal",
    "|| name                                               ||": "Bagel, wholemeal",
    "rating": "3",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "whole",
    "alternate-spellings": "Wholemeal: Wholewheat",
    "weight-g": "81",
    "calories": "195",
    "fat": "1",
    "cal-from-fat": "9",
    "per-cal-from-fat": "5",
    "saturated": "0",
    "polyunsaturated": "0.6",
    "monounsaturated": "0.3",
    "trans": "0",
    "cholesterol": "4.9",
    "sodium": "291",
    "carbs": "38.5",
    "fibre": "5",
    "sugar": "7",
    "protein": "8",
    "cal-from-protein": "32",
    "per-cal-from-protein": "16",
    "potassium": "165",
    "calcium": "100",
    "iron": "1.8",
    "phosphorus": "152",
    "source": "USDA",
    "old units (ref)": "Bagels, multigrain\nGI: 69 (link, page 1, (\"Bagel, white bread\")\nhttps://glycemicindex.com/gi-search/?food_name=bagel&product_category=&country=&gi=&gi_filter=&serving_size_(g)=&serving_size_(g)_filter=&carbs_per_serve_(g)=&carbs_per_serve_(g)_filter=&gl=&gl_filter="
  },
  {
    "id": "1211",
    "type": "meal",
    "|| name                                               ||": "Chicken thigh, baked / grilled",
    "rating": "2",
    "high-fat": "1",
    "high-phosphate": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "whole",
    "weight-g": "95",
    "calories": "130",
    "fat": "8.2",
    "cal-from-fat": "73.8",
    "per-cal-from-fat": "57",
    "saturated": "2.3",
    "polyunsaturated": "1.8",
    "monounsaturated": "3.2",
    "cholesterol": "49",
    "sodium": "44",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "13.2",
    "cal-from-protein": "53",
    "per-cal-from-protein": "41",
    "potassium": "117",
    "calcium": "6",
    "iron": "0.7",
    "phosphorus": "92",
    "source": "N Buddy",
    "old units (ref)": "Grilled Chicken Thigh, with skin"
  },
  {
    "id": "1212",
    "type": "meal",
    "|| name                                               ||": "Banh mi",
    "rating": "2",
    "high-salt": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "whole",
    "info-translation": "Vietnamese baguette with filling",
    "weight-g": "433",
    "calories": "431",
    "fat": "5.3",
    "cal-from-fat": "47.7",
    "per-cal-from-fat": "11",
    "saturated": "1",
    "polyunsaturated": "1.7",
    "monounsaturated": "2.6",
    "cholesterol": "61",
    "sodium": "3125",
    "carbs": "66.9",
    "fibre": "4.7",
    "sugar": "23.5",
    "protein": "30",
    "cal-from-protein": "120",
    "per-cal-from-protein": "28",
    "source": "N Buddy",
    "old units (ref)": "Vietnamese Grilled Chicken Banh Mi"
  },
  {
    "id": "1213",
    "type": "meal",
    "|| name                                               ||": "Pesto sauce",
    "rating": "2",
    "high-fat": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "15",
    "calories": "45",
    "fat": "4.5",
    "cal-from-fat": "40.5",
    "per-cal-from-fat": "90",
    "saturated": "0.8",
    "polyunsaturated": "1",
    "monounsaturated": "2.7",
    "carbs": "0.5",
    "fibre": "0.5",
    "sugar": "0",
    "protein": "0.5",
    "cal-from-protein": "2",
    "per-cal-from-protein": "4",
    "source": "N Buddy",
    "old units (ref)": "Pesto Sauce"
  },
  {
    "id": "1214",
    "type": "meal",
    "|| name                                               ||": "Black fungus, boiled",
    "rating": "3",
    "high-fibre": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "100",
    "calories": "20",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "9",
    "carbs": "6",
    "fibre": "2",
    "sugar": "0",
    "protein": "4",
    "cal-from-protein": "16",
    "per-cal-from-protein": "80",
    "potassium": "500",
    "calcium": "34",
    "iron": "5.5",
    "phosphorus": "86",
    "source": "N Buddy",
    "old units (ref)": "Black Fungus, boiled"
  },
  {
    "id": "1215",
    "type": "snack",
    "|| name                                               ||": "Seaweed cracker",
    "rating": "2",
    "high-iodine": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "2",
    "|| unit ||": "piece",
    "weight-g": "14",
    "calories": "59",
    "fat": "1.4",
    "cal-from-fat": "12.6",
    "per-cal-from-fat": "21",
    "saturated": "0.8",
    "polyunsaturated": "0.3",
    "monounsaturated": "0.3",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "50",
    "carbs": "10.5",
    "fibre": "0",
    "sugar": "3",
    "protein": "1.3",
    "cal-from-protein": "5",
    "per-cal-from-protein": "9",
    "source": "N Buddy",
    "old units (ref)": "Bin Bin Rice Crackers, spicy seawood\nGI: 111 (link, page12, \"Rice cracker (osenbe))\"\nhttps://www.nature.com/articles/s41387-020-00145-w.pdf"
  },
  {
    "id": "1216",
    "type": "meal",
    "|| name                                               ||": "Chicken, braised",
    "rating": "2",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "weight-g": "100",
    "calories": "201",
    "fat": "13.8",
    "cal-from-fat": "124.2",
    "per-cal-from-fat": "62",
    "saturated": "4.2",
    "polyunsaturated": "2.5",
    "monounsaturated": "6.3",
    "cholesterol": "67",
    "sodium": "576",
    "carbs": "0.6",
    "fibre": "0",
    "sugar": "0.4",
    "protein": "18.7",
    "cal-from-protein": "75",
    "per-cal-from-protein": "37",
    "potassium": "176",
    "calcium": "21",
    "iron": "0.9",
    "phosphorus": "116",
    "source": "N Buddy",
    "old units (ref)": "Braised Chicken"
  },
  {
    "id": "1217",
    "type": "meal",
    "|| name                                               ||": "Braised pork belly",
    "rating": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "info-translation": "Hong Shao Ruo / Lor Bak",
    "alternate-spellings": "Lor: Lou",
    "weight-g": "120",
    "calories": "460",
    "fat": "41",
    "cal-from-fat": "369",
    "per-cal-from-fat": "80",
    "saturated": "13.9",
    "polyunsaturated": "5.1",
    "monounsaturated": "19.5",
    "cholesterol": "70",
    "sodium": "664",
    "carbs": "1.9",
    "fibre": "0.5",
    "sugar": "0",
    "protein": "20.8",
    "cal-from-protein": "83",
    "per-cal-from-protein": "18",
    "potassium": "82",
    "calcium": "49",
    "iron": "0.6",
    "phosphorus": "104",
    "source": "N Buddy",
    "old units (ref)": "Lor Bak, Teochew Braised Pork"
  },
  {
    "id": "1218",
    "type": "meal",
    "|| name                                               ||": "Tau pok",
    "rating": "1",
    "fried": "1",
    "high-fat": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "info-translation": "Fried beancurd puff",
    "alternate-spellings": "Tau: Dou\r",
    "weight-g": "16",
    "calories": "36",
    "fat": "2.5",
    "cal-from-fat": "22.5",
    "per-cal-from-fat": "63",
    "saturated": "0.3",
    "polyunsaturated": "0.4",
    "monounsaturated": "1.8",
    "cholesterol": "0",
    "sodium": "1",
    "carbs": "0.5",
    "sugar": "0.2",
    "protein": "3.1",
    "cal-from-protein": "12",
    "per-cal-from-protein": "34",
    "calcium": "11",
    "source": "N Buddy",
    "old units (ref)": "Taupok, fried tofu puff"
  },
  {
    "id": "1219",
    "type": "meal",
    "|| name                                               ||": "Sea cucumber, boiled",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "337",
    "calories": "91",
    "fat": "0.3",
    "cal-from-fat": "2.7",
    "per-cal-from-fat": "3",
    "saturated": "0.1",
    "polyunsaturated": "0",
    "monounsaturated": "0.1",
    "cholesterol": "0",
    "sodium": "138",
    "carbs": "0.3",
    "fibre": "0",
    "sugar": "0",
    "protein": "21.6",
    "cal-from-protein": "86",
    "per-cal-from-protein": "95",
    "potassium": "37",
    "calcium": "10",
    "iron": "1",
    "phosphorus": "10",
    "source": "N Buddy",
    "old units (ref)": "Sea Cucumber, soaked"
  },
  {
    "id": "1220",
    "type": "meal",
    "|| name                                               ||": "Bread, brioche",
    "rating": "2",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "36",
    "calories": "125",
    "fat": "4",
    "cal-from-fat": "36",
    "per-cal-from-fat": "29",
    "saturated": "1.5",
    "trans": "0",
    "cholesterol": "20",
    "sodium": "30",
    "carbs": "20",
    "fibre": "0.5",
    "sugar": "4",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "10",
    "calcium": "20",
    "iron": "0.2",
    "source": "N Buddy",
    "old units (ref)": "Brioche Slice"
  },
  {
    "id": "1221",
    "type": "meal",
    "|| name                                               ||": "Century egg",
    "rating": "1",
    "high-cholesterol": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "whole",
    "weight-g": "115",
    "calories": "177",
    "fat": "11.1",
    "cal-from-fat": "99.9",
    "per-cal-from-fat": "56",
    "saturated": "3.4",
    "polyunsaturated": "1.5",
    "monounsaturated": "6.2",
    "cholesterol": "629",
    "sodium": "566",
    "carbs": "4.7",
    "fibre": "0",
    "sugar": "4.7",
    "protein": "14.7",
    "cal-from-protein": "59",
    "per-cal-from-protein": "33",
    "potassium": "157",
    "calcium": "65",
    "iron": "3.4",
    "phosphorus": "171",
    "source": "N Buddy",
    "old units (ref)": "Century Egg, whole"
  },
  {
    "id": "1222",
    "type": "meal",
    "|| name                                               ||": "Cereal chicken",
    "rating": "1",
    "fried": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "weight-g": "100",
    "calories": "234",
    "fat": "13",
    "cal-from-fat": "117",
    "per-cal-from-fat": "50",
    "saturated": "8.2",
    "polyunsaturated": "0.5",
    "monounsaturated": "3.8",
    "trans": "0.5",
    "cholesterol": "87",
    "sodium": "353",
    "carbs": "7.1",
    "fibre": "0.5",
    "sugar": "4.3",
    "protein": "20.7",
    "cal-from-protein": "83",
    "per-cal-from-protein": "35",
    "potassium": "167",
    "calcium": "21",
    "iron": "1.1",
    "phosphorus": "117",
    "source": "N Buddy",
    "old units (ref)": "Crispy Butter Cereal Chicken"
  },
  {
    "id": "1223",
    "type": "meal",
    "|| name                                               ||": "Cheese tofu",
    "rating": "2",
    "high-fat": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "25",
    "calories": "51.8",
    "fat": "3.9",
    "cal-from-fat": "35.1",
    "per-cal-from-fat": "68",
    "saturated": "1.5",
    "cholesterol": "3.1",
    "sodium": "205.5",
    "carbs": "1.6",
    "fibre": "0.3",
    "protein": "2.7",
    "cal-from-protein": "11",
    "per-cal-from-protein": "21",
    "source": "HPB",
    "old units (ref)": "Cheese tofu, uncooked"
  },
  {
    "id": "1224",
    "type": "snack",
    "|| name                                               ||": "Chestnut, roasted",
    "rating": "3",
    "high-phosphate": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "50",
    "calories": "120",
    "fat": "0.6",
    "cal-from-fat": "5.4",
    "per-cal-from-fat": "5",
    "saturated": "0.1",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.3",
    "cholesterol": "0",
    "sodium": "2",
    "carbs": "26.2",
    "fibre": "1.1",
    "sugar": "1",
    "protein": "2.3",
    "cal-from-protein": "9",
    "per-cal-from-protein": "8",
    "potassium": "239",
    "calcium": "10",
    "iron": "0.8",
    "phosphorus": "51",
    "source": "N Buddy",
    "old units (ref)": "Roasted Chestnut, without shell\nGI: 54.3 (link, page 15, \"Baked chestnuts\")\nhttps://www.nature.com/articles/s41387-020-00145-w.pdf"
  },
  {
    "id": "1225",
    "type": "meal",
    "|| name                                               ||": "Chicken drumlets, grilled",
    "rating": "2",
    "high-fat": "1",
    "high-phosphate": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "24",
    "calories": "64",
    "fat": "4.1",
    "cal-from-fat": "36.9",
    "per-cal-from-fat": "58",
    "saturated": "1.2",
    "polyunsaturated": "0.5",
    "monounsaturated": "2.1",
    "cholesterol": "40",
    "sodium": "20",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "6.9",
    "cal-from-protein": "28",
    "per-cal-from-protein": "43",
    "potassium": "62",
    "calcium": "6",
    "iron": "0.2",
    "phosphorus": "59",
    "source": "N Buddy",
    "old units (ref)": "Barbequed Chicken, drumette, with skin"
  },
  {
    "id": "1226",
    "type": "meal",
    "|| name                                               ||": "Chicken patty, grilled",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "85",
    "calories": "154",
    "fat": "6.9",
    "cal-from-fat": "62.1",
    "per-cal-from-fat": "40",
    "saturated": "1.9",
    "polyunsaturated": "1.1",
    "monounsaturated": "3.3",
    "cholesterol": "90",
    "sodium": "47",
    "carbs": "1.4",
    "fibre": "0",
    "sugar": "0",
    "protein": "21.7",
    "cal-from-protein": "87",
    "per-cal-from-protein": "56",
    "potassium": "254",
    "calcium": "11",
    "iron": "0.7",
    "phosphorus": "199",
    "source": "N Buddy",
    "old units (ref)": "Patty, chicken, medium, grilled\nGI: 66 (link, page 1, \"McChicken™ burger (chicken patty, lettuce, mayonnaise on a burger bun)\")\nhttps://glycemicindex.com/gi-search/?food_name=patty&product_category=&country=&gi=&gi_filter=&serving_size_(g)=&serving_size_(g)_filter=&carbs_per_serve_(g)=&carbs_per_serve_(g)_filter=&gl=&gl_filter=https://glycemicindex.com/gi-search/?food_name=patty&product_category=&country=&gi=&gi_filter=&serving_size_(g)=&serving_size_(g)_filter=&carbs_per_serve_(g)=&carbs_per_serve_(g)_filter=&gl=&gl_filter="
  },
  {
    "id": "1227",
    "type": "meal",
    "|| name                                               ||": "Stir fried chives with egg",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "100",
    "calories": "128",
    "fat": "10.4",
    "cal-from-fat": "93.6",
    "per-cal-from-fat": "73",
    "saturated": "2.1",
    "polyunsaturated": "3.7",
    "monounsaturated": "3.5",
    "trans": "0",
    "cholesterol": "178",
    "sodium": "383",
    "carbs": "1.6",
    "fibre": "0.5",
    "sugar": "1.6",
    "protein": "7",
    "cal-from-protein": "28",
    "per-cal-from-protein": "22",
    "potassium": "249",
    "calcium": "27",
    "iron": "1.4",
    "phosphorus": "100",
    "source": "N Buddy",
    "old units (ref)": "Stir Fried Chives with Egg"
  },
  {
    "id": "1228",
    "disabled": "1",
    "type": "drink",
    "|| name                                               ||": "Protein powder, vegan",
    "rating": "2",
    "plant-protein": "1",
    "processed": "1",
    "serving": "2",
    "|| unit ||": "tbsp",
    "info-brands": "Nutrilite",
    "weight-g": "12.5",
    "calories": "50",
    "fat": "0.5",
    "cal-from-fat": "4.5",
    "per-cal-from-fat": "9",
    "cholesterol": "0",
    "sodium": "125",
    "protein": "10",
    "cal-from-protein": "40",
    "per-cal-from-protein": "80",
    "potassium": "0",
    "calcium": "25",
    "iron": "1",
    "old units (ref)": "Nutrilite™ All Plant Protein Powder\nhttps://www.amway.com/en_US/Nutrilite%E2%84%A2-All-Plant-Protein-Powder-p-110415\n\nGI: 36 (link , page 1, \"Hi-Pro energy drink mix, vanilla, containing soy protein and whey powder mixed in reduced-fat (1.5%) milk\")\nhttps://glycemicindex.com/gi-search/?food_name=protein+powder&product_category=&country=&gi=&gi_filter=&serving_size_(g)=&serving_size_(g)_filter=&carbs_per_serve_(g)=&carbs_per_serve_(g)_filter=&gl=&gl_filter="
  },
  {
    "id": "1229",
    "type": "snack",
    "|| name                                               ||": "Coffee bun",
    "rating": "2",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "90",
    "calories": "356",
    "fat": "18.2",
    "cal-from-fat": "163.8",
    "per-cal-from-fat": "46",
    "saturated": "10.8",
    "polyunsaturated": "1.5",
    "monounsaturated": "5.5",
    "trans": "0.3",
    "cholesterol": "33",
    "sodium": "426",
    "carbs": "39.9",
    "fibre": "0.9",
    "sugar": "15.7",
    "protein": "8.1",
    "cal-from-protein": "32",
    "per-cal-from-protein": "9",
    "potassium": "131",
    "calcium": "49",
    "iron": "0.7",
    "phosphorus": "86",
    "source": "N Buddy",
    "old units (ref)": "Coffee Bun"
  },
  {
    "id": "1230",
    "type": "snack",
    "|| name                                               ||": "Corn thins",
    "rating": "2",
    "high-gi": "1",
    "processed": "1",
    "serving": "2",
    "|| unit ||": "slice",
    "weight-g": "12",
    "calories": "47",
    "fat": "0.4",
    "cal-from-fat": "3.6",
    "per-cal-from-fat": "8",
    "saturated": "0.1",
    "trans": "0",
    "sodium": "31",
    "carbs": "8.7",
    "fibre": "1.2",
    "sugar": "0.1",
    "protein": "1.3",
    "cal-from-protein": "5",
    "per-cal-from-protein": "11",
    "source": "N Buddy",
    "old units (ref)": "Real Foods Corn Thins Multigrain\nGI: 87 (link, page 1, \"Corn Thins, puffed corn cakes, gluten-free crackers\")\nhttps://glycemicindex.com/gi-search/?food_name=corn+thins&product_category=&country=&gi=&gi_filter=&serving_size_(g)=&serving_size_(g)_filter=&carbs_per_serve_(g)=&carbs_per_serve_(g)_filter=&gl=&gl_filter="
  },
  {
    "id": "1231",
    "type": "snack",
    "|| name                                               ||": "Cottage cheese",
    "rating": "2",
    "high-fat": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "20",
    "calories": "29",
    "fat": "1.9",
    "cal-from-fat": "17.1",
    "per-cal-from-fat": "59",
    "saturated": "1.3",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.5",
    "cholesterol": "7",
    "sodium": "40",
    "carbs": "0.1",
    "fibre": "0",
    "sugar": "0.1",
    "protein": "3.1",
    "cal-from-protein": "12",
    "per-cal-from-protein": "43",
    "potassium": "19",
    "calcium": "13",
    "iron": "0",
    "phosphorus": "39",
    "source": "N Buddy",
    "old units (ref)": "Cottage Cheese"
  },
  {
    "id": "1232",
    "type": "meal",
    "|| name                                               ||": "Dou miao, stir fried",
    "rating": "3",
    "high-salt": "1",
    "vegetable": "1",
    "serving": "2",
    "|| unit ||": "handful",
    "info-translation": "Pea shoots",
    "alternate-spellings": "Dou: Tow\nMiao: Meow",
    "weight-g": "100",
    "calories": "129",
    "fat": "12",
    "cal-from-fat": "108",
    "per-cal-from-fat": "84",
    "saturated": "5.1",
    "polyunsaturated": "1.4",
    "monounsaturated": "4.9",
    "cholesterol": "0",
    "sodium": "352",
    "carbs": "1.4",
    "fibre": "1.1",
    "sugar": "0",
    "protein": "3.8",
    "cal-from-protein": "15",
    "per-cal-from-protein": "12",
    "potassium": "124",
    "calcium": "17",
    "iron": "0.5",
    "phosphorus": "43",
    "source": "N Buddy",
    "old units (ref)": "Stir Fried Pea Shoots, Tow Meow"
  },
  {
    "id": "1233",
    "type": "meal",
    "|| name                                               ||": "Duck noodle, soup",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "539",
    "calories": "507",
    "fat": "18.9",
    "cal-from-fat": "170.1",
    "per-cal-from-fat": "34",
    "saturated": "6.5",
    "polyunsaturated": "3.2",
    "monounsaturated": "8.1",
    "cholesterol": "108",
    "sodium": "1926",
    "carbs": "62",
    "fibre": "3.2",
    "sugar": "1.8",
    "protein": "22.1",
    "cal-from-protein": "88",
    "per-cal-from-protein": "17",
    "potassium": "302",
    "calcium": "70",
    "iron": "1.4",
    "phosphorus": "199",
    "source": "N Buddy",
    "old units (ref)": "Duck Noodles, soup"
  },
  {
    "id": "1234",
    "type": "meal",
    "|| name                                               ||": "Egg mayonnaise",
    "rating": "1",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "2",
    "|| unit ||": "tbsp",
    "weight-g": "65",
    "calories": "110",
    "fat": "8.5",
    "cal-from-fat": "76.5",
    "per-cal-from-fat": "70",
    "saturated": "2",
    "polyunsaturated": "2.6",
    "monounsaturated": "3.1",
    "cholesterol": "211",
    "sodium": "141",
    "carbs": "2.2",
    "fibre": "0",
    "sugar": "1.6",
    "protein": "6.5",
    "cal-from-protein": "26",
    "per-cal-from-protein": "24",
    "potassium": "53",
    "calcium": "22",
    "iron": "0.9",
    "phosphorus": "72",
    "source": "N Buddy",
    "old units (ref)": "Egg Mayonnaise"
  },
  {
    "id": "1235",
    "type": "meal",
    "|| name                                               ||": "Fajita, with meat and vegetables",
    "rating": "2",
    "high-fat": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "142",
    "calories": "234",
    "fat": "10.1",
    "cal-from-fat": "90.9",
    "per-cal-from-fat": "39",
    "saturated": "3.6",
    "polyunsaturated": "1.9",
    "monounsaturated": "3.1",
    "cholesterol": "38.3",
    "sodium": "498",
    "carbs": "20.9",
    "fibre": "2.1",
    "sugar": "3.9",
    "protein": "14.7",
    "cal-from-protein": "59",
    "per-cal-from-protein": "25",
    "potassium": "317",
    "calcium": "80.9",
    "iron": "2.1",
    "phosphorus": "182",
    "source": "USDA",
    "old units (ref)": "Fajita with meat and vegetables\nGI: 42 (link, page 1, \"Fajitas, chicken \")\nhttps://glycemicindex.com/gi-search/?food_name=faji&product_category=&country=&gi=&gi_filter=&serving_size_(g)=&serving_size_(g)_filter=&carbs_per_serve_(g)=&carbs_per_serve_(g)_filter=&gl=&gl_filter="
  },
  {
    "id": "1236",
    "type": "snack",
    "|| name                                               ||": "Figs, preserved",
    "rating": "2",
    "fruit": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "info-include-exclude": "with sugar",
    "weight-g": "20",
    "calories": "47",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "trans": "0",
    "sodium": "1.8",
    "carbs": "13",
    "sugar": "12",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "source": "USDA",
    "old units (ref)": "FIG PRESERVE\nGI: 61 (link, page 1, \"Figs, dried, tenderised, Dessert Maid brand\")\nhttps://glycemicindex.com/gi-search/?food_name=fig&product_category=&country=&gi=&gi_filter=&serving_size_(g)=&serving_size_(g)_filter=&carbs_per_serve_(g)=&carbs_per_serve_(g)_filter=&gl=&gl_filter="
  },
  {
    "id": "1237",
    "type": "meal",
    "|| name                                               ||": "Stew, fish",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "227",
    "calories": "225",
    "fat": "8.5",
    "cal-from-fat": "76.5",
    "per-cal-from-fat": "34",
    "saturated": "2",
    "polyunsaturated": "1.3",
    "monounsaturated": "3.9",
    "cholesterol": "93.1",
    "sodium": "506",
    "carbs": "4.9",
    "fibre": "0.7",
    "sugar": "1.4",
    "protein": "30.5",
    "cal-from-protein": "122",
    "per-cal-from-protein": "54",
    "potassium": "638",
    "calcium": "74.9",
    "iron": "1.6",
    "phosphorus": "384",
    "source": "USDA",
    "old units (ref)": "Bouillabaisse"
  },
  {
    "id": "1238",
    "type": "meal",
    "|| name                                               ||": "Focaccia",
    "rating": "2",
    "high-phosphate": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "53",
    "calories": "163",
    "fat": "4.2",
    "cal-from-fat": "37.8",
    "per-cal-from-fat": "23",
    "saturated": "0.6",
    "polyunsaturated": "0.5",
    "monounsaturated": "2.9",
    "cholesterol": "0",
    "sodium": "340",
    "carbs": "25.6",
    "fibre": "1.4",
    "sugar": "1",
    "protein": "5.6",
    "cal-from-protein": "22",
    "per-cal-from-protein": "14",
    "potassium": "68",
    "calcium": "34",
    "iron": "0.8",
    "phosphorus": "60",
    "source": "N Buddy",
    "old units (ref)": "Focaccia Bread"
  },
  {
    "id": "1239",
    "type": "meal",
    "|| name                                               ||": "Cucumber, stir fried",
    "rating": "3",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "185",
    "calories": "81.4",
    "fat": "5.8",
    "cal-from-fat": "52.2",
    "per-cal-from-fat": "64",
    "saturated": "1.7",
    "polyunsaturated": "1.8",
    "monounsaturated": "2",
    "cholesterol": "3.7",
    "sodium": "270",
    "carbs": "7.7",
    "fibre": "1.1",
    "sugar": "3.5",
    "protein": "1.4",
    "cal-from-protein": "6",
    "per-cal-from-protein": "7",
    "potassium": "311",
    "calcium": "35.2",
    "iron": "0.6",
    "phosphorus": "51.8",
    "source": "USDA",
    "old units (ref)": "Cucumber, cooked"
  },
  {
    "id": "1240",
    "type": "meal",
    "|| name                                               ||": "Brown rice, fried",
    "rating": "2",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-include-exclude": "with vegetables",
    "weight-g": "315",
    "calories": "564",
    "fat": "16.6",
    "cal-from-fat": "149.4",
    "per-cal-from-fat": "26",
    "saturated": "6.7",
    "polyunsaturated": "2.6",
    "monounsaturated": "6.6",
    "trans": "0",
    "cholesterol": "7",
    "sodium": "1270",
    "carbs": "89",
    "fibre": "3.7",
    "sugar": "1.3",
    "protein": "14.6",
    "cal-from-protein": "58",
    "per-cal-from-protein": "10",
    "potassium": "303",
    "calcium": "96",
    "iron": "0.2",
    "phosphorus": "663",
    "source": "N Buddy",
    "old units (ref)": "Vegetarian Fried Brown Rice\nGI: 87 (link, page 1, \"Brown rice\")\nhttps://glycemicindex.com/gi-search/?food_name=brown+rice&product_category=&country=&gi=&gi_filter=&serving_size_(g)=&serving_size_(g)_filter=&carbs_per_serve_(g)=&carbs_per_serve_(g)_filter=&gl=&gl_filter="
  },
  {
    "id": "1241",
    "type": "meal",
    "|| name                                               ||": "Udon, stir fried",
    "rating": "2",
    "high-fat": "1",
    "high-fibre": "1",
    "high-iron": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "info-include-exclude": "with meat",
    "weight-g": "445",
    "calories": "516",
    "fat": "23.9",
    "cal-from-fat": "215.1",
    "per-cal-from-fat": "42",
    "saturated": "9",
    "polyunsaturated": "5.1",
    "monounsaturated": "9.7",
    "trans": "0.1",
    "sodium": "1927",
    "carbs": "52.1",
    "fibre": "5.8",
    "sugar": "7.1",
    "protein": "23.6",
    "cal-from-protein": "94",
    "per-cal-from-protein": "18",
    "calcium": "91",
    "iron": "3.8",
    "source": "N Buddy",
    "old units (ref)": "Yaki Udon with Chicken, stir fried noodles\nGI: 62 (link, page 1, \"Udon noodles, fresh, reheated (Fantastic brand, Windsor Gardens, SA, Australia)\")\nhttps://glycemicindex.com/gi-search/?food_name=udon&product_category=&country=&gi=&gi_filter=&serving_size_(g)=&serving_size_(g)_filter=&carbs_per_serve_(g)=&carbs_per_serve_(g)_filter=&gl=&gl_filter="
  },
  {
    "id": "1242",
    "type": "meal",
    "|| name                                               ||": "Bread, with nut",
    "rating": "2",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "info-examples": "walnut",
    "weight-g": "22",
    "calories": "50",
    "fat": "1.1",
    "cal-from-fat": "9.9",
    "per-cal-from-fat": "20",
    "saturated": "0.2",
    "trans": "0",
    "cholesterol": "0.5",
    "sodium": "100",
    "carbs": "8.4",
    "fibre": "0.8",
    "sugar": "0.3",
    "protein": "2",
    "cal-from-protein": "8",
    "per-cal-from-protein": "16",
    "source": "N Buddy",
    "old units (ref)": "Gardenia Country Load - Walnut Country Loaf"
  },
  {
    "id": "1243",
    "type": "drink",
    "|| name                                               ||": "Wheatgrass juice",
    "rating": "3",
    "high-phosphate": "1",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "info-include-exclude": "without sugar",
    "weight-g": "200",
    "calories": "4",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "1",
    "carbs": "1.1",
    "fibre": "0.2",
    "sugar": "1.1",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "706",
    "calcium": "15",
    "iron": "1.5",
    "phosphorus": "204",
    "source": "N Buddy",
    "old units (ref)": "Wheatgrass Juice, no sugar"
  },
  {
    "id": "1244",
    "type": "meal",
    "|| name                                               ||": "Vegetarian noodles, soup",
    "rating": "2",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "601",
    "calories": "281",
    "fat": "3",
    "cal-from-fat": "27",
    "per-cal-from-fat": "10",
    "saturated": "1",
    "polyunsaturated": "1",
    "monounsaturated": "1",
    "trans": "0",
    "cholesterol": "5",
    "sodium": "1363",
    "carbs": "57",
    "fibre": "5",
    "sugar": "1",
    "protein": "9",
    "cal-from-protein": "36",
    "per-cal-from-protein": "13",
    "potassium": "305",
    "calcium": "23",
    "iron": "1",
    "phosphorus": "145",
    "source": "N Buddy",
    "old units (ref)": "Vegetarian Mushroom Noodles, soup"
  },
  {
    "id": "1245",
    "type": "meal",
    "|| name                                               ||": "Vegetarian dumpling, soup",
    "rating": "2",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "209",
    "calories": "196",
    "fat": "4",
    "cal-from-fat": "36",
    "per-cal-from-fat": "18",
    "saturated": "0",
    "cholesterol": "0",
    "sodium": "690",
    "carbs": "41",
    "fibre": "4",
    "sugar": "4",
    "protein": "8",
    "cal-from-protein": "32",
    "per-cal-from-protein": "16",
    "potassium": "514",
    "calcium": "81.5",
    "iron": "1",
    "source": "USDA",
    "old units (ref)": "VEGETARIAN NEPALESE BRAND DUMPLING, VEGETARIAN"
  },
  {
    "id": "1246",
    "type": "meal",
    "|| name                                               ||": "Pasta, stir fried with vegetables",
    "rating": "2",
    "high-salt": "1",
    "refined-grain": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "235",
    "calories": "424",
    "fat": "11.9",
    "cal-from-fat": "107.1",
    "per-cal-from-fat": "25",
    "saturated": "1.6",
    "polyunsaturated": "5.1",
    "monounsaturated": "3",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "400",
    "carbs": "67.3",
    "fibre": "3.8",
    "sugar": "3.8",
    "protein": "12.1",
    "cal-from-protein": "48",
    "per-cal-from-protein": "11",
    "potassium": "117",
    "calcium": "36",
    "iron": "0.3",
    "phosphorus": "26",
    "source": "N Buddy",
    "old units (ref)": "Stir Fried Spaghetti with Vegetables"
  },
  {
    "id": "1247",
    "type": "meal",
    "|| name                                               ||": "Vada",
    "rating": "1",
    "fried": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "alternate-spellings": "Vada: Bada, Wada, Vade, Vadai",
    "weight-g": "60",
    "calories": "181",
    "fat": "10.6",
    "cal-from-fat": "95.4",
    "per-cal-from-fat": "53",
    "saturated": "4.3",
    "polyunsaturated": "1.8",
    "monounsaturated": "4.1",
    "cholesterol": "0",
    "sodium": "317",
    "carbs": "13.9",
    "fibre": "4.4",
    "sugar": "0.7",
    "protein": "7.4",
    "cal-from-protein": "30",
    "per-cal-from-protein": "16",
    "potassium": "31",
    "calcium": "28",
    "iron": "1.4",
    "phosphorus": "105",
    "source": "N Buddy",
    "old units (ref)": "Vadai\nGI: 21.5 (link, page 23, \"Uzhunnu vada\") \nhttps://www.nature.com/articles/s41387-020-00145-w.pdf"
  },
  {
    "id": "1248",
    "type": "meal",
    "|| name                                               ||": "Unagi",
    "rating": "2",
    "high-calcium": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "info-translation": "Japanese marinated eel",
    "weight-g": "100",
    "calories": "296",
    "fat": "22.3",
    "cal-from-fat": "200.7",
    "per-cal-from-fat": "68",
    "saturated": "6.5",
    "polyunsaturated": "0.5",
    "monounsaturated": "12",
    "cholesterol": "22",
    "sodium": "358",
    "carbs": "5.5",
    "fibre": "0.1",
    "sugar": "4.7",
    "protein": "18.4",
    "cal-from-protein": "74",
    "per-cal-from-protein": "25",
    "potassium": "925",
    "calcium": "164",
    "iron": "0.5",
    "phosphorus": "1120",
    "source": "N Buddy",
    "old units (ref)": "Unagi Fish, eel"
  },
  {
    "id": "1249",
    "type": "meal",
    "|| name                                               ||": "Wholemeal wrap, without fillings",
    "rating": "3",
    "high-salt": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "45",
    "calories": "134",
    "fat": "3",
    "cal-from-fat": "27",
    "per-cal-from-fat": "20",
    "saturated": "1.6",
    "polyunsaturated": "0.2",
    "monounsaturated": "1.2",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "280",
    "carbs": "23.2",
    "fibre": "1.5",
    "sugar": "1.7",
    "protein": "3.6",
    "cal-from-protein": "14",
    "per-cal-from-protein": "11",
    "calcium": "45",
    "iron": "0.4",
    "source": "N Buddy",
    "old units (ref)": "FairPrice Wraps, wholemeal\nGI: 27 (link, page 1, \"Herman Brot Protein Wraps\")\nhttps://glycemicindex.com/gi-search/?food_name=wraps&product_category=&country=&gi=&gi_filter=&serving_size_(g)=&serving_size_(g)_filter=&carbs_per_serve_(g)=&carbs_per_serve_(g)_filter=&gl=&gl_filter="
  },
  {
    "id": "1250",
    "type": "meal",
    "|| name                                               ||": "Tomato, boiled",
    "rating": "3",
    "high-phosphate": "1",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "240",
    "calories": "43",
    "fat": "0.3",
    "cal-from-fat": "2.7",
    "per-cal-from-fat": "6",
    "saturated": "0",
    "polyunsaturated": "0.1",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "26",
    "carbs": "9.6",
    "fibre": "1.7",
    "sugar": "6",
    "protein": "2.3",
    "cal-from-protein": "9",
    "per-cal-from-protein": "21",
    "potassium": "523",
    "calcium": "26",
    "iron": "1.6",
    "phosphorus": "67",
    "source": "N Buddy",
    "old units (ref)": "Tomatoes, cooked"
  },
  {
    "id": "1251",
    "type": "meal",
    "|| name                                               ||": "Salad with seafood",
    "rating": "2",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "info-include-exclude": "without dressing",
    "weight-g": "78",
    "calories": "52",
    "fat": "1.3",
    "cal-from-fat": "11.7",
    "per-cal-from-fat": "23",
    "saturated": "0.3",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.6",
    "trans": "0",
    "cholesterol": "46",
    "sodium": "196",
    "carbs": "2.9",
    "fibre": "1",
    "sugar": "1.2",
    "protein": "7",
    "cal-from-protein": "28",
    "per-cal-from-protein": "54",
    "potassium": "153",
    "calcium": "36",
    "iron": "0.5",
    "phosphorus": "68",
    "source": "N Buddy",
    "old units (ref)": "Salad, seafood"
  },
  {
    "id": "1252",
    "type": "snack",
    "|| name                                               ||": "Tau sar piah",
    "rating": "1",
    "high-fat": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "info-translation": "Red bean pastry",
    "info-include-exclude": "sweet",
    "alternate-spellings": "Tau: Dou\nSar: Sha\nPiah: Bing",
    "weight-g": "47",
    "calories": "215",
    "fat": "12.1",
    "cal-from-fat": "108.9",
    "per-cal-from-fat": "51",
    "saturated": "5",
    "polyunsaturated": "1.7",
    "monounsaturated": "4.9",
    "cholesterol": "10",
    "sodium": "9",
    "carbs": "23.4",
    "fibre": "0.6",
    "sugar": "9.7",
    "protein": "3.2",
    "cal-from-protein": "13",
    "per-cal-from-protein": "6",
    "potassium": "79",
    "calcium": "7",
    "iron": "0.5",
    "phosphorus": "39",
    "source": "N Buddy",
    "old units (ref)": "Pastry, red bean, sweet"
  },
  {
    "id": "1253",
    "type": "meal",
    "|| name                                               ||": "Taiyaki, red bean",
    "rating": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "info-translation": "Japanese fish shaped cake",
    "weight-g": "76",
    "calories": "204",
    "fat": "5.2",
    "cal-from-fat": "46.8",
    "per-cal-from-fat": "23",
    "saturated": "2.1",
    "cholesterol": "8.4",
    "sodium": "212.8",
    "carbs": "34.9",
    "fibre": "0.3",
    "protein": "4.3",
    "cal-from-protein": "17",
    "per-cal-from-protein": "8",
    "source": "HPB",
    "old units (ref)": "Red bean taiyaki"
  },
  {
    "id": "1254",
    "type": "meal",
    "|| name                                               ||": "Sweetener, non-caloric",
    "rating": "2",
    "serving": "1",
    "|| unit ||": "sachet",
    "info-brands": "Equal, Splenda, Stevia",
    "weight-g": "2",
    "calories": "0",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "0",
    "calcium": "0",
    "iron": "0",
    "phosphorus": "0",
    "source": "N Buddy",
    "old units (ref)": "Equal stevia sachet (sweetener)"
  },
  {
    "id": "1255",
    "type": "meal",
    "|| name                                               ||": "Prawn / shrimp, stir fried",
    "rating": "2",
    "high-iodine": "1",
    "high-phosphate": "1",
    "white-meat": "1",
    "serving": "6",
    "|| unit ||": "pieces",
    "weight-g": "30",
    "calories": "35",
    "fat": "1.4",
    "cal-from-fat": "12.6",
    "per-cal-from-fat": "36",
    "saturated": "0.2",
    "polyunsaturated": "0.5",
    "monounsaturated": "0.5",
    "cholesterol": "45",
    "sodium": "134",
    "carbs": "0.4",
    "fibre": "0",
    "sugar": "0",
    "protein": "4.9",
    "cal-from-protein": "20",
    "per-cal-from-protein": "56",
    "potassium": "41",
    "calcium": "20",
    "iron": "0.1",
    "phosphorus": "87",
    "source": "N Buddy",
    "old units (ref)": "Sauteed Prawns, medium-sized"
  },
  {
    "id": "1256",
    "type": "meal",
    "|| name                                               ||": "Stir fried chicken with mushrooms",
    "rating": "2",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "121",
    "calories": "165",
    "fat": "7.1",
    "cal-from-fat": "63.9",
    "per-cal-from-fat": "39",
    "saturated": "2",
    "polyunsaturated": "2",
    "monounsaturated": "2.8",
    "cholesterol": "25",
    "sodium": "587",
    "carbs": "3.2",
    "fibre": "1.8",
    "sugar": "1.5",
    "protein": "21.9",
    "cal-from-protein": "88",
    "per-cal-from-protein": "53",
    "potassium": "323",
    "calcium": "11",
    "iron": "0.6",
    "phosphorus": "200",
    "source": "N Buddy",
    "old units (ref)": "Stir Fried Chicken with Button Mushroom"
  },
  {
    "id": "1257",
    "type": "meal",
    "|| name                                               ||": "Bean curd, steamed / boiled",
    "rating": "3",
    "high-calcium": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "block",
    "info-translation": "Tofu",
    "weight-g": "147",
    "calories": "150",
    "fat": "6.9",
    "cal-from-fat": "62.1",
    "per-cal-from-fat": "41",
    "saturated": "1.2",
    "polyunsaturated": "3.4",
    "monounsaturated": "1.5",
    "cholesterol": "0",
    "sodium": "3",
    "carbs": "5.9",
    "fibre": "0.6",
    "sugar": "2.9",
    "protein": "16",
    "cal-from-protein": "64",
    "per-cal-from-protein": "43",
    "potassium": "184",
    "calcium": "241",
    "iron": "3.8",
    "phosphorus": "209",
    "source": "N Buddy",
    "old units (ref)": "Boiled Beancurd"
  },
  {
    "id": "1258",
    "type": "meal",
    "|| name                                               ||": "Chicken, steamed",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "weight-g": "100",
    "calories": "193",
    "fat": "12.3",
    "cal-from-fat": "110.7",
    "per-cal-from-fat": "57",
    "saturated": "4.7",
    "polyunsaturated": "1.8",
    "monounsaturated": "5",
    "cholesterol": "89",
    "sodium": "169",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "20.6",
    "cal-from-protein": "82",
    "per-cal-from-protein": "43",
    "potassium": "200",
    "calcium": "27",
    "iron": "1.2",
    "phosphorus": "150",
    "source": "N Buddy",
    "old units (ref)": "Steamed Chicken"
  },
  {
    "id": "1259",
    "type": "meal",
    "|| name                                               ||": "Pumpkin, steamed",
    "rating": "3",
    "high-phosphate": "1",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "200",
    "calories": "40",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "5",
    "saturated": "0.1",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "2",
    "carbs": "9.8",
    "fibre": "2.2",
    "sugar": "0",
    "protein": "1.4",
    "cal-from-protein": "6",
    "per-cal-from-protein": "14",
    "potassium": "460",
    "calcium": "30",
    "iron": "1.2",
    "phosphorus": "60",
    "source": "N Buddy",
    "old units (ref)": "Boiled Pumpkin\nGI: 52.1 (link, page 15, \"Steamed sweet pumpkin\")\nhttps://www.nature.com/articles/s41387-020-00145-w.pdf="
  },
  {
    "id": "1260",
    "type": "snack",
    "|| name                                               ||": "Fruit jelly",
    "rating": "2",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "42",
    "calories": "28",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "3",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "37",
    "carbs": "6.2",
    "fibre": "0.3",
    "sugar": "5.3",
    "protein": "0.3",
    "cal-from-protein": "1",
    "per-cal-from-protein": "4",
    "potassium": "210",
    "calcium": "8",
    "iron": "0",
    "phosphorus": "2",
    "source": "N Buddy",
    "old units (ref)": "Konnyaku Jelly, with fruit\nGI: 53 (link, page 1, \"Jelly, made from commercial jelly crystals, Raspberry Razzle flavor (Aeroplane Jelly Company, West Ryde, Australia)\")\nhttps://glycemicindex.com/gi-search/?food_name=jelly&product_category=&country=&gi=&gi_filter=&serving_size_(g)=&serving_size_(g)_filter=&carbs_per_serve_(g)=&carbs_per_serve_(g)_filter=&gl=&gl_filter="
  },
  {
    "id": "1261",
    "type": "drink",
    "|| name                                               ||": "Vitamin water",
    "rating": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bottle",
    "weight-g": "500",
    "calories": "90",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "22",
    "fibre": "0",
    "sugar": "21.5",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "source": "N Buddy",
    "old units (ref)": "Glaceau Vitamin Water (Multi-V) Lemonade"
  },
  {
    "id": "1262",
    "type": "drink",
    "|| name                                               ||": "Honey lemon drink",
    "rating": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "glass",
    "weight-g": "250",
    "calories": "95",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "28",
    "carbs": "23.8",
    "fibre": "0",
    "sugar": "22.5",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "source": "N Buddy",
    "old units (ref)": "Pokka Honey Lemon"
  },
  {
    "id": "1263",
    "type": "meal",
    "|| name                                               ||": "Instant soup, sachet",
    "rating": "1",
    "high-calcium": "1",
    "high-fat": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "sachet",
    "info-examples": "mushroom",
    "info-include-exclude": "with cream",
    "weight-g": "21",
    "calories": "89",
    "fat": "3.8",
    "cal-from-fat": "34.2",
    "per-cal-from-fat": "38",
    "saturated": "0.8",
    "polyunsaturated": "2",
    "monounsaturated": "1",
    "sodium": "375",
    "carbs": "13",
    "sugar": "3.5",
    "protein": "0.9",
    "cal-from-protein": "4",
    "per-cal-from-protein": "4",
    "calcium": "170",
    "iron": "2",
    "source": "N Buddy",
    "old units (ref)": "Campbell's Instant Soup Cream of Mushroom, sachet\nGI: 54 (link, page 1, \"Minestrone & Pasta Instant soup, low-fat, President's Choice® Blue Menu™ (Loblaw Brands Limited, Canada)\")\nhttps://glycemicindex.com/gi-search/&?food_name=instant+soup"
  },
  {
    "id": "1264",
    "type": "snack",
    "|| name                                               ||": "Lemon meringue tart",
    "rating": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "tart",
    "weight-g": "117",
    "calories": "314",
    "fat": "10.2",
    "cal-from-fat": "91.8",
    "per-cal-from-fat": "29",
    "saturated": "2.1",
    "polyunsaturated": "4.3",
    "monounsaturated": "3.1",
    "cholesterol": "52.6",
    "sodium": "201",
    "carbs": "55.2",
    "fibre": "1.4",
    "sugar": "27.9",
    "protein": "1.8",
    "cal-from-protein": "7",
    "per-cal-from-protein": "2",
    "potassium": "104",
    "calcium": "65.5",
    "iron": "0.7",
    "phosphorus": "123",
    "source": "USDA",
    "old units (ref)": "Pie, lemon meringue, individual size or tart"
  },
  {
    "id": "1265",
    "type": "meal",
    "|| name                                               ||": "Lobster roll",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "roll",
    "weight-g": "238",
    "calories": "560",
    "fat": "37.9",
    "cal-from-fat": "341.1",
    "per-cal-from-fat": "61",
    "saturated": "4.9",
    "polyunsaturated": "15.1",
    "monounsaturated": "17.9",
    "cholesterol": "222",
    "sodium": "1063",
    "carbs": "25.5",
    "fibre": "0.8",
    "sugar": "4.1",
    "protein": "30",
    "cal-from-protein": "120",
    "per-cal-from-protein": "21",
    "source": "N Buddy",
    "old units (ref)": "Lobster Roll"
  },
  {
    "id": "1266",
    "type": "meal",
    "|| name                                               ||": "Fish pao fan",
    "rating": "2",
    "high-calcium": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-translation": "Soup rice",
    "weight-g": "613",
    "calories": "597",
    "fat": "18.6",
    "cal-from-fat": "167.4",
    "per-cal-from-fat": "28",
    "saturated": "6.6",
    "polyunsaturated": "4.1",
    "monounsaturated": "6.4",
    "trans": "0",
    "cholesterol": "62",
    "sodium": "1478",
    "carbs": "70.4",
    "fibre": "3.7",
    "sugar": "0.8",
    "protein": "37",
    "cal-from-protein": "148",
    "per-cal-from-protein": "25",
    "potassium": "606",
    "calcium": "178",
    "iron": "2.1",
    "phosphorus": "392",
    "source": "N Buddy",
    "old units (ref)": "Fish Pao Fan"
  },
  {
    "id": "1267",
    "type": "snack",
    "|| name                                               ||": "Green bean soup, sweetened",
    "rating": "1",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-examples": "mung bean",
    "info-include-exclude": "without coconut milk",
    "weight-g": "240",
    "calories": "132",
    "fat": "1",
    "cal-from-fat": "9",
    "per-cal-from-fat": "7",
    "saturated": "0",
    "polyunsaturated": "0.5",
    "monounsaturated": "0.5",
    "cholesterol": "0",
    "sodium": "2",
    "carbs": "22.6",
    "fibre": "6",
    "sugar": "8.2",
    "protein": "8.2",
    "cal-from-protein": "33",
    "per-cal-from-protein": "25",
    "potassium": "382",
    "calcium": "43",
    "iron": "1.2",
    "phosphorus": "115",
    "source": "N Buddy",
    "old units (ref)": "Green Bean Soup, without coconut milk\nGI: 54 (link, page 19, \"Green bean dessert\")\nhttps://www.nature.com/articles/s41387-020-00145-w.pdf"
  },
  {
    "id": "1268",
    "type": "snack",
    "|| name                                               ||": "Green bean soup, unsweetened",
    "rating": "3",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-examples": "mung bean",
    "info-include-exclude": "without coconut milk",
    "weight-g": "240",
    "calories": "107",
    "fat": "1",
    "cal-from-fat": "9",
    "per-cal-from-fat": "8",
    "saturated": "0",
    "polyunsaturated": "0.5",
    "monounsaturated": "0.5",
    "cholesterol": "0",
    "sodium": "2",
    "carbs": "16.4",
    "fibre": "6",
    "sugar": "2",
    "protein": "8.2",
    "cal-from-protein": "33",
    "per-cal-from-protein": "31",
    "potassium": "382",
    "calcium": "43",
    "iron": "1.2",
    "phosphorus": "115",
    "source": "N Buddy",
    "old units (ref)": "Green Bean Soup, without sugar"
  },
  {
    "id": "1269",
    "type": "meal",
    "|| name                                               ||": "Oden",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-translation": "Japanese stew",
    "info-include-exclude": "with fish cakes, daikon, potatoes, eggs and konbu",
    "weight-g": "300",
    "calories": "373",
    "fat": "16.7",
    "cal-from-fat": "150.3",
    "per-cal-from-fat": "40",
    "saturated": "2.6",
    "polyunsaturated": "5.8",
    "monounsaturated": "8.3",
    "trans": "0",
    "cholesterol": "238",
    "sodium": "975",
    "carbs": "32.2",
    "fibre": "1.8",
    "sugar": "1.7",
    "protein": "21.9",
    "cal-from-protein": "88",
    "per-cal-from-protein": "23",
    "potassium": "515",
    "calcium": "60",
    "iron": "2.2",
    "phosphorus": "80",
    "source": "N Buddy",
    "old units (ref)": "Japanese Oden"
  },
  {
    "id": "1270",
    "type": "meal",
    "|| name                                               ||": "Soup with old cucumber",
    "rating": "2",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "450",
    "calories": "41",
    "fat": "2.3",
    "cal-from-fat": "20.7",
    "per-cal-from-fat": "50",
    "saturated": "0.9",
    "polyunsaturated": "0.2",
    "monounsaturated": "1.1",
    "sodium": "545",
    "carbs": "2.3",
    "sugar": "1.8",
    "protein": "3.6",
    "cal-from-protein": "14",
    "per-cal-from-protein": "35",
    "source": "N Buddy",
    "old units (ref)": "Old Cucumber Soup"
  },
  {
    "id": "1271",
    "type": "snack",
    "|| name                                               ||": "Otah bun",
    "rating": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "alternate-spellings": "Otah: Otak, Otar",
    "weight-g": "67",
    "calories": "190",
    "fat": "5.5",
    "cal-from-fat": "49.5",
    "per-cal-from-fat": "26",
    "saturated": "3.8",
    "polyunsaturated": "0.3",
    "monounsaturated": "1.1",
    "cholesterol": "27",
    "sodium": "264",
    "carbs": "28.1",
    "fibre": "1.3",
    "sugar": "0.5",
    "protein": "7.1",
    "cal-from-protein": "28",
    "per-cal-from-protein": "15",
    "calcium": "29",
    "iron": "1",
    "source": "N Buddy",
    "old units (ref)": "Bun, otar"
  },
  {
    "id": "1272",
    "type": "meal",
    "|| name                                               ||": "Chicken breast pan fried, without skin",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "weight-g": "100",
    "calories": "187",
    "fat": "4.7",
    "cal-from-fat": "42.3",
    "per-cal-from-fat": "23",
    "saturated": "1.3",
    "polyunsaturated": "1",
    "monounsaturated": "1.7",
    "cholesterol": "91",
    "sodium": "79",
    "carbs": "0.5",
    "fibre": "0",
    "sugar": "0",
    "protein": "33.4",
    "cal-from-protein": "134",
    "per-cal-from-protein": "71",
    "potassium": "276",
    "calcium": "16",
    "iron": "1.1",
    "phosphorus": "246",
    "source": "USDA",
    "old units (ref)": "Chicken, broilers or fryers, breast, meat only, cooked, fried"
  },
  {
    "id": "1273",
    "type": "meal",
    "|| name                                               ||": "Curd, plain",
    "rating": "2",
    "high-fat": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "225",
    "calories": "135",
    "fat": "9",
    "cal-from-fat": "81",
    "per-cal-from-fat": "60",
    "saturated": "5.9",
    "sodium": "72",
    "carbs": "6.5",
    "protein": "6.9",
    "cal-from-protein": "28",
    "per-cal-from-protein": "20",
    "source": "HPB",
    "old units (ref)": "Curd, cow's milk"
  },
  {
    "id": "1274",
    "disabled": "1",
    "type": "meal",
    "|| name                                               ||": "Poke rice bowl",
    "rating": "2",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "378",
    "calories": "490",
    "fat": "22",
    "cal-from-fat": "198",
    "per-cal-from-fat": "40",
    "saturated": "3",
    "polyunsaturated": "10",
    "monounsaturated": "7",
    "sodium": "1000",
    "carbs": "51",
    "fibre": "2",
    "sugar": "4",
    "protein": "22",
    "cal-from-protein": "88",
    "per-cal-from-protein": "18",
    "potassium": "344",
    "calcium": "80",
    "iron": "2",
    "phosphorus": "273",
    "source": "N Buddy",
    "old units (ref)": "Poke Rice Bowl"
  },
  {
    "id": "1275",
    "type": "meal",
    "|| name                                               ||": "Porridge with sweet potato",
    "rating": "2",
    "high-potassium": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "362",
    "calories": "167",
    "fat": "1.5",
    "cal-from-fat": "13.5",
    "per-cal-from-fat": "8",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "0",
    "sodium": "7",
    "carbs": "34.8",
    "fibre": "1.5",
    "sugar": "2.2",
    "protein": "4.3",
    "cal-from-protein": "17",
    "per-cal-from-protein": "10",
    "potassium": "177",
    "calcium": "18",
    "iron": "0",
    "phosphorus": "22",
    "source": "N Buddy",
    "old units (ref)": "Porridge, sweet potato"
  },
  {
    "id": "1276",
    "type": "meal",
    "|| name                                               ||": "Quail egg",
    "rating": "2",
    "serving": "1",
    "|| unit ||": "egg",
    "weight-g": "12",
    "calories": "18",
    "fat": "1.3",
    "cal-from-fat": "11.7",
    "per-cal-from-fat": "65",
    "saturated": "0.6",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.6",
    "cholesterol": "54",
    "sodium": "12",
    "carbs": "0.1",
    "fibre": "0",
    "sugar": "0",
    "protein": "1.3",
    "cal-from-protein": "5",
    "per-cal-from-protein": "29",
    "potassium": "14",
    "calcium": "6",
    "iron": "0.4",
    "phosphorus": "26",
    "source": "N Buddy",
    "old units (ref)": "Quail Egg, whole, raw"
  },
  {
    "id": "1277",
    "type": "meal",
    "|| name                                               ||": "Rosti potatoes",
    "rating": "2",
    "fried": "1",
    "high-fat": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "103",
    "calories": "166",
    "fat": "11.6",
    "cal-from-fat": "104.4",
    "per-cal-from-fat": "63",
    "saturated": "5.1",
    "polyunsaturated": "1",
    "monounsaturated": "10.8",
    "cholesterol": "0",
    "sodium": "20",
    "carbs": "13.1",
    "fibre": "1.9",
    "sugar": "3.7",
    "protein": "2.3",
    "cal-from-protein": "9",
    "per-cal-from-protein": "6",
    "potassium": "174",
    "calcium": "8",
    "iron": "0.1",
    "phosphorus": "18",
    "source": "N Buddy",
    "old units (ref)": "Rosti, without cream"
  },
  {
    "id": "1278",
    "type": "meal",
    "|| name                                               ||": "Salmon roe",
    "rating": "2",
    "high-calcium": "1",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "weight-g": "100",
    "calories": "167",
    "fat": "9.7",
    "cal-from-fat": "87.3",
    "per-cal-from-fat": "52",
    "cholesterol": "300",
    "carbs": "6.7",
    "fibre": "0",
    "sugar": "0",
    "protein": "13.3",
    "cal-from-protein": "53",
    "per-cal-from-protein": "32",
    "calcium": "133",
    "iron": "3.6",
    "source": "USDA",
    "old units (ref)": "CAVIAR RED SALMON [fat has been adjusted]"
  },
  {
    "id": "1279",
    "type": "meal",
    "|| name                                               ||": "Sambal ikan bilis",
    "rating": "1",
    "fried": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-purine": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "15",
    "calories": "31",
    "fat": "2.1",
    "cal-from-fat": "18.9",
    "per-cal-from-fat": "61",
    "saturated": "0.3",
    "polyunsaturated": "1.4",
    "monounsaturated": "0.4",
    "trans": "0",
    "cholesterol": "6",
    "sodium": "251",
    "carbs": "1.5",
    "fibre": "0.6",
    "sugar": "1.3",
    "protein": "1.6",
    "cal-from-protein": "6",
    "per-cal-from-protein": "21",
    "source": "N Buddy",
    "old units (ref)": "Woh Hup Sambal Ikan Bilis"
  },
  {
    "id": "1280",
    "type": "meal",
    "|| name                                               ||": "Scallop, pan fried",
    "rating": "3",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "13",
    "calories": "15",
    "fat": "0.6",
    "cal-from-fat": "5.4",
    "per-cal-from-fat": "36",
    "saturated": "0.08",
    "polyunsaturated": "0.22",
    "monounsaturated": "0.2",
    "cholesterol": "3.77",
    "sodium": "64.6",
    "carbs": "0.5",
    "fibre": "0",
    "sugar": "0.004",
    "protein": "1.9",
    "cal-from-protein": "8",
    "per-cal-from-protein": "51",
    "potassium": "32",
    "calcium": "1",
    "iron": "0.06",
    "phosphorus": "51.7",
    "source": "USDA",
    "old units (ref)": "Scallops, baked or broiled, fat added"
  },
  {
    "id": "1281",
    "type": "meal",
    "|| name                                               ||": "Schnitzel",
    "rating": "1",
    "fried": "1",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "170",
    "calories": "462",
    "fat": "23.9",
    "cal-from-fat": "215.1",
    "per-cal-from-fat": "47",
    "saturated": "4.6",
    "polyunsaturated": "4.9",
    "monounsaturated": "12.9",
    "cholesterol": "171",
    "sodium": "511",
    "carbs": "22.6",
    "fibre": "1.3",
    "sugar": "0.9",
    "protein": "38.2",
    "cal-from-protein": "153",
    "per-cal-from-protein": "33",
    "potassium": "455",
    "calcium": "40",
    "iron": "1.6",
    "phosphorus": "374",
    "source": "N Buddy",
    "old units (ref)": "Schnitzel, fried chicken"
  },
  {
    "id": "1282",
    "type": "meal",
    "|| name                                               ||": "Seafood stew",
    "rating": "2",
    "high-fat": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "456",
    "calories": "240",
    "fat": "13.9",
    "cal-from-fat": "125.1",
    "per-cal-from-fat": "52",
    "saturated": "4.7",
    "polyunsaturated": "3.9",
    "monounsaturated": "4.3",
    "trans": "0",
    "cholesterol": "37",
    "sodium": "1041",
    "carbs": "6",
    "fibre": "1",
    "sugar": "3.2",
    "protein": "22.7",
    "cal-from-protein": "91",
    "per-cal-from-protein": "38",
    "potassium": "571",
    "calcium": "102",
    "iron": "3.4",
    "phosphorus": "356",
    "source": "N Buddy",
    "old units (ref)": "Korean Seafood Tofu Soup"
  },
  {
    "id": "1283",
    "type": "meal",
    "|| name                                               ||": "Yam ring, with chicken",
    "rating": "1",
    "high-fat": "1",
    "high-fibre": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "alternate-spellings": "Yam: Taro",
    "weight-g": "257",
    "calories": "388",
    "fat": "18.4",
    "cal-from-fat": "165.6",
    "per-cal-from-fat": "43",
    "saturated": "6.1",
    "polyunsaturated": "2.9",
    "monounsaturated": "8.4",
    "trans": "0",
    "cholesterol": "15",
    "sodium": "1006",
    "carbs": "44.8",
    "fibre": "7",
    "sugar": "8.9",
    "protein": "12.4",
    "cal-from-protein": "50",
    "per-cal-from-protein": "13",
    "potassium": "972",
    "calcium": "48",
    "iron": "2.2",
    "phosphorus": "201",
    "source": "N Buddy",
    "old units (ref)": "Yam Ring With Chicken"
  },
  {
    "id": "1284",
    "type": "snack",
    "|| name                                               ||": "Soy chunks, dried",
    "rating": "3",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "info-translation": "Meal maker",
    "alternate-spellings": "Soy: Soya",
    "weight-g": "30",
    "calories": "104",
    "fat": "2.2",
    "cal-from-fat": "19.8",
    "per-cal-from-fat": "19",
    "saturated": "0.3",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "6",
    "carbs": "4.6",
    "fibre": "0.6",
    "sugar": "3",
    "protein": "13.4",
    "cal-from-protein": "54",
    "per-cal-from-protein": "52",
    "potassium": "651",
    "calcium": "72",
    "iron": "2.8",
    "phosphorus": "202",
    "source": "N Buddy",
    "old units (ref)": "Soya Chunks, dried"
  },
  {
    "id": "1285",
    "type": "meal",
    "|| name                                               ||": "Chinese spinach with trio eggs",
    "rating": "2",
    "high-calcium": "1",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "info-include-exclude": "with century and salted eggs",
    "weight-g": "146",
    "calories": "125",
    "fat": "8.6",
    "cal-from-fat": "77.4",
    "per-cal-from-fat": "62",
    "saturated": "3",
    "polyunsaturated": "1.1",
    "monounsaturated": "3.9",
    "cholesterol": "243",
    "sodium": "613",
    "carbs": "4",
    "fibre": "1.4",
    "sugar": "1",
    "protein": "9.1",
    "cal-from-protein": "36",
    "per-cal-from-protein": "29",
    "potassium": "404",
    "calcium": "123",
    "iron": "3.4",
    "phosphorus": "296",
    "source": "N Buddy",
    "old units (ref)": "Chinese Spinach With Three Eggs"
  },
  {
    "id": "1286",
    "type": "meal",
    "|| name                                               ||": "Soup with spinach and meat",
    "rating": "3",
    "high-potassium": "1",
    "high-salt": "1",
    "red-meat": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "250",
    "calories": "86",
    "fat": "6",
    "cal-from-fat": "54",
    "per-cal-from-fat": "63",
    "saturated": "2",
    "polyunsaturated": "2",
    "monounsaturated": "2",
    "cholesterol": "35",
    "sodium": "375",
    "carbs": "1",
    "fibre": "0.8",
    "sugar": "0",
    "protein": "7",
    "cal-from-protein": "28",
    "per-cal-from-protein": "33",
    "potassium": "175",
    "calcium": "25",
    "iron": "0.5",
    "phosphorus": "28",
    "source": "N Buddy",
    "old units (ref)": "Soup, Spinach and Minced Pork"
  },
  {
    "id": "1287",
    "type": "meal",
    "|| name                                               ||": "Salad with seafood, with dressing",
    "rating": "2",
    "healthy-fats": "1",
    "high-calcium": "1",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-purine": "1",
    "high-salt": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "208",
    "calories": "395",
    "fat": "33.3",
    "cal-from-fat": "299.7",
    "per-cal-from-fat": "76",
    "saturated": "5.2",
    "polyunsaturated": "19.6",
    "monounsaturated": "7.4",
    "cholesterol": "191",
    "sodium": "1200",
    "carbs": "1.6",
    "fibre": "0.8",
    "sugar": "1.2",
    "protein": "21",
    "cal-from-protein": "84",
    "per-cal-from-protein": "21",
    "potassium": "345",
    "calcium": "139",
    "iron": "1.2",
    "phosphorus": "237",
    "source": "USDA",
    "old units (ref)": "Seafood salad"
  },
  {
    "id": "1288",
    "type": "meal",
    "|| name                                               ||": "Tau kwa, braised",
    "rating": "2",
    "high-salt": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "83",
    "calories": "129",
    "fat": "7",
    "cal-from-fat": "63",
    "per-cal-from-fat": "49",
    "saturated": "1.3",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "216",
    "carbs": "0.7",
    "fibre": "3.1",
    "sugar": "0",
    "protein": "16",
    "cal-from-protein": "64",
    "per-cal-from-protein": "50",
    "source": "Fairprice Braised Tau Kwa",
    "old units (ref)": "https://www.fairprice.com.sg/product/fairprice-braised-tau-kwa-250g-13222611"
  },
  {
    "id": "1289",
    "type": "meal",
    "|| name                                               ||": "Steamed mixed vegetables",
    "rating": "3",
    "high-fibre": "1",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "weight-g": "226",
    "calories": "90",
    "fat": "1",
    "cal-from-fat": "9",
    "per-cal-from-fat": "10",
    "saturated": "0",
    "polyunsaturated": "0.5",
    "monounsaturated": "0.5",
    "trans": "0",
    "cholesterol": "0",
    "carbs": "16",
    "fibre": "8",
    "sugar": "5",
    "protein": "3",
    "cal-from-protein": "12",
    "per-cal-from-protein": "13",
    "calcium": "60",
    "iron": "1.8",
    "source": "N Buddy",
    "old units (ref)": "Steamed Vegetables"
  },
  {
    "id": "1290",
    "type": "meal",
    "|| name                                               ||": "Beancurd, silken (Japanese style)",
    "rating": "3",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "info-translation": "Tofu",
    "weight-g": "90",
    "calories": "40",
    "fat": "2",
    "cal-from-fat": "18",
    "per-cal-from-fat": "45",
    "saturated": "1",
    "polyunsaturated": "1",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "1",
    "fibre": "0",
    "sugar": "0",
    "protein": "4",
    "cal-from-protein": "16",
    "per-cal-from-protein": "40",
    "potassium": "115",
    "calcium": "20",
    "iron": "0.7",
    "source": "N Buddy",
    "old units (ref)": "Silken Tofu"
  },
  {
    "id": "1291",
    "type": "snack",
    "|| name                                               ||": "Fromage frais, fat free",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "serving",
    "weight-g": "100",
    "calories": "50",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "4",
    "saturated": "0.1",
    "carbs": "4.4",
    "fibre": "0.3",
    "sugar": "3.3",
    "protein": "7.6",
    "cal-from-protein": "30",
    "per-cal-from-protein": "61",
    "source": "Carb Manager",
    "old units (ref)": "Natural Fromage Frais Eat Smart"
  },
  {
    "id": "1292",
    "type": "meal",
    "|| name                                               ||": "Galaktoboureko",
    "rating": "1",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "170",
    "calories": "573",
    "fat": "25.7",
    "cal-from-fat": "231.3",
    "per-cal-from-fat": "40",
    "saturated": "15.5",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "cholesterol": "112.6",
    "carbs": "63.9",
    "fibre": "1.1",
    "sugar": "58.5",
    "protein": "6.9",
    "cal-from-protein": "28",
    "per-cal-from-protein": "5",
    "source": "Carb Manager",
    "old units (ref)": "Galaktoboureko"
  },
  {
    "id": "1293",
    "type": "snack",
    "|| name                                               ||": "Friand",
    "rating": "1",
    "high-fat": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cake",
    "weight-g": "60",
    "calories": "163",
    "fat": "10.2",
    "cal-from-fat": "91.8",
    "per-cal-from-fat": "56",
    "saturated": "0.9",
    "sodium": "52",
    "carbs": "17.1",
    "fibre": "1.7",
    "sugar": "8.4",
    "protein": "4.2",
    "cal-from-protein": "17",
    "per-cal-from-protein": "10",
    "calcium": "34",
    "source": "https://www.calorieking.com/au/en/foods/f/calories-in-cakes-friand-average-all-types/DjCWvMUIT1u4cOZGGWXGSg",
    "old units (ref)": "Friand, Average All Types"
  },
  {
    "id": "1294",
    "type": "meal",
    "|| name                                               ||": "Green curry with meat",
    "rating": "2",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "weight-g": "150",
    "calories": "255",
    "fat": "19.8",
    "cal-from-fat": "178.2",
    "per-cal-from-fat": "70",
    "saturated": "14.5",
    "polyunsaturated": "0.9",
    "monounsaturated": "3.3",
    "cholesterol": "48",
    "sodium": "711",
    "carbs": "3.6",
    "fibre": "1.1",
    "sugar": "3",
    "protein": "15.5",
    "cal-from-protein": "62",
    "per-cal-from-protein": "24",
    "potassium": "414",
    "calcium": "36",
    "iron": "1.4",
    "phosphorus": "165",
    "source": "N Buddy",
    "old units (ref)": "Green Curry Chicken"
  },
  {
    "id": "1295",
    "type": "meal",
    "|| name                                               ||": "Dumpling noodle soup",
    "rating": "2",
    "high-calcium": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-include-exclude": "with shrimps",
    "weight-g": "600",
    "calories": "414",
    "fat": "9.6",
    "cal-from-fat": "86.4",
    "per-cal-from-fat": "21",
    "saturated": "3.6",
    "polyunsaturated": "1.7",
    "monounsaturated": "4",
    "cholesterol": "30",
    "sodium": "2088",
    "carbs": "58.2",
    "fibre": "4.2",
    "sugar": "3.6",
    "protein": "24",
    "cal-from-protein": "96",
    "per-cal-from-protein": "23",
    "potassium": "534",
    "calcium": "192",
    "iron": "3",
    "phosphorus": "156",
    "source": "N Buddy",
    "old units (ref)": "Shrimp Dumpling Noodles Soup"
  },
  {
    "id": "1296",
    "type": "drink",
    "|| name                                               ||": "Margarita",
    "rating": "1",
    "alcohol": "1",
    "high-purine": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "glass",
    "weight-g": "240",
    "calories": "173",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "17",
    "carbs": "43",
    "fibre": "0",
    "sugar": "40",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "0",
    "calcium": "0",
    "iron": "0",
    "phosphorus": "0",
    "source": "N Buddy",
    "old units (ref)": "Margarita"
  },
  {
    "id": "1297",
    "type": "meal",
    "|| name                                               ||": "Bread, multigrain",
    "rating": "2",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "32",
    "calories": "85",
    "fat": "1.1",
    "cal-from-fat": "9.9",
    "per-cal-from-fat": "12",
    "saturated": "0.3",
    "sodium": "93.1",
    "carbs": "15",
    "fibre": "1.8",
    "protein": "3.8",
    "cal-from-protein": "15",
    "per-cal-from-protein": "18",
    "source": "HPB",
    "old units (ref)": "Bread, multigrain"
  },
  {
    "id": "1298",
    "type": "meal",
    "|| name                                               ||": "Pan-fried potato slice",
    "rating": "2",
    "high-fat": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "10",
    "calories": "12",
    "fat": "0.5",
    "cal-from-fat": "4.5",
    "per-cal-from-fat": "38",
    "saturated": "0.1",
    "polyunsaturated": "0.3",
    "monounsaturated": "0.1",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "0.3",
    "carbs": "1.8",
    "fibre": "0.2",
    "sugar": "0.1",
    "protein": "0.2",
    "cal-from-protein": "1",
    "per-cal-from-protein": "7",
    "potassium": "34.3",
    "calcium": "0.5",
    "iron": "0",
    "phosphorus": "4",
    "source": "N Buddy",
    "old units (ref)": "Pan Fried Potato Chips"
  },
  {
    "id": "1299",
    "type": "meal",
    "|| name                                               ||": "Pastrami Reuben sandwich",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "processed": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "info-include-exclude": "with white bread",
    "weight-g": "400",
    "calories": "730",
    "fat": "30",
    "cal-from-fat": "270",
    "per-cal-from-fat": "37",
    "saturated": "9",
    "cholesterol": "100",
    "carbs": "67",
    "fibre": "5",
    "sugar": "7",
    "protein": "39",
    "cal-from-protein": "156",
    "per-cal-from-protein": "21",
    "source": "Carb Manager",
    "old units (ref)": "#20 grilled pastrami reuben sub, white bread regular"
  },
  {
    "id": "1300",
    "type": "meal",
    "|| name                                               ||": "Polvoron",
    "rating": "1",
    "high-fat": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "25",
    "calories": "120",
    "fat": "5",
    "cal-from-fat": "45",
    "per-cal-from-fat": "38",
    "saturated": "4.5",
    "cholesterol": "0",
    "carbs": "16",
    "fibre": "1",
    "sugar": "9",
    "protein": "2",
    "cal-from-protein": "8",
    "per-cal-from-protein": "7",
    "source": "Carb Manager",
    "old units (ref)": "classic polvoron"
  },
  {
    "id": "1301",
    "type": "meal",
    "|| name                                               ||": "Prawns, baked in garlic butter sauce",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "weight-g": "100",
    "calories": "272",
    "fat": "13.4",
    "cal-from-fat": "120.6",
    "per-cal-from-fat": "44",
    "saturated": "7.8",
    "polyunsaturated": "1.1",
    "monounsaturated": "3.7",
    "cholesterol": "269.8",
    "carbs": "5.2",
    "fibre": "0",
    "sugar": "0",
    "protein": "26",
    "cal-from-protein": "104",
    "per-cal-from-protein": "38",
    "source": "Carb Manager",
    "old units (ref)": "Prawns, garlic and Butter"
  },
  {
    "id": "1302",
    "type": "meal",
    "|| name                                               ||": "Pumpkin soup without cream",
    "rating": "3",
    "high-salt": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-examples": "squash",
    "weight-g": "242",
    "calories": "101",
    "fat": "2",
    "cal-from-fat": "18",
    "per-cal-from-fat": "18",
    "saturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "420",
    "carbs": "18",
    "fibre": "2.5",
    "sugar": "4",
    "protein": "2",
    "cal-from-protein": "8",
    "per-cal-from-protein": "8",
    "calcium": "40",
    "iron": "0.5",
    "source": "N Buddy",
    "old units (ref)": "Squash soup (watery)"
  },
  {
    "id": "1303",
    "type": "meal",
    "|| name                                               ||": "Sans rival",
    "rating": "1",
    "high-fat": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "weight-g": "120",
    "calories": "470",
    "fat": "33.1",
    "cal-from-fat": "297.9",
    "per-cal-from-fat": "63",
    "saturated": "15.7",
    "carbs": "36.8",
    "fibre": "0.8",
    "sugar": "30.5",
    "protein": "7.6",
    "cal-from-protein": "30",
    "per-cal-from-protein": "6",
    "source": "Carb Manager",
    "old units (ref)": "Sans Rival"
  },
  {
    "id": "1304",
    "type": "meal",
    "|| name                                               ||": "Sesame oil chicken",
    "rating": "2",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "info-translation": "Ma You Ji",
    "weight-g": "100",
    "calories": "208",
    "fat": "15.2",
    "cal-from-fat": "136.8",
    "per-cal-from-fat": "66",
    "saturated": "3.3",
    "polyunsaturated": "4.8",
    "monounsaturated": "5.9",
    "cholesterol": "49",
    "sodium": "594",
    "carbs": "1.3",
    "fibre": "0.2",
    "sugar": "0.9",
    "protein": "14.2",
    "cal-from-protein": "57",
    "per-cal-from-protein": "27",
    "potassium": "118",
    "calcium": "13",
    "iron": "1",
    "phosphorus": "86",
    "source": "N Buddy",
    "old units (ref)": "Sesame oil chicken (chicken thigh)"
  },
  {
    "id": "1305",
    "type": "meal",
    "|| name                                               ||": "Shishamo, baked",
    "rating": "3",
    "healthy-fats": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "20",
    "calories": "31.9",
    "fat": "1.4",
    "cal-from-fat": "12.6",
    "per-cal-from-fat": "39",
    "saturated": "0.3",
    "cholesterol": "54",
    "sodium": "115.2",
    "carbs": "0",
    "protein": "4.4",
    "cal-from-protein": "18",
    "per-cal-from-protein": "55",
    "source": "HPB",
    "old units (ref)": "Shishamo, semi-dried, baked"
  },
  {
    "id": "1306",
    "type": "meal",
    "|| name                                               ||": "Chicken sausage breakfast wrap",
    "rating": "1",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "info-brands": "McDonald's",
    "weight-g": "157",
    "calories": "552",
    "fat": "30",
    "cal-from-fat": "270",
    "per-cal-from-fat": "49",
    "saturated": "10.1",
    "polyunsaturated": "6.6",
    "monounsaturated": "10.4",
    "cholesterol": "528",
    "sodium": "1368",
    "carbs": "42.6",
    "fibre": "3",
    "sugar": "1.2",
    "protein": "27.9",
    "cal-from-protein": "112",
    "per-cal-from-protein": "20",
    "source": "N Buddy",
    "old units (ref)": "Mcdonald's chicken sausage breakfast wrap"
  },
  {
    "id": "1307",
    "type": "meal",
    "|| name                                               ||": "Chicken chop, grilled, with skin",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "weight-g": "172",
    "calories": "545",
    "fat": "45.6",
    "cal-from-fat": "410.4",
    "per-cal-from-fat": "75",
    "saturated": "13.3",
    "polyunsaturated": "6.4",
    "monounsaturated": "23.3",
    "trans": "0.2",
    "cholesterol": "185",
    "sodium": "98",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "34.7",
    "cal-from-protein": "139",
    "per-cal-from-protein": "25",
    "potassium": "400",
    "calcium": "22",
    "iron": "1.4",
    "phosphorus": "316",
    "source": "N Buddy",
    "old units (ref)": "Chicken chop grilled with skin"
  },
  {
    "id": "1308",
    "type": "meal",
    "|| name                                               ||": "Chicken chop, grilled, without skin",
    "rating": "3",
    "high-cholesterol": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "131",
    "calories": "233",
    "fat": "12",
    "cal-from-fat": "108",
    "per-cal-from-fat": "46",
    "saturated": "3.2",
    "polyunsaturated": "2",
    "monounsaturated": "6.2",
    "trans": "0.1",
    "cholesterol": "136",
    "sodium": "87",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "31.3",
    "cal-from-protein": "125",
    "per-cal-from-protein": "54",
    "potassium": "383",
    "calcium": "16",
    "iron": "1.1",
    "phosphorus": "301",
    "source": "N Buddy",
    "old units (ref)": "Chicken Chop, grilled, without skin"
  },
  {
    "id": "1309",
    "type": "snack",
    "|| name                                               ||": "Falafel",
    "rating": "2",
    "fried": "1",
    "high-fat": "1",
    "high-fibre": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "60",
    "calories": "148",
    "fat": "6.4",
    "cal-from-fat": "57.6",
    "per-cal-from-fat": "39",
    "saturated": "1.2",
    "polyunsaturated": "2.4",
    "monounsaturated": "2.5",
    "trans": "0.1",
    "cholesterol": "0",
    "sodium": "350.3",
    "carbs": "17.2",
    "fibre": "7.1",
    "sugar": "2.8",
    "protein": "5.3",
    "cal-from-protein": "21",
    "per-cal-from-protein": "14",
    "potassium": "204",
    "calcium": "40.7",
    "iron": "2.2",
    "phosphorus": "60",
    "source": "N Buddy",
    "old units (ref)": "Falafel"
  },
  {
    "id": "1310",
    "type": "meal",
    "|| name                                               ||": "Chutney",
    "rating": "2",
    "high-salt": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "25",
    "calories": "46",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "2",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "173",
    "carbs": "11.4",
    "fibre": "0.6",
    "sugar": "10.4",
    "protein": "0.1",
    "cal-from-protein": "0",
    "per-cal-from-protein": "1",
    "potassium": "53",
    "calcium": "6",
    "iron": "0.2",
    "phosphorus": "5",
    "source": "N Buddy",
    "old units (ref)": "Chutney"
  },
  {
    "id": "1311",
    "type": "meal",
    "|| name                                               ||": "Vegetable cutlet",
    "rating": "1",
    "fried": "1",
    "high-fat": "1",
    "serving": "1",
    "|| unit ||": "cutlet",
    "weight-g": "53",
    "calories": "150",
    "fat": "6",
    "cal-from-fat": "54",
    "per-cal-from-fat": "36",
    "saturated": "2",
    "cholesterol": "0",
    "carbs": "16",
    "fibre": "2",
    "sugar": "2",
    "protein": "5",
    "cal-from-protein": "20",
    "per-cal-from-protein": "13",
    "source": "Carb Manager",
    "old units (ref)": "Vegetable cutlets"
  },
  {
    "id": "1312",
    "type": "snack",
    "|| name                                               ||": "Samosa, with vegetable filling",
    "rating": "1",
    "fried": "1",
    "high-fat": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "68",
    "calories": "206",
    "fat": "11.4",
    "cal-from-fat": "102.6",
    "per-cal-from-fat": "50",
    "saturated": "5.3",
    "polyunsaturated": "1.2",
    "monounsaturated": "4.5",
    "cholesterol": "12",
    "sodium": "311",
    "carbs": "22.2",
    "fibre": "1.8",
    "sugar": "1.1",
    "protein": "3.5",
    "cal-from-protein": "14",
    "per-cal-from-protein": "7",
    "potassium": "148",
    "calcium": "25",
    "iron": "0.7",
    "phosphorus": "43",
    "source": "N Buddy",
    "old units (ref)": "Samosa vegetable"
  },
  {
    "id": "1313",
    "type": "meal",
    "|| name                                               ||": "Roasted mixed vegetables",
    "rating": "3",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "weight-g": "100",
    "calories": "117",
    "fat": "6.7",
    "cal-from-fat": "60.3",
    "per-cal-from-fat": "52",
    "saturated": "1",
    "polyunsaturated": "0.7",
    "monounsaturated": "4.6",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "184",
    "carbs": "10.4",
    "fibre": "4.5",
    "sugar": "4.8",
    "protein": "2.1",
    "cal-from-protein": "8",
    "per-cal-from-protein": "7",
    "potassium": "356",
    "calcium": "25",
    "iron": "0.5",
    "phosphorus": "54",
    "source": "N Buddy",
    "old units (ref)": "Roasted mixed vegetables"
  },
  {
    "id": "1314",
    "type": "meal",
    "|| name                                               ||": "Pasta, aglio e olio with seafood or meat",
    "rating": "2",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "info-examples": "spaghetti, linguine, macaroni",
    "weight-g": "232",
    "calories": "408",
    "fat": "9.3",
    "cal-from-fat": "83.7",
    "per-cal-from-fat": "21",
    "saturated": "1.7",
    "sodium": "786.5",
    "carbs": "64",
    "protein": "17.4",
    "cal-from-protein": "70",
    "per-cal-from-protein": "17",
    "source": "HPB",
    "old units (ref)": "Prawn aglio Olio"
  },
  {
    "id": "1315",
    "type": "meal",
    "|| name                                               ||": "Sandwich with added sugar peanut butter",
    "rating": "2",
    "healthy-fats": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "set",
    "weight-g": "45",
    "calories": "200",
    "fat": "7.7",
    "cal-from-fat": "69.3",
    "per-cal-from-fat": "35",
    "saturated": "1.4",
    "polyunsaturated": "2.5",
    "monounsaturated": "3.5",
    "cholesterol": "0",
    "sodium": "147",
    "carbs": "25.6",
    "fibre": "2.4",
    "sugar": "10.7",
    "protein": "6.9",
    "cal-from-protein": "28",
    "per-cal-from-protein": "14",
    "potassium": "109",
    "calcium": "19",
    "iron": "1.2",
    "phosphorus": "83",
    "source": "N Buddy",
    "old units (ref)": "Peanut butter sandwich with added sugar peanut butter | row 548 (https://www.nature.com/articles/s41387-020-00145-w.pdf) peanut butter and jam toast has a high GI therefore assumed that with added sugar, the GI would be high too."
  },
  {
    "id": "1316",
    "type": "meal",
    "|| name                                               ||": "Pork katsu don",
    "rating": "1",
    "fried": "1",
    "high-fat": "1",
    "high-salt": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "599",
    "calories": "910",
    "fat": "35.8",
    "cal-from-fat": "322.2",
    "per-cal-from-fat": "35",
    "saturated": "14.7",
    "sodium": "1533.4",
    "carbs": "103",
    "fibre": "3.6",
    "protein": "44.3",
    "cal-from-protein": "177",
    "per-cal-from-protein": "19",
    "source": "HPB",
    "old units (ref)": "(Pork katsu don)| (https://www.nature.com/articles/s41387-020-00145-w.pdf) row 318. japanese white rice with curry has a GI of 82. Pork Katsu don has both ingredients in it therefore assumed that it would be high GI"
  },
  {
    "id": "1317",
    "type": "meal",
    "|| name                                               ||": "Soy sauce chicken",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "alternate-spellings": "Soy: Soya",
    "weight-g": "100",
    "calories": "203",
    "fat": "14.4",
    "cal-from-fat": "129.6",
    "per-cal-from-fat": "64",
    "saturated": "5",
    "polyunsaturated": "2.9",
    "monounsaturated": "5.7",
    "cholesterol": "124",
    "sodium": "659",
    "carbs": "3.7",
    "fibre": "0",
    "sugar": "0",
    "protein": "14.6",
    "cal-from-protein": "58",
    "per-cal-from-protein": "29",
    "potassium": "130",
    "calcium": "66",
    "iron": "1.1",
    "phosphorus": "84",
    "source": "N Buddy",
    "old units (ref)": "Soya Sauce Chicken"
  },
  {
    "id": "1318",
    "type": "meal",
    "|| name                                               ||": "Soy sauce chicken noodles, dry",
    "rating": "2",
    "high-calcium": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "alternate-spellings": "Soy: Soya",
    "weight-g": "350",
    "calories": "518",
    "fat": "22.1",
    "cal-from-fat": "198.9",
    "per-cal-from-fat": "38",
    "saturated": "7.7",
    "polyunsaturated": "3.5",
    "monounsaturated": "9.7",
    "cholesterol": "70",
    "sodium": "994",
    "carbs": "47.6",
    "fibre": "4.6",
    "sugar": "4.2",
    "protein": "32.2",
    "cal-from-protein": "129",
    "per-cal-from-protein": "25",
    "potassium": "525",
    "calcium": "116",
    "iron": "1.1",
    "phosphorus": "207",
    "source": "N Buddy",
    "old units (ref)": "Soya sauce chicken noodles, dry"
  },
  {
    "id": "1319",
    "type": "meal",
    "|| name                                               ||": "Roasted pork noodles",
    "rating": "1",
    "high-calcium": "1",
    "high-fat": "1",
    "high-fibre": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "weight-g": "412",
    "calories": "724",
    "fat": "32.7",
    "cal-from-fat": "294.3",
    "per-cal-from-fat": "41",
    "saturated": "10.5",
    "polyunsaturated": "9.6",
    "monounsaturated": "11.3",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "3763",
    "carbs": "101.5",
    "fibre": "6.8",
    "sugar": "0.6",
    "protein": "7.4",
    "cal-from-protein": "30",
    "per-cal-from-protein": "4",
    "potassium": "478",
    "calcium": "109",
    "iron": "3.9",
    "phosphorus": "193",
    "source": "N Buddy",
    "old units (ref)": "Roasted Pork Noodles"
  },
  {
    "id": "1320",
    "type": "snack",
    "|| name                                               ||": "Corn dog",
    "rating": "1",
    "fried": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "88",
    "calories": "220",
    "fat": "10.6",
    "cal-from-fat": "95.4",
    "per-cal-from-fat": "43",
    "saturated": "3.1",
    "polyunsaturated": "3",
    "monounsaturated": "4.2",
    "cholesterol": "38.7",
    "sodium": "588",
    "carbs": "23.8",
    "fibre": "0.9",
    "sugar": "6.6",
    "protein": "7.5",
    "cal-from-protein": "30",
    "per-cal-from-protein": "14",
    "potassium": "110",
    "calcium": "63.4",
    "iron": "1.7",
    "phosphorus": "197",
    "source": "USDA",
    "old units (ref)": "Corn dog, frankfurter or hot dog with cornbread coating"
  },
  {
    "id": "1321",
    "type": "meal",
    "|| name                                               ||": "Buckwheat, boiled",
    "rating": "3",
    "high-phosphate": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "168",
    "calories": "155",
    "fat": "1",
    "cal-from-fat": "9",
    "per-cal-from-fat": "6",
    "saturated": "0.2",
    "polyunsaturated": "0.3",
    "monounsaturated": "0.3",
    "cholesterol": "0",
    "sodium": "7",
    "carbs": "33.5",
    "fibre": "4.5",
    "sugar": "1.5",
    "protein": "5.7",
    "cal-from-protein": "23",
    "per-cal-from-protein": "15",
    "potassium": "148",
    "calcium": "12",
    "iron": "1.3",
    "phosphorus": "118",
    "source": "N Buddy",
    "old units (ref)": "Buckwheat cooked"
  },
  {
    "id": "1322",
    "type": "drink",
    "|| name                                               ||": "Nutren Diabetes (powder)",
    "rating": "3",
    "healthy-fats": "1",
    "high-calcium": "1",
    "high-fibre": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "info-portion-aid": "standard dilution (7 scoops with water)",
    "volume-ml": "250",
    "calories": "253",
    "fat": "11",
    "cal-from-fat": "99",
    "per-cal-from-fat": "39",
    "saturated": "1",
    "polyunsaturated": "1.8",
    "monounsaturated": "7.1",
    "sodium": "237",
    "carbs": "24.5",
    "fibre": "4.8",
    "sugar": "2.6",
    "protein": "11.3",
    "cal-from-protein": "45",
    "per-cal-from-protein": "18",
    "potassium": "240",
    "calcium": "204",
    "iron": "3",
    "phosphorus": "138",
    "source": "https://www.nestlehealthscience.sg/brands/nutren-diabetes/nutren-diabetes-powder",
    "old units (ref)": "Nutren Diabetes Powder"
  },
  {
    "id": "1323",
    "type": "drink",
    "|| name                                               ||": "Fibrosol",
    "rating": "3",
    "high-fibre": "1",
    "serving": "1",
    "|| unit ||": "sachet",
    "weight-g": "5",
    "calories": "8",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "0.1",
    "carbs": "0.3",
    "fibre": "4.5",
    "sugar": "0.1",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "0",
    "source": "https://fibrosol.com/faqs/",
    "old units (ref)": "Fibrosol supplement facts"
  },
  {
    "id": "1324",
    "type": "snack",
    "|| name                                               ||": "Psyllium husk",
    "rating": "3",
    "high-fibre": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "6",
    "calories": "20",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "carbs": "5.1",
    "fibre": "5.1",
    "sugar": "0",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "source": "N Buddy",
    "old units (ref)": "Psyllium husk powder"
  },
  {
    "id": "1325",
    "type": "drink",
    "|| name                                               ||": "GNC Lean Shake",
    "rating": "3",
    "high-calcium": "1",
    "high-fibre": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "scoop",
    "info-translation": "Meal replacement / shake",
    "info-portion-aid": "standard dilution (1 scoop with water)",
    "weight-g": "52",
    "calories": "200",
    "fat": "3",
    "cal-from-fat": "27",
    "per-cal-from-fat": "14",
    "saturated": "2",
    "trans": "0",
    "cholesterol": "55",
    "sodium": "120",
    "carbs": "19",
    "fibre": "8",
    "sugar": "3",
    "protein": "25",
    "cal-from-protein": "100",
    "per-cal-from-protein": "50",
    "potassium": "200",
    "calcium": "500",
    "iron": "3.6",
    "phosphorus": "300",
    "source": "https://www.gnc.com.sg/en/brands/gnc-total-lean/lean-shake-25-french-vanilla-74300070.html?catId=food_shakes",
    "old units (ref)": "Lean Shake™ 25 French Vanilla"
  },
  {
    "id": "1326",
    "type": "drink",
    "|| name                                               ||": "USANA Nutrimeal",
    "rating": "3",
    "high-calcium": "1",
    "high-fibre": "1",
    "high-iron": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "info-translation": "Meal replacement / shake",
    "info-portion-aid": "standard dilution (1 pouch with water)",
    "weight-g": "60",
    "calories": "259",
    "fat": "7.4",
    "cal-from-fat": "66.6",
    "per-cal-from-fat": "26",
    "saturated": "0.8",
    "polyunsaturated": "0.8",
    "monounsaturated": "5.6",
    "trans": "0",
    "cholesterol": "0.1",
    "sodium": "315.6",
    "carbs": "24.6",
    "fibre": "8.3",
    "sugar": "18",
    "protein": "15",
    "cal-from-protein": "60",
    "per-cal-from-protein": "23",
    "calcium": "311.5",
    "iron": "5.1",
    "source": "https://www.usana.com/content/35509144-131f-451c-9ae9-505eaf793541.pdf",
    "old units (ref)": "Nutrimeal 14pk Pouch"
  },
  {
    "id": "1327",
    "type": "drink",
    "|| name                                               ||": "Amway Nutrilite BodyKey",
    "rating": "3",
    "high-fibre": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "info-translation": "Meal replacement / shake",
    "info-portion-aid": "standard dilution (1 pouch with water)",
    "weight-g": "28",
    "calories": "112",
    "fat": "4.5",
    "cal-from-fat": "40.5",
    "per-cal-from-fat": "36",
    "saturated": "0.5",
    "polyunsaturated": "2.7",
    "monounsaturated": "1.1",
    "sodium": "110",
    "carbs": "14.5",
    "fibre": "5",
    "sugar": "5.9",
    "protein": "6.3",
    "cal-from-protein": "25",
    "per-cal-from-protein": "23",
    "potassium": "125",
    "calcium": "85",
    "iron": "5.3",
    "phosphorus": "85",
    "source": "N Buddy",
    "old units (ref)": "Amway bodykey nutrilite meal replacement (vanilla)"
  },
  {
    "id": "1328",
    "type": "drink",
    "|| name                                               ||": "Garden of Life Raw Organic Meal",
    "rating": "3",
    "high-iron": "1",
    "high-salt": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "scoop",
    "info-translation": "Meal replacement / shake",
    "info-portion-aid": "standard dilution (1 scoop with water)",
    "weight-g": "35",
    "calories": "130",
    "fat": "2",
    "cal-from-fat": "18",
    "per-cal-from-fat": "14",
    "saturated": "0",
    "polyunsaturated": "1",
    "monounsaturated": "0.5",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "190",
    "carbs": "10",
    "fibre": "2",
    "sugar": "1",
    "protein": "20",
    "cal-from-protein": "80",
    "per-cal-from-protein": "62",
    "potassium": "55",
    "calcium": "40",
    "iron": "4",
    "source": "https://www.gardenoflife.com/raw-organic-meal-shake-meal-replacement-vanilla",
    "old units (ref)": "Raw Organic Meal Shake & Meal Replacement Vanilla Powder"
  },
  {
    "id": "1329",
    "type": "snack",
    "|| name                                               ||": "Flaxseed",
    "rating": "3",
    "healthy-fats": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "10",
    "calories": "55",
    "fat": "4.4",
    "cal-from-fat": "39.6",
    "per-cal-from-fat": "72",
    "saturated": "0.4",
    "polyunsaturated": "3",
    "monounsaturated": "0.8",
    "cholesterol": "0",
    "sodium": "3.1",
    "carbs": "3",
    "fibre": "2.8",
    "sugar": "0.2",
    "protein": "1.9",
    "cal-from-protein": "8",
    "per-cal-from-protein": "14",
    "potassium": "83.7",
    "calcium": "26.3",
    "iron": "0.6",
    "phosphorus": "66.1",
    "source": "USDA",
    "old units (ref)": "Seeds, flaxseed"
  },
  {
    "id": "1330",
    "type": "meal",
    "|| name                                               ||": "Rawa thosai, large",
    "rating": "2",
    "high-phosphate": "1",
    "high-salt": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "alternate-spellings": "Rawa: Rava\nThosai: Dosa, Tosei",
    "weight-g": "152",
    "calories": "365",
    "fat": "10.5",
    "cal-from-fat": "94.5",
    "per-cal-from-fat": "26",
    "saturated": "5",
    "polyunsaturated": "1",
    "monounsaturated": "4.1",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "664",
    "carbs": "59.9",
    "fibre": "1.5",
    "sugar": "1.7",
    "protein": "7.5",
    "cal-from-protein": "30",
    "per-cal-from-protein": "8",
    "potassium": "122",
    "calcium": "20",
    "iron": "1.7",
    "phosphorus": "100",
    "source": "N Buddy",
    "old units (ref)": "Rawa Thosai (Ravadosai), Large"
  },
  {
    "id": "1331",
    "type": "meal",
    "|| name                                               ||": "Ven pongal, savoury",
    "rating": "2",
    "high-fibre": "1",
    "high-iron": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "alternate-spellings": "Ven: Venn \nPongal: Pongali, Huggi",
    "weight-g": "200",
    "calories": "273",
    "fat": "5.6",
    "cal-from-fat": "50.4",
    "per-cal-from-fat": "18",
    "saturated": "3.4",
    "polyunsaturated": "0.3",
    "monounsaturated": "1.5",
    "trans": "0",
    "cholesterol": "15",
    "sodium": "9",
    "carbs": "47.4",
    "fibre": "5.3",
    "sugar": "2.3",
    "protein": "8.4",
    "cal-from-protein": "34",
    "per-cal-from-protein": "12",
    "potassium": "30",
    "calcium": "31",
    "iron": "4.3",
    "phosphorus": "14",
    "source": "N Buddy",
    "old units (ref)": "Ven Pongal"
  },
  {
    "id": "1332",
    "type": "meal",
    "|| name                                               ||": "Sakkarai pongal, sweet",
    "rating": "1",
    "high-iron": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "alternate-spellings": "Sakkarai: Chakkara \nPongal: Pongali, Huggi",
    "weight-g": "200",
    "calories": "408",
    "fat": "12.6",
    "cal-from-fat": "113.4",
    "per-cal-from-fat": "28",
    "saturated": "7.2",
    "polyunsaturated": "0.7",
    "monounsaturated": "3.9",
    "trans": "0",
    "cholesterol": "30",
    "sodium": "19",
    "carbs": "68",
    "fibre": "4",
    "sugar": "36.6",
    "protein": "6.4",
    "cal-from-protein": "26",
    "per-cal-from-protein": "6",
    "potassium": "72",
    "calcium": "24",
    "iron": "3.2",
    "phosphorus": "33",
    "source": "N Buddy",
    "old units (ref)": "Sweet Pongal"
  },
  {
    "id": "1333",
    "type": "snack",
    "|| name                                               ||": "Egg tart, large",
    "rating": "1",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-phosphate": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "info-translation": "Dan Tat",
    "weight-g": "100",
    "calories": "280",
    "fat": "15.2",
    "cal-from-fat": "136.8",
    "per-cal-from-fat": "49",
    "saturated": "8.6",
    "polyunsaturated": "0.9",
    "monounsaturated": "5",
    "cholesterol": "84",
    "sodium": "117",
    "carbs": "28.8",
    "fibre": "1.1",
    "sugar": "11.6",
    "protein": "6.7",
    "cal-from-protein": "27",
    "per-cal-from-protein": "10",
    "potassium": "119",
    "calcium": "55",
    "iron": "1.1",
    "phosphorus": "109",
    "source": "N Buddy",
    "old units (ref)": "Egg Tart (large)"
  },
  {
    "id": "1334",
    "type": "meal",
    "|| name                                               ||": "Rasam",
    "rating": "2",
    "high-iron": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "420",
    "calories": "168",
    "fat": "9.2",
    "cal-from-fat": "82.8",
    "per-cal-from-fat": "49",
    "saturated": "4.2",
    "polyunsaturated": "0.8",
    "monounsaturated": "3.4",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "3725",
    "carbs": "17.2",
    "fibre": "0",
    "sugar": "1",
    "protein": "4.2",
    "cal-from-protein": "17",
    "per-cal-from-protein": "10",
    "potassium": "0",
    "calcium": "67",
    "iron": "3.8",
    "phosphorus": "0",
    "source": "N Buddy",
    "old units (ref)": "Rasam, spicy indian soup"
  },
  {
    "id": "1335",
    "type": "meal",
    "|| name                                               ||": "Curry, egg",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "236",
    "calories": "425",
    "fat": "36.3",
    "cal-from-fat": "326.7",
    "per-cal-from-fat": "77",
    "saturated": "14.8",
    "polyunsaturated": "5.7",
    "monounsaturated": "10.1",
    "cholesterol": "354.8",
    "carbs": "10",
    "fibre": "1.9",
    "sugar": "7.1",
    "protein": "14.5",
    "cal-from-protein": "58",
    "per-cal-from-protein": "14",
    "source": "Carb Manager",
    "old units (ref)": "Egg Curry"
  },
  {
    "id": "1336",
    "type": "meal",
    "|| name                                               ||": "Millet, whole",
    "rating": "3",
    "high-phosphate": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "174",
    "calories": "207",
    "fat": "1.7",
    "cal-from-fat": "15.3",
    "per-cal-from-fat": "7",
    "saturated": "0.3",
    "polyunsaturated": "0.9",
    "monounsaturated": "0.3",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "3",
    "carbs": "41.2",
    "fibre": "2.3",
    "sugar": "0.2",
    "protein": "6.1",
    "cal-from-protein": "24",
    "per-cal-from-protein": "12",
    "potassium": "108",
    "calcium": "5",
    "iron": "1.1",
    "phosphorus": "174",
    "source": "N Buddy",
    "old units (ref)": "Millet, cooked"
  },
  {
    "id": "1337",
    "type": "meal",
    "|| name                                               ||": "Cocoa powder, unsweetened",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "tbsp",
    "alternate-spellings": "Cocoa: Cacao",
    "weight-g": "7",
    "calories": "22",
    "fat": "1.5",
    "cal-from-fat": "13.5",
    "per-cal-from-fat": "61",
    "saturated": "0.9",
    "polyunsaturated": "0",
    "monounsaturated": "0.5",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "67",
    "carbs": "0.8",
    "fibre": "0.3",
    "sugar": "0.8",
    "protein": "1.3",
    "cal-from-protein": "5",
    "per-cal-from-protein": "23",
    "potassium": "105",
    "calcium": "9",
    "iron": "0.7",
    "phosphorus": "46",
    "source": "N Buddy",
    "old units (ref)": "Cocoa Powder, unsweetened"
  },
  {
    "id": "1338",
    "type": "meal",
    "|| name                                               ||": "Nutritional yeast",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "5.3",
    "calories": "20",
    "fat": "0.2",
    "cal-from-fat": "1.8",
    "per-cal-from-fat": "9",
    "saturated": "0.1",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "10",
    "carbs": "1.6",
    "fibre": "1",
    "sugar": "0",
    "protein": "2.6",
    "cal-from-protein": "10",
    "per-cal-from-protein": "50",
    "potassium": "114",
    "calcium": "1.9",
    "iron": "0.3",
    "source": "USDA",
    "old units (ref)": "NUTRITIONAL YEAST"
  },
  {
    "id": "1339",
    "type": "meal",
    "|| name                                               ||": "Arugula, raw",
    "rating": "3",
    "high-calcium": "1",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "info-translation": "Rocket leaves",
    "weight-g": "80",
    "calories": "12",
    "fat": "0.4",
    "cal-from-fat": "3.6",
    "per-cal-from-fat": "30",
    "saturated": "0",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.2",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "22",
    "carbs": "2",
    "fibre": "1",
    "sugar": "1.4",
    "protein": "0.6",
    "cal-from-protein": "2",
    "per-cal-from-protein": "17",
    "potassium": "296",
    "calcium": "128",
    "iron": "1.2",
    "phosphorus": "30",
    "source": "N Buddy",
    "old units (ref)": "Rocket Leaves (Arugula) raw"
  },
  {
    "id": "1340",
    "type": "snack",
    "|| name                                               ||": "Gelato",
    "rating": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "scoop",
    "info-include-exclude": "assorted flavours",
    "weight-g": "69",
    "calories": "126",
    "fat": "5.1",
    "cal-from-fat": "45.9",
    "per-cal-from-fat": "36",
    "saturated": "2.6",
    "polyunsaturated": "0.1",
    "monounsaturated": "1.9",
    "trans": "0.3",
    "cholesterol": "8",
    "sodium": "39",
    "carbs": "17.6",
    "fibre": "0.1",
    "sugar": "15.7",
    "protein": "3.2",
    "cal-from-protein": "13",
    "per-cal-from-protein": "10",
    "potassium": "106",
    "calcium": "61",
    "iron": "0.1",
    "phosphorus": "58",
    "source": "N Buddy",
    "old units (ref)": "Gelato, assorted flavour"
  },
  {
    "id": "1341",
    "type": "meal",
    "|| name                                               ||": "Upma, broken wheat",
    "rating": "2",
    "high-potassium": "1",
    "high-salt": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "155",
    "calories": "321",
    "fat": "8.4",
    "cal-from-fat": "75.6",
    "per-cal-from-fat": "24",
    "saturated": "0.1",
    "polyunsaturated": "5.8",
    "monounsaturated": "2.5",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "250",
    "carbs": "54",
    "fibre": "4.2",
    "sugar": "0.2",
    "protein": "7.2",
    "cal-from-protein": "29",
    "per-cal-from-protein": "9",
    "potassium": "223",
    "calcium": "0",
    "iron": "0",
    "phosphorus": "0",
    "source": "N Buddy",
    "old units (ref)": "Cracked Wheat Upma, Broken Wheat"
  },
  {
    "id": "1342",
    "type": "meal",
    "|| name                                               ||": "Ravioli, cheese filled",
    "rating": "2",
    "high-fat": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "info-include-exclude": "with cream sauce",
    "weight-g": "38",
    "calories": "60.4",
    "fat": "2.9",
    "cal-from-fat": "26.1",
    "per-cal-from-fat": "43",
    "saturated": "1.2",
    "polyunsaturated": "0.5",
    "monounsaturated": "1",
    "cholesterol": "23.9",
    "sodium": "181",
    "carbs": "6.1",
    "fibre": "0.2",
    "sugar": "0.7",
    "protein": "2.3",
    "cal-from-protein": "9",
    "per-cal-from-protein": "15",
    "potassium": "50.9",
    "calcium": "40.7",
    "iron": "0.4",
    "phosphorus": "43.3",
    "source": "USDA",
    "old units (ref)": "Ravioli, cheese-filled, with cream sauce"
  },
  {
    "id": "1343",
    "type": "meal",
    "|| name                                               ||": "Spam musubi",
    "rating": "1",
    "high-salt": "1",
    "processed": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "61",
    "calories": "103",
    "fat": "2.9",
    "cal-from-fat": "26.1",
    "per-cal-from-fat": "25",
    "saturated": "1.1",
    "trans": "0",
    "cholesterol": "7.5",
    "sodium": "258",
    "carbs": "16",
    "fibre": "0.2",
    "sugar": "2.4",
    "protein": "2.8",
    "cal-from-protein": "11",
    "per-cal-from-protein": "11",
    "potassium": "69",
    "old units (ref)": "https://www.nutritionix.com/i/nutritionix/spam-musubi-1-piece/58667a4ac8683de25b213381"
  },
  {
    "id": "1344",
    "type": "snack",
    "|| name                                               ||": "Dairy free ice cream",
    "rating": "1",
    "high-fat": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "info-brands": "Magnum",
    "weight-g": "72",
    "calories": "178.6",
    "fat": "15.9",
    "cal-from-fat": "143.1",
    "per-cal-from-fat": "80",
    "saturated": "9.8",
    "sodium": "28.8",
    "carbs": "23.3",
    "sugar": "18.9",
    "protein": "1.9",
    "cal-from-protein": "8",
    "per-cal-from-protein": "4",
    "source": "https://www.magnumicecream.com/sg/products/dairy-free/magnum-dairy-free-almond-3-x-270ml.html",
    "old units (ref)": "Magnum Dairy-Free Almond"
  },
  {
    "id": "1345",
    "type": "drink",
    "|| name                                               ||": "Glucerna Plus 1.5 Kcal (liquid)",
    "rating": "3",
    "healthy-fats": "1",
    "high-calcium": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "bottle",
    "volume-ml": "220",
    "calories": "333",
    "fat": "16.5",
    "cal-from-fat": "148.5",
    "per-cal-from-fat": "45",
    "saturated": "1.2",
    "monounsaturated": "11.2",
    "sodium": "308",
    "carbs": "28.1",
    "fibre": "3.3",
    "sugar": "15",
    "protein": "16.5",
    "cal-from-protein": "66",
    "per-cal-from-protein": "20",
    "potassium": "363",
    "calcium": "220",
    "iron": "1.9",
    "phosphorus": "220",
    "source": "https://abbottfamily.com.sg/products/page/glucerna-plus",
    "old units (ref)": "GLUCERNA PLUS 1.5KCAL"
  },
  {
    "id": "1346",
    "type": "meal",
    "|| name                                               ||": "Crab cake",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "serving": "1",
    "|| unit ||": "cake",
    "weight-g": "95.3",
    "calories": "260",
    "fat": "15.8",
    "cal-from-fat": "142.2",
    "per-cal-from-fat": "55",
    "saturated": "4",
    "polyunsaturated": "3.3",
    "monounsaturated": "7.4",
    "cholesterol": "148.2",
    "carbs": "12.3",
    "fibre": "0.9",
    "sugar": "1.8",
    "protein": "15.7",
    "cal-from-protein": "63",
    "per-cal-from-protein": "24",
    "source": "Carb Manager",
    "old units (ref)": "Crab cake or patty"
  },
  {
    "id": "1347",
    "type": "snack",
    "|| name                                               ||": "Keto bread",
    "rating": "2",
    "high-fat": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "32",
    "calories": "110",
    "fat": "6",
    "cal-from-fat": "54",
    "per-cal-from-fat": "49",
    "saturated": "0.5",
    "trans": "0",
    "cholesterol": "25",
    "sodium": "180",
    "carbs": "4",
    "fibre": "4",
    "sugar": "0",
    "protein": "4",
    "cal-from-protein": "16",
    "per-cal-from-protein": "15",
    "potassium": "120",
    "calcium": "49.9",
    "iron": "0.7",
    "source": "USDA",
    "old units (ref)": "ORIGINAL KETO BREAD, ORIGINAL KETO"
  },
  {
    "id": "1348",
    "type": "meal",
    "|| name                                               ||": "Tuna ceviche",
    "rating": "2",
    "healthy-fats": "1",
    "high-iodine": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "weight-g": "100",
    "calories": "162",
    "fat": "3.7",
    "cal-from-fat": "33.3",
    "per-cal-from-fat": "21",
    "saturated": "0.5",
    "polyunsaturated": "0.5",
    "monounsaturated": "2.2",
    "cholesterol": "50.6",
    "carbs": "8.9",
    "fibre": "3.5",
    "sugar": "2",
    "protein": "23.4",
    "cal-from-protein": "94",
    "per-cal-from-protein": "58",
    "source": "Carb Manager",
    "old units (ref)": "Tuna Ceviche"
  },
  {
    "id": "1349",
    "type": "meal",
    "|| name                                               ||": "Parsnip, boiled",
    "rating": "3",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "160",
    "calories": "146",
    "fat": "4.5",
    "cal-from-fat": "40.5",
    "per-cal-from-fat": "28",
    "saturated": "1.2",
    "polyunsaturated": "1.3",
    "monounsaturated": "1.7",
    "cholesterol": "3.2",
    "sodium": "16",
    "carbs": "20.8",
    "fibre": "5.6",
    "sugar": "7.4",
    "protein": "2.1",
    "cal-from-protein": "8",
    "per-cal-from-protein": "5",
    "potassium": "570",
    "calcium": "57.6",
    "iron": "0.9",
    "phosphorus": "107",
    "source": "USDA",
    "old units (ref)": "Parsnips, cooked"
  },
  {
    "id": "1350",
    "type": "snack",
    "|| name                                               ||": "Sesame seeds",
    "rating": "3",
    "healthy-fats": "1",
    "high-calcium": "1",
    "high-fat": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "14",
    "calories": "80",
    "fat": "6.8",
    "cal-from-fat": "61.2",
    "per-cal-from-fat": "77",
    "saturated": "1",
    "polyunsaturated": "3",
    "monounsaturated": "2.6",
    "cholesterol": "0",
    "sodium": "1.6",
    "carbs": "1.7",
    "fibre": "2",
    "protein": "2.4",
    "cal-from-protein": "10",
    "per-cal-from-protein": "13",
    "potassium": "67.5",
    "calcium": "140",
    "iron": "2.1",
    "phosphorus": "90.5",
    "source": "USDA",
    "old units (ref)": "Seeds, sesame seeds, whole, roasted and toasted"
  },
  {
    "id": "1351",
    "type": "snack",
    "|| name                                               ||": "Sesame powder, unsweetened",
    "rating": "3",
    "healthy-fats": "1",
    "high-calcium": "1",
    "high-fat": "1",
    "high-iron": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "10",
    "calories": "62",
    "fat": "5.2",
    "cal-from-fat": "46.8",
    "per-cal-from-fat": "75",
    "saturated": "0.8",
    "polyunsaturated": "2.4",
    "monounsaturated": "2",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "1",
    "carbs": "1.6",
    "fibre": "1.3",
    "sugar": "0",
    "protein": "2.2",
    "cal-from-protein": "9",
    "per-cal-from-protein": "15",
    "potassium": "53",
    "calcium": "141",
    "iron": "2.5",
    "old units (ref)": "https://www.fairprice.com.sg/product/biogreen-100-pure-black-sesame-powder-300-g-90060102"
  },
  {
    "id": "1352",
    "type": "snack",
    "|| name                                               ||": "Protein ball",
    "rating": "3",
    "high-fat": "1",
    "high-fibre": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "ball",
    "info-include-exclude": "with dates and nuts",
    "weight-g": "40",
    "calories": "160",
    "fat": "7",
    "cal-from-fat": "63",
    "per-cal-from-fat": "39",
    "saturated": "1.5",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "84.8",
    "carbs": "18",
    "fibre": "5",
    "sugar": "9",
    "protein": "5",
    "cal-from-protein": "20",
    "per-cal-from-protein": "13",
    "calcium": "20",
    "iron": "1.1",
    "source": "USDA",
    "old units (ref)": "PUMPKIN SPICE WITH PROTEIN, PUMPKIN, CASHEWS & DATES NUTS ENERGY BALLS, PUMPKIN SPICE WITH PROTEIN, PUMPKIN, CASHEWS & DATES"
  },
  {
    "id": "1353",
    "type": "snack",
    "|| name                                               ||": "Cacao nibs",
    "rating": "3",
    "high-fat": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "alternate-spellings": "Cacao: Cocoa",
    "weight-g": "9.3",
    "calories": "63",
    "fat": "5",
    "cal-from-fat": "45",
    "per-cal-from-fat": "71",
    "saturated": "3",
    "cholesterol": "0",
    "carbs": "3.7",
    "fibre": "2.7",
    "sugar": "0",
    "protein": "1.3",
    "cal-from-protein": "5",
    "per-cal-from-protein": "8",
    "source": "Carbs Manager",
    "old units (ref)": "CACAO NIBS"
  },
  {
    "id": "1354",
    "type": "snack",
    "|| name                                               ||": "Carrot cake, with icing",
    "rating": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "133",
    "calories": "571",
    "fat": "35.5",
    "cal-from-fat": "319.5",
    "per-cal-from-fat": "56",
    "saturated": "11.6",
    "polyunsaturated": "10.7",
    "monounsaturated": "11",
    "cholesterol": "75.8",
    "sodium": "310",
    "carbs": "59.1",
    "fibre": "1.7",
    "sugar": "45.2",
    "protein": "5.4",
    "cal-from-protein": "22",
    "per-cal-from-protein": "4",
    "potassium": "162",
    "calcium": "43.9",
    "iron": "1.3",
    "phosphorus": "91.8",
    "source": "USDA",
    "old units (ref)": "Cake or cupcake, carrot, with icing or filling"
  },
  {
    "id": "1355",
    "type": "drink",
    "|| name                                               ||": "Optifast",
    "rating": "3",
    "high-calcium": "1",
    "high-iodine": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "info-translation": "Meal replacement",
    "info-portion-aid": "standard dilution (1 pouch with water)",
    "weight-g": "53",
    "calories": "201",
    "fat": "4.5",
    "cal-from-fat": "40.5",
    "per-cal-from-fat": "20",
    "saturated": "0.9",
    "sodium": "215",
    "carbs": "18.2",
    "fibre": "3.6",
    "sugar": "10",
    "protein": "20",
    "cal-from-protein": "80",
    "per-cal-from-protein": "40",
    "potassium": "955",
    "calcium": "420",
    "iron": "8",
    "phosphorus": "360",
    "source": "https://www.nestlehealthscience.sg/brands/optifast/optifast-shake",
    "old units (ref)": "OPTIFAST VLCD SHAKE"
  },
  {
    "id": "1356",
    "type": "meal",
    "|| name                                               ||": "Saba Shioyaki",
    "rating": "2",
    "healthy-fats": "1",
    "high-fat": "1",
    "high-iodine": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "serve",
    "info-translation": "Japanese grilled mackerel",
    "weight-g": "100",
    "calories": "200",
    "fat": "11",
    "cal-from-fat": "99",
    "per-cal-from-fat": "50",
    "saturated": "2.4",
    "polyunsaturated": "1.2",
    "monounsaturated": "7.1",
    "trans": "0",
    "cholesterol": "59",
    "sodium": "271",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "26",
    "cal-from-protein": "104",
    "per-cal-from-protein": "52",
    "potassium": "435",
    "calcium": "24",
    "iron": "1.5",
    "source": "USDA",
    "old units (ref)": "SKINLESS & BONELESS GRILLED MACKEREL IN 100% OLIVE OIL"
  },
  {
    "id": "1357",
    "type": "meal",
    "|| name                                               ||": "Artichoke",
    "rating": "3",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "vegetable": "1",
    "serving": "1",
    "|| unit ||": "whole",
    "weight-g": "120",
    "calories": "63.6",
    "fat": "0.4",
    "cal-from-fat": "3.6",
    "per-cal-from-fat": "6",
    "saturated": "0.1",
    "polyunsaturated": "0.2",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "72",
    "carbs": "7.6",
    "fibre": "6.8",
    "sugar": "1.2",
    "protein": "3.5",
    "cal-from-protein": "14",
    "per-cal-from-protein": "22",
    "potassium": "343",
    "calcium": "25.2",
    "iron": "0.7",
    "phosphorus": "87.6",
    "source": "USDA",
    "old units (ref)": "https://fdc.nal.usda.gov/fdc-app.html#/food-details/168386/nutrients"
  },
  {
    "id": "1358",
    "type": "drink",
    "|| name                                               ||": "Ginger lemon tea, sweetened",
    "rating": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "sachet",
    "weight-g": "18",
    "calories": "70",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "17",
    "fibre": "0",
    "sugar": "16",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "23",
    "calcium": "2",
    "iron": "0",
    "old units (ref)": "https://ml.iherb.com/pr/prince-of-peace-original-ginger-honey-crystals-10-sachets-18-g-each/6004?gclid=Cj0KCQjw_viWBhD8ARIsAH1mCd7PWhy7Wa3iAYMslZmfbaQqgCeLeGHPLhTxDxZtvTRoMpZV-9rIvx4aAtymEALw_wcB&gclsrc=aw.ds"
  },
  {
    "id": "1359",
    "type": "drink",
    "|| name                                               ||": "Ginger lemon tea, unsweetened",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "sachet",
    "weight-g": "3",
    "calories": "0",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "sodium": "5",
    "carbs": "0",
    "sugar": "0",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "50",
    "iron": "0.3",
    "old units (ref)": "https://www.fairprice.com.sg/product/gold-kili-natural-ginger-bag-with-lemon-20-x-3g-13183786?gclid=Cj0KCQjwz96WBhC8ARIsAATR251OXdFN7pAgKFQCIk8F1Yc0amlKMpLXEX3yuO32Wd9ZkwGnjkzzsBAaAjBwEALw_wcB"
  },
  {
    "id": "1360",
    "type": "snack",
    "|| name                                               ||": "Digestive biscuit, plain",
    "rating": "2",
    "high-fat": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "10",
    "calories": "48.8",
    "fat": "2.1",
    "cal-from-fat": "18.9",
    "per-cal-from-fat": "39",
    "saturated": "1",
    "cholesterol": "0.2",
    "sodium": "61.1",
    "carbs": "6.9",
    "fibre": "0.4",
    "protein": "0.6",
    "cal-from-protein": "2",
    "per-cal-from-protein": "4",
    "source": "HPB",
    "old units (ref)": "Digestive biscuit"
  },
  {
    "id": "1361",
    "type": "snack",
    "|| name                                               ||": "Digestive biscuit, chocolate coated",
    "rating": "1",
    "high-fat": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "14.3",
    "calories": "70",
    "fat": "3.4",
    "cal-from-fat": "30.6",
    "per-cal-from-fat": "44",
    "saturated": "1.7",
    "sodium": "53",
    "carbs": "9",
    "fibre": "0.4",
    "sugar": "4",
    "protein": "0.9",
    "cal-from-protein": "4",
    "per-cal-from-protein": "6",
    "old units (ref)": "https://www.fairprice.com.sg/product/mcvities-digestive-biscuits-milk-chocolate-300g-12562721"
  },
  {
    "id": "1362",
    "type": "snack",
    "|| name                                               ||": "Pretzel, with chocolate",
    "rating": "1",
    "high-fat": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "12",
    "calories": "55",
    "fat": "2.2",
    "cal-from-fat": "19.8",
    "per-cal-from-fat": "36",
    "saturated": "1.3",
    "polyunsaturated": "0.2",
    "monounsaturated": "0.6",
    "cholesterol": "1.6",
    "carbs": "7.9",
    "fibre": "0.4",
    "sugar": "3.6",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "7",
    "source": "Carbs Manager",
    "old units (ref)": "Pretzels, chocolate covered"
  },
  {
    "id": "1363",
    "type": "meal",
    "|| name                                               ||": "Protein pancake",
    "rating": "2",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "50",
    "calories": "170",
    "fat": "13.5",
    "cal-from-fat": "2.7",
    "per-cal-from-fat": "2",
    "saturated": "0.5",
    "trans": "0",
    "cholesterol": "10",
    "sodium": "350",
    "carbs": "23",
    "fibre": "4",
    "sugar": "3",
    "protein": "16",
    "cal-from-protein": "64",
    "per-cal-from-protein": "38",
    "potassium": "243",
    "calcium": "117",
    "iron": "2",
    "old units (ref)": "https://ml.iherb.com/pr/bob-s-red-mill-protein-pancake-waffle-mix-whole-grain-14-oz-397-g/78662?gclid=Cj0KCQjw_viWBhD8ARIsAH1mCd4-3UGhTgCrNMAKnH5XZf6V-6uOgQInV3TDtyb5YvL6z7LX4gpijHcaAqvhEALw_wcB&gclsrc=aw.ds"
  },
  {
    "id": "1364",
    "type": "drink",
    "|| name                                               ||": "Lemon tea, sweetened",
    "rating": "1",
    "high-gi": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "300",
    "calories": "65",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "15.3",
    "fibre": "0.4",
    "sugar": "15.3",
    "protein": "0.4",
    "cal-from-protein": "2",
    "per-cal-from-protein": "3",
    "potassium": "63",
    "calcium": "3",
    "iron": "0.1",
    "phosphorus": "6",
    "source": "N Buddy",
    "old units (ref)": "Homemade Iced Lemon tea, regular sweetened"
  },
  {
    "id": "1365",
    "type": "drink",
    "|| name                                               ||": "Lemon tea, reduced sugar",
    "rating": "2",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "300",
    "calories": "33",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "7.3",
    "fibre": "0.4",
    "sugar": "7.3",
    "protein": "0.3",
    "cal-from-protein": "1",
    "per-cal-from-protein": "3",
    "potassium": "52",
    "calcium": "3",
    "iron": "0.1",
    "phosphorus": "5",
    "source": "N Buddy",
    "old units (ref)": "Homemade iced lemon tea, less sugar"
  },
  {
    "id": "1366",
    "type": "drink",
    "|| name                                               ||": "Lemon tea, unsweetened",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "300",
    "calories": "5",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "0.6",
    "fibre": "0.3",
    "sugar": "0.3",
    "protein": "0.4",
    "cal-from-protein": "2",
    "per-cal-from-protein": "40",
    "potassium": "63",
    "calcium": "3",
    "iron": "0.1",
    "phosphorus": "6",
    "source": "N Buddy",
    "old units (ref)": "Homemade iced lemon tea, without sugar"
  },
  {
    "id": "1367",
    "type": "meal",
    "|| name                                               ||": "Meatball",
    "rating": "2",
    "high-fat": "1",
    "high-phosphate": "1",
    "processed": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "36",
    "calories": "96",
    "fat": "7.1",
    "cal-from-fat": "63.9",
    "per-cal-from-fat": "67",
    "saturated": "2.5",
    "polyunsaturated": "1.4",
    "monounsaturated": "3",
    "trans": "0",
    "cholesterol": "29",
    "sodium": "127",
    "carbs": "2.6",
    "fibre": "0.1",
    "sugar": "0.5",
    "protein": "5.5",
    "cal-from-protein": "22",
    "per-cal-from-protein": "23",
    "potassium": "82",
    "calcium": "35",
    "iron": "0.4",
    "phosphorus": "51",
    "source": "N Buddy",
    "old units (ref)": "Fried Pork Meatball"
  },
  {
    "id": "1368",
    "type": "snack",
    "|| name                                               ||": "Pumpkin seeds, plain",
    "rating": "3",
    "healthy-fats": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "14.2",
    "calories": "79",
    "fat": "7",
    "cal-from-fat": "63",
    "per-cal-from-fat": "80",
    "saturated": "1.2",
    "polyunsaturated": "3",
    "monounsaturated": "2.3",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "1",
    "carbs": "0.7",
    "fibre": "0.9",
    "sugar": "0.2",
    "protein": "4.3",
    "cal-from-protein": "17",
    "per-cal-from-protein": "22",
    "potassium": "114.5",
    "calcium": "6.5",
    "iron": "1.3",
    "phosphorus": "174.5",
    "source": "USDA",
    "old units (ref)": "https://fdc.nal.usda.gov/fdc-app.html#/food-details/170556/nutrients"
  },
  {
    "id": "1369",
    "type": "snack",
    "|| name                                               ||": "Sunflower seeds, plain",
    "rating": "3",
    "healthy-fats": "1",
    "high-fat": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "12",
    "calories": "68.8",
    "fat": "6.1",
    "cal-from-fat": "54.9",
    "per-cal-from-fat": "80",
    "saturated": "0.5",
    "sodium": "0",
    "carbs": "0.3",
    "fibre": "1.3",
    "protein": "2.7",
    "cal-from-protein": "11",
    "per-cal-from-protein": "16",
    "source": "HPB",
    "old units (ref)": "Sunflower seed"
  },
  {
    "id": "1370",
    "type": "snack",
    "|| name                                               ||": "Almonds, plain",
    "rating": "3",
    "healthy-fats": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "plant-protein": "1",
    "serving": "0.25",
    "|| unit ||": "cup",
    "weight-g": "35.8",
    "calories": "207",
    "fat": "17.9",
    "cal-from-fat": "161.1",
    "per-cal-from-fat": "78",
    "saturated": "1.4",
    "polyunsaturated": "4.4",
    "monounsaturated": "11.3",
    "cholesterol": "0",
    "sodium": "0.4",
    "carbs": "3.3",
    "fibre": "4.5",
    "sugar": "1.6",
    "protein": "7.6",
    "cal-from-protein": "30",
    "per-cal-from-protein": "14",
    "potassium": "262.5",
    "calcium": "96.3",
    "iron": "1.3",
    "phosphorus": "172",
    "source": "USDA",
    "old units (ref)": "https://fdc.nal.usda.gov/fdc-app.html#/food-details/170567/nutrients"
  },
  {
    "id": "1371",
    "type": "snack",
    "|| name                                               ||": "Cashews,plain",
    "rating": "3",
    "healthy-fats": "1",
    "high-fat": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "plant-protein": "1",
    "serving": "0.25",
    "|| unit ||": "cup",
    "weight-g": "34.3",
    "calories": "196.5",
    "fat": "15.9",
    "cal-from-fat": "143.1",
    "per-cal-from-fat": "73",
    "saturated": "3.1",
    "polyunsaturated": "2.7",
    "monounsaturated": "9.4",
    "cholesterol": "0",
    "sodium": "5.5",
    "carbs": "10.2",
    "fibre": "1",
    "sugar": "1.7",
    "protein": "5.3",
    "cal-from-protein": "21",
    "per-cal-from-protein": "11",
    "potassium": "193.5",
    "calcium": "15.4",
    "iron": "2.1",
    "phosphorus": "167.8",
    "source": "USDA",
    "old units (ref)": "https://fdc.nal.usda.gov/fdc-app.html#/food-details/170571/nutrients"
  },
  {
    "id": "1372",
    "type": "snack",
    "|| name                                               ||": "Walnuts, plain",
    "rating": "3",
    "healthy-fats": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "serving": "0.25",
    "|| unit ||": "cup",
    "weight-g": "30",
    "calories": "196.3",
    "fat": "19.6",
    "cal-from-fat": "176.4",
    "per-cal-from-fat": "90",
    "saturated": "1.8",
    "polyunsaturated": "14.2",
    "monounsaturated": "2.7",
    "cholesterol": "0",
    "sodium": "0.6",
    "carbs": "2.1",
    "fibre": "2",
    "sugar": "0.8",
    "protein": "4.6",
    "cal-from-protein": "18",
    "per-cal-from-protein": "9",
    "potassium": "132.3",
    "calcium": "29.5",
    "iron": "0.9",
    "phosphorus": "103.8",
    "source": "USDA",
    "old units (ref)": "https://fdc.nal.usda.gov/fdc-app.html#/food-details/170187/nutrients"
  },
  {
    "id": "1373",
    "type": "snack",
    "|| name                                               ||": "Pistachios, plain",
    "rating": "3",
    "healthy-fats": "1",
    "high-fat": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "plant-protein": "1",
    "serving": "0.25",
    "|| unit ||": "cup",
    "weight-g": "30.8",
    "calories": "172.3",
    "fat": "13.9",
    "cal-from-fat": "125.1",
    "per-cal-from-fat": "73",
    "saturated": "1.8",
    "polyunsaturated": "4.4",
    "monounsaturated": "7.2",
    "cholesterol": "0",
    "sodium": "0.3",
    "carbs": "5.1",
    "fibre": "3.3",
    "sugar": "2.4",
    "protein": "6.2",
    "cal-from-protein": "25",
    "per-cal-from-protein": "15",
    "potassium": "312.5",
    "calcium": "32.3",
    "iron": "1.2",
    "phosphorus": "150.8",
    "source": "USDA",
    "old units (ref)": "https://fdc.nal.usda.gov/fdc-app.html#/food-details/170184/nutrients"
  },
  {
    "id": "1374",
    "type": "meal",
    "|| name                                               ||": "Salted egg",
    "rating": "1",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "whole",
    "weight-g": "67",
    "calories": "111",
    "fat": "7.4",
    "cal-from-fat": "66.6",
    "per-cal-from-fat": "60",
    "saturated": "2.6",
    "polyunsaturated": "0.8",
    "monounsaturated": "3.9",
    "trans": "0",
    "cholesterol": "377",
    "sodium": "1577",
    "carbs": "3.7",
    "fibre": "0",
    "sugar": "0",
    "protein": "7.4",
    "cal-from-protein": "30",
    "per-cal-from-protein": "27",
    "potassium": "107",
    "calcium": "69",
    "iron": "2.1",
    "phosphorus": "135",
    "source": "N Buddy",
    "old units (ref)": "Salted Egg"
  },
  {
    "id": "1375",
    "type": "meal",
    "|| name                                               ||": "Alchemy fibre",
    "rating": "3",
    "high-fibre": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "5",
    "calories": "2.3",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "0.9",
    "carbs": "0.6",
    "fibre": "4.2",
    "sugar": "0.4",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "old units (ref)": "https://alchemyfoodtech.com/product/alchemy-fibre-700g/"
  },
  {
    "id": "1376",
    "type": "meal",
    "|| name                                               ||": "Bean curd skin, fried",
    "rating": "1",
    "fried": "1",
    "high-fat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "info-translation": "Taukee",
    "weight-g": "8",
    "calories": "57.4",
    "fat": "5.4",
    "cal-from-fat": "48.6",
    "per-cal-from-fat": "85",
    "saturated": "2.2",
    "cholesterol": "0",
    "sodium": "11",
    "carbs": "0.2",
    "fibre": "0.1",
    "protein": "2",
    "cal-from-protein": "8",
    "per-cal-from-protein": "14",
    "source": "HPB",
    "old units (ref)": "Yong tau foo, beancurd skin, deep fried"
  },
  {
    "id": "1377",
    "type": "meal",
    "|| name                                               ||": "Pork floss",
    "rating": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "11",
    "calories": "44",
    "fat": "0.8",
    "cal-from-fat": "7.2",
    "per-cal-from-fat": "16",
    "saturated": "0.3",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.4",
    "trans": "0",
    "cholesterol": "8",
    "sodium": "206.7",
    "carbs": "5.5",
    "fibre": "0",
    "sugar": "5.5",
    "protein": "3.6",
    "cal-from-protein": "14",
    "per-cal-from-protein": "32",
    "potassium": "22.7",
    "calcium": "5.7",
    "iron": "0.4",
    "phosphorus": "22.7",
    "source": "N Buddy",
    "old units (ref)": "Pork Floss, regular"
  },
  {
    "id": "1378",
    "type": "meal",
    "|| name                                               ||": "Up and Go Liquid Breakfast",
    "rating": "3",
    "high-calcium": "1",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "pack",
    "volume-ml": "250",
    "calories": "195",
    "fat": "4.3",
    "cal-from-fat": "38.7",
    "per-cal-from-fat": "20",
    "saturated": "0.6",
    "sodium": "158",
    "carbs": "28.4",
    "fibre": "4",
    "sugar": "15.8",
    "protein": "8.3",
    "cal-from-protein": "33",
    "per-cal-from-protein": "17",
    "potassium": "450",
    "calcium": "300",
    "phosphorus": "250",
    "old units (ref)": "https://www.woolworths.com.au/shop/productdetails/201407/sanitarium-up-go-liquid-breakfast-vanilla-ice"
  },
  {
    "id": "1379",
    "type": "meal",
    "|| name                                               ||": "Up and Go Protein Energize",
    "rating": "3",
    "high-calcium": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "pack",
    "volume-ml": "250",
    "calories": "217",
    "fat": "4.5",
    "cal-from-fat": "40.5",
    "per-cal-from-fat": "19",
    "saturated": "0.6",
    "sodium": "226",
    "carbs": "25",
    "fibre": "2.4",
    "sugar": "16",
    "protein": "17.5",
    "cal-from-protein": "70",
    "per-cal-from-protein": "32",
    "potassium": "500",
    "calcium": "300",
    "phosphorus": "250",
    "old units (ref)": "https://www.woolworths.com.au/shop/productdetails/750079/sanitarium-up-go-protein-energize-vanilla"
  },
  {
    "id": "1380",
    "type": "meal",
    "|| name                                               ||": "Up and Go Liquid Breakfast No Added Sugar",
    "rating": "3",
    "high-calcium": "1",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "pack",
    "volume-ml": "250",
    "calories": "137",
    "fat": "3.7",
    "cal-from-fat": "33.3",
    "per-cal-from-fat": "24",
    "saturated": "0.6",
    "sodium": "171",
    "carbs": "15.5",
    "fibre": "4",
    "sugar": "10.5",
    "protein": "8.1",
    "cal-from-protein": "32",
    "per-cal-from-protein": "23",
    "potassium": "644",
    "calcium": "300",
    "phosphorus": "250",
    "old units (ref)": "https://www.woolworths.com.au/shop/productdetails/77956/sanitarium-up-go-liquid-breakfast-no-added-sugar-vanilla-ice"
  },
  {
    "id": "1381",
    "type": "snack",
    "|| name                                               ||": "Biscuit with chocolate coating",
    "rating": "1",
    "high-fat": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "info-brands": "Tim Tam",
    "weight-g": "18.3",
    "calories": "95.4",
    "fat": "4.9",
    "cal-from-fat": "44.1",
    "per-cal-from-fat": "46",
    "saturated": "2.7",
    "sodium": "29",
    "carbs": "11.8",
    "fibre": "0.3",
    "sugar": "8.2",
    "protein": "0.8",
    "cal-from-protein": "3",
    "per-cal-from-protein": "3",
    "old units (ref)": "https://www.fairprice.com.sg/product/arnotts-tim-tam-biscuits-original-200g-11496398"
  },
  {
    "id": "1382",
    "type": "drink",
    "|| name                                               ||": "Soy milk, sweetened (Healthier Choice Symbol, high calcium)",
    "rating": "2",
    "high-calcium": "1",
    "high-sugar": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "glass",
    "info-brands": "Nutrisoy",
    "info-include-exclude": "with sugar",
    "alternate-spellings": "Soy: Soya",
    "volume-ml": "250",
    "calories": "148",
    "fat": "3",
    "cal-from-fat": "27",
    "per-cal-from-fat": "18",
    "saturated": "0.5",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "15",
    "carbs": "19.8",
    "fibre": "1.8",
    "sugar": "16.8",
    "protein": "10.5",
    "cal-from-protein": "42",
    "per-cal-from-protein": "28",
    "calcium": "500",
    "old units (ref)": "https://www.fairprice.com.sg/product/f-n-nutrisoy-fresh-soya-milk-high-calcium-1lt-457912"
  },
  {
    "id": "1383",
    "type": "drink",
    "|| name                                               ||": "Soy milk, unsweetened (Healthier Choice Symbol, high calcium)",
    "rating": "3",
    "high-calcium": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "glass",
    "info-brands": "Nutrisoy",
    "info-include-exclude": "without sugar",
    "alternate-spellings": "Soy: Soya",
    "volume-ml": "250",
    "calories": "105",
    "fat": "5",
    "cal-from-fat": "45",
    "per-cal-from-fat": "43",
    "saturated": "1.3",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "8",
    "carbs": "4.5",
    "fibre": "0.3",
    "sugar": "2",
    "protein": "10",
    "cal-from-protein": "40",
    "per-cal-from-protein": "38",
    "calcium": "500",
    "old units (ref)": "https://www.nutrisoy.com.sg/soyproduct/original-no-sugar-added-high-calcium-fresh-soya-milk/"
  },
  {
    "id": "1384",
    "type": "meal",
    "|| name                                               ||": "Puri",
    "rating": "1",
    "fried": "1",
    "high-fat": "1",
    "high-fibre": "1",
    "high-iron": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "alternate-spellings": "Puri: Poori",
    "weight-g": "147",
    "calories": "323",
    "fat": "14.3",
    "cal-from-fat": "128.7",
    "per-cal-from-fat": "40",
    "saturated": "7.8",
    "polyunsaturated": "1.5",
    "monounsaturated": "4.4",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "1010",
    "carbs": "41.9",
    "fibre": "8.8",
    "sugar": "0",
    "protein": "6.9",
    "cal-from-protein": "28",
    "per-cal-from-protein": "9",
    "potassium": "0",
    "calcium": "38",
    "iron": "2.8",
    "phosphorus": "0",
    "source": "N Buddy",
    "old units (ref)": "Puri"
  },
  {
    "id": "1385",
    "type": "meal",
    "|| name                                               ||": "Poke bowl",
    "rating": "2",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-include-exclude": "rice/quinoa base with meat, vegetables and creamy sauce",
    "weight-g": "400",
    "calories": "481",
    "fat": "15",
    "cal-from-fat": "135",
    "per-cal-from-fat": "28",
    "carbs": "54",
    "protein": "35",
    "cal-from-protein": "140",
    "per-cal-from-protein": "29"
  },
  {
    "id": "1386",
    "type": "meal",
    "|| name                                               ||": "Poke bowl",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-include-exclude": "rice/quinoa base with meat, vegetables and non-creamy sauce",
    "weight-g": "400",
    "calories": "418",
    "fat": "7",
    "cal-from-fat": "63",
    "per-cal-from-fat": "15",
    "carbs": "47",
    "protein": "31",
    "cal-from-protein": "124",
    "per-cal-from-protein": "30"
  },
  {
    "id": "1387",
    "type": "meal",
    "|| name                                               ||": "Poke bowl",
    "rating": "2",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-include-exclude": "lettuce base with meat, vegetables and creamy sauce",
    "weight-g": "300",
    "calories": "341",
    "fat": "13",
    "cal-from-fat": "117",
    "per-cal-from-fat": "34",
    "carbs": "27",
    "protein": "31",
    "cal-from-protein": "124",
    "per-cal-from-protein": "36"
  },
  {
    "id": "1388",
    "type": "meal",
    "|| name                                               ||": "Poke bowl",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-include-exclude": "lettuce base with meat, vegetables and non-creamy sauce",
    "weight-g": "300",
    "calories": "278",
    "fat": "5",
    "cal-from-fat": "45",
    "per-cal-from-fat": "16",
    "carbs": "20",
    "protein": "27",
    "cal-from-protein": "108",
    "per-cal-from-protein": "39"
  },
  {
    "id": "1389",
    "type": "meal",
    "|| name                                               ||": "Poke bowl",
    "rating": "2",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-include-exclude": "pasta base with meat, vegetables and creamy sauce",
    "weight-g": "400",
    "calories": "447",
    "fat": "14",
    "cal-from-fat": "126",
    "per-cal-from-fat": "28",
    "carbs": "48",
    "protein": "36",
    "cal-from-protein": "144",
    "per-cal-from-protein": "32"
  },
  {
    "id": "1390",
    "type": "meal",
    "|| name                                               ||": "Poke bowl",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "bowl",
    "info-include-exclude": "pasta base with meat, vegetables and non-creamy sauce",
    "weight-g": "400",
    "calories": "385",
    "fat": "6",
    "cal-from-fat": "54",
    "per-cal-from-fat": "14",
    "carbs": "41",
    "protein": "32",
    "cal-from-protein": "128",
    "per-cal-from-protein": "33"
  },
  {
    "id": "1391",
    "type": "drink",
    "|| name                                               ||": "Diben Drink (1.5 kcal)",
    "rating": "3",
    "healthy-fats": "1",
    "high-calcium": "1",
    "high-fat": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "bottle",
    "volume-ml": "200",
    "calories": "300",
    "fat": "14",
    "cal-from-fat": "126",
    "per-cal-from-fat": "42",
    "saturated": "3.4",
    "polyunsaturated": "3",
    "monounsaturated": "7.6",
    "cholesterol": "12",
    "sodium": "140",
    "carbs": "26.2",
    "fibre": "4",
    "sugar": "5",
    "protein": "15",
    "cal-from-protein": "60",
    "per-cal-from-protein": "20",
    "potassium": "260",
    "calcium": "300",
    "iron": "4",
    "phosphorus": "190",
    "old units (ref)": "https://www.fresubin.com/ua/sites/default/files/2020-02/Productsheet_DibenDrink_2019.pdf"
  },
  {
    "id": "1392",
    "type": "drink",
    "|| name                                               ||": "Nepro LP (liquid)",
    "rating": "3",
    "healthy-fats": "1",
    "high-calcium": "1",
    "high-fat": "1",
    "high-iron": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "bottle",
    "volume-ml": "220",
    "calories": "401",
    "fat": "21",
    "cal-from-fat": "189",
    "per-cal-from-fat": "47",
    "saturated": "1.6",
    "polyunsaturated": "3.1",
    "monounsaturated": "16",
    "sodium": "176",
    "carbs": "40.77",
    "fibre": "2.77",
    "sugar": "13",
    "protein": "9.94",
    "cal-from-protein": "40",
    "per-cal-from-protein": "10",
    "potassium": "251",
    "calcium": "161",
    "iron": "4.2",
    "phosphorus": "143",
    "old units (ref)": "https://www.family.abbott/sg-en/nepro/products/nepro-lp.html"
  },
  {
    "id": "1393",
    "type": "drink",
    "|| name                                               ||": "Pulmocare (liquid)",
    "rating": "3",
    "healthy-fats": "1",
    "high-calcium": "1",
    "high-fat": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "volume-ml": "237",
    "calories": "355",
    "fat": "22.1",
    "cal-from-fat": "198.9",
    "per-cal-from-fat": "56",
    "sodium": "310",
    "carbs": "25",
    "sugar": "14",
    "protein": "14.8",
    "cal-from-protein": "59",
    "per-cal-from-protein": "17",
    "potassium": "422",
    "calcium": "250",
    "iron": "4.5",
    "phosphorus": "250",
    "old units (ref)": "https://www.abbottnutrition.com/our-products/pulmocare"
  },
  {
    "id": "1394",
    "type": "drink",
    "|| name                                               ||": "Ensure Max Protein (liquid)",
    "rating": "3",
    "high-calcium": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "carton",
    "volume-ml": "330",
    "calories": "150",
    "fat": "1.5",
    "cal-from-fat": "13.5",
    "per-cal-from-fat": "9",
    "saturated": "0.5",
    "trans": "0",
    "cholesterol": "20",
    "sodium": "140",
    "carbs": "6",
    "fibre": "2",
    "sugar": "1",
    "protein": "30",
    "cal-from-protein": "120",
    "per-cal-from-protein": "80",
    "potassium": "528",
    "calcium": "650",
    "iron": "5.6",
    "phosphorus": "624",
    "old units (ref)": "https://www.family.abbott/sg-en/ensure/products/ensure-max-protein.html"
  },
  {
    "id": "1395",
    "type": "snack",
    "|| name                                               ||": "Beneprotein (powder)",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "scoop",
    "weight-g": "7",
    "calories": "25",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "sodium": "15",
    "carbs": "0",
    "protein": "6",
    "cal-from-protein": "24",
    "per-cal-from-protein": "96",
    "old units (ref)": "https://www.nestlehealthscience.sg/brands/beneprotein/beneprotein"
  },
  {
    "id": "1396",
    "type": "snack",
    "|| name                                               ||": "Myotein (powder)",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "scoop",
    "weight-g": "6.3",
    "calories": "26",
    "fat": "0.5",
    "cal-from-fat": "4.5",
    "per-cal-from-fat": "17",
    "sodium": "12.6",
    "carbs": "0.4",
    "protein": "5",
    "cal-from-protein": "20",
    "per-cal-from-protein": "77",
    "potassium": "29.6",
    "calcium": "25.8",
    "phosphorus": "12.6",
    "old units (ref)": "https://valensnutrition.com.sg/product/myotein/"
  },
  {
    "id": "1397",
    "type": "drink",
    "|| name                                               ||": "Herbalife Formula 1 Nutritional Shake Mix",
    "rating": "3",
    "high-iron": "1",
    "high-phosphate": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "info-translation": "Meal replacement",
    "info-portion-aid": "standard dilution (2 heaping tablespoons with water)",
    "weight-g": "26",
    "calories": "93",
    "fat": "2.3",
    "cal-from-fat": "20.7",
    "per-cal-from-fat": "22",
    "saturated": "0.5",
    "sodium": "135",
    "carbs": "9",
    "fibre": "2.5",
    "sugar": "8.5",
    "protein": "9",
    "cal-from-protein": "36",
    "per-cal-from-protein": "39",
    "potassium": "130",
    "calcium": "100",
    "iron": "5.2",
    "phosphorus": "171",
    "old units (ref)": "https://www.herbalife.com/en-sg/u/products/formula-1-nutritional-shake-mix-french-vanilla-550g-0141"
  },
  {
    "id": "1398",
    "type": "drink",
    "|| name                                               ||": "Susenji Chocolate Shake",
    "rating": "3",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "info-translation": "Meal replacement",
    "info-portion-aid": "standard dilution (1 sachet with water)",
    "weight-g": "35",
    "calories": "140",
    "fat": "2",
    "cal-from-fat": "18",
    "per-cal-from-fat": "13",
    "saturated": "1",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "130",
    "carbs": "12",
    "fibre": "4",
    "sugar": "5",
    "protein": "13",
    "cal-from-protein": "52",
    "per-cal-from-protein": "37",
    "potassium": "380",
    "calcium": "40",
    "iron": "4.7",
    "phosphorus": "150",
    "source": "N Buddy"
  },
  {
    "id": "1399",
    "type": "drink",
    "|| name                                               ||": "Quest Protein Shake",
    "rating": "3",
    "high-calcium": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "carton",
    "volume-ml": "325",
    "calories": "160",
    "fat": "3.5",
    "cal-from-fat": "31.5",
    "per-cal-from-fat": "20",
    "saturated": "0",
    "trans": "0",
    "cholesterol": "20",
    "sodium": "250",
    "carbs": "3",
    "fibre": "0",
    "sugar": "1",
    "protein": "30",
    "cal-from-protein": "120",
    "per-cal-from-protein": "75",
    "potassium": "130",
    "calcium": "840",
    "iron": "1.7",
    "old units (ref)": "https://www.questnutrition.com/products/vanilla-protein-shake"
  },
  {
    "id": "1400",
    "type": "drink",
    "|| name                                               ||": "Arla Protein Milk drink",
    "rating": "2",
    "high-salt": "1",
    "high-sugar": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "bottle",
    "volume-ml": "500",
    "calories": "250",
    "fat": "4.5",
    "cal-from-fat": "40.5",
    "per-cal-from-fat": "16",
    "saturated": "3",
    "sodium": "349",
    "carbs": "24.5",
    "sugar": "23.5",
    "protein": "28",
    "cal-from-protein": "112",
    "per-cal-from-protein": "45",
    "old units (ref)": "https://www.lazada.sg/products/arla-protein-vanilla-flavoured-milk-drink-500g-i2402527105-s14126035253.html?from_gmc=1&exlaz=d_1:mm_150050845_51350205_2010350205::12:17939068746!!!!!c!!14126035253!132052768&gclid=CjwKCAjw5_GmBhBIEiwA5QSMxBntniuIIi43NmeRoxcD3ls5wn_2SdXVJekB_2-DgUHSoza96jDe_BoCyvEQAvD_BwE"
  },
  {
    "id": "1401",
    "type": "drink",
    "|| name                                               ||": "Labrada Lean Body Protein Shake",
    "rating": "2",
    "high-calcium": "1",
    "high-fibre": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "carton",
    "volume-ml": "500",
    "calories": "280",
    "fat": "9",
    "cal-from-fat": "81",
    "per-cal-from-fat": "29",
    "saturated": "1",
    "trans": "0",
    "cholesterol": "25",
    "sodium": "440",
    "carbs": "4",
    "fibre": "5",
    "sugar": "0",
    "protein": "40",
    "cal-from-protein": "160",
    "per-cal-from-protein": "57",
    "potassium": "600",
    "calcium": "400",
    "iron": "0",
    "old units (ref)": "https://www.decathlon.sg/p/labrada-lean-body-protein-shake-drink-vanilla-500ml-labrada-8740835.html"
  },
  {
    "id": "1402",
    "type": "drink",
    "|| name                                               ||": "Premier Protein Shake",
    "rating": "3",
    "high-calcium": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "bottle",
    "volume-ml": "340",
    "calories": "160",
    "fat": "3",
    "cal-from-fat": "27",
    "per-cal-from-fat": "17",
    "saturated": "1",
    "trans": "0",
    "cholesterol": "20",
    "sodium": "230",
    "carbs": "3",
    "fibre": "1",
    "sugar": "1",
    "protein": "30",
    "cal-from-protein": "120",
    "per-cal-from-protein": "75",
    "potassium": "180",
    "calcium": "650",
    "iron": "1.8",
    "phosphorus": "550",
    "old units (ref)": "https://www.amazon.sg/Premier-Protein-Vitamins-Minerals-Nutrients/dp/B07ZP4FZNF/ref=asc_df_B07MCCVZYC/?tag=googleshoppin-22&linkCode=df0&hvadid=404979017242&hvpos=&hvnetw=g&hvrand=9894865661288120174&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9062530&hvtargid=pla-1380310241472&th=1"
  },
  {
    "id": "1403",
    "type": "drink",
    "|| name                                               ||": "Magnolia Plus High Protein Milk",
    "rating": "2",
    "high-calcium": "1",
    "high-salt": "1",
    "high-sugar": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "bottle",
    "volume-ml": "375",
    "calories": "221",
    "fat": "2.3",
    "cal-from-fat": "20.7",
    "per-cal-from-fat": "9",
    "saturated": "1.5",
    "polyunsaturated": "0",
    "monounsaturated": "0.8",
    "trans": "0",
    "cholesterol": "23",
    "sodium": "401",
    "carbs": "24.8",
    "fibre": "0",
    "sugar": "22.1",
    "protein": "25.1",
    "cal-from-protein": "100",
    "per-cal-from-protein": "45",
    "calcium": "488",
    "old units (ref)": "https://www.magnolia.com.sg/products/fresh-milk/high-protein-milk-vanilla/"
  },
  {
    "id": "1404",
    "type": "drink",
    "|| name                                               ||": "Rokeby Farm Whole Protein Smoothie",
    "rating": "2",
    "high-calcium": "1",
    "high-sugar": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "bottle",
    "volume-ml": "425",
    "calories": "253",
    "fat": "6.4",
    "cal-from-fat": "57.6",
    "per-cal-from-fat": "23",
    "saturated": "4.7",
    "sodium": "111",
    "carbs": "18.7",
    "sugar": "16.6",
    "protein": "30",
    "cal-from-protein": "120",
    "per-cal-from-protein": "47",
    "calcium": "833",
    "old units (ref)": "https://www.lazada.sg/products/rokeby-farm-whole-protein-smoothie-banana-i303276316-s536600029.html?from_gmc=1&exlaz=d_1:mm_150050845_51350205_2010350205::12:17939068746!!!!!c!!536600029!132052768&gclid=CjwKCAjw5_GmBhBIEiwA5QSMxOCkoAzQfCEQ1_fpPhpNw6qGHM27lhz6t1vTzz-jVID6daV1mzr4MRoClKEQAvD_BwE"
  },
  {
    "id": "1405",
    "type": "drink",
    "|| name                                               ||": "Ensure Plant Based Protein Nutrition Shake",
    "rating": "3",
    "high-calcium": "1",
    "high-fibre": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "plant-protein": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "carton",
    "volume-ml": "330",
    "calories": "180",
    "fat": "6",
    "cal-from-fat": "54",
    "per-cal-from-fat": "30",
    "saturated": "1",
    "polyunsaturated": "1.5",
    "monounsaturated": "3.5",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "300",
    "carbs": "8",
    "fibre": "5",
    "sugar": "7",
    "protein": "20",
    "cal-from-protein": "80",
    "per-cal-from-protein": "44",
    "potassium": "470",
    "calcium": "400",
    "iron": "7.2",
    "old units (ref)": "https://abbottstore.com/adult-nutrition/ensure/ensure-plant-based-protein/ensure-plant-basedproteinnutritionshake/ensure-plant-based-protein-nutrition-shake-vanilla-11-fl-oz-tetra-case-of-12-67450.html"
  },
  {
    "id": "1406",
    "type": "meal",
    "|| name                                               ||": "Bread, low GI",
    "rating": "3",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "info-brands": "Smart Carb",
    "weight-g": "36",
    "calories": "81.5",
    "fat": "2.1",
    "cal-from-fat": "18.45",
    "per-cal-from-fat": "23",
    "saturated": "0.6",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "114.5",
    "carbs": "9.3",
    "fibre": "3.2",
    "sugar": "0.9",
    "protein": "6.6",
    "cal-from-protein": "26",
    "per-cal-from-protein": "32",
    "calcium": "73",
    "iron": "0.9",
    "old units (ref)": "https://www.fairprice.com.sg/product/sunshine-smart-carb-low-gi-bread-360g-13083462?gclid=Cj0KCQjw0IGnBhDUARIsAMwFDLldRtVirgtmSTeSQj8wlW4swM5LhgrU2jEGj7LSLAJ2ppf3FTPxlVQaAl8ZEALw_wcB"
  },
  {
    "id": "1407",
    "type": "snack",
    "|| name                                               ||": "Upgrain brioche loaf \n",
    "rating": "3",
    "high-fibre": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "50",
    "calories": "111",
    "fat": "3.6",
    "cal-from-fat": "32.4",
    "per-cal-from-fat": "29",
    "carbs": "14.2",
    "fibre": "6.6",
    "protein": "5.5",
    "cal-from-protein": "22",
    "per-cal-from-protein": "20",
    "old units (ref)": "https://upgrain.com/collections/upgrain-products/products/brioche-loaf?variant=40472659951783"
  },
  {
    "id": "1408",
    "type": "snack",
    "|| name                                               ||": "Upgrain multigrain bun",
    "rating": "3",
    "high-fat": "1",
    "high-fibre": "1",
    "serving": "1",
    "|| unit ||": "bun",
    "weight-g": "80",
    "calories": "206",
    "fat": "9.1",
    "cal-from-fat": "81.9",
    "per-cal-from-fat": "40",
    "carbs": "21.8",
    "fibre": "8.5",
    "protein": "9.1",
    "cal-from-protein": "36",
    "per-cal-from-protein": "17",
    "old units (ref)": "https://upgrain.com/collections/upgrain-products/products/multigrain-buns?variant=40472660148391"
  },
  {
    "id": "1409",
    "type": "snack",
    "|| name                                               ||": "Upgrain pita bread",
    "rating": "3",
    "high-fibre": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "70",
    "calories": "110",
    "fat": "3.4",
    "cal-from-fat": "30.6",
    "per-cal-from-fat": "28",
    "carbs": "11",
    "fibre": "15.5",
    "protein": "9",
    "cal-from-protein": "36",
    "per-cal-from-protein": "33",
    "old units (ref)": "https://upgrain.com/collections/upgrain-products/products/pita-bread?variant=40472659034279"
  },
  {
    "id": "1410",
    "type": "snack",
    "|| name                                               ||": "Upgrain raisin cinnamon demi\nloaf",
    "rating": "3",
    "high-fibre": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "70",
    "calories": "133",
    "fat": "3",
    "cal-from-fat": "27",
    "per-cal-from-fat": "20",
    "carbs": "18.6",
    "fibre": "13.7",
    "sugar": "6",
    "protein": "7.9",
    "cal-from-protein": "32",
    "per-cal-from-protein": "24",
    "old units (ref)": "https://upgrain.com/collections/upgrain-products/products/low-carb-raisin-cinnamon-demi-loaf?variant=42829160841428"
  },
  {
    "id": "1411",
    "type": "snack",
    "|| name                                               ||": "Upgrain signature bun",
    "rating": "3",
    "high-fibre": "1",
    "serving": "1",
    "|| unit ||": "bun",
    "weight-g": "70",
    "calories": "155",
    "fat": "5",
    "cal-from-fat": "45",
    "per-cal-from-fat": "29",
    "carbs": "19.8",
    "fibre": "9.2",
    "protein": "7.7",
    "cal-from-protein": "31",
    "per-cal-from-protein": "20",
    "old units (ref)": "https://upgrain.com/collections/upgrain-products/products/signature-buns?variant=40472658641063"
  },
  {
    "id": "1412",
    "type": "snack",
    "|| name                                               ||": "Upgrain sundried tomato bun",
    "rating": "3",
    "high-fat": "1",
    "high-fibre": "1",
    "serving": "1",
    "|| unit ||": "bun",
    "weight-g": "70",
    "calories": "161",
    "fat": "7",
    "cal-from-fat": "63",
    "per-cal-from-fat": "39",
    "carbs": "17.8",
    "fibre": "8.3",
    "sugar": "3.4",
    "protein": "6.7",
    "cal-from-protein": "27",
    "per-cal-from-protein": "17",
    "old units (ref)": "https://upgrain.com/collections/upgrain-products/products/low-carb-sun-dried-tomato-buns?variant=43379686703316"
  },
  {
    "id": "1413",
    "type": "snack",
    "|| name                                               ||": "Upgrain vegan bun",
    "rating": "3",
    "high-fibre": "1",
    "serving": "1",
    "|| unit ||": "bun",
    "weight-g": "70",
    "calories": "110",
    "fat": "2.7",
    "cal-from-fat": "24.3",
    "per-cal-from-fat": "22",
    "carbs": "12.9",
    "fibre": "15.9",
    "protein": "8.6",
    "cal-from-protein": "34",
    "per-cal-from-protein": "31",
    "old units (ref)": "https://upgrain.com/collections/upgrain-products/products/vegan-buns?variant=40472660443303"
  },
  {
    "id": "1414",
    "type": "snack",
    "|| name                                               ||": "Upgrain walnut raisin bread",
    "rating": "3",
    "high-fat": "1",
    "serving": "1",
    "|| unit ||": "slice",
    "weight-g": "50",
    "calories": "140",
    "fat": "5.6",
    "cal-from-fat": "50.4",
    "per-cal-from-fat": "36",
    "carbs": "17.4",
    "fibre": "4.2",
    "sugar": "5.9",
    "protein": "5.1",
    "cal-from-protein": "20",
    "per-cal-from-protein": "14",
    "old units (ref)": "https://upgrain.com/collections/upgrain-products/products/low-carb-walnut-raisin-bread?variant=43633731567828"
  },
  {
    "id": "1415",
    "type": "snack",
    "|| name                                               ||": "Upgrain mee kia",
    "rating": "3",
    "high-fibre": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "alternate-spellings": "Mee: Mian",
    "weight-g": "70",
    "calories": "93",
    "fat": "0.8",
    "cal-from-fat": "7.2",
    "per-cal-from-fat": "8",
    "sodium": "254.5",
    "carbs": "14",
    "fibre": "17.9",
    "protein": "7.4",
    "cal-from-protein": "30",
    "per-cal-from-protein": "32",
    "old units (ref)": "https://upgrain.com/collections/upgrain-products/products/low-carb-mee-kia-thin-egg-noodles?variant=40472661098663"
  },
  {
    "id": "1416",
    "type": "snack",
    "|| name                                               ||": "Upgrain mee pok",
    "rating": "3",
    "high-fibre": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "alternate-spellings": "Mee: Mian",
    "weight-g": "70",
    "calories": "93",
    "fat": "0.8",
    "cal-from-fat": "7.2",
    "per-cal-from-fat": "8",
    "sodium": "254.5",
    "carbs": "14",
    "fibre": "17.9",
    "protein": "7.4",
    "cal-from-protein": "30",
    "per-cal-from-protein": "32",
    "old units (ref)": "https://upgrain.com/collections/upgrain-products/products/low-carb-fresh-mee-pok-flat-egg-noodles?variant=40472660869287"
  },
  {
    "id": "1417",
    "type": "snack",
    "|| name                                               ||": "Upgrain ramen",
    "rating": "2",
    "high-fibre": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "weight-g": "70",
    "calories": "73",
    "fat": "0.8",
    "cal-from-fat": "7.2",
    "per-cal-from-fat": "10",
    "sodium": "390.6",
    "carbs": "9",
    "fibre": "23.9",
    "protein": "7.4",
    "cal-from-protein": "30",
    "per-cal-from-protein": "41",
    "old units (ref)": "https://upgrain.com/collections/upgrain-products/products/low-carb-ramen-japanese-noodles?variant=40472660672679"
  },
  {
    "id": "1418",
    "type": "snack",
    "|| name                                               ||": "Upgrain wonton noodles",
    "rating": "3",
    "high-fibre": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "alternate-spellings": "Wonton: Wanton",
    "weight-g": "70",
    "calories": "93",
    "fat": "0.8",
    "cal-from-fat": "7.2",
    "per-cal-from-fat": "8",
    "sodium": "254.5",
    "carbs": "14",
    "fibre": "17.9",
    "protein": "7.4",
    "cal-from-protein": "30",
    "per-cal-from-protein": "32",
    "old units (ref)": "https://upgrain.com/collections/upgrain-products/products/low-carb-wonton-mee-hong-kong-style?variant=42828979306708"
  },
  {
    "id": "1419",
    "type": "snack",
    "|| name                                               ||": "Upgrain cranberry rose cookie",
    "rating": "2",
    "high-fat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "5.9",
    "calories": "28",
    "fat": "1.9",
    "cal-from-fat": "17.1",
    "per-cal-from-fat": "61",
    "carbs": "2.5",
    "fibre": "1",
    "sugar": "1.2",
    "protein": "0.4",
    "cal-from-protein": "2",
    "per-cal-from-protein": "7",
    "old units (ref)": "https://upgrain.com/collections/upgrain-products/products/low-carb-cranberry-rose-cookies-revamped?variant=43676681601236"
  },
  {
    "id": "1420",
    "type": "drink",
    "|| name                                               ||": "Teh O siew dai",
    "rating": "2",
    "serving": "1",
    "|| unit ||": "cup",
    "info-translation": "Tea with less sugar",
    "alternate-spellings": "Siew: Siu",
    "weight-g": "194",
    "calories": "28",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "0",
    "carbs": "7",
    "fibre": "0",
    "sugar": "7",
    "protein": "0.2",
    "cal-from-protein": "1",
    "per-cal-from-protein": "4",
    "potassium": "32",
    "calcium": "0",
    "iron": "0",
    "phosphorus": "2",
    "source": "N Buddy"
  },
  {
    "id": "1421",
    "type": "meal",
    "|| name                                               ||": "Edamame pasta, plain (dry)",
    "rating": "3",
    "high-calcium": "1",
    "high-fibre": "1",
    "high-iron": "1",
    "high-potassium": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "info-examples": "spaghetti, linguine, macaroni",
    "weight-g": "57",
    "calories": "200",
    "fat": "3",
    "cal-from-fat": "27",
    "per-cal-from-fat": "14",
    "saturated": "0.5",
    "trans": "0",
    "sodium": "0",
    "carbs": "9",
    "fibre": "12",
    "sugar": "3",
    "protein": "24",
    "cal-from-protein": "96",
    "per-cal-from-protein": "48",
    "potassium": "1120",
    "calcium": "110",
    "iron": "8",
    "old units (ref)": "https://sg.iherb.com/pr/seapoint-farms-organic-edamame-spaghetti-7-05-oz-200-g/70719?gclid=Cj0KCQjwpompBhDZARIsAFD_Fp97NPsxF3VQYmD8N22IKeVYs0YUTKvm0oCtEqUlU3K5meHr6kA9wTwaAg5HEALw_wcB&gclsrc=aw.ds"
  },
  {
    "id": "1422",
    "type": "meal",
    "|| name                                               ||": "Chickpea pasta, plain (dry)",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "serving",
    "info-examples": "spaghetti, linguine, macaroni",
    "weight-g": "50",
    "calories": "182.5",
    "fat": "3.1",
    "cal-from-fat": "27.9",
    "per-cal-from-fat": "15",
    "saturated": "0.8",
    "sodium": "0",
    "carbs": "25.5",
    "fibre": "4.4",
    "sugar": "1.2",
    "protein": "11",
    "cal-from-protein": "44",
    "per-cal-from-protein": "24",
    "old units (ref)": "https://www.fairprice.com.sg/product/alb-gold-organic-chickpea-pasta-250-g-90148582?gclid=Cj0KCQjwuZGnBhD1ARIsACxbAViouyZzZ0gjCaUINMQNtaQVy7Iw_1BqoespVkc6IbCnjBbTEvn_0_IaAqZdEALw_wcB"
  },
  {
    "id": "1423",
    "type": "snack",
    "|| name                                               ||": "Yogurt, Greek, whole milk",
    "rating": "2",
    "high-calcium": "1",
    "high-fat": "1",
    "probiotic": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "alternate-spellings": "Yogurt: Yoghurt",
    "weight-g": "160",
    "calories": "138",
    "fat": "6.4",
    "cal-from-fat": "57.6",
    "per-cal-from-fat": "42",
    "saturated": "4.2",
    "sodium": "52",
    "carbs": "6.2",
    "sugar": "4.3",
    "protein": "13.7",
    "cal-from-protein": "55",
    "per-cal-from-protein": "40",
    "calcium": "160",
    "old units (ref)": "https://www.lazada.sg/products/chobani-plain-4-percent-fat-greek-yoghurt-907g-i303070117-s536366073.html?from_gmc=1&exlaz=d_1:mm_150050845_51350205_2010350205::12:17939068746!!!!!c!!536366073!132052768&gclid=Cj0KCQjw6KunBhDxARIsAKFUGs8DC0RcEDTJfRH34xKkBi7Za9W5oVtFYtKqXc4qz-Qkx3cqcM8huzsaAlD-EALw_wcB"
  },
  {
    "id": "1424",
    "type": "snack",
    "|| name                                               ||": "Yogurt, Greek, low fat",
    "rating": "3",
    "high-calcium": "1",
    "probiotic": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "alternate-spellings": "Yogurt: Yoghurt",
    "weight-g": "170",
    "calories": "99",
    "fat": "0.3",
    "cal-from-fat": "2.7",
    "per-cal-from-fat": "3",
    "saturated": "0.2",
    "sodium": "54",
    "carbs": "7.1",
    "sugar": "5.6",
    "protein": "16.5",
    "cal-from-protein": "66",
    "per-cal-from-protein": "67",
    "calcium": "204",
    "old units (ref)": "https://www.woolworths.com.au/shop/productdetails/717499/chobani-plain-0-5-fat-greek-yogurt-yoghurt"
  },
  {
    "id": "1425",
    "type": "drink",
    "|| name                                               ||": "Vitaplus Benecol",
    "rating": "3",
    "serving": "1",
    "|| unit ||": "bottle",
    "info-include-exclude": "with plant stanols",
    "volume-ml": "70",
    "calories": "42",
    "fat": "2.9",
    "cal-from-fat": "26.1",
    "per-cal-from-fat": "62",
    "saturated": "0.4",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "25",
    "carbs": "3.2",
    "fibre": "0.4",
    "sugar": "1.3",
    "protein": "0.6",
    "cal-from-protein": "2",
    "per-cal-from-protein": "5",
    "old units (ref)": "https://www.fairprice.com.sg/product/vitaplus-benecol-juice-drink-strawberry-4-x-70ml-13199870"
  },
  {
    "id": "1426",
    "type": "meal",
    "|| name                                               ||": "Beef tendon",
    "rating": "2",
    "high-purine": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "weight-g": "100",
    "calories": "147",
    "fat": "0.6",
    "cal-from-fat": "5.4",
    "per-cal-from-fat": "4",
    "cholesterol": "51",
    "sodium": "99.3",
    "carbs": "0.1",
    "protein": "35.2",
    "cal-from-protein": "141",
    "per-cal-from-protein": "96",
    "potassium": "48",
    "calcium": "13",
    "iron": "1.7",
    "phosphorus": "22",
    "old units (ref)": "https://www.cfs.gov.hk/english/nutrient/fc-myreport.php"
  },
  {
    "id": "1427",
    "type": "meal",
    "|| name                                               ||": "Beef brisket, braised",
    "rating": "2",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "weight-g": "100",
    "calories": "331",
    "fat": "24.5",
    "cal-from-fat": "220.5",
    "per-cal-from-fat": "67",
    "saturated": "9.44",
    "cholesterol": "93",
    "sodium": "64",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "25.85",
    "cal-from-protein": "103",
    "per-cal-from-protein": "31",
    "potassium": "251",
    "calcium": "7",
    "iron": "2.46",
    "phosphorus": "207",
    "old units (ref)": "https://www.cfs.gov.hk/english/nutrient/fc-myreport.php"
  },
  {
    "id": "1428",
    "type": "meal",
    "|| name                                               ||": "Pork liver, braised",
    "rating": "2",
    "high-cholesterol": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "high-purine": "1",
    "red-meat": "1",
    "serving": "1",
    "|| unit ||": "portion",
    "weight-g": "100",
    "calories": "165",
    "fat": "4.4",
    "cal-from-fat": "39.6",
    "per-cal-from-fat": "24",
    "saturated": "1.4",
    "cholesterol": "355",
    "sodium": "49",
    "carbs": "3.8",
    "fibre": "0",
    "protein": "26",
    "cal-from-protein": "104",
    "per-cal-from-protein": "63",
    "potassium": "150",
    "calcium": "10",
    "iron": "18",
    "phosphorus": "241",
    "old units (ref)": "https://www.cfs.gov.hk/english/nutrient/fc-myreport.php"
  },
  {
    "id": "1429",
    "type": "drink",
    "|| name                                               ||": "Keto coffee",
    "rating": "1",
    "high-fat": "1",
    "serving": "2",
    "|| unit ||": "tbsp",
    "weight-g": "15",
    "calories": "100",
    "fat": "9",
    "cal-from-fat": "81",
    "per-cal-from-fat": "81",
    "saturated": "8",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "95",
    "carbs": "3",
    "fibre": "0",
    "sugar": "1",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "4",
    "potassium": "70",
    "calcium": "20",
    "iron": "0",
    "old units (ref)": "https://shopee.sg/product/106499204/13105519286?gclid=Cj0KCQjwi7GnBhDXARIsAFLvH4nKwQ3VuHRHoWQekndpT3hdLZk4bm3rxL_BgvyJTY5ufPDd54pezUMaAgoGEALw_wcB"
  },
  {
    "id": "1430",
    "type": "meal",
    "|| name                                               ||": "Lentil, with spinach and spices, stewed",
    "rating": "2",
    "healthy-fats": "1",
    "high-fat": "1",
    "high-fibre": "1",
    "high-salt": "1",
    "plant-protein": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "379",
    "calories": "227",
    "fat": "8.7",
    "cal-from-fat": "78.3",
    "per-cal-from-fat": "34",
    "saturated": "3.2",
    "sodium": "787",
    "carbs": "24.6",
    "fibre": "7.2",
    "protein": "12.5",
    "cal-from-protein": "50",
    "per-cal-from-protein": "22",
    "source": "HPB"
  },
  {
    "id": "1431",
    "type": "meal",
    "|| name                                               ||": "Chicken, black pepper",
    "rating": "2",
    "high-fat": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "palm",
    "weight-g": "100",
    "calories": "150",
    "fat": "6.1",
    "cal-from-fat": "54.9",
    "per-cal-from-fat": "37",
    "saturated": "2.1",
    "trans": "0",
    "cholesterol": "60",
    "sodium": "634",
    "carbs": "6.9",
    "fibre": "0",
    "sugar": "1",
    "protein": "16.9",
    "cal-from-protein": "68",
    "per-cal-from-protein": "45",
    "source": "N Buddy"
  },
  {
    "id": "1432",
    "type": "meal",
    "|| name                                               ||": "Rava idli",
    "rating": "2",
    "high-fat": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "51",
    "calories": "107",
    "fat": "3.9",
    "cal-from-fat": "35.1",
    "per-cal-from-fat": "33",
    "saturated": "2.2",
    "polyunsaturated": "0.1",
    "monounsaturated": "0.7",
    "trans": "0.4",
    "cholesterol": "0",
    "sodium": "321",
    "carbs": "12.6",
    "fibre": "1.6",
    "sugar": "1.1",
    "protein": "3.1",
    "cal-from-protein": "12",
    "per-cal-from-protein": "11",
    "potassium": "49",
    "calcium": "57",
    "iron": "0.6",
    "phosphorus": "29",
    "source": "N Buddy"
  },
  {
    "id": "1433",
    "type": "snack",
    "|| name                                               ||": "Coconut, grated and desiccated",
    "rating": "2",
    "high-fat": "1",
    "high-fibre": "1",
    "serving": "1",
    "|| unit ||": "cup",
    "weight-g": "75",
    "calories": "497",
    "fat": "48.8",
    "cal-from-fat": "439.2",
    "per-cal-from-fat": "88",
    "saturated": "43.3",
    "sodium": "11.3",
    "carbs": "5.5",
    "fibre": "11.6",
    "protein": "4.7",
    "cal-from-protein": "19",
    "per-cal-from-protein": "4",
    "source": "HPB"
  },
  {
    "id": "1434",
    "type": "meal",
    "|| name                                               ||": "Paneer",
    "rating": "2",
    "high-calcium": "1",
    "high-fat": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "weight-g": "100",
    "calories": "293",
    "fat": "23",
    "cal-from-fat": "207",
    "per-cal-from-fat": "71",
    "saturated": "16",
    "carbs": "2",
    "sugar": "0",
    "protein": "17",
    "cal-from-protein": "68",
    "per-cal-from-protein": "23",
    "calcium": "480",
    "old units (ref)": "https://www.lazada.sg/products/suvai-foods-fresh-cottage-cheese-paneer-200g-i2636783824-s16832076295.html?spm=a2o42.lazmart_search.list.19.5d7e1b08yEvgN4&fastshipping=0&priceCompare=&fs_ab=2&c=&ratingscore=0&freeshipping=0&source=search&channelLpJumpArgs=&fuse_fs=0&search=1&sale=13&price=4.5&review=&location=Singapore&stock=0%27&lang=en&request_id=63f8fc29bb02c6f94e29d872643729b5"
  },
  {
    "id": "1435",
    "type": "meal",
    "|| name                                               ||": "Paneer, low fat",
    "rating": "3",
    "high-calcium": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "weight-g": "100",
    "calories": "157",
    "fat": "5",
    "cal-from-fat": "45",
    "per-cal-from-fat": "29",
    "carbs": "3",
    "protein": "25",
    "cal-from-protein": "100",
    "per-cal-from-protein": "64",
    "calcium": "554"
  },
  {
    "id": "1436",
    "type": "meal",
    "|| name                                               ||": "Acai bowl",
    "rating": "2",
    "high-fat": "1",
    "high-fibre": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "347",
    "calories": "375",
    "fat": "14",
    "cal-from-fat": "126",
    "per-cal-from-fat": "34",
    "saturated": "4.2",
    "sodium": "63.5",
    "carbs": "54",
    "fibre": "8",
    "protein": "8",
    "cal-from-protein": "32",
    "per-cal-from-protein": "9",
    "source": "HPB"
  },
  {
    "id": "1437",
    "type": "meal",
    "|| name                                               ||": "Chicken wing, grilled / BBQ",
    "rating": "2",
    "high-fat": "1",
    "high-salt": "1",
    "white-meat": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "alternate-spellings": "BBQ: Barbecue",
    "weight-g": "98",
    "calories": "154",
    "fat": "10.6",
    "cal-from-fat": "95.4",
    "per-cal-from-fat": "62",
    "saturated": "3",
    "cholesterol": "80",
    "sodium": "325",
    "carbs": "0",
    "fibre": "0",
    "protein": "14.9",
    "cal-from-protein": "60",
    "per-cal-from-protein": "39",
    "source": "HPB"
  },
  {
    "id": "1438",
    "type": "snack",
    "|| name                                               ||": "Olive, preserved",
    "rating": "2",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "weight-g": "17",
    "calories": "34",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "63",
    "carbs": "6",
    "fibre": "1",
    "sugar": "5.7",
    "protein": "0.1",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "4",
    "calcium": "1",
    "iron": "0.1",
    "phosphorus": "1",
    "source": "N Buddy"
  },
  {
    "id": "1439",
    "type": "snack",
    "|| name                                               ||": "Sambal chilli",
    "rating": "1",
    "high-fat": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "tsp",
    "weight-g": "12",
    "calories": "18.8",
    "fat": "1.2",
    "cal-from-fat": "10.8",
    "per-cal-from-fat": "57",
    "saturated": "0.3",
    "cholesterol": "1.8",
    "sodium": "195",
    "carbs": "1.6",
    "fibre": "0.6",
    "protein": "0.4",
    "cal-from-protein": "2",
    "per-cal-from-protein": "11",
    "source": "HPB"
  },
  {
    "id": "1440",
    "type": "snack",
    "|| name                                               ||": "Coleslaw sauce",
    "rating": "1",
    "high-fat": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "20",
    "calories": "78",
    "fat": "6",
    "cal-from-fat": "54",
    "per-cal-from-fat": "69",
    "saturated": "0.7",
    "polyunsaturated": "3.9",
    "monounsaturated": "1.4",
    "cholesterol": "8",
    "sodium": "194",
    "carbs": "6.1",
    "fibre": "0",
    "sugar": "6.1",
    "protein": "0.2",
    "cal-from-protein": "1",
    "per-cal-from-protein": "1",
    "potassium": "6",
    "calcium": "3",
    "iron": "0.1",
    "phosphorus": "7",
    "source": "N Buddy"
  },
  {
    "id": "1441",
    "type": "snack",
    "|| name                                               ||": "Mushroom sauce",
    "rating": "2",
    "high-fat": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "15",
    "calories": "69",
    "fat": "6.5",
    "cal-from-fat": "58.5",
    "per-cal-from-fat": "85",
    "sodium": "398",
    "carbs": "0.5",
    "fibre": "0.1",
    "sugar": "0.5",
    "protein": "1.2",
    "cal-from-protein": "5",
    "per-cal-from-protein": "7",
    "source": "N Buddy"
  },
  {
    "id": "1442",
    "type": "snack",
    "|| name                                               ||": "Table salt",
    "rating": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "tsp",
    "weight-g": "5",
    "calories": "0",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "cholesterol": "0",
    "sodium": "1959",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "5",
    "calcium": "1",
    "source": "HPB"
  },
  {
    "id": "1443",
    "type": "snack",
    "|| name                                               ||": "K-Salt lower sodium salt",
    "rating": "2",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "tsp",
    "weight-g": "4.8",
    "calories": "0",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "cholesterol": "0",
    "sodium": "1320",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "600",
    "old units (ref)": "https://www.fairprice.com.sg/product/k-salt-lower-sodium-salt-400g-13224671"
  },
  {
    "id": "1444",
    "type": "snack",
    "|| name                                               ||": "Pagoda lower sodium salt",
    "rating": "2",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "tsp",
    "weight-g": "5",
    "calories": "0",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "cholesterol": "0",
    "sodium": "1205",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "468.5",
    "old units (ref)": "https://www.fairprice.com.sg/product/pagoda-less-sodium-mineral-salt-350g-12563396"
  },
  {
    "id": "1445",
    "type": "snack",
    "|| name                                               ||": "Pansalt lower sodium salt",
    "rating": "2",
    "high-potassium": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "tsp",
    "weight-g": "4.8",
    "calories": "0",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "cholesterol": "0",
    "sodium": "1072",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "560",
    "old units (ref)": "https://www.lazada.sg/products/pansalt-iodised-salt-i305058693-s541204200.html?from_gmc=1&exlaz=d_1:mm_150050845_51350205_2010350205::12:17939068746!!!!!c!!541204200!132052768&gclid=Cj0KCQjwi7GnBhDXARIsAFLvH4nyH9B1688KPwnHUK6cEm0HuGjPOfACI-51sxPcjCWvwfzeMqaE6YIaAl_6EALw_wcB"
  },
  {
    "id": "1446",
    "type": "snack",
    "|| name                                               ||": "Light soy sauce",
    "rating": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "17",
    "calories": "13",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "polyunsaturated": "0",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "1241",
    "carbs": "1.6",
    "fibre": "0",
    "sugar": "0.9",
    "protein": "1.6",
    "cal-from-protein": "6",
    "per-cal-from-protein": "46",
    "potassium": "8",
    "calcium": "9",
    "iron": "0",
    "phosphorus": "4",
    "source": "N Buddy"
  },
  {
    "id": "1447",
    "type": "snack",
    "|| name                                               ||": "Dark soy sauce",
    "rating": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "17",
    "calories": "15",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "6",
    "saturated": "0",
    "polyunsaturated": "0.1",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "689",
    "carbs": "2.2",
    "fibre": "0",
    "sugar": "1.4",
    "protein": "1.3",
    "cal-from-protein": "5",
    "per-cal-from-protein": "33",
    "potassium": "102",
    "calcium": "13",
    "iron": "0.8",
    "phosphorus": "39",
    "source": "N Buddy"
  },
  {
    "id": "1448",
    "type": "snack",
    "|| name                                               ||": "Sweet soy sauce",
    "rating": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "20",
    "calories": "36",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "3",
    "saturated": "0",
    "polyunsaturated": "0.1",
    "monounsaturated": "0",
    "trans": "0",
    "cholesterol": "7",
    "sodium": "561",
    "carbs": "7.4",
    "fibre": "0",
    "sugar": "4.5",
    "protein": "1.3",
    "cal-from-protein": "5",
    "per-cal-from-protein": "14",
    "potassium": "30",
    "calcium": "13",
    "iron": "0.8",
    "phosphorus": "5",
    "source": "N Buddy"
  },
  {
    "id": "1449",
    "type": "snack",
    "|| name                                               ||": "Bragg liquid amino",
    "rating": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "tsp",
    "volume-ml": "5",
    "calories": "5",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "sodium": "310",
    "carbs": "0",
    "protein": "1",
    "cal-from-protein": "4",
    "per-cal-from-protein": "80",
    "old units (ref)": "https://www.bragg.com/products/liquid-aminos?variant=39436560531552#gallery-8"
  },
  {
    "id": "1450",
    "type": "snack",
    "|| name                                               ||": "Sesame sauce dressing",
    "rating": "1",
    "high-fat": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "15",
    "calories": "65",
    "fat": "6.6",
    "cal-from-fat": "59.4",
    "per-cal-from-fat": "91",
    "saturated": "0.9",
    "polyunsaturated": "3.7",
    "monounsaturated": "1.8",
    "cholesterol": "0",
    "sodium": "147",
    "carbs": "1.2",
    "fibre": "0.1",
    "sugar": "1.2",
    "protein": "0.5",
    "cal-from-protein": "2",
    "per-cal-from-protein": "3",
    "potassium": "23",
    "calcium": "3",
    "iron": "0.1",
    "phosphorus": "5",
    "source": "USDA"
  },
  {
    "id": "1451",
    "type": "snack",
    "|| name                                               ||": "Wafu salad dressing",
    "rating": "1",
    "high-fat": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "15.5",
    "calories": "55",
    "fat": "5.5",
    "cal-from-fat": "49.5",
    "per-cal-from-fat": "90",
    "saturated": "0.5",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "245",
    "carbs": "1",
    "fibre": "0",
    "sugar": "1",
    "protein": "0.5",
    "cal-from-protein": "2",
    "per-cal-from-protein": "4",
    "potassium": "0",
    "calcium": "0",
    "iron": "0",
    "old units (ref)": "https://www.kewpieshop.com/products/kewpie-wafu-dressing-8oz"
  },
  {
    "id": "1452",
    "type": "snack",
    "|| name                                               ||": "Butter, unsalted",
    "rating": "1",
    "high-fat": "1",
    "serving": "1",
    "|| unit ||": "tsp",
    "weight-g": "5",
    "calories": "37",
    "fat": "4.2",
    "cal-from-fat": "37.8",
    "per-cal-from-fat": "102",
    "saturated": "2.75",
    "polyunsaturated": "0.1",
    "monounsaturated": "1.1",
    "trans": "0.2",
    "cholesterol": "12.5",
    "sodium": "0.5",
    "carbs": "0.05",
    "fibre": "0",
    "sugar": "0.05",
    "protein": "0.05",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "1",
    "calcium": "1",
    "iron": "0",
    "phosphorus": "1",
    "source": "N Buddy"
  },
  {
    "id": "1453",
    "type": "snack",
    "|| name                                               ||": "Margarine, soft",
    "rating": "2",
    "high-fat": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "tsp",
    "info-brands": "Naturel",
    "weight-g": "5",
    "calories": "37.5",
    "fat": "4.1",
    "cal-from-fat": "36.9",
    "per-cal-from-fat": "98",
    "saturated": "0.8",
    "polyunsaturated": "1.15",
    "monounsaturated": "2.1",
    "trans": "0.05",
    "cholesterol": "0",
    "sodium": "35",
    "carbs": "0.05",
    "fibre": "0",
    "protein": "0.05",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "old units (ref)": "https://www.fairprice.com.sg/product/naturel-cholesterol-free-margarine-soft-500g-11146259?gclid=Cj0KCQjwl8anBhCFARIsAKbbpyRQsBsCj7uf80nmq3K55RdDfG61wLOUBpjh6K8BQgN5z-bqu-a_dsoaAp9iEALw_wcB"
  },
  {
    "id": "1454",
    "type": "meal",
    "|| name                                               ||": "Margarine, multi-purpose",
    "rating": "1",
    "high-fat": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "tsp",
    "info-brands": "Planta",
    "weight-g": "5",
    "calories": "37",
    "fat": "4.1",
    "cal-from-fat": "36.9",
    "per-cal-from-fat": "100",
    "saturated": "2.45",
    "polyunsaturated": "0.25",
    "monounsaturated": "1.15",
    "trans": "0.5",
    "cholesterol": "0",
    "sodium": "39",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "old units (ref)": "https://www.fairprice.com.sg/product/planta-multi-purpose-margarine-240g-11900856?gclid=Cj0KCQjwl8anBhCFARIsAKbbpyTlMINIMMnXt98gkcE3XEoM5SGj--Q_zPHWtI-SPyK24amK8FTD6pwaAl6-EALw_wcB"
  },
  {
    "id": "1455",
    "type": "meal",
    "|| name                                               ||": "Olive spread",
    "rating": "2",
    "high-fat": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "tsp",
    "weight-g": "5",
    "calories": "28.7",
    "fat": "3.2",
    "cal-from-fat": "28.8",
    "per-cal-from-fat": "100",
    "saturated": "0.8",
    "polyunsaturated": "0.7",
    "monounsaturated": "1.6",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "17",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "0",
    "cal-from-protein": "0",
    "per-cal-from-protein": "0",
    "potassium": "2",
    "old units (ref)": "https://www.lazada.sg/products/olive-grove-classic-spread-i352316597-s820750802.html?from_gmc=1&exlaz=d_1:mm_150050845_51350205_2010350205::12:17939068746!!!!!c!!820750802!132052768&gclid=Cj0KCQjwl8anBhCFARIsAKbbpyShcQrXmwjRVZHEE0_A2tOSMHbVBJpCBQdeCdjhpioEZLGCs_KtXyIaArcPEALw_wcB"
  },
  {
    "id": "1456",
    "type": "meal",
    "|| name                                               ||": "Rice, multigrain",
    "rating": "3",
    "high-fibre": "1",
    "high-phosphate": "1",
    "high-potassium": "1",
    "whole-grain": "1",
    "serving": "1",
    "|| unit ||": "bowl",
    "weight-g": "200",
    "calories": "234",
    "fat": "3.6",
    "cal-from-fat": "32.4",
    "per-cal-from-fat": "14",
    "saturated": "0.5",
    "polyunsaturated": "1.6",
    "monounsaturated": "1",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "3",
    "carbs": "42.7",
    "fibre": "5.1",
    "sugar": "0.3",
    "protein": "7.5",
    "cal-from-protein": "30",
    "per-cal-from-protein": "13",
    "potassium": "280",
    "calcium": "15",
    "iron": "1.2",
    "phosphorus": "261",
    "source": "N Buddy"
  },
  {
    "id": "1457",
    "type": "meal",
    "|| name                                               ||": "Reduced carb wrap, without fillings",
    "rating": "3",
    "high-fibre": "1",
    "high-salt": "1",
    "serving": "1",
    "|| unit ||": "piece",
    "info-brands": "Mission",
    "weight-g": "45",
    "calories": "130",
    "fat": "3",
    "cal-from-fat": "27",
    "per-cal-from-fat": "21",
    "saturated": "2",
    "polyunsaturated": "0.3",
    "monounsaturated": "0.8",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "225",
    "carbs": "16.6",
    "fibre": "8.6",
    "sugar": "1.1",
    "protein": "9",
    "cal-from-protein": "36",
    "per-cal-from-protein": "28",
    "old units (ref)": "https://www.missionfoods.com.sg/en/products/bakery/healthy-wraps-range/mission-reduced-carb-wrap.aspx"
  },
  {
    "id": "1458",
    "type": "drink",
    "|| name                                               ||": "Milk powder, full cream",
    "rating": "2",
    "high-calcium": "1",
    "high-fat": "1",
    "high-iron": "1",
    "high-phosphate": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "info-brands": "Nespray",
    "info-portion-aid": "standard dilution (3 tablespoons with water)",
    "weight-g": "33",
    "calories": "168",
    "fat": "9.3",
    "cal-from-fat": "83.7",
    "per-cal-from-fat": "50",
    "saturated": "5.3",
    "sodium": "111",
    "carbs": "13.2",
    "sugar": "13.2",
    "protein": "7.8",
    "cal-from-protein": "31",
    "per-cal-from-protein": "18",
    "calcium": "396",
    "iron": "4.2",
    "phosphorus": "185",
    "old units (ref)": "https://www.fairprice.com.sg/product/nespray-fortified-instant-full-cream-milk-powder-600g-11896770?gclid=Cj0KCQjwusunBhCYARIsAFBsUP84b7AoRGCb1sG6UKnLl2pPbiWTFVlQTV9FYFs4aojSxhlTc1SRov0aAmnEEALw_wcB"
  },
  {
    "id": "1459",
    "type": "drink",
    "|| name                                               ||": "Milk powder, low fat with plant sterols",
    "rating": "3",
    "high-calcium": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "info-brands": "Nestle Omega Plus ActiCol",
    "info-portion-aid": "standard dilution (3 tablespoons with water)",
    "weight-g": "31",
    "calories": "121",
    "fat": "2.9",
    "cal-from-fat": "26.1",
    "per-cal-from-fat": "22",
    "saturated": "0.8",
    "polyunsaturated": "0.8",
    "monounsaturated": "1.1",
    "trans": "0.1",
    "cholesterol": "9",
    "sodium": "105",
    "carbs": "18",
    "fibre": "0",
    "sugar": "13.2",
    "protein": "6.2",
    "cal-from-protein": "25",
    "per-cal-from-protein": "21",
    "calcium": "496",
    "old units (ref)": "https://www.fairprice.com.sg/product/nestle-omega-plus-adult-milk-powder-acticol-600g-11754756"
  },
  {
    "id": "1460",
    "type": "drink",
    "|| name                                               ||": "Milk, low fat with plant sterols",
    "rating": "3",
    "high-calcium": "1",
    "serving": "1",
    "|| unit ||": "glass",
    "info-brands": "Marigold",
    "volume-ml": "250",
    "calories": "122",
    "fat": "1.8",
    "cal-from-fat": "16.2",
    "per-cal-from-fat": "13",
    "saturated": "1.3",
    "trans": "0",
    "cholesterol": "13",
    "sodium": "138",
    "carbs": "16",
    "fibre": "0",
    "sugar": "12.8",
    "protein": "10.5",
    "cal-from-protein": "42",
    "per-cal-from-protein": "34",
    "calcium": "500",
    "old units (ref)": "https://www.lazada.sg/products/marigold-plant-sterols-plus-hl-milk-946ml-i301150659-s527232239.html?from_gmc=1&exlaz=d_1:mm_150050845_51350205_2010350205::12:17939068746!!!!!c!!527232239!132052768&gclid=Cj0KCQjwusunBhCYARIsAFBsUP_vWUYispJaiCXstzti5At-MrUyDG5h5n5g8TvvTVdjj2QFmp3WQ8oaAhpeEALw_wcB"
  },
  {
    "id": "1461",
    "type": "drink",
    "|| name                                               ||": "Milk, Anlene Gold Concentrate 4X",
    "rating": "3",
    "high-calcium": "1",
    "serving": "1",
    "|| unit ||": "pack",
    "volume-ml": "125",
    "calories": "72",
    "fat": "1.9",
    "cal-from-fat": "17.1",
    "per-cal-from-fat": "24",
    "saturated": "1.3",
    "trans": "0.1",
    "cholesterol": "8",
    "sodium": "66",
    "carbs": "9.5",
    "fibre": "0",
    "sugar": "9.5",
    "protein": "4.4",
    "cal-from-protein": "18",
    "per-cal-from-protein": "25",
    "calcium": "500",
    "old units (ref)": "https://www.fairprice.com.sg/product/anlene-gold-concentrate-uht-milk-vanilla-4-x-125ml-13109053"
  },
  {
    "id": "1462",
    "type": "drink",
    "|| name                                               ||": "Optimum Nutrition Gold Standard 100% Whey Protein",
    "rating": "3",
    "high-calcium": "1",
    "high-potassium": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "scoop",
    "info-translation": "Protein powder",
    "weight-g": "30.4",
    "calories": "120",
    "fat": "1.5",
    "cal-from-fat": "13.5",
    "per-cal-from-fat": "11",
    "saturated": "1",
    "cholesterol": "35",
    "sodium": "55",
    "carbs": "3",
    "sugar": "1",
    "protein": "24",
    "cal-from-protein": "96",
    "per-cal-from-protein": "80",
    "potassium": "210",
    "calcium": "130",
    "iron": "0.7",
    "old units (ref)": "https://www.optimumnutrition.com/en-us/Products/Shakes-%26-Powders/OPTIMUM-NUTRITION-GOLD-STANDARD-100%25-WHEY-PROTEIN/p/gold-standard-100-whey-protein"
  },
  {
    "id": "1463",
    "type": "drink",
    "|| name                                               ||": "Optimum Nutrition Gold Standard 100% Isolate",
    "rating": "3",
    "high-calcium": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "scoop",
    "info-translation": "Protein powder",
    "weight-g": "31",
    "calories": "110",
    "fat": "0.5",
    "cal-from-fat": "4.5",
    "per-cal-from-fat": "4",
    "cholesterol": "5",
    "sodium": "190",
    "carbs": "1",
    "protein": "25",
    "cal-from-protein": "100",
    "per-cal-from-protein": "91",
    "potassium": "240",
    "calcium": "150",
    "iron": "0.5",
    "old units (ref)": "https://www.optimumnutrition.com/en-us/Products/Shakes-%26-Powders/GOLD-STANDARD-100%25-ISOLATE/p/C100185"
  },
  {
    "id": "1464",
    "type": "drink",
    "|| name                                               ||": "Optimum Nutrition Gold Standard 100% Casein",
    "rating": "3",
    "high-calcium": "1",
    "high-potassium": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "scoop",
    "info-translation": "Protein powder",
    "weight-g": "34",
    "calories": "120",
    "fat": "1",
    "cal-from-fat": "9",
    "per-cal-from-fat": "8",
    "cholesterol": "15",
    "sodium": "260",
    "carbs": "3",
    "fibre": "1",
    "sugar": "1",
    "protein": "24",
    "cal-from-protein": "96",
    "per-cal-from-protein": "80",
    "potassium": "230",
    "calcium": "500",
    "iron": "1.5",
    "old units (ref)": "https://www.optimumnutrition.com/en-us/Products/Shakes-%26-Powders/GOLD-STANDARD-100%25-CASEIN/p/100-casein-time-release-proteine"
  },
  {
    "id": "1465",
    "type": "drink",
    "|| name                                               ||": "MyProtein Impact Whey Protein",
    "rating": "2",
    "high-fat": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "pack",
    "info-translation": "Protein powder",
    "weight-g": "35",
    "calories": "158",
    "fat": "6.2",
    "cal-from-fat": "55.8",
    "per-cal-from-fat": "35",
    "saturated": "4.9",
    "sodium": "8",
    "carbs": "10",
    "fibre": "1",
    "sugar": "5.8",
    "protein": "15",
    "cal-from-protein": "60",
    "per-cal-from-protein": "38",
    "old units (ref)": "https://www.myprotein.com.sg/sports-nutrition/impact-whey-protein/10530943.html"
  },
  {
    "id": "1466",
    "type": "drink",
    "|| name                                               ||": "MyProtein Impact Whey Isolate",
    "rating": "3",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "scoop",
    "info-translation": "Protein powder",
    "weight-g": "25",
    "calories": "93",
    "fat": "0.1",
    "cal-from-fat": "0.9",
    "per-cal-from-fat": "1",
    "saturated": "0.1",
    "sodium": "52",
    "carbs": "0.6",
    "sugar": "0.6",
    "protein": "23",
    "cal-from-protein": "92",
    "per-cal-from-protein": "99",
    "old units (ref)": "https://www.myprotein.com.sg/sports-nutrition/impact-whey-isolate/10530911.html"
  },
  {
    "id": "1467",
    "type": "drink",
    "|| name                                               ||": "PranaOn Power Plant Protein",
    "rating": "3",
    "high-iron": "1",
    "high-salt": "1",
    "plant-protein": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "info-translation": "Protein powder",
    "weight-g": "44",
    "calories": "181",
    "fat": "3.5",
    "cal-from-fat": "31.5",
    "per-cal-from-fat": "17",
    "saturated": "1.5",
    "sodium": "399",
    "carbs": "5.6",
    "sugar": "0.6",
    "protein": "30.3",
    "cal-from-protein": "121",
    "per-cal-from-protein": "67",
    "iron": "6",
    "old units (ref)": "https://pranaon.com/products/power-plant-protein"
  },
  {
    "id": "1468",
    "type": "drink",
    "|| name                                               ||": "Isopure Whey Protein Isolate",
    "rating": "3",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "scoop",
    "info-translation": "Protein powder",
    "weight-g": "29",
    "calories": "100",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "cholesterol": "10",
    "sodium": "230",
    "carbs": "0",
    "sugar": "0",
    "protein": "25",
    "cal-from-protein": "100",
    "per-cal-from-protein": "100",
    "calcium": "80",
    "old units (ref)": "https://www.lac.sg/en/brands/isopure/whey-protein-isolate-unflavored-75600280.html?catId=brands_isopure"
  },
  {
    "id": "1469",
    "type": "drink",
    "|| name                                               ||": "Herbalife Formula 3 Personalized Protein Powder",
    "rating": "3",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "tbsp",
    "weight-g": "6",
    "calories": "20",
    "fat": "0",
    "cal-from-fat": "0",
    "per-cal-from-fat": "0",
    "saturated": "0",
    "cholesterol": "0",
    "sodium": "50",
    "carbs": "0",
    "fibre": "0",
    "sugar": "0",
    "protein": "5",
    "cal-from-protein": "20",
    "per-cal-from-protein": "100",
    "potassium": "30",
    "iron": "0",
    "old units (ref)": "https://www.herbalife.com/en-sg/u/products/formula-3-personalized-protein-powder-240g-0242"
  },
  {
    "id": "1470",
    "type": "drink",
    "|| name                                               ||": "Nutrilite All Plant Protein Powder",
    "rating": "3",
    "plant-protein": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "weight-g": "12.5",
    "calories": "50",
    "fat": "0.5",
    "cal-from-fat": "4.5",
    "per-cal-from-fat": "9",
    "saturated": "0",
    "cholesterol": "0",
    "sodium": "125",
    "carbs": "0.4",
    "sugar": "0",
    "protein": "10",
    "cal-from-protein": "40",
    "per-cal-from-protein": "80",
    "calcium": "25",
    "iron": "1",
    "old units (ref)": "https://www.amway.sg/Shop-All/Health/Protein/Nutrilite-All-Plant-Protein-Powder/p/110415R"
  },
  {
    "id": "1471",
    "type": "snack",
    "|| name                                               ||": "Protein bar, 10g protein",
    "rating": "2",
    "high-fat": "1",
    "high-fibre": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "bar",
    "info-include-exclude": "generic",
    "weight-g": "40",
    "calories": "165",
    "fat": "8",
    "cal-from-fat": "72",
    "per-cal-from-fat": "44",
    "saturated": "3.1",
    "sodium": "165",
    "carbs": "11",
    "fibre": "6",
    "sugar": "4",
    "protein": "10",
    "cal-from-protein": "40",
    "per-cal-from-protein": "24",
    "old units (ref)": "estimated (averaged values)"
  },
  {
    "id": "1472",
    "type": "snack",
    "|| name                                               ||": "Protein bar, 15g protein",
    "rating": "2",
    "high-fat": "1",
    "high-fibre": "1",
    "high-salt": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "bar",
    "info-include-exclude": "generic",
    "weight-g": "48",
    "calories": "162",
    "fat": "6",
    "cal-from-fat": "54",
    "per-cal-from-fat": "33",
    "saturated": "3.3",
    "sodium": "190",
    "carbs": "12",
    "fibre": "8",
    "sugar": "2",
    "protein": "15",
    "cal-from-protein": "60",
    "per-cal-from-protein": "37",
    "old units (ref)": "estimated (averaged values)"
  },
  {
    "id": "1473",
    "type": "snack",
    "|| name                                               ||": "Protein bar, 20g protein",
    "rating": "2",
    "high-fat": "1",
    "high-fibre": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "bar",
    "info-include-exclude": "generic",
    "weight-g": "59",
    "calories": "204",
    "fat": "8",
    "cal-from-fat": "72",
    "per-cal-from-fat": "35",
    "saturated": "3.4",
    "sodium": "136",
    "carbs": "15",
    "fibre": "7",
    "sugar": "1",
    "protein": "20",
    "cal-from-protein": "80",
    "per-cal-from-protein": "39",
    "old units (ref)": "estimated (averaged values)"
  },
  {
    "id": "1474",
    "type": "drink",
    "|| name                                               ||": "Protein powder",
    "rating": "3",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "serving",
    "info-include-exclude": "generic",
    "weight-g": "33",
    "calories": "126",
    "fat": "2",
    "cal-from-fat": "18",
    "per-cal-from-fat": "14",
    "carbs": "3",
    "protein": "24",
    "cal-from-protein": "96",
    "per-cal-from-protein": "76",
    "old units (ref)": "estimated (averaged values)"
  },
  {
    "id": "1475",
    "type": "drink",
    "|| name                                               ||": "3-in-1 coffee, regular",
    "rating": "1",
    "high-sugar": "1",
    "processed": "1",
    "serving": "1",
    "|| unit ||": "sachet",
    "weight-g": "20",
    "calories": "84",
    "fat": "1.7",
    "cal-from-fat": "15.3",
    "per-cal-from-fat": "18",
    "saturated": "1.7",
    "trans": "0",
    "cholesterol": "0",
    "sodium": "13",
    "carbs": "16",
    "fibre": "0.6",
    "sugar": "10.5",
    "protein": "0.5",
    "cal-from-protein": "2",
    "per-cal-from-protein": "2"
  },
  {
    "id": "1476",
    "type": "meal",
    "|| name                                               ||": "Char siew and roast pork rice",
    "rating": "1",
    "high-cholesterol": "1",
    "high-fat": "1",
    "high-iron": "1",
    "high-salt": "1",
    "red-meat": "1",
    "refined-grain": "1",
    "serving": "1",
    "|| unit ||": "plate",
    "alternate-spellings": "Char: Cha \nSiew: Shao, Siu",
    "weight-g": "449",
    "calories": "927",
    "fat": "39",
    "cal-from-fat": "351",
    "per-cal-from-fat": "38",
    "saturated": "16",
    "cholesterol": "155",
    "sodium": "1570",
    "carbs": "93",
    "fibre": "2.9",
    "sugar": "5.5",
    "protein": "49",
    "cal-from-protein": "196",
    "per-cal-from-protein": "21",
    "iron": "2.1",
    "old units (ref)": "estimated (90g roast pork, 70g char siew, 2 tbsp char siw sauce, 1.3 bowl rice, 25g cucumber)"
  }
]
    });
  