define("ember-animated-routes/templates/components/route", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let
    (component this.componentName model=this.renderTask.attributes __renderTask__=this.renderTask)
    as |RouteComponent|
  }}
    {{#if (has-block)}}
  
      {{yield
        (hash
          inDOM=this.inDOM
          isActive=this.isActive
          component=RouteComponent
          __renderTask__=this.renderTask
        )
      }}
  
    {{else if this.inDOM}}
  
      <RouteComponent />
  
    {{/if}}
  {{/let}}
  */
  {
    "id": "zWSEYe8c",
    "block": "[[[44,[[50,[30,0,[\"componentName\"]],0,null,[[\"model\",\"__renderTask__\"],[[30,0,[\"renderTask\",\"attributes\"]],[30,0,[\"renderTask\"]]]]]],[[[41,[48,[30,2]],[[[1,\"\\n    \"],[18,2,[[28,[37,5],null,[[\"inDOM\",\"isActive\",\"component\",\"__renderTask__\"],[[30,0,[\"inDOM\"]],[30,0,[\"isActive\"]],[30,1],[30,0,[\"renderTask\"]]]]]]],[1,\"\\n\\n\"]],[]],[[[41,[30,0,[\"inDOM\"]],[[[1,\"\\n    \"],[8,[30,1],null,null,null],[1,\"\\n\\n  \"]],[]],null]],[]]]],[1]]]],[\"RouteComponent\",\"&default\"],false,[\"let\",\"component\",\"if\",\"has-block\",\"yield\",\"hash\"]]",
    "moduleName": "ember-animated-routes/templates/components/route.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});