
    ;define("novi-data/activities", ["exports"], function (_exports) {
      "use strict";

      Object.defineProperty(_exports, "__esModule", {
        value: true
      });
      _exports.default = [
  {
    "id": 0,
    "title": "Prescribed Workout"
  },
  {
    "id": 1,
    "title": "Aerobics"
  },
  {
    "id": 2,
    "title": "Badminton"
  },
  {
    "id": 3,
    "title": "Cricket"
  },
  {
    "id": 4,
    "title": "Basketball"
  },
  {
    "id": 5,
    "title": "Bowling"
  },
  {
    "id": 6,
    "title": "Kayaking / Dragon Boating"
  },
  {
    "id": 7,
    "title": "Cycling"
  },
  {
    "id": 8,
    "title": "Dancing"
  },
  {
    "id": 9,
    "title": "Football / Rugby",
    "disabled": true
  },
  {
    "id": 10,
    "title": "Frisbee / Disc Sports"
  },
  {
    "id": 11,
    "title": "Golf"
  },
  {
    "id": 12,
    "title": "Gymnastics"
  },
  {
    "id": 13,
    "title": "Handball"
  },
  {
    "id": 14,
    "title": "Hockey / Floorball / Ice Hockey"
  },
  {
    "id": 15,
    "title": "Martial Arts / MMA"
  },
  {
    "id": 16,
    "title": "Netball"
  },
  {
    "id": 17,
    "title": "Pilates"
  },
  {
    "id": 18,
    "title": "Running / Jogging"
  },
  {
    "id": 19,
    "title": "Rock Climbing"
  },
  {
    "id": 20,
    "title": "Ice Skating"
  },
  {
    "id": 21,
    "title": "Sepak Takraw"
  },
  {
    "id": 22,
    "title": "Football (Soccer)"
  },
  {
    "id": 23,
    "title": "Squash"
  },
  {
    "id": 24,
    "title": "Bodyweight Strength, e.g. Squats / Pushups"
  },
  {
    "id": 25,
    "title": "Swimming"
  },
  {
    "id": 26,
    "title": "Tai Chi"
  },
  {
    "id": 27,
    "title": "Tennis"
  },
  {
    "id": 28,
    "title": "Track and Field"
  },
  {
    "id": 29,
    "title": "Volleyball"
  },
  {
    "id": 30,
    "title": "Walking"
  },
  {
    "id": 31,
    "title": "Water Polo"
  },
  {
    "id": 32,
    "title": "Weightlifting"
  },
  {
    "id": 33,
    "title": "Yoga"
  },
  {
    "id": 34,
    "title": "Archery"
  },
  {
    "id": 35,
    "title": "Barre"
  },
  {
    "id": 36,
    "title": "Baseball"
  },
  {
    "id": 37,
    "title": "Biathlon"
  },
  {
    "id": 38,
    "title": "Boxing"
  },
  {
    "id": 39,
    "title": "Calisthenics"
  },
  {
    "id": 40,
    "title": "Circuit Training"
  },
  {
    "id": 41,
    "title": "Climbing"
  },
  {
    "id": 42,
    "title": "Core Training"
  },
  {
    "id": 43,
    "title": "Cross Training"
  },
  {
    "id": 44,
    "title": "Crossfit"
  },
  {
    "id": 45,
    "title": "Curling"
  },
  {
    "id": 46,
    "title": "Diving"
  },
  {
    "id": 47,
    "title": "Elliptical"
  },
  {
    "id": 48,
    "title": "Fencing"
  },
  {
    "id": 49,
    "title": "Fitness Gaming"
  },
  {
    "id": 50,
    "title": "Flexibility"
  },
  {
    "id": 51,
    "title": "Football (American)"
  },
  {
    "id": 52,
    "title": "Gardening"
  },
  {
    "id": 53,
    "title": "High Intensity Interval Training (HIIT)"
  },
  {
    "id": 54,
    "title": "Hiking"
  },
  {
    "id": 55,
    "title": "Horseback Riding"
  },
  {
    "id": 56,
    "title": "Jump Rope / Skipping"
  },
  {
    "id": 57,
    "title": "Kickboxing"
  },
  {
    "id": 58,
    "title": "Kitesurfing"
  },
  {
    "id": 59,
    "title": "Lacrosse"
  },
  {
    "id": 60,
    "title": "Mixed Cardio"
  },
  {
    "id": 61,
    "title": "Mountain Biking"
  },
  {
    "id": 62,
    "title": "Other"
  },
  {
    "id": 63,
    "title": "Pickleball"
  },
  {
    "id": 64,
    "title": "Polo"
  },
  {
    "id": 65,
    "title": "Racquetball"
  },
  {
    "id": 66,
    "title": "Rowing"
  },
  {
    "id": 67,
    "title": "Rowing Machine"
  },
  {
    "id": 68,
    "title": "Rugby"
  },
  {
    "id": 69,
    "title": "Sailing"
  },
  {
    "id": 70,
    "title": "Scuba Diving"
  },
  {
    "id": 71,
    "title": "Skateboarding"
  },
  {
    "id": 72,
    "title": "Skating"
  },
  {
    "id": 73,
    "title": "Snow Sports, e.g. Skiing / Snowboarding"
  },
  {
    "id": 74,
    "title": "Social Dance"
  },
  {
    "id": 75,
    "title": "Softball"
  },
  {
    "id": 76,
    "title": "Spinning"
  },
  {
    "id": 77,
    "title": "Stair Climbing"
  },
  {
    "id": 78,
    "title": "Stand-up Paddleboarding"
  },
  {
    "id": 79,
    "title": "Step Training"
  },
  {
    "id": 80,
    "title": "Surfing"
  },
  {
    "id": 81,
    "title": "Table Tennis (Ping Pong)"
  },
  {
    "id": 82,
    "title": "Triathlon"
  },
  {
    "id": 83,
    "title": "Wakeboarding"
  },
  {
    "id": 84,
    "title": "Water Fitness"
  },
  {
    "id": 85,
    "title": "Wheelchair"
  },
  {
    "id": 86,
    "title": "Windsurfing"
  },
  {
    "id": 87,
    "title": "Wrestling"
  },
  {
    "id": 88,
    "title": "Zumba"
  }
]
    });
  