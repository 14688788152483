
    ;define("novi-data/medicine", ["exports"], function (_exports) {
      "use strict";

      Object.defineProperty(_exports, "__esModule", {
        value: true
      });
      _exports.default = [
  {
    "id": 0,
    "name": "ACTRAPID",
    "description": "Insulin Soluble",
    "type": "bolus"
  },
  {
    "id": 1,
    "name": "APIDRA",
    "description": "Insulin Glulisine",
    "type": "bolus"
  },
  {
    "id": 2,
    "name": "BASAGLAR",
    "description": "Insulin Glargine",
    "type": "basal"
  },
  {
    "id": 3,
    "name": "HUMALOG",
    "description": "Insulin Lispro",
    "type": "bolus"
  },
  {
    "id": 4,
    "name": "HUMALOG MIX 25/75",
    "description": "Insulin Lispro 25% + Insulin Lispro Protamine 75%",
    "type": "mixed"
  },
  {
    "id": 5,
    "name": "HUMALOG MIX 50/50",
    "description": "Insulin Lispro 50% + Insulin Lispro Protamine 50%",
    "type": "mixed"
  },
  {
    "id": 6,
    "name": "HUMULIN 30/70",
    "description": "Insulin Soluble 30% + Insulin Isophane 70%",
    "type": "mixed"
  },
  {
    "id": 7,
    "name": "HUMULIN N",
    "description": "Insulin Isophane",
    "type": "bolus"
  },
  {
    "id": 8,
    "name": "HUMULIN R",
    "description": "Insulin Soluble",
    "type": "bolus"
  },
  {
    "id": 9,
    "name": "INSULATARD",
    "description": "Insulin Isophane",
    "type": "bolus"
  },
  {
    "id": 10,
    "name": "LANTUS",
    "description": "Insulin Glargine",
    "type": "basal"
  },
  {
    "id": 11,
    "name": "LEVEMIR",
    "description": "Insulin Detemir",
    "type": "basal"
  },
  {
    "id": 12,
    "name": "MIXTARD 30",
    "description": "Insulin Soluble 30% + Insulin Isophane 70%",
    "type": "mixed"
  },
  {
    "id": 13,
    "name": "NOVOMIX 30",
    "description": "Insulin Aspart 30% + Insulin Aspart Protamine 70%",
    "type": "mixed"
  },
  {
    "id": 14,
    "name": "NOVORAPID",
    "description": "Insulin Aspart",
    "type": "bolus"
  },
  {
    "id": 15,
    "name": "PODEVTA",
    "description": "Insulin Glargine",
    "type": "basal"
  },
  {
    "id": 16,
    "name": "SOLIQUA",
    "description": "Insulin Glargine 300unit/3mL + Lixisenatide 100mcg/3mL",
    "type": "basal"
  },
  {
    "id": 17,
    "name": "SOLIQUA",
    "description": "Insulin Glargine 300unit/3mL + Lixisenatide 150mcg/3mL",
    "type": "basal"
  },
  {
    "id": 18,
    "name": "TOUJEO",
    "description": "Insulin Glargine",
    "type": "basal"
  }
]
    });
  