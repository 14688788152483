
    ;define("novi-data/food-repository", ["exports"], function (_exports) {
      "use strict";

      Object.defineProperty(_exports, "__esModule", {
        value: true
      });
      _exports.default = [
  {
    "level": 1,
    "name": "Bread",
    "is_variation": false,
    "items": [
      {
        "level": 2,
        "name": "Gardenia - Wholemeal Bread - Fine Grain",
        "image": "Bread_ Gardenia - Wholemeal Bread - Fine Grain.jpg",
        "text": "Gardenia Fine Grain contains a higher amount of fibre compared to white bread, which promotes greater satiety. It also takes a longer time to digest, leading to a slower rise in blood glucose levels after consumption.",
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Gardenia - White Bread - High Fibre",
        "image": "Bread_Gardenia - White Bread - High Fibre.jpg",
        "text": "High Fibre White Bread contains the same fibre content as wholemeal bread while retaining the softness of white bread. It is also fortified with vitamins and minerals to boost its nutritional content.",
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Sunshine - Smart Carb Bread - Low G.I",
        "image": "Bread_Sunshine - Smart Carb Bread - Low G.I.jpg",
        "text": "Sunshine Smart-Carb Low G.I Bread contains a high amount of fibre, which promotes greater satiety. It is also lower in carbohydrates and Glycaemic Index (GI), resulting in a more gradual increase in blood sugar levels.",
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Sunshine - Ultra Fine 100% Whole Grains",
        "image": "Bread_Sunshine - Ultra Fine 100 Whole Grains.jpg",
        "text": "Sunshine Ultra Fine contains a higher amount of fibre compared to white bread; yet, it has a soft and ultra-fine texture. The higher fibre content promotes great satiety. It also takes a longer time to digest, leading to a slower rise in blood glucose levels after consumption.",
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Swissbake - Abend Brot Evening Bread",
        "image": "Bread_Swissbake - Abend Brot Evening Bread.jpg",
        "text": "Swissbake Abend Brot Eve Bread is low in carbs, high in protein, and high in fibre. The higher fibre content promotes greater satiety. The low carb content makes it a great option to control your carb portion better and reduce the post-meal glucose rise.",
        "is_variation": true
      }
    ]
  },
  {
    "level": 1,
    "name": "Wrap",
    "is_variation": false,
    "items": [
      {
        "level": 2,
        "name": "Mission - Wraps - Wholegrain",
        "image": "Wrap_Mission - Wraps - Wholegrain.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Mission - Wraps - Low Carb",
        "image": "Wrap_Mission - Wraps - Low Carb.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Mission - Mini Wraps - Wholemeal",
        "image": "Wrap_Mission - Mini Wraps - Wholemeal.jpg",
        "text": null,
        "is_variation": true
      }
    ]
  },
  {
    "level": 1,
    "name": "Spreads",
    "is_variation": false,
    "items": [
      {
        "level": 2,
        "name": "Ayam Brand - Tuna Chunks in Water",
        "image": "Spreads_Ayam Brand - Tuna Chunks in Water.jpg",
        "text": "Tuna chunks in water is a great source of protein and omega-3. It is also lower in fat and calories compared to tuna in oil or tuna flavoured with mayonnaise, curry, or chilli. It is a convenient protein source that can be easily added to your bread, salad, or other dishes. If you prefer smaller pieces, you can opt for tuna flakes instead.",
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Ayam Brand - Tuna Flakes in Water - Light",
        "image": "Spreads_Ayam Brand - Tuna Flakes in Water - Light.jpg",
        "text": "Tuna flakes in water is a great source of protein and omega-3. It is also lower in fat and calories compared to tuna in oil or tuna flavoured with mayonnaise, curry, or chilli. It is a convenient protein source that can be easily added to your bread, salad, or other dishes. If you prefer bigger pieces, you can opt for tuna chunks instead.",
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Ayam Brand - Sardines in Tomato - Light",
        "image": "Spreads_Ayam Brand - Sardines in Tomato - Light.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Ayam Brand - Sardines in Spring Water",
        "image": "Spreads_Ayam Brand - Sardines in Spring Water.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Fortune - Tuna Chunks in Brine",
        "image": "Spreads_Fortune - Tuna Chunks in Brine.jpg",
        "text": "Tuna chunks in brine is a great source of protein and omega-3. It is also lower in fat and calories compared to tuna in oil or tuna flavoured with mayonnaise, curry, or chilli. It is a convenient protein source that can be easily added to your bread, salad, or other dishes. If you prefer smaller pieces, you can opt for tuna flakes instead.",
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Ayam Brand - Mackerel Fillet in Tomato Sauce",
        "image": "Spreads_Ayam Brand - Mackerel Fillet in Tomato Sauce.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Bulla - Cottage Cheese - Original",
        "image": "Spreads_Bulla - Cottage Cheese - Original.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Naturel - Cholesterol Free Margarine - Soft",
        "image": "Spreads_Naturel - Cholesterol Free Margarine - Soft.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Naturel - Cholesterol Free Spread - Extra Virgin Olive",
        "image": "Spreads_Naturel - Cholesterol Free Spread - Extra Virgin Olive.jpg",
        "text": null,
        "is_variation": true
      }
    ]
  },
  {
    "level": 1,
    "name": "Cereals",
    "is_variation": false,
    "items": [
      {
        "level": 2,
        "name": "Quaker - Oatmeal - Instant",
        "image": "Cereals_Quaker - Oatmeal - Instant.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Captain Oats - Oatmeal - Instant",
        "image": "Cereals_Captain Oats - Oatmeal - Instant.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Quaker - Oatmeal - Quick Cook",
        "image": "Cereals_Quaker - Oatmeal - Quick Cook.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Captain Oats - Oatmeal - Quick Cook",
        "image": "Cereals_Captain Oats - Oatmeal - Quick Cook.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Quaker - Whole Rolled Oats - 100% Wholegrain",
        "image": "Cereals_Quaker - Whole Rolled Oats - 100 Wholegrain.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Harvest Fields - Rolled Oats - Whole Grain",
        "image": "Cereals_Harvest Fields - Rolled Oats - Whole Grain.jpg",
        "text": null,
        "is_variation": true
      }
    ]
  },
  {
    "level": 1,
    "name": "Yoghurts",
    "is_variation": false,
    "items": [
      {
        "level": 2,
        "name": "Emmi - Swiss Premium Greek Style Yoghurt - Natural (0% Fat)",
        "image": "Yoghurt_Emmi - Swiss Premium Greek Style Yoghurt - Natural (0 Fat).jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Emmi - Swiss Premium Low Fat Yoghurt - Plain",
        "image": "Yoghurt_Emmi - Swiss Premium Low Fat Yoghurt - Plain.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Evia - Protein Yoghurt - Vanilla",
        "image": "Yoghurt_Evia - Protein Yoghurt - Vanilla.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Chobani - Greek Yoghurt - Natural Light",
        "image": "Yoghurt_Chobani - Greek Yoghurt - Natural Light.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Farmers Union - Greek Style Yoghurt - High Protein (less than 0.5% Fat)",
        "image": "Yoghurt_Farmers Union - Greek Style Yoghurt - High Protein (less than 0.5 Fat).jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Farmers Union - Greek Style Yoghurt - Light",
        "image": "Yoghurt_Farmers Union - Greek Style Yoghurt - Light.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Farmers Union - Greek Style Yoghurt - Natural",
        "image": "Yoghurt_Farmers Union - Greek Style Yoghurt - Natural.jpg",
        "text": null,
        "is_variation": true
      }
    ]
  },
  {
    "level": 1,
    "name": "Protein Bars",
    "is_variation": false,
    "items": [
      {
        "level": 2,
        "name": "MyProtein - Pop Rolls",
        "image": "ProteinBars_MyProtein - Pop Rolls.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "MyProtein - Lean Layered Bar",
        "image": "ProteinBars_MyProtein - Lean Layered Bar.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "MyVegan - Choc Chew",
        "image": "ProteinBars_MyVegan - Choc Chew.jpg",
        "text": null,
        "is_variation": true
      }
    ]
  },
  {
    "level": 1,
    "name": "Protein Powder",
    "is_variation": false,
    "items": [
      {
        "level": 2,
        "name": "Optimum Nutrition - Gold Standard 100% Whey - Double Rich Chocolate",
        "image": "ProteinPowder_Optimum Nutrition - Gold Standard 100 Whey - Double Rich Chocolate.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "True - WPI90 - Whey Protein Isolate",
        "image": "ProteinPowder_True - WPI90 - Whey Protein Isolate.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "MyVegan - Soy Protein Isolate Powder",
        "image": "ProteinPowder_MyVegan - Soy Protein Isolate Powder.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "MyVegan - Pea Protein Isolate Powder",
        "image": "ProteinPowder_MyVegan - Pea Protein Isolate Powder.jpg",
        "text": null,
        "is_variation": true
      }
    ]
  },
  {
    "level": 1,
    "name": "Soya Milk",
    "is_variation": false,
    "items": [
      {
        "level": 2,
        "name": "Nutrisoy - Soya Milk - Unsweetened",
        "image": "Soyamilk_Nutrisoy - Soya Milk - Unsweetened.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Marigold - Soya Milk - Unsweetened",
        "image": "Soyamilk_Marigold - Soya Milk - Unsweetened.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Nutrisoy - Fresh Soya Milk - Reduced Sugar",
        "image": "Soyamilk_Nutrisoy - Fresh Soya Milk - Reduced Sugar.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Nutrisoy - Fresh Soya Milk - Original",
        "image": "Soyamilk_Nutrisoy - Fresh Soya Milk - Original.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Maeil - Soya Milk - Unsweetened",
        "image": "Soyamilk_Maeil - Soya Milk - Unsweetened.jpg",
        "text": null,
        "is_variation": true
      }
    ]
  },
  {
    "level": 1,
    "name": "Low Fat Milk",
    "is_variation": false,
    "items": [
      {
        "level": 2,
        "name": "Anlene - Gold Concentrate UHT Milk - Vanilla",
        "image": "Low Fat Milk_Anlene - Gold Concentrate UHT Milk - Vanilla.jpg",
        "text": null,
        "is_variation": true
      }
    ]
  },
  {
    "level": 1,
    "name": "Rice",
    "is_variation": false,
    "items": [
      {
        "level": 2,
        "name": "Naturel - Organic Rice - Brown",
        "image": "Rice_Naturel - Organic Rice - Brown.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "FairPrice - Thai Red Unpolished Rice",
        "image": "Rice_FairPrice - Thai Red Unpolished Rice.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "24 Mantra - Organic Brown Basmati Rice",
        "image": "Rice_24 Mantra - Organic Brown Basmati Rice.jpg",
        "text": null,
        "is_variation": true
      }
    ]
  },
  {
    "level": 1,
    "name": "Noodles",
    "is_variation": false,
    "items": [
      {
        "level": 2,
        "name": "Koka - Non-Fried Plain Instant Noodles - Low Fat",
        "image": "Noodles_Koka - Non-Fried Plain Instant Noodles - Low Fat.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "AAA - Brown Rice Vermicelli/Beehoon",
        "image": "Noodles_AAA - Brown Rice Vermicelli/Beehoon.jpg",
        "text": null,
        "is_variation": true
      }
    ]
  },
  {
    "level": 1,
    "name": "Pasta",
    "is_variation": false,
    "items": [
      {
        "level": 2,
        "name": "FairPrice - Whole Wheat Pasta - Fusilli",
        "image": "Pasta_FairPrice - Whole Wheat Pasta - Fusilli.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "San Remo - Wholemeal Pasta - Spaghetti",
        "image": "Pasta_San Remo - Wholemeal Pasta - Spaghetti.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Seapoint Farms - Organic Edamame Spaghetti",
        "image": "Pasta_Seapoint Farms - Organic Edamame Spaghetti.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Chickapea - Organic Spirals",
        "image": "Pasta_Chickapea - Organic Spirals.jpg",
        "text": null,
        "is_variation": true
      }
    ]
  },
  {
    "level": 1,
    "name": "Plant-based Protein Food Sources",
    "is_variation": false,
    "items": [
      {
        "level": 2,
        "name": "Sojasun - Natural Soya Yoghurt",
        "image": "Plant Protein_Sojasun - Natural Soya Yoghurt.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Impossible - Beef",
        "image": "Plant Protein_Impossible - Beef.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Quorn - Pieces - Meat Free",
        "image": "Plant Protein_Quorn - Pieces - Meat Free.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Quorn - Mince - Meat Free",
        "image": "Plant Protein_Quorn - Mince - Meat Free.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Quorn - Fillets - Meat Free",
        "image": "Plant Protein_Quorn - Fillets - Meat Free.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Bob's Red Mill - Large Flake Nutritional Yeast",
        "image": "Plant Protein_Bob's Red Mill - Large Flake Nutritional Yeast.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "SunnyFarm - Edamame",
        "image": "Plant Protein_SunnyFarm - Edamame (Unsalted).jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Soon Brand - Edamame",
        "image": "Plant Protein_Soon Brand - Edamame (Unsalted).jpg",
        "text": null,
        "is_variation": true
      }
    ]
  },
  {
    "level": 1,
    "name": "High Protein Snacks",
    "is_variation": false,
    "items": [
      {
        "level": 2,
        "name": "Bodiz - Protein Water - Mango Passion",
        "image": "High Protein_Bodiez - Protein Water - Mango Passion.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "7 Eleven - Hanjuku Eggs",
        "image": "High Protein_7 Eleven - Hanjuku Eggs.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Betagro - Tender Chicken Breast",
        "image": "High Protein_Betagro - Tender Chicken Breast.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Seapoint Farms - Dry Roasted Edamame - Wasabi",
        "image": "High Protein_Seapoint Farms - Dry Roasted Edamame - Wasabi.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Seapoint Farms - Dry Roasted Edamame - Sea Salt",
        "image": "High Protein_Seapoint Farms - Dry Roasted Edamame - Sea Salt.jpg",
        "text": null,
        "is_variation": true
      }
    ]
  },
  {
    "level": 1,
    "name": "Low Calories Snack",
    "is_variation": false,
    "items": [
      {
        "level": 2,
        "name": "Woo Yang - Blueberry - Frozen",
        "image": "Low Calorie_Woo Yang - Blueberry - Frozen.jpg",
        "text": null,
        "is_variation": true
      }
    ]
  },
  {
    "level": 1,
    "name": "Chocolates",
    "is_variation": false,
    "items": [
      {
        "level": 2,
        "name": "Sugarless Confectionery - Dark Chocolate & Mint",
        "image": "Choc_Sugarless Confectionery - Dark Chocolate & Mint.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Sugarless Confectionery - Chocolate Crunch Balls",
        "image": "Choc_Sugarless Confectionery - Chocolate Crunch Balls.jpg",
        "text": null,
        "is_variation": true
      }
    ]
  },
  {
    "level": 1,
    "name": "Sugarless Drinks",
    "is_variation": false,
    "items": [
      {
        "level": 2,
        "name": "F&N - Ice Mountain Sparkling Can Water",
        "image": "SugarlessDrinks_F&N - Ice Mountain Sparkling Can Water.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "F&N - Seasons Ice Lemon Tea - Zero Sugar",
        "image": "SugarlessDrinks_F&N - Seasons Ice Lemon Tea - Zero Sugar.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Remedy - Organic Kombucha Can Drink",
        "image": "SugarlessDrinks_Remedy - Organic Kombucha Can Drink.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "100 Plus - Zero Sugar",
        "image": "SugarlessDrinks_100 Plus - Zero Sugar.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Coca-cola - Zero Sugar",
        "image": "SugarlessDrinks_Coca-cola - Zero Sugar.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Jia Jia - Herbal Tea - Zero Sugar",
        "image": "SugarlessDrinks_Jia Jia - Herbal Tea - Zero Sugar.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Twinnings - Cold Infuse Tea - Watermelon & Mint",
        "image": "SugarlessDrinks_Twinnings - Cold Infuse Tea - Watermelon & Mint.jpg",
        "text": null,
        "is_variation": true
      },
      {
        "level": 2,
        "name": "Basilur - Assorted Black Fruit Teas",
        "image": "SugarlessDrinks_Basilur - Assorted Black Fruit Teas.jpg",
        "text": null,
        "is_variation": true
      }
    ]
  }
]
    });
  